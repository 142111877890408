import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Button,
  Radio
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import styles from '../styles/OtherRelatedPIP.styles';

// assets
import { CaretDownIcon } from '../../../assets/svgs/Icons';

const StyledAccordion = styled(Accordion)(styles.styledAccordion);

const OtherRelatedPIP = ({
  setOtherPIPOpen,
  handleRadioChange,
  otherPIPs,
  selectedPipNumber
}: any) => {
  const [openPIP, setOpenPIP] = useState([selectedPipNumber]);

  const toggleOpenPIP = (pipNumber: string) => {
    if (openPIP.includes(pipNumber)) {
      // If pip_number exists, remove it
      setOpenPIP(openPIP?.filter(each => each !== pipNumber));
    } else {
      // If pip_number doesn't exist, add it
      setOpenPIP([...openPIP, pipNumber]);
    }
  };

  const handleRadio = (pip: any) => {
    handleRadioChange(pip);
    if (!openPIP.includes(pip.pi_number)) {
      setOpenPIP(prev => [...prev, pip.pip_number]);
    }
  };

  return (
    <Box sx={styles.wrapper}>
      <Box height={60} sx={styles.innerWrapper}>
        <Typography sx={styles.headerText}>Other related PIPs</Typography>
        <Button
          onClick={(event: any) => {
            event.stopPropagation();
            setOtherPIPOpen(false);
          }}
          sx={{ p: 0, minWidth: 0, mr: 0.5 }}>
          <CloseIcon
            sx={{
              fontSize: 27.45,
              color: 'gray.0',
              cursor: 'pointer'
            }}
          />
        </Button>
      </Box>
      <Divider />
      <Box maxHeight='100vh' sx={styles.sectionWrapper}>
        {otherPIPs?.map((pip: any) => {
          return (
            <Box
              key={pip.pip_number}
              display='flex'
              flexDirection='row'
              justifyContent='space-between'>
              <StyledAccordion
                disableGutters
                expanded={openPIP.includes(pip.pip_number)}
                onChange={event => {
                  event.stopPropagation();
                }}>
                <AccordionSummary sx={styles.accordionSummary}>
                  <IconButton
                    sx={{ width: '16px', height: '16px' }}
                    onClick={() => toggleOpenPIP(pip.pip_number)}>
                    <CaretDownIcon
                      sx={{
                        ...styles.caretDown,
                        rotate: openPIP.includes(pip.pip_number) ? '180deg' : '0deg'
                      }}
                    />
                  </IconButton>
                  <Radio
                    checked={pip.pip_number === selectedPipNumber}
                    onChange={() => handleRadio(pip)}
                    sx={styles.radio}
                  />
                  <Tooltip title={pip.pip_number}>
                    <Typography sx={styles.pipNumberText}>{pip.pip_number}</Typography>
                  </Tooltip>
                </AccordionSummary>
                <AccordionDetails sx={styles.accordionDetails}>
                  <Box sx={styles.accordionInnerBox}>
                    <Box>
                      <Typography sx={styles.accordionHeaderText}>Sponsor Name</Typography>
                      <Typography sx={styles.accordionHeaderContent}>{pip.sponsor_name}</Typography>
                    </Box>
                    <Box>
                      <Typography sx={styles.accordionHeaderText}>Indication</Typography>
                      <Typography sx={styles.accordionHeaderContent}>{pip.indication}</Typography>
                    </Box>
                    <Box>
                      <Typography sx={styles.accordionHeaderText}>Therapeutic Area</Typography>
                      <Typography sx={styles.accordionHeaderContent}>
                        {pip.therapeutic_area}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={styles.accordionHeaderText}>Trade Name</Typography>
                      <Typography sx={styles.accordionHeaderContent}>{pip.brand_name}</Typography>
                    </Box>
                  </Box>
                </AccordionDetails>
              </StyledAccordion>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default React.memo(OtherRelatedPIP);
