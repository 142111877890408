const US_CT_ROWS: any = {
  infant: false,
  toddler: false,
  child: false,
  adolescent: false,
  adult: false,
  'older-adults': false
};
const EU_CT_ROWS = {
  'in-utero': false,
  newborns: false,
  'newborn-infants': false,
  'infants-toddlers': false,
  children: false,
  adolescent: false,
  adults: false,
  elderly: false
};
const EU_CT_PED_ROWS = {
  'Preterm Newborn Infants': false,
  'Newborn Infants': false,
  'Infants And Toddlers': false,
  Children: false,
  Adolescents: false
};

const US_CT_AVATARS = [
  {
    label: 'Infant',
    initial: 'IN',
    hoverText: 'Infant',
    fieldName: 'infant'
  },
  {
    label: 'Toddler',
    initial: 'TL',
    hoverText: 'Toddler',
    fieldName: 'toddler'
  },
  {
    label: 'Child',
    initial: 'CH',
    hoverText: 'Child',
    fieldName: 'child'
  },
  {
    label: 'Adolescent',
    initial: 'AS',
    hoverText: 'Adolescent',
    fieldName: 'adolescent'
  },
  {
    label: 'Adult',
    initial: 'AD',
    hoverText: 'Adult',
    fieldName: 'adult'
  },
  {
    label: 'Older-adults',
    initial: 'OA',
    hoverText: 'Older-adults',
    fieldName: 'older-adults'
  }
];
const EU_CT_AVATARS = [
  {
    label: 'In Utero',
    initial: 'IN',
    hoverText: 'In Utero',
    fieldName: 'in-utero'
  },
  {
    label: 'Newborns',
    initial: 'NB',
    hoverText: 'Newborns',
    fieldName: 'newborns'
  },
  {
    label: 'Newborn Infants',
    initial: 'NI',
    hoverText: 'Newborn Infants',
    fieldName: 'newborn-infants'
  },
  {
    label: 'Infants Toddlers',
    initial: 'IT',
    hoverText: 'Infants Toddlers',
    fieldName: 'infants-toddlers'
  },
  {
    label: 'Children',
    initial: 'CH',
    hoverText: 'Children',
    fieldName: 'children'
  },
  {
    label: 'Adolescent',
    initial: 'AS',
    hoverText: 'Adolescent',
    fieldName: 'adolescent'
  },
  {
    label: 'Adults',
    initial: 'AD',
    hoverText: 'Adults',
    fieldName: 'adults'
  },
  {
    label: 'Elderly',
    initial: 'EL',
    hoverText: 'Elderly',
    fieldName: 'elderly'
  }
];
const EU_CTR_PEDIATRICS_AVATARS = [
  {
    label: 'Preterm Newborn Infants',
    initial: 'PIN',
    hoverText: 'Preterm Newborn Infants',
    fieldName: 'Preterm Newborn Infants'
  },

  {
    label: 'Newborn Infants',
    initial: 'NI',
    hoverText: 'Newborn Infants',
    fieldName: 'Newborn Infants'
  },
  {
    label: 'Infants Toddlers',
    initial: 'IT',
    hoverText: 'Infants And Toddlers',
    fieldName: 'Infants And Toddlers'
  },

  {
    label: 'Children',
    initial: 'CH',
    hoverText: 'Children',
    fieldName: 'Children'
  },
  {
    label: 'Adolescents',
    initial: 'AS',
    hoverText: 'Adolescents',
    fieldName: 'Adolescents'
  }
];

export const ROWS: any = {
  euctr: EU_CT_ROWS,
  usnlm: US_CT_ROWS,
  euctr_ped: EU_CT_PED_ROWS
};

export const AVATARS: any = {
  euctr: EU_CT_AVATARS,
  usnlm: US_CT_AVATARS,
  euctr_ped: EU_CTR_PEDIATRICS_AVATARS
};
