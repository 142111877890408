import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    containerRoot: {
      width: '100%'
    },
    container: {
      width: '100%',
      height: '94%',
      background: theme.palette.white,
      borderRadius: theme.spacing(2),
      padding: theme.spacing(2)
    },
    root: {
      height: '100%',
      margin: '0px !important',
      width: '100%'
    },
    heading: {
      fontWeight: 600,
      color: theme.palette.adcomm.gray
    },
    report: {
      color: theme.palette.adcomm.blue,
      fontWeight: 500,
      cursor: 'pointer'
    },
    graph: {
      height: '25vh'
    },
    add: {
      height: '35px',
      cursor: 'pointer',
      background: 'none',
      boxShadow: 'none',
      '&:hover': {
        background: 'none',
        border: 1,
        color: theme.palette.navbar.button,
        borderStyle: 'solid'
      }
    },
    addText: {
      color: theme.palette.navbar.button,
      fontWeight: 'bold',
      textTransform: 'capitalize',
      '&:hover': {
        background: 'none'
      }
    },
    loaderBox: {
      width: '80%',
      height: '30vh'
      // border:'1px solid red'
    },
    circleBox: {
      width: '100%'
    },
    skeltonCircle: {
      width: '100px',
      height: '100px'
    },
    barGraphRect: {
      width: '100%',
      height: '100%'
    }
  }),
  { index: 1 }
);
