const styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'orange.light',
    borderRadius: 2,
    padding: '40px',
    boxShadow: '0 0 8px #0000004d'
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    mb: 2,
    px: 4
  },
  warningIcon: { alignSelf: 'center', mb: 2, color: 'error.900' },
  message: {
    color: 'error.900',
    fontWeight: 600,
    lineHeight: '18px',
    fontSize: 16
  },
  confirmButtonGroup: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    mt: '40px'
  },
  yesButton: {
    minWidth: 100,
    textTransform: 'none',
    borderRadius: 10,
    fontWeight: 700,
    fontSize: 12,
    bgcolor: 'error.900',
    color: 'white.main',
    '&:hover': {
      boxShadow: '0 0 8px #0000004d',
      bgcolor: 'orange.darkVariant1'
    }
  },
  noButton: {
    minWidth: 100,
    textTransform: 'none',
    color: 'error.900',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'error.900',
    borderRadius: 20,
    fontWeight: 700,
    fontSize: 12,
    bgcolor: 'white.main',
    '&:hover': {
      boxShadow: '0 0 8px #0000004d',
      bgcolor: 'white.main'
    }
  }
};

export default styles;
