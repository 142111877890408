import { useCallback, useContext, useEffect } from 'react';
import Papa from 'papaparse';
import XLSX from 'xlsx';

// api
import lettersStore from '../../../store/Letters';

// store
import actions from '../../../store/Letters/actions';
import globalActions from '../../../store/actions';
import globalStore from '../../../store/index';

import { constructPieChartData } from '../utils/getDonutChartStructure';
import { getAllDocuments, getInsights, postLetters } from '../../../api/pages/Letters';
import getFilters from '../utils/common';
import { EXPORT_TYPE, LETTERS_COLUMN_NAME_MAPPING, PAGINATION_DEFAULT_OPTIONS } from '../const';
import { getFileName } from '../../../helpers/getFileName';
import transformData from '../../../utils/transformData';

import AuthContext from '../../../store/Auth/AuthProvider';
import { getDataGridFilter } from '../../../utils/getDatagridFilterOption';

const useLettersData = () => {
  const { lettersState, lettersDispatch } = useContext(lettersStore);
  const { state, dispatch }: any = useContext(globalStore);
  const { currentUser } = useContext(AuthContext);

  const getLettersTableData = async (payload: any, limit: number, offset: number) => {
    const res = await postLetters(payload, limit, offset);
    if (res?.data?.status === 200) {
      lettersDispatch({
        type: actions.SET_LETTERS_TABLE,
        value: res?.data?.body?.data ?? []
      });

      lettersDispatch({
        type: actions.SET_TOTAL_COUNT,
        value: res?.data?.body?.total_letters_count ?? 0
      });
    }
  };

  const getInsightData = async (filters: any, appliedFilters: any) => {
    const res = await getInsights({ filters });
    if (res.status === 200) {
      const graphs = constructPieChartData(res.data.body.insights.donut_graph_data);
      lettersDispatch({
        type: actions.SET_TREEMAP_CHARTS,
        value: graphs
      });

      lettersDispatch({
        type: actions.SET_DOC_TYPE_TREEMAP,
        value: res.data.body.insights.donut_graph_data.letter_document_type
      });

      lettersDispatch({
        type: actions.SET_STATUS_TREEMAP,
        value: res.data.body.insights.donut_graph_data.fda_issuing_office
      });

      lettersDispatch({
        type: actions.SET_ISSUE_DATE,
        value: res.data.body.insights.bar_graph_data.filter((item: any) => item.count > 0)
      });

      const stats = {
        totalLastThirtyDays: res.data.body.insights.last_30_days_count,
        totalClosedLetters: res.data.body.insights.total_closed_letters,
        totalLettersIssued: res.data.body.insights.total_letters_issued,
        pdfCount: res.data.body.insights.pdf_stats.total_pdf_count,
        pageCount: res.data.body.insights.pdf_stats.total_page_count
      };

      lettersDispatch({
        type: actions.SET_LETTERS_STATS,
        value: stats
      });

      const newfilters = getFilters(res.data.body.filters);

      lettersDispatch({
        type: actions.SET_FILTERS,
        value: newfilters
      });

      lettersDispatch({
        type: actions.SET_AVAILABLE_FILTERS,
        value: appliedFilters
      });

      lettersDispatch({
        type: actions.SET_ASK_RIA_FILTERS,
        value: res?.data?.body?.filters?.entity_id || []
      });
    }
  };

  // Hook to fetch data based on datagrid filter
  const getDatawithDataGridFilter = async (
    appliedFilters: any,
    datagridFilter: any,
    sortFields: any,
    limit: number = PAGINATION_DEFAULT_OPTIONS.limit,
    offset: number = PAGINATION_DEFAULT_OPTIONS.offset
  ) => {
    lettersDispatch({
      type: actions.SET_LOADING,
      value: true
    });

    const source = {
      us: ['fda-letters']
    };

    const res: any = await getDataGridFilter(
      appliedFilters,
      datagridFilter,
      sortFields,
      limit,
      offset,
      lettersState,
      currentUser,
      source
    );

    if (res?.data?.status === 200) {
      lettersDispatch({
        type: actions.SET_LETTERS_TABLE,
        value: res?.data?.body?.result?.['fda-letters']?.results ?? []
      });

      lettersDispatch({
        type: actions.SET_TOTAL_COUNT,
        value: res?.data?.body?.result?.['fda-letters']?.total ?? 0
      });
      lettersDispatch({
        type: actions.SET_DATA_GRID_FILTERS,
        value: datagridFilter
      });
    }
    lettersDispatch({
      type: actions.SET_LOADING,
      value: false
    });
  };

  const getLettersData = useCallback(
    async (
      appliedFilters: any,
      limit: number = PAGINATION_DEFAULT_OPTIONS.limit,
      offset: number = PAGINATION_DEFAULT_OPTIONS.offset,
      sort: Array<any> = [],
      shouldGetInsight: boolean = true,
      dataGridFilter: any = {}
    ) => {
      lettersDispatch({
        type: actions.SET_LOADING,
        value: true
      });

      const payload: any = {};
      payload.filters = { ...appliedFilters };
      if (sort) {
        payload.sort = sort;
      }

      lettersDispatch({
        type: actions.SET_IS_FILTERS_APPLIED,
        value: Object.keys(appliedFilters).length !== 0
      });

      if (Object.keys(dataGridFilter).length !== 0) {
        await getDatawithDataGridFilter(appliedFilters, dataGridFilter, sort, limit, offset);
      } else {
        try {
          await getLettersTableData(payload, limit, offset);
          if (shouldGetInsight) {
            await getInsightData(payload?.filters, appliedFilters);
          }
          lettersDispatch({
            type: actions.SET_DATA_GRID_FILTERS,
            value: {}
          });
          lettersDispatch({
            type: actions.SET_LOADING,
            value: false
          });
        } catch (err) {
          lettersDispatch({
            type: actions.SET_LOADING,
            value: false
          });
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }
    },
    [lettersState.dataGridFilters]
  );

  const handleCSVExport = async () => {
    lettersDispatch({
      type: actions.SET_LOADING,
      value: true
    });
    const res = await getAllDocuments({ filters: lettersState.availableFilters });
    const selectedColumns = state?.exportType?.isAllColumnsSelected
      ? LETTERS_COLUMN_NAME_MAPPING
      : Object.fromEntries(
          Object.entries(LETTERS_COLUMN_NAME_MAPPING).filter(([key]) =>
            state?.exportType?.selectedColumns?.includes(key)
          )
        );
    const formattedData = transformData(res?.data?.body?.data, selectedColumns);
    const csv = Papa.unparse(formattedData);
    // Create a Blob from the CSV string
    const blob = new Blob([csv], { type: 'text/csv' });
    // Create a download link and click it
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    const day = new Date();
    const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
    const fileName = getFileName({
      prefix: 'ria',
      module: 'fda_letters',
      postfix: date,
      additional: '',
      extension: 'csv'
    });
    a.download = `${fileName}.csv`;
    a.click();
    dispatch({
      type: globalActions.SET_EXPORT_TYPE,
      value: { format: '', selectedColumns: [], isAllColumnsSelected: false }
    });
    lettersDispatch({
      type: actions.SET_LOADING,
      value: false
    });
  };

  const handleExcelExport = async () => {
    lettersDispatch({
      type: actions.SET_LOADING,
      value: true
    });
    const res = await getAllDocuments({ filters: lettersState.availableFilters });
    const selectedColumns = state?.exportType?.isAllColumnsSelected
      ? LETTERS_COLUMN_NAME_MAPPING
      : Object.fromEntries(
          Object.entries(LETTERS_COLUMN_NAME_MAPPING).filter(([key]) =>
            state?.exportType?.selectedColumns?.includes(key)
          )
        );
    const formattedData = transformData(res?.data?.body?.data, selectedColumns);
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const day = new Date();
    const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
    const fileName = getFileName({
      prefix: 'ria',
      module: 'fda_letters',
      postfix: date,
      additional: '',
      extension: 'xlsx'
    });
    XLSX.writeFile(wb, `${fileName}.xlsx`);
    dispatch({
      type: globalActions.SET_EXPORT_TYPE,
      value: { format: '', selectedColumns: [], isAllColumnsSelected: false }
    });
    lettersDispatch({
      type: actions.SET_LOADING,
      value: false
    });
  };

  useEffect(() => {
    if (
      state?.exportType?.format &&
      [EXPORT_TYPE.csv, EXPORT_TYPE.xlsx].includes(state?.exportType?.format)
    ) {
      if (state?.exportType?.format === EXPORT_TYPE.csv) {
        handleCSVExport();
      } else if (state?.exportType?.format === EXPORT_TYPE.xlsx) {
        handleExcelExport();
      }
    }
  }, [state?.exportType]);

  return {
    isLoading: lettersState.loading,
    lettersfilters: lettersState.filters,
    lettersTableData: lettersState.tableData,
    isFilterApplied: lettersState.isFiltersApplied,
    getLettersData,
    sortFields: lettersState.sortFields,
    getDatawithDataGridFilter,
    dataGridFilter: lettersState.dataGridFilters
  };
};

export default useLettersData;
