import { Avatar, Box, Button, Tooltip, Typography } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router';
import AuthContext from '../../../../store/Auth/AuthProvider';
import styles from '../../styles/LandingPage.styles';
import { stringAvatar } from '../../../../helpers/utils';

const UserAvatar = () => {
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);

  const disablePasswordChange = useMemo(() => {
    return currentUser && 'identities' in currentUser;
  }, [currentUser]);

  const userInitials = useMemo(() => stringAvatar(currentUser?.['custom:user']), [currentUser]);

  return (
    <Box display='flex' sx={{ alignItems: 'center' }}>
      <Box sx={{ marginRight: '1em' }}>
        <Avatar sx={styles.avatar}>{userInitials}</Avatar>
      </Box>
      <Box display='flex' flexDirection='column' justifyContent='center' overflow='hidden'>
        <Typography data-dd-privacy='mask' sx={styles.userName}>
          {currentUser?.['custom:user']}
        </Typography>
        <Typography data-dd-privacy='mask' sx={styles.userEmail}>
          {currentUser?.email}
        </Typography>
        <Tooltip title={disablePasswordChange && 'SSO users cannot change passwords'}>
          <Box>
            <Button
              disabled={disablePasswordChange}
              variant='text'
              sx={styles.changePasswordButton}
              onClick={() => history.push('/reset')}>
              Change password
            </Button>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default React.memo(UserAvatar);
