const styles = {
  btn: {
    marginTop: 1,
    padding: 2,
    width: 10,
    height: 4,
    fontSize: 14,
    fontFamily: 'Mulish',
    textTransform: 'capitalize',
    color: 'gray.darkVariant',
    backgroundColor: 'gray.background',
    borderRadius: 2.5
  }
};

export default styles;
