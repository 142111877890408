import React, { useContext } from 'react';
import { isEmpty } from 'lodash';
import ClinicalTrialsHeader from './ClinicalTrialsHeader';
import ClinicalTrialsTable from './ClinicalTrialsTable';
import useClinicalTrials from '../hooks/useClinicalTrials';
import ResultsStore from '../../../store/SearchResults';
import Loading from './Loading';

import EmptyResult from './EmptyResult';
import { EMPTY_RESULTS_MESSAGES } from '../constants';
import useEntities from '../hooks/useEntities';
import useUpdateModule from '../../../hooks/useUpdateModule';
import getCtSource from '../utils/getCtSource';

const ClinicalTrials = ({ searchTerm = '' }: any) => {
  const { resultsState } = useContext(ResultsStore);
  useUpdateModule('clinicalTrials');
  const { applications, sortBy, sortOptions, sources } = useClinicalTrials();
  const {
    searchType,
    selectedSources,
    handleSourceChange,
    entityCategory,
    entityCategoryOptions,
    applyCategoryEntity,
    entityValue,
    entityTextOptions,
    applyEntityText,
    makeSearch
  } = useEntities();

  if (resultsState.isContentLoading) {
    return <Loading />;
  }

  if (isEmpty(resultsState?.ctResults) && isEmpty(resultsState?.ctDataGridFilters)) {
    return (
      <EmptyResult
        firstMessage={
          Object.keys(resultsState.filters)?.length > 0
            ? EMPTY_RESULTS_MESSAGES.filterEmptyMessage.firstMessage
            : EMPTY_RESULTS_MESSAGES.GeneralSearchEmptyMessage.firstMessage
        }
        secondMessage={
          Object.keys(resultsState.filters)?.length > 0
            ? EMPTY_RESULTS_MESSAGES.filterEmptyMessage.secondMessage
            : EMPTY_RESULTS_MESSAGES.GeneralSearchEmptyMessage.secondMessage
        }
      />
    );
  }

  const ctSource = getCtSource(sources);

  return (
    <div>
      <ClinicalTrialsHeader
        sortOptions={sortOptions}
        onSortOptionChange={sortBy}
        searchType={searchType}
        selectedSources={selectedSources}
        handleSourceChange={handleSourceChange}
        entityCategory={entityCategory}
        entityCategoryOptions={entityCategoryOptions}
        applyCategoryEntity={applyCategoryEntity}
        entityValue={entityValue}
        entityTextOptions={entityTextOptions}
        applyEntityText={applyEntityText}
        makeSearch={makeSearch}
        totalCategory={applications?.length}
      />

      <ClinicalTrialsTable applications={applications} searchTerm={searchTerm} source={ctSource} />
    </div>
  );
};

export default React.memo(ClinicalTrials);
