export const userInfoOnClick = (event, setIsUserInfoOpen, setUserInfoAnchorEl) => {
  setIsUserInfoOpen(true);
  setUserInfoAnchorEl(event.currentTarget);
};

export const userInfoOnClose = (setIsUserInfoOpen, setUserInfoAnchorEl) => {
  setIsUserInfoOpen(false);
  setUserInfoAnchorEl(null);
};

export const downloadOnClick = setIsDownloadOpen => {
  setIsDownloadOpen(true);
};

export const downloadOnClose = (setIsDownloadOpen, dispatch, Actions) => {
  dispatch({ type: Actions.SET_PDF_MESSAGE, value: 'Nothing has been added to the report' });
  setIsDownloadOpen(false);
};
