import makeRequest, { makeArrayBufferRequest } from '../client';

/**
 * Function to get aria query results
 * @param {string} module name of the module
 * @param {string} categoryBucket bucket name
 * @param {string} source name of the source
 * @param {object} payload contains the request body
 * @param {number} page page number for the kendra query
 * @returns
 */
export const getAriaQueryResults = (module, categoryBucket, source, payload, page = 1) =>
  makeRequest(
    `/aria/base_query/${module}/${categoryBucket}/${source}?page=${page}`,
    'POST',
    payload
  );

/**
 * Function to get most popular query suggestions
 * @param {string} source data source for documents
 * @param {string} searchText text that is being typed by user on search field
 */
export const getAriaQuerySuggestions = (source, searchText) =>
  makeRequest(`/aria/suggestions/${source}/${searchText}`, 'GET');

/**
 * Function to submit feedback for an aria result to improve suggestions
 * @param {string} source data source for documents
 * @param {object} payload request body which contains query_id, result_id and user_reaction
 */
export const submitAriaFeedback = (source, payload) =>
  makeRequest(`/aria/feedback/${source}`, 'POST', payload);

/**
 * Function to highlight the PDF <b></b> tags.
 * @param {string} source data source for documents
 * @param {object} payload request body which contains query_id, result_id and user_reaction
 */
export const highlightPDF = payload => makeArrayBufferRequest('/pdf/highlight', 'POST', payload);

export const getSectionPageNumbers = payload =>
  makeRequest(`/comparison/labels/page_numbers`, 'POST', payload);

export const getAriaQueryById = searchId =>
  makeRequest(`/user-profile/aria_search/${searchId}`, 'GET');
