import { memo, useContext, useEffect, useState } from 'react';
import { Box, Grid, Skeleton } from '@mui/material';
import { isEmpty } from 'lodash';
import { getSubscribedNotifications } from '../../../api/modules/userNotification';
import NotificationsStore from '../../../store/Notifications';
import NotificationsActions from '../../../store/Notifications/actions';
import Applications from '../../UserProfile/components/SavedItemsAndProjects/Applications';
import savedItemStyles from '../../UserProfile/styles/SavedItems.styles';
import applicationTabStyles from '../../UserProfile/styles/ApplicationsTab.styles';
import { identifierKey } from '../../SearchResults/constants';

const ApplicationsSubscription = () => {
  const { notificationsState, notificationsDispatch } = useContext(NotificationsStore);
  const [loading, setLoading] = useState(isEmpty(notificationsState.subscribedApplications));

  const getSubscribedNotificationData = async (type: string) => {
    try {
      setLoading(true);
      const response = await getSubscribedNotifications(type);
      if (response.data.body) {
        notificationsDispatch({
          type: NotificationsActions.SET_SUBSCRIBED_APPLICATIONS,
          value: response.data.body
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching applications', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFavorite = ({
    source,
    identifier,
    isFavorite
  }: {
    source: string;
    identifier: string;
    isFavorite: boolean;
  }) => {
    const newApplications = [...notificationsState.subscribedApplications];
    const selectedApplicationIndex = newApplications?.findIndex((x: any) => {
      const appIdentifierKey = identifierKey[source];
      const appIdentifier = x?.[appIdentifierKey] ?? x?.application_number;
      return x.source === source && appIdentifier === identifier;
    });
    if (selectedApplicationIndex === -1) return;
    newApplications[selectedApplicationIndex].isFavorite = isFavorite;
    notificationsDispatch({
      type: NotificationsActions.SET_SUBSCRIBED_APPLICATIONS,
      value: newApplications
    });
  };

  const handleProject = ({
    source,
    identifier,
    project
  }: {
    source: string;
    identifier: string;
    project: any;
  }) => {
    const newApplications = [...notificationsState.subscribedApplications];
    const selectedApplicationIndex = newApplications?.findIndex((x: any) => {
      const appIdentifierKey = identifierKey[source];
      const appIdentifier = x?.[appIdentifierKey] ?? x?.application_number;
      return x.source === source && appIdentifier === identifier;
    });
    if (selectedApplicationIndex === -1) return;
    const projects = newApplications[selectedApplicationIndex].inProjects;
    newApplications[selectedApplicationIndex].inProjects = project.inProject
      ? projects.filter(
          (p: { project_id: any; id: any }) =>
            (p.project_id ?? p.id) !== (project.project_id ?? project.id)
        )
      : [...projects, { id: project.project_id ?? project.id, name: project.name }];

    notificationsDispatch({
      type: NotificationsActions.SET_SUBSCRIBED_APPLICATIONS,
      value: newApplications
    });
  };

  const handleSubscription = ({
    source,
    identifier,
    isSubscribed
  }: {
    source: string;
    identifier: string;
    isSubscribed: boolean;
  }) => {
    if (isSubscribed) return;
    const newApplications = [...notificationsState.subscribedApplications];
    const selectedApplicationIndex = newApplications?.findIndex((x: any) => {
      const appIdentifierKey = identifierKey[source];
      const appIdentifier = x?.[appIdentifierKey] ?? x?.application_number;
      return x.source === source && appIdentifier === identifier;
    });
    if (selectedApplicationIndex === -1) return;
    newApplications.splice(selectedApplicationIndex, 1);
    notificationsDispatch({
      type: NotificationsActions.SET_SUBSCRIBED_APPLICATIONS,
      value: newApplications
    });
  };

  useEffect(() => {
    if (isEmpty(notificationsState.subscribedApplications)) {
      getSubscribedNotificationData('application');
    }
  }, []);

  if (loading) {
    return (
      <Box sx={savedItemStyles.loadingBox}>
        <Grid container>
          {Array.from({ length: 2 }).map((_, index) => (
            <Grid
              // eslint-disable-next-line react/no-array-index-key
              key={`application_loading_${index}`}
              item
              xs={12}
              md={6}
              sx={applicationTabStyles.applicationsGrid}>
              <Skeleton
                variant='rectangular'
                width={266}
                height={142}
                sx={savedItemStyles.applicationSkeletion}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }
  return (
    <Applications
      applications={notificationsState.subscribedApplications}
      handleFavoriteHook={handleFavorite}
      handleProjectHook={handleProject}
      handleSubscriptionHook={handleSubscription}
      gridSizes={{ xs: 12, md: 6, lg: 6, xl: 6 }}
    />
  );
};
export default memo(ApplicationsSubscription);
