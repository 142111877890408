import { useContext, useEffect } from 'react';
import Store from '../store';
import Actions from '../store/actions';

const useUpdateModule = (currentModule: string) => {
  const { dispatch, state } = useContext<any>(Store);
  useEffect(() => {
    dispatch({ type: Actions.SET_MODULE, value: currentModule });
  }, [state.module]);
};

export default useUpdateModule;
