// material ui v5
import CssBaseline from '@mui/material/CssBaseline';

import { ThemeProvider } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Reg360Tabs from './Reg360Tabs/Reg360Tabs';

// import styles from './index.styles';
import themev5 from '../../themev5';
import Header from './Header/Header';
// context provider
import { RegulatoryInformationStateProvider } from '../../store/RegulatoryInformation';
import useUpdateModule from '../../hooks/useUpdateModule';
// Need to make it take 100vh
const RegulatoryInformation = () => {
  useUpdateModule('reg360');

  return (
    <Box>
      <CssBaseline />
      <RegulatoryInformationStateProvider>
        <Stack direction='row'>
          {/* reg360 content */}
          <ThemeProvider theme={themev5}>
            <Stack sx={{ width: '100%', height: 'calc(100vh - 85px)' }}>
              <Header />
              <Reg360Tabs />
            </Stack>
          </ThemeProvider>
        </Stack>
      </RegulatoryInformationStateProvider>
    </Box>
  );
};

export default RegulatoryInformation;
