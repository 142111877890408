/**
 *
 * @param {Array} TABLE_COLUMNS
 * @param {Array.<{field: string, cellRenderer: requestCallback}>} renderers
 * @returns
 */
const getTableColumnWithCellRenderers = (TABLE_COLUMNS, renderers) => {
  return TABLE_COLUMNS.map(column => {
    const renderer = renderers.find(rndrr => rndrr.field === column.field);
    if (renderer) {
      return {
        ...column,
        renderCell: rowData => renderer.cellRenderer({ value: rowData.value, rowData: rowData.row })
      };
    }
    return {
      ...column
    };
  });
};

export default getTableColumnWithCellRenderers;
