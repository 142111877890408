const searchSuggestionStyles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '884px',
    margin: 'auto',
    mt: '54px'
  },
  searchIconWrapper: {
    width: '64px',
    height: '64px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: 'gray.background',
    borderRadius: '50%',
    mb: '24px'
  },
  searchIcon: { height: '22px', width: '22px', color: 'gray.950' },
  noMatchedText: {
    color: 'gray.950',
    fontFamily: 'Mulish',
    fontWeight: 700,
    fontSize: '18px',
    mb: '60px'
  },
  searchQueryText: {
    color: 'secondary.950',
    fontFamily: 'Mulish',
    fontWeight: 700,
    fontSize: '15px',
    textAlign: 'center'
  },
  suggestionText: {
    color: 'gray.950',
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: '14px',
    textAlign: 'center',
    mb: '10px'
  },
  gridContainer: { marginTop: '24px', justifyContent: 'center', columnGap: '24px', rowGap: '24px' },
  gridItem: { cursor: 'pointer' },
  suggestionContainer: {
    padding: '12px 16px',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'primary.450',
    background: 'white',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    gap: '12px'
  },
  titleContainer: {
    color: 'gray.950',
    fontFamily: 'Mulish',
    fontWeight: 700,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    gap: '2px'
  },
  count: {
    color: 'gray.950',
    fontFamily: 'Mulish',
    fontWeight: 700,
    fontSize: '14px'
  },
  multipleSourceTextContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    mt: '4px'
  },
  multipleSourceText: {
    color: 'gray.950',
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: 1
  },
  tooltipInfoIcon: { height: '14px', width: '14px', color: 'primary.450' },
  suggestionsMainContainer: {
    padding: '20px 40px 32px 40px',
    borderRadius: '8px',
    backgroundColor: 'primary.background'
  },
  rightArrowIcon: { height: '16px', width: '16px', color: 'gray.650' }
};

export default searchSuggestionStyles;
