const styles = {
  drawerContainer: {
    '& >.MuiDrawer-paper': {
      height: '60vh',
      borderRadius: '16px',
      paddingX: '24px',
      paddingY: '32px',
      position: 'absolute'
    }
  },
  drawerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch'
  },
  drawerTitle: {
    flexGrow: 1,
    textAlign: 'center',
    alignSelf: 'center',
    fontWeight: '700',
    fontSize: '20px',
    color: 'gray.800',
    textTransform: 'capitalize'
  },
  drawerDivider: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'gray.400',
    borderRadius: '2px',
    mt: 1,
    width: '64px'
  },
  drawerCloseIcon: {
    display: 'flex',
    height: '56px',
    marginTop: '6px'
  },
  select: {
    maxWidth: 400,
    fontSize: '14px',
    fontWeight: 700,
    p: 0,
    border: 'none',
    '& .MuiSelect-icon': { fontSize: 16, color: 'gray.700' },
    fieldset: {
      p: 0,
      border: 'none'
    }
  },
  searchBar: {
    borderRadius: '8px',
    background: '#fff',
    width: '795px'
  },
  divider: {
    m: '12px 16px',
    backgroundColor: 'gray.200'
  }
};

export default styles;
