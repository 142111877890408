import React, { lazy, memo, Suspense, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { isEqual } from 'lodash';
import SuspenseCircularLoader from '../../helpers/SuspenseCircularLoader';
import { decodeBase64ToObject } from '../../utils/encodeDecodeObject';
import { highlightPDF } from '../../api/pages/Aria';
import generateHighlightPayload from './utils';

const PDFViewerInternal = lazy(() => import('./pdfViewer'));

const PDFViewer = ({
  fileUrl,
  initialPage,
  defaultScale,
  onPageChange,
  plugins,
  phraseToHighlight = '',
  keywordsToHighlight = []
}) => {
  const [loading, setLoading] = useState(true);
  const [cleanedFileUrl, setCleanedFileUrl] = useState(null);
  const prevValues = useRef({ fileUrl: null, phraseToHighlight: '', keywordsToHighlight: [] });

  useEffect(() => {
    const fetchData = async () => {
      if (
        fileUrl &&
        (!isEqual(fileUrl, prevValues.current.fileUrl) ||
          !isEqual(phraseToHighlight, prevValues.current.phraseToHighlight) ||
          !isEqual(keywordsToHighlight, prevValues.current.keywordsToHighlight))
      ) {
        const cleanedUrl = fileUrl.replace(/#page=\d+/, '');
        const prevCleanedUrl = prevValues.current?.fileUrl?.replace(/#page=\d+/, '');
        if (cleanedUrl === prevCleanedUrl && !phraseToHighlight && !keywordsToHighlight.length) {
          return;
        }
        setLoading(true);
        // Update previous values
        prevValues.current = { fileUrl, phraseToHighlight, keywordsToHighlight };
        const splitObject = window.location.pathname.split('/');
        const payload = splitObject[splitObject.length - 1];
        const searchPayload = decodeBase64ToObject(payload);
        const highlightedColor = searchPayload?.highlightedColor || [];
        const highlightPayload = generateHighlightPayload(
          fileUrl,
          keywordsToHighlight,
          phraseToHighlight,
          highlightedColor
        );
        const res = await highlightPDF(highlightPayload);
        setCleanedFileUrl(
          res.status === 200
            ? URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
            : cleanedUrl
        );

        setLoading(false);
      }
    };
    fetchData();
  }, [fileUrl, phraseToHighlight, keywordsToHighlight]);
  if (loading) {
    return (
      <Stack
        height='70vh'
        alignItems='center'
        justifyContent='center'
        sx={{
          bgcolor: 'white !important'
        }}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Suspense fallback={<SuspenseCircularLoader />}>
      <PDFViewerInternal
        fileUrl={cleanedFileUrl}
        initialPage={initialPage}
        defaultScale={defaultScale}
        onPageChange={onPageChange}
        plugins={plugins}
      />
    </Suspense>
  );
};

PDFViewer.propTypes = {
  fileUrl: PropTypes.string.isRequired,
  initialPage: PropTypes.number,
  defaultScale: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onPageChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  plugins: PropTypes.arrayOf(PropTypes.any),
  phraseToHighlight: PropTypes.string,
  keywordsToHighlight: PropTypes.arrayOf(PropTypes.string)
};

PDFViewer.defaultProps = {
  initialPage: 0,
  defaultScale: 1.35,
  onPageChange: () => {},
  plugins: [],
  phraseToHighlight: null,
  keywordsToHighlight: []
};

export default memo(PDFViewer);
