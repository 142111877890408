const styles = {
  button: {
    textTransform: 'none',
    color: 'primary.700',
    fontSize: 13,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '160%',
    p: 0,
    ml: 0.5,
    '& .MuiButton-endIcon': {
      mx: 0
    }
  }
};

export default styles;
