import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import themev5 from '../../../themev5';

const Tags = styled(Paper)(({ suggested = false }) => ({
  padding: 5,
  width: 122,
  height: 29,
  fontSize: themev5.typography.subtitle2,
  fontFamily: 'Nunito Sans',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 'bold',
  backgroundColor: suggested ? '#FFFFFF' : '#F1F2F8',
  borderRadius: 10,
  textAlign: 'center',
  color: themev5.palette.gray.main
}));

export default Tags;
