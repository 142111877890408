/* eslint-disable react/require-default-props */
import React, { memo } from 'react';

import { Stack, Tooltip } from '@mui/material';

import AriaCard from '../../../components/ui/cards/AriaCard';

import { prepareDocumentCard } from '../utils/documentResultsUtils';

import { DOCUMENT_DATA_SOURCE_CHIP_OPTIONS } from '../constants';
import {
  getCderOrCdrhChip,
  getFlagIconWithTooltip,
  getScoreConfidence,
  getScoreConfidenceTooltip
} from '../utils/documentViewUtils';

const Item = ({
  context: {
    handleShareClick,
    handleFavoriteClick,
    handleProjectClick,
    resultsState,
    handleResultClick,
    handleAriaResponseClick,
    getAriaResponse
  },
  data,
  index
}: any) => {
  const ScoreConfidence = getScoreConfidence(data);
  const scoreConfidenceTooltipTitle = getScoreConfidenceTooltip(data);
  const flag = getFlagIconWithTooltip(data);
  const modifiedData = {
    ...data,
    scrollIndex: index
  };
  return (
    <Stack my={2}>
      <Stack id={`child-${index}`}>
        <AriaCard
          key={modifiedData.result_id}
          handleShareClick={handleShareClick}
          handleFavoriteClick={handleFavoriteClick}
          handleProjectClick={handleProjectClick}
          searchId={resultsState?.searchIds?.documentSearchId || ''}
          documentCardDetails={prepareDocumentCard(modifiedData)}
          isSelected={resultsState.selectedDocument?.resultId === data.result_id}
          isPdfViewOpen={resultsState.selectedDocument !== null}
          handleResultClick={handleResultClick}
          handleAriaResponseClick={handleAriaResponseClick}
          ariaResponse={getAriaResponse()}
          ariaResponseLoading={resultsState.ariaResponseLoading}
          flag={flag}
          scoreConfidence={
            <Tooltip title={scoreConfidenceTooltipTitle}>
              <ScoreConfidence sx={{ fontSize: 14 }} />
            </Tooltip>
          }
          documentDataSourceChipOptions={
            modifiedData['data-source']
              ? DOCUMENT_DATA_SOURCE_CHIP_OPTIONS[modifiedData['data-source']]
              : {}
          }
          cderOrCdrhChip={getCderOrCdrhChip(modifiedData)}
        />
      </Stack>
    </Stack>
  );
};

export default memo(Item);
