import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import SuspenseCircularLoader from '../../../helpers/SuspenseCircularLoader';

import ArcLinkLabel from './CustomArcLinkLabel';

// style
import CustomPieChartStyles from './CustomPieChart.styles';

const ResponsivePie = lazy(() =>
  import('@nivo/pie').then(module => ({ default: module.ResponsivePie }))
);

const CustomPieChart = ({
  data,
  margin,
  innerRadius,
  colors,
  fillText,
  radialLabelsTextColor,
  sliceLabelsTextColor,
  legends = null,
  enableArcLinkLabels = true,
  arcLabelsSkipAngle = 10,
  arcLinkLabelsSkipAngle = 10,
  labelCharacterLimit = 20,
  startAngle = 0
}) => {
  const classes = CustomPieChartStyles();
  const titleCase = st => {
    return st?.split(' ')?.reduce((s, c) => `${s}${c.charAt(0).toUpperCase() + c.slice(1)} `, '');
  };

  data.forEach(ele => {
    // eslint-disable-next-line no-param-reassign
    ele.id = titleCase(ele.id || ele.key);
  });

  return (
    <Suspense fallback={<SuspenseCircularLoader />}>
      <ResponsivePie
        className={classes.root}
        data={data}
        valueFormat=' >-d'
        margin={margin || { top: 40, right: 40, bottom: 20, left: 30 }}
        innerRadius={innerRadius || 0.2}
        padAngle={1}
        cornerRadius={0}
        startAngle={startAngle}
        colors={colors || ['#48E0A6', '#0A467C', '#008B8B', '#318AC8']}
        sliceLabel={e => {
          return `${e.id || e.key}(${e.value})`;
        }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        radialLabelsLinkOffset={-10}
        radialLabelsLinkDiagonalLength={20}
        radialLabelsLinkHorizontalLength={10}
        arcLinkLabelsSkipAngle={arcLinkLabelsSkipAngle || 10}
        arcLabelsSkipAngle={arcLabelsSkipAngle || 10}
        radialLabelsSkipAngle={10}
        radialLabelsTextColor={radialLabelsTextColor || '#1FBDCA'}
        radialLabelsLinkColor={{ from: 'color' }}
        sliceLabelsSkipAngle={10}
        arcLinkLabelsColor={['#BDBDBD']}
        arcLabelsTextColor={['#fff']}
        sliceLabelsTextColor={sliceLabelsTextColor || '#fff'}
        // eslint-disable-next-line react/no-unstable-nested-components
        arcLinkLabelComponent={d => (
          <ArcLinkLabel datum={d} labelCharacterLimit={labelCharacterLimit} />
        )}
        theme={{
          axis: {
            fontSize: 14,
            tickColor: '#000',
            ticks: {
              line: {
                stroke: '#555555'
              },
              text: {
                fill: fillText || '#1FBDCA',
                fontWeight: 'bold'
              }
            }
          }
        }}
        enableArcLinkLabels={enableArcLinkLabels}
        legends={legends || []}
      />
    </Suspense>
  );
};

CustomPieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ),
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  legends: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  colors: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  innerRadius: PropTypes.number,
  fillText: PropTypes.string,
  radialLabelsTextColor: PropTypes.string,
  sliceLabelsTextColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  arcLabelsSkipAngle: PropTypes.number,
  arcLinkLabelsSkipAngle: PropTypes.number,
  labelCharacterLimit: PropTypes.number,
  startAngle: PropTypes.number,
  enableArcLinkLabels: PropTypes.bool
};

CustomPieChart.defaultProps = {
  data: [],
  margin: null,
  colors: ['#0E5A94', '#318AC8', '#008B8B', '#3AB09E', '#48E0A6', '#1C2D59', '#20A1C7'],
  innerRadius: 0.2,
  legends: null,
  fillText: '#1FBDCA',
  radialLabelsTextColor: '#1FBDCA',
  sliceLabelsTextColor: '#1FBDCA',
  enableArcLinkLabels: true,
  arcLabelsSkipAngle: 10,
  arcLinkLabelsSkipAngle: 10,
  labelCharacterLimit: 20,
  startAngle: 0
};

export default React.memo(CustomPieChart);
