// mui
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';

import Styles from '../../PMRImpact/styles/Insights.styles';
import CustomBarGraph from '../../CustomComponents/Graphs/CustomBarGraph';

const PMRImpactReport = ({ data }) => {
  return (
    <Stack>
      <Grid item xs={12} container>
        <Grid item xs={12} container style={{ paddingLeft: '20px' }}>
          <Grid item xs={12} container>
            <Typography sx={Styles.graphTitle}>
              Number of PMR/PMC and Number of days from issue to fulfillment or release
            </Typography>
          </Grid>
          <Grid item container sx={Styles.graphContainer}>
            {data.categoryGraph?.length > 0 ? (
              <CustomBarGraph
                keys={['days', 'number']}
                index='x'
                data={data.categoryGraph}
                borderRadius={2}
                colors='#B1BBE4'
                groupMode='grouped'
                paddings={0.2}
                gridXValues={5}
                defs={[
                  {
                    id: 'dots',
                    type: 'linearGradient',
                    colors: [{ color: '#B1BBE4' }]
                  },
                  {
                    id: 'lines',
                    type: 'linearGradient',
                    colors: [{ color: '#838691' }],
                    spacing: 10
                  }
                ]}
                fill={[
                  {
                    match: {
                      id: 'days'
                    },
                    id: 'dots'
                  },
                  {
                    match: {
                      id: 'number'
                    },
                    id: 'lines'
                  }
                ]}
                tickRotationValue={25}
                enableLabel
                labelTextColor='#ffffff'
                axisText='#A3A3A3'
              />
            ) : (
              <Box display='flex' alignItems='center' ml='50%'>
                <Typography> No Data </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} container style={{ paddingLeft: '20px' }}>
            <Grid item xs={6} container>
              <Grid item xs={6} container style={{ height: '7vh' }}>
                <Typography sx={Styles.graphTitle}>
                  Number of PMR/PMC issued by year (and fulfilled or released)
                </Typography>
              </Grid>
              <Grid item container sx={Styles.graphContainer}>
                {data.issueYearGraph?.length > 0 ? (
                  <CustomBarGraph
                    data={data.issueYearGraph}
                    keys={['y']}
                    index='x'
                    indexBy='x'
                    borderRadius={2}
                    paddings={0.4}
                    innerPadding={0.1}
                    gridXValues={5}
                    colors='#B1BBE4'
                    tickRotationValue={90}
                    enableLabel
                    labelTextColor='#ffffff'
                    axisText='#A3A3A3'
                  />
                ) : (
                  <Box display='flex' alignItems='center' ml='50%'>
                    <Typography>No Data</Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
            <Grid item xs={6} container>
              <Grid item xs={6} container style={{ height: '7vh' }}>
                <Typography sx={Styles.graphTitle}>
                  Number of PMR/PMC fulfilled or released by year
                </Typography>
              </Grid>
              <Grid item container sx={Styles.graphContainer}>
                {data.fulfillmentGraph?.length > 0 ? (
                  <CustomBarGraph
                    data={data.fulfillmentGraph}
                    keys={['y']}
                    index='x'
                    indexBy='x'
                    colors='#B1BBE4'
                    borderRadius={2}
                    paddings={0.4}
                    innerPadding={0.1}
                    gridXValues={5}
                    tickRotationValue={90}
                    enableLabel
                    labelTextColor='#ffffff'
                    axisText='#A3A3A3'
                  />
                ) : (
                  <Box display='flex' alignItems='center' ml='50%'>
                    <Typography>No Data</Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default React.memo(PMRImpactReport);
