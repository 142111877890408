const styles = {
  root: {
    // height: '100vh'
  },
  heading: {
    color: '#313246',
    textTransform: 'capitalize',
    fontWeight: 'bold'
    // fontSize: 36
  },
  back: {
    cursor: 'pointer'
  },
  navigationText: {
    color: 'black.light'
  },
  paper: {
    p: 2,
    height: 200,
    width: 200,
    margin: 'auto',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white.main',
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.1)',
      cursor: 'pointer'
    }
  },
  paperSelected: {
    p: 2,
    height: 200,
    width: 200,
    margin: 'auto',
    borderRadius: 8,
    border: '1px solid #33B187',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white.main',
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.1)',
      cursor: 'pointer'
    }
  },
  icon: {
    alignSelf: 'center',
    color: 'primary.main',
    fontSize: 36
  },
  cardTitle: {
    mt: 2,
    color: '#313246',
    fontWeight: 'bold'
  },
  cardDescription: {
    color: 'gray.main',
    fontWeight: '500'
  },
  headerTab: {
    borderRadius: 3,
    textTransform: 'capitalize',
    width: 160,
    height: 60
  }
};

export default styles;
