const timelineDotMixin = {
  width: '55px',
  height: '55px',
  alignItems: 'center'
};

const styles: { [key: string]: any } = {
  header: {
    color: 'primary.400',
    fontSize: '16px',
    fontWeight: 600
    // lineHeight: '1.5'
  },
  timeline: {
    margin: 'unset',
    padding: 'unset'
  },
  timelineItem: {
    minHeight: '100px'
  },
  timelineDotGreen: {
    ...timelineDotMixin,
    bgcolor: 'white.main',
    color: 'primary.400',
    borderColor: 'primary.400'
  },
  timelineDotDarkGreen: {
    ...timelineDotMixin,
    bgcolor: 'white.main',
    color: 'primary.700',
    borderColor: 'primary.700'
  },
  dotlabel: {
    margin: '0 auto',
    // fontSize: '16px',
    fontWeight: 600
  },
  timelineTab: {
    padding: '0px 10px',
    paddingRight: '10px',
    color: 'gray.darkStorm',
    fontSize: '16px',
    fontWeight: 600,
    minHeight: '100px',
    marginBottom: '10px'
  },
  timelineStackItem: {
    bgcolor: 'white.main',
    margin: '10px',
    height: '100px'
  },
  csvLink: {
    textDecoration: 'none'
  },
  reportText: {
    fontSize: '16px',
    marginRight: '16px',
    cursor: 'pointer',
    color: 'primary.400',
    display: 'flex',
    padding: '0px 0px',
    paddingRight: '10px'
  },
  download: {
    marginRight: '8px'
  }
};

export default styles;
