import Papa from 'papaparse';
import { getFileName } from '../../../helpers/getFileName';
import getFormatDataForCSV from '../../ResultsPage/utils/getFormatDataForCSV';

export const COLUMN_MAPPING = {
  identifier: 'Application Number',
  source: 'source',
  labels: 'Type',
  product_name: 'Product Name',
  generic_name: 'Generic Name',
  matched_submissions: 'Indications First Appeared',
  sponsor_name: 'Sponsor Name',
  approval_year: 'Approval Year',
  formulation: 'Formulation',
  route: 'Route'
};

export const IGNORE_FIELDS = [
  'indications_and_usage_text',
  'submission',
  's3_url',
  's3_bucket',
  's3_path'
];

// Function to transform and rename the keys using COLUMN_NAME_MAPPING
const transformData = (data: any, columnMapping: any) => {
  const allowedColumns = Object.keys(columnMapping);
  return data?.map((each: any) =>
    allowedColumns.reduce((acc: any, key: any) => {
      if (each[key] !== undefined) {
        if (Array.isArray(each[key])) {
          if (typeof each[key][0] === 'object' && !Array.isArray(each[key][0])) {
            acc[columnMapping[key]] = getFormatDataForCSV(each[key], IGNORE_FIELDS);
          } else {
            acc[columnMapping[key]] = each[key].join(', ');
          }
        } else if (typeof each[key] === 'object' && !Array.isArray(each[key])) {
          acc[columnMapping[key]] = getFormatDataForCSV([each[key]], IGNORE_FIELDS);
        } else {
          acc[columnMapping[key]] = each[key];
        }
      }
      return acc;
    }, {})
  );
};

const handleDownload = (data: any, searchTerm: string) => {
  const selectedColumns = COLUMN_MAPPING;
  const formattedData = transformData(data, selectedColumns);
  const csv = Papa.unparse(formattedData);
  // Create a Blob from the CSV string
  const blob = new Blob([csv], { type: 'text/csv' });
  // Create a download link and click it
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  const day = new Date();
  const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
  const fileName = getFileName({
    prefix: 'ria',
    module: 'search',
    postfix: `${searchTerm}_${date}`,
    additional: '',
    extension: 'csv'
  });
  a.download = `${fileName}.csv`;
  a.click();
};

export default handleDownload;
