import { createSvgIcon } from '@mui/material/utils';

const PlusIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 13 13' fill='none'>
    <g clipPath='url(#clip0_15657_3243)'>
      <path
        d='M12.4583 5.9681H7.04167V0.551432C7.04167 0.407773 6.9846 0.269998 6.88302 0.168416C6.78143 0.0668339 6.64366 0.00976563 6.5 0.00976562V0.00976563C6.35634 0.00976563 6.21857 0.0668339 6.11698 0.168416C6.0154 0.269998 5.95833 0.407773 5.95833 0.551432V5.9681H0.541667C0.398008 5.9681 0.260233 6.02517 0.158651 6.12675C0.0570683 6.22833 0 6.36611 0 6.50977H0C0 6.65342 0.0570683 6.7912 0.158651 6.89278C0.260233 6.99436 0.398008 7.05143 0.541667 7.05143H5.95833V12.4681C5.95833 12.6118 6.0154 12.7495 6.11698 12.8511C6.21857 12.9527 6.35634 13.0098 6.5 13.0098C6.64366 13.0098 6.78143 12.9527 6.88302 12.8511C6.9846 12.7495 7.04167 12.6118 7.04167 12.4681V7.05143H12.4583C12.602 7.05143 12.7398 6.99436 12.8414 6.89278C12.9429 6.7912 13 6.65342 13 6.50977C13 6.36611 12.9429 6.22833 12.8414 6.12675C12.7398 6.02517 12.602 5.9681 12.4583 5.9681Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_15657_3243'>
        <rect width='13' height='13' fill='white' transform='translate(0 0.00976562)' />
      </clipPath>
    </defs>
  </svg>,
  'PlusIcon'
);
export default PlusIcon;
