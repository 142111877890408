import { useCallback, useContext } from 'react';
import UserProfileStore from '../../../store/UserProfile';
import UserProfileActions from '../../../store/UserProfile/actions';
import Store from '../../../store';
import Actions from '../../../store/actions';
import { deleteSearchByActivityIDList, listActivities } from '../../../api/pages/UserProfile';
import { normalizeFavoritesAndProjectsKey } from '../utils';

const useActivityData = () => {
  const { profileState, profileDispatch } = useContext(UserProfileStore);
  const { dispatch } = useContext(Store) as any;

  const fetchPaginatedUserActivity = useCallback(
    async (timestamp = profileState.timestampUserActivity) => {
      try {
        if (!profileState.paginateOnScroll) {
          return;
        }
        profileDispatch({
          type: UserProfileActions.SET_PAGINATION_ON_SCROLL,
          value: false
        });
        profileDispatch({
          type: UserProfileActions.SET_LOADING,
          value: true
        });
        const res = await listActivities({ timestamp });
        if (!res || res.status !== 200 || !res?.data) {
          throw new Error(res?.data?.message ?? 'Error in fetching activity data');
        }
        const { body, status, error, message } = res.data;
        if (status === 200 && !error && body.success) {
          const normalizedActivityData = normalizeFavoritesAndProjectsKey(body.success);
          profileDispatch({
            type: UserProfileActions.SET_ACTIVITY_DATA,
            value: [...profileState.activityData, ...normalizedActivityData]
          });
          profileDispatch({
            type: UserProfileActions.SET_PROFILE_TIMESTAMP,
            value: body.lastTimestamp
          });
          profileDispatch({
            type: UserProfileActions.SET_LOADING,
            value: false
          });
          const continuePagination = !(Array.isArray(body.success) && body.success.length === 0);
          profileDispatch({
            type: UserProfileActions.SET_PAGINATION_ON_SCROLL,
            value: continuePagination
          });
        } else {
          profileDispatch({
            type: UserProfileActions.SET_LOADING,
            value: false
          });
          throw new Error(message);
        }
      } catch (error) {
        const errorMessage = (error as Error)?.message ?? 'Something went wrong';
        await dispatch({
          type: Actions.SET_ALERT,
          value: { message: errorMessage, status: true }
        });
      } finally {
        profileDispatch({
          type: UserProfileActions.SET_LOADING,
          value: false
        });
      }
    },
    [profileState.timestampUserActivity, profileState.paginateOnScroll]
  );

  const setFavorite = async ({
    identifier,
    isFavorite
  }: {
    identifier: string;
    isFavorite: boolean;
  }) => {
    try {
      const newActivityData = profileState.activityData.map((ac: any) => {
        if (ac.id === identifier) {
          return { ...ac, isFavorite };
        }
        return ac;
      });

      profileDispatch({
        type: UserProfileActions.SET_ACTIVITY_DATA,
        value: newActivityData
      });
    } catch (e) {
      const errorMessage = (e as Error)?.message ?? 'Something went wrong';
      await dispatch({
        type: Actions.SET_ALERT,
        value: { message: errorMessage, status: true }
      });
    }
  };

  const setInProject = async ({ identifier, project }: { identifier: string; project: any }) => {
    try {
      const newActivityData = profileState.activityData.map((ac: any) => {
        if (ac.id === identifier) {
          const projects = [...ac.inProjects];

          if (project?.inProject) {
            // Remove the project from inProjects if it exists
            return {
              ...ac,
              inProjects: projects.filter(
                (p: any) => (p?.project_id ?? p?.id) !== (project?.project_id ?? project?.id)
              )
            };
          }
          // Add the project to inProjects if it doesn't exist
          projects.push({
            id: project?.project_id ?? project?.id,
            name: project?.name
          });
          return {
            ...ac,
            inProjects: projects
          };
        }
        return ac;
      });

      profileDispatch({
        type: UserProfileActions.SET_ACTIVITY_DATA,
        value: newActivityData
      });
    } catch (e) {
      const errorMessage = (e as Error)?.message ?? 'Something went wrong';
      await dispatch({
        type: Actions.SET_ALERT,
        value: { message: errorMessage, status: true }
      });
    }
  };

  const handleDeleteActivity = async ({
    type,
    activityIds
  }: {
    type: string;
    activityIds: number[];
  }) => {
    try {
      if (!type || !activityIds || activityIds.length === 0) {
        throw new Error('Cannot delete this search');
      }
      const payload = {
        activityType: type,
        activityIds
      };
      switch (type) {
        case 'advancedSearch':
        case 'quickSearch':
          payload.activityType = 'quick-advanced-search';
          break;
        case 'ariaSearch':
          payload.activityType = 'aria-search';
          break;
        default:
          break;
      }
      const res = await deleteSearchByActivityIDList(payload);

      if (res && res.status === 200) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: {
            message: 'Search removed from activity',
            status: true,
            color: 'success'
          }
        });
        const newActivityData = profileState.activityData.filter(
          (activity: any) => !activityIds.includes(activity.activity_id)
        );
        profileDispatch({
          type: UserProfileActions.SET_ACTIVITY_DATA,
          value: newActivityData
        });
      } else {
        throw new Error('Something went wrong');
      }
    } catch (e) {
      const message = (e as Error)?.message ?? 'Something went wrong';
      await dispatch({
        type: Actions.SET_ALERT,
        value: { message, status: true }
      });
    }
  };

  return {
    isLoading: profileState.loading,
    activityData: profileState.activityData,
    paginationScroll: profileState.paginateOnScroll,
    fetchPaginatedUserActivity,
    setFavorite,
    setInProject,
    deleteActivity: handleDeleteActivity
  };
};

export default useActivityData;
