const css = `
.a{fill:#4172d9;}
`;

const DocumentIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='15' height='14.25' viewBox='0 0 15 14.25'>
    <defs>
      <style>{css}</style>
    </defs>
    <path
      className='a'
      d='M.916,14.5h13.5a.75.75,0,0,0,0-1.5h-6V11.5h5.25a.75.75,0,0,0,.75-.75V1a.75.75,0,0,0-.75-.75h-4.5a.75.75,0,0,0-.532.217L7.359,1.75H1.666a.75.75,0,0,0-.75.75v8.25a.75.75,0,0,0,.75.75h5.25V13h-6a.75.75,0,1,0,0,1.5Zm1.5-4.5V3.25h5.25A.75.75,0,0,0,8.2,3.032L9.474,1.75h3.442V10Z'
      transform='translate(-0.166 -0.25)'
    />
  </svg>
);
export default DocumentIcon;
