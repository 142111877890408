import React from 'react';
import { createSvgIcon } from '@mui/material';

const TimelineDotIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8' fill='none'>
    <g clipPath='url(#clip0_1222_8998)'>
      <path
        d='M4 0C3.20888 0 2.43552 0.234596 1.77772 0.674122C1.11992 1.11365 0.607234 1.73836 0.304484 2.46927C0.00173314 3.20017 -0.0774802 4.00444 0.0768607 4.78036C0.231202 5.55629 0.612165 6.26902 1.17157 6.82843C1.73098 7.38784 2.44372 7.7688 3.21964 7.92314C3.99556 8.07748 4.79983 7.99827 5.53074 7.69552C6.26164 7.39277 6.88635 6.88008 7.32588 6.22228C7.76541 5.56449 8 4.79113 8 4C7.99886 2.93949 7.57706 1.92274 6.82716 1.17284C6.07727 0.422943 5.06052 0.00114703 4 0V0Z'
        fill='#BDBDBD'
      />
    </g>
    <defs>
      <clipPath id='clip0_1222_8998'>
        <rect width='8' height='8' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'RightArrow'
);

export default TimelineDotIcon;
