// mui
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

// styles
import styles from '../styles/Content.styles';

const AboutContent = () => {
  return (
    <Container>
      <Stack spacing={2} mb={5}>
        <Accordion disableGutters={false} elavation={0} square sx={styles.accordian}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon fontSize='medium' sx={styles.expandIcon} />}
            aria-controls='panel1a-content'
            id='panel1a-header'>
            <Typography sx={styles.title}>
              What is Vivpro<sup>AI</sup> ?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={styles.description}>
              Vivpro<sup>AI</sup> is a revolutionary software platform to unlock regulatory
              intelligence and make evidence-based business decisions at lightning speed, for
              pharmaceutical, biotech and device developers, CROs and investors. <br />
              <br />
              Vivpro<sup>AI</sup> is a platform that captures publicly available data related to
              pharmaceutical products. Vivpro<sup>AI</sup> has curated FDA, EMA and
              ClinicalTrials.gov data sources from various locations (Drugs@FDA, AccessFDA, OpenFDA,
              Orangebook, Pediatrics, Devices, Biologics, EPAR, EU Commissions etc.) and in
              different formats (text files, excel files, csv, json, pdf documents etc.).
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion disableGutters={false} elavation={0} square sx={styles.accordian}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon fontSize='medium' sx={styles.expandIcon} />}
            aria-controls='panel1a-content'
            id='panel1a-header'>
            <Typography sx={styles.title}>What is RIA ?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={styles.description}>
              Regulatory Intelligence Assistant (RIA) aims to solve a longstanding problem that
              regulatory information is unstructured, unfriendly, unwieldy, and managed using
              outdated technology. Without any formal regulatory training available, companies spend
              valuable time and resources searching for complex information and hiring diverse
              regulatory experts instead of focusing on strategy. RIA is an application within
              Vivpro<sup>AI</sup> platform that delivers harmonized and consolidated data for
              industry experts and drug manufacturers to accurately assess the regulatory challenges
              that may arise during the drug approval process. <br /> <br />
              RIA saves time and offers high-quality information by automating the tasks to Search,
              Collect, Curate, Summarize and Analyze key data, so the user can focus on the
              Strategy.
            </Typography>
            <img
              src='https://vivpro-site.s3.amazonaws.com/Help_Page_About/About_1.png'
              alt='guide'
              style={styles.image}
            />
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Container>
  );
};

export default AboutContent;
