/* eslint-disable import/prefer-default-export */
/**
 * To be used for results page which has circular tags for each source
 */
import React from 'react';
import { useParams } from 'react-router-dom';

import { GridRenderCellParams } from '@mui/x-data-grid-pro';

// other components
import CircularAvatar from '../../../pages/ResultsPage/components/CircularAvatar';

// constants
import {
  APPROVAL_STATUS_TAGS_LIST_VIEW,
  APPROVAL_STATUS_TAGS_LOOKUP
} from '../../../pages/ResultsPage/utils/constants';

// types
import { UrlParameters } from '../../../types/reactRouterDom';

const DatagridAvatars = (params: GridRenderCellParams) => {
  const { source } = useParams<UrlParameters>();

  const { row, field } = params;
  let identifier;
  if (field in APPROVAL_STATUS_TAGS_LOOKUP) {
    identifier = APPROVAL_STATUS_TAGS_LOOKUP[field];
  } else {
    identifier = source;
  }
  if (source?.toLowerCase() === 'hpra' && row?.source_index?.toLowerCase() === 'ema') {
    identifier = 'eu';
  }
  return (
    <CircularAvatar
      data={row}
      avatarList={APPROVAL_STATUS_TAGS_LIST_VIEW[identifier?.toLowerCase() as string]}
      tooltipWithoutPrefix={source === 'ct'}
    />
  );
};

export default DatagridAvatars;
