import palette from '../../../themev5/palette';

const AskRiaButton = {
  button: {
    borderRadius: '16px',
    height: '32px',
    width: '95px',
    padding: '10px 0px 11px 8px',
    borderColor: palette.white.smoke,
    backgroundColor: palette.blue.background,
    textTransform: 'capitalize',
    color: palette.white.main,
    fontSize: '12px',
    fontWeight: '700',
    fontFamily: 'Mulish',
    '&:hover': {
      borderColor: palette.blue.backgroundLight,
      backgroundColor: palette.white.smoke,
      color: palette.blue.backgroundLight,
      border: '1px solid'
    },
    '&.Mui-disabled': {
      color: palette.gray.light
    }
  },
  sendIcon: {
    padding: '5px',
    fontSize: '30px'
  },
  bottomFilterPosition: {
    position: 'fixed',
    bottom: '20px',
    right: '80px',
    zIndex: 1
  }
};

export default AskRiaButton;
