const isValidDate = (d: any) => {
  if (isNaN(new Date(d).getTime())) {
    return false;
  }
  return true;
};

const sortDateComparator = (v1: string, v2: string) => {
  v1 = isValidDate(v1) ? v1 : '01/01/1800';
  v2 = isValidDate(v2) ? v2 : '01/01/1800';
  return new Date(v1).getTime() - new Date(v2).getTime();
};

export default sortDateComparator;
