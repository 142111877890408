import { PMRImpactContextInterface } from '.';
import actions from './actions';

interface ActionProps {
  type: string;
  value: any;
}

const reducers = (state: PMRImpactContextInterface, action: ActionProps) => {
  switch (action.type) {
    case actions.SET_PMR_TABLE: {
      return {
        ...state,
        tableData: action.value
      };
    }
    case actions.SET_ISSUE_DATE_GRAPH: {
      return {
        ...state,
        issueYearGraph: action.value
      };
    }
    case actions.SET_FULLFILLMENT_GARPH: {
      return {
        ...state,
        fulfillmentGraph: action.value
      };
    }
    case actions.SET_CATEGORY_GARPH: {
      return {
        ...state,
        categoryGraph: action.value
      };
    }
    case actions.SET_PMR_STATS: {
      return {
        ...state,
        pmrStats: action.value
      };
    }
    case actions.SET_PMR_ERRORS: {
      return {
        ...state,
        pmrErrors: action.value
      };
    }
    case actions.SET_FILTERS: {
      return {
        ...state,
        filters: action.value
      };
    }
    case actions.SET_IS_FILTERS_APPLIED: {
      return {
        ...state,
        isFiltersApplied: action.value
      };
    }
    case actions.SET_AVAILABLE_FILTERS: {
      return {
        ...state,
        availableFilters: action.value
      };
    }
    case actions.RESET_FILTERS: {
      return {
        ...state,
        filters: []
      };
    }
    case actions.SET_SEARCH: {
      return {
        ...state,
        search: action.value
      };
    }
    case actions.RESET_SEARCH: {
      return {
        ...state,
        search: action.value
      };
    }
    case actions.SET_LOADING: {
      return {
        ...state,
        loading: action.value
      };
    }
    case actions.SET_TOTAL_COUNT: {
      return {
        ...state,
        totalRecord: action.value
      };
    }
    case actions.SET_SORT: {
      return {
        ...state,
        sortFields: action.value
      };
    }
    case actions.SET_DATA_GRID_FILTERS: {
      return {
        ...state,
        dataGridFilters: action.value
      };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
