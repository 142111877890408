import { SxProps, Theme, Typography } from '@mui/material';

const LanguageToggleText = ({ styles }: { styles?: SxProps<Theme> }) => {
  return (
    <Typography
      sx={{ fontSize: 13, fontWeight: 500, color: 'gray.500', textAlign: 'right', ...styles }}>
      Machine-translated text accuracy may vary. Please use Collaborate to report discrepancies.
    </Typography>
  );
};

LanguageToggleText.defaultProps = {
  styles: {}
};

export default LanguageToggleText;
