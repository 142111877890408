import { useCallback, useContext, useEffect } from 'react';
import Papa from 'papaparse';
import XLSX from 'xlsx';

// context
import PediatricsStore from '../../../store/Pediatrics';
import actions from '../../../store/Pediatrics/actions';
import globalStore from '../../../store/index';
import globalActions from '../../../store/actions';

import { mergeOldFilterValues } from '../../../utils/filters';

// api
import {
  getPediatricsStats,
  getPediatricsDetails,
  getAllPediatricsDocuments
} from '../../../api/pages/Pediatrics';

import {
  EXPORT_TYPE,
  FDA_WR_COLUMN_NAME_MAPPING,
  PAGINATION_DEFAULT_OPTIONS,
  SUBMODULE
} from '../const';
import getTopValuesForDonutChart from '../../PediatricsEMA/utils/getTopValuesForDonutChart';
import getFilterOptions from '../utils/common';
import transformData from '../../../utils/transformData';
import { getFileName } from '../../../helpers/getFileName';

import AuthContext from '../../../store/Auth/AuthProvider';
import { getDataGridFilter } from '../../../utils/getDatagridFilterOption';

const usePediatricsFDAData = () => {
  const { pediatricsFDAState, pediatricsFDADispatch } = useContext(PediatricsStore);
  const { state, dispatch }: any = useContext(globalStore);
  const { currentUser } = useContext(AuthContext);

  const getPediatricsTableData = async (payload: any, limit: number, offset: number) => {
    const res = await getPediatricsDetails(SUBMODULE, payload, limit, offset);
    if (res?.data?.status === 200) {
      pediatricsFDADispatch({
        type: actions.SET_TABLE_DATA,
        value: res?.data?.body?.written_request_doc ?? []
      });

      pediatricsFDADispatch({
        type: actions.SET_TOTAL_COUNT,
        value: res?.data?.body?.written_request_count ?? 0
      });
    }
  };

  const getPediatricsInsightData = async (filters: any, appliedFilters: any) => {
    const res = await getPediatricsStats(SUBMODULE, { filters });
    if (res?.data?.status === 200) {
      const pediatricsStats = res?.data?.body?.stats;
      pediatricsStats.formulations_stats = getTopValuesForDonutChart(
        pediatricsStats?.formulations_stats,
        'id',
        4
      );
      pediatricsStats.route_stats = getTopValuesForDonutChart(
        pediatricsStats?.route_stats,
        'id',
        4
      );
      pediatricsFDADispatch({
        type: actions.SET_STATS,
        value: pediatricsStats
      });
      let mergedFilters = [];
      const newFilters = getFilterOptions(res?.data?.body?.filters);
      if (appliedFilters.length) {
        mergedFilters = mergeOldFilterValues(newFilters, appliedFilters);
      } else {
        mergedFilters = newFilters;
      }
      pediatricsFDADispatch({
        type: actions.SET_FILTERS,
        value: mergedFilters
      });

      pediatricsFDADispatch({
        type: actions.SET_AVAILABLE_FILTERS,
        value: appliedFilters
      });

      pediatricsFDADispatch({
        type: actions.SET_ASK_RIA_FILTERS,
        value: res?.data?.body?.filters?.entity_id || []
      });
    }
  };

  // Hook to fetch data based on datagrid filter
  const getDatawithDataGridFilter = async (
    appliedFilters: any,
    datagridFilter: any,
    sortFields: any,
    limit: number = PAGINATION_DEFAULT_OPTIONS.limit,
    offset: number = PAGINATION_DEFAULT_OPTIONS.offset
  ) => {
    pediatricsFDADispatch({
      type: actions.SET_LOADING,
      value: true
    });

    const source = {
      us: ['written_requests']
    };
    const res: any = await getDataGridFilter(
      appliedFilters,
      datagridFilter,
      sortFields,
      limit,
      offset,
      pediatricsFDAState,
      currentUser,
      source
    );

    if (res?.data?.status === 200) {
      pediatricsFDADispatch({
        type: actions.SET_TABLE_DATA,
        value: res?.data?.body?.result?.written_requests?.results ?? []
      });

      pediatricsFDADispatch({
        type: actions.SET_TOTAL_COUNT,
        value: res?.data?.body?.result?.written_requests?.total ?? 0
      });
      pediatricsFDADispatch({
        type: actions.SET_DATA_GRID_FILTERS,
        value: datagridFilter
      });
    }

    pediatricsFDADispatch({
      type: actions.SET_LOADING,
      value: false
    });
  };

  const getPediatricsFDAData = useCallback(
    async (
      appliedFilters: any,
      limit: number = PAGINATION_DEFAULT_OPTIONS.limit,
      offset: number = PAGINATION_DEFAULT_OPTIONS.offset,
      sort: Array<any> = [],
      shouldGetInsight: boolean = true,
      dataGridFilter: any = {}
    ) => {
      pediatricsFDADispatch({
        type: actions.SET_LOADING,
        value: true
      });

      pediatricsFDADispatch({
        type: actions.SET_IS_FILTERS_APPLIED,
        value: Object.keys(appliedFilters).length !== 0
      });

      const payload: any = {};
      payload.filters = { ...appliedFilters };
      if (sort) {
        payload.sort = sort;
      }
      if (Object.keys(dataGridFilter).length !== 0) {
        await getDatawithDataGridFilter(appliedFilters, dataGridFilter, sort, limit, offset);
      } else {
        try {
          await getPediatricsTableData(payload, limit, offset);
          if (shouldGetInsight) {
            // get visualize and filters data
            await getPediatricsInsightData(payload?.filters, appliedFilters);
          }

          pediatricsFDADispatch({
            type: actions.SET_DATA_GRID_FILTERS,
            value: {}
          });

          pediatricsFDADispatch({
            type: actions.SET_LOADING,
            value: false
          });
        } catch (err) {
          pediatricsFDADispatch({
            type: actions.SET_LOADING,
            value: false
          });
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }
    },
    []
  );

  const handleCSVExport = async () => {
    pediatricsFDADispatch({
      type: actions.SET_LOADING,
      value: true
    });
    const res = await getAllPediatricsDocuments(SUBMODULE, {
      filters: pediatricsFDAState.availableFilters
    });
    const selectedColumns = state?.exportType?.isAllColumnsSelected
      ? FDA_WR_COLUMN_NAME_MAPPING
      : Object.fromEntries(
          Object.entries(FDA_WR_COLUMN_NAME_MAPPING).filter(([key]) =>
            state?.exportType?.selectedColumns?.includes(key)
          )
        );
    const formattedData = transformData(res?.data?.body?.written_request_doc, selectedColumns);
    const csv = Papa.unparse(formattedData);
    // Create a Blob from the CSV string
    const blob = new Blob([csv], { type: 'text/csv' });
    // Create a download link and click it
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    const day = new Date();
    const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
    const fileName = getFileName({
      prefix: 'ria',
      module: 'written_requests',
      postfix: date,
      additional: '',
      extension: 'csv'
    });
    a.download = `${fileName}.csv`;
    a.click();
    dispatch({
      type: globalActions.SET_EXPORT_TYPE,
      value: { format: '', selectedColumns: [], isAllColumnsSelected: false }
    });
    pediatricsFDADispatch({
      type: actions.SET_LOADING,
      value: false
    });
  };

  const handleExcelExport = async () => {
    pediatricsFDADispatch({
      type: actions.SET_LOADING,
      value: true
    });
    const res = await getAllPediatricsDocuments(SUBMODULE, {
      filters: pediatricsFDAState.availableFilters
    });
    const selectedColumns = state?.exportType?.isAllColumnsSelected
      ? FDA_WR_COLUMN_NAME_MAPPING
      : Object.fromEntries(
          Object.entries(FDA_WR_COLUMN_NAME_MAPPING).filter(([key]) =>
            state?.exportType?.selectedColumns?.includes(key)
          )
        );
    const formattedData = transformData(res?.data?.body?.written_request_doc, selectedColumns);
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const day = new Date();
    const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
    const fileName = getFileName({
      prefix: 'ria',
      module: 'written_requests',
      postfix: date,
      additional: '',
      extension: 'xlsx'
    });
    XLSX.writeFile(wb, `${fileName}.xlsx`);
    dispatch({
      type: globalActions.SET_EXPORT_TYPE,
      value: { format: '', selectedColumns: [], isAllColumnsSelected: false }
    });
    pediatricsFDADispatch({
      type: actions.SET_LOADING,
      value: false
    });
  };

  useEffect(() => {
    if (
      state?.exportType?.format &&
      [EXPORT_TYPE.csv, EXPORT_TYPE.xlsx].includes(state?.exportType?.format)
    ) {
      if (state?.exportType?.format === EXPORT_TYPE.csv) {
        handleCSVExport();
      } else if (state?.exportType?.format === EXPORT_TYPE.xlsx) {
        handleExcelExport();
      }
    }
  }, [state?.exportType]);

  return {
    isLoading: pediatricsFDAState.loading,
    isFiltersApplied: pediatricsFDAState.isFiltersApplied,
    pediatricsFDAFilters: pediatricsFDAState.filters,
    pediatricsFDATableData: pediatricsFDAState.tableData,
    getPediatricsFDAData,
    sortFields: pediatricsFDAState.sortFields,
    getDatawithDataGridFilter,
    dataGridFilter: pediatricsFDAState.dataGridFilters
  };
};

export default usePediatricsFDAData;
