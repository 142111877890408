import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

// material ui
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// context
import AriaStore from '../../../store/Aria';
import AriaActions from '../../../store/Aria/actions';

const futureYears = {
  completion_date: 76
};

const StartEndFilter = ({ title, filterType, keyValue, disabled = false }) => {
  const { ariaState, ariaDispatch } = useContext(AriaStore);

  // states for showing start and end values
  const [startValue, setStartValue] = useState(null);
  const [endValue, setEndValue] = useState(null);
  const maxEndDate = new Date();

  if (keyValue in futureYears) {
    maxEndDate.setFullYear(maxEndDate.getFullYear() + futureYears[keyValue]);
  }

  useEffect(() => {
    const currentFilters = ariaState.filters;
    const currentStartDate = new Date(parseInt(currentFilters?.[filterType]?.[0], 10), 1, 1);
    const currentEndDate = new Date(parseInt(currentFilters?.[filterType]?.[1], 10), 1, 1);
    setStartValue(!Number.isNaN(currentStartDate.getTime()) ? currentStartDate : null);
    setEndValue(!Number.isNaN(currentEndDate.getTime()) ? currentEndDate : null);
  }, [ariaState.filters]);

  /**
   * function to update start date category
   * @param {string} newValue is the start value that the user clicks inside the calendar
   */
  const handleStartChange = async newValue => {
    if (newValue) {
      setStartValue(newValue);
      const currentFilters = ariaState.filters;
      currentFilters[filterType] = [
        newValue.getFullYear(),
        endValue ? endValue.getFullYear() : new Date().getFullYear()
      ];
      await ariaDispatch({ type: AriaActions.SET_FILTERS, value: currentFilters });
      ariaDispatch({ type: AriaActions.SET_PENDING_FILTERS, value: true });
    }
  };

  /**
   * function to update end date category
   * @param {string} newValue is the end value that the user clicks inside the calendar
   */
  const handleEndChange = async newValue => {
    if (newValue) {
      setEndValue(newValue);
      const currentFilters = ariaState.filters;
      currentFilters[filterType] = [
        startValue ? startValue.getFullYear() : new Date(1940, 1, 1).getFullYear(),
        newValue.getFullYear()
      ];
      await ariaDispatch({ type: AriaActions.SET_FILTERS, value: currentFilters });
      ariaDispatch({ type: AriaActions.SET_PENDING_FILTERS, value: true });
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Typography variant='subtitle1'>{title}</Typography>
      <Box sx={{ justifyContent: 'space-between' }}>
        <DatePicker
          minDate={new Date(1940, 1, 1)}
          maxDate={endValue ? new Date(endValue) : maxEndDate}
          views={['year']}
          label='From'
          disabled={disabled}
          value={startValue}
          onChange={newValue => handleStartChange(newValue)}
          renderInput={params => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              id='start_date'
              helperText={null}
              sx={{ width: '100px', marginRight: '1vw', marginBottom: '2vh' }}
            />
          )}
          components={{
            OpenPickerIcon: ArrowDropDownIcon
          }}
        />
        <DatePicker
          minDate={startValue ? new Date(startValue) : new Date(1940, 1, 1)}
          maxDate={maxEndDate}
          views={['year']}
          label='To'
          disabled={disabled}
          value={endValue}
          onChange={newValue => handleEndChange(newValue)}
          renderInput={params => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <TextField {...params} id='end_date' helperText={null} sx={{ width: '100px' }} />
          )}
          components={{
            OpenPickerIcon: ArrowDropDownIcon
          }}
        />
      </Box>
    </LocalizationProvider>
  );
};

StartEndFilter.propTypes = {
  title: PropTypes.string.isRequired,
  filterType: PropTypes.string.isRequired,
  keyValue: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

StartEndFilter.defaultProps = {
  disabled: false
};
export default StartEndFilter;
