/* eslint-disable import/prefer-default-export */
export const analyticViewMapping = (results: Array<any>) => {
  // set analytic view data
  const yearGroups: any = {
    '1930-1940': [],
    '1941-1950': [],
    '1951-1960': [],
    '1961-1970': [],
    '1971-1980': [],
    '1981-1990': [],
    '1991-2000': [],
    '2001-2010': [],
    '2011-2020': [],
    '2021-2030': []
  };
  results?.forEach((item: { [key: string]: any }) => {
    let year = 0;

    if ('approval_year' in item) {
      year = item.approval_year;
    } else if ('sales_year' in item) {
      year = item.sales_year;
    }

    if (year >= 1930 && year <= 1940) {
      yearGroups['1930-1940'].push(item);
    } else if (year >= 1941 && year <= 1950) {
      yearGroups['1941-1950'].push(item);
    } else if (year >= 1951 && year <= 1960) {
      yearGroups['1951-1960'].push(item);
    } else if (year >= 1961 && year <= 1970) {
      yearGroups['1961-1970'].push(item);
    } else if (year >= 1971 && year <= 1980) {
      yearGroups['1971-1980'].push(item);
    } else if (year >= 1981 && year <= 1990) {
      yearGroups['1981-1990'].push(item);
    } else if (year >= 1991 && year <= 2000) {
      yearGroups['1991-2000'].push(item);
    } else if (year >= 2001 && year <= 2010) {
      yearGroups['2001-2010'].push(item);
    } else if (year >= 2011 && year <= 2020) {
      yearGroups['2011-2020'].push(item);
    } else if (year >= 2021 && year <= 2030) {
      yearGroups['2021-2030'].push(item);
    }
  });

  return yearGroups;
};
