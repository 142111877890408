const styles = {
  pdfRoot: {
    background: 'white',
    position: 'relative'
  },
  pdfHeader: {
    paddingX: 0,
    paddingY: 0.5
  },
  closeBtn: {
    cursor: 'pointer',
    color: 'gray.650'
  },
  button: {
    fontWeight: 'bold'
  },
  button__disabled: {
    fontWeight: 'bold',
    color: 'gray.light'
  },
  loadingIcon: {
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    top: '50%',
    left: '50%'
  },
  chatRiaButton: {
    display: 'flex',
    ml: '10px',
    width: 'fit-content',
    borderRadius: '16px',
    height: '32px',
    padding: '6px 16px',
    border: '1px solid',
    borderColor: 'blue.backgroundLight',
    backgroundColor: 'white.main',
    textTransform: 'capitalize',
    color: 'blue.backgroundLight',
    fontSize: '12px',
    fontWeigth: '400'
  },
  docTypeButton: { color: 'primary.500', fontWeight: 700, textTransform: 'capitalize' }
};

export default styles;
