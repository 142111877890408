import palette from '../../../themev5/palette';

const styles: any = {
  chips: {
    fontSize: 14,
    p: 1,
    borderColor: `${palette.gray[300]} !important`,
    '&:hover': {
      backgroundColor: `${palette.primary[50]} !important`,
      borderColor: `${palette.primary[700]} !important`
    },
    '&.MuiChip-filled': {
      backgroundColor: `${palette.primary[50]} !important`,
      color: `${palette.primary[700]} !important`,
      fontWeight: 'bold'
    }
  },
  iconsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '14px',
    '& .MuiIconButton-root': {
      padding: '0 !important'
    }
  }
};

export default styles;
