import React from 'react';

import { Box, Checkbox, Chip, Stack, Tooltip, Typography } from '@mui/material';

import styles from '../../styles/ApplicationCard.styles';

// types
import { CardMetadata, SelectableManageCardProps } from '../../types';

import { LABEL_COLOR, sourceFlagMapping } from '../../constants';

const SelectableManageCardComponent = ({
  data,
  showDottedTitle,
  onCheck
}: SelectableManageCardProps) => {
  const { title, identifier, metadata, source, labels, groupTitle, isChecked } = data;
  const FlagComponent = sourceFlagMapping[source];

  return (
    <Box>
      <Tooltip title={groupTitle}>
        <Typography
          fontSize={14}
          pt={1}
          pb={1}
          color='black.lightVariant4'
          maxHeight={48}
          maxWidth='60%'
          sx={styles.overflowEllipsis}>
          {showDottedTitle ? '...' : groupTitle}
        </Typography>
      </Tooltip>

      <Box
        width='100%'
        p={2}
        sx={styles.cardContainer}
        onClick={() => onCheck({ source, identifier, checked: !isChecked })}>
        <Stack direction='row' alignItems='center'>
          <Tooltip title={title}>
            <Typography
              color='green.sea'
              fontWeight='bold'
              fontSize={16}
              maxHeight={24}
              flex={1}
              sx={styles.overflowEllipsis}>
              {title}
            </Typography>
          </Tooltip>
          <Checkbox
            value={identifier}
            checked={isChecked}
            onChange={(_e, checked) => onCheck({ source, identifier, checked })}
          />
        </Stack>
        <Box display='flex' flexWrap='nowrap' pt={1}>
          {metadata.slice(0, 2).map((dataVal: CardMetadata, index: number) => (
            <>
              <Tooltip title={`${dataVal.key}: ${dataVal.value}`}>
                <Typography maxWidth='50%' maxHeight={20} sx={styles.overflowEllipsis}>
                  {dataVal.value}
                </Typography>
              </Tooltip>
              {index < metadata.length - 1 && (
                <Typography pl={1} pr={1}>
                  &#x2022;
                </Typography>
              )}
            </>
          ))}
        </Box>
        <Box display='flex'>
          {metadata.slice(2, metadata.length).map((dataVal: CardMetadata, index: number) => (
            <>
              <Tooltip title={`${dataVal.key}: ${dataVal.value}`}>
                <Typography maxWidth='50%' maxHeight={20} sx={styles.overflowEllipsis}>
                  {dataVal.value}
                </Typography>
              </Tooltip>
              {index < metadata.length - 3 && (
                <Typography pl={1} pr={1} maxWidth='50%'>
                  &#x2022;
                </Typography>
              )}
            </>
          ))}
        </Box>
        <hr />
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Box display='flex' alignItems='center'>
            <FlagComponent sx={{ fontSize: 20 }} />
            {labels &&
              labels.map((label: string) => {
                const labelKey = label.toLowerCase();
                const labelColor = LABEL_COLOR[labelKey];
                return labelColor ? (
                  <Chip
                    label={label}
                    key={label}
                    sx={{
                      ...styles.labelChip,
                      bgcolor: labelColor
                    }}
                  />
                ) : null;
              })}
            <Typography pl={1} pr={1} fontWeight={500}>
              {identifier}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default React.memo(SelectableManageCardComponent);
