import { createSvgIcon } from '@mui/material';

const MoreIcon = createSvgIcon(
  <svg width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' width='32' height='32' rx='8' fill='#828282' />
    <path
      d='M16.4353 14.155C16.1812 14.155 15.9295 14.205 15.6947 14.3023C15.4599 14.3996 15.2465 14.5421 15.0668 14.7218C14.8871 14.9015 14.7446 15.1149 14.6473 15.3497C14.5501 15.5845 14.5 15.8361 14.5 16.0903C14.5 16.3444 14.5501 16.5961 14.6473 16.8309C14.7446 17.0657 14.8871 17.279 15.0668 17.4588C15.2465 17.6385 15.4599 17.781 15.6947 17.8783C15.9295 17.9755 16.1812 18.0256 16.4353 18.0256C16.9486 18.0255 17.4408 17.8215 17.8036 17.4584C18.1665 17.0954 18.3703 16.6031 18.3702 16.0898C18.37 15.5766 18.166 15.0844 17.803 14.7215C17.44 14.3587 16.9477 14.1549 16.4344 14.155H16.4353ZM16.4353 11.8688C16.6893 11.8687 16.9409 11.8186 17.1755 11.7213C17.4102 11.6239 17.6233 11.4814 17.8029 11.3016C17.9824 11.1219 18.1248 10.9086 18.2219 10.6739C18.319 10.4391 18.369 10.1876 18.3688 9.93354C18.3687 9.67951 18.3186 9.42799 18.2213 9.19334C18.1239 8.95869 17.9814 8.7455 17.8016 8.56596C17.6219 8.38641 17.4086 8.24402 17.1739 8.14691C16.9391 8.04981 16.6876 7.99988 16.4335 8C15.9205 8.00023 15.4286 8.20426 15.066 8.5672C14.7033 8.93014 14.4998 9.42226 14.5 9.9353C14.5002 10.4483 14.7043 10.9403 15.0672 11.3029C15.4301 11.6655 15.9223 11.8691 16.4353 11.8688ZM16.4353 20.31C15.922 20.31 15.4298 20.5139 15.0668 20.8768C14.7039 21.2397 14.5 21.732 14.5 22.2453C14.5 22.7585 14.7039 23.2508 15.0668 23.6137C15.4298 23.9767 15.922 24.1806 16.4353 24.1806C16.9486 24.1806 17.4408 23.9767 17.8038 23.6137C18.1667 23.2508 18.3706 22.7585 18.3706 22.2453C18.3706 21.732 18.1667 21.2397 17.8038 20.8768C17.4408 20.5139 16.9486 20.31 16.4353 20.31Z'
      fill='white'
    />
  </svg>,
  'More'
);

export default MoreIcon;
