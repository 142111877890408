import {
  Box,
  CircularProgress,
  Collapse,
  debounce,
  Dialog,
  DialogActions,
  Divider,
  Fade,
  Grow,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import React, {
  forwardRef,
  memo,
  ReactElement,
  Ref,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { Clear, InfoOutlined, Search } from '@mui/icons-material';
import Slide from '@mui/material/Slide';
import { useHistory } from 'react-router-dom';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';

import GlobalActions from '../../store/actions';
import AriaActions from '../../store/Aria/actions';
import GlobalStore from '../../store';
import AriaStore from '../../store/Aria';
import { getAriaQuerySuggestions } from '../../api/pages/Aria';
import generateDescriptionText from '../../pages/Aria/utils/generateDescriptionText';
import {
  ARIA_SEARCH_SUBTEXT,
  TIMEOUT_DURATION_ARIA_BANNER
} from '../../pages/Aria/utils/constants';
import style from '../../pages/Aria/styles/Banner.styles';
import AriaLogoSmall from '../../assets/svgs/Core/AriaLogoSmall';
import trimText from '../../utils/trimText';
import { encodeJSONtoString } from '../../helpers/backToEncoding';
import { getSearchParams } from '../../hooks/useBreadcrumbs';

interface SearchBoxProps {
  openAriaSearch: boolean;
  // eslint-disable-next-line no-unused-vars
  setOpenAriaSearch: (openAriaSearch: boolean) => void;
  source: string;
  module: string;
  queryParam: string | null | undefined;
  searchTerm: string | null | undefined;
  placeholderText: string | undefined;
  navState: any;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Grow in ref={ref} {...props} />;
});

const SearchBox = ({
  openAriaSearch,
  setOpenAriaSearch,
  source = 'us',
  module = 'core',
  queryParam,
  searchTerm,
  placeholderText,
  navState
}: SearchBoxProps) => {
  const styles = style();

  // @ts-ignore
  const { dispatch, state } = useContext(GlobalStore);
  // @ts-ignore
  const { ariaState, ariaDispatch } = useContext(AriaStore);
  const history = useHistory();

  const [search, setSearch] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpenAriaSearch(false);
  };
  const handleClear = () => {
    setSearch('');
  };
  const changeHandler = useCallback(
    // @ts-ignore
    async event => {
      const currentText = event.target.value;
      setSearch(currentText);
      setLoading(true);
      // fetch query suggestions only if there are more than 2 chars entered
      if (currentText.length > 1) {
        const res = await getAriaQuerySuggestions(source, currentText);
        // emptythe list if no results found
        const optionList = res && res.status === 200 ? res.data.body : [];
        setOptions(optionList);
      }
      setLoading(false);
    },
    [source]
  );
  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 200), [changeHandler]);

  const getRouteNameFromNavState = (navFrom: any) => {
    const navNameMap: any = {
      reg360: 'Reg 360',
      searchResult: 'Search Results'
    };
    return navNameMap[navFrom] || 'Home';
  };

  const handleSubmit = (val: string) => {
    if (val?.length > 1000) {
      dispatch({
        type: GlobalActions.SET_ALERT,
        value: {
          status: true,
          message: 'Search query cannot be more than 1000 characters'
        }
      });
      return;
    }
    if (val) {
      if (ariaState.documentLink?.length) {
        try {
          // releases the existing object URL which was previously created by calling URL.createObjectURL()
          URL.revokeObjectURL(ariaState.documentLink.split('#page=')[0]);
          ariaDispatch({ type: AriaActions.SET_DOCUMENT_LINK, value: '' });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
      // clear the previous search details when new search is executed
      ariaDispatch({ type: AriaActions.SET_ORIGINAL_RESULTS, value: [] });
      ariaDispatch({ type: AriaActions.SET_ARIA_RESULTS, value: [] });
      ariaDispatch({ type: AriaActions.SET_SUGGESTED_ANS, value: [] });
      ariaDispatch({ type: AriaActions.SET_DOCUMENT_OPEN, value: false });
      ariaDispatch({ type: AriaActions.SET_DOCUMENT_LINK, value: '' });
      ariaDispatch({ type: AriaActions.SET_FILTERS, value: {} });
      ariaDispatch({ type: AriaActions.SET_TOP_ANSWER, value: '' });
      ariaDispatch({ type: AriaActions.SET_DID_YOU_MEAN_TEXT, value: '' });
      ariaDispatch({ type: AriaActions.SET_ERROR, value: '' });
      // From Aria page, if we click back button we are going back to reg360. Not for the banner page.
      // So replace the banner route from search route so that browser back button will take you to reg360 on aria result page
      if (queryParam) {
        if (!queryParam.startsWith('&')) {
          if (queryParam.startsWith('?')) {
            // eslint-disable-next-line no-param-reassign
            queryParam = queryParam.replace('?', '&');
          } else {
            // eslint-disable-next-line no-param-reassign
            queryParam = `&${queryParam}`;
          }
        }
      }
      const {
        location: { pathname: locationPathName, search: locationSearch }
      } = window;
      const backToObject = {
        module,
        path: locationPathName,
        search: getSearchParams(locationSearch || ''),
        name: getRouteNameFromNavState(navState?.from)
      };
      const backToString = encodeJSONtoString(backToObject);
      history.push(
        `/aria/${module}/${source}?term=${val.trim()}&bucket=all${queryParam}&backTo=${backToString}`,
        navState
      );
    }
  };
  useEffect(() => {
    if (openAriaSearch) {
      // clear the previous earch details when the user comes to banner page
      ariaDispatch({ type: AriaActions.SET_ARIA_RESULTS, value: [] });
      ariaDispatch({ type: AriaActions.SET_SUGGESTED_ANS, value: [] });
      ariaDispatch({ type: AriaActions.SET_DOCUMENT_OPEN, value: false });
      ariaDispatch({ type: AriaActions.SET_DOCUMENT_LINK, value: '' });
      if (
        ariaState.filters['group-id']?.length > 1000 ||
        // @ts-ignore
        JSON.parse(sessionStorage.getItem('aria_cache'))?.ariaFilterList?.length > 1000
      ) {
        let searchResults = module;
        if (module === 'htaDocuments') searchResults = 'HTA Documents';
        else if (module === 'core') {
          searchResults = source === 'ct' ? 'trials' : 'applications';
        }
        // eslint-disable-next-line no-alert
        // eslint-disable-next-line no-nested-ternary
        dispatch({
          type: GlobalActions.SET_ALERT,
          value: {
            status: true,
            message: `Please reduce the number of 
            ${searchResults}
             to less than 1000 using filters to get more relevant results`,
            color: 'warning',
            duration: 5000
          }
        });
      }
    }
  }, [ariaDispatch, openAriaSearch]);

  const descriptionText = generateDescriptionText(searchTerm || '');
  return (
    <Dialog
      sx={{
        '& 	.MuiBackdrop-root': {
          backgroundColor: 'rgba(0,0,0,0.13)',
          backdropFilter: 'blur(2px)'
        }
      }}
      PaperProps={{
        sx: {
          position: 'fixed',
          top: 60,
          // @ts-ignore
          backgroundColor: `white`,
          p: 0,
          borderRadius: 5,
          boxShadow: '-0.1em 0.1em 3em #33b1874a'
        }
      }}
      maxWidth='md'
      fullWidth
      open={openAriaSearch}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby='alert-dialog-slide-description'
    >
      <Stack
        direction='column'
        sx={{
          borderRadius: 2,
          minHeight: '30vh',
          maxHeight: '60vh'
        }}
      >
        <TextField
          fullWidth
          sx={{
            p: 3,
            borderRadius: 5
          }}
          inputRef={input => input && input.focus()}
          // @ts-ignore
          onChange={(e, value) => {
            // @ts-ignore
            setSearch(value);
            // @ts-ignore
            debouncedChangeHandler(e);
          }}
          onKeyUp={e => {
            if (e.key === 'Enter') {
              // @ts-ignore
              handleSubmit(search);
            }
          }}
          maxLength={1000}
          placeholder={placeholderText || 'Search here'}
          required
          variant='standard'
          value={search}
          focused
          InputProps={{
            autoFocus: true,
            style: { fontSize: '1rem' },
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position='end'>
                {search && (
                  <Tooltip title='Clear search'>
                    <IconButton onClick={handleClear} sx={{ color: 'rgba(177,187,228,0.78)' }}>
                      <Clear fontSize='medium' />
                    </IconButton>
                  </Tooltip>
                )}
                <IconButton
                  onClick={() => {
                    handleSubmit(search);
                  }}
                  sx={{ color: 'rgba(177,187,228,0.78)' }}
                >
                  <Search fontSize='medium' />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Divider />
        <Typography variant='caption' sx={{ pl: 3, pt: 1 }} color='grey'>
          {/* eslint-disable-next-line no-nested-ternary */}
          {!search ? 'Start typing to get suggestions...' : options.length > 0 ? 'Results' : ''}
        </Typography>
        {/* eslint-disable-next-line no-nested-ternary */}
        {loading ? (
          <Stack direction='column' justifyContent='center' alignItems='center' mt={3}>
            <Box>
              <CircularProgress />
            </Box>
            <Typography variant='subtitle1'>Loading suggestions ...</Typography>
          </Stack>
        ) : search && options.length <= 0 ? (
          <Stack direction='column' justifyContent='center' alignItems='center' mt={3}>
            <Typography variant='subtitle1'>No suggestion found</Typography>
          </Stack>
        ) : (
          ''
        )}

        <Collapse in={!!(search && options && !loading)}>
          <List
            sx={{
              pl: 1,
              pr: 1,
              pb: 1,
              mr: 3,
              maxHeight: 200,
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                width: '0.4em'
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,0.26)'
              }
            }}
          >
            {options.map((option, index) => (
              <ListItemButton
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(51,177,135,0.1)',
                    border: '1px solid #33b187',
                    borderRadius: 3
                  }
                }}
                onClick={() => {
                  // @ts-ignore
                  handleSubmit(option);
                }}
              >
                <ListItemText
                  primary={option}
                  primaryTypographyProps={{
                    variant: 'subtitle1'
                  }}
                />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      </Stack>
      <DialogActions
        sx={{
          p: 2,
          mr: 1
        }}
      >
        <Box mr={1}>
          <AriaLogoSmall />
        </Box>
        {ariaState.filters['group-id']?.length > 1000 ||
          // @ts-ignore
          (JSON.parse(sessionStorage.getItem('aria_cache'))?.ariaFilterList?.length > 1000 && (
            <Slide direction='up' in timeout={2000} style={{ transitionDelay: '5000ms' }}>
              <Tooltip title='ARIA results derived from top 1000 records of the source dataset.'>
                <TipsAndUpdatesOutlinedIcon sx={{ fontSize: 16 }} color='warning' />
              </Tooltip>
            </Slide>
          ))}
        {
          <Tooltip title={descriptionText}>
            <Typography align='center' variant='subtitle1' fontStyle='italic'>
              Searching results{' '}
              {
                // @ts-ignore
                descriptionText && 'for '
              }
              <Typography component='span' variant='subtitle1' sx={styles.searchText}>
                {
                  // @ts-ignore
                  trimText(descriptionText, 50)
                }
              </Typography>{' '}
              from
              <Fade in timeout={TIMEOUT_DURATION_ARIA_BANNER}>
                <Typography component='span' variant='subtitle1' sx={styles.description}>
                  {
                    // @ts-ignore
                    ARIA_SEARCH_SUBTEXT[source]
                  }
                </Typography>
              </Fade>
            </Typography>
          </Tooltip>
        }
        {source === 'hpra' && (
          <Tooltip title='This search is limited to HPRA documents and EMA educational materials only'>
            <InfoOutlined />
          </Tooltip>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default memo(SearchBox);
