/**
 *
 * @param data:- response data
 * @param pieChartField:- Array containing list of piechart field
 * @returns
 */

const removeZeroValueFromPieChartData = (
  data: { [key: string]: string | any },
  pieChartField: Array<string>
) => {
  const updatedData = { ...data };
  Object.keys(updatedData).forEach((item: string) => {
    if (pieChartField.includes(item)) {
      updatedData[item] = updatedData[item].filter((ele: any) => ele.value !== 0);
    }
  });

  return updatedData;
};

export default removeZeroValueFromPieChartData;
