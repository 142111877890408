import React from 'react';
import PropTypes from 'prop-types';

// material ui
import { Box, Stack, Tooltip, Typography } from '@mui/material';

// styles
import styles from '../styles/statsCount.styles';
import formatCount from '../../utils/formatCount';

const StatsCountTile = ({ text, count, toolTipValue }) => {
  return (
    <Stack sx={styles.statsTile} justifyContent='center'>
      <Box>
        <Tooltip title={toolTipValue || (count >= 10000 ? formatCount(count) : '')} placement='top'>
          <Typography textAlign='left' letterSpacing='0.4px' sx={styles.countText}>
            {count}
          </Typography>
        </Tooltip>
        <Typography sx={styles.statsText}>{text}</Typography>
      </Box>
    </Stack>
  );
};

StatsCountTile.propTypes = {
  text: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  // eslint-disable-next-line react/require-default-props
  toolTipValue: PropTypes.string
};

export default StatsCountTile;
