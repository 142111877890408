import React from 'react';

// mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

const RosterSkeleton = () => {
  return (
    <Box
      flexDirection='column'
      justifyContent='space-evenly'
      p={3}
      sx={{ width: '100%', minHeight: '65vh', marginTop: 1.5 }}>
      <Stack spacing={2} display='flex' flexDirection='row'>
        <Grid container spacing={2}>
          <Grid item lg={12} xl={12}>
            <Box display='flex' flexDirection='row' justifyContent='space-between'>
              <Skeleton height='500px' width='100%' variant='rectangular' />
              <Skeleton height='500px' width='100%' variant='rectangular' />
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default React.memo(RosterSkeleton);
