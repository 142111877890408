import React, { Fragment } from 'react';
//MATERIAL UI IMPORTS
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
//external libs
import AnimatedNumber from 'animated-number-react';
//styles
import CardsStyles from '../styles/Cards.styles';

const Cards = ({ data }) => {
  const classes = CardsStyles();
  let formatValue = value => `${Number(value).toFixed(0)}`;
  return (
    <Fragment>
      <Box display='flex' flexDirection='column'>
        {data ? (
          <Typography align='left' className={classes.headings}>
            Overview
          </Typography>
        ) : (
          <Skeleton height={16} width={100} className={classes.headings} />
        )}

        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-around'
          className={classes.cards}>
          {data ? (
            data &&
            data.map(item => {
              return (
                <Paper elevation={2} className={classes.paper} key={`${item.id.toLowerCase()}`}>
                  <Box
                    padding={1}
                    display='flex'
                    flexDirection='column'
                    justifyContent='space-evenly'
                    alignItems='center'
                    className={classes.cardContent}>
                    <Typography className={classes.number}>
                      <AnimatedNumber
                        className='animatedNumber'
                        value={String(item.count)}
                        formatValue={formatValue}
                        duration='1000'
                      />
                    </Typography>
                    <Typography className={classes.cardText}>{item.id}</Typography>
                  </Box>
                </Paper>
              );
            })
          ) : (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-around'
              className={classes.cards}>
              <Skeleton className={classes.paper} />
              <Skeleton className={classes.paper} />
              <Skeleton className={classes.paper} />
            </Box>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

export default Cards;
