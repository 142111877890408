import actions from './actions';
import { CanadaGuidanceContextInterface } from '.';

interface ActionProps {
  type: string;
  value: any;
}

const reducers = (state: CanadaGuidanceContextInterface, action: ActionProps) => {
  switch (action.type) {
    case actions.SET_CANADA_GUIDANCE_TABLE: {
      return {
        ...state,
        tableData: action.value
      };
    }
    case actions.SET_CANADA_GUIDANCE_STATS: {
      return {
        ...state,
        canadaGuidanceStats: action.value
      };
    }
    case actions.SET_CANADA_GUIDANCE_ERRORS: {
      return {
        ...state,
        canadaGuidanceErrors: action.value
      };
    }
    case actions.SET_DONUT_CHARTS: {
      return {
        ...state,
        donutCharts: action.value
      };
    }
    case actions.SET_ISSUE_DATE: {
      return {
        ...state,
        issueDate: action.value
      };
    }
    case actions.SET_ADOPTED_DATE: {
      return {
        ...state,
        adopted_date: action.value
      };
    }
    case actions.SET_FILTERS: {
      return {
        ...state,
        filters: action.value
      };
    }
    case actions.SET_AVAILABLE_FILTERS: {
      return {
        ...state,
        availableFilters: action.value
      };
    }
    case actions.SET_LOADING: {
      return {
        ...state,
        loading: action.value
      };
    }
    case actions.SET_IS_FILTERS_APPLIED: {
      return {
        ...state,
        isFiltersApplied: action.value
      };
    }
    case actions.SET_SEARCH: {
      return {
        ...state,
        search: action.value
      };
    }
    case actions.RESET_SEARCH: {
      return {
        ...state,
        search: action.value
      };
    }
    case actions.SET_DOCUMENT_TO_COMPARE: {
      return {
        ...state,
        documentsToCompare: [...state.documentsToCompare, action.value]
      };
    }
    case actions.REMOVE_DOCUMENT_TO_COMPARE: {
      return {
        ...state,
        // eslint-disable-next-line no-underscore-dangle
        documentsToCompare: [...state.documentsToCompare].filter(doc => doc._id !== action.value)
      };
    }
    case actions.CLEAR_DOCUMENT_TO_COMPARE: {
      return {
        ...state,
        documentsToCompare: []
      };
    }
    case actions.SET_COMPARE_ENABLED: {
      return {
        ...state,
        compareEnabled: action.value
      };
    }
    case actions.SET_COMPARE_POPUP_OPEN: {
      return {
        ...state,
        comparePopupOpen: action.value
      };
    }
    case actions.SET_TOTAL_COUNT: {
      return {
        ...state,
        totalRecord: action.value
      };
    }
    case actions.SET_SORT: {
      return {
        ...state,
        sortFields: action.value
      };
    }
    case actions.SET_ASK_RIA_FILTERS: {
      return {
        ...state,
        askRiaFilters: action.value
      };
    }
    case actions.SET_DATA_GRID_FILTERS: {
      return {
        ...state,
        dataGridFilters: action.value
      };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
