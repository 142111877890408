import React from 'react';

// mui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

// styles
import styles from '../../styles/Summary.styles';

const SummarySkeleton = () => {
  return (
    <Box sx={{ width: '100%' }} m={2}>
      <Grid container spacing={2}>
        <Grid item lg={11} xl={11}>
          <Box display='flex' flexDirection='column'>
            <Skeleton variant='rectangular' sx={styles.SummaryBox} />
          </Box>
        </Grid>
        <Grid item lg={11} xl={11}>
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Skeleton sx={styles.card} variant='rectangular' />
            <Skeleton sx={styles.stats} variant='rectangular' />
            <Skeleton sx={styles.stats} variant='rectangular' />
            <Skeleton sx={styles.stats} variant='rectangular' />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(SummarySkeleton);
