/* eslint-disable no-undef */
import React, { useEffect, useMemo } from "react";
import { CSVLink } from "react-csv";
import { csvProps } from "./csvPropsType";
import { getFileName } from "../../helpers/getFileName";

const CsvDownload = ({
  filedata,
  data,
  children,
  style,
  headers,
  className,
  id
}: csvProps): JSX.Element => {
  const styles = { ...style, textDecoration: 'none' };
  const [cleanedData] = React.useState(JSON.parse(JSON.stringify(data)));

  // Remove /n and , from the string to maintain proper alignment.
  useEffect(() => {
    Object.values(cleanedData).forEach((item: any) => {
      Object.entries(item).forEach(([key, field]) => {
        if (Array.isArray(field)) {
          // eslint-disable-next-line no-param-reassign
          item[key] = field.join(' ').replace(/\n/g, '').replace(/,/g, '');
        }
      });
    });
  }, [cleanedData]);
  return (
    <CSVLink
      data={cleanedData}
      id={id}
      filename={useMemo(() => getFileName(filedata), [filedata])}
      style={styles}
      headers={headers}
      className={className}
    >
      {children}
    </CSVLink>
  );
};

export default CsvDownload;
