import {
  KeyboardEventHandler,
  useRef,
  useState,
  useCallback,
  FC,
  type ReactNode,
  useMemo,
  memo
} from 'react';
import {
  MenuItemProps,
  MenuItem,
  MenuProps as MenuPropsInterface,
  ListItemIcon,
  ListItemText,
  PopoverOrigin
} from '@mui/material';
import { uniqueId } from 'lodash';
import RoundedMenu from '../RoundedMenu';
import { ArrowRightIcon } from '../../../../assets/svgs/Icons';

import styles from './MenuItemWithSubMenu.styles';

type MoreMenuItemProps = {
  // Icon to be displayed before the text (optional)
  MenuItemIcon?: ReactNode;
  // Text to be displayed in the menu item (this can be a string or a Component)
  MenuItemText: string | ReactNode;
  MenuProps?: Omit<MenuPropsInterface, 'open' | 'onClose' | 'anchorEl' | 'onKeyDown'>;
} & Omit<MenuItemProps, 'onKeyDown' | 'onMouseEnter' | 'onMouseLeave'>;

const MenuItemWithSubMenu: FC<MoreMenuItemProps> = ({
  MenuItemIcon,
  MenuItemText,
  children,
  id,
  MenuProps,
  ...other
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  const menuItemRef = useRef<HTMLLIElement>(null);
  const menuItemId = uniqueId();
  const normMenuItemId = id ?? menuItemId;

  const handleItemKeyDown: KeyboardEventHandler<HTMLLIElement> = ev => {
    if (
      (ev.key !== 'ArrowRight' && ev.key !== 'Enter') ||
      ev.ctrlKey ||
      ev.shiftKey ||
      ev.altKey ||
      ev.metaKey
    )
      return;
    ev.preventDefault();
    ev.stopPropagation();
    setIsOpen(true);
  };

  const handleMenuKeyDown: KeyboardEventHandler<HTMLDivElement> = ev => {
    ev.stopPropagation();
    if (
      (ev.key !== 'ArrowLeft' && ev.key !== 'Escape') ||
      ev.ctrlKey ||
      ev.shiftKey ||
      ev.altKey ||
      ev.metaKey
    )
      return;
    ev.preventDefault();
    setIsOpen(false);
  };

  // Function to decide the position of the nested menu
  const getSubMenuPosition = useMemo(() => {
    if (!menuItemRef?.current) return undefined;
    // You can customize this logic based on your layout and styles
    const windowWidth = window.innerWidth;
    const nestedRect = menuItemRef.current?.getBoundingClientRect();
    const spaceRight = windowWidth - (nestedRect?.right ?? 0);
    const nestedWidth = nestedRect?.width ?? 999;

    // Open to the right if there is enough space, otherwise open to the left
    return spaceRight > nestedWidth
      ? {
          anchorOrigin: { vertical: 'center', horizontal: 'right' } as PopoverOrigin,
          transformOrigin: { vertical: 'center', horizontal: 'left' } as PopoverOrigin
        }
      : {
          anchorOrigin: { vertical: 'center', horizontal: 'left' } as PopoverOrigin,
          transformOrigin: { vertical: 'center', horizontal: 'right' } as PopoverOrigin
        };
  }, [menuItemRef?.current, normMenuItemId]);

  return (
    <MenuItem
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      onKeyDown={handleItemKeyDown}
      ref={menuItemRef}
      sx={{
        ...(isOpen && { backgroundColor: 'action.hover' }),
        ...styles.menuItem,
        ...other?.sx
      }}
      onMouseEnter={open}
      onMouseLeave={close}
      id={normMenuItemId}>
      {MenuItemIcon && <ListItemIcon sx={styles.listItemIcon}>{MenuItemIcon}</ListItemIcon>}
      {typeof MenuItemText === 'string' ? (
        <ListItemText sx={styles.listItemText}>{MenuItemText}</ListItemText>
      ) : (
        MenuItemText
      )}
      <ArrowRightIcon sx={styles.MenuExpandIcon} />
      <RoundedMenu
        TransitionProps={{ onExited: () => menuItemRef.current?.focus() }}
        disableRestoreFocus
        closeAfterTransition
        onKeyDown={handleMenuKeyDown}
        sx={styles.subMenu}
        MenuListProps={{
          ...MenuProps?.MenuListProps,
          'aria-labelledby': normMenuItemId
        }}
        anchorEl={menuItemRef.current}
        open={isOpen}
        onClose={close}
        anchorOrigin={MenuProps?.anchorOrigin ?? getSubMenuPosition?.anchorOrigin}
        transformOrigin={MenuProps?.transformOrigin ?? getSubMenuPosition?.transformOrigin}>
        {children}
      </RoundedMenu>
    </MenuItem>
  );
};

MenuItemWithSubMenu.defaultProps = {
  MenuItemIcon: null,
  MenuProps: {}
};

export default memo(MenuItemWithSubMenu);
