/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useMemo } from 'react';

// muiv5
import Stack from '@mui/material/Stack';
// utils
import { debounce } from 'lodash';
import { PIPS_EMA_COLUMNS } from '../utils/columnMapping';
import VivproDatagrid from '../../../components/Datagrid';
import { getFileName } from '../../../helpers/getFileName';
import styles from '../../../components/PMRSurveillance/styles/Details.styles';

// context
import PediatricsEMAStore from '../../../store/PediatricsEMA';
import actions from '../../../store/PediatricsEMA/actions';
import getDatagridFilterOption from '../../../utils/getDatagridFilterOption';
import { DATAGRID_OPTION_LIST } from '../../../components/Datagrid/constants';

const ListView = ({
  pagination,
  setPagination,
  sort,
  setSort,
  sortFields,
  getPediatricsEMAData,
  isFilterUpdated,
  setIsFilterUpdated,
  getDatawithDataGridFilter,
  dataGridFilter
}: any) => {
  const { pediatricsEMAState, pediatricsEMADispatch } = useContext<any>(PediatricsEMAStore as any);
  const day = new Date();
  const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
  const filename = {
    prefix: 'ria',
    module: 'pips',
    postfix: date,
    additional: '',
    extension: 'csv'
  };

  useEffect(() => {
    if (isFilterUpdated) {
      setIsFilterUpdated(false);
    }
  }, [isFilterUpdated]);

  const fetchData = useCallback(
    (pageModel: any, sortModel: any, gridFilters: any) => {
      getPediatricsEMAData(
        pediatricsEMAState.availableFilters,
        pageModel.pageSize,
        pageModel.page * pageModel.pageSize,
        sortModel,
        false,
        gridFilters
      );
    },
    [pediatricsEMAState.availableFilters]
  );

  const handlePageChange = useCallback(
    (pageModel: any) => {
      if (!isFilterUpdated) {
        fetchData(pageModel, sortFields, dataGridFilter);
        setPagination(pageModel);
      }
    },
    [sortFields, pediatricsEMAState.availableFilters, isFilterUpdated, dataGridFilter]
  );

  const handleSortModelChange = useCallback(
    (newSortingModel: any) => {
      const modifiedList: any = [];

      newSortingModel.forEach((item: any) => {
        modifiedList.push(item);
      });

      if (JSON.stringify(modifiedList) !== JSON.stringify(pediatricsEMAState.sortFields)) {
        fetchData(pagination, modifiedList, dataGridFilter);
      }
      pediatricsEMADispatch({
        type: actions.SET_SORT,
        value: modifiedList
      });

      setSort(modifiedList);
    },
    [
      pagination,
      sortFields,
      pediatricsEMAState.filters,
      pediatricsEMAState,
      actions,
      dataGridFilter
    ]
  );

  const renderableTableColumns = useMemo(() => {
    const renderableColumns = [...PIPS_EMA_COLUMNS];

    return getDatagridFilterOption(renderableColumns, DATAGRID_OPTION_LIST, pediatricsEMAState);
  }, [pediatricsEMAState]);

  const handleFilterChange = useCallback(
    debounce(newFilterModel => {
      if (newFilterModel?.items?.length > 0) {
        getDatawithDataGridFilter(
          pediatricsEMAState.availableFilters,
          newFilterModel,
          sortFields,
          pagination.pageSize,
          pagination.page * pagination.pageSize
        );
      } else {
        fetchData(pagination, sortFields, {});
      }
    }, 300),
    [pediatricsEMAState.availableFilters, sortFields]
  );

  useEffect(() => {
    return () => {
      handleFilterChange.cancel();
    };
  }, [handleFilterChange]);

  return (
    <Stack sx={styles.root}>
      <VivproDatagrid
        rows={pediatricsEMAState.pip_details}
        columnsMapping={renderableTableColumns}
        rowId='decision_number'
        csvFileName={getFileName(filename)}
        loading={pediatricsEMAState.loading}
        rowCount={pediatricsEMAState.totalRecord}
        isPagination
        pagination={pagination}
        setPagination={handlePageChange}
        sortingModel={sort}
        handleSortModelChange={handleSortModelChange}
        isServerSideExport
        isFilterModeServer
        allowFilterPanelWithLogicOperators
        handleFilterModelChange={handleFilterChange}
      />
    </Stack>
  );
};

export default ListView;
