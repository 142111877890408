import { createSvgIcon } from '@mui/material';

const EditIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
    <path
      d='M15.2933 0.705732C14.8401 0.253197 14.2258 -0.000976562 13.5853 -0.000976562C12.9449 -0.000976562 12.3306 0.253197 11.8773 0.705732L0 12.5831V15.9991H3.416L15.2933 4.12173C15.7457 3.66841 15.9998 3.05415 15.9998 2.41373C15.9998 1.77331 15.7457 1.15905 15.2933 0.705732V0.705732ZM2.86667 14.6657H1.33333V13.1324L10.2067 4.26573L11.74 5.79906L2.86667 14.6657ZM14.3507 3.17906L12.6793 4.8504L11.1493 3.31707L12.82 1.6484C13.0233 1.44507 13.2991 1.33083 13.5867 1.33083C13.8742 1.33083 14.15 1.44507 14.3533 1.6484C14.5567 1.85173 14.6709 2.12751 14.6709 2.41507C14.6709 2.70262 14.5567 2.9784 14.3533 3.18173L14.3507 3.17906Z'
      fill='currentColor'
    />
  </svg>,
  'EditIcon'
);
export default EditIcon;
