import makeRequest from '../client';

export const regulatoryInformationAPI = (
  source: string,
  applno: string,
  feature: string,
  center: string = ''
) => {
  let url = `/reg360/${source}/${applno}?feature_name=${feature}`;
  if (center) url += `&center=${center}`;
  return makeRequest(url, 'GET');
};

export const regulatory360ApiWithFilters = (
  source: string,
  applno: string,
  feature: string,
  payload: any
) => makeRequest(`/reg360/${source}/${applno}?feature_name=${feature}`, 'POST', payload);

export const verifyClinicalStudy = (payload: any) =>
  makeRequest(`/reg360/clinical-study-section`, 'POST', payload);

export default regulatoryInformationAPI;
