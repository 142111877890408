import titleCase from '../../../utils/titleCase';
import { CardData } from '../types';

import getApprovalTracks from './getApprovalTracks';

interface USAppAPIData {
  accelerated_approval: boolean;
  accelerated_approval_status: string;
  accelerated_approval_text: string;
  generic_name: Array<string>;
  advisory_committee: boolean;
  advisory_committee_date: string;
  application_boxed_warning_flag: boolean;
  application_boxed_warning_text: string;
  application_number: string;
  application_type: string;
  approval_date: string;
  approval_pathway: string;
  approval_pathway_flag: boolean;
  approval_time: string;
  approval_year: string;
  atc_classification: Array<any>;
  atc_code: string;
  biosimilar: boolean;
  biosimilar_text: string;
  breakthrough_status: boolean;
  breakthrough_status_text: string;
  center: string;
  division: string;
  document_count: 2;
  document_total_page_count: 32;
  fast_track: false;
  fast_track_text: '';
  filing_date: string;
  formulation: Array<string>;
  inProjects: Array<{
    id: number;
    name: string;
  }>;
  indications_and_usage: string;
  is_favorite: boolean;
  is_subscribed: boolean;
  marketing_status: string;
  marketing_status_description: string;
  matched_synonyms: Array<string>;
  monoclonol_antibody: boolean;
  office: string;
  orphan_status: boolean;
  orphan_status_text: string;
  pediatric_use: Array<any>;
  rems_active_flag: string;
  rems_text: string;
  route: Array<string>;
  rtor: boolean;
  rtorfullsubmissiondate: string;
  sponsor_name: string;
  submission_class: string;
  product_name: Array<string>;
  active_ingredients: string;
}

export const USA_IDENTIFIER_KEY = 'application_number';

const mapUSApps = (apiData: USAppAPIData) => {
  const approvalTracks = getApprovalTracks(apiData, 'us');
  const cardProps: CardData = {
    source: 'us',
    title: titleCase(
      Array.isArray(apiData.product_name) ? apiData.product_name.join(' ') : apiData.product_name
    ),
    groupTitle: Array.isArray(apiData.generic_name)
      ? titleCase(apiData.generic_name.join(' '))
      : titleCase(apiData.active_ingredients),
    identifier: apiData.application_number,
    showTentative: apiData.marketing_status?.toLowerCase() === 'yellow',
    subGroupTitle: apiData.center === 'CBER' ? 'CBER' : '',
    showDiscontinued: apiData.marketing_status?.toLowerCase() === 'red',
    isFavorite: apiData.is_favorite,
    isSubscribed: apiData.is_subscribed,
    metadata: [
      {
        key: 'Sponsor Name',
        value: titleCase(
          (Array.isArray(apiData.sponsor_name)
            ? apiData.sponsor_name.join(';')
            : apiData.sponsor_name) || '---'
        )
      },
      {
        key: 'Formulation',
        value: titleCase(
          (Array.isArray(apiData.formulation)
            ? apiData.formulation.join(';')
            : apiData.formulation) || '---'
        )
      },
      {
        key: 'Route',
        value: titleCase(
          (Array.isArray(apiData.route) ? apiData.route.join(' ') : apiData.route) || '---'
        )
      },
      {
        key: 'Approval Pathway',
        value: titleCase(apiData.approval_pathway || '---')
      },
      { key: 'First Approval Year', value: apiData.approval_year }
    ],
    approvalPathway: apiData.approval_pathway,
    approvalTracks,
    labels: [apiData.application_type?.toUpperCase()],
    colorScheme: apiData?.application_type,
    ...apiData
  };

  return cardProps;
};

export default mapUSApps;
