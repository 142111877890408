import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import reducers from './reducers';

const initialState = {
  loading: false,
  error: '',
  originalResults: [],
  ariaResults: [],
  suggestedAns: [],
  documentOpen: false,
  documentLink: '',
  originalLangDocumentLink: '',
  documentState: 'CLOSE',
  globalFilters: {},
  filters: {},
  showFiltersDrawer: false,
  originalTopAnswer: '',
  topAnswer: '',
  page: 1,
  maxPage: 1,
  originalTotalResults: 0,
  totalResults: 0,
  explorePageResultsCount: 0,
  selectedSort: 'Relevance: Very High -> Low',
  selectedDocument: {},
  scrollToTop: false,
  searchId: null,
  isFavorite: false,
  inProjects: [],
  uniqueResults: false,
  uniqueResultsCount: 0,
  collapsedSuggestions: true,
  pendingFilters: false,
  originalTextEnabled: false
};

const AriaStore = createContext(initialState);
const { Provider } = AriaStore;

export const AriaStateProvider = ({ children }) => {
  const [ariaState, ariaDispatch] = useReducer(reducers, initialState);
  return <Provider value={{ ariaState, ariaDispatch }}>{children}</Provider>;
};

AriaStateProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AriaStore;
