import React, { createContext, useReducer } from 'react';
import reducers from './reducers';

type Props = {
  children: React.ReactNode;
};

export interface EMAGuidanceContextInterface {
  emaGuidanceStats: any;
  tableData: any[];
  donutCharts: any[];
  publishedDate: any[];

  emaGuidanceErrors: any[];
  filters: any[];
  availableFilters: any;
  loading: boolean;
  isFiltersApplied: boolean;

  documentsToCompare: any[];
  compareEnabled: boolean;
  comparePopupOpen: boolean;
  totalRecord: number;
  sortFields: any[];
  askRiaFilters: any[];
  dataGridFilters: any;
}

const initialState = {
  emaGuidanceStats: {},
  tableData: [],
  donutCharts: [],
  publishedDate: [],

  emaGuidanceErrors: [],
  filters: [],
  availableFilters: {},
  loading: true,
  isFiltersApplied: false,
  search: {},
  documentsToCompare: [],
  compareEnabled: false,
  comparePopupOpen: false,
  totalRecord: 0,
  sortFields: [],
  askRiaFilters: [],
  dataGridFilters: {}
};

const EMAGuidanceStore = createContext<{
  EMAGuidanceState: EMAGuidanceContextInterface;
  EMAGuidanceDispatch: React.Dispatch<any>;
}>({ EMAGuidanceState: initialState, EMAGuidanceDispatch: () => null });
const { Provider } = EMAGuidanceStore;

export const EMAGuidanceStateProvider = ({ children }: Props) => {
  const [EMAGuidanceState, EMAGuidanceDispatch] = useReducer(reducers, initialState);
  return <Provider value={{ EMAGuidanceState, EMAGuidanceDispatch }}>{children}</Provider>;
};

export default EMAGuidanceStore;
