import Close from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import styles from './Drawer.style';
import renderTreeNode from './TreeRenderer';
import useATCClassification from './useATCClassification';
import ApplyFilterAlert from '../NewFilters/ApplyFilterAlert';
import customTitleCase from '../../utils/titleCase';

const ATCFilter = ({ onClose, drawerRef }: any) => {
  const {
    getATCTreeData,
    treeData,
    updateATCFilters,
    applyFilter,
    isATCFilterModified,
    isLoading,
    resetATCFilters
  } = useATCClassification();

  const [atcNode, setAtcNode] = useState<any>({
    atcCode: null,
    parentTerm: null,
    type: null
  });
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const onNodeClick = (node: any) => {
    setAtcNode({ atcCode: node.id, type: 'atc_class', parentTerm: node.name });
  };

  const onNodeSelect = (node: any) => {
    updateATCFilters(node.id);
  };

  useEffect(() => {
    getATCTreeData('us');
  }, []);

  const handleClose = () => {
    if (isATCFilterModified) {
      setShowAlert(true);
    } else {
      onClose();
    }
  };

  // eslint-disable-next-line no-param-reassign
  drawerRef.current.onClose = handleClose;

  return (
    <Box overflow='hidden' height='100%' position='relative'>
      <Box sx={styles.drawerHeader} display='flex' justifyContent='space-between'>
        <Typography color='gray.0' variant='h4'>
          ATC Classification
        </Typography>
        <Box onClick={handleClose} sx={{ cursor: 'pointer' }}>
          <Close sx={{ color: 'gray.0' }} />
        </Box>
      </Box>
      <Box sx={styles.mainInformationText}>
        <Box>
          <Typography sx={styles.expandedDrawerSubInfoText}>
            Check the box of a ATC Classification term to filter applications, as indicated by the
            number of applications containing the term.
          </Typography>
        </Box>
        <Box>{treeData?.map((n: any) => renderTreeNode(n, onNodeClick, onNodeSelect))}</Box>
      </Box>
      <Box p={3} bgcolor='gray.200'>
        <Box display='flex' justifyContent='space-between'>
          <Button
            size='small'
            sx={styles.clearAll}
            onClick={() => {
              resetATCFilters();
              onClose();
            }}>
            Reset
          </Button>
          <LoadingButton
            size='small'
            loading={false}
            disabled={!isATCFilterModified}
            sx={{
              ...styles.applyButton,
              backgroundColor: !isATCFilterModified ? 'gray.500' : 'primary.600'
            }}
            onClick={applyFilter}>
            View Results
          </LoadingButton>
        </Box>
      </Box>
      {isLoading && (
        <Box sx={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
      {atcNode.atcCode && (
        <Box sx={styles.expandedDrawer}>
          <Box sx={styles.expandedDrawerTitle}>
            <Typography color='gray.0' variant='h4'>
              ATC Code
            </Typography>
            <Box
              onClick={() =>
                setAtcNode({
                  atcCode: null,
                  parentTerm: null,
                  type: null
                })
              }
              sx={{ cursor: 'pointer' }}>
              <Close sx={{ color: 'gray.0' }} />
            </Box>
          </Box>
          <Box sx={styles.expandedDrawerSubTitle}>
            Showing ATC Code for: {customTitleCase(atcNode.parentTerm)}
          </Box>
          <Box sx={styles.subTermsContainer}>
            <Box display='flex'>
              <Typography>&#9679;</Typography>
              <Typography pl={1}>{atcNode.atcCode}</Typography>
            </Box>
          </Box>
        </Box>
      )}
      <ApplyFilterAlert
        showAlert={showAlert}
        applyFilters={applyFilter}
        selectedCategoryOptions={null}
        previousSelectedCategoryOptions={null}
        handleClose={() => {
          setShowAlert(false);
          onClose();
        }}
        setSelectedCategoryOptions={null}
      />
    </Box>
  );
};

export default React.memo(ATCFilter);
