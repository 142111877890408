import React, { useCallback, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import { groupBy, uniq } from 'lodash';
import KeyDownIcon from '../../../../assets/svgs/Icons/KeyDownIcon';
import SearchIcon from '../../../../assets/svgs/Icons/SearchIcon';
import { styles } from '../../styles/MeetingOptions';

const MEETING_TYPES_WITHOUT_CATEGORIES = ['Type A', 'Type C', 'Type D', 'Interact'];

interface MeetingOptionsProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  options: any[];
  onOptionSelect: (category: string) => void;
  onGroupSelect: (type: string) => void;
  onClear: () => void;
}

const MeetingOptions: React.FC<MeetingOptionsProps> = ({
  anchorEl,
  onClose,
  options,
  onOptionSelect,
  onGroupSelect,
  onClear,
}) => {
  const [searchText, setSearchText] = useState<string>('');
  const [expandedGroups, setExpandedGroups] = useState<Array<string>>([]);

  const groupedOptions = useMemo(() => {
    const filteredOptions =
      options?.filter((opt: any) => opt.category.toLowerCase().includes(searchText.toLowerCase())) || [];
    return groupBy(filteredOptions, (opt: any) => opt.type);
  }, [options, searchText]);

  const handleSearchTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setSearchText(value);

      const filteredOptions = options.filter((opt: any) =>
        opt.category.toLowerCase().includes(value.toLowerCase())
      );
      const newGroups = uniq(filteredOptions.map((opt: any) => opt.type));
      setExpandedGroups(newGroups);
    },
    [options]
  );

  const handleAccordionClick = useCallback(
    (key: string) => {
      setExpandedGroups((prevGroups) =>
        prevGroups.includes(key) ? prevGroups.filter((group) => group !== key) : [...prevGroups, key]
      );
    },
    []
  );

  const clearSelectionEnabled = useMemo(() => options.some((opt) => opt.selected), [options]);

  return (
    <Popover
      id='popover'
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={styles.submissionOptionsContainer}>
        {clearSelectionEnabled && (
          <Box sx={styles.clearSelectionContainer}>
            <Typography sx={styles.clearSelection} onClick={onClear}>
              Clear Selection
            </Typography>
          </Box>
        )}

        <Box sx={styles.searchContainer}>
          <TextField
            size='small'
            value={searchText}
            onChange={handleSearchTextChange}
            placeholder='Search here'
            sx={styles.searchBox}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon sx={styles.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {Object.keys(groupedOptions).map((key, index) => (
          <Accordion
            key={key}
            onChange={() => handleAccordionClick(key)}
            expanded={expandedGroups.includes(key)}
            sx={{
              ...styles.accordion,
              '&::before': { backgroundColor: index === 0 ? 'unset !important' : 'gray.200' },
            }}
          >
            <AccordionSummary
              expandIcon={!MEETING_TYPES_WITHOUT_CATEGORIES.includes(key) && (
                <KeyDownIcon sx={styles.expandIcon} />
              )}
              id={key}
              sx={styles.accordionSummary}
            >
              <Box sx={styles.accordionHeaderBox}>
                <Checkbox
                  sx={styles.checkBox}
                  checked={
                    groupedOptions[key].filter((opt: any) => opt.selected).length ===
                    groupedOptions[key].length
                  }
                  onClick={(e) => e.stopPropagation()} // To avoid accordion close.
                  onChange={() => onGroupSelect(key)}
                />
                <Typography sx={styles.groupTitle}>{key}</Typography>
              </Box>
            </AccordionSummary>
            {!MEETING_TYPES_WITHOUT_CATEGORIES.includes(key) && (
              <AccordionDetails sx={styles.accordionDetails}>
                <FormGroup>
                  {groupedOptions[key].map((submission: any, idx: number) => (
                    <FormControlLabel
                      key={idx}
                      control={
                        <Checkbox
                          sx={styles.checkBox}
                          checked={submission.selected}
                          onChange={() => onOptionSelect(submission.category)}
                          onClick={(e) => e.stopPropagation()}
                        />
                      }
                      label={<Typography sx={styles.optionText}>{submission.category}</Typography>}
                      sx={styles.formControlLabel}
                    />
                  ))}
                </FormGroup>
              </AccordionDetails>
            )}
          </Accordion>
        ))}
      </Box>
    </Popover>
  );
};

export default React.memo(MeetingOptions);
