import React, { useCallback, forwardRef, memo } from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

const VirtuosoCustomScrollbar = forwardRef(({ style, children, ...props }: any, ref: any) => {
  const refSetter = useCallback(
    (scrollbarsRef: any) => {
      if (scrollbarsRef) {
        const customViewport = scrollbarsRef.osInstance().getElements().viewport;

        // Safely iterate through props and assign only valid attributes
        Object.entries(props).forEach(([key, value]) => {
          if (
            typeof value === 'string' ||
            typeof value === 'number' ||
            typeof value === 'boolean'
          ) {
            customViewport.setAttribute(key, String(value)); // Convert to string
          }
        });

        if (ref && typeof ref === 'object' && ref !== null) {
          // eslint-disable-next-line no-param-reassign
          (ref as React.MutableRefObject<HTMLDivElement>).current = customViewport;
        }
      }
    },
    [ref, props]
  );

  return (
    <OverlayScrollbarsComponent ref={refSetter} style={style}>
      {children}
    </OverlayScrollbarsComponent>
  );
});

export default memo(VirtuosoCustomScrollbar);
