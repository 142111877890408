import React, { useContext, useState } from 'react';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  IconButton,
  Link,
  Portal,
  Slide,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material';
import { TransitionProps } from '@material-ui/core/transitions';
import SendIcon from '@mui/icons-material/Send';
import Reg360Icon from '../../../../assets/svgs/CardsPage/Reg360Icon';
import { LABEL_COLOR } from '../../constants';
import styles, {
  chipsContainerStyle,
  indicationSectionStyle,
  snackBarContentStyle,
  snackBarStyle
} from './styles';
import CloseIcon from '../../../../assets/svgs/Icons/CloseIcon';
import PDFViewer from '../../../../components/PDFViewer';
import getDocumentMetadata from '../../../../components/Timeline/TimelineDocuments/getDocumentMetadata';

import GlobalStore from '../../../../store';
import GlobalActions from '../../../../store/actions';
import trimText from '../../../../utils/trimText';
import DocumentIcon from '../../../../assets/svgs/ChatRIA/DocumentIcon';

interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props, no-undef
  children?: React.ReactNode;
  index: string;
  value: string;
}

const Transition = (props: TransitionProps) => {
  // @ts-ignore
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide {...props} direction='left' />;
};
const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}>
      {value === index && children}
    </div>
  );
};

const TabView = ({ submissions }: any) => {
  const [value, setValue] = React.useState('');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs value={value} variant='scrollable' scrollButtons='auto' onChange={handleChange}>
        {submissions.map((item: any, index: number) => {
          const label = `${item.submission.toUpperCase()}`;
          return (
            <Tab
              key={label}
              wrapped
              label={label}
              id={`activity-tab-${index}`}
              aria-controls={`activity-tabpanel-${index}`}
            />
          );
        })}
      </Tabs>
      {submissions.map((item: any) => {
        return (
          <TabPanel key={`${item.submission.toUpperCase()}`} value={value} index={value}>
            <Box mt='24px'>
              <Typography variant='h6' lineHeight={1}>
                Indications
              </Typography>
            </Box>
            <Box sx={indicationSectionStyle}>
              <Typography variant='body2' fontSize='14px' mb='5px'>
                <b>Approval Date:</b>
                {item?.approval_date}
              </Typography>
              <Typography variant='caption'>{item.indications_and_usage_text || ''}</Typography>
            </Box>
          </TabPanel>
        );
      })}
    </>
  );
};

const ExpandedDrawer = ({ open, onClose, payload }: any) => {
  const labelKey = payload?.labels ? payload.labels[0]?.toLowerCase() : 'ema';
  const labelColor = LABEL_COLOR[labelKey];
  const [openDocument, setOpenDocument] = useState<boolean>(false);
  const [pdfPath, setPdfUrl] = useState<string>('');
  const [docTitle, setDocTitle] = useState<string>('');
  const { dispatch } = useContext(GlobalStore) as any;
  const openPDFInNewTab = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    link: string,
    s3Path: string
  ) => {
    e.stopPropagation();
    const parts = s3Path.split('/');
    const titleValue = parts[parts.length - 1];
    setDocTitle(titleValue || '');
    setPdfUrl(link);
    setOpenDocument(true);
  };

  const handleChatRia = ({ pdfUrl = '' }: { pdfUrl?: string }) => {
    const metadata = getDocumentMetadata(
      payload.source as string,
      payload ?? {},
      payload?.submissionData?.[0],
      'Labels'
    );
    dispatch({
      type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM,
      value: 'document'
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: payload.source
    });
    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: {
        blob: pdfUrl,
        url: pdfUrl,
        item: metadata,
        source: payload?.source,
        triggerReopenChatRia: false
      }
    });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
    setOpenDocument(false);
  };

  return (
    <Portal>
      {openDocument && (
        <Snackbar
          TransitionComponent={Transition}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={styles.snackBarContainer}
          open={openDocument}>
          <Stack flexDirection='column' sx={styles.pdfContainer}>
            <Box
              display='flex'
              alignItems='center'
              width='100%'
              justifyContent='space-between'
              mb='20px'>
              <Stack flex='display' alignItems='center' sx={{ pl: '15px', flexDirection: 'row' }}>
                {docTitle && (
                  <Tooltip title={docTitle}>
                    <Typography sx={styles.titleText}>{trimText(docTitle ?? '', 40)}</Typography>
                  </Tooltip>
                )}
                <Divider
                  orientation='vertical'
                  variant='middle'
                  flexItem
                  sx={{ p: '5px', height: 16 }}
                />{' '}
                <Tooltip title='Use Chat RIA to interact with the document'>
                  <Button
                    variant='text'
                    disabled={!pdfPath}
                    onClick={() =>
                      handleChatRia({
                        pdfUrl: pdfPath
                      })
                    }
                    sx={styles.chatRiaButton}>
                    Chat RIA
                    <SendIcon sx={styles.chatRiaIcon} />
                  </Button>
                </Tooltip>
              </Stack>

              <Box mr='20px'>
                <CloseIcon
                  onClick={() => {
                    setPdfUrl('');
                    setOpenDocument(false);
                  }}
                  sx={styles.closeIcon}
                />
              </Box>
            </Box>
            <Box id='pdfBox'>
              <PDFViewer fileUrl={pdfPath} defaultScale={1.35} />
            </Box>
          </Stack>
        </Snackbar>
      )}
      <Drawer
        anchor='right'
        hideBackdrop={false}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onClose();
          }
        }}
        open={open}
        sx={snackBarStyle}>
        <Stack direction='column' sx={snackBarContentStyle}>
          <Box position='absolute' top={2} right={2}>
            <IconButton
              aria-label='close'
              onClick={() => {
                setOpenDocument(false);
                onClose();
              }}>
              <Close sx={{ fontSize: '30px' }} />
            </IconButton>
          </Box>
          <Box
            mt='5px'
            display='flex'
            flexWrap='wrap'
            justifyContent='space-between'
            alignItems='center'>
            <Box display='flex'>
              <Typography
                sx={{
                  ...styles.identifier,
                  bgcolor: labelColor
                }}>
                {payload?.identifier}
              </Typography>
              <Typography
                sx={{
                  color: labelColor,
                  ...styles.title
                }}>
                {payload?.title?.toUpperCase()}
              </Typography>
            </Box>
            <Link
              href={`/regulatory360/core/${encodeURIComponent(payload.identifier)}/${
                payload.source
              }`}
              target='_blank'>
              <Tooltip title='Open Reg 360&deg;'>
                <Reg360Icon />
              </Tooltip>
            </Link>
          </Box>
          <Divider sx={{ mt: '4px' }} />
          <Stack direction='row' flexWrap='wrap' sx={chipsContainerStyle}>
            {payload &&
              payload?.metadata?.map((data: { key: string; value: string }) => (
                <Chip
                  key={data.key}
                  label={
                    <Tooltip title={`${data.key}: ${data.value}`}>
                      <Typography variant='body2'>{data.value}</Typography>
                    </Tooltip>
                  }
                />
              ))}
          </Stack>
          <Divider sx={{ m: 0 }} />
          {payload?.submissionData?.length > 1 ? (
            <TabView submission={payload?.submissionData} />
          ) : (
            <>
              <Box mt='24px' display='flex' alignContent='center' alignItems='center'>
                <Typography variant='h6' lineHeight={1}>
                  Indications
                </Typography>
                {payload?.submissionData?.[0]?.s3_url && (
                  <Tooltip title='Open PDF document'>
                    <Box
                      height='18px'
                      width='14px'
                      onClick={(e: any) => {
                        openPDFInNewTab(
                          e,
                          payload?.submissionData?.[0]?.s3_url,
                          payload?.submissionData?.[0]?.s3_path
                        );
                      }}>
                      <DocumentIcon sx={styles.documentIcon} />
                    </Box>
                  </Tooltip>
                )}
              </Box>
              <Box sx={indicationSectionStyle}>
                <Typography fontSize='14px' mb='5px'>
                  <b>Approval Date:</b> {payload?.submissionData?.[0]?.approval_date}
                </Typography>
                {payload?.source === 'us' && (
                  <Typography fontSize='14px' mb='5px'>
                    <b>Submissions:</b> {payload?.submissionData?.[0]?.submission}
                  </Typography>
                )}
                <Typography variant='caption'>
                  {payload?.submissionData?.[0]?.indications_and_usage_text ?? 'NA'}
                </Typography>
              </Box>
            </>
          )}
        </Stack>
      </Drawer>
    </Portal>
  );
};

export default ExpandedDrawer;
