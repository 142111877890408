import { memo } from 'react';

// mui v5
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import themev5 from '../../themev5';

import ChmpTabs from './components/Tabs';

// store
import useUpdateModule from '../../hooks/useUpdateModule';

// Provider
// import { EMAGuidanceStateProvider } from '../../store/EMAGuidance';

const Chmp = () => {
  useUpdateModule('chmp');

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <ChmpTabs />
    </ThemeProvider>
  );
};

export default memo(Chmp);
