const RightArrow = ({ active = false, height = '14px', width = '7px' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 8.81 16.121'>
    <path
      id='Path_488'
      data-name='Path 488'
      d='M1146.675,111.808l6.651,7,7.349-7'
      transform='translate(-110.748 1161.735) rotate(-90)'
      fill='none'
      stroke={!active ? '#b1bbe4' : '#fff'}
      stroke-linecap='round'
      stroke-linejoin='round'
      stroke-width='1.5'
    />
  </svg>
);

export default RightArrow;
