const styles: { [key: string]: any } = {
  purpleNumber: {
    minWidth: '50px',
    borderRadius: '4px',
    py: 0,
    px: 0.5,
    fontWeight: 700,
    bgcolor: 'blue.main',
    color: 'white.main'
  }
};

export default styles;
