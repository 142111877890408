import { memo, useEffect, useContext, useState, useCallback, MouseEvent } from 'react';
import { Stack, Typography, Divider, Link, Paper, Popper, Box } from '@mui/material';

import styles from './CompanionDiagnosticDevices.styles';

// API
import { regulatoryInformationAPI } from '../../../api/pages/Regulatory360';

// Store
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import Actions from '../../../store/RegulatoryInformation/actions';
import EmptyPage from '../../../components/EmptyPage/EmptyPage';
import Loading from '../../SearchResults/components/Loading';
import { LABEL_NAMES, APPLICATIONS_TO_IGNORE } from './constants';

const CompanionDiagnostics = () => {
  const { regulatoryState, regulatoryDispatch } = useContext(RegulatoryInformationStore);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [popoverAnchor, setPopoverAnchor] = useState<null | { element: HTMLElement; id: string }>(
    null
  );

  const handlePopoverOpen = (id: string) => (event: MouseEvent<HTMLElement>) => {
    setPopoverAnchor({ element: event.currentTarget, id });
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const data = await regulatoryInformationAPI(
          regulatoryState?.db,
          regulatoryState?.applicationDetails?.application_number,
          'companion_diagnostic_devices'
        );

        regulatoryDispatch({
          type: Actions.SET_COMPANION_DIAGNOSTIC_DEVICES,
          value: data?.data?.body?.companion_diagnostic_devices ?? []
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (
      regulatoryState?.companionDiagnosticDevices?.length < 1 &&
      regulatoryState?.applicationDetails?.application_number
    ) {
      getData();
    }
  }, [regulatoryState?.db, regulatoryState?.applicationDetails]);

  const getLink = (source: string, number: string, linkText: string) => {
    return (
      <Link href={`/regulatory360/core/${number}/${source}`} target='_blank' rel='noreferrer'>
        {linkText}
      </Link>
    );
  };

  const notAvailable = () => {
    return <Typography sx={styles.notAvailableText}>N/A</Typography>;
  };

  const getReg360Links = (applications: string[], source: string) => {
    if (!applications?.length) {
      return notAvailable();
    }

    return applications?.map(application => {
      const [number, ...info] =
        application?.split(' ')?.filter((item: string) => item?.length > 0) ?? [];
      const correctNumber = number?.split('/')?.[0];

      return (
        <div key={number}>
          {APPLICATIONS_TO_IGNORE.includes(number) ? (
            <span>{number}</span>
          ) : (
            getLink(source, correctNumber, number)
          )}
          <br />
          <span>{info?.join(' ')}</span>
          <br />
        </div>
      );
    });
  };

  const getDevice = useCallback((device: any) => {
    const sections = [
      {
        label: LABEL_NAMES.indication,
        content: device?.indication?.length > 0 ? device?.indication : notAvailable()
      },

      {
        label: LABEL_NAMES.biomarker,
        content: device?.biomarker?.length > 0 ? device?.biomarker : notAvailable()
      },
      {
        label: LABEL_NAMES.biomarkerDetails,
        content: device?.biomarker_details?.length > 0 ? device?.biomarker_details : notAvailable()
      },
      {
        label: LABEL_NAMES.sampleType,
        content: device?.sample_type?.length > 0 ? device?.sample_type : notAvailable()
      },
      {
        label: LABEL_NAMES.inCombinationWithApplications,
        content: getReg360Links(device?.in_combination_with_application, 'us')
      },
      {
        label: LABEL_NAMES.inCombinationWithDevices,
        content: getReg360Links(device?.in_combination_with_device, 'us-devices')
      },
      {
        label: LABEL_NAMES.groupOncologyProductsIndication,
        content:
          device?.group_oncology_products_indication?.length > 0
            ? device?.group_oncology_products_indication
            : notAvailable()
      },
      {
        label: LABEL_NAMES.manufacturer,
        content: device?.manufacturer?.length > 0 ? device?.manufacturer : notAvailable()
      }
    ];

    return sections;
  }, []);

  const showPopper = (content: any) => {
    if (!content) {
      return false;
    }

    if (typeof content === 'string') {
      return content.length > 55;
    }

    if (Array.isArray(content)) {
      return true;
    }

    return false;
  };

  if (isLoading) {
    return <Loading showTips={false} />;
  }

  return (
    <Stack sx={styles.container}>
      {regulatoryState?.companionDiagnosticDevices?.length > 0 ? (
        <Stack sx={styles.gridContainer}>
          <Stack sx={styles.labelsIndicatorWrapper}>
            {Object.keys(LABEL_NAMES)?.map((label: string, labelIndex) => (
              <Box key={label} sx={styles.section}>
                <Stack sx={styles.section}>
                  <Typography variant='subtitle2' sx={styles.sectionLabel}>
                    {LABEL_NAMES[label]}
                  </Typography>
                </Stack>
                {labelIndex !== Object.keys(LABEL_NAMES).length - 1 && (
                  <Divider sx={styles.sectionDivider} />
                )}
              </Box>
            ))}
          </Stack>
          <Stack sx={styles.companionDiagnosticDevicesWrapper}>
            {regulatoryState?.companionDiagnosticDevices?.map(
              (companionDiagnosticDevice: any, companionDiagnosticDeviceIndex) => {
                const deviceSections = getDevice(companionDiagnosticDevice);
                return (
                  <Stack
                    spacing={2}
                    sx={styles.sectionsWrapper}
                    key={companionDiagnosticDevice?.id}>
                    {deviceSections?.map((section, sectionIndex) => (
                      <Stack
                        onMouseEnter={handlePopoverOpen(
                          `cdx-${companionDiagnosticDeviceIndex}-${sectionIndex}`
                        )}
                        onMouseLeave={handlePopoverClose}
                        key={section.label}
                        sx={styles.sectionWrapper}>
                        <Stack sx={styles.sectionContentWrapper}>
                          <Box sx={styles.sectionText}>{section.content}</Box>
                        </Stack>

                        <Popper
                          open={
                            popoverAnchor?.id ===
                              `cdx-${companionDiagnosticDeviceIndex}-${sectionIndex}` &&
                            showPopper(section?.content)
                          }
                          anchorEl={popoverAnchor?.element}
                          placement='bottom-start'>
                          <Paper elevation={1} sx={styles.paper}>
                            <Typography variant='subtitle2' sx={styles.paperText}>
                              {section.content}
                            </Typography>
                          </Paper>
                        </Popper>

                        {sectionIndex !== deviceSections.length - 1 && (
                          <Divider sx={styles.sectionDivider} />
                        )}
                      </Stack>
                    ))}
                  </Stack>
                );
              }
            )}
          </Stack>
        </Stack>
      ) : (
        <Stack height={250}>
          <EmptyPage message='No companion diagnostic device available.' />
        </Stack>
      )}
    </Stack>
  );
};

export default memo(CompanionDiagnostics);
