import { createSvgIcon } from '@mui/material';

const SortIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
    <g clipPath='url(#clip0_4081_49139)'>
      <path
        d='M16.4999 17.25C16.4999 17.6647 16.1647 18 15.7499 18H12.6659C12.1514 18 11.6774 17.7203 11.4284 17.2703C11.1794 16.8202 11.1944 16.2705 11.4667 15.8355C11.4892 15.7987 14.8814 12 14.8814 12H12.0007C11.5859 12 11.2507 11.6648 11.2507 11.25C11.2507 10.8352 11.5859 10.5 12.0007 10.5H15.0847C15.5992 10.5 16.0724 10.779 16.3214 11.229C16.5704 11.679 16.5562 12.2287 16.2832 12.6652C16.2607 12.7012 16.2352 12.7358 16.2059 12.7673L12.8692 16.5007H15.7499C16.1647 16.5007 16.4999 16.8353 16.4999 17.25ZM16.4999 2.625V6.75C16.4999 7.16475 16.1647 7.5 15.7499 7.5C15.3352 7.5 14.9999 7.16475 14.9999 6.75V5.25H12.7499V6.75C12.7499 7.16475 12.4147 7.5 11.9999 7.5C11.5852 7.5 11.2499 7.16475 11.2499 6.75V2.625C11.2499 1.1775 12.4274 0 13.8749 0C15.3224 0 16.4999 1.1775 16.4999 2.625ZM14.9999 3.75V2.625C14.9999 2.00475 14.4952 1.5 13.8749 1.5C13.2547 1.5 12.7499 2.00475 12.7499 2.625V3.75H14.9999ZM7.71969 13.2803L5.24994 15.75V0.75C5.24994 0.33525 4.91469 0 4.49994 0C4.08519 0 3.74994 0.33525 3.74994 0.75V15.75L1.28019 13.2803C0.986943 12.987 0.512943 12.987 0.219693 13.2803C-0.0735567 13.5735 -0.0735567 14.0475 0.219693 14.3407L3.43944 17.5605C3.73194 17.853 4.11594 17.9993 4.49994 17.9993C4.88394 17.9993 5.26794 17.853 5.56044 17.5605L8.78019 14.3407C9.07344 14.0475 9.07344 13.5735 8.78019 13.2803C8.48694 12.987 8.01294 12.987 7.71969 13.2803Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_4081_49139'>
        <rect width='18' height='18' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'Sort'
);

export default SortIcon;
