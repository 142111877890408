const applNumberMixin = {
  color: 'white.main',
  borderRadius: '8px',
  padding: '3px 8px',
  fontSize: '12px',
  fontWeight: 400,
  width: 'max-content'
};

const nameMixin = {
  fontWeight: 700,
  fontSize: '16px',
  textTransform: 'uppercase',
  paddingLeft: '10px',
  whiteSpace: 'nowrap'
};

export const verticalDesignationCard: any = {
  width: 20,
  height: 20,
  right: -10,
  top: -5,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  backgroundColor: 'primary.main',
  position: 'absolute',
  color: 'white.main'
};
export const verticalDesignationCardContainer: any = {
  p: 0.5,
  width: '100%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  mt: 1,
  border: '1px solid',
  borderColor: 'gray.light',
  borderRadius: 2,
  position: 'relative',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: 'white.main',
    boxShadow: 1
  }
};

export const styles: any = {
  appNumRed: {
    ...applNumberMixin,
    backgroundColor: 'red.light'
  },
  appNumBlue: {
    ...applNumberMixin,
    backgroundColor: 'blue.berry'
  },
  appNumOrange: {
    ...applNumberMixin,
    backgroundColor: 'orange.main'
  },
  appNumGreen: {
    ...applNumberMixin,
    backgroundColor: 'green.accept'
  },
  appNumGray: {
    ...applNumberMixin,
    backgroundColor: 'gray.light'
  },
  appNumPink: {
    ...applNumberMixin,
    backgroundColor: '#DA70D6'
  },
  appNumYellow: {
    ...applNumberMixin,
    backgroundColor: 'orange.main'
  },
  nameYellow: {
    ...nameMixin,
    color: 'orange.main'
  },
  nameRed: {
    ...nameMixin,
    color: 'red.light'
  },
  nameBlue: {
    ...nameMixin,
    color: 'blue.berry'
  },
  nameOrange: {
    ...nameMixin,
    color: 'orange.main'
  },
  nameGreen: {
    ...nameMixin,
    color: 'green.accept'
  },
  namePink: {
    ...nameMixin,
    color: '#DA70D6'
  },
  nameGray: {
    ...nameMixin,
    color: 'gray.light'
  }
};
