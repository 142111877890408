import { createSvgIcon } from '@mui/material';

const SettingsIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'>
    <path
      d='M1.0712 12.7437C1.62264 13.7007 2.84554 14.0295 3.8026 13.4781C3.80326 13.4777 3.80389 13.4773 3.80454 13.477L4.1012 13.3056C4.6612 13.7847 5.30489 14.1563 5.99985 14.4016V14.7437C5.99985 15.8482 6.89529 16.7437 7.99985 16.7437C9.10442 16.7437 9.99986 15.8482 9.99986 14.7437V14.4017C10.6949 14.1559 11.3386 13.7839 11.8985 13.3043L12.1965 13.4763C13.1538 14.0286 14.3776 13.7003 14.9299 12.743C15.4821 11.7857 15.1538 10.5619 14.1965 10.0096L13.9005 9.83896C14.0338 9.11393 14.0338 8.37068 13.9005 7.64562L14.1965 7.47496C15.1538 6.92268 15.4821 5.69893 14.9299 4.74162C14.3776 3.78434 13.1538 3.456 12.1965 4.00828L11.8999 4.17962C11.3393 3.70109 10.6952 3.33021 9.99986 3.08565V2.74365C9.99986 1.63909 9.10442 0.743652 7.99985 0.743652C6.89529 0.743652 5.99985 1.63909 5.99985 2.74365V3.08565C5.30479 3.33137 4.66107 3.70337 4.1012 4.183L3.8032 4.01034C2.84589 3.45803 1.62214 3.78637 1.06985 4.74365C0.517573 5.70093 0.845886 6.92471 1.8032 7.477L2.0992 7.64765C1.96595 8.37268 1.96595 9.11593 2.0992 9.841L1.8032 10.0117C0.848542 10.5654 0.521292 11.7868 1.0712 12.7437ZM7.99985 6.077C9.4726 6.077 10.6665 7.2709 10.6665 8.74365C10.6665 10.2164 9.4726 11.4103 7.99985 11.4103C6.5271 11.4103 5.3332 10.2164 5.3332 8.74365C5.3332 7.2709 6.5271 6.077 7.99985 6.077Z'
      fill='currentColor'
    />
  </svg>,
  'SettingsIcon'
);

export default SettingsIcon;
