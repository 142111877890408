const styles = {
  insightsText: {
    fontWeight: '600',
    fontSize: '1.125rem',
    color: '#464255',
    textAlign: 'start',
    marginTop: '1vh'
  },
  addToReportText: {
    color: '#3AB09E',
    cursor: 'pointer',
    fontFamily: 'Nunito Sans',
    fontWeight: '400',
    fontSize: '14.96px',
    marginTop: '1vh',
    marginBottom: '2vh'
  }
};

export default styles;
