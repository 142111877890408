import { createSvgIcon } from '@mui/material';

const FilterFilledIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
    <g clipPath='url(#clip0_4081_48990)'>
      <path
        d='M10.5 18C10.3377 18 10.1798 17.9474 10.05 17.85L7.04999 15.6C6.95684 15.5301 6.88124 15.4396 6.82917 15.3354C6.77709 15.2313 6.74999 15.1164 6.74999 15V10.785L1.48799 4.86525C1.11428 4.44366 0.87027 3.92311 0.785295 3.36618C0.700321 2.80924 0.777994 2.23961 1.00898 1.72576C1.23996 1.21191 1.61443 0.775694 2.08737 0.469549C2.56031 0.163405 3.1116 0.000355814 3.67499 0L14.325 0C14.8883 0.000660788 15.4395 0.163979 15.9122 0.47033C16.385 0.776681 16.7592 1.21303 16.9899 1.72694C17.2207 2.24085 17.2981 2.81046 17.2129 3.36731C17.1277 3.92416 16.8836 4.44457 16.5097 4.866L11.25 10.785V17.25C11.25 17.4489 11.171 17.6397 11.0303 17.7803C10.8897 17.921 10.6989 18 10.5 18Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_4081_48990'>
        <rect width='18' height='18' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'FilterFilled'
);

export default FilterFilledIcon;
