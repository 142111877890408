const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    p: '24px 2px 0px 24px',
    height: '100vh',
    overflowY: 'auto',
    bgcolor: 'white.background'
  },
  clearText: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  closeIcon: {
    cursor: 'pointer'
  },
  title: {
    fontWeight: 'bold',
    cursor: 'pointer',
    paddingLeft: '16px'
  },
  btn: {
    marginTop: 1,
    padding: 2,
    width: 10,
    height: 4,
    fontSize: 14,
    fontFamily: 'Mulish',
    textTransform: 'capitalize',
    color: 'gray.darkVariant',
    backgroundColor: 'gray.background',
    borderRadius: 2.5
  },
  selectedOptionsContainer: {
    marginTop: 1,
    p: 1,
    borderRadius: 18,
    alignItems: 'center',
    backgroundColor: 'primary.backgroundDark'
  },
  selectedOptionsContainerCards: {
    marginTop: 1,
    p: 1,
    borderRadius: 18,
    alignItems: 'center',
    backgroundColor: 'gray.background'
  },
  selectedFilter: {
    marginTop: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pl: 1,
    pr: 1,
    width: 160,
    height: 35,
    borderRadius: 18,
    fontWeight: '600',
    color: 'gray.darkVariant',
    backgroundColor: 'primary.backgroundDark'
  },
  popoverBox: {
    height: '100%',
    maxHeight: 500,
    overflowY: 'auto'
  },
  scrollContainer: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '7px'
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#c1c1c1',
      borderRadius: '5px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555'
    }
  }
};

export default styles;
