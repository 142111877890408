const numberMixin: { [key: string]: any } = {
  paddingTop: 0.5,
  paddingBottom: 0.5,
  paddingLeft: 2,
  paddingRight: 2,
  borderRadius: 2
};

const textMixin: { [key: string]: any } = {
  fontWeight: 'bold',
  textTransform: 'uppercase'
};

const styles: { [key: string]: any } = {
  skeletonCard: {
    marginTop: 1,
    width: '34vh',
    height: '29vh',
    borderRadius: 16,
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  cardGrid: {
    pl: {
      xs: 2,
      sm: 2,
      lg: 2,
      xl: 3
    },
    pr: {
      xs: 2,
      sm: 2,
      lg: 2,
      xl: 3
    },
    pt: { xs: 2, sm: 2, lg: 1, xl: 1 },
    pb: { xs: 2, sm: 2, lg: 1, xl: 1 }
    // border:'1px solid red'
  },
  cardContainer: {
    p: 2,
    bgcolor: 'white.main',
    borderRadius: 6,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  cardZoom: {
    transform: 'scale(1.1)'
  },
  redNumber: {
    // width: '40%',
    ...numberMixin,
    bgcolor: 'red.main',
    color: 'white.main'
  },
  purpleNumber: {
    ...numberMixin,
    bgcolor: 'blue.main',
    color: 'white.main'
  },
  greenNumber: {
    ...numberMixin,
    bgcolor: 'green.accept',
    color: 'white.main'
  },
  grayNumber: {
    ...numberMixin,
    bgcolor: 'gray.main',
    color: 'white.main'
  },
  orchidNumber: {
    ...numberMixin,
    bgcolor: '#DA70D6',
    color: 'white.main'
  },
  yellowNumber: {
    ...numberMixin,
    bgcolor: 'orange.main',
    color: 'white.main'
  },
  redText: {
    color: 'red.main',
    ...textMixin
  },
  orchidText: {
    color: '#DA70D6',
    ...textMixin
  },
  yellowText: {
    color: 'orange.main',
    ...textMixin
  },
  purpleText: {
    color: 'blue.main',
    ...textMixin
  },
  greenText: {
    color: 'green.accept',
    ...textMixin
  },
  grayText: {
    color: 'gray.main',
    ...textMixin
  },
  metadata: {
    color: 'black.lightVariant'
    // fontWeight: 'bold',
    // opacity: 0.8
  },
  headerText: {
    color: 'gray.darkText'
  },
  actionBtns: {
    textTransform: 'capitalize',
    bgcolor: 'primary.backgroundLight',
    color: 'primary.dark',
    fontWeight: 'bold',
    '&:hover': {
      color: 'white.main'
    }
  }
};

export default styles;
