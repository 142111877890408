import {
  Autocomplete,
  Box,
  Chip,
  DialogActions,
  ListItem,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import React, { useContext, useEffect, useMemo } from 'react';
import DialogContent from '@mui/material/DialogContent';
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider';
import { createGroup, getUserListForGroup } from '../../../api/services/Moxo';
import GlobalActions from '../../../store/actions';
import GlobalStore from '../../../store';
import AuthContext from '../../../store/Auth/AuthProvider';
import { CheckboxUncheckedIcon, CheckIcon } from '../../../assets/svgs/Icons';
import styles from './styles/CreateGroup.styles';
import AngleDownIcon from '../../../assets/svgs/Icons/AngleDownIcon';
import { MAX_GROUP_NAME_LENGTH } from './constants';

interface MemberList {
  id: string;
  name: string;
  email: string;
}

const CreateNewGroup = ({ closeCreateGroupDialog }: { closeCreateGroupDialog: any }) => {
  const { dispatch } = useContext(GlobalStore) as any;
  const { currentUser } = useContext(AuthContext);

  const [groupName, setGroupName] = React.useState<string>('');
  const [members, setMembers] = React.useState<Array<MemberList>>([]);
  const [memberList, setMemberList] = React.useState<Array<MemberList>>([]);
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [fetchingUsers, setFetchingUsers] = React.useState<boolean>(false);
  const handleGroupNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupName(e.target.value);
  };
  const fetchUsers = async () => {
    try {
      setFetchingUsers(true);
      const { status, data: response } = await getUserListForGroup();
      if (status === 200) {
        const { body: listOfMembers }: { body: MemberList[] } = response;
        setMemberList(listOfMembers);
      }
      setFetchingUsers(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setFetchingUsers(false);
    }
  };
  const handleCreateGroup = async () => {
    try {
      setProcessing(true);
      const payload = {
        group_name: groupName,
        user_list: members
      };
      const response = await createGroup(payload);
      if (response.status === 200) {
        setGroupName('');
        setMembers([]);
        closeCreateGroupDialog();
        dispatch({
          type: GlobalActions.SET_ALERT,
          value: { status: true, message: `Group created`, color: 'success' }
        });
      } else {
        throw new Error('Failed to create group');
      }
      setProcessing(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setProcessing(false);
      dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: `Failed to create group` }
      });
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const membersToDisplay = useMemo(() => {
    return memberList
      .filter((member: MemberList) => {
        if (currentUser?.sub) {
          return member.id !== currentUser?.sub;
        }
        return member.email !== currentUser?.email;
      })
      .sort((a: MemberList, b: MemberList) => a.name.localeCompare(b.name));
  }, [memberList, currentUser]);

  return (
    <>
      <Divider sx={styles.divider} />
      <DialogContent sx={styles.paper}>
        <Stack spacing={2}>
          <Box>
            <Typography sx={styles.inputLabel}>Group Name</Typography>
            <TextField
              autoFocus
              placeholder='Enter group name'
              id='group-name'
              type='text'
              fullWidth
              variant='outlined'
              value={groupName}
              onChange={handleGroupNameChange}
              sx={styles.groupNameInput}
              InputProps={{
                sx: styles.groupNameInputProp
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{ maxLength: MAX_GROUP_NAME_LENGTH }}
              error={groupName?.length >= MAX_GROUP_NAME_LENGTH}
              helperText={
                groupName?.length >= MAX_GROUP_NAME_LENGTH &&
                `Maximum of ${MAX_GROUP_NAME_LENGTH} characters allowed.`
              }
            />
          </Box>
          <Box>
            <Typography sx={styles.inputLabel}>Members</Typography>
            <Autocomplete
              multiple
              id='tags-outlined'
              value={members}
              disableCloseOnSelect
              clearIcon={null}
              popupIcon={<AngleDownIcon sx={{ fontSize: 16, m: 0.5, color: 'gray.500' }} />}
              onChange={(event, newValue: any) => {
                if (!processing) {
                  setMembers(newValue);
                }
              }}
              loading={fetchingUsers}
              options={membersToDisplay}
              getOptionLabel={option => {
                return option.name;
              }}
              renderInput={params => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant='outlined'
                  placeholder='Add members'
                  sx={styles.groupNameInput}
                  InputProps={{
                    ...params.InputProps,
                    sx: styles.groupNameInputProp
                  }}
                />
              )}
              componentsProps={{
                paper: {
                  sx: styles.memberSuggestionPaper
                }
              }}
              renderOption={(props, option) => {
                return (
                  <ListItem
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    key={option.id || option.email}
                    disablePadding
                    sx={{ bgcolor: 'inherit !important' }}>
                    <Box display='flex' alignItems='center' pl={1}>
                      <Box display='flex'>
                        {/* eslint-disable-next-line react/prop-types */}
                        {props['aria-selected'] ? (
                          <CheckIcon sx={{ ...styles.checkbox, color: 'primary.600' }} />
                        ) : (
                          <CheckboxUncheckedIcon sx={styles.checkbox} />
                        )}
                      </Box>
                      <Typography component='span' sx={styles.userName}>
                        {option.name}
                      </Typography>
                    </Box>
                  </ListItem>
                );
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant='outlined'
                    label={option.name}
                    sx={styles.selectedChip}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...getTagProps({ index })}
                    key={option.id || option.email}
                  />
                ))
              }
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions disableSpacing sx={{ p: 0 }}>
        <Stack width='100%' spacing={3}>
          <Divider sx={styles.divider} />
          <Box display='flex' justifyContent='end' pb={3} pr={4}>
            <LoadingButton
              onClick={handleCreateGroup}
              loading={processing}
              loadingPosition='end'
              variant='contained'
              size='small'
              color='primary'
              disabled={!groupName || members.length === 0}
              sx={styles.createGroupButton}>
              Create Group
            </LoadingButton>
          </Box>
        </Stack>
      </DialogActions>
    </>
  );
};
export default React.memo(CreateNewGroup);
