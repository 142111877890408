import { isArray } from 'lodash';

const INDEX_ID_BY_SOURCE: { [key: string]: string } = {
  us: 'application_number',
  eu: 'product_number',
  'us-devices': 'application_number',
  ct: 'nct_id',
  ca: 'vin',
  hpra: 'product_number',
  jp: 'yj_code',
  euct: 'euct_id',
  hma: 'vin_number',
  ema: 'product_number'
};

const traverseTree = (tree: any, atcCodeCount: any) => {
  let count = 0;
  let applications: Array<string> = [];
  Object.keys(atcCodeCount).forEach((atcCode: string) => {
    if (atcCode.startsWith(tree.id)) {
      applications = Array.from(new Set([...atcCodeCount[atcCode].id, ...applications]));
      count = applications.length;
    }
  });
  // eslint-disable-next-line no-param-reassign
  tree.count = count;
  // eslint-disable-next-line no-param-reassign
  tree.applications = applications;
  if (tree.children && tree.children.length > 0) {
    // eslint-disable-next-line no-param-reassign
    tree.children.map((child: any) => {
      return traverseTree(child, atcCodeCount);
    });
  }

  return tree;
};

const mapSynonymsWithMatchedResults = (tree: any, applications: any) => {
  const atcCodeCount: any = {};
  applications.forEach((result: any) => {
    if (isArray(result.atc_code)) {
      // eslint-disable-next-line no-param-reassign
      result.atc_code = result.atc_code.join(',');
    }
    result.atc_code?.split(',').forEach((atcCode: string) => {
      // eslint-disable-next-line no-param-reassign
      atcCode = atcCode.trim();
      if (!atcCodeCount[atcCode]) {
        atcCodeCount[atcCode] = {
          count: 1,
          id: [result[INDEX_ID_BY_SOURCE[result?.source_index]]]
        };
      } else {
        const ids = Array.from(
          new Set([...atcCodeCount[atcCode].id, result[INDEX_ID_BY_SOURCE[result?.source_index]]])
        );
        atcCodeCount[atcCode] = {
          count: ids.length,
          id: ids
        };
      }
    });
  });
  return traverseTree(tree, atcCodeCount);
};

const getATCTreeCount = (trees: any, applications: any) => {
  const treesWithCount: any = [];

  (trees || []).forEach((tree: any) => {
    const treeWithCount = mapSynonymsWithMatchedResults(tree, applications);
    treesWithCount.push(treeWithCount);
  });
  return treesWithCount;
};

export default getATCTreeCount;
