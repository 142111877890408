import React from 'react';
import Box from '@mui/material/Box';
import { GridOverlay } from '@mui/x-data-grid-pro';

const DatagridNoRowsOverlay: React.FC<any> = ({ message = 'No rows' }: any) => {
  return (
    <GridOverlay>
      <Box>{message}</Box>
    </GridOverlay>
  );
};

export default React.memo(DatagridNoRowsOverlay);
