/* eslint-disable no-underscore-dangle */
import React, { useState, useCallback, useContext, useMemo } from 'react';

// MU
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';

// Custom Components
import VivproDatagrid from '../../../components/Datagrid';

// store
import store from '../../../store/CanadaConsultation';
import actions from '../../../store/CanadaConsultation/actions';
import Search from './Search';

import { getFileName } from '../../../helpers/getFileName';
import useSearchedTableData from '../../../hooks/useSearchedTableData';
// import PDFPreview from '../../Guidance/components/PDFPreview';
import PDFCellComponent from '../../Guidance/components/PDFCellRenderer';

// constants
import { CANADA_CONSULTATION_COLUMN, CSV_FILENAME, SEARCH_IGNORE_FIELDS } from '../const';
import getTableColumnWithCellRenderers from '../../../utils/getTableColumnWithCellRenders';
import styles from '../../Guidance/styles/Details.styles';

const CanadaConsultationDetails = () => {
  const datagridMessage = 'Loading...';
  // eslint-disable-next-line no-unused-vars
  const [pdfUrl, setPdfUrl] = useState('');
  const { CanadaConsultationState, CanadaConsultationDispatch } = useContext(store);

  const { searchedData } = useSearchedTableData({
    tableData: CanadaConsultationState.tableData,
    search: CanadaConsultationState.search,
    ignoreFields: SEARCH_IGNORE_FIELDS
  });

  const handlePDFOpenClick = useCallback((pdfLink: any) => {
    setPdfUrl(pdfLink);
  }, []);

  const pdfCellRenderer = useCallback(
    ({ value, rowData }: any) => (
      <PDFCellComponent value={value} rowData={rowData} onPDFButtonClick={handlePDFOpenClick} />
    ),
    [handlePDFOpenClick]
  );

  const tableColumns = useMemo(
    () =>
      getTableColumnWithCellRenderers(CANADA_CONSULTATION_COLUMN, [
        { field: 's3_path', cellRenderer: pdfCellRenderer }
      ]),
    [pdfCellRenderer]
  );

  const handleComparisonCheckbox = useCallback(
    async (row: any) => {
      if (
        CanadaConsultationState.documentsToCompare.find(
          (doc: any) => doc._id === row.consultation_id
        )
      ) {
        CanadaConsultationDispatch({
          type: actions.REMOVE_DOCUMENT_TO_COMPARE,
          value: row.consultation_id
        });
      } else if (row.consultation_doc_title.length > 0) {
        CanadaConsultationDispatch({
          type: actions.SET_DOCUMENT_TO_COMPARE,
          value: { doc_title: row.consultation_doc_title[0], _id: row.consultation_id }
        });
      }
    },
    [CanadaConsultationState.tableData, CanadaConsultationState.documentsToCompare]
  );

  const renderableTableColumns = useMemo(() => {
    const renderableColumns = [...tableColumns];
    if (CanadaConsultationState.compareEnabled) {
      renderableColumns.unshift({
        field: 'consultation_id',
        headerName: 'Select',
        width: 100,
        headerClassName: 'table-header',
        cellClassName: 'ria-MuiDataGrid-cell',
        disableExport: true,
        renderCell: (params: any) => (
          <Tooltip
            title={
              CanadaConsultationState.documentsToCompare.length === 2 &&
              !CanadaConsultationState.documentsToCompare.find(
                (doc: any) => doc._id === params.value
              )
                ? 'Selection limit reached'
                : ''
            }>
            <Box key={params.value}>
              <Radio
                disabled={
                  (CanadaConsultationState.documentsToCompare.length === 2 &&
                    !CanadaConsultationState.documentsToCompare.find(
                      (doc: any) => doc._id === params.value
                    )) ||
                  params.row.document_count === 0
                }
                checked={
                  !!CanadaConsultationState.documentsToCompare.find(
                    (doc: any) => doc?._id === params.value
                  )
                }
                onClick={() => handleComparisonCheckbox(params.row)}
              />
            </Box>
          </Tooltip>
        )
      });
    }

    return renderableColumns;
  }, [
    CanadaConsultationState.documentsToCompare,
    tableColumns,
    CanadaConsultationState.compareEnabled,
    CanadaConsultationState.tableData
  ]);

  return (
    <Stack
      sx={CanadaConsultationState.compareEnabled ? styles.rootWithComparisonEnabled : styles.root}>
      {CanadaConsultationState.compareEnabled && (
        <Box paddingTop={1} paddingBottom={1} display='flex' alignItems='center'>
          <Search />
        </Box>
      )}
      <VivproDatagrid
        rows={searchedData}
        columnsMapping={renderableTableColumns}
        rowId='consultation_id'
        csvFileName={getFileName(CSV_FILENAME)}
        noRowMessage={datagridMessage}
        loading={CanadaConsultationState.loading}
      />
    </Stack>
  );
};

export default React.memo(CanadaConsultationDetails);
