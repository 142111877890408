const styles = {
  container: {
    display: 'flex',
    margin: '0.25rem 0 -4px 0',
    backgroundColor: 'inherit',
    fontFamily: 'Mulish'
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    textTransform: 'capitalize',
    lineHeight: 1.25,
    color: 'grey.800'
  },
  subTitle: {
    fontFamily: 'Mulish',
    fontSize: '11px',
    fontWeight: 500,
    color: 'grey.800'
  },
  anchorLink: {
    cursor: 'pointer',
    fontSize: '11px',
    color: '#0076D6',
    textDecoration: 'underline',
    fontWeight: 700,
    fontFamily: 'Mulish',
    display: 'inline',
    marginLeft: '8px'
  },
  searchQueryText: {
    fontWeight: 800,
    fontFamily: 'Mulish',
    display: 'inline'
  }
};

export default styles;
