import makeRequest from '../client';

/**
 * Function to Create a User.
 * @param {string} requestOptions
 */
const getCreateUser = requestOptions =>
  makeRequest(`/clignosis/create-user`, 'POST', requestOptions);

export default getCreateUser;
