import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  yes: {
    background: theme.palette.adcomm.objectiveGreen
  },
  no: {
    background: '#FF5A48'
  },
  abstain: {
    background: '#F8B518',
    borderRadius: '0px 10px 10px 0px'
  },
  Bar: {
    height: '10px',
    width: '70%'
  }
}));
