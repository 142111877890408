import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  LinearProgress,
  OutlinedInput,
  Slide,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useCallback } from 'react';
import { KeyboardArrowDownRounded } from '@mui/icons-material';

import {
  cancelRequestButton,
  chatContainer,
  formContainer,
  inputBox,
  seeMoreButton,
  suggestionButtonStyle
} from '../styles/styles';
import SendIcon from '../../../Images/svgs/sendIcon';
import ChatBubbles from './ChatBubbles';
import DownloadReport from './DownloadReport';

import SuggestionButton from '../../../assets/svgs/ChatRIA/SuggestionButton';

const ChatSection = ({
  chatFlow,
  seeMore,
  setSeeMore,
  queryLoading,
  handleSubmitQuery,
  queryText,
  handleChangeQuery,
  initialised,
  handleAskHelp,
  currentSection,
  brandName,
  handleAddToReportAction,
  handleVerifyAction,
  verifyAnswer,
  processingReport,
  downloadReport,
  showDownloadReport,
  reportCount,
  clearReport,
  handleSetHelpQuery,
  source,
  handleCancelCall,
  currentApplicationNumber,
  reportData,
  isARIAMode,
  fetchReportTitle,
  loadingReportTitle,
  handleNewSection,
  latestChatId,
  applicationMeta,
  documentReferencePages,
  setOpenApplicationOption,
  addedToReportList,
  reportLoading
}: any) => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const lastMessageRef = React.useRef<HTMLDivElement>(null);
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const handleScroll = () => {
    const parentEl = scrollRef.current;
    const childEl = lastMessageRef.current;

    if (!parentEl || !childEl) return;
    // Get the bounding rectangles of the parent and child elements
    const parentBounding = parentEl.getBoundingClientRect();
    const childBounding = childEl.getBoundingClientRect();

    // Calculate the top and bottom positions of the child element relative to the parent element
    // const childTop = childBounding.top;
    const childBottom = childBounding.bottom;

    // Calculate the top and bottom positions of the parent element relative to the viewport
    const parentTop = parentBounding.top;
    const parentBottom = parentBounding.bottom;

    const viewportHeight = parentEl.scrollHeight;

    // Check if the child element is completely within the parent element and the parent element is within the viewport
    if (childBottom <= parentBottom && parentTop <= viewportHeight) {
      setSeeMore(false);
    } else {
      setSeeMore(true);
    }
  };

  const handleScrollToBottom = useCallback(() => {
    const lastMessage = document.getElementById(`chat-bubble-${latestChatId.chatId}`);
    if (scrollRef.current && lastMessage) {
      if (latestChatId.isNew) {
        scrollRef.current.scrollTo({
          top: lastMessage.clientHeight + lastMessage.offsetTop,
          behavior: 'smooth'
        });
      } else {
        scrollRef.current.scrollTo({
          top: lastMessage.offsetTop - 200,
          behavior: 'smooth'
        });
      }
    }
  }, [latestChatId]);
  const handleScrollToLastMessage = useCallback(() => {
    if (scrollRef.current && lastMessageRef.current) {
      scrollRef.current.scrollTo({
        top: lastMessageRef.current.clientHeight + lastMessageRef.current.offsetTop,
        behavior: 'smooth'
      });
    }
  }, [lastMessageRef]);

  React.useEffect(() => {
    handleScrollToBottom();
  }, [chatFlow]);
  const fieldDisabled = queryLoading || !initialised;
  return (
    <Stack direction='column' height='90%' id='chat-ria-container'>
      <Box ref={scrollRef} sx={chatContainer} onScroll={handleScroll}>
        <ChatBubbles
          applicationMeta={applicationMeta}
          isDefault
          currentSection={currentSection}
          isARIAMode={isARIAMode}
          handleNewSection={handleNewSection}
          source={source}
          brandName={brandName}
          documentReferencePages={documentReferencePages}
          setOpenApplicationOption={setOpenApplicationOption}
          addedToReportList={addedToReportList}
          reportLoading={reportLoading}
        />
        {chatFlow?.map((items: any, index: number): any => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={`${index}-chat-bot`}>
              {index === chatFlow.length - 1 && <Box ref={lastMessageRef} height='1px' />}
              <ChatBubbles
                disableChatActions={queryLoading && index === chatFlow.length - 1}
                source={source}
                isDefault={false}
                items={items}
                queryLoading={queryLoading}
                currentSection={currentSection}
                handleAddToReportAction={handleAddToReportAction}
                handleVerifyAction={handleVerifyAction}
                verifyAnswer={verifyAnswer}
                handleSetHelpQuery={handleSetHelpQuery}
                currentApplication={currentApplicationNumber}
                isARIAMode={isARIAMode}
                handleNewSection={handleNewSection}
                bubbleIndex={index}
                brandName={brandName}
                documentReferencePages={documentReferencePages}
                setOpenApplicationOption={setOpenApplicationOption}
                addedToReportList={addedToReportList}
                reportLoading={reportLoading}
              />
            </React.Fragment>
          );
        })}
      </Box>
      <Box position='relative'>
        <Stack
          direction='row'
          justifyContent='center'
          sx={{
            width: seeMore ? '100%' : 0,
            ...seeMoreButton
          }}>
          <Button
            variant='text'
            endIcon={<KeyboardArrowDownRounded />}
            onClick={handleScrollToLastMessage}
            sx={{
              visibility: !queryLoading ? 'visible' : 'hidden'
            }}>
            <Typography variant='body1'>Latest messages</Typography>
          </Button>
        </Stack>
        <Slide direction='up' in={queryLoading} mountOnEnter unmountOnExit>
          <Stack direction='column' alignItems='center' sx={cancelRequestButton}>
            <Button
              onClick={handleCancelCall}
              variant='contained'
              sx={{
                color: 'white.main',
                textTransform: 'none'
              }}>
              <Typography variant='body1'>Cancel this query... </Typography>
            </Button>
          </Stack>
        </Slide>
        <LinearProgress
          color='primary'
          sx={{
            width: '90%',
            ml: 'auto',
            mr: 'auto',
            mb: 1,
            visibility: queryLoading ? 'visible' : 'hidden'
          }}
        />
        <Stack direction='row' alignItems='center' sx={formContainer}>
          <form
            onSubmit={async e => {
              inputRef.current?.focus();
              await handleSubmitQuery(e);
              inputRef.current?.focus();
            }}
            ref={formRef}>
            <FormControl variant='filled' fullWidth>
              <OutlinedInput
                multiline
                maxRows={3}
                id='query'
                type='text'
                value={queryText}
                required
                disabled={fieldDisabled}
                onChange={handleChangeQuery}
                autoFocus
                onKeyDown={e => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    formRef.current?.requestSubmit();
                  }
                }}
                inputRef={inputRef}
                sx={inputBox}
                endAdornment={
                  <InputAdornment position='end'>
                    <Button
                      id='input-box-chatRIA'
                      disabled={fieldDisabled}
                      aria-label='submit query'
                      type='submit'
                      sx={{ minWidth: '24px' }}>
                      <SendIcon sx={{ fontSize: '24px' }} />
                    </Button>
                  </InputAdornment>
                }
                placeholder='Type your question here to chat with RIA'
              />
            </FormControl>
          </form>
          <Box sx={{ width: '30px', pl: '8px' }}>
            <Slide direction='up' in={initialised}>
              <IconButton disabled={fieldDisabled} onClick={handleAskHelp}>
                <Tooltip title='Need suggestions?'>
                  <SuggestionButton sx={suggestionButtonStyle} />
                </Tooltip>
              </IconButton>
            </Slide>
          </Box>
          <DownloadReport
            showDownloadReport={showDownloadReport}
            reportCount={reportCount}
            processingReport={processingReport}
            downloadReport={downloadReport}
            clearReport={clearReport}
            reportData={reportData}
            fetchReportTitle={fetchReportTitle}
            loadingReportTitle={loadingReportTitle}
          />
        </Stack>
      </Box>
    </Stack>
  );
};
export default React.memo(ChatSection);
