import Switch, { SwitchProps } from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

interface IconProps {
  loading: boolean;
}

interface SwitchWithLoaderProps {
  loading: boolean;
}

const Icon = ({ loading }: IconProps) => (
  <Box
    component='div'
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '13.5px',
      height: '13.5px',
      borderRadius: '50%',
      backgroundColor: 'gray.0',
      boxShadow: '0px 0.75px 3px 0px #00000066'
    }}>
    {loading && <CircularProgress size={14} color='primary' thickness={6} />}
  </Box>
);

const SwitchWithLoader = ({ loading, ...switchProps }: SwitchWithLoaderProps & SwitchProps) => {
  return (
    <Switch
      checkedIcon={<Icon loading={loading} />}
      icon={<Icon loading={loading} />}
      disabled={loading}
      size='small'
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...switchProps}
      sx={{
        '& .MuiSwitch-switchBase': {
          padding: 0,
          transform: `translate(5px, 5.25px)`,
          '&.Mui-checked': {
            transform: `translate(16px, 5.25px)`
          },
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: 'primary.600',
            opacity: 1
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            backgroundColor: 'gray.300',
            opacity: 1
          }
        },
        '& .MuiSwitch-track': {
          marginY: 'auto',
          height: '9px',
          width: '21px',
          borderRadius: '5.25px',
          backgroundColor: 'gray.500',
          opacity: 1
        },
        '& .MuiSwitch-thumb': {
          marginY: 'auto'
        }
      }}
    />
  );
};

export default SwitchWithLoader;
