import palette from '../../../themev5/palette';
import typography from '../../../themev5/typography';

const styles = {
  root: {
    m: 2
  },
  rootAlternate: {
    mt: 0.5
  },
  filterChips: {
    mt: 1,
    display: 'wrap'
  },
  closeIcon: {
    fontSize: typography.subtitle2.fontSize
  },
  clearText: {
    cursor: 'pointer',
    fontWeight: 'bold',
    color: 'primary.main',
    '&:hover': {
      color: 'primary.medium'
    }
  },
  selectedOptionsContainerCards: {
    mr: 1.5,
    mb: 1.5,
    px: 1,
    py: 0.5,
    borderRadius: 2,
    alignItems: 'center',
    backgroundColor: 'gray.background'
  },
  filterButton: {
    textTransform: 'none',
    backgroundColor: palette.white.smoke,
    padding: '3px 10px',
    borderRadius: '10px',
    boxShadow: 0,
    m: 0,
    mr: 1,
    paddingLeft: '1.5rem',
    color: palette.black.light,
    fontFamily: 'Nunito Sans',
    fontWeight: '400',
    minWidth: 'max-content',

    fontSize: typography.subtitle1.fontSize,
    '&:hover': {
      backgroundColor: palette.white.aliceBlue,
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: palette.white.aliceBlue
    },
    '&:focus': {
      boxShadow: 0
    }
  },
  closeButton: {
    textTransform: 'none',
    backgroundColor: palette.gray.background,
    px: 1,
    py: 0.5,
    borderRadius: 2,
    alignItems: 'center',
    boxShadow: 0,
    color: palette.black.light,
    fontFamily: 'Nunito Sans',
    fontWeight: '400',
    fontSize: typography.subtitle2.fontSize,
    '&:hover': {
      backgroundColor: palette.gray.border,
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: palette.gray.border
    },
    '&:focus': {
      boxShadow: 0
    }
  },
  closeButtonAlternate: {
    textTransform: 'none',
    backgroundColor: palette.gray.background,
    px: 1,
    py: 0.5,
    mb: 1,
    mr: 1,
    borderRadius: 2,
    alignItems: 'center',
    boxShadow: 0,
    color: palette.black.light,
    fontFamily: 'Nunito Sans',
    fontWeight: '400',
    minWidth: 'max-content',
    fontSize: typography.subtitle2.fontSize,
    '&:hover': {
      backgroundColor: palette.gray.border,
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: palette.gray.border
    },
    '&:focus': {
      boxShadow: 0
    }
  }
};

export default styles;
