import { Backdrop, Box, CircularProgress, Stack, Typography } from '@mui/material';

import React, { useEffect, useState } from 'react';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';

let intervalId: any;

const Loading = ({
  onDoneAnimation,
  loading = false
}: {
  loading: boolean;
  onDoneAnimation: () => void;
}) => {
  const [progress, setProgress] = useState([
    {
      step: 'Gathering Applications',
      percentage: 0
    },
    {
      step: ' Preparing Graphs',
      percentage: 0
    }
  ]);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    if (!loading && intervalId) {
      clearInterval(intervalId);
      intervalId = null;
      setProgress(prevProgress => {
        return prevProgress.map(p => ({ ...p, percentage: 100 }));
      });
      setStepIndex(indx => indx + 1);
      setTimeout(() => onDoneAnimation?.(), 1000);
    }
    if (loading) {
      intervalId = setTimeout(() => {
        const currentProgress = { ...progress[stepIndex] };
        currentProgress.percentage = Math.min(
          loading && stepIndex === progress.length - 1 ? 82 : 100,
          currentProgress.percentage + 20
        );
        progress[stepIndex] = currentProgress;

        setProgress([...progress]);
        if (progress[stepIndex].percentage === 100) {
          setStepIndex(prevIndex => (prevIndex < progress.length - 1 ? prevIndex + 1 : prevIndex));
        }
      }, 500);
    }

    return () => {
      clearTimeout(intervalId);
    };
  }, [progress, stepIndex, loading, onDoneAnimation]);

  return (
    <Box
      display='flex'
      position='absolute'
      left='50%'
      top='50%'
      alignItems={'center'}
      justifyContent={'center'}
      flexDirection='column'
      sx={{ transform: 'translate(-50%, -50%)' }}>
      <Timeline position={'right'}>
        {progress.map((p: any, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <TimelineItem key={`step-${index}`}>
            <TimelineSeparator sx={index === 1 ? { ml: -1.2 } : {}}>
              <Box display='flex' justifyContent='center' alignItems='center'>
                {p.percentage < 21 ? (
                  <CircularProgress variant='determinate' value={100} sx={{ color: 'gray.dove' }} />
                ) : (
                  <CircularProgress
                    variant={p.percentage < 100 ? 'indeterminate' : 'determinate'}
                    value={p.percentage}
                    sx={{ color: p.percentage === 100 ? 'primary.main' : '#007aff' }}
                  />
                )}
                {p.percentage === 100 && (
                  <DoneOutlinedIcon sx={{ position: 'absolute', color: 'primary.main' }} />
                )}
              </Box>

              {index < progress.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Typography
                fontSize={12}
                fontWeight={p.percentage === 100 || stepIndex === index ? 'bold' : 'normal'}
                color={stepIndex === index ? 'black.main' : 'gray.main'}>
                {p.step}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  );
};

const FullScreenProcessing = ({
  open,
  message,
  loading
}: {
  open: boolean;
  message: string;
  loading: boolean;
}) => {
  if (loading) {
    return (
      <Stack justifyContent={'center'} alignItems={'center'}>
        <Loading loading={loading} onDoneAnimation={() => {}} />
      </Stack>
    );
  }
  return (
    <Backdrop sx={{ zIndex: 9999, color: '#fff' }} open={open}>
      <Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
        <Box mb={2}>
          <CircularProgress color='inherit' />
        </Box>
        <Box>
          <Typography variant='h6' color='inherit'>
            {message}
          </Typography>
        </Box>
      </Stack>
    </Backdrop>
  );
};

export default React.memo(FullScreenProcessing);
