import { SOURCE_MENU_ITEMS } from '../../Home/const';

const getTabsToShow = (sourceList: unknown[], searchType: any) => {
  const tabsToShow: any = [];

  SOURCE_MENU_ITEMS.forEach((item: any) => {
    item.module.forEach((module: any) => {
      if (!sourceList.includes(module.value)) return;

      let tabOptions = searchType === 'advanced' ? item.advancedSearchResultTabs : item.resultTabs;

      // check if both ct sources are selected, if selected hide the clinical trial tab
      if (sourceList.includes('usnlm') && sourceList.includes('euctr')) {
        tabOptions = tabOptions.filter((tab: any) => tab !== 'ct');
      }

      if (module.isApplicationsView) {
        tabsToShow.push(...tabOptions);
      } else {
        tabsToShow.push(...tabOptions.filter((tab: string) => tab !== 'application'));
      }
    });
  });
  return tabsToShow;
};

export default getTabsToShow;
