import makeRequest from '../client';

/**
 *
 * Function gets all data for consultation module
 */
const getCanadaConsultationDocs = (filters: any) =>
  makeRequest('/canada-consultations', 'POST', filters);

/**
 *
 * Function gets all data for documnet details for a consultation
 */
export const getCanadaConsultationDocDetails = (consultationId: String) =>
  makeRequest(`/canada-consultations/${consultationId}`, 'GET');

export default getCanadaConsultationDocs;
