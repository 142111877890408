import { GridColDef } from '@mui/x-data-grid-pro';
import capitalize from 'lodash/capitalize';
import DatagridTooltip from '../../../components/Datagrid/DatagridTooltip';
import DatagridDate from '../../../components/Datagrid/DatagridDate';
import DatagridArray from '../../../components/Datagrid/DatagridArray/DatagridArray';
import renderCellExpand from '../../../components/Datagrid/DatagridPopover';
import { ApprovalHistoryFilters } from '../types';

export const PMR_C = 'PMR/C';

export const PMRC_UNIQUE_FIELD = 'id';

export const PMRC_DATAGRID_COLUMNS: GridColDef[] = [
  {
    field: 'supplement_type',
    headerName: 'Submission',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridArray
  },
  {
    field: 'approval_date',
    headerName: 'Approval Date',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridDate
  },
  {
    field: 'cmt_number',
    headerName: 'Commitment Number',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'cmt_desc',
    headerName: 'Commitment Description',
    headerClassName: 'table-header',
    width: 800,
    cellClassName: 'table-row',
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${capitalize(row.cmt_desc)}`
  },
  {
    field: 'subpart_description',
    headerName: 'Subpart Description',
    headerClassName: 'table-header',
    width: 400,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  }
];

export const FILTERS: Array<ApprovalHistoryFilters> = [
  {
    id: 'approval_date_range',
    label: 'Approval Date',
    filterType: 'date_range',
    apiKey: 'approval_date'
  },
  {
    id: 'supplement_type',
    label: 'Submission Type',
    filterType: 'select_options',
    apiKey: 'supplement_type',
    apiResponseKey: 'supplement_type',
    options: []
  },
  {
    id: 'subpart_description',
    label: 'Subpart Description',
    filterType: 'select_options',
    apiKey: 'subpart_description',
    apiResponseKey: 'subpart_description',
    options: []
  }
];

export default PMR_C;
