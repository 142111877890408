import React, { useContext, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Skeleton } from '@mui/material';
import EmptyPage from '../../../components/EmptyPage/EmptyPage';

import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import Actions from '../../../store/RegulatoryInformation/actions';
import { UrlParameters } from '../../../types/reactRouterDom';

import regulatoryInformationAPI from '../../../api/pages/Regulatory360';
import SUMMARY, { AddressKeys } from './constants';
import customTitleCase from '../../../utils/titleCase';

const Summary = () => {
  const { regulatoryState, regulatoryDispatch } = useContext(RegulatoryInformationStore);
  const [isLoading, setLoading] = useState<boolean>(false);

  const { source } = useParams<UrlParameters>();
  const [noContent, setNoContent] = useState(false);
  useEffect(() => {
    if (
      Object.keys(regulatoryState?.summaryDetails).length === 0 &&
      regulatoryState?.applicationDetails?.application_number &&
      regulatoryState?.db
    ) {
      const getData = async () => {
        try {
          setLoading(true);
          const data: any = await regulatoryInformationAPI(
            regulatoryState.db,
            regulatoryState?.applicationDetails?.application_number,
            SUMMARY
          );
          if (!Object.keys(data?.data?.body)?.length) {
            setNoContent(true);
          } else {
            regulatoryDispatch({ type: Actions.SET_SUMMARY_DETAILS, value: data.data.body });
          }
          setLoading(false);
        } catch {
          setNoContent(true);
          setLoading(false);
        }
      };
      getData();
    } else if (Object.keys(regulatoryState?.summaryDetails).length === 0) {
      setNoContent(true);
    }
  }, [regulatoryState.db, regulatoryState.applicationDetails, source]);

  const DetailsKeys = Object.keys(regulatoryState?.summaryDetails).filter(
    key => !AddressKeys.includes(key)
  );

  const formatDetailsData = (key: string, data: string) => {
    if (['product_code', 'state', 'docket_number'].includes(key)) {
      return data;
    }
    return customTitleCase(data);
  };

  if (isLoading) {
    return (
      <Box p={4}>
        <Skeleton variant='rectangular' height='60vh' width='100%' />
      </Box>
    );
  }

  return (
    <Box sx={{ backgroundColor: 'white.main' }}>
      {noContent ? (
        <Box sx={{ height: 'calc(100vh - 340px)' }}>
          <EmptyPage message='No content for Summary' />
        </Box>
      ) : (
        <Grid container spacing={2} p={4}>
          {AddressKeys.map(key => (
            <React.Fragment key={key}>
              <Grid item xs={2} id={key}>
                <strong>{customTitleCase(key.replaceAll('_', ' '))}:</strong>
              </Grid>
              <Grid item xs={10}>
                {regulatoryState?.summaryDetails[key]
                  ? formatDetailsData(key, regulatoryState?.summaryDetails[key])
                  : '----'}
              </Grid>
            </React.Fragment>
          ))}

          {DetailsKeys.map(key => (
            <React.Fragment key={key}>
              <Grid item xs={2} id={key}>
                <strong>{customTitleCase(key.replaceAll('_', ' '))}:</strong>
              </Grid>
              <Grid item xs={9}>
                {regulatoryState?.summaryDetails[key]
                  ? formatDetailsData(key, regulatoryState?.summaryDetails[key])
                  : '----'}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default React.memo(Summary);
