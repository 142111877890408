import { createSvgIcon } from '@mui/material';

const PlayButtonIcon = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='141'
    height='141'
    viewBox='0 0 141 141'
    fill='none'>
    <g filter='url(#filter0_d_5149_40308)'>
      <rect x='49.1191' y='48.0205' width='44.9641' height='43.8674' fill='#52BCAD' />
      <path
        d='M70.4746 30.4736C48.3778 30.4736 30.4746 48.3769 30.4746 70.4736C30.4746 92.5704 48.3778 110.474 70.4746 110.474C92.5714 110.474 110.475 92.5704 110.475 70.4736C110.475 48.3769 92.5714 30.4736 70.4746 30.4736ZM89.1359 74.3446L60.7488 90.6349C58.2004 92.0543 54.9907 90.2317 54.9907 87.2478V53.6994C54.9907 50.7317 58.1843 48.893 60.7488 50.3123L89.1359 67.5704C91.7811 69.0543 91.7811 72.8769 89.1359 74.3446Z'
        fill='#E1F3F1'
      />
    </g>
    <defs>
      <filter
        id='filter0_d_5149_40308'
        x='0.474609'
        y='0.473633'
        width='140'
        height='140'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset />
        <feGaussianBlur stdDeviation='15' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_5149_40308' />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_5149_40308'
          result='shape'
        />
      </filter>
    </defs>
  </svg>,
  'PlayButton'
);

export default PlayButtonIcon;
