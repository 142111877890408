import { useCallback, useContext } from 'react';
import UserProfileStore from '../../../store/UserProfile';
import UserProfileActions from '../../../store/UserProfile/actions';
import Store from '../../../store';
import Actions from '../../../store/actions';
import {
  deleteReport as deleteReportAPI,
  listReports,
  renameReport
} from '../../../api/pages/UserProfile';
import { formatDate } from '../utils';
import { REPORT_DESCRIPTION_MAX_LENGTH, REPORT_TITLE_MAX_LENGTH } from '../constants';
import { ReportData } from '../interface';

const useReportData = () => {
  const { profileState, profileDispatch } = useContext(UserProfileStore);
  const { dispatch } = useContext(Store) as any;

  const fetchReportData = async () => {
    try {
      profileDispatch({
        type: UserProfileActions.SET_LOADING,
        value: true
      });
      const res = await listReports();
      if (res?.status === 200) {
        const sectionData = res?.data?.body?.data;
        const reportData = sectionData.map((section: any) => {
          return {
            id: section.id,
            reportName: section.report_name,
            reportDescription: section.report_description,
            createdAt: formatDate(section.created_at),
            updatedAt: formatDate(section.updated_at)
          } as ReportData;
        }) as ReportData[];

        reportData.sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        profileDispatch({
          type: UserProfileActions.SET_REPORT_DATA,
          value: reportData
        });
      }
    } catch (error) {
      const errorMessage = (error as Error)?.message ?? 'Something went wrong';
      await dispatch({
        type: Actions.SET_ALERT,
        value: { message: errorMessage, status: true }
      });
    } finally {
      profileDispatch({
        type: UserProfileActions.SET_LOADING,
        value: false
      });
    }
  };

  const updateReportDetails = async (
    reportId: number,
    reportName: string,
    reportDescription: string
  ) => {
    try {
      if (!reportId || !reportName || !reportDescription) {
        throw new Error('Invalid report details');
      }
      if (reportName.length > REPORT_TITLE_MAX_LENGTH) {
        throw new Error(`Report title should not exceed ${REPORT_TITLE_MAX_LENGTH} characters`);
      }
      if (reportDescription.length > REPORT_DESCRIPTION_MAX_LENGTH) {
        throw new Error(
          `Report description should not exceed ${REPORT_DESCRIPTION_MAX_LENGTH} characters`
        );
      }
      const res = await renameReport(reportId, {
        report_name: reportName,
        report_description: reportDescription
      });
      if (!res || res?.status !== 200 || !res?.data) {
        throw new Error(res?.data?.message ?? 'Error in renaming the report');
      }
      const { body, status, error, message } = res.data;
      if (status !== 200 || error || !body) {
        throw new Error(message ?? 'Error in renaming the report');
      }
      const updatedReportList = profileState.reportData.map(report => {
        if (report?.id === reportId)
          return {
            ...report,
            reportName: body?.report_name,
            reportDescription: body?.report_description,
            updatedAt: formatDate(body?.updated_at)
          } as ReportData;
        return report;
      });
      profileDispatch({
        type: UserProfileActions.SET_REPORT_DATA,
        value: updatedReportList
      });
      await dispatch({
        type: Actions.SET_ALERT,
        value: {
          message: 'Renamed report successfully',
          status: true,
          color: 'success'
        }
      });
    } catch (error) {
      const errorMessage = (error as Error)?.message ?? 'Something went wrong';
      await dispatch({
        type: Actions.SET_ALERT,
        value: { message: errorMessage, status: true }
      });
    }
  };

  const deleteReport = async (reportId: number) => {
    try {
      if (!reportId) {
        throw new Error('Invalid report id');
      }
      const res = await deleteReportAPI(reportId);
      if (!res || res?.status !== 200) {
        throw new Error(res?.data?.message ?? 'Error in deleting the report');
      }
      await dispatch({
        type: Actions.SET_ALERT,
        value: {
          message: 'Deleted report successfully',
          status: true,
          color: 'success'
        }
      });
      const updatedReportList = profileState.reportData.filter(report => report?.id !== reportId);
      profileDispatch({
        type: UserProfileActions.SET_REPORT_DATA,
        value: updatedReportList
      });
    } catch (error) {
      const errorMessage = (error as Error)?.message ?? 'Something went wrong';
      await dispatch({
        type: Actions.SET_ALERT,
        value: { message: errorMessage, status: true }
      });
    }
  };

  const sortReport = useCallback(
    (column: 'reportName' | 'createdAt', sortOrder: 'asc' | 'desc') => {
      const sortedData = [...profileState.reportData];
      if (column === 'createdAt') {
        sortedData.sort((a, b) =>
          sortOrder === 'asc'
            ? new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            : new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      } else {
        sortedData.sort((a, b) =>
          sortOrder === 'asc'
            ? b.reportName.localeCompare(a.reportName)
            : a.reportName.localeCompare(b.reportName)
        );
      }
      profileDispatch({
        type: UserProfileActions.SET_REPORT_DATA,
        value: sortedData
      });
    },
    [profileState.reportData]
  );

  return {
    isLoading: profileState.loading,
    reportData: profileState.reportData,
    fetchReportData,
    updateReportDetails,
    deleteReport,
    sortReport
  };
};

export default useReportData;
