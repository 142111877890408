/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useContext, useEffect, useState } from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import DocumentCountIcon from '../../../assets/svgs/CardsPage/DocumentCountIcon';
import PageCountIcon from '../../../assets/svgs/CardsPage/PageCountIcon';

// store
import EMAGuidanceStore from '../../../store/EMAGuidance';
import actions from '../../../store/EMAGuidance/actions';
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';
// component
import ComparisonButtons from './ComparisonButtons';
import CompareDialog from './CompareDialog';

// utils
import mapEntityData from '../utils/mapEntityData';
import formatCount from '../../../utils/formatCount';
import truncateCountAboveMillions from '../../../utils/truncateCountAboveMillions';

// styles
import styles from '../styles/DocumentHistory.styles';

// Icons
import PDFPreview from '../../../components/PdfPreview/PDFPreview';
import { getDocumentSourceName } from '../../SearchResults/utils/documentResultsUtils';
import FolderIcon from '../../../assets/svgs/Icons/FolderIcon';
import { getEMAGuidanceDocumentsSignedURL } from '../../../api/pages/EMAGuidance';

const GridCellDouments: React.FC<any> = ({ data }) => {
  const { EMAGuidanceDispatch } = useContext(EMAGuidanceStore);
  const { dispatch } = useContext(GlobalStore) as any;
  const [pdfUrl, setPdfUrl] = useState('');
  const [selectedRowData, setSelectdRowData] = useState({}) as any;
  const [documentsToCompare, setDocumentsToCompare] = useState<Array<any>>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [compareEnabled, setCompareEnabled] = useState<boolean>(false);
  const [openComparisonPopup, setOpenComparisonPopup] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [compareItems, setCompareItems] = useState<Array<any>>([]);

  useEffect(() => {
    const fetchData = async () => {
      const formattedDocumentsToCompare = await Promise.all(
        documentsToCompare.map(async doc => {
          const pdfSignedUrl = await getEMAGuidanceDocumentsSignedURL({
            source: 'ema_guidance',
            s3_path: doc.s3_path
          });
          return {
            title: `${doc.doc_title.replace(/ \([\s\S]*?\)/g, '')}`,
            pdfLink: pdfSignedUrl.data.body.s3_url
          };
        })
      );
      setCompareItems(formattedDocumentsToCompare);
    };

    // check is added so that when user selects both the pdf only pdf url is fetched
    if (documentsToCompare.length === 2) {
      fetchData();
    }
  }, [documentsToCompare]);

  const handleOpenPopup = () => {
    setOpen(true);
  };

  const handleClosePopup = () => {
    setOpen(false);
    setDocumentsToCompare([]);
    setCompareEnabled(false);
  };

  const handlePDFOpenClick = useCallback(async (pdfLink: any) => {
    const res: any = await getEMAGuidanceDocumentsSignedURL({
      source: 'ema_guidance',
      s3_path: pdfLink
    });
    setPdfUrl(res?.data?.body?.s3_url ?? '');
  }, []);

  const handleCompareClick = useCallback(() => {
    if (compareEnabled) {
      setCompareEnabled(false);
      setDocumentsToCompare([]);
    } else {
      setCompareEnabled(true);
      EMAGuidanceDispatch({
        type: actions.CLEAR_DOCUMENT_TO_COMPARE
      });
      EMAGuidanceDispatch({
        type: actions.SET_COMPARE_ENABLED,
        value: false
      });
      EMAGuidanceDispatch({
        type: actions.SET_SEARCH,
        value: {
          term: ''
        }
      });
    }
  }, [compareEnabled]);

  const handleComparisonCheckbox = useCallback(
    (id: string) => {
      if (documentsToCompare.find((doc: any) => doc.key === id)) {
        const removeDocument = documentsToCompare.filter((doc: any) => doc.key !== id);
        setDocumentsToCompare(removeDocument);
      } else if (
        documentsToCompare.length === 2 &&
        !documentsToCompare.find((doc: any) => doc.key === id)
      ) {
        documentsToCompare.pop();
        // Check whether doc is current version
        if (data.key === id) {
          const addDocument = [...documentsToCompare, data];
          setDocumentsToCompare(addDocument);
        } else {
          // Check whether doc is present in array of document_history
          const addDocument = [
            ...documentsToCompare,
            data?.document_history.find((doc: any) => doc.key === id)
          ];
          setDocumentsToCompare(addDocument);
        }
      } else {
        // Check whether doc is current version
        // eslint-disable-next-line no-lonely-if
        if (data.key === id) {
          const addDocument = [...documentsToCompare, data];
          setDocumentsToCompare(addDocument);
        } else {
          // Check whether doc is present in array of document_history
          const addDocument = [
            ...documentsToCompare,
            data?.document_history.find((doc: any) => doc.key === id)
          ];
          setDocumentsToCompare(addDocument);
        }
      }
    },
    [documentsToCompare, data]
  );

  const clearComparison = useCallback(() => {
    setDocumentsToCompare([]);
    setOpenComparisonPopup(false);
  }, []);

  const handleComparisonPopup = useCallback((value: boolean) => {
    setOpenComparisonPopup(value);
  }, []);

  const handleChatRia = ({ pdfUrl = '' }: { pdfUrl?: string }) => {
    const mappedSourceName = getDocumentSourceName('EMA Guidances');
    dispatch({
      type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM,
      value: 'document'
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: mappedSourceName
    });
    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: {
        blob: pdfUrl,
        url: pdfUrl,
        item: {
          last_published_date: selectedRowData?.first_published_date ?? '',
          ema_document_no: selectedRowData?.ema_document_no ?? '',
          status: selectedRowData?.status ?? '',
          document_type: selectedRowData?.document_type ?? '',
          doc_title: selectedRowData?.doc_title ?? ''
        },
        source: mappedSourceName,
        triggerReopenChatRia: false
      }
    });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
    setPdfUrl('');
    handleClosePopup();
  };

  const groupByDocumentType = (pdfDocs: Array<any>) => {
    const groupedObjects: any = {};

    pdfDocs.forEach((doc: any) => {
      if (!groupedObjects[doc.document_type]) {
        groupedObjects[doc.document_type] = [];
      }
      groupedObjects[doc.document_type].push(doc);
    });

    return groupedObjects;
  };

  return (
    <Box sx={styles.textAlignment}>
      <Box alignItems='center' sx={{ cursor: 'pointer' }}>
        {data && (
          <Stack
            direction='row'
            spacing={0.5}
            alignItems='center'
            sx={styles.linkItem}
            onClick={() => {
              if (data?.document_count !== 0) {
                handleOpenPopup();
              }
            }}>
            <FolderIcon sx={{ fontSize: 12 }} />
            <Typography variant='subtitle2' sx={styles.pdfCellText}>
              {data?.document_count}
            </Typography>
          </Stack>
        )}
      </Box>
      <Dialog
        fullWidth
        maxWidth='lg'
        aria-labelledby='customized-dialog-title'
        open={open}
        sx={styles.dialog}
        onClose={handleClosePopup}>
        <DialogTitle>
          <Grid display='flex' justifyContent='space-between' alignItems='flex-start'>
            <Grid sx={styles.heading} display='flex' justifyContent='center'>
              Documents
            </Grid>
            <Stack
              display='flex'
              flexDirection='row'
              sx={{
                height: '40px',
                padding: '1px 28px',
                borderRadius: '10px',
                bgcolor: 'white.main'
              }}>
              <Stack direction='row' spacing={2}>
                <DocumentCountIcon />
                <Stack>
                  <Typography variant='subtitle2'>Documents</Typography>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Tooltip title={formatCount(data?.document_count)}>
                      <Typography variant='subtitle1' fontWeight='bold'>
                        {truncateCountAboveMillions(data?.document_count)}
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Stack>
                <PageCountIcon />
                <Stack>
                  <Typography variant='subtitle2'>Pages</Typography>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Tooltip title={formatCount(data?.document_total_page_count)}>
                      <Typography variant='subtitle1' fontWeight='bold'>
                        {truncateCountAboveMillions(data?.document_total_page_count)}
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Box display='flex' flexDirection='row'>
              {data.document_history.length > 1 && (
                <Stack
                  spacing={1}
                  alignItems='center'
                  direction='row'
                  sx={{ cursor: 'pointer', paddingRight: '50px' }}
                  onClick={handleCompareClick}>
                  <Switch checked={compareEnabled} />
                  <Typography
                    id='filters'
                    sx={{ justifySelf: 'flex-end', color: 'gray.lightVariant' }}
                    variant='subtitle1'>
                    Comparison Mode
                  </Typography>
                </Stack>
              )}
              <IconButton aria-label='delete' onClick={handleClosePopup} sx={{ fontSize: '30px' }}>
                <CancelIcon fontSize='inherit' />
              </IconButton>
            </Box>
          </Grid>
          <Divider />
        </DialogTitle>
        <DialogContent sx={{ height: '94%' }}>
          <Stack spacing={1}>
            {openComparisonPopup && (
              <CompareDialog
                open={openComparisonPopup}
                onClose={() => handleComparisonPopup(false)}
                compareItems={compareItems}
                onNewCompare={clearComparison}
              />
            )}
            {compareEnabled && (
              <ComparisonButtons
                documentsToCompare={documentsToCompare}
                removeFromComparison={handleComparisonCheckbox}
                openComparisonPopup={handleComparisonPopup}
                clearComparison={clearComparison}
              />
            )}
            <Stack spacing={1}>
              {data.document_history.length > 0 && (
                <>
                  {Object.entries(groupByDocumentType(data.document_history)).map(
                    ([key, value]: any) => {
                      return (
                        <Stack
                          spacing={1}
                          display='flex'
                          flexDirection='column'
                          sx={{ width: '100%' }}
                          key={key}>
                          <Stack
                            spacing={1}
                            display='flex'
                            flexDirection='row'
                            sx={{ width: '100%' }}
                            key={key}>
                            <Box sx={{ width: '15%' }}>
                              <Box
                                sx={{
                                  color: 'primary.dark',
                                  padding: '13px 10px',
                                  marginBottom: 'auto'
                                }}>
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    color: 'black.darkVariant',
                                    fontWeight: '600'
                                  }}>
                                  {key}
                                </Typography>
                              </Box>
                            </Box>
                            <Stack sx={{ width: '100%' }}>
                              <>
                                {value.map((row: any) => {
                                  return (
                                    <Stack key={row.key} spacing={1}>
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        sx={{
                                          padding: '12px 10px',
                                          borderRadius: 8,
                                          backgroundColor: 'primary.background'
                                        }}>
                                        <Stack direction='row' spacing={1}>
                                          {compareEnabled && (
                                            <Tooltip
                                              title={
                                                documentsToCompare.length === 2 &&
                                                !documentsToCompare.find(
                                                  (doc: any) => doc.key === row.key
                                                )
                                                  ? 'Selection limit reached'
                                                  : ''
                                              }>
                                              <Box>
                                                <Radio
                                                  disabled={
                                                    documentsToCompare.length === 2 &&
                                                    !documentsToCompare.find(
                                                      (doc: any) => doc.key === row.key
                                                    )
                                                  }
                                                  checked={
                                                    !!documentsToCompare.find(
                                                      (doc: any) => doc.key === row.key
                                                    )
                                                  }
                                                  onClick={() => handleComparisonCheckbox(row.key)}
                                                />
                                              </Box>
                                            </Tooltip>
                                          )}
                                          <Box>
                                            <Box
                                              sx={{
                                                padding: '0.2rem',
                                                width: '40px',
                                                height: '40px'
                                              }}>
                                              <img
                                                src='https://vivpro-site.s3.amazonaws.com/Help_Page_FAQ/pdf_icon.png'
                                                alt='PDF download'
                                                height='100%'
                                                width='100%'
                                              />
                                            </Box>
                                          </Box>
                                          <Stack>
                                            <Stack
                                              direction='row'
                                              alignItems='center'
                                              onClick={() => {
                                                setSelectdRowData(row);
                                                handlePDFOpenClick(row.s3_path);
                                                setTitle(
                                                  row.doc_title.replace(/ \([\s\S]*?\)/g, '')
                                                );
                                              }}>
                                              <Typography
                                                sx={styles.title}>{`${row.doc_title.replace(
                                                / \([\s\S]*?\)/g,
                                                ''
                                              )}`}</Typography>
                                            </Stack>
                                            <Stack
                                              display='flex'
                                              flexDirection='row'
                                              sx={{ paddingTop: '10px' }}>
                                              {Object.entries(mapEntityData(row)).map(
                                                ([key, value]) => {
                                                  if (value) {
                                                    return (
                                                      <Tooltip key={key} title={`${key}: ${value}`}>
                                                        <Box display='flex' sx={styles.chips}>
                                                          <Typography sx={{ fontSize: '12px' }}>
                                                            {value}
                                                          </Typography>
                                                        </Box>
                                                      </Tooltip>
                                                    );
                                                  }
                                                }
                                              )}
                                            </Stack>
                                          </Stack>
                                        </Stack>
                                      </Box>
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        sx={{ marginTop: '5px' }}
                                      />
                                    </Stack>
                                  );
                                })}
                              </>
                            </Stack>
                          </Stack>
                          <Divider />
                        </Stack>
                      );
                    }
                  )}
                </>
              )}
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      {pdfUrl && (
        <Box>
          <PDFPreview
            open={!!pdfUrl}
            pdfUrl={pdfUrl}
            onClose={() => setPdfUrl('')}
            handleChatRia={handleChatRia}
            title={title}
          />
        </Box>
      )}
    </Box>
  );
};

const DocumentHistoryPDF = (params: any) => {
  return <GridCellDouments data={params.row} />;
};

export default DocumentHistoryPDF;
