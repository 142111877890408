import React from 'react';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

const CustomTimeLineSkeleton = () => {
  const skeletons = [];
  for (let i = 0; i < 5; i += 1) {
    skeletons.push(
      <Skeleton variant='rectangular' key={`${i}`} sx={{ width: '95%' }} height={100} />
    );
  }
  return (
    <Stack spacing={2} sx={{ width: '100%', alignItems: 'center' }}>
      {skeletons}
    </Stack>
  );
};

export default React.memo(CustomTimeLineSkeleton);
