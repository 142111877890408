const styles = {
  clearAll: {
    textTransform: 'capitalize',
    color: 'gray.700',
    fontSize: 14,
    fontWeight: 700,
    '&:hover': {
      background: 'none',
      boxShadow: '0 0 2px #0000001a'
    },
    justifyContent: 'flex-start'
  },
  applyButton: {
    color: 'white.main',
    borderRadius: 20,
    fontSize: 14,
    '&:hover': {
      boxShadow: '0 0 8px #0000004d'
    },
    textTransform: 'capitalize',
    px: 3,
    fontWeight: 700
  },
  mainInformationText: {
    p: 1,
    overflow: 'auto',
    height: 'calc(100% - 140px)'
  },
  loadingContainer: {
    p: 1,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  drawerHeader: {
    backgroundColor: 'primary.600',
    p: 2,
    display: 'flex',
    justifyContent: 'space-between'
  },
  expandedDrawer: {
    position: 'fixed',
    width: 360,
    top: 0,
    transform: 'translate(-100%, 0)',
    height: '100%',
    overflow: 'hidden',
    borderRight: '1px solid',
    borderRightColor: 'primary.100',
    bgcolor: 'white.main'
  },
  expandedDrawerTitle: {
    backgroundColor: 'primary.300',
    p: 2,
    display: 'flex',
    justifyContent: 'space-between'
  },
  expandedDrawerSubInfoText: {
    fontSize: 13,
    color: 'gray.700',
    fontWeight: 400,
    pl: 2,
    pb: 0.5
  },
  expandedDrawerSubTitle: { p: 2, pb: 1, fontSize: 13, color: 'gray.700' },

  subTermsContainer: {
    p: 1,
    pl: 2,
    height: 'calc(100% - 70px)',
    overflow: 'auto'
  }
};

export default styles;
