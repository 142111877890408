import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import Dialog from '@material-ui/core/Dialog';
import { cloneDeep, isEmpty } from 'lodash';

import Close from '../../assets/svgs/CardsPage/CloseIcon';
import SearchListIcon from '../../assets/svgs/CardsPage/SearchListIcon';
import SynonymItem from './SynonymItem';
import FilterBySynonyms from '../SearchListTreeView/FilterBySynonyms';
import EmptyPage from '../EmptyPage/EmptyPage';

import getSynonymsTreeCount from './utils/getSynonymsTreeCount';
import getAdvanceSearchName from './utils/getAdvanceSearchName';
import getATCTreeCount from '../ATCClassificationTreeView/utils';

// context
import Store from '../../store';

import makeStyles from './SearchList.styles';
import ATCClassificationTreeView from '../ATCClassificationTreeView';
import styles from '../ATCClassificationTreeView/popup.styles';
import ResultsStore from '../../store/SearchResults';

// eslint-disable-next-line react/prop-types
const SearchList = ({ showCount, onClose = () => {} }) => {
  const classes = makeStyles();
  const { resultsState } = useContext(ResultsStore);
  const [synonymTreeWithCount, setsynonymsTreeWithCount] = useState({});
  const [atcEnabled, setAtcEnabled] = useState(false);
  const { state } = useContext(Store);
  const { data: treeData, type } = useMemo(() => {
    const data = { ...state.treeData };
    delete data.type;
    return { data, type: state.treeData?.type };
  }, [state.treeData]);

  useEffect(() => {
    let treeCountData = {};
    if (type === 'advanced_search_synonyms') {
      // eslint-disable-next-line no-underscore-dangle
      let _atcEnabled = false;
      Object.keys(treeData).forEach(term => {
        if (!term.includes('atc_classification')) {
          const { countData } = getSynonymsTreeCount(cloneDeep(treeData[term]), resultsState);
          treeCountData[term] = countData;
        } else {
          const applications = Object.entries(resultsState.applicationResults || {}).reduce(
            (acc, [, value]) => {
              return [...acc, ...value.results];
            },
            []
          );
          treeCountData[term] = {
            trees: getATCTreeCount(treeData[term].trees, applications, 'us')
          };
          _atcEnabled = true;
        }
      });
      setAtcEnabled(_atcEnabled);
    } else {
      const { countData } = getSynonymsTreeCount(cloneDeep(treeData), resultsState);
      treeCountData = countData;
    }
    setsynonymsTreeWithCount(treeCountData);
  }, [treeData, resultsState, type]);

  return (
    <Stack sx={{ overflow: 'hidden' }}>
      {showCount ? (
        <Stack sx={styles.Synonymscontainer}>
          <Stack flexDirection='row' sx={{ marginLeft: 2 }}>
            <SearchListIcon />
            <Typography
              variant='subtitle1'
              sx={{ marginLeft: '1vh', fontSize: '18px', color: 'gray.darkText' }}>
              Synonyms
            </Typography>
          </Stack>
          <FilterBySynonyms atcView={atcEnabled} onClose={onClose} />
        </Stack>
      ) : null}
      <Box className={classes.container}>
        {type === 'advanced_search_synonyms' ? (
          Object.keys(synonymTreeWithCount).map(term => {
            return (
              <Fragment key={term}>
                <Typography
                  variant='subtitle1'
                  sx={{ marginLeft: 2, fontSize: '16px', color: 'gray.darkText' }}>
                  {getAdvanceSearchName(term)}
                </Typography>
                {term.includes('atc_classification') ? (
                  <ATCClassificationTreeView tree={synonymTreeWithCount[term].trees} />
                ) : (
                  <SynonymItem treeData={synonymTreeWithCount[term]} showCount={showCount} />
                )}
              </Fragment>
            );
          })
        ) : !isEmpty(treeData) ? (
          <SynonymItem treeData={synonymTreeWithCount} showCount={showCount} />
        ) : (
          <EmptyPage message='No Synonyms Data' />
        )}
      </Box>
    </Stack>
  );
};

// eslint-disable-next-line react/prop-types
export const DialogBox = ({ open, setOpen, showCount = true }) => {
  const classes = makeStyles();
  return (
    <Dialog
      onClose={() => setOpen()}
      aria-labelledby='customized-dialog-title'
      open={open}
      fullWidth
      maxWidth='md'
      className={classes.dialog}>
      <Box
        p={1}
        className={classes.close}
        display='flex'
        onClick={() => setOpen()}
        justifyContent='flex-end'>
        <Close />
      </Box>
      <SearchList showCount={showCount} />
    </Dialog>
  );
};
export default SearchList;
