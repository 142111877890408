const getFormattedDate = date => {
  let day = date.getDate() < 10 ? '0' + date.getDate() : String(date.getDate());
  let month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : String(date.getMonth() + 1);
  let year = String(date.getFullYear());
  let formattedDate = year + '-' + month + '-' + day;

  return formattedDate;
};

export default getFormattedDate;
