/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext } from 'react';

// mui
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import Tooltip from '@mui/material/Tooltip';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FolderIcon } from '../../../../assets/svgs/Icons';

// Custom components and api's
import { getHTADocs } from '../../../../api/pages/HTADocuments';
import styles from './DatagridPDFCell.styles';
import PDFPreview from '../../../../components/PdfPreview/PDFPreview';

// store
import GlobalStore from '../../../../store';
import GlobalActions from '../../../../store/actions';
// eslint-disable-next-line react/prop-types, no-unused-vars
const GridCellDouments = ({ value, type, guidanceRef, row }) => {
  const [PDFData, setPDFData] = useState([false, '']);
  const [title, setTitle] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [linkData, setLinkData] = useState(null);
  const [documentCategory, setDocumentCategory] = useState('UNKNOWN');
  const { dispatch } = useContext(GlobalStore);

  const getData = async () => {
    const res = await getHTADocs(guidanceRef);
    setLinkData(res);
  };

  const handleOpenPopup = e => {
    setAnchorEl(e.currentTarget);
    getData();
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
    setLinkData(null);
  };

  const handleChatRia = ({ pdfUrl = '' }) => {
    const mappedSourceName = 'uk';
    dispatch({
      type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM,
      value: 'document'
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: mappedSourceName
    });

    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: {
        blob: pdfUrl,
        url: pdfUrl,
        item: {
          published: row?.published_date ?? '',
          reference_number: row?.guidance_ref ?? '',
          document_type: row?.nice_guidance_type ?? '',
          document_category: documentCategory,
          title: row?.title ?? ''
        },
        source: mappedSourceName,
        triggerReopenChatRia: false
      }
    });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
    setPDFData([false, '']);
    handleClosePopup();
  };

  return (
    <Box>
      <Stack
        direction='row'
        spacing={0.5}
        sx={styles.linkItem}
        alignItems='center'
        onClick={e => handleOpenPopup(e)}>
        <FolderIcon sx={{ fontSize: 12 }} />
        <Typography variant='subtitle2' sx={styles.pdfCellText}>
          {value}
        </Typography>
      </Stack>
      <Popover
        id='simple-popover'
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        elevation={1}>
        <Box sx={styles.popoverDiv} p={2}>
          {linkData && (
            <>
              {Object.keys(linkData.data.body.results).map(key => {
                return (
                  <Accordion key={key} p={2}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography sx={styles.linkHeading}>{key}</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      {linkData.data.body.results[key].map(item => {
                        return (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <Link
                            sx={styles.link}
                            key={item.title}
                            onClick={() => {
                              setDocumentCategory(key);
                              setPDFData([true, item.pdf_s3_url]);
                              setTitle(item.title);
                            }}>
                            <Tooltip title={item.title}>
                              <Typography variant='subtitle1' sx={styles.link}>
                                {item.title.length > 60
                                  ? `${item.title.substring(0, 60)}...`
                                  : item.title}
                              </Typography>
                            </Tooltip>
                          </Link>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </>
          )}
        </Box>
      </Popover>

      <Box>
        <PDFPreview
          open={PDFData[0]}
          pdfUrl={PDFData[1].toString() ?? ''}
          onClose={() => {
            setPDFData([false, '']);
          }}
          handleChatRia={handleChatRia}
          title={title}
        />
      </Box>
    </Box>
  );
};

const DatagridPDFCell = params => {
  const { value, row, field } = params;
  return (
    <GridCellDouments value={value || '0'} type={field} guidanceRef={row.guidance_ref} row={row} />
  );
};

export default DatagridPDFCell;
