import { createSvgIcon } from '@mui/material';

const EnglishToJapaneseIcon = createSvgIcon(
  <svg width='50' height='21' viewBox='0 0 50 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_279_2)'>
      <path
        d='M3.9 20.6998H0L7.2 0.299805H11.8L19 20.6998H15.1L9.7 4.4998H9.5L3.9 20.6998ZM2.8 12.9998H13.5V15.9998H2.8V12.9998Z'
        fill='currentColor'
      />
      <path
        d='M33.3 20.3998C36.6 20.3998 39.4 18.8998 41.9 16.8998C44.4 18.9998 47.2 20.3998 50.6 20.3998V17.2998C50.6 16.7998 50.5 16.7998 50 16.6998C47.8 16.5998 46 15.4998 44.3 14.1998V14.0998C44.4 13.9998 44.5 13.8998 44.5 13.7998C46.1 11.5998 47.4 9.1998 47.8 6.3998C47.9 5.7998 48.1 5.6998 48.6 5.6998H50.7V2.0998H43.6V-0.200195H40V2.0998H33V5.7998H44.3C43.9 7.9998 43.1 9.9998 41.7 11.7998C40.9 10.3998 40.2 9.0998 39.5 7.8998C38.5 8.3998 37.4 8.8998 36.3 9.3998C37.3 11.0998 38.3 12.6998 39.3 14.2998C37.7 15.5998 35.9 16.5998 33.7 16.6998C33.5 16.6998 33.2 16.8998 33.2 16.9998C33.3 18.1998 33.3 19.2998 33.3 20.3998Z'
        fill='currentColor'
      />
      <path
        d='M30.9778 13.8651C30.9778 14.2415 30.6558 14.5506 30.2638 14.5506H21.4858L22.2698 15.3032C22.5078 15.5317 22.5638 15.8946 22.3818 16.1768C22.1298 16.5531 21.5978 16.58 21.2898 16.2843L19.1898 14.2683C18.9518 14.0399 18.9518 13.6904 19.1898 13.4619L21.2758 11.4594C21.5838 11.1637 22.1158 11.204 22.3678 11.5669C22.5498 11.8357 22.4938 12.212 22.2558 12.4405L21.4718 13.1931H30.2638C30.6698 13.1797 30.9778 13.4888 30.9778 13.8651ZM30.8098 7.17201L28.7238 5.16945C28.4158 4.87377 27.8838 4.91409 27.6318 5.27697C27.4498 5.54577 27.5058 5.92209 27.7438 6.15057L28.5278 6.90321H19.7218C19.3298 6.90321 19.0078 7.21233 19.0078 7.58865C19.0078 7.96497 19.3298 8.27409 19.7218 8.27409H28.5138L27.7298 9.02673C27.4918 9.25521 27.4358 9.61809 27.6178 9.90033C27.8698 10.2767 28.4018 10.3035 28.7098 10.0079L30.7958 8.00529C31.0478 7.76337 31.0478 7.40049 30.8098 7.17201Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_279_2'>
        <rect width='50' height='21' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'EnglishToJapanese'
);

export default EnglishToJapaneseIcon;
