import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

// component
import Summary from './Summary';
import Roster from './Roster';

// hooks
import useQuery from '../../../../helpers/customHooks/queryParameter';
import useTabsData from '../../hooks/useTabsData';

// styles
import styles from '../../styles/Summary.styles';
import RosterSkeleton from '../Skeleton/RosterSkeleton';
import CustomChip from '../../CustomChip';

const Tabs = ({ selectedMeeting, updatedSearchParams }: any) => {
  const location = useLocation();
  const query = useQuery();
  const [selectedTab, setSelectedTab] = useState<string>(query.get('tab') || 'summary');
  const { isLoading, responseData, errorMessage, getTabsData } = useTabsData();

  const handleMeetingTabChange = (
    event: React.MouseEvent<HTMLElement> | null,
    newValue: string | null
  ) => {
    if (newValue !== null) {
      if (newValue === 'roster') {
        if (responseData.roster?.table?.length !== 0) {
          setSelectedTab(newValue);
          updatedSearchParams.set('tab', newValue);
          window.history.pushState(
            {},
            '',
            `${location.pathname}?${updatedSearchParams.toString()}`
          );
        }
      } else if (newValue === 'summary') {
        setSelectedTab(newValue);
        updatedSearchParams.set('tab', newValue);
        window.history.pushState({}, '', `${location.pathname}?${updatedSearchParams.toString()}`);
      }
    }
  };

  useEffect(() => {
    if (responseData && responseData.roster?.table?.length === 0) {
      updatedSearchParams.delete('memberId');
      updatedSearchParams.delete('memberName');
      updatedSearchParams.delete('member');
      handleMeetingTabChange(null, 'summary');
    }
  }, [responseData]);

  useEffect(() => {
    if (selectedMeeting && 'meeting_id' in selectedMeeting) {
      getTabsData(selectedMeeting.meeting_id || '', selectedMeeting?.date || '');
    }
  }, [selectedMeeting]);

  if (isLoading) {
    return <RosterSkeleton />;
  }

  return (
    <>
      <Box maxHeight='7vh'>
        <Stack
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          maxHeight='inherit'
          sx={{
            pl: 1,
            pr: 3,
            pt: 2
          }}>
          <Box id='adcomm-meeting-type' sx={{ width: '20%' }}>
            <ToggleButtonGroup
              value={selectedTab || 'summary'}
              exclusive
              onChange={handleMeetingTabChange}
              aria-label='tab'
              sx={{
                width: '100%',
                height: '4vh'
              }}>
              <ToggleButton
                id='summary'
                value='summary'
                aria-label='summary'
                sx={selectedTab === 'summary' ? styles.selectedTab : styles.unselectedTab}>
                Summary
              </ToggleButton>
              <Tooltip title={responseData.roster?.table?.length === 0 ? errorMessage : ''}>
                <ToggleButton
                  id='roster'
                  value='roster'
                  aria-label='roster'
                  sx={selectedTab === 'roster' ? styles.selectedTab : styles.unselectedTab}>
                  Roster
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </Box>
          {selectedMeeting?.center && (
            <Box display='flex' alignItems='center'>
              <CustomChip label={selectedMeeting.center} />
            </Box>
          )}
        </Stack>
      </Box>
      <Box>
        {selectedTab === 'summary' ? (
          <Summary
            isLoading={isLoading}
            summaryData={responseData.summary || {}}
            resourceData={responseData.resource}
            selectedMeeting={selectedMeeting}
            errorMessage={errorMessage}
          />
        ) : null}
        {selectedTab === 'roster' && 'roster' in responseData ? (
          <Roster
            isLoading={isLoading}
            rosterData={responseData.roster}
            selectedMeeting={selectedMeeting}
            errorMessage={errorMessage}
            updatedSearchParams={updatedSearchParams}
          />
        ) : null}
      </Box>
    </>
  );
};

export default React.memo(Tabs);
