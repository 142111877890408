import * as React from 'react';
import capitalize from 'lodash/capitalize';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { customTransform } from '../utils';
import styles from '../Datagrid.styles';

interface GridCellExpandProps {
  value: string;
  width: number;
  field: string;
  capitalCasing: boolean;
}

const isOverflown = (element: Element): boolean => {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
};

const GridCellExpand = React.memo((props: GridCellExpandProps) => {
  const { width, field, capitalCasing } = props;
  let { value } = props;
  value = customTransform(value, field);
  const wrapper = React.useRef<HTMLDivElement | null>(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current!);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    const handleKeyDown = (nativeEvent: KeyboardEvent) => {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: 1,
        height: 1,
        position: 'relative',
        display: 'flex'
      }}>
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...styles.datagridCellText
        }}
        /* If we want customize the cell through its parent, we can use this class from the parent */
        className='cellValue'>
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          placement='bottom'
          style={{ width, marginLeft: -17, zIndex: 1300 }}>
          <Paper
            elevation={1}
            sx={{
              maxHeight: 300,
              overflowY: 'auto',
              minHeight: wrapper.current!.offsetHeight - 3,
              borderRadius: '8px',
              p: 1,
              width: 400
            }}>
            <Typography variant='subtitle2' style={{ padding: 8 }}>
              {/* If the value is html or react component, render it directly */}
              {!(value as any).$$typeof && capitalCasing ? capitalize(value) : value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

const renderCellExpand = (params: GridRenderCellParams) => {
  const { field } = params;
  return (
    <GridCellExpand
      value={params.value || ''}
      width={params.colDef.computedWidth}
      capitalCasing={
        (params.colDef as any).capitalCasing === undefined
          ? true
          : (params.colDef as any).capitalCasing
      }
      field={field}
    />
  );
};

export default renderCellExpand;
