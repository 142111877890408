const pageRange = (currentPage: number, resultsLength: number) => {
  if (currentPage === 1) {
    return `1 - ${Math.min(currentPage * 100, resultsLength)}`;
  }
  return `${currentPage * 100 - 99} - ${Math.min(
    currentPage * 100,
    (currentPage - 1) * 100 + resultsLength
  )}`;
};

export default pageRange;
