/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const Heading = ({ feature, setModalOpen, searchObject }: any) => {
  if (feature === '505b2') {
    return (
      <Stack direction='row' alignItems='flex-end' mb={1}>
        <Divider
          orientation='vertical'
          sx={{
            fontSize: 14,
            padding: '5px',
            height: '100%',
            borderRightWidth: '2px'
          }}
        />
        <Stack direction='column' ml={1}>
          <Typography
            fontSize={20}
            color='grey.800'
            sx={{ fontFamily: 'Mulish', fontWeight: '500' }}>
            505b2
          </Typography>
          <Typography
            fontSize={12}
            color='black.lightVariant4'
            sx={{ fontFamily: 'Mulish', fontWeight: '500' }}>
            Showing Results for Applications Modified From{' '}
            <span
              style={{
                textTransform: 'capitalize',
                fontFamily: 'Mulish',
                fontWeight: '800'
              }}>
              {'search_term' in searchObject
                ? searchObject.search_term.replace('From', '').replace('TO', '->')
                : ''}
            </span>
          </Typography>
        </Stack>
        <Link
          component='button'
          onClick={() => {
            setModalOpen('505b2');
          }}
          sx={{
            cursor: 'pointer',
            ml: 2,
            pt: 1,
            fontSize: '12px',
            color: 'secondary.700',
            fontFamily: 'Mulish',
            fontWeight: '700'
          }}>
          New Search
        </Link>
      </Stack>
    );
  }
  if (feature === 'comparsion') {
    return (
      <Stack direction='row' alignItems='flex-end' mb={1}>
        <Divider
          orientation='vertical'
          sx={{
            fontSize: 14,
            height: '100%',
            borderRightWidth: '2px'
          }}
        />
        <Stack direction='column' ml={1}>
          <Typography
            fontSize={20}
            color='grey.800'
            sx={{ fontFamily: 'Mulish', fontWeight: '500' }}>
            Label Comparison
          </Typography>
          <Typography
            fontSize={12}
            color='black.lightVariant4'
            sx={{ fontFamily: 'Mulish', fontWeight: '500' }}>
            Select any two applications to compare labels
          </Typography>
        </Stack>
        <Link
          component='button'
          onClick={() => {
            setModalOpen('comparsion');
          }}
          sx={{
            cursor: 'pointer',
            ml: 2,
            pt: 1,
            fontSize: '12px',
            color: 'secondary.700',
            fontFamily: 'Mulish',
            fontWeight: '700'
          }}>
          New Search
        </Link>
      </Stack>
    );
  }
  return null;
};

export default React.memo(Heading);
