import { useCallback, useState } from 'react';
import { Auth } from 'aws-amplify';

import { sendFeedback } from '../../../api/pages/cdp';

const useFeedback: any = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const submit = useCallback(
    async (applicationNumber: string, tradeName: string, feedbackType: string, message: string) => {
      setLoading(true);
      // eslint-disable-next-line no-console
      const user = await Auth.currentUserInfo().catch(err => console.error(err));
      try {
        await sendFeedback({
          applicationNumber,
          tradeName,
          type: feedbackType,
          message,
          email: user?.attributes?.email
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Failed to send feedback', err);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    submit,
    isLoading
  };
};

export default useFeedback;
