import { CHART } from '../constants';
import getTopValuesForPieChart from './getTopValuesForPieChart';

const constructDonutChartStructure = (data: any) => {
  const formattedData: Array<any> = [];
  Object.entries(data).forEach(([key, values]: any) => {
    formattedData.push({ id: key, label: key, value: values });
  });
  return formattedData;
};

export const constructPieChartData = (data: any) => {
  const graphs: any = [];
  CHART.forEach((item: any) => {
    graphs.push({
      label: item.label,
      children: getTopValuesForPieChart(
        constructDonutChartStructure(data[item.graphItem]),
        item.apiKey,
        item.count
      ),
      apiKey: item.apiKey
    });
  });
  return graphs;
};

export default constructDonutChartStructure;
