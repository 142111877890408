import React from 'react';
import { createSvgIcon } from '@mui/material';

const LeftArrowIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='6' height='10' viewBox='0 0 6 10' fill='none'>
    <path
      d='M4.18321 9.47148L0.654542 5.94281C0.404581 5.69277 0.26416 5.35369 0.26416 5.00014C0.26416 4.64659 0.404581 4.30751 0.654542 4.05748L4.18321 0.528809L5.12588 1.47147L1.59988 5.00014L5.12854 8.52881L4.18321 9.47148Z'
      fill='#616161'
    />
  </svg>,
  'LeftArrow'
);

export default LeftArrowIcon;
