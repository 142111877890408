import React, { useCallback, useContext, useEffect, useMemo } from 'react';

// MUI
import Stack from '@mui/material/Stack';

// Custom Components
import { debounce } from 'lodash';
import VivproDatagrid from '../../../components/Datagrid';

// store
import DrugDevelopmentToolsStore from '../../../store/DrugDevelopmentTools';

// constants
import { CSV_FILENAME, DATA_GRID_COLUMNS } from '../constants';

// styles
import styles from '../../Guidance/styles/Details.styles';
import { getFileName } from '../../../helpers/getFileName';
import actions from '../../../store/DrugDevelopmentTools/actions';
import getDatagridFilterOption from '../../../utils/getDatagridFilterOption';
import { DATAGRID_OPTION_LIST } from '../../../components/Datagrid/constants';

const Details = ({
  pagination,
  setPagination,
  sort,
  setSort,
  sortFields,
  getDrugDevelopmentTools,
  isFilterUpdated,
  setIsFilterUpdated,
  getDatawithDataGridFilter,
  dataGridFilter
}: any) => {
  const { DrugDevelopmentToolsState, DrugDevelopmentToolsDispatch } = useContext(
    DrugDevelopmentToolsStore
  ) as any;

  useEffect(() => {
    if (isFilterUpdated) {
      setIsFilterUpdated(false);
    }
  }, [isFilterUpdated]);

  const fetchData = useCallback(
    (pageModel: any, sortModel: any, gridFilters: any) => {
      getDrugDevelopmentTools(
        DrugDevelopmentToolsState.availableFilters,
        pageModel.pageSize,
        pageModel.page * pageModel.pageSize,
        sortModel,
        false,
        gridFilters
      );
    },
    [DrugDevelopmentToolsState.availableFilters]
  );

  const handlePageChange = useCallback(
    (pageModel: any) => {
      if (!isFilterUpdated) {
        fetchData(pageModel, sortFields, dataGridFilter);
        setPagination(pageModel);
      }
    },
    [sortFields, DrugDevelopmentToolsState.availableFilters, isFilterUpdated, dataGridFilter]
  );

  const handleSortModelChange = useCallback(
    (newSortingModel: any) => {
      const modifiedList: any = [];

      newSortingModel.forEach((item: any) => {
        modifiedList.push(item);
      });

      if (JSON.stringify(modifiedList) !== JSON.stringify(DrugDevelopmentToolsState.sortFields)) {
        fetchData(pagination, modifiedList, dataGridFilter);
      }
      DrugDevelopmentToolsDispatch({
        type: actions.SET_SORT,
        value: modifiedList
      });

      setSort(modifiedList);
    },
    [
      pagination,
      sortFields,
      DrugDevelopmentToolsState.filters,
      DrugDevelopmentToolsState,
      actions,
      dataGridFilter
    ]
  );

  const renderableTableColumns = useMemo(() => {
    const renderableColumns = [...DATA_GRID_COLUMNS];

    return getDatagridFilterOption(
      renderableColumns,
      DATAGRID_OPTION_LIST,
      DrugDevelopmentToolsState
    );
  }, [DrugDevelopmentToolsState]);

  const handleFilterChange = useCallback(
    debounce(newFilterModel => {
      if (newFilterModel?.items?.length > 0) {
        getDatawithDataGridFilter(
          DrugDevelopmentToolsState.availableFilters,
          newFilterModel,
          sortFields,
          pagination.pageSize,
          pagination.page * pagination.pageSize
        );
      } else {
        fetchData(pagination, sortFields, {});
      }
    }, 300),
    [DrugDevelopmentToolsState.availableFilters, sortFields]
  );

  useEffect(() => {
    return () => {
      handleFilterChange.cancel();
    };
  }, [handleFilterChange]);

  return (
    <Stack sx={styles.root}>
      <VivproDatagrid
        rows={DrugDevelopmentToolsState.projects}
        columnsMapping={renderableTableColumns}
        rowId='project_number'
        csvFileName={getFileName(CSV_FILENAME)}
        loading={DrugDevelopmentToolsState.isLoading}
        rowCount={DrugDevelopmentToolsState.totalRecord}
        isPagination
        pagination={pagination}
        setPagination={handlePageChange}
        sortingModel={sort}
        handleSortModelChange={handleSortModelChange}
        isServerSideExport
        isFilterModeServer
        allowFilterPanelWithLogicOperators
        handleFilterModelChange={handleFilterChange}
      />
    </Stack>
  );
};

export default React.memo(Details);
