import { createSvgIcon } from '@mui/material';

const DocumentIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='15' viewBox='0 0 14 15' fill='none'>
    <g clipPath='url(#clip0_853_4452)'>
      <path
        d='M4.08366 7.5H9.91699V8.66667H4.08366V7.5ZM4.08366 11H8.16699V9.83333H4.08366V11ZM12.8337 4.92517V14.5H1.16699V2.25C1.16699 1.78587 1.35137 1.34075 1.67956 1.01256C2.00774 0.684374 2.45286 0.5 2.91699 0.5L8.40849 0.5L12.8337 4.92517ZM8.75033 4.58333H10.8422L8.75033 2.4915V4.58333ZM11.667 13.3333V5.75H7.58366V1.66667H2.91699C2.76228 1.66667 2.61391 1.72812 2.50451 1.83752C2.39512 1.94692 2.33366 2.09529 2.33366 2.25V13.3333H11.667Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_853_4452'>
        <rect width='14' height='14' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>,
  'DocumentIcon'
);
export default DocumentIcon;
