import { CSV_UNIQUE_FIELD } from './constants';

const getSortCSVData = (source: string, data: any) => {
  try {
    const field = CSV_UNIQUE_FIELD[source?.toLowerCase() as string];
    const sortedData = data.sort((a: any, b: any) => {
      return a[field].localeCompare(b[field]);
    });
    return sortedData;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return data;
  }
};

export default getSortCSVData;
