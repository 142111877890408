import { PIECHARTCOLOR } from '../../components/CustomComponents/Graphs/constant';
import { Filter } from '../../components/Filters';
import { FILTER_TYPE } from '../../components/NewFilters/constants';

export interface PediatricsFDAFilters extends Filter {
  apiRequestKey: string;
  apiResponseKey?: string;
}

export const PEDIATRICS_FDA_FILTERS = [
  {
    id: 1,
    label: 'Drug Name',
    value: 'drug_name',
    elasticSearchType: 'drug_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Sponsor',
    value: 'sponsor',
    elasticSearchType: 'sponsor_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Application Number',
    value: 'application_number',
    elasticSearchType: 'application_number',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Search Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Application Type',
    value: 'application_type',
    elasticSearchType: 'application_type',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Active Ingredient',
    value: 'active_ingredient',
    elasticSearchType: 'active_ingredient',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 7,
    label: 'Formulation',
    value: 'formulation',
    elasticSearchType: 'formulation',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 8,
    label: 'Route',
    value: 'route',
    elasticSearchType: 'route',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 9,
    label: 'Written Request Sponsor',
    value: 'wr_sponsor',
    elasticSearchType: 'wr_sponsor',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 10,
    label: 'Determination Date',
    value: 'determination_date',
    elasticSearchType: 'determination_date',
    filterType: FILTER_TYPE.YEAR_RANGE,
    showInBottomFilter: true
  }
];

export const barChartStyles = {
  colors: ['#B1BBE4'],
  padding: 0.8,
  labelTextColor: '#ffffff',
  axisText: '#A3A3A3',
  axisBottom: {
    tickRotation: 0
  }
};

export const pieChartStyles = {
  colors: PIECHARTCOLOR,
  pieLabel: true,
  pieLegend: false,
  innerRadius: 0.6,
  margin: { top: 20, right: 20, bottom: 20, left: 20 },
  enableLabels: 'both'
};

export const FDA_WR_REPORT_QUERIES = [
  {
    id: 'drugs_by_determination_year',
    title: {
      title: 'Drugs by Determination Year',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['determination_date'], agg_function: 'count', sort_on_axis: 'x' },
    graphStyle: {
      ...barChartStyles,
      axisLeft: {
        tickRotation: 0
      }
    }
  },
  {
    id: 'formulations',
    title: {
      title: 'Formulations',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['formulation'],
      agg_function: 'count',
      limit: 4
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'route',
    title: {
      title: 'Routes',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['route'],
      agg_function: 'count',
      limit: 4
    },
    graphStyle: pieChartStyles
  }
];

export const SUBMODULE = 'written_requests';

export const PAGINATION_DEFAULT_OPTIONS = {
  limit: 20,
  offset: 0
};

export const EXPORT_TYPE = {
  csv: 'csv',
  xlsx: 'xlsx'
};

export const FDA_WR_COLUMN_NAME_MAPPING = {
  application_type: 'Application Type',
  application_number: 'Application Number',
  active_ingredient: 'Active Ingredient',
  determination_date: 'Determination Date',
  indications: 'Indications and Usage',
  drug_name: 'Drug Name',
  formulation: 'Fornulation',
  route: 'Route',
  wr_sponsor: 'Written Requests Sponsor',
  sponsor_name: 'Sponsor name'
};
