import { createSvgIcon } from '@mui/material';

const AddCircleIcon = createSvgIcon(
  <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_135_11113)'>
      <path
        d='M8.5 0C6.91775 0 5.37104 0.469192 4.05544 1.34824C2.73985 2.22729 1.71447 3.47672 1.10897 4.93853C0.503466 6.40034 0.34504 8.00887 0.653721 9.56072C0.962403 11.1126 1.72433 12.538 2.84315 13.6569C3.96197 14.7757 5.38743 15.5376 6.93928 15.8463C8.49113 16.155 10.0997 15.9965 11.5615 15.391C13.0233 14.7855 14.2727 13.7602 15.1518 12.4446C16.0308 11.129 16.5 9.58225 16.5 8C16.4977 5.87897 15.6541 3.84547 14.1543 2.34568C12.6545 0.845886 10.621 0.00229405 8.5 0V0ZM8.5 14.6667C7.18146 14.6667 5.89253 14.2757 4.7962 13.5431C3.69987 12.8106 2.84539 11.7694 2.34081 10.5512C1.83622 9.33305 1.7042 7.99261 1.96144 6.6994C2.21867 5.40619 2.85361 4.21831 3.78596 3.28596C4.71831 2.35361 5.9062 1.71867 7.1994 1.46143C8.49261 1.2042 9.83305 1.33622 11.0512 1.8408C12.2694 2.34539 13.3106 3.19987 14.0431 4.2962C14.7757 5.39253 15.1667 6.68146 15.1667 8C15.1647 9.76752 14.4617 11.4621 13.2119 12.7119C11.9621 13.9617 10.2675 14.6647 8.5 14.6667ZM9.16667 7.33333H11.8333V8.66667H9.16667V11.3333H7.83334V8.66667H5.16667V7.33333H7.83334V4.66667H9.16667V7.33333Z'
        fill='#289D78'
      />
    </g>
    <defs>
      <clipPath id='clip0_135_11113'>
        <rect width='16' height='16' fill='white' transform='translate(0.5)' />
      </clipPath>
    </defs>
  </svg>,
  'AddCircle'
);

export default AddCircleIcon;
