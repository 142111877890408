// utils
import { MODULE_NAME_MAPPING_NAVIGATION } from '../utils/constants';

/**
 * funtion to get reg360 redirectUrl for aria results cards
 * @param {*} module
 * @param {*} designationType
 * @param {*} source
 * @param {*} applicationNumber
 * @returns
 */

const startsWithNumber = input => {
  const firstChar = input.charAt(0);
  return !Number.isNaN(parseInt(firstChar, 10));
};

const getReg360Url = (module, designationType, source, applicationNumber) => {
  let sourceToUse = source;
  if (source.toLowerCase() === 'eu') {
    applicationNumber = applicationNumber[0]?.split('-')[3];
  } else if (
    source?.toLowerCase() === 'hpra' &&
    (applicationNumber[0].startsWith('EMEA') || startsWithNumber(applicationNumber[0]))
  ) {
    sourceToUse = 'eu';
  }
  applicationNumber = encodeURIComponent(applicationNumber);

  return `/regulatory360/${
    MODULE_NAME_MAPPING_NAVIGATION[module]
  }/${applicationNumber}/${sourceToUse}?category=indications_and_usage${
    source === 'us' ? `&type=${designationType}` : ''
  }`;
};

export default getReg360Url;
