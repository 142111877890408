import React from 'react';

import Close from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AdvancedSearchAlert } from '../types';
import styles from '../styles/SearchBar.styles';

const AdvanceSearchAlert = ({ handleAlertClose }: AdvancedSearchAlert) => {
  return (
    <Snackbar
      open
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}>
      <Box sx={styles.alertBox}>
        <Stack display='flex' flexDirection='column'>
          <Typography textAlign='center'>Your search has too many criteria</Typography>
          <Typography textAlign='center' sx={styles.alertText}>
            Please simplify by reducing the categories selected or disabling synonyms
          </Typography>
        </Stack>
        <IconButton title='Close' onClick={handleAlertClose}>
          <Close />
        </IconButton>
      </Box>
    </Snackbar>
  );
};

export default React.memo(AdvanceSearchAlert);
