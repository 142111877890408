import { createSvgIcon } from '@mui/material';

const InfoIcon = createSvgIcon(
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6 14C7.5913 14 9.11742 13.3679 10.2426 12.2426C11.3679 11.1174 12 9.5913 12 8C12 6.4087 11.3679 4.88258 10.2426 3.75736C9.11742 2.63214 7.5913 2 6 2C4.4087 2 2.88258 2.63214 1.75736 3.75736C0.632141 4.88258 0 6.4087 0 8C0 9.5913 0.632141 11.1174 1.75736 12.2426C2.88258 13.3679 4.4087 14 6 14ZM5.0625 9.875H5.625V8.375H5.0625C4.75078 8.375 4.5 8.12422 4.5 7.8125C4.5 7.50078 4.75078 7.25 5.0625 7.25H6.1875C6.49922 7.25 6.75 7.50078 6.75 7.8125V9.875H6.9375C7.24922 9.875 7.5 10.1258 7.5 10.4375C7.5 10.7492 7.24922 11 6.9375 11H5.0625C4.75078 11 4.5 10.7492 4.5 10.4375C4.5 10.1258 4.75078 9.875 5.0625 9.875ZM6 5C6.19891 5 6.38968 5.07902 6.53033 5.21967C6.67098 5.36032 6.75 5.55109 6.75 5.75C6.75 5.94891 6.67098 6.13968 6.53033 6.28033C6.38968 6.42098 6.19891 6.5 6 6.5C5.80109 6.5 5.61032 6.42098 5.46967 6.28033C5.32902 6.13968 5.25 5.94891 5.25 5.75C5.25 5.55109 5.32902 5.36032 5.46967 5.21967C5.61032 5.07902 5.80109 5 6 5Z'
      fill='#959595'
    />
  </svg>,
  'Info'
);

export default InfoIcon;
