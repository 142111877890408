import palette from '../../../themev5/palette';

const notificationsListStyles = {
  listItem: {
    p: '12px 24px 12px 16px !important',
    '&.Mui-selected': {
      backgroundColor: `${palette.gray['100']} !important`
    },
    '&#read': {
      '& #notification-dot': {
        visibility: 'hidden !important'
      },
      '& #change-count': {
        backgroundColor: `${palette.gray['200']} !important`,
        color: `${palette.gray['800']} !important`
      },
      '& #count-info': {
        color: `${palette.gray['800']} !important`
      }
    }
  },
  itemAvatar: {
    display: 'flex',
    flexDirection: 'row'
  },
  notificationDot: {
    borderRadius: '50%',
    minHeight: 8,
    minWidth: 8,
    backgroundColor: `${palette.primary['500']} !important`
  },
  avatar: {
    height: '40px !important',
    width: '40px !important',
    borderRadius: '6.66px !important',
    mx: '16px',
    border: `0.833px solid ${palette.gray['200']} !important`,
    backgroundColor: `${palette.gray['100']} !important`,
    color: `${palette.gray['600']} !important`,
    '& svg': {
      height: '20px !important',
      width: '20px !important'
    },
    '& svg#flag': {
      height: '40px !important',
      width: '60px !important'
    },
    '& svg#application': {
      height: '30px !important',
      width: '50px !important'
    }
  },
  title: {
    color: `${palette.gray['800']} !important`,
    fontFamily: 'Mulish',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    fontFeatureSettings: "'dlig' on",
    textTransform: 'capitalize'
  },
  subTitle: {
    color: `${palette.gray['600']} !important`,
    fontFamily: 'Mulish',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    fontFeatureSettings: "'dlig' on",
    textTransform: 'capitalize'
  },
  overview: {
    color: `${palette.primary['800']} !important`,
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    fontFeatureSettings: "'dlig' on",
    textTransform: 'capitalize'
  },
  number: {
    display: 'flex',
    padding: '2px 4px',
    mr: '2px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '14px',
    borderRadius: '24px',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '100%',
    fontFeatureSettings: "'clig' off, 'liga' off",
    letterSpacing: '0.4px',
    backgroundColor: `${palette.primary['50']} !important`,
    color: `${palette.primary['700']} !important`
  },
  moreIcon: {
    my: 'auto',
    pr: '24px',
    height: '16px',
    width: '16px',
    '& svg': {
      height: '16px',
      width: '16px'
    }
  }
};
export default notificationsListStyles;
