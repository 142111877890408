/* eslint-disable no-console */
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';

// api
import { fetchChmpMeetings, fetchChmpMeetingsWithFilters } from '../../../api/pages/Chmp';

import useQuery from '../../../helpers/customHooks/queryParameter';
import { groupResourcesByType, getStatistics, getFiltersOptions } from '../utils/helpers';
import { monthOrder } from '../utils/constants';

const useChmpData = () => {
  const query = useQuery();
  const history = useHistory();
  const [chmpStats, setChmpStats] = useState<any>({});
  const [meetings, setMeetings] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedMeeting, setSelectedMeeting] = useState<any>({});
  const [meetingStats, setMeetingStats] = useState<any>({});
  const [selectedResources, setSelectedResources] = useState<any>({});
  const [allMeetingsByYear, setAllMeetingsByYear] = useState<any>({});
  const [chmpFilters, setChmpFilters] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied]: [boolean, Function] = useState(false);

  /**
   * function to fetch chmp meetings
   * @param userEmail
   */
  const getChmpMeetings = async (appliedFilters: any = {}) => {
    try {
      setLoading(true);

      setIsFilterApplied(Object.keys(appliedFilters).length !== 0);

      const res = isEmpty(appliedFilters)
        ? await fetchChmpMeetings()
        : await fetchChmpMeetingsWithFilters(appliedFilters);

      if (res?.status === 200) {
        const result: any = {};
        setChmpStats(res.data.body.stats);
        if (res.data.body?.meetings?.meetings?.length > 0) {
          res.data.body?.meetings?.meetings.forEach((meeting: any) => {
            // eslint-disable-next-line no-param-reassign
            meeting.overallStats = getStatistics(meeting?.statistics as any);
            const { year } = meeting;
            if (!result[year]) {
              result[year] = [];
            }
            result[year].push(meeting);
          });

          Object.entries(result).forEach(([key, value]: any) => {
            result[key] = value.sort((a: any, b: any) => {
              return monthOrder[b.month?.toLowerCase()] - monthOrder[a.month?.toLowerCase()];
            });
          });

          setAllMeetingsByYear(result);
          setMeetings(res.data.body.meetings.meetings);
          let tempSelectedMeeting = null;
          if (query.get('meetingId') && isEmpty(appliedFilters)) {
            const filteredArray = res.data.body.meetings.meetings.filter(
              (obj: any) => obj.meeting_id === query.get('meetingId')
            );
            // get the first element from the filtered array
            [tempSelectedMeeting] = filteredArray;
          } else {
            // Get the keys of the object and sort them in ascending order
            const sortedKeys = Object.keys(result).sort((a: string, b: string) =>
              a.localeCompare(b)
            );

            // Get the last key from the sorted array
            const latestYear = sortedKeys[sortedKeys.length - 1];
            // Get the first element from the result[latestYear]
            [tempSelectedMeeting] = result[latestYear];
          }

          setSelectedMeeting(tempSelectedMeeting);
          // set selected meeting stats
          const selectedMeetingStats = getStatistics(tempSelectedMeeting?.statistics as any);
          setMeetingStats(selectedMeetingStats);
          // set selected meeting resources
          const meetingResources = groupResourcesByType(tempSelectedMeeting.resources);
          setSelectedResources(meetingResources);

          // required for filters
          let mergedFilters = [];
          const newfilters = getFiltersOptions(res.data.body.meetings.filters);
          mergedFilters = newfilters;
          setChmpFilters(mergedFilters);

          const queryParams = new URLSearchParams();

          queryParams.set('meetingId', tempSelectedMeeting?.meeting_id);
          // Update the URL with the query parameters
          history.push(`?${queryParams.toString()}`);
        } else {
          setSelectedMeeting({});
          setMeetingStats({});
          setAllMeetingsByYear({});
          setMeetings([]);
          history.push('/chmp');
        }
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setSelectedMeeting({});
      setMeetingStats({});
      setAllMeetingsByYear({});
      setMeetings([]);
      console.error(err);
    }
  };

  const handleSelectMeeting = (meetingId: string) => {
    const filteredArray = meetings.filter((obj: any) => obj.meeting_id === meetingId);
    setSelectedMeeting(filteredArray[0]);
    const meetingResources = groupResourcesByType(filteredArray[0].resources);
    setSelectedResources(meetingResources);
    const selectedMeetingStats = getStatistics(filteredArray[0].statistics);
    setMeetingStats(selectedMeetingStats);
    const queryParams = new URLSearchParams();
    queryParams.set('meetingId', meetingId);

    // Update the URL with the query parameters
    history.push(`?${queryParams.toString()}`);
  };

  return {
    getChmpMeetings,
    meetings,
    selectedMeeting,
    setSelectedMeeting,
    handleSelectMeeting,
    chmpStats,
    allMeetingsByYear,
    chmpFilters,
    loading,
    selectedResources,
    isFilterApplied,
    meetingStats
  };
};

export default useChmpData;
