const styles = {
  dialog: {
    backdropFilter: 'blur(1px)',
    borderRadius: 3,
    '& .MuiDialog-paper': { borderRadius: 3 }
  },
  title: {
    color: 'gray.800',
    textAlign: 'center',
    fontSize: 20,
    lineHeight: 'normal',
    textTransform: 'capitalize',
    p: 1,
    pt: 2.5
  },
  backIcon: { position: 'absolute', top: 18, left: 24 },
  closeIcon: { position: 'absolute', top: 16, right: 24 },
  titleDivider: {
    width: 64,
    borderColor: 'gray.400',
    borderBottomWidth: 4,
    borderRadius: 1,
    mx: 'auto'
  },
  searchInputLabel: {
    color: 'gray.800',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '150%',
    pr: 1.5
  },
  searchInput: {
    '& .MuiInputBase-input': {
      px: 1.5,
      py: 1,
      fontSize: 14,
      borderColor: 'unset'
    },
    '& fieldset': {
      borderColor: 'inherit'
    }
  },
  searchInputElementProp: {
    borderRadius: 1.5,
    p: 0,
    borderColor: 'gray.400',
    height: 40
  },
  newButton: {
    fontSize: 16,
    fontWeight: 700,
    color: 'primary.600',
    textTransform: 'capitalize',
    py: 1,
    px: 0,
    lineHeight: 1,
    ml: 0.5,
    borderRadius: 6,
    justifyContent: 'end',
    '& .MuiButton-startIcon': {
      mr: 0.5
    },
    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  sendButton: {
    ml: 'auto',
    borderRadius: 6,
    bgcolor: 'primary.600',
    px: 4,
    py: 0.5,
    fontSize: 14,
    fontWeight: 700,
    textTransform: 'capitalize',
    color: 'white.main',
    '&:disabled': {
      border: 0,
      color: 'gray.green',
      bgcolor: 'primary.50'
    }
  },
  copyLink: {
    pl: 1,
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 0.2
  },
  copyLinkContainer: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: 'primary.600'
  },
  messageInput: {
    position: 'relative',
    '& .MuiInputBase-input': {
      px: 3,
      py: 1.5,
      fontSize: 14,
      border: 0,
      borderRadius: 2,
      color: 'gray.900',
      fontWeight: 500,
      bgcolor: 'gray.100',
      '&::placeholder': {
        color: 'gray.600',
        fontWeight: 400,
        opacity: 1
      }
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: 0,
      transform: 'translate(10%,100%)'
    }
  },
  messageInputProp: {
    p: 0,
    border: 0,
    borderRadius: 2,
    bgcolor: 'gray.100'
  },
  noBinder: { mt: 3, ml: 1, fontSize: 14, color: 'gray.800', fontWeight: 600 },
  loaderPosition: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  paper: {
    width: 600,
    height: 256,
    borderRadius: 5,
    p: 3,
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray.lightVariant3',
      borderRadius: '4px',
      opacity: '0.8'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    }
  },
  heading: { color: '#101010' }
};

export default styles;
