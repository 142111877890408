const AnimatedLogo = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    id='Layer_1'
    x={0}
    y={0}
    style={{
      enableBackground: 'new 0 0 1000 1000'
    }}
    viewBox='0 0 1000 1000'
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <style>{'.st1{fill:#fff}'}</style>
    <linearGradient
      id='SVGID_1_'
      x1={280.587}
      x2={738.024}
      y1={501.219}
      y2={501.219}
      gradientUnits='userSpaceOnUse'
    >
      <stop
        offset={0}
        style={{
          stopColor: 'rgb(41,171,226)'
        }}
      >
        <animate
          attributeName='stop-color'
          dur='2s'
          repeatCount='indefinite'
          values='#29ABE299; #29ABE24C; #29ABE2E6; #39B54A4D; #39B54A99; #39B54AE6;'
        />
      </stop>
      <stop
        offset={1}
        style={{
          stopColor: 'rgb(57,181,74)'
        }}
      >
        <animate
          attributeName='stop-color'
          dur='2s'
          repeatCount='indefinite'
          values='  #39B54AE6;#39B54A99;#39B54A4D; #29ABE2E6;#29ABE24C;#29ABE299;'
        />
      </stop>
    </linearGradient>
    <path
      d='M640.8 710.9c-4.5 0-8.3-3.3-11.1-6.2-16.9-16-34-31.8-51-47.7-3.5-3.3-7.7-5.7-12.4-7-33.3-9.9-69.7-22-91.9-50.3-7.8-11-6.8-17.8 2.8-27.4l65.2-65.2c5.8-5.8 9.4-12.4 10.2-20.9 1.1-12.7 15.3-25.2 27.7-25.1 13.1.1 27.7 13.3 29.1 26.5 1.1 10.1-5.1 27.2-21.4 30.3-11.9 2.2-21.3 7.9-29.4 16.5-13.5 14.4-27.5 28.4-40.9 42.9-8 8.6-8.2 10.5 1.9 16.2 38 22.9 89 44.7 128.3 12.4 13.6-9.7 36.5-27.4 50.1-56.7 22.3-56.4-5.7-125.2-74.1-121.6-13.4 1.2-21.3-20-10-26.2 10.7-5 6.4-12.1 3.5-17.9-8.5-17.2-19.4-33-36.3-43-37.7-22.5-90.7-18-120.6 15-16.7 16.7-32.7 45.6-17.9 68.4 9.8 16.1.1 38.7-18.3 42.1-23 3.8-42.6-20.7-32.4-39.3 6-12.7 9.6-8.2 14.4-36.1 1.1-6.4-2.3-10.8-8.4-10-93 10.9-117.7 138.4-32.4 179.5 7.3 3 12.7.2 17.1-4.2 47.2-46.7 94.3-93.4 140.1-141.4 4.3-4.3 5.1-9.8 6-14.9 3.2-17.9 14.9-24.1 27.5-25.6 11.7-1.3 27.6 13.3 29.6 25.8 2.4 14.4-10.7 30-28 32.5-6.1.9-10.9 3.3-15.2 7.4-49.9 47-97.7 96.2-145.4 145.5-16.8 17.3-18.2 16.4-39.6 6.9-42.3-18.9-67.5-52.2-75.3-98.2-13.8-74.5 51.8-147.8 126.8-144.9 10.4 0 17.2-2.5 24-9.9 17.6-19.2 39.8-33.6 64.8-40.8 18.7-6.2 35-9.1 55-4.4 49.5 7.6 86.8 45.9 100.6 92.7 2.1 6.9 5.1 10.7 12 13.1 47.9 14.4 74.5 62.1 72.1 109.8 4.2 64.8-40.7 112.3-91.3 135.7-7.6 3-12.7 5.7-8.6 10.6 9.4 11 19.2 21.6 29.2 32l8.1 8.3c5.4 5.5 1.5 14.8-6.3 14.8h-27.9z'
      style={{
        fill: 'url(#SVGID_1_)'
      }}
    />
    <path
      d='M557.8 413.3c-6.7-.2-10.8-4.6-10.6-11.3.3-6.7 4.9-11 11.5-10.4 6.6.6 10.1 4.6 10.4 11 .2 6.3-4.8 10.8-11.3 10.7zM419.1 427.9c6 .7 9 5.1 9.6 11.1.6 6.4-4.5 10.7-11.3 10.5-7-.2-9.9-4.4-10.2-10.8-.2-6.4 4.7-10.9 11.9-10.8zM582 478.8c6.1-.2 11.2 4.7 11 10.9-.3 6.4-3.6 10.4-10.3 10.5-6.6.1-10.4-3.2-10.7-9.9-.5-6.9 3.4-11.3 10-11.5z'
      className='st1'
    />
  </svg>
);
export default AnimatedLogo;
