import React, { useMemo, memo } from 'react';
import { Stack, Typography, Divider, Box, IconButton, Tooltip } from '@mui/material';
// styles
import { Close } from '@mui/icons-material';
import styles from '../styles/LatestPIP.styles';
// asset
import DocumentIcon from '../../../assets/svgs/PDCO/DocumentIcon';
import LeftLongArrowIcon from '../../../assets/svgs/PDCO/LeftLongArrow';
// components
import EmptyPage from '../../../components/EmptyPage/EmptyPage';

import { LABEL_NAMES, keyMapping, InputObject } from '../constants';

const LatestPIP = ({
  latestPIP,
  setViewLatestPIP,
  clearTimeline,
  setPdfPath,
  setDocTitle,
  setOpenDocument
}: any) => {
  const handleTimelineClick = () => {
    setPdfPath('');
    setDocTitle('');
    setOpenDocument(false);
    setViewLatestPIP(false);
  };

  const handlePDFViwer = () => {
    setPdfPath(latestPIP?.s3_url);
    setDocTitle(latestPIP?.file_name);
    setOpenDocument(true);
  };

  const handleClose = (event: any) => {
    event.stopPropagation();
    clearTimeline(false);
  };
  const LatestPIPObject = useMemo(() => {
    const result: { [key: string]: keyof InputObject } = {};
    Object.keys(keyMapping).forEach(key => {
      const inputKey = keyMapping[key];
      result[key] = latestPIP[inputKey];
    });
    return result;
  }, [latestPIP, keyMapping]);

  return (
    <>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{
          p: 2,
          backgroundColor: 'primary.600',
          height: '6%'
        }}>
        <Stack direction='row'>
          <Typography sx={styles.title}>{latestPIP?.decision_number || 'Latest PIP'}</Typography>
        </Stack>
        <Box>
          <Tooltip title='Close Chat'>
            <IconButton size='small' sx={styles.closeChat} onClick={event => handleClose(event)}>
              <Close fontSize='small' />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
      <Box sx={styles.latestPipContainer}>
        <Box sx={styles.headerBox} onClick={handleTimelineClick}>
          <LeftLongArrowIcon sx={{ fontSize: 14 }} />
          <Typography sx={styles.timelineButton}>Timeline View</Typography>
        </Box>
        <Box
          width='96%'
          onClick={handlePDFViwer}
          sx={{
            display: 'flex',
            gap: '6px',
            cursor: 'pointer'
          }}>
          <DocumentIcon sx={{ fontSize: '14px' }} />
          <Typography sx={styles.fileName}>{latestPIP?.file_name}</Typography>
        </Box>

        <Stack sx={styles.container}>
          {Object.keys(LatestPIPObject)?.length > 0 ? (
            <Stack sx={styles.gridContainer}>
              <Stack sx={styles.labelsIndicatorWrapper}>
                {Object.keys(LABEL_NAMES)?.map((label: string, labelIndex) => (
                  <Box key={label} sx={styles.section}>
                    <Stack sx={styles.section}>
                      <Typography sx={styles.sectionLabel}>{LABEL_NAMES[label]}</Typography>
                    </Stack>
                    {labelIndex !== Object.keys(LABEL_NAMES).length - 1 && (
                      <Divider sx={styles.sectionDivider} />
                    )}
                  </Box>
                ))}
              </Stack>
              <Stack sx={styles.companionDiagnosticDevicesWrapper}>
                <Stack spacing={2}>
                  {Object.keys(LABEL_NAMES)?.map((section, sectionIndex) => (
                    <Stack key={section} sx={styles.sectionWrapper}>
                      <Stack sx={styles.sectionContentWrapper}>
                        <Box sx={styles.sectionText}>{LatestPIPObject[section]}</Box>
                      </Stack>
                      {sectionIndex !== Object.keys(LABEL_NAMES).length - 1 && (
                        <Divider sx={styles.sectionDivider} />
                      )}
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <Stack height={250}>
              <EmptyPage message='No Latest PIP data available.' />
            </Stack>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default memo(LatestPIP);
