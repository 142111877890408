export default {
  SET_STATS: 'SET_STATS',
  SET_PIP_DETAILS: 'SET_PIP_DETAILS',
  SET_SHOW_LABEL_COMPARE: 'SET_SHOWLABEL_COMPARE',
  SET_LABEL_COMPARE_LIST: 'SET_LABEL_COMPARE_LIST',
  SET_SEARCH: 'SET_SEARCH',
  SET_PIP_DOWNLOAD_DATA: 'SET_PIP_DOWNLOAD_DATA',
  SET_FILTERS: 'SET_FILTERS',
  SET_IS_FILTERS_APPLIED: 'SET_IS_FILTERS_APPLIED',
  SET_LOADING: 'SET_LOADING',
  SET_STATS_LOADING: 'SET_STATS_LOADING',
  SET_AVAILABLE_FILTERS: 'SET_AVAILABLE_FILTERS',
  SET_TOTAL_COUNT: 'SET_TOTAL_COUNT',
  SET_SORT: 'SET_SORT',
  SET_ASK_RIA_FILTERS: 'SET_ASK_RIA_FILTERS',
  SET_DATA_GRID_FILTERS: 'SET_DATA_GRID_FILTERS'
};
