import { createSvgIcon } from '@mui/material';

const DocumentSolidEdges = createSvgIcon(
  <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_2842_67077)'>
      <path
        d='M8.40752 0.5H2.91602C2.45189 0.5 2.00677 0.684374 1.67858 1.01256C1.35039 1.34075 1.16602 1.78587 1.16602 2.25V14.5H12.8327V4.92517L8.40752 0.5ZM8.74935 2.4915L10.8412 4.58333H8.74935V2.4915ZM2.33268 13.3333V2.25C2.33268 2.09529 2.39414 1.94692 2.50354 1.83752C2.61293 1.72812 2.76131 1.66667 2.91602 1.66667H7.58268V5.75H11.666V13.3333H2.33268Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_2842_67077'>
        <rect width='14' height='14' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>,
  'DocumentSimple'
);

export default DocumentSolidEdges;
