export const searchStyle = {
  select: {
    width: 200
  },
  searchInput: {
    width: 300,
    padding: 0
  },
  button: {
    color: 'gray.main',
    fontSize: `body1.fontSize`
  },
  buttonReset: {
    color: 'gray.main',
    marginLeft: 2,
    marginRight: 2,
    fontSize: `body1.fontSize`
  },
  resetIcon: {
    marginRight: '2px',
    marginBottom: '2px'
  }
};
