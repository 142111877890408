/* eslint-disable import/prefer-default-export */
import { GridColDef } from '@mui/x-data-grid-pro';

export const Exclusivity: GridColDef[] = [
  {
    field: 'exclusivity_code',
    headerName: 'Code',
    headerClassName: 'table-header',
    width: 400,
    cellClassName: 'table-row'
  },
  {
    field: 'definition',
    headerName: 'Definition',
    headerClassName: 'table-header',
    width: 450,
    cellClassName: 'table-row'
  },
  {
    field: 'exclusivity_date',
    headerName: 'Exclusivity Date',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row'
  },
  {
    field: 'product_number',
    headerName: 'Product Number',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row'
  }
];
