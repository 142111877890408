import { createSvgIcon } from '@mui/material';

const ArrowUpIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='12' height='14' viewBox='0 0 12 14' fill='none'>
    <path
      d='M11.7072 4.87906L7.41418 0.586061C7.03913 0.211119 6.53051 0.000488281 6.00018 0.000488281C5.46985 0.000488281 4.96124 0.211119 4.58618 0.586061L0.293182 4.87906L1.70718 6.29306L5.00018 3.00006V14.0001H7.00018V3.00006L10.2932 6.29306L11.7072 4.87906Z'
      fill='currentColor'
    />
  </svg>,
  'ArrowUp'
);

export default ArrowUpIcon;
