import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetchResults from '../hooks/useFetchResults';

import Applications from './Applications';
import DocumentsView from './DocumentsView';
import ClinicalTrials from './ClinicalTrials';
import EntitySelectionWindow from './EntitySelectionWindow';
import NewFilters from '../../../components/NewFilters';
import { Category } from '../../../components/NewFilters/types';
import ResultsStore from '../../../store/SearchResults';
import {
  ALL_SECTIONS_FILTER_OPTIONS,
  BOTTOM_FILTERS_ORDER,
  CT_RESULTS_FILTERS,
  FILTER_TYPE,
  MAP_SINGLE_SOURCE_APPLICATION_FILTERS,
  QUICK_MULTISOURCE_APPLICATION_SEARCH_FILTERS,
  FILTER_LABEL_SECTION_US_MAPPING
} from '../../../components/NewFilters/constants';
import {
  formatDate,
  sortFilterCategoriesOptions,
  sortFilterCatgories
} from '../../../components/NewFilters/utils';
import { getAllowedApplicationFilters } from '../../../api/pages/ResultsPage';
import { decodeBase64ToObject } from '../../../utils/encodeDecodeObject';
import RESULT_VIEW_TYPES from './constants';
import AskRIA from '../../../components/NewFilters/AskRIA';
import AriaDrawer from './AriaDrawer';
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';
import ResultActions from '../../../store/SearchResults/actions';
import transformData from '../utils/dataTransform';
import triggerGlobalChatRIA from '../utils/trggerGlobalChatRia';
import getAriaFilters_ from '../utils/getAriaFilters';
import Loading from './Loading';
import { AriaApplications } from '../types/ariaDrawer.types';
import EmptyResult from './EmptyResult';
import { EMPTY_RESULTS_MESSAGES } from '../constants';
import { CHAT_RIA_ALLOWED_SOURCES } from '../../../components/ChatRIA/const';
import getCtSource from '../utils/getCtSource';
import getApplicationSource from '../utils/getApplicationSource';
import addOptions from '../utils/addFilterOptions';

const SearchResultsWrapper = () => {
  const { viewType, openEntityPopup, setOpenEntityPopup, entityList, entityClick } =
    useFetchResults();
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const { state, dispatch } = useContext(GlobalStore) as any;
  const [applications, setApplications] = useState<AriaApplications[]>([]);
  const [ctDocCount, setCtDocCount] = useState<number>(0);
  const [ctPageCount, setCtPageCount] = useState<number>(0);
  const [ariaModalOpen, setAriaModalOpen] = useState(false);
  const [documentCount, setDocumentCount] = useState<number>(0);

  const { payload }: any = useParams();
  const {
    source: sources,
    entity_category: entityCategory,
    search_type: searchType,
    search_term: searchTerm
  }: any = useMemo(() => decodeBase64ToObject(payload), [payload]);
  const [filters, setFilters] = useState([]);

  const checkDisableChatRIAInApplicationsView = useMemo(() => {
    const notDisable = Object.keys(resultsState.applicationResults).some(
      source =>
        CHAT_RIA_ALLOWED_SOURCES.includes(source) &&
        resultsState.applicationResults[source]?.total > 0
    );
    return !notDisable;
  }, [resultsState.applicationResults]);

  const isMultipleSourceSearch = () => {
    return (
      Object.keys(sources as Record<string, any>).length > 1 ||
      Object.values(sources as Record<string, any>)[0].length > 1
    );
  };

  const triggerChatRIA = () => {
    triggerGlobalChatRIA(applications, GlobalActions, dispatch, viewType);
  };

  const getAriaFilters = () => {
    const formattedAllowedFilters: any =
      getAriaFilters_(resultsState, sortFilterCategoriesOptions) || [];
    return formattedAllowedFilters;
  };

  // create filters and their options when no any filter is applied
  const getFilters = async (selectedFilters = {}, shouldCallAPI = true) => {
    if (!shouldCallAPI) {
      let results: any[] = [];
      let formattedAllowedFilters: any = [];
      let filtersData: any[] = [];
      if (viewType === RESULT_VIEW_TYPES.CT && !ariaModalOpen) {
        results = resultsState?.ctResults?.results;
        const ctSource = getCtSource(sources);
        filtersData = CT_RESULTS_FILTERS[ctSource];
        const filterOptions = resultsState?.ctFilters ?? {};
        filtersData?.forEach((each: any, index: any) => {
          if (each.filterType !== FILTER_TYPE.MULTI_SEARCH) {
            const tempOptions = new Set();
            if (results) {
              filterOptions[each.value]?.map((option: any) => tempOptions.add(option));
            } else if (
              // calculate filter from applied filters when response is empty
              each?.filterType === FILTER_TYPE.SELECT_OPTIONS &&
              resultsState?.filters?.[each?.elasticSearchType]
            ) {
              const filterValues = resultsState?.filters?.[each?.elasticSearchType];
              if (Array.isArray(filterValues) && filterValues?.length > 0) {
                filterValues?.map((value: any) => tempOptions.add(value));
              }
            }

            // removing empty string, null, and undefined data from filter category option
            const cleanedTempOptions = Array.from(tempOptions).filter(
              (item: any) => item !== '' && item !== null && item !== undefined
            );
            filtersData[index].options = cleanedTempOptions.sort((a, b) =>
              sortFilterCategoriesOptions(a, b)
            );
          }
        });

        formattedAllowedFilters = filtersData;
      } else if (viewType === RESULT_VIEW_TYPES.APPLICATION && !ariaModalOpen) {
        // application filters
        results = resultsState.applicationResults;

        // if multisource search, then filter according to allowed filters from response
        if (isMultipleSourceSearch()) {
          let allowedFilters = await getAllowedApplicationFilters({ source: sources });
          allowedFilters = allowedFilters?.data?.body?.result?.map((each: any) => each.id);
          formattedAllowedFilters = QUICK_MULTISOURCE_APPLICATION_SEARCH_FILTERS.filter(
            (
              each // for now hardcoded approval date for multiple source search but later it need to sent from backend
            ) => [...allowedFilters, 'approval_date']?.includes(each.value)
          );
        } else {
          const apiResponseSources = getApplicationSource(sources);
          formattedAllowedFilters = MAP_SINGLE_SOURCE_APPLICATION_FILTERS[apiResponseSources];
          // adding options for label section filter category
          const advanceSearchLabelSectionList = state.labelSectionListAdvanceSearch;
          const advanceSearchLabelSet = new Set(
            advanceSearchLabelSectionList.map((val: { id: string; label: string }) =>
              val.label.toLowerCase()
            )
          );
          // remove the All Label Section filter if have added label section from advance search
          if (
            advanceSearchLabelSet.size !== 0 &&
            !advanceSearchLabelSet.has('all label sections')
          ) {
            formattedAllowedFilters = formattedAllowedFilters?.filter((itemValue: any) => {
              return itemValue.value !== 'filter_label_sections';
            });
          }
          formattedAllowedFilters = formattedAllowedFilters?.filter((item: any, index: any) => {
            if (item.value === 'filter_label_sections') {
              // advanced search
              if (searchType === 'advanced') {
                // All Label Section is selected as category in advance search then display all filters
                if (
                  searchTerm?.includes('label_section_search') &&
                  apiResponseSources in ALL_SECTIONS_FILTER_OPTIONS
                ) {
                  // store the applied label section from advance search as filter on application page
                  formattedAllowedFilters[index].options =
                    ALL_SECTIONS_FILTER_OPTIONS[apiResponseSources];
                }
                // Indications is selected as category and source is us in advance search then display selected filters
                else if (
                  apiResponseSources === 'us' &&
                  searchTerm?.includes('indications_and_usage')
                ) {
                  formattedAllowedFilters[index].options = FILTER_LABEL_SECTION_US_MAPPING;
                }
              }
              // if quick search application results with category All label Sections and source US selectd
              // then display all filters in Label Sections category filter
              else if (
                entityCategory === 'label_section_search' &&
                apiResponseSources in ALL_SECTIONS_FILTER_OPTIONS
              ) {
                formattedAllowedFilters[index].options =
                  ALL_SECTIONS_FILTER_OPTIONS[apiResponseSources];
              }
              // if quick search application result with category Indication and source as US selected
              // then display selected filters in Label Sections category filter
              else if (apiResponseSources === 'us' && entityCategory === 'indications_and_usage') {
                formattedAllowedFilters[index].options = FILTER_LABEL_SECTION_US_MAPPING;
              }
            }

            if (item?.showFilter && typeof item?.showFilter === 'function') {
              if (searchType === 'advanced') {
                return item?.showFilter({ entityCategory, searchTerm });
              }
              return item?.showFilter({ entityCategory, searchTerm });
            }
            return true;
          });
        }

        // format the response according to the use
        filtersData = Object.keys(results).reduce((acc: any[], item: any) => {
          return [...acc, ...results[item].results];
        }, []);

        formattedAllowedFilters = formattedAllowedFilters?.map((each: any) => {
          // condition is added to check if we already have options added for label section filter category should not overwrite them
          return addOptions(each, filtersData, resultsState?.filters);
        });
      } else if (viewType === RESULT_VIEW_TYPES.DOCUMENT || ariaModalOpen) {
        // document filters
        formattedAllowedFilters = getAriaFilters();
      }

      // if single source then sort the filters based on default category order that need to be displayed in bottom
      if (!isMultipleSourceSearch()) {
        // viewType has been taken according to ariaModalOpen state to prevent the issue of bottom filters order wrong from application page to Ask RIA page
        const formattedViewType = ariaModalOpen ? RESULT_VIEW_TYPES.DOCUMENT : viewType;
        const defaultBottomFilters = formattedViewType
          ? BOTTOM_FILTERS_ORDER[
              `${formattedViewType}-${Object.keys(sources)[0]}-${
                sources[Object.keys(sources)[0]][0]
              }`
            ]
          : [];
        formattedAllowedFilters = formattedAllowedFilters?.sort((a: any, b: any) =>
          sortFilterCatgories(a.label, b.label, defaultBottomFilters)
        );
      }
      setFilters(formattedAllowedFilters || {});
    } else {
      resultsDispatch({
        type: ResultActions.SET_TOP_10_SUMMARY,
        value: []
      });
      resultsDispatch({ type: ResultActions.SET_RIA_RESPONSE_TITLE, value: 'Top Result' });
      resultsDispatch({ type: ResultActions.SET_SHOW_TOP_10_SUMMARY, value: false });
      let filtersForAria: any = {
        ...(resultsState?.ariaEntityIds &&
          resultsState?.ariaEntityIds?.length > 0 && { entity_id: resultsState.ariaEntityIds }), // add entity id list in payload only if it is present
        ...selectedFilters
      };

      // this is use case that we are selecting year but need to send the whole date which we are adding
      if (
        viewType === RESULT_VIEW_TYPES.APPLICATION &&
        !isMultipleSourceSearch() &&
        !!filtersForAria?.approval_date
      ) {
        const startDate = new Date(
          filtersForAria?.approval_date[0]?.length === 4
            ? filtersForAria?.approval_date[0]
            : filtersForAria?.approval_date[0].toString().slice(0, 4),
          0,
          1
        );
        const endDate = new Date(
          filtersForAria?.approval_date[1]?.length === 4
            ? filtersForAria?.approval_date[1]
            : filtersForAria?.approval_date[1].toString().slice(0, 4),
          12,
          0
        );
        filtersForAria = {
          ...filtersForAria,
          approval_date: [formatDate(startDate), formatDate(endDate)]
        };
      }
      if (ariaModalOpen) {
        resultsDispatch({ type: ResultActions.SET_ARIA_FILTERS, value: { ...filtersForAria } });
      } else {
        resultsDispatch({ type: ResultActions.SET_FILTERS, value: { ...filtersForAria } });
      }
    }
  };

  const isResultAvailable = () => {
    if (viewType === RESULT_VIEW_TYPES.APPLICATION) {
      return !!Object.keys(resultsState?.applicationResults)?.length;
    }
    if (viewType === RESULT_VIEW_TYPES.DOCUMENT) {
      return !!(
        Object.keys(resultsState?.documentResults)?.length &&
        resultsState.documentResults?.results?.length
      );
    }
    if (viewType === RESULT_VIEW_TYPES.CT) {
      return !!resultsState?.ctResults?.results?.length;
    }
    return false;
  };

  // used for AskRIA button stat count
  useEffect(() => {
    let totalDocumentCount = 0;
    Object.keys(resultsState.applicationResults).forEach((source: any) => {
      resultsState.applicationResults[source].results.forEach((result: any) => {
        totalDocumentCount += result.document_count;
      });
    });
    setDocumentCount(totalDocumentCount);
  }, [resultsState.applicationResults]);

  useEffect(() => {
    const sourceWiseApps = resultsState.applicationResults;
    const apps: any = transformData(sourceWiseApps);
    setApplications(apps);
    // if results data avaialble call for updated filters and
    // if no results data available we shouldn't update the filters as there will be empty option being displayed in filters
    if (isResultAvailable() || resultsState?.isSearchBySearchId) {
      getFilters({}, false);
      if (viewType === RESULT_VIEW_TYPES.CT && isResultAvailable()) {
        setCtPageCount(resultsState?.ctStats?.totalPages ?? 0);
        setCtDocCount(resultsState?.ctStats?.totalDocuments ?? 0);
      }
    }
  }, [
    resultsState.applicationResults,
    resultsState.documentResults,
    resultsState?.ctResults?.results,
    ariaModalOpen,
    resultsState?.isSearchBySearchId,
    resultsState?.viewType,
    resultsState?.filters
  ]);

  useEffect(() => {
    // for aria share
    const decodedPayload: any = decodeBase64ToObject(payload);

    const isAriaModalReady = state.showNewFilters && filters?.length > 0;
    const isItAriaShare =
      decodedPayload?.isAriaOpen && decodedPayload?.ariaSearchTerm && decodedPayload?.ariaSearchId;

    if (isAriaModalReady && isItAriaShare) {
      setAriaModalOpen(true);
    }
  }, [state.showNewFilters, filters?.length]);
  if (resultsState.isContentLoading) return <Loading showTips inContainer />;

  return (
    <>
      {!resultsState.isContentLoading && !viewType && (
        <EmptyResult
          firstMessage={EMPTY_RESULTS_MESSAGES.GeneralSearchEmptyMessage.firstMessage}
          secondMessage={EMPTY_RESULTS_MESSAGES.GeneralSearchEmptyMessage.secondMessage}
        />
      )}
      {viewType === RESULT_VIEW_TYPES.DOCUMENT ? <DocumentsView /> : null}
      {viewType === RESULT_VIEW_TYPES.APPLICATION ? <Applications /> : null}
      {viewType === RESULT_VIEW_TYPES.CT ? <ClinicalTrials /> : null}

      {viewType === 'entity_popup' ? (
        <EntitySelectionWindow
          openEntityPopup={openEntityPopup}
          setOpenEntityPopup={setOpenEntityPopup}
          entityList={entityList}
          handleEntityClick={entityClick}
        />
      ) : null}
      {!resultsState?.selectedDocument &&
        state.showNewFilters &&
        filters?.length > 0 &&
        // don't display the NewFilters section if we are landing in documents page after search and switching to Application page gives empty result
        (isResultAvailable() || Object.keys(resultsState?.filters)?.length > 0) && (
          <>
            <NewFilters
              data={filters as Category[]}
              applyFilters={getFilters}
              isLoading={resultsState.isContentLoading}
              selectedFilters={resultsState.filters}
            />
            {viewType === 'application' && (
              <AskRIA
                setModalOpen={setAriaModalOpen}
                triggerChatRIA={triggerChatRIA}
                globalDispatch={dispatch}
                state={state}
                documentCount={documentCount}
                disableChatRia={checkDisableChatRIAInApplicationsView}
              />
            )}
            {viewType === RESULT_VIEW_TYPES.CT && (
              <AskRIA
                setModalOpen={setAriaModalOpen}
                triggerChatRIA={triggerChatRIA}
                state={state}
                globalDispatch={dispatch}
                disableChatRia
                documentCount={ctDocCount}
              />
            )}
          </>
        )}
      {ariaModalOpen &&
        (resultsState?.viewType !== RESULT_VIEW_TYPES.CT ? (
          <AriaDrawer
            applications={applications}
            sources={sources}
            modalOpen={ariaModalOpen}
            setModalOpen={setAriaModalOpen}
            filters={filters}
            getFilters={getFilters}
            globalDispatch={dispatch}
            viewType={viewType}
          />
        ) : (
          <AriaDrawer
            applications={resultsState?.ctResults?.results}
            sources={sources}
            modalOpen={ariaModalOpen}
            setModalOpen={setAriaModalOpen}
            filters={filters}
            getFilters={getFilters}
            globalDispatch={dispatch}
            isCount
            DocCount={ctDocCount}
            PageCount={ctPageCount}
            viewType={viewType}
          />
        ))}
    </>
  );
};
export default memo(SearchResultsWrapper);
