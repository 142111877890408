import { memo } from 'react';
import { Box, Typography } from '@mui/material';

import styles from './styles/VideoTutorialCard.styles';
import { PlayButtonIcon } from '../../assets/svgs/Icons';

const VideoGuide = ({ video, handleExpandTutorial }: any) => {
  return (
    <Box sx={styles.container} onClick={() => handleExpandTutorial(video.id)}>
      <Box sx={styles.card}>
        <Typography sx={styles.howToTitle}>{video.howToTitle}</Typography>
        <Typography sx={styles.pageTitle}>{video.pageTitle}</Typography>
        <img src={video.thumbnail} alt={video.pageTitle} />
      </Box>
      <Box sx={styles.contentContainer}>
        <Typography sx={styles.title}>
          {video.title.slice(0, 40)} {video.title.length > 40 && '...'}
        </Typography>
        <Typography sx={styles.description}>
          {`${video.shortDescription.slice(0, 55)}...`}
        </Typography>
        <Typography sx={styles.videoLength}>{video.videoLength}</Typography>
      </Box>
      <Box className='playButtonContainer'>
        <PlayButtonIcon className='playButton' />
      </Box>
    </Box>
  );
};

export default memo(VideoGuide);
