export const loading = (open, dynamicSearchResultRes) => {
  return open && dynamicSearchResultRes.length === 0;
};

export const filterOptions = dynamicSearchResultRes => {
  return dynamicSearchResultRes;
};

export const handleDateChangeOnAcceptUtil = (newValue, selectedDate) => {
  try {
    const updated = selectedDate.toLocaleDateString().split('/');
    const close = newValue.toLocaleDateString().split('/');
    if (updated[0] === close[0] && updated[1] === close[1]) {
      return updated[2] === close[2] ? newValue : selectedDate || newValue;
    }
    return newValue;
  } catch (e) {
    // If for some reason the date comparision is not possible use the new date.
    return newValue;
  }
};

export default handleDateChangeOnAcceptUtil;
