export const rules = [
  { htmlTag: 'b', bbcodeTag: 'b' },
  { htmlTag: 'i', bbcodeTag: 'i' },
  { htmlTag: 'u', bbcodeTag: 'u' },
  { htmlTag: 's', bbcodeTag: 's' }
];

export interface Rule {
  htmlTag: string;
  bbcodeTag: string;
}
