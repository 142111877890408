const actions = {
  SET_CANADA_GUIDANCE_TABLE: 'SET_CANADA_GUIDANCE_TABLE',
  SET_CANADA_GUIDANCE_STATS: 'SET_CANADA_GUIDANCE_STATS',
  SET_CANADA_GUIDANCE_ERRORS: 'SET_CANADA_GUIDANCE_ERRORS',
  SET_DONUT_CHARTS: 'SET_DONUT_CHARTS',
  SET_FILTERS: 'SET_FILTERS',
  SET_ISSUE_DATE: 'SET_ISSUE_DATE',
  SET_ADOPTED_DATE: 'SET_ADOPTED_DATE',
  SET_LOADING: 'SET_LOADING',
  SET_IS_FILTERS_APPLIED: 'SET_IS_FILTERS_APPLIED',
  SET_AVAILABLE_FILTERS: 'SET_AVAILABLE_FILTERS',
  SET_SEARCH: 'SET_SEARCH',
  RESET_SEARCH: 'RESET_SEARCH',
  SET_DOCUMENT_TO_COMPARE: 'SET_DOCUMENT_TO_COMPARE',
  CLEAR_DOCUMENT_TO_COMPARE: 'CLEAR_DOCUMENT_TO_COMPARE',
  REMOVE_DOCUMENT_TO_COMPARE: 'REMOVE_DOCUMENT_TO_COMPARE',
  SET_COMPARE_ENABLED: 'SET_COMPARE_ENABLED',
  SET_COMPARE_POPUP_OPEN: 'SET_COMPARE_POPUP_OPEN',
  SET_TOTAL_COUNT: 'SET_TOTAL_COUNT',
  SET_SORT: 'SET_SORT',
  SET_ASK_RIA_FILTERS: 'SET_ASK_RIA_FILTERS',
  SET_DATA_GRID_FILTERS: 'SET_DATA_GRID_FILTERS'
};

export default actions;
