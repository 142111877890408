import { createSvgIcon } from '@mui/material';

const LettersIcon = createSvgIcon(
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_10703_4839)'>
      <path d='M25.0001 8.01611V13.0001H29.8601L25.0001 8.01611Z' fill='currentColor' />
      <path
        d='M22.9999 15V8H17.9999C17.2042 8 16.4412 8.31607 15.8786 8.87868C15.3159 9.44129 14.9999 10.2044 14.9999 11V27H29.9999V15H22.9999Z'
        fill='currentColor'
      />
      <path
        d='M13.0001 29.0005V12.0005C12.2045 12.0005 11.4414 12.3166 10.8788 12.8792C10.3162 13.4418 10.0001 14.2048 10.0001 15.0005V32.0005H26.0001V29.0005H13.0001Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_10703_4839'>
        <rect width='24' height='24' fill='white' transform='translate(8 8)' />
      </clipPath>
    </defs>
  </svg>,
  'LettersIcon'
);

export default LettersIcon;
