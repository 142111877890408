import { createSvgIcon } from '@mui/material';

const LinkIcon = createSvgIcon(
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_11073_25989)'>
      <path
        d='M7.17349 12.9756C6.62103 13.5133 5.87908 13.8119 5.10817 13.8067C4.33726 13.8016 3.59938 13.4931 3.05417 12.948C2.50896 12.403 2.20028 11.6652 2.19489 10.8943C2.18951 10.1234 2.48787 9.38135 3.02541 8.82874L5.51157 6.34082L4.68616 5.51599L2.20057 8.0039C1.4319 8.77296 1.00021 9.81588 1.00049 10.9032C1.00076 11.9906 1.43297 13.0333 2.20203 13.8019C2.97109 14.5706 4.01401 15.0023 5.10135 15.002C6.1887 15.0017 7.2314 14.5695 8.00007 13.8005L10.4862 11.3143L9.66141 10.4895L7.17349 12.9756Z'
        fill='currentColor'
      />
      <path
        d='M13.8006 2.20251C13.421 1.82032 12.9693 1.51725 12.4718 1.31088C11.9742 1.10451 11.4406 0.998928 10.902 1.00026C10.3636 0.998828 9.83028 1.10414 9.33285 1.3101C8.83543 1.51606 8.38376 1.81858 8.00398 2.20018L5.51489 4.68693L6.33973 5.51234L8.82764 3.02618C9.09922 2.75297 9.42228 2.53635 9.77815 2.38886C10.134 2.24137 10.5156 2.16593 10.9008 2.16693C11.4808 2.16712 12.0477 2.33926 12.5299 2.66159C13.012 2.98392 13.3878 3.44197 13.6097 3.97784C13.8316 4.5137 13.8896 5.10332 13.7765 5.67216C13.6633 6.241 13.3841 6.76353 12.974 7.17368L10.4878 9.65984L11.3132 10.4853L13.7994 8.00026C14.5671 7.2309 14.9983 6.18849 14.9985 5.10162C14.9988 4.01476 14.5679 2.97218 13.8006 2.20251Z'
        fill='currentColor'
      />
      <path
        d='M9.65981 5.51613L5.51562 9.66031L6.34058 10.4853L10.4848 6.34109L9.65981 5.51613Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_11073_25989'>
        <rect width='14' height='14' fill='white' transform='translate(1 1)' />
      </clipPath>
    </defs>
  </svg>,
  'LinkIcon'
);

export default LinkIcon;
