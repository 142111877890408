import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';

export interface AvatarProperties {
  title: string;
  shortForm: string;
  bgColor: string;
  textColor: string;
  showOnCard: boolean;
  showOnlyTrue: boolean;
}

const CircularAvatar = ({ avatarList, onCard }: any) => {
  return (
    <Stack direction='row' justifyContent='end'>
      {avatarList?.map((item: AvatarProperties) => {
        // Check if the showOnCard property is false, but we are rendering this component on a Card
        // Some approval tracks are to be shown on datagrid only and not on Cards
        const shouldRenderAvatar = item.showOnCard !== false || onCard !== true;
        const shouldRenderIfTrue = item?.showOnlyTrue ? item.bgColor === 'primary.100' : true;
        return shouldRenderAvatar && shouldRenderIfTrue ? (
          <Tooltip key={item.shortForm} title={item.title} sx={{ textTransform: 'capitalize' }}>
            <Avatar
              sizes='small'
              sx={{
                ml: 0.5,
                width: 20,
                height: 20,
                fontSize: 10,
                // REMS bubble has customised condition ,if its fieldName value is Not applicable,it should grey out.
                bgcolor: item.bgColor || 'gray.lightVariant5',
                transition: 'transform .2s',
                '&:hover': {
                  transform: 'scale(1.2)'
                }
              }}>
              <Typography
                variant='body1'
                color='gray.900'
                // We can not control the color from pallette here. So we are hardcoding it
                sx={{ color: `${item.textColor || '#212121'} !important`, fontSize: 10 }}>
                {item.shortForm}
              </Typography>
            </Avatar>
          </Tooltip>
        ) : null;
      })}
    </Stack>
  );
};

export default React.memo(CircularAvatar);
