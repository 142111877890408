import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

// MUI
import { Box } from '@mui/material';
// Styles
import styles from '../styles/Details.styles';
// Custom Components
import VivproDatagrid from '../../Datagrid';

// store
import store from '../../../store/EUCTRPediatrics';
import actions from '../../../store/EUCTRPediatrics/actions';
import { getFileName } from '../../../helpers/getFileName';

// constants
import { EUCTRPEDIATRICSCOLUMN } from '../const';

// hooks
import useEUCTRPediatricsData from '../hook/useEUCTRPediatricsData';

const EUCTRPediatricsDetails = () => {
  const [, setDataGridMessage] = useState('Loading...') as any;
  // eslint-disable-next-line no-unused-vars
  const { isLoading, isFiltersApplied, euctrPediatricsFilters, sortFields, getEUCTRPedData } =
    useEUCTRPediatricsData();
  const { euctrPediatricsState, euctrPediatricsDispatch } = useContext(store);

  const day = new Date();
  const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
  const filename = {
    prefix: 'ria',
    module: 'euctrPediatrics',
    postfix: date,
    additional: '',
    extension: 'csv'
  };
  const [sort, setSort] = useState([...sortFields]);
  const [pagination, setPagination] = useState({
    pageSize: 20,
    page: 0
  });

  const fetchData = useCallback(
    (pageModel: any, sortModel: any) => {
      getEUCTRPedData(
        euctrPediatricsState.availableFilters,
        pageModel.pageSize,
        pageModel.page * pageModel.pageSize,
        sortModel
      );
    },
    [euctrPediatricsState.availableFilters]
  );

  const handlePageChange = useCallback(
    (pageModel: any) => {
      fetchData(pageModel, sortFields);
      setPagination(pageModel);
      euctrPediatricsDispatch({
        type: actions.SET_PAGINATION,
        value: pageModel
      });
    },
    [sortFields, euctrPediatricsState.availableFilters]
  );

  const handleSortModelChange = useCallback(
    (newSortingModel: any) => {
      const modifiedList: any = [];

      newSortingModel.forEach((item: any) => {
        modifiedList.push(item);
      });

      if (JSON.stringify(modifiedList) !== JSON.stringify(euctrPediatricsState.sortFields)) {
        fetchData(pagination, modifiedList);
      }
      euctrPediatricsDispatch({
        type: actions.SET_SORT,
        value: modifiedList
      });

      setSort(modifiedList);
    },
    [
      pagination,
      sortFields,
      euctrPediatricsState.availableFilters,
      euctrPediatricsDispatch,
      actions
    ]
  );

  useEffect(() => {
    // If API has loaded the data but filtered or searched data is not present, show message
    if (euctrPediatricsState.tableData?.length > 0) {
      setDataGridMessage('No results found');
    }
    // if API data is empty
    if (euctrPediatricsState.tableData?.length === 0) {
      setDataGridMessage(`No Rows`);
    }
  }, [euctrPediatricsState.tableData]);

  return (
    <Box sx={styles.root}>
      <VivproDatagrid
        rows={euctrPediatricsState.tableData}
        columnsMapping={EUCTRPEDIATRICSCOLUMN}
        rowId='paediatric_trial_id'
        csvFileName={useMemo(() => getFileName(filename), [filename])}
        loading={euctrPediatricsState.loading}
        rowCount={euctrPediatricsState.totalRecord}
        isPagination
        pagination={euctrPediatricsState.pagination}
        setPagination={handlePageChange}
        sortingModel={sort}
        handleSortModelChange={handleSortModelChange}
      />
    </Box>
  );
};

export default React.memo(EUCTRPediatricsDetails);
