import { createSvgIcon } from '@mui/material';

const LocationIcon = createSvgIcon(
  <svg width='20' height='26' viewBox='0 0 20 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.99981 0.621826C4.77411 0.621826 0.52272 4.87322 0.52272 10.0989C0.52272 11.7716 0.964662 13.4161 1.80072 14.8546C1.99563 15.19 2.21351 15.5168 2.44841 15.826L9.58804 25.3783H10.4115L17.5512 15.8261C17.7861 15.5168 18.0039 15.19 18.1989 14.8547C19.035 13.4161 19.4769 11.7716 19.4769 10.0989C19.4769 4.87322 15.2255 0.621826 9.99981 0.621826ZM9.99981 13.2418C8.26681 13.2418 6.8569 11.8319 6.8569 10.0989C6.8569 8.36591 8.26681 6.956 9.99981 6.956C11.7328 6.956 13.1427 8.36591 13.1427 10.0989C13.1427 11.8319 11.7328 13.2418 9.99981 13.2418Z'
      fill='currentColor'
    />
  </svg>,
  'Location'
);

export default LocationIcon;
