import { createSvgIcon } from '@mui/material';

const OrphanDesignationsIcon = createSvgIcon(
  <svg width='41' height='40' viewBox='0 0 41 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_12767_4867)'>
      <path
        d='M19.7222 16.8521C19.7222 16.8521 14.1667 12.9221 14.1667 9.72206C14.1316 8.94897 14.4042 8.19347 14.9248 7.62084C15.4453 7.04822 16.1715 6.70508 16.9444 6.6665C17.7174 6.70508 18.4435 7.04822 18.9641 7.62084C19.4847 8.19347 19.7573 8.94897 19.7222 9.72206C19.6872 8.94897 19.9598 8.19347 20.4803 7.62084C21.0009 7.04822 21.7271 6.70508 22.5 6.6665C23.2729 6.70508 23.9991 7.04822 24.5197 7.62084C25.0402 8.19347 25.3128 8.94897 25.2778 9.72206C25.2778 12.9221 19.7222 16.8521 19.7222 16.8521ZM33.22 16.3121C32.6549 15.7965 31.9088 15.5254 31.1446 15.5581C30.3803 15.5908 29.6601 15.9246 29.1411 16.4865L25.2056 20.6387C25.2482 20.8728 25.2723 21.1098 25.2778 21.3476C25.273 22.4731 24.865 23.5596 24.1276 24.41C23.3902 25.2603 22.3724 25.8181 21.2589 25.9821L16.5467 26.6554L16.2311 24.4554L20.9444 23.7776C21.5545 23.6835 22.1068 23.3634 22.4918 22.8809C22.8767 22.3984 23.0661 21.7887 23.0223 21.173C22.9785 20.5573 22.7047 19.9806 22.2554 19.5574C21.806 19.1342 21.2139 18.8955 20.5967 18.8887H10.8333C9.94928 18.8887 9.10143 19.2399 8.47631 19.865C7.85119 20.4902 7.5 21.338 7.5 22.2221V29.9998C7.5 30.8839 7.85119 31.7317 8.47631 32.3569C9.10143 32.982 9.94928 33.3332 10.8333 33.3332H21.5711L33.4311 20.3832C33.937 19.8125 34.1984 19.066 34.1589 18.3044C34.1194 17.5428 33.7822 16.8273 33.22 16.3121Z'
        fill='currentColor'
      />
      <g clipPath='url(#clip1_12767_4867)'>
        <path
          d='M32.9075 13.143L30.8334 11.6185L28.7592 13.143L29.5556 10.681L27.4825 9.16631H30.042L30.8334 6.7002L31.6248 9.16631H34.1839L32.1112 10.681L32.9075 13.143Z'
          fill='currentColor'
        />
      </g>
      <g clipPath='url(#clip2_12767_4867)'>
        <path
          d='M12.9075 17.5873L10.8334 16.0629L8.75921 17.5873L9.5556 15.1254L7.48254 13.6106H10.042L10.8334 11.1445L11.6248 13.6106H14.1839L12.1112 15.1254L12.9075 17.5873Z'
          fill='currentColor'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_12767_4867'>
        <rect width='26.6667' height='26.6667' fill='white' transform='translate(7.5 6.6665)' />
      </clipPath>
      <clipPath id='clip1_12767_4867'>
        <rect width='6.66667' height='6.66667' fill='white' transform='translate(27.5 6.6665)' />
      </clipPath>
      <clipPath id='clip2_12767_4867'>
        <rect width='6.66667' height='6.66667' fill='white' transform='translate(7.5 11.1108)' />
      </clipPath>
    </defs>
  </svg>,
  'OrphanDesignations'
);

export default OrphanDesignationsIcon;
