/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
// mui
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';

// Satics
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { VIDEO_TUTORIALS } from '../../utils/constants';

// styles
import styles from './VideoItem.styles';
import themev5 from '../../../../themev5';
import { markdownToText } from '../../../../components/ChatRIA/component/MarkdownBox';

// api's
import { getSignedVideoLink } from '../../../../api/pages/VideoList';

// custom components
import VideoSuggestions from '../VideoSuggestion/VideoSuggestions';

const VideoItem = () => {
  const [videoLink, setvideoLink] = useState();
  const [videoData, setVideoData] = useState(null);
  const [expand, setExpand] = React.useState(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(async () => {
    const path = location.pathname.split('/');
    const id = path[path.length - 1];
    // eslint-disable-next-line eqeqeq
    const video = VIDEO_TUTORIALS.find(element => element.id.toString() === id.toString());
    const resp = await getSignedVideoLink(video.videoName);
    setvideoLink(resp.data.Success.uri);
    setVideoData(video);
  }, [location]);

  const backToVideos = useCallback(() => {
    history.push(`/help?content=videos`);
  }, []);

  const handleOpen = e => {
    e.preventDefault();
    setExpand(!expand);
  };

  const cleanText = text => {
    const div = document.createElement('div');
    div.innerHTML = text;
    return div.textContent || div.innerText || '';
  };

  return (
    <ThemeProvider theme={themev5}>
      <Stack
        spacing={1}
        direction='row'
        alignItems='center'
        sx={styles.back}
        onClick={backToVideos}>
        <ArrowBackIcon fontSize='small' />
        <Typography variant='subtitle1' sx={styles.navigationText}>
          Back to Video Tutorials
        </Typography>
      </Stack>
      {videoData && (
        <Grid container spacing={2} p={2}>
          <Grid item xs={7} lg={8}>
            <Stack p={2}>
              {videoLink && (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video
                  controls
                  src={videoLink}
                  controlsList='nodownload'
                  name={videoData.videoName}
                  style={{ borderRadius: '10px' }}
                />
              )}
              <Typography variant='subtitle2' sx={styles.tags}>
                {`#${videoData.tags.join(' #')}`}
              </Typography>
              <Typography variant='h3' sx={styles.title}>
                {videoData.title}
              </Typography>
              <Typography variant='body1' sx={styles.date}>
                {format(videoData.date, 'MMM dd, yyyy')}
              </Typography>
              <Stack sx={styles.secondary}>
                <Typography variant='h4' sx={styles.secondaryTitle}>
                  {videoData.secondaryTitle}
                </Typography>
                {videoData.content && (
                  <div style={{ alignItems: 'baseline' }}>
                    {!expand ? (
                      <Typography variant='subtitle1' sx={styles.description}>
                        {`${cleanText(markdownToText(videoData.content.slice(0, 300)))}...`}
                        <Link onClick={handleOpen} style={{ color: 'blue', cursor: 'pointer' }}>
                          {expand ? 'less' : 'more'}
                        </Link>
                      </Typography>
                    ) : (
                      <>
                        <Typography
                          variant='subtitle1'
                          dangerouslySetInnerHTML={{ __html: videoData.content }}
                          sx={styles.description}
                        />
                        <Link
                          onClick={handleOpen}
                          style={{ color: 'blue', cursor: 'pointer', marginLeft: '-4px' }}>
                          {expand ? 'less' : 'more'}
                        </Link>
                      </>
                    )}
                  </div>
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={5} lg={4}>
            <VideoSuggestions video={videoData} />
          </Grid>
        </Grid>
      )}
    </ThemeProvider>
  );
};

export default React.memo(VideoItem);
