import React, { Fragment } from 'react';
//material ui imports
import HomeReportStyles from '../styles/HomeReport.styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { Typography } from '@mui/material';
//other components
import Graph from '../../../pages/Core/components/Graphs';

const HomeReport = ({ data }) => {
  const classes = HomeReportStyles();

  return (
    <Fragment>
      <Box display='flex' flexDirection='column' className={classes.graphs}>
        <Paper elevation={0} className={classes.paper}>
          <Typography className={classes.insightsTypo}>Insights</Typography>
          <Graph data={data} height={'65vh'} />
        </Paper>
      </Box>
    </Fragment>
  );
};

export default HomeReport;
