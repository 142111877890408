const actions = {
  SET_EUCTR_PEDIATRICS_TABLE: 'SET__SUR_TABLE',
  SET_EUCTR_PEDIATRICS_STATS: 'SET_EUCTR_PEDIATRICS_STATS',
  SET_EUCTR_PEDIATRICS_ERRORS: 'SET_EUCTR_PEDIATRICS_ERRORS',
  SET_FILTERS: 'SET_FILTERS',
  RESET_FILTERS: 'RESET_FILTERS',
  SET_SEARCH: 'SET_SEARCH',
  RESET_SEARCH: 'RESET_SEARCH',
  SET_LOADING: 'SET_LOADING',
  SET_AVAILABLE_FILTERS: 'SET_AVAILABLE_FILTERS',
  SET_IS_FILTERS_APPLIED: 'SET_IS_FILTERS_APPLIED',
  SET_TOTAL_COUNT: 'SET_TOTAL_COUNT',
  SET_SORT: 'SET_SORT',
  SET_PAGINATION: 'SET_PAGINATION'
};

export default actions;
