import { useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { NumberRangeProps } from './types';
import { validateMinMaxValues } from './utils';

const NumberRange = ({
  selectedCategory,
  selectedCategoryOptions,
  setSelectedCategoryOptions,
  isInputError,
  setIsInputError,
  minValue = undefined,
  maxValue = undefined
}: NumberRangeProps) => {
  const [values, setValues] = useState({
    min: '',
    max: ''
  });
  const [errorMessage, setErrorMessage] = useState<string>();

  const selectedCategoryValueKey = selectedCategory?.elasticSearchType || selectedCategory?.value;

  useEffect(() => {
    setValues({
      min: selectedCategoryOptions[selectedCategoryValueKey]
        ? selectedCategoryOptions[selectedCategoryValueKey][0]
        : '',
      max: selectedCategoryOptions[selectedCategoryValueKey]
        ? selectedCategoryOptions[selectedCategoryValueKey][1]
        : ''
    });
  }, [selectedCategoryOptions]);

  const handleChange = (ev: any) => {
    // don't allow non-numeric values
    if (Number.isNaN(ev.target.value * 1)) return;

    // get value without leading zeros
    const value = ev.target.value?.length ? ev.target.value * 1 : ev.target.value;
    const valueName = ev.target.name;

    const newValues = { ...values, [ev.target.name]: value };
    setValues(newValues);

    // Delete the approval time range if the values are reset.
    if (
      !(Number.isInteger(newValues.min) || newValues.min?.length) &&
      !(Number.isInteger(newValues.max) || newValues.max?.length)
    ) {
      // eslint-disable-next-line no-param-reassign
      delete selectedCategoryOptions[selectedCategoryValueKey];
      setSelectedCategoryOptions({
        ...selectedCategoryOptions
      });
    } else {
      setSelectedCategoryOptions({
        ...selectedCategoryOptions,
        [selectedCategoryValueKey]:
          valueName === 'min' ? [value, values?.max || null] : [values?.min || null, value]
      });
    }
  };

  useEffect(() => {
    const errorMsg = validateMinMaxValues(
      // @ts-ignore
      { min: values.min * 1, max: values.max * 1 },
      minValue,
      maxValue
    );
    setErrorMessage(errorMsg);
    setIsInputError(Boolean(errorMsg));
  }, [setIsInputError, values]);

  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' justifyContent='space-between' gap={2}>
        <TextField
          size='small'
          type='tel'
          variant='outlined'
          label='Min'
          name='min'
          placeholder='Min'
          error={isInputError}
          value={values.min}
          InputLabelProps={{ shrink: true }}
          onChange={(ev: any) => handleChange(ev)}
          sx={{ background: 'white' }}
          inputProps={{ min: minValue, maxLength: maxValue ? `${maxValue}`.length + 1 : undefined }}
        />
        <TextField
          size='small'
          type='tel'
          variant='outlined'
          label='Max'
          name='max'
          placeholder='Max'
          error={isInputError}
          value={values.max}
          InputLabelProps={{ shrink: true }}
          onChange={(ev: any) => handleChange(ev)}
          sx={{ background: 'white' }}
          inputProps={{ min: minValue, maxLength: maxValue ? `${maxValue}`.length + 1 : undefined }}
        />
      </Box>
      <Typography align='center' variant='caption' color='error'>
        {isInputError && errorMessage}
      </Typography>
    </Box>
  );
};

export default NumberRange;
