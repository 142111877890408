// fonts

/**
 * this object will contain all the custom variants for a material ui component
 * for each MUI compoent we can create an array of new variants
 */
const components = {
  MuiCssBaseline: {
    styleOverrides: `
          @font-face {
            font-family: 'Mulish';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
          }
        `
  },
  MuiAutocomplete: {
    variants: [
      {
        props: { variant: 'ariaSearch' },
        style: {
          padding: 10,
          backgroundColor: '#F0F2FD',
          borderRadius: 10
        }
      }
    ]
  }
};

export default components;
