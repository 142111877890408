import { createSvgIcon } from '@mui/material';

const JapanFlagIcon = createSvgIcon(
  <svg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0.2 0.2H17.8V11.8H0.2V0.2Z' fill='#F5F5F5' stroke='#BDBDBD' strokeWidth='0.4' />
    <path
      d='M9 10C11.2091 10 13 8.20914 13 6C13 3.79086 11.2091 2 9 2C6.79086 2 5 3.79086 5 6C5 8.20914 6.79086 10 9 10Z'
      fill='#D30000'
    />
  </svg>,
  'JapanFlagIcon'
);

export default JapanFlagIcon;
