/* eslint-disable no-param-reassign */
import React, { useCallback, useContext, useEffect, useState } from 'react';

// material ui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Popover from '@mui/material/Popover';
import LoadingButton from '@mui/lab/LoadingButton';

import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import GlobalStore from '../../store';
import Actions from '../../store/actions';

import FilterSelect, { Option } from './FilterSelect';
import YearRange from './YearRange';
import DateRange from './DateRange';
import RadioGroup from './RadioGroup';
import AutoSearchOptions from '../Index/AutoSearch/AutoSearch';
import KeywordSearch from './KeywordSearch';

import styles from './styles';

// add date filters, make UI changes. Done.
// eslint-disable-next-line react/prop-types
interface DateRangeValue {
  startDate?: string | Date;
  endDate?: string | Date;
}

export interface Filter {
  id: string;
  label: string;
  filterType: 'date_range' | 'year_range' | 'select_options' | 'radio_group' | 'keyword_search';
  options?: Array<Option>;
  value?: any;
}

interface RadioGroupProps {
  radioButtonStyle?: any;
}

export interface ElementProps {
  wrapperStyle?: any;
  titleElement?: HTMLElement | React.ReactElement;
  scrollContainerStyle?: any;
  selectedChipStyle?: any;
  radioGroupProps?: RadioGroupProps;
  applyButtonStyle?: any;
}

export interface FiltersComponentProps {
  // eslint-disable-next-line no-unused-vars
  onApply: (filters: Array<Filter>) => void;
  onClear: () => void;
  loading: boolean;
  // eslint-disable-next-line no-unused-vars
  setFilterValidity: (val: boolean) => void;
  filters: Array<Filter>;
  // eslint-disable-next-line react/require-default-props
  elementProps?: ElementProps;
}

const Filters: React.FC<FiltersComponentProps> = ({
  onApply,
  onClear,
  loading,
  setFilterValidity,
  filters = [],
  elementProps = {} as ElementProps
}: FiltersComponentProps) => {
  const { dispatch } = useContext(GlobalStore) as any;
  const [openPopup, setOpenPopup] = useState(false);
  const [anchorEle, setAnchor] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<Filter | undefined>();
  const [currentText, setCurrentText] = useState('');
  const [filtersState, setFilters] = useState<Array<Filter>>([]);

  let showCategoriesTitle = false;

  useEffect(() => {
    setFilters(cloneDeep(filters));
  }, [filters]);

  useEffect(() => {
    setFilterValidity?.(isEqual(filtersState, filters));
  }, [filtersState, filters, setFilterValidity]);

  const clearFilters = useCallback(() => {
    onClear();
    setFilterValidity(true);
  }, []);

  const applyFilters = useCallback(() => {
    onApply(filtersState);
    setFilterValidity(true);
  }, [filtersState]);

  const handleAddClick = useCallback(
    (e: any, filterId: string) => {
      setAnchor(e.currentTarget);
      setSelectedFilter(filtersState.find((fltr: Filter) => fltr.id === filterId));
      setOpenPopup(true);
    },
    [filtersState]
  );

  const handleOptionChange = useCallback(
    (e: any, optionItem: any) => {
      const newFilters = [...filtersState];
      const updatedFilter = newFilters.find((fltr: Filter) => fltr.id === selectedFilter?.id);
      if (updatedFilter) {
        updatedFilter.options = updatedFilter.options?.map((opt: any) => {
          if (opt.value === optionItem.value) {
            return { ...opt, selected: !opt.selected };
          }
          return opt;
        });
        setFilters(newFilters);
      }
    },
    [filtersState, selectedFilter]
  );

  const handleOptionRemove = useCallback(
    (filterId: string, option: any) => {
      const newFilters = [...filtersState];
      const updatedFilter = newFilters.find((fltr: Filter) => fltr.id === filterId);
      if (updatedFilter) {
        updatedFilter.options = updatedFilter.options?.map((opt: any) => {
          if (opt.value === option.value) {
            return { ...opt, selected: false };
          }
          return opt;
        });
        setFilters(newFilters);
      }
    },
    [filtersState]
  );

  const handleYearSelectionChange = useCallback(
    (filterId: string, date: any, startDate: boolean = true) => {
      const newFilters = [...filtersState];
      const updatedFilter = newFilters.find((fltr: Filter) => fltr.id === filterId);
      if (updatedFilter) {
        if (startDate) {
          updatedFilter.value = {
            ...(updatedFilter.value as DateRangeValue),
            startDate: date
          };
        } else {
          updatedFilter.value = {
            ...(updatedFilter.value as DateRangeValue),
            endDate: date
          };
        }
      }
      setFilters(newFilters);
    },
    [filtersState]
  );

  const handleDateSelectionChange = useCallback(
    (filterId: string, date: any, startDate: boolean = true) => {
      const newFilters = [...filtersState];
      const updatedFilter = newFilters.find((fltr: Filter) => fltr.id === filterId);
      if (updatedFilter) {
        if (startDate) {
          updatedFilter.value = {
            ...(updatedFilter.value as DateRangeValue),
            startDate: date
          };
        } else {
          updatedFilter.value = {
            ...(updatedFilter.value as DateRangeValue),
            endDate: date
          };
        }
      }

      setFilters(newFilters);
    },
    [filtersState]
  );

  const handleRadioGroupSelectionChange = useCallback(
    (filterId: string, value: string) => {
      const newFilters = [...filtersState];
      const updatedFilter = newFilters.find((fltr: Filter) => fltr.id === filterId);
      if (updatedFilter) {
        updatedFilter.value = value;
        setFilters(newFilters);
      }
    },
    [filtersState]
  );

  const handleSearch = useCallback(
    async (e: any, filterId: string) => {
      e.preventDefault();
      const newFilters = [...filtersState];
      const updatedFilter = newFilters.find((fltr: Filter) => fltr.id === filterId);
      if (currentText === '') {
        await dispatch({
          type: Actions.SET_ALERT,
          value: {
            message: 'You have selected an empty keyword.Please enter a keyword',
            status: true
          }
        });
        setCurrentText('');
        return;
      }
      if (updatedFilter) {
        if (updatedFilter.value?.includes(currentText)) {
          await dispatch({
            type: Actions.SET_ALERT,
            value: {
              message: 'Keyword has been already selected.Please enter a new keyword',
              status: true
            }
          });
          setCurrentText('');
        } else {
          updatedFilter.value.push(currentText);
        }
        setFilters(newFilters);
      }
    },
    [currentText]
  );

  const removeFromKeywords = useCallback(
    (keyword: string, filterId: string) => {
      const newFilters = [...filtersState];
      const updatedFilter = newFilters.find((fltr: Filter) => fltr.id === filterId);
      if (updatedFilter) {
        if (updatedFilter.value?.includes(keyword)) {
          updatedFilter.value = updatedFilter.value.filter((item: any) => item !== keyword);
        }
        setFilters(newFilters);
      }
    },
    [filtersState]
  );
  return (
    <Stack spacing={2} width='320px' sx={{ ...styles.root, ...(elementProps?.wrapperStyle || {}) }}>
      {/* title and action button */}
      <Stack direction='row' display='flex' justifyContent='space-between' width='100%'>
        <>
          {elementProps.titleElement ? (
            elementProps.titleElement
          ) : (
            <Typography sx={styles.title} variant='subtitle1' pr={10}>
              Filters
            </Typography>
          )}

          <Typography
            onClick={() => {
              if (!loading) clearFilters();
            }}
            display='inline-block'
            ml='auto'
            variant='subtitle1'
            pr={1}
            fontWeight={600}
            sx={{ ...styles.clearText, textDecorationThickness: '2px' }}>
            Clear Filters
          </Typography>
        </>
      </Stack>

      {/* filter categories */}
      <Stack
        mt={2}
        spacing={2}
        sx={{ ...styles.scrollContainer, ...(elementProps?.scrollContainerStyle || {}) }}>
        {filtersState.map(item => {
          // remove blank options
          if (item?.options) {
            item.options = item.options.filter((opt: Option) => {
              return opt.label !== '';
            });
          }
          if (item.filterType === 'year_range') {
            return (
              <YearRange
                key={item.id}
                label={item.label}
                minDate={new Date(1900, 1, 1)}
                maxDate={new Date(2100, 1, 1)}
                disabled={loading}
                startValue={(item.value as DateRangeValue)?.startDate as Date}
                endValue={(item.value as DateRangeValue)?.endDate as Date}
                onStartDateChange={date => handleYearSelectionChange(item.id, date, true)}
                onEndDateChange={date => handleYearSelectionChange(item.id, date, false)}
              />
            );
          }
          if (item.filterType === 'date_range') {
            return (
              <DateRange
                key={item.id}
                label={item.label}
                minDate={new Date(1900, 1, 1)}
                maxDate={new Date(2100, 1, 1)}
                disabled={loading}
                startValue={(item.value as DateRangeValue)?.startDate as Date}
                endValue={(item.value as DateRangeValue)?.endDate as Date}
                onStartDateChange={date => handleDateSelectionChange(item.id, date, true)}
                onEndDateChange={date => handleDateSelectionChange(item.id, date, false)}
              />
            );
          }
          if (item.filterType === 'radio_group') {
            return (
              <RadioGroup
                key={item.id}
                id={item.id}
                label={item.label}
                value={item.value as string}
                options={item.options as any}
                disabled={loading}
                onChange={e => handleRadioGroupSelectionChange(item.id, e.target.value)}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...(elementProps?.radioGroupProps || {})}
              />
            );
          }
          if (item.filterType === 'keyword_search') {
            return (
              <KeywordSearch
                key={item.id}
                id={item.id}
                label={item.label}
                values={item.value as any}
                disabled={loading}
                setCurrentText={setCurrentText}
                onRemoveSelected={(keyword: string) => removeFromKeywords(keyword, item.id)}
                handleSearch={(e: any) => handleSearch(e, item.id)}
              />
            );
          }

          // eslint-disable-next-line no-return-assign
          return (
            <React.Fragment key={item.id}>
              {/* The conditional statement ensures that the 'Select Categories' title is only rendered once, before the first 'FilterSelect' component. 
                  The assignment is needed as local state can't be maintained between loop iteration and we need to track the 'Select Catgories' title has been rendered once.
              */}
              {!showCategoriesTitle && (showCategoriesTitle = true) && (
                <Typography variant='subtitle1' color='primary' fontWeight={600}>
                  Select Categories
                </Typography>
              )}
              <FilterSelect
                key={item.id}
                id={item.id}
                options={item.options?.filter((opt: Option) => opt.label !== '') ?? []}
                label={item.label}
                disabled={loading}
                onAddClick={handleAddClick}
                onRemoveSelected={(option: Option) => handleOptionRemove(item.id, option)}
                selectedChipStyle={elementProps?.selectedChipStyle}
              />
            </React.Fragment>
          );
        })}
      </Stack>

      {/* apply filters action button */}
      <LoadingButton
        variant='contained'
        onClick={applyFilters}
        loadingPosition='end'
        loading={loading}
        sx={{
          m: '10px 24px 10px 0px !important',
          textTransform: 'capitalize',
          fontSize: 16,
          borderRadius: 10,
          bgcolor: 'primary.main',
          color: 'white.main',
          cursor: 'pointer',
          ...(elementProps?.applyButtonStyle || {})
        }}>
        Apply
      </LoadingButton>

      {/* options popup */}
      <Popover
        disableEnforceFocus
        id='popup'
        sx={{ width: '100%', minWidth: 400, borderRadius: 2, padding: 16 }}
        open={openPopup}
        anchorEl={anchorEle as any}
        onClose={() => setOpenPopup(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <Box sx={styles.popoverBox}>
          <AutoSearchOptions
            searchOptions={selectedFilter?.options || []}
            filterState={{}}
            handleCheckboxChange={handleOptionChange}
          />
        </Box>
      </Popover>
    </Stack>
  );
};

export default React.memo(Filters);
