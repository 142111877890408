/* eslint-disable react/require-default-props */
import React from 'react';
import { Box, Typography, Stack, Tooltip } from '@mui/material';

interface GridRenderCellParams {
  value?: Array<string>;
  row?: any;
}
const DatagridIdentifier = (props: GridRenderCellParams = { value: [], row: {} }) => {
  const { value, row } = props;

  return (
    <Box display='flex'>
      <Stack direction='row' spacing={1} alignItems='center'>
        <Typography
          p={1}
          pt={0.5}
          pb={0.5}
          display='inline-block'
          bgcolor='gray.background'
          color='black.gravel'
          borderRadius={2}
          fontWeight={700}>
          {value}
        </Typography>
        {row?.relevance_score === 'suggested' && (
          <Stack
            justifyContent='center'
            alignItems='center'
            sx={{ bgcolor: 'secondary.75', borderRadius: 1, py: 0.25, px: 0.75 }}>
            <Tooltip title='Showing similar results that you might like!'>
              <Typography variant='body2' fontWeight={700} sx={{ color: 'secondary.800' }}>
                Similar Result
              </Typography>
            </Tooltip>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default DatagridIdentifier;
