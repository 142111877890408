import React, { Fragment } from 'react';
//material ui imports
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
//styles
import BarGraphReportStyles from '../styles/BarGraphReport.styles';
//other components
import CustomPieGraph from '../../CustomComponents/Graphs/CustomPieChart';

const PieGraphReport = ({ data }) => {
  const classes = BarGraphReportStyles();

  return (
    <Fragment>
      <Box display='flex' flexDirection='column' className={classes.graphs}>
        <Paper elevation={0} className={classes.paper}>
          <Typography variant='h3'>{data.title}</Typography>
          <Typography variant='h3'>{data.searchTerm}</Typography>
          <Typography variant='h3'>{data.subtitle}</Typography>
          <CustomPieGraph
            data={data && data.data}
            className={classes.bar}
            colors={data.colors ? data.colors : null}
            innerRadius={data.innerRadius ? data.innerRadius : null}
          />
        </Paper>
      </Box>
    </Fragment>
  );
};

export default PieGraphReport;
