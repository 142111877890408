import { createSvgIcon } from '@mui/material';

const JapaneseIcon = createSvgIcon(
  <svg width='19' height='21' viewBox='0 0 19 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0.305283 20C3.66121 20 6.50867 18.5436 9.05104 16.6019C11.5934 18.6407 14.4409 20 17.8985 20V16.9902C17.8985 16.5048 17.7968 16.5048 17.2883 16.4077C15.051 16.3106 13.2205 15.2427 11.4917 13.9805V13.8835C11.5934 13.7864 11.6951 13.6893 11.6951 13.5922C13.3222 11.4563 14.6443 9.12617 15.051 6.40772C15.1527 5.8252 15.3561 5.72811 15.8646 5.72811H18.0002V2.23297H10.7799V-4.27922e-05H7.11884V2.23297H0.000197847V5.8252H11.4917C11.0849 7.96112 10.2714 9.90287 8.84765 11.6504C8.0341 10.2912 7.32223 9.02908 6.61037 7.86403C5.59342 8.34947 4.47477 8.83491 3.35613 9.32035C4.37308 10.9708 5.39003 12.5242 6.40698 14.0776C4.77986 15.3398 2.94935 16.3106 0.712062 16.4077C0.508672 16.4077 0.203588 16.6019 0.203588 16.699C0.305283 17.864 0.305283 18.932 0.305283 20Z'
      fill='currentColor'
    />
  </svg>,
  'Japanese'
);

export default JapaneseIcon;
