import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { DocumentPageCountBySource, DocumentPageCountInterface, SourceDropdown } from '../types';
import { getDocumentAndPageCount } from '../../../api/pages/HomePage';
import formatCount from '../../../utils/formatCount';

const DocumentPageCount = ({ selectedSources }: { selectedSources: SourceDropdown[] }) => {
  const [allDocPageCount, setAllDocPageCount] = useState<DocumentPageCountBySource>({});
  const [totalCount, setTotalCount] = useState<DocumentPageCountInterface>({
    documents: 0,
    pages: 0
  });
  const [loading, setLoading] = useState<boolean>(false);

  const countTotalDocsAndPages = ({
    payloadData,
    selectedSourcesVal
  }: {
    payloadData: DocumentPageCountBySource;
    selectedSourcesVal: SourceDropdown[];
  }) => {
    const initialValue = { documents: 0, pages: 0 } as DocumentPageCountInterface;

    const finalVal = selectedSourcesVal.reduce((acc, { module }) => {
      if (!module) return acc;
      const { documents, pages } = module.reduce(
        (moduleAcc, { value: moduleValue, sourceValue }) => {
          const moduleData = payloadData[sourceValue]?.[moduleValue];
          return moduleData
            ? ({
                documents: moduleAcc.documents + moduleData.documents,
                pages: moduleAcc.pages + moduleData.pages
              } as DocumentPageCountInterface)
            : moduleAcc;
        },
        initialValue
      );

      return {
        documents: acc.documents + documents,
        pages: acc.pages + pages
      } as DocumentPageCountInterface;
    }, initialValue);
    return finalVal;
  };

  useEffect(() => {
    const fetchDocumentAndPageCount = async () => {
      setLoading(true);
      const response = await getDocumentAndPageCount();
      if (response?.status === 200 && response?.data) {
        setAllDocPageCount(response.data);
      }
      setLoading(false);
    };
    fetchDocumentAndPageCount();
  }, []);

  useEffect(() => {
    const totalValue = countTotalDocsAndPages({
      payloadData: allDocPageCount,
      selectedSourcesVal: selectedSources
    });
    setTotalCount(totalValue);
  }, [selectedSources, allDocPageCount]);

  if (!loading && !totalCount) return null;
  if (!loading && !totalCount.documents && !totalCount.pages) return null;

  return (
    <Box sx={{ color: 'gray.600', fontWeight: 400 }}>
      <Stack direction='row' spacing={0.5}>
        <Typography variant='body1'>Searching from</Typography>
        <Box fontWeight={700}>
          {loading ? <Skeleton variant='text' width={30} /> : formatCount(totalCount.documents)}
        </Box>
        <Typography variant='body1'>documents and</Typography>
        <Box fontWeight={700}>
          {loading ? <Skeleton variant='text' width={30} /> : formatCount(totalCount.pages)}
        </Box>
        <Typography variant='body1'>pages</Typography>
      </Stack>
    </Box>
  );
};

export default memo(DocumentPageCount);
