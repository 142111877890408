/**
 * To be used for results page where the color scheme is determined by one of the attributes in the response
 */
import * as React from 'react';
import { capitalize } from 'lodash';

import Tooltip from '@mui/material/Tooltip';
import CircleIcon from '@mui/icons-material/Circle';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

// constants
import { CARD_METADATA, COLOR_SCHEME_NUMBER } from '../../../pages/ResultsPage/utils/constants';

import styles from '../Datagrid.styles';

interface GridCellExpandProps {
  type: string;
}

const GridCellExpand = React.memo((props: GridCellExpandProps) => {
  const { type } = props;

  return (
    <Tooltip title={capitalize(type || '')}>
      <CircleIcon
        sx={{
          color: styles[COLOR_SCHEME_NUMBER[type?.toLowerCase() || 'ema']].bgcolor,
          fontSize: 14
        }}
      />
    </Tooltip>
  );
});

const DatagridColorIcon = (params: GridRenderCellParams) => {
  const {
    row,
    colDef: { source }
  }: any = params;
  const { colorScheme } = CARD_METADATA[source?.toLowerCase() as string];
  return <GridCellExpand type={row[colorScheme]} />;
};

export default DatagridColorIcon;
