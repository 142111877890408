export const categoryMappingValue: { [key: string]: any } = {
  name: 'sponsor_name',
  interventions: 'active_ingredients',
  indications_and_usage: 'indications_and_usage',
  primary_endpoint: 'primary_endpoint'
};

export const dateFields: { [key: string]: any } = {
  primary_completion_date: 'primary_completion_date',
  completion_date: 'completion_date',
  last_update_submitted_date: 'last_update_submitted_date',
  study_first_submitted_date: 'study_first_submitted_date'
};
