import React, { useEffect } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { isArray } from 'lodash';
import DesignationStore from '../../../store/Designation';
import DesignationActions from '../../../store/Designation/actions';
import theme from '../../../theme';
import TwoDimensionalTimeLine from './TwoDimensionalTimeLine';
import VerticalTimeline from './VerticalTimeline';

export const designationTypes: string[] = ['aa', 'bt', 'ft', 'or'];
export const designationName: any = {
  aa: 'Accelerated Approval',
  bt: 'Breakthrough Therapy',
  ft: 'Fast Track',
  or: 'Orphan Drug'
};
export const designationTypesColor: any = {
  aa: { shadow: 'rgba(41,171,226,0.3)', background: 'rgba(41,171,226)' },
  bt: { shadow: 'rgba(93,193,164,0.3)', background: 'rgba(93,193,164)' },
  ft: { shadow: 'rgba(144,211,104,0.3)', background: 'rgba(144,211,104)' },
  or: { shadow: 'rgba(180,233,42,0.3)', background: 'rgba(180,233,42)' }
};
export const handleArrayNonArrayElements = (data: any) => {
  if (isArray(data)) {
    return data.join(' : ');
  }
  return data;
};
const SpecialityTimeLine = ({ drugs }: { drugs: any[] }) => {
  const { designationState, designationDispatch } = React.useContext(DesignationStore);
  const { search } = useParams() as any;
  const divRef = React.useRef(null);

  const dataByYear: any = {};
  const dataByYearGroupedByApplication: any = {};
  let applicationNumbers: string[] = [];
  drugs.forEach(obj => {
    obj?.specialty_designations.forEach((spec: any) => {
      const fieldToConsider =
        spec.designation_date ||
        spec.aa_date ||
        spec.bt_date ||
        spec.ft_date ||
        spec.designated_date ||
        spec.rescinded_date ||
        spec.final_approval_date;

      if (fieldToConsider) {
        const year = fieldToConsider.substr(0, 4);
        dataByYear[`${year}`] = dataByYear[`${year}`] || {
          aa: {},
          bt: {},
          ft: {},
          or: {}
        };
        dataByYearGroupedByApplication[`${year}`] = dataByYearGroupedByApplication[`${year}`] || {};
        applicationNumbers.push(obj?.application_number);
        const mainCardData = {
          tradeName: handleArrayNonArrayElements(obj?.product_name ?? ''),
          activeIngredients: handleArrayNonArrayElements(obj?.active_ingredients ?? ''),
          number: obj?.application_number,
          indications: obj?.indication || '',
          sponsorName: obj?.sponsor_name,
          route: handleArrayNonArrayElements(obj?.route || ''),
          marketingStatus: obj?.marketing_status || '',
          formulation: handleArrayNonArrayElements(obj?.formulation || ''),
          applicationType: obj?.application_type || ''
        };
        const specialityDesignationHorizontal = {
          ...mainCardData,
          specialityDesignation: [{ ...spec }]
        };
        const specialityDesignationVertical = {
          ...mainCardData,
          specialityDesignation: [{ ...spec }]
        };

        // @ts-ignore
        if (dataByYear[`${year}`][`${spec?.type}`][`${obj?.application_number}`]) {
          // @ts-ignore
          dataByYear[`${year}`][`${spec?.type}`][
            `${obj?.application_number}`
          ].specialityDesignation.push(spec);
        } else {
          // @ts-ignore
          dataByYear[`${year}`][`${spec?.type}`][`${obj?.application_number}`] =
            specialityDesignationVertical;
        }

        if (dataByYearGroupedByApplication[`${year}`][`${obj?.application_number}`]) {
          dataByYearGroupedByApplication[`${year}`][
            `${obj?.application_number}`
          ].specialityDesignation.push(spec);
        } else {
          dataByYearGroupedByApplication[`${year}`][`${obj?.application_number}`] =
            specialityDesignationHorizontal;
        }
      }
    });
  });
  if (applicationNumbers) {
    // @ts-ignore
    applicationNumbers = [...new Set(applicationNumbers)];
  }
  const downloadImage = async (fileName: string) => {
    const element = divRef.current;
    if (!element) {
      return;
    }
    // dynamic import
    const { default: html2canvas } = await import('html2canvas');
    html2canvas(element, {
      scale: 2,
      scrollY: -window.scrollY,
      scrollX: -window.scrollX,
      // @ts-ignore
      width: element.scrollWidth
    })
      .then(canvas => {
        const link = document.createElement('a');
        link.download = fileName;
        link.href = canvas.toDataURL();
        link.click();
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    designationDispatch({ type: DesignationActions.OPEN_ALL_SEE_MORE, value: false });
  };

  useEffect(() => {
    if (designationState.openAllSeeMore) {
      setTimeout(() => {
        downloadImage(`${search}-expedited-pathway.png`);
      }, 1000);
    }
  }, [designationState.openAllSeeMore]);
  if (drugs.length === 0) {
    return (
      <Typography
        variant='subtitle2'
        alignItems='center'
        textAlign='center'
        sx={{ fontSize: '20px', fontWeight: 'bold', width: '100%', mt: 10 }}>
        {designationState.loading ? (
          ''
        ) : (
          <Box
            marginTop={theme.spacing(2)}
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'>
            No expedited pathways found for: {search}. Please try another search.
          </Box>
        )}
      </Typography>
    );
  }
  return (
    <>
      <Stack flexDirection='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='body2' component={Box}>
          {designationState.view === 'horizontal'
            ? 'Click on the highlighted designation to see the details of the drug. You can move the graph by dragging it.'
            : 'Click on the application to see the details of the drug'}
        </Typography>
      </Stack>

      {designationState.view === 'horizontal' ? (
        <Grid container ref={divRef} mb={3} mt={2} flexWrap='nowrap'>
          <TwoDimensionalTimeLine
            dataByYear={dataByYearGroupedByApplication}
            applicationNumbers={applicationNumbers}
            drugs={drugs}
          />
        </Grid>
      ) : (
        <Grid container ref={divRef} mb={3} mt={2}>
          <VerticalTimeline dataByYear={dataByYear} />
        </Grid>
      )}
    </>
  );
};
export default SpecialityTimeLine;
