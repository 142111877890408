export const styles = {
    submissionOptionsContainer: {
      padding: 2,
      width: 300,
      '& .MuiAccordion-root:nth-of-type(1)::before': {
        backgroundColor: '#0f0 !important'
      },
      '& .MuiAccordion-root': {
        boxShadow: 'unset'
      },
      '& .MuiAccordionSummary-content': {
        margin: '0px !important'
      },
      '& .MuiAccordionSummary-root.Mui-expanded': {
        minHeight: 'unset'
      },
      maxHeight: 500
    },
    clearSelectionContainer: {
      paddingTop: 1,
      paddingLeft: 1,
    },
    clearSelection: {
      cursor: 'pointer',
      fontWeight: 500,
      fontSize: 14,
      color: 'primary.main',
      textDecoration: 'underline',
    },
    searchContainer: {
      paddingBottom: 1,
      paddingTop: 1,
    },
    searchBox: {
      width: '100%',
    },
    searchIcon: {
      fontSize: 16,
      color: 'gray.500',
    },
    accordion: {
      boxShadow: 'none',
      '&:before': {
        display: 'none',
      },
      '&.Mui-expanded': {
        margin: 0,
      },
    },
    accordionSummary: {
      padding: 0,
    },
    accordionHeaderBox: {
      display: 'flex',
      alignItems: 'center',
    },
    expandIcon: {
      fontSize: 12,
      color: 'gray.700',
      marginX: 1,
    },
    checkBox: {
      marginRight: 1,
    },
    groupTitle: {
      fontSize: 14,
      fontWeight: 600,
      color: 'gray.800',
    },
    accordionDetails: {
      padding: 0,
    },
    formControlLabel: {
      paddingLeft: 4,
      paddingY: 0,
    },
    optionText: {
      fontSize: 14,
      lineHeight: 1,
      color: 'gray.800',
    },
  };