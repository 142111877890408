import { designationContextInterface } from '.';
import actions from './actions';

interface ActionProps {
  type: string;
  value: any;
}

const reducers = (state: designationContextInterface, action: ActionProps) => {
  switch (action.type) {
    case actions.OPEN_ALL_SEE_MORE: {
      return {
        ...state,
        openAllSeeMore: action.value
      };
    }
    case actions.SET_VIEW: {
      return {
        ...state,
        view: action.value
      };
    }
    case actions.SET_EXPAND_CARD: {
      return {
        ...state,
        expandCard: action.value
      };
    }
    case actions.SET_EXPAND_CARD_PAYLOAD: {
      return {
        ...state,
        expandedCardPayload: action.value
      };
    }
    case actions.SORT_BY: {
      return {
        ...state,
        sortBy: action.value
      };
    }
    case actions.SET_LOADING: {
      return {
        ...state,
        loading: action.value
      };
    }
    case actions.SET_FILTERS: {
      return {
        ...state,
        filters: action.value
      };
    }
    case actions.SET_IS_FILTERS_APPLIED: {
      return {
        ...state,
        isFiltersApplied: action.value
      };
    }

    default: {
      return state;
    }
  }
};

export default reducers;
