/* eslint-disable no-console */
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { CrossIcon, MinusIcon, PlusIcon, VerifyIcon } from '../../../assets/svgs/Icons';

import PDFViewer from '../../../components/PDFViewer';
import ClinicalStudies from './ClinicalStudies';

import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';

import styles from './styles';

import { verifyClinicalStudy } from '../../../api/pages/Regulatory360';

interface DataItem {
  snapshot?: any;
  file_links?: {
    labels?: Array<{
      original_s3_path?: string;
    }>;
  };
  center?: string;
  submission_type?: string;
  submission_num?: string;
}

interface ClinicalSummaryProps {
  dataItem: DataItem;
  selectedTabIndex: number;
}

const ClinicalSummary: React.FC<ClinicalSummaryProps> = ({ dataItem, selectedTabIndex }) => {
  const { source, number } = useParams<any>();
  const { dispatch } = useContext(GlobalStore) as any;

  const [expanded, setExpanded] = React.useState<string | false>('panel1'); // Tracks which accordion panel is currently expanded
  const [showPdf, setShowPdf] = React.useState<boolean>(false); // Controls visibility of the PDF viewer dialog
  const [pdfLoading, setPdfLoading] = React.useState<boolean>(false); // Indicates loading state during PDF verification
  const [pdfDetails, setPDFDetails] = React.useState({
    s3_url: '',
    clinical_studies_page_number: 0
  }); // Stores details about the PDF to be displayed
  const selectedTab = Object.keys(dataItem?.snapshot)[selectedTabIndex];
  const reportPayload = {
    source,
    number,
    submission_type: dataItem?.submission_type,
    submission_num: dataItem?.submission_num,
    document_id: dataItem?.snapshot[selectedTab]?.document_id
  };

  const handleChange = useCallback(
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    },
    []
  );

  const labelS3Path = useMemo(
    () => dataItem?.file_links?.labels?.[selectedTabIndex]?.original_s3_path ?? null,
    [dataItem, selectedTabIndex] // Computes the S3 path only when dataItem changes, for efficiency
  );

  /**
   * Handles clicking on the PDF verification link.
   * Initiates an API call to verify the clinical study and then either opens the PDF viewer
   * with the received PDF details or dispatches an error alert if the verification fails.
   */
  const handlePdfClick = useCallback(async () => {
    // Only make the API call if PDF details are not yet retrieved or need refresh
    try {
      setPdfLoading(true);
      const payload = {
        s3_path: labelS3Path ?? '',
        center: dataItem?.center ?? ''
      };

      const res = await verifyClinicalStudy(payload);
      if (res?.status === 200) {
        setPDFDetails(res.data.body);
      } else {
        throw new Error('Unexpected error occurred while verifying clinical study');
      }
    } catch (error) {
      console.error('Error fetching PDF details:', error);
      dispatch({
        type: GlobalActions.SET_ALERT,
        value: {
          message: 'Unable to open PDF file. Please try again later.',
          status: true
        }
      });
    } finally {
      setPdfLoading(false);
    }
  }, [labelS3Path, dispatch]);

  const openPdfViewer = () => {
    if (pdfDetails.clinical_studies_page_number > 0) {
      setShowPdf(true);
    }
  };

  useEffect(() => {
    handlePdfClick();
  }, [selectedTabIndex]);

  const hasClinicalStudies = pdfDetails.clinical_studies_page_number > 0;

  return (
    <Stack
      spacing={2}
      justifyContent='space-between'
      sx={{
        bgcolor: 'white.main',
        borderRadius: '12px',
        p: '20px'
      }}>
      {/* clinical summary header */}
      <Stack direction='row' spacing={1} justifyContent='space-between' alignItems='center'>
        <Tooltip
          title='Provides a summary of clinical studies included in section 14 to support each indication'
          placement='top-start'>
          <Typography variant='subtitle1' sx={styles.snapshotHeading}>
            Clinical Summary
          </Typography>
        </Tooltip>
        {/* verify clinical studies in label if available */}
        {labelS3Path && (
          <Stack
            direction='row'
            onClick={openPdfViewer}
            alignItems='center'
            spacing={0.5}
            sx={{ cursor: pdfDetails.clinical_studies_page_number > 0 ? 'pointer' : 'default' }}>
            {pdfLoading ? (
              <CircularProgress size={14} sx={{ color: 'primary.700' }} />
            ) : (
              <>
                <VerifyIcon
                  sx={hasClinicalStudies ? styles.verifyIcon : styles.disabledVerifyIcon}
                />
                <Typography
                  sx={hasClinicalStudies ? styles.verifyBtn : styles.disabledVerifyBtn}
                  variant='body1'>
                  Verify
                </Typography>
              </>
            )}
          </Stack>
        )}

        {/* pdf dialog box */}
        {showPdf && (
          <Dialog
            fullWidth
            maxWidth='lg'
            onClose={() => {
              setShowPdf(false);
            }}
            aria-labelledby='customized-dialog-title'
            open={showPdf}>
            <DialogTitle>
              <Stack direction='row' justifyContent='space-between'>
                <Typography variant='subtitle1'>{labelS3Path?.split('/')[-1]}</Typography>
                <IconButton onClick={() => setShowPdf(false)}>
                  <CrossIcon />
                </IconButton>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <Box sx={{ height: '80vh', maxHeight: '80vh' }}>
                <Stack sx={{ overflow: 'auto', height: '100%' }}>
                  <PDFViewer
                    fileUrl={pdfDetails?.s3_url}
                    initialPage={
                      hasClinicalStudies ? pdfDetails.clinical_studies_page_number - 1 : 0
                    }
                    defaultScale={1.35}
                  />
                </Stack>
              </Box>
            </DialogContent>
          </Dialog>
        )}
      </Stack>

      {/* Maps over clinical_summary indications from dataItem, rendering an accordion for each item */}
      {dataItem?.snapshot
        ? dataItem?.snapshot[selectedTab]?.clinical_summary?.map((item: any, index: number) => (
            <Accordion
              key={item.text}
              elevation={0}
              sx={{
                ...styles.accordion,
                borderColor: expanded === item.text ? 'primary.300' : 'gray.300'
              }}
              expanded={expanded === item.text}
              onChange={handleChange(item.text)}>
              <AccordionSummary
                expandIcon={
                  expanded === item.text ? (
                    <MinusIcon fontSize='small' />
                  ) : (
                    <PlusIcon fontSize='small' sx={{ color: 'primary.650' }} />
                  )
                }>
                <Box sx={{ width: '100%' }}>
                  <Tooltip title={item.text} placement='top-start'>
                    <Typography variant='subtitle1' noWrap sx={styles.indicationText}>
                      {index + 1}. {item.text}
                    </Typography>
                  </Tooltip>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {/* indication study details */}
                <ClinicalStudies item={item} payload={reportPayload} productName={selectedTab} />
              </AccordionDetails>
            </Accordion>
          ))
        : null}

      {!hasClinicalStudies && !dataItem?.snapshot[selectedTab]?.clinical_summary?.length && (
        <Typography variant='subtitle1' sx={styles.snapshotText}>
          Clinical Studies section is not available
        </Typography>
      )}

      {hasClinicalStudies && !dataItem?.snapshot[selectedTab]?.clinical_summary?.length && (
        <Typography variant='subtitle1' sx={styles.snapshotText}>
          {dataItem.center === 'CBER' ? 'CBER' : ''} Extraction is in progress...
        </Typography>
      )}
    </Stack>
  );
};

export default ClinicalSummary;
