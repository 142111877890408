import React from 'react';
import {
  Dialog,
  DialogTitle,
  Stack,
  Typography,
  DialogContent,
  Tooltip,
  IconButton,
  Box
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CancelIcon from '@mui/icons-material/Cancel';
import { resourceKeyMapping } from '../constants';

const DocumentsModal = ({
  resources,
  resourceDialog,
  setResourceDialog,
  title,
  handlePDFOpenClick
}: any) => {
  const openPDFInNewTab = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, link: string) => {
    const loc = new URL(link);
    if (!loc?.hostname?.includes('s3')) window.open(link, '_blank');
    else {
      const domain = new URL(window.location?.href)?.origin;
      const bucket = loc?.hostname?.split('.')[0];
      window.open(
        `${domain}/resource?bucket=${bucket}&filename=${loc?.pathname?.replace(/\//, '')}`,
        '_blank'
      );
    }
    e.stopPropagation();
  };

  return (
    <Dialog
      fullWidth
      maxWidth='lg'
      onClose={() => {
        setResourceDialog(false);
      }}
      aria-labelledby='customized-dialog-title'
      open={resourceDialog}>
      <DialogTitle>
        <Stack direction='row' justifyContent='space-between'>
          <Typography variant='h4' fontWeight='bold' color='primary'>
            {`${title?.split(' ')[1]} ${title?.split(' ')[0]} Documents`}
          </Typography>
          <IconButton
            aria-label='delete'
            onClick={() => {
              setResourceDialog(false);
            }}>
            <CancelIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack sx={{ height: 'calc(100vh - 290px)', overflowY: 'auto' }}>
          {Object.keys(resources).length > 0 &&
            Object.keys(resources).map((key: string, resourceIndex: number) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Stack spacing={2} direction='column' key={`${resourceIndex}-${key}`}>
                  <Typography variant='subtitle1' fontWeight='bold'>
                    {resourceKeyMapping[key] || key}
                  </Typography>
                  {resources[key]?.map((item: any) => {
                    return (
                      <Stack
                        key={`${item.category_bucket}-${item.title}`}
                        direction='row'
                        spacing={2}
                        justifyContent='space-between'
                        alignItems='center'
                        onClick={() =>
                          handlePDFOpenClick(item.s3_url, item.title, item.category_bucket)
                        }
                        sx={{
                          height: 'flex',
                          borderRadius: 3,
                          backgroundColor: '#F2F7F7',
                          padding: 2,
                          cursor: 'pointer'
                        }}>
                        <Stack direction='row' alignItems='center' spacing={1}>
                          <Box sx={{ padding: '0.2rem', width: '40px', height: '40px' }}>
                            <img
                              src='https://vivpro-site.s3.amazonaws.com/Help_Page_FAQ/pdf_icon.png'
                              alt='PDF download'
                              height='100%'
                              width='100%'
                            />
                          </Box>
                          <Typography
                            variant='subtitle1'
                            fontWeight='bold'
                            sx={{ textDecoration: 'underline', color: 'primary.dark' }}>
                            {item.title}
                          </Typography>
                        </Stack>
                        <Tooltip title='Open PDF in new tab'>
                          <IconButton
                            aria-label='Open PDF in new tab'
                            onClick={e => openPDFInNewTab(e, item.s3_url)}>
                            <OpenInNewIcon fontSize='small' />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    );
                  })}
                </Stack>
              );
            })}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(DocumentsModal);
