import { createSvgIcon } from '@mui/material';

const ModuleInfo = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
    <path
      d='M8.00049 16C6.41824 16 4.87152 15.5308 3.55593 14.6518C2.24034 13.7727 1.21496 12.5233 0.609456 11.0615C0.00395457 9.59966 -0.154472 7.99113 0.15421 6.43928C0.462891 4.88743 1.22482 3.46197 2.34364 2.34315C3.46246 1.22433 4.88792 0.462403 6.43977 0.153721C7.99162 -0.15496 9.60015 0.00346629 11.062 0.608967C12.5238 1.21447 13.7732 2.23985 14.6522 3.55544C15.5313 4.87104 16.0005 6.41775 16.0005 8C15.9982 10.121 15.1546 12.1545 13.6548 13.6543C12.155 15.1541 10.1215 15.9977 8.00049 16ZM8.00049 1.33334C6.68195 1.33334 5.39302 1.72433 4.29669 2.45687C3.20036 3.18942 2.34588 4.23061 1.8413 5.44878C1.33671 6.66695 1.20469 8.0074 1.46192 9.30061C1.71916 10.5938 2.3541 11.7817 3.28645 12.714C4.2188 13.6464 5.40668 14.2813 6.69989 14.5386C7.9931 14.7958 9.33354 14.6638 10.5517 14.1592C11.7699 13.6546 12.8111 12.8001 13.5436 11.7038C14.2762 10.6075 14.6672 9.31855 14.6672 8C14.6652 6.23249 13.9622 4.53792 12.7124 3.2881C11.4626 2.03828 9.76801 1.33528 8.00049 1.33334Z'
      fill='#757575'
    />
    <path
      d='M9.33365 12.667H8.00032V8.00033H6.66699V6.66699H8.00032C8.35394 6.66699 8.69308 6.80747 8.94313 7.05752C9.19318 7.30757 9.33365 7.6467 9.33365 8.00033V12.667Z'
      fill='#757575'
    />
    <path
      d='M8.00049 5.33301C8.55277 5.33301 9.00049 4.88529 9.00049 4.33301C9.00049 3.78072 8.55277 3.33301 8.00049 3.33301C7.4482 3.33301 7.00049 3.78072 7.00049 4.33301C7.00049 4.88529 7.4482 5.33301 8.00049 5.33301Z'
      fill='#757575'
    />
  </svg>,
  'PlusIcon'
);

export default ModuleInfo;
