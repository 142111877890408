import { memo, useContext, useEffect, useState } from 'react';

// mui
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  SwipeableDrawer,
  Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Global } from '@emotion/react';

// dnd-kit
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  pointerWithin,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';

import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

// styles
import styles from '../styles/ExploreFeatures.styles';
import palette from '../../../themev5/palette';

import { MoreIcon } from '../../../assets/svgs/Icons';
import ExploreFeatureIconButton from './ExploreFeatureIconButton';
import { bottomDrawerHeight, bottomDrawerHeightExpanded, EXPLORE_FEATURES } from '../const';
import { FeatureButton, FeatureButtonsState } from '../types';
import Draggable from './Draggable';
import Droppable from './Droppable';
import Reg360SearchBar from '../../RegulatoryInformation/Reg360SearchBar';
import CdpSearchBar from '../../CDP/CdpSearchBar';
import SearchBox5052B2 from '../../../components/ApprovalPathway/5052bSearchBox';
import ComparsionSearchBox from '../../Comparison/ComparsionSearchBox';
import DesignationSearchBox from '../../SpecialityDesignation/components/DesignationSearchBox';

// store
import Store from '../../../store';
import Actions from '../../../store/actions';

const defaultFeatureButtons = {
  added: [
    EXPLORE_FEATURES.regulatory360,
    EXPLORE_FEATURES.cdp,
    EXPLORE_FEATURES.advisoryCommittee,
    EXPLORE_FEATURES.guidance,
    EXPLORE_FEATURES.comparison,
    EXPLORE_FEATURES.expeditedPathway,
    EXPLORE_FEATURES.pmr
  ],
  removed: [
    EXPLORE_FEATURES['505b2'],
    EXPLORE_FEATURES.pediatrics,
    EXPLORE_FEATURES.hta,
    EXPLORE_FEATURES['fda-letters'],
    EXPLORE_FEATURES['dd-tools'],
    EXPLORE_FEATURES.ema_orphan,
    EXPLORE_FEATURES.consultation,
    EXPLORE_FEATURES.ema_meetings
  ]
};

const ExploreFeatures = () => {
  const [open, setOpen] = useState(false);
  const [personalize, setPersonalize] = useState(false);
  const [activeFeatureButton, setActiveFeatureButton] = useState<FeatureButton | null>(null);
  const [featureButtons, setFeatureButtons] = useState<FeatureButtonsState>(defaultFeatureButtons);
  const [modalOpen, setModalOpen] = useState('');
  const { dispatch } = useContext(Store) as any;

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    }),
    useSensor(TouchSensor, { activationConstraint: { distance: 8 } })
  );

  const handleDragStart = (event: DragStartEvent) => {
    const featureButtonId = event.active.id;
    let featureButton = featureButtons.added.find(feature => feature.id === featureButtonId);
    if (!featureButton)
      featureButton = featureButtons.removed.find(feature => feature.id === featureButtonId);
    if (!featureButton) return;
    setActiveFeatureButton(featureButton);
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    const overId = over?.id;
    if (overId === null) return;
    if (active.id === overId) return;
    const activeContainerId = active.data.current?.sortable
      ?.containerId as keyof FeatureButtonsState;
    let overContainerId = over?.data.current?.sortable?.containerId as keyof FeatureButtonsState;
    if (
      activeContainerId === undefined ||
      overContainerId === undefined ||
      activeContainerId === overContainerId
    )
      return;
    if (overContainerId !== 'removed' && overContainerId !== 'added') {
      overContainerId = overId as keyof FeatureButtonsState;
    }
    if (overContainerId === 'removed' && featureButtons.added.length <= 3) {
      dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Cannot remove more than 3 items', color: 'warning' }
      });
      return;
    }
    const newFeatureButtons = {
      added: [...featureButtons.added],
      removed: [...featureButtons.removed]
    };
    const activeArray = newFeatureButtons[activeContainerId];
    const overArray = newFeatureButtons[overContainerId];
    if (!activeArray || !overArray) return;

    const oldIndex = activeArray?.findIndex(feature => feature.id === active.id) ?? -1;
    const newIndex = overArray?.findIndex(feature => feature.id === over?.id) ?? -1;
    if (oldIndex === -1) return;
    const featureToMove = activeArray.splice(oldIndex, 1)[0];
    if (newIndex === -1) {
      if (overArray.length === 0) overArray.push(featureToMove);
      else {
        const isAhead = (active.rect.current?.translated?.left ?? 0) < (over?.rect?.left ?? 0);
        if (isAhead) overArray.push(featureToMove);
        else overArray.unshift(featureToMove);
      }
    } else overArray.splice(newIndex, 0, featureToMove);
    setFeatureButtons(newFeatureButtons);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    setActiveFeatureButton(null);
    const { active, over } = event;
    const overId = over?.id;
    if (overId === null) return;
    if (active.id === overId) return;
    const activeContainerId: keyof FeatureButtonsState = active.data.current?.sortable?.containerId;
    const overContainerId: keyof FeatureButtonsState = over?.data.current?.sortable?.containerId;
    if (
      activeContainerId === undefined ||
      overContainerId === undefined ||
      activeContainerId !== overContainerId
    )
      return;

    const newFeatureButtons = {
      added: [...featureButtons.added],
      removed: [...featureButtons.removed]
    };
    const activeArray = newFeatureButtons[activeContainerId];

    const oldIndex = activeArray.findIndex(feature => feature.id === active.id);
    const newIndex = activeArray.findIndex(feature => feature.id === over?.id);
    if (oldIndex === -1 || newIndex === -1) return;
    newFeatureButtons[activeContainerId] = arrayMove(activeArray, oldIndex, newIndex);
    setFeatureButtons(newFeatureButtons);
  };

  const moveFeatureButtom = (id: string) => {
    const newFeatureButtons = {
      added: [...featureButtons.added],
      removed: [...featureButtons.removed]
    };
    let currentArray = newFeatureButtons.added;
    let newCurrentArray = newFeatureButtons.removed;
    let index = currentArray.findIndex(feature => feature.id === id);
    if (index === -1) {
      currentArray = newFeatureButtons.removed;
      newCurrentArray = newFeatureButtons.added;
      index = currentArray.findIndex(feature => feature.id === id);
    }
    if (index === -1) return;
    const featureToMove = currentArray.splice(index, 1)[0];
    newCurrentArray.push(featureToMove);
    setFeatureButtons(newFeatureButtons);
  };

  const saveExploreFeaturesPreferences = () => {
    const newFeatureButtons = {
      added: featureButtons.added.map(x => x.id),
      removed: featureButtons.removed.map(x => x.id)
    };
    localStorage.setItem('exploreFeatures', JSON.stringify(newFeatureButtons));
  };

  const getExploreFeaturesPreferences = () => {
    try {
      const exploreFeatures = localStorage.getItem('exploreFeatures');
      if (exploreFeatures) {
        const parsedExploreFeatures = JSON.parse(exploreFeatures);
        const newFeatureButtons = {
          added: parsedExploreFeatures?.added
            ?.filter((x: keyof FeatureButton) => x in EXPLORE_FEATURES)
            ?.map((x: keyof FeatureButton) => EXPLORE_FEATURES[x]),
          removed: parsedExploreFeatures?.removed
            ?.filter((x: keyof FeatureButton) => x in EXPLORE_FEATURES)
            ?.map((x: keyof FeatureButton) => EXPLORE_FEATURES[x])
        };
        if (
          newFeatureButtons.added.length + newFeatureButtons.removed.length !==
          EXPLORE_FEATURES.length
        ) {
          const pendingFeatureButtons = Object.keys(EXPLORE_FEATURES)
            .filter(
              x =>
                !newFeatureButtons.added.some((y: FeatureButton) => y.id === x) &&
                !newFeatureButtons.removed.some((y: FeatureButton) => y.id === x)
            )
            .map(x => EXPLORE_FEATURES[x]);
          newFeatureButtons.removed = [...newFeatureButtons.removed, ...pendingFeatureButtons];
        }
        setFeatureButtons(newFeatureButtons);
      }
    } catch (e) {
      setFeatureButtons(defaultFeatureButtons);
    }
  };

  const toggleDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
    if (!newOpen) {
      setPersonalize(false);
      saveExploreFeaturesPreferences();
    }
  };

  const personalizeClick = () => {
    if (personalize) saveExploreFeaturesPreferences();
    setPersonalize(!personalize);
  };

  const onDrawerClose = () => {
    if (personalize) {
      saveExploreFeaturesPreferences();
      setPersonalize(false);
    }
    toggleDrawer(false);
  };

  useEffect(() => {
    getExploreFeaturesPreferences();
  }, []);

  // closes explore features modal if any other feature modal is opened
  useEffect(() => {
    if (modalOpen) setOpen(false);
  }, [modalOpen]);

  return (
    <>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: bottomDrawerHeightExpanded,
            backgroundColor: palette.gray[50],
            overflow: 'visible'
          }
        }}
      />
      <SwipeableDrawer
        anchor='bottom'
        open={open}
        onClose={onDrawerClose}
        onOpen={() => toggleDrawer(true)}
        swipeAreaWidth={bottomDrawerHeight}
        allowSwipeInChildren
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true
        }}>
        <Box>
          <DndContext
            sensors={sensors}
            collisionDetection={pointerWithin}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: -bottomDrawerHeight,
                  height: bottomDrawerHeight,
                  visibility: 'visible',
                  right: 0,
                  left: 0,
                  borderRadius: open ? '16px 16px 0 0' : '0px',
                  backgroundColor: 'gray.50',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                {open ? (
                  <Box
                    paddingTop={2}
                    alignItems='center'
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignSelf: 'stretch',
                      marginLeft: 7.5
                    }}>
                    <Typography variant='h3' sx={styles.titleExpanded}>
                      Explore our features
                    </Typography>
                    <IconButton onClick={onDrawerClose} sx={{ mr: 2 }}>
                      <Close />
                    </IconButton>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignSelf: 'stretch',
                      alignItems: 'start'
                    }}>
                    <Divider sx={{ flex: 1, backgroundColor: 'gray.200', opacity: 0.3 }} />
                    <Typography variant='subtitle1' sx={styles.title}>
                      Explore our features
                    </Typography>
                    <Divider sx={{ flex: 1, backgroundColor: 'gray.200', opacity: 0.3 }} />
                  </Box>
                )}
                {open && (
                  <Box display='flex' flexDirection='row' justifyContent='center'>
                    <Divider
                      sx={{
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'gray.400',
                        borderRadius: '2px',
                        width: '64px',
                        mb: 1
                      }}
                    />
                  </Box>
                )}
                {/* Droppable container for added items */}
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Droppable
                    id='added'
                    featureButtonItems={featureButtons.added}
                    isPersonalize={personalize}>
                    {featureButtons.added.map((featureButton, index) => {
                      return (
                        <Draggable
                          key={featureButton.id}
                          id={featureButton.id}
                          isPersonalize={personalize}>
                          <ExploreFeatureIconButton
                            index={index}
                            key={featureButton.id}
                            feature={featureButton}
                            isPersonalize={personalize}
                            onClickAddRemove={() => moveFeatureButtom(featureButton.id)}
                            isAddPersonaize={false}
                            allowDragging={featureButtons.added.length > 3}
                            setModalOpen={setModalOpen}
                          />
                        </Draggable>
                      );
                    })}
                  </Droppable>
                  {!open && (
                    <ExploreFeatureIconButton
                      index={99}
                      key='more'
                      feature={{
                        id: 'more',
                        title: 'More',
                        Icon: MoreIcon,
                        clickType: 'link',
                        tooltip: 'More',
                        onClick: () => setOpen(true)
                      }}
                      isPersonalize={false}
                      isAddPersonaize={false}
                      allowDragging={false}
                      setModalOpen={setModalOpen}
                    />
                  )}
                </Box>
              </Box>
              <Divider
                sx={{
                  backgroundColor: 'gray.300',
                  width: '85%',
                  mt: 3,
                  mb: 1,
                  alignSelf: 'center'
                }}
              />
              <Stack
                direction='column'
                spacing={2}
                alignItems='center'
                justifyContent='space-around'>
                {/* Droppable container for removed items */}
                <Droppable
                  id='removed'
                  featureButtonItems={featureButtons.removed}
                  isPersonalize={personalize}>
                  {featureButtons.removed.map((featureButton, index) => {
                    return (
                      <Draggable
                        key={featureButton.id}
                        id={featureButton.id}
                        isPersonalize={personalize}>
                        <ExploreFeatureIconButton
                          index={index}
                          key={featureButton.id}
                          feature={featureButton}
                          onClickAddRemove={() => moveFeatureButtom(featureButton.id)}
                          isPersonalize={personalize}
                          isAddPersonaize
                          allowDragging
                          setModalOpen={setModalOpen}
                        />
                      </Draggable>
                    );
                  })}
                  {personalize && featureButtons.removed.length === 0 && (
                    <Typography
                      variant='subtitle1'
                      fontWeight='bold'
                      color='gray.light'
                      textAlign='center'>
                      Drop items here
                    </Typography>
                  )}
                </Droppable>
                <Button
                  variant={personalize ? 'contained' : 'outlined'}
                  onClick={personalizeClick}
                  sx={personalize ? styles.personalizeDoneButton : styles.personalizeButton}>
                  {personalize ? 'Done' : 'Personalize home screen'}
                </Button>
                <Typography
                  variant='body1'
                  color='gray.lightVariant'
                  sx={{ mb: 1, visibility: personalize ? 'visible' : 'hidden' }}>
                  Personalize your home screen with easy drag-and-drop
                </Typography>
              </Stack>
              <DragOverlay>
                {activeFeatureButton ? (
                  <ExploreFeatureIconButton
                    index={99}
                    key={activeFeatureButton.id}
                    feature={{
                      id: activeFeatureButton.id,
                      title: activeFeatureButton.title,
                      Icon: activeFeatureButton.Icon,
                      clickType: 'link',
                      tooltip: ''
                    }}
                    isPersonalize={false}
                    isAddPersonaize={false}
                    setModalOpen={setModalOpen}
                    allowDragging={false}
                  />
                ) : null}
              </DragOverlay>
            </Box>
          </DndContext>
        </Box>
        {modalOpen === 'regulatory360' && (
          <Reg360SearchBar modalOpen={modalOpen} setModalOpen={setModalOpen} />
        )}
        {modalOpen === '505b2' && (
          <SearchBox5052B2 modalOpen={modalOpen} setModalOpen={setModalOpen} />
        )}
        {modalOpen === 'cdp' && <CdpSearchBar modalOpen={modalOpen} setModalOpen={setModalOpen} />}
        {modalOpen === 'comparsion' && (
          <ComparsionSearchBox modalOpen={modalOpen} setModalOpen={setModalOpen} />
        )}

        {modalOpen === 'expeditedPathway' && (
          <DesignationSearchBox modalOpen={modalOpen} setModalOpen={setModalOpen} />
        )}
      </SwipeableDrawer>
    </>
  );
};

export default memo(ExploreFeatures);
