import { createSvgIcon } from '@mui/material';

const RightArrowIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='8' viewBox='0 0 14 8' fill='none'>
    <path
      d='M13.489 2.76281L10.8138 0.0876465L9.989 0.91248L12.4752 3.39923L0 3.41673V4.5834L12.5102 4.5659L9.98842 7.08765L10.8133 7.91248L13.489 5.23731C13.8161 4.90861 13.9997 4.46377 13.9997 4.00006C13.9997 3.53636 13.8161 3.09151 13.489 2.76281Z'
      fill='currentColor'
    />
  </svg>,
  'RightArrow'
);

export default RightArrowIcon;
