import { cloneDeep, sortBy, uniqBy } from 'lodash';
import { UTCtoLocalTime } from '../components/Header/utils/localTimeZoneDate';
import { GeneralizedFilters } from '../types/filters';
import { EMA_GUIDANCE_FILTERS } from '../pages/EMAGuidance/const';

export const getProcessedDate = (date: any) => {
  const localDate = UTCtoLocalTime(new Date(date) as any);
  const year = localDate.getFullYear();
  let day: any = localDate.getDate();
  let month: any = localDate.getMonth() + 1;
  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  return `${year}-${month}-${day}`;
};

export const getProcessedYearRange = (startdate: string, endDate: string) => {
  const start = `${new Date(startdate).getFullYear()}-01-01`;
  const end = `${new Date(endDate).getFullYear()}-12-31`;
  return [start, end];
};

// Function to retain the previously selected filters.
export const mergeOldFilterValues = (newFilters: any, oldFilters: any) => {
  newFilters?.forEach((newFltr: any) => {
    const oldFltr = oldFilters.find((fltr: any) => fltr.id === newFltr.id);
    if (newFltr.filterType === 'select_options') {
      // eslint-disable-next-line no-param-reassign
      newFltr.options = newFltr.options.map((newOpt: any) => {
        const selected =
          oldFltr.options.findIndex(
            (oldOpt: any) => oldOpt.value === newOpt.value && oldOpt.selected
          ) > -1;

        return {
          ...newOpt,
          selected
        };
      });

      const prevSelectedOptions = oldFltr.options.filter((oldOpt: any) => oldOpt.selected);
      const prevOptionsMissingInNewOptions = prevSelectedOptions.filter(
        (oldOpt: any) =>
          newFltr.options.findIndex((newOpt: any) => newOpt.value === oldOpt.value) === -1
      );
      // eslint-disable-next-line no-param-reassign
      newFltr.options = [...newFltr.options, ...prevOptionsMissingInNewOptions];
    } else if (oldFltr?.value) {
      // eslint-disable-next-line no-param-reassign
      newFltr.value = oldFltr.value;
    }
  });

  return newFilters;
};

export const getFilters = (availableFilters: any, filterMapping: any) => {
  const filters = cloneDeep(
    filterMapping.map((fltr: { [key: string]: any }) => ({
      ...fltr,
      options: [...(fltr?.options || [])]
    }))
  );

  if (Object.keys(availableFilters)?.length === 0) {
    return {};
  }
  Object.entries(availableFilters).forEach(([key, value]: any) => {
    filters.forEach((filter: { [key: string]: any }) => {
      if (filter.filterType === 'select_options' && key === filter.apiResponseKey) {
        // eslint-disable-next-line no-param-reassign
        filter.options = sortBy(
          uniqBy(
            [
              ...value.map((val: string | number) => ({
                value: val,
                label: val,
                selected: false
              }))
            ],
            'value'
          ),
          'label'
        ) as Array<any>;
      }
    });
  });

  return filters;
};

export const getApiPayloadFromFilters = (filters: Array<GeneralizedFilters>) => {
  const filtersObject: any = {};
  filters.forEach((filter: GeneralizedFilters) => {
    if (filter.filterType === 'select_options') {
      const selectedOptions = filter.options?.filter((opt: any) => opt.selected) || [];
      if (filter.options && selectedOptions.length > 0) {
        filtersObject[filter.apiRequestKey] = selectedOptions.map((opt: any) => opt.value);
      }
    } else if (filter.filterType === 'radio_group') {
      // Do not consider default value
      if (filter.value !== 'all') {
        filtersObject[filter.apiRequestKey] = filter.value;
      }
    } else if (filter.filterType === 'date_range') {
      if (filter.value?.startDate) {
        filtersObject[filter.apiRequestKey] = [
          getProcessedDate(filter.value?.startDate),
          getProcessedDate(filter.value?.endDate)
        ];
      }
    } else if (filter.filterType === 'year_range') {
      if (filter.value?.startDate) {
        filtersObject[filter.apiRequestKey] = getProcessedYearRange(
          filter.value?.startDate,
          filter.value?.endDate
        );
      }
    } else {
      filtersObject[filter.apiRequestKey] = filter.value;
    }
  });
  return filtersObject;
};

const getFieldValues = (row: any, key: string) => {
  return [row[key]];
};

export const getFiltersFromApiData = (apiData: any, filterMapping: any) => {
  const filters = cloneDeep(
    filterMapping.map((fltr: { [key: string]: any }) => ({
      ...fltr,
      options: [...(fltr?.options || [])]
    }))
  );

  apiData.forEach((row: any) => {
    filters.forEach((filter: { [key: string]: any }) => {
      if (filter.filterType === 'select_options') {
        // eslint-disable-next-line no-param-reassign
        filter.options = sortBy(
          uniqBy(
            [
              ...(filter.options || []),
              ...getFieldValues(row, filter.apiResponseKey).map((val: string | number) => ({
                value: val,
                label: val,
                selected: false
              }))
            ],
            'value'
          ),
          'label'
        ) as Array<any>;
      }
    });
  });

  return filters;
};

export const getFiltersLabelList = () => {
  return EMA_GUIDANCE_FILTERS.reduce((acc: any, elem) => {
    acc[elem.value] = elem.label;
    return acc;
  }, {});
};
