const comingSoonMixin = {
  width: '600px',
  background: 'linear-gradient(269deg, #00B893 -0.83%, #4C8FF2 107.76%)',
  backgroundClip: 'text',
  '-webkit-background-clip': 'text',
  '-webkit-text-fill-color': 'transparent'
};

const styles = {
  searchInput: {
    padding: 0,
    color: 'green',
    mr: 3
  },
  newTutorialText: {
    color: '#313246',
    fontFamily: 'Mulish',
    fontSize: 20,
    fontWeight: 700,
    mb: 2
    // ml: 6.8,
    // selfAlign: 'start'
  },
  comingSoonContainer: {
    mt: '80px',
    textAlign: 'center',
    fontFamily: 'Mulish',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  moreVideosTitle: { ...comingSoonMixin, mt: '11px', fontSize: '13.333px', fontWeight: 400 },
  comingSoonTitle: { ...comingSoonMixin, fontSize: '42px', fontWeight: 300 },
  comingSoonDescription: {
    ...comingSoonMixin,
    fontSize: '16px',
    fontWeight: 400,
    mt: '16px'
  },
  gradientDownArrowIcon: {
    height: '24px',
    width: '24px',
    mb: '10px',
    mt: '16px'
  },
  newVideosContainer: {
    justifyContent: 'center',
    columnGap: 2,
    rowGap: 2
  },
  resetButton: {
    fontSize: 14,
    fontWeight: 700,
    color: 'gray.700',
    textTransform: 'capitalize'
  }
};

export default styles;
