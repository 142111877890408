import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { Box, Divider, Drawer, IconButton, Tooltip, Typography } from '@mui/material';

import { ThemeProvider } from '@mui/material/styles';
import themev5 from '../../themev5';

import Loading from '../../pages/SearchResults/components/Loading';

import { CrossIcon } from '../../assets/svgs/Icons';

import whatsNewStyles from './WhatsNew.styles';

import Store from '../../store';
import { Update, WhatsNewEntry } from './types';
import MainContent from './MainContent';
import List from './List';

const WhatsNewModal = () => {
  const location = useLocation();
  const history = useHistory();
  const { state } = useContext<any>(Store);

  const [isLoading, setIsLoading] = useState(false);
  const [showWhatsNewModal, setShowWhatsNewModal] = useState(false);
  const [whatsNewUpdates, setWhatsNewUpdates] = useState<WhatsNewEntry[]>([]);
  const [selectedWhatsNew, setSelectedWhatsNew] = useState<Update | null>(null);
  const [selectedContentIndex, setSelectedContentIndex] = useState(0);
  const [expandedAccordions, setExpandedAccordions] = useState<string[]>([]);

  const localStorageSeenUpdateIdsString = localStorage.getItem('WN_last30DaySeenUpdateIds') ?? '[]';
  const localStorageSeenUpdateIds = JSON.parse(localStorageSeenUpdateIdsString);
  const [seenUpdateIds, setSeenUpdateIds] = useState<number[]>(localStorageSeenUpdateIds);

  const localStorageLast30DaysUpdateIdsString =
    localStorage.getItem('WN_last30DaysUpdateIds') ?? '[]';
  const localStorageLast30DaysUpdateIds = JSON.parse(localStorageLast30DaysUpdateIdsString);

  const unseenCount = localStorageLast30DaysUpdateIds.length - seenUpdateIds.length;

  const markUpdateAsSeen = useCallback((id: number) => {
    const storageSeenUpdateIdsString = localStorage.getItem('WN_last30DaySeenUpdateIds') ?? '[]';
    const storageSeenUpdateIds: Array<number> = JSON.parse(storageSeenUpdateIdsString);

    storageSeenUpdateIds.push(id);
    const newSeenUpdateIds = Array.from(new Set(storageSeenUpdateIds));

    setSeenUpdateIds(newSeenUpdateIds);

    localStorage.setItem('WN_last30DaySeenUpdateIds', JSON.stringify(newSeenUpdateIds));
  }, []);

  const handleCloseWhatsNewModal = useCallback(() => {
    setShowWhatsNewModal(false);
  }, []);

  const handlePrevContent = useCallback(() => {
    setSelectedContentIndex(prev => (selectedWhatsNew && prev > 0 ? prev - 1 : 0));
  }, [selectedWhatsNew]);

  const handleNextContent = useCallback(() => {
    setSelectedContentIndex(prev =>
      selectedWhatsNew ? Math.min(prev + 1, selectedWhatsNew.content.length - 1) : prev
    );
  }, [selectedWhatsNew]);

  const handleSelectUpdate = useCallback((item: Update) => {
    setSelectedWhatsNew(item);
    setSelectedContentIndex(0);
    markUpdateAsSeen(item.id);
  }, []);

  const handleAccordionChange = useCallback(
    (panel: string) => () => {
      setExpandedAccordions(prevExpandedAccordions =>
        prevExpandedAccordions.includes(panel)
          ? prevExpandedAccordions.filter(id => id !== panel)
          : [...prevExpandedAccordions, panel]
      );
    },
    []
  );

  const openAllAccordions = useCallback(() => {
    const accordionIds = state?.whatsNewUpdates?.map((update: WhatsNewEntry) => update.id);
    setExpandedAccordions(accordionIds);
  }, [state?.whatsNewUpdates]);

  useEffect(() => {
    try {
      if (location.hash === '#whats-new') {
        setIsLoading(true);

        const last30DaysUpdates = state?.whatsNewUpdates;
        setWhatsNewUpdates(last30DaysUpdates);

        const firstUpdates = last30DaysUpdates?.[0]?.updates;
        setSelectedWhatsNew({
          ...firstUpdates?.[0],
          description: last30DaysUpdates?.[0]?.description
        });

        openAllAccordions();

        markUpdateAsSeen(firstUpdates?.[0]?.id);

        setShowWhatsNewModal(true);

        // Remove the hash from the URL
        history.push({
          ...location,
          hash: ''
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [location, state?.whatsNewUpdates]);

  return (
    <ThemeProvider theme={themev5}>
      <Drawer
        anchor='bottom'
        open={showWhatsNewModal}
        onClose={handleCloseWhatsNewModal}
        sx={whatsNewStyles.drawer}>
        <Box sx={whatsNewStyles.drawerContainer}>
          <Box sx={whatsNewStyles.header.container}>
            <Box sx={whatsNewStyles.header.bar}>
              <Box sx={whatsNewStyles.header.titleWrapper}>
                <Typography sx={whatsNewStyles.header.title}>What&apos;s New</Typography>
                {unseenCount > 0 && (
                  <Box sx={whatsNewStyles.header.countChip}>
                    <Typography sx={whatsNewStyles.header.countChipText}>
                      {unseenCount} new
                    </Typography>
                  </Box>
                )}
              </Box>
              <Tooltip title='Close'>
                <IconButton onClick={handleCloseWhatsNewModal}>
                  <CrossIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={whatsNewStyles.header.dividerWrapper}>
              <Divider sx={whatsNewStyles.header.divider} />
            </Box>
          </Box>

          {state?.isWhatsNewLoading || isLoading ? (
            <Box sx={whatsNewStyles.modalLoadingContainer}>
              <Loading showTips={false} />
            </Box>
          ) : (
            <Box sx={whatsNewStyles.content}>
              <List
                updates={whatsNewUpdates}
                expandedAccordions={expandedAccordions}
                onAccordionChange={handleAccordionChange}
                selectedWhatsNew={selectedWhatsNew}
                onSelectUpdate={handleSelectUpdate}
                seenUpdateIds={seenUpdateIds}
              />

              {selectedWhatsNew && selectedWhatsNew?.content?.length > 0 && (
                <MainContent
                  selectedWhatsNew={selectedWhatsNew}
                  selectedContentIndex={selectedContentIndex}
                  handlePrevContent={handlePrevContent}
                  handleNextContent={handleNextContent}
                />
              )}
            </Box>
          )}
        </Box>
      </Drawer>
    </ThemeProvider>
  );
};

export default memo(WhatsNewModal);
