import React from 'react';

// material ui
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import SearchIcon from '@mui/icons-material/Search';
import Chip from '../Chip';

// styled components
import FiltersTextField from '../../../pages/Aria/styledComponents/FiltersTextField';

export interface Option {
  label: string;
  value: string;
  selected?: boolean;
}

const KeywordSearch: React.FC<any> = ({
  id,
  label,
  values,
  setCurrentText,
  onRemoveSelected,
  handleSearch,
  disabled = false
}: any) => {
  return (
    <Stack spacing={1}>
      <Typography variant='subtitle1' color='primary' fontWeight={600}>
        {label}
      </Typography>
      {/* show selected options */}
      {values.map((opt: string) => (
        <Chip key={opt} title={opt} disabled={disabled} onClose={() => onRemoveSelected(opt, id)} />
      ))}
      <form onSubmit={handleSearch}>
        <FiltersTextField
          placeholder='Search keywords here'
          variant='outlined'
          onChange={(e: any) => setCurrentText(e.target.value)}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton aria-label='toggle password visibility' type='submit' edge='end'>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Typography mt={2} mb={2} sx={{ textWrap: 'balance' }}>
          Use keywords above to narrow results across all or specific categories below.
        </Typography>
        <Divider sx={{ borderWidth: '1px', width: '75%', borderColor: '#c1c1c1' }} />
      </form>
    </Stack>
  );
};

export default React.memo(KeywordSearch);
