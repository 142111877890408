import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  roots: {
    // background: theme.palette.white,
    background: theme.palette.cards.background
    // height: '70vh'
  },
  root: {
    // background: theme.palette.white,
    background: theme.palette.cards.background,
    height: '80vh'
  },
  close: {
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: theme.palette.cards.background
  },
  title: {
    textTransform: 'capitalize',
    color: theme.palette.cards.grayDark,
    fontSize: '11px'
  },
  reportText: {
    fontSize: theme.spacing(1.6),
    cursor: 'pointer',
    color: theme.palette.navbar.button,
    paddingLeft: '30px'
  },
  nameNDA: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.cards.red,
    fontSize: '14px'
  },
  nameEUA: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.cards.orange,
    fontSize: 14
  },
  nameDenovo: {
    marginTop: theme.spacing(0.5),
    color: '#DA70D6',
    fontSize: 14
  },
  nameBLA: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.cards.blue,
    fontSize: '14px'
  },
  timeForApproval: {
    fontSize: '10px',
    paddingBottom: '10px'
  },
  tags: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    background: theme.palette.cards.grayAlternate
  },
  tagText: {
    marginRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1.2),
    paddingRight: theme.spacing(1.2),
    borderRadius: theme.spacing(1),
    background: theme.palette.cards.grayAlternate,
    color: theme.palette.white
  },
  tagContent: {
    fontSize: '12px'
  },
  avatar: {
    marginLeft: theme.spacing(1),
    height: '24px',
    width: '24px',
    fontSize: '12px',
    background: theme.palette.cards.avatar
  },
  avatarLight: {
    marginLeft: theme.spacing(1),
    height: '24px',
    width: '24px',
    fontSize: '12px',
    background: theme.palette.biologics.textSecondary
  },
  description: {
    height: theme.spacing(15),
    fontWeight: '400',
    overflowY: 'scroll',
    marginTop: theme.spacing(2),
    // fontSize: theme.spacing(2),
    color: theme.palette.cards.grayDescription
  },
  meter: {
    height: '100%'
  },
  meterHeading: {
    marginTop: theme.spacing(4),
    fontWeight: '400',
    color: theme.palette.cards.grayDark,
    marginBottom: theme.spacing(-2)
  },
  icon: {
    marginRight: theme.spacing(0.5),
    fontSize: theme.spacing(1.5)
  },
  regIcon: {
    cursor: 'pointer',
    height: theme.spacing(6)
  },
  numberNDA: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1.4),
    paddingRight: theme.spacing(1.4),
    borderRadius: theme.spacing(1.5),
    background: theme.palette.cards.red,
    color: theme.palette.white,
    marginRight: theme.spacing(1),
    fontSize: '11px'
  },
  numberEUA: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1.4),
    paddingRight: theme.spacing(1.4),
    borderRadius: theme.spacing(1.5),
    background: theme.palette.cards.orange,
    color: theme.palette.white,
    marginRight: theme.spacing(1),
    fontSize: '11px'
  },
  numberBLA: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1.4),
    paddingRight: theme.spacing(1.4),
    borderRadius: theme.spacing(1.5),
    background: theme.palette.cards.blue,
    color: theme.palette.white,
    marginRight: theme.spacing(1),
    fontSize: '11px'
  },
  numberDenovo: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1.4),
    paddingRight: theme.spacing(1.4),
    borderRadius: theme.spacing(1.5),
    background: '#DA70D6',
    color: theme.palette.white,
    marginRight: theme.spacing(1),
    fontSize: '11px'
  },
  adminDate: {
    color: theme.palette.regulatory.lightGreen,
    background: theme.palette.white,
    borderRadius: theme.spacing(0.8),
    margin: '0px 70px'
  },
  paperHeader: {
    background: '#fff',
    border: '1px solid #C9D2F6',
    borderRadius: '20px'
  },
  guageBox: {
    textAlign: 'center',
    paddingTop: '4px'
  },
  noOfDaysBox: {
    width: '100%',
    borderRadius: '20px',
    background: theme.palette.white,
    color: theme.palette.cards.avatar,
    fontSize: '10px'
  },
  extendedCard: {
    minHeight: '65vh'
  }
}));
