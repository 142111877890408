import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import themev5 from '../../../themev5';

const AriaTextField = styled(TextField)(() => ({
  background: themev5.palette.gray.backgroundDark,
  border: 'none',
  padding: 10,
  borderRadius: 10,
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: themev5.palette.gray.backgroundDark
    },
    '&:hover fieldset': {
      borderColor: themev5.palette.gray.backgroundDark
    },
    '&.Mui-focused fieldset': {
      borderColor: themev5.palette.gray.backgroundDark
    }
  }
}));

export default AriaTextField;
