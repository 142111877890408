import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

// muiv5
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import VivproDatagrid from '../../../components/Datagrid';

// context
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';

// hoc
import withFetchingGenerics from './withFetchingGenerics';

import { GENERICS_COLUMN_DEFINITION } from './constants';

interface ParameterProps {
  source: string;
}

const GenericDetails = () => {
  const { regulatoryState } = useContext(RegulatoryInformationStore);
  // get the url parameters
  const { source } = useParams<ParameterProps>();
  return (
    <Box display='flex' sx={{ width: '100%' }}>
      <Stack flexDirection='row' sx={{ height: '60vh', width: 'calc(100% - 24px)' }}>
        <VivproDatagrid
          rows={regulatoryState?.generics}
          columnsMapping={GENERICS_COLUMN_DEFINITION[source?.toLowerCase() as string]}
          rowId='anda'
          loading={regulatoryState?.loadingGenerics}
        />
      </Stack>
    </Box>
  );
};

export default withFetchingGenerics(GenericDetails);
