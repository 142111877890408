export const deleteParamsOnTabChange = (updatedSearchParams: URLSearchParams) => {
  updatedSearchParams.delete('cttimelinesort');
  updatedSearchParams.delete('cttype');
  updatedSearchParams.delete('view');
  updatedSearchParams.delete('sort');
};

export const isTruthyValue = (item: any) =>
  item !== '' &&
  item !== 'nan' &&
  item !== 'None' &&
  item !== 'null' &&
  item !== null &&
  item !== '-';

export default { deleteParamsOnTabChange };
