import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Amplify from 'aws-amplify';
import { LicenseInfo } from '@mui/x-license-pro';
import * as serviceWorker from './serviceWorker';
// import UnderMaintenance from './pages/Maintenance/UnderMaintenance';
import App from './components/App';

Amplify.configure({
  Auth: {
    manatorySignId: true,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT
  },
  oauth: {
    domain: process.env.REACT_APP_COGNITO_DOMAIN,
    scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: `${window.location.origin}/`,
    redirectSignOut: `${window.location.origin}/`,
    responseType: 'code',
    clientId: process.env.REACT_APP_COGNITO_APP_CLIENT,
    state: 'STATE',
    autoRefreshToken: {
      enable: true
    }
  }
});

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_LICENCE_KEY);
// ReactDOM.render(<UnderMaintenance />, document.getElementById('root'));
ReactDOM.render(<App />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
