import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Box, Divider, Stack } from '@mui/material';
import Navigation from './Navigation/Navigation';
import styles from '../styles/LandingPage.styles';
import HeaderTabs from './HeaderTabs';
import { HEADERS } from '../constants';
import ResultsStore from '../../../store/SearchResults';
import PdfDetailsView from '../../SearchResults/components/PdfDetailsView';
import Loading from '../../SearchResults/components/Loading';
import ProjectTitle from './SavedItemsAndProjects/ProjectTitle';
import ResultActions from '../../../store/SearchResults/actions';

const LandingPage = ({ childrenRoutes }: { childrenRoutes: any[] }) => {
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const location = useLocation();
  const headerType = useMemo(
    () => location.pathname.split('/')?.[2] ?? 'activity',
    [location.pathname]
  );
  const [selectedTab, setSelectedTab] = useState<string>(HEADERS[headerType]?.[0].key ?? '');

  const documentOpen = useMemo(
    () => resultsState.documentLoader || resultsState?.selectedDocument,
    [resultsState.documentLoader, resultsState?.selectedDocument]
  );

  // based on search params, set the selected tab using useEffect
  useEffect(() => {
    const urlTabValue = new URLSearchParams(location.search).get('tab');
    if (urlTabValue) {
      setSelectedTab(urlTabValue);
    } else {
      setSelectedTab(HEADERS[headerType]?.[0].key ?? '');
    }
    resultsDispatch({ type: ResultActions.SET_SELECTED_DOCUMENT, value: null });
    resultsDispatch({ type: ResultActions.SET_DOCUMENT_LOADER, value: false });
  }, [location, headerType]);

  return (
    <Box sx={styles.root}>
      <Box sx={styles.rootGrid}>
        {!documentOpen && (
          <Box sx={styles.navigationBox}>
            <Navigation />
          </Box>
        )}
        <Box
          data-dd-privacy='mask'
          sx={{ ...styles.containerGrid, width: documentOpen ? '50%' : '100%' }}>
          <Stack
            direction='column'
            sx={headerType in HEADERS ? styles.container : styles.containerWithoutBorder}>
            {location.pathname.includes('project') && <ProjectTitle />}

            {headerType in HEADERS && (
              <>
                <HeaderTabs
                  header={HEADERS[headerType]}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                />
                <Divider />
              </>
            )}
            <Switch>
              {childrenRoutes.map((route: { path: any; component: any }) => {
                const { path, component: Component } = route;
                return (
                  <Route
                    key={`${path}-user-profile-sub-route`}
                    path={path}
                    exact
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    render={props => <Component {...props} selectedTab={selectedTab} />}
                  />
                );
              })}
            </Switch>
          </Stack>
        </Box>
        <Box
          sx={{
            overflowY: 'none',
            width: documentOpen ? '50%' : '0',
            minWidth: documentOpen ? '670px' : '0'
          }}>
          {resultsState.documentLoader && (
            <Stack sx={styles.documentLoadingStack} justifyContent='center' alignItems='center'>
              <Loading showTips={false} />
            </Stack>
          )}
          {resultsState?.selectedDocument && <PdfDetailsView fromUserProfile />}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(LandingPage);
