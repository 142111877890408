import { styled } from '@mui/material';
import Switch from '@mui/material/Switch';

const CustomSwitch = styled(Switch)(({ theme }: any) => ({
  '& .MuiSwitch-switchBase': {
    padding: 0,
    transform: 'translate(8px, 9.5px)',
    '&.Mui-checked': {
      transform: 'translate(22px,9.5px)',
      '& > .MuiSwitch-thumb': {
        backgroundColor: theme.palette.primary[600]
      }
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.primary[200],
      opacity: 1
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: theme.palette.grey[300],
      opacity: 1
    }
  },
  '& .MuiSwitch-track': {
    marginY: 'auto',
    height: '9px',
    width: '21px',
    borderRadius: '5.25px',
    backgroundColor: theme.palette.grey[500],
    opacity: 1
  },
  '& .MuiSwitch-thumb': {
    marginY: 'auto',
    width: '13.5px',
    height: '13.5px',
    borderRadius: '7.5px',
    color: theme.palette.common.white,
    boxShadow: '0px 0.75px 3px 0px #00000066 !important'
  }
}));

export default CustomSwitch;
