import React, { useContext } from 'react';

// muiv5
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import VivproDatagrid from '../../../components/Datagrid';
import EmptyPage from '../../../components/EmptyPage/EmptyPage';
// context
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';

// hoc
import withFetchingProceduralSteps from './withFetchingProceduralSteps';

import { ProceduralSteps } from './constants';

const ProceduralStepsGrid = () => {
  const { regulatoryState } = useContext(RegulatoryInformationStore);

  return (
    <Box display='flex' justifyContent='center' sx={{ width: '100%', height: '100%' }}>
      {!regulatoryState.loadingproceduralSteps && !regulatoryState.proceduralSteps?.length ? (
        <EmptyPage message='Procedural Steps are not available' />
      ) : (
        <Stack sx={{ m: 2, height: '60vh', width: '100%' }}>
          <VivproDatagrid
            rows={regulatoryState?.proceduralSteps}
            columnsMapping={ProceduralSteps}
            rowId='appln_no'
            loading={regulatoryState?.loadingproceduralSteps}
          />
        </Stack>
      )}
    </Box>
  );
};

export default withFetchingProceduralSteps(ProceduralStepsGrid);
