/* eslint-disable import/no-named-as-default */
import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import Actions from '../../../store/RegulatoryInformation/actions';
import EmptyPage from '../../../components/EmptyPage/EmptyPage';
// Components and Interfaces
import CustomTimeline from '../../../components/Timeline/CustomTimeline';
import { UrlParameters } from '../../../types/reactRouterDom';
import { fileNomenclature } from '../../../components/csvdownload/csvPropsType';
// Constants
import { COLUMNS_EU, COMMISSION_PROCEDURES } from './constants';

// APIs
import regulatoryInformationAPI from '../../../api/pages/Regulatory360';
import getDocuments from '../../../components/Timeline/TimelineDocuments/getDocuments';
import getDateString from '../../../utils/getDateString';
import getSubmissionName from '../../../components/Timeline/TimelineDocuments/getSubmissionName';

const CommissionProcedures = () => {
  const { regulatoryState, regulatoryDispatch } = useContext(RegulatoryInformationStore);
  const { module, source } = useParams<UrlParameters>();
  const [noContent, setNoContent] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [filename, setfilename] = useState<fileNomenclature>({
    prefix: module || 'core',
    module: source || 'us',
    postfix: new Date().toISOString().split('T')[0],
    additional: '_commission_procedures',
    extension: 'csv'
  });

  const getSubmissions = (approvalHistory: any[]) => {
    return approvalHistory?.map((history: any) => ({
      id: getSubmissionName(history, 'commissionProcedure'),
      name: getSubmissionName(history, 'commissionProcedure'),
      getDocuments: () => getDocuments(history, 'commissionProcedure'),
      metadata: [getDateString(history.approval_date)]
    }));
  };

  useEffect(() => {
    if (regulatoryState?.commissionProcedures.length === 0) {
      const getData = async () => {
        setLoading(true);
        const data = await regulatoryInformationAPI(
          regulatoryState?.db,
          regulatoryState?.applicationDetails?.application_number,
          COMMISSION_PROCEDURES
        );
        if (!data?.data?.body?.length) {
          setNoContent(true);
        } else {
          regulatoryDispatch({ type: Actions.SET_COMMISSION_PROCEDURES, value: data.data.body });
          regulatoryDispatch({
            type: Actions.SET_COMMISSION_PROCEDURE_SUBMISSION_DOCUMENTS,
            value: getSubmissions(data.data.body)
          });
        }
        setLoading(false);
      };
      getData();
      setfilename({
        prefix: regulatoryState?.applicationDetails?.active_ingredients || 'core',
        module: source || 'us',
        postfix: new Date().toISOString(),
        additional: '_commission_procedures',
        extension: 'csv'
      });
    }
    // set the state with the context
  }, [regulatoryState.db, regulatoryState?.applicationDetails]);

  if (noContent) {
    return <EmptyPage message='No content for Commission Procedures' />;
  }

  return (
    <CustomTimeline
      columns={COLUMNS_EU}
      timeline={regulatoryState?.commissionProcedures}
      triggeredBy='commission_procedures'
      filename={filename}
      isLoading={isLoading}
      source={source || 'us'}
    />
  );
};

export default React.memo(CommissionProcedures);
