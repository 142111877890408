// mui v5
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import themev5 from '../../themev5';

import Page from './Page';
import useUpdateModule from '../../hooks/useUpdateModule';

import { DrugDevelopmentToolsStateProvider } from '../../store/DrugDevelopmentTools';

const DrugDevelopmentTools = () => {
  useUpdateModule('drugDevelopmentTools');

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <DrugDevelopmentToolsStateProvider>
        <Page />
      </DrugDevelopmentToolsStateProvider>
    </ThemeProvider>
  );
};

export default DrugDevelopmentTools;
