import React, { useContext, useEffect } from 'react';

// Provider
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

import themev5 from '../../themev5';
import DesignationTabs from './hoc/Tabs';
import { DesignationStateProvider } from '../../store/Designation';
import Actions from '../../store/actions';
import Store from '../../store';

const SpecialityDesignation = () => {
  const { dispatch } = useContext(Store) as any;

  useEffect(() => {
    dispatch({ type: Actions.SET_MODULE, value: 'designation' });
  }, []);
  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <DesignationStateProvider>
        <DesignationTabs />
      </DesignationStateProvider>
    </ThemeProvider>
  );
};

export default React.memo(SpecialityDesignation);
