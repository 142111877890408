import theme from '../../../themev5';

const styles = {
  root: {
    background: '#F7F7F7',
    margin: '20px',
    width: '100%'
  },
  box: {
    borderRadius: '3px',
    width: '100%'
  },
  addToReport: {
    height: '35px',
    cursor: 'pointer',
    background: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: 'none',
      border: 1,
      borderColor: 'primary.main',
      borderStyle: 'solid'
    }
  },
  graphHeading: {
    fontSize: 14,
    color: 'gray.800',
    fontWeight: '700'
  },
  meetingHeading: {
    fontSize: '16px',
    color: 'gray.lightVariant',
    fontWeight: 'bold'
  },
  reportText: {
    color: 'primary',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    '&:hover': {
      background: 'none'
    }
  },
  barGraph: {
    backgroundColor: 'white.main',
    height: '30vh',
    borderRadius: '20px',
    [theme.breakpoints.up('xl')]: {
      height: '35vh'
    }
  },
  pieCharts: {
    paddingTop: 1,
    height: '30vh'
  },
  pieContainers: {
    heigth: '100%',
    marginTop: 1,
    marginLeft: 1,
    padding: 1,
    width: 'calc(50% - 8px)',
    backgroundColor: 'white.main',
    borderRadius: '10px'
  },
  pieChartMargin: { top: 20, right: 45, bottom: 50, left: 30 },
  heading: {
    fontSize: 14,
    fontWeight: 700,
    color: 'primary.700'
  },
  info: {
    display: 'flex',
    height: 40,
    padding: '8px 16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'gray.0',
    borderRadius: 2,
    cursor: 'pointer'
  },
  meetingContainer: {
    backgroundColor: 'gray.0',
    borderRadius: 2,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: 'inherit',
    padding: 2,
    gap: 2
  },
  dialogText: {
    textTransform: 'capitalize',
    overflow: 'hidden',
    color: 'gray.700',
    textOverflow: 'ellipsis',
    fontSize: 14,
    fontWeight: 700,
    width: '100%'
  },
  meetingData: {
    fontSize: 14,
    color: 'gray.700',
    fontWeight: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%'
  },
  tabContainer: {
    display: 'flex',
    paddingRight: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderBottomColor: 'gray.lightBorder',
    background: '#F7F7F7',
    alignItems: 'flex-end',
    maxHeight: '20%'
  },
  tabs: {
    paddingLeft: 1,
    marginBottom: '-1px'
  },
  activeTab: {
    border: '0.5px solid',
    borderColor: 'gray.light',
    borderRadius: '10px 10px 0px 0px',
    fontSize: '16px',
    boxShadow: 'none',
    color: 'gray.main',
    textTransform: 'capitalize'
  },
  inactiveTab: {
    borderRadius: '10px 10px 0px 0px',
    textTransform: 'capitalize',
    color: 'white.main',
    fontFamily: 'Nunito Sans',
    fontWeight: '600',
    fontSize: '16px',
    boxShadow: 'none',
    background: 'gradient.main'
  },
  paper: {
    margin: 3,
    width: '50%',
    height: '50%',
    padding: 3,
    borderRadius: 3
  },
  loaderBox: {
    width: '90%',
    height: '90%'
  },
  barGraphRect: {
    width: '100%',
    height: '100%'
  },
  cards: {
    height: '70vh',
    width: '30vh'
  },
  card: {
    backgroundColor: 'white.main',
    padding: 2,
    borderRadius: 2,
    height: '93%',
    display: 'flex',
    alignContent: 'space-between',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  meetingCard: {
    backgroundColor: 'primary.backgroundDark',
    borderRadius: 2,
    height: 'auto',
    display: 'flex',
    alignContent: 'space-between',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  selectedtitle: {
    paddingTop: '5px',
    fontSize: 14,
    color: 'primary.dark',
    textTransform: 'capitalize',
    fontWeight: '600',
    cursor: 'pointer',
    border: `1px solid #33B187`,
    p: 1
  },
  unselectedtitle: {
    paddingTop: '5px',
    fontSize: 14,
    textTransform: 'capitalize',
    fontWeight: '600',
    cursor: 'pointer',
    p: 1
  },
  InfoBox: {
    borderRadius: '20px',
    maxHeight: 'inherit',
    overflowY: 'scroll',
    scrollBehavior: 'smooth',
    scrollbarColor: 'primary.main',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'primary.light',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'primary.light'
    }
  },
  dropDownBox: {
    display: 'inline-flex',
    borderRadius: '8px',
    boxShadow: '0px 0px 4px rgba(0,0,0,0.16)',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: 'min-content'
  },
  meetingCardContiner: {
    backgroundColor: 'gray.100',
    borderRadius: 2,
    padding: 2,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: 1,
    width: '100%'
  },
  gridContainer: { height: '100%', width: '100%', pr: 3 },
  gridItems: {
    display: 'flex',
    width: { xs: '100%', md: '80%' },
    p: '0px !important',
    height: '100%'
  }
};

export default styles;
