const styles = {
  container: {
    backgroundColor: 'gray.collaborateGray'
  },
  radioIcon: { width: 14, height: 14, color: 'gray.900' },
  labelText: { color: 'gray.900', fontWeight: 600 },
  circleIcon: {
    pt: 0.5,
    color: 'gray.700',
    fontSize: 6
  },
  infoText: { color: 'gray.700' },
  settingsIcon: {
    color: 'gray.800',
    fontSize: 12,
    width: 14,
    height: 16
  },
  openSettingsIcon: {
    color: 'primary.600',
    fontSize: 12,
    width: 14,
    height: 16
  },
  menuTitle: { color: 'gray.800', textTransform: 'capitalize' },
  openMenuTitle: { color: 'primary.600', textTransform: 'capitalize' }
};

export default styles;
