const Actions = {
  SET_LOADING: 'SET_LOADING',
  SET_ORIGINAL_RESULTS: 'SET_ORIGINAL_RESULTS',
  SET_ARIA_RESULTS: 'SET_ARIA_RESULTS',
  SET_ARIA_EXPLORE_RESULTS: 'SET_ARIA_EXPLORE_RESULTS',
  SET_SUGGESTED_ANS: 'SET_SUGGESTED_ANS',
  SET_DOCUMENT_OPEN: 'SET_DOCUMENT_OPEN',
  SET_DOCUMENT_LINK: 'SET_DOCUMENT_LINK',
  SET_ORIGINAL_LANG_DOCUMENT_LINK: 'SET_ORIGINAL_LANG_DOCUMENT_LINK',
  SET_DOCUMENT_STATE: 'SET_DOCUMENT_STATE',
  SET_GLOBAL_FILTERS: 'SET_GLOBAL_FILTERS',
  SET_FILTERS: 'SET_FILTERS',
  SET_FILTERS_DRAWER: 'SET_FILTERS_DRAWER',
  SET_TOP_ANSWER: 'SET_TOP_ANSWER',
  SET_ORIGNAL_TOP_ANSWER: 'SET_ORIGNAL_TOP_ANSWER',
  SET_ERROR: 'SET_ERROR',
  SET_PAGE: 'SET_PAGE',
  SET_MAX_PAGE: 'SET_MAX_PAGE',
  SET_ORIGINAL_TOTAL_RESULTS: 'SET_ORIGINAL_TOTAL_RESULTS',
  SET_TOTAL_RESULTS: 'SET_TOTAL_RESULTS',
  SET_EXPLORE_PAGE_RESULTS_COUNT: 'SET_EXPLORE_PAGE_RESULTS_COUNT',
  SET_SELECTED_SORT: 'SET_SELECTED_SORT',
  SET_SELECTED_DOCUMENT: 'SET_SELECTED_DOCUMENT',
  SET_DID_YOU_MEAN_TEXT: 'SET_DID_YOU_MEAN_TEXT',
  SCROLL_TO_TOP: 'SCROLL_TO_TOP',
  SET_SEARCH_ID: 'SET_SEARCH_ID',
  SET_IS_FAVORITE: 'SET_IS_FAVORITE',
  SET_IN_PROJECT: 'SET_IN_PROJECT',
  SET_UNIQUE_RESULTS: 'SET_UNIQUE_RESULTS',
  SET_COLLAPSED_SUGGESTION: 'SET_COLLAPSED_SUGGESTION',
  SET_UNIQUE_RESULTS_COUNT: 'SET_UNIQUE_RESULTS_COUNT',
  SET_PENDING_FILTERS: 'SET_PENDING_FILTERS',
  SET_ORIGINAL_TEXT_ENABLED: 'SET_ORIGINAL_TEXT_ENABLED'
};

export default Actions;
