import { Backdrop, Box, LinearProgress } from '@mui/material';
import React from 'react';
import Stack from '@mui/material/Stack';

const AdvancedSearchLoading = () => {
  return (
    <Box
      height='100%'
      position='absolute'
      top={0}
      left={0}
      sx={{
        zIndex: 100,
        width: '100%',
        // background blur effect
        backdropFilter: 'blur(1px)',
        // background color with opacity
        backgroundColor: 'rgba(255, 255, 255, 0.5)'
      }}>
      <Backdrop open sx={{ zIndex: 1 }}>
        <Stack justifyContent='center' alignItems='center' width='100%' height='100%'>
          <Box width={240} mt={2} mb={3}>
            <LinearProgress
              sx={{
                borderRadius: 4,
                color: 'primary.avatar'
              }}
            />
          </Box>
        </Stack>
      </Backdrop>
    </Box>
  );
};
export default AdvancedSearchLoading;
