import React from 'react';
import { createSvgIcon } from '@mui/material';

const ApprovalDateIcon = createSvgIcon(
  <svg width='30' height='31' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect y='0.77002' width='30' height='30' rx='15' fill='#FFD69A' />
    <path
      d='M18.147 16.4827C18.2086 16.4827 18.2394 16.4827 18.301 16.4519C18.4858 16.3903 23.0431 15.251 23.0739 11.7406C23.0739 11.0631 22.612 10.1393 21.6574 9.76982C21.1955 9.58507 20.3641 9.4311 19.2864 10.0162L19.3172 9.70824C19.348 9.52348 19.2864 9.33872 19.1632 9.21555C19.04 9.09238 18.8553 9 18.6705 9H11.4034C11.2186 9 11.0339 9.09238 10.9107 9.21555C10.7875 9.33872 10.7259 9.52348 10.7567 9.70824L10.7875 10.0162C9.70978 9.4311 8.87837 9.58507 8.41647 9.76982C7.49269 10.1701 7 11.0631 7 11.7406C7.03079 15.251 11.5881 16.4211 11.7729 16.4519C11.8345 16.4519 11.8653 16.4827 11.9269 16.4827C12.4811 17.2833 13.3741 17.8684 14.3903 18.0531V21.04H12.1732C11.8037 21.04 11.5266 21.3172 11.5266 21.6867C11.5266 22.0562 11.8037 22.3333 12.1732 22.3333H17.9007C18.2702 22.3333 18.5473 22.0562 18.5473 21.6867C18.5473 21.3172 18.2702 21.04 17.9007 21.04H15.6836V18.0531C16.6998 17.8684 17.5928 17.2525 18.147 16.4827ZM21.1647 10.94C21.5651 11.0939 21.7806 11.4942 21.7806 11.7098C21.7806 13.4958 19.8714 14.4811 18.7629 14.9122C18.7937 14.7275 18.8245 14.5119 18.8245 14.3272L19.1016 11.7098C19.9022 10.9707 20.6105 10.7244 21.1647 10.94ZM8.3241 11.7098C8.3241 11.4942 8.53965 11.0939 8.93995 10.94C9.49423 10.7244 10.2025 11.0015 11.0031 11.7098L11.2802 14.3272C11.2802 14.5427 11.311 14.7275 11.3418 14.9122C10.2333 14.4811 8.3241 13.4958 8.3241 11.7098ZM12.5735 14.3272C12.5735 14.2964 12.5735 14.2656 12.5735 14.2656L12.1424 10.2933H17.9931L17.562 14.2656C17.562 14.2964 17.562 14.3272 17.562 14.3272C17.562 15.7129 16.4534 16.8214 15.0677 16.8214C13.6821 16.8214 12.5735 15.6513 12.5735 14.3272Z'
      fill='currentColor'
    />
  </svg>,
  'ApprovalDateIcon'
);
export default React.memo(ApprovalDateIcon);
