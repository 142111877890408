// react
import { memo, MouseEvent, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

// mui
import {
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  PopoverOrigin,
  Tooltip
} from '@mui/material';

// api
import {
  addSubscription,
  getSubscriptionPreferences,
  removeSubscription,
  updateSubscriptionSourcePreference
} from '../../api/modules/userNotification';

// store
import GlobalStore from '../../store';
import Actions from '../../store/actions';

import { SubscribeIcon, SubscribeIconFilled, ThreeDotIconVariation } from '../../assets/svgs/Icons';
import CustomAlertBox from '../Alert/CustomAlertBox';
import ALLOWED_SOURCE_APPLICATIONS from './const';
import styles from '../FavoritesAndProjectsAction/styles/FavoriteAndProjectActions.styles';
import { CHANNELS } from '../../pages/Notifications/constants';
import SubscriptionPreferences, { SubscriptionPreferencesType } from './SubscriptionPreferences';
import MenuItemWithSubMenu from '../ui/Menu/MenuItemWithSubMenu/MenuItemWithSubMenu';
import { RoundedMenu } from '../ui/Menu';

interface SubscriptionActionsProps {
  // This is used to decide the source of the subscribed actions
  invokedBy: (typeof CHANNELS)[number];
  // This is not needed for search
  source?: string;
  id: string;
  // This is used to decide if the component is a button or a menu item
  isButton: boolean;
  isSubscribed: boolean;
  // This function is used to update the state of isSubscribed in the parent component or hook
  setSubscriptionInHook: Function;
  // This is used to close the menu when the menu is a button
  handleMenuClose?: Function;
  // This is used to decide if the menu is a three dots menu
  isThreeDotsMenu?: boolean;
  // This is used to add any menu items before the subscription preferences
  preMenuItems?: ReactNode;
  // This is used to add any menu items after the subscription preferences
  postMenuItems?: ReactNode;
}

const SubscriptionActions = ({
  invokedBy,
  source,
  id,
  isButton,
  isSubscribed,
  setSubscriptionInHook,
  handleMenuClose,
  isThreeDotsMenu,
  preMenuItems,
  postMenuItems
}: SubscriptionActionsProps) => {
  const { dispatch } = useContext(GlobalStore) as any;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [nestedAnchorEl, setNestedAnchorEl] = useState<null | HTMLElement>(null);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);

  const [manageListLoading, setManageListLoading] = useState(false);
  const [subscriptionPreferences, setSubscriptionPreferences] =
    useState<SubscriptionPreferencesType>({ preferences: {} });
  const [showUnsubscribeAlert, setShowUnsubscribeAlert] = useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const isNestedMenuOpen = Boolean(nestedAnchorEl);

  // Function to get subscription preferences
  const getSubscriptionPreferencesMenu = async () => {
    if ((invokedBy === 'application' || invokedBy === 'data_source') && !source) return;
    try {
      setManageListLoading(true);
      const res = await getSubscriptionPreferences({
        source,
        entityId: id,
        entityType: invokedBy
      });
      if (res && res.status === 200) {
        setSubscriptionPreferences(res.data.body);
      } else {
        throw new Error(`Failed to update subscription preference for ${id}`);
      }
      setManageListLoading(false);
    } catch (e) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { message: `Something went wrong:${(e as Error).message}`, status: true }
      });
      setManageListLoading(false);
    }
  };

  // Function to add or remove subscribtion
  // This component does not save the state of the subscribtion, it just calls the api and updates the state in the parent component
  const handleSubscribe = async () => {
    try {
      let res = null;
      setSubscriptionLoading(true);
      if (invokedBy === 'data_source') {
        res = await updateSubscriptionSourcePreference([
          {
            source: source ?? '',
            subscribed: !isSubscribed
          }
        ]);
      } else if (isSubscribed) {
        res = await removeSubscription({
          entityId: id,
          entityType: invokedBy,
          source: source ?? ''
        });
      } else {
        res = await addSubscription({
          entityId: id,
          entityType: invokedBy,
          source: source ?? ''
        });
      }

      if (res && res.status === 200) {
        setSubscriptionInHook({ source, identifier: id, isSubscribed: !isSubscribed });
        await dispatch({
          type: Actions.SET_ALERT,
          value: {
            message: !isSubscribed ? 'Subscribed' : 'Unsubscribed',
            status: true,
            color: 'success'
          }
        });
        if (isSubscribed) {
          setAnchorEl(null);
          setNestedAnchorEl?.(null);
        }
      } else {
        throw new Error("Couldn't update subscription");
      }
    } catch (e) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: {
          message: (e as Error)?.message ?? `Something went wrong:${(e as Error).message}`,
          status: true
        }
      });
    } finally {
      setSubscriptionLoading(false);
      setShowUnsubscribeAlert(false);
    }
  };

  const handleButtonClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (!isSubscribed) handleSubscribe();
    else setAnchorEl(event?.currentTarget);
  };

  const handleMenuClick = async (event: MouseEvent<HTMLElement>) => {
    if (!isSubscribed) {
      await handleSubscribe();
      handleMenuClose?.();
    } else setNestedAnchorEl?.(event?.currentTarget);
  };

  const handleThreeDotsMenuClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSubscriptionMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCloseNestedMenu = () => {
    setNestedAnchorEl?.(null);
  };

  // Function to decide the position of the nested menu
  const getSubMenuPosition = useMemo(() => {
    if (!nestedAnchorEl) return undefined;
    // You can customize this logic based on your layout and styles
    const windowWidth = window.innerWidth;
    const spaceRight = windowWidth - (nestedAnchorEl?.getBoundingClientRect()?.right ?? 0);
    const spaceLeft = nestedAnchorEl?.getBoundingClientRect()?.left ?? 0;

    // Open to the right if there is enough space, otherwise open to the left
    return spaceRight > spaceLeft
      ? {
          anchorOrigin: { vertical: 'top', horizontal: 'right' } as PopoverOrigin,
          transformOrigin: { vertical: 'top', horizontal: 'left' } as PopoverOrigin
        }
      : {
          anchorOrigin: { vertical: 'top', horizontal: 'left' } as PopoverOrigin,
          transformOrigin: { vertical: 'top', horizontal: 'right' } as PopoverOrigin
        };
  }, [nestedAnchorEl]);

  // Only fetch subscription prefs when the menu is open and it's subscribed
  useEffect(() => {
    if (invokedBy !== 'data_source' && isSubscribed && (isNestedMenuOpen || isMenuOpen))
      getSubscriptionPreferencesMenu();
  }, [isNestedMenuOpen, isMenuOpen, invokedBy, isSubscribed]);

  // Don't show the button if the source is not allowed
  if (invokedBy === 'application' && source && !ALLOWED_SOURCE_APPLICATIONS.includes(source))
    return null;
  return (
    <>
      {isThreeDotsMenu && (
        <IconButton
          id='subscription-action-button'
          aria-label='Subscribe'
          onClick={handleThreeDotsMenuClick}>
          <ThreeDotIconVariation sx={styles.iconGray} />
        </IconButton>
      )}
      {!isThreeDotsMenu && isButton && (
        <Tooltip title={!isSubscribed ? 'Subscribe' : 'Manage subscription'}>
          <IconButton
            id='subscription-action-button'
            aria-label='Subscribe'
            onClick={handleButtonClick}>
            {subscriptionLoading && <CircularProgress size={18} />}
            {!subscriptionLoading &&
              (isSubscribed ? (
                <SubscribeIconFilled sx={styles.iconPrimary} />
              ) : (
                <SubscribeIcon sx={styles.iconGray} />
              ))}
          </IconButton>
        </Tooltip>
      )}
      {!isThreeDotsMenu &&
        !isButton &&
        (isSubscribed ? (
          <MenuItemWithSubMenu
            onMouseOver={handleMenuClick}
            onClick={handleMenuClick}
            MenuItemIcon={
              subscriptionLoading ? (
                <CircularProgress size={18} />
              ) : (
                <SubscribeIconFilled sx={styles.menuIconPrimary} />
              )
            }
            MenuItemText='Subscribed'>
            <SubscriptionPreferences
              isSubscribed={isSubscribed}
              subscriptionLoading={subscriptionLoading}
              manageListLoading={manageListLoading}
              subscriptionPreferences={subscriptionPreferences}
              setSubscriptionPreferences={setSubscriptionPreferences}
              source={source ?? ''}
              id={id}
              invokedBy={invokedBy}
              setShowUnsubscribeAlert={setShowUnsubscribeAlert}
              handleSubscribe={handleSubscribe}
              preMenuItems={preMenuItems}
              postMenuItems={postMenuItems}
            />
          </MenuItemWithSubMenu>
        ) : (
          <MenuItem
            onClick={handleMenuClick}
            sx={{
              backgroundColor: isNestedMenuOpen ? 'gray.100' : null,
              padding: '8px 24px !important'
            }}>
            <ListItemIcon
              // @ts-ignore
              sx={{
                '& svg': { width: '16px', height: '16px' },
                minWidth: '16px !important'
              }}>
              {subscriptionLoading ? (
                <CircularProgress size={18} />
              ) : (
                <SubscribeIcon sx={styles.menuIconGray} />
              )}
            </ListItemIcon>
            <ListItemText
              sx={{
                fontFamily: 'Mulish',
                fontSize: '12px',
                fontWeight: '400',
                ml: '8px',
                color: 'primary.darkVariant1'
              }}>
              Subscribe
            </ListItemText>
          </MenuItem>
        ))}
      {(isButton || isThreeDotsMenu) && (
        <RoundedMenu
          id='card-actions-subscribe-menu'
          disablePortal
          sx={{ zIndex: 1233 }}
          anchorEl={isButton || (isThreeDotsMenu ?? false) ? anchorEl : nestedAnchorEl}
          anchorOrigin={
            isButton || (isThreeDotsMenu ?? false)
              ? { vertical: 'bottom', horizontal: 'left' }
              : getSubMenuPosition?.anchorOrigin
          }
          transformOrigin={
            isButton || (isThreeDotsMenu ?? false) ? undefined : getSubMenuPosition?.transformOrigin
          }
          open={isButton || (isThreeDotsMenu ?? false) ? isMenuOpen : isNestedMenuOpen}
          MenuListProps={{ onMouseLeave: handleCloseNestedMenu }}
          onClose={
            isButton || (isThreeDotsMenu ?? false)
              ? handleSubscriptionMenuClose
              : handleCloseNestedMenu
          }
          onClick={e => {
            // this stops the event from propagating to the parent and causing the card onClick to fire
            e.stopPropagation();
          }}>
          <SubscriptionPreferences
            isSubscribed={isSubscribed}
            subscriptionLoading={subscriptionLoading}
            manageListLoading={manageListLoading}
            subscriptionPreferences={subscriptionPreferences}
            setSubscriptionPreferences={setSubscriptionPreferences}
            source={source ?? ''}
            id={id}
            invokedBy={invokedBy}
            setShowUnsubscribeAlert={setShowUnsubscribeAlert}
            handleSubscribe={handleSubscribe}
            preMenuItems={preMenuItems}
            postMenuItems={postMenuItems}
          />
        </RoundedMenu>
      )}

      {showUnsubscribeAlert && (
        <CustomAlertBox
          message='Are you sure you want to unsubscribe?'
          showAlert={showUnsubscribeAlert}
          yesButtonOnClick={handleSubscribe}
          noButtonOnClick={() => setShowUnsubscribeAlert(false)}
          yesButtonTitle='Yes, Unsubscribe'
          yesLoading={subscriptionLoading}
        />
      )}
    </>
  );
};

SubscriptionActions.defaultProps = {
  source: null,
  handleMenuClose: null,
  isThreeDotsMenu: false,
  preMenuItems: null,
  postMenuItems: null
};

export default memo(SubscriptionActions);
