const styles = {
  root: {
    backgroundColor: 'white.main',
    borderRadius: '8px',
    borderWidth: '0.5px',
    borderStyle: 'solid',
    borderColor: 'primary.200',
    hr: {
      borderColor: '#fefefe',
      opacity: 0.15,
      mt: 1.5,
      mb: 1.5,
      maxHeight: 78
    },
    ':hover': {
      cursor: 'pointer',
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)'
    },
    width: '234px',
    height: '78px',
    p: '10px',
    m: '10px'
  },
  heading: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  chip: {
    ml: 0.5,
    fontSize: 10,
    height: 13,
    borderRadius: 1,
    fontWeight: 800,
    color: 'white.main',
    '& span': {
      px: 0.5
    }
  }
};

export default styles;
