const styles = {
  container: {
    display: 'flex',
    gap: '8px',
    cursor: 'pointer',
    position: 'relative',
    '&:hover > .playButtonContainer': {
      display: 'block',
      cursor: 'pointer'
    },
    '.playButtonContainer': {
      display: 'none',
      position: 'absolute',
      width: '176px',
      height: '100px',
      top: 0,
      borderRadius: '8px',
      background: '#00000066'
    },
    '.playButton': {
      display: 'block',
      margin: '14px auto auto auto',
      height: '70px',
      width: '70px'
    }
  },
  card: {
    width: '176px',
    height: '100px',
    borderRadius: '8px',
    background: 'linear-gradient(269deg, #00B893 -0.83%, #4C8FF2 107.76%)',
    fontFamily: 'Mulish',
    color: 'white.main',
    textAlign: 'center',
    overflow: 'hidden',
    '& img': {
      width: '116.16px',
      mt: '13.32px',
      borderRadius: '8px'
    }
  },
  howToTitle: {
    mt: '14.08px',
    fontSize: '6.16px',
    fontWeight: 400
  },
  pageTitle: { fontSize: '16px', fontWeight: 400, lineHeight: 1 },
  contentContainer: { width: '193px' },
  title: {
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Mulish',
    textOverflow: 'ellipsis'
  },
  description: {
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Mulish',
    color: 'gray.650',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  videoLength: {
    width: '41px',
    height: '19px',
    borderRadius: '4px',
    background: (theme: any) => `${theme.palette.primary.darkVariant1}`,
    padding: '3px 4px',
    color: 'white.main',
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: 'Mulish',
    lineHeight: 1.25,
    boxSizing: 'inherit'
  }
};

export default styles;
