import formatATCInput from './utils/formatATCInput';

/* eslint-disable no-underscore-dangle */
/* Options in Category drop down */
export const SEARCH_TYPE_TEXT = 'TEXT';
export const SEARCH_TYPE_DATE = 'DATE';

export const ADVANCE_SEARCH_MAX_INPUTS = 5;
export const ALL_SECTIONS_KEY = 'label_section_search';
/*
Each category can have following field, 
  {
    label: <lable which appears in the dropdown>,
    value: <unique id or value which is used for internal coding logics>,
    autoCompleteAPIParam: <used in auto complete API payload>,
    urlRouteParam: <used in navigations, localstorage and in the backend api calls>,
    searchType: <used to decide search input component>,
    isAdvancedSearchOption: <If true, it will appear in the advanced search category dropdown>
  }
*/

// For source US FDA - Drug & Biologics and EU EMA - Drug & Biologics
const BASE_CATEGORY_MENU_ITEMS = [
  {
    label: 'Indications',
    value: 'ta-indication',
    autoCompleteAPIParam: 'ta_indication',
    urlRouteParam: 'indications_and_usage',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'indications_and_usage',
    clearSearchTerm: false
  },
  {
    label: 'Active Ingredient',
    value: 'active-ingredient',
    autoCompleteAPIParam: 'active_ingredient',
    urlRouteParam: 'active_ingredients',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    clearSearchTerm: false,
    elasticSearchType: 'active_ingredients'
  },
  {
    label: 'ATC Classification',
    value: 'atc-classification',
    autoCompleteAPIParam: 'atc_classification',
    urlRouteParam: 'atc_classification',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    clearSearchTerm: true,
    elasticSearchType: 'atc_classification'
  },
  {
    label: 'Trade Name',
    value: 'trade-name',
    autoCompleteAPIParam: 'trade_name',
    urlRouteParam: 'trade_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    clearSearchTerm: false,
    elasticSearchType: 'trade_name'
  },
  {
    label: 'Formulation',
    value: 'formulation',
    autoCompleteAPIParam: 'formulation',
    urlRouteParam: 'formulation',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'formulation',
    clearSearchTerm: true
  },
  {
    label: 'Route',
    value: 'route',
    autoCompleteAPIParam: 'route',
    urlRouteParam: 'route',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'route',
    clearSearchTerm: true
  },
  {
    label: 'Sponsor',
    value: 'sponsor',
    autoCompleteAPIParam: 'sponsor',
    urlRouteParam: 'sponsor_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'sponsor_name',
    clearSearchTerm: true
  },
  {
    label: 'All Label Sections',
    value: ALL_SECTIONS_KEY,
    autoCompleteAPIParam: ALL_SECTIONS_KEY,
    urlRouteParam: ALL_SECTIONS_KEY,
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: ALL_SECTIONS_KEY
  },
  {
    label: 'Approval Date',
    value: 'approval-date',
    autoCompleteAPIParam: undefined, // Date field, does not have suggestion
    urlRouteParam: 'first_approval_date',
    searchType: SEARCH_TYPE_DATE,
    isAdvancedSearchOption: false,
    clearSearchTerm: true
  }
];
const BASE_CATEGORY_MENU_ITEMS_COMPARISON = [
  {
    label: 'Indications',
    value: 'ta-indication',
    autoCompleteAPIParam: 'ta_indication',
    urlRouteParam: 'indications_and_usage',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'indications_and_usage',
    clearSearchTerm: false
  },
  {
    label: 'Active Ingredient',
    value: 'active-ingredient',
    autoCompleteAPIParam: 'active_ingredient',
    urlRouteParam: 'active_ingredients',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    clearSearchTerm: false,
    elasticSearchType: 'active_ingredients'
  },
  {
    label: 'ATC Classification',
    value: 'atc-classification',
    autoCompleteAPIParam: 'atc_classification',
    urlRouteParam: 'atc_classification',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    clearSearchTerm: true,
    elasticSearchType: 'atc_classification'
  },
  {
    label: 'Trade Name',
    value: 'trade-name',
    autoCompleteAPIParam: 'trade_name',
    urlRouteParam: 'trade_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    clearSearchTerm: false,
    elasticSearchType: 'trade_name'
  },
  {
    label: 'Formulation',
    value: 'formulation',
    autoCompleteAPIParam: 'formulation',
    urlRouteParam: 'formulation',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'formulation',
    clearSearchTerm: true
  },
  {
    label: 'Route',
    value: 'route',
    autoCompleteAPIParam: 'route',
    urlRouteParam: 'route',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'route',
    clearSearchTerm: true
  },
  {
    label: 'Sponsor',
    value: 'sponsor',
    autoCompleteAPIParam: 'sponsor',
    urlRouteParam: 'sponsor_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'sponsor_name',
    clearSearchTerm: true
  },
  {
    label: 'All Label Sections',
    value: ALL_SECTIONS_KEY,
    autoCompleteAPIParam: ALL_SECTIONS_KEY,
    urlRouteParam: ALL_SECTIONS_KEY,
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: ALL_SECTIONS_KEY
  },
  {
    label: 'Approval Date',
    value: 'approval-date',
    autoCompleteAPIParam: undefined, // Date field, does not have suggestion
    urlRouteParam: 'first_approval_date',
    searchType: SEARCH_TYPE_DATE,
    isAdvancedSearchOption: false,
    clearSearchTerm: true
  }
];
const BASE_EU_CATEGORY_MENU_ITEMS = [
  {
    label: 'Indications',
    value: 'ta-indication',
    autoCompleteAPIParam: 'ta_indication',
    urlRouteParam: 'indications_and_usage',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'indications_and_usage',
    clearSearchTerm: false
  },
  {
    label: 'Active Ingredient',
    value: 'active-ingredient',
    autoCompleteAPIParam: 'active_ingredient',
    urlRouteParam: 'active_ingredient',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'active_ingredients',
    clearSearchTerm: false
  },
  {
    label: 'ATC Classification',
    value: 'atc-classification',
    autoCompleteAPIParam: 'atc_classification',
    urlRouteParam: 'atc_classification',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'atc_classification',
    clearSearchTerm: true
  },
  {
    label: 'Trade Name',
    value: 'trade-name',
    autoCompleteAPIParam: 'trade_name',
    urlRouteParam: 'trade_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'trade_name',
    clearSearchTerm: false
  },
  {
    label: 'Formulation',
    value: 'formulation',
    autoCompleteAPIParam: 'formulation',
    urlRouteParam: 'formulation',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'formulation',
    clearSearchTerm: true
  },
  {
    label: 'Route',
    value: 'route',
    autoCompleteAPIParam: 'route',
    urlRouteParam: 'route',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'route',
    clearSearchTerm: true
  },
  {
    label: 'Sponsor',
    value: 'sponsor',
    autoCompleteAPIParam: 'sponsor',
    urlRouteParam: 'sponsor_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'sponsor_name',
    clearSearchTerm: true
  },
  {
    label: 'All Label Sections',
    value: ALL_SECTIONS_KEY,
    autoCompleteAPIParam: ALL_SECTIONS_KEY,
    urlRouteParam: ALL_SECTIONS_KEY,
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: ALL_SECTIONS_KEY
  },
  {
    label: 'Approval Date',
    value: 'approval-date',
    autoCompleteAPIParam: undefined, // Date field, does not have suggestion
    urlRouteParam: 'approval_date',
    searchType: SEARCH_TYPE_DATE,
    isAdvancedSearchOption: false,
    clearSearchTerm: true
  }
];

// For source CT Gov
const CT_GOV_CATEGORY_MENU_ITEMS = [
  {
    label: 'Conditions',
    value: 'ta-indication',
    autoCompleteAPIParam: 'ta_indication',
    urlRouteParam: 'indications_and_usage',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'indications_and_usage',
    clearSearchTerm: false
  },
  {
    label: 'Intervention',
    value: 'active-ingredient',
    autoCompleteAPIParam: 'active_ingredient',
    urlRouteParam: 'interventions',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'active_ingredients',
    clearSearchTerm: false
  },
  {
    label: 'Sponsor',
    value: 'sponsor_name',
    autoCompleteAPIParam: 'sponsor_name',
    urlRouteParam: 'name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'sponsor_name',
    clearSearchTerm: true
  },
  {
    label: 'Endpoint',
    value: 'primary_endpoint',
    autoCompleteAPIParam: 'primary_endpoint',
    urlRouteParam: 'primary_endpoint',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'primary_endpoint',
    clearSearchTerm: true
  }
];

// For source US FDA - Devices
const DEVICES_CATEGORY_MENU_ITEMS = [
  {
    label: 'Definition/AOS',
    value: 'ta-indication',
    autoCompleteAPIParam: 'ta_indication',
    urlRouteParam: 'indications_and_usage',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'indications_and_usage',
    clearSearchTerm: false
  },
  {
    label: 'Generic Name',
    value: 'generic-name',
    autoCompleteAPIParam: 'generic_name',
    urlRouteParam: 'generic_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'generic_name',
    clearSearchTerm: false
  },
  {
    label: 'Trade Name',
    value: 'trade-name',
    autoCompleteAPIParam: 'trade_name',
    urlRouteParam: 'brand_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'trade_name',
    clearSearchTerm: false
  },
  {
    label: 'Sponsor',
    value: 'sponsor',
    autoCompleteAPIParam: 'sponsor',
    urlRouteParam: 'applicant',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'sponsor_name',
    clearSearchTerm: true
  }
];
const BASE_CANADA_CATEGORY_MENU_ITEMS = [
  {
    label: 'Indications',
    value: 'ta-indication',
    autoCompleteAPIParam: 'ta_indication',
    urlRouteParam: 'indications_and_usage',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'indications_and_usage',
    clearSearchTerm: false
  },
  {
    label: 'Active Ingredient',
    value: 'active-ingredient',
    autoCompleteAPIParam: 'active_ingredient',
    urlRouteParam: 'active_ingredients',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'active_ingredients',
    clearSearchTerm: false
  },
  {
    label: 'ATC Classification',
    value: 'atc-classification',
    autoCompleteAPIParam: 'atc_classification',
    urlRouteParam: 'atc_classification',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'atc_classification',
    clearSearchTerm: true
  },
  {
    label: 'Trade Name',
    value: 'trade-name',
    autoCompleteAPIParam: 'trade_name',
    urlRouteParam: 'trade_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'trade_name',
    clearSearchTerm: false
  },
  {
    label: 'Formulation',
    value: 'formulation',
    autoCompleteAPIParam: 'formulation',
    urlRouteParam: 'formulation',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'formulation',
    clearSearchTerm: true
  },
  {
    label: 'Route',
    value: 'route',
    autoCompleteAPIParam: 'route',
    urlRouteParam: 'route',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'route',
    clearSearchTerm: true
  },
  {
    label: 'Sponsor',
    value: 'sponsor',
    autoCompleteAPIParam: 'sponsor',
    urlRouteParam: 'sponsor_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'sponsor_name',
    clearSearchTerm: true
  },
  {
    label: 'All Label Sections',
    value: ALL_SECTIONS_KEY,
    autoCompleteAPIParam: ALL_SECTIONS_KEY,
    urlRouteParam: ALL_SECTIONS_KEY,
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: false
  },
  {
    label: 'Approval Date',
    value: 'approval-date',
    autoCompleteAPIParam: undefined, // Date field, does not have suggestion
    urlRouteParam: 'approval_date',
    searchType: SEARCH_TYPE_DATE,
    isAdvancedSearchOption: false,
    clearSearchTerm: true
  }
];

const BASE_JAPAN_CATEGORY_MENU_ITEMS = [
  {
    label: 'Indications',
    value: 'ta-indication',
    autoCompleteAPIParam: 'ta_indication',
    urlRouteParam: 'indications_and_usage',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'indications_and_usage',
    clearSearchTerm: false
  },
  {
    label: 'Active Ingredient',
    value: 'active-ingredients',
    autoCompleteAPIParam: 'active_ingredients',
    urlRouteParam: 'active_ingredients',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'active_ingredients',
    clearSearchTerm: false
  },
  {
    label: 'Trade Name',
    value: 'trade-name',
    autoCompleteAPIParam: 'trade_name',
    urlRouteParam: 'trade_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'trade_name',
    clearSearchTerm: false
  },
  {
    label: 'Formulation',
    value: 'formulation',
    autoCompleteAPIParam: 'formulation',
    urlRouteParam: 'formulation',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'formulation',
    clearSearchTerm: true
  },
  {
    label: 'Route',
    value: 'route',
    autoCompleteAPIParam: 'route',
    urlRouteParam: 'route',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'route',
    clearSearchTerm: true
  },
  {
    label: 'Sponsor',
    value: 'sponsor',
    autoCompleteAPIParam: 'sponsor',
    urlRouteParam: 'sponsor_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'sponsor_name',
    clearSearchTerm: true
  },
  {
    label: 'All Label Sections',
    value: ALL_SECTIONS_KEY,
    autoCompleteAPIParam: ALL_SECTIONS_KEY,
    urlRouteParam: ALL_SECTIONS_KEY,
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: ALL_SECTIONS_KEY
  },
  {
    label: 'Approval Date',
    value: 'approval-date',
    autoCompleteAPIParam: undefined, // Date field, does not have suggestion
    urlRouteParam: 'sales_date',
    searchType: SEARCH_TYPE_DATE,
    isAdvancedSearchOption: false,
    clearSearchTerm: true
  }
];
const BIOLOGICS_CATEGORY_MENU_ITEMS = [
  {
    label: 'Indications',
    value: 'ta-indication',
    autoCompleteAPIParam: 'ta_indication',
    urlRouteParam: 'indications_and_usage',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'indications_and_usage'
  },
  {
    label: 'Active Ingredient',
    value: 'active-ingredient',
    autoCompleteAPIParam: 'active_ingredient',
    urlRouteParam: 'active_ingredients',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'active_ingredients'
  },
  {
    label: 'Trade Name',
    value: 'trade-name',
    autoCompleteAPIParam: 'trade_name',
    urlRouteParam: 'trade_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'trade_name'
  },
  {
    label: 'Formulation',
    value: 'formulation',
    autoCompleteAPIParam: 'formulation',
    urlRouteParam: 'formulation',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'formulation'
  },
  {
    label: 'Route',
    value: 'route',
    autoCompleteAPIParam: 'route',
    urlRouteParam: 'route',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'route'
  },
  {
    label: 'Sponsor',
    value: 'sponsor',
    autoCompleteAPIParam: 'sponsor',
    urlRouteParam: 'sponsor_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true
  },
  {
    label: 'Approval Date',
    value: 'approval-date',
    autoCompleteAPIParam: undefined, // Date field, does not have suggestion
    urlRouteParam: 'first_approval_date',
    searchType: SEARCH_TYPE_DATE,
    isAdvancedSearchOption: false
  }
];

const _505B2_CATEGORY_MENU_ITEMS = [
  {
    label: 'Indications',
    value: 'ta-indication',
    autoCompleteAPIParam: 'ta_indication',
    urlRouteParam: 'indications_and_usage',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: false
  },
  {
    label: 'Active Ingredient',
    value: 'active-ingredient',
    autoCompleteAPIParam: 'active_ingredient',
    urlRouteParam: 'active_ingredients',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: false
  },
  {
    label: 'Trade Name',
    value: 'trade-name',
    autoCompleteAPIParam: 'trade_name',
    urlRouteParam: 'trade_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: false
  },
  {
    label: 'Formulation',
    value: 'formulation',
    autoCompleteAPIParam: 'formulation',
    urlRouteParam: 'formulation',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true
  },
  {
    label: 'Route',
    value: 'route',
    autoCompleteAPIParam: 'route',
    urlRouteParam: 'route',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true
  },
  {
    label: 'Sponsor',
    value: 'sponsor',
    autoCompleteAPIParam: 'sponsor',
    urlRouteParam: 'sponsor_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: false
  },
  {
    label: 'Approval Date',
    value: 'approval-date',
    urlRouteParam: 'first_approval_date',
    searchType: SEARCH_TYPE_DATE,
    isAdvancedSearchOption: false
  }
];

// For source Hpra
const HPRA_CATEGORY_MENU_ITEMS = [
  {
    label: 'Indications',
    value: 'ta-indication',
    autoCompleteAPIParam: 'ta_indication',
    urlRouteParam: 'indications_and_usage',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'indications_and_usage',
    clearSearchTerm: false
  },
  {
    label: 'Active Ingredient',
    value: 'active-ingredient',
    autoCompleteAPIParam: 'active_ingredient',
    urlRouteParam: 'active_ingredient',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'active_ingredients',
    clearSearchTerm: false
  },
  {
    label: 'ATC Classification',
    value: 'atc-classification',
    autoCompleteAPIParam: 'atc_classification',
    urlRouteParam: 'atc_classification',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'atc_classification',
    clearSearchTerm: true
  },
  {
    label: 'Trade Name',
    value: 'trade_name',
    autoCompleteAPIParam: 'trade_name',
    urlRouteParam: 'trade_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'trade_name',
    clearSearchTerm: false
  },
  {
    label: 'Formulation',
    value: 'formulation',
    autoCompleteAPIParam: 'formulation',
    urlRouteParam: 'formulation',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'formulation',
    clearSearchTerm: true
  },
  {
    label: 'Route',
    value: 'route',
    autoCompleteAPIParam: 'route',
    urlRouteParam: 'route',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'route',
    clearSearchTerm: true
  },
  {
    label: 'Sponsor',
    value: 'sponsor',
    autoCompleteAPIParam: 'sponsor_name',
    urlRouteParam: 'sponsor_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'sponsor_name',
    clearSearchTerm: true
  },
  {
    label: 'All Label Sections',
    value: ALL_SECTIONS_KEY,
    autoCompleteAPIParam: ALL_SECTIONS_KEY,
    urlRouteParam: ALL_SECTIONS_KEY,
    searchType: SEARCH_TYPE_TEXT,
    elasticSearchType: ALL_SECTIONS_KEY,
    isAdvancedSearchOption: true
  },
  {
    label: 'Approval Date',
    value: 'approval_date',
    autoCompleteAPIParam: undefined, // Date field, does not have suggestion
    urlRouteParam: 'approval_date',
    searchType: SEARCH_TYPE_DATE,
    isAdvancedSearchOption: false,
    clearSearchTerm: true
  }
];

// For HMA source
const HMA_CATEGORY_MENU_ITEMS = [
  {
    label: 'Indications',
    value: 'ta-indication',
    autoCompleteAPIParam: 'ta_indication',
    urlRouteParam: 'indications_and_usage',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'indications_and_usage',
    clearSearchTerm: false
  },
  {
    label: 'Active Ingredient',
    value: 'active-ingredient',
    autoCompleteAPIParam: 'active_ingredient',
    urlRouteParam: 'active_ingredient',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'active_ingredients',
    clearSearchTerm: false
  },
  {
    label: 'ATC Classification',
    value: 'atc-classification',
    autoCompleteAPIParam: 'atc_classification',
    urlRouteParam: 'atc_classification',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'atc_classification',
    clearSearchTerm: true
  },
  {
    label: 'Trade Name',
    value: 'trade_name',
    autoCompleteAPIParam: 'trade_name',
    urlRouteParam: 'trade_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'trade_name',
    clearSearchTerm: false
  },
  {
    label: 'Formulation',
    value: 'formulation',
    autoCompleteAPIParam: 'formulation',
    urlRouteParam: 'formulation',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'formulation',
    clearSearchTerm: true
  },
  {
    label: 'Sponsor',
    value: 'sponsor',
    autoCompleteAPIParam: 'sponsor_name',
    urlRouteParam: 'sponsor_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'sponsor_name',
    clearSearchTerm: true
  },
  {
    label: 'Approval Date',
    value: 'approval_date',
    autoCompleteAPIParam: undefined, // Date field, does not have suggestion
    urlRouteParam: 'approval_date',
    searchType: SEARCH_TYPE_DATE,
    isAdvancedSearchOption: false,
    clearSearchTerm: true
  }
];

/* Options in Source Dropdown */
export const CORE_SOURCE_MENU_ITEMS = [
  {
    label: 'US FDA - Drugs & Biologics',
    value: 'us-fda-drug-biologics',
    categories: BASE_CATEGORY_MENU_ITEMS,
    autoCompleteAPIParam: 'fda',
    urlRouteParam: 'US',
    db: 'us',
    isAdvancedSearchOption: true // for source, it will enable advance search button
  },
  {
    label: 'EU EMA - Drugs & Biologics',
    value: 'eu-ema-drug-biologics',
    categories: BASE_EU_CATEGORY_MENU_ITEMS,
    autoCompleteAPIParam: 'ema',
    urlRouteParam: 'EU',
    db: 'eu',
    isAdvancedSearchOption: true
  },
  {
    label: 'CA HPFB - Drugs & Biologics',
    value: 'canada',
    categories: BASE_CANADA_CATEGORY_MENU_ITEMS,
    autoCompleteAPIParam: 'ca',
    urlRouteParam: 'ca',
    db: 'ca',
    isAdvancedSearchOption: true
  },
  {
    label: 'EU HMA - Drugs & Biologics',
    value: 'hma',
    categories: HMA_CATEGORY_MENU_ITEMS,
    autoCompleteAPIParam: 'hma',
    urlRouteParam: 'hma',
    db: 'hma',
    isAdvancedSearchOption: true
  },
  {
    label: 'IE HPRA - Drugs & Biologics',
    value: 'hpra',
    categories: HPRA_CATEGORY_MENU_ITEMS,
    autoCompleteAPIParam: 'hpra',
    urlRouteParam: 'hpra',
    db: 'hpra',
    isAdvancedSearchOption: true
  },
  {
    label: 'JP PMDA - Drugs & Biologics',
    value: 'japan',
    categories: BASE_JAPAN_CATEGORY_MENU_ITEMS,
    autoCompleteAPIParam: 'jp',
    urlRouteParam: 'jp',
    db: 'jp',
    isAdvancedSearchOption: true
  },
  {
    label: 'CT GOV - Clinical Trials',
    value: 'ct-gov-clinical-trials',
    categories: CT_GOV_CATEGORY_MENU_ITEMS,
    autoCompleteAPIParam: 'ct',
    urlRouteParam: 'ct',
    db: 'clinicalTrials',
    isAdvancedSearchOption: true
  },
  {
    label: 'EU Clinical Trials',
    value: 'eu-clinical-trials',
    categories: [],
    autoCompleteAPIParam: 'euctr',
    urlRouteParam: 'euctr',
    db: 'euClinicalTrials',
    isAdvancedSearchOption: true
  },
  {
    label: 'US FDA - Devices',
    value: 'us-fda-devices',
    categories: DEVICES_CATEGORY_MENU_ITEMS,
    autoCompleteAPIParam: 'us-devices',
    urlRouteParam: 'US-Devices',
    db: 'us-devices',
    isAdvancedSearchOption: true
  }
];

const _505B2_SOURCE_MENU_ITEMS = [
  {
    label: 'US FDA - Drugs',
    value: 'us-fda-drug',
    categories: _505B2_CATEGORY_MENU_ITEMS,
    autoCompleteAPIParam: 'fda',
    urlRouteParam: 'US',
    db: 'us',
    isAdvancedSearchOption: true // for source it will enable adavnce search button
  }
];

export const BIOLOGICS_SOURCE_MENU_ITEMS = [
  {
    label: 'US FDA - Biologics',
    value: 'biologics',
    categories: BIOLOGICS_CATEGORY_MENU_ITEMS,
    autoCompleteAPIParam: 'fda',
    urlRouteParam: 'US',
    db: 'us',
    isAdvancedSearchOption: true // for source it will enable adavnce search button
  }
];

export const MODULE_AND_SOURCES = {
  core: CORE_SOURCE_MENU_ITEMS,
  '505b2': _505B2_SOURCE_MENU_ITEMS,
  biologics: BIOLOGICS_SOURCE_MENU_ITEMS,
  designation: CORE_SOURCE_MENU_ITEMS
};

// Some modules might not have source selection; we can let them use
export const MODULE_AND_CATEGORIES = {
  comparison: BASE_CATEGORY_MENU_ITEMS_COMPARISON
};

export const MODULE_HEADER_TEXTS = {
  pmr: {
    // if same module has multiple routes and if we have to change the title, use url path to match the header text
    'pmr/surveillance': 'Surveillance for FDA Postmarketing Requirements and Commitments'
  },
  pmrImpact: 'IMPACT OF FDA POSTMARKETING REQUIREMENTS AND COMMITMENTS',
  pediatricsFDA: 'FDA Written Requests',
  pediatricsEMA: 'EMA PIPs',
  guidance: 'US FDA Guidances',
  EMAGuidance: 'EMA Guidances',
  CanadaGuidance: 'Canada Guidances',
  HTA: 'HTA',
  'fda-letters': 'FDA Warnings and Untitled Letters',
  adcomm: 'Advisory Committee',
  pmrSurveillance: 'Surveillance for FDA Postmarketing Requirements and Commitments',
  designation: 'Expedited Pathway',
  chmp: 'CHMP',
  euctrPediatrics: 'EUCTR Pediatrics Trials'
};
export const MODULE_HEADER_TEXTS_DEFAULT = {
  core: 'Core',
  biologics: 'Biologics',
  comparison: 'XCompare',
  userProfile: 'User Profile'
};

export const MODULE_HEADER_LINK = {
  core: '/core',
  biologics: '/biologics',
  '505b2': '/505b2',
  pmr: '/pmr/surveillance',
  pmrImpact: '/pmr/impact',
  pediatrics: '/pediatrics',
  pediatricsEMA: '/pediatricsEMA',
  guidance: '/guidanceDocuments',
  HTA: '/htaDocuments',
  'fda-letters': '/letters',
  adcomm: '/adcomm?committee=ALL',
  pmrSurveillance: '/pmr/surveillance',
  euctrPediatrics: '/euctr/pediatrics',
  designation: '/designation',
  userProfile: '/account'
};

// Parsing search input before sending to API
export const CATEGORY_INPUT_FORMATTING = {
  atc_classification: formatATCInput
};

export const CATEGORY_SOURCE_MAP = {
  indications_and_usage: {
    US: 'indications_and_usage',
    EU: 'indications_and_usage',
    ca: 'indications_and_usage',
    ct: 'indications_and_usage',
    'US-Devices': 'indications_and_usage',
    hpra: 'indications_and_usage',
    hma: 'indications_and_usage',
    jp: 'indications_and_usage'
  },
  active_ingredients: {
    US: 'active_ingredients',
    EU: 'active_ingredient',
    ca: 'active_ingredients',
    ct: 'interventions',
    'US-Devices': 'generic_name',
    hpra: 'active_ingredient',
    hma: 'active_ingredient',
    jp: 'active_ingredients'
  },
  atc_classification: {
    US: 'atc_classification',
    EU: 'atc_classification',
    ca: 'atc_classification',
    ct: '',
    'US-Devices': '',
    hpra: 'atc_classification',
    hma: 'atc_classification',
    jp: ''
  },
  trade_name: {
    US: 'trade_name',
    EU: 'trade_name',
    ca: 'trade_name',
    ct: '',
    'US-Devices': 'brand_name',
    hpra: 'trade_name',
    hma: 'trade_name',
    jp: 'trade_name'
  },
  formulation: {
    US: 'formulation',
    EU: 'formulation',
    ca: 'formulation',
    ct: '',
    'US-Devices': '',
    hpra: 'formulation',
    hma: 'formulation',
    jp: 'formulation'
  },
  route: {
    US: 'route',
    EU: 'route',
    ca: 'route',
    ct: '',
    'US-Devices': '',
    hpra: 'route',
    jp: 'route'
  },
  sponsor_name: {
    US: 'sponsor_name',
    EU: 'sponsor_name',
    ca: 'sponsor_name',
    ct: 'name',
    'US-Devices': 'applicant',
    hpra: 'sponsor_name',
    hma: 'sponsor_name',
    jp: 'sponsor_name'
  },
  first_approval_date: {
    US: 'first_approval_date',
    EU: 'approval_date',
    ca: 'approval_date',
    ct: '',
    'US-Devices': '',
    hpra: 'approval_date',
    hma: 'approval_date',
    jp: 'approval_date'
  },
  // EU
  active_ingredient: {
    US: 'active_ingredients',
    EU: 'active_ingredient',
    ca: 'active_ingredients',
    ct: 'interventions',
    'US-Devices': 'generic_name',
    hpra: 'active_ingredient',
    hma: 'active_ingredient',
    jp: 'active_ingredients'
  },
  approval_date: {
    US: 'first_approval_date',
    EU: 'approval_date',
    ca: 'approval_date',
    ct: '',
    'US-Devices': '',
    hpra: 'approval_date',
    hma: 'approval_date',
    jp: 'approval_date'
  },
  // CT
  interventions: {
    US: 'active_ingredients',
    EU: 'active_ingredient',
    ca: 'active_ingredients',
    ct: 'interventions',
    'US-Devices': 'generic_name',
    hpra: 'active_ingredient',
    hma: 'active_ingredient',
    jp: 'active_ingredients'
  },
  name: {
    US: 'sponsor_name',
    EU: 'sponsor_name',
    ca: 'sponsor_name',
    ct: 'name',
    'US-Devices': 'applicant',
    hpra: 'sponsor_name',
    hma: 'sponsor_name',
    jp: 'sponsor_name'
  },
  primary_endpoint: {
    US: '',
    EU: '',
    ca: '',
    ct: 'primary_outcome',
    'US-Devices': '',
    hpra: '',
    hma: '',
    jp: ''
  },
  // DEVICE
  generic_name: {
    US: 'active_ingredients',
    EU: 'active_ingredient',
    ca: 'active_ingredients',
    ct: 'interventions',
    'US-Devices': 'generic_name',
    hpra: 'active_ingredient',
    hma: 'active_ingredient',
    jp: 'active_ingredients'
  },
  brand_name: {
    US: 'trade_name',
    EU: 'trade_name',
    ca: 'trade_name',
    ct: '',
    'US-Devices': 'brand_name',
    hpra: 'trade_name',
    hma: 'trade_name',
    jp: 'trade_name'
  },
  applicant: {
    US: 'sponsor_name',
    EU: 'sponsor_name',
    ca: 'sponsor_name',
    ct: 'name',
    'US-Devices': 'applicant',
    hpra: 'sponsor_name',
    hma: 'sponsor_name',
    jp: 'sponsor_name'
  }
};

export const NOTIFICATION_TYPE_COLOR_SCHEME: { [key: string]: any } = {
  application: 'applicationNotification',
  quick_search: 'quickSearchNotification',
  advanced_search: 'advancedSearchNotification',
  advanced_search_filters: 'advancedSearchNotification'
};
