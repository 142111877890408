import { MenuItem, Popover } from '@mui/material';

const CustomPopover = ({
  isOpen,
  anchorRef,
  handleMenuClose,
  handleMenuItemClick,
  menuItems
}: any) => {
  return (
    <Popover
      open={isOpen}
      anchorEl={anchorRef.current}
      onClose={handleMenuClose}
      disableEnforceFocus
      disableScrollLock
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      PaperProps={{
        style: {
          fontSize: 18,
          maxHeight: 200,
          width: '300px',
          borderRadius: 8,
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
        }
      }}>
      {menuItems.map(({ value, label }: any) => (
        <MenuItem
          key={value}
          onClick={() => handleMenuItemClick(value)}
          sx={{
            fontFamily: 'Nunito Sans',
            fontWeight: '400',
            fontSize: 18,
            color: '#202020'
          }}>
          {label}
        </MenuItem>
      ))}
    </Popover>
  );
};
export default CustomPopover;
