import { useCallback, useContext } from 'react';

import getEUCTRPediatricsData from '../../../api/pages/EUCTRPages';
// store
import EUCTRPediatricsStore from '../../../store/EUCTRPediatrics';
import actions from '../../../store/EUCTRPediatrics/actions';

import { mergeOldFilterValues } from '../../../utils/filters';
import getFilterOptions from '../utils/common';

const useEUCTRPediatricsData = () => {
  const { euctrPediatricsState, euctrPediatricsDispatch } = useContext(EUCTRPediatricsStore);

  const getEUCTRPedData = useCallback(
    async (
      appliedFilters: any,
      limit: number = 20,
      pageSize: number = 0,
      sort: Array<any> = []
    ) => {
      euctrPediatricsDispatch({
        type: actions.SET_LOADING,
        value: true
      });
      const payload: any = {};

      euctrPediatricsDispatch({
        type: actions.SET_IS_FILTERS_APPLIED,
        value: Object.keys(appliedFilters).length !== 0
      });

      payload.filters = appliedFilters;
      if (sort) {
        payload.sort = sort;
      }
      try {
        const res = await getEUCTRPediatricsData(payload, limit, pageSize);
        const errors = [] as any;
        if (res.data.status === 200) {
          euctrPediatricsDispatch({
            type: actions.SET_EUCTR_PEDIATRICS_STATS,
            value: res.data.body.stats
          });
          euctrPediatricsDispatch({
            type: actions.SET_EUCTR_PEDIATRICS_TABLE,
            value: res.data.body.paediatric_trials_table
          });

          euctrPediatricsDispatch({
            type: actions.SET_TOTAL_COUNT,
            value: res.data.body.paediatric_trials_count
          });

          let mergedFilters = [];
          const newFilters = getFilterOptions(res.data.body.filters);
          if (appliedFilters.length) {
            mergedFilters = mergeOldFilterValues(newFilters, appliedFilters);
          } else {
            mergedFilters = newFilters;
          }
          //   set filters only if there are records as empty options will be displayed for all filter categories
          if (res.data.body.paediatric_trials_table?.length) {
            euctrPediatricsDispatch({
              type: actions.SET_FILTERS,
              value: mergedFilters
            });
          }
          euctrPediatricsDispatch({
            type: actions.SET_AVAILABLE_FILTERS,
            value: appliedFilters
          });
        } else {
          errors.push('No data was found');

          euctrPediatricsDispatch({
            type: actions.SET_EUCTR_PEDIATRICS_ERRORS,
            value: errors
          });
        }
        euctrPediatricsDispatch({
          type: actions.SET_LOADING,
          value: false
        });
      } catch (err) {
        euctrPediatricsDispatch({
          type: actions.SET_LOADING,
          value: false
        });
      }
    },
    []
  );

  return {
    isLoading: euctrPediatricsState.loading,
    isFiltersApplied: euctrPediatricsState.isFiltersApplied,
    euctrPediatricsFilters: euctrPediatricsState.filters,
    sortFields: euctrPediatricsState.sortFields,
    getEUCTRPedData
  };
};

export default useEUCTRPediatricsData;
