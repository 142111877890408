export const styles= {
    list: {
      padding: 0,
    },
    listItemButton: {
      '&.MuiListItemButton-root': {
        padding: '0 16px',
      },
    },
    listItemText: {
      fontWeight: 400,
      fontSize: 14,
      color: 'gray.600',
    },
    collapseBox: {
      display: 'flex',
      flexDirection: 'row',
    },
    verticalDivider: {
      height: 'auto',
      backgroundColor: 'gray.300',
      marginLeft: 3,
    },
    meetingBox: {
      display: 'flex',
      alignItems: 'start',
      flexDirection: 'column',
      padding: '6px 32px 6px 17.5px',
    },
    meetingBoxItem: {
      width: '100%',
    },
    meetingListItemButton: {
      p: 0,
      '&.MuiListItemButton-root': {
        padding: 0,
      },
    },
    meetingText: {
      fontWeight: 400,
      fontSize: 14,
      color: 'gray.900',
      padding: 0,
    },
    dropDownIcon:{
      color: 'gray.700',
      fontSize:16
    }
  };