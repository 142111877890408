import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import capitalize from 'lodash/capitalize';

// mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';

// styles
import styles from '../../styles/Roster.styles';

// api
import { getRosterPerson } from '../../../../api/pages/Adcomm';

// icons
import Person from '../../../../Images/Adcomm/gender-neutral-person.svg';

// component
import CustomPieChart from '../../../../components/CustomComponents/Graphs/CustomPieChart';
import { PIECHARTCOLOR } from '../../../../components/CustomComponents/Graphs/constant';

// hooks
import useQuery from '../../../../helpers/customHooks/queryParameter';
import RosterSkeleton from '../Skeleton/RosterSkeleton';
import removeZeroValueFromPieChartData from '../../../../utils/removeZeroValueFromPieChartData';

const Roster: React.FC<any> = ({
  isLoading,
  rosterData,
  selectedMeeting,
  errorMessage,
  updatedSearchParams
}) => {
  const query = useQuery();
  const chipRef = useRef<HTMLDivElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const [memberData, setMemberData] = useState<{
    [key: string]: any;
  }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedMemberId, SetSelectedMemberId] = useState<{ [key: string]: string | any }>({});

  const handleRosterMember = useCallback(
    async (member: any) => {
      setLoading(true);
      try {
        const res = await getRosterPerson(selectedMeeting.meeting_id, member.member_id);
        if (res?.status === 200) {
          const data = removeZeroValueFromPieChartData(res.data.body.result, ['acm_graph']);
          setMemberData(data);
          SetSelectedMemberId(member);
          updatedSearchParams.set('memberId', member.member_id);
          window.history.pushState(
            {},
            '',
            `${location.pathname}?${updatedSearchParams.toString()}`
          );
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
      setLoading(false);
    },
    [selectedMeeting, updatedSearchParams]
  );

  useEffect(() => {
    if (rosterData && rosterData.table.length !== 0 && !query.get('memberId')) {
      handleRosterMember(rosterData.table[0]);
    }
    if (query.get('memberId') && rosterData && rosterData.table.length !== 0) {
      let found: boolean = false;
      rosterData.table.forEach((ele: any) => {
        if (ele.member_id === updatedSearchParams.get('memberId')) {
          handleRosterMember(ele || '');
          found = true;
        }
      });
      if (!found) {
        handleRosterMember(rosterData.table[0]);
      }
    }
  }, [rosterData, updatedSearchParams]);

  useEffect(() => {
    if (boxRef.current && chipRef.current) {
      chipRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
        // @ts-ignore
        container: boxRef.current
      });
    }
  }, [selectedMemberId]);

  const handleNavigation = useCallback(() => {
    const updatedSearch = new URLSearchParams(updatedSearchParams);
    updatedSearch.set('memberName', selectedMemberId.name);
    updatedSearch.set('filteredMemberId', selectedMemberId.member_id);
    updatedSearch.set('member', 'true');
    updatedSearch.set('page', '1');
    window.open(`${location.pathname}?${updatedSearch.toString()}`);
  }, [updatedSearchParams, selectedMemberId]);

  if (isLoading) {
    return <RosterSkeleton />;
  }
  if (rosterData.table.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '25%' }}>
        <Typography variant='h6'>{errorMessage}</Typography>
      </Box>
    );
  }
  return (
    <Box
      flexDirection='column'
      justifyContent='space-evenly'
      p={3}
      sx={{ width: '100%', maxHeight: '60vh', marginTop: 1.5 }}>
      <Stack spacing={2} display='flex' flexDirection='row' maxHeight='60vh'>
        <Box
          sx={{
            overflow: 'hidden',
            width: '50%',
            margin: 1
          }}>
          <Box>
            <Grid container lg={12} sx={styles.heading}>
              <Grid item lg={4}>
                <Typography sx={styles.headingText}>Name</Typography>
              </Grid>
              <Grid item lg={5}>
                <Typography sx={styles.headingText}>Attendance</Typography>
              </Grid>
              <Grid item lg={3}>
                <Typography sx={styles.headingText}>Voting/Non-Voting</Typography>
              </Grid>
            </Grid>
            <Grid ref={boxRef} sx={styles.table}>
              {rosterData &&
                rosterData.table.map((item: any) => (
                  <Grid
                    container
                    lg={12}
                    ref={selectedMemberId.member_id === item.member_id ? chipRef : null}
                    sx={
                      selectedMemberId.member_id === item.member_id && !loading
                        ? styles.selectedTableBody
                        : styles.tablebody
                    }
                    onClick={() => {
                      handleRosterMember(item);
                    }}
                    key={item.member_id}>
                    <Grid item lg={4}>
                      <Typography sx={styles.name}>{item.name}</Typography>
                    </Grid>
                    <Grid item lg={5}>
                      <Typography sx={styles.role}>{capitalize(item.role)}</Typography>
                    </Grid>
                    <Grid item lg={3}>
                      <Typography sx={styles.text}>{item.voting}</Typography>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Box>
        <Divider flexItem orientation='vertical' sx={{ margin: 2 }} />
        <Box
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
            width: '50%',
            margin: 1,
            maxHeight: '58vh',
            pl: 2
          }}>
          <Grid container xs={12} lg={12}>
            <Grid item xs={4} lg={4}>
              {!loading ? (
                <Box display='flex'>
                  <img alt='PersonLogo' style={styles.icon} src={Person} />
                </Box>
              ) : (
                <Box display='flex'>
                  <Skeleton height={150} width={150} variant='circular' />
                </Box>
              )}
            </Grid>

            {!loading ? (
              <Grid item container xs={8} lg={8} sx={{ alignItems: 'center' }}>
                <Grid item>
                  {' '}
                  <Typography sx={styles.rosterHeading}>{memberData && memberData.name}</Typography>
                </Grid>
                <Grid item>
                  {' '}
                  <Typography sx={styles.memberText}>{memberData && memberData.address}</Typography>
                </Grid>
                <Grid item>
                  <Typography sx={styles.rosterHeading}>
                    {' '}
                    Last AdComm Meeting &nbsp;
                    {memberData && memberData.last_adcomm_meeting_attended}{' '}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid item container xs={8} lg={8}>
                <Grid item>
                  {' '}
                  <Skeleton height={20} width={150} variant='rectangular' />
                </Grid>
                <Grid item>
                  {' '}
                  <Skeleton height={80} width={300} variant='rectangular' />
                </Grid>
                <Grid item>
                  <Skeleton height={20} width={150} variant='rectangular' />
                </Grid>
              </Grid>
            )}
          </Grid>
          {!loading ? (
            <Box display='flex' pl={2} alignItems='center'>
              <Button
                variant='text'
                fullWidth={false}
                sx={styles.button}
                onClick={handleNavigation}>
                <Typography align='right' variant='subtitle2' sx={styles.buttonText}>
                  {`Total ACM: ${memberData?.total_acm}`}
                </Typography>
              </Button>
              <Tooltip title={`Click to view Advisory Meetings attended by ${memberData.name}`}>
                <IconButton edge='end' sx={{ mt: '20px' }}>
                  <InfoOutlinedIcon
                    sx={{ color: 'primary.main', cursor: 'auto' }}
                    fontSize='small'
                  />
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            <Box display='flex' m={2}>
              <Skeleton height={30} width={120} variant='rectangular' />
            </Box>
          )}

          {!loading ? (
            <Box
              sx={{
                height: '25%',
                width: '100%',
                marginTop: '5%',
                maxHeight: 'inherit',
                display: 'flex',
                flexDirection: 'column'
              }}>
              <Typography variant='subtitle2' sx={styles.subHeading}>
                ACM Question Response Summary
              </Typography>
              {memberData && memberData.acm_graph ? (
                <CustomPieChart
                  data={memberData.acm_graph}
                  innerRadius={0.7}
                  colors={PIECHARTCOLOR}
                  fillText='#707070'
                  radialLabelsTextColor='#707070'
                  sliceLabelsTextColor={{ from: 'color' }}
                  margin={{}}
                />
              ) : null}
            </Box>
          ) : (
            <Box display='flex' alignSelf='center' pt={4} justifyContent='center'>
              <CircularProgress
                value={90}
                thickness={5}
                size='10rem'
                variant='indeterminate'
                sx={{
                  color: '#adadac',
                  opacity: 0.5
                }}
              />
            </Box>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default React.memo(Roster);
