import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button, { ButtonProps } from '@mui/material/Button';
import Papa from 'papaparse';
// muiv5
import {
  GridCsvExportOptions,
  GridCsvGetRowsToExportParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiContext
} from '@mui/x-data-grid-pro';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { cloneDeep, uniq } from 'lodash';
import { HIDDEN_COLUMNS } from '../constants';
// types
import { UrlParameters } from '../../../types/reactRouterDom';
import AuthContext from '../../../store/Auth/AuthProvider';
import config from '../../../config';
import { RESTRICT_DOWNLOADS_BY_ORGANIZATION } from '../../../const';
// styles and icons
import { ExportIcon } from '../../../assets/svgs/Core/ExportIcon';
import CTIndicator from '../../../assets/svgs/CardsPage/CTIndicator';
import { decodeBase64ToObject } from '../../../utils/encodeDecodeObject';

import { MAX_CSV_CELL_LENGTH } from '../../../pages/ResultsPage/utils/getFormatDataForCSV';
import styles from '../Datagrid.styles';
import getCtSource from '../../../pages/SearchResults/utils/getCtSource';
import globalStore from '../../../store/index';
import globalActions from '../../../store/actions';
import ResultsStore from '../../../store/SearchResults';
import ResultActions from '../../../store/SearchResults/actions';

const DatagridToolbar = ({
  fileName,
  useQuickSearch = false,
  showAlertOnExport = null,
  isServerSideExport = false,
  showExportOption = true
}: any) => {
  const { source, module, search, searchType, number, payload } = useParams<UrlParameters>();
  const [allowedToDownloadAllData, setAllowedToDownloadAllData] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const { resultsDispatch } = useContext(ResultsStore);
  const [anchorEl, setAnchorEl] = useState(null);

  const apiRef = useGridApiContext();
  const payloadObj: any = decodeBase64ToObject(payload || '');
  const { dispatch }: any = useContext(globalStore);
  const ctSource = getCtSource(payloadObj?.source);
  const shouldShowCtIndicator = payloadObj?.view_type === 'ct' && ctSource === 'usnlm';

  const getRowsToExport = (params: GridCsvGetRowsToExportParams) => {
    const rowIds = params.apiRef.current.getAllRowIds();
    const filteredData = apiRef.current?.state?.filter?.filteredRowsLookup; // get list of rowId after applying datagrid filters
    const filteredRowIds = rowIds.filter(item => filteredData[item]);
    if (!allowedToDownloadAllData) {
      filteredRowIds.length = 50;
    }
    return filteredRowIds;
  };

  const csvExportDefaultOption = {
    fileName:
      fileName ||
      `ria_${source}_${module}_${
        number ||
        (searchType === 'advance' ? 'advanced_search' : decodeURIComponent(search as string))
      }`,
    delimiter: ',',
    utf8WithBom: true,
    getRowsToExport
  };

  useEffect(() => {
    // Implementations same as PMR impact
    const isAllowedToDowloadAllData = config.allowedLicense.includes(
      currentUser['custom:license']?.toLowerCase()
    );
    setAllowedToDownloadAllData(isAllowedToDowloadAllData);
  }, [currentUser]);

  const getIsFilterApplied = () => {
    // Logic for checking if the column filter is applied or not from the DataGridToolbar
    // we have a default position of column (some columns are visible and some are hidden)
    // if we have change this default combination we can say we hav applied the filters.
    // ** In case we have hidden all the columns, isFilerApplied should be false
    const columnsAll = apiRef.current.state.columns.orderedFields;
    const hiddenColumnsAll = Object.keys(HIDDEN_COLUMNS).filter(key => !HIDDEN_COLUMNS[key]);
    const visibleColumns = columnsAll.filter(col => !hiddenColumnsAll.includes(col));
    const hiddenColumns = hiddenColumnsAll.filter(col => columnsAll.includes(col));
    // check if any visible column is skipped/ made hidden.
    let filterAppliedOnVisbleColumns = false;
    const visibleColumnsIndexes: any[] = [];
    visibleColumns.forEach(col => {
      const ind = apiRef.current.getColumnIndex(col);
      visibleColumnsIndexes.push(ind);
      if (ind === -1) {
        filterAppliedOnVisbleColumns = true;
      }
    });
    // check if any hidden column is made visible.
    let filterAppliedOnHiddenColumns = false;
    hiddenColumns.forEach(col => {
      const ind = apiRef.current.getColumnIndex(col);
      if (ind !== -1) {
        filterAppliedOnHiddenColumns = true;
      }
    });
    // first check if we have hidden all the columns, in that isFilerApplied should be false
    if (filterAppliedOnHiddenColumns === false) {
      const allVisibleColumnsAreHidden = visibleColumnsIndexes.every(index => index === -1);
      if (allVisibleColumnsAreHidden) {
        return false;
      }
    }
    const isFilterApplied = filterAppliedOnHiddenColumns || filterAppliedOnVisbleColumns;
    return isFilterApplied;
  };

  const buttonBaseProps: ButtonProps = {
    startIcon: <ExportIcon />
  };

  const handleDownloadClose = () => {
    setAnchorEl(null);
  };

  const handleExport = (options: GridCsvExportOptions) => {
    try {
      if (isServerSideExport) {
        const selectedColumns = options?.allColumns
          ? []
          : apiRef.current?.getVisibleColumns().map((col: any) => col.field);
        dispatch({
          type: globalActions.SET_EXPORT_TYPE,
          value: { format: 'csv', selectedColumns, isAllColumnsSelected: options?.allColumns }
        });
      } else {
        if (payloadObj?.view_type === 'ct') {
          resultsDispatch({
            type: ResultActions.SET_CT_EXPORT,
            value: {
              type: 'csv',
              isOn: true,
              source: ctSource,
              columns: options?.allColumns ? [] : apiRef?.current?.getVisibleColumns()
            }
          });

          return;
        }

        /*
      Get the CSV string and check if any of the column has reached the limit that is supported by microsoft excel.
      We are doing this here because, selected column and all column, and functions which datagrid uses for
      exporting the content is complex. So it is better to get the csv using the options and then check for the limit.

      */
        const csvString: string = apiRef.current.getDataAsCsv(options);
        const results = Papa.parse(csvString as any);
        const columns: Array<string> = results.data[0] as Array<string>;
        const rowData = results.data.splice(1, results.data.length);

        const maxCharIndices: Array<any> = [];

        rowData.forEach((row: any) => {
          row.forEach((cell: string, columnIndex: number) => {
            if (cell.length >= MAX_CSV_CELL_LENGTH - 4) {
              maxCharIndices.push(columnIndex);
            }
          });
        });
        if (maxCharIndices.length > 0) {
          showAlertOnExport?.({
            erroredColumns: uniq(maxCharIndices).map((index: any) => columns[index])
          });
        }
        apiRef.current.exportDataAsCsv(options);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleCSVDownload = (mode: string) => {
    const csvExportOptions: GridCsvExportOptions =
      mode === 'selected'
        ? { ...csvExportDefaultOption, allColumns: false }
        : { ...csvExportDefaultOption, allColumns: true };
    handleExport(csvExportOptions);
    handleDownloadClose();
  };

  const handleExcelDownload = async (mode: string) => {
    if (payloadObj?.view_type === 'ct') {
      resultsDispatch({
        type: ResultActions.SET_CT_EXPORT,
        value: {
          type: 'xlsx',
          isOn: true,
          source: ctSource,
          columns: mode === 'selected' ? apiRef?.current?.getVisibleColumns() : []
        }
      });

      return;
    }
    if (isServerSideExport) {
      const selectedColumns =
        mode === 'selected' ? apiRef.current?.getVisibleColumns().map((col: any) => col.field) : [];
      dispatch({
        type: globalActions.SET_EXPORT_TYPE,
        value: { format: 'xlsx', selectedColumns, isAllColumnsSelected: mode === 'all' }
      });
    } else {
      const excelExportOptions: GridCsvExportOptions =
        mode === 'selected'
          ? { ...csvExportDefaultOption, allColumns: false }
          : { ...csvExportDefaultOption, allColumns: true };

      const csvString: string = apiRef.current.getDataAsCsv(excelExportOptions);
      const results = Papa.parse(csvString as any, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true
      });
      const data = cloneDeep(results.data);
      try {
        /*
          Get the CSV string and check if any of the column has reached the limit that is supported by microsoft excel.
          We are doing this here because, selected column and all column, and functions which datagrid uses for
          exporting the content is complex. So it is better to get the csv using the options and then check for the limit.
    
          */
        const rowData = results.data;

        const maxCharColumns: Array<any> = [];

        rowData.forEach((row: any) => {
          Object.entries(row).forEach(([columnName, columnValue]: any) => {
            if (String(columnValue).length >= MAX_CSV_CELL_LENGTH - 4) {
              maxCharColumns.push(columnName);
            }
          });
        });
        if (maxCharColumns.length > 0) {
          showAlertOnExport?.({
            erroredColumns: uniq(maxCharColumns)
          });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
      // Papaparser gives the extra data. This could be ignored.
      const filteredData = data.map((item: any) => {
        const rest = { ...item };
        // eslint-disable-next-line no-underscore-dangle
        delete rest.__parsed_extra;
        return rest;
      });
      const XLSX = await import('xlsx');
      const sheet = XLSX.utils.json_to_sheet(filteredData, {
        cellStyles: true
      });

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet1');

      // Get the CSV Headers. And get the data grid cell width config using get all columns.
      const headerRow: Array<string> = results.meta.fields || [];
      const columns = apiRef.current.getAllColumns();

      // XLSX sheet column width style could be updated using array of width for each column.
      const cols: Array<{ width: number }> = [];

      // iterate over the datagrid column settings.
      columns.forEach(({ width, headerName }: any) => {
        const columnIndex = headerRow.findIndex((value: string) => value === headerName);
        if (columnIndex !== -1) {
          /* 
          use the datagrid column width as width of the column in the sheet. Datagrid uses the pixels, and sheet uses the character length.
          So we are making an assumption that 8 pixels would fit 1 character.
        */
          cols[columnIndex] = { width: width / 8 };
        }
      });

      // Set column widths in the worksheet
      sheet['!cols'] = cols;

      XLSX.writeFile(workbook, `${excelExportOptions.fileName}.xlsx`);
    }
  };

  const handleDownloadOpen = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const filterApplied = getIsFilterApplied();

  return (
    <GridToolbarContainer
      sx={{
        justifyContent: shouldShowCtIndicator ? 'space-between' : 'flex-end',
        bgcolor: 'none',
        '& button': {
          textTransform: 'capitalize'
        }
      }}>
      {shouldShowCtIndicator && <CTIndicator />}
      <div>
        {useQuickSearch && <GridToolbarQuickFilter prop />}

        <GridToolbarFilterButton sx={styles.toolbarBtn} />
        {
          // @ts-ignore
          <GridToolbarColumnsButton sx={styles.toolbarBtn} />
        }
        {
          // @ts-ignore
          <GridToolbarDensitySelector sx={styles.toolbarBtn} />
        }
        {!RESTRICT_DOWNLOADS_BY_ORGANIZATION.includes(currentUser['custom:organization']) &&
          showExportOption && (
            <>
              <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonBaseProps}
                sx={styles.toolbarBtn}
                onClick={(event: React.MouseEvent<HTMLElement>) => handleDownloadOpen(event)}>
                Export
              </Button>
              <Menu
                sx={{
                  '& .MuiPaper-root': {
                    borderRadius: '10px'
                  }
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleDownloadClose}>
                <MenuItem onClick={() => handleCSVDownload('all')}>
                  Export as CSV (All Columns)
                </MenuItem>
                {filterApplied && (
                  <MenuItem onClick={() => handleCSVDownload('selected')}>
                    Export as CSV (Selected Columns)
                  </MenuItem>
                )}
                <MenuItem onClick={() => handleExcelDownload('all')}>
                  Export as Excel (All Columns)
                </MenuItem>
                {filterApplied && (
                  <MenuItem onClick={() => handleExcelDownload('selected')}>
                    Export as Excel (Selected Columns)
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
      </div>
    </GridToolbarContainer>
  );
};

export default DatagridToolbar;
