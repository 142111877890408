import React from 'react';

// mui
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import SummarySkeleton from './SummarySkeleton';

const MeetingListSkeleton = () => {
  return (
    <Box height='100%'>
      <Stack p={2} display='flex' flexDirection='row'>
        <Paper
          sx={{
            backgroundColor: 'white.main',
            height: '100%',
            minHeight: '65vh',
            maxHeight: '65vh',
            borderRadius: '20px',
            width: '20%',
            margin: 2
          }}>
          <Box
            paddingTop={2}
            paddingBottom={1}
            display='flex'
            justifyContent='space-between'
            flexDirection='column'
            sx={{ height: '65vh' }}
            overflow='auto'>
            <Box m={2}>
              <Skeleton height={50} width={150} />
            </Box>
            <Stack
              m={2}
              spacing={2}
              display='flex'
              flexDirection='column'
              sx={{ height: 'inherit' }}>
              <Skeleton variant='rectangular' height='15%' width='100%' />
              <Skeleton variant='rectangular' height='15%' width='100%' />
              <Skeleton variant='rectangular' height='15%' width='100%' />
              <Skeleton variant='rectangular' height='15%' width='100%' />
              <Skeleton variant='rectangular' height='15%' width='100%' />
              <Skeleton variant='rectangular' height='15%' width='100%' />
            </Stack>
          </Box>
        </Paper>
        <Paper
          sx={{
            backgroundColor: 'white.main',
            height: '100%',
            minHeight: '65vh',
            maxHeight: '65vh',
            borderRadius: '20px',
            width: '80%',
            margin: 2
          }}>
          <Box m={2}>
            <Skeleton height={50} width={150} />
          </Box>
          <SummarySkeleton />
        </Paper>
      </Stack>
    </Box>
  );
};

export default React.memo(MeetingListSkeleton);
