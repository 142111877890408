const styles = {
  yearLabel: {
    fontWeight: 700,
    fontSize: 12,
    color: 'gray.800',
    lineHeight: '150%',
    bgcolor: 'gray.100',
    px: 1.5,
    py: 0.25,
    '& .MuiChip-label': {
      pr: 0.5,
      pl: 0.5
    }
  }
};

export default styles;
