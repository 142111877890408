import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import isEqual from 'lodash/isEqual';
import { OptionsProps } from './types';
import { styles } from './styles';
import { FILTER_TYPE } from './constants';

const OptionFooter = ({
  selectedCategory,
  selectedCategoryOptions,
  setSelectedCategoryOptions,
  applyFilters,
  isLoading,
  setShowFilterSidebar,
  previousSelectedCategoryOptions,
  isInputError,
  handleApplyClose,
  setShouldRefreshDate
}: OptionsProps) => {
  const handleClearAll = () => {
    if (selectedCategory.filterType === FILTER_TYPE.MULTI_SEARCH) {
      const valuesToRemove = selectedCategory.options.map((option: any) => option.value);
      // removing keys present in valuesToRemove variable from selectedCategoryOptions
      const { ...result } = selectedCategoryOptions;
      valuesToRemove.forEach((value: any) => {
        delete result[value];
      });
      setSelectedCategoryOptions(result);
    } else {
      const selectedCategoryValueKey =
        selectedCategory?.elasticSearchType || selectedCategory?.value;
      const { [selectedCategoryValueKey]: _, ...others } = selectedCategoryOptions;
      setSelectedCategoryOptions(others);
      if ([FILTER_TYPE.DATE_RANGE, FILTER_TYPE.YEAR_RANGE].includes(selectedCategory.filterType)) {
        setShouldRefreshDate(true);
      }
    }
  };

  const handleApplyFilters = async () => {
    await applyFilters(selectedCategoryOptions);
    if (!isLoading) {
      handleApplyClose();
      setShowFilterSidebar(false);
    }
  };

  const isSelectedFiltersEqual = isEqual(selectedCategoryOptions, previousSelectedCategoryOptions);

  return (
    <>
      <Button
        disabled={Object.keys(selectedCategoryOptions)?.length === 0 || isInputError}
        size='small'
        sx={styles.clearAll}
        onClick={handleClearAll}>
        Clear
      </Button>
      <LoadingButton
        disabled={isSelectedFiltersEqual || isInputError}
        size='small'
        loading={!!isLoading}
        sx={{
          ...styles.applyButton,
          opacity: isSelectedFiltersEqual || isInputError ? 0.38 : 1,
          '&.Mui-disabled': {
            color: isLoading ? 'transparent' : 'white.main'
          }
        }}
        style={{
          backgroundColor: '#0D917D'
        }}
        onClick={handleApplyFilters}>
        Apply
      </LoadingButton>
    </>
  );
};

export default OptionFooter;
