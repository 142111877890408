const styles: { [key: string]: any } = {
  popoverDiv: {
    minHeight: '300px',
    maxHeight: '300px',
    width: '500px'
  },
  linkItem: {
    cursor: 'pointer',
    textAlign: 'center'
  },
  linkHeading: {
    fontSize: '18px',
    fontWeight: '600',
    cursor: 'pointer',
    display: 'block',
    textTransform: 'capitalize'
  },
  chipContainer: {
    overflow: 'auto',
    width: '100%',
    alignContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  link: {
    fontSize: 16,
    fontWeight: '600',
    cursor: 'pointer',
    display: 'block',
    textAlign: 'left',
    marginBottom: '5px',
    color: 'blue.main',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    },
    textTransform: 'capitalize'
  },
  textAlignment: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    bgcolor: 'white.main'
  },
  dialogContent: {
    height: '79vh',
    boxShadow: '0px 0px 10px blue.alice',
    border: 'unset',
    borderRadius: '10px'
  },
  chips: {
    fontSize: 12,
    fontFamily: 'Mulish',
    padding: '7px 18px',
    width: 'fit-content',
    fontWeight: 'bold',
    color: 'gray.main',
    marginRight: '10px',
    border: '1px solid #C9D2F6',
    borderRadius: 5,
    textAlign: 'center',
    backgroundColor: 'white.main'
  },
  button: {
    fontSize: 14,
    fontFamily: 'Mulish',
    padding: '7px 18px',
    width: 'fit-content',
    border: 1,
    borderColor: 'primary.400',
    borderRadius: 2,
    borderStyle: 'solid',
    textAlign: 'center',
    backgroundColor: 'white.main',
    textTransform: 'capitalize',
    color: 'black.darkVariant',
    fontWeight: '400',
    cursor: 'pointer',
    '&:hover': {
      borderColor: 'gray.lightVariant3',
      boxShadow: 'none',
      bgcolor: 'primary.main',
      color: 'white.main'
    }
  },
  closeBtn: {
    cursor: 'pointer',
    position: 'absolute',
    right: '5px',
    top: '5px'
  },
  trials: {
    boxShadow: 'none',
    position: 'inherit'
  },
  dialog: {
    '& .MuiDialog-paper': {
      height: '100%'
    }
  },
  heading: {
    color: 'primary.dark',
    padding: '13px 10px',
    borderRadius: '10px 10px 0px 0px',
    marginBottom: 'auto',
    fontSize: 18,
    fontWeight: '600'
  },
  title: {
    paddingTop: '5px',
    textDecoration: 'underline',
    fontSize: 14,
    fontFamily: 'Mulish',
    color: 'primary.700',
    textTransform: 'capitalize',
    fontWeight: '600',
    cursor: 'pointer'
  },
  drawerContainer: {
    '& >.MuiDrawer-paper': {
      height: '95vh',
      borderTopLeftRadius: '16px',
      borderTopRightRadius: '16px',
      position: 'absolute',
      backgroundColor: 'gray.100',
      overflow: 'hidden'
    }
  },
  snapshotTitle: {
    flexGrow: 1,
    textAlign: 'center',
    alignSelf: 'center',
    fontWeight: '700',
    fontSize: '20px',
    color: 'primary.darkVariant1'
  },
  actionsWrapper: {
    position: 'absolute',
    display: 'flex',
    left: '92%'
  },
  dividerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  divider: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'gray.400',
    borderRadius: '2px',
    mb: '16px',
    width: '64px'
  },
  snapshotBtn: {
    cursor: 'pointer',
    gap: '2px',
    py: '3px',
    px: '4px',
    borderRadius: '4px',
    bgcolor: 'gray.100',
    '&:hover': {
      bgcolor: 'gray.300'
    }
  },
  snapshotBtnText: {
    color: 'secondary.500',
    fontWeight: '600',
    cursor: 'pointer',
    lineHeight: '12px',
    fontStyle: 'normal'
  },
  cardInfoComponent: {
    width: 360
  }
};

export default styles;
