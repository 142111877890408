import React, { useContext } from 'react';
import { Box, Snackbar } from '@mui/material';
import Alert from '@mui/lab/Alert';
import Store from '../../../store';

const SourceMaintenance = () => {
  const { state } = useContext<any>(Store);

  return (
    <Box>
      {state.sourceMaintenance && state.sourceMaintenance.status && (
        <Snackbar
          open={state.sourceMaintenance && state.sourceMaintenance.status}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}>
          <Alert severity='error'>
            {state.sourceMaintenance && state.sourceMaintenance.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default SourceMaintenance;
