import { memo, useContext, useEffect } from 'react';

// mui v5
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import themev5 from '../../themev5';

// hoc
// import PMRSurveillanceTabs from './hoc/Tabs';
import EUCTRPediatricsTabs from './hoc/tab';

// store
import Store from '../../store';
import Actions from '../../store/actions';

// Provider
import { EUCTRPediatricStatStateProvider } from '../../store/EUCTRPediatrics';

const EUCTRPediatrics = () => {
  const { dispatch } = useContext(Store) as any;

  useEffect(() => {
    dispatch({ type: Actions.SET_MODULE, value: 'euctrPediatrics' });
  }, []);

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <EUCTRPediatricStatStateProvider>
        <EUCTRPediatricsTabs />
      </EUCTRPediatricStatStateProvider>
    </ThemeProvider>
  );
};

export default memo(EUCTRPediatrics);
