import themev5 from '../../../themev5';

const styles = {
  value: {
    fontSize: '14px',
    textAlign: 'left',
    fontWeight: 'bold'
  },
  resource: {
    fontSize: '14px',
    textAlign: 'left',
    fontWeight: 'bold',
    textDecoration: 'underline'
  },
  drugName: {
    textTransform: 'capitalize',
    fontSize: '14px',
    textAlign: 'left',
    fontWeight: 'bold'
  },
  key: {
    fontSize: '14px',
    textAlign: 'left'
  },
  objective: {
    maxHeight: 'inherit',
    overflowY: 'auto',
    cursor: 'pointer'
  },
  textGreen: {
    fontSize: '14px',
    textAlign: 'left',
    fontWeight: 'bold',
    color: 'primary.main'
  },
  infoRoot: {
    boxShadow: '0px 1px 10px #00000014',
    borderRadius: 1.5,
    padding: 1.5,
    height: '50%'
  },
  questionSection: {
    marginTop: 2,
    padding: 1.5
  },
  errorMessage: {
    marginTop: '15%',
    postion: 'fixed',
    display: 'flex',
    justifyContent: 'center'
  },
  heading: {
    fontSize: '14px',
    fontWeight: 'bold'
  },
  textHeading: {
    fontSize: '14px',
    textAlign: 'left',
    color: 'primary.main',
    display: 'flex'
  },
  textHeadingBold: {
    fontSize: '14px',
    textAlign: 'left',
    color: 'primary.main',
    fontWeight: 'bold'
  },
  row: {
    padding: 1,
    marginBottom: 2,
    overflow: 'auto'
  },
  text: {
    fontSize: '14px',
    textAlign: 'left'
  },
  questiontext: {
    fontSize: '14px',
    textAlign: 'left',
    fontWeight: 'bold',
    color: 'primary.main',
    paddingRight: 1
  },
  icons: {
    width: '25px',
    height: '25px'
  },
  stats: {
    borderRadius: 1.5,
    marginRight: 2,
    width: '35%',
    height: '300px'
  },
  card: {
    width: '100%',
    height: '300px',
    marginRight: 2,
    borderRadius: 1.5,
    [themev5.breakpoints.up('xl')]: {
      width: '100%'
    }
  },
  SummaryBox: {
    marginTop: 1,
    width: '100%',
    height: '100px',
    borderRadius: 1,
    [themev5.breakpoints.up('xl')]: {
      width: '100%'
    }
  },
  selectedTab: {
    width: '50%',
    borderRadius: 4,
    backgroundColor: '#33B187',
    textTransform: 'capitalize',
    '&.Mui-selected': {
      backgroundColor: 'primary.main',
      color: 'white.main'
    }
  },
  unselectedTab: {
    width: '50%',
    borderRadius: 4,
    textTransform: 'capitalize',
    backgroundColor: '#F7F7F7'
  }
};

export default styles;
