import { createSvgIcon } from '@mui/material';

const Document3DIcon = createSvgIcon(
  <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.33301 20.9307H30.9997V23.5973H8.33301V20.9307ZM8.33301 26.264H30.9997V28.9307H8.33301V26.264ZM8.33301 31.5973H30.9997V34.264H8.33301V31.5973ZM8.33301 36.9307H20.9997V39.5973H8.33301V36.9307Z'
      fill='white'
    />
    <path
      d='M33.5103 31.376L40.6144 27.9053C41.3681 27.5123 42.2443 27.4248 43.0609 27.6608C43.8775 27.8969 44.5719 28.4383 44.9998 29.1729L30.5921 38.7619C28.6554 39.6861 26.4727 39.9624 24.3668 39.5499L14.3085 36.8681C13.3831 36.6214 12.3981 36.7421 11.5596 37.2049L9.47949 38.3542L9.62611 28.1295L9.70936 28.1304C11.1649 27.0677 12.8851 26.4265 14.6812 26.2773C16.4772 26.1281 18.2796 26.4767 19.8906 27.2847L20.4941 27.5874C21.4383 28.0607 22.4773 28.3145 23.5334 28.33L29.4267 28.4152C30.3294 28.4279 31.2062 28.7192 31.9371 29.2492C32.6681 29.7791 33.2175 30.5219 33.5103 31.376Z'
      fill='#F7CAA5'
    />
    <path
      d='M9.47973 38.3541L9.4627 39.5053L3 39.4126L3.20432 25.231L9.66703 25.3246L9.62635 28.1293L9.47973 38.3541Z'
      fill='#4F4F4F'
    />
    <path
      d='M24.0691 8.26467C23.4186 8.25529 22.7727 8.37511 22.1688 8.61717C21.5649 8.85924 21.015 9.21874 20.551 9.67485C20.0871 10.131 19.7183 10.6746 19.4659 11.2743C19.2136 11.874 19.0828 12.5178 19.0811 13.1684V13.8788H20.5946V13.1684C20.5938 12.5422 20.7661 11.9281 21.0924 11.3937C21.4187 10.8594 21.8864 10.4256 22.4437 10.1403C23.001 9.855 23.6264 9.72927 24.2507 9.77701C24.8749 9.82475 25.4739 10.0441 25.9814 10.4108C26.4888 10.7775 26.8851 11.2774 27.1264 11.8551C27.3676 12.4329 27.4446 13.0661 27.3486 13.6848C27.2526 14.3035 26.9876 14.8837 26.5826 15.3612C26.1777 15.8388 25.6487 16.1951 25.0539 16.3909C24.5242 16.5661 24.0635 16.9046 23.7382 17.3579C23.4128 17.8113 23.2396 18.3561 23.2432 18.914V21.3517H24.7567V18.914C24.7538 18.6752 24.8266 18.4417 24.9647 18.2468C25.1028 18.052 25.299 17.9059 25.5253 17.8295C26.6287 17.4685 27.5682 16.7277 28.1767 15.7391C28.7852 14.7504 29.0232 13.578 28.8485 12.4303C28.6738 11.2826 28.0976 10.2341 27.2224 9.47136C26.3473 8.70859 25.2299 8.281 24.0691 8.26467Z'
      fill='#4F4F4F'
    />
    <path d='M24.7567 22.2979H23.2432V24.5681H24.7567V22.2979Z' fill='#4F4F4F' />
  </svg>,
  'Document3D'
);

export default Document3DIcon;
