import { createSvgIcon } from '@mui/material';

const KeyDownIcon = createSvgIcon(
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0 5.37155L6.34667 11.7095C6.78481 12.1456 7.37782 12.3905 7.996 12.3905C8.61418 12.3905 9.20719 12.1456 9.64533 11.7095L16 5.36421L14.586 3.95288L8.232 10.2975C8.16949 10.36 8.08472 10.3951 7.99633 10.3951C7.90795 10.3951 7.82318 10.36 7.76067 10.2975L1.414 3.96021L0 5.37155Z'
      fill='currentColor'
    />
  </svg>,
  'KeyDown'
);

export default KeyDownIcon;
