import React from 'react';

import CustomChip from '../../AdcommModule/CustomChip';
import { Tooltip } from '@mui/material';

import { scoreConfidenceMapper, scoreConfidenceMapperTooltip } from '../constants';
import { getDocumentFlag, getDocumentFlagTooltipTitle } from './documentResultsUtils';

export const getScoreConfidence = (data: any) => {
  if (data.type === 'answer') {
    return scoreConfidenceMapper.answer;
  }
  return scoreConfidenceMapper[data.score_confidence?.toLowerCase()];
};

export const getScoreConfidenceTooltip = (data: any) => {
  if (data.type === 'answer') {
    return scoreConfidenceMapperTooltip.answer;
  }
  return scoreConfidenceMapperTooltip[data.score_confidence?.toLowerCase()];
};

export const getFlagIconWithTooltip = (data: any) => {
  const FlagIcon = getDocumentFlag(data.data_source || data['data-source']);
  const flagTooltipTitle = getDocumentFlagTooltipTitle(data.data_source || data['data-source']);
  return FlagIcon ? (
    <Tooltip title={flagTooltipTitle}>
      <FlagIcon sx={{ fontSize: 18 }} />
    </Tooltip>
  ) : null;
};

export const getCderOrCdrhChip = (modifiedData: any) => {
  if (modifiedData?.category_bucket !== 'Adcomm') {
    return null;
  }

  let label = 'CDER'; // Default label

  if (modifiedData?.data_source?.includes('CDRH')) {
    label = 'CDRH';
  } else if (modifiedData?.data_source?.includes('CBER')) {
    label = 'CBER';
  }

  return <CustomChip label={label} />;
};
