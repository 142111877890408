import { createSvgIcon } from '@mui/material';

const BackArrowIcon = createSvgIcon(
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_4468_45739)'>
      <path
        d='M24.0003 13V11L2.55528 11.031L6.87728 6.70697L5.46328 5.29297L0.877276 9.87897C0.314863 10.4416 -0.00108337 11.2045 -0.00108337 12C-0.00108337 12.7955 0.314863 13.5584 0.877276 14.121L5.46328 18.707L6.87728 17.293L2.61528 13.031L24.0003 13Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_4468_45739'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'BackArrow'
);

export default BackArrowIcon;
