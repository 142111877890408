import titleCase from '../../../utils/titleCase';
import { CardData } from '../types';
import getApprovalTracks from './getApprovalTracks';

interface USDevicesAPIApps {
  application_number: string;
  application_type: string;
  approval_pathway: string;
  approval_year: string;
  combination_product: boolean;
  combination_product_text: string;
  device_class: string;
  document_count: number;
  document_total_page_count: number;
  expedited_review_flag: boolean;
  expedited_review_flag_text: string;
  generic_name: string;
  gmp_exempt_flag: boolean;
  gmp_exempt_flag_text: string;
  implant_flag: boolean;
  implant_flag_text: string;
  inProjects: Array<any>;
  indications_and_usage: string;
  is_favorite: boolean;
  is_subscribed: boolean;
  life_sustain_support_flag: boolean;
  life_sustain_support_flag_text: string;
  matched_synonyms: Array<string>;
  product_code: string;
  source_index: string;
  sponsor_name: string;
  submission_type: string;
  third_party_flag: boolean;
  third_party_flag_text: string;
  product_name: string;
}

export const DEVICES_IDENTIFIER_KEY = 'application_number';

const mapDevicesApps = (apiData: USDevicesAPIApps) => {
  const approvalTracks = getApprovalTracks(apiData, 'us-devices');
  const cardProps: CardData = {
    source: 'us-devices',
    title: titleCase(
      Array.isArray(apiData.product_name) ? apiData.product_name.join(' ') : apiData.product_name
    ),
    groupTitle: titleCase(
      Array.isArray(apiData.generic_name) ? apiData.generic_name.join(' ') : apiData.generic_name
    ),
    identifier: apiData.application_number,
    isFavorite: apiData.is_favorite,
    isSubscribed: apiData.is_subscribed,
    metadata: [
      {
        key: 'Sponsor Name',
        value: titleCase(
          (Array.isArray(apiData.sponsor_name)
            ? apiData.sponsor_name.join(';')
            : apiData.sponsor_name) || '---'
        )
      },
      {
        key: 'Product Code',
        value: titleCase(
          (Array.isArray(apiData.product_code)
            ? apiData.product_code.join(';')
            : apiData.product_code) || '---'
        )
      },
      {
        key: 'Device Class',
        value: titleCase(
          (Array.isArray(apiData.device_class)
            ? apiData.device_class.join(' ')
            : apiData.device_class) || '---'
        )
      },
      {
        key: 'Approval Pathway',
        value: titleCase(
          (Array.isArray(apiData.approval_pathway)
            ? apiData.approval_pathway.join(' ')
            : apiData.approval_pathway) || '---'
        )
      },
      { key: 'First Approval Year', value: apiData.approval_year }
    ],
    labels: [apiData.application_type?.toUpperCase()],
    approvalTracks,
    colorScheme: apiData?.approval_pathway,
    ...apiData
  };

  return cardProps;
};

export default mapDevicesApps;
