// mui
import { Box, Link, Stack, Typography } from '@mui/material';

// types
import { InformationModuleProps } from '../types';

// styles
import styles from '../styles/InformationModule.styles';

const InformationModule = ({
  title,
  subtitle,
  linkText,
  linkUrl,
  Icon,
  showUpdateCount,
  updateCount = 0
}: InformationModuleProps) => {
  return (
    <Link href={linkUrl} underline='none' color='inherit'>
      <Box sx={styles.parentBox}>
        <Stack direction='row' spacing={1} alignItems='center'>
          {Icon && <Icon sx={styles.documentIcon} />}
          <Stack spacing={0.3}>
            <Stack direction='row' alignItems='center' spacing={5}>
              <Typography variant='subtitle2' sx={styles.title}>
                {title}
              </Typography>
              {showUpdateCount && updateCount !== null && updateCount !== undefined && (
                <Box sx={styles.updateChip}>
                  <Typography variant='body2' sx={styles.updateText}>
                    {updateCount} new
                  </Typography>
                </Box>
              )}
            </Stack>
            <Typography variant='body2' sx={styles.secondaryText}>
              {subtitle}
            </Typography>
            <Typography variant='body2' sx={styles.seeAllDetailsText}>
              {linkText}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Link>
  );
};

InformationModule.defaultProps = {
  showUpdateCount: false,
  updateCount: 0
};

export default InformationModule;
