import React, { useState, useRef, useCallback } from 'react';

// mui
import {
  Box,
  Grid,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
  IconButton,
  Typography
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

// styles
import styles from '../styles/homePage.styles';
import CustomChip from '../CustomChip';
import MeetingCard from './MeetingCard';

const CommitteeInfo = ({ data }: any) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [committeeInfo, setCommitteeInfo] = useState<{
    [key: string]: string;
  }>(data.committee[0] || {});

  const handleInfoClick = useCallback((info: { [key: string]: string }) => {
    setCommitteeInfo(info);
    if (boxRef.current) {
      boxRef.current.scrollTop = 0;
    }
  }, []);

  return (
    <Grid
      container
      id='meeting-preview'
      gap={2}
      ml={2}
      sx={{ maxHeight: 'inherit', flexDirection: 'column', width: '100%' }}>
      <Grid item width='inherit'>
        <Tooltip title='Click here for more information'>
          <Box
            sx={styles.info}
            onClick={() => {
              setOpen(true);
            }}>
            <Typography sx={styles.heading}>Adcomm Basic Info</Typography>
            <IconButton aria-label='toggle password visibility' edge='end'>
              <InfoOutlinedIcon fontSize='small' />
            </IconButton>
          </Box>
        </Tooltip>
      </Grid>
      {data?.upcoming?.meeting_title && (
        <Grid item sx={styles.meetingContainer}>
          <MeetingCard title='Upcoming' meetingData={data.upcoming} />
        </Grid>
      )}

      {data?.recently_completed?.meeting_title && (
        <Grid item sx={styles.meetingContainer}>
          <MeetingCard title='Completed' meetingData={data.recently_completed} />
        </Grid>
      )}

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth='lg'
        fullWidth>
        <DialogTitle>
          <Grid container justifyContent='space-between' alignItems='flex-start'>
            <Grid item sx={{ fontSize: '20px', color: 'primary.dark' }}>
              Adcomm Basic Information
            </Grid>
            <IconButton
              aria-label='toggle password visibility'
              onClick={() => {
                setOpen(false);
              }}
              edge='end'>
              <CancelOutlinedIcon fontSize='small' />
            </IconButton>
          </Grid>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ maxHeight: '70vh' }}>
            <Grid item xs={4} sx={styles.InfoBox}>
              {data.committee &&
                data.committee.map((item: any) => (
                  <Grid container spacing={1} key={item.title}>
                    <Grid item>
                      <Box
                        sx={{
                          marginTop: '5px',
                          padding: '12px 10px'
                        }}>
                        <Grid container spacing={1} alignItems='center'>
                          <Grid item onClick={() => handleInfoClick(item)}>
                            <Typography
                              sx={
                                item.title === committeeInfo?.title
                                  ? styles.selectedtitle
                                  : styles.unselectedtitle
                              }>
                              {item.title}
                            </Typography>
                            <CustomChip label={item.center} />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
            {committeeInfo && (
              <Grid item xs={8} sx={styles.InfoBox} ref={boxRef}>
                {Object.keys(committeeInfo)
                  .filter(key => !['acronym'].includes(key))
                  .map((item: any) => (
                    <Box
                      key={item.title}
                      sx={{
                        width: '100%',
                        textAlign: 'left',
                        backgroundColor: 'primary.backgroundDark',
                        marginBottom: '10px',
                        borderRadius: '16px',
                        padding: 2
                      }}>
                      <Typography sx={styles.dialogText}>{`${item}: `}</Typography>
                      <Typography style={{ fontSize: '14px' }}>{committeeInfo[item]}</Typography>
                    </Box>
                  ))}
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default React.memo(CommitteeInfo);
