import { useContext } from 'react';
import useDocumentsViewFunctions from '../../../SearchResults/hooks/useDocumentsViewFunctions';
import { scoreConfidenceMapper } from '../../../SearchResults/constants';
import {
  getDocumentFlag,
  prepareDocumentCard
} from '../../../SearchResults/utils/documentResultsUtils';
import ResultsStore from '../../../../store/SearchResults';
import AriaCard from '../../../../components/ui/cards/AriaCard';
import { DocumentResultCardProps } from '../../interface';

const DocumentResultCard = ({
  data,
  documentSearchId,
  searchTerm,
  handleFavoriteHook,
  handleProjectHook
}: DocumentResultCardProps) => {
  const { handleResultClick, handleShareClick, handleAriaResponseClick } =
    useDocumentsViewFunctions();

  let ScoreConfidence = scoreConfidenceMapper[data.score_confidence?.toLowerCase()];
  if (data.type === 'answer') {
    ScoreConfidence = scoreConfidenceMapper.answer;
  }
  const FlagIcon = getDocumentFlag(data.data_source || data['data-source']);
  const { resultsState } = useContext(ResultsStore);

  return (
    <AriaCard
      key={data.result_id}
      handleShareClick={handleShareClick}
      searchId={documentSearchId}
      handleFavoriteClick={({ identifier }: { identifier: string }) => {
        handleFavoriteHook({ documentSearchId, identifier });
      }}
      handleProjectClick={({ identifier, project }: { identifier: string; project: any }) => {
        handleProjectHook({ documentSearchId, identifier, project });
      }}
      documentCardDetails={prepareDocumentCard(data)}
      isSelected={resultsState.selectedDocument?.resultId === data.result_id}
      handleResultClick={handleResultClick}
      flag={FlagIcon ? <FlagIcon sx={{ fontSize: 18 }} /> : null}
      scoreConfidence={<ScoreConfidence sx={{ fontSize: 14 }} />}
      handleAriaResponseClick={handleAriaResponseClick}
      isPdfViewOpen={resultsState.selectedDocument !== null}
      showAriaResponseBtn={false}
      originalQuestion={searchTerm ?? ''}
    />
  );
};

export default DocumentResultCard;
