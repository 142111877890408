import { createSvgIcon } from '@mui/material';

const CollaborateIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
    <g clipPath='url(#clip0_2371_52420)'>
      <path d='M13.333 1.33301H2.66634C1.93301 1.33301 1.33967 1.93301 1.33967 2.66634L1.33301 14.6663L3.99967 11.9997H13.333C14.0663 11.9997 14.6663 11.3997 14.6663 10.6663V2.66634C14.6663 1.93301 14.0663 1.33301 13.333 1.33301ZM4.66634 5.99967H11.333C11.6997 5.99967 11.9997 6.29967 11.9997 6.66634C11.9997 7.03301 11.6997 7.33301 11.333 7.33301H4.66634C4.29967 7.33301 3.99967 7.03301 3.99967 6.66634C3.99967 6.29967 4.29967 5.99967 4.66634 5.99967ZM8.66634 9.33301H4.66634C4.29967 9.33301 3.99967 9.03301 3.99967 8.66634C3.99967 8.29967 4.29967 7.99967 4.66634 7.99967H8.66634C9.03301 7.99967 9.33301 8.29967 9.33301 8.66634C9.33301 9.03301 9.03301 9.33301 8.66634 9.33301ZM11.333 5.33301H4.66634C4.29967 5.33301 3.99967 5.03301 3.99967 4.66634C3.99967 4.29967 4.29967 3.99967 4.66634 3.99967H11.333C11.6997 3.99967 11.9997 4.29967 11.9997 4.66634C11.9997 5.03301 11.6997 5.33301 11.333 5.33301Z' />
    </g>
    <defs>
      <clipPath id='clip0_2371_52420'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'Collaborate'
);

export default CollaborateIcon;
