/* eslint-disable no-restricted-globals */
import * as React from 'react';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { styled, ThemeProvider } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Box, Stack } from '@mui/material';
import palette from '../../themev5/palette';
import themev5 from '../../themev5';
import Store from '../../store';
import { downloadOnClose } from './utils/rightPartUtils';
import DownloadModal from './components/DownloadModal';
import Actions from '../../store/actions';
import TopNavigation from './components/TopNavigation';
import SearchResultHeader from '../../pages/SearchResults/components/SearchResultHeader';
import SearchBar from '../../pages/Home/components/SearchBar';
import { decodeBase64ToObject } from '../../utils/encodeDecodeObject';
import ChatRIA from '../ChatRIA/ChatRIA';
import { createSourceModuleDropdownMapping } from '../../pages/Home/utils';
import Logo from '../../assets/svgs/Logo';
import QuickAccessTutorial from '../QuickAccessTutorial/QuickAccessTutorial';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
}));

const Header = () => {
  const { dispatch } = useContext<any>(Store);

  const history = useHistory();

  const [isDownloadOpen, setIsDownloadOpen] = useState(false);

  const handleOnDownloadModalClose = useCallback(
    () => downloadOnClose(setIsDownloadOpen, dispatch, Actions),
    []
  );

  const handleLogoClick = () => {
    history.push('/');
    sessionStorage.removeItem('aria_cache');
  };

  const selectedSourcesFromParams = useMemo(() => {
    const splitObject = location.pathname.split('/');
    if (splitObject) {
      const payload = splitObject[splitObject.length - 1];
      const searchPayload: any = decodeBase64ToObject(payload);
      if (searchPayload && 'source' in searchPayload) {
        return createSourceModuleDropdownMapping(searchPayload.source);
      }
    }
    return undefined;
  }, [location.pathname]);

  const showSearchBar = useMemo(() => {
    const splitObject = location.pathname.split('/');
    if (splitObject) {
      const payload = splitObject[splitObject.length - 1];
      const searchPayload: any = decodeBase64ToObject(payload);
      if (!searchPayload) return true;
      if ('feature' in searchPayload) {
        return searchPayload.feature !== 'comparsion';
      }
      if ('us' in searchPayload.source) {
        return !searchPayload.source.us.includes('505b2');
      }
    }

    return true;
  }, [location.pathname]);

  return (
    <ThemeProvider theme={themev5}>
      <ChatRIA />
      <AppBar
        position='sticky'
        elevation={0}
        sx={{
          backgroundColor: palette.white,
          color: 'black',
          width: '100%',
          borderBottom: '1px solid #EEEEEE'
        }}>
        <Toolbar sx={{ width: 'calc(100%-24px)', px: '40px !important', background: '#fff' }}>
          <Stack width='100%'>
            <Stack flexDirection='row' width='100%' overflow='visible' alignItems='center'>
              <Box
                id='main-vivpro-logo'
                sx={{
                  cursor: 'pointer',
                  '& svg': { width: '92px !important', height: '36px !important' }
                }}
                onClick={handleLogoClick}>
                <Logo />
              </Box>
              {location.pathname.startsWith('/search') && showSearchBar && (
                <SearchBar type='nav' selectedSourcesValue={selectedSourcesFromParams} />
              )}
              <Box flexGrow={1} />
              <TopNavigation />
            </Stack>
            {location.pathname.startsWith('/search') && <SearchResultHeader />}
          </Stack>

          <QuickAccessTutorial />

          <DownloadModal open={isDownloadOpen} onClose={handleOnDownloadModalClose} />
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default Header;
