import { Menu, MenuItem } from '@mui/material';

import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';

import { caseConverter } from '../const';

const SectionSuggestions = ({
  item,
  handleNewSection,
  bubbleIndex,
  generatedSection,
  anchorEl,
  openSectionSelection,
  handleSectionClose
}: any) => {
  const [filteredSection, setFilteredSection] = React.useState<any[]>([]);
  if (
    item?.text?.startsWith('Sorry I am not able to answer this at the moment') ||
    item?.action === 'help'
  ) {
    return null;
  }
  useEffect(() => {
    const sections: any[] = [];
    item?.availableSections?.map((section: any) =>
      generatedSection.includes(section.section) ? null : sections.push(section)
    );
    setFilteredSection(sections);
  }, [item, generatedSection]);

  return (
    <Menu anchorEl={anchorEl} open={openSectionSelection} onClose={handleSectionClose}>
      <MenuItem disabled>
        <Typography sx={{ fontSize: '13px' }}>Switch section to regenerate result</Typography>
      </MenuItem>
      {filteredSection?.map((section: any) => (
        <MenuItem
          key={section.section}
          onClick={() => {
            handleNewSection(section.section, item.question, bubbleIndex);
            handleSectionClose();
          }}>
          <Typography sx={{ fontSize: '13px' }}>{caseConverter(section.section)}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};
export default React.memo(SectionSuggestions);
