import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const LineChart = ({ data, yAxis, title = {} }: any) => {
  return (
    <Box
      position='relative'
      width='100%'
      height='100%'
      sx={{ 'page-break-inside': 'avoid', display: 'inline-block' } as any}>
      {title.show && (
        <Box position='relative' width='100%' top='0px' textAlign='center'>
          <Typography fontSize={`${title.fontSize || 20}px`} textAlign='center'>
            {title.title}
          </Typography>
        </Box>
      )}

      <ResponsiveLine
        data={data}
        margin={{ top: 100, right: 50, bottom: 100, left: 50 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: true,
          reverse: false
        }}
        axisTop={null}
        axisRight={null}
        curve='monotoneX'
        axisBottom={{
          tickSize: 3,
          tickPadding: 10,
          tickRotation: 45
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: yAxis,
          legendOffset: -40,
          legendPosition: 'middle'
        }}
        theme={{
          axis: {
            ticks: {
              text: {
                fontSize: 13
              }
            }
          },
          legends: {
            text: {
              fontSize: 16
            }
          }
        }}
        enableArea
        areaOpacity={1}
        enableGridX={false}
        enableGridY={false}
        enablePoints={false}
        // colors={['#0E5A94', '#03A9F4', '#AFE9FF']}
        colors={['#48E0A6', '#6868FA', '#12815E']}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={1}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel='y'
        pointLabelYOffset={-12}
        useMesh
        legends={[
          {
            anchor: 'top',
            direction: 'row',
            justify: false,
            translateX: 40,
            translateY: -60,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 200,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    </Box>
  );
};

export default React.memo(LineChart);
