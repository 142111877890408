import React from 'react';

const PDFCellComponent: React.FC<any> = ({ value, onPDFButtonClick }) => {
  const handleButtonClick = () => {
    onPDFButtonClick(value);
  };
  return (
    <>
      {' '}
      {!value ? (
        <>-</>
      ) : (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div onClick={handleButtonClick} style={{ color: 'blue', cursor: 'pointer' }}>
          PDF
        </div>
      )}
    </>
  );
};

export default React.memo(PDFCellComponent);
