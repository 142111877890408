import React, { useEffect, useState } from 'react';

import { Typography, Paper, Popper, Box, Stack } from '@mui/material';

import CustomTimelinePropsInterface from '../../../interfaces/CustomTimelinePropsInterface';

import styles from './TimelineArray.styles';
import trimText from '../../../utils/trimText';

const TimelineArray: React.FC<CustomTimelinePropsInterface> = ({
  column,
  dataItem,
  source
}: CustomTimelinePropsInterface) => {
  const [concatValue, setConcatValue] = useState('');
  const [showPopper, setShowPopper] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [mouseOverContent, setMouseOverContent] = useState(false);

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    if (column?.data === 'submission_type') return;
    if (concatValue) {
      setShowPopper(true);
    }
  };

  const handleMouseLeave = () => {
    if (!mouseOverContent) {
      setAnchorEl(null);
      setShowPopper(false);
    }
  };

  const packageInsertPopup = (packageInsert: string) => {
    try {
      const [companyCode, yjCode, ...rest] = packageInsert.split('_');
      const attachmentNumber = rest.join('_');
      return companyCode && yjCode && attachmentNumber ? (
        <Stack>
          <Typography variant='body1'>
            <Box component='span' sx={{ fontWeight: '600' }}>
              Company Code:
            </Box>{' '}
            {companyCode}
          </Typography>
          <Typography variant='body1'>
            <Box component='span' sx={{ fontWeight: '600' }}>
              YJ Code:
            </Box>{' '}
            {yjCode}
          </Typography>
          <Typography variant='body1'>
            <Box component='span' sx={{ fontWeight: '600' }}>
              Attachment Number:
            </Box>{' '}
            {attachmentNumber}
          </Typography>
        </Stack>
      ) : (
        <Typography variant='body1' sx={{ fontWeight: '600' }}>
          {packageInsert}
        </Typography>
      );
    } catch (e) {
      return (
        <Typography variant='body1' sx={{ fontWeight: '600' }}>
          {packageInsert}
        </Typography>
      );
    }
  };

  const getDocumentTitlePopOver = (documentTitle: string) => {
    try {
      const [title, productName] = documentTitle.split(' | ');
      return title && productName ? (
        <Stack>
          <Typography variant='body1'>
            <Box component='span' sx={{ fontWeight: '600' }}>
              Title:
            </Box>{' '}
            {title}
          </Typography>
          <Typography variant='body1'>
            <Box component='span' sx={{ fontWeight: '600' }}>
              Product Number:
            </Box>{' '}
            {productName}
          </Typography>
        </Stack>
      ) : (
        <Typography variant='body1' sx={{ fontWeight: '600' }}>
          {documentTitle}
        </Typography>
      );
    } catch (e) {
      return (
        <Typography variant='body1' sx={{ fontWeight: '600' }}>
          {documentTitle}
        </Typography>
      );
    }
  };

  const getArrayData = (item: string, data: any, indexValue: number) => {
    if (item === 'package_insert_number') {
      return (
        <Typography style={{ fontSize: '14px' }} key={`${indexValue}-package`}>
          <div>
            {Array.isArray(dataItem[item])
              ? packageInsertPopup(dataItem[item][0])
              : packageInsertPopup(dataItem[item])}
          </div>
        </Typography>
      );
    }
    if (item === 'document_title') {
      return (
        <Typography style={{ fontSize: '14px' }} key={`${indexValue}-package`}>
          <div>
            {Array.isArray(dataItem[item])
              ? getDocumentTitlePopOver(dataItem[item][0])
              : getDocumentTitlePopOver(dataItem[item])}
          </div>
        </Typography>
      );
    }
    return (
      <Typography style={{ fontSize: '14px' }} key={`${indexValue}-other`}>
        {indexValue === 0 ? (
          <>
            <b>{Array.isArray(dataItem[item]) ? dataItem[item][0] : dataItem[item]}</b>
            <br />
            <br />
          </>
        ) : (
          <div>{Array.isArray(dataItem[item]) ? dataItem[item][0] : dataItem[item]}</div>
        )}
      </Typography>
    );
  };

  useEffect(() => {
    if (column !== undefined) {
      const data =
        column?.concatColumns?.map(val => {
          if (Array.isArray(dataItem[val])) {
            return dataItem[val][0];
          }
          return dataItem[val];
        }) || [];
      let concatString: string = '';
      if (data.length > 0) {
        concatString = data.filter(item => item).join(' : ');
      } else {
        concatString = data.filter(item => item).join(' ');
      }

      setConcatValue(concatString);
    }
  }, [dataItem, column]);

  return (
    <div style={{ height: '100%' }}>
      <Box sx={styles.textAlignment} onMouseOver={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Typography
          variant='body1'
          maxHeight={70}
          overflow='hidden'
          textOverflow='ellipsis'
          sx={{
            p: 2,
            color: 'gray.darkStorm',
            fontWeight: '600',
            textAlign: 'left'
          }}>
          {trimText(concatValue, 256) || '.....'}
        </Typography>
        <Popper
          open={showPopper}
          placement='bottom'
          anchorEl={anchorEl}
          onMouseOver={() => {
            setMouseOverContent(true);
          }}
          onMouseLeave={() => {
            setMouseOverContent(false);
            setAnchorEl(null);
            setShowPopper(false);
          }}>
          <Paper
            elevation={1}
            sx={{
              maxHeight: 300,
              overflowY: 'auto',
              borderRadius: '8px',
              p: 1,
              width: 400,
              ml: -10
            }}>
            <Box
              sx={{
                width: '100%',
                textAlign: 'left',
                backgroundColor: 'primary.backgroundDark',
                marginBottom: '10px',
                borderRadius: '16px',
                padding: 2,
                textTransform: 'capitalize'
              }}>
              {column?.concatColumns &&
                column?.concatColumns?.map((item, index) => getArrayData(item, dataItem, index))}
            </Box>
          </Paper>
        </Popper>
      </Box>
    </div>
  );
};

export default React.memo(TimelineArray);
