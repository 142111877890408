import { useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NumberRange from './NumberRange';
import { OptionsProps } from './types';
import { styles } from './styles';
import OptionFooter from './OptionFooter';

const NumberRangeOptions = ({
  selectedCategory,
  selectedCategoryOptions,
  setSelectedCategoryOptions,
  isLoading,
  applyFilters,
  handleClose,
  setShowFilterSidebar,
  handleApplyClose,
  minValue,
  maxValue
}: OptionsProps) => {
  const [isNumberRangeInputError, setIsNumberRangeInputError] = useState<boolean>(false);
  return (
    <Box width={300} height={176}>
      <Box height={115}>
        <Box sx={styles.header} onClick={handleClose}>
          <Button sx={styles.closeIconButton}>
            <CloseIcon sx={{ ...styles.icon, fontSize: 20 }} />
          </Button>
        </Box>
        <Box mx={3}>
          <Typography fontSize={14} color='green.light' sx={{ mb: 1.7 }}>
            {selectedCategory.label}
          </Typography>
          <NumberRange
            selectedCategory={selectedCategory}
            selectedCategoryOptions={selectedCategoryOptions}
            setSelectedCategoryOptions={setSelectedCategoryOptions}
            isInputError={isNumberRangeInputError}
            setIsInputError={setIsNumberRangeInputError}
            minValue={minValue}
            maxValue={maxValue}
          />
        </Box>
      </Box>
      <Divider />
      <Box mx={3} height={60} sx={styles.footer}>
        <OptionFooter
          selectedCategory={selectedCategory}
          selectedCategoryOptions={selectedCategoryOptions}
          setSelectedCategoryOptions={setSelectedCategoryOptions}
          applyFilters={applyFilters}
          isLoading={isLoading}
          handleApplyClose={handleApplyClose}
          setShowFilterSidebar={setShowFilterSidebar}
          isInputError={isNumberRangeInputError}
        />
      </Box>
    </Box>
  );
};

export default NumberRangeOptions;
