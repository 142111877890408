import { createSvgIcon } from '@mui/material';

const GridVariantIcon = createSvgIcon(
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_4853_27378)'>
      <path
        d='M6.41667 6.41667H0V1.75C0 1.28587 0.184374 0.840752 0.512563 0.512563C0.840752 0.184374 1.28587 0 1.75 0L6.41667 0V6.41667ZM1.16667 5.25H5.25V1.16667H1.75C1.59529 1.16667 1.44692 1.22812 1.33752 1.33752C1.22812 1.44692 1.16667 1.59529 1.16667 1.75V5.25Z'
        fill='currentColor'
      />
      <path
        d='M13.9997 6.41667H7.58301V0H12.2497C12.7138 0 13.1589 0.184374 13.4871 0.512563C13.8153 0.840752 13.9997 1.28587 13.9997 1.75V6.41667ZM8.74967 5.25H12.833V1.75C12.833 1.59529 12.7715 1.44692 12.6621 1.33752C12.5528 1.22812 12.4044 1.16667 12.2497 1.16667H8.74967V5.25Z'
        fill='currentColor'
      />
      <path
        d='M6.41667 14H1.75C1.28587 14 0.840752 13.8156 0.512563 13.4874C0.184374 13.1592 0 12.7141 0 12.25L0 7.58331H6.41667V14ZM1.16667 8.74998V12.25C1.16667 12.4047 1.22812 12.5531 1.33752 12.6625C1.44692 12.7718 1.59529 12.8333 1.75 12.8333H5.25V8.74998H1.16667Z'
        fill='currentColor'
      />
      <path
        d='M12.2497 14H7.58301V7.58331H13.9997V12.25C13.9997 12.7141 13.8153 13.1592 13.4871 13.4874C13.1589 13.8156 12.7138 14 12.2497 14ZM8.74967 12.8333H12.2497C12.4044 12.8333 12.5528 12.7718 12.6621 12.6625C12.7715 12.5531 12.833 12.4047 12.833 12.25V8.74998H8.74967V12.8333Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_4853_27378'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'GridVariant'
);

export default GridVariantIcon;
