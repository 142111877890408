import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import IconButton from '@mui/material/IconButton';
import DatagridTooltip from '../../../components/Datagrid/DatagridTooltip';
import regionMapping from './regionMapping';
import sourceMapping from './sourceMapping';
import regionSpaceFormatting from './regionSpaceFormatting';
import valueFormatting from './valueFormatting';
import hoverValueFormatting from './hoverValueFormatting';
import sortDateComparator from './sortDateComparator';

export const FAQ_LIST = [
  {
    title: 'Access and Support',
    content: [
      {
        id: 1,
        summary: 'How to Login into RIA?',
        description: [
          'Please use log-in credentials supplied when you purchased access license to RIA. Please enter login details (username and password). Note that login credentials are case-sensitive. Please contact <a href="mailto:support@vivpro.ai">support@vivpro.ai</a> if you have questions about your account.'
        ]
      },
      {
        id: 2,
        summary: 'How to do Reset Password for RIA?',
        description: ['You can reset the password by clicking <a href="reset">here</a>']
      },
      {
        id: 3,
        summary: 'How do I contact RIA support?',
        description: [
          'You can send an email to <a href="mailto:support@vivpro.ai"> support@vivpro.ai </a>'
        ]
      },
      {
        id: 4,
        summary: 'What is the recommended web browser for VivproAI RIA application?',
        description: ['Google Chrome, Mozilla Firefox, Safari, Microsoft Edge']
      },
      {
        id: 5,
        summary: 'What browser(s) is not compatible with RIA application?',
        description: ['Internet Explorer']
      },
      {
        id: 6,
        summary: 'Download Searching ARIA with Booleans Reference Sheet',
        description: [
          '<a target=_blank href="https://vivpro-site.s3.amazonaws.com/Help_Page_FAQ/Searching_with_Booleans_Reference_Sheet.pdf"><img src="https://vivpro-site.s3.amazonaws.com/Help_Page_FAQ/pdf_icon.png" alt="PDF download" height="60" width="54"></img></a></br><a target=_blank href="https://vivpro-site.s3.amazonaws.com/Help_Page_FAQ/Searching_with_Booleans_Reference_Sheet.pdf">Click to download</a>'
        ]
      }
    ]
  },
  {
    title: 'Data Sources',
    content: [
      {
        id: 1,

        summary: 'What data sources are included in RIA ?',
        description: [
          'VivproAI has curated data for NDAs, BLAs, ANDAs, PMAs, PMNs, DENs, EMA Products, and Clinical trials from various data sources.'
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_FAQ/FAQ_1_1.png',
        list: [
          {
            label: 'Structured Data sources ',
            items: [
              'CDER, CBER, EUA approvals',
              'DailyMed',
              'OpenFDA',
              'Orangebook',
              'MeshTerms',
              'EMA Approvals',
              'Breakthrough Designations',
              'Accelerated Approvals',
              'Fast Track Approvals',
              'PMR/PMCs',
              ' Clinical Trials',
              'PMN Devices',
              ' PMA Devices',
              'Device Classes',
              'Pediatric Written Requests',
              'Pediatric Deferrals',
              'Pediatric Waivers',
              'Health Canada',
              'NICE',
              'Vivpro Proprietary Curated Datasets (15+ databases customized to deliver contextual intelligence)'
            ]
          },
          {
            label: 'Unstructured Data Sources ',
            items: [
              'CDER, CBER, EUA approval SBAs',
              'Advisory Committee and PDFs',
              'EMA EPAR PDFs',
              'Pediatric Written Request PDFs',
              'PMN Devices- addition to data not available in structured sources',
              'PMA Devices- addition to data not available in structured sources',
              'PMA PDFs',
              'PMN PDFs',
              'Guidance documents and PDFs',
              'Health Canada- Summary Basis of Decision, Clinical Information, Monographs',
              'NICE- Guidances, Guideline PDFs'
            ]
          }
        ]
      },
      {
        id: 2,
        datagrid: true,
        summary: 'How current is the data on RIA?'
      },
      {
        id: 3,
        summary: 'Does RIA include information on approved or unapproved drugs?',
        description: [
          'US FDA (Drugs and Biologics, Devices) and EU EMA( Drugs and Biologics) include approved, discontinued and withdrawn drugs. Clinical Trials module and Advisory Committee module includes approved and unapproved drugs. '
        ]
      },
      {
        id: 4,
        summary: 'Can I search guidances within RIA?',
        description: [
          'RIA allows easy access to US FDA guidances as well as ease of query in natural language search through ARIA.'
        ]
      },
      {
        id: 5,
        summary: 'Are the data within RIA current?',
        description: [
          'RIA data is expected to be current with the source. RIA refreshes data daily, weekly, monthly and annually as the data source is refreshed. The time lag between source refresh and availability on RIA is based on the need for curation, processing as well as any changes to the source files.'
        ]
      }
    ]
  },
  {
    title: 'Data Overview',
    content: [
      {
        id: 1,
        summary: 'What information is available from CA HPFB ?',
        download: [
          {
            source: 'ca',
            description: 'clinical_information',
            tooltip: 'Download Latest Clinical Information Documents details',
            heading:
              'CA HPFB provides Clinical Information Documents for ### applications. You can download details here.',
            statsId: 62
          }
        ]
      }
    ]
  },
  {
    title: 'Search Capabilities',
    content: [
      {
        id: 1,
        summary: 'What does RIA provide for search results?',
        description: [
          'RIA manages the flow of information and provides users with the most accurate information possible in a step-by-step manner. RIA helps manage the information overload and provides users the ability to customize results for their unique project needs.'
        ]
      },
      {
        id: 2,
        summary: 'Does RIA have machine learning capability?',
        description: [
          'Yes, RIA is equipped with Ask-RIA (ARIA), a machine learning system that can search documents and provide suggested answer as well as other possible answers with a link to document where the contents exist. The user can also filter the results.'
        ]
      },
      {
        id: 3,
        summary: 'Terms and Abbreviations',
        description: ['The following terms and abbreviations are used in this guide:'],
        table: {
          headers: ['Term', 'Definition'],
          rows: [
            {
              term: 'ANDA',
              definition: 'Abbreviated New Drug Application'
            },
            {
              term: 'BLA',
              definition: 'Biologics Licensing Application'
            },
            {
              term: 'DEN',
              definition: 'DeNovo Classification'
            },
            {
              term: 'Infographics',
              definition:
                'A visual image, such as a chart or diagram used to represent information or data'
            },
            {
              term: 'PMA',
              definition: 'Premarket Approval'
            },
            {
              term: 'PMN',
              definition: 'Premarket Notification'
            },
            {
              term: 'PMR/C ',
              definition: 'Post Marketing Requirements/Commitments'
            },
            {
              term: 'NDA',
              definition: 'New Drug Application'
            },
            {
              term: 'UI ',
              definition: 'User Interface'
            },
            {
              term: 'Approval Pathway ',
              definition:
                'Section under which a drug application was submitted.Eg 505(b), 505(b)(2), 505(j) etc'
            }
          ]
        }
      }
    ]
  },
  {
    title: 'ChatRIA',
    content: [
      {
        id: 1,
        summary: 'What is ChatRIA and how can I access it?',
        list: [
          {
            items: [
              "ChatRIA is a revolutionary conversational chatbot which is powered by OpenAI's GPT generative AI model. It can easily analyze, summarize, and interpret data into tables, bullets and even compute numbers and percentages from US and EU labels.",
              'ChatRIA is accessible for both US FDA and EU EMA labels on either the Application View, or the Regulatory360 view.'
            ]
          }
        ]
      },
      {
        id: 2,
        summary: 'What are the benefits of using ChatRIA?',
        list: [
          {
            items: [
              'Increase THINK time: ChatRIA analyzes and summarizes content based on the question you ask allowing you to increase your think-time and maximize your productivity.',
              '24/7 Availability: ChatRIA is available 24/7, which means that it can handle  queries or requests at any time of the day, including outside of regular business hours.',
              'Create reports in a matter of minutes: Improve response times and reduce frustration for your stakeholders with beautiful reports generated by ChatRIA.'
            ]
          }
        ]
      },
      {
        id: 3,
        summary: 'What are the limitations of ChatRIA?',
        list: [
          {
            items: [
              'At this time ChatRIA cannot reliably answer anything outside of the label section that you have selected.',
              'ChatRIA is designed for "chatting" so the length of answers will be limited. Prompts with a more limited scope tend to get better answers.'
            ]
          }
        ]
      },
      {
        id: 4,
        summary: 'How can I trust that ChatRIA is giving me correct answers?',
        list: [
          {
            items: [
              'ChatRIA is currently trained to answer only from authenticated FDA and EMA labels. These labels, as with all documents on RIA, go through a rigorous QC process before being released to the RIA platform.',
              'ChatRIA allows you the option to "Verify my answers" which directs you to the source document where you can verify the content provided by the response.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/chatRIA/chatRIA_verify_answers.jpg'
      },
      {
        id: 5,
        summary: 'What documents does ChatRIA have access to?',
        list: [
          {
            items: [
              'At this time ChatRIA can only answer questions from certain US and EU label sections. We are pushing our limits to solve cost and technical limitations which will allow ChatRIA to answer questions from all 1.6MM+ documents and 12MM+ pages present in the RIA corpus.'
            ]
          }
        ]
      },
      {
        id: 6,
        summary: "What's the difference between ChatRIA and ARIA?",
        list: [
          {
            items: [
              'ARIA is incredible at finding "a needle in a haystack". It searches for answers to your questions within a certain scope of documents and even highlights the content where the answer was found. While it takes you to the exact page of the source document, it cannot do further analysis, summaries, or comparisons.',
              'Unlike ARIA, ChatRIA can interpret and summarize data based on the context and questions given and creates concise and presentable responses that make it easy to understand and utilize in reports. It is currently limited to sections of the most current FDA or EMA label.'
            ]
          }
        ]
      },
      {
        id: 7,
        summary: 'Is ChatRIA included in my current subscription of RIA?',
        list: [
          {
            items: [
              'While ChatRIA may be a premium service in future, at this time ChatRIA is included in your RIA subscription.'
            ]
          }
        ]
      },
      {
        id: 8,
        summary: 'Can ChatRIA give me questions to ask?',
        list: [
          {
            items: [
              'If you are not sure where to start, or what questions to ask, click the lightbulb icon and ChatRIA will review the section text and generate relevant questions that you may be interested in asking.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/chatRIA/chatRIA_generate_questions.jpg'
      },
      {
        id: 9,
        summary: 'Are there other type of questions ChatRIA can answer?',
        description: ['Beyond the suggestions above, ChatRIA can be prompted to:'],
        list: [
          {
            items: [
              'Summarize',
              'Create executive summary in x bullet points',
              'Organize response into specific headings',
              'Create a table',
              'Calculate',
              'The limit to get best output out of ChatRIA is your own curiosity.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/chatRIA/chatRIA_generate_questions.jpg'
      },
      {
        id: 10,
        summary: 'Can I save my ChatRIA responses?',
        list: [
          {
            items: ['Yes! You can click on the "Add to report" button and download your answers.']
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/chatRIA/chatRIA_report.jpg'
      },
      {
        id: 10,
        summary: 'What is the difference between "RIA mode" and "RIA+ mode" on ChatRIA?',
        list: [
          {
            items: [
              'In "RIA" mode , ChatRIA will give responses with high accuracy based on the current context of the search. RIA mode is set by default unless the user changes the mode and is reset for every new search.',
              'In "RIA+" mode , ChatRIA will give responses based on the current context of the search and may also use external data which is not present in the given context. It is always recommended that the user verify the accuracy of the answer while in RIA+ mode.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/chatRIA/chatRIA_ria_mode.jpg'
      },
      {
        id: 11,
        summary: 'Why is my ChatRIA search not loading or taking a long time to process?',
        list: [
          {
            items: [
              'When you open a ChatRIA search, you will see a health indicator that represents the current status of the ChatRIA service. If the indicator shows a high volume of requests, please be patient or try your search at a later time.'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/chatRIA/chatRIA_status.png'
      }
    ]
  }
];

export const GUIDES = [
  {
    id: '1',
    summary:
      'RIA helped a sponsor to design a new Phase 3 Clinical study with "Clinical Relapse" as outcome measures for the treatment of Multiple Sclerosis',
    steps: [
      {
        id: 'Step 01',
        text: 'Search for "Multiple Sclerosis" in Quick Search with Category as "Indications".',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy1/Case_Study1_1.png'
      },
      {
        id: 'Step 02',
        text: 'Click on “Clinical Trials” in the summary tab on the Application view.',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy1/Case_Study1_2.png'
      },
      {
        id: 'Step 03',
        text: 'After reaching the Clinical Trial module, click on “Filters” to refine the results.',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy1/Case_Study1_3.png'
      },
      {
        id: 'Step 04',
        text: 'Identify relevant studies by filtering for "Phase: Phase 3" and "Status: Completed”. Finally, type the term "relapse" into the keyword search filter and hit "Apply".',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy1/Case_Study1_4.png'
      }
    ]
  },
  {
    id: '2',
    summary: 'Has FDA issued a complete response after advisory committee meeting?',
    steps: [
      {
        id: 'Step 01',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy2/Case_Study2_1.png',
        text: 'Ask-RIA “did FDA issue complete response letter after  advisory committee?“'
      },
      {
        id: 'Step 02',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy2/Case_Study2_2.png',
        text: 'From scope of about 5600+ applications and 30000+ supplements, RIA identified most probable applications where the complete response was issued after the advisory committee. '
      },
      {
        id: 'Step 03',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy2/Case_Study2_3.png',
        text: 'The Suggested answers provide 2 top examples where it was indeed the case'
      },
      {
        id: 'Step 04',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy2/Case_Study2_4.png',
        text: 'Conduct further analysis of applications to select an appropriate precedence'
      }
    ]
  },
  {
    id: '3',
    summary:
      'Has FDA required post-approval RCTs for orphan products in oncology approved based on single-arm studies?',
    steps: [
      {
        id: 'Step 01',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy3/Case_Study3_1.png',
        text: 'Ask-RIA (ARIA) “were there "orphan" designations in oncology with "single arm" studies?'
      },
      {
        id: 'Step 02',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy3/Case_Study3_2.png',
        text: 'ARIA narrowed down search space from 8000+ applications to specific Orphan destination applications in Oncology approved using Single-Arm'
      },
      {
        id: 'Step 03',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy3/Case_Study3_3.png',
        text: 'Download and classify applications based on interest (for example, NDA vs BLA) to further narrow down the search scope'
      },
      {
        id: 'Step 04',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy3/Case_Study3_4.png',
        text: 'Select to Search for cholangiocarcinoma indication '
      },
      {
        id: 'Step 05',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy3/Case_Study3_5.png',
        text: 'Then Ask-RIA “was there a post marketing requirement for cholangiocarcinoma?”.Select “Letters” tab.'
      }
    ]
  },
  {
    id: '4',
    summary: 'What Symbicort trials have pediatric patients enrolled down to Age 4, minimum?',
    steps: [
      {
        id: 'Step 01',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy4/Case_Study4_1.png',
        text: 'Do a Quick Search for Symbicort with SOURCE: US FDA - Drugs & Biologics and CATEGORY: Trade Name.'
      },
      {
        id: 'Step 02',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy4/Case_Study4_2.png',
        text: 'Click on Clinical Trials in the summary statistics.'
      },
      {
        id: 'Step 03',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy4/Case_Study4_3.png',
        text: 'Narrow the scope by filtering trials for Status: Completed and Age Category: infant/toddler.'
      },
      {
        id: 'Step 04',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy4/Case_Study4_4.png',
        text: 'Download trials as a CSV for further processing and to help identify randomized, interventional trials in children. Click back on the breadcrumbs to go back to the Symbicort results.'
      },
      {
        id: 'Step 05',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy4/Case_Study4_5.png',
        text: 'Click on ARIA from Symbicort Quick Search. Asking ARIA “Are there symbicort trials in children?” confirmed that FDA has reviewed trials in children up to 6 years.'
      },
      {
        id: 'Step 06',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy4/Case_Study4_6.png',
        text: "Asking ARIA “are there data for symbicort in children under 6 years?“ highlighted FDA's position on Symbicort leading to a waiver in children < 6 years of age."
      }
    ]
  },
  {
    id: '5',
    summary:
      'RIA helped a Biotech company to develop a plan for immunogenicity assessment for new biologics licensing application (BLA) for Multiple Sclerosis treatment',
    steps: [
      {
        id: 'Step 01',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy5/Case_Study5_1.png',
        text: 'Click on BLA(Biologics Licensing Application) icon on the side tray of the application'
      },
      {
        id: 'Step 02',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy5/Case_Study5_2.png',
        text: 'Now, switch the tab to Immunogenicty to identify important elements of the assessment requirements '
      },
      {
        id: 'Step 03',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy5/Case_Study5_3.png',
        text: 'Search for "Multiple Sclerosis" in search tab by selecting Categories as "Indications" and appropriate data source'
      },
      {
        id: 'Step 04',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy5/Case_Study5_4.png',
        text: 'Click on “Analytic View” to get the business ready'
      },
      {
        id: 'Step 05',
        image: 'https://vivpro-site.s3.amazonaws.com/Case_Studies/CaseStudy5/Case_Study5_5.png',
        text: 'Select “Immunogenicity” tab to get the business ready report'
      }
    ]
  }
];

// Add content to the TOP of the list
export const WHATS_NEW_CONTENT = [
  {
    id: 57,
    header: 'New Source Integration Alert! EMA Opinions on Orphan Medicinal Product Designation',
    subHeader: '',
    date: new Date('07/16/2024'),
    description: [
      {
        title: '',
        body: [
          'We are excited to inform you about our latest RIA enhancement: the integration of Opinions on Orphan Medicinal Product Designation. This new feature brings you invaluable insights from the European Medicines Agency’s Committee for Orphan Medicinal Products (COMP).'
        ]
      }
    ],
    slides: [
      {
        description: [
          {
            title: 'Natural Language Search of all opinions',
            body: [
              'Our advanced natural language search functionality allows you to search through all documents related to orphan medicinal products effortlessly. Find the information you need quickly and accurately.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/orphan_designation/Natural+Language+Search+of+all+opinions.gif'
      },
      {
        description: [
          {
            title: 'Comprehensive List of Orphan Medicine Designations',
            body: [
              'Access all consultations in one centralized location. This enables you to assess the impact of regulatory changes comprehensively and be more involved in the evolving regulatory landscape'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/orphan_designation/Comprehensive+List+of+Orphan+Medicine+Designations.gif'
      },
      {
        description: [
          {
            title: '360 View of Approved Products',
            body: [
              'Access Reg360 for approved products  in one centralized location. This enables you to assess the impact of designations and amendments on your business and stay informed about significant developments.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/orphan_designation/360+View+of+Approved+Products.gif'
      }
    ]
  },
  {
    id: 56,
    header:
      'New Source Integration Alert! Facilitate regulatory policy and strategy with Health Canada consultations!',
    subHeader: '',
    date: new Date('07/09/2024'),
    description: [
      {
        title: '',
        body: [
          'We are thrilled to announce a new addition to RIA: the integration of Health Canada consultations as a new source of regulatory information. This addition brings you even more comprehensive data to stay informed and strategically ahead in the regulatory landscape.'
        ]
      }
    ],
    slides: [
      {
        description: [
          {
            title: 'Natural Language Search of all consulations',
            body: []
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/canada_consultation/Natural Language Search of all consulations.gif'
      },
      {
        description: [
          {
            title: 'Centralized View of All Consultations',
            body: [
              'Access all consultations in one centralized location. This enables you to assess the impact of regulatory changes comprehensively and be more involved in the evolving regulatory landscape'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/canada_consultation/centralized.gif'
      }
    ]
  },
  {
    id: 55,
    header: 'Integration of FDA Companion Diagnostics with Drugs and Biologics',
    subHeader: '',
    date: new Date('06/03/2024'),
    description: [
      {
        title: '',
        body: [
          'We are thrilled to announce a major integration to RIA, designed to streamline your workflow and enhance your strategic capabilities. Our latest integration now connects FDA-approved companion diagnostics (CDx) with drugs and biologics, offering you a more cohesive and efficient experience. Here are the three key enhancements you can expect'
        ]
      }
    ],
    slides: [
      {
        description: [
          {
            title: 'Identify Products Approved with CDx',
            body: [
              'On the Applications page, easily identify which drugs are accompanied by companion diagnostics using our new CDx filter. This feature allows you to quickly pinpoint relevant drugs, saving you time and ensuring you have the information you need at your fingertips.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/companion_diagnostics/CDx1.gif'
      },
      {
        description: [
          {
            title: 'Access Detailed CDx Information on 360 Page',
            body: [
              'Detailed CDx information is now available on the 360 page for relevant drugs and biologics. This centralized view provides comprehensive insights, enabling you to make informed decisions with all critical data in one place.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/companion_diagnostics/CDx2.gif'
      },
      {
        description: [
          {
            title: 'Find Drugs and Biologics on the Device Page',
            body: [
              'RIA is seamless to locate drugs and biologics associated with specific CDx directly on the device page. This integration highlights the interconnected nature of our data, allowing you to focus on strategy as the essential information is meticulously prepared for you.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/companion_diagnostics/CDx3.gif'
      }
    ]
  },
  {
    id: 54,
    header: "Elevate Your Labeling Strategy with RIA's Latest Enhancement!",
    subHeader: '',
    date: new Date('05/28/2024'),
    description: [
      {
        title: '',
        body: [
          'RIA is revolutionizing your labeling strategy with a groundbreaking new feature that makes comparing and reviewing product labels easier and more efficient than ever before. Not only you can effortlessly compare two labels side by side (with color coding), you can now download product labels from multiple regions in HTML or WORD format that suits your workflow.'
        ]
      }
    ],
    slides: [
      {
        description: [
          {
            title: 'Multi-Product and Multi-Region Download for Label Sections',
            body: [
              'Compile and download product label sections (one or multiple) from different products across regions all at once.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/label-comparison/LC_Gif1.gif'
      },
      {
        description: [
          {
            title: 'Flexible Formats',
            body: [
              'Choose between side-by-side HTML for an interactive review or stacked Word documents for a comprehensive overview.'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/label-comparison/LC_2.png'
      },
      {
        description: [
          {
            title: 'Seamless Side-by-Side Comparison of Two Products',
            body: [
              'Unlock the power of Enhanced Comparison, the color-coded comparison than traditional multi-label viewing. Dive deeper than ever before by comparing entire labels or specific sections with precision. Experience the future of label analysis with our cutting-edge Generative AI Summary, delivering clear, concise comparisons of sections. Transform how you compare and analyze with Enhanced Comparison, where innovation meets efficiency.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/label-comparison/LC_Gif3.gif'
      }
    ]
  },
  {
    id: 53,
    header: 'European Union Clinical Trials Register in intuitive RIA workflow',
    subHeader: '',
    date: new Date('05/24/2024'),
    description: [
      {
        title: '',
        body: [
          "We are excited to announce the launch of a powerful new feature designed to streamline your clinical trial research and planning. Now, you can search the European Union Clinical Trials Register with the same intuitive flow you're accustomed to with US Clinical Trials"
        ]
      }
    ],
    slides: [
      {
        description: [
          {
            title: 'Find EU Clinical Trials of Interest',
            body: [
              'Gain insights into competitor activities and stay ahead of industry trends by accessing detailed information on ongoing and completed trials across the EU.'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/eu_ctr/CTR_Gif1.gif'
      },
      {
        description: [
          {
            title: 'Visualize Trial details in Graphical format',
            body: [
              'Easily interpret complex data with our graphical visualizations. Understand trial timelines, outcomes, and other critical details at a glance.'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/eu_ctr/CTR_Gif2.gif'
      },
      {
        description: [
          {
            title: 'Search EU Clinical Trial Documents',
            body: [
              'Leverage the comprehensive documents available to help design robust, compliant, and effective clinical trials. Analyze trial designs and methodologies to refine your own strategies.'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/eu_ctr/CTR_Gif3.gif'
      },
      {
        description: [
          {
            title: 'Find Country Specific Protocols',
            body: [
              'Keep yourself updated with the latest developments in clinical research within the EU. Stay on top of regulatory changes, new trial announcements, and amendments to existing trials.'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/eu_ctr/CTR_Gif4.gif'
      }
    ]
  },
  {
    id: 52,
    header: 'Drug Development Tools and Qualification at your fingertips!',
    subHeader: '',
    date: new Date('05/08/2024'),
    description: [
      {
        title: '',
        body: [
          "We're thrilled to introduce a brand-new integration of new source that allows for natural language search of Drug Development Tools (DDT) and Biomarker Qualifications within RIA. Gone are the days of sifting through complex jargon or struggling to find the information you need. With our intuitive search functionality, simply ask your DDT questions in plain language, and discover the answers you seek instantly."
        ]
      }
    ],
    slides: [
      {
        description: [
          {
            title: 'Natural Language Search of DDT details',
            body: []
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/drug_development_tools/DDT_1.gif',
        action: {
          text: 'Explore DDTs',
          url: 'https://ria.vivpro.ai/drug-development-tools'
        }
      },
      {
        description: [
          {
            title: 'Tabular summary and search of DDTs',
            body: []
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/drug_development_tools/DDT_2.gif',
        action: {
          text: 'Explore DDTs',
          url: 'https://ria.vivpro.ai/drug-development-tools'
        }
      }
    ]
  },
  {
    id: 51,
    header: 'Introducing the Snapshot of Approval Summary for US FDA Products',
    subHeader: '',
    date: new Date('05/02/2024'),
    description: [
      {
        title: '',
        body: [
          `<p>
          Access highly curated Administrative, Submission, and Clinical summaries for a specific submission with Snapshot:
          </p>
          <ul>
            <li>Administrative summary: Approval tracks, reviewing office/division, original sponsor, and more.</li>
            <li>Submission summary: Safety database details.</li>
            <li>Clinical summary: Structured design details of each clinical trial from section 14, categorized by indication.</li>
          </ul>
          <p>
          Currently available for ORIGINAL submissions only, supplements with incremental changes will follow soon.
          </p>
          `
        ]
      }
    ],
    slides: [
      {
        description: [
          {
            title: 'Find Safety Database details',
            body: []
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/snapshot/Snap_1.gif'
      },
      {
        description: [
          {
            title: 'Find Clinical Trial Summary supporting each indication',
            body: []
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/snapshot/Snap_2.gif'
      }
    ]
  },
  {
    id: 50,
    header: 'ChatRIAs now Available for Any PDF in the Document within RIA',
    subHeader: '',
    date: new Date('04/09/2024'),
    description: [
      {
        title: '',
        body: [
          'Previously confined to document searches or product labels, the power of Generative AI now transcends those limits. Experience a paradigm shift as crucial documents such as guidance, adcomm reports, and more are effortlessly transformed into actionable knowledge with GenAI.'
        ]
      }
    ],
    slides: [
      {
        description: [
          {
            title: 'Find the ChatRIA icon and start chatting',
            body: []
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/pdf_chatria/chatria.gif'
      }
    ]
  },
  {
    id: 49,
    header: "Take your efficiency to the next level with RIA's Collated Section Summary",
    subHeader: 'Download Collated Sections Across Different Product Labels in Word Format',
    date: new Date('03/27/2024'),
    description: [
      {
        title: `
        <ol>
          <li>Select Label Sections of interest</li>
          <li>Identify Products for which the label sections are needed</li>
          <li>Download in Word Format</li>
        </ol>`,
        body: ['']
      }
    ],
    slides: [
      {
        description: [
          {
            title: '1. Select Label Sections of interest',
            body: [
              'Using the quick/advanced search to identify products within the scope and then select label section of interest.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/collated_summary/Label-Section_Part-1.gif'
      },
      {
        description: [
          {
            title: '2. Identify Products for which the label sections are needed',
            body: ['Select the products to be included in the report. ']
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/collated_summary/Label-Section_Part-2.gif'
      },
      {
        description: [
          {
            title: '3. Download in Word Format',
            body: [
              'Our system will collate your selected sections into a cohesive Word document with a click of a button.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/collated_summary/Label-Section_Part-3.png'
      }
    ]
  },
  {
    id: 48,
    header: 'Launch of Vivpro 2.0',
    subHeader: 'A Significant Milestone in R&D Intelligence',
    description: [
      {
        title: '',
        body: [
          "We want to extend our heartfelt thanks for your continued trust in Vivpro RIA and especially the Vivpro Customer Advisory Board (CAB). We are delighted to announce that RIA 2.0 is now live! As we embrace this new chapter, we are committed to ensuring that our valued clients are well-equipped to leverage the full potential of RIA 2.0's sophisticated features and enhancements."
        ]
      }
    ],
    date: new Date('02/06/2024'),
    slides: [
      {
        description: [
          {
            title: "What's Revolutionary in Vivpro RIA 2.0?",
            body: [
              "<b>Universal Search</b>: Experience the world's most advanced intelligent, singular search across all regions- tailored for the pace and precision of modern drug development."
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/ria2.0/ria2.0-search.gif',
        action: {
          text: 'Explore Vivpro RIA 2.0',
          url: 'https://ria.vivpro.ai/core'
        }
      },
      {
        description: [
          {
            title: "What's Revolutionary in Vivpro RIA 2.0?",
            body: [
              "<b>Unprecedented Productivity</b>: Experience peak productivity. Tools that don't just work for you, but ahead of you- turn hours into minutes."
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/ria2.0/explore-feature.jpeg',
        action: {
          text: 'Explore Vivpro RIA 2.0',
          url: 'https://ria.vivpro.ai/core'
        }
      }
    ]
  },
  {
    id: 47,
    header: 'Maximize Compliance Insights with  FDA Warning, Untitled and Closeout Letters',
    subHeader: 'RIA now consolidates information from three key sources of FDA enforcement actions',
    description: [
      {
        title:
          '<ol><li>Untitled Letters for pharmaceutical companies</li><li>General Warning Letters, and</li><li>  Untitled Letters regarding advertising and promotional labeling for approved drugs and biologics</li></ol>',
        body: ['']
      }
    ],
    date: new Date('01/17/2024'),
    slides: [
      {
        description: [
          {
            title:
              'Your One-Stop Source for FDA Compliance: Untitled Letters, Warning Letters, and Biologics Insights',
            body: []
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/fda_letters_and_warnings/Letter_gif1.gif',
        footer: 'FDA issued 3,501 Warning Letters in the last 5 years',
        action: {
          text: 'Explore Compliance Letters',
          url: 'https://ria.vivpro.ai/letters/insights'
        }
      },
      {
        description: [
          {
            title: 'Ask ARIA about Compliance Letters',
            body: []
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/fda_letters_and_warnings/Letters_gif2.gif',
        footer: 'FDA issued 3,501 Warning Letters in the last 5 years',
        action: {
          text: 'Explore Compliance Letters',
          url: 'https://ria.vivpro.ai/letters/insights'
        }
      }
    ]
  },

  {
    id: 46,
    header: 'Fast-track Your Japan Strategy with PMDA Approvals in RIA',
    subHeader:
      'Gain quick access to all approved PMDA Japan products using the same intuitive workflow you are used to.',
    description: [
      {
        title:
          '<ol><li>Quick & Advanced Search including translation for product labels!</li><li>ChatRIA and ARIA for product labels</li><li>360 View with all history and original documents beyond labels</li><li>Label comparison (including Cross-region compare - i.e. XCompare)</li><li>Search through all label sections</li><li>View up to 5 labels side-by-side</li></ol>',
        body: ['']
      }
    ],
    date: new Date('12/19/2023'),
    slides: [
      {
        description: [
          {
            title: "Optimize Your PMDA Strategy with RIA's Bilingual Workflow",
            body: [
              "RIA's one-of-a-kind workflow to access drug approvals now gives you quick access to all approved PMDA Japan products and supports both English and Japanese languages for approval details and product labels!"
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/PMDA/search.gif',
        footer: 'PMDA Japan has approved 27 products YTD in 2023',
        action: {
          text: 'Explore PMDA approvals',
          url: 'https://ria.vivpro.ai/core?source=jp&category=indications_and_usage'
        }
      },
      {
        description: [
          {
            title: 'Ask ARIA about Product Labels',
            body: [
              'PMDA approvals in RIA comes with the same intuitive workflow found with other data sources including easily comparing labels between products and across data sources, finding answers to complex questions using ARIA, increasing your understanding of labels with ChatRIA, and much more.'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/PMDA/ARIA.gif',
        footer: 'PMDA Japan has approved 27 products YTD in 2023',
        action: {
          text: 'Explore PMDA approvals',
          url: 'https://ria.vivpro.ai/core?source=jp&category=indications_and_usage'
        }
      },
      {
        description: [
          {
            title: 'Gain greater access into the label by searching all sections at once',
            body: [
              'Enjoy a competitive advantage as you and your team quickly gain the key insights necessary to build a solid PMDA strategy!'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/PMDA/Reg360.gif',
        footer: 'PMDA Japan has approved 27 products YTD in 2023',
        action: {
          text: 'Explore PMDA approvals',
          url: 'https://ria.vivpro.ai/core?source=jp&category=indications_and_usage'
        }
      }
    ]
  },
  {
    id: 45,
    header: `Take your efficiency to the next level with RIA's built-in dynamic Reporting`,
    subHeader:
      "Save time sifting through mountains of data with RIA's streamlined reporting feature, allowing you to focus on what truly matters - expediting your regulatory strategy.",
    date: new Date('12/05/2023'),
    slides: [
      {
        description: [
          {
            title: 'Build powerful reports from nearly any page within RIA!',
            body: [
              `Easily create customizable pie charts, bar graphs, and tables with RIA's new reporting feature. From nearly any page, simply click on the Reports icon and start creating!`
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/reporting/quick_search_report.gif'
      },
      {
        description: [
          {
            title: 'Deliver comprehensive and visually compelling reports',
            body: [
              `Effortlessly craft stunning and customizable reports with RIA's user-friendly interface. Tailor the content to your preference and turn complex regulatory data into visually appealing graphs, charts, and text giving your messaging the power to persuade.`
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/reporting/report_preview.gif'
      },
      {
        description: [
          {
            title: 'Manage all your reports in User Profile',
            body: [
              `Manage your saved reports with ease inside your User Profile. You can swiftly access past reports, making them readily available for viewing, editing, and downloading, all in one convenient location.`,
              `Your reports stay up-to-date automatically. No need to worry about manual updates or notifications – when your data changes, your report changes with it. This ensures that your insights are always based on the latest information, giving you a competitive edge.`
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/reporting/myreport.gif'
      }
    ]
  },
  {
    id: 44,
    header: 'RIA Revolutionizes Access to HPRA Ireland Approvals',
    subHeader:
      "Expand your strategy with RIA's unmatched workflow for HPRA Ireland using the following features:",
    description: [
      {
        title:
          '<ol><li>Quick & Advanced Search</li><li>ChatRIA for labels and ARIA searches</li><li>360 View</li><li>Label comparison (including Cross-region compare - i.e. XCompare)</li><li>Search through all label sections</li><li>View up to 5 labels side-by-side</li></ol>',
        body: ['']
      }
    ],
    date: new Date('10/26/2023'),
    slides: [
      {
        description: [
          {
            title: 'Enhance your strategic thinking using ChatRIA for HPRA Ireland labels',
            body: [
              'By integrating ChatRIA into HPRA Ireland labels, you can efficiently optimize your workflow, improve your decision-making processes, and quickly understand the complex information found therein.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/hpra/hpra_chatRIA_cards.jpg',
        footer:
          'Did you know that 6,238 human medicines are approved and listed by Ireland national medicine register that did not use central procedure?',
        action: {
          text: 'Explore HPRA Approvals',
          url: 'https://ria.vivpro.ai/core?source=hpra&category=indications_and_usage'
        }
      },
      {
        description: [
          {
            title:
              "Save valuable time comparing HPRA Ireland labels using RIA's label comparison tool",
            body: [
              "RIA's ability to compare labels between products and across regions now extends to include HPRA Ireland labels. Benefit from the same straightforward and user-friendly workflow you're accustomed to when comparing labels side-by-side."
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/hpra/hpra_chatRIA_cards_compare.jpg',
        footer:
          'Did you know that 6,238 human medicines are approved and listed by Ireland national medicine register that did not use central procedure?',
        action: {
          text: 'Explore HPRA Approvals',
          url: 'https://ria.vivpro.ai/core?source=hpra&category=indications_and_usage'
        }
      },
      {
        description: [
          {
            title: 'Gain greater access into the label by searching all sections at once',
            body: [
              'Effortlessly pinpoint specific text within any section of the latest product label, using the familiar Quick Search workflow. Your search will include relevant synonyms and MeSH terms, resulting in a more comprehensive and precise match.<br><br>Enhance your research capabilities on product labels by selecting and analyzing up to five labels simultaneously.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/hpra/hpra_chatRIA_all_label_sections.jpg',
        footer:
          'Did you know that 6,238 human medicines are approved and listed by Ireland national medicine register that did not use central procedure?',
        action: {
          text: 'Explore HPRA Approvals',
          url: 'https://ria.vivpro.ai/core?source=hpra&category=indications_and_usage'
        }
      }
    ]
  },
  {
    id: 43,
    header: 'Save time with new ARIA enhancements',
    subHeader: '',
    description: [
      {
        title:
          'Elevate your ARIA experience with these four recent updates aimed at ensuring you receive the fastest and most comprehensive answers:',
        body: [
          'ARIA best answer now includes an experience with Generative AI capabilities,<br>Soft filters for easy review of page content,<br>ChatRIA scope is broader,<br>ARIA filters clearer than ever'
        ]
      }
    ],
    date: new Date('10/04/2023'),
    slides: [
      {
        description: [
          {
            title: 'ARIA provides the best possible answer with Generative AI',
            body: [
              'Get the most reliable and customizable information at your fingertips with ARIA’s improved best answers derived from Generative AI capabilities, helping you make informed decisions and solve complex queries effortlessly.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/aria/suggested_answers.jpg',
        footer:
          'You can use ARIA to ask natural language questions on over 1.6 million documents and 12 million pages!',
        action: {
          text: 'Explore ARIA Results',
          url: 'https://www.ria.vivpro.ai/core?source=US&category=indications_and_usage'
        }
      },
      {
        description: [
          {
            title: 'Explore ARIA results with soft filters',
            body: [
              'Make the most out of soft filters to quickly hone in on key ARIA results based on your specific criteria.'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/aria/ARIA_softfilters.png',
        footer:
          'You can use ARIA to ask natural language questions on over 1.6 million documents and 12 million pages!',
        action: {
          text: 'Explore ARIA Results',
          url: 'https://www.ria.vivpro.ai/core?source=US&category=indications_and_usage'
        }
      },
      {
        description: [
          {
            title: 'Transparent ARIA Filters for Informed Decision-Making',
            body: [
              'Experience enhanced transparency with ARIA filters, providing you with clear visibility into the extent of the search space for results within each category in the dataset. This valuable feature empowers you to make informed decisions based on comprehensive data insights.'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/aria/aria_filters.jpg',
        footer:
          'You can use ARIA to ask natural language questions on over 1.6 million documents and 12 million pages!',
        action: {
          text: 'Explore ARIA Results',
          url: 'https://www.ria.vivpro.ai/core?source=US&category=indications_and_usage'
        }
      },
      {
        description: [
          {
            title: 'Expanded document access for ChatRIA on ARIA results',
            body: [
              "You now have the ability to select any page range in ARIA documents on which to ask questions in ChatRIA. We have also expanded ChatRIA's search capacity to cover 17 pages – over a fivefold increase so you can get more comprehensive answers!"
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/aria/chatRIA_17pages.png',
        footer:
          'You can use ARIA to ask natural language questions on over 1.6 million documents and 12 million pages!',
        action: {
          text: 'Explore ARIA Results',
          url: 'https://www.ria.vivpro.ai/core?source=US&category=indications_and_usage'
        }
      }
    ]
  },
  {
    id: 42,
    header: 'Access Approved Products for Health Products Regulatory Authority (HPRA, Ireland)',
    subHeader:
      'Empower your decision-making and accelerate your strategy with unparalleled access to products approved in Ireland that did not use centralized procedure. (e.g. justifying control arm in trials, products registered only in a specific member state, etc.)',
    description: [
      {
        title: '',
        body: []
      }
    ],
    date: new Date('9/12/2023'),
    slides: [
      {
        description: [
          {
            title:
              'Simplify your regulatory research for HPRA approvals while still viewing central (EMA) approvals',
            body: [
              "Using the following power features from RIA, you can dive deep into Ireland's Health Products Regulatory Authority  and quickly get the answers you need:<ol><li>Easily search for and view all approved HPRA products along with key data inside the familiar Application view using Quick or Advanced Search.</li><li>Ask RIA (ARIA) any natural language question to pinpoint answers to complex regulatory questions.</li><li>Utilize the Regulatory 360 view to get a complete picture of the regulatory approval history of individual HPRA products.</li><ol>"
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/hpra/hpra_combined4.png',
        footer:
          'Did you know that 6,238 human medicines are approved and listed by Ireland national medicine register that did not use central procedure?<br><br>These mostly include generic medicines and medicines available without a prescription, but older medicines available today were also authorized at national level because they were marketed before EMA was created. However, a company can utilize the national process, if a company wishes, to request marketing authorization in select EU Member States.',
        action: {
          text: 'Search HPRA Approvals for Melanoma',
          url: 'https://ria.vivpro.ai/search/label/core/indications_and_usage/melanoma/hpra/nNY4i694HIZsH8eL7Q3qP1k_aV-YY5DeevxZwMO9VQA'
        }
      }
    ]
  },
  {
    id: 41,
    header: "RIA's Unmatched Workflow Enhances Access to Health Canada Approvals",
    subHeader: "Major Additions to RIA's Health Canada Approvals",
    // description: [
    //   {
    //     title: 'Available Features:',
    //     body: ['Quick & Advanced Search', 'ChatRIA for Monographs and ARIA searches', '360 View', 'Compare Labels (including Cross-region compare - i.e. XCompare)', 'Search through all Monograph sections', 'View up to 5 monographs side-by-side']
    //   }
    // ],
    date: new Date('8/28/2023'),
    slides: [
      {
        description: [
          {
            title: 'Elevate your strategic thinking with ChatRIA for Health Canada monographs',
            body: [
              "With the power of ChatRIA's advanced AI technology applied to Health Canada monographs, you can streamline your workflow, enhance your decision-making, and ensure a comprehensive understanding of the complex information contained within."
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/canada/ca_chatRIA.jpg',
        // footer:
        //   '',
        action: {
          text: 'Chat with Lymphoma Monographs',
          url: 'https://ria.vivpro.ai/search/label/core/indications_and_usage/lymphoma/ca/PRveZpobfX9aQX6zMs3dylk_aV-YY5DeevxZwMO9VQA'
        }
      },
      {
        description: [
          {
            title: 'RIA saves you time comparing Health Canada Monographs',
            body: [
              "RIA's well known label comparison capabilities between products and across regions now includes Health Canada monographs! Take advantage of the same simple and intuitive workflow you are used to for comparing monographs side-by-side."
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/canada/ca_label_comp_combined.png',
        // footer:
        //   '',
        action: {
          text: 'Cross Region Compare ADPKD',
          url: 'https://ria.vivpro.ai/comparison/us_vs_eu/indications_and_usage/adpkd?source=US&category=indications_and_usage'
        }
      },
      {
        description: [
          {
            title: 'Easily search monograph sections and view up to five monographs side-by-side',
            body: [
              'Effortlessly locate specific text within any section of the most recent product monograph using the same Quick Search workflow you are familiar with.<br><br>Your search will include relevant synonyms and MeSH terms, resulting in a more comprehensive and precise match. Enhance your research capabilities on product monographs by effortlessly selecting and analyzing up to five monographs simultaneously.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/canada/ca_compare_2_labels.jpg',
        // footer:
        //   '',
        action: {
          text: 'Compare Lymphoma Monographs',
          url: 'https://ria.vivpro.ai/search/label/core/indications_and_usage/lymphoma/ca/PRveZpobfX9aQX6zMs3dylk_aV-YY5DeevxZwMO9VQA'
        }
      }
    ]
  },
  {
    id: 40,
    header: 'Access the Complete Regulatory History for All Devices with the 360 View',
    subHeader:
      'Gain a competitive edge by having the full history of Devices products at your fingertips.',
    description: [
      {
        title: '',
        body: []
      }
    ],
    date: new Date('8/07/2023'),
    slides: [
      {
        description: [
          {
            title:
              'Boost your access and understanding of critical Devices product intelligence with the Devices 360 view',
            body: [
              'Optimize your drug development strategy and save valuable time by utilizing the 360 view for Devices. This comprehensive tool provides access to the complete approval history and a detailed device summary, allowing for quick and efficient reference in your research and formulation process.'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/reg360/devices_2.png',
        // footer:
        //   '',
        action: {
          text: 'Explore Devices360',
          url: 'https://ria.vivpro.ai/regulatory360/core/P120010/us-devices?category=indications_and_usage&tabname=approvalHistory&searchId=phtA5BV-0MZFzzrh8vvvvQ&backTo=eyJtb2R1bGUiOiJjb3JlIiwicGF0aCI6Ii9zZWFyY2gvbGFiZWwvY29yZS9pbmRpY2F0aW9uc19hbmRfdXNhZ2UvZGlhYmV0ZXMvVVMtRGV2aWNlcy9waHRBNUJWLTBNWkZ6enJoOHZ2dnZRIiwic2VhcmNoIjp7fSwibmFtZSI6IlNlYXJjaCBSZXN1bHRzIn0%3D&source=US-Devices'
        }
      }
    ]
  },
  {
    id: 39,
    header: 'Expedite Your EU Product Strategy with Direct Access to CHMP on RIA',
    subHeader:
      'Gain in-depth intelligence into CHMP decisions and discussions with full access to meetings and ARIA-ready documents.',
    description: [
      {
        title: '',
        body: []
      }
    ],
    date: new Date('7/25/2023'),
    slides: [
      {
        description: [
          {
            title: 'Rapid Access to Key CHMP Insights',
            body: [
              'RIA empowers you to quickly gather and analyze essential data related to the Committee for Medicinal Products for Human Use (CHMP) and enables you to make informed strategic decisions to optimize your product strategies.'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/chmp/chmp_insights.jpg'
        // footer:
        //   '',
        // action: {
        //   text: '',
        //   url: ''
        // }
      },
      {
        description: [
          {
            title: 'Quickly Navigate ARIA-Searchable CHMP Meetings',
            body: [
              'Access valuable meeting details through CHMP Meetings, providing you with a comprehensive list of drugs discussed and direct access to the ARIA-ready source documents for each meeting.'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/chmp/chmp_meetings.jpg',
        footer:
          'Did you know that Keytruda has been reviewed by CHMP 23 times (5 times in 2021). What application would you be interested in? Click below to look it up!',
        action: {
          text: 'Access CHMP Module',
          url: 'https://ria.vivpro.ai/chmp/insights'
        }
      }
    ]
  },
  {
    id: 38,
    header: 'ChatRIA answers using the best-matched label section',
    subHeader: '',
    description: [
      {
        title: '',
        body: []
      }
    ],
    date: new Date('6/22/2023'),
    slides: [
      {
        description: [
          {
            title: '',
            body: [
              'ChatRIA has been trained to understand product labels like an expert assistant. With the most recent update to ChatRIA, selecting a section of the label is no longer required; the first iteration was merely a starting point. ChatRIA will now, to the best of its ability, identify the section that best answers your question. If you would like an answer from different section, ChatRIA will execute a new section, just choose a new section using the stars icon. As before, the response can be easily validated and appended to reports. This addition enables you to manage ChatRIA responses using the same intuitive user experience.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/chatRIA/chatRIA_alternative_sections.jpg',
        // footer:
        //   '',
        action: {
          text: 'Access ChatRIA for LEQEMBI',
          url: 'https://ria.vivpro.ai/search/label/core/trade_name/leqembi/US/twpyaq76oiipc2yh3Vjlcg'
        }
      }
    ]
  },
  {
    id: 37,
    header: 'Enhanced label search and view up to 5 labels side-by-side',
    subHeader:
      'Search and locate complex information with targeted precision. View multiple labels effortlessly with a side-by-side view.',
    description: [
      {
        title: '',
        body: []
      }
    ],
    date: new Date('6/16/2023'),
    slides: [
      {
        description: [
          {
            title: 'Quickly search specific label sections',
            body: [
              'Pinpoint text found in any section of a most recent product label quickly and do it with the same Quick Search workflow you are used to. Your search will automatically include any available synonyms and MeSH terms for a more comprehensive match. Interested in a particular section? Simply narrow down your search scope with the new Label Section filter option.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/label-comparison/view_labels_cards_view.jpg',
        // footer:
        //   '',
        action: {
          text: 'Search "overall survival" in All Label Sections',
          url: 'https://ria.vivpro.ai/search/label/core/label_section_search/overall%20survival/us/3lJfpFY8iEAvAFTVO1ss01k_aV-YY5DeevxZwMO9VQA'
        }
      },
      {
        description: [
          {
            title: 'Easily view multiple labels side by side',
            body: [
              'Say goodbye to the hassle of finding and viewing multiple PDFs. With RIA, you can conduct superior research on product labels by effortlessly choosing and examining up to five labels simultaneously.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/label-comparison/view_labels_view.jpg',
        footer:
          "Did you know that 250 labels include 'overall survival' and 226 of those are in the clinical studies section",
        action: {
          text: 'Search "overall survival" in All Label Sections',
          url: 'https://ria.vivpro.ai/search/label/core/label_section_search/overall%20survival/us/3lJfpFY8iEAvAFTVO1ss01k_aV-YY5DeevxZwMO9VQA'
        }
      }
    ]
  },
  {
    id: 36,
    header: 'Gain quicker access to CBER and Clinical Trials data with the 360 view',
    subHeader:
      'Speed up your winning strategy with access to the full history of CBER products and Clinical Trials at your fingertips',
    description: [
      {
        title: '',
        body: []
      }
    ],
    date: new Date('5/18/2023'),
    slides: [
      {
        description: [
          {
            title: '360 View of Clinical Trials',
            body: [
              'Save valuable time in researching and formulating your drug development strategy using the 360 view for Clinical Trials. With this tool, access ready-to-use charts to track changes in the Primary Completion Date, conveniently view the Recruitment Status history for any version of a clinical trial, and access a comprehensive list of adverse reactions.'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/reg360/ct360_combined.png',
        // footer:
        //   '',
        action: {
          text: 'Explore Clinical Trial 360',
          url: 'https://ria.vivpro.ai/regulatory360/core/NCT02494583/ct?tabname=approvalHistory&searchId=begYWgQSlATTob8mxutCl1k_aV-YY5DeevxZwMO9VQA'
        }
      },
      {
        description: [
          {
            title: 'Supercharge Your Access to CBER Product Intelligence with a 360 view',
            body: [
              'Get full access to the Regulatory 360 view for all US FDA CBER products! This upgrade provides in-depth information similar to CDER products, including complete Approval History, label comparison, ARIA, and more*.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/reg360/cber360_combined.png',
        footer: '*CDP for CBER coming soon!',
        action: {
          text: 'Search BREYANZI 360',
          url: 'https://ria.vivpro.ai/regulatory360/core/125714/us?type=BLA&category=trade_name&tabname=approvalHistory&searchId=3Hw28BMHRhLjlsRQ9PtG7Fk_aV-YY5DeevxZwMO9VQA'
        }
      }
    ]
  },
  {
    id: 35,
    header: 'ChatRIA keeps getting better',
    subHeader: 'Enhancements to ChatRIA can help you save time and improve your strategy:',
    description: [
      {
        title: '',
        body: []
      }
    ],
    date: new Date('5/11/2023'),
    slides: [
      {
        description: [
          {
            title: 'ChatRIA Meets ARIA = Your personal regulatory consultant',
            body: [
              'ChatRIA can now summarize and analyze ARIA result pages, improving your understanding of complex regulatory documents and increasing the time you spend on building a winning strategy.'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/chatRIA/chatRIA_aria.jpg',
        // footer:
        //   '',
        action: {
          text: 'Try it out!',
          url: 'https://ria.vivpro.ai/aria/core/us/vgntFI_t5tCMkOpEdKzclw?term=what+was+the+primary+endpoint+for+sofosbuvir&bucket=all'
        }
      },
      {
        description: [
          {
            title: 'Build a report across multiple documents',
            body: [
              'Minimizing the ChatRIA pane allows you to navigate between ARIA results or product labels so you can easily build comprehensive reports. Simply click the minus button in the top right corner of ChatRIA, open a new ARIA result or product, and start chatting to create your report!'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/chatRIA/chatRIA_minimize.jpg',
        // footer:
        //   '',
        action: {
          text: 'Try it out!',
          url: 'https://ria.vivpro.ai/aria/core/us/vgntFI_t5tCMkOpEdKzclw?term=what+was+the+primary+endpoint+for+sofosbuvir&bucket=all'
        }
      },
      {
        description: [
          {
            title: 'Empowering your reporting',
            body: [
              'Easily rearrange sections, edit suggested titles provided by ChatRIA, and download the report as a PDF for immediate sharing, or a Word document for personal editing.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/chatRIA/chatRIA_rearrange_reports_titles.jpg',
        // footer:
        //   '',
        action: {
          text: 'Try it out!',
          url: 'https://ria.vivpro.ai/aria/core/us/vgntFI_t5tCMkOpEdKzclw?term=what+was+the+primary+endpoint+for+sofosbuvir&bucket=all'
        }
      },
      {
        description: [
          {
            title: 'Send ChatRIA reports with confidence!',
            body: [
              'ChatRIA reports provide convenient reference links directing you to RIA where the source document can be viewed and the answer verified.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/chatRIA/chatRIA_report_references.jpg',
        // footer:
        //   '',
        action: {
          text: 'Try it out!',
          url: 'https://ria.vivpro.ai/aria/core/us/vgntFI_t5tCMkOpEdKzclw?term=what+was+the+primary+endpoint+for+sofosbuvir&bucket=all'
        }
      },
      {
        description: [
          {
            title: 'Let ChatRIA guide your questions',
            body: [
              'Not sure what to ask? ChatRIA can speed up your understanding of complex regulatory documents by suggesting relevant questions based on the content within scope. Click on the light bulb in the bottom right hand corner of the ChatRIA window to get suggestions.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/chatRIA/chatRIA_aria_suggested_questions.jpg',
        // footer:
        //   '',
        action: {
          text: 'Try it out!',
          url: 'https://ria.vivpro.ai/aria/core/us/vgntFI_t5tCMkOpEdKzclw?term=what+was+the+primary+endpoint+for+sofosbuvir&bucket=all'
        }
      },
      {
        description: [
          {
            title: 'Converse with ChatRIA like you would a true regulatory expert',
            body: [
              'ChatRIA is more conversational than ever, allowing you to interact with previous answers from your current window giving you a seamless chat experience.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/chatRIA/chatRIA_aria_conversational.jpg',
        // footer:
        //   '',
        action: {
          text: 'Try it out!',
          url: 'https://ria.vivpro.ai/aria/core/us/vgntFI_t5tCMkOpEdKzclw?term=what+was+the+primary+endpoint+for+sofosbuvir&bucket=all'
        }
      }
    ]
  },
  {
    id: 34,
    header: 'Accelerate Product Strategy with RIA’s Integration of Health Canada Approvals',
    subHeader:
      'Research Health Canada-approved products faster with the same intuitive interface provided by Vivpro for other regions',
    description: [
      {
        title: '',
        body: []
      }
    ],
    date: new Date('5/1/2023'),
    slides: [
      {
        description: [
          {
            title: 'Navigate Health Canada Products with Quick & Advanced Search',
            body: [
              'Search more than 12,800 Health Canada approved products by Indication, Active Ingredient, Approval Date, and more, to gain a clear understanding of the current regulatory landscape in Canada.'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/canada/canada_cards.jpg',
        footer:
          'Pro Tip: Easily share Health Canada approval searches and ARIA queries with your team using the Collaborate feature inside your Profile!',
        action: {
          text: 'Search Diabetes',
          url: 'https://ria.vivpro.ai/search/label/core/indications_and_usage/diabetes/ca/IvPGkCDX81BX4FceBe3QJ1k_aV-YY5DeevxZwMO9VQA'
        }
      },
      {
        description: [
          {
            title:
              'ARIA can help answer your questions from 24,580 documents and 6.8M pages from Health Canada approvals',
            body: [
              "Utilize ARIA to efficiently search through millions of PDF pages and effectively locate the information you need to formulate a winning Canada strategy. It's like finding a needle in a haystack made easy."
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/canada/canada_ARIA.jpg',
        footer:
          'Pro Tip: When you have a PDF open in ARIA, simply click on ChatRIA and ask questions to aid in your comprehension and interaction with the ARIA results.',
        action: {
          text: 'Search Health Canada',
          url: 'https://ria.vivpro.ai/core?source=ca&category=indications_and_usage'
        }
      },
      {
        description: [
          {
            title: 'Regulatory360 View on All Approved Drugs for Health Canada',
            body: [
              "No more hunting through Health Canada's website for approval details! The Regulatory360 module connects Approval History, Monographs, Regulatory Decision Summaries, and Clinical Information from various parts of Health Canada website."
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/canada/canada_360.jpg',
        footer:
          'Did you know that Health Canada publishes M2 and M5 from sponsor submissions? RIA includes 15,472 M2 and M5 documents under Clinical Information.',
        action: {
          text: '360 View for KEYTRUDA',
          url: 'https://ria.vivpro.ai/regulatory360/core/V005405/ca?category=trade_name&tabname=approvalHistory&searchId=-uqjcBvVlqy3S29AMzSwLVk_aV-YY5DeevxZwMO9VQA'
        }
      }
    ]
  },
  {
    id: 33,
    header: "Speed Up Collaboration with RIA's Integrated Chat Service: COLLABORATE",
    subHeader: 'What does enhanced collaboration look like in RIA?',
    description: [
      {
        title: '',
        body: [
          'Easily chat with other RIA users inside your organization,',
          'Share search links with your colleagues,',
          'Open service tickets, request features and get direct Vivpro support.'
        ]
      }
    ],
    date: new Date('4/17/2023'),
    slides: [
      {
        description: [
          {
            title: "Boost your research and collaboration through RIA's new Collaborate feature",
            body: [
              'With the help of RIA\'s integrated chat service, you can quickly communicate with team members, ask and respond to questions, delegate and track critical tasks, and exchange relevant searches, thereby accelerating the strategic process and output. Check out the <a target="_blank" href="https://ria.vivpro.ai/help/videos/collaborate">training video!</a>'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/user_profile/collaborate_UP.jpg',
        // footer:
        //   '',
        action: {
          text: 'Access Collaborate',
          url: 'https://ria.vivpro.ai/collaborate'
        }
      },
      {
        description: [
          {
            title: 'Open a Service Request directly from Chat!',
            body: [
              'Easily open a ticket and get quick responses from Vivpro on topics such as system issues, feature requests, or general questions by sending a message directly from your Profile under Service Requests. Check out the <a target="_blank" href="https://ria.vivpro.ai/help/videos/service_requests">training video!</a>'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/user_profile/service_requests_UP.jpg',
        // footer:
        //   '',
        action: {
          text: 'Access Service Requests',
          url: 'https://ria.vivpro.ai/account/serviceRequests'
        }
      }
    ]
  },
  {
    id: 32,
    header: 'Bringing Comparing to EMA Guidances and ARIA to Projects & Favorites in Profile',
    subHeader: '',
    description: [
      {
        title: '',
        body: ['']
      }
    ],
    date: new Date('4/5/2023'),
    slides: [
      {
        description: [
          {
            title: 'Compare EMA Guidance documents with RIA',
            body: [
              'Stay ahead of the curve in the ever-changing world of EMA policies. Compare any two Guidance documents and obtain valuable insights for policy analysis and research, enabling you to develop a winning strategy.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/ema/ema_guidance_comparison.jpg',
        // footer:
        //   '',
        action: {
          text: 'Access EMA Guidances',
          url: 'https://ria.vivpro.ai/ema_guidance'
        }
      },
      {
        description: [
          {
            title: 'Customize your ARIA search with a specific set of applications',
            body: [
              'Leverage the power of ARIA on your personalized Favorites and Project application lists inside your RIA Profile to make your research and decision-making processes smoother and more efficient.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/user_profile/ARIA_user_profile_projects.jpg',
        // footer:
        //   '',
        action: {
          text: 'Access your Favorites',
          url: 'https://ria.vivpro.ai/account/favorites'
        }
      },
      {
        description: [
          {
            title: 'Bring clarity to your projects through Project Descriptions',
            body: [
              'Enhance the quality and efficiency of your process by writing detailed project descriptions to compound and expedite your research.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/user_profile/user_profile_description.jpg',
        // footer:
        //   '',
        action: {
          text: 'Access your Projects',
          url: 'https://ria.vivpro.ai/account'
        }
      }
    ]
  },
  {
    id: 31,
    header:
      'Speed up a winning drug development strategy with Expedited Pathways module and other enhancements',
    subHeader:
      'Quickly gain insights about the timelines and landscape of all applications that have been given the "expedited pathways" label by each indication.',
    description: [
      {
        title: '',
        body: []
      }
    ],
    date: new Date('3/22/2023'),
    slides: [
      {
        description: [
          {
            title: 'Maximize your insights into Expedited Pathways',
            body: [
              'The Expedited Pathways module allows you to view application submissions for any given condition in an application timeline view, categorized by designation, allowing for a more comprehensive understanding of expedited pathways utilized for a particular condition.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/expedited-pathways/exp_pathway_application_view-1.jpg',
        footer:
          'Did you know that UROTHELIAL CARCINOMA received a total of 14 designations since 2012? Click on the link to see how many were AA, BT, FT, and OR!',
        action: {
          text: 'Expedited Pathways Application View',
          url: 'https://ria.vivpro.ai/designation/urothelial%20carcinoma?tab=details&view=horizontal'
        }
      },
      {
        description: [
          {
            title: 'Quickly understand the regulatory landscape surrounding Expedited Pathways',
            body: [
              'Easily identify trends and patterns in the submission and approval of specialty designations for specific conditions with the timeline view allowing you to focus on optimizing your drug development strategies and identifying opportunities to increase the pace of development for your drug.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/expedited-pathways/exp_pathway_designation_view.jpg',
        // footer:
        //   '',
        action: {
          text: 'Expedited Pathways Designation View',
          url: 'https://ria.vivpro.ai/designation/urothelial%20carcinoma?tab=details&view=vertical'
        }
      },
      {
        description: [
          {
            title: 'Simplify your clinical trial tracking with our Gantt Chart view',
            body: [
              'Say goodbye to the hassle of sorting through multiple documents and spreadsheets to track clinical trials of your interest. With our Gantt Chart view, you can collate all your clinical trials into one easy-to-use interface. Get a comprehensive overview of trial timelines, including key milestones and phases, with just a glance.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/clinical_trials/ct_gantt_timeline.jpg',
        // footer:
        //   '',
        action: {
          text: 'Clinical Trials Timeline View',
          url: 'https://ria.vivpro.ai/search/label/core/indications_and_usage/alport%20syndrome/ct/U7tysFcd4A36debiMSVb7lk_aV-YY5DeevxZwMO9VQA?tab=timelineCT'
        }
      }
    ]
  },
  {
    id: 30,
    header: 'GPT Meets RIA: Your new drug development expert is here.',
    subHeader: "Let's chat!",
    description: [
      {
        title:
          'Vivpro has given more power to you. Now with ChatRIA, the only limit is your own curiosity. ',
        body: []
      }
    ],
    date: new Date('3/20/2023'),
    slides: [
      {
        description: [
          {
            title: 'You + ChatRIA = Expert!',
            body: [
              'Why consult experts when you can leverage the power of ChatRIA to easily summarize, analyze, and interpret data into tables, bullets and even compute numbers.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/chatRIA/chatRIA_360_table.jpg',
        // footer:
        //   '',
        action: {
          text: 'Access ChatRIA for FILSPARI',
          url: 'https://ria.vivpro.ai/regulatory360/core/216403/us?type=NDA&category=trade_name&tabname=approvalHistory&searchId=5sqc77N5G-h3QmpHWnPAHVk_aV-YY5DeevxZwMO9VQA'
        }
      },
      {
        description: [
          {
            title: 'Summary Reports in Minutes',
            body: [
              'ChatRIA is designed to provide instant responses, which can allow you to create reports in a matter of minutes. This can help you improve response times and reduce frustration for your stakeholders.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/chatRIA/chatRIA_report.jpg',
        // footer:
        //   '',
        action: {
          text: 'Access ChatRIA for FILSPARI',
          url: 'https://ria.vivpro.ai/regulatory360/core/216403/us?type=NDA&category=trade_name&tabname=approvalHistory&searchId=5sqc77N5G-h3QmpHWnPAHVk_aV-YY5DeevxZwMO9VQA'
        }
      },
      {
        description: [
          {
            title: 'Increase Think-time by 20x!',
            body: [
              "ChatRIA's ability to respond to natural language questions and give simple yet comprehensive answers allows you time to think and strategize rather than spend time analyzing complex data."
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/chatRIA/chatRIA_header.png',
        // footer:
        //   '',
        action: {
          text: 'Access ChatRIA for FILSPARI',
          url: 'https://ria.vivpro.ai/regulatory360/core/216403/us?type=NDA&category=trade_name&tabname=approvalHistory&searchId=5sqc77N5G-h3QmpHWnPAHVk_aV-YY5DeevxZwMO9VQA'
        }
      },
      {
        description: [
          {
            title: 'Revolutionize Regulatory Intelligence',
            body: [
              'Unleash the power of ChatRIA to revolutionize regulatory intelligence using cutting-edge conversational chatbot technology and accelerate the development of novel medicines.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/chatRIA/GPT+meets+RIA+Introducing+New+R%26D+Intelligence+Expert+Assistant.png',
        // footer:
        //   '',
        action: {
          text: 'Access ChatRIA for FILSPARI',
          url: 'https://ria.vivpro.ai/regulatory360/core/216403/us?type=NDA&category=trade_name&tabname=approvalHistory&searchId=5sqc77N5G-h3QmpHWnPAHVk_aV-YY5DeevxZwMO9VQA'
        }
      }
    ]
  },
  {
    id: 29,
    header: 'Vivpro Enhances the RIA Platform with Essential EMA Guidances',
    subHeader: 'Vivpro ensures consistent quality across EMA and FDA Guidance modules',
    description: [
      {
        title: '',
        body: []
      }
    ],
    date: new Date('2/21/2023'),
    slides: [
      {
        description: [
          {
            title: 'Easily Navigate EMA Guidances',
            body: [
              "RIA's new EMA Guidance module allows you to access 755 EMA guidances in their native PDF format, quickly review and export key guidance data, and use ARIA to answer your questions on over 35k pages to expedite your EMA research."
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/ema/ema_guidances_combined.png',
        footer:
          'Did you know that 42 new EMA Guidances were introduced in 2022 with 24 in Adopted status?',
        action: {
          text: 'Access EMA Guidance Module',
          url: 'https://ria.vivpro.ai/ema_guidance'
        }
      }
    ]
  },
  {
    id: 28,
    header: 'Comparing Guidances made easier in RIA',
    subHeader:
      "Did you ever wonder how you could track the evolution of the FDA's perspectives and practices regarding policies and guidelines over time? With RIA, now you can compare Guidances and stay ahead of the game.",
    description: [
      {
        title: '',
        body: []
      }
    ],
    date: new Date('2/13/2023'),
    slides: [
      {
        description: [
          {
            title: 'You Asked, We Delivered!',
            body: [
              'Track changing policies with ease using RIA. Quickly compare any two Guidance documents and gain insights for policy analysis and research to create a winning strategy.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/guidances/guidance_compare_combined1.png',
        footer:
          'Did you know that on January 12, 2023 FDA updated the 2010-03-23 guidance on “Content and Format of the Dosage and Administration Section of Labeling for Human Prescription Drug and Biological Products”? The new draft guidance is open for comments and include major changes to the guidance content.',
        action: {
          text: 'Compare Guidances',
          url: 'https://ria.vivpro.ai/guidanceDocuments'
        }
      }
    ]
  },
  {
    id: 27,
    header: 'Unveiling the Power Behind RIA with Additional Platform Data Statistics',
    subHeader:
      'RIA clearly displays summary metrics, including document, page, and other information counts, offering insights into data quality and volume.',
    description: [
      {
        title: '',
        body: []
      }
    ],
    date: new Date('2/09/2023'),
    slides: [
      {
        description: [
          {
            title: 'Access valuable insights and make more informed decisions',
            body: [
              'Large amounts of complex regulatory data are gathered and curated to provide highly accurate and useful content to our customers.<br>Check out a table of summary statistics inside the FAQ page of the RIA Help pages.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/platform_level/faqs_stats.png',
        footer:
          'Did you know that RIA contains 1.5MM+ documents, 400K+ Clinical Trials, and 12MM+ pages across 4 regions?',
        action: {
          text: 'View Data Sources',
          url: 'https://ria.vivpro.ai/help?content=faq#Data%20Sources'
        }
      },
      {
        description: [
          {
            title: 'Navigate RIA with confidence',
            body: [
              'RIA now displays the document and page counts directly on each module, offering greater visibility into the data driving each page of the platform giving you confidence in the accuracy and completeness of the data being used.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/platform_level/db_stats_combined.png',
        footer:
          'Did you know that in January 2023, 58 new applications, 315 new submissions, and 143 new products were added to US FDA Drugs & Biologics?',
        action: {
          text: 'Access RIA',
          url: 'https://ria.vivpro.ai/core'
        }
      }
    ]
  },
  {
    id: 26,
    header: 'Streamline Your Drug Research in RIA by Searching with ATC Classes',
    subHeader:
      'View categories of products quickly with the new ATC Class search for US FDA & EU EMA Drugs & Biologics.',
    description: [
      {
        title: '',
        body: []
      }
    ],
    date: new Date('2/02/2023'),
    slides: [
      {
        description: [
          {
            title: 'Focus your research on specific data sets',
            body: [
              'Searching by ATC Class allows you to easily compare drugs across indications of the same ATC category. Enjoy the same experience when searching in either US FDA or EU EMA Drugs & Biologics data sources!'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/synonyms/atc_protein_kinase_list_view.jpg',
        footer:
          'Learn about Anatomical Therapeutic Chemical (ATC) Classifications by visiting the <a target="_blank" href="https://www.who.int/tools/atc-ddd-toolkit/atc-classification">WHO website</a>.'
        // action: {
        //   text: '',
        //   url: ''
        // }
      },
      {
        description: [
          {
            title: 'Quickly identify key drugs by using the ATC Class View',
            body: [
              'Select the ATC Class View to gain additional insights into the data set which can be narrowed down to ensure you receive the most relevant results.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/synonyms/atc_protein_kinase_syn_view.jpg',
        footer:
          'Did you know that out of the 93 drugs approved for ATC Class L01E: PROTEIN KINASE INHIBITORS, 8 are approved for Mammalian target of rapamycin (mTOR) kinase inhibitors?',
        action: {
          text: 'View drugs approved for PROTEIN KINASE INHIBITORS',
          url: 'https://ria.vivpro.ai/search/label/core/atc_classification/L01E%3A%20PROTEIN%20KINASE%20INHIBITORS/US?view=true'
        }
      }
    ]
  },
  {
    id: 25,
    header: 'Experience the power of enhanced insight with Regulatory 360!',
    subHeader:
      "The Regulatory 360 module provides you with the convenience of having all the relevant information about a product in one place. We've just added three new features that we think you'll love! Streamline your research process with these powerful additions.",
    description: [
      {
        title: '',
        body: []
      }
    ],
    date: new Date('1/3/2023'),
    slides: [
      {
        description: [
          {
            title: 'Discover REMS details for the history of any product',
            body: [
              "Regulatory 360's new REMS section offers convenient access to key REMS details including Shared System, Med Guide, and Communication Plan."
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/reg360/rems_natpara.jpg',
        footer: 'Did you know that NATPARA has both REMS and Boxed Warning?',
        action: {
          text: 'Access NATPARA 360',
          url: 'https://ria.vivpro.ai/regulatory360/core/125511/us?type=BLA&category=indications_and_usage&tabname=rems'
        }
      },
      {
        description: [
          {
            title: 'Quickly Find Trials Cited in SBAs',
            body: [
              "RIA has connected trials in ClinicalTrials.gov and the trials cited in SBAs for each submission. You can now quickly view the basis of FDA's submission (where available)."
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/reg360/pivotal_studies.jpg',
        footer: '',
        action: {
          text: 'View Trials Cited in SBAs for CALQUENCE',
          url: 'https://ria.vivpro.ai/regulatory360/core/210259/us?type=NDA&category=indications_and_usage&tabname=approvalHistory'
        }
      },
      {
        description: [
          {
            title: 'Quickly Identify Applications with Boxed Warnings!',
            body: [
              'Along with other key approval information you can now access boxed warning details for each submission.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/reg360/boxed_warning_bubble.jpg',
        footer: ''
        // action: {
        //   text: "",
        //   url: ''
        // }
      }
    ]
  },
  {
    id: 24,
    header: "Quickly Recreate a Competitor's Clinical Development Plan",
    subHeader: '',
    description: [
      {
        title:
          "Have you ever wished you could readily view the development plan of a competitor's successful drug?<br><br>Now, with the help of RIA, it’s simple to link clinical trials to FDA actions and recreate the Gantt chart of any Clinical Development Plan from publicly available information.",
        body: []
      }
    ],
    date: new Date('1/9/2023'),
    slides: [
      {
        description: [
          {
            title: 'Create a CDP Gantt chart',
            body: [
              'CDP allows you to easily <ol><li>Gather all trials for a given active ingredient</li><li>Discover which trials were in scope for FDA Reviews</li><li>Access a stacked view of trials and FDA actions.</li></ol>'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/cdp/cdp_graph.jpg',
        footer:
          'Did you know that there were 8 trials reported on clinicaltrials.gov that were cited in FDA Reviews for MEKTOVI? Both trials were cited in the first FDA action to support MEKTOVI approval.<br><br>* Only trials included in the FDA review and sponsored by industry are shown when you first generate the CDP.',
        action: {
          text: "Access MEKTOVI's CDP",
          url: 'https://ria.vivpro.ai/cdp/core/210498/us/binimetinib?type=NDA&category=trade_name&searchTerm=MEKTOVI'
        }
      },
      {
        description: [
          {
            title: 'Focusing on trials and FDA actions of interest',
            body: ['Utilize filters to narrow down trials or FDA actions depending on your scope.']
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/cdp/cdp_mektovi_all_trials.jpg',
        footer:
          "Did you know that Pfizer and Array BioPharma reported a total of 40 trials for MEKTOVI's Clinical Development Plan? RIA makes it easy to view which 8 of the 40 trials were actually included in the CDP.",
        action: {
          text: "Access MEKTOVI's CDP",
          url: 'https://ria.vivpro.ai/cdp/core/210498/us/binimetinib?type=NDA&category=trade_name&searchTerm=MEKTOVI'
        }
      },
      {
        description: [
          {
            title: 'Accessing Submission and Clinical Trial Documents',
            body: [
              "Bring additional insight into a drug's Clinical Development Plan by viewing documents for individual submissions or clinical trials."
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/cdp/cdp_docs.jpg',
        footer:
          'Did you know that the sponsor of NCT02159066 was Pfizer and NCT02050815 was Array BioPharma. Both trials were included in the first FDA action to support MEKTOVI approval.<br><br><span style="font-weight: bold;">* Why is CDP in Beta?</span> At Vivpro, we prioritize the quality of the data provided by our CDP module, which combines two complex data sources that can be prone to errors. While we are confident in the current quality of the module, we are constantly working to optimize and improve the data connections for a full release. If you have any comments or feedback on the CDP module, we would greatly appreciate it if you could share them by clicking the "Share your feedback" button in the bottom left corner of the module.',
        action: {
          text: "Access MEKTOVI's CDP",
          url: 'https://ria.vivpro.ai/cdp/core/210498/us/binimetinib?type=NDA&category=trade_name&searchTerm=MEKTOVI'
        }
      }
    ]
  },
  {
    id: 23,
    header: 'Centralizing Regulatory Intelligence in RIA',
    subHeader: 'Three Exciting RIA Additions to Centralize Intelligence History',
    description: [
      {
        title:
          '👉🏼 Access activity as a Feed or Journey<br><br>⭐ Create a Favorites list of activity<br><br>✅ Structure activity into Projects',
        body: []
      }
    ],
    date: new Date('12/13/2022'),
    slides: [
      {
        description: [
          {
            title: 'Activity: Feed',
            body: [
              'Easily view all search activity as an organized list inside your Activity Feed.',
              'Same searches are combined to allow you to review only the unique search activity.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/user_profile/Activity_feed.jpg',
        footer: '',
        action: {
          text: 'Access your RIA Profile',
          url: 'https://ria.vivpro.ai/account'
        }
      },
      {
        description: [
          {
            title: 'Activity: Journey',
            body: [
              'The Activity Journey tab groups Quick, Advanced, and ARIA searches based on a common search term so you can easily recreate/review your journey and thought process.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/user_profile/Activity_feed_journey.jpg',
        footer: '',
        action: {
          text: 'Access your RIA Profile',
          url: 'https://ria.vivpro.ai/account'
        }
      },
      {
        description: [
          {
            title: 'Favorites',
            body: [
              'Add your frequently visited Applications and Quick, Advanced, and ARIA searches to your Profile Favorites for quick and convenient reference.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/user_profile/favorites.jpg',
        footer: '',
        action: {
          text: 'Access your RIA Profile',
          url: 'https://ria.vivpro.ai/account'
        }
      },
      {
        description: [
          {
            title: 'Projects',
            body: [
              'Effortlessly organize queries and applications into Projects so you can manage your data and workflows most effectively.'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/user_profile/projects.jpg',
        footer: '',
        action: {
          text: 'Access your RIA Profile',
          url: 'https://ria.vivpro.ai/account'
        }
      }
    ]
  },
  {
    id: 22,
    header: 'Gain Superior Control over Search Results through Synonyms',
    subHeader: 'Narrow Down Search Results by Selecting Specific MeSH Synonyms',
    description: [
      {
        title: '',
        body: []
      }
    ],
    date: new Date('12/08/2022'),
    slides: [
      {
        description: [
          {
            title: '',
            body: [
              'RIA uses the Medical Subject Headings (MeSH) and other synonyms to ensure you receive the most comprehensive and accurate search results. Now you can select Synonyms to focus your search on specific applications.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/synonyms/synonyms_filtering.jpg',
        footer:
          '<span style="font-weight: bold; font-style: italic;">Did you know</span> that "Spasms, Infantile" is a MeSH term for "Epilepsy" and it contributes to 4 of the 151 applications returned when you search for "Epilepsy" in Quick Search? Try your search and narrow it down using MeSH terms of interest!',
        action: {
          text: 'Go to RIA',
          url: 'https://ria.vivpro.ai/core'
        }
      }
    ]
  },
  {
    id: 21,
    header: 'Clinical Trials now has access to ARIA and a more intuitive experience',
    subHeader:
      'Your access, experience, and interaction with Clinical Trials data are now fundamentally different on the RIA platform. The Vivpro team is excited to deliver this unparalleled experience for you.',
    description: [
      {
        title: '',
        body: []
      }
    ],
    date: new Date('09/15/2022'),
    slides: [
      {
        description: [
          {
            title: 'ARIA is trained on over 426,368 Clinical Trials',
            body: [
              'Enjoy a consistent ARIA experience across the RIA platform as we apply the power of ARIA to Clinical Trials.',
              'Try asking ARIA this question from Clinical Trials core to find trials in patients with prior anti-PD-1 therapy: <span style="font-weight: bold; font-style: italic;">are patients with prior anti-PD-1/PD-L1 therapy allowed?</span>'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/clinical_trials/ct_aria.jpg',
        footer: '',
        action: {
          text: 'Ask RIA',
          url: 'https://ria.vivpro.ai/aria/core/ct?term=are%20patients%20with%20prior%20anti-PD-1/PD-L1%20therapy%20allowed?&bucket=all'
        }
      },
      {
        description: [
          {
            title: 'Enhancements to Clinical Trial List View',
            body: [
              'Interacting with, and consuming Clinical Trials data is easier than ever in the upgraded List view. Sort and filter the results to get a hyper-focused list of Clinical Trials which can be exported into a CSV format for additional post-processing.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/clinical_trials/ct_list.jpg',
        footer: ''
        // action: {
        //   text: '',
        //   url: ''
        // }
      },
      {
        description: [
          {
            title: 'Introducing Grid View to Clinical Trials',
            body: [
              'The Clinical Trials module is now more intuitive and user-friendly than ever with the introduction of the Vivpro-standard Grid View. Each Clinical Trial is highlighted with key information for quick consumption and analysis.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/clinical_trials/ct_grid.jpg',
        footer: ''
        // action: {
        //   text: '',
        //   url: ''
        // }
      },
      {
        description: [
          {
            title: 'Business Intelligence',
            body: [
              'The Clinical Trials Business Intelligence tab provides a quick view into key statistics like the number of trials by year of completion, by phase, and by recruitment status.'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/clinical_trials/ct_bi.jpg',
        footer: '',
        action: {
          text: 'Clinical Trials for Melanoma',
          url: 'https://ria.vivpro.ai/search/label/core/indications_and_usage/melanoma/ct'
        }
      }
    ]
  },
  {
    id: 20,
    header: 'Synonyms Transparency to Search Results',
    subHeader: '',
    description: [
      {
        title: '',
        body: ['']
      }
    ],
    date: new Date('08/26/2022'),
    slides: [
      {
        description: [
          {
            title: 'Gain additional insight into search results through synonyms',
            body: [
              'When entering a search term into RIA, it uses synonyms (MeSH terms, brand names from FDA and EMA) to ensure the highest coverage for a search. RIA now provides clarity by displaying a number next to the synonyms to show how many applications were matched in the Quick or Advanced Search results!',
              'Try searching for <span style="font-weight: bold";>“germinoblastic sarcomas”</span> in Quick Search and click on Synonyms. <span style="font-weight: bold";>“germinoblastic sarcomas”</span> does not have a green bubble next to it, meaning it is not found in ANY indication and did not directly contribute to any results. However, because it is a direct synonym for <span style="font-weight: bold";>“Lymphoma”</span>, <span style="font-weight: bold";>“germinoblastic sarcomas”</span> will return all approved Lymphoma applications.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/synonyms/synonyms_count.jpg',
        footer:
          'Did you know that the term "Hodgkin Disease" contributed to 27 of the 111 approved applications for "Lymphoma"?',
        action: {
          text: 'Search Germinoblastic Sarcomas',
          url: 'https://ria.vivpro.ai/search/label/core/indications_and_usage/germinoblastic%20sarcomas/US'
        }
      }
    ]
  },
  {
    id: 19,
    header: 'More Power to RIA by Filtering for Monoclonal Antibodies',
    subHeader: 'Easily filter applications and ARIA results for monoclonal antibodies (mAbs)',
    description: [
      {
        title: '',
        body: ['']
      }
    ],
    date: new Date('08/04/2022'),
    slides: [
      {
        description: [
          {
            title: 'Filter Approved Applications for Monoclonal Antibodies',
            body: [
              'Out of 102 approved Lymphoma applications for US FDA Drugs & Biologics, 15 applications are mAbs.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/cards_filters/mAbs_cards.jpg',
        footer: '',
        action: {
          text: 'Search Lymphoma',
          url: 'https://ria.vivpro.ai/search/label/core/indications_and_usage/lymphoma/US'
        }
      },
      {
        description: [
          {
            title: 'Filter ARIA results for Monoclonal Antibodies',
            body: [
              'ARIA is trained on all monoclonal antibodies.',
              'Search ARIA for "was single arm study used for approval?" and filter for monoclonal antibodies to see the results.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/cards_filters/mAbs_ARIA.jpg',
        footer: '',
        action: {
          text: 'Search ARIA',
          url: 'https://ria.vivpro.ai/aria/core/us?term=was%20single%20arm%20study%20used%20for%20approval?&bucket=all'
        }
      }
    ]
  },
  {
    id: 18,
    header: 'Introducing NOT to Advanced Search',
    subHeader:
      'Previously you could use AND and OR with Advanced Search, but now you can limit the search to exclude certain words or phrases.',
    description: [
      {
        title: '',
        body: [
          'Check out our latest addition to include NOT in your Advanced Search to hone in on an even more specific subset of approved applications.'
        ]
      }
    ],
    date: new Date('07/29/2022'),
    slides: [
      {
        description: [
          {
            title: 'Select NOT to remove a specific word or phrase from Advanced Search results',
            body: [
              'In Advanced Search, search for Indication: Lymphoma and NOT Indication: Melanoma',
              'FDA has approved 100 products that have Lymphoma, or its synonyms, in the indications section. Three of these products\' indications also include Melanoma or its synonyms. By using "NOT melanoma", you will only see 97 lymphoma products which don’t include Melanoma or its MeSH synonyms!'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/advanced_search/advanced_search_NOT.jpg',
        footer: '',
        action: {
          text: 'RIA Home',
          url: 'https://ria.vivpro.ai/core'
        }
      }
    ]
  },
  {
    id: 17,
    header: 'Access 2,169 HTA NICE Documents on RIA',
    subHeader: '',
    description: [
      {
        title: 'What is HTA?',
        body: [
          'Health technology assessment (HTA) is a systematic and multidisciplinary evaluation of the properties of health technologies and interventions covering both their direct and indirect consequences. It is a multidisciplinary process that aims to determine the value of a health technology and to inform guidance on how these technologies can be used in health systems around the world. - <a target="_blank" href="https://www.who.int/health-topics/health-technology-assessment#tab=tab_1">source</a>'
        ]
      },
      {
        title: 'What is NICE?',
        body: [
          'HTA NICE is a UK organization who\'s role is to <ol><li>produce evidence-based guidance and advice for health, public health and social care practitioners.</li> <li>develop quality standards and performance metrics for those providing and commissioning health, public health and social care services.</li> <li>providing a range of information services for commissioners, practitioners and managers across health and social care. - <a target="_blank" href="https://www.nice.org.uk/about/what-we-do">source</a></li></ol>'
        ]
      }
    ],
    date: new Date('07/24/2022'),
    slides: [
      {
        description: [
          {
            title: 'Gain Quick Insights Into HTA Documents',
            body: [
              "RIA's new HTA module now contains NICE documents with more (all HTA countries - IQWiG, TGA, etc.) to come soon!"
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/hta/nice/hta_nice_insights.jpg',
        footer: 'Did you know that 20 NICE Guidances were approved in the last 30 days?',
        action: {
          text: 'HTA NICE Insights',
          url: 'https://ria.vivpro.ai/htaDocuments'
        }
      },
      {
        description: [
          {
            title: 'Get Direct Access to All Available NICE Guidance Documents',
            body: [
              'Get the information you need from HTA NICE Guidance Documents and download the documents for convenient post-processing.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/hta/nice/hta_nice_documents.jpg',
        footer: '',
        action: {
          text: 'HTA NICE Documents',
          url: 'https://ria.vivpro.ai/htaDocuments'
        }
      },
      {
        description: [
          {
            title: 'Effectively Search All Available NICE Guidances with ARIA',
            body: [
              'Have a question about NICE Guidances? Ask ARIA and  compare search results with the NICE website! Witness the power of ARIA.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/hta/nice/hta_nice_aria.jpg',
        footer: 'Did you know that ARIA is trained on all 2,169 NICE Guidance documents?',
        action: {
          text: 'HTA NICE ARIA Search',
          url: 'https://ria.vivpro.ai/aria/htaDocuments/uk?term=What%20are%20treatment%20pathways%20for%20RCC?&bucket=all'
        }
      }
    ]
  },
  {
    id: 16,
    header: 'RIA Now Identifies Applications Which Used RTOR Pilot Program',
    subHeader: '',
    description: [
      {
        title: 'What is RTOR?',
        body: [
          'RTOR stands for Real-Time Oncology Review and according to FDA, RTOR "aims to provide a more efficient review process to ensure that safe and effective treatments are available to patients as early as possible..." - (<a href="https://www.fda.gov/about-fda/oncology-center-excellence/real-time-oncology-review#:~:text=The%20Oncology%20Center%20of%20Excellence,iterative%20communication%20with%20the%20applicant." target=_blank>source</a>)'
        ]
      }
    ],
    date: new Date('07/21/2022'),
    slides: [
      {
        description: [
          {
            title: 'View Applications that used RTOR Program',
            body: [
              'RTOR status can be viewed in two places: Extended Application view and Regulatory 360 View'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/rtor/rtor_combined.png',
        footer: ''
        // action: {
        //   text: '',
        //   url: ''
        // }
      },
      {
        description: [
          {
            title: 'Narrow down applications that used the RTOR track',
            body: [
              'Search for Melanoma and filter for RTOR to see the one approved product that used RTOR pathway.'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/rtor/rtor_melanoma.jpg',
        footer: '',
        action: {
          text: 'Search Melanoma',
          url: 'https://ria.vivpro.ai/search/label/core/indications_and_usage/melanoma/US'
        }
      }
    ]
  },
  {
    id: 15,
    header: 'Introducing How To Videos for RIA!',
    subHeader: 'Help Video • ARIA Case Studies Walkthrough',
    description: [
      {
        title: 'You asked. We delivered.',
        body: [
          'One of our most user-requested features is a series of How To videos! We are excited to kick off a series of step-by-step instructions on how to use the RIA platform.'
        ]
      }
    ],
    date: new Date('07/11/2022'),
    slides: [
      {
        description: [
          {
            title: 'Video 1: How to access Help Resources within the RIA platform',
            body: [
              'Check out this video if you are a first-time user or just need a refresher on the basics of using the RIA platform.'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/Thumbails_helpvideo.png',
        footer: '',
        action: {
          text: 'Help Resources Video',
          url: 'https://ria.vivpro.ai/help/videos/1'
        }
      },
      {
        description: [
          {
            title: 'Video 2: How to Search ARIA from the Core module',
            body: ['Learn with a case study: Was approval based on a single arm trial?']
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/Thumbails2_ARIAVideo1.png',
        footer: '',
        action: {
          text: 'Access ARIA Training Video',
          url: 'https://ria.vivpro.ai/help/videos/2'
        }
      },
      {
        description: [
          {
            title: 'Video 4: How to Search ARIA within a Group of Applications',
            body: [
              'Follow along with this case study: Was approval based on a single arm trial for Lymphoma?'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/Thumbails2_ARIAVideo2.png',
        footer: '',
        action: {
          text: 'Access ARIA Training Video',
          url: 'https://ria.vivpro.ai/help/videos/3'
        }
      },
      {
        description: [
          {
            title: 'Video 4: How to Search ARIA for a Single Application',
            body: ['Try this case study: Was approval based on a single arm trial for VELCADE?']
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/Thumbails2_ARIAVideo3.png',
        footer: '',
        action: {
          text: 'Access ARIA Training Video',
          url: 'https://ria.vivpro.ai/help/videos/4'
        }
      }
    ]
  },
  {
    id: 14,
    header: 'Transparency into ARIA Search Results & Pediatric Documents',
    subHeader: '',
    description: [
      {
        title: "Vivpro's Commitment to Excellence",
        body: [
          'Our mission is to bring more power to you, and one way we accomplish this is to provide you with the best user experience possible on the RIA platform.',
          'Based on your requests check out our recent updates:'
        ]
      }
    ],
    date: new Date('07/07/2022'),
    slides: [
      {
        description: [
          {
            title: 'Increase Visibility into ARIA Search Space',
            body: [
              'The total number of results returned from ARIA search query shows the size of the search space to understand the specificity of your query.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/aria_results_transparency_ped_docs/aria_showing_x_of_x_results-mag.png',
        footer:
          'Is your output 100 out of 200 results (a narrow or specific query), or 100 out of 70,000 results (a broad or non-specific query? If the search space is too large, you may want to adjust your query to increase specificity.',
        action: {
          text: 'Search ARIA',
          url: 'https://ria.vivpro.ai/aria/core/us?term=what%20was%20the%20primary%20endpoint?&bucket=all'
        }
      },
      {
        description: [
          {
            title: 'Gain Access to Pediatric Documents',
            body: [
              'Get the information you need from Pediatric Written Requests and download the documents for convenient post-processing.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/aria_results_transparency_ped_docs/pediatric_documents_column.jpg',
        footer: '',
        action: {
          text: 'Go to Pediatrics Module',
          url: 'https://ria.vivpro.ai/pediatrics'
        }
      }
    ]
  },
  {
    id: 13,
    header: 'Updates to the Application Grid and List View Design',
    subHeader:
      'The Vivpro team is excited to share continued enhancements to the R&D Intelligence Assistant (RIA) search results and the user experience.',
    description: [
      {
        title: '',
        body: ['']
      }
    ],
    date: new Date('07/06/2022'),
    slides: [
      {
        description: [
          {
            title: 'Sleek new design',
            body: ['Improved Grid view in line with UI/UX standards set by RIA.']
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/new_cards_design/cards_page_new_view_final.jpg',
        footer: '',
        action: {
          text: 'RIA Home',
          url: 'https://ria.vivpro.ai/core'
        }
      },
      {
        description: [
          {
            title: 'Toggle between Grid and List View',
            body: [
              'Easily interact with applications on the List view to add/hide columns, to select preferred view for the table, and to export applications into a CSV format for efficient post-processing.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/new_cards_design/cards_list_new_view_final.jpg',
        footer: '',
        action: {
          text: 'RIA Home',
          url: 'https://ria.vivpro.ai/core'
        }
      },
      {
        description: [
          {
            title: 'Improved Filters',
            body: ['Better experience and enhanced visibility into your selected filters.']
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/new_cards_design/cards_page_new_filter_view.jpg',
        footer: '',
        action: {
          text: 'RIA Home',
          url: 'https://ria.vivpro.ai/core'
        }
      }
    ]
  },
  {
    id: 12,
    header: 'Now Access 2,003 Product Specific Guidances',
    subHeader: '',
    description: [
      {
        title: 'What are PSGs?',
        body: [
          'FDA publishes these product-specific guidances to foster drug product development, and ANDA submission and approval, ultimately providing increased access to safe, affordable generic drugs.'
        ]
      }
    ],
    date: new Date('06/29/2022'),
    slides: [
      {
        description: [
          {
            title: 'Access PSGs With RIA',
            body: ['RIA has 2,003 PSGs to the Guidance module for a total of 4,744 Guidances!']
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/psg/psg_donut_graph.jpg',
        footer: '',
        action: {
          text: 'Access Guidances',
          url: 'https://ria.vivpro.ai/guidanceDocuments'
        }
      },
      {
        description: [
          {
            title: 'Process PSGs With the Power of ARIA',
            body: [
              'ARIA is now trained on Product Specific Guidances!',
              'Try asking ARIA for <span style="font-style: italic;">"what are the requirements for in vivo bioequivalence for palbociclib?"</span> from the Guidance module.'
            ]
          }
        ],
        image: 'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/psg/psg_aria_open_pdf.jpg',
        footer: '',
        action: {
          text: 'Ask RIA (ARIA) About PSGs',
          url: 'https://ria.vivpro.ai/aria/guidanceDocuments'
        }
      }
    ]
  },
  {
    id: 11,
    header: 'Introducing Cross Region Compare (XCompare) Module',
    subHeader: "Compare EMA and FDA label sections with one of Vivpro's most requested features",
    description: [
      {
        title: 'Why XCompare?',
        body: [
          'FDA US Prescribing Information (USPI) and EMA Summary of Product Characteristics (SmPC) are fundamentally different documents and are difficult to compare. Users are mostly interested in comparing relevant sections across the two regions.'
        ]
      },
      {
        title: 'What is the Value of XCompare?',
        body: [
          'Now RIA allows comparing specific sections of labels to save your time with manual comparison and increase think time to accelerate strategy.'
        ]
      },
      {
        title:
          'How different are Abrocitinib indications between FDA and EMA labels? Find out with few clicks and download a clean comparison',
        body: []
      }
    ],
    date: new Date('06/16/2022'),
    slides: [
      {
        description: [
          {
            title: 'How to use XCompare Module',
            body: [
              'The XCompare module gives you easy access to compare the latest FDA and EMA label by sections!'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/xcompare/xcompare_main.jpg',
        footer: '',
        action: {
          text: 'XCompare',
          url: 'https://ria.vivpro.ai/comparison/us_vs_eu'
        }
      },
      {
        description: [
          {
            title: 'Select Applications',
            body: [
              'Try searching <span style="font-weight: bold;">Abrocitinib</span> in <span style="font-style: italic;">Category: Active Ingredient</span> in the Quick Search to see the approved products by FDA and EMA.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/xcompare/xcompare_Abrocitinib.jpg',
        footer: '',
        action: {
          text: 'Search Abrocitinib',
          url: 'https://ria.vivpro.ai/comparison/us_vs_eu/substance_name/Abrocitinib'
        }
      },
      {
        description: [
          {
            title: 'Select Label and Section',
            body: [
              'Select the desired labels, then select  sections you would like to review, and click Compare!'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/xcompare/xcompare_section_selection.jpg',
        footer: '',
        action: {
          text: 'Try it for yourself!',
          url: 'https://ria.vivpro.ai/comparison/us_vs_eu'
        }
      },
      {
        description: [
          {
            title: 'Label Comparison',
            body: [
              "Easily compare the differences between two label sections in RIA's built-in Label Comparison tool, or download the documents in multiple formats at your convenience for further analysis."
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/xcompare/xcompare_label_compare.jpg',
        footer: '',
        action: {
          text: 'Try it for yourself!',
          url: 'https://ria.vivpro.ai/comparison/us_vs_eu'
        }
      }
    ]
  },
  {
    id: 10,
    header: 'Introducing EUAs to RIA!',
    subHeader: 'Emergency Use Authorizations have exploded since COVID. Now available on RIA.',
    description: [],
    date: new Date('05/23/2022'),
    slides: [
      {
        description: [
          {
            title:
              '<span style="font-style: italic;">Did you know that there were 8 trials reported on clinicaltrials.gov that were included in FDA Reviews for MEKTOVI?</span>',
            body: [
              'RIA now has access to 19 EUAs for US FDA Drugs & Biologics and 487 EUA devices!'
            ]
          },
          {
            title: 'Searching CDER, CBER, & EUAs',
            body: [
              'EUAs are now seamlessly integrated with CDER and CBER applications, allowing you to easily search all applications together.',
              'Quickly identify EUAs by the <span style="color:orange; font-weight: bold;">ORANGE</span> text of the application card. You can even filter the Application Type by opening the Filters tab and selecting either NDA, BLA, or EUA.',
              'Try searching for <span style="font-weight: bold; font-style: italic;">COVID-19</span> in the quick search and see the results!'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/eua_launch/eua_cards_view.jpg',
        footer: 'Reg360 and extended card view for EUA coming soon!',
        action: {
          text: 'Search COVID-19',
          url: 'https://ria.vivpro.ai/search/label/core/indications_and_usage/covid%2019/US'
        }
      },
      {
        description: [
          {
            title: '<span style="font-style: italic;">Did you know?</span>',
            body: [
              '<span style="font-style: italic;">ARIA is trained on 2,733 EUA documents!</span>'
            ]
          },
          {
            title: 'ARIA for EUA',
            body: [
              'Use the power of ARIA to find answers to your EUA questions.',
              'After searching for <span style="font-weight: bold; font-style: italic;">COVID-19</span> in quick search, ask ARIA:<span style="font-style: italic;">"what were the safety issues?"</span>'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/eua_launch/eua_covid_aria.jpg',
        footer:
          'RIA employs synonyms and MeSH terms so that when you search for COVID 19, RIA also searches for COVID-19, covid-19 pandemics, covid 19 virus infection, and 24 other variations!'
        // action: { text: '', url: '' }
      }
    ]
  },
  {
    id: 9,
    header:
      'Hover to see which part of the indication was approved for Accelerated Approval, Breakthrough Designation, and Fast Track status',
    subHeader:
      'RIA provides easy access to approved AA, BT, and FT Indications on Application and 360˚ view',
    description: [],
    date: new Date('05/09/2022'),
    slides: [
      {
        description: [
          {
            title: 'Application View',
            body: [
              'If an application receives Accelerated Approval, Breakthrough Designation, or Fast Track status at the time of original approval, then the (AA), (BT), or (FT) icons will turn green for the application. Hovering over the highlighted icons will display the Indication that supported the respective status designation.',
              'Try searching for <span style="font-weight: bold; font-style: italic">Renal Cell Carcinoma</span> in the quick search and hover over <span style="color:#6868f9; font-weight: bold;">BAVENCIO</span> <span style="color:#4bb368; font-weight: bold;">(AA)</span>, <span style="color:#4bb368; font-weight: bold;">(BT)</span>, or <span style="color:#4bb368; font-weight: bold;">(FT)</span>!'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/indications_highlighting/indications_highlighted_cards.jpg',
        footer: '',
        action: {
          text: 'Search RCC',
          url: 'https://ria.vivpro.ai/search/label/core/indications_and_usage/Renal%20Cell%20Carcinoma/US'
        }
      },
      {
        description: [
          {
            title: '360˚ View',
            body: [
              'For any submission (Original or Supplement) if AA, BT, FT status was granted, hovering over the green highlighted icon will display the Indication that supported the respective status designation.',
              'From your previous search, click on the <span style="font-style: italic;">360˚</span> icon for <span style="color:#6868f9; font-weight: bold;">BAVENCIO</span>, and inside the <span style="font-style: italic;">360˚</span> view, hover over <span style="color:#4bb368; font-weight: bold;">(BT)</span> on submission <span style="color:#4bb368; font-weight: bold;">6</span> to see that designation\'s approved Indication.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/indications_highlighting/indications_highlighted_360.jpg',
        footer: '',
        action: {
          text: '360˚ View',
          url: 'https://ria.vivpro.ai/regulatory360/core/761049/us?type=BLA&category=indications_and_usage'
        }
      }
    ]
  },
  {
    id: 8,
    header: 'Use logical operators (booleans AND, OR, and NOT) for a more flexible ARIA search!',
    subHeader:
      'You can now use AND, OR, and NOT in your ARIA searches to enhance your search results',
    description: [],
    date: new Date('05/05/2022'),
    slides: [
      {
        description: [
          {
            title: 'AND, OR, and NOT Operators',
            body: [
              'You can combine or exclude words using the Boolean operators AND, OR, and NOT (booleans must be in ALL CAPS!).',
              'For example, searching <span style="font-style: italic; font-weight: bold;">(fetal toxicity AND boxed warning) NOT REMS</span> will return results where fetal toxicity <span style="font-style: italic;">and</span> boxed warning are BOTH present in the results, but REMS is not.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/ARIA_boolean_searching/aria_AND_NOT_query_magnified.png',
        footer:
          'Use quotes around a term or phrase to ensure it is returned in ARIA results. For example, if you want exact matches for "fetal toxicity" and "boxed warning" you can add quotes around the phrase and ensure they get returned.',
        action: {
          text: 'Try the search with quotes!',
          url: 'https://ria.vivpro.ai/aria/core/us?term=(%E2%80%9Cfetal%20toxicity%E2%80%9D%20AND%20%E2%80%9Cboxed%20warning%E2%80%9D)%20NOT%20REMS&bucket=all'
        }
      },
      {
        description: [
          {
            title: 'Other Example Queries',
            body: ['(PRO OR instrument) NOT oncology']
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/ARIA_boolean_searching/aria_boolean_parentheses.jpg',
        footer:
          'Use parentheses to query nested words in order of precedence. The parentheses indicate to ARIA how the query should be read.',
        action: {
          text: 'Search this question',
          url: 'https://ria.vivpro.ai/aria/core/us?term=(PRO%20OR%20instrument)%20NOT%20oncology&bucket=all'
        }
      },
      {
        description: [
          {
            title: 'Other Example Queries',
            body: [
              'Was a "Bayesian" design used for a ((“phase 2” OR “phase 3") NOT “phase 1”) trial?'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/ARIA_boolean_searching/aria_boolean_quotes.jpg',
        footer:
          'Use parentheses to query nested words in order of precedence. The parentheses indicate to ARIA how the query should be read.',
        action: {
          text: 'Search this question',
          url: 'https://ria.vivpro.ai/aria/core/us?term=Was%20a%20%22Bayesian%22%20design%20used%20for%20a%20((%E2%80%9Cphase%202%E2%80%9D%20OR%20%E2%80%9Cphase%203%22)%20NOT%20%E2%80%9Cphase%201%E2%80%9D)%20trial?&bucket=all'
        }
      }
    ]
  },
  {
    id: 7,
    header: 'CBER Applications at your Fingertips!',
    subHeader:
      'Center for Biologics Evaluation and Research applications integrated into the RIA platform',
    description: [],
    date: new Date('05/02/2022'),
    slides: [
      {
        description: [
          {
            title: 'Did you know?',
            body: [
              'RIA now has access to 311 CBER applications, 882 CBER products, and 5, 436 SBAs!'
            ]
          },
          {
            title: 'Searching CDER & CBER',
            body: [
              'CDER and CBER now coexist on RIA allowing you to easily search 5,852 CDER and 311 CBER drugs & biologics.',
              'Easily identify CBER drugs by the highlighted <span style="color:#6868f9; font-weight: bold;">(CBER)</span> in the top right corner above the card. You can even filter the Center by opening the Filters tab and selecting either CBER or CDER.',
              'Try searching for <span style="font-weight: bold; font-style: italic">hepatitis</span> in the quick search and see the results!'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/cber_launch/cber_cards_page.jpg',
        footer:
          '<span style="font-style: italic">Reg360 and extended card view for CBER coming soon!</span>',
        action: {
          text: 'Search Hepatitis',
          url: 'https://ria.vivpro.ai/search/label/core/indications_and_usage/Hepatitis/US'
        }
      },
      {
        description: [
          {
            title: 'Did you know?',
            body: ['ARIA is trained on 5,436 CBER documents!']
          },
          {
            title: 'ARIA for CBER',
            body: [
              'Use the power of ARIA to find answers to your CBER questions.',
              'After searching for gene therapy in quick search, ask ARIA: <span style="font-style: italic;">"was biodistribution evaluation of onasemnogene performed?"</span>'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/cber_launch/cber_aria_gene_therapy.jpg',
        footer: 'Approval dates and submissions for some documents may be missing.'
        // action: { text: 'Ask ARIA', url: 'https://ria.vivpro.ai/aria/core/us?term=was%20biodistribution%20evaluation%20of%20onasemnogene%20performed?&bucket=all&resultsPage=true&searchTerm=gene%20therapy' }
      }
    ]
  },
  {
    id: 6,
    header: 'Avoid spelling errors when searching in ARIA',
    subHeader: 'Are you frustrated with silly spelling mistakes? ARIA now has you covered!',
    description: [],
    date: new Date('04/26/2022'),
    slides: [
      {
        description: [
          {
            title: 'Did you mean?',
            body: [
              'ARIA analyzes each word in your search query and checks for spelling accuracy to provide <span style="font-weight: bold;">"Did you mean"</span> suggestions to help you ask better questions.',
              'ARIA will not only provide suggestions for usual spelling mistakes but also for medical jargon - click the link below to see what happens when you misspell "lymphoma" as "lymhoma".'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/ARIA_did_you_mean_spellchecker/aria_spellchecker_did_you_mean.jpg',
        footer:
          "Also, note that the results displayed are the best answers to a misspelled query but ARIA's natural language and machine learning capability has the ability to detect the possibility of a better question.",
        action: {
          text: 'Search "lymhoma" in ARIA',
          url: 'https://ria.vivpro.ai/aria/core/us?term=lymhoma&bucket=all'
        }
      }
    ]
  },
  {
    id: 5,
    header: 'Adding Regulatory 360° navigation to ARIA',
    subHeader: 'Connect 360° view to ARIA Results',
    description: [],
    date: new Date('04/21/2022'),
    slides: [
      {
        description: [
          {
            title: 'ARIA',
            body: [
              'Easily access the 360° view of each drug directly from ARIA! Try searching <span style="font-style: italic;">"what are the oncology drugs approved based on single-arm studies?"</span>'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/ARIA_360_button/360_ARIA.jpg',
        footer: '',
        action: {
          text: 'Search ARIA',
          url: 'https://ria.vivpro.ai/aria/core/us?term=what%20are%20the%20oncology%20drugs%20approved%20based%20on%20single-arm%20trials?&bucket=all'
        }
      },
      {
        description: [
          {
            title: '360° Module',
            body: [
              'Dive deep into a drug\'s Approval History, submission documents, Approval Tracks, compare its labels, and much, much more! Try it for <span style="font-style: italic;">POMALYST</span>'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/ARIA_360_button/360_ARIA_360_view.jpg',
        footer: '',
        action: {
          text: 'View 360°',
          url: 'https://ria.vivpro.ai/regulatory360/core/204026/us?category=indications_and_usage&type=NDA'
        }
      }
    ]
  },
  {
    id: 4,
    header: 'See EU Commissions label history!',
    subHeader:
      "RIA has tapped into the EU Commissions' data source to provide label history for all EMA-approved products!",
    description: [],
    date: new Date('04/13/2022'),
    slides: [
      {
        description: [
          {
            title: 'Did you know?',
            body: [
              'You have access to 16,372 label versions for 1,619 unique EMA-approved products'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/eu_commissions/eu_commissions_main_page.jpg',
        footer: '',
        action: {
          text: 'Learn more',
          url: 'https://ria.vivpro.ai/regulatory360/core/004580/eu?category=indications_and_usage'
        }
      },
      {
        description: [
          {
            title: 'Label Comparison',
            body: [
              'Easily compare any two versions of labels within a product by clicking on <span style="font-weight: bold;">Compare Labels</span> inside the Reg360 module!'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/eu_commissions/eu_commissions_label_comparison.jpg',
        footer: '',
        action: {
          text: 'Try it now!',
          url: 'https://ria.vivpro.ai/regulatory360/core/004580/eu?category=indications_and_usage'
        }
      }
    ]
  },
  {
    id: 3,
    header: 'Advanced Search is now more transparent and powerful than ever',
    subHeader:
      'Utilize Advanced Search within US FDA, EMA, Clinical Trials, and US Devices modules to extract more details from your searches.',
    description: [],
    date: new Date('04/04/2022'),
    slides: [
      {
        description: [
          {
            title: 'Search with or without synonyms',
            body: [
              'You may want to narrow your search using a combination of drug names, indications, active ingredients, routes, formulations, or sponsors. Advanced Search gives you the ability to enter multiple conditions and even choose whether or not to search using MeSH terms!',
              'Head to the Core Module and enter your first Advanced Search!'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/advanced_search/adv_search_synonyms.jpg',
        footer: '',
        action: {
          text: 'Core Module',
          url: 'https://ria.vivpro.ai/core'
        }
      },
      {
        description: [
          {
            title:
              'Advanced Search queries are displayed not only as you type but when results are shown',
            body: [
              'If the search scope requires going beyond a quick search, you can now use a combination of drug names, indications, active ingredients, routes, formulations, or sponsors. Advanced Search gives you the ability to enter multiple conditions and even choose whether or not to search using MeSH terms!',
              'Try out Advanced Search now in the Core Module!'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/advanced_search/advanced_search_query.jpg',
        footer: '',
        action: {
          text: 'Core Module',
          url: 'https://ria.vivpro.ai/core'
        }
      }
    ]
  },
  {
    id: 2,
    header: 'Biologics Module: Summarize Business Critical Information Faster',
    subHeader:
      'Key Business Intelligence Information is easily available for all search results and additionally, Immunogenicity summaries are available within the Biologics Module!',
    description: [],
    date: new Date('03/15/2022'),
    slides: [
      {
        description: [
          {
            title:
              'Retrieve curated intelligence with two new tabs - Business Intelligence (for all results) and Immunogenicity (for biologics)!',
            body: [
              'Navigate to the Biologics module and search for "Melanoma or perform a search of your own!'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/BI_and_immunogenicity/bioligics_cards_page.jpg',
        footer: '',
        action: {
          text: 'Biologics Module',
          url: 'https://ria.vivpro.ai/search/label/biologics/indications_and_usage/melanoma/US'
        }
      },
      {
        description: [
          {
            title: 'Immunogenicity data at your fingertips',
            body: [
              'Click on the “Immunogenicity” tab to see immunogenicity information curated from the latest prescribing information and add ready-made graphs to your reports. Try adding Immunogenicity “ADA Incidence" and other key charts to your report.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/BI_and_immunogenicity/immunogenicity_add_to_report.jpg',
        footer: ''
        // action: {
        //   text: '',
        //   url: ''
        // }
      },
      {
        description: [
          {
            title: 'Business Intelligence to enhance your business and scientific needs',
            body: [
              'The Business Intelligence tab summarizes the landscape based on the search results with graphs for the number of approvals by year, approval time in days as well as Fast Track,  Breakthrough, Accelerated Approval, and Orphan Designations status at first approval. Add any of these graphs to your report and download to as a PDF to begin your strategic planning!'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/BI_and_immunogenicity/business_intelligence_add_to_report.jpg',
        footer: ''
        // action: {
        //   text: '',
        //   url: ''
        // }
      }
    ]
  },
  {
    id: 1,
    header: 'ARIA PDF highlighter: Information to Strategy Is Even Faster',
    subHeader:
      'ARIA not only directs you to the exact page from where the answer was derived but now highlights the full response text - minimizing your time to find and review the answer!',
    description: [],
    date: new Date('03/11/2022'),
    slides: [
      {
        description: [
          {
            title: 'ARIA highlights in GREEN the full answer text',
            body: [
              'Give it a try! Open ARIA within the Core module and search for: "what was the primary endpoint for sofosbuvir?" and click on any result to view the full answer text highlighted in green.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/pdf_highlighter/pdf_highlighter_green.jpg',
        footer: '',
        action: {
          text: 'Try it out!',
          url: 'https://ria.vivpro.ai/aria/core/us?term=what%20was%20the%20primary%20endpoint%20for%20sofosbuvir?&bucket=all'
        }
      },
      {
        description: [
          {
            title: 'ARIA highlights in YELLOW the keywords within the answer text',
            body: [
              'Give it a try! After searching for "what was the primary endpoint for sofosbuvir?" notice the keywords highlighted in yellow throughout the page.'
            ]
          }
        ],
        image:
          'https://vivpro-site.s3.amazonaws.com/Help_Page_Whats_New/pdf_highlighter/pdf_highlighter_yellow.jpg',
        footer: ''
        // action: {
        //   text: '',
        //   url: ''
        // }
      }
    ]
  }
];

export const VIDEO_TUTORIALS = [
  {
    id: 'how-to-navigate-home-page',
    thumbnail: 'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/How to Navigate Home Page.png',
    title: 'How to Navigate Home Page',
    secondaryTitle: '',
    date: new Date('02/06/2024'),
    tags: ['HomePage', 'ExploreMoreFeatures', 'HowTo', 'Modules', 'Features'],
    videoName: 'How to Navigate Home Page.mp4',
    content:
      'Dive into the heart of RIA with our first tutorial video that introduces you to the Home Page. Experience a seamless search across a vast database of regulatory documents, and learn how to tailor your queries with advanced search options and region selection. This video will also walk you through personalizing your workflow and collaborating with your team productively.',
    shortDescription:
      'Dive into the heart of RIA with our first tutorial video that introduces you to the Home Page. Experience a seamless search across a vast database of regulatory documents, and learn how to tailor your queries with advanced search options and region selection. This video will also walk you through personalizing your workflow and collaborating with your team productively.',
    howToTitle: 'How to navigate',
    pageTitle: 'Home Page',
    videoLength: '02:55'
  },
  {
    id: 'how-to-navigate-documents-page',
    thumbnail:
      'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/How to Navigate Documents Page.png',
    title: 'How to Navigate Documents Page',
    secondaryTitle: '',
    date: new Date('02/06/2024'),
    tags: [
      'Documents',
      'RegulatoryDocuments',
      'GenerativeAI',
      'Explore',
      'PDF',
      'ChatRIA',
      'ChatBot',
      'Share',
      'Save'
    ],
    videoName: 'How to Navigate Documents Page.mp4',
    content:
      "Join us for a comprehensive exploration of the Documents Page within RIA, where we employ an engaging case study to demonstrate the platform's robust document search capabilities. This tutorial reveals how to sift through millions of documents to locate the most pertinent information for your pharmaceutical research queries. Learn how to leverage various features, utilize smart AI-driven interactions for deeper understanding, and apply precise filters to zero in on the documents that matter. Experience the efficiency of RIA in action and get a glimpse of the power behind RIA's intelligent document retrieval system, designed for the dedicated researcher seeking accuracy and depth in their work.",
    shortDescription:
      "Join us for a comprehensive exploration of the Documents Page within RIA, where we employ an engaging case study to demonstrate the platform's robust document search capabilities. This tutorial reveals how to sift through millions of documents to locate the most pertinent information for your pharmaceutical research queries. Learn how to leverage various features, utilize smart AI-driven interactions for deeper understanding, and apply precise filters to zero in on the documents that matter. Experience the efficiency of RIA in action and get a glimpse of the power behind RIA's intelligent document retrieval system, designed for the dedicated researcher seeking accuracy and depth in their work.",
    howToTitle: 'How to navigate',
    pageTitle: 'Documents Page',
    videoLength: '03:33'
  },
  {
    id: 'how-to-navigate-applications-page',
    thumbnail:
      'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/How to Navigate Applications Page.png',
    title: 'How to Navigate Applications Page',
    secondaryTitle: '',
    date: new Date('02/06/2024'),
    tags: [
      'Applications',
      'DrugApplications',
      'Entity',
      'Detection',
      'Visualize',
      'Synonyms',
      'AskRIA',
      'ChatRIA',
      'Label',
      'Create',
      'Report',
      'List',
      'Share',
      'Save'
    ],
    videoName: 'How to Navigate Applications Page.mp4',
    content:
      'In this detailed tutorial, we explore the Applications Page of RIA, utilizing practical case studies to illustrate how the system supports your research in drug development and regulatory submissions. Discover how to navigate through the intricacies of drug applications, from sorting and grouping entries to accessing detailed drug information with ease. This video will guide you through the nuances of examining drug approvals, understanding active ingredients, and managing application details.',
    shortDescription:
      'In this detailed tutorial, we explore the Applications Page of RIA, utilizing practical case studies to illustrate how the system supports your research in drug development and regulatory submissions. Discover how to navigate through the intricacies of drug applications, from sorting and grouping entries to accessing detailed drug information with ease. This video will guide you through the nuances of examining drug approvals, understanding active ingredients, and managing application details.',
    howToTitle: 'How to navigate',
    pageTitle: 'Applications Page',
    videoLength: '05:46'
  },
  {
    id: 'how-to-search-and-use-askria-for-single-application',
    thumbnail:
      'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/How to Search and use AskRIA for Single Application.png',
    title: 'How to Search and use AskRIA for Single Application',
    secondaryTitle: '',
    date: new Date('02/28/2024'),
    tags: [
      'RIATutorial',
      'SingleApplicationSearch',
      'RegulatoryData',
      'Vivpro',
      'ResearchEfficiency',
      'DataNavigation',
      '360',
      'AskRIA'
    ],
    videoName: 'How to Search and use AskRIA for Single Application.mp4',
    content: `Dive deep into the functionalities of RIA in our step-by-step tutorial designed to enhance your research processes. Learn how to conduct precise searches within a single application to support your research needs. Whether you're a seasoned professional or new to regulatory data, this tutorial will empower you to uncover specific details like never before. <br/><br/>
      In this video, you'll discover:
      <ul>
        <li>Utilizing the Ask RIA feature for documents related to a single application.</li>
        <li>Extracting exact information from a sea of data with ease.</li>
      </ul>
      Scenario Tackled: "Was a single-arm study used to support IRESSA's approval?" </br>
      Need additional support? Our team is here to help! Reach out directly through Collaborate for personalized assistance.`,
    shortDescription: `Dive deep into the functionalities of RIA in our step-by-step tutorial designed to enhance your research processes. Learn how to conduct precise searches within a single application to support your research needs. Whether you're a seasoned professional or new to regulatory data, this tutorial will empower you to uncover specific details like never before. <br>
    In this video, you'll discover:
    <ul>
      <li>Utilizing the Ask RIA feature for documents related to a single application.</li>
      <li>Extracting exact information from a sea of data with ease.</li>
    </ul>
    Scenario Tackled: "Was a single-arm study used to support IRESSA's approval?" </br>
    Need additional support? Our team is here to help! Reach out directly through Collaborate for personalized assistance`,
    howToTitle: 'How to search and use',
    pageTitle: 'AskRIA for Single Application',
    videoLength: '01:56'
  },
  {
    id: 'how-to-search-and-use-askria-for-multiple-application',
    thumbnail:
      'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/How to Search and Use AskRIA for Multiple Applications.png',
    title: 'How to Search and Use AskRIA for Multiple Applications',
    secondaryTitle: '',
    date: new Date('02/28/2024'),
    tags: [
      'RIATutorial',
      'MultipleApplicationSearch',
      'RegulatoryData',
      'Vivpro',
      'ResearchEfficiency',
      'DataNavigation',
      '360',
      'AskRIA',
      'Applications'
    ],
    videoName: 'How to Search and Use AskRIA for Multiple Applications.mp4',
    content: `Welcome to our tutorial on how to search and use AskRIA for Multiple Applications. In this session, we'll apply a practical case study to demonstrate how to effectively leverage the system's capabilities for your research needs. <br/><br/>
      In this video, you'll discover:
      <ul>
        <li>Employing AskRIA for an efficient andspecialized search within a subset of applications.</li>
        <li>Direct navigation to the necessary data points within vast document collections.</li>
        <li>Leveraging feedback mechanisms to enhance the performance of AskRIA.</li>
        <li>Downloading search results to Excel for further analysis and reporting.</li>
      </ul>
      Scenario Tackled: "Was approval for Lymphoma treatments based on a single-arm trial?" </br>
      Need additional support? Our team is here to help! Reach out directly through Collaborate for personalized assistance.`,
    shortDescription: `Welcome to our tutorial on how to search and use AskRIA for Multiple Applications. In this session, we'll apply a practical case study to demonstrate how to effectively leverage the system's capabilities for your research needs. <br>
    In this video, you'll discover:
    <ul>
      <li>Employing AskRIA for an efficient andspecialized search within a subset of applications.</li>
      <li>Direct navigation to the necessary data points within vast document collections.</li>
      <li>Leveraging feedback mechanisms to enhance the performance of AskRIA.</li>
      <li>Downloading search results to Excel for further analysis and reporting.</li>
    </ul>
    Scenario Tackled: "Was approval for Lymphoma treatments based on a single-arm trial?" </br>
    Need additional support? Our team is here to help! Reach out directly through Collaborate for personalized assistance`,
    howToTitle: 'How to search and use',
    pageTitle: 'AskRIA for Multiple Applications',
    videoLength: '02:24'
  },
  {
    id: 'how-to-search-using-booleans-and-logical-operators-1',
    thumbnail:
      'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/How to Search Using Booleans & Logical Operators-1.png',
    title: 'How to Search Using Booleans & Logical Operators-1',
    secondaryTitle: '',
    date: new Date('02/28/2024'),
    tags: [
      'RIATutorial',
      'BooleanSearch',
      'LogicalOperators',
      'ResearchSkills',
      'SearchEfficiency',
      'Part1',
      'BooleanBasics',
      'HomePage',
      'Documents'
    ],
    videoName: 'How to Search Using Booleans & Logical Operators-1.mp4',
    content: `Welcome to the first part of our educational series on mastering the art of searching with Booleans and Logical Operators in ARIA. Whether you're a seasoned professional or new to the platform, this video is your steppingstone to conducting more effective and efficient searches. <br/><br/>
      What this tutorial covers:
      <ul>
        <li>Understanding the basic principles of Boolean logic in search queries.</li>
        <li>Learning how to use AND, OR, and NOT to fine-tune your ARIA searches.</li>
        <li>Applying logical operators like QUOTES, PARENTHESIS, and the WILDCARD asterisk to refine search results.</li>
      </ul>
      Key Learning Outcomes:
      <ul>
        <li>Expand or narrow down your search results with precision.</li>
        <li>Craft search queries when uncertain of exact terms.</li>
        <li>"Force" specific terms to be included in your search results.</li>
      </ul>
      Resources: <a href="https://vivpro-site.s3.amazonaws.com/Help_Page_FAQ/Searching_with_Booleans_Reference_Sheet.pdf" target="_blank">Boolean Operators Guide</a> </br>
      Need additional support? Our team is here to help! Reach out directly through Collaborate for personalized assistance.`,
    shortDescription: `Welcome to the first part of our educational series on mastering the art of searching with Booleans and Logical Operators in ARIA. Whether you're a seasoned professional or new to the platform, this video is your steppingstone to conducting more effective and efficient searches. <br>
    What this tutorial covers:
    <ul>
      <li>Understanding the basic principles of Boolean logic in search queries.</li>
      <li>Learning how to use AND, OR, and NOT to fine-tune your ARIA searches.</li>
      <li>Applying logical operators like QUOTES, PARENTHESIS, and the WILDCARD asterisk to refine search results.</li>
    </ul>
    Key Learning Outcomes:
    <ul>
      <li>Expand or narrow down your search results with precision.</li>
      <li>Craft search queries when uncertain of exact terms.</li>
      <li>"Force" specific terms to be included in your search results.</li>
    </ul>
    Resources: <a href="https://vivpro-site.s3.amazonaws.com/Help_Page_FAQ/Searching_with_Booleans_Reference_Sheet.pdf" target="_blank">Boolean Operators Guide</a> </br>
    Need additional support? Our team is here to help! Reach out directly through Collaborate for personalized assistance.`,
    howToTitle: 'How to search using',
    pageTitle: 'Booleans & Logical Operators-1',
    videoLength: '02:57'
  },
  {
    id: 'how-to-search-using-booleans-and-logical-operators-2',
    thumbnail:
      'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/How to Search Using Booleans & Logical Operators-2.png',
    title: 'How to Search Using Booleans & Logical Operators-2',
    secondaryTitle: '',
    date: new Date('02/28/2024'),
    tags: [
      'RIATutorial',
      'BooleanSearch',
      'LogicalOperators',
      'PracticalApplication',
      'ResearchSkills',
      'SearchEfficiency',
      'Part2',
      'BooleanBasics',
      'HomePage',
      'Documents'
    ],
    videoName: 'How to Search Using Booleans & Logical Operators-2.mp4',
    content: `Building on our foundational knowledge from Part 1, this second installment of our educational series dives into real-world applications of Boolean and Logical Operators within ARIA searches. Enhance your research skills as we explore complex queries and learn how to adjust them for precision results. <br/><br/>
      What this tutorial covers:
      <ul>
        <li>Applying our understanding of Boolean logic to form complex search queries in ARIA.</li>
        <li>Step-by-step breakdown of a multifaceted search question involving trial phases and Bayesian design.</li>
        <li>Troubleshooting common issues with Boolean searches, such as unwanted results slipping through.</li>
        <li>Employing advanced tactics like nesting operators and excluding closely related terms to refine searches.</li>
      </ul>
      Key Learning Outcomes:
      <ul>
        <li>Develop complex ARIA search strategies to filter out irrelevant results.</li>
        <li>Understand the importance of precise phrasing and operator nesting in search queries.</li>
        <li>Learn how to modify queries on-the-fly for more accurate search outcomes.</li>
      </ul>
      Resources: <a href="https://vivpro-site.s3.amazonaws.com/Help_Page_FAQ/Searching_with_Booleans_Reference_Sheet.pdf" target="_blank">Boolean Operators Guide</a> </br>
      Need additional support? Our team is here to help! Reach out directly through Collaborate for personalized assistance.`,
    shortDescription: `Building on our foundational knowledge from Part 1, this second installment of our educational series dives into real-world applications of Boolean and Logical Operators within ARIA searches. Enhance your research skills as we explore complex queries and learn how to adjust them for precision results. <br>
    What this tutorial covers:
    <ul>
      <li>Applying our understanding of Boolean logic to form complex search queries in ARIA.</li>
      <li>Step-by-step breakdown of a multifaceted search question involving trial phases and Bayesian design.</li>
      <li>Troubleshooting common issues with Boolean searches, such as unwanted results slipping through.</li>
      <li>Employing advanced tactics like nesting operators and excluding closely related terms to refine searches.</li>
    </ul>
    Key Learning Outcomes:
    <ul>
      <li>Develop complex ARIA search strategies to filter out irrelevant results.</li>
      <li>Understand the importance of precise phrasing and operator nesting in search queries.</li>
      <li>Learn how to modify queries on-the-fly for more accurate search outcomes.</li>
    </ul>
    Resources: <a href="https://vivpro-site.s3.amazonaws.com/Help_Page_FAQ/Searching_with_Booleans_Reference_Sheet.pdf" target="_blank">Boolean Operators Guide</a> </br>
    Need additional support? Our team is here to help! Reach out directly through Collaborate for personalized assistance.`,
    howToTitle: 'How to search using',
    pageTitle: 'Booleans & Logical Operators-2',
    videoLength: '02:47'
  },
  {
    id: 'how-to-use-search-effectively-in-ria',
    thumbnail:
      'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/How to Use Search effectively in RIA.png',
    title: 'How to Use Search Effectively in RIA',
    secondaryTitle: '',
    date: new Date('02/28/2024'),
    tags: [
      'RIATutorial',
      'SearchTutorial',
      'RegulatoryIntelligence',
      'ClinicalResearch',
      'Documents',
      'Applications',
      'HomePage',
      'TimeSavingTips',
      'EfficientResearch'
    ],
    videoName: 'How to Use Search effectively in RIA.mp4',
    content: `Embark on a journey of discovery with our detailed RIA search tutorial. Designed for professionals delving into clinical and regulatory documents. This video provides a step-by-step guide on using RIA's search functionalities to their fullest potential. <br/><br/>
      In this video, you'll learn:
      <ul>
        <li>The essentials of conducting efficient searches on the RIA homepage.</li>
        <li>Leveraging RIA's algorithms for targeted searches that save time and increase productivity.</li>
        <li>Customizing your search experience by controlling search parameters and categories.</li>
      </ul>
      Scenarios Tackled: "What was the primary endpoint of a drug called sofosbuvir" and "Melanoma" </br>
      Need additional support? Our team is here to help! Reach out directly through Collaborate for personalized assistance.`,
    shortDescription: `Embark on a journey of discovery with our detailed RIA search tutorial. Designed for professionals delving into clinical and regulatory documents. This video provides a step-by-step guide on using RIA's search functionalities to their fullest potential. <br>
    In this video, you'll learn:
    <ul>
      <li>The essentials of conducting efficient searches on the RIA homepage.</li>
      <li>Leveraging RIA's algorithms for targeted searches that save time and increase productivity.</li>
      <li>Customizing your search experience by controlling search parameters and categories.</li>
    </ul>
    Scenarios Tackled: "What was the primary endpoint of a drug called sofosbuvir" and "Melanoma" </br>
    Need additional support? Our team is here to help! Reach out directly through Collaborate for personalized assistance.`,
    howToTitle: 'How to use',
    pageTitle: 'Search Effectively in RIA',
    videoLength: '03:05'
  },
  {
    id: 'how-to-use-clinical-development-plan',
    thumbnail:
      'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/How to Use Clinical Development Plan (CDP).png',
    title: 'How to Use Clinical Development Plan (CDP)',
    secondaryTitle: '',
    date: new Date('02/28/2024'),
    tags: [
      'RIATutorial',
      'RIA',
      'CDPModule',
      'ClinicalTrials',
      'FDA',
      'PharmaceuticalResearch',
      'ClinicalDevelopment',
      'RegulatoryAffairs ',
      'ResearchEfficiency',
      'HomePage'
    ],
    videoName: 'How to Use Clinical Development Plan (CDP).mp4',
    content: `Delve into the depths of clinical research with our latest tutorial on RIA's Clinical Development Plan (CDP) module. This intuitive tool is your shortcut to mapping out comprehensive development plans for pharmaceutical products. Perfect for competitive analysis or strategizing your own clinical pathway, the CDP module is a crucial asset for any R&D professional. <br/><br/>
      In this video, you'll learn:
      <ul>
        <li>Step-by-step guidance through the CDP module for efficient navigation.</li>
        <li>Switching between different drug applications without losing your research momentum.</li>
        <li>Insights into the trial details like sample size, phase, and primary completion dates.</li>
        <li>Directly accessing original documents for in-depth research.</li>
        <li>Downloading graphs for easy inclusion in reports and presentations.</li>
        <li>Filtering options to customize the data according to your research needs.</li>
      </ul>
      Have questions or need a deeper dive into the CDP module? Our team is here to help! Reach out directly through Collaborate for personalized assistance.`,
    shortDescription: `Delve into the depths of clinical research with our latest tutorial on RIA's Clinical Development Plan (CDP) module. This intuitive tool is your shortcut to mapping out comprehensive development plans for pharmaceutical products. Perfect for competitive analysis or strategizing your own clinical pathway, the CDP module is a crucial asset for any R&D professional. <br>
    In this video, you'll learn:
    <ul>
      <li>Step-by-step guidance through the CDP module for efficient navigation.</li>
      <li>Switching between different drug applications without losing your research momentum.</li>
      <li>Insights into the trial details like sample size, phase, and primary completion dates.</li>
      <li>Directly accessing original documents for in-depth research.</li>
      <li>Downloading graphs for easy inclusion in reports and presentations.</li>
      <li>Filtering options to customize the data according to your research needs.</li>
    </ul>
    Have questions or need a deeper dive into the CDP module? Our team is here to help! Reach out directly through Collaborate for personalized assistance.`,
    howToTitle: 'How to use',
    pageTitle: 'Clinical Development Plan',
    videoLength: '03:30'
  },
  {
    id: 'how-to-use-collaborate',
    thumbnail: 'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/How to Use Collaborate.png',
    title: 'How to Use Collaborate',
    secondaryTitle: '',
    date: new Date('03/04/2024'),
    tags: [
      'Collaborate',
      'TeamCommunication',
      'CollaborationTool',
      'ResearchEfficiency',
      'VivproSupport',
      'Teamwork',
      'Share',
      'Group',
      'Annotate',
      'HomePage'
    ],
    videoName: 'How to Use Collaborate (July 2024).mp4',
    content: `Explore RIA's Collaborate feature in this instructional video, designed to enhance teamwork and communication within your organization and with Vivpro SMEs. Learn how to navigate and utilize the Collaborate tab effectively, ensuring you can manage and participate in group discussions seamlessly. <br/><br/>
      The video guides you through:
      <ol>
        <li><strong>Accessing the Collaborate Tab:</strong> Discover how to access all your conversations in one place, enhancing your workflow and connectivity.</li>
        <li><strong>Starting New Conversations:</strong> Learn how to initiate discussions with Vivpro SMEs and colleagues, adding necessary participants to foster comprehensive communication.</li>
        <li><strong>Sharing Search Results:</strong> See how easily you can share search results from RIA's vast database directly in your conversations, keeping all members informed and engaged.</li>
        <li><strong>Enhancing Team Productivity:</strong> Utilize collaborative tools to share documents, exchange messages, and make informed decisions quickly and efficiently.</li>
      </ol>
      This tutorial is ideal for RIA users looking to maximize their collaborative efforts and streamline communications across their organization. Watch now to transform how your team connects and operates within the RIA platform. <br/>
    <strong>Remember</strong>, for any questions or additional guidance, RIA support is just a message away, ready to assist you in leveraging these powerful tools to boost your organization's efficiency and success.`,
    shortDescription: `Explore RIA's Collaborate feature in this instructional video, designed to enhance teamwork and communication within your organization and with Vivpro SMEs. Learn how to navigate and utilize the Collaborate tab effectively, ensuring you can manage and participate in group discussions seamlessly. <br/><br/>
      The video guides you through:
      <ol>
        <li><strong>Accessing the Collaborate Tab:</strong> Discover how to access all your conversations in one place, enhancing your workflow and connectivity.</li>
        <li><strong>Starting New Conversations:</strong> Learn how to initiate discussions with Vivpro SMEs and colleagues, adding necessary participants to foster comprehensive communication.</li>
        <li><strong>Sharing Search Results:</strong> See how easily you can share search results from RIA's vast database directly in your conversations, keeping all members informed and engaged.</li>
        <li><strong>Enhancing Team Productivity:</strong> Utilize collaborative tools to share documents, exchange messages, and make informed decisions quickly and efficiently.</li>
      </ol>
      This tutorial is ideal for RIA users looking to maximize their collaborative efforts and streamline communications across their organization. Watch now to transform how your team connects and operates within the RIA platform. <br/>
    <strong>Remember</strong>, for any questions or additional guidance, RIA support is just a message away, ready to assist you in leveraging these powerful tools to boost your organization's efficiency and success.`,
    howToTitle: 'How to use',
    pageTitle: 'Collaborate',
    videoLength: '02:22'
  },
  {
    id: 'how-to-use-pediatrics',
    thumbnail: 'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/How to Use Pediatrics.png',
    title: 'How to Use Pediatrics Module',
    secondaryTitle: '',
    date: new Date('03/04/2024'),
    tags: [
      'RIAPediatricsModule',
      'PediatricResearch',
      'FDAWrittenRequests',
      'EMAPIPs',
      'PediatricInvestigationPlans',
      'RIA',
      'PediatricDrugDevelopment',
      'RegulatoryAffairs',
      'ClinicalResearch',
      'HomePage'
    ],
    videoName: 'How to Use Pediatrics.mp4',
    content: `Dive into the depths of pediatric research with our RIA Pediatrics module tutorial. This video is tailored for professionals seeking to leverage comprehensive pediatric data from the US FDA and EU EMA. Whether you're strategizing for a new pediatric drug development project or searching for specific pediatric investigation plans (PIPs), this tutorial equips you with the necessary tools and knowledge. <br/><br/>
      What this tutorial covers:
      <ul>
        <li>A step-by-step guide focused on navigating the Pediatrics module for PIPs, with applicable workflows for FDA Written Requests.</li>
        <li>Insights into the module's functionality, including the innovative Ask RIA feature for answering complex queries.</li>
        <li>Tips on making the most out of the module's features for your pediatric strategy.</li>
      </ul>
      Have any questions or need further assistance? Our dedicated support team is here to help you every step of the way. Reach out directly through Collaborate for personalized assistance.`,
    shortDescription: `Dive into the depths of pediatric research with our RIA Pediatrics module tutorial. This video is tailored for professionals seeking to leverage comprehensive pediatric data from the US FDA and EU EMA. Whether you're strategizing for a new pediatric drug development project or searching for specific pediatric investigation plans (PIPs), this tutorial equips you with the necessary tools and knowledge. <br/><br/>
    What this tutorial covers:
    <ul>
      <li>A step-by-step guide focused on navigating the Pediatrics module for PIPs, with applicable workflows for FDA Written Requests.</li>
      <li>Insights into the module's functionality, including the innovative Ask RIA feature for answering complex queries.</li>
      <li>Tips on making the most out of the module's features for your pediatric strategy.</li>
    </ul>
    Have any questions or need further assistance? Our dedicated support team is here to help you every step of the way. Reach out directly through Collaborate for personalized assistance.`,
    howToTitle: 'How to use',
    pageTitle: 'Pediatrics Module',
    videoLength: '02:05'
  },
  {
    id: 'how-to-use-compare-labels',
    thumbnail:
      'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/How to Use Label Comparison.png',
    title: 'How to Use Compare Labels',
    secondaryTitle: '',
    date: new Date('03/04/2024'),
    tags: [
      'LabelComparison',
      'RegulatoryAffairs',
      'DrugLabels',
      'LabelAnalysis',
      'RIATutorial',
      'PharmaceuticalResearch',
      'Regions',
      'Source',
      'RegulatoryResearch',
      'HomePage',
      'Applications',
      'Reg360'
    ],
    videoName: 'How to Use Compare Labels.mp4',
    content: `Join us in this in-depth tutorial on how to utilize RIA's robust label comparison features across different products and regions, along with a unique functionality to generate AI summaries of differences. This tutorial is perfect for regulatory professionals and stakeholders in the pharmaceutical industry who need to compare regulatory labels efficiently and extract meaningful insights quickly. <br/><br/>
      What this tutorial covers:
      <ul>
        <li>Navigating to the label comparison feature within the RIA platform.</li>
        <li>Step-by-step instructions on comparing labels within a single product, between different products and across regions to generating AI summaries of the comparisons, ensuring a comprehensive analysis irrespective of geographic boundaries.</li>
        <li>Understanding the label comparison interface, including how to select labels for comparison, understanding the color-coded text indicating changes, and how to download a side-by-side view of the compared labels.</li>
        <li>Accessing the label comparison tool directly from the RIA homepage for quick and efficient comparisons.</li>
      </ul>
      Have any questions or need further assistance? Our dedicated support team is here to help you every step of the way. Reach out directly through Collaborate for personalized assistance.`,
    shortDescription: `Join us in this in-depth tutorial on how to utilize RIA's robust label comparison features across different products and regions, along with a unique functionality to generate AI summaries of differences. This tutorial is perfect for regulatory professionals and stakeholders in the pharmaceutical industry who need to compare regulatory labels efficiently and extract meaningful insights quickly. <br/><br/>
      What this tutorial covers:
      <ul>
        <li>Navigating to the label comparison feature within the RIA platform.</li>
        <li>Step-by-step instructions on comparing labels within a single product, between different products and across regions to generating AI summaries of the comparisons, ensuring a comprehensive analysis irrespective of geographic boundaries.</li>
        <li>Understanding the label comparison interface, including how to select labels for comparison, understanding the color-coded text indicating changes, and how to download a side-by-side view of the compared labels.</li>
        <li>Accessing the label comparison tool directly from the RIA homepage for quick and efficient comparisons.</li>
      </ul>
      Have any questions or need further assistance? Our dedicated support team is here to help you every step of the way. Reach out directly through Collaborate for personalized assistance.`,
    howToTitle: 'How to use',
    pageTitle: 'Compare Labels',
    videoLength: '04:30'
  },
  {
    id: 'how-to-use-pmr-or-pmc-module',
    thumbnail:
      'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/How to Use PMR or PMC Module.png',
    title: 'How to Use PMR/PMC Module',
    secondaryTitle: '',
    date: new Date('03/06/2024'),
    tags: [
      'PMR',
      'PMC',
      'Surveillance',
      'Impact',
      'RegulatoryAffairs',
      'PostMarketingRequirements',
      'PostMarketingCommitments',
      'PharmaceuticalResearch',
      'DrugRegulations',
      'RegulatoryResearch',
      'HomePage'
    ],
    videoName: 'How to Use PMR or PMC Module.mp4',
    content: `Unlock the potential of the Post-Marketing Requirements and Commitments (PMR/Cs) module within the RIA platform with our detailed tutorial. This video is designed to walk you through accessing and analyzing comprehensive PMR/C data, making it simpler to find product-specific PMR/Cs. <br/><br/>
      What this tutorial covers:
      <ul>
        <li>The tutorial offers insights into efficiently using the PMR/C module to support your regulatory research needs.</li>
        <li>Navigating the Surveillance sub-module to access an exhaustive list of PMR/Cs from both CBER and CDER, complete with descriptive metadata for each entry.</li>
        <li>Demonstrations on applying keyword filters and refining search results to focus on your area of interest within the PMR/C data.</li>
        <li>Tips on how to export your findings for deeper analysis and how to compare labels between various submissions using the Impact sub-module.</li>
      </ul>
      Have any questions or need further assistance? Our dedicated support team is here to help you every step of the way. Reach out directly through Collaborate for personalized assistance.`,
    shortDescription: `Unlock the potential of the Post-Marketing Requirements and Commitments (PMR/Cs) module within the RIA platform with our detailed tutorial. This video is designed to walk you through accessing and analyzing comprehensive PMR/C data, making it simpler to find product-specific PMR/Cs. <br/><br/>
    What this tutorial covers:
    <ul>
      <li>The tutorial offers insights into efficiently using the PMR/C module to support your regulatory research needs.</li>
      <li>Navigating the Surveillance sub-module to access an exhaustive list of PMR/Cs from both CBER and CDER, complete with descriptive metadata for each entry.</li>
      <li>Demonstrations on applying keyword filters and refining search results to focus on your area of interest within the PMR/C data.</li>
      <li>Tips on how to export your findings for deeper analysis and how to compare labels between various submissions using the Impact sub-module.</li>
    </ul>
    Have any questions or need further assistance? Our dedicated support team is here to help you every step of the way. Reach out directly through Collaborate for personalized assistance.`,
    howToTitle: 'How to use',
    pageTitle: 'PMR/PMC Module',
    videoLength: '03:12'
  },
  {
    id: 'how-to-use-chmp-meetings',
    thumbnail: 'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/How to Use CHMP Meetings.png',
    title: 'How to Use CHMP Meetings',
    secondaryTitle: '',
    date: new Date('03/06/2024'),
    tags: [
      'CHMP',
      'Meetings',
      'RegulatoryAffairs',
      'EMA',
      'MedicinalProducts',
      'RegulatoryResearch',
      'DrugRegulations',
      'RegulatoryResearch',
      'HomePage'
    ],
    videoName: 'How to Use CHMP Meetings.mp4',
    content: `Dive deep into the workings of the European Medicines Agency's Committee for Medicinal Products for Human Use (CHMP) with our informative RIA tutorial. This video is designed to guide you through the CHMP module, providing a detailed walkthrough of how to access and analyze CHMP meeting details, opinions, and documentation directly within the RIA platform. <br/><br/>
      What this tutorial covers:
      <ul>
        <li>A step-by-step guide to mastering the CHMP module on RIA.</li>
        <li>Insights into efficiently navigating CHMP meeting records and documentation.</li>
        <li>Tips on maximizing the use of AskRIA for focused and expansive research inquiries.</li>
      </ul>
      Have any questions or need further assistance? Our dedicated support team is here to help you every step of the way. Reach out directly through Collaborate for personalized assistance.`,
    shortDescription: `Dive deep into the workings of the European Medicines Agency's Committee for Medicinal Products for Human Use (CHMP) with our informative RIA tutorial. This video is designed to guide you through the CHMP module, providing a detailed walkthrough of how to access and analyze CHMP meeting details, opinions, and documentation directly within the RIA platform. <br/><br/>
    What this tutorial covers:
    <ul>
      <li>A step-by-step guide to mastering the CHMP module on RIA.</li>
      <li>Insights into efficiently navigating CHMP meeting records and documentation.</li>
      <li>Tips on maximizing the use of AskRIA for focused and expansive research inquiries.</li>
    </ul>
    Have any questions or need further assistance? Our dedicated support team is here to help you every step of the way. Reach out directly through Collaborate for personalized assistance.`,
    howToTitle: 'How to use',
    pageTitle: 'CHMP Meetings',
    videoLength: '02:16'
  },
  {
    id: 'how-to-use-advisory-committee-meetings',
    thumbnail:
      'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/How to Use Advisory Committee Meetings.png',
    title: 'How to Use Advisory Committee Meetings',
    secondaryTitle: '',
    date: new Date('03/06/2024'),
    tags: [
      'AdComm',
      'Meetings',
      'RegulatoryAffairs',
      'AdvisoryCommittee',
      'CDER',
      'CDRH',
      'RegulatoryResearch',
      'Roster',
      'RegulatoryIntelligence',
      'HomePage'
    ],
    videoName: 'How to Use Advisory Committee Meetings.mp4',
    content: `Unlock the potential of RIA's Advisory Committee (AdComm) meeting module with our detailed tutorial. This instructional video is your key to accessing a wealth of information on CDER and CDRH Advisory Committee meetings, minutes, resources, and insights into committee members' contributions. Whether you're preparing for a meeting or conducting post-meeting analyses, the AdComm module is an essential tool for your regulatory research. <br/><br/>
      What this tutorial covers:
      <ul>
        <li>Step-by-step instructions to master the AdComm module on RIA.</li>
        <li>Insights into efficiently finding and analyzing Advisory Committee meeting details.</li>
        <li>Tips on making the most out of AskRIA for targeted and comprehensive queries.</li>
      </ul>
      Have any questions or need further assistance? Our dedicated support team is here to help you every step of the way. Reach out directly through Collaborate for personalized assistance.`,
    shortDescription: `Unlock the potential of RIA's Advisory Committee (AdComm) meeting module with our detailed tutorial. This instructional video is your key to accessing a wealth of information on CDER and CDRH Advisory Committee meetings, minutes, resources, and insights into committee members' contributions. Whether you're preparing for a meeting or conducting post-meeting analyses, the AdComm module is an essential tool for your regulatory research. <br/><br/>
    What this tutorial covers:
    <ul>
      <li>Step-by-step instructions to master the AdComm module on RIA.</li>
      <li>Insights into efficiently finding and analyzing Advisory Committee meeting details.</li>
      <li>Tips on making the most out of AskRIA for targeted and comprehensive queries.</li>
    </ul>
    Have any questions or need further assistance? Our dedicated support team is here to help you every step of the way. Reach out directly through Collaborate for personalized assistance.`,
    howToTitle: 'How to use',
    pageTitle: 'Advisory Committee Meetings',
    videoLength: '02:47'
  },
  {
    id: 'how-to-use-service-request',
    thumbnail:
      'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/How to Use Service Request.png',
    title: 'How to Use Service Request',
    secondaryTitle: '',
    date: new Date('03/07/2024'),
    tags: [
      'ServiceRequest',
      'RIASupport',
      'RegulatoryResearch',
      'StrategicToolkit',
      'RIACollaborate',
      'CustomerSupport',
      'RegulatoryIntelligence',
      'HomePage'
    ],
    videoName: 'How to Use Service Request.mp4',
    content: `Dive into the world of RIA's Service Request feature, your direct line to Vivpro's expertise and support. This tutorial guides you through the seamless process of submitting service requests within RIA, from drafting your query to receiving personalized feedback. Whether you're verifying research methods or inquiring about new data sources, Service Requests enhance your RIA experience by providing swift, expert answers. <br/><br/>
      What this tutorial covers:
      <ul>
        <li>Detailed walkthrough on using the Service Request feature for enhanced support.</li>
        <li>The process of submitting a new Service Request includes selecting request types, detailing your query and sharing findings from search using real life examples.</li>
      </ul>
      Got questions or need further assistance? Our dedicated support team is ready to provide you with the guidance needed to maximize your use of Service Requests and other RIA functionalities. Reach out directly through Collaborate for personalized assistance.`,
    shortDescription: `Dive into the world of RIA's Service Request feature, your direct line to Vivpro's expertise and support. This tutorial guides you through the seamless process of submitting service requests within RIA, from drafting your query to receiving personalized feedback. Whether you're verifying research methods or inquiring about new data sources, Service Requests enhance your RIA experience by providing swift, expert answers. <br/><br/>
    What this tutorial covers:
    <ul>
      <li>Detailed walkthrough on using the Service Request feature for enhanced support.</li>
      <li>The process of submitting a new Service Request includes selecting request types, detailing your query and sharing findings from search using real life examples.</li>
    </ul>
    Got questions or need further assistance? Our dedicated support team is ready to provide you with the guidance needed to maximize your use of Service Requests and other RIA functionalities. Reach out directly through Collaborate for personalized assistance.`,
    howToTitle: 'How to use',
    pageTitle: 'Service Request',
    videoLength: '01:55'
  },
  {
    id: 'how-to-use-guidances-module',
    thumbnail:
      'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/How to Use Guidances Module.png',
    title: 'How to Use Guidances Module',
    secondaryTitle: '',
    date: new Date('03/08/2024'),
    tags: [
      'GuidanceModule',
      'RegulatoryGuidance',
      'FDA',
      'EMA',
      'HealthCanada',
      'RegulatoryAffairs',
      'Compliance',
      'RegulatoryDocuments',
      'HomePage'
    ],
    videoName: 'How to Use Guidances Module.mp4',
    content: `Step into the realm of regulatory guidance with our in-depth tutorial on the RIA Guidance module. This video serves as a comprehensive guide to accessing a vast array of guidance documents from the US FDA, EU EMA, and Health Canada, all in one place. <br/><br/>
      What this tutorial covers:
      <ul>
        <li>A step-by-step guide focused on navigating the Guidances module for FDA, with applicable workflows for EMA and Health Canada.</li>
        <li>A walkthrough of searching for, comparing guidance documents, and filtering with practical examples.</li>
        <li>Tips on how to use Ask RIA for targeted inquiries, ensuring comprehensive coverage of relevant documents.</li>
      </ul>
      Have any questions or need further assistance? Our dedicated support team is here to help you every step of the way. Reach out directly through Collaborate for personalized assistance.`,
    shortDescription: `Step into the realm of regulatory guidance with our in-depth tutorial on the RIA Guidance module. This video serves as a comprehensive guide to accessing a vast array of guidance documents from the US FDA, EU EMA, and Health Canada, all in one place. <br/><br/>
    What this tutorial covers:
    <ul>
      <li>A step-by-step guide focused on navigating the Guidances module for FDA, with applicable workflows for EMA and Health Canada.</li>
      <li>A walkthrough of searching for, comparing guidance documents, and filtering with practical examples.</li>
      <li>Tips on how to use Ask RIA for targeted inquiries, ensuring comprehensive coverage of relevant documents.</li>
    </ul>
    Have any questions or need further assistance? Our dedicated support team is here to help you every step of the way. Reach out directly through Collaborate for personalized assistance.`,
    howToTitle: 'How to use',
    pageTitle: 'Guidances Module',
    videoLength: '03:14'
  },
  {
    id: 'how-to-use-advanced-search',
    thumbnail:
      'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/How to Use Advanced Search.png',
    title: 'How to Use Advanced Search',
    secondaryTitle: '',
    date: new Date('03/19/2024'),
    tags: [
      'AdvancedSearch',
      'RegulatoryResearch',
      'PrecisionSearch',
      'RegulatoryAffairs',
      'PharmaceuticalResearch',
      'Healthcare',
      'Regulation',
      'HomePage'
    ],
    videoName: 'How to Use Advanced Search.mp4',
    content: `Welcome to RIA's tutorial on the Advanced Search feature, a pivotal tool designed for those who seek detailed control over their search queries. This video is an in-depth guide on how to leverage Advanced Search within RIA, allowing for precise refinement of search results according to specific criteria. This feature is especially useful for regulatory professionals looking to pinpoint exact data within RIA's vast repository of regulatory information. <br/><br/>
      What this tutorial covers:
      <ul>
        <li>A step-by-step guide to utilizing the Advanced Search on RIA.</li>
        <li>Demonstrating the functionality and versatility of Advanced Search for granular insights.</li>
        <li>Practical application of Advanced Search criteria to achieve targeted search results.</li>
        <li>Verifying the precision of your search results by conducting comparative Advanced Searches.</li>
      </ul>
      Have any questions or need further assistance? Our dedicated support team is here to help you navigate through RIA's functionalities and ensure you get the most out of the Advanced Search feature. Reach out directly through Collaborate for personalized assistance.`,
    shortDescription: `Welcome to RIA's tutorial on the Advanced Search feature, a pivotal tool designed for those who seek detailed control over their search queries. This video is an in-depth guide on how to leverage Advanced Search within RIA, allowing for precise refinement of search results according to specific criteria. This feature is especially useful for regulatory professionals looking to pinpoint exact data within RIA's vast repository of regulatory information. <br/><br/>
    What this tutorial covers:
    <ul>
      <li>A step-by-step guide to utilizing the Advanced Search on RIA.</li>
      <li>Demonstrating the functionality and versatility of Advanced Search for granular insights.</li>
      <li>Practical application of Advanced Search criteria to achieve targeted search results.</li>
      <li>Verifying the precision of your search results by conducting comparative Advanced Searches.</li>
    </ul>
    Have any questions or need further assistance? Our dedicated support team is here to help you navigate through RIA's functionalities and ensure you get the most out of the Advanced Search feature. Reach out directly through Collaborate for personalized assistance.`,
    howToTitle: 'How to use',
    pageTitle: 'Advanced Search',
    videoLength: '02:27'
  },
  {
    id: 'how-to-access-help-resources',
    thumbnail:
      'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/How to Use Guide and Help Center.png',
    title: 'How to Access Help Resources',
    secondaryTitle: '',
    date: new Date('04/09/2024'),
    tags: [
      'HelpPage',
      'Guide',
      'HowTo',
      'About',
      'FAQ',
      'CaseStudies',
      'WhatsNew',
      'VideoTutorial',
      'HomePage',
      'Support',
      'Help'
    ],
    videoName: 'How to Use Guide and Help Center.mp4',
    content: `Embark on your RIA journey with our comprehensive guide to using the Help page effectively. This tutorial is your first step towards mastering the RIA platform, providing you with all the essential tools and resources needed to navigate through RIA's vast regulatory information and data analytics tools seamlessly. Whether you're a new user setting up your account or looking to deepen your understanding of RIA's functionalities, this video has got you covered. <br/><br/>
      What this tutorial covers:
      <ul>
        <li>Step-by-step navigation through the RIA Help page, including practical demonstrations of the Guide feature.</li>
        <li>Insights into making the most out of the FAQs, Case Studies, What's New, and Video tutorials sections.</li>
        <li>Tips on how to quickly find relevant information and tutorials that can aid in your regulatory research and analysis.</li>
      </ul>
      Have any questions or need further assistance? Our dedicated support team is here to help you navigate through RIA's functionalities and ensure you get the most out of the Advanced Search feature. Reach out directly through Collaborate for personalized assistance.`,
    shortDescription: `Embark on your RIA journey with our comprehensive guide to using the Help page effectively. This tutorial is your first step towards mastering the RIA platform, providing you with all the essential tools and resources needed to navigate through RIA's vast regulatory information and data analytics tools seamlessly. Whether you're a new user setting up your account or looking to deepen your understanding of RIA's functionalities, this video has got you covered. <br/><br/>
    What this tutorial covers:
    <ul>
      <li>Step-by-step navigation through the RIA Help page, including practical demonstrations of the Guide feature.</li>
      <li>Insights into making the most out of the FAQs, Case Studies, What's New, and Video tutorials sections.</li>
      <li>Tips on how to quickly find relevant information and tutorials that can aid in your regulatory research and analysis.</li>
    </ul>
    Have any questions or need further assistance? Our dedicated support team is here to help you navigate through RIA's functionalities and ensure you get the most out of the Advanced Search feature. Reach out directly through Collaborate for personalized assistance.`,
    howToTitle: 'How to access',
    pageTitle: 'Help Resources',
    videoLength: '02:26'
  },
  {
    id: 'how-to-manage-user-notifications',
    thumbnail:
      'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/How%20to%20Manage%20User%20Notifications.jpeg',
    title: 'How to Manage User Notifications',
    secondaryTitle: '',
    date: new Date('08/12/2024'),
    tags: [
      'RIA',
      'UserNotifications',
      'Applications',
      'HomePage',
      'Notifications',
      'Subscription',
      'Settings',
      'Sources',
      'Searches',
      'Updates'
    ],
    videoName: 'How to Manage Notifications.mp4',
    content: `Discover how to stay informed about the latest updates in your field with RIA's user notification system in this instructional video. Learn to tailor notifications according to your specific needs, whether you're following updates from regulatory bodies, tracking keyword searches, or keeping tabs on specific drug applications.
      <br/>The tutorial covers:
      <ol>
      <li><strong>Accessing the Notifications Tab:</strong> Familiarize yourself with where to find and how to navigate the notifications interface on RIA.</li>
      <li><strong>Setting Source-Level Notifications:</strong> Learn how to receive updates from selected regulatory sources like the US FDA, EU EMA, and Health Canada.</li>
      <li><strong>Managing Keyword Search Notifications:</strong> Set up alerts for specific keyword searches and refine them with filters to ensure you receive the most relevant updates.</li>
      <li><strong>Subscribing to Drug Applications:</strong> Stay updated on specific drug applications by subscribing to receive alerts for any new or updated information.</li>
      <li><strong>Adjusting Subscription Settings:</strong> View and manage your current subscriptions and customize how you receive these notifications via email.</li>
      </ol>
      This guide is perfect for RIA users who want to optimize how they receive updates and ensure they never miss out on critical information. Watch now to enhance how you engage with RIA's vast repository of information, making sure you're always in the loop.
      <strong>For any questions or additional guidance</strong>, our support team is ready to help you navigate and make the most of RIA's notification features.`,
    shortDescription: `Discover how to stay informed about the latest updates in your field with RIA's user notification system in this instructional video. Learn to tailor notifications according to your specific needs, whether you're following updates from regulatory bodies, tracking keyword searches, or keeping tabs on specific drug applications.
      <br/>The tutorial covers:
      <ol>
      <li><strong>Accessing the Notifications Tab:</strong> Familiarize yourself with where to find and how to navigate the notifications interface on RIA.</li>
      <li><strong>Setting Source-Level Notifications:</strong> Learn how to receive updates from selected regulatory sources like the US FDA, EU EMA, and Health Canada.</li>
      <li><strong>Managing Keyword Search Notifications:</strong> Set up alerts for specific keyword searches and refine them with filters to ensure you receive the most relevant updates.</li>
      <li><strong>Subscribing to Drug Applications:</strong> Stay updated on specific drug applications by subscribing to receive alerts for any new or updated information.</li>
      <li><strong>Adjusting Subscription Settings:</strong> View and manage your current subscriptions and customize how you receive these notifications via email.</li>
      </ol>
      This guide is perfect for RIA users who want to optimize how they receive updates and ensure they never miss out on critical information. Watch now to enhance how you engage with RIA's vast repository of information, making sure you're always in the loop.
      <strong>For any questions or additional guidance</strong>, our support team is ready to help you navigate and make the most of RIA's notification features.`,
    howToTitle: 'How to Manage',
    pageTitle: 'User Notifications',
    videoLength: '02:50'
  },
  {
    id: 'mastering-chat-ria-optimizing-queries-for-better-results',
    thumbnail: 'https://vivpro-site.s3.amazonaws.com/Video_Tutorials/Mastering ChatRIA.jpeg',
    title: 'Mastering ChatRIA: Optimizing Queries for Better Results',
    secondaryTitle: '',
    date: new Date('08/19/2024'),
    tags: [
      'ChatRIA',
      'QueryOptimization',
      'DataAccuracy',
      'TechTutorial',
      'ChatGPT ',
      'AICommunication',
      'Applications',
      'HomePage',
      'Reg360',
      'Documents'
    ],
    videoName: 'Mastering ChatRIA.mp4',
    content: `Explore the art of creating effective queries to enhance your interactions with ChatRIA for more accurate responses. This tutorial will guide you through refining your queries, using examples like RINVOQ and ZORYVE, to demonstrate the impact of precise phrasing.<br/><br/>
      The tutorial covers:
      <ol>
        <li><strong>Optimizing Query Phrasing:</strong> Learn how to refine your questions to ChatRIA to enhance the quality of responses, using specific examples.</li>
        <li><strong>Handling Complex Queries:</strong> Discover strategies for dealing with intricate queries that require detailed answers or involve complex calculations.</li>
        <li><strong>Improving Interaction with ChatRIA:</strong> Gain insights on how to interact more effectively with ChatRIA by clarifying prompts, simplifying complex questions, and recognizing the tool's limitations.</li>
        <li><strong>Verification Tips:</strong> Understand the importance of verifying ChatRIA’s responses to ensure accuracy.</li>
      </ol>
      This video is essential for users looking to maximize their efficiency and effectiveness in using ChatRIA. Watch now to transform your approach and achieve optimal results with ChatRIA. For any questions or personalized support, our team is ready to assist you in enhancing your ChatRIA experience.`,
    shortDescription: `Explore the art of creating effective queries to enhance your interactions with ChatRIA for more accurate responses. This tutorial will guide you through refining your queries, using examples like RINVOQ and ZORYVE, to demonstrate the impact of precise phrasing.<br/><br/>
      The tutorial covers:
      <ol>
        <li><strong>Optimizing Query Phrasing:</strong> Familiarize yourself with where to find and how to navigate the notifications interface on RIA.</li>
        <li><strong>Handling Complex Queries:</strong> Learn how to receive updates from selected regulatory sources like the US FDA, EU EMA, and Health Canada.</li>
        <li><strong>Improving Interaction with ChatRIA:</strong> Set up alerts for specific keyword searches and refine them with filters to ensure you receive the most relevant updates.</li>
        <li><strong>Verification Tips:</strong> Stay updated on specific drug applications by subscribing to receive alerts for any new or updated information.</li>
      </ol>
      This video is essential for users looking to maximize their efficiency and effectiveness in using ChatRIA. Watch now to transform your approach and achieve optimal results with ChatRIA. For any questions or personalized support, our team is ready to assist you in enhancing your ChatRIA experience.`,
    howToTitle: 'Optimizing queries for better results',
    pageTitle: 'Mastering ChatRIA',
    videoLength: '02:50'
  }
];

export const DATASTATS_COLUMNS_MAPPINGS = [
  {
    field: 'region',
    headerName: 'Region',
    width: 150,
    headerClassName: 'table-header',
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    toolTipValueGetter: ({ row }) => regionMapping(row.region),
    valueGetter: ({ row }) => regionSpaceFormatting(row.region)
  },
  {
    field: 'source',
    headerName: 'Source (Link)',
    width: 180,
    headerClassName: 'table-header',
    cellClassName: 'table-row',
    disableExport: true,
    renderCell: params => {
      const { value } = params;
      const toolTipText = sourceMapping(value);
      const handleClick = () => {
        window.open(params.row.link, '_blank');
      };

      return (
        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          <Tooltip title={toolTipText === 'No tool tip text' ? '' : toolTipText || value}>
            <Typography align='center' variant='subtitle1' style={{ marginRight: '4px' }}>
              {params.row.source}
            </Typography>
          </Tooltip>
          <IconButton aria-label='Open PDF in new tab' onClick={handleClick}>
            <OpenInNewIcon fontSize='small' />
          </IconButton>
        </div>
      );
    }
  },
  // this column will not be displayed on the UI but for csv purpose
  {
    field: 'source_csv',
    headerName: 'Source',
    width: 150,
    headerClassName: 'table-header',
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.source}`
  },
  // this column will not be displayed on the UI but for csv purpose
  {
    field: 'link',
    headerName: 'Link',
    width: 150,
    headerClassName: 'table-header',
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 450,
    headerClassName: 'table-header',
    cellClassName: 'table-row'
  },
  {
    field: 'value',
    headerName: 'Value',
    width: 170,
    headerClassName: 'table-header',
    cellClassName: 'table-row',
    sortComparator: (a, b) => {
      return a - b;
    },
    renderCell: DatagridTooltip,
    toolTipValueGetter: ({ row }) => hoverValueFormatting(row.value),
    formattedValueGetter: ({ row }) => valueFormatting(row.value)
  },
  {
    field: 'updated_at',
    headerName: 'Last Updated',
    width: 140,
    headerClassName: 'table-header',
    cellClassName: 'table-row',
    sortComparator: sortDateComparator
  },
  {
    field: 'next_refresh',
    headerName: 'Refresh Scheduled',
    width: 140,
    headerClassName: 'table-header',
    cellClassName: 'table-row',
    sortComparator: sortDateComparator
  }
];

export const DB_STATS_REGION_MAP = {
  USFDA: 'US Food and Drug Administration',
  EUEMA: 'European Union - European Medicines Agency',
  UK: 'UK Medicines and Healthcare products Regulatory Agency',
  USNLM: 'US National Library of Medicine'
};

export const DB_STATS_SOURCE_MAP = {
  EPAR: 'European Public Assessment Reports',
  NICE: 'National Institute for Health and Care Excellence',
  CBER: 'Center for Biologics Evaluation and Research',
  CDER: 'Center for Drug Evaluation and Research',
  EUA: 'Emergency Use Authorization',
  CDRH: 'Center for Devices and Radiological Health',
  PSG: 'Product Specific Guidance',
  ADCOMMS: 'Advisory Committees',
  PIPS: 'Paediatric Investigation Plans',
  CTGOV: 'Clinical Trials Gov',
  ORANGEBOOK: 'Orange Book',
  EUCOMMISSION: 'European Commission'
};

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const DB_STATS_REGION_SPACE_MAP = {
  usfda: 'US FDA',
  euema: 'EU EMA',
  usnlm: 'US NLM'
};

export const WHATS_NEW_TABS = {
  all: 'All',
  majorUpdates: 'Major Updates',
  enhancementUpdates: 'Enhancement Updates',
  oldUpdates: 'Old Updates'
};

export const WHATS_NEW_TOOLTIPS = {
  [WHATS_NEW_TABS.majorUpdates]:
    'Additions of new sources or tools, or significant platform overhauls that expand your capabilities with RIA',
  [WHATS_NEW_TABS.enhancementUpdates]:
    "Updates that enhance the platform's performance and user experience, facilitating smoother completion of tasks."
};

export const WHATS_NEW_LIMITS = {
  listTitle: 42
};
