import { createSvgIcon } from '@mui/material';

const ActivityIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='15' viewBox='0 0 14 15' fill='none'>
    <path d='M7 0.5C5.27675 0.505213 3.61567 1.14432 2.33333 2.2955V0.5H1.16667V3.50008C1.16698 3.78728 1.2812 4.06263 1.48428 4.26572C1.68737 4.4688 1.96272 4.58302 2.24992 4.58333H5.25V3.41667H2.84608C3.79415 2.45625 5.0463 1.85485 6.38859 1.71522C7.73088 1.57558 9.08001 1.90639 10.2055 2.65111C11.3309 3.39583 12.1628 4.50824 12.5591 5.79829C12.9553 7.08833 12.8913 8.47595 12.378 9.72404C11.8647 10.9721 10.9339 12.0033 9.74469 12.6412C8.55547 13.2792 7.18162 13.4844 5.85788 13.2218C4.53415 12.9592 3.34267 12.2451 2.48703 11.2015C1.63139 10.1579 1.16469 8.84953 1.16667 7.5H0C0 8.88447 0.410543 10.2378 1.17971 11.389C1.94888 12.5401 3.04213 13.4373 4.32122 13.9672C5.6003 14.497 7.00777 14.6356 8.36563 14.3655C9.7235 14.0954 10.9708 13.4287 11.9497 12.4497C12.9287 11.4708 13.5954 10.2235 13.8655 8.86563C14.1356 7.50777 13.997 6.1003 13.4672 4.82122C12.9373 3.54213 12.0401 2.44888 10.889 1.67971C9.73785 0.910543 8.38447 0.5 7 0.5V0.5Z' />
    <path d='M6.41699 4.58295V7.74111L8.3379 9.66203L9.16273 8.8372L7.58366 7.25811V4.58295H6.41699Z' />
  </svg>,
  'Activity'
);

export default ActivityIcon;
