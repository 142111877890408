const styles = {
  gridItems: {
    display: 'flex',
    height: '100%'
  },
  boxContainer: {
    width: '100%',
    height: '100%'
  },
  graphContainer: {
    padding: 2,
    backgroundColor: 'white.main',
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  heading: {
    color: 'grey.800',
    fontFamily: 'Mulish',
    fontSize: 16,
    fontWeight: 700
  }
};

export default styles;
