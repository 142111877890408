import { DATAGRID_UNIQUE_FIELD } from '../../../ResultsPage/utils/constants';
import transformData from '../../utils/dataTransform';
import { INDICATION_SEQUENCING_VALID_SOURCE } from './constant';

const groupIndicationTermByYear = (data: any, source: string, isReport: boolean = false) => {
  let appArray = transformData(data);
  appArray =
    source !== 'all'
      ? appArray.filter(
          (obj: { source: string }) =>
            obj?.source === source && INDICATION_SEQUENCING_VALID_SOURCE.includes(source)
        )
      : appArray.filter((obj: { source: string }) =>
          INDICATION_SEQUENCING_VALID_SOURCE.includes(obj?.source)
        );
  const applicationList = isReport ? appArray.slice(0, 50) : appArray;
  const result: any = {};
  const applList: Array<string> = [];
  const searchTerms: Array<string> = [];
  applicationList.forEach((item: any) => {
    const key: string = DATAGRID_UNIQUE_FIELD[item?.source];
    applList.push(item[key]);
    const year = item?.approval_year;
    if (!result[year]) {
      result[year] = {
        searchterm: {},
        approvalDate: {}
      };
    }
    item?.matched_submissions?.forEach((submission: { term: string; approval_date: string }) => {
      if (!searchTerms.includes(submission?.term)) {
        searchTerms.push(submission?.term);
      }

      const submissionYear = submission?.approval_date.split('-')[0];
      if (!result[submissionYear]) {
        result[submissionYear] = {
          searchterm: {},
          approvalDate: {}
        };
      }

      if (!result[submissionYear].searchterm[item[key]]) {
        result[submissionYear].searchterm[item[key]] = {
          id: item[key],
          ...item,
          matched_submissions: item?.matched_submissions.filter(
            (itemValue: { approval_year: any }) => itemValue?.approval_year === submissionYear
          )
        };
      }
      if (!result[year].approvalDate[item[key]]) {
        result[year].approvalDate[item[key]] = {
          id: item[key],
          ...item
        };
      }
    });
  });

  return {
    appArray: applicationList,
    result,
    searchTerms,
    applList
  };
};

export default groupIndicationTermByYear;
