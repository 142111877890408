import { createSvgIcon } from '@mui/material';

const MyProjectsIcon = createSvgIcon(
  <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M12.25 2.25016H7.13767L4.80433 1.0835H1.75C1.28587 1.0835 0.840752 1.26787 0.512563 1.59606C0.184374 1.92425 0 2.36937 0 2.8335L0 13.9168H14V4.00016C14 3.53603 13.8156 3.09091 13.4874 2.76273C13.1592 2.43454 12.7141 2.25016 12.25 2.25016ZM1.75 2.25016H4.529L6.86233 3.41683H12.25C12.4047 3.41683 12.5531 3.47829 12.6625 3.58768C12.7719 3.69708 12.8333 3.84545 12.8333 4.00016V4.51408L1.16667 4.58V2.8335C1.16667 2.67879 1.22812 2.53041 1.33752 2.42102C1.44692 2.31162 1.59529 2.25016 1.75 2.25016ZM1.16667 12.7502V5.74666L12.8333 5.68075V12.7502H1.16667Z' />
    <g clipPath='url(#clip0_1838_5027)'>
      <path d='M8.36094 11.3125L6.99977 10.3121L5.63859 11.3125L6.16122 9.69689L4.80078 8.70286H6.48042L6.99977 7.08447L7.51911 8.70286H9.19857L7.83831 9.69689L8.36094 11.3125Z' />
    </g>
    <defs>
      <clipPath id='clip0_1838_5027'>
        <rect width='4.375' height='4.375' fill='white' transform='translate(4.8125 7.0625)' />
      </clipPath>
    </defs>
  </svg>,
  'MyProjects'
);

export default MyProjectsIcon;
