import React, { useContext, useState, FC } from 'react';

// material ui
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// icons
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// styled components
import FiltersTextField from '../styledComponents/FiltersTextField';

// context
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';
import AriaStore from '../../../store/Aria';
import AriaActions from '../../../store/Aria/actions';

// styles
import styles from '../styles/Filters.styles';
import { FilterSearchFieldProps } from '../../ResultsPage/components/SearchFilter';

const FilterSearchField: FC<FilterSearchFieldProps> = ({
  setTextInSearch,
  textInSearch,
  showTextInSearchError,
  setShowTextInSearchError,
  disabled = false
}) => {
  const { dispatch } = useContext(GlobalStore) as any;
  const { ariaState, ariaDispatch } = useContext(AriaStore) as any;

  // states for search by keyword
  const [showTextField, setShowTextField] = useState(false);

  /**
   * function to add a list of searched keywords to the filters field
   * this list of keywords is matched with the aria results desctiption
   * @param {object} e event handler
   */
  const handleSearch = async (e: any) => {
    if (!disabled) {
      e.preventDefault();
      const currentFilters = ariaState.filters || {};

      const currentSearchTerms = ariaState.filters?.search_terms || [];
      // check if search keyword exists to avoid duplication
      if (!currentSearchTerms.includes(textInSearch?.toLowerCase()?.trim())) {
        currentSearchTerms.push(textInSearch?.toLowerCase()?.trim());
        // update the filters
        currentFilters.search_terms = currentSearchTerms;

        ariaDispatch({ type: AriaActions.SET_PENDING_FILTERS, value: true });
        await ariaDispatch({ type: AriaActions.SET_FILTERS, value: currentFilters });
        setShowTextField(false);
      } else {
        await dispatch({
          type: GlobalActions.SET_ALERT,
          value: {
            message: 'Keyword has been already selected. Please enter a new keyword',
            status: true
          }
        });
      }
      if (showTextInSearchError) {
        setShowTextInSearchError(false);
      }
      setTextInSearch('');
    }
  };

  /**
   * funtion to remove the keyword from the search terms list
   * @param {object} e event handler
   * @param {string} val keyword to be removed
   */
  const removeFromKeywords = async (e: any, val: any) => {
    e.preventDefault();
    const currentFilters = ariaState.filters || {};
    if (currentFilters?.search_terms) {
      const updatedSearchTerms =
        currentFilters?.search_terms.filter((keyword: any) => keyword !== val) || [];
      currentFilters.search_terms = updatedSearchTerms;
      if (showTextInSearchError) {
        setShowTextInSearchError(false);
      }
      await ariaDispatch({ type: AriaActions.SET_FILTERS, value: currentFilters });
    }
  };

  return (
    <Stack spacing={1}>
      <Typography variant='subtitle1'>Search keyword</Typography>
      {ariaState.filters?.search_terms &&
        ariaState.filters?.search_terms?.map((val: any) => (
          <Stack key={val} direction='row' sx={styles.selectedOptionsContainer}>
            <CloseIcon
              fontSize='small'
              sx={styles.closeIcon}
              onClick={e => {
                if (!disabled) removeFromKeywords(e, val);
              }}
            />
            <Typography variant='subtitle2'>
              {val.length > 15 ? `${val.substring(0, 15)}...` : val}
            </Typography>
          </Stack>
        ))}
      {showTextField ? (
        <>
          <form onSubmit={handleSearch}>
            <Stack direction='row' spacing={1} alignItems='center'>
              <FiltersTextField
                placeholder='Search here'
                variant='outlined'
                required
                value={textInSearch}
                disabled={disabled}
                onChange={e => {
                  if (showTextInSearchError) {
                    setShowTextInSearchError(false);
                  }
                  setTextInSearch(e.target.value);
                }}
                error={showTextInSearchError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        // onClick={handleSearch}
                        type='submit'
                        // onMouseDown={handleMouseDownPassword}
                        edge='end'>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Tooltip title='Search Keyword based on Description'>
                <IconButton edge='end'>
                  <InfoOutlinedIcon
                    sx={{ color: 'primary.main', cursor: 'pointer' }}
                    fontSize='small'
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          </form>
          <Typography
            ml={5}
            onClick={handleSearch}
            color='primary'
            sx={styles.clearText}
            variant='subtitle2'>
            Search term
          </Typography>
        </>
      ) : null}
      {!showTextField ? (
        <Button
          id='popup'
          disabled={disabled}
          onClick={() => setShowTextField(true)}
          sx={styles.btn}>
          +Add
        </Button>
      ) : null}
    </Stack>
  );
};

export default FilterSearchField;
