import React, { useContext, useEffect, useMemo, useRef } from 'react';

// store
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import ResultsStore from '../../../store/SearchResults';
import useBusinessIntelligenceAdverseEventsData from '../../ClinicalTrials/TimelineCT/hooks/useBusinessIntelligenceAdverseEventsData';

import VISUALIZE_CHART_MAPPING, { EUCT_PLOT_GRAPH } from '../../Insights/const';
import Insights from '../../Insights/Insights';
import PlotChart from '../../Insights/PlotChart';
import styles from './EUCTGraph.styles';

const EUCTBusinessIntellegence = () => {
  const { resultsState } = useContext(ResultsStore);
  const { fetchEUCTBusinessSearchResultsFromId } = useBusinessIntelligenceAdverseEventsData();

  const isSearchIdChangedRef = useRef(true);

  useEffect(() => {
    isSearchIdChangedRef.current = true;
  }, [resultsState?.searchIds?.ctSearchId]);

  useEffect(() => {
    if (isSearchIdChangedRef.current) {
      fetchEUCTBusinessSearchResultsFromId(resultsState?.searchIds?.ctSearchId);
      isSearchIdChangedRef.current = false;
    }
  }, []);

  const formatdata = (data: any[]) => {
    const categories = data.map(item => item.nctId || item.euct_id);
    const keys = data.reduce((ele, item) => {
      Object.keys(item).forEach(key => {
        if (key !== 'euct_id' && !ele.includes(key) && item[key] !== 0) {
          ele.push(key);
        }
      });
      return ele;
    }, []);

    const traces = keys.map((key: string | number) => ({
      x: categories,
      y: data.map(item => item[key] || 0),
      name: key,
      type: 'bar'
    }));
    return traces;
  };

  const getLayer1Charts = useMemo(() => {
    const ctGraph = VISUALIZE_CHART_MAPPING['clinical-trials-eu']?.layer1;

    if (ctGraph) {
      ctGraph.forEach(graph => {
        if (graph?.id === 'study_completion_year') {
          // eslint-disable-next-line no-param-reassign
          graph.data = resultsState?.ctBussinessIntelligence?.year_graph;
        }
        if (graph?.id === 'phase') {
          // eslint-disable-next-line no-param-reassign
          graph.data = resultsState?.ctBussinessIntelligence?.phase_graph;
        }
      });

      return ctGraph;
    }
    return ctGraph;
  }, [resultsState?.ctBussinessIntelligence]);

  const getLayer2Charts = useMemo(() => {
    const ctGraph = VISUALIZE_CHART_MAPPING['clinical-trials-eu']?.layer2;

    if (ctGraph) {
      ctGraph.forEach(graph => {
        if (graph?.id === 'phase') {
          // eslint-disable-next-line no-param-reassign
          graph.data = resultsState?.ctBussinessIntelligence?.phase_graph;
        }
      });

      return ctGraph;
    }
    return ctGraph;
  }, [resultsState?.ctBussinessIntelligence]);

  const getGraphData = useMemo(() => {
    const ctGraph = EUCT_PLOT_GRAPH;

    if (ctGraph) {
      ctGraph.data = formatdata(resultsState?.ctBussinessIntelligence?.enrollment_by_trial ?? []);
      return ctGraph;
    }
    return ctGraph;
  }, [resultsState?.ctBussinessIntelligence]);

  return (
    <Grid height='100%' m={0} container spacing={2}>
      <Grid item xs={6} p='0px !important' sx={styles.gridItems}>
        <Insights
          statsList={[]}
          isStats={false}
          isLoading={resultsState.ctBusinessIntelligenceLoading || resultsState.loading}
          source='euctr'
          searchType='quicksearch'
          appliedFilter={{}}
          layer1Component={getLayer1Charts}
          layer2Component={getLayer2Charts}
          searchId={resultsState.searchIds.ctSearchId}
        />
      </Grid>

      <Grid item xs={6} p='0px !important' sx={styles.gridItems}>
        <Box display='flex' flexDirection='column' ml={2} sx={styles.boxContainer}>
          <Grid item xs={12} sx={styles.graphContainer}>
            <Box height='10%' display='flex' justifyContent='space-between'>
              {!(resultsState.ctBusinessIntelligenceLoading || resultsState.loading) ? (
                <Typography sx={styles.heading}>Number of Patients by Trial</Typography>
              ) : (
                <>
                  <Skeleton height='100%' width='10%' />
                  <Skeleton height='100%' width='10%' />
                </>
              )}
            </Box>
            <PlotChart
              graphData={getGraphData}
              loading={resultsState.ctBusinessIntelligenceLoading || resultsState.loading}
            />
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default React.memo(EUCTBusinessIntellegence);
