import { useCallback, useContext, useEffect } from 'react';
import Papa from 'papaparse';
import XLSX from 'xlsx';

// api
import {
  getAllDocuments,
  getInsights,
  postDrugDevelopmentTools
} from '../../../api/pages/DrugDevelopmentTools';

// store
import DrugDevelopmentToolsStore from '../../../store/DrugDevelopmentTools';
import actions from '../../../store/DrugDevelopmentTools/actions';
import { constructPieChartData } from '../utils/getChartStructure';
import globalActions from '../../../store/actions';
import globalStore from '../../../store/index';

import getFilters from '../utils/common';
import {
  DD_TOOLS_COLUMN_NAME_MAPPING,
  EXPORT_TYPE,
  PAGINATION_DEFAULT_OPTIONS
} from '../constants';
import { getFileName } from '../../../helpers/getFileName';
import transformData from '../../../utils/transformData';
import { getProjectDetailsForCSV, getSortList } from '../utils/getProjectDetails';

import AuthContext from '../../../store/Auth/AuthProvider';

import { getDataGridFilter } from '../../../utils/getDatagridFilterOption';

const useDrugDevelopmentTools = () => {
  const { DrugDevelopmentToolsState, DrugDevelopmentToolsDispatch } =
    useContext(DrugDevelopmentToolsStore);
  const { state, dispatch }: any = useContext(globalStore);
  const { currentUser } = useContext(AuthContext);

  const getDDToolsTableData = async (payload: any, limit: number, offset: number) => {
    const res = await postDrugDevelopmentTools(payload, limit, offset);
    if (res?.data?.status === 200) {
      DrugDevelopmentToolsDispatch({
        type: actions.SET_PROJECTS,
        value: res?.data?.body?.data ?? []
      });

      DrugDevelopmentToolsDispatch({
        type: actions.SET_TOTAL_COUNT,
        value: res?.data?.body?.total_count ?? 0
      });
    }
  };

  const getInsightData = async (filters: any, appliedFilters: any) => {
    const res = await getInsights({ filters });
    if (res?.data?.status === 200) {
      const insights = res?.data?.body?.insights ?? {};

      const stats = {
        total: insights?.total_ddt_application ?? 0,
        totalLastThirtyDays: insights?.last_30_days_count ?? 0,
        totalDocuments: insights?.pdf_stats?.total_pdf_count ?? 0,
        totalPages: insights?.pdf_stats?.total_page_count ?? 0,
        totalAcceptedStatusProjects: insights?.total_accept_status_application ?? 0
      };

      const graphs = constructPieChartData(insights?.donut_graph_data);

      DrugDevelopmentToolsDispatch({
        type: actions.SET_CHARTS,
        value: graphs
      });

      DrugDevelopmentToolsDispatch({
        type: actions.SET_PROGRAM_TYPE_CHART,
        value: insights?.donut_graph_data?.program_type_desc ?? {}
      });

      DrugDevelopmentToolsDispatch({
        type: actions.SET_SUBMISSION_STATUS_CHART,
        value: insights?.donut_graph_data?.submission_status ?? {}
      });

      DrugDevelopmentToolsDispatch({
        type: actions.SET_BAR_GRAPH,
        value: insights?.bar_graph_data?.filter((item: any) => item.count > 0) ?? []
      });

      DrugDevelopmentToolsDispatch({
        type: actions.SET_STATS,
        value: stats
      });

      const newFilters = getFilters(res?.data?.body?.filters ?? {});

      DrugDevelopmentToolsDispatch({
        type: actions.SET_FILTERS,
        value: newFilters
      });

      DrugDevelopmentToolsDispatch({
        type: actions.SET_ASK_RIA_FILTERS,
        value: res?.data?.body?.filters?.project_number || []
      });

      DrugDevelopmentToolsDispatch({
        type: actions.SET_AVAILABLE_FILTERS,
        value: appliedFilters
      });
    }
  };

  // Hook to fetch data based on datagrid filter
  const getDatawithDataGridFilter = async (
    appliedFilters: any,
    datagridFilter: any,
    sortFields: any,
    limit: number = PAGINATION_DEFAULT_OPTIONS.limit,
    offset: number = PAGINATION_DEFAULT_OPTIONS.offset
  ) => {
    DrugDevelopmentToolsDispatch({
      type: actions.SET_IS_LOADING,
      value: true
    });

    const source = {
      us: ['dd-tools']
    };
    const res: any = await getDataGridFilter(
      appliedFilters,
      datagridFilter,
      sortFields,
      limit,
      offset,
      DrugDevelopmentToolsState,
      currentUser,
      source
    );

    if (res?.data?.status === 200) {
      DrugDevelopmentToolsDispatch({
        type: actions.SET_PROJECTS,
        value: res?.data?.body?.result?.['dd-tools']?.results ?? []
      });

      DrugDevelopmentToolsDispatch({
        type: actions.SET_TOTAL_COUNT,
        value: res?.data?.body?.result?.['dd-tools']?.total ?? 0
      });
      DrugDevelopmentToolsDispatch({
        type: actions.SET_DATA_GRID_FILTERS,
        value: datagridFilter
      });
    }

    DrugDevelopmentToolsDispatch({
      type: actions.SET_IS_LOADING,
      value: false
    });
  };

  const getDrugDevelopmentTools = useCallback(
    async (
      appliedFilters: any,
      limit: number = PAGINATION_DEFAULT_OPTIONS.limit,
      offset: number = PAGINATION_DEFAULT_OPTIONS.offset,
      sort: Array<any> = [],
      shouldGetInsight: boolean = true,
      dataGridFilter: any = {}
    ) => {
      DrugDevelopmentToolsDispatch({
        type: actions.SET_IS_LOADING,
        value: true
      });

      DrugDevelopmentToolsDispatch({
        type: actions.SET_IS_FILTERS_APPLIED,
        value: Object.keys(appliedFilters).length !== 0
      });

      const payload: any = {};
      payload.filters = { ...appliedFilters };
      if (sort) {
        payload.sort = getSortList(sort);
      }

      if (Object.keys(dataGridFilter).length !== 0) {
        await getDatawithDataGridFilter(appliedFilters, dataGridFilter, sort, limit, offset);
      } else {
        try {
          await getDDToolsTableData(payload, limit, offset);

          if (shouldGetInsight) {
            await getInsightData(payload?.filters, appliedFilters);
          }
          DrugDevelopmentToolsDispatch({
            type: actions.SET_DATA_GRID_FILTERS,
            value: {}
          });
          DrugDevelopmentToolsDispatch({
            type: actions.SET_IS_LOADING,
            value: false
          });
        } catch (error) {
          DrugDevelopmentToolsDispatch({
            type: actions.SET_IS_LOADING,
            value: false
          });

          // eslint-disable-next-line no-console
          console.log(error);
        } finally {
          DrugDevelopmentToolsDispatch({
            type: actions.SET_IS_LOADING,
            value: false
          });
        }
      }
    },
    []
  );

  const handleCSVExport = async () => {
    DrugDevelopmentToolsDispatch({
      type: actions.SET_IS_LOADING,
      value: true
    });
    const res = await getAllDocuments({
      filters: DrugDevelopmentToolsState.availableFilters
    });
    const selectedColumns = state?.exportType?.isAllColumnsSelected
      ? DD_TOOLS_COLUMN_NAME_MAPPING
      : Object.fromEntries(
          Object.entries(DD_TOOLS_COLUMN_NAME_MAPPING).filter(([key]) =>
            state?.exportType?.selectedColumns?.includes(key)
          )
        );
    const formattedData = transformData(
      getProjectDetailsForCSV(res?.data?.body?.data),
      selectedColumns
    );
    const csv = Papa.unparse(formattedData);
    // Create a Blob from the CSV string
    const blob = new Blob([csv], { type: 'text/csv' });
    // Create a download link and click it
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    const day = new Date();
    const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
    const fileName = getFileName({
      prefix: 'ria',
      module: 'dd_tools',
      postfix: date,
      additional: '',
      extension: 'csv'
    });
    a.download = `${fileName}.csv`;
    a.click();
    dispatch({
      type: globalActions.SET_EXPORT_TYPE,
      value: { format: '', selectedColumns: [], isAllColumnsSelected: false }
    });
    DrugDevelopmentToolsDispatch({
      type: actions.SET_IS_LOADING,
      value: false
    });
  };

  const handleExcelExport = async () => {
    DrugDevelopmentToolsDispatch({
      type: actions.SET_IS_LOADING,
      value: true
    });
    const res = await getAllDocuments({
      filters: DrugDevelopmentToolsState.availableFilters
    });
    const selectedColumns = state?.exportType?.isAllColumnsSelected
      ? DD_TOOLS_COLUMN_NAME_MAPPING
      : Object.fromEntries(
          Object.entries(DD_TOOLS_COLUMN_NAME_MAPPING).filter(([key]) =>
            state?.exportType?.selectedColumns?.includes(key)
          )
        );

    const formattedData = transformData(
      getProjectDetailsForCSV(res?.data?.body?.data),
      selectedColumns
    );
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const day = new Date();
    const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
    const fileName = getFileName({
      prefix: 'ria',
      module: 'dd_tools',
      postfix: date,
      additional: '',
      extension: 'xlsx'
    });
    XLSX.writeFile(wb, `${fileName}.xlsx`);
    dispatch({
      type: globalActions.SET_EXPORT_TYPE,
      value: { format: '', selectedColumns: [], isAllColumnsSelected: false }
    });
    DrugDevelopmentToolsDispatch({
      type: actions.SET_IS_LOADING,
      value: false
    });
  };

  useEffect(() => {
    if (
      state?.exportType?.format &&
      [EXPORT_TYPE.csv, EXPORT_TYPE.xlsx].includes(state?.exportType?.format)
    ) {
      if (state?.exportType?.format === EXPORT_TYPE.csv) {
        handleCSVExport();
      } else if (state?.exportType?.format === EXPORT_TYPE.xlsx) {
        handleExcelExport();
      }
    }
  }, [state?.exportType]);

  return {
    filters: DrugDevelopmentToolsState.filters,
    projects: DrugDevelopmentToolsState.projects,
    isLoading: DrugDevelopmentToolsState.isLoading,
    isFiltersApplied: DrugDevelopmentToolsState.isFiltersApplied,
    getDrugDevelopmentTools,
    sortFields: DrugDevelopmentToolsState.sortFields,
    getDatawithDataGridFilter,
    dataGridFilter: DrugDevelopmentToolsState.dataGridFilters
  };
};

export default useDrugDevelopmentTools;
