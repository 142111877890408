const styles: any = {
  heading: {
    color: 'black.light',
    fontFamily: 'Mulish',
    fontSize: 14,
    fontWeight: '700'
  },
  divider: {
    marginX: '8px',
    borderColor: 'gray.300',
    height: '24px',
    borderRightWidth: '1px'
  },
  changeButton: {
    cursor: 'pointer',
    color: 'secondary.700',
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontWeight: '700'
  },
  backArrow: {
    color: 'gray.700',
    width: '24px',
    height: '24px'
  },
  reg360: { width: '40px', height: '28px', color: 'gray.700' }
};

export default styles;
