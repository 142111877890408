import { createSvgIcon } from '@mui/material';

const ProjectIcon = createSvgIcon(
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_14631_35642)'>
      <path
        d='M14 2.00008H8.15733L5.49067 0.666748H2C1.46957 0.666748 0.960859 0.877462 0.585786 1.25253C0.210714 1.62761 0 2.13631 0 2.66675L0 15.3334H16V4.00008C16 3.46965 15.7893 2.96094 15.4142 2.58587C15.0391 2.2108 14.5304 2.00008 14 2.00008ZM2 2.00008H5.176L7.84267 3.33341H14C14.1768 3.33341 14.3464 3.40365 14.4714 3.52868C14.5964 3.6537 14.6667 3.82327 14.6667 4.00008V4.58741L1.33333 4.66275V2.66675C1.33333 2.48994 1.40357 2.32037 1.5286 2.19534C1.65362 2.07032 1.82319 2.00008 2 2.00008ZM1.33333 14.0001V5.99608L14.6667 5.92075V14.0001H1.33333Z'
        fill='currentColor'
      />
      <g clipPath='url(#clip1_14631_35642)'>
        <path
          d='M9.55557 12.3572L7.99994 11.2139L6.44432 12.3572L7.04161 10.5108L5.48682 9.37473H7.4064L7.99994 7.52515L8.59348 9.37473H10.5129L8.95827 10.5108L9.55557 12.3572Z'
          fill='currentColor'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_14631_35642'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
      <clipPath id='clip1_14631_35642'>
        <rect width='5' height='5' fill='white' transform='translate(5.5 7.5)' />
      </clipPath>
    </defs>
  </svg>,
  'ProjectIcon'
);
export default ProjectIcon;
