export const horizontalTimelineStyle: any = {
  cursor: 'grab',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  borderBottom: '1px solid #e0e0e0',
  borderRight: '1px solid #e0e0e0'
};
export const horizontalTimelineContainerBeforeStyle: any = {
  content: '""',
  position: 'absolute',
  top: '53%',
  left: '0',
  border: '1px solid',
  borderColor: 'rgb(46, 204, 113)',
  borderStyle: 'dashed',

  height: 0,
  transform: 'translateY(-50%)'
};
