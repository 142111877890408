import {
  Box,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack
} from '@mui/material';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import UserAvatar from './UserAvatar';
import styles from '../../styles/Navigation.styles';
import ProjectList from './ProjectsNavigationList';
import { SERVICE_REQUESTS_NAV_BAR, USER_PROFILE_NAV_BAR } from '../../constants';

const Navigation = () => {
  const history = useHistory();
  const location = useLocation();
  const handleClick = (page: string) => {
    if (page) {
      history.push(`/account/${page}`);
    }
  };
  return (
    <Box display='flex' flexDirection='column' sx={styles.rootBox}>
      <UserAvatar />
      <Stack
        spacing='8px'
        sx={styles.rootList}
        component='nav'
        aria-labelledby='nested-list-subheader'>
        {USER_PROFILE_NAV_BAR.map(({ text, icon: Icon, path }) => (
          <ListItem key={path} disablePadding>
            <ListItemButton
              sx={styles.navigationMenuItem}
              selected={Boolean(
                location.pathname.match(path) || location.pathname === `/account/${path}`
              )}
              onClick={() => handleClick(path)}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={text} sx={styles.listItemText} />
            </ListItemButton>
          </ListItem>
        ))}
      </Stack>
      <ProjectList />
      <Divider sx={styles.divider} />
      <ListItem disablePadding>
        <ListItemButton
          sx={styles.navigationMenuItem}
          selected={
            Boolean(location.pathname.match(SERVICE_REQUESTS_NAV_BAR.path)) ||
            location.pathname === `/account/${SERVICE_REQUESTS_NAV_BAR.path}`
          }
          onClick={() => handleClick(SERVICE_REQUESTS_NAV_BAR.path)}>
          <ListItemIcon>
            <SERVICE_REQUESTS_NAV_BAR.Icon sx={styles.serviceRequestIcon} />
          </ListItemIcon>
          <ListItemText primary={SERVICE_REQUESTS_NAV_BAR.text} sx={styles.listItemText} />
        </ListItemButton>
      </ListItem>
    </Box>
  );
};

export default React.memo(Navigation);
