import React from 'react';
import { createSvgIcon } from '@mui/material';

const ViewIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='15' height='12' viewBox='0 0 15 12' fill='none'>
    <path
      d='M14.3955 5.52225C13.8833 4.40225 11.8749 0.75 7.49993 0.75C3.12493 0.75 1.11651 4.40225 0.604342 5.52225C0.535589 5.67234 0.5 5.83549 0.5 6.00058C0.5 6.16567 0.535589 6.32882 0.604342 6.47892C1.11651 7.59775 3.12493 11.25 7.49993 11.25C11.8749 11.25 13.8833 7.59775 14.3955 6.47775C14.4641 6.32783 14.4996 6.16488 14.4996 6C14.4996 5.83512 14.4641 5.67217 14.3955 5.52225ZM7.49993 10.0833C3.82084 10.0833 2.10409 6.95317 1.66659 6.00642C2.10409 5.04683 3.82084 1.91667 7.49993 1.91667C11.1703 1.91667 12.8876 5.03342 13.3333 6C12.8876 6.96658 11.1703 10.0833 7.49993 10.0833Z'
      fill='currentColor'
    />
    <path
      d='M7.49968 3.0835C6.92281 3.0835 6.35891 3.25456 5.87926 3.57504C5.39962 3.89553 5.02578 4.35105 4.80503 4.884C4.58427 5.41695 4.52651 6.0034 4.63905 6.56918C4.75159 7.13495 5.02938 7.65465 5.43728 8.06256C5.84518 8.47046 6.36488 8.74825 6.93066 8.86079C7.49644 8.97333 8.08288 8.91557 8.61584 8.69481C9.14879 8.47406 9.60431 8.10022 9.9248 7.62058C10.2453 7.14093 10.4163 6.57703 10.4163 6.00016C10.4154 5.2269 10.1078 4.48557 9.56105 3.93879C9.01427 3.39201 8.27294 3.08442 7.49968 3.0835ZM7.49968 7.75016C7.15356 7.75016 6.81521 7.64753 6.52743 7.45524C6.23964 7.26294 6.01534 6.98963 5.88289 6.66986C5.75043 6.35009 5.71578 5.99822 5.7833 5.65876C5.85083 5.31929 6.0175 5.00747 6.26224 4.76273C6.50698 4.51798 6.8188 4.35131 7.15827 4.28379C7.49773 4.21626 7.8496 4.25092 8.16937 4.38337C8.48914 4.51583 8.76246 4.74013 8.95475 5.02792C9.14704 5.3157 9.24968 5.65405 9.24968 6.00016C9.24968 6.46429 9.0653 6.90941 8.73711 7.2376C8.40892 7.56579 7.9638 7.75016 7.49968 7.75016Z'
      fill='currentColor'
    />
  </svg>,
  'View'
);

export default ViewIcon;
