const styles = {
  projectNavigationRoot: { mt: '24px' },
  heading: {
    color: 'grey.700',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '18px',
    marginBottom: '8px'
  },
  rootList: {
    mt: 0,
    pt: 0,
    pb: '8px',
    borderRadius: '16px',
    color: 'primary.800',
    maxHeight: '270px',
    overflow: 'auto',
    overflowX: 'none',
    '@media (max-width: 1280px)': {
      maxHeight: '100px'
    },
    '@media (max-height: 800px)': {
      maxHeight: '100px'
    }
  },
  navigationListItemText: {
    m: '0px'
  },
  navigationMenuItem: {
    marginBottom: '8px',
    color: 'grey.800',
    paddingX: '12px',
    paddingY: '6px',
    borderRadius: '8px',
    minWidth: '175px',
    '& span': {
      color: 'grey.700',
      fontSize: '14px',
      fontWeight: 600
    },

    '& svg': {
      margin: '2px',
      fontSize: '16px'
    },
    '&.Mui-selected': {
      backgroundColor: 'primary.50',
      '& span': {
        color: 'primary.800'
      },
      '& svg': {
        color: 'primary.800'
      }
    },

    '& > .MuiListItemIcon-root': {
      minWidth: 0,
      marginRight: '8px'
    }
  },
  projectIcon: {
    color: 'gray.800'
  },
  createProjecIconBG: {
    borderRadius: '50%',
    backgroundColor: 'gray.200',
    width: '18px',
    height: '18px'
  },
  menuItemAddIcon: {
    width: '14px',
    height: '14px',
    color: 'grey.700'
  }
};

export default styles;
