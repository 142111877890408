import React, { useContext, useEffect, useState } from 'react';
// MATERIAL UI IMPORTS
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
// other components
import MainStyles from '../styles/505b2.styles';
import Cards from './Cards';
import LineGraph from './LineGraph';
import Percentages from './Percentages';
// store
import Store from '../../../store';
import Actions from '../../../store/actions';
import { get505b2Stats } from '../../../api/pages/505b2';
import truncateCountAboveMillions from '../../../utils/truncateCountAboveMillions';
import formatCount from '../../../utils/formatCount';
// utils
import getPlaceholderText from '../../../pages/RegulatoryInformation/Header/utils/getPlaceholderText';

const BusinessIntelligence = ({
  accelerated_approval,
  fastrack,
  breakthrough_designation,
  yearGraph,
  activeIngredients,
  formulations,
  blaCount,
  orphan_status,
  onUpdatePlaceholderText
}) => {
  const classes = MainStyles();
  const { state, dispatch } = useContext(Store);
  const [percentages, setPercentages] = useState();
  const [linegraph, setLineGraph] = useState();
  const [cards, setCards] = useState();

  useEffect(() => {
    async function getData() {
      try {
        const response = await get505b2Stats(state.db);
        const percentagesData = {
          acceleratedApproval: accelerated_approval || response.data.Success.accelerated_approval,
          breakthroughDesignation:
            breakthrough_designation || response.data.Success.breakthrough_designation,
          fasttrack: fastrack || response.data.Success.fasttrack,
          orphan_status: orphan_status || response.data.Success.orphan_status
        };
        setPercentages(percentagesData);
        const approvaltimeGraphData = [];
        response.data.Success.median_approval_time.forEach(({ year, count }) => {
          const mean = response.data.Success.approval_time.find(elem => elem.year === year)?.count;
          if (parseInt(count) > 0) {
            approvaltimeGraphData.push({
              year,
              count: parseInt(count),
              mean: mean ? parseInt(mean) : 0
            });
          }
        });
        const approvalyearGraphData = [];
        for (const elem of yearGraph || response.data.Success.approval_year) {
          const { year } = elem;
          const count = parseInt(elem.count);
          if (count > 0) approvalyearGraphData.push({ count, year });
        }

        const lineGraphData = {
          approvalTimeGraph: approvaltimeGraphData,
          approvalYearGraph: approvalyearGraphData
        };
        setLineGraph(lineGraphData);
        setCards([
          {
            id: 'Active Ingredients',
            count: blaCount || response.data.Success.active_ingredients
          },
          {
            id: 'Formulations',
            count: activeIngredients || response.data.Success.formulations
          },
          {
            id: 'Routes',
            count: formulations || response.data.Success.route
          },
          {
            id: 'Total Documents | Pages',
            count: `${truncateCountAboveMillions(
              response.data.Success.document_count
            )} | ${truncateCountAboveMillions(response.data.Success.document_total_page_count)}`,
            toolTipValue: `${formatCount(response.data.Success.document_count)} | ${formatCount(
              response.data.Success.document_total_page_count
            )}`
          }
        ]);
        if (
          response.data?.Success.document_count > 0 &&
          response.data.Success.document_total_page_count > 0
        ) {
          onUpdatePlaceholderText(
            getPlaceholderText(
              response.data.Success.document_count,
              response.data.Success.document_total_page_count,
              state.db?.toLowerCase()
            )
          );
        }
      } catch (e) {
        console.error(e);
      }
    }

    getData();
  }, [
    accelerated_approval,
    activeIngredients,
    blaCount,
    breakthrough_designation,
    fastrack,
    formulations,
    orphan_status,
    state.db,
    yearGraph
  ]);

  const addProgressBars = async () => {
    await dispatch({
      type: Actions.SET_CART_ITEMS,
      value: state.cartItems + 1
    });
    await dispatch({
      type: Actions.SET_REPORT,
      value: { id: 'Progress Bars', title: '505(b)(2)', data: percentages }
    });
    await dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message: 'Successfully added to Report.', color: 'success' }
    });
  };
  return (
    <Grid container>
      <Grid item lg={2}>
        <Cards data={cards} />
      </Grid>
      <Grid item lg={10}>
        <Grid item id='approvalPathwayStats' lg={12}>
          <LineGraph data={linegraph} />
        </Grid>
        <Grid item lg={12}>
          <Box className={classes.percentageGraphsContainer}>
            <Box
              mt={5}
              display='flex'
              alignItems='center'
              justifyContent='flex-end'
              className={classes.percentages}></Box>
            <Percentages data={percentages} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BusinessIntelligence;
