// import { concat } from 'lodash';
import { GridColDef } from '@mui/x-data-grid-pro';
import renderCellExpand from '../Datagrid/DatagridPopover';
import DatagridColorNumber from './components/DatagridColorNumber/DatagridColorNumber';
import DatagridTooltip from '../Datagrid/DatagridTooltip';
import DatagridArrayAvatars from '../Datagrid/DatagridArrayAvatars';
import { Filter } from '../Filters';
import { FILTER_TYPE } from '../NewFilters/constants';

import { PIECHARTCOLOR } from '../CustomComponents/Graphs/constant';

type CustomGridColDef = GridColDef & {
  capitalCasing?: boolean;
  source?: string;
};

export interface EUCTRPediatricsFilters extends Filter {
  apiRequestKey: string;
  apiResponseKey?: string;
}
export const EUCTRPEDIATRICSCOLUMN: CustomGridColDef[] = [
  {
    field: 'paediatric_trial_id',
    headerName: 'Trial ID',
    width: 150,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridColorNumber
  },
  {
    field: 'study_title',
    headerName: 'Study Title',
    width: 350,
    cellClassName: 'table-row',
    headerClassName: 'table-header',
    renderCell: DatagridTooltip
  },

  {
    field: 'therapeutic_area',
    headerName: 'Therapeutic Area',
    width: 300,
    cellClassName: 'ria-MuiDataGrid-cell',
    headerClassName: 'table-header',
    renderCell: DatagridTooltip
  },
  {
    field: 'type_of_medicine',
    headerName: 'Type of Medicine',
    width: 300,
    cellClassName: 'ria-MuiDataGrid-cell',
    headerClassName: 'table-header',
    renderCell: DatagridTooltip
  },
  {
    field: 'age_category',
    headerName: 'Age Category',
    width: 150,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridArrayAvatars,
    valueGetter: ({ row }) =>
      `${row.age_category && row.age_category.length ? row.age_category.join(', ') : '---'}`,
    // @ts-ignore
    source: 'euctr_ped',
    sortable: false
  },
  {
    field: 'study_number',
    headerName: 'Study Number',
    width: 150,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridTooltip
  },
  {
    field: 'active_substance',
    headerName: 'Active Ingredients',
    width: 200,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    capitalCasing: false,
    renderCell: DatagridTooltip
  },
  {
    field: 'atc_code',
    headerName: 'ATC Code',
    width: 150,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridTooltip
  },
  {
    field: 'document_date',
    headerName: 'Document Date',
    width: 150,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridTooltip
  }
];

export const EUCTR_PEDIATRICS_FILTERS = [
  {
    id: 1,
    label: 'Active Ingredient',
    value: 'active_substance',
    elasticSearchType: 'active_substance',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Search Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'ATC Code',
    value: 'atc_code',
    elasticSearchType: 'atc_code',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Age Category',
    value: 'age_category',
    elasticSearchType: 'age_category',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Scope of Study',
    value: 'scope_of_study',
    elasticSearchType: 'scope_of_study',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Therapeutic Area',
    value: 'therapeutic_area',
    elasticSearchType: 'therapeutic_area',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 7,
    label: 'Type of Medicine',
    value: 'type_of_medicine',
    elasticSearchType: 'type_of_medicine',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  }
];

export const barChartStyles = {
  colors: ['#B1BBE4'],
  padding: 0.8,
  axisBottom: {
    tickRotation: 25
  },
  axisLeft: {
    tickRotation: 0
  }
};

export const pieChartStyles = {
  colors: PIECHARTCOLOR,
  margin: { top: 20, right: 20, bottom: 20, left: 20 },
  pieLabel: true,
  pieLegend: false,
  innerRadius: 0.6,
  enableLabels: 'both'
};
export const EUCTR_PEDIATRICS_QUERIES = [
  {
    id: 'age_category_graph',
    title: {
      title: 'Trials by Age Categories',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['age_category'], agg_function: 'count' },
    graphStyle: barChartStyles
  },
  {
    id: 'year_trials_graphs',
    title: {
      title: 'Trials By Year',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['document_date'], agg_function: 'count' },
    graphStyle: pieChartStyles
  },
  {
    id: 'type_of_medicine_graph',
    title: {
      title: 'Type of Medicine',
      show: true
    },
    graph: 'PIE',
    query: { 'x-axis': ['type_of_medicine'], agg_function: 'count' },
    graphStyle: pieChartStyles
  },
  {
    id: 'scope_of_study_graph',
    title: {
      title: 'Scope of Study',
      show: true
    },
    graph: 'PIE',
    query: { 'x-axis': ['scope_of_study'], agg_function: 'count' },
    graphStyle: pieChartStyles
  }
];
