import React from 'react';
import { createSvgIcon } from '@mui/material';

const DocumentLinkIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
    <path
      d='M6.173 11.9753C5.62054 12.513 4.87859 12.8115 4.10768 12.8064C3.33677 12.8012 2.59889 12.4927 2.05368 11.9477C1.50848 11.4026 1.19979 10.6648 1.19441 9.89392C1.18902 9.12301 1.48738 8.38098 2.02492 7.82837L4.51109 5.34046L3.68567 4.51562L1.20008 7.00354C0.43141 7.7726 -0.000273369 8.81551 1.2988e-07 9.90285C0.000273629 10.9902 0.432482 12.0329 1.20154 12.8016C1.9706 13.5702 3.01352 14.0019 4.10087 14.0017C5.18821 14.0014 6.23091 13.5692 6.99958 12.8001L9.48575 10.3139L8.66092 9.48912L6.173 11.9753Z'
      fill='#0076D6'
    />
    <path
      d='M12.8003 1.20226C12.4207 0.820073 11.9691 0.51701 11.4715 0.310637C10.974 0.104264 10.4404 -0.00131627 9.90173 1.43738e-05V1.43738e-05C9.36336 -0.0014158 8.83004 0.103893 8.33261 0.309853C7.83518 0.515812 7.38352 0.818335 7.00373 1.19993L4.51465 3.68668L5.33948 4.5121L7.8274 2.02593C8.09897 1.75273 8.42204 1.53611 8.7779 1.38862C9.13377 1.24112 9.51535 1.16569 9.90057 1.16668V1.16668C10.4806 1.16687 11.0475 1.33902 11.5296 1.66135C12.0118 1.98368 12.3876 2.44173 12.6095 2.97759C12.8314 3.51346 12.8894 4.10308 12.7762 4.67192C12.6631 5.24076 12.3838 5.76328 11.9737 6.17343L9.48757 8.6596L10.313 9.48502L12.7991 7.00001C13.5668 6.23065 13.9981 5.18824 13.9983 4.10138C13.9985 3.01452 13.5677 1.97193 12.8003 1.20226V1.20226Z'
      fill='#0076D6'
    />
    <path
      d='M8.65981 4.51598L4.51562 8.66016L5.34058 9.48511L9.48477 5.34094L8.65981 4.51598Z'
      fill='#0076D6'
    />
  </svg>,
  'DocumentLink'
);

export default DocumentLinkIcon;
