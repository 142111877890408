import React from 'react';
import { Box } from '@mui/material';
import { sourceFlagMapping } from '../../../pages/SearchResults/constants';

interface GridRenderCellParams {
  value?: string;
}
const DatagridApprovalTracks = (props: GridRenderCellParams = { value: '' }) => {
  const { value } = props;
  const FlagComponent = sourceFlagMapping[value as string];
  return <Box display='flex'>{FlagComponent && <FlagComponent sx={{ fontSize: 20 }} />}</Box>;
};

export default DatagridApprovalTracks;
