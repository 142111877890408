import React, { useContext } from 'react';
import { pathNameManipulation } from '../pages/ResultsPage/hoc/withFetchingSearchResults';

// context
import AriaStore from '../store/Aria';
import AriaActions from '../store/Aria/actions';

// api
import { getAriaQueryResults } from '../api/pages/Aria';

// utils
import getMaxPageNumber from '../pages/Aria/utils/getMaxPageNumber';

// constants
import { DOCUMENT_STATE, ERROR_MESSAGES } from '../pages/Aria/utils/constants';

const useAriaFilters = () => {
  // aria context store
  // @ts-ignore
  const { ariaState, ariaDispatch } = useContext(AriaStore);

  /**
   * function to remove a selected option from filters
   * @param {string} type filter category type
   * @param {string} value option value that needs to be removed
   * @param {string} filterType filter type
   */
  const removeFromFilters = (type: string, value: string, filterType: string) => {
    const currentFilters = ariaState.filters;
    if (filterType === 'range') {
      delete currentFilters[type];
    } else {
      const newFilters = currentFilters[type].filter((x: string) => x !== value);
      if (Array.isArray(newFilters) && !newFilters.length) {
        delete currentFilters[type];
      } else {
        currentFilters[type] = newFilters;
      }
    }
    ariaDispatch({ type: AriaActions.SET_PENDING_FILTERS, value: true });
    ariaDispatch({ type: AriaActions.SET_FILTERS, value: currentFilters });
  };

  /**
   * function to filter out the aria results with selected options from the user
   * @param {URLSearchParams} query object from the useQuery() hook
   * @param {string} module value from useParams()
   * @param {string} source value from useParams()
   * @param {string} searchId value from useParams()
   * @param {string} pathname value from useLocation()
   * @param {React.Dispatch<React.SetStateAction<boolean>>} setLoading optional parameter for setting loading
   * @param {React.Dispatch<React.SetStateAction<boolean>>} setclear optional parameter for setting clear
   */
  const applyFilter = async (
    query: URLSearchParams,
    module: string,
    source: string,
    searchId: string,
    pathname: string,
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>,
    setclear?: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (setLoading) setLoading(true);
    ariaDispatch({ type: AriaActions.SET_LOADING, value: true });
    // if the document view is opened, update these states to default
    ariaDispatch({ type: AriaActions.SET_DOCUMENT_OPEN, value: false });
    ariaDispatch({ type: AriaActions.SET_FILTERS_DRAWER, value: false });
    ariaDispatch({ type: AriaActions.SET_DOCUMENT_STATE, value: DOCUMENT_STATE.close });
    ariaDispatch({ type: AriaActions.SET_SELECTED_DOCUMENT, value: {} });

    // releases the existing object URL which was previously created by calling URL.createObjectURL()
    URL.revokeObjectURL(ariaState.documentLink?.split('#page=')[0]);

    ariaDispatch({ type: AriaActions.SET_SELECTED_SORT, value: 'Relevance: Very High -> Low' });
    // get deep copy of current state of filters
    let currentFilters = JSON.parse(JSON.stringify(ariaState.filters));
    // if this is a results page update the filters state with cached filter list
    if (query.get('resultsPage')) {
      const ariaCache = JSON.parse(sessionStorage.getItem('aria_cache') as string);
      const resultsFilterList = ariaCache?.ariaFilterList;
      // use cached filter list when same category is not exisiting in current filters
      if (resultsFilterList?.length) {
        currentFilters['group-id'] = ariaState.filters['group-id'] || resultsFilterList;
      }
      // required filters when not searching from whole index
      if (
        typeof ariaCache?.filterOptions === 'object' &&
        Object.keys(ariaCache?.filterOptions || {}).length > 0
      ) {
        currentFilters = { ...currentFilters, ...ariaCache?.filterOptions };
      }
    }

    const payload: any = {
      query: query.get('term'),
      filters: currentFilters
    };
    if (query.get('operationId')) {
      payload.search_operation_id = query.get('operationId');
    } else if (query.get('favorites')) {
      payload.search_from_favorites = true;
    } else if (query.get('projectId')) {
      payload.search_project_id = query.get('projectId');
    }
    payload.filterSource = ariaState.filters['group-id'] ? 'custom' : 'default';

    const res = await getAriaQueryResults(module, query.get('bucket') as string, source, payload);

    if (res && res.status === 200) {
      // make sure there is no existing error message from previouse searches
      ariaDispatch({ type: AriaActions.SET_ERROR, value: '' });

      let finalResults: Array<any> = [];
      let finalSuggestions: Array<any> = [];
      const {
        results,
        suggested_answers: suggestions,
        search_id: ariaSearchId,
        isFavorite,
        inProjects,
        total_results: totalResults,
        global_filters: globalFilters
      } = res.data.body;

      if (globalFilters && Object.keys(globalFilters).length > 0)
        ariaDispatch({ type: AriaActions.SET_GLOBAL_FILTERS, value: globalFilters });
      pathNameManipulation(searchId, ariaSearchId, pathname, query);
      // check if there are selected keywords and filter the results
      if (ariaState.filters?.search_terms?.length > 0) {
        ariaState.filters?.search_terms.forEach((searchTerm: string) => {
          finalResults = results.filter((x: any) =>
            x.text.toLowerCase().includes(searchTerm.toLowerCase())
          );

          // eslint-disable-next-line camelcase
          finalSuggestions = suggestions.filter((x: any) =>
            x.text.toLowerCase().includes(searchTerm.toLowerCase())
          );
        });

        ariaDispatch({ type: AriaActions.SET_ARIA_RESULTS, value: finalResults });
        ariaDispatch({ type: AriaActions.SET_ORIGINAL_RESULTS, value: finalResults });
        ariaDispatch({
          type: AriaActions.SET_SUGGESTED_ANS,
          value: finalSuggestions
        });
        if (finalResults.length === 0) {
          await ariaDispatch({
            type: AriaActions.SET_ERROR,
            value: ERROR_MESSAGES['404']
          });
        }
        // eslint-disable-next-line camelcase
        ariaDispatch({ type: AriaActions.SET_SEARCH_ID, value: ariaSearchId });
        ariaDispatch({ type: AriaActions.SET_IS_FAVORITE, value: isFavorite });
        ariaDispatch({ type: AriaActions.SET_IN_PROJECT, value: inProjects });
        // update total results for new results when filters are applied
        ariaDispatch({ type: AriaActions.SET_TOTAL_RESULTS, value: totalResults });
        // get the max page number for new results when filters are applied
        const maxPageNum = getMaxPageNumber(totalResults);
        await ariaDispatch({ type: AriaActions.SET_MAX_PAGE, value: maxPageNum });
      } else {
        ariaDispatch({ type: AriaActions.SET_ARIA_RESULTS, value: results });
        ariaDispatch({ type: AriaActions.SET_ORIGINAL_RESULTS, value: results });
        // eslint-disable-next-line camelcase
        ariaDispatch({ type: AriaActions.SET_SEARCH_ID, value: ariaSearchId });
        ariaDispatch({ type: AriaActions.SET_IS_FAVORITE, value: isFavorite });
        ariaDispatch({ type: AriaActions.SET_IN_PROJECT, value: inProjects });
        ariaDispatch({ type: AriaActions.SET_SUGGESTED_ANS, value: suggestions });
        // update total results for new results when filters are applied
        ariaDispatch({ type: AriaActions.SET_TOTAL_RESULTS, value: res.data.body.total_results });
        // get the max page number for new results when filters are applied
        const maxPageNum = getMaxPageNumber(res.data.body.total_results);
        await ariaDispatch({ type: AriaActions.SET_MAX_PAGE, value: maxPageNum });
      }
      // update the top answer for new search filtered query
      ariaDispatch({ type: AriaActions.SET_TOP_ANSWER, value: res.data.body.top_answer });
      // reset current page number to 1
      await ariaDispatch({ type: AriaActions.SET_PAGE, value: 1 });
    } else {
      // handle 404 and 500 error resonse from rest API
      ariaDispatch({
        type: AriaActions.SET_ERROR,
        value: (ERROR_MESSAGES as { [key: string]: string })[`${res?.data.status || 500}`]
      });
      ariaDispatch({ type: AriaActions.SET_ARIA_RESULTS, value: [] });
      ariaDispatch({ type: AriaActions.SET_SUGGESTED_ANS, value: [] });
      ariaDispatch({ type: AriaActions.SET_TOP_ANSWER, value: '' });
    }
    if (setLoading) setLoading(false);
    ariaDispatch({ type: AriaActions.SET_PENDING_FILTERS, value: false });
    ariaDispatch({ type: AriaActions.SET_LOADING, value: false });
    if (setclear) setclear(false);
  };

  /**
   * function to remove all filters and show the original results for the query
   * @param {React.Dispatch<React.SetStateAction<boolean>>} setclear optional parameter for setting clear
   */
  const clearFilters = async (setclear: React.Dispatch<React.SetStateAction<boolean>>) => {
    await ariaDispatch({ type: AriaActions.SET_LOADING, value: true });
    await ariaDispatch({ type: AriaActions.SET_ERROR, value: '' });
    await ariaDispatch({ type: AriaActions.SET_FILTERS, value: {} });
    // await ariaDispatch({ type: AriaActions.SET_ARIA_RESULTS, value: ariaState.originalResults });
    await ariaDispatch({
      type: AriaActions.SET_SUGGESTED_ANS,
      value: ariaState.originalResults.filter((item: any) => item.type === 'answer')
    });
    await ariaDispatch({
      type: AriaActions.SET_ORIGINAL_TOTAL_RESULTS,
      value: ariaState.originalTotalResults
    });
    ariaDispatch({ type: AriaActions.SET_TOTAL_RESULTS, value: ariaState.originalTotalResults });
    ariaDispatch({ type: AriaActions.SET_TOP_ANSWER, value: ariaState.originalTopAnswer });
    // set max page num based on the original results when filters are cleared
    const maxPageNum = getMaxPageNumber(ariaState.originalTotalResults);
    await ariaDispatch({ type: AriaActions.SET_MAX_PAGE, value: maxPageNum });
    await ariaDispatch({ type: AriaActions.SET_PAGE, value: 1 });
    await ariaDispatch({ type: AriaActions.SET_SELECTED_SORT, value: '' });
    await ariaDispatch({ type: AriaActions.SET_LOADING, value: false });
    await ariaDispatch({ type: AriaActions.SET_PENDING_FILTERS, value: false });

    setclear(true);
  };

  return { removeFromFilters, applyFilter, clearFilters };
};

export default useAriaFilters;
