import { useCallback, useContext, useEffect } from 'react';
import Papa from 'papaparse';
import XLSX from 'xlsx';

// api
import {
  getAllEMAGuidanceDocuments,
  getEMAGuidanceDocs,
  getEMAGuidanceInsights
} from '../../../api/pages/EMAGuidance';

// store
import EMAGuidanceStore from '../../../store/EMAGuidance';
import globalStore from '../../../store/index';
import actions from '../../../store/EMAGuidance/actions';
import globalActions from '../../../store/actions';

// utils
import { constructPieChartData } from '../utils/getDonutChartStructure';
import { mergeOldFilterValues } from '../../../utils/filters';
import getFilterOptions from '../utils/common';
import {
  PAGINATION_DEFAULT_OPTIONS,
  EXPORT_TYPE,
  EMA_GUIDANCE_COLUMN_NAME_MAPPING
} from '../const';
import { getFileName } from '../../../helpers/getFileName';
import transformEMAGuidanceData from '../utils/transformEMAGuidanceData';

import AuthContext from '../../../store/Auth/AuthProvider';

import { getDataGridFilter } from '../../../utils/getDatagridFilterOption';

const useEMAGuidanceData = () => {
  const { EMAGuidanceState, EMAGuidanceDispatch } = useContext(EMAGuidanceStore);
  const { state, dispatch }: any = useContext(globalStore);
  const { currentUser } = useContext(AuthContext);

  const getEMAGuidanceInsightData = async (filters: any, appliedFilters: any) => {
    const insightResponse = await getEMAGuidanceInsights({ filters });
    if (insightResponse?.data?.status === 200) {
      // extract and set visualize data
      const graphs = constructPieChartData(insightResponse?.data?.body?.stats?.donut_graph_data);
      EMAGuidanceDispatch({
        type: actions.SET_DONUT_CHARTS,
        value: graphs
      });
      EMAGuidanceDispatch({
        type: actions.SET_PUBLISHED_DATE,
        value: insightResponse?.data?.body?.stats?.graph_by_published_date
      });
      const stats = {
        totalAdoptedCount: insightResponse?.data?.body?.stats?.adopted_status_count,
        totalLastThirtyDays: insightResponse?.data?.body?.stats?.last_30_days_updated_count,
        totalGuidance: insightResponse?.data?.body?.stats?.total_guidance_documents,
        pdfCount: insightResponse?.data?.body?.stats?.pdf_stats?.total_pdf_count,
        pageCount: insightResponse?.data?.body?.stats?.pdf_stats?.total_page_count
      };
      EMAGuidanceDispatch({
        type: actions.SET_EMA_GUIDANCE_STATS,
        value: stats
      });

      // extract and set filter data
      let mergedFilters: any = [];
      const newFilters = getFilterOptions(insightResponse?.data?.body?.filters);
      if (appliedFilters?.length) {
        mergedFilters = mergeOldFilterValues(newFilters, appliedFilters);
      } else {
        mergedFilters = newFilters;
      }

      EMAGuidanceDispatch({
        type: actions.SET_FILTERS,
        value: mergedFilters
      });
      EMAGuidanceDispatch({
        type: actions.SET_ASK_RIA_FILTERS,
        value: insightResponse?.data?.body?.filters?.entity_id || []
      });
      EMAGuidanceDispatch({
        type: actions.SET_AVAILABLE_FILTERS,
        value: appliedFilters
      });
    }
  };

  const getEMAGuidanceTableData = async (payload: any, limit: number, offset: number) => {
    const res = await getEMAGuidanceDocs(payload, limit, offset);
    if (res?.data?.status === 200) {
      EMAGuidanceDispatch({
        type: actions.SET_EMA_GUIDANCE_TABLE,
        value: res?.data?.body?.ema_guidance_documents ?? []
      });

      EMAGuidanceDispatch({
        type: actions.SET_TOTAL_COUNT,
        value: res?.data?.body?.total_count ?? 0
      });
    }
  };

  // Hook to fetch data based on datagrid filter
  const getDatawithDataGridFilter = async (
    appliedFilters: any,
    datagridFilter: any,
    sortFields: any,
    limit: number = PAGINATION_DEFAULT_OPTIONS.limit,
    offset: number = PAGINATION_DEFAULT_OPTIONS.offset
  ) => {
    EMAGuidanceDispatch({
      type: actions.SET_LOADING,
      value: true
    });

    const source = {
      eu: ['ema_guidance']
    };
    const res: any = await getDataGridFilter(
      appliedFilters,
      datagridFilter,
      sortFields,
      limit,
      offset,
      EMAGuidanceState,
      currentUser,
      source
    );

    if (res?.data?.status === 200) {
      EMAGuidanceDispatch({
        type: actions.SET_EMA_GUIDANCE_TABLE,
        value: res?.data?.body?.result?.ema_guidance?.results ?? []
      });

      EMAGuidanceDispatch({
        type: actions.SET_TOTAL_COUNT,
        value: res?.data?.body?.result?.ema_guidance?.total ?? 0
      });
      EMAGuidanceDispatch({
        type: actions.SET_DATA_GRID_FILTERS,
        value: datagridFilter
      });
    }

    EMAGuidanceDispatch({
      type: actions.SET_LOADING,
      value: false
    });
  };

  const getEMAGuidanceData = useCallback(
    async (
      appliedFilters: any,
      limit: number = PAGINATION_DEFAULT_OPTIONS.limit,
      offset: number = PAGINATION_DEFAULT_OPTIONS.offset,
      sort: Array<any> = [],
      shouldGetInsight: boolean = true,
      dataGridFilter: any = {}
    ) => {
      EMAGuidanceDispatch({
        type: actions.SET_LOADING,
        value: true
      });

      EMAGuidanceDispatch({
        type: actions.SET_IS_FILTERS_APPLIED,
        value: Object.keys(appliedFilters).length !== 0
      });

      const payload: any = {};
      payload.filters = { ...appliedFilters };
      if (sort) {
        payload.sort = sort;
      }
      if (Object.keys(dataGridFilter).length !== 0) {
        await getDatawithDataGridFilter(appliedFilters, dataGridFilter, sort, limit, offset);
      } else {
        try {
          // get table data
          await getEMAGuidanceTableData(payload, limit, offset);

          if (shouldGetInsight) {
            // get visualize and filters data
            await getEMAGuidanceInsightData(payload?.filters, appliedFilters);
          }
          EMAGuidanceDispatch({
            type: actions.SET_DATA_GRID_FILTERS,
            value: {}
          });

          EMAGuidanceDispatch({
            type: actions.SET_LOADING,
            value: false
          });
        } catch (err) {
          const errors: any = [];
          errors.push('No data was found');

          EMAGuidanceDispatch({
            type: actions.SET_EMA_GUIDANCE_ERRORS,
            action: errors
          });
          EMAGuidanceDispatch({
            type: actions.SET_LOADING,
            value: false
          });
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }
    },
    []
  );

  const openGuidanceComparison = useCallback((value: boolean) => {
    EMAGuidanceDispatch({
      type: actions.SET_COMPARE_ENABLED,
      value
    });
  }, []);
  const removeFromComparison = useCallback((id: string) => {
    EMAGuidanceDispatch({
      type: actions.REMOVE_DOCUMENT_TO_COMPARE,
      value: id
    });
  }, []);

  const openComparisonPopup = useCallback((value: boolean) => {
    EMAGuidanceDispatch({
      type: actions.SET_COMPARE_POPUP_OPEN,
      value
    });
  }, []);

  const clearComparison = useCallback(() => {
    EMAGuidanceDispatch({
      type: actions.CLEAR_DOCUMENT_TO_COMPARE
    });
    openComparisonPopup(false);
  }, []);

  const clearSearch = useCallback(() => {
    EMAGuidanceDispatch({
      type: actions.SET_SEARCH,
      value: {
        term: ''
      }
    });
  }, []);

  const handleCSVExport = async () => {
    EMAGuidanceDispatch({
      type: actions.SET_LOADING,
      value: true
    });
    const res = await getAllEMAGuidanceDocuments({ filters: EMAGuidanceState.availableFilters });
    const selectedColumns = state?.exportType?.isAllColumnsSelected
      ? EMA_GUIDANCE_COLUMN_NAME_MAPPING
      : Object.fromEntries(
          Object.entries(EMA_GUIDANCE_COLUMN_NAME_MAPPING).filter(([key]) =>
            state?.exportType?.selectedColumns?.includes(key)
          )
        );
    const formattedData = transformEMAGuidanceData(
      res?.data?.body?.ema_guidance_documents,
      selectedColumns
    );
    const csv = Papa.unparse(formattedData);
    // Create a Blob from the CSV string
    const blob = new Blob([csv], { type: 'text/csv' });
    // Create a download link and click it
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    const day = new Date();
    const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
    const fileName = getFileName({
      prefix: 'ria',
      module: 'ema_guidances',
      postfix: date,
      additional: '',
      extension: 'csv'
    });
    a.download = `${fileName}.csv`;
    a.click();
    dispatch({
      type: globalActions.SET_EXPORT_TYPE,
      value: { format: '', selectedColumns: [], isAllColumnsSelected: false }
    });
    EMAGuidanceDispatch({
      type: actions.SET_LOADING,
      value: false
    });
  };

  const handleExcelExport = async () => {
    EMAGuidanceDispatch({
      type: actions.SET_LOADING,
      value: true
    });
    const res = await getAllEMAGuidanceDocuments({ filters: EMAGuidanceState.availableFilters });
    const selectedColumns = state?.exportType?.isAllColumnsSelected
      ? EMA_GUIDANCE_COLUMN_NAME_MAPPING
      : Object.fromEntries(
          Object.entries(EMA_GUIDANCE_COLUMN_NAME_MAPPING).filter(([key]) =>
            state?.exportType?.selectedColumns?.includes(key)
          )
        );
    const formattedData = transformEMAGuidanceData(
      res?.data?.body?.ema_guidance_documents,
      selectedColumns
    );
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const day = new Date();
    const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
    const fileName = getFileName({
      prefix: 'ria',
      module: 'ema_guidances',
      postfix: date,
      additional: '',
      extension: 'xlsx'
    });
    XLSX.writeFile(wb, `${fileName}.xlsx`);
    dispatch({
      type: globalActions.SET_EXPORT_TYPE,
      value: { format: '', selectedColumns: [], isAllColumnsSelected: false }
    });
    EMAGuidanceDispatch({
      type: actions.SET_LOADING,
      value: false
    });
  };

  useEffect(() => {
    if (
      state?.exportType?.format &&
      [EXPORT_TYPE.csv, EXPORT_TYPE.xlsx].includes(state?.exportType?.format)
    ) {
      if (state?.exportType?.format === EXPORT_TYPE.csv) {
        handleCSVExport();
      } else if (state?.exportType?.format === EXPORT_TYPE.xlsx) {
        handleExcelExport();
      }
    }
  }, [state?.exportType]);

  return {
    EMAGuidancefilters: EMAGuidanceState.filters,
    isFilterApplied: EMAGuidanceState.isFiltersApplied,
    EMAGuidanceTableData: EMAGuidanceState.tableData,
    isLoading: EMAGuidanceState.loading,
    compareEnabled: EMAGuidanceState.compareEnabled,
    documentsToCompare: EMAGuidanceState.documentsToCompare,
    comparePopupOpen: EMAGuidanceState.comparePopupOpen,
    getEMAGuidanceData,
    openComparisonPopup,
    removeFromComparison,
    openGuidanceComparison,
    clearComparison,
    clearSearch,
    sortFields: EMAGuidanceState.sortFields,
    getDatawithDataGridFilter,
    dataGridFilter: EMAGuidanceState.dataGridFilters
  };
};

export default useEMAGuidanceData;
