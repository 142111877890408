import React, { Suspense, useContext, useMemo, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { isArray } from 'lodash';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CustomTimelinePropsInterface from '../../../interfaces/CustomTimelinePropsInterface';
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import { formatDate } from '../../../helpers/base';

import createPrimaryCompletionGraphData from './utils';
import styles from './styles';
import SuspenseCircularLoader from '../../../helpers/SuspenseCircularLoader';

const Plot = React.lazy(() => import('react-plotly.js'));

const PrimaryCompletionDateComparison: React.FC<CustomTimelinePropsInterface> = ({
  dataItem = {},
  column = { name: '' }
}) => {
  const { regulatoryState } = useContext(RegulatoryInformationStore);
  const nctID = regulatoryState?.applicationDetails?.application_number || '';
  const [openModal, setOpenModal] = useState(false);
  const { version = '' } = dataItem;
  const primaryDateData = dataItem[column.name] || {};
  const { last_version_changed: lastVersionChanged = '', history = [] } = primaryDateData;
  const todaysDate = new Date();

  const plotData = useMemo(() => {
    if (isArray(history) && history.length)
      return createPrimaryCompletionGraphData(history, todaysDate);
    return [];
  }, [history]);

  const layout: any = {
    barmode: 'stack',
    title: { text: nctID },
    xaxis: { title: '', type: 'date' },
    yaxis: { title: 'Study Record Version', type: 'category', autorange: 'reversed' },
    shapes: [
      {
        line: {
          color: 'red',
          dash: 'dot',
          width: 2
        },
        opacity: 0.7,
        type: 'line',
        x0: formatDate(todaysDate),
        x1: formatDate(todaysDate),
        y0: 0,
        y1: 1,
        xref: 'x',
        yref: 'y domain'
      }
    ],
    annotations: [
      {
        ax: 0,
        ay: -1,
        font: {
          color: 'red'
        },
        showarrow: true,
        text: `Today: ${formatDate(todaysDate)}`,
        x: formatDate(todaysDate),
        xref: 'x',
        y: 1.02,
        yanchor: 'center',
        yref: 'y domain'
      }
    ]
  };

  const config = {
    toImageButtonOptions: {
      filename: `${nctID}_primarychange_version_${version}`
    }
  };

  return (
    <Box sx={styles.iconContainers}>
      <Tooltip
        title={
          plotData.length > 0
            ? 'View primary completion date changes'
            : `Primary completion date has not changed since version ${lastVersionChanged}.`
        }>
        {/* 
// @ts-ignore */}
        <center>
          <IconButton
            onClick={() => setOpenModal(true)}
            disabled={!plotData.length}
            sx={{ color: 'green.accept' }}>
            <EventAvailableIcon sx={{ fontSize: '32px' }} />
          </IconButton>
          {/* 
// @ts-ignore */}
        </center>
      </Tooltip>
      <Dialog
        open={openModal}
        PaperProps={{
          style:
            history.length < 3
              ? {
                  ...styles.primaryCompletionDialogPaper,
                  minHeight: '50vh',
                  minWidth: '50vw',
                  height: 'auto',
                  width: 'auto'
                }
              : { ...styles.primaryCompletionDialogPaper }
        }}
        onClose={() => setOpenModal(false)}>
        <DialogTitle>
          <Grid display='flex' justifyContent='space-between' alignItems='flex-start'>
            <Grid sx={styles.dialogHeading} display='flex' justifyContent='center'>
              Primary Completion Date Changes
            </Grid>
            <Box>
              <IconButton
                aria-label='delete'
                onClick={() => setOpenModal(false)}
                sx={{ fontSize: '30px' }}>
                <CancelIcon fontSize='inherit' />
              </IconButton>
            </Box>
          </Grid>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <Suspense fallback={<SuspenseCircularLoader />}>
            <Plot
              style={{ width: '100%', height: '100%' }}
              data={plotData}
              layout={layout}
              config={config}
            />
          </Suspense>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default PrimaryCompletionDateComparison;
