import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  container: {
    height: '35vh',
    width: '100%',
    backgroundColor: theme.palette.white,
    borderRadius: '16px',
    [theme.breakpoints.up('xl')]: {
      height: theme.spacing(45)
    }
  },
  headings: {
    color: theme.palette.biologics.textPrimary,
    fontWeight: 'bold',
    fontSize: theme.spacing(2)
  },
  graph: {
    height: '100%'
  },
  loaderBox: {
    width: '90%',
    height: '90%'
    // border:'1px solid red'
  },
  barGraphRect: {
    width: '100%',
    height: '100%'
  },
  add: {
    fontSize: theme.spacing(1.87),
    color: theme.palette.navbar.button,
    cursor: 'pointer'
  }
}));
