import makeRequest, {
  makePDFRequest,
  makeRequestWithCancel,
  makeStreamRequestWithCancel
} from '../client';

interface sendQueryProps {
  applicationNumber: string;
  payload: {
    question: string;
    riaMode: boolean;
    s3_document_url?: string;
    conversation_id?: string;
    section?: any;
  };
  source: string;
  cancelToken?: any;
  onDownloadProgress?: any;
}

export interface HelpQueryPayload {
  application_number: string[];
  s3_document_url?: string;
  source: string;
  section?: string;
  conversation_id?: string;
  page_range?: any;
}

export const initialiseChat = (payload: any) => {
  return makeRequest(`/chat_ria/init`, 'POST', payload);
};

export const sendConversation = (payload: any) => {
  return makeRequest(`/chat_ria/conversations`, 'POST', payload);
};

export const sendQuery = ({
  applicationNumber,
  payload,
  source,
  cancelToken,
  onDownloadProgress
}: sendQueryProps) => {
  return makeStreamRequestWithCancel(
    `/chat_ria/conversations`,
    'POST',
    {
      source,
      application_number: [applicationNumber],
      help_question: false,
      ...payload
    },
    {},
    cancelToken,
    onDownloadProgress
  );
};
export const askHelp = (payload: HelpQueryPayload, cancelToken: any) => {
  const defaultPayload: {
    ria_mode: boolean;
    help_question: boolean;
    question: string;
  } = {
    ria_mode: true,
    help_question: true,
    question: ''
  };

  return makeRequestWithCancel(
    `/chat_ria/conversations`,
    'POST',
    { ...payload, ...defaultPayload },
    {},
    cancelToken
  );
};

export const createReport = (reportList: string[], metaData: any) =>
  makePDFRequest(`/chat_ria/generate-pdf`, 'POST', {
    reportList,
    metaData
  });

export const getChatRiaStatus = () => {
  return makeRequest(`/chat_ria/status`, 'GET');
};

export const getReportTitle = (reportData: any) => {
  return makeRequest(`/chat_ria/response-title`, 'POST', reportData);
};

export const getReferencePdf = (reportData: any) => {
  return makeRequest(`/chat_ria/reference_pdf`, 'POST', reportData);
};
