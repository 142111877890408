import React from 'react';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import {
  recruitmentStatusCardBoxContainer,
  recruitmentStatusSubmittedDateStyle,
  recruitmentStatusTextStyle
} from './styles';

const RecruitmentStatusCardBox = ({
  submittedDate,
  status,
  recruitmentStatusColor
}: {
  submittedDate: string;
  status: string;
  recruitmentStatusColor: string;
}) => {
  return (
    <React.Fragment>
      <Stack direction='column' sx={recruitmentStatusCardBoxContainer}>
        <Box display='flex' justifyContent='flex-start' alignItems='center'>
          <Typography
            sx={{
              ...recruitmentStatusSubmittedDateStyle,
              textDecoration: 'none',
              backgroundColor: recruitmentStatusColor
            }}>
            {submittedDate}
          </Typography>
          <Tooltip title={status}>
            <Typography
              sx={{
                ...recruitmentStatusTextStyle,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                textDecoration: 'none'
              }}>
              {status}
            </Typography>
          </Tooltip>
        </Box>
      </Stack>
    </React.Fragment>
  );
};

export default React.memo(RecruitmentStatusCardBox);
