import { memo, useContext, useEffect } from 'react';

// mui v5
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import themev5 from '../../themev5';

// hoc
import PediatricsFDATabs from './hoc/Tabs';

// store
import Store from '../../store';
import Actions from '../../store/actions';

// Provider
import { PediatricsStateProvider } from '../../store/Pediatrics';

const PediatricsFDA = () => {
  const { dispatch } = useContext(Store) as any;

  useEffect(() => {
    dispatch({ type: Actions.SET_MODULE, value: 'fda-written-request' });
  }, []);

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <PediatricsStateProvider>
        <PediatricsFDATabs />
      </PediatricsStateProvider>
    </ThemeProvider>
  );
};

export default memo(PediatricsFDA);
