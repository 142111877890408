import { ENTITY_MAPPING } from '../constants';

const mapGraphEntityData = (data: { [key: string]: string | number } | null) => {
  const mappedData: { [key: string]: string | number } = {};
  if (data) {
    Object.keys(ENTITY_MAPPING).forEach((key: string) => {
      mappedData[ENTITY_MAPPING[key]?.name || key] =
        ENTITY_MAPPING[key]?.getValue?.(data[key]) || data[key];
    });
  }
  return mappedData;
};

export default mapGraphEntityData;
