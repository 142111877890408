export const HTA_COLUMN_STYLES = {
  title: 'capitalize',
  alt_source_names: 'capitalize',
  std_status: 'capitalize',
  nice_doc_type: 'nocase',
  published_date: 'nocase',
  last_updated_date: 'nocase'
};

export const CT_COLUMN_STYLES = {
  brief_title: 'titlecase'
};

export default HTA_COLUMN_STYLES;

export const PMR_SURVEILLANCE_STYLES = {
  applicant_name: 'uppercase',
  product_name: 'uppercase',
  active_ingredient: 'capitalize'
};
