import { useContext, useEffect, useState } from 'react';
import { Box, CssBaseline, Stack, ThemeProvider, Skeleton } from '@mui/material';
import { difference } from 'lodash';

// custom icons
import { HowToIcon, WhatsNewHomeTabIcon } from '../../assets/svgs/Icons';

// components
import SearchBar from './components/SearchBar';
import InformationModule from './components/InformationModule';
import themev5 from '../../themev5';
import Explore from './components/ExploreFeatures';
import { bottomDrawerHeight } from './const';
import useUpdateModule from '../../hooks/useUpdateModule';
import { getFilteredWhatsNewUpdates } from '../../api/pages/WhatsNew';
import { getWhatsNewStats, getWhatsNewUnseenCount } from './utils';
import Store from '../../store';
import actions from '../../store/actions';
import WhatsNewOnboardingExperience from '../../components/WhatsNew/WhatsNewOnboardingExperience';
import WhatsNewStatsModal from '../../components/WhatsNew/WhatsNewStatsModal';

const finalPageHeight = bottomDrawerHeight + 74;

const Home = () => {
  useUpdateModule('home');
  const { dispatch, state } = useContext<any>(Store);

  const [unseenWhatsNewCount, setUnseenWhatsNewCount] = useState(0);
  const [whatsNewStatsTitle, setWhatsNewStatsTitle] = useState('');
  const [showOnboardingExperience, setShowOnboardingExperience] = useState(false);
  const [showStatsModal, setShowStatsModal] = useState(false);
  const [latestWhatsNewStats, setLatestWhatsNewStats] = useState({
    majorUpdates: 0,
    enhancementUpdates: 0
  });

  const checkWhatsNewStatsSeen = () => {
    const seen = localStorage.getItem('WN_isStatsModalSeen');
    const latestWhatsNewIds = JSON.parse(localStorage.getItem('WN_latestIds') || '{}');
    const whatsNewStatsIds = JSON.parse(localStorage.getItem('WN_statsIds') || '{}');

    const latestMajorUpdatesIds = difference(
      latestWhatsNewIds?.majorUpdates ?? [],
      whatsNewStatsIds?.majorUpdates ?? []
    );

    const latestEnhancementUpdatesIds = difference(
      latestWhatsNewIds?.enhancementUpdates ?? [],
      whatsNewStatsIds?.enhancementUpdates ?? []
    );

    const latestWhatsNewStatsIds = {
      majorUpdates: latestWhatsNewIds?.majorUpdates ?? [],
      enhancementUpdates: latestWhatsNewIds?.enhancementUpdates ?? []
    };

    setLatestWhatsNewStats({
      majorUpdates: latestMajorUpdatesIds.length,
      enhancementUpdates: latestEnhancementUpdatesIds.length
    });

    localStorage.setItem('WN_statsIds', JSON.stringify(latestWhatsNewStatsIds));

    if (!seen || latestMajorUpdatesIds.length > 0 || latestEnhancementUpdatesIds.length > 0) {
      setShowStatsModal(true);
    }
  };

  const checkOnboardingExperienceSeen = () => {
    const seen = localStorage.getItem('WN_isOnboardingExperienceSeen');

    if (!seen) {
      setShowOnboardingExperience(true);
    } else {
      checkWhatsNewStatsSeen();
    }
  };

  useEffect(() => {
    const fetchWhatsNew = async () => {
      try {
        dispatch({ type: actions.SET_IS_WHATS_NEW_LOADING, value: true });

        const payload = {
          nDays: 30,
          typeName: 'All'
        };

        const result = await getFilteredWhatsNewUpdates(payload);

        if (result?.status !== 200 && !result?.data?.Success?.result) {
          return;
        }

        const last30DaysUpdates = result.data?.Success?.result;

        const unseenCount = getWhatsNewUnseenCount(last30DaysUpdates);

        setUnseenWhatsNewCount(unseenCount);

        if (!last30DaysUpdates) {
          return;
        }

        const stats = getWhatsNewStats(last30DaysUpdates);

        dispatch({ type: actions.SET_WHATS_NEW_UPDATES, value: last30DaysUpdates });

        if (stats) {
          const updates = [];

          if (stats.majorUpdates > 0) {
            updates.push(`Major Updates: ${stats.majorUpdates}`);
          }

          if (stats.enhancementUpdates > 0) {
            updates.push(`Enhancement Updates: ${stats.enhancementUpdates}`);
          }

          setWhatsNewStatsTitle(updates.join(', '));
        }

        checkOnboardingExperienceSeen();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        dispatch({ type: actions.SET_IS_WHATS_NEW_LOADING, value: false });
      }
    };

    fetchWhatsNew();
  }, []);

  const handleCloseOnboardingExperience = () => {
    localStorage.setItem('WN_isOnboardingExperienceSeen', 'true');
    setShowOnboardingExperience(false);
    checkWhatsNewStatsSeen();
  };

  const handleCloseStatsModal = () => {
    localStorage.setItem('WN_isStatsModalSeen', 'true');
    setShowStatsModal(false);
  };

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: `calc(100vh - ${finalPageHeight}px) !important`
        }}>
        <Box
          flex={1}
          flexGrow={1}
          sx={{
            marginTop: '144px',
            alignSelf: 'center',
            width: { xs: '762px', md: '762px', lg: '794px' },
            minWidth: '762px',
            maxWidth: '794px'
          }}>
          <SearchBar />
        </Box>
        <Stack direction='row' justifyContent='center' spacing={2} sx={{ marginBottom: '40px' }}>
          {/* DO NOT DELETE CODE - Not yet implemented */}
          {/* <InformationModule
            title='Latest RIA updates'
            subtitle='FDA: 32 | EMA: 18| New Features: 2'
            linkText='See all details >'
            linkUrl='/help?content=whatsnew'
            Icon={Document3DIcon}
            showUpdateCount
            updateCount={50}
          /> */}
          <InformationModule
            title="What's new!"
            subtitle={
              state?.isWhatsNewLoading ? <Skeleton variant='text' width={90} /> : whatsNewStatsTitle
            }
            linkText='Check Updates >'
            linkUrl='#whats-new'
            Icon={WhatsNewHomeTabIcon}
            updateCount={unseenWhatsNewCount}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(unseenWhatsNewCount > 0 && { showUpdateCount: true })}
          />
          <WhatsNewOnboardingExperience
            showOnboardingExperience={showOnboardingExperience}
            handleCloseOnboardingExperience={handleCloseOnboardingExperience}
          />
          <WhatsNewStatsModal
            showStatsModal={showStatsModal}
            handleCloseStatsModal={handleCloseStatsModal}
            latestWhatsNewStats={latestWhatsNewStats}
          />
          <InformationModule
            title='How to?'
            subtitle='Let us help you explore RIA better!'
            linkText='See video tutorials in help center >'
            linkUrl='/help?content=videos'
            Icon={HowToIcon}
          />
        </Stack>
        <Explore />
      </Box>
    </ThemeProvider>
  );
};

export default Home;
