import React, { useState } from 'react';
import {
  Stack,
  Box,
  Tooltip,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { HideIcon, ViewIcon } from '../../../assets/svgs/Icons';
import TimelineIcon from '../../../assets/svgs/PDCO/TimelineIcon';
import styles from '../styles/Meeting.styles';

const MeetingDetailsCard = ({ sections, setTimelineDetails, setShowTimeline }: any) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (id: string) => {
    setExpanded(prev => (prev === id ? false : id));
  };

  const getSummaryTitle = (summary: string, meetingCard: string) => {
    if (summary === 'NA') {
      return 'No summary available from the source';
    }
    if (expanded === meetingCard) {
      return 'Hide Summary';
    }
    return 'View Summary';
  };

  return (
    <>
      {Object.keys(sections).map((key: string) => {
        const sect = sections[key];
        return (
          <Stack
            // eslint-disable-next-line react/no-array-index-key
            key={key}
            spacing={2}
            id={key}
            sx={styles.sectionsContainer}>
            <Stack spacing={1} aria-label='section' margin={0}>
              <Typography sx={styles.sectionKey}>{key}</Typography>
            </Stack>
            {/* card  */}
            {sect.map((topic: any, index: number) => (
              <Stack
                // eslint-disable-next-line react/no-array-index-key
                key={`${topic.active_ingredient}-${index}`}
                spacing={1}
                aria-label='section-item-card'
                width='100%'
                sx={{ margin: '0px !important' }}>
                <Box sx={styles.cardBox}>
                  <Box sx={styles.cardHeader}>
                    <Tooltip
                      title={`Active Ingredient: ${topic.active_ingredient}`}
                      placement='top'>
                      <Typography sx={styles.cardHeaderText}>
                        {`${index + 1}. ${topic.active_ingredient}`}
                      </Typography>
                    </Tooltip>
                    <Box
                      display='flex'
                      alignItems='center'
                      sx={{
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setShowTimeline(true);
                        setTimelineDetails(topic);
                      }}>
                      <TimelineIcon sx={{ fontSize: 14 }} />
                      <Box marginLeft='4px'>
                        <Typography sx={styles.cardHeaderTimelineText}>Timeline</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={styles.cardSection}>
                    <Box sx={styles.cardSectionDetails}>
                      <Tooltip title={`Sponser Name: ${topic.sponsor_name}`} placement='top'>
                        <Typography sx={styles.cardSectionDetailsText}>
                          {topic.sponsor_name}
                        </Typography>
                      </Tooltip>
                      <Tooltip title={`Indication: ${topic.indication}`} placement='top'>
                        <Typography sx={styles.cardSectionDetailsText}>
                          {topic.indication}
                        </Typography>
                      </Tooltip>
                      <Tooltip
                        title={`Therapeutic Area: ${topic.therapeutic_area}`}
                        placement='top'>
                        <Typography sx={styles.cardSectionDetailsText}>
                          {topic.therapeutic_area}
                        </Typography>
                      </Tooltip>
                      <Tooltip
                        title={`Trade Name: ${
                          topic?.latest_pip?.brand_name && topic?.latest_pip?.brand_name !== 'NA'
                            ? topic?.latest_pip?.brand_name
                            : 'NA'
                        }`}
                        placement='top'>
                        <Typography
                          sx={{
                            ...styles.cardSectionDetailsText,
                            color:
                              topic?.latest_pip?.brand_name &&
                              topic?.latest_pip?.brand_name !== 'NA'
                                ? 'gray.800'
                                : 'gray.500',
                            fontWeight:
                              topic?.latest_pip?.brand_name &&
                              topic?.latest_pip?.brand_name !== 'NA'
                                ? 700
                                : 500
                          }}>
                          {topic?.latest_pip?.brand_name ? topic?.latest_pip?.brand_name : 'NA'}
                        </Typography>
                      </Tooltip>
                      <Box sx={styles.cardSectionOpinionDaysBox}>
                        <Tooltip title={topic.opinion_day} placement='top'>
                          <Typography
                            sx={{
                              ...styles.cardSectionDetailsText,
                              fontSize: 12,
                              color: 'gray.900'
                            }}>
                            {topic.opinion_day.replace(/opinion|discussion|letter/gi, '').trim()}
                          </Typography>
                        </Tooltip>
                      </Box>
                    </Box>
                    <Box sx={styles.cardSummaryWrapper} />
                    <Accordion
                      expanded={expanded === `${topic.active_ingredient}-${topic.indication}`}
                      onChange={() => {
                        if (topic.summary !== 'NA') {
                          handleChange(`${topic.active_ingredient}-${topic.indication}`);
                        }
                      }}
                      defaultExpanded
                      sx={styles.cardSummaryAccordion}>
                      <AccordionSummary
                        expandIcon={
                          expanded === `${topic.active_ingredient}-${topic.indication}` ? (
                            <HideIcon
                              sx={{
                                ...styles.hideIcon,
                                color: topic.summary !== 'NA' ? 'primary.700' : 'gray.500'
                              }}
                            />
                          ) : (
                            <ViewIcon
                              sx={{
                                ...styles.hideIcon,
                                color: topic.summary !== 'NA' ? 'primary.700' : 'gray.500'
                              }}
                            />
                          )
                        }
                        aria-controls={`${topic.active_ingredient}-${topic.indication}`}
                        id={`${topic.active_ingredient}-${topic.indication}`}
                        sx={styles.cardSummaryAccordionSummary}>
                        <Typography
                          fontWeight={500}
                          sx={{
                            ...styles.cardAccordionHideSummaryText,
                            color: topic.summary !== 'NA' ? 'primary.700' : 'gray.500'
                          }}>
                          {getSummaryTitle(
                            topic.summary,
                            `${topic.active_ingredient}-${topic.indication}`
                          )}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={styles.cardSummaryAccordionDetails}>
                        <Box sx={styles.accordionCard}>
                          <Typography sx={styles.accordionCardHeader}>
                            Summary of Committee discussion:
                          </Typography>
                          <Typography sx={styles.accordionPipNumberText}>
                            {topic.pip_number}
                          </Typography>
                        </Box>
                        <Box component='ul' sx={{ margin: 0, paddingLeft: 3 }}>
                          {[topic.summary].map((summaryText: string, topicIndex: number) => (
                            <Box
                              component='li'
                              sx={{ marginBottom: '8px' }}
                              // eslint-disable-next-line react/no-array-index-key
                              key={`sumary-${topicIndex}`}>
                              <Typography sx={styles.accordionSummaryText}>
                                {summaryText}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </Box>
              </Stack>
            ))}
          </Stack>
        );
      })}
    </>
  );
};

export default React.memo(MeetingDetailsCard);
