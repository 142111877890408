import palette from '../../../themev5/palette';

const styles = {
  container: {
    margin: '4px 27px 58px 27px',
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 264px)'
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '6px',
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 264px)'
  },
  labelsIndicatorWrapper: {
    minWidth: '236px',
    maxWidth: '236px',
    minHeight: '526px',
    maxHeight: '526px',
    padding: '14px 16px 0 16px',
    borderRadius: '8px 0 0 8px',
    background: palette.white.main,
    boxShadow: `0 0 4px 0 ${palette.gray.lightVariant8}`
  },
  cardHeader: {
    mb: 1,
    fontWeight: 700,
    lineHeight: '24px'
  },
  companionDiagnosticDevicesWrapper: {
    background: palette.white.main,
    boxShadow: `0 0 4px 0 ${palette.gray.lightVariant8}`,
    borderRadius: '0 8px 8px 0',
    padding: '14px 16px 0 16px',
    flexDirection: 'row',
    minHeight: '526px',
    maxHeight: '526px',
    overflowY: 'hidden',
    overflowX: 'auto'
  },
  sectionsWrapper: {
    minWidth: '236px',
    maxWidth: '236px',
    minHeight: '526px',
    maxHeight: '526px'
  },
  sectionLabel: {
    fontWeight: 500,
    color: 'gray.750',
    padding: '8px 32px 16px 16px',
    fontSize: '13px'
  },
  sectionText: {
    fontWeight: 500,
    color: 'gray.950',
    fontSize: '13px',
    padding: '8px 16px'
  },
  sectionDivider: {
    borderColor: 'gray.200',
    margin: '0 !important'
  },
  companionDiagnosticDeviceNumberText: {
    fontWeight: 700,
    color: 'gray.950',
    fontSize: '15px',
    margin: 'auto 0',
    padding: '8px 16px'
  },
  sectionWrapper: {
    height: '64px',
    maxHeight: '64px',
    margin: '0 !important'
  },
  section: {
    height: '64px',
    maxHeight: '64px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    margin: '0 !important'
  },
  sectionContentWrapper: {
    height: '46px',
    maxHeight: '46px',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    marginBottom: '18px'
  },
  notAvailableText: {
    fontSize: '13px',
    fontWeight: 400,
    color: 'gray.400'
  },
  paper: {
    maxHeight: 300,
    maxWidth: 300,
    overflowY: 'auto',
    borderRadius: '8px',
    width: 400,
    backgroundColor: 'gray.50',
    padding: '20px 24px'
  },
  paperText: {
    fontWeight: 500,
    color: 'gray.950',
    fontSize: '13px'
  }
};

export default styles;
