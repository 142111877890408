import React from 'react';

import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import { DATAGRID_ARRAY, DATAGRID_ARRAY_CONNECTOR } from '../DatagridAvatar/constants';
import { GridCellExpand } from '../DatagridTooltip';

const DatagridArray = (params: GridRenderCellParams) => {
  const { row, field } = params;
  let concatstring = row[field];
  if (field in DATAGRID_ARRAY) {
    DATAGRID_ARRAY[field].forEach(ele => {
      concatstring += DATAGRID_ARRAY_CONNECTOR[field] + row[ele] || '';
    });
  }

  return <GridCellExpand value={concatstring} field={field} />;
};

export default DatagridArray;
