import { Box, Divider, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OptionFooter from './OptionFooter';
import SearchKeyword from './SearchKeyword';
import { styles } from './styles';

const SearchKeywordOptions = ({
  selectedCategory,
  applyFilters,
  isLoading,
  handleClose,
  setShowFilterSidebar,
  selectedCategoryOptions,
  setSelectedCategoryOptions,
  handleApplyClose,
  isBottomFilterView
}: any) => {
  return (
    <Box width={295} height={280}>
      <Box height={203}>
        <Box sx={styles.header} onClick={handleClose}>
          <Button sx={styles.closeIconButton}>
            <CloseIcon sx={{ ...styles.icon, fontSize: 20 }} />
          </Button>
        </Box>
        <Box mx={3}>
          <SearchKeyword
            selectedCategoryOptions={selectedCategoryOptions}
            setSelectedCategoryOptions={setSelectedCategoryOptions}
            isBottomFilterView={isBottomFilterView}
          />
        </Box>
      </Box>
      <Divider sx={{ mt: 1 }} />
      <Box mx={3} height={68} sx={styles.footer}>
        <OptionFooter
          selectedCategory={selectedCategory}
          selectedCategoryOptions={selectedCategoryOptions}
          setSelectedCategoryOptions={setSelectedCategoryOptions}
          applyFilters={applyFilters}
          isLoading={isLoading}
          handleApplyClose={handleApplyClose}
          setShowFilterSidebar={setShowFilterSidebar}
        />
      </Box>
    </Box>
  );
};

export default SearchKeywordOptions;
