import React, { useEffect } from 'react';
import { Box, IconButton, List, ListItem, Stack, Tooltip, Typography } from '@mui/material';
import { CloseCrossIcon, CustomCheckIcon } from '../../../../assets/svgs/Icons';

import styles from '../Drawer.styles';
import { SecondaryDrawerProps, SubEntity } from '../types';

const SecondaryDrawer = ({
  title,
  entities,
  selectedEntity,
  onClose,
  onEntitySelect
}: SecondaryDrawerProps) => {
  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById(selectedEntity);
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 300);
  }, []);
  return (
    <Box sx={styles.secondaryDrawer}>
      <Stack direction='row' sx={{ px: 2.5, py: 1.5, bgcolor: 'primary.300' }}>
        <Typography sx={styles.secondaryDrawerTitleText}>Select {title}</Typography>
        <IconButton sx={styles.secondaryDrawerCloseIcon} onClick={onClose}>
          <CloseCrossIcon sx={styles.drawerHeaderIcon} />
        </IconButton>
      </Stack>
      <Box sx={styles.secondaryDrawerScrollContainer}>
        <List>
          {entities.map((entity: SubEntity) => {
            return (
              <ListItem
                id={entity.id}
                sx={styles.secondaryDrawerListItem}
                key={entity.id}
                onClick={() => onEntitySelect(entity)}>
                <Tooltip title={entity.name}>
                  <Typography
                    sx={
                      selectedEntity === entity.id
                        ? styles.secondaryDrawerListItemSelectedText
                        : styles.secondaryDrawerListItemText
                    }>
                    {entity.name}
                  </Typography>
                </Tooltip>

                {selectedEntity === entity.id && (
                  <CustomCheckIcon sx={styles.secondaryDrawerListItemSelectedCheckbox} />
                )}
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Box>
  );
};

export default React.memo(SecondaryDrawer);
