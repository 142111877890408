const styles = {
  box: {
    height: '100%'
  },
  barGraph: {
    backgroundColor: 'white.main',
    height: '85%',
    borderRadius: 2.5
  },
  barConatiner: {
    backgroundColor: 'white.main',
    borderRadius: 1,
    padding: 2,
    height: '50%'
  },
  layer1Charts: {
    gap: 2,
    height: '50%'
  },
  layer2Charts: {
    pt: 2,
    gap: 2,
    height: '50%'
  },
  graphHeading: {
    color: 'grey.800',
    fontFamily: 'Mulish',
    fontSize: 16,
    fontWeight: 700
  },
  reportText: {
    color: 'primary.700',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    '&:hover': {
      background: 'none'
    }
  },
  pieContainers: {
    heigth: '100%',
    marginTop: 1,
    padding: 1,
    width: '50%',
    backgroundColor: 'white.main',
    borderRadius: 1
  },
  graphContainer: {
    width: '100%',
    height: '70%',
    mt: 3,
    '@media (max-width: 1280px)': {
      height: '60%'
    }
  },
  paper: {
    margin: 3,
    width: '50%',
    height: '50%',
    padding: 3,
    borderRadius: 3
  },
  loaderBox: {
    width: '90%',
    height: '90%'
  },
  barGraphRect: {
    width: '100%',
    height: '100%'
  },
  addToReport: {
    height: 35,
    cursor: 'pointer',
    background: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: 'none',
      border: 1,
      borderColor: 'primary.main',
      borderStyle: 'solid'
    }
  }
};

export default styles;
