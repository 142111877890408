import { memo } from 'react';

import { Link, Typography, Tooltip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import styles from '../styles/Reg360.styles';

interface GridCellExpandProps {
  row: any;
}

const GridCellExpand = memo((props: GridCellExpandProps) => {
  const { row } = props;

  return row?.product_name === 'N/A' || row?.product_number < 1 ? (
    <Typography>N/A</Typography>
  ) : (
    <Tooltip title='Open Reg 360'>
      <Link
        sx={styles.link}
        href={`/regulatory360/core/${row?.product_number}/eu`}
        target='_blank'
        rel='noreferrer'>
        {row?.product_name}
      </Link>
    </Tooltip>
  );
});

const DatagridReg360 = ({ row }: GridRenderCellParams) => {
  return <GridCellExpand row={row} />;
};

export default DatagridReg360;
