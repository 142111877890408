import React, { useContext } from 'react';
import ResultsAction from '../../../store/SearchResults/actions';
import ResultsStore from '../../../store/SearchResults';
import SelectionView from './SelectionView';

interface CompareViewProps {
  sources: any;
  handleLabelSection?: any;
  handleCompareView?: any;
  comparisonApplications?: any;
}

const CompareView = ({
  sources = {},
  handleLabelSection,
  handleCompareView,
  comparisonApplications
}: CompareViewProps) => {
  const { resultsState, resultsDispatch } = useContext(ResultsStore);

  return resultsState?.labelComparisonSelection ? (
    <SelectionView
      open={resultsState?.labelComparisonSelection}
      onClose={() => {
        resultsDispatch({
          type: ResultsAction.SET_LABEL_COMPARISON_SELECTION,
          value: false
        });
      }}
      sources={sources}
      handleLabelSection={handleLabelSection}
      handleCompareView={handleCompareView}
      comparisonApplications={comparisonApplications}
    />
  ) : null;
};

CompareView.defaultProps = {
  handleLabelSection: () => {},
  handleCompareView: () => {},
  comparisonApplications: {}
};

export default React.memo(CompareView);
