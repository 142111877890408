import { createSvgIcon } from '@mui/material';

const StarOutlineIcon = createSvgIcon(
  <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.3559 14.1006L7.00013 10.8993L2.64438 14.1006L4.3168 8.93055L-0.0366211 5.74963H5.33821L7.00013 0.570801L8.66205 5.74963H14.0363L9.68346 8.93055L11.3559 14.1006ZM7.00013 9.45147L9.13396 11.0201L8.3173 8.48605L10.4634 6.9163H7.81096L7.00013 4.38988L6.1893 6.9163H3.5363L5.68471 8.48605L4.86805 11.0201L7.00013 9.45147Z'
      fill='currentColor'
    />
  </svg>,
  'StarOutline'
);

export default StarOutlineIcon;
