import { createSvgIcon } from '@mui/material';

const ChatBubbleIcon = createSvgIcon(
  <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_4468_10840)'>
      <path
        d='M13.9998 14.5H7.01034C5.64475 14.4994 4.30917 14.0993 3.16811 13.3491C2.02705 12.5989 1.13034 11.5313 0.588431 10.2778C0.0465235 9.02435 -0.116914 7.63975 0.118249 6.29456C0.353413 4.94937 0.976906 3.70234 1.91193 2.70707C2.84696 1.7118 4.05268 1.01175 5.38059 0.693164C6.7085 0.374574 8.1006 0.451353 9.38541 0.914041C10.6702 1.37673 11.7917 2.20512 12.6116 3.29718C13.4315 4.38924 13.914 5.69728 13.9998 7.06017V7.09692V14.5ZM7.01034 1.66667C5.46325 1.66667 3.97952 2.28125 2.88555 3.37521C1.79159 4.46917 1.17701 5.9529 1.17701 7.5C1.17701 9.0471 1.79159 10.5308 2.88555 11.6248C3.97952 12.7188 5.46325 13.3333 7.01034 13.3333H12.8332V7.11558C12.7332 5.63877 12.0764 4.25493 10.9956 3.24355C9.91486 2.23217 8.49054 1.6686 7.01034 1.66667V1.66667Z'
        fill='currentColor'
      />
      <path d='M7.58301 4.58325H4.08301V5.74992H7.58301V4.58325Z' fill='currentColor' />
      <path d='M9.91634 6.91675H4.08301V8.08342H9.91634V6.91675Z' fill='currentColor' />
      <path d='M9.91634 9.25H4.08301V10.4167H9.91634V9.25Z' fill='currentColor' />
    </g>
    <defs>
      <clipPath id='clip0_4468_10840'>
        <rect width='14' height='14' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>,
  'ChatBubble'
);

export default ChatBubbleIcon;
