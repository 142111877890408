const styles = {
  main: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    fontFamily: 'Mulish',
    position: 'relative',
    'page-break-before': 'avoid',
    'page-break-after': 'avoid',
    thead: {
      borderBottom: '2px solid #333'
    },
    table: {
      display: 'table',
      tableLayout: 'auto !important',
      border: '1px solid #ccc',
      width: '100%',
      margin: '0 0 10px',
      backgroundColor: 'transparent',
      backgroundSpacing: '0',
      borderCollapse: 'collapse',
      'page-break-inside': 'avoid'
    },
    th: {
      border: '1px solid #e1e1e1',
      padding: '8px',
      fontWeight: 'bold',
      backgroundColor: '#f3f3f3',
      backgroundClip: 'padding-box'
    },
    tr: {
      border: '1px solid #efefef'
    },
    td: {
      border: '1px solid #e1e1e1',
      padding: '8px',
      wordBreak: 'break-all',
      backgroundClip: 'padding-box'
    }
  }
};

export default styles;
