import { createSvgIcon } from '@mui/material';

const EuFlagIcon = createSvgIcon(
  <svg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0 0H17.9998V12H0V0Z' fill='#0052B4' />
    <path
      d='M9.00015 1.9502L9.21559 2.61317H9.91265L9.34869 3.02294L9.5641 3.68591L9.00015 3.27619L8.43619 3.68591L8.6516 3.02294L8.08764 2.61317H8.7847L9.00015 1.9502ZM6.13641 3.13642L6.75753 3.45287L7.25044 2.95999L7.14135 3.64849L7.76246 3.96499L7.07397 4.07404L6.96492 4.76258L6.64843 4.14146L5.95998 4.25047L6.4529 3.75755L6.13641 3.13642ZM4.9502 6.00019L5.61316 5.78478L5.61321 5.08772L6.02288 5.65168L6.68589 5.43627L6.27613 6.00019L6.68589 6.56416L6.02288 6.34879L5.61321 6.91271V6.21565L4.9502 6.00019ZM6.13641 8.86401L6.45281 8.24284L5.95998 7.74996L6.64852 7.85902L6.96492 7.23789L7.07397 7.92643L7.76246 8.03545L7.14139 8.35194L7.25044 9.04039L6.75753 8.54752L6.13641 8.86401ZM9.00015 10.0502L8.7847 9.38722H8.08764L8.65164 8.97749L8.43619 8.31452L9.00015 8.72424L9.5641 8.31452L9.34869 8.97749L9.91265 9.38722H9.21555L9.00015 10.0502ZM11.8639 8.86401L11.2428 8.54756L10.7499 9.04048L10.8589 8.35189L10.2378 8.03545L10.9263 7.92643L11.0354 7.23789L11.3519 7.85902L12.0403 7.74996L11.5474 8.24288L11.8639 8.86401ZM13.0501 6.00019L12.3871 6.21565V6.91271L11.9774 6.34871L11.3144 6.56416L11.7242 6.00019L11.3144 5.43627L11.9774 5.65164L12.3871 5.08772L12.3871 5.78483L13.0501 6.00019ZM11.8639 3.13642L11.5474 3.75755L12.0404 4.25047L11.3518 4.14137L11.0354 4.76254L10.9263 4.07404L10.2378 3.96499L10.8589 3.64849L10.7499 2.95999L11.2428 3.45291L11.8639 3.13642Z'
      fill='#FFDA44'
    />
  </svg>,
  'EuFlagIcon'
);

export default EuFlagIcon;
