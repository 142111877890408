import * as React from 'react';

import Tooltip from '@mui/material/Tooltip';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

// constants
import LaunchIcon from '@mui/icons-material/Launch';

interface GridCellExpandProps {
  value: string;
  source: string;
}

export const UNIQUE_CT_ID: any = {
  usnlm: 'nct_id',
  euct: 'euct_id'
};

const GridCellExpand = React.memo((props: GridCellExpandProps) => {
  const { value, source } = props;

  const hrefUrl =
    source === 'euct'
      ? `https://www.clinicaltrialsregister.eu/ctr-search/trial/${value}/results`
      : `https://clinicaltrials.gov/ct2/show/${value}`;

  return (
    <Tooltip title={`Click to Open Clinical Trials Gov Page for ${value}`}>
      <a
        href={hrefUrl}
        target='_blank'
        style={{ color: 'white', textDecoration: 'none' }}
        rel='noreferrer'>
        <LaunchIcon
          style={{ color: 'black', fontSize: 20, paddingLeft: '5px', paddingTop: '5px' }}
        />
      </a>
    </Tooltip>
  );
});

const DatagridLink = (params: GridRenderCellParams, source = 'usnlm') => {
  const { row } = params;
  return <GridCellExpand value={row[UNIQUE_CT_ID[source]] || ''} source={source} />;
};

export default DatagridLink;
