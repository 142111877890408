import { useHistory } from 'react-router-dom';
import { Chip, ListItem, Stack } from '@mui/material';
import styles from '../styles/HeaderTabs.styles';
import { Header } from '../interface';

const HeaderTabs = ({
  header,
  selectedTab,
  setSelectedTab,
  handleTabClickAction
}: {
  header: Header[];
  selectedTab: string;
  setSelectedTab: any;
  handleTabClickAction?: Function;
}) => {
  const history = useHistory();

  const handleChange = (key: string) => {
    setSelectedTab(key);
    // add query params to the url
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('tab', key);
    history.push(currentUrl.pathname + currentUrl.search);
    handleTabClickAction?.();
  };

  return (
    <Stack direction='row' alignItems='start' justifyContent='start' sx={styles.container}>
      {header.map((type: any) => {
        return (
          <ListItem
            key={type.key}
            sx={{
              pl: 0,
              pr: '8px',
              width: 'fit-content',
              py: 0
            }}>
            <Chip
              onClick={() => handleChange(type.key)}
              sx={{
                fontSize: 13,
                '&:hover': {
                  backgroundColor: (theme: any) =>
                    `${theme.palette.primary.backgroundLight} !important`,
                  borderColor: (theme: any) => `${theme.palette.primary.main} !important`
                },
                '&.MuiChip-filled': {
                  backgroundColor: (theme: any) =>
                    `${theme.palette.primary.lightVariant1} !important`,
                  color: (theme: any) => `${theme.palette.primary.darkVariant2} !important`,
                  fontWeight: 'bold'
                },
                '& > .MuiChip-label': {
                  padding: '8px 16px'
                },
                color: (theme: any) => theme.palette.primary.darkVariant1,
                borderColor: (theme: any) => theme.palette.gray.lightVariant8
              }}
              variant={selectedTab === type.key ? 'filled' : 'outlined'}
              label={type.label}
            />
          </ListItem>
        );
      })}
    </Stack>
  );
};

HeaderTabs.defaultProps = {
  handleTabClickAction: () => {}
};

export default HeaderTabs;
