const adcommCommitteeMapping = {
  All: 'All Committees',
  NDAC: 'Nonprescription Drug Advisory Committee',
  ODAC: 'Oncologic Drugs Advisory Committee',
  AADAC: 'Anesthetic and Analgesic Drug Products Advisory Committee',
  AMDAC: 'Antimicrobial Drugs Advisory Committee',
  AVDAC: 'Antiviral Drugs Advisory Committee',
  ARDAC: 'Arthritis Advisory Committee',
  BRUDAC: 'Bone, Reproductive and Urologic Drugs Advisory Committee',
  CRDAC: 'Cardiovascular and Renal Drugs Advisory Committee',
  DODAC: 'Dermatologic and Ophthalmic Drugs Advisory Committee',
  DSRMDAC: 'Drug Safety and Risk Management Advisory Committee',
  EMDAC: 'Endocrinologic and Metabolic Drugs Advisory Committee',
  GIDAC: 'Gastrointestinal Drugs Advisory Committee',
  MIDAC: 'Medical Imaging Drugs Advisory Committee',
  PCNSDAC: 'Peripheral and Central Nervous System Drugs Advisory Committee',
  PSCPDAC: 'Pharmaceutical Science and Clinical Pharmacology Advisory Committee',
  PCAC: 'Pharmacy Compounding Advisory Committee',
  PPDAC: 'Psychopharmacologic Drugs Advisory Committee',
  PADAC: 'Pulmonary-Allergy Drugs Advisory Committee'
};

/**
 * function to generate description text below aria search field when redirected from cards page
 * @param {string} searchTerm search term for the cards results page
 * @returns string that will be shown below aria search field
 */
const generateDescriptionText = searchTerm => {
  let resultString = '';
  if (searchTerm && searchTerm === 'rangeSearch') {
    return false;
  }

  // get the aria cache
  const ariaCache = JSON.parse(sessionStorage.getItem('aria_cache'));

  // check if it is adcomm search
  if (ariaCache?.navigation.includes('adcomm')) {
    if (!searchTerm) {
      return false;
    }
    // check if this is for adcomm meetings page where meeting-id is used as filter Eg. AVDAC_2016-05-12
    if (searchTerm?.includes('_')) {
      const splitSearchTerm = searchTerm?.split('_');
      resultString += ` ${adcommCommitteeMapping[splitSearchTerm[0]]} on ${splitSearchTerm[1]}`;
    } else {
      // else this is for adcomm committee Eg. AVDAC
      resultString += ` ${adcommCommitteeMapping[searchTerm] || searchTerm}`;
    }
    return resultString;
  }
  if (
    ariaCache?.navigation?.includes('pediatricsEMA') ||
    ariaCache?.navigation?.includes('guidance') ||
    ariaCache?.navigation?.includes('ema-guidance') ||
    ariaCache?.navigation?.includes('canada-guidance') ||
    ariaCache?.navigation?.includes('fda-letters') ||
    ariaCache?.navigation?.includes('fda-written-request') ||
    ariaCache?.navigation?.includes('hta')
  ) {
    return false;
  }
  // advance search details on local storage
  if (ariaCache?.navigation?.includes('advance')) {
    return `ADVANCED SEARCH`;
  }
  if (!searchTerm) {
    return false;
  }
  if (searchTerm) {
    return `${searchTerm}`;
  }

  return `${resultString}`;
};

export default generateDescriptionText;
