import makeRequest, { makeRequestWithCancel } from '../client';

/**
 * Function to do a quick search for core and biologics module
 * @param {object} payload
 */
export const getLabelSearch = payload =>
  makeRequest(
    `/clignosis/labelSearch/${payload.category}/${payload.search}/${payload.source}${payload.query}`,
    'GET',
    {},
    {
      role: payload.role // Temp header for saving the user search histories
    }
  );

/**
 * Function to do a quick search for 505b2 module
 * @param {object} payload
 */
export const getLabelSearchApprovalPathway = payload =>
  makeRequest(
    `/clignosis/labelSearch/505b2/${payload.category}/${payload.search}/${payload.source}${payload.query}`,
    'GET',
    {},
    {
      role: payload.role // Temp header for saving the user search histories
    }
  );

/**
 * Function to do a advance search for 505b2 module
 * @param {object} payload
 */
export const getAdvanceSearch505b2 = payload =>
  makeRequest(`/clignosis/advanceSearch/505b2`, 'POST', payload.data);

/**
 * Function to do a advance search for core and biologics module
 * @param {object} payload
 * @param {string} db
 */
export const getAdvanceSearch = (payload, moduleName, db) =>
  makeRequest(`/advanced-search/${moduleName}/${db}`, 'POST', payload, {
    role: payload.role // Temp header for saving the user search histories
  });

/**
 * Function to get information for extended cards
 * @param {string} applicationNumber
 */
export const getExtendedCard = applicationNumber =>
  makeRequest(`/clignosis/extended-card/${applicationNumber}`, 'GET');

/**
 * Function to get information for generics
 * @param {string} applicationNumber
 */
export const getGenerics = applicationNumber =>
  makeRequest(`/clignosis/generic_count/${applicationNumber}`, 'GET');

/**
 * Function to get information for mesh terms/synonyms
 * @param {string} category
 * @param {string} module
 * @param {string} activeIngredient
 * @param {string} source
 * @param {object} payload
 */
export const getSearchList = (category, activeIngredient, source, payload) =>
  makeRequest(
    `/quick_search/synonyms/${source.toLowerCase()}/${category}/${activeIngredient}?synonyms=true`,
    'GET',
    payload
  );

export const get505b2SearchList = (category, activeIngredient, source, module, payload) =>
  makeRequest(
    `/quick_search/synonyms/${source.toLowerCase()}/${category}/${activeIngredient}${
      module ? `?module_name=${module}` : ''
    }`,
    'GET',
    payload
  );

/**
 * Function to get information for mesh terms/synonyms from an Advanced Search
 * @param {string} module
 * @param {string} db
 * @param {object} payload
 */
export const getAdvancedSearchList = (db, payload) =>
  makeRequest(`/advanced-search/synonyms/${db.toLowerCase()}`, 'POST', payload);

export const get505b2AdvancedSearchList = (db, payload) =>
  makeRequest(`/advanced-search/synonyms/${module}/${db.toLowerCase()}`, 'POST', payload);

/**
 * Function to get information for mesh terms/synonyms
 * @param {string} category
 * @param {string} activeIngredient
 */
export const getCtSearchList = (category, activeIngredient) =>
  makeRequest(`/clignosis/ct_gov/${category}/${activeIngredient}?synonyms=true`, 'GET');

/**
 * Function to get all favaourite cards of a user.
 * @param {string} userId
 */
export const getFavouriteApplications = userId =>
  makeRequest(`/profile/favorites/applications?user_id=${userId}`, 'GET');

/**
 * Function to add card to user's favourite list.
 * @param {object} payload
 */
export const submitFavouriteApplication = payload =>
  makeRequest(`/profile/favorites/applications`, 'POST', payload);

/**
 * Function to remove favourite card from the user's favourite list.
 * @param {object} payload
 */
export const removeFavouriteApplication = payload =>
  makeRequest(`/profile/favorites/applications`, 'DELETE', payload);

/**
 * Funtion to get parenthesized query for advance search selected values
 * @param {object} payload
 */
export const getCnfQuery = payload => makeRequest(`/advanced-search/get-cnf`, 'POST', payload);

/**
 * @param {string} application gives the application number
 * @param {string} section gives the filename we want to fetch
 * @description returns the link for the latest submission section
 */
export const getLatestSubmissonSection = (application, section) =>
  makeRequest(`/clignosis/latest_section_pdf/${application}/${section}`, 'GET');

export const getATCTreeAPIData = (source, search) =>
  makeRequest(`/quick_search/${source}/atc_classification/${search}/atc_tree`);

/**
 * Function to get Stack approval history list of all applications
 * @param {string} source
 * @param {object} payload
 * @param {string} feature
 */
export const getStackedList = (source, payload, feature, cancelToken) =>
  makeRequestWithCancel(
    `/reg360/${source.toLowerCase()}/approval_history?feature_name=${feature}`,
    'POST',
    payload,
    {},
    cancelToken
  );

/**
 * Function to get Stack approval history list of all applications
 * @param {string} source
 * @param {object} payload
 * @param {string} feature
 */
export const getCommissionProcedureList = (source, payload, feature, cancelToken) =>
  makeRequestWithCancel(
    `/reg360/${source.toLowerCase()}/commission_procedures?feature_name=${feature}`,
    'POST',
    payload,
    {},
    cancelToken
  );
