import React, { useCallback, useState } from 'react';
import { Box, IconButton, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import concat from 'lodash/concat';
import PDFCompare from './PDFCompare';
import { ThreeDotIcon, ComparisonIcon } from '../../../assets/svgs/Icons';
import { RoundedMenu } from '../../../components/ui/Menu';
import styles from '../styles/Insights.styles';

const LabelCompareButton: React.FC<any> = ({ value }) => {
  const [openPopup, setOpenPopup] = useState<Boolean>(false);
  const [compareList, setCompareList] = useState<any>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = useCallback(() => {
    // save unique id to show as heading in comparison dialog box
    setCompareList({
      name: concat(value.application_type, value.application_number, value.determination_date),
      documentList: value.s3_paths
    });
    setOpenPopup(true);
  }, [openPopup, compareList]);

  const handleLabelSelectionClose = useCallback(() => {
    setOpenPopup(false);
  }, [openPopup]);
  return (
    <>
      <Box>
        <IconButton size='small' onClick={handleClick}>
          <ThreeDotIcon />
        </IconButton>
        <RoundedMenu
          id='demo-customized-menu'
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button'
          }}
          anchorEl={anchorEl}
          open={open}
          sx={styles.roundedMenu}
          onClose={handleClose}>
          <MenuItem sx={styles.menuItem} onClick={handleOpen}>
            <ListItemIcon sx={styles.listItemIcon}>
              <ComparisonIcon sx={styles.svgIcon} />
            </ListItemIcon>
            <ListItemText sx={styles.listItemText}>Compare</ListItemText>
          </MenuItem>
        </RoundedMenu>
      </Box>
      {openPopup ? (
        <PDFCompare open={openPopup} onClose={handleLabelSelectionClose} rowData={compareList} />
      ) : null}
    </>
  );
};

export default React.memo(LabelCompareButton);
