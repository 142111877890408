/* eslint-disable no-param-reassign */
import getFormatDataForCSV from './getFormatDataForCSV';

export const REVIEW_MAPPING = [
  'Summary Review',
  'Cross Discipline Team Leader Review',
  'Office Director Memo',
  'Multi-Discipline Review',
  'Integrated Review'
];

export const DEVICES_FLAG = [
  'combination_product',
  'expedited_review_flag',
  'gmp_exempt_flag',
  'implant_flag',
  'life_sustain_support_flag',
  'third_party_flag'
];

const getCSVData = (source: string, data: any, domain: string) => {
  try {
    if (
      source.toLowerCase() === 'us' ||
      source.toLowerCase() === 'eu' ||
      source.toLowerCase() === 'eua'
    ) {
      data.forEach((element: any) => {
        const labels: Array<string> = [];
        const letter: Array<string> = [];
        const review: Array<string> = [];
        const nctId: Array<string> = [];
        const rapporteur: Array<string> = [];
        const coRapporteur: Array<string> = [];
        if ('labels' in element.file_links) {
          element.file_links.labels.forEach((ele: any) => {
            labels.push(
              `${domain}/resource?bucket=${ele.bucket}&filename=${encodeURIComponent(ele.s3_path)}`
            );
          });

          element.labels = labels.join('\n');
        }

        if ('letter' in element.file_links) {
          element.file_links.letter.forEach((ele: any) => {
            letter.push(
              `${domain}/resource?bucket=${ele.bucket}&filename=${encodeURIComponent(ele.s3_path)}`
            );
          });
          element.letter = letter.join('\n');
        }

        if ('review' in element.file_links) {
          if (source.toLowerCase() === 'us') {
            element.file_links.review
              .filter((item: any) => REVIEW_MAPPING.includes(item.category))
              .forEach((ele: any) => {
                review.push(
                  `${domain}/resource?bucket=${ele.bucket}&filename=${encodeURIComponent(
                    ele.s3_path
                  )}`
                );
              });
          } else {
            element.file_links.review.forEach((ele: any) => {
              review.push(
                `${domain}/resource?bucket=${ele.bucket}&filename=${encodeURIComponent(
                  ele.s3_path
                )}`
              );
            });
          }

          element.review = review.join('\n');
        }

        if ('trials_in_scope' in element) {
          element.trials_in_scope.forEach((ele: any) => {
            nctId.push(ele.nct_id);
          });

          element.nct_id = nctId.join(', ');
        }

        if ('rapporteur' in element) {
          element.rapporteur.forEach((ele: any) => {
            if (ele.designation === 'rapporteur') {
              rapporteur.push(ele);
            } else {
              coRapporteur.push(ele);
            }
          });
          element.rapporteur = getFormatDataForCSV(rapporteur);
          element.co_rapporteur = getFormatDataForCSV(coRapporteur);
        }
      });

      return data;
    }
    if (source.toLowerCase() === 'ca') {
      data.forEach((element: any) => {
        const productMonogrpah: Array<string> = [];
        if ('Product Monograph' in element.file_links) {
          element.file_links['Product Monograph'].forEach((ele: any) => {
            productMonogrpah.push(
              `${domain}/resource?bucket=${ele.bucket}&filename=${ele.s3_path}`
            );
          });
          element.product_monogrpah = productMonogrpah.join('\n');
        }
      });
    }
    if (source.toLowerCase() === 'hpra') {
      data.forEach((element: any) => {
        const documents: Array<string> = [];
        if ('Products' in element.file_links) {
          element.file_links?.Products.forEach((ele: any) => {
            documents.push(`${domain}/resource?bucket=${ele.bucket}&filename=${ele.s3_path}`);
          });
          element.documents = documents.join('\n');
        }
      });
    }
    if (source.toLowerCase() === 'hma') {
      data.forEach((element: any) => {
        const documents: Array<string> = [];
        Object.keys(element?.file_links).forEach((key: string) => {
          element.file_links?.[key].forEach((ele: any) => {
            documents.push(`${domain}/resource?bucket=${ele.bucket}&filename=${ele.s3_path}`);
          });
          element.documents = documents.join('\n');
        });
      });
    }
    if (source.toLowerCase() === 'us-devices') {
      data.forEach((element: any) => {
        const labels: Array<string> = [];
        const letter: Array<string> = [];
        const review: Array<string> = [];
        const other: Array<string> = [];
        const approvalOrder: Array<string> = [];
        const summary: Array<string> = [];
        if ('labels' in element.file_links) {
          element.file_links.labels.forEach((ele: any) => {
            labels.push(
              `${domain}/resource?bucket=${ele.bucket}&filename=${encodeURIComponent(ele.s3_path)}`
            );
          });

          // eslint-disable-next-line no-param-reassign
          element.labels = labels.join('\n');
        }

        if ('letter' in element.file_links) {
          element.file_links.letter.forEach((ele: any) => {
            letter.push(
              `${domain}/resource?bucket=${ele.bucket}&filename=${encodeURIComponent(ele.s3_path)}`
            );
          });
          // eslint-disable-next-line no-param-reassign
          element.letter = letter.join('\n');
        }

        if ('review' in element.file_links) {
          element.file_links.review.forEach((ele: any) => {
            review.push(
              `${domain}/resource?bucket=${ele.bucket}&filename=${encodeURIComponent(ele.s3_path)}`
            );
          });

          // eslint-disable-next-line no-param-reassign
          element.review = review.join('\n');
        }

        if ('other' in element.file_links) {
          element.file_links.other.forEach((ele: any) => {
            other.push(
              `${domain}/resource?bucket=${ele.bucket}&filename=${encodeURIComponent(ele.s3_path)}`
            );
          });
          // eslint-disable-next-line no-param-reassign
          element.other = other.join('\n');
        }

        if ('approval_order' in element.file_links) {
          element.file_links.approval_order.forEach((ele: any) => {
            approvalOrder.push(
              `${domain}/resource?bucket=${ele.bucket}&filename=${encodeURIComponent(ele.s3_path)}`
            );
          });
          // eslint-disable-next-line no-param-reassign
          element.approval_order = approvalOrder.join('\n');
        }

        if ('summary' in element.file_links) {
          element.file_links.summary.forEach((ele: any) => {
            summary.push(
              `${domain}/resource?bucket=${ele.bucket}&filename=${encodeURIComponent(ele.s3_path)}`
            );
          });
          // eslint-disable-next-line no-param-reassign
          element.summary = summary.join('\n');
        }
        DEVICES_FLAG.forEach((flag: string) => {
          if (flag in element) {
            if (element[flag]) {
              element[flag] = 'Yes';
            } else {
              element[flag] = 'No';
            }
          }
        });
      });
    }
    if (source.toLowerCase() === 'jp') {
      data.forEach((element: any) => {
        const link: any = {};
        Object.entries(element.file_links).forEach(([key, value]: any) => {
          if (!(key in link)) {
            link[key] = [];
          }
          link[key].push(
            `${domain}/resource?bucket=${value.bucket}&filename=${encodeURIComponent(
              value.s3_path
            )}`
          );
          element[key] = link[key].join('\n');
        });
      });
    }
  } catch (error) {
    return data;
  }
  return data;
};

export default getCSVData;
