import { createSvgIcon } from '@mui/material';

const ActivityIcon = createSvgIcon(
  <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_14267_2246)'>
      <path
        d='M2 14.1667V0.5H0V14.1667C0 14.7855 0.245833 15.379 0.683417 15.8166C1.121 16.2542 1.71449 16.5 2.33333 16.5H16V14.5H2.33333C2.24493 14.5 2.16014 14.4649 2.09763 14.4024C2.03512 14.3399 2 14.2551 2 14.1667Z'
        fill='currentColor'
      />
      <path
        d='M15.0003 3.83337H11.3336L12.9596 5.45937L10.667 7.75271L9.0003 6.08604L4.29297 10.7927L5.70764 12.2074L9.0003 8.91404L10.667 10.5807L14.3743 6.87404L16.0003 8.50004V4.83337C16.0003 4.56816 15.8949 4.3138 15.7074 4.12627C15.5199 3.93873 15.2655 3.83337 15.0003 3.83337Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_14267_2246'>
        <rect width='16' height='16' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>,
  'MyReports'
);

export default ActivityIcon;
