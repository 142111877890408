import { Backdrop, Box, LinearProgress, Stack, Typography } from '@mui/material';
import { chatInitialize } from '../styles/styles';
import React from 'react';

const InitializeChat = ({ open }: { open: boolean }) => {
  return (
    <Backdrop sx={chatInitialize} open={open}>
      <Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
        <Box>
          <Typography variant='h6' color='inherit'>
            Initializing chat...
          </Typography>
        </Box>
        <Box mt={2} width={'100%'}>
          <LinearProgress color='primary' />
        </Box>
      </Stack>
    </Backdrop>
  );
};

export default React.memo(InitializeChat);
