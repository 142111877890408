const styles = {
  disclaimer: {
    fontSize: '1.8vh',
    color: 'gray.main',
    fontWeight: 'bold',
    paddingLeft: 2
  }
};

export default styles;
