const styles = {
  styledAccordion: {
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    },
    width: '100%',
    '& .MuiPaper-root': {
      width: '100%'
    }
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    right: 0,
    width: 320,
    height: '100vh',
    marginRight: '50%',
    borderRadius: '8px 0px 0px 8px',
    borderRight: '1px solid',
    borderColor: 'primary.avatar',
    backgroundColor: 'white.main'
  },
  innerWrapper: {
    display: 'flex',
    height: '5.48%',
    py: 1.5,
    px: 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: 'primary.300',
    borderRadius: '8px 0px 0px 0px'
  },
  headerText: {
    color: 'white.main',
    fontFamily: 'Mulish',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '150%' /* 30px */
  },
  sectionWrapper: {
    paddingTop: '8px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '7px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'grey.300',
      borderRadius: '5px'
    },
    borderRadius: '0px 0px 0px 8px'
  },
  accordionSummary: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      height: '20px',
      width: '100%'
    },
    '& .MuiPaper-root': {
      width: '100%'
    }
  },
  caretDown: {
    fontSize: 16,

    color: 'gray.800'
  },
  radio: {
    p: 0.6,
    '& .MuiSvgIcon-root': {
      fontSize: 18
    }
  },
  pipNumberText: {
    color: 'gray.950',
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal'
  },
  accordionDetails: {
    p: '0px 24px 14px',
    margin: '0px 16px',
    borderBottom: '2px solid',
    borderBottomColor: 'gray.background'
  },
  accordionInnerBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  accordionHeaderText: {
    overflow: 'hidden',
    color: 'gray.650',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontFamily: 'Mulish',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '120%',
    textTransform: 'capitalize'
  },
  accordionHeaderContent: {
    overflow: 'hidden',
    color: 'primary.darkVariant1',
    textOverflow: 'ellipsis',
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '120%',
    textTransform: 'capitalize'
  }
};

export default styles;
