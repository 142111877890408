export default {
  OPEN_ALL_SEE_MORE: 'OPEN_ALL_SEE_MORE',
  SET_VIEW: 'SET_VIEW',
  SET_EXPAND_CARD: 'SET_EXPAND_CARD',
  SET_EXPAND_CARD_PAYLOAD: 'SET_EXPAND_CARD_PAYLOAD',
  SORT_BY: 'SORT_BY',
  SET_PUBLISHED_DATE: 'SET_PUBLISHED_DATE',
  SET_LOADING: 'SET_LOADING',
  SET_FILTERS: 'SET_FILTERS',
  SET_IS_FILTERS_APPLIED: 'SET_IS_FILTERS_APPLIED'
};
