import { Checkbox } from '@mui/material';
import {
  CheckboxCheckedIcon,
  CheckboxIndeterminateIcon,
  CheckboxUncheckedIcon
} from '../../../assets/svgs/Icons';

const CustomCheckbox = props => {
  // eslint-disable-next-line react/prop-types
  const { disabled, fontSize = 16 } = props;

  return (
    <Checkbox
      icon={<CheckboxUncheckedIcon sx={{ color: disabled ? 'grey.400' : 'gray.800', fontSize }} />}
      checkedIcon={
        <CheckboxCheckedIcon sx={{ color: disabled ? 'grey.400' : 'primary.600', fontSize }} />
      }
      indeterminateIcon={
        <CheckboxIndeterminateIcon
          sx={{ color: disabled ? 'grey.400' : 'primary.600', fontSize }}
        />
      }
      sx={{
        '&.Mui-disabled': {
          color: 'grey.400'
        }
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default CustomCheckbox;
