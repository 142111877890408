import React from 'react';

const SearchListIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <g id='Group_1008' data-name='Group 1008' transform='translate(-1687.095 -144.015)'>
        <g id='Group_1004' data-name='Group 1004' transform='translate(0 -56)'>
          <rect
            id='Rectangle_477'
            data-name='Rectangle 477'
            width='24'
            height='24'
            transform='translate(1687.095 200.015)'
            fill='none'
          />
        </g>
        <g
          id='search-shot-interface-symbol-with-a-magnifier-tool'
          transform='translate(1690.564 145.839)'>
          <g id='Group_469' data-name='Group 469' transform='translate(0 1.978)'>
            <path
              id='Path_346'
              data-name='Path 346'
              d='M17.323,10.276a4.7,4.7,0,0,0-7.088,6.138l-.815.815a1.215,1.215,0,0,0-1.1.332l-3.5,3.5a1.217,1.217,0,0,0,1.721,1.721l3.5-3.5a1.216,1.216,0,0,0,.332-1.1l.815-.815a4.7,4.7,0,0,0,6.138-7.089Zm-5.7,5.7a3.358,3.358,0,1,1,4.748,0A3.361,3.361,0,0,1,11.625,15.974Z'
              transform='translate(-3.71 -7.739)'
              fill='#646464'
            />
            <path
              id='Path_347'
              data-name='Path 347'
              d='M1.2,2.65H4.7V1.978H1.2A1.2,1.2,0,0,0,0,3.174v3.5H.672v-3.5A.525.525,0,0,1,1.2,2.65Z'
              transform='translate(0 -1.978)'
              fill='#646464'
            />
            <path
              id='Path_348'
              data-name='Path 348'
              d='M77.652,71.645v3.5a.525.525,0,0,1-.524.524h-3.5v.672h3.5a1.2,1.2,0,0,0,1.2-1.2v-3.5Z'
              transform='translate(-61.263 -59.949)'
              fill='#646464'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SearchListIcon;
