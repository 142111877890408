import React from 'react';
import { Box } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineCard from './TimelineCard';
import TimelineDotIcon from '../../../assets/svgs/PDCO/TimelineDot';
import styles from '../styles/TimelineDetails.styles';

export const BasicTimeline = ({
  timelineTrack,
  setPdfPath,
  setDocTitle,
  setOpenDocument,
  setMeetingId,
  selectedMeetingId
}: any) => {
  return (
    <Box sx={styles.wrapper}>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0
          },
          m: 0,
          py: 0
        }}>
        {timelineTrack?.length > 0 &&
          timelineTrack.map((timelineFact: any, index: number) => {
            const isLastItem = index === timelineTrack.length - 1;
            return (
              <TimelineItem key={`${timelineFact.meeting_id}-${timelineFact.pdco_number}`}>
                <TimelineSeparator sx={{ mt: 1.25 }}>
                  <TimelineDotIcon sx={{ m: 0, fontSize: 12 }} />
                  <TimelineConnector
                    sx={{
                      width: '1px',
                      height: isLastItem ? '57%' : '100%',
                      mb: -1.25,
                      flexGrow: 0
                    }}
                  />
                </TimelineSeparator>
                <TimelineContent sx={styles.contentWrapper}>
                  <TimelineCard
                    section={`${timelineFact?.sub_section_number?.slice(0, 3)} ${
                      timelineFact?.full_section_name
                    }`}
                    pdcoNumber={timelineFact?.pdco_number}
                    documentUrl={timelineFact?.s3_url}
                    summaryText={timelineFact?.summary}
                    setPdfPath={setPdfPath}
                    setDocTitle={setDocTitle}
                    fileName={timelineFact?.file_name}
                    setOpenDocument={setOpenDocument}
                    meetingId={timelineFact.meeting_id}
                    setMeetingId={setMeetingId}
                    selectedMeetingId={selectedMeetingId}
                    opinionDay={timelineFact?.opinion_day}
                  />
                </TimelineContent>
              </TimelineItem>
            );
          })}
      </Timeline>
    </Box>
  );
};

export default React.memo(BasicTimeline);
