import { Grid, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { memo } from 'react';
import { styled, ThemeProvider } from '@mui/material/styles';
import { Settings } from '@mui/icons-material';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { SubscribeIconFilled } from '../../assets/svgs/Icons';
import themev5 from '../../themev5';
import notificationsStyles from './components/Notifications.styles';
import NotificationView from './NotificationView';
import NotificationSettings from './NotificationSettings';
import { NotificationsStateProvider } from '../../store/Notifications';
import useUpdateModule from '../../hooks/useUpdateModule';

export const StyledListItemButton = styled(ListItemButton)<{ component?: React.ElementType }>({
  marginBottom: '5px !important',
  '& > div > span': {
    color: `${themev5.palette.grey['700']} !important`,
    fontSize: `16px !important`
  },
  '&.Mui-selected': {
    borderRadius: '16px', // @ts-ignore
    backgroundColor: `${themev5.palette.primary['50']} !important`,
    fontSize: `16px !important`, // @ts-ignore
    color: `${themev5.palette.primary['700']} !important`,
    '& > div ': {
      fontWeight: `700 !important`,
      '& > span': {
        fontWeight: `700 !important`, // @ts-ignore
        color: `${themev5.palette.primary['800']} !important`
      },
      '& > svg': {
        // @ts-ignore
        fill: `${themev5.palette.primary['800']} !important`, // @ts-ignore
        color: `${themev5.palette.primary['800']} !important`
      }
    }
  },
  '&:hover': {
    borderRadius: '16px'
  },
  '& .MuiSvgIcon-root': { fill: `${themev5.palette.grey['700']} !important` },
  '& > .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: '12px'
  }
});

const Notifications = () => {
  const { type } = useParams<any>();
  useUpdateModule('notifications');

  const history = useHistory();
  const location = useLocation();

  const handleClick = (path: string) => {
    history.push(path);
  };

  return (
    <ThemeProvider theme={themev5}>
      <NotificationsStateProvider>
        <Grid maxWidth='1440px' container mt='20px' mx='auto' height='90vh' columnSpacing='16px'>
          <Grid item md={2}>
            <List
              sx={notificationsStyles.menuContainer}
              component='nav'
              aria-labelledby='nested-list-subheader'>
              <StyledListItemButton
                selected={location.pathname === '/notifications/notification'}
                onClick={() => handleClick('/notifications/notification')}>
                <ListItemIcon>
                  <SubscribeIconFilled sx={{ fontSize: 16 }} />
                </ListItemIcon>
                <ListItemText primary='Notifications' />
              </StyledListItemButton>
              <StyledListItemButton
                selected={location.pathname === '/notifications/settings'}
                onClick={() => handleClick('/notifications/settings')}>
                <ListItemIcon>
                  <Settings sx={{ fontSize: 16 }} />
                </ListItemIcon>
                <ListItemText primary='My subscriptions & settings' />
              </StyledListItemButton>
            </List>
          </Grid>
          {type === 'settings' ? <NotificationSettings /> : <NotificationView />}
        </Grid>
      </NotificationsStateProvider>
    </ThemeProvider>
  );
};
export default memo(Notifications);
