import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import CompareChip, { CompareChipPlaceholder } from './CompareChip';

interface ComparisonButtonProps {
  documentsToCompare: any[];
  // eslint-disable-next-line no-unused-vars
  removeFromComparison: (id: string) => void;
  // eslint-disable-next-line no-unused-vars
  openComparisonPopup: (value: boolean) => void;
  clearComparison: () => void;
}

const ComparisonButtons = ({
  documentsToCompare,
  removeFromComparison,
  openComparisonPopup,
  clearComparison
}: ComparisonButtonProps) => {
  return (
    <Stack spacing={1} sx={{ pb: 1 }} alignItems='center' direction='row'>
      {documentsToCompare.map((doc: any) => (
        <CompareChip
          // eslint-disable-next-line no-underscore-dangle
          key={`selected_doc${doc._id}`}
          title={doc.title}
          // eslint-disable-next-line no-underscore-dangle
          value={doc._id}
          status={doc.status}
          issueDate={doc.issue_datetime}
          onClose={removeFromComparison}
        />
      ))}
      {Array(2 - documentsToCompare.length)
        .fill(1)
        .map((_, index: number) => (
          <CompareChipPlaceholder
            key={`Document ${index + 1 + documentsToCompare.length}`}
            title={`Document ${index + 1 + documentsToCompare.length}`}
          />
        ))}
      <Stack spacing={2} alignItems='center' direction='row'>
        <Button
          variant='contained'
          type='submit'
          sx={{
            color: 'white.main',
            fontWeight: 'bold',
            borderRadius: '30px',
            textTransform: 'capitalize'
          }}
          onClick={() => openComparisonPopup(true)}
          disabled={documentsToCompare.length !== 2}
        >
          Compare
        </Button>
        <Button
          variant='contained'
          type='submit'
          disabled={documentsToCompare.length === 0}
          sx={{
            color: 'white.main',
            fontWeight: 'bold',
            borderRadius: '30px',
            textTransform: 'capitalize'
          }}
          onClick={clearComparison}
        >
          Clear Selection
        </Button>
      </Stack>
    </Stack>
  );
};

export default React.memo(ComparisonButtons);
