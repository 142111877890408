import { Modal, Box, Typography, Button } from '@mui/material';
import { WarningIcon } from '../../assets/svgs/Icons';
import { ApplyFilterAlertProps } from './types';
import { applyFilterAlertStyles } from './styles';

const ApplyFilterAlert = ({
  showAlert,
  applyFilters,
  selectedCategoryOptions,
  previousSelectedCategoryOptions,
  setSelectedCategoryOptions,
  handleClose
}: ApplyFilterAlertProps) => {
  const handleApplyFilters = async () => {
    await applyFilters(selectedCategoryOptions);
    handleClose();
  };

  const handleExit = () => {
    setSelectedCategoryOptions?.(previousSelectedCategoryOptions);
    handleClose();
  };

  return (
    <Modal open={showAlert}>
      <Box sx={applyFilterAlertStyles.container}>
        <Box sx={applyFilterAlertStyles.messageContainer}>
          <WarningIcon sx={applyFilterAlertStyles.warningIcon} />
          <Typography sx={applyFilterAlertStyles.message}>
            You need to click ‘Apply’ before closing filters to see changes.{' '}
            <span style={{ fontWeight: 700 }}>Do you want to ‘Apply’ changes?</span>
          </Typography>
        </Box>
        <Box sx={applyFilterAlertStyles.confirmButtonGroup}>
          <Button size='small' onClick={handleExit} sx={applyFilterAlertStyles.noButton}>
            No, Exit
          </Button>
          <Button size='small' onClick={handleApplyFilters} sx={applyFilterAlertStyles.yesButton}>
            Yes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ApplyFilterAlert;
