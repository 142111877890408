function findBreakPointKey(inputNumber: any, breakpoints: any) {
  // Sorting keys by breakpoint values
  const sortedKeys = Object.keys(breakpoints).sort((a, b) => breakpoints[a] - breakpoints[b]);

  // Finding the appropriate key based on the input number
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < sortedKeys.length - 1; i++) {
    const currentKey = sortedKeys[i];
    const nextKey = sortedKeys[i + 1];

    if (inputNumber >= breakpoints[currentKey] && inputNumber < breakpoints[nextKey]) {
      return currentKey;
    }
  }

  // If the input number is greater than or equal to the largest breakpoint, return the last key
  return sortedKeys[sortedKeys.length - 1];
}

export default findBreakPointKey;
