import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    paddingLeft: '5px',
    background: '#289D78',
    color: '#FFFFFF',
    fontSize: '1.5vh'
  }
}));

export default StyledBadge;
