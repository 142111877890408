import React from 'react';

import { format } from 'date-fns';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { GridCellExpand } from '../DatagridTooltip';
import { HTA_DATE_FIELDS } from './constants';
import { UTCtoLocalTime } from '../../Header/utils/localTimeZoneDate';

interface GridCellExpandProps {
  value: string;
  field: string;
}

const formatFields: { [key: string]: string } = {
  ...HTA_DATE_FIELDS
};

const GridCellDate = React.memo((props: GridCellExpandProps) => {
  let { value } = props;
  const { field } = props;

  if (field in formatFields) {
    try {
      value = format(UTCtoLocalTime(value), formatFields[field]);
    } catch {
      // eslint-disable-next-line no-self-assign
      value = value;
    }
  }

  return <GridCellExpand value={value} field={field} />;
});

const DatagridDate = (params: GridRenderCellParams) => {
  const { value, field } = params;
  return <GridCellDate value={value || ''} field={field} />;
};

export default DatagridDate;
