import { ALL_SECTIONS_FILTER_OPTIONS } from '../NewFilters/constants';
import { IGNORE_SECTION_BY_SOURCE, SECTION_RENAMING_BY_SOURCE } from './const';

export default function getAllSections(source: string) {
  return (
    ALL_SECTIONS_FILTER_OPTIONS[source]
      ?.filter((section: any) => !IGNORE_SECTION_BY_SOURCE[source]?.includes(section))
      ?.map((section: any) => SECTION_RENAMING_BY_SOURCE[source]?.[section] ?? section) ?? []
  );
}
