import React, { useContext } from 'react';

// muiv5
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import VivproDatagrid from '../../../components/Datagrid';
import EmptyPage from '../../../components/EmptyPage/EmptyPage';
// context
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';

// hoc
import withFetchingExclusivityDetails from './withFetchingExclusivityDetails';

import { Exclusivity } from './constants';

const ExclusivityDetails = () => {
  const { regulatoryState } = useContext(RegulatoryInformationStore);

  return (
    <Box display='flex' justifyContent='center' sx={{ width: '100%', height: '100%' }}>
      {!regulatoryState?.loadingExclusivity && regulatoryState.exclusivity?.length <= 0 ? (
        <EmptyPage message='Exclusivity are not available' />
      ) : (
        <Stack flexDirection='row' sx={{ height: '60vh', width: 'calc(100% - 24px)' }}>
          <VivproDatagrid
            rows={regulatoryState?.exclusivity}
            columnsMapping={Exclusivity}
            rowId='exclusivity_code'
            loading={regulatoryState?.loadingExclusivity}
          />
        </Stack>
      )}
    </Box>
  );
};

export default withFetchingExclusivityDetails(ExclusivityDetails);
