import { ROWS, AVATARS } from './constants';

const constructAgeObject = (data: any[], rows: any) => {
  data.forEach((category: any) => {
    // eslint-disable-next-line no-param-reassign
    rows[category] = true;
  });

  return rows;
};

export const arrayAvatarData = (field: string, data: any[], source: any) => {
  if (field === 'age_category') {
    const rows = constructAgeObject(data, { ...ROWS[source] });
    return [rows, AVATARS[source]];
  }
  return [{}, []];
};

export default arrayAvatarData;
