const styles = {
  cardCheckbox: {
    '& svg': { fontSize: 18 },
    '&.Mui-checked': {
      color: 'primary.700'
    },
    p: 0.5
  },
  label: {
    maxWidth: '60%',
    overflow: 'hidden',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    fontSize: 14,
    p: 0.5
  }
};

export default styles;
