export const pieChartStyles = {
  enableArcLinkLabels: false,
  margin: { top: 20, right: 20, bottom: 20, left: 20 },
  pieLabel: true,
  pieLegend: true,
  enableLabels: 'inchart',
  legends: [
    {
      anchor: 'bottom',
      direction: 'row',
      justify: false,
      translateX: 0,
      translateY: 20,
      itemsSpacing: 0,
      itemWidth: 42,
      itemHeight: 18,
      itemTextColor: '#999',
      itemDirection: 'left-to-right',
      itemOpacity: 1,
      symbolSize: 9,
      symbolShape: 'circle',
      effects: [
        {
          on: 'hover',
          style: {
            itemTextColor: '#000'
          }
        }
      ]
    }
  ]
};

export const REPORT_QUERIES = [
  {
    id: 'us_result_approval_by_year',
    title: {
      title: 'Approval(s) by Year',
      show: true
    },
    graph: 'BAR',
    query: {
      'x-axis': ['approval_year'],
      agg_function: 'count',
      sort_on_axis: 'x'
    },
    graphStyle: {
      colors: ['#12815E'],
      axisLeft: {
        tickRotation: 0
      },
      enableLabel: false
    }
  },
  {
    id: 'us_result_approval_time_by_year',
    title: {
      title: 'Median Approval Time, Days from Filing',
      show: true
    },
    graph: 'BAR',
    query: {
      'x-axis': ['approval_year'],
      'y-axis': ['approval_time'],
      sort_on_axis: 'x',
      agg_function: 'median'
    },
    graphStyle: {
      colors: ['#6868FA'],
      padding: 0.4,
      axisBottom: {
        tickRotation: 90
      },
      axisLeft: {
        tickRotation: 0
      }
    }
  },
  {
    id: 'accelerated_approval_percentage',
    title: {
      title: 'Accelerated Approvals',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['accelerated_approval'],
      agg_function: 'percentage'
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'breakthrough_designation_percentage',
    title: {
      title: 'Breakthrough Designation',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['breakthrough_status'],
      agg_function: 'percentage'
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'fast_track_percentage',
    title: {
      title: 'Fast Track',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['fast_track'],
      agg_function: 'percentage'
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'orphan_designation_percentage',
    title: {
      title: 'Orphan Designation',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['orphan_status'],
      agg_function: 'percentage'
    },
    graphStyle: pieChartStyles
  }
];
