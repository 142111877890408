import { memo, useContext, useEffect } from 'react';

// mui v5
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import themev5 from '../../themev5';

// hoc
import PMRSurveillanceTabs from './hoc/Tabs';

// store
import Store from '../../store';
import Actions from '../../store/actions';

// Provider
import { PMRStateProvider } from '../../store/PMRSurveillance';

const PMRSurveillance = () => {
  const { dispatch } = useContext(Store) as any;

  useEffect(() => {
    dispatch({ type: Actions.SET_MODULE, value: 'pmrSurveillance' });
  }, []);

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <PMRStateProvider>
        <PMRSurveillanceTabs />
      </PMRStateProvider>
    </ThemeProvider>
  );
};

export default memo(PMRSurveillance);
