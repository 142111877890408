/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-named-as-default */
import React, { useCallback, useContext, useEffect, useState } from 'react';

// MUI components
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// Custom Components
import { useHistory, useLocation, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Badge, Divider, IconButton, Link } from '@mui/material';
import regulatoryInformationAPI from '../../../api/pages/Regulatory360';
// Utils
import { STATUS } from './constants';
import useQuery from '../../../helpers/customHooks/queryParameter';
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import Actions from '../../../store/RegulatoryInformation/actions';
// Icons
import ApplicationCard from '../../../components/ApplicationCard';
import FeatureButton from '../../../components/Buttons/FeatureButton';

import ChartIcon from '../../../assets/svgs/Bot/ChartIcon';
import { encodeJSONtoString } from '../../../helpers/backToEncoding';
import { getSearchParams } from '../../../hooks/useBreadcrumbs';
import Reg360SearchBar from '../Reg360SearchBar';
import useChatRIAConfig from '../../../components/ChatRIA/hooks/useChatRIAConfig';

import styles from './styles';
import { decodeBase64ToObject, encodeStringForURL } from '../../../utils/encodeDecodeObject';
import { apiDataToCardProps } from '../../SearchResults/constants';
import { BackArrowIcon, Reg360Icon } from '../../../assets/svgs/Icons';

const Header = () => {
  const { regulatoryState, regulatoryDispatch } = useContext(RegulatoryInformationStore);
  const [displayTradeName, setDisplayTradeName] = useState('');
  const [modalOpen, setModalOpen] = useState('');
  const query = useQuery();
  const { pathname, search: locationSearch } = useLocation();
  const { module, number, source }: any = useParams();
  const history = useHistory();
  const { deleteAllChatRIAConfig } = useChatRIAConfig();

  useEffect(() => {
    const getData = async () => {
      const data = await regulatoryInformationAPI(source, number, 'application_details');
      let result = data?.data?.body;
      try {
        if (apiDataToCardProps[source]) {
          result = apiDataToCardProps[source]?.(data?.data?.body);
        }
      } catch (e) {
        result = data?.data?.body;
      }
      regulatoryDispatch({ type: Actions.SET_APPLICATION_DETAILS, value: result });
      regulatoryDispatch({ type: Actions.SET_DB, value: source });

      if (data.data.body && 'is_favorite' in data.data.body) {
        regulatoryDispatch({
          type: Actions.SET_IS_FAVORITE,
          value: data.data.body.is_favorite
        });
      }
      if (data.data.body && 'is_subscribed' in data.data.body) {
        regulatoryDispatch({
          type: Actions.SET_IS_SUBSCRIBED,
          value: data.data.body.is_subscribed
        });
      }
      if (data.data.body && data.data.body.inProjects) {
        regulatoryDispatch({
          type: Actions.SET_IN_PROJECTS,
          value: data.data.body.inProjects
        });
      }
    };
    getData();

    return () => {
      deleteAllChatRIAConfig();
    };
  }, [source, number]);

  useEffect(() => {
    if (!isEmpty(regulatoryState?.applicationDetails)) {
      const cardStatus = regulatoryState?.applicationDetails?.card_status;
      let displayName = '';
      const colorScheme =
        (regulatoryState?.db === 'us' || regulatoryState?.db === 'ca') &&
        (regulatoryState?.applicationDetails?.application_type === 'NDA' ||
          regulatoryState?.applicationDetails?.application_type === 'CANADA');
      if (colorScheme) {
        if (cardStatus === 'YELLOW') {
          displayName = STATUS[cardStatus][1];
        } else {
          displayName = cardStatus in STATUS ? STATUS[cardStatus][0] : '';
        }
      } else {
        displayName = cardStatus in STATUS ? STATUS[cardStatus][0] : '';
      }
      displayName = `${regulatoryState?.applicationDetails?.product_name} ${displayName}`;
      setDisplayTradeName(displayName);
    }
  }, [regulatoryState.applicationDetails, regulatoryState.db, STATUS]);

  const handleCDPClick = useCallback(() => {
    const type = query.get('type');
    const category = query.get('category');
    const searchId = query.get('searchId');

    const activeIngredients = encodeStringForURL(
      regulatoryState?.applicationDetails?.active_ingredients
    );
    const tradeName = displayTradeName;

    let searchQuery = `category=${category}&searchTerm=${tradeName}&searchId=${searchId}`;
    if (type) {
      searchQuery = `type=${type}&${searchQuery}`;
    }
    const searchParams = getSearchParams(locationSearch || '');
    const backToObject = {
      module,
      path: pathname,
      search: searchParams,
      name: 'Reg 360'
    };
    const backToString = encodeJSONtoString(backToObject);
    searchQuery += `&backTo=${backToString}`;
    history.push(
      `/cdp/${module}/${number}/${source}/${activeIngredients.toLowerCase()}?${searchQuery}`,
      { prevPath: pathname }
    );
  }, [regulatoryState?.applicationDetails, module, source, displayTradeName, query]);

  const getPreviousUrl = () => {
    if (query.get('backTo')) {
      const urlPath: any = decodeBase64ToObject(query.get('backTo') || '');
      history.push(`${urlPath?.path}`);
    } else {
      history.push('/');
    }
  };

  if (isEmpty(regulatoryState?.applicationDetails)) {
    // loaders
    return <Box />;
  }

  return (
    <Box mt={2}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Stack direction='row' pl={1} alignItems='center'>
          <IconButton
            id='reportdownloadIcon'
            onClick={() => {
              getPreviousUrl();
            }}>
            <BackArrowIcon sx={styles.backArrow} />
          </IconButton>
          <Divider orientation='vertical' sx={styles.divider} />
          <Reg360Icon sx={styles.reg360} />
          <Divider orientation='vertical' sx={styles.divider} />
          <ApplicationCard data={regulatoryState?.applicationDetails} />
          {source !== 'ct' && (
            <Link
              onClick={() => {
                setModalOpen('regulatory360');
              }}
              sx={styles.changeButton}>
              Switch Application
            </Link>
          )}
        </Stack>
        <Stack direction='row' spacing={2}>
          {module === 'core' &&
            source === 'us' &&
            (regulatoryState?.applicationDetails?.center === 'CDER' ||
              regulatoryState?.applicationDetails?.center === 'CBER') &&
            regulatoryState?.applicationDetails?.application_type !== 'EUA' && (
              <Badge
                badgeContent='Beta'
                color='primary'
                sx={{ '& .MuiBadge-badge': { right: 12, color: 'white.main' } }}>
                <FeatureButton
                  handleClick={handleCDPClick}
                  iconId='cdp'
                  text='CDP'
                  startIcon={<ChartIcon />}
                />
              </Badge>
            )}
        </Stack>
      </Stack>
      {modalOpen === 'regulatory360' && (
        <Reg360SearchBar modalOpen={modalOpen} setModalOpen={setModalOpen} />
      )}
    </Box>
  );
};

export default Header;
