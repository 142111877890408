import React from 'react';

import Typography from '@mui/material/Typography';
import { COLOR_SCHEME_NUMBER } from '../../const';

// types
import styles from './DatagridColorNumber.styles';

interface GridCellExpandProps {
  value: string;
}

const GridCellExpand: React.FC<GridCellExpandProps> = ({ value }) => {
  return (
    <Typography
      align='center'
      variant='body1'
      sx={styles[COLOR_SCHEME_NUMBER[value?.toLowerCase()]]}>
      {value}
    </Typography>
  );
};

const DatagridColorNumber = (value: string) => {
  return <GridCellExpand value={value} />;
};

export default DatagridColorNumber;
