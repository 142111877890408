// Functional HOC using the useState hook
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// api
import regulatoryInformationAPI from '../../../api/pages/Regulatory360';

// context
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import RegulatoryActions from '../../../store/RegulatoryInformation/actions';

interface ParameterProps {
  source: string;
  number: string;
}

const withFetchingProceduralSteps = (WrappedComponent: React.FunctionComponent) => {
  return () => {
    // context store for aria state management
    const { regulatoryDispatch, regulatoryState } = useContext(RegulatoryInformationStore) as any;

    // get the url parameters
    const { source, number } = useParams<ParameterProps>();

    const center = regulatoryState?.applicationDetails?.center || '';

    useEffect(() => {
      const getData = async () => {
        try {
          const res = await regulatoryInformationAPI(source, number, 'procedural_steps', center);
          if (res.status === 200) {
            regulatoryDispatch({
              type: RegulatoryActions.SET_PROCEDURAL_STEPS,
              value: res.data.body
            });
          }
          regulatoryDispatch({
            type: RegulatoryActions.SET_LOADING_PROCEDURAL_STEPS,
            value: false
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          regulatoryDispatch({
            type: RegulatoryActions.SET_LOADING_PROCEDURAL_STEPS,
            value: false
          });
          console.error(e);
        }
      };

      getData();
    }, []);

    return <WrappedComponent />;
  };
};

export default withFetchingProceduralSteps;
