/* eslint-disable prefer-const */
/* eslint-disable camelcase */
import React, { useContext, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import isString from 'lodash/isString';
import omit from 'lodash/omit';
import capitalize from 'lodash/capitalize';
import pullAll from 'lodash/pullAll';

// muiv5
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// icons
import CloseIcon from '@mui/icons-material/Close';

// context
import { Auth } from 'aws-amplify';
import ResultsStore from '../../../store/ResultsPage';
import ResultsAction from '../../../store/ResultsPage/actions';

// constants
import { DATE_FILTER_CATEGORIES, ELASTIC_SEARCH_ATTRIBUTES_LABELS } from '../utils/constants';

// utils
import { processResultsForARIA } from '../utils/processResultsForARIA';
import { analyticViewMapping } from '../utils/analyticViewMapping';
import { saveAriaCache } from '../../Aria/utils/ariaCache';

// custom hooks
import useQuery from '../../../helpers/customHooks/queryParameter';

// types
import { UrlParameters } from '../../../types/reactRouterDom';

// api
import {
  applyAdvancedSearchFilters,
  applyApprovalDateSearchFilters,
  applySearchFilters
} from '../../../api/pages/ResultsPage';

// styles
import styles from '../styles/Filters.styles';
import getDisplayableValue from '../utils/getDisplayableValue';
import { handleCTGraphFetch, pathNameManipulation } from '../hoc/withFetchingSearchResults';
import useTimelineData from '../../../components/ClinicalTrials/TimelineCT/hooks/useTimelineData';
import { advancedSearchLogicForResults } from '../../../components/Header/utils/advanceSearchHelpers';
import useBusinessIntelligenceAdverseEventsData from '../../../components/ClinicalTrials/hooks/useBusinessIntelligenceAdverseEventsData';

interface SearchStub {
  source: string | any;
  module: string | any;
  searchText: string | any;
  category: string | any;
  dateRange?: any;
}

const SelectedFilterBar = () => {
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const { source, category, search, module, searchType, searchId } = useParams<UrlParameters>();
  const { getTimelineData } = useTimelineData();
  // get the query arguments from url
  const query = useQuery();
  const { pathname } = useLocation();
  const [filterId, setFiterId] = React.useState('');
  const {
    fetchCTBusinessSearchResultsWithFilters,
    fetchCTAdverseEventsResultsWithFilters,
    fetchCTBusinessAdvancedSearchResultsWithFilters,
    fetchCTAdverseEventsAdvancedSearchResultsWithFilters
  } = useBusinessIntelligenceAdverseEventsData();

  // search details from url required for getting default search results
  const searchStub: SearchStub = {
    category,
    source: source?.toLowerCase(),
    searchText: search,
    module
  };

  const [showPopper, setShowPopper] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>, labelName: string) => {
    setAnchorEl(event.currentTarget);
    setShowPopper(true);
    setFiterId(labelName);
  };

  const handleClose = () => {
    setShowPopper(false);
    setFiterId('');
  };

  /**
   * function to apply the selected filters
   * a POST request is made to the backend server with a payload object which contains eastic search attribute as key
   * and each key has a array of selected values that will be part of the elastic search query
   * Example payload: { filters: { appl_num: ['1234','2345'...]}}
   */
  const applyFilters = async (currentFilters: any) => {
    try {
      resultsDispatch({
        type: ResultsAction.SET_ERROR,
        value: false
      });
      const payloadStub: { [key: string]: any } = {
        filters: currentFilters
      };
      let res = null;
      if (searchType === 'advance') {
        // advance search payload required for filters
        const user = await Auth.currentUserInfo();
        payloadStub.advancedSearchFilter = advancedSearchLogicForResults(query, module || '', user);
        res = await applyAdvancedSearchFilters(searchStub, payloadStub);
      } else if (
        ['approval_date', 'first_approval_date', 'sales_date'].includes(category as string)
      ) {
        searchStub.dateRange = {
          startDate: query.get('startDate') || '',
          endDate: query.get('endDate') || ''
        };
        res = await applyApprovalDateSearchFilters(searchStub, payloadStub);
      } else {
        res = await applySearchFilters(searchStub, payloadStub);
      }

      if (searchId && source?.toLowerCase() === 'ct') {
        if (searchType === 'advance') {
          fetchCTAdverseEventsAdvancedSearchResultsWithFilters(searchStub, payloadStub);
          fetchCTBusinessAdvancedSearchResultsWithFilters(searchStub, payloadStub);
        } else {
          fetchCTBusinessSearchResultsWithFilters(searchStub, {});
          fetchCTAdverseEventsResultsWithFilters(searchStub, {});
        }
        const { cnfOfSelectedQuery, dateRange, useSynonyms } = handleCTGraphFetch(
          searchType || '',
          query,
          currentFilters,
          searchStub?.category || '',
          searchStub?.searchText || ''
        );
        getTimelineData(cnfOfSelectedQuery, '', dateRange, false, useSynonyms);
      }

      if (res?.status === 200) {
        let {
          results,
          // business_intelligence,
          search_id = 0,
          isFavorite = false,
          inProjects = []
        } = res.data.body;
        // check if some applications are already hidden
        if (resultsState.hideApplications.length) {
          resultsState.hideApplications?.forEach((app: any) => {
            results = results.filter((item: any) => !isEqual(app, item));
          });
        }
        resultsDispatch({ type: ResultsAction.SET_RESULTS_ID, value: search_id });
        resultsDispatch({ type: ResultsAction.SET_IS_FAVORITE, value: isFavorite });
        resultsDispatch({ type: ResultsAction.SET_IN_PROJECT, value: inProjects });
        resultsDispatch({ type: ResultsAction.SET_RESULTS, value: results });
        // set analytic view data
        const analyticViewData = analyticViewMapping(results);

        resultsDispatch({
          type: ResultsAction.SET_ANALYTIC_VIEW_RESULTS,
          value: analyticViewData
        });
        let newPath = pathNameManipulation(searchId as string, search_id, pathname, query);
        // for aria searches
        saveAriaCache(processResultsForARIA(source, results), newPath, source?.toLowerCase());
        window.history.pushState(null, '', newPath);
        window.history.replaceState(null, '', newPath);
      }
      if (res?.status === 404 || res?.status === 204) {
        resultsDispatch({ type: ResultsAction.SET_RESULTS, value: [] });
        resultsDispatch({
          type: ResultsAction.SET_ANALYTIC_VIEW_RESULTS,
          value: {}
        });
      }
      if (res?.status === 500) {
        resultsDispatch({ type: ResultsAction.SET_RESULTS, value: [] });
        resultsDispatch({
          type: ResultsAction.SET_ANALYTIC_VIEW_RESULTS,
          value: {}
        });
        resultsDispatch({
          type: ResultsAction.SET_ERROR,
          value: 500
        });
      }
      resultsDispatch({ type: ResultsAction.SET_LOADING, value: false });
    } catch (e) {
      resultsDispatch({ type: ResultsAction.SET_LOADING, value: false });
    }
  };

  /**
   * function to remove a filter value from list of selected filters
   * @param e event when the cross button on selected filter tag is clicked
   * @param type key in resultsState.filters from where the value should be removed
   * @param value value in resultsState.filters[key] from where the value should be removed
   */
  const removeFromFilters = (
    e: React.SyntheticEvent,
    type: string,
    value: string,
    clearCatgory?: boolean
  ) => {
    resultsDispatch({ type: ResultsAction.SET_LOADING, value: true });

    let currentFilters = resultsState.filters;
    let currentSynonyms = resultsState.synonymsList;
    if (clearCatgory) {
      currentFilters = omit(currentFilters, [type]);
    } else if (isString(currentFilters[type])) {
      currentFilters = omit(currentFilters, [type]);
    } else if (DATE_FILTER_CATEGORIES.includes(type)) {
      currentFilters = omit(currentFilters, [type]);
    } else {
      const toRemoveFrom = currentFilters[type];
      let newList = toRemoveFrom.filter((x: string) => x !== value);

      // Remove all tree numbers for a selected mesh node
      if (type === 'mesh_tree_numbers') {
        const synonymObj = resultsState.synonymsList?.find((syn: any) =>
          syn.mesh_tree_numbers?.includes(value)
        );
        const itemsToRemove = synonymObj.mesh_tree_numbers || [];
        newList = pullAll(newList, itemsToRemove);
      }

      if (Array.isArray(newList) && !newList.length) {
        delete currentFilters[type];
      } else {
        currentFilters[type] = newList;
      }
    }
    // remove keyword based on synonyms
    currentSynonyms.forEach((ele: any) => {
      if (category === 'atc_classification') {
        if (ele.value === value) {
          currentSynonyms = currentSynonyms.filter(item => item.value !== value);
        }
      } else if (ele.mesh_tree_numbers?.includes(value)) {
        currentSynonyms = currentSynonyms.filter(item => !item.mesh_tree_numbers?.includes(value));
      }
    });
    resultsDispatch({ type: ResultsAction.SET_SYNONYMS_FILTER, value: currentSynonyms });
    resultsDispatch({ type: ResultsAction.SET_FILTERS, value: currentFilters });

    applyFilters(currentFilters);
    handleClose();
  };

  const clearFilters = () => {
    resultsDispatch({ type: ResultsAction.SET_LOADING, value: true });
    resultsDispatch({ type: ResultsAction.SET_FILTERS, value: {} });
    applyFilters({});
  };

  if (!resultsState.loading) {
    return (
      <Stack ml={3} flexDirection='column'>
        {Object.keys(resultsState.filters).length !== 0 && (
          <Stack ml={1} flexDirection='row' alignItems='center'>
            <Typography variant='subtitle2' sx={{ ...styles.heading, cursor: 'auto' }}>
              APPLIED FILTERS
            </Typography>
            <Tooltip title='Clear Filters'>
              <IconButton onClick={clearFilters}>
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        )}

        <Stack direction='row' flexWrap='wrap' sx={{ mt: 1, overflowX: 'auto' }}>
          {Object.entries(resultsState.filters || {}).map(([key, value]: any) => {
            if (isString(value)) {
              return (
                <Box
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) => {
                    handleClick(e, key);
                  }}
                  onMouseLeave={handleClose}
                  key={key}
                  pb={1}
                  flexDirection='row'
                  sx={{ overflowX: 'auto' }}>
                  <Button
                    variant='contained'
                    sx={styles.filterButton}
                    endIcon={
                      showPopper && filterId === key ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                    }>
                    <Typography variant='subtitle1'>
                      {ELASTIC_SEARCH_ATTRIBUTES_LABELS[source?.toLowerCase() as any][key] || key}
                    </Typography>
                  </Button>
                  <Popper
                    open={showPopper && anchorEl !== null}
                    anchorEl={anchorEl}
                    sx={{ flexDirection: 'column' }}
                    placement='bottom-start'>
                    {filterId === key ? (
                      <Paper elevation={1}>
                        <Box display='flex' justifyContent='flex-end' pt={1} mr={1}>
                          <Button
                            onClick={e => {
                              removeFromFilters(e, key, '', true);
                            }}
                            variant='text'
                            fullWidth={false}
                            sx={styles.clearButton}>
                            <Typography align='right' variant='subtitle2' sx={styles.heading}>
                              Clear Filter
                            </Typography>
                          </Button>
                        </Box>
                        <Stack p={2} direction='column' alignItems='stretch'>
                          <Button
                            key={value}
                            variant='contained'
                            sx={styles.chips}
                            startIcon={<CloseIcon sx={{ fontSize: 14 }} />}
                            onClick={e => removeFromFilters(e, key, value)}>
                            <Tooltip title={value}>
                              <Typography noWrap variant='subtitle2'>
                                {value.length > 15
                                  ? `${value.substring(0, 15)}...`
                                  : capitalize(value)}
                              </Typography>
                            </Tooltip>
                          </Button>
                        </Stack>
                      </Paper>
                    ) : null}
                  </Popper>
                </Box>
              );
            }
            return (
              <Box
                onMouseEnter={(e: React.MouseEvent<HTMLElement>) => {
                  handleClick(e, key);
                }}
                onMouseLeave={handleClose}
                key={key}
                pb={1}
                flexDirection='row'
                sx={{ overflowX: 'auto' }}>
                <Button
                  variant='contained'
                  sx={styles.filterButton}
                  endIcon={
                    showPopper && filterId === key ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                  }>
                  <Typography variant='subtitle1'>
                    {ELASTIC_SEARCH_ATTRIBUTES_LABELS[source?.toLowerCase() as any][key] || key}
                  </Typography>
                </Button>
                <Popper
                  open={showPopper && anchorEl !== null}
                  anchorEl={anchorEl}
                  sx={{ flexDirection: 'column' }}
                  placement='bottom-start'>
                  {filterId === key ? (
                    <Paper elevation={1}>
                      <Box display='flex' justifyContent='flex-end' pt={1} mr={1}>
                        <Button
                          onClick={e => {
                            removeFromFilters(e, key, '', true);
                          }}
                          variant='text'
                          fullWidth={false}
                          sx={styles.clearButton}>
                          <Typography align='right' variant='subtitle2' sx={styles.heading}>
                            Clear Filter
                          </Typography>
                        </Button>
                      </Box>
                      <Stack p={2} direction='column' alignItems='stretch'>
                        {/* for range filters */}
                        {DATE_FILTER_CATEGORIES.includes(key) && (
                          <Button
                            key={value}
                            variant='contained'
                            sx={styles.chips}
                            startIcon={<CloseIcon sx={{ fontSize: 14 }} />}
                            onClick={e => removeFromFilters(e, key, value)}>
                            <Tooltip title={value}>
                              <Typography noWrap variant='subtitle2'>
                                From {value[0]} to {value[1] === Infinity ? 'max' : value[1]}
                              </Typography>
                            </Tooltip>
                          </Button>
                        )}

                        {!DATE_FILTER_CATEGORIES.includes(key) &&
                          getDisplayableValue(value, resultsState.synonymsList, key)?.map(
                            (item: string) => {
                              return (
                                <Button
                                  key={item}
                                  variant='contained'
                                  sx={styles.chips}
                                  startIcon={<CloseIcon sx={{ fontSize: 14 }} />}
                                  onClick={e => removeFromFilters(e, key, item)}>
                                  {key === 'mesh_tree_numbers' || key === 'atc_code' ? (
                                    // eslint-disable-next-line array-callback-return, consistent-return
                                    resultsState?.synonymsList &&
                                    resultsState.synonymsList.map((ele: any) => {
                                      if (
                                        ele.mesh_tree_numbers?.includes(item) ||
                                        ele.value === item
                                      ) {
                                        return (
                                          <Tooltip title={ele.label}>
                                            <Typography noWrap variant='subtitle2'>
                                              {ele.label.length > 15
                                                ? `${ele.label.substring(0, 15)}...`
                                                : capitalize(ele.label)}
                                            </Typography>
                                          </Tooltip>
                                        );
                                      }
                                      <Tooltip title={item}>
                                        <Typography noWrap variant='subtitle2'>
                                          {item.length > 15 ? `${item.substring(0, 15)}...` : item}
                                        </Typography>
                                      </Tooltip>;
                                    })
                                  ) : (
                                    <Tooltip title={item}>
                                      <Typography noWrap variant='subtitle2'>
                                        {item.length > 15 ? `${item.substring(0, 15)}...` : item}
                                      </Typography>
                                    </Tooltip>
                                  )}
                                </Button>
                              );
                            }
                          )}
                      </Stack>
                    </Paper>
                  ) : null}
                </Popper>
              </Box>
            );
          })}
        </Stack>
      </Stack>
    );
  }
  return null;
};

export default React.memo(SelectedFilterBar);
