const styles = {
  iconsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '14px',
    '& .MuiIconButton-root': {
      padding: '0 !important'
    }
  }
};

export default styles;
