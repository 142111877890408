const styles = {
  selectedOptionsContainer: {
    marginTop: 1,
    p: 1,
    pr: 2,
    borderRadius: 18,
    alignItems: 'center',
    backgroundColor: 'primary.backgroundDark'
  }
};

export default styles;
