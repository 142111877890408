const styles = {
  close: {
    width: '100%',
    paddingTop: 2,
    paddingRight: 2,
    backgroundColor: 'primary.background',
    cursor: 'pointer'
  },
  container: {
    width: '100%',
    height: '80vh',
    backgroundColor: 'primary.background',
    paddingLeft: 2.5,
    paddingRight: 2.5,
    paddingBottom: 1,
    overflow: 'auto'
  },
  Synonymscontainer: {
    backgroundColor: 'primary.background',
    paddingLeft: 2.5,
    paddingRight: 2.5,
    paddingBottom: 1
  },
  subHeading: {
    fontSize: '14px'
  },
  node: {
    width: 'fit-content',
    padding: '3px 12px',
    display: 'inline-flex',
    borderRadius: '16px',
    margin: 0.5,
    fontSize: '1.5vh',
    fontFamily: 'fontFamily',
    boxShadow: '0px 5px 10px #00000008',
    background: 'white',
    border: '1px solid #707070'
  },
  node_count: {
    width: 'fit-content',
    paddingTop: '3px',
    paddingLeft: '10px',
    paddingRight: '2px',
    paddingBottom: '3px',
    display: 'inline-flex',
    borderRadius: '16px',
    margin: 0.5,
    fontSize: '1.5vh',
    fontFamily: 'fontFamily',
    boxShadow: '0px 5px 10px #00000008',
    background: 'white',
    border: '1px solid #707070'
  },
  count: {
    width: 'fit-content',
    padding: '5px',
    paddingLeft: '15px',
    display: 'inline-flex',
    borderRadius: 1.5,
    margin: 0.5
  }
};

export default styles;
