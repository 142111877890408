import React, { useEffect, useState } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import AnimatedLogo from '../../../assets/svgs/AnimatedLogo';
import themev5 from '../../../themev5';

const NoInternetDialog = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const handleRefresh = () => {
    window.location.reload();
  };

  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(true);
      handleRefresh();
    };
    const handleOfflineStatus = () => setIsOnline(false);

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOfflineStatus);

    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOfflineStatus);
    };
  }, []);

  return (
    <ThemeProvider theme={themev5}>
      <Dialog
        open={!isOnline}
        PaperProps={{ style: { padding: 2, minWidth: '20vw', borderRadius: '40px' } }}>
        <DialogContent>
          <Box textAlign='center' sx={{ '& svg': { filter: 'grayscale(1)' }, mt: -2 }}>
            <AnimatedLogo width='256' height='256' />
          </Box>
          <Box sx={{ mt: -5 }}>
            <DialogContentText variant='h5' align='center' sx={{ mt: 1 }}>
              Oops!
            </DialogContentText>
            <DialogContentText variant='subtitle1' align='center'>
              No internet connection found.
            </DialogContentText>
            <DialogContentText variant='subtitle1' align='center'>
              Check your connection!
            </DialogContentText>
            <Box textAlign='center' sx={{ mt: 1.5 }}>
              <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={handleRefresh}>
                Refresh
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default React.memo(NoInternetDialog);
