import { createSvgIcon } from '@mui/material';

const SnapshotPlayBtn = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
    <g clipPath='url(#clip0_6715_64912)'>
      <path
        d='M9.69181 4.9685L2.93185 0.652832V11.6258L9.68906 7.295C9.88576 7.17136 10.0479 6.99989 10.1604 6.7966C10.2729 6.59331 10.332 6.36484 10.3323 6.13251C10.3326 5.90018 10.274 5.67156 10.162 5.46801C10.05 5.26446 9.88822 5.09261 9.69181 4.9685Z'
        fill='#0096F7'
      />
    </g>
    <defs>
      <clipPath id='clip0_6715_64912'>
        <rect width='11' height='11' fill='white' transform='translate(0.640114 0.630859)' />
      </clipPath>
    </defs>
  </svg>,
  'SnapshotPlayBtn'
);

export default SnapshotPlayBtn;
