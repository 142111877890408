import React, { useContext } from 'react';

// MUI
import Stack from '@mui/material/Stack';

// Custom Components
import VivproDatagrid from '../../../components/Datagrid';

// store
import OrphanDesignationsStore from '../../../store/OrphanDesignations';

// constants
import { CSV_FILENAME, DATA_GRID_COLUMNS } from '../constants';

// styles
import styles from '../../Guidance/styles/Details.styles';
import { getFileName } from '../../../helpers/getFileName';

const Details = () => {
  const { OrphanDesignationsState } = useContext(OrphanDesignationsStore) as any;

  return (
    <Stack sx={styles.root}>
      <VivproDatagrid
        rows={OrphanDesignationsState.projects}
        columnsMapping={DATA_GRID_COLUMNS}
        rowId='designation_number'
        csvFileName={getFileName(CSV_FILENAME)}
        loading={OrphanDesignationsState.isLoading}
      />
    </Stack>
  );
};

export default React.memo(Details);
