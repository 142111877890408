import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Chip } from '@mui/material';
import {
  LABEL_COLOR,
  LABEL_TOOLTIP_TITLE,
  sourceFlagMapping,
  sourceFlagMappingTooltip
} from '../../../pages/SearchResults/constants';

const DocumentMetadata = ({ applicationData }: any) => {
  const metadataChip = [
    {
      label: 'Approval date',
      value: 'approvalDate'
    },
    {
      label: 'Application Number',
      value: 'applNumber'
    },
    {
      label: 'Submission Id',
      value: 'submissionId'
    }
  ];
  const FlagComponent = sourceFlagMapping[applicationData?.sourceValue];
  const flagTooltipTitle = sourceFlagMappingTooltip[applicationData?.sourceValue];
  return (
    <Stack direction='column' width='95%'>
      <Stack direction='row' spacing={1} alignItems='center'>
        <Tooltip
          title={
            applicationData?.groupTitle
              ? `${applicationData?.groupTitle} (${applicationData?.label})`
              : `${applicationData?.brandName}`
          }
          placement='bottom-start'>
          <Typography
            variant='h3'
            sx={{
              color: 'grey.800',
              fontFamily: 'Mulish',
              fontSize: '18px',
              fontWeight: 700,
              textTransform: 'capitalize'
            }}
            noWrap>
            {applicationData?.groupTitle
              ? `${applicationData?.groupTitle} (${applicationData?.label})`
              : `${applicationData?.brandName}`}
          </Typography>
        </Tooltip>
      </Stack>
      <Stack direction='row' display='flex' justifyContent='flex-start' alignItems='center'>
        {metadataChip.map((item: any) => {
          return (
            <Stack direction='row' alignItems='center' flexWrap='wrap' key={item.value}>
              <Tooltip
                title={`${item.label}: ${applicationData[item.value] ?? 'NA'}`}
                placement='bottom-start'>
                <Typography
                  sx={{
                    color: 'gray.750',
                    fontFamily: 'Mulish',
                    fontSize: '12px',
                    fontWeight: 400
                  }}
                  noWrap>
                  {applicationData[item.value] ?? 'NA'}
                </Typography>
              </Tooltip>
              <Typography
                sx={{
                  color: 'gray.550',
                  fontFamily: 'Mulish',
                  fontSize: '12px',
                  fontWeight: 400,
                  m: '5px'
                }}
                noWrap>
                •
              </Typography>
            </Stack>
          );
        })}
        <Tooltip title={flagTooltipTitle}>
          {FlagComponent && <FlagComponent sx={{ width: '18px', height: '18px' }} />}
        </Tooltip>
        {applicationData?.labels &&
          applicationData?.labels.map((label: string) => {
            const labelKey = label.toLowerCase();
            const labelColor = LABEL_COLOR[labelKey];
            const labelTooltipTitle = LABEL_TOOLTIP_TITLE[labelKey];
            return labelColor ? (
              <Tooltip title={labelTooltipTitle}>
                <Chip
                  label={label}
                  key={label}
                  sx={{
                    ml: 1,
                    fontSize: 10,
                    height: 13,
                    borderRadius: 1,
                    fontWeight: 800,
                    color: 'white.main',
                    '& span': {
                      px: 0.5
                    },
                    bgcolor: labelColor
                  }}
                />
              </Tooltip>
            ) : null;
          })}
      </Stack>
    </Stack>
  );
};

export default DocumentMetadata;
