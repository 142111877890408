interface Stat {
  _id: number;
  description: string;
  source: string;
  updated_at: string;
  value: number;
}

/**
 * This function replaces the "###" in heading string with the stats value.
 * @param statsData - Array of stats.
 * @param id - id of stats.
 * @param heading - Heading string
 * @returns formmated srting.
 */
const downloadHeading = (statsData: Stat[], id: number, heading: string) => {
  if (!heading.includes('###')) {
    return heading;
  }
  const stats = statsData.filter(item => item._id == id);
  if (!stats) {
    return heading;
  }
  const value = stats[0].value;
  return heading.replace('###', value.toString());
};

export default downloadHeading;
