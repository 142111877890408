import { createSvgIcon } from '@mui/material';

const OpenCdpIcon = createSvgIcon(
  <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_4468_10832)'>
      <path d='M2.07979 9.26123H0.583008V14.5H2.07979V9.26123Z' fill='currentColor' />
      <path d='M5.82199 7.7644H4.3252V14.4999H5.82199V7.7644Z' fill='currentColor' />
      <path d='M9.56418 10.0098H8.06738V14.5001H9.56418V10.0098Z' fill='currentColor' />
      <path d='M13.3064 7.01611H11.8096V14.5001H13.3064V7.01611Z' fill='currentColor' />
      <path
        d='M4.71223 3.90091L8.1374 7.32608L13.417 2.04652L12.4483 1.07788L8.1374 5.3888L4.71223 1.96363L0.802734 5.87312L1.77137 6.84176L4.71223 3.90091Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_4468_10832'>
        <rect width='14' height='14' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>,
  'CDP'
);

export default OpenCdpIcon;
