import React, { useEffect, useContext } from 'react';

// mui v5
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import themev5 from '../../themev5';

// hoc
import HTATabs from './hoc/Tabs';

// store
import Store from '../../store';
import Actions from '../../store/actions';

// Provider
import { HTAStateProvider } from '../../store/HTADocuments';

const HTADocuments = () => {
  const { dispatch } = useContext(Store);

  useEffect(() => {
    dispatch({ type: Actions.SET_MODULE, value: 'HTA' });
  }, []);

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <HTAStateProvider>
        <HTATabs />
      </HTAStateProvider>
    </ThemeProvider>
  );
};

export default HTADocuments;
