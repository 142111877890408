import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ResponsiveRadar } from '@nivo/radar';
import React from 'react';

interface RadarGraphProps {
  data: Array<{ [key: string]: any }>;
  indexKey: string;
  keys: Array<string>;
  title: { title?: string; show?: boolean; fontSize?: number };
}

const RadarGraph = ({ data, indexKey, keys, title = {} }: RadarGraphProps) => {
  return (
    <Box
      position='relative'
      width='100%'
      height='100%'
      p={2}
      sx={{ 'page-break-inside': 'avoid', display: 'inline-block' } as any}>
      {title.show && (
        <Box position='relative' width='100%' top='0px' textAlign='center'>
          <Typography fontSize={`${title.fontSize || 20}px`} textAlign='center'>
            {title.title}
          </Typography>
        </Box>
      )}
      <ResponsiveRadar
        data={data}
        keys={keys}
        indexBy={indexKey}
        maxValue='auto'
        margin={{ top: 100, right: 100, bottom: 100, left: 100 }}
        curve='cardinalClosed'
        borderWidth={2}
        borderColor={{ from: 'color' }}
        gridLevels={5}
        gridShape='linear'
        gridLabelOffset={36}
        enableDots={false}
        dotSize={10}
        dotColor={{ theme: 'background' }}
        dotBorderWidth={2}
        dotBorderColor={{ from: 'color' }}
        enableDotLabel
        dotLabel='value'
        // @ts-ignore
        fontSize={20}
        dotLabelYOffset={-12}
        colors={['#48E0A6', '#6868FA', '#12815E']}
        fillOpacity={1}
        blendMode='multiply'
        animate
        motionStiffness={90}
        motionDamping={15}
        isInteractive
        theme={{
          axis: {
            ticks: {
              text: {
                fontSize: 16
              }
            }
          },
          legends: {
            text: {
              fontSize: 16
            }
          }
        }}
        axisTop={{
          fontSize: 30
        }}
        axisBottom={{
          fontSize: 30
        }}
        legends={[
          {
            anchor: 'top',
            direction: 'row',
            translateX: -50,
            translateY: -90,
            itemWidth: 200,
            itemHeight: 20,
            itemTextColor: '#000000',
            symbolSize: 15,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  symbolSize: 25,
                  itemTextColor: '#000'
                }
              }
            ]
          }
        ]}
      />
    </Box>
  );
};

export default React.memo(RadarGraph);
