const acronymnMap = {
  document_type: {
    warning_letter: 'Warning Letter',
    closeout_letter: 'Closeout Letter'
  }
};

const getTopValuesForPieChart = (values, key, count = 5) => {
  let sortedData = values.sort((a, b) => b.value - a.value);
  const otherValues = sortedData.slice(count);
  const otherValue = otherValues.reduce(
    (prev, current) => ({ ...prev, value: prev.value + current.value }),
    {
      id: 'Other',
      label: 'Other',
      value: 0
    }
  );
  sortedData.length = count;
  sortedData.push(otherValue);

  sortedData = sortedData.map(data => ({
    value: data.value,
    id: acronymnMap[key]?.[data.id] || data.id
  }));
  return sortedData;
};

export default getTopValuesForPieChart;
