const getStringForSorting = str => {
  str = str || '';
  str = str.trim();
  str = str.toLowerCase();

  return str.replace(/[^a-zA-Z0-9 ]/g, '');
};

const sortTextComparator = (v1, v2) => {
  v1 = getStringForSorting(v1);
  v2 = getStringForSorting(v2);
  return v1.localeCompare(v2);
};

export default sortTextComparator;
