const shortenMonthYear = (dateString: string): string => {
  try {
    const [month, year] = dateString.split(' ');
    const shortMonth = month.slice(0, 3);

    return `${shortMonth} ${year}`;
  } catch (err) {
    return dateString;
  }
};

export default shortenMonthYear;
