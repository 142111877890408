// Returns a YYYY-MM-DD format of a date.
export function formatDate(date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
}

// Returns unique values of array.
export function unique(arr) {
  const myObject = {};
  const myArray = [];
  for (let i = 0, l = arr.length; i < l; i += 1) {
    if (!Object.prototype.hasOwnProperty.call(myObject, arr[i])) {
      myArray.push(arr[i]);
      myObject[arr[i]] = 1;
    }
  }
  return myArray;
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function arraySum(array) {
  function add(accumulator, a) {
    return accumulator + a;
  }

  const sum = array.reduce(add, 0);

  return sum;
}

export function getLastWeek() {
  const today = new Date();
  const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
  return lastWeek;
}

function segregate(arr, size) {
  let j = 0,
    i;
  for (i = 0; i < size; i = i + 1) {
    if (arr[i] <= 0) {
      const temp = arr[i];
      arr[i] = arr[j];
      arr[j] = temp;
      // increment count of non-positive
      // integers
      j = j + 1;
    }
  }

  return j;
}

/* Find the smallest positive missing
        number in an array that contains
        all positive integers */
function findMissingPositive(arr, size) {
  let i;

  // Mark arr[i] as visited by making
  // arr[arr[i] - 1] negative. Note that
  // 1 is subtracted because index start
  // from 0 and positive numbers start from 1
  for (i = 0; i < size; i = i + 1) {
    let x = Math.abs(arr[i]);
    if (x - 1 < size && arr[x - 1] > 0) arr[x - 1] = -arr[x - 1];
  }

  // Return the first index value at which
  // is positive
  for (i = 0; i < size; i = i + 1) if (arr[i] > 0) return i + 1; // 1 is added because indexes
  // start from 0

  return size + 1;
}

/* Find the smallest positive missing
        number in an array that contains
        both positive and negative integers */
export function findMissing(arr, size) {
  // First separate positive and
  // negative numbers
  let shift = segregate(arr, size);
  let arr2 = new Array(size - shift);
  let j = 0;
  for (let i = shift; i < size; i = i + 1) {
    arr2[j] = arr[i];
    j = j + 1;
  }

  // Shift the array and call
  // findMissingPositive for
  // positive part
  return findMissingPositive(arr2, j);
}

/**
 * Removes all the undefined elements from a list.
 */
export const removeUndefined = dataList => {
  let newDataList = [];
  dataList.forEach(element => {
    if (element !== undefined) {
      newDataList.push(element);
    }
  });
  return newDataList;
};
