import TimelineColumnInterface from "../../../interfaces/TimelineColumnInterface";
import TimelineDate from "../../../components/Timeline/TimelineDate/TimelineDate";
import TimelineArray from "../../../components/Timeline/TimelineArray/TimelineArray";
import TimelineMore from "../../../components/Timeline/TimelineMore/TimelineMore";
import TimelineDocuments from "../../../components/Timeline/TimelineDocuments/TimelineDocuments";
import TimelineApprovalTime from "../../../components/Timeline/TimelineApprovalTime/TimelineApprovalTime";
// import TimelineAvatars from '../../../components/Timeline/TimelineAvatars/TimelineAvatars';

export const COLUMNS_EU: TimelineColumnInterface[] = [
  {
    width: 1.3,
    header: 'Date',
    data: 'approval_date',
    name: 'approval_date',
    rendercomponent: (
      <TimelineDate
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 4,
    header: 'Submission Details',
    data: 'submission_type',
    name: 'submission_type',
    rendercomponent: (
      <TimelineArray
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    ),
    concatColumns: ['submission_type', 'description']
  },
  {
    width: 1,
    header: 'CHMP to EC',
    data: 'delta',
    name: 'delta',
    showPopover: true,
    rendercomponent: (
      <TimelineApprovalTime
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    ),
    popOverColumn: {
      chmp_date: 'CHMP Date',
      indications: 'Indications'
    }
  },
  {
    width: 3.7,
    header: 'Action Details',
    data: 'change',
    name: 'change',
    rendercomponent: (
      <TimelineMore
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 1,
    header: 'Resources',
    data: 'file_links',
    name: 'file_links',
    rendercomponent: (
      <TimelineDocuments
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  }
  // {
  //   width: '400px',
  //   header: 'Indications',
  //   data: 'indications_text',
  //   name: 'indications_text',
  //   rendercomponent: (
  //     <TimelineMore column={undefined} dataItem={undefined} triggeredBy='placeholder' />
  //   )
  // }
];

export const COMMISSION_PROCEDURES = 'commission_procedures';
export default COLUMNS_EU;
