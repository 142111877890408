import { GridColDef } from '@mui/x-data-grid-pro';
import { Filter } from '../../components/Filters';
import DatagridTooltip from '../../components/Datagrid/DatagridTooltip';
import DatagridPDFCell from './components/DatagridPDFCell/DatagridPDFCell';
import DatagridDate from '../../components/Datagrid/DatagridDate';
import { PIECHARTCOLOR } from '../../components/CustomComponents/Graphs/constant';
import { FILTER_TYPE } from '../../components/NewFilters/constants';

export const SEARCH_IGNORE_FIELDS = ['doc_hash', 'field_associated_media_2', 'guidance_pk'];

export interface LettersFilters extends Filter {
  apiRequestKey: string;
  apiResponseKey?: string;
}

export const LETTERS_FILTERS = [
  {
    id: 1,
    label: 'Enter Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Company Name',
    value: 'company_name',
    elasticSearchType: 'company_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Source Page',
    value: 'source_page',
    elasticSearchType: 'source_page',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Document Type',
    value: 'document_type',
    elasticSearchType: 'document_type',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Subject',
    value: 'subject',
    elasticSearchType: 'subject',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Issuing Office',
    value: 'issuing_office',
    elasticSearchType: 'issuing_office',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 7,
    label: 'Issue Date',
    value: 'issue_date',
    elasticSearchType: 'issue_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    id: 8,
    label: 'Posted Date',
    value: 'posted_date',
    elasticSearchType: 'posted_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    id: 9,
    label: 'Closeout Date',
    value: 'closeout_date',
    elasticSearchType: 'closeout_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    id: 10,
    label: 'Response Date',
    value: 'response_date',
    elasticSearchType: 'response_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  }
];

export const LETTERS_PIE_CHART = [
  {
    label: 'Document Type',
    apiKey: 'letter_document_type',
    graphItem: 'letter_document_type',
    count: 2
  },
  {
    label: 'FDA Issuing Office',
    apiKey: 'issuing_office',
    graphItem: 'fda_issuing_office',
    count: 3
  }
];

export const LETTERS_COLUMNS: GridColDef[] = [
  {
    field: 'document_count',
    headerName: 'Documents',
    headerClassName: 'table-header',
    width: 100,
    cellClassName: 'table-row',
    sortable: false,
    renderCell: DatagridPDFCell
  },
  {
    field: 'posted_date',
    headerName: 'Posted Date',
    headerClassName: 'table-header',
    width: 150,
    align: 'center',
    headerAlign: 'center',
    cellClassName: 'table-row',
    renderCell: DatagridDate
  },
  {
    field: 'issue_date',
    headerName: 'Issue Date',
    headerClassName: 'table-header',
    width: 150,
    align: 'center',
    headerAlign: 'center',
    cellClassName: 'table-row',
    renderCell: DatagridDate
  },
  {
    field: 'company_name',
    headerName: 'Company Name',
    headerClassName: 'table-header',
    width: 250,
    headerAlign: 'center',
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'issuing_office',
    headerName: 'Issuing Office',
    headerClassName: 'table-header',
    width: 300,
    headerAlign: 'center',
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'subject',
    headerName: 'Subject',
    headerClassName: 'table-header',
    width: 300,
    headerAlign: 'center',
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'source_page',
    headerName: 'Source Page',
    headerClassName: 'table-header',
    width: 130,
    align: 'center',
    headerAlign: 'center',
    cellClassName: 'table-row',
    valueGetter: params => {
      const words = params.row.source_page.split('_');
      const capitalizedWords = words.map(
        (word: string) => word.charAt(0).toUpperCase() + word.slice(1)
      );
      return capitalizedWords.join(' ');
    },
    renderCell: DatagridTooltip
  },
  {
    field: 'closeout_date',
    headerName: 'Closeout Date',
    headerClassName: 'table-header',
    width: 150,
    align: 'center',
    headerAlign: 'center',
    cellClassName: 'table-row',
    renderCell: DatagridDate
  },
  {
    field: 'response_date',
    headerName: 'Response Date',
    headerClassName: 'table-header',
    width: 150,
    align: 'center',
    headerAlign: 'center',
    cellClassName: 'table-row',
    renderCell: DatagridDate
  }
];

export const LETTERS_COLUMN_STYLES = {
  title: 'capitalize',
  alt_source_names: 'capitalize',
  std_status: 'capitalize'
};

export const MAX_FREE_DOWNLOADABLE_DATA = 50;

const day = new Date();
const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
export const CSV_FILENAME = {
  prefix: 'ria',
  module: 'Letters',
  postfix: date,
  additional: '',
  extension: ''
};

export const barChartStyles = {
  colors: ['#B1BBE4'],
  padding: 0.8,
  labelTextColor: '#ffffff',
  axisText: '#A3A3A3'
};

export const pieChartStyles = {
  colors: PIECHARTCOLOR,
  pieLabel: true,
  pieLegend: false,
  innerRadius: 0.7,
  margin: { top: 20, right: 20, bottom: 20, left: 20 },
  enableLabels: 'both'
};

export const LETTERS_REPORT_QUERIES = [
  {
    id: 'letters_by_year',
    title: {
      title: 'Letters by Year',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['issue_date'], agg_function: 'count', sort_on_axis: 'x' },
    graphStyle: {
      ...barChartStyles,
      axisLeft: {
        tickRotation: 0
      }
    }
  },
  {
    id: 'letter_document_type',
    title: {
      title: 'Document Type',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['all_documents_type'],
      agg_function: 'count'
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'issuing_office',
    title: {
      title: 'FDA Issuing Office',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['issuing_office'],
      agg_function: 'count'
    },
    graphStyle: pieChartStyles
  }
];

export const PAGINATION_DEFAULT_OPTIONS = {
  limit: 20,
  offset: 0
};

export const EXPORT_TYPE = {
  csv: 'csv',
  xlsx: 'xlsx'
};

export const LETTERS_COLUMN_NAME_MAPPING = {
  posted_date: 'Posted Date',
  issue_date: 'Issue Date',
  company_name: 'Company Name',
  issuing_office: 'Issuing Office',
  source_page: 'Source Page',
  subject: 'Subject',
  closeout_date: 'Closeout Date',
  response_date: 'Response Date'
};
