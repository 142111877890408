import { createSvgIcon } from '@mui/material';

const CheckIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'>
    <path
      d='M12.6667 0.166687H3.33334C1.49331 0.168906 0.00221875 1.66 0 3.50003V12.8334C0.00221875 14.6734 1.49331 16.1645 3.33334 16.1667H12.6667C14.5067 16.1645 15.9978 14.6734 16 12.8333V3.50003C15.9978 1.66 14.5067 0.168906 12.6667 0.166687ZM13.3333 5.55269L7.164 11.722C6.64341 12.2428 5.79919 12.243 5.27838 11.7224V11.7224L2.66666 9.11138C2.40578 8.8505 2.40578 8.42756 2.66666 8.16672C2.92753 7.90588 3.35047 7.90584 3.61131 8.16672L6.22197 10.7774L12.392 4.60803C12.6528 4.34809 13.075 4.34884 13.335 4.60969C13.5949 4.87056 13.5942 5.29275 13.3333 5.55269Z'
      fill='#109E8A'
    />
  </svg>,
  'CheckIcon'
);

export default CheckIcon;
