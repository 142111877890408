/* eslint-disable camelcase */
import React, { useContext, useEffect, useState, useRef } from 'react';

// muiv5
import Stack from '@mui/material/Stack';

import VivproDatagrid from '../../Datagrid';

import useBusinessIntelligenceAdverseEventsData from '../../ClinicalTrials/TimelineCT/hooks/useBusinessIntelligenceAdverseEventsData';
import ResultsStore from '../../../store/SearchResults';
import {
  DATAGRID_COLUMN_MAPPING,
  DATAGRID_UNIQUE_FIELD
} from '../../../pages/ResultsPage/utils/constants';
import { getFileName } from '../../../helpers/getFileName';

const EUCTAdverseEventsListView = () => {
  const { resultsState } = useContext(ResultsStore);
  const { fetchEUCTAdverseEventsResultsFromId } = useBusinessIntelligenceAdverseEventsData();
  const [adverseEventsData, setAdverseEventsData] = useState([]);
  const day = new Date();
  const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
  const CSV_FILENAME = {
    prefix: 'ria',
    module: 'EUCT Adverse Effect',
    postfix: date,
    additional: '',
    extension: ''
  };

  const isSearchIdChangedRef = useRef(true);

  useEffect(() => {
    isSearchIdChangedRef.current = true;
  }, [resultsState?.searchIds?.ctSearchId]);

  useEffect(() => {
    if (isSearchIdChangedRef.current) {
      fetchEUCTAdverseEventsResultsFromId(resultsState?.searchIds?.ctSearchId);
      isSearchIdChangedRef.current = false;
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(resultsState?.ctAdverseEvent)) {
      const newData = resultsState?.ctAdverseEvent.map((result_row, row_index) => {
        const { euct_id, adverse_events = [], overall_status_formated = '' } = result_row;
        if (!adverse_events) return [];
        const advEventTransformedData = adverse_events.map((adv_event: any, adv_index: number) => {
          const {
            adverse_event_term = '',
            organ_system = '',
            risk_ratio = 0,
            subjects_affected = 0,
            subjects_at_risk = 0,
            result_group_code = '',
            result_group_title = '',
            result_group_description = ''
          } = adv_event;
          let { event_type = '' } = adv_event;
          event_type = event_type ? event_type.charAt(0).toUpperCase() + event_type.slice(1) : '';
          return {
            euct_id,
            adverse_event_term,
            event_type,
            organ_system,
            risk_ratio,
            subjects_affected,
            subjects_at_risk,
            result_group_code,
            result_group_title,
            result_group_description,
            [DATAGRID_UNIQUE_FIELD.ct_adverse_events]: `${row_index}_${adv_index}_${risk_ratio}`,
            overall_status_formated
          };
        });
        return advEventTransformedData;
      });
      const result = newData.reduce((accumulator, value) => accumulator.concat(value), []);
      setAdverseEventsData(result);
    }
  }, [resultsState?.ctAdverseEvent]);

  return (
    <Stack id='ListData' sx={{ height: '65vh', width: '100%' }}>
      <VivproDatagrid
        rows={adverseEventsData}
        columnsMapping={DATAGRID_COLUMN_MAPPING.eu_ct_adverse_event}
        rowId={DATAGRID_UNIQUE_FIELD.ct_adverse_events}
        csvFileName={getFileName(CSV_FILENAME)}
        loading={resultsState?.loading || resultsState?.ctAdverseEventLoading}
        useQuickSearch
      />
    </Stack>
  );
};

export default React.memo(EUCTAdverseEventsListView);
