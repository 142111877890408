import { makeStyles } from '@material-ui/styles';
import lightLogin from '../../../Images/Group705.svg';

export default makeStyles(theme => ({
  root: {},
  titleBox: {
    background: theme.palette.bot.buttonBackground,
    width: '15%',
    height: '100px'
  },
  contentDialog: {
    padding: theme.spacing(0)
  },
  dialog: {
    height: 'inherit'
  },
  dialogWithImage: {
    height: 'inherit',
    backgroundImage: `url(${lightLogin})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  title: {
    padding: '24px 24px'
  },
  labelTitle: {
    fontSize: '1.4vh',
    fontWeight: 500,
    paddingLeft: '5px',
    margin: 'auto'
  },
  selectLabel: {
    background: theme.palette.bot.buttonBackground,
    color: theme.palette.white,
    padding: '13px 10px',
    borderRadius: '15px',
    marginBottom: 'auto',
    fontSize: '0.8rem',
    marginTop: '5%'
  },
  LabelComparisionDate: {
    fontSize: '0.8rem',
    fontWeight: 600,
    padding: '5px'
  },
  selectLabelC: {
    background: theme.palette.bot.buttonBackground,
    color: theme.palette.white,
    borderRadius: '10px 10px 0px 0px',
    marginBottom: 'auto',
    fontSize: theme.typography.title,
    padding: '13px 10px'
  },
  compareN: {
    height: '30px',
    padding: '3px 20px',
    fontSize: '1.7vh',
    fontWeight: 'bold',
    border: '2px solid #e3f1ff',
    boxShadow: '0 0 4px rgb(220, 220, 220,0.5)',
    borderRadius: '20px',
    backgroundColor: theme.palette.buttons.compareButtonColor,
    color: '#FFFFFF',
    cursor: 'pointer',
    margin: '10px auto',
    display: 'flex',
    width: 'fit-content',
    textTransform: 'capitalize',
    '&:hover': {
      background: theme.palette.buttons.compareButtonColor
    }
  },
  noPadding: {
    padding: '0px 0px 0px 0px'
  },
  content: {
    height: 'inherit'
  },
  textfield: {
    padding: `${theme.spacing(1)}px !important`,
    border: '1px solid black !important',
    borderRadius: theme.spacing(2)
  },
  reportSubmitButton: {
    backgroundColor: '#289D78',
    color: '#FFFFFF',
    borderRadius: '20px',
    '&:hover': {
      backgroundColor: theme.palette.userProfile.activeTab,
      color: 'white'
    }
  }
}));
