import React, { useCallback, useContext, useMemo, useEffect, useState } from 'react';
import { debounce } from 'lodash';
// MUI
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';

import styles from '../../Guidance/styles/Details.styles';
// Custom Components
import VivproDatagrid from '../../../components/Datagrid';
import PDFPreview from './PDFViewer';
import useSearchedTableData from '../../../hooks/useSearchedTableData';
// store
import store from '../../../store/EMAGuidance';
import actions from '../../../store/EMAGuidance/actions';
import Search from './Search';

import PDFCellComponent from './PDFCellComponent';
import { getFileName } from '../../../helpers/getFileName';

// constants
import EMAGUIDANCE_COLUMN, {
  CSV_FILENAME,
  SEARCH_IGNORE_FIELDS,
  PAGINATION_DEFAULT_OPTIONS
} from '../const';
import getTableColumnWithCellRenderers from '../../../utils/getTableColumnWithCellRenders';
import { getEMAGuidanceDocs } from '../../../api/pages/EMAGuidance';
import getDatagridFilterOption from '../../../utils/getDatagridFilterOption';
import { DATAGRID_OPTION_LIST } from '../../../components/Datagrid/constants';

const EMAGuidanceDetails = ({
  pagination,
  setPagination,
  sort,
  setSort,
  sortFields,
  getEMAGuidanceData,
  isFilterUpdated,
  getDatawithDataGridFilter,
  dataGridFilter,
  setIsFilterUpdated
}: any) => {
  const [datagridMessage] = useState('Loading...');
  const [pdfUrl, setPdfUrl] = useState('');
  const { EMAGuidanceState, EMAGuidanceDispatch } = useContext(store) as any;

  const { searchedData } = useSearchedTableData({
    tableData: EMAGuidanceState.tableData,
    search: EMAGuidanceState.search,
    ignoreFields: SEARCH_IGNORE_FIELDS,
    limit: PAGINATION_DEFAULT_OPTIONS.limit,
    offset: PAGINATION_DEFAULT_OPTIONS.offset,
    searchUrl: getEMAGuidanceDocs,
    module: 'ema_guidance_documents'
  });

  useEffect(() => {
    if (isFilterUpdated) {
      setIsFilterUpdated(false);
    }
  }, [isFilterUpdated]);

  const fetchData = useCallback(
    (pageModel: any, sortModel: any, gridFilters: any) => {
      getEMAGuidanceData(
        EMAGuidanceState.availableFilters,
        pageModel.pageSize,
        pageModel.page * pageModel.pageSize,
        sortModel,
        false,
        gridFilters
      );
    },
    [EMAGuidanceState.availableFilters]
  );

  const handlePageChange = useCallback(
    (pageModel: any) => {
      if (!isFilterUpdated) {
        fetchData(pageModel, sortFields, dataGridFilter);
        setPagination(pageModel);
      }
    },
    [sortFields, EMAGuidanceState.availableFilters, isFilterUpdated, dataGridFilter]
  );

  const handleSortModelChange = useCallback(
    (newSortingModel: any) => {
      const modifiedList: any = [];

      newSortingModel.forEach((item: any) => {
        modifiedList.push(item);
      });

      if (JSON.stringify(modifiedList) !== JSON.stringify(EMAGuidanceState.sortFields)) {
        fetchData(pagination, modifiedList, dataGridFilter);
      }
      EMAGuidanceDispatch({
        type: actions.SET_SORT,
        value: modifiedList
      });

      setSort(modifiedList);
    },
    [pagination, sortFields, EMAGuidanceState.filters, EMAGuidanceState, actions, dataGridFilter]
  );

  const handlePDFOpenClick = useCallback((pdfLink: any) => {
    setPdfUrl(pdfLink);
  }, []);

  const pdfCellRenderer = useCallback(
    ({ value }: any) => <PDFCellComponent value={value} onPDFButtonClick={handlePDFOpenClick} />,
    [handlePDFOpenClick]
  );

  const tableColumns = useMemo(
    () =>
      getTableColumnWithCellRenderers(EMAGUIDANCE_COLUMN, [
        { field: 's3_path', cellRenderer: pdfCellRenderer }
      ]),
    [pdfCellRenderer]
  );

  const handleComparisonCheckbox = useCallback(
    (id: string) => {
      if (EMAGuidanceState?.documentsToCompare?.find((doc: any) => doc?.key === id)) {
        EMAGuidanceDispatch({
          type: actions.REMOVE_DOCUMENT_TO_COMPARE,
          value: id
        });
      } else {
        EMAGuidanceDispatch({
          type: actions.SET_DOCUMENT_TO_COMPARE,
          value: EMAGuidanceState?.tableData?.find((doc: any) => doc?.key === id)
        });
      }
    },
    [EMAGuidanceState?.tableData, EMAGuidanceState?.documentsToCompare]
  );

  const renderableTableColumns = useMemo(() => {
    const renderableColumns = [...tableColumns];
    if (EMAGuidanceState.compareEnabled) {
      renderableColumns.unshift({
        field: '_id',
        headerName: 'Select',
        width: 100,
        headerClassName: 'table-header',
        cellClassName: 'ria-MuiDataGrid-cell',
        disableExport: true,
        renderCell: ({ value }: any) => (
          <Tooltip
            title={
              EMAGuidanceState.documentsToCompare.length === 2 &&
              !EMAGuidanceState.documentsToCompare.find((doc: any) => doc.key === value)
                ? 'Selection limit reached'
                : ''
            }>
            <Box key={value}>
              <Radio
                disabled={
                  EMAGuidanceState.documentsToCompare.length === 2 &&
                  !EMAGuidanceState.documentsToCompare.find((doc: any) => doc.key === value)
                }
                checked={
                  !!EMAGuidanceState.documentsToCompare.find((doc: any) => doc?.key === value)
                }
                onClick={() => handleComparisonCheckbox(value)}
              />
            </Box>
          </Tooltip>
        )
      });
    }

    return getDatagridFilterOption(renderableColumns, DATAGRID_OPTION_LIST, EMAGuidanceState);
  }, [
    EMAGuidanceState.documentsToCompare,
    tableColumns,
    EMAGuidanceState.compareEnabled,
    EMAGuidanceState.tableData,
    EMAGuidanceState.tableData,
    EMAGuidanceState.dataGridFilters
  ]);

  const handleFilterChange = useCallback(
    debounce(newFilterModel => {
      if (newFilterModel?.items?.length > 0) {
        getDatawithDataGridFilter(
          EMAGuidanceState.availableFilters,
          newFilterModel,
          sortFields,
          pagination.pageSize,
          pagination.page * pagination.pageSize
        );
      } else {
        fetchData(pagination, sortFields, {});
      }
    }, 300),
    [EMAGuidanceState.availableFilters, sortFields]
  );

  useEffect(() => {
    return () => {
      handleFilterChange.cancel();
    };
  }, [handleFilterChange]);

  return (
    <Stack sx={EMAGuidanceState.compareEnabled ? styles.rootWithComparisonEnabled : styles.root}>
      {EMAGuidanceState.compareEnabled && (
        <Box paddingTop={1} paddingBottom={1} display='flex' alignItems='center'>
          <Search />
        </Box>
      )}
      <Box>
        <PDFPreview open={!!pdfUrl} pdfUrl={pdfUrl} onClose={() => setPdfUrl('')} />
      </Box>
      <VivproDatagrid
        rows={searchedData?.length > 0 ? searchedData : EMAGuidanceState.tableData}
        columnsMapping={renderableTableColumns}
        rowId='_id'
        csvFileName={getFileName(CSV_FILENAME)}
        noRowMessage={datagridMessage}
        loading={EMAGuidanceState.loading}
        rowCount={EMAGuidanceState.totalRecord}
        isPagination
        pagination={pagination}
        setPagination={handlePageChange}
        sortingModel={sort}
        handleSortModelChange={handleSortModelChange}
        isServerSideExport
        isFilterModeServer
        allowFilterPanelWithLogicOperators
        handleFilterModelChange={handleFilterChange}
      />
    </Stack>
  );
};

export default React.memo(EMAGuidanceDetails);
