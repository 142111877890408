import React from 'react';

const FiltersIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <g id='Group_1004' data-name='Group 1004' transform='translate(-1687.095 -200.015)'>
        <rect
          id='Rectangle_477'
          data-name='Rectangle 477'
          width='24'
          height='24'
          transform='translate(1687.095 200.015)'
          fill='none'
        />
        <path
          id='filter_1_'
          data-name='filter (1)'
          d='M15.072,0H1.185A1.187,1.187,0,0,0,0,1.185V2.754a1.193,1.193,0,0,0,.384.875L5.821,8.609a.843.843,0,0,1,.275.624v6.517a.508.508,0,0,0,.79.423l2.747-1.831a1.183,1.183,0,0,0,.528-.986V9.233a.846.846,0,0,1,.275-.625l5.437-4.982a1.187,1.187,0,0,0,.384-.873V1.185A1.187,1.187,0,0,0,15.072,0Z'
          transform='translate(1691.095 203.887)'
          fill='#646464'
        />
      </g>
    </svg>
  );
};

export default FiltersIcon;
