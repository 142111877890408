import { flatten } from 'lodash';
import { getGridStringOperators, GridFilterOperator } from '@mui/x-data-grid-pro';

const filterFunction = (filterItem: any) => {
  if (
    !filterItem.field ||
    !filterItem.value ||
    filterItem?.value?.length === 0 ||
    !filterItem.operator
  ) {
    return null;
  }

  return ({ row }: any) => {
    const classStrings = flatten(row.atc_classification || []).map(
      (cls: any) => `${cls.code}: ${cls.classification}`
    );
    let foundIndex = -1;
    filterItem.value.forEach((element: string) => {
      const index = classStrings.findIndex((str: string) =>
        str.toLowerCase().includes(element.toLowerCase())
      );
      if (index > foundIndex) foundIndex = index;
    });
    return foundIndex > -1;
  };
};

const getATCFilterOperator = () => {
  return getGridStringOperators()
    .filter((fltr: any) => {
      return fltr.value === 'isAnyOf';
    })
    .map(
      op =>
        ({
          ...op,
          getApplyFilterFn: filterFunction
        } as GridFilterOperator)
    );
};

export default getATCFilterOperator;
