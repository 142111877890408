import { createSvgIcon } from '@mui/material/utils';

const EqualsToSelectedIcon = createSvgIcon(
  <svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='8' cy='8' r='8' fill='currentColor' />
    <path d='M12 5.33337H4V6.6667H12V5.33337Z' fill='white' />
    <path d='M12 9.33362H4V10.667H12V9.33362Z' fill='white' />
  </svg>,
  'EqualsToSelected'
);
export default EqualsToSelectedIcon;
