/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  List,
  ListItemButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import { InfoOutlined } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
  chatBubble,
  chatBubbleBot,
  reportQuestionDeleteButtons,
  botAnswer,
  suggestionList,
  markdownStyle
} from '../styles/styles';
import GlobalStore from '../../../store';
import customTitleCase from '../../../utils/titleCase';
import { getAriaResultTitle } from '../../../pages/Aria/components/AriaResult';
import { ariaCardResultsMapping } from '../../../pages/Aria/utils/constants';
import AriaResponseSelected from '../../../assets/svgs/Icons/AriaResponseSelected';
import VerifyIcon from '../../../assets/svgs/Icons/VerifyIcon';
import PlusIcon from '../../../assets/svgs/Icons/PlusIcon';
import SuggestionIcon from '../../../assets/svgs/ChatRIA/SuggestionIcon';
import SendIcon from '../../../Images/svgs/sendIcon';
import CheckIcon from '../../../assets/svgs/Icons/CheckIcon';
import VerifyChatRiaIcon from '../../../assets/svgs/ChatRIA/VerifyChatRiaIcon';

interface Props {
  question: string;
  markdown: string;
  type: string;
  pageNumber?: number;
  url?: string;
  // eslint-disable-next-line react/require-default-props
  originalUrl?: string;
  handleAddToReportAction?: any;
  handleVerifyAction?: any;
  verifyAnswer?: any;
  riaMode?: boolean;
  action?: any;
  handleSetHelpQuery?: any;
  queryLoading?: boolean;
  isError?: boolean;
  isCanceled?: boolean;
  currentApplication?: string;
  currentSection?: string;
  generatedForSection?: string;
  generatedForApplication?: string;
  disableChatActions?: boolean;
  generatedForBrand?: string;
  generatedForSource?: string;
  generatedForCenter?: string;
  chatBubbleId?: string;
  isARIAMode?: boolean;
  sectionStepper?: any;
  bubbleIndex?: number;
  inReport?: boolean;
  ariaPageMetaData?: any;
  currentAriaState?: any;
  documentReferencePages?: any;
  sectionNavigation?: any;
  setOpenApplicationOption?: any;
  addedToReportList?: any;
  reportLoading?: boolean;
}

export const markdownToText = (markdown: string) => {
  const convertedHtml = marked(markdown);
  const sanitizedHtml = DOMPurify.sanitize(convertedHtml, { USE_PROFILES: { html: true } });
  const dom = new DOMParser().parseFromString(sanitizedHtml, 'text/html');
  return dom?.body?.textContent || '';
};
export const ReadOnlyMarkdown = ({ markdown }: { markdown: string }) => {
  const [html, setHtml] = useState('');

  useEffect(() => {
    const initialize = async () => {
      // Use the marked library to convert the markdown to HTML
      const convertedHtml = marked(markdown);
      // Use the DOMPurify library to sanitize the HTML and prevent XSS attacks
      const sanitizedHtml = DOMPurify.sanitize(convertedHtml, { USE_PROFILES: { html: true } });
      // Set the sanitized HTML as the component's state
      setHtml(sanitizedHtml);
    };
    if (markdown) {
      initialize();
    }
  }, [markdown]);

  return (
    <Typography
      component={Box}
      paragraph={false}
      variant='subtitle1'
      sx={chatBubble}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

function parseTextFromHTML(mdString: any) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(mdString, 'text/html');
  const walker = document.createTreeWalker(doc, NodeFilter.SHOW_TEXT);

  const textList = [];
  let { currentNode } = walker;

  while (currentNode) {
    textList.push(currentNode?.textContent?.trim().replace(/(\r\n|\n|\r)/gm, ''));
    // @ts-ignore
    currentNode = walker.nextNode();
  }

  return textList;
}

const ClickableQuestions = ({ markdown, handleSetHelpQuery, queryLoading }: any) => {
  const convertedHtml = marked(markdown);
  const sanitizedHtml = DOMPurify.sanitize(convertedHtml, {
    USE_PROFILES: { html: true }
  });
  const questions = parseTextFromHTML(sanitizedHtml);
  let index: number = 0;
  return (
    <Stack ml={2}>
      <Stack direction='row' alignItems='center' spacing={1}>
        <SuggestionIcon sx={markdownStyle.suggestionIcon} />
        <Typography variant='subtitle2' sx={markdownStyle.suggestionText}>
          Suggestion:
        </Typography>
      </Stack>
      <List sx={{ ...chatBubbleBot, p: '16px 0px' }}>
        {questions.map((question: any) => {
          if (question) {
            index += 1;
            return (
              <Tooltip
                key={`question-${btoa(encodeURIComponent(question))}`}
                title={
                  queryLoading
                    ? 'Kindly wait till the previous question is being answered.'
                    : 'Click to ask this question'
                }>
                <span>
                  <ListItemButton
                    sx={suggestionList}
                    onCopy={e => {
                      e.preventDefault();
                    }}
                    onClick={e => {
                      e.stopPropagation();
                      handleSetHelpQuery(question);
                    }}
                    disabled={queryLoading}>
                    <Stack display='flex' flexDirection='row' alignItems='flex-start' width='90%'>
                      <Typography fontSize='15px'>{index}:&nbsp;</Typography>
                      <Typography
                        width='90%'
                        fontSize='15px'
                        key={`question-${btoa(encodeURIComponent(question))}`}>
                        {question}
                      </Typography>
                    </Stack>
                    <Button
                      aria-label='submit query'
                      type='submit'
                      sx={{ minWidth: '20px', width: '10%' }}>
                      <SendIcon sx={{ fontSize: '24px', ml: '16px' }} />
                    </Button>
                  </ListItemButton>
                </span>
              </Tooltip>
            );
          }
          return null;
        })}
      </List>
    </Stack>
  );
};
const MarkdownRenderer = ({
  question,
  markdown,
  type,
  pageNumber,
  url,
  originalUrl,
  handleVerifyAction,
  handleAddToReportAction,
  verifyAnswer,
  riaMode,
  action,
  handleSetHelpQuery,
  queryLoading,
  isError = false,
  isCanceled = false,
  currentSection,
  currentApplication,
  generatedForSection,
  generatedForApplication,
  generatedForBrand,
  generatedForSource,
  generatedForCenter,
  disableChatActions = false,
  chatBubbleId = '',
  isARIAMode,
  sectionStepper,
  bubbleIndex,
  inReport,
  ariaPageMetaData,
  currentAriaState,
  documentReferencePages,
  sectionNavigation,
  setOpenApplicationOption,
  addedToReportList,
  reportLoading
}: Props) => {
  const { state, dispatch } = useContext(GlobalStore) as any;

  const [html, setHtml] = useState('');
  const [confirmDocumentChange, setConfirmDocumentChange] = useState(false);
  const [citation, setCitations] = useState<any>({});
  const [verifyHover, setVerifyHover] = useState<any>(false);

  // Function to replace keys with buttons
  const replaceKeysWithButtons = (text: any) => {
    const pageNumberArr = [];
    for (const key in documentReferencePages[chatBubbleId]) {
      const buttonNumber = documentReferencePages[chatBubbleId][key];
      const button = `<span  id="${buttonNumber}" title="Scroll to this page" style="border-radius:50px;font-size:14px;padding-left:6px;padding-right:6px;border:1px solid #3ec886;color:#3ec886;cursor:pointer;">P${buttonNumber}</span>`;

      if (text.includes(key)) {
        text = text.replaceAll(`${key}`, button);
        pageNumberArr.push(buttonNumber);
      }
    }
    const currentCitation = citation;
    currentCitation[chatBubbleId] = pageNumberArr;
    setCitations(currentCitation);

    return text;
  };

  useEffect(() => {
    const initialize = async () => {
      // Use the marked library to convert the markdown to HTML
      const convertedHtml = marked(markdown);
      // Use the DOMPurify library to sanitize the HTML and prevent XSS attacks
      const sanitizedHtml = DOMPurify.sanitize(replaceKeysWithButtons(convertedHtml), {
        USE_PROFILES: { html: true }
      });
      // Set the sanitized HTML as the component's state

      setHtml(sanitizedHtml);
    };
    initialize();
  }, [markdown, documentReferencePages[chatBubbleId]]);

  const handleAriaDocumentClick = async (referencePage: any) => {
    if (state?.ariaDocument?.url !== url) {
      setConfirmDocumentChange(true);
    } else {
      dispatch({
        type: 'SET_SCROLL_TO_ARIA_PAGE',
        value: referencePage || ariaPageMetaData?.currentPage
      });
    }
  };

  const handleHtmlClick = (e: any) => {
    if (e.target.id) {
      handleAriaDocumentClick(e.target.id); // Pass the buttonNumber to handleButtonClick
    }
  };

  const isVerifyOpen =
    verifyAnswer?.url !== '' &&
    verifyAnswer?.url === url &&
    verifyAnswer?.pageNumber === pageNumber;

  const getIconComponent = () => {
    if (reportLoading) {
      return <CircularProgress color='success' size={14} />;
    }
    if (addedToReportList && addedToReportList.includes(chatBubbleId)) {
      return <CheckIcon sx={markdownStyle.IconStyle} />;
    }
    return <PlusIcon sx={markdownStyle.IconStyle} />;
  };

  if (action && action === 'help') {
    return (
      <ClickableQuestions
        isARIAMode={isARIAMode}
        markdown={markdown}
        handleSetHelpQuery={handleSetHelpQuery}
        queryLoading={queryLoading}
        currentSection={currentSection}
        currentApplication={currentApplication}
        generatedForSection={generatedForSection}
        generatedForApplication={generatedForApplication}
      />
    );
  }

  return (
    <Stack sx={botAnswer}>
      <Stack direction='row' alignItems='center' spacing={1}>
        <AriaResponseSelected fontSize='small' sx={{ width: '28px', height: '28px' }} />
        <Stack direction='column' alignItems='flex-start' justifyContent='space-between'>
          <Typography variant='subtitle2' sx={markdownStyle.chatHeaderText}>
            RIA Response
          </Typography>
          {sectionStepper}
        </Stack>
      </Stack>

      {type !== 'user' && (
        <Typography
          onClick={handleHtmlClick}
          component={Box}
          paragraph={false}
          variant='subtitle1'
          sx={chatBubbleBot}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      )}

      {url && !isError && !disableChatActions && (
        <Stack direction='row' justifyContent='space-between' alignItems='center' width='95%'>
          {isARIAMode ? (
            <Stack alignItems='flex-start' direction='row' gap='14px' mt='8px'>
              <Tooltip
                title={`${customTitleCase(
                  getAriaResultTitle(
                    currentAriaState?.item[ariaCardResultsMapping[currentAriaState?.source]?.title],
                    currentAriaState?.item,
                    currentAriaState?.source
                  )
                )} ${
                  currentAriaState?.item?.trade_name
                    ? `(${currentAriaState?.item?.trade_name ?? ''})`
                    : ''
                }`}>
                <InfoOutlined sx={{ color: 'grey.500', fontSize: 12 }} />
              </Tooltip>
              <Tooltip
                title={
                  // eslint-disable-next-line no-nested-ternary
                  reportLoading
                    ? 'Loading...'
                    : addedToReportList && addedToReportList.includes(chatBubbleId)
                    ? 'Remove from Report'
                    : 'Add to Report'
                }>
                <Stack
                  direction='row'
                  onClick={() => {
                    let appNumber = generatedForApplication;
                    if (generatedForSource?.toLowerCase() === 'eu') {
                      appNumber =
                        generatedForCenter === 'ema-who'
                          ? `EMEA-H-W-${generatedForApplication}`
                          : `EMEA-H-C-${generatedForApplication}`;
                    }
                    handleAddToReportAction(
                      html,
                      question,
                      generatedForSection,
                      generatedForBrand,
                      appNumber,
                      generatedForSource,
                      url,
                      !inReport ? 'add' : 'remove',
                      chatBubbleId,
                      bubbleIndex,
                      originalUrl
                    );
                  }}
                  spacing={0.5}
                  alignItems='center'
                  sx={markdownStyle.Container}>
                  {getIconComponent()}
                  <Typography noWrap variant='body1' sx={markdownStyle.Text}>
                    Report
                  </Typography>
                </Stack>
              </Tooltip>
            </Stack>
          ) : (
            <Stack alignItems='flex-start' direction='row' gap='14px' mt='8px'>
              <Tooltip title={`${isVerifyOpen ? 'Close document' : 'Open document to verify'} `}>
                <Stack
                  direction='row'
                  onClick={() => {
                    handleVerifyAction(url, pageNumber || 0);
                    setOpenApplicationOption(false);
                  }}
                  spacing={0.5}
                  alignItems='center'
                  onMouseEnter={() => setVerifyHover(true)}
                  onMouseLeave={() => setVerifyHover(false)}
                  sx={markdownStyle.Container}>
                  {isVerifyOpen || verifyHover ? (
                    <VerifyChatRiaIcon sx={{ ...markdownStyle.IconStyle, color: 'primary.900' }} />
                  ) : (
                    <VerifyIcon sx={{ ...markdownStyle.IconStyle, color: 'primary.700' }} />
                  )}

                  <Typography
                    noWrap
                    variant='body1'
                    sx={{
                      ...markdownStyle.Text,
                      color: isVerifyOpen || verifyHover ? 'primary.900' : 'primary.700',
                      fontWeight: isVerifyOpen || verifyHover ? 700 : 600
                    }}>
                    Verify
                  </Typography>
                </Stack>
              </Tooltip>
              <Tooltip
                title={
                  // eslint-disable-next-line no-nested-ternary
                  reportLoading
                    ? 'Loading...'
                    : addedToReportList && addedToReportList.includes(chatBubbleId)
                    ? 'Remove from Report'
                    : 'Add to Report'
                }>
                <Stack
                  direction='row'
                  onClick={() => {
                    let appNumber = generatedForApplication;
                    if (generatedForSource?.toLowerCase() === 'eu') {
                      appNumber =
                        generatedForCenter === 'ema-who'
                          ? `EMEA-H-W-${generatedForApplication}`
                          : `EMEA-H-C-${generatedForApplication}`;
                    }
                    handleAddToReportAction(
                      html,
                      question,
                      generatedForSection,
                      generatedForBrand,
                      appNumber,
                      generatedForSource,
                      url,
                      !inReport ? 'add' : 'remove',
                      chatBubbleId,
                      bubbleIndex,
                      originalUrl
                    );
                  }}
                  spacing={0.5}
                  alignItems='center'
                  sx={markdownStyle.Container}>
                  {getIconComponent()}
                  <Typography noWrap variant='body1' sx={markdownStyle.Text}>
                    Report
                  </Typography>
                </Stack>
              </Tooltip>
            </Stack>
          )}
          {!riaMode && (
            <Tooltip
              title={
                "I tried my best to provide you with a detailed response to your inquiry, and it's possible that I consulted resources outside of the RIA platform in doing so. As a matter of precaution, it is wise to double-check the response."
              }>
              <InfoOutlined sx={{ color: 'grey.500', fontSize: 15 }} />
            </Tooltip>
          )}
        </Stack>
      )}
      {sectionNavigation}
      {isCanceled && (
        <Stack direction='row' justifyContent='center' alignItems='center' width='95%'>
          <Typography
            variant='subtitle1'
            sx={{
              backgroundColor: 'red.main',
              color: 'white.main',
              width: '100%',
              textAlign: 'center',
              borderRadius: 2,
              fontWeight: 'bold'
            }}>
            Request canceled
          </Typography>
        </Stack>
      )}
      <Dialog open={confirmDocumentChange}>
        <DialogContent>
          <Typography variant='subtitle1' fontWeight='bold' component={Box}>
            You are about to change the context to a different document. Do you want to proceed?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Stack direction='row' mt={2}>
            <Button
              onClick={async () => {
                await dispatch({
                  type: 'SET_ARIA_DOCUMENT',
                  value: { ...currentAriaState, triggerReopenChatRia: true }
                });
                setConfirmDocumentChange(false);
                setTimeout(() => {
                  dispatch({
                    type: 'SET_SCROLL_TO_ARIA_PAGE',
                    value: currentAriaState?.currentPage
                  });
                }, 2000);
              }}
              sx={reportQuestionDeleteButtons}
              size='small'>
              Yes
            </Button>
            <Button
              onClick={() => {
                setConfirmDocumentChange(false);
              }}
              sx={reportQuestionDeleteButtons}
              size='small'>
              No
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};
MarkdownRenderer.defaultProps = {
  pageNumber: 0,
  url: '',
  handleVerifyAction: () => {},
  handleAddToReportAction: () => {},
  verifyAnswer: {},
  riaMode: false,
  action: '',
  handleSetHelpQuery: () => {},
  queryLoading: false,
  isError: false,
  isCanceled: false,
  currentSection: '',
  currentApplication: '',
  generatedForSection: '',
  generatedForApplication: '',
  generatedForBrand: '',
  generatedForSource: '',
  generatedForCenter: '',
  disableChatActions: false,
  chatBubbleId: '',
  isARIAMode: false,
  sectionStepper: null,
  bubbleIndex: 0,
  inReport: false,
  ariaPageMetaData: {},
  currentAriaState: {},
  documentReferencePages: {},
  sectionNavigation: null,
  setOpenApplicationOption: null,
  addedToReportList: [],
  reportLoading: false
};
export default React.memo(MarkdownRenderer);
