import React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';

const TimelineLoader = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          padding: '16px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '12px',
          alignSelf: 'stretch',
          background: 'var(--color-grey-100, #F5F5F5)'
        }}>
        <Grid
          container
          sx={{
            borderBottom: '1px solid #E0E0E0',
            paddingBottom: '12px'
          }}>
          <Grid item xs={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Skeleton variant='text' width='80%' height={20} />
              <Skeleton variant='text' width='60%' height={25} />
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Skeleton variant='text' width='80%' height={20} />
              <Skeleton variant='text' width='60%' height={25} />
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Skeleton variant='text' width='80%' height={20} />
              <Skeleton variant='text' width='60%' height={25} />
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Skeleton variant='text' width='80%' height={20} />
              <Skeleton variant='text' width='60%' height={25} />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={1} sx={{ paddingBottom: '12px' }}>
          <Grid item xs={4}>
            <Skeleton variant='text' width='100%' height={20} />
          </Grid>
          <Grid item xs={4}>
            <Skeleton variant='text' width='100%' height={20} />
          </Grid>
          <Grid item xs={4}>
            <Skeleton variant='text' width='100%' height={20} />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          padding: '16px'
        }}>
        <Skeleton variant='text' width='95%' height={125} />
        <Skeleton variant='text' width='95%' height={125} />
        <Skeleton variant='text' width='95%' height={125} />
        <Skeleton variant='text' width='95%' height={125} />
      </Box>
    </>
  );
};

export default TimelineLoader;
