import { PIECHARTCOLOR } from '../../components/CustomComponents/Graphs/constant';
import { Filter } from '../../components/Filters';
import { FILTER_TYPE } from '../../components/NewFilters/constants';

export interface PediatricsEMAFilters extends Filter {
  apiRequestKey: string;
  apiResponseKey?: string;
}

export const PEDIATRICS_EMA_FILTERS = [
  {
    id: 1,
    label: 'Active Ingredients',
    value: 'active_ingredient',
    elasticSearchType: 'active_ingredient',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Trade Name',
    value: 'trade_name',
    elasticSearchType: 'trade_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Formulations',
    value: 'formulations',
    elasticSearchType: 'formulations',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Search Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Routes',
    value: 'routes',
    elasticSearchType: 'routes',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Therapeutic Area',
    value: 'therapeutic_area',
    elasticSearchType: 'therapeutic_area',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 7,
    label: 'Decision Date',
    value: 'decision_date',
    elasticSearchType: 'decision_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    id: 8,
    label: 'First Published Date',
    value: 'first_published',
    elasticSearchType: 'first_published',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    id: 9,
    label: 'Revision Date',
    value: 'revision_date',
    elasticSearchType: 'revision_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  }
];

export const barChartStyles = {
  colors: ['#B1BBE4'],
  padding: 0.8,
  labelTextColor: '#ffffff',
  axisText: '#A3A3A3'
};

export const pieChartStyles = {
  colors: PIECHARTCOLOR,
  pieLabel: true,
  pieLegend: true,
  innerRadius: 0.7,
  margin: { top: 20, right: 20, bottom: 20, left: 20 },
  enableLabels: 'both'
};

export const PIPS_REPORT_QUERIES = [
  {
    id: 'drugs_by_determination_year',
    title: {
      title: 'Drugs by Determination Year',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['decision_date'], agg_function: 'count', sort_on_axis: 'x' },
    graphStyle: {
      ...barChartStyles,
      axisLeft: {
        tickRotation: 0
      }
    }
  },
  {
    id: 'formulations',
    title: {
      title: 'Formulations',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['formulations'],
      agg_function: 'count',
      limit: 4
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'route',
    title: {
      title: 'Routes',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['routes'],
      agg_function: 'count',
      limit: 4
    },
    graphStyle: pieChartStyles
  }
];

export const SUBMODULE = 'pips';

export const EMA_PIPS_COLUMN_NAME_MAPPING = {
  decision_number: 'Decision Number',
  decision_type: 'Decision Type',
  first_published: 'First Published',
  decision_date: 'Decision Date',
  revision_date: 'Revision Date',
  active_ingredient: 'Active Ingredient',
  formulations: 'Formulations',
  indications_and_usage: 'Indication',
  routes: 'routes',
  therapeutic_area: 'Therapeutic Area',
  trade_name: 'Trade Name'
};
