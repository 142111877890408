// This function formats the number to readable string if the no of digits in the number
// are greater than arguement noOfDigits else return an empty string . Specailly for hover effect.
// num: 4316 noOfDigits: 6 returns ""
// num: 43162390 noOfDigits: 6 returns "43,162,390"
const formatCount = (num: number = 0, noOfDigits = 0) => {
  const strNum = num?.toString().split('').reverse() ?? '';
  if (strNum.length < noOfDigits) {
    return '';
  }
  const formattedNum: string[] = [];
  strNum.forEach((ele, index) =>
    formattedNum.push(index % 3 === 0 && index !== 0 ? `${ele},` : ele)
  );
  return formattedNum.reverse().join('');
};

export default formatCount;
