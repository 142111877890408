const getCtSource = (source: any) => {
  let isEuCtSelected = false;
  let isUsCtSelected = false;
  if (source?.eu?.includes('euctr')) {
    isEuCtSelected = true;
  }

  if (source?.ct?.includes('usnlm')) {
    isUsCtSelected = true;
  }
  // eslint-disable-next-line no-nested-ternary
  return isEuCtSelected ? 'euctr' : isUsCtSelected ? 'usnlm' : '';
};

export default getCtSource;
