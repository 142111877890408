import { useMemo } from 'react';
import { isArray } from 'lodash';

import { Icon, IconButton, Tooltip } from '@mui/material';

// custom icons
import { EnglishToJapaneseIcon, JapaneseToEnglishIcon } from '../../assets/svgs/Icons';

// styles
import styles from './styles/LanguageToggleButton.styles';

interface LanguageToggleButtonProps {
  source: string | string[] | undefined;
  originalTextEnabled: boolean;
  // eslint-disable-next-line no-unused-vars
  toggleTextLanugage: (value: boolean) => void;
  color?: 'white' | 'green';
}

const sourcesWithOriginalTextEnabled = ['jp', 'pmda'];

const LanguageToggleButton = ({
  source,
  originalTextEnabled,
  toggleTextLanugage,
  color
}: LanguageToggleButtonProps) => {
  // condition to check if langugage toggle should be shown
  const viewOriginalTextEnabled = useMemo(() => {
    if (isArray(source))
      return source?.every(item => sourcesWithOriginalTextEnabled.includes(item?.toLowerCase()));
    return sourcesWithOriginalTextEnabled.includes(source?.toLowerCase() ?? '');
  }, [source]);

  const translateButtonStyle =
    color === 'white' ? styles.translateButtonWhite : styles.translateButtonGreen;
  const translateButtonEnabledStyle =
    color === 'white' ? styles.translateButtonWhiteEnabled : styles.translateButtonGreenEnabled;

  const translateIconStyle =
    color === 'white' ? styles.translateIconWhite : styles.translateIconGreen;
  const translateIconEnabledStyle =
    color === 'white' ? styles.translateIconWhiteEnabled : styles.translateIconGreenEnabled;

  if (!viewOriginalTextEnabled) return null;
  return (
    <Tooltip title={originalTextEnabled ? 'Revert to English' : 'View Original Text'}>
      <IconButton
        size='small'
        onClick={() => toggleTextLanugage(!originalTextEnabled)}
        id='translate'
        sx={originalTextEnabled ? translateButtonEnabledStyle : translateButtonStyle}>
        <Icon
          sx={{
            color: originalTextEnabled ? translateIconEnabledStyle : translateIconStyle
          }}>
          {originalTextEnabled ? <JapaneseToEnglishIcon /> : <EnglishToJapaneseIcon />}
        </Icon>
      </IconButton>
    </Tooltip>
  );
};

LanguageToggleButton.defaultProps = {
  color: 'green'
};

export default LanguageToggleButton;
