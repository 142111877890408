import actions from './actions';
import { AdcommContextInterface } from '.';

interface ActionProps {
  type: string;
  value: any;
}

const reducers = (state: AdcommContextInterface, action: ActionProps) => {
  switch (action.type) {
    case actions.SET_SELECTED_MEETING_ID: {
      return {
        ...state,
        meeting_id: action.value
      };
    }
    case actions.SET_SELECTED_MEETING_DOCUMENT_COUNT: {
      return {
        ...state,
        selected_meeting_doc_count: action.value
      };
    }
    case actions.SET_SELECTED_MEETING_DOCUMENT_PAGE_COUNT: {
      return {
        ...state,
        selected_meeting_page_count: action.value
      };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
