export type fileNomenclature = {
  prefix: string;
  module: string;
  postfix: string;
  additional: string;
  extension: string;
};

export const getFileName = (filedata: fileNomenclature) => {
  if (!filedata.module || !filedata.postfix) {
    return `undefined `;
  }
  // substring is used because windows does not allow big file names
  return `${`${filedata.prefix && filedata.prefix !== 'undefined' ? `${filedata.prefix}_` : ''}${
    filedata.module
  }`.substring(0, 150)}_${filedata.postfix}${filedata.additional}`;
};
