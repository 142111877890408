import makeRequest from '../client';

/**
 * Function to get all statistics
 */
export const getStatsAll = () => makeRequest(`/clignosis/adcomm`, 'GET');

/**
 * Function to get committee stats
 * @param {object} filters
 * @param {string} tab
 */
export const getStats = (filters: any, tab: string) =>
  makeRequest(`/adcomm/stats?&tab=${tab}`, 'POST', filters);

/**
 * Function to get Summary details
 * @param {string} meetingId
 */
export const getSummary = (meetingId: string) =>
  makeRequest(`/adcomm/meeting/${meetingId}/summary`, 'GET');

/**
 * Function to get Roster Table data
 * @param {string} meetingId
 */
export const getRosterTable = (meetingId: string) =>
  makeRequest(`/adcomm/${meetingId}/roster`, 'GET');

/**
 * Function to get details of Roster Person
 * @param {string} memberId
 * @param {string} meetingId
 */
export const getRosterPerson = (meetingId: string, memberId: string) =>
  makeRequest(`/adcomm/${meetingId}/roster/${memberId}`, 'GET');

/**
 * Function to get details of Resources
 * @param {string} meetingId
 */
export const getResources = (meetingId: string) =>
  makeRequest(`/adcomm/${meetingId}/resources`, 'GET');

/**
 * Function to get meeting list data
 * @param {object} filters
 * @param {number} limit
 * @param {number} offset
 * @param {string} tab
 */
export const getMeetingsList = (filters: any, limit: number, offset: number, tab: string) =>
  makeRequest(`/adcomm/meetings?limit=${limit}&offset=${offset}&tab=${tab}`, 'POST', filters);

/**
 * Function to get meeting list data
 * @param {object} filters
 * @param {number} limit
 * @param {string} meetingId
 * @param {string} tab
 */
export const getMeetingsListByUnknownOffset = (
  filters: any,
  limit: number,
  meetingId: string,
  tab: string
) =>
  makeRequest(
    `/adcomm/meetings?limit=${limit}&meeting_id=${meetingId}&tab=${tab}`,
    'POST',
    filters
  );
