/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { Typography, Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import styles from '../Datagrid.styles';

interface GridCellExpandProps {
  value: any;
  width: number;
}

const isOverflown = (element: Element): boolean => {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
};
export const valueMapper = (value: any, isExport: boolean) => {
  const finalArray: any = [];
  if (value) {
    value.forEach((item: any) => {
      if (item) {
        // @ts-ignore
        const sortedVal = item.sort((a, b) => {
          return a.level - b.level;
        });
        const localArray: string[] = [];
        // @ts-ignore
        sortedVal.forEach(({ code, classification }) => {
          localArray.push(`${code.toUpperCase()}: ${classification.toUpperCase()}`);
        });
        if (localArray.length > 0) {
          finalArray.push(localArray);
        }
      }
    });
  }
  if (isExport) {
    let finalString = '';
    finalArray.forEach((item: any[]) => {
      finalString = `${finalString + item.join(' \n')}\n`;
    });
    return finalString;
  }
  return finalArray;
};

const GridCellExpand = React.memo((props: GridCellExpandProps) => {
  const { width, value } = props;
  const wrapper = React.useRef<HTMLDivElement | null>(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);
  const [classifications, setClassifications] = React.useState([]);
  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current!);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    const handleKeyDown = (nativeEvent: KeyboardEvent) => {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);
  useEffect(() => {
    if (value) {
      const finalArray = valueMapper(value, false);
      if (finalArray.length > 0) {
        setClassifications(finalArray);
      }
    }
  }, [value]);
  if (classifications.length > 0) {
    return (
      <Box
        ref={wrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          alignItems: 'center',
          lineHeight: '24px',
          width: 1,
          height: 1,
          position: 'relative',
          display: 'flex'
        }}>
        <Box
          ref={cellDiv}
          sx={{
            height: 1,
            width,
            display: 'block',
            position: 'absolute',
            top: 0
          }}
        />
        <Box
          ref={cellValue}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            ...styles.datagridCellText
          }}
          className='cellValue'>
          {classifications?.map(item => {
            // @ts-ignore
            return item?.join(', ');
          })}
        </Box>
        {showPopper && (
          <Popper open={showFullCell && anchorEl !== null} anchorEl={anchorEl} placement='bottom'>
            <Paper
              elevation={1}
              sx={{
                maxHeight: 300,
                overflowY: 'auto',
                minHeight: wrapper.current!.offsetHeight - 3,
                borderRadius: '8px',
                p: 1,
                width: 400,
                ml: -10
              }}>
              {classifications?.map((item, index) => (
                <Stack
                  spacing={1}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  mt={index % 2 === 0 ? '1em' : 0}>
                  {
                    // @ts-ignore
                    item?.map((code: string) => (
                      <Stack
                        key={code}
                        sx={{
                          width: '100%',
                          textAlign: 'left',
                          backgroundColor: 'primary.backgroundDark',
                          borderRadius: '16px',
                          padding: 2,
                          textTransform: 'capitalize'
                        }}>
                        <Typography variant='subtitle2'>{code}</Typography>
                      </Stack>
                    ))
                  }
                </Stack>
              ))}
            </Paper>
          </Popper>
        )}
      </Box>
    );
  }
  return <Box />;
});

const DatagridChipsPopup = (params: GridRenderCellParams) => {
  return <GridCellExpand value={params.value || []} width={params.colDef.computedWidth} />;
};

export default DatagridChipsPopup;
