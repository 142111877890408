import { createSvgIcon } from '@mui/material';

const MinusIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='3' viewBox='0 0 20 3' fill='none'>
    <path
      d='M19.1667 0.666504H0.83332C0.373086 0.666504 0 1.03959 0 1.49982C0 1.96005 0.373086 2.33313 0.83332 2.33313H19.1666C19.6269 2.33313 20 1.96005 20 1.49982C20 1.03959 19.6269 0.666504 19.1667 0.666504Z'
      fill='#B00020'
    />
  </svg>,
  'PlusIcon'
);

export default MinusIcon;
