import { useCallback, useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { cloneDeep, difference, isEqual } from 'lodash';

import { decodeBase64ToObject } from '../../utils/encodeDecodeObject';
import {
  getAdvancedSearchSynonyms,
  getGenericSynonyms,
  getMatchSynonymsData
} from '../../api/pages/ResultsPage';
import getSynonymsTreeCount from '../SearchList/utils/getSynonymsTreeCount';

import ResultsStore from '../../store/SearchResults';
import ResultsAction from '../../store/SearchResults/actions';
import getATCTreeCount from './utils/getATCTreeCount';
import { setIsSelected as selectIsATCSelected } from './useATCClassification';

const setIsSelected = (node: any, selectedTrees: any) => {
  // eslint-disable-next-line no-param-reassign
  node.isSelected = node.mesh_tree_numbers?.every((i: any) => selectedTrees.includes(i));
  if (node.children?.length) {
    node.children?.forEach((n: any) => setIsSelected(n, selectedTrees));
  }
  return node;
};

const useSynonyms = () => {
  const location = useLocation();
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isATCIncluded, setATCIncluded] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const [synonymsTree, setSynonymsTree] = useState<any>();
  const [selectedTreeNumbers, setSelectedTreeNumber] = useState<any>([
    ...(resultsState.filters?.mesh_tree_numbers || [])
  ]);

  const decodedPayload: any = decodeBase64ToObject(location.pathname.split('/')[2]);
  const {
    search_term: searchTerm,
    source: sources,
    search_type: searchType,
    approval_date: approvalDate,
    use_synonyms: shouldUseSynonyms, // eslint-disable-next-line no-unused-vars
    approvalPathway,
    category,
    entity_text: entityText,
    ct_search_id: ctSearchId
  } = decodedPayload;

  const getMeshSyonyms = async () => {
    // get synonyms list and source to determine whether it is euct or us ct
    const meshSynonyms: any = { synonymsList: [], ctSource: 'ct' };
    if (ctSearchId) {
      const res: any = await getMatchSynonymsData(ctSearchId);
      if (res?.status === 200 && res?.data?.body?.result) {
        if (sources?.ct) {
          meshSynonyms.synonymsList = res?.data?.body?.result?.ct?.results;
          meshSynonyms.ctSource = 'ct';
        }
        if (sources?.eu && sources?.eu?.includes('euctr')) {
          meshSynonyms.synonymsList = res?.data?.body?.result?.euctr?.results;
          meshSynonyms.ctSource = 'euct';
        }
      }
    }
    return meshSynonyms;
  };

  const getSynonyms = async () => {
    setLoading(true);
    let isATCIsPartOfSearch = false;

    let response: any = null;
    let treeWithCount: any = {};
    try {
      const matchSynonymsList = await getMeshSyonyms();
      if (searchType === 'advanced') {
        const user = await Auth.currentUserInfo();
        const API_PAYLOAD: any = {
          query: searchTerm,
          approval_date: approvalDate ?? {},
          use_synonyms: shouldUseSynonyms ?? false,
          approvalPathway: approvalPathway ?? {},
          source: sources ?? {},
          role: user.attributes['custom:role']
        };

        response = await getAdvancedSearchSynonyms(API_PAYLOAD);
        const treeData = response?.data?.body?.Success;

        const treeCountData: any = {};
        Object.keys(treeData).forEach((term: string) => {
          if (!term.includes('atc_classification')) {
            const { countData } = getSynonymsTreeCount(cloneDeep(treeData[term]), {
              ...resultsState,
              ...matchSynonymsList
            });

            // Setting the default expanded nodes
            if (countData.trees) {
              countData.trees = countData.trees.map((tree: any) => ({
                ...tree,
                isExpanded: tree.children?.length > 0
              }));
            }
            treeCountData[term] = countData;
          } else {
            isATCIsPartOfSearch = true;
            const applications = Object.entries(resultsState.applicationResults || {}).reduce(
              (acc: any, [, value]: any) => {
                return [...acc, ...value.results];
              },
              []
            );
            let countData = getATCTreeCount(treeData[term].trees, applications);

            // Setting the default expanded nodes
            if (countData?.length) {
              countData = countData.map((tree: any) => ({
                ...tree,
                isExpanded: tree.children?.length > 0
              }));
            }
            treeCountData[term] = {
              trees: countData
            };
          }
        });
        treeWithCount = treeCountData;
        delete treeWithCount.type;
      } else {
        // Search term should change when query when entity is detected in query send the selected entity
        let searchValue = searchTerm;
        if (entityText) {
          searchValue = entityText;
        }
        response = await getGenericSynonyms(category ?? 'indications_and_usage', searchValue);

        const data = { ...response?.data?.body?.Success };
        delete data.type;
        const { data: treeData } = { data };

        const { countData } = getSynonymsTreeCount(cloneDeep(treeData), {
          ...resultsState,
          ...matchSynonymsList
        });

        // Setting the default expanded nodes
        if (countData.trees) {
          countData.trees = countData.trees.map((tree: any) => ({
            ...tree,
            isExpanded: tree.children?.length > 0
          }));
        }
        treeWithCount = countData;
      }

      const temp = { ...treeWithCount };
      const type = response?.data?.body?.Success?.type;

      let vivproSupplTerms: any = {};
      if (searchType === 'advanced') {
        Object.keys(temp).forEach((cnfPart: string) => {
          if (!cnfPart.includes('atc_classification')) {
            vivproSupplTerms = {};
            Object.keys(temp[cnfPart].vivpro_suppl_terms).forEach((key: string) => {
              vivproSupplTerms[key] = {
                ...temp[cnfPart].vivpro_suppl_terms[key].mesh_entry_terms,
                ...temp[cnfPart].vivpro_suppl_terms[key].suppl_terms
              };
            });
            temp[cnfPart].vivproSupplTerms = vivproSupplTerms;
            temp[cnfPart].supplConceptTerms = temp.suppl_concept_terms;
          }
        });
        temp.type = type;
        setSynonymsTree(temp);
      } else {
        Object.keys(temp.vivpro_suppl_terms).forEach(key => {
          vivproSupplTerms[key] = {
            ...temp.vivpro_suppl_terms[key].mesh_entry_terms,
            ...temp.vivpro_suppl_terms[key].suppl_terms
          };
        });
        temp.type = type;
        setSynonymsTree({
          tree: temp.trees,
          supplConceptTerms: temp.suppl_concept_terms,
          vivproSupplTerms,
          type
        });
      }
      setATCIncluded(isATCIsPartOfSearch);
      setError(null);
    } catch (err) {
      setError('No Synonyms Found');
    } finally {
      setLoading(false);
    }
  };

  const updateTreeFilters = (treeNumbers: Array<string>) => {
    if (difference(treeNumbers, selectedTreeNumbers).length === 0) {
      setSelectedTreeNumber(selectedTreeNumbers.filter((el: any) => !treeNumbers.includes(el)));
    } else {
      setSelectedTreeNumber([...selectedTreeNumbers, ...treeNumbers]);
    }
  };

  const applyFilter = useCallback(
    (selectedATCCodes: any = []) => {
      let payloadStub: { [key: string]: any } = {};

      payloadStub = {
        ...resultsState.filters,
        mesh_tree_numbers: selectedTreeNumbers,
        atc_code: selectedATCCodes
      };

      if (!selectedTreeNumbers?.length) {
        delete payloadStub.mesh_tree_numbers;
      }

      if (!selectedATCCodes?.length) {
        delete payloadStub.atc_code;
      }

      resultsDispatch({ type: ResultsAction.SET_FILTERS, value: payloadStub });
    },
    [selectedTreeNumbers]
  );

  const resetFilter = () => {
    let payloadStub: { [key: string]: any } = {};
    payloadStub = {
      ...resultsState.filters
    };
    if (payloadStub.mesh_tree_numbers) {
      delete payloadStub.mesh_tree_numbers;
    }

    if (payloadStub.atc_code) {
      delete payloadStub.atc_code;
    }
    setSelectedTreeNumber([]);
    resultsDispatch({ type: ResultsAction.SET_FILTERS, value: payloadStub });
  };

  const isSynonymsFilterModified = useMemo(() => {
    return !isEqual(selectedTreeNumbers, resultsState.filters.mesh_tree_numbers || []);
  }, [resultsState.filters.mesh_tree_numbers, selectedTreeNumbers]);

  const tree = useMemo(() => {
    // Mark the status of the tree to is selected if it was selected as filter.
    const trees = cloneDeep(synonymsTree);
    if (searchType === 'advanced') {
      Object.entries(trees || {}).forEach(([term, synonyms]: any) => {
        if (term !== 'type') {
          synonyms.trees?.forEach((t: any) => {
            if (term.includes('atc_classification')) {
              selectIsATCSelected(
                t,
                (resultsState.filters?.atc_code || []).map((code: string) => code.toUpperCase())
              );
            } else {
              setIsSelected(t, selectedTreeNumbers);
            }
          });

          // Check if searching was not used any synonyms
          // eslint-disable-next-line no-param-reassign
          synonyms.isOnlySynonymsUsed =
            synonyms?.trees?.length === 0 &&
            Object.keys(synonyms?.synonyms || {}).length === 0 &&
            Object.keys(synonyms?.supplConceptTerms || {}).length === 0 &&
            Object.keys(synonyms?.vivproSupplTerms || {}).length === 0;
        }
      });
    } else {
      trees?.tree?.forEach((t: any) => setIsSelected(t, selectedTreeNumbers));
      if (trees) {
        // Check if searching was not used any synonyms
        trees.isOnlySynonymsUsed =
          trees?.tree?.length === 0 &&
          Object.keys(trees?.synonyms || {}).length === 0 &&
          Object.keys(trees?.supplConceptTerms || {}).length === 0 &&
          Object.keys(trees?.vivproSupplTerms || {}).length === 0;
      }
    }

    return trees;
  }, [selectedTreeNumbers, synonymsTree, searchType, resultsState.filters?.atc_code]);

  return {
    synonymsTree: tree,
    getSynonyms,
    updateTreeFilters,
    applyFilter,
    resetFilter,
    selectedTreeNumbers,
    isSynonymsFilterModified,
    isLoading,
    isATCIncluded,
    error,
    searchTerm
  };
};

export default useSynonyms;
