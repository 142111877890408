/**
 * function to generate maximum page number possible for a given results
 * max number of results kendra can provide is 1000
 * and 300 for rest of the sources
 * @param {number} totalResults
 * @param {string} source
 * @returns a number which gives the count for maximum page number
 */
const getMaxPageNumber = totalResults => {
  // round off to next integer when totalResults/100 is a decimal (i.e 1.10 should return 2)
  // kendra can return upto max 10 pages, so if limit the number of pages to max 10
  const limit = Math.min(Math.ceil(totalResults / 100), 10);

  // return 1 if max number is 0 so pagination component can display just 1 as the list of numbers
  return limit === 0 ? 1 : limit;
};

export default getMaxPageNumber;
