import { ExportToCsv } from 'export-to-csv';
import getJsonBlob from '../api/modules/LabelComparison';

const getComparisonFile = async identifier => {
  const data = await getJsonBlob(identifier);
  const { changes } = data.data;
  const changesJson = [];
  changes.forEach(item => {
    if (item.kind === 'replace') {
      changesJson.push({
        ChangeType: 'Replace',
        LeftText: item.leftText,
        RightText: item.rightText
      });
    } else if (item.kind === 'insert') {
      changesJson.push({
        ChangeType: 'Insert',
        LeftText: '',
        RightText: item.rightText
      });
    } else if (item.kind === 'delete') {
      changesJson.push({
        ChangeType: 'Delete',
        LeftText: item.leftText,
        RightText: ''
      });
    }
  });
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    title: 'ria_pdf_comparison',
    useTextFile: false,
    filename: 'ria_pdf_comparison',
    useBom: true,
    useKeysAsHeaders: true
  };
  const csvExporter = new ExportToCsv(options);
  return csvExporter.generateCsv(changesJson);
};

export default getComparisonFile;
