import React from 'react';
import { Box, Chip, Tooltip } from '@mui/material';
import { EuFlagIcon, ModuleInfo } from '../../../assets/svgs/Icons';
import styles from '../styles/Header.styles';

const HeaderTitle = () => (
  <Box display='flex' alignItems='center'>
    Paediatric Committee (PDCO)
    <EuFlagIcon sx={styles.euFlagIcon} />
    <Chip label='EMA' sx={styles.emaChip} />
    <Tooltip
      title="The Paediatric Committee (PDCO) is the European Medicines Agency's (EMA) scientific committee responsible for activities on medicines for children and to support the development of such medicines in the European Union by providing scientific expertise and defining paediatric needs."
      placement='top'>
      <ModuleInfo sx={{ fontSize: 16, ml: 1 }} />
    </Tooltip>
  </Box>
);

export default React.memo(HeaderTitle);
