import { createSvgIcon } from '@mui/material';

const LeftArrowIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='8' viewBox='0 0 14 8' fill='none'>
    <path
      d='M14.0001 4.58327V3.41661L1.49048 3.43469L4.01164 0.912357L3.18681 0.0875244L0.511644 2.76269C0.18357 3.09086 -0.000732422 3.5359 -0.000732422 3.99994C-0.000732422 4.46398 0.18357 4.90902 0.511644 5.23719L3.18681 7.91236L4.01164 7.08752L1.52548 4.60136L14.0001 4.58327Z'
      fill='currentColor'
    />
  </svg>,
  'LeftArrow'
);

export default LeftArrowIcon;
