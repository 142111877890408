import makeRequest from '../client';

/**
 *
 * Function gets all data for guidance module
 */
export const getEMAGuidanceDocs = (filters, limit, offset) =>
  makeRequest(`/ema-guidances?limit=${limit}&offset=${offset}`, 'POST', filters);

export const getEMAGuidanceInsights = filters =>
  makeRequest('/ema-guidances/insights', 'POST', filters);

export const getEMAGuidanceDocumentsSignedURL = payload =>
  makeRequest('/ema-guidances/resource', 'POST', payload);

export const getAllEMAGuidanceDocuments = filters => makeRequest('/ema-guidances', 'POST', filters);
