import { Box, Grow, IconButton, SlideProps, Snackbar, Stack } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { pdfViewer, verifyAnswerSnackbar } from '../styles/styles';
import PDFViewer from '../../PDFViewer';
import GlobalStore from '../../../store';
import jumpToPagePlugin from '../../PDFViewer/jumpToPagePlugin';
import AriaDocumentMeta from './AriaDocumentMeta';
import DocumentMetadata from './DocumentMetadata';
import RESULT_VIEW_TYPES from '../../../pages/SearchResults/components/constants';

const SlideTransition = (props: SlideProps) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Grow {...props} />;
};
const VerifyAnswer = ({
  verifyAnswer,
  isARIAMode,
  ARIADocumentLink,
  ariaDocPageNumber,
  ariaPageNumber,
  applicationMeta,
  applicationNameList,
  handleVerifyAction,
  setOpenApplicationOption
}: any) => {
  const { state, dispatch } = useContext(GlobalStore) as any;
  const [page, setPage] = React.useState({ url: '', pageNumber: 0 });
  const [localPageNumber, setLocalPageNumber] = React.useState(0);
  const [isPageChanged, setIsPageChanged] = React.useState(false);
  const [rangeData, setRangeData] = React.useState<any>({});
  const jumpToPagePluginInstance = jumpToPagePlugin();
  const { jumpToPage } = jumpToPagePluginInstance;

  useEffect(() => {
    const pageNumber = verifyAnswer?.pageNumber || 0;
    setPage({
      url: verifyAnswer?.url || '',
      pageNumber: pageNumber ?? 0
    });
  }, [verifyAnswer]);
  useEffect(() => {
    const baseCondition =
      isARIAMode &&
      localPageNumber !== ariaDocPageNumber &&
      localPageNumber !== 0 &&
      ariaDocPageNumber !== 0;
    setIsPageChanged(baseCondition);
  }, [isARIAMode, localPageNumber, ariaDocPageNumber, ariaPageNumber]);
  useEffect(() => {
    if (applicationMeta && 'pageRange' in applicationMeta) {
      setRangeData(applicationMeta.pageRange);
    }
  }, [applicationMeta, localPageNumber]);
  useEffect(() => {
    if (state?.scrollToAriaPage && state?.scrollToAriaPage !== -1) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      jumpToPage(state?.scrollToAriaPage - 1);
      dispatch({ type: 'SET_SCROLL_TO_ARIA_PAGE', value: -1 });
    }
  }, [state.scrollToAriaPage]);
  const applData = applicationNameList.find(
    (item: any) => item?.appNumber === applicationMeta.applNumber
  );

  return (
    <Snackbar
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={verifyAnswerSnackbar}
      open={isARIAMode || page?.url !== ''}>
      <Stack
        flexDirection='column'
        sx={{
          ...pdfViewer,
          ...{
            height: '90vh',
            maxHeight: '99vh',
            '& > div#pdfBox': { height: '86%', width: '96%' }
          }
        }}>
        <Stack
          display='flex'
          flexDirection='row'
          alignItems='flex-start'
          justifyContent='space-between'
          sx={{ p: 2, width: '100%' }}>
          {isARIAMode ? (
            <AriaDocumentMeta
              item={state?.ariaDocument?.item}
              source={state?.ariaDocument?.source || 'us'}
            />
          ) : (
            <DocumentMetadata applicationData={{ ...applData, ...applicationMeta } ?? {}} />
          )}
          {state.chatRiaTriggeredFrom === RESULT_VIEW_TYPES.APPLICATION ? (
            <IconButton
              aria-label='delete'
              onClick={() => {
                setOpenApplicationOption(false);
                handleVerifyAction();
              }}
              sx={{ fontSize: '30px' }}>
              <CloseIcon fontSize='inherit' />
            </IconButton>
          ) : null}
        </Stack>
        <Box id='pdfBox'>
          {isARIAMode && ARIADocumentLink ? (
            <PDFViewer
              fileUrl={ARIADocumentLink}
              initialPage={page.pageNumber || ARIADocumentLink.split('#page=')[1] - 1 || 0}
              defaultScale={'PageWidth'}
              plugins={[jumpToPagePluginInstance]}
            />
          ) : (
            page?.url && (
              <PDFViewer
                fileUrl={`${page.url}#page=${page.pageNumber}`}
                initialPage={page.pageNumber - 1}
              />
            )
          )}
        </Box>

        {/* {totalPages && (
          <Stack direction='column' alignItems='center' sx={changePageRequestButton}>
            <Button
              disabled={!isPageChanged || queryLoading || totalPages <= 17}
              onClick={() => {
                setARIADocPageNumber(localPageNumber);
              }}
              variant='contained'
              sx={{
                color: 'white.main',
                textTransform: 'none',
                '&:disabled': {
                  backgroundColor: 'grey.500',
                  color: 'white.main'
                }
              }}>
              {isPageChanged && totalPages > 17 ? (
                <Typography variant='body1'>
                  Click here to update ChatRIA&apos;s search scope for page range{' '}
                  {rangeData?.[localPageNumber]?.start} to {rangeData?.[localPageNumber]?.end}
                </Typography>
              ) : (
                <Typography variant='body1'>
                  My answer may refer to materials from pages{' '}
                  {rangeData?.[ariaDocPageNumber]?.start} to {rangeData?.[ariaDocPageNumber]?.end}
                </Typography>
              )}
            </Button>
          </Stack>
        )} */}
      </Stack>
    </Snackbar>
  );
};

export default React.memo(VerifyAnswer);
