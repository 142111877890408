import { useEffect, useState, useContext, memo, useCallback, useMemo } from 'react';

// material ui
import { Box, Popover } from '@mui/material';

// store
import DrugDevelopmentToolsStore from '../../store/DrugDevelopmentTools';
import GlobalStore from '../../store';

import useDrugDevelopmentTools from './hooks/useDrugDevelopmentTools';
import SubHeader from '../../components/SubHeader';
import NewFilters from '../../components/NewFilters';
import AriaDrawerCore from '../SearchResults/components/AriaDrawerCore';
import AskRiaButton from '../../components/Buttons/AskRiaButton';
import AskRiaButtonStyles from '../../components/Buttons/styles/AskRiaButton.styles';
import ReportWidgets from '../../components/Report/Widgets';

import { Stat } from '../SearchResults/types';
import { DRUG_DEVELOPMENT_TOOLS_FILTERS, PAGINATION_DEFAULT_OPTIONS } from './constants';
import Details from './components/Details';
import useAriaShareHandler from '../../components/AriaShare/useAriaShareHandler';
import BottomDrawer from '../../components/CustomComponents/BottomDrawer';
import truncateCountAboveMillions from '../../utils/truncateCountAboveMillions';
import formatCount from '../../utils/formatCount';
import VISUALIZE_CHART_MAPPING from '../../components/Insights/const';
import Insights from '../../components/Insights/Insights';

const DrugDevelopmentToolsPage = () => {
  const { dispatch } = useContext(GlobalStore) as any;
  const { DrugDevelopmentToolsState } = useContext(DrugDevelopmentToolsStore);
  const {
    getDrugDevelopmentTools,
    filters,
    isLoading,
    sortFields,
    getDatawithDataGridFilter,
    dataGridFilter
  } = useDrugDevelopmentTools();

  const [ariaModalOpen, setAriaModalOpen] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);
  const [stats, setStats] = useState<Stat[]>([]);
  const [reportChoiceAnchorElement, setReportChoiceAnchorElement] = useState<HTMLElement | null>(
    null
  );
  const [sort, setSort] = useState([...sortFields]);
  const [pagination, setPagination] = useState({
    pageSize: PAGINATION_DEFAULT_OPTIONS.limit,
    page: PAGINATION_DEFAULT_OPTIONS.offset
  });
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);

  useEffect(() => {
    getDrugDevelopmentTools(
      [],
      PAGINATION_DEFAULT_OPTIONS.limit,
      PAGINATION_DEFAULT_OPTIONS.offset,
      []
    );
  }, []);

  const handleVisualizeOpen = () => {
    setIsVisualizeOpen(true);
  };

  useEffect(() => {
    const newStats: Stat[] = [];

    newStats.push({
      title: 'Total',
      value: DrugDevelopmentToolsState?.stats?.total ?? 0
    });
    newStats.push({
      title: 'Accepted Status Projects',
      value: DrugDevelopmentToolsState?.stats?.totalAcceptedStatusProjects ?? 0
    });
    newStats.push({
      title: 'Issued in Last 30 Days',
      value: DrugDevelopmentToolsState?.stats?.totalLastThirtyDays ?? 0
    });
    newStats.push({
      title: 'Total Documents | Pages',
      value: DrugDevelopmentToolsState?.stats?.totalDocuments ?? 0,
      value2: DrugDevelopmentToolsState?.stats?.totalPages ?? 0
    });

    setStats([...newStats]);
  }, [filters]);

  useAriaShareHandler(isLoading, ariaModalOpen, setAriaModalOpen);

  const getAriaDocuments = () => {
    const ariaDocuments = DrugDevelopmentToolsState?.askRiaFilters?.map((item: any) => ({
      identifier: item
    }));

    return ariaDocuments || [];
  };

  const handleFilterApply = useCallback(
    (currentfilters: any) => {
      setIsFilterUpdated(true);
      setPagination({ page: PAGINATION_DEFAULT_OPTIONS.offset, pageSize: pagination.pageSize });
      getDrugDevelopmentTools(
        currentfilters,
        pagination.pageSize,
        PAGINATION_DEFAULT_OPTIONS.offset,
        sortFields
      );
    },
    [sortFields, pagination]
  );

  const getFiltersLabelList = () => {
    return DRUG_DEVELOPMENT_TOOLS_FILTERS.reduce((acc: any, element: any) => {
      acc[element.value] = element.label;
      return acc;
    }, {});
  };

  const closeDrawer = () => {
    setIsVisualizeOpen(false);
  };

  const getInsightStatsList = useMemo(() => {
    if (!DrugDevelopmentToolsState?.stats) {
      return [];
    }
    return [
      {
        text: 'Total Projects',
        count: DrugDevelopmentToolsState?.stats?.total,
        toolTipValue: ''
      },
      {
        text: 'Accepted Status Projects',
        count: DrugDevelopmentToolsState?.stats?.totalAcceptedStatusProjects,
        toolTipValue: ''
      },
      {
        text: 'Issued in Last 30 Days',
        count: DrugDevelopmentToolsState?.stats?.totalLastThirtyDays,
        toolTipValue: ''
      },
      {
        text: 'Total Documents | Pages',
        count: `${truncateCountAboveMillions(
          DrugDevelopmentToolsState?.stats?.totalDocuments ?? 0
        )} | ${truncateCountAboveMillions(DrugDevelopmentToolsState?.stats?.totalPages ?? 0)}`,
        toolTipValue: `${formatCount(
          DrugDevelopmentToolsState?.stats?.totalDocuments ?? 0
        )} | ${formatCount(DrugDevelopmentToolsState?.stats?.totalPages ?? 0)}`
      }
    ];
  }, [DrugDevelopmentToolsState?.stats]);

  const getLayer1Charts = useMemo(() => {
    const ddtoolsGraph = VISUALIZE_CHART_MAPPING['dd-tools']?.layer1;

    if (!ddtoolsGraph || !DrugDevelopmentToolsState?.barGraph) {
      return ddtoolsGraph;
    }
    const updatedGraph = ddtoolsGraph.map((graph: any) => ({
      ...graph,
      data: DrugDevelopmentToolsState?.barGraph
    }));
    return updatedGraph;
  }, [DrugDevelopmentToolsState.barGraph]);

  const getLayer2Charts = useMemo(() => {
    const ddtoolsGraph = [...VISUALIZE_CHART_MAPPING['dd-tools'].layer2];

    if (!ddtoolsGraph || !DrugDevelopmentToolsState?.charts) {
      return ddtoolsGraph;
    }
    ddtoolsGraph.forEach(graph => {
      const donutItem = DrugDevelopmentToolsState.charts.find(
        (donut: { apiKey: string }) => donut?.apiKey === graph?.id
      );
      if (donutItem) {
        // eslint-disable-next-line no-param-reassign
        graph.data = donutItem.children;
      }
    });
    return ddtoolsGraph;
  }, [DrugDevelopmentToolsState?.charts]);

  return (
    <>
      <SubHeader
        title='Drug Development Tool (DDT) Qualification Programs'
        subTitle='Showing list of Drug Development Tool Qualification Projects by CDER & CBER'
        stats={stats}
        handleVisualizeOpen={handleVisualizeOpen}
      />

      <Box>
        {!isLoading && (
          <Popover
            open={!!reportChoiceAnchorElement}
            anchorEl={reportChoiceAnchorElement}
            onClose={() => setReportChoiceAnchorElement(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            sx={{
              '& .MuiPopover-paper': {
                borderRadius: '16px !important',
                backgroundColor: 'none'
              }
            }}>
            <Box>
              <ReportWidgets
                widgets={['pie-widget', 'bar-widget', 'table-widget']}
                title=''
                dataSource={{
                  source: 'dd-tools',
                  moduleName: 'dd-tools',
                  disableSelectionSource: true,
                  filters: DrugDevelopmentToolsState?.availableFilters,
                  filterLabelList: getFiltersLabelList()
                }}
                pathMetaData={{
                  module: 'dd-tools',
                  href: window.location.href
                }}
                setReportWidgetClose={setReportChoiceAnchorElement}
              />
            </Box>
          </Popover>
        )}
        <Details
          pagination={pagination}
          setPagination={setPagination}
          sort={sort}
          setSort={setSort}
          sortFields={sortFields}
          getDrugDevelopmentTools={getDrugDevelopmentTools}
          isFilterUpdated={isFilterUpdated}
          setIsFilterUpdated={setIsFilterUpdated}
          getDatawithDataGridFilter={getDatawithDataGridFilter}
          dataGridFilter={dataGridFilter}
        />
      </Box>

      <BottomDrawer
        isDrawerOpen={isVisualizeOpen}
        closeDrawer={closeDrawer}
        handleReportChoiceAnchorElement={setReportChoiceAnchorElement}>
        <Insights
          source='dd-tools'
          searchType='dd-tools'
          appliedFilter={DrugDevelopmentToolsState?.availableFilters}
          statsList={getInsightStatsList}
          layer1Component={getLayer1Charts}
          layer2Component={getLayer2Charts}
          isLoading={isLoading}
        />
      </BottomDrawer>

      {ariaModalOpen && (
        <AriaDrawerCore
          applications={DrugDevelopmentToolsState?.isFiltersApplied ? getAriaDocuments() : []}
          sources={{ us: ['dd-tools'] }}
          groupId='entity_id'
          docCount={DrugDevelopmentToolsState?.stats?.totalDocuments ?? 0}
          docPageCount={DrugDevelopmentToolsState?.stats?.totalPages ?? 0}
          modalOpen={ariaModalOpen}
          setModalOpen={setAriaModalOpen}
          dispatch={dispatch}
        />
      )}

      <NewFilters
        data={filters}
        applyFilters={handleFilterApply}
        isLoading={isLoading}
        selectedFilters={DrugDevelopmentToolsState.availableFilters}
      />

      <Box sx={AskRiaButtonStyles.bottomFilterPosition}>
        <AskRiaButton
          iconId='aria'
          setOpenAriaSearch={setAriaModalOpen}
          text='Ask RIA'
          disabled={isLoading}
        />
      </Box>
    </>
  );
};

export default memo(DrugDevelopmentToolsPage);
