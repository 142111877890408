const styles = {
  loaderContainer: {
    boxShadow: 'none',
    width: 'fit-content',
    mx: 'auto',
    padding: '4px 16px',
    minHeight: 'unset',
    borderRadius: '18px',
    border: '1px solid',
    borderColor: 'gray.200',
    backgroundColor: 'gray.100',
    margin: 'auto !important'
  },
  innerContainer: {
    py: 1,

    px: 2,
    transition: 'transform 0.3s',
    cursor: 'pointer',
    borderRadius: 1,
    border: '2px solid',
    borderColor: 'gray.300',
    '&:hover': {
      transform: 'scale(1)',
      zIndex: 2
    }
  }
};

export default styles;
