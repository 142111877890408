const styles = {
  grayDot: {
    backgroundColor: 'gray.500',
    margin: '0 8px',
    width: 4,
    height: 4,
    borderRadius: '50%'
  },
  greenDot: {
    backgroundColor: 'green.jungle',
    margin: '0 8px',
    width: 4,
    height: 4,
    borderRadius: '50%'
  }
};

export default styles;
