/* eslint-disable no-param-reassign */
const isValidDate = (date: any) => {
  if (Number.isNaN(new Date(date).getTime())) {
    return false;
  }
  return true;
};

const sortDateComparator = (v1: any, v2: any) => {
  v1 = isValidDate(v1) ? v1 : '1800-01-01';
  v2 = isValidDate(v2) ? v2 : '1800-01-01';
  return new Date(v1).getTime() - new Date(v2).getTime();
};

export default sortDateComparator;
