import { createSvgIcon } from '@mui/material';

const AriaResponseDefault = createSvgIcon(
  <svg width='27' height='28' viewBox='0 0 27 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.333252' y='0.666656' width='26.6667' height='26.6667' rx='13.3333' fill='#EEEEEE' />
    <path
      d='M18.3333 12L19.1666 10.1667L20.9999 9.33333L19.1666 8.49999L18.3333 6.66666L17.4999 8.49999L15.6666 9.33333L17.4999 10.1667L18.3333 12ZM13.3333 12.3333L11.6666 8.66666L9.99992 12.3333L6.33325 14L9.99992 15.6667L11.6666 19.3333L13.3333 15.6667L16.9999 14L13.3333 12.3333ZM18.3333 16L17.4999 17.8333L15.6666 18.6667L17.4999 19.5L18.3333 21.3333L19.1666 19.5L20.9999 18.6667L19.1666 17.8333L18.3333 16Z'
      fill='#0A806D'
    />
  </svg>,
  'AriaResponseDefault'
);

export default AriaResponseDefault;
