const styles = {
  close: {
    width: '100%',
    paddingTop: 2,
    paddingRight: 2,
    background: 'primary.background',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  iframeContainer: {
    width: '100%',
    height: '100vh',
    padding: 1.5
  },
  pdfIframe: {
    height: '100%',
    width: '100%',
    border: 'unset',
    borderRadius: '10px'
  }
};

export default styles;
