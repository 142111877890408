import { GridColDef } from '@mui/x-data-grid-pro';
import { FILTER_TYPE } from '../../components/NewFilters/constants';
import { PIECHARTCOLOR } from '../../components/CustomComponents/Graphs/constant';
import DatagridDate from '../../components/Datagrid/DatagridDate';
import renderCellExpand from '../../components/Datagrid/DatagridPopover';
import DocumentView from './components/DocumentView';
import DatagridReg360 from './components/Reg360';
import DataGridListView from './components/DatagridListView/DataGridListView';

type CustomGridColDef = GridColDef & {
  capitalCasing?: boolean;
};

export const ORPHAN_DESIGNATIONS_FILTERS = [
  {
    id: 1,
    label: 'Enter Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Age Ranges',
    value: 'age_ranges',
    elasticSearchType: 'age_ranges',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Decision Outcome',
    value: 'decision_outcome',
    elasticSearchType: 'decision_outcome',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Designation Number',
    value: 'designation_number',
    elasticSearchType: 'designation_number',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Designation Status',
    value: 'designation_status',
    elasticSearchType: 'designation_status',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Product Name',
    value: 'product_name',
    elasticSearchType: 'product_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 7,
    label: 'Development Stage',
    value: 'product_development_stage_at_application',
    elasticSearchType: 'product_development_stage_at_application',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 8,
    label: 'Generic Name',
    value: 'generic_name',
    elasticSearchType: 'generic_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 9,
    label: 'Date',
    value: 'decision_date',
    elasticSearchType: 'decision_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  }
];

export const DATA_GRID_COLUMNS: CustomGridColDef[] = [
  {
    field: 'document_count',
    headerName: 'Documents',
    headerClassName: 'table-header',
    width: 100,
    cellClassName: 'table-row',
    disableExport: true,
    renderCell: DocumentView
  },
  {
    field: 'designation_number',
    headerName: 'Designation Number',
    headerClassName: 'table-header',
    width: 155,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      row?.designation_number?.startsWith('NA') ? 'N/A' : `${row?.designation_number}`
  },
  {
    field: 'substance_name_at_designation',
    headerName: 'Substance(s) name (at designation)',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    capitalCasing: false,
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.substance_name_at_designation}`
  },
  {
    field: 'generic_name',
    headerName: 'Generic Name',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    capitalCasing: false,
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.generic_name}`
  },
  {
    field: 'product_name',
    headerName: 'Product Name',
    headerClassName: 'table-header',
    width: 150,
    cellClassName: 'table-row',
    capitalCasing: false,
    renderCell: DatagridReg360,
    valueGetter: ({ row }) => `${row.product_name}`
  },
  {
    field: 'opinion_date',
    headerName: 'Opinion Date',
    headerClassName: 'table-header',
    width: 115,
    cellClassName: 'table-row',
    renderCell: DatagridDate
  },
  {
    field: 'decision_date',
    headerName: 'Decision Date',
    headerClassName: 'table-header',
    width: 115,
    cellClassName: 'table-row',
    renderCell: DatagridDate
  },
  {
    field: 'decision_outcome',
    headerName: 'Outcome',
    headerClassName: 'table-header',
    width: 105,
    cellClassName: 'table-row',
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.decision_outcome}`
  },
  {
    field: 'designation_status',
    headerName: 'Status',
    headerClassName: 'table-header',
    width: 95,
    cellClassName: 'table-row',
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.designation_status}`
  },
  {
    field: 'sponsor_name',
    headerName: 'Sponsor name',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.sponsor_name}`
  },
  {
    field: 'indications_and_usage',
    headerName: 'Indications and Usage',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.scope} ${row.indications_and_usage}`
  },
  {
    field: 'condition_med_dra',
    headerName: 'Condition (MedDRA)',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.condition_med_dra}`
  },
  {
    field: 'product_class',
    headerName: 'Product class',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.product_class}`
  },
  {
    field: 'mechanism_of_action',
    headerName: 'Mechanism of action',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.mechanism_of_action}`
  },
  {
    field: 'therapeutic_area',
    headerName: 'Therapeutic area',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.therapeutic_area}`
  },
  {
    field: 'prevalence',
    headerName: 'Prevalence',
    headerClassName: 'table-header',
    width: 95,
    cellClassName: 'table-row',
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.prevalence}`
  },
  {
    field: 'significant_benefit_clause',
    headerName: 'Significant benefit clause',
    headerClassName: 'table-header',
    width: 200,
    cellClassName: 'table-row',
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.significant_benefit_clause}`
  },
  {
    field: 'age_ranges',
    headerName: 'Age Ranges',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    capitalCasing: false,
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.age_ranges}`
  },
  {
    field: 'product_development_stage_at_application',
    headerName: 'Development Stage',
    headerClassName: 'table-header',
    width: 150,
    cellClassName: 'table-row',
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.product_development_stage_at_application}`
  },
  {
    field: 'condition_severity',
    headerName: 'Condition severity',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.condition_severity}`
  },
  {
    field: 'criteria_for_medical_plausibility',
    headerName: 'Criteria for medical plausibility',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.criteria_for_medical_plausibility}`
  },
  {
    field: 'email_for_public_enquiries',
    headerName: 'Email for public enquiries',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.email_for_public_enquiries}`
  },
  {
    field: 'update_history',
    headerName: 'Update History',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    renderCell: DataGridListView,
    valueGetter: ({ row }) => `${row?.update_history?.join('; ')}`
  }
];

export const CHART = [
  {
    label: 'Decision Outcome',
    apiKey: 'decision_outcome',
    graphItem: 'decision_outcome',
    count: 3
  },
  {
    label: 'Decision Status',
    apiKey: 'designation_status',
    graphItem: 'designation_status',
    count: 4
  },
  {
    label: 'Age Ranges',
    apiKey: 'age_range',
    graphItem: 'age_range',
    count: 4
  }
];

export const barChartStyles = {
  colors: ['#B1BBE4'],
  padding: 0.8,
  labelTextColor: '#ffffff',
  axisText: '#A3A3A3'
};

export const pieChartStyles = {
  colors: PIECHARTCOLOR,
  pieLabel: true,
  pieLegend: false,
  innerRadius: 0.7,
  margin: { top: 20, right: 20, bottom: 20, left: 20 },
  enableLabels: 'both'
};

export const REPORT_QUERIES = [
  {
    id: 'ema_orphan_designations_by_year',
    title: {
      title: 'Opinions on Orphan Medicinal Product Designation by Year',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['decision_date'], agg_function: 'count', sort_on_axis: 'x' },
    graphStyle: {
      ...barChartStyles,
      axisLeft: {
        tickRotation: 0
      }
    }
  },
  {
    id: 'decision_outcome',
    title: {
      title: 'Decision Outcome',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['decision_outcome'],
      agg_function: 'count'
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'designation_status',
    title: {
      title: 'Decision Status',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['designation_status'],
      agg_function: 'count'
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'age_range',
    title: {
      title: 'Age Ranges',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['age_ranges'],
      agg_function: 'count'
    },
    graphStyle: pieChartStyles
  }
];

export const DATA_GRID_LIST_VIEW_MAPPER: any = {
  address: 'Address',
  city: 'City',
  country: 'Country',
  email: 'Email',
  first_name: 'First Name',
  last_name: 'Last Name',
  org_name: 'Organization Name',
  phone_number: 'Phone Number',
  state: 'State',
  animal_specie_desc: 'Animal Species',
  challenge_agent: 'Challenge Agent',
  challenge_agent_desc: 'Challenge Agent Category',
  biomarker_category_desc: 'Biomarker Category',
  biomarker_type_desc: 'Biomarker Type',
  disease_or_condition_desc: 'Disease or Condition',
  measure_of_desc: 'Measure of',
  min_age: 'Minimum Age',
  product_type_desc: 'Product Type',
  therapeutic_area_desc: 'Therapeutic Area',
  outcome_of_interest: 'Outcome of Interest',
  age_group_desc: 'Age Group',
  coa_type_desc: 'COA Type',
  concept_of_interest: 'Concept of Interest',
  istand_type_desc: 'ISTAND Type',
  program_type: 'Program Type'
};

export const PROJECT_DETAIL_MAPPER: any = {
  1: 'amqp_details',
  2: 'bmq_detail',
  3: 'coaqp_detail',
  4: 'istand_detail'
};

export const ENTITY_MAPPING: { [key: string]: any } = {
  approval_date: { name: 'Approval Date ' },
  category_bucket: { name: 'Category ' }
};

const day = new Date();
const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
export const CSV_FILENAME = {
  prefix: 'ria',
  module: 'ema_orphan',
  postfix: date,
  additional: '',
  extension: ''
};
