import { useContext } from 'react';
import GuideButton from './GuideButton';
import Store from '../../store';
import GUIDES from './constants';

const QuickAccessTutorial = () => {
  const { state } = useContext<any>(Store);

  const guideTag = GUIDES[state?.module];

  return guideTag ? <GuideButton /> : null;
};

export default QuickAccessTutorial;
