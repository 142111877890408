import { createSvgIcon } from '@mui/material';

const Reg360Icon = createSvgIcon(
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.81225 11.6855C9.70749 11.6862 9.60643 11.6485 9.52981 11.5803C9.45319 11.512 9.40682 11.4183 9.40023 11.3184C9.39364 11.2185 9.42732 11.12 9.49436 11.0431C9.56141 10.9662 9.65672 10.9167 9.76074 10.9049C10.9035 10.793 12.0131 10.4722 13.0291 9.95996C13.7709 9.54684 14.1797 9.06149 14.1797 8.59281C14.1797 8.07691 13.7016 7.65386 13.3003 7.38996C13.2559 7.3608 13.218 7.32358 13.1887 7.28041C13.1594 7.23725 13.1392 7.18899 13.1294 7.13839C13.1196 7.0878 13.1204 7.03585 13.1316 6.98552C13.1428 6.9352 13.1643 6.88747 13.1948 6.84508C13.2253 6.80268 13.2643 6.76645 13.3094 6.73844C13.3546 6.71043 13.4051 6.69119 13.4581 6.68183C13.5111 6.67247 13.5654 6.67317 13.6181 6.68388C13.6708 6.6946 13.7207 6.71512 13.7651 6.74428C14.5731 7.27527 14.9997 7.9142 14.9997 8.59281C14.9997 9.3627 14.4597 10.0699 13.4415 10.637C12.3319 11.2052 11.1166 11.5603 9.86417 11.6823C9.84681 11.6844 9.82974 11.6854 9.81225 11.6855Z'
      fill='currentColor'
    />
    <path
      d='M7.90054 11.1241L6.80682 10.0792C6.7297 10.0056 6.6251 9.96416 6.51604 9.96416C6.40698 9.96416 6.30239 10.0056 6.22527 10.0792C6.14815 10.1529 6.10483 10.2528 6.10483 10.357C6.10483 10.4612 6.14815 10.5611 6.22527 10.6348L6.5447 10.94C5.43899 10.8503 4.357 10.5833 3.34411 10.1503C2.375 9.705 1.8192 9.1379 1.8192 8.59382C1.8192 8.13268 2.21715 7.65328 2.94076 7.24452C3.0344 7.19153 3.10218 7.10517 3.12917 7.00444C3.15616 6.9037 3.14016 6.79685 3.08469 6.70738C3.02922 6.61792 2.93883 6.55317 2.83339 6.52739C2.72795 6.5016 2.61611 6.51688 2.52246 6.56988C1.26382 7.28421 0.999634 8.06402 0.999634 8.59382C0.999634 9.46332 1.7058 10.2669 2.98936 10.8559C4.15514 11.3539 5.40335 11.6526 6.67721 11.7385L6.2261 12.1694C6.18791 12.2059 6.15762 12.2492 6.13696 12.2969C6.11629 12.3446 6.10566 12.3956 6.10566 12.4472C6.10566 12.5514 6.14898 12.6514 6.2261 12.725C6.30322 12.7987 6.40781 12.8401 6.51687 12.8401C6.62593 12.8401 6.73053 12.7987 6.80765 12.725L7.90137 11.6801C7.93966 11.6437 7.97003 11.6004 7.99076 11.5527C8.01149 11.5051 8.02215 11.4539 8.02215 11.4023C8.02215 11.3507 8.01149 11.2996 7.99076 11.2519C7.97003 11.2043 7.93966 11.161 7.90137 11.1245L7.90054 11.1241Z'
      fill='currentColor'
    />
    <path
      d='M5.29596 7.80776V7.71331C5.29596 7.38074 5.08285 7.31646 4.79745 7.31646C4.62089 7.31646 4.56356 7.16764 4.56356 7.01882C4.56356 6.87 4.62089 6.72078 4.79745 6.72078C4.99477 6.72078 5.20291 6.69618 5.20291 6.29377C5.20291 6.00605 5.03133 5.9366 4.81822 5.9366C4.56356 5.9366 4.43394 5.99613 4.43394 6.18979C4.43394 6.35846 4.35585 6.47275 4.05466 6.47275C3.68077 6.47275 3.63383 6.39814 3.63383 6.16003C3.63383 5.7731 3.92463 5.27148 4.81863 5.27148C5.47875 5.27148 5.97727 5.49967 5.97727 6.16995C5.99415 6.32753 5.96584 6.48652 5.89537 6.62991C5.82489 6.77329 5.71491 6.89565 5.57721 6.98389C5.73832 7.03132 5.87812 7.12901 5.97423 7.26132C6.07033 7.39364 6.11719 7.55293 6.1073 7.7137V7.80815C6.1073 8.62209 5.5203 8.93005 4.79246 8.93005C3.89887 8.93005 3.55573 8.40898 3.55573 7.99189C3.55573 7.76847 3.6546 7.70894 3.94 7.70894C4.27234 7.70894 4.35543 7.77839 4.35543 7.96729C4.35543 8.20064 4.58433 8.25501 4.8178 8.25501C5.17133 8.25461 5.29596 8.1304 5.29596 7.80776Z'
      fill='currentColor'
    />
    <path
      d='M9.26057 7.7137V7.75894C9.26057 8.61257 8.70472 8.93044 7.98769 8.93044C7.27066 8.93044 6.70941 8.61296 6.70941 7.75894V6.44299C6.70941 5.58936 7.28603 5.27148 8.03464 5.27148C8.91285 5.27148 9.26098 5.79255 9.26098 6.20448C9.26098 6.44259 9.14134 6.5172 8.8817 6.5172C8.6582 6.5172 8.46087 6.46243 8.46087 6.23424C8.46087 6.04574 8.25315 5.94653 8.00887 5.94653C7.70229 5.94653 7.52033 6.1005 7.52033 6.44299V6.88984C7.606 6.81119 7.70833 6.75102 7.82041 6.71336C7.93249 6.67571 8.05172 6.66146 8.17006 6.67157C8.31792 6.65326 8.46822 6.66762 8.60936 6.71353C8.7505 6.75945 8.87872 6.83571 8.98415 6.93642C9.08958 7.03714 9.16941 7.15962 9.21747 7.29445C9.26554 7.42928 9.28057 7.57285 9.2614 7.7141L9.26057 7.7137ZM7.51992 7.81291C7.51992 8.1554 7.69647 8.30422 7.98769 8.30422C8.27891 8.30422 8.45007 8.1554 8.45007 7.81291V7.76807C8.45007 7.40574 8.27849 7.26685 7.98229 7.26685C7.91824 7.2591 7.85319 7.26571 7.79228 7.28616C7.73138 7.30662 7.6763 7.34035 7.63142 7.38468C7.58654 7.42902 7.55311 7.48273 7.53375 7.54157C7.51439 7.6004 7.50966 7.66273 7.51992 7.72362V7.81291Z'
      fill='currentColor'
    />
    <path
      d='M9.88806 7.75894V6.44299C9.88806 5.58936 10.4439 5.27148 11.1609 5.27148C11.878 5.27148 12.4392 5.58896 12.4392 6.44299V7.75894C12.4392 8.61257 11.878 8.93044 11.1609 8.93044C10.4439 8.93044 9.88806 8.61217 9.88806 7.75894ZM11.6287 6.44338C11.6287 6.1009 11.4522 5.94692 11.1609 5.94692C10.8697 5.94692 10.6986 6.1009 10.6986 6.44338V7.75894C10.6986 8.10142 10.8701 8.2554 11.1609 8.2554C11.4517 8.2554 11.6287 8.10142 11.6287 7.75894V6.44338Z'
      fill='currentColor'
    />
    <path
      d='M13.4239 5.26842C13.1806 5.26842 12.9427 5.19948 12.7403 5.07032C12.5379 4.94116 12.3802 4.75758 12.2871 4.54279C12.194 4.328 12.1696 4.09165 12.2171 3.86363C12.2645 3.63561 12.3817 3.42616 12.5538 3.26177C12.7259 3.09738 12.9452 2.98542 13.1839 2.94007C13.4226 2.89471 13.67 2.91799 13.8948 3.00696C14.1197 3.09593 14.3119 3.24659 14.4471 3.4399C14.5823 3.6332 14.6544 3.86047 14.6544 4.09295C14.654 4.40458 14.5242 4.70332 14.2935 4.92367C14.0629 5.14403 13.7501 5.268 13.4239 5.26842ZM13.4239 3.70086C13.3428 3.70086 13.2636 3.72384 13.1961 3.76688C13.1287 3.80992 13.0761 3.87109 13.0451 3.94266C13.0141 4.01423 13.006 4.09299 13.0218 4.16897C13.0376 4.24495 13.0767 4.31474 13.134 4.36952C13.1913 4.4243 13.2644 4.46161 13.3439 4.47672C13.4235 4.49183 13.5059 4.48408 13.5808 4.45443C13.6558 4.42479 13.7198 4.37458 13.7649 4.31017C13.8099 4.24575 13.834 4.17002 13.834 4.09256C13.8337 3.98874 13.7905 3.88923 13.7136 3.81582C13.6368 3.74241 13.5326 3.70107 13.4239 3.70086Z'
      fill='currentColor'
    />
  </svg>,
  'Reg360'
);

export default Reg360Icon;
