import React, { useEffect, useMemo, useState } from 'react';

import { Box, Divider } from '@mui/material';
import { cloneDeep } from 'lodash';

import Dropdown from './Dropdown';

const TreeNode: React.FC<any> = ({ node, onNodeClick, onNodeSelect, parentSelected }: any) => {
  const [nodeState, setNodeState] = useState<any>({ ...node, isSelected: !!node.isSelected });
  const handleSelect = () => {
    onNodeSelect?.(nodeState);
    setNodeState((n: any) => ({ ...cloneDeep(n), isSelected: !n.isSelected }));
  };
  const handleExpand = () => {
    setNodeState((n: any) => ({ ...cloneDeep(n), isExpanded: !n.isExpanded }));
  };

  useEffect(() => {
    setNodeState((n: any) => ({
      ...cloneDeep(n),
      isSelected: !!node.isSelected || parentSelected
    }));
  }, [node.isSelected, parentSelected]);

  const children = useMemo(() => {
    return nodeState.children?.map((child: any) => ({
      ...child,
      children: [...(child.children || [])]
    }));
  }, [node.isSelected, nodeState.isSelected, nodeState.children]);

  return (
    <Box position='relative'>
      <Dropdown
        onSelect={handleSelect}
        disabled={parentSelected || nodeState.count === 0}
        isSelected={nodeState.isSelected}
        title={nodeState.name}
        count={nodeState.count}
        onExpand={handleExpand}
        isExpanded={nodeState.isExpanded}
        isClicked={nodeState.isClicked}
        onClick={() => onNodeClick(nodeState)}
        expandDisabled={!children?.length}
      />
      {children && nodeState.isExpanded && children.length > 0 && (
        <Box position='absolute' left={15} top={46} height='calc(100% - 46px)'>
          <Divider
            orientation='vertical'
            sx={{
              borderColor: 'gray.200',
              borderWidth: 0.5
            }}
          />
        </Box>
      )}
      <Box pl={3.125}>
        {children &&
          nodeState.isExpanded &&
          children
            .sort((aChild: any, bChild: any) => aChild.count - bChild.count)
            .map((n: any) => {
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              return renderTreeNode(n, onNodeClick, onNodeSelect, nodeState.isSelected);
            })}
      </Box>
    </Box>
  );
};

const renderTreeNode = (node: any, onNodeClick: any, onNodeSelect: any, parentSelected = false) => {
  return (
    <TreeNode
      node={node}
      key={`${node.name}-${node.level}`}
      onNodeClick={onNodeClick}
      onNodeSelect={onNodeSelect}
      parentSelected={parentSelected}
    />
  );
};

export default renderTreeNode;
