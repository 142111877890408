import React from 'react';
import CustomDropdownStyles from './CustomDropdown.styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const CustomDropdown = props => {
  const classes = CustomDropdownStyles();

  return (
    <div>
      <FormControl className={classes.filterParent}>
        <InputLabel className={classes.filterLabel} id='demo-simple-select-outlined-label'>
          {props.title}
        </InputLabel>
        <Select
          className={classes.filterSelect}
          labelId='demo-simple-select-outlined-label'
          id={props.id}
          name={props.name}
          value={props.dropdownValue}
          disableUnderline={true}
          // onChange={props.handleChange}
        >
          {props.menuItems.map((i, index) => {
            return (
              <MenuItem key={String(i) + index} value={i}>
                {i}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

const CustomSearchDropdown = props => {
  const classes = CustomDropdownStyles();

  return (
    <div>
      {props.handleChange ? (
        <FormControl className={classes.filterParent}>
          <InputLabel className={classes.filterLabel} id='demo-simple-select-outlined-label'>
            {props.title}
          </InputLabel>
          <Select
            className={classes.filterSelect}
            labelId='demo-simple-select-outlined-label'
            id={props.id}
            name={props.name}
            value={props.dropdownValue}
            disableUnderline={true}
            onChange={props.handleChange}
          >
            {props.menuItems.map((i, index) => {
              return (
                <MenuItem key={String(i) + index} value={i}>
                  {i}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : (
        <FormControl className={classes.filterParent}>
          <InputLabel className={classes.filterLabel} id='demo-simple-select-outlined-label'>
            {props.title}
          </InputLabel>
          <Select
            className={classes.filterSelect}
            labelId='demo-simple-select-outlined-label'
            id={props.id}
            name={props.name}
            value={props.dropdownValue}
            disableUnderline={true}
          >
            {props.menuItems.map((i, index) => {
              return (
                <MenuItem key={String(i) + index} value={i}>
                  {i}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

const CustomThinDropdown = props => {
  const classes = CustomDropdownStyles();

  return (
    <div>
      <FormControl className={classes.filterParent}>
        <InputLabel className={classes.filterLabel} id='demo-simple-select-outlined-label'>
          {props.title}
        </InputLabel>
        <Select
          className={classes.filterSelect}
          labelId='demo-simple-select-outlined-label'
          id={props.id}
          name={props.name}
          value={props.dropdownValue}
          disableUnderline={true}
        >
          {props.menuItems.map((i, index) => {
            return (
              <MenuItem key={String(i) + index} value={i}>
                {i}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

const CustomImageButton = props => {
  const classes = CustomDropdownStyles();

  return (
    <div>
      <FormControl className={classes.filterParent}>
        <InputLabel className={classes.filterLabel} id='demo-simple-select-outlined-label'>
          {props.title}
        </InputLabel>
        <Select
          className={classes.filterSelect}
          labelId='demo-simple-select-outlined-label'
          id={props.id}
          name={props.name}
          value={props.dropdownValue}
          disableUnderline={true}
        >
          {props.menuItems.map((i, index) => {
            return (
              <MenuItem key={String(i) + index} value={i}>
                {i}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

const CustomLoginDropdown = props => {
  const classes = CustomDropdownStyles();

  return (
    <div style={{ width: '100%' }}>
      {props.handleChange ? (
        <FormControl className={classes.filterParentL} style={{ width: 'inherit', float: 'right' }}>
          <InputLabel
            className={classes.filterLabel}
            id='demo-simple-select-outlined-label'
            style={{ color: 'rgba(0, 0, 0, 0.87)', opacity: '0.5' }}
          >
            {props.title}
          </InputLabel>
          <Select
            className={classes.filterSelectL}
            labelId='demo-simple-select-outlined-label'
            id={props.id}
            name={props.name}
            value={props.dropdownValue}
            disableUnderline={true}
            onChange={props.handleChange}
          >
            {props.menuItems.map((i, index) => {
              return (
                <MenuItem key={String(i) + index} value={i}>
                  {i}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : (
        <FormControl className={classes.filterParent} style={{ width: 'inherit', float: 'right' }}>
          <InputLabel className={classes.filterLabel} id='demo-simple-select-outlined-label'>
            {props.title}
          </InputLabel>
          <Select
            className={classes.filterSelect}
            labelId='demo-simple-select-outlined-label'
            id={props.id}
            name={props.name}
            value={props.dropdownValue}
            disableUnderline={true}
          >
            {props.menuItems.map((i, index) => {
              return (
                <MenuItem key={String(i) + index} value={i}>
                  {i}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

const CustomCompareDropdown = props => {
  const classes = CustomDropdownStyles();

  return (
    <div style={{ width: '100%' }}>
      {props.handleChange ? (
        <FormControl
          className={classes.filterParentCompare}
          style={{ width: 'inherit', float: 'right' }}
        >
          <Select
            className={classes.filterSelectL}
            labelId='demo-simple-select-outlined-label'
            id={props.id}
            name={props.name}
            value={props.dropdownValue}
            disableUnderline={true}
            onChange={props.handleChange}
          >
            {props.menuItems.map((i, index) => {
              return (
                <MenuItem key={String(i) + index} value={i}>
                  {i}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : (
        <FormControl
          className={classes.filterParentCompare}
          style={{ width: 'inherit', float: 'right' }}
        >
          <Select
            className={classes.filterSelectC}
            labelId='demo-simple-select-outlined-label'
            id={props.id}
            name={props.name}
            value={props.dropdownValue}
            disableUnderline={true}
          >
            {props.menuItems.map((i, index) => {
              return (
                <MenuItem key={String(i) + index} value={i}>
                  {i}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

const CustomSearchPMRDropdown = props => {
  const classes = CustomDropdownStyles();

  return (
    <div>
      {props.handleChange ? (
        <FormControl className={classes.filterParentPMR}>
          <Select
            className={classes.filterSelectPMR}
            labelId='demo-simple-select-outlined-label'
            id={props.id}
            name={props.name}
            value={props.dropdownValue}
            disableUnderline={true}
            onChange={props.handleChange}
          >
            {props.menuItems.map((i, index) => {
              return (
                <MenuItem key={String(i) + index} value={i}>
                  {i}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : (
        <FormControl className={classes.filterParentPMR}>
          <Select
            className={classes.filterSelectPMR}
            labelId='demo-simple-select-outlined-label'
            id={props.id}
            name={props.name}
            value={props.dropdownValue}
            disableUnderline={true}
          >
            {props.menuItems.map((i, index) => {
              return (
                <MenuItem key={String(i) + index} value={i}>
                  {i}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

export {
  CustomDropdown,
  CustomThinDropdown,
  CustomImageButton,
  CustomSearchDropdown,
  CustomLoginDropdown,
  CustomCompareDropdown,
  CustomSearchPMRDropdown
};
