import uniqueId from 'lodash/uniqueId';
import capitalize from 'lodash/capitalize';
import uppperFirst from 'lodash/upperFirst';

// muiv5
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';

const CircularAvatar = ({
  avatarList,
  data,
  tooltipWithoutPrefix
}: {
  avatarList: Array<any>;
  data: any;
  tooltipWithoutPrefix: boolean;
}) => {
  const prefixText = 'indicates status at the time of original approval';

  let AAColor = '';
  if (data[avatarList[0]?.AA_status] === 'Not Yet Converted') {
    AAColor = 'orange';
  } else if (
    data[avatarList[0]?.AA_status] === 'Not Converted-Indication Withdrawn' ||
    data[avatarList[0]?.AA_status] === 'Not Converted-Application Withdrawn'
  ) {
    AAColor = 'red';
  }

  const getAvatarTextToRender = (item: any) => {
    let avatarColor;
    if (item.initial === 'RM' && data[item.fieldName] === 'Inactive') {
      avatarColor = 'red';
    } else if (item.initial === 'MS' && data[item.fieldName]?.toLowerCase() === 'not marketed') {
      avatarColor = 'red';
    } else if (item.initial === 'AA' && data[item.AA_status] !== 'Converted') {
      avatarColor = AAColor;
    }
    if (avatarColor) {
      return (
        <Typography variant='body1' style={{ color: avatarColor }}>
          {item.initial}
        </Typography>
      );
    }
    return <Typography variant='body1'>{item.initial}</Typography>;
  };

  return (
    <Stack direction='row'>
      {avatarList?.map(item => {
        if (!(item.fieldName in data)) {
          return null;
        }
        return (
          <Tooltip
            key={uniqueId()}
            title={
              // eslint-disable-next-line no-nested-ternary
              tooltipWithoutPrefix
                ? item.label
                : item?.label === 'REMS'
                ? `${item.label}: ${uppperFirst(data[item.hoverText]) || 'No'}`
                : `${item.label}: ${capitalize(prefixText)}- ${
                    uppperFirst(data[item.hoverText]) || 'No'
                  }`
            }
            sx={{ textTransform: 'capitalize' }}>
            <Avatar
              sizes='small'
              sx={{
                mr: 0.5,
                width: 24,
                height: 24,
                fontSize: 12, // REMS bubble has customised condition ,if its fieldName value is Not applicable,it should grey out.
                bgcolor:
                  data[item.fieldName] &&
                  !['no', 'not applicable', 'unknown'].includes(
                    String(data[item.fieldName])?.toLowerCase()
                  )
                    ? 'primary.100'
                    : 'gray.200',
                transition: 'transform .2s',
                color:
                  data[item.fieldName] &&
                  !['no', 'not applicable', 'unknown'].includes(
                    String(data[item.fieldName])?.toLowerCase()
                  )
                    ? 'gray.900'
                    : 'gray.750',
                '&:hover': {
                  transform: 'scale(1.2)'
                }
              }}>
              {getAvatarTextToRender(item)}
            </Avatar>
          </Tooltip>
        );
      })}
    </Stack>
  );
};

export default CircularAvatar;
