import Button from '@mui/material/Button';

// icons
import RightArrow from '../../assets/svgs/Bot/RightArrow';
import React, { useState } from 'react';
import SearchBox from './SearchBox';
import { AriaStateProvider } from '../../store/Aria';

const FeatureButton = ({
  handleClick,
  iconId,
  startIcon,
  text,
  type = 'small',
  ariaAttributes,
  disabled = false
}: {
  handleClick: any;
  iconId: string;
  text: string;
  startIcon: any;
  // eslint-disable-next-line
  disabled?: boolean;
  type?: 'big' | 'small' | undefined | null;
  ariaAttributes?: {
    source: string;
    module: string;
    queryParam: string;
    searchTerm: string;
    placeholderText?: string;
    navState?: any;
  };
}) => {
  const [openAriaSearch, setOpenAriaSearch] = useState(false);

  return (
    <>
      <Button
        id={iconId}
        onClick={() => {
          setOpenAriaSearch(true);
          handleClick();
        }}
        variant='contained'
        sx={{
          boxShadow: type === 'big' ? 'none' : '-.1em .1em #33B187',
          bgcolor: 'white.main',
          minWidth: type === 'big' ? 180 : 130,
          justifyContent: 'space-evenly',
          height: type === 'big' ? 65 : 40,
          mt: type === 'big' ? 1 : 0,
          mb: type === 'big' ? 1 : 0,
          border: 1,
          borderColor: 'primary.main',
          p: '8px 4px 8px 12px',
          borderRadius: 2,
          borderStyle: 'solid',
          textTransform: 'capitalize',
          color: 'black.darkVariant',
          fontWeight: '400',
          cursor: 'pointer',
          alignSelf: 'flex-end',
          '&:hover': {
            borderColor: 'gray.lightVariant3',
            boxShadow: 'none',
            bgcolor: 'primary.main',
            color: 'white.main',
            '& .ariaLogoColor': {
              stopColor: 'white'
            },
            '& .arrowColorHover': {
              stroke: 'white'
            },
            '& .cdpIconColor': {
              fill: 'white'
            }
          }
        }}
        startIcon={startIcon}
        disabled={disabled}
        endIcon={<RightArrow />}>
        {text}
      </Button>
      {ariaAttributes && (
        <AriaStateProvider>
          <SearchBox
            openAriaSearch={openAriaSearch}
            setOpenAriaSearch={setOpenAriaSearch}
            source={ariaAttributes?.source}
            queryParam={ariaAttributes?.queryParam}
            searchTerm={ariaAttributes?.searchTerm}
            navState={ariaAttributes?.navState}
            module={ariaAttributes?.module}
            placeholderText={ariaAttributes?.placeholderText}
          />
        </AriaStateProvider>
      )}
    </>
  );
};

export default FeatureButton;
