import themev5 from '../../../themev5';

const styles = () => ({
  searchBox: {
    width: '100%',
    height: '100%',
    fontSize: '2rem',
    [themev5.breakpoints.down('md')]: {
      width: '70%'
    },
    [themev5.breakpoints.down('sm')]: {
      width: '90%'
    }
  }
});

export default styles;
