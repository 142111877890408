import { memo, useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack
} from '@mui/material';
import { isEmpty, snakeCase } from 'lodash';
import { getSubscribedNotifications } from '../../../api/modules/userNotification';
import NotificationLoading from './NotificationLoading';
import NoNotification from './NoNotification';
import NotificationsStore from '../../../store/Notifications';
import NotificationsActions from '../../../store/Notifications/actions';
import notificationsStyles from './Notifications.styles';
import notificationsDetailStyles from './NotificationsDetail.styles';
import SubscriptionActions from '../../../components/Subscription/SubscriptionActions';
import notificationsListStyles from './NotificationsList.styles';
import { CHANNELS } from '../constants';
import {
  getAdvancedSearchSubtitle,
  getAdvancedSearchTitle,
  getQuickSearchSubtitle,
  getQuickSearchTitle,
  handleAdvancedSearchClick,
  handleSearchClick
} from '../utils';
import NotificationFlag from './NotificationFlag';
import NotificationAvatar from './NotificationAvatar';
import { SearchData } from './NotificationInterface';
import { cnfToAdvancedSearch } from '../../../components/Header/utils/advanceSearchHelpers';

const SearchesSubscription = () => {
  const { notificationsState, notificationsDispatch } = useContext(NotificationsStore);
  const [loading, setLoading] = useState(isEmpty(notificationsState.subscribedSearches));

  const fetchSubscribedSearches = async () => {
    try {
      setLoading(true);
      const response = await getSubscribedNotifications('search');
      notificationsDispatch({
        type: NotificationsActions.SET_SUBSCRIBED_SEARCHES,
        value: response.data.body
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching applications', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubscription = ({ isSubscribed, id }: { isSubscribed: boolean; id: string }) => {
    const searches = notificationsState?.subscribedSearches ?? [];
    const selectedSearchIndex = searches?.findIndex((x: any) => x.id === id);
    if (selectedSearchIndex === -1) return;

    const newSearches = [...searches];
    // isSubscribed = true cannot be case here
    if (isSubscribed) return;
    newSearches.splice(selectedSearchIndex, 1);
    notificationsDispatch({
      type: NotificationsActions.SET_SUBSCRIBED_SEARCHES,
      value: newSearches
    });
  };

  const subscribedSearchClick = ({ searchInfo }: { searchInfo: SearchData | undefined }) => {
    const notificationType = snakeCase(searchInfo?.type) as (typeof CHANNELS)[number];
    if (notificationType === 'quick_search') {
      handleSearchClick({ searchInfo });
    } else {
      handleAdvancedSearchClick({
        searchInfo,
        advanceSearchPayload: cnfToAdvancedSearch(searchInfo?.cnf_query ?? '')
      });
    }
  };

  useEffect(() => {
    // fetch searches
    if (isEmpty(notificationsState.subscribedSearches)) {
      fetchSubscribedSearches();
    }
  }, []);

  if (loading) {
    return <NotificationLoading />;
  }
  if (isEmpty(notificationsState.subscribedSearches) && !loading) {
    return <NoNotification />;
  }
  return (
    <List sx={notificationsStyles.searchSubscription}>
      {notificationsState.subscribedSearches.map(search => {
        const notificationType = snakeCase(search.type) as (typeof CHANNELS)[number];
        let title = '';
        if (notificationType === 'quick_search') {
          title = getQuickSearchTitle(search);
        } else {
          const { localSearch } = getAdvancedSearchTitle(search);
          title = localSearch as string;
        }
        const subtitle =
          notificationType === 'quick_search'
            ? getQuickSearchSubtitle(search)
            : getAdvancedSearchSubtitle(search);
        return (
          <ListItem
            sx={notificationsDetailStyles.topSection}
            key={search.id}
            onClick={() => subscribedSearchClick({ searchInfo: search })}>
            <ListItemAvatar sx={notificationsListStyles.itemAvatar}>
              <Avatar sx={notificationsListStyles.avatar}>
                <NotificationAvatar
                  notificationType={notificationType}
                  source={Object.keys(search.source)}
                />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={title}
              secondary={
                <Stack
                  direction='row'
                  alignItems='center'
                  sx={{ '& >svg': { height: '12px', width: '18px', mr: '4px' }, mt: '4px' }}>
                  <NotificationFlag source={Object.keys(search?.source ?? {})} />
                  {subtitle}
                </Stack>
              }
              sx={notificationsDetailStyles.headerTextWithHover}
            />
            {notificationType &&
              (loading ? (
                <CircularProgress size={20} />
              ) : (
                <Box mr='8px'>
                  <SubscriptionActions
                    invokedBy={notificationType}
                    id={search.id}
                    isButton
                    isSubscribed
                    setSubscriptionInHook={({ isSubscribed }: { isSubscribed: boolean }) =>
                      handleSubscription({ isSubscribed, id: search.id })
                    }
                  />
                </Box>
              ))}
          </ListItem>
        );
      })}
    </List>
  );
};
export default memo(SearchesSubscription);
