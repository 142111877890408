import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// material ui imports
import { Avatar, Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import ResgistrationStyles from './Registration.styles';
import logo from '../../Images/Viv3211.png';
import { CustomLoginDropdown } from '../CustomComponents/Dropdowns';
import getCreateUser from '../../api/pages/AdminPage';
import GlobalStore from '../../store';
import GlobalActions from '../../store/actions';
import AuthContext from '../../store/Auth/AuthProvider';

const Resgistration = () => {
  const history = useHistory();
  const classes = ResgistrationStyles();
  const { state, dispatch } = useContext(GlobalStore);
  const { currentUser } = useContext(AuthContext);

  const [usernameEmail, setusernameEmail] = useState('');
  const [passwordfield, setpasswordfield] = useState('');

  const [status, setStatus] = useState('Active');
  const [rolefield, setrolefield] = useState('Admin');
  const [FirstN, setFirstN] = useState('');
  const [LastN, setLastN] = useState('');
  const [Organization, setOrganization] = useState('');
  const [License, setLicense] = useState('Government');

  const onSubmit = async event => {
    event.preventDefault();

    const requestOptions = {
      first_name: FirstN,
      last_name: LastN,
      user_email: usernameEmail,
      password: passwordfield,
      license: License,
      user_status: status,
      organization: Organization,
      user_role: rolefield
    };

    const response = await getCreateUser(requestOptions);

    if (response && response.data.success !== null && response.data.success !== '') {
      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: `${response.data.success}`, color: 'success' }
      });
      setFirstN('');
      setLastN('');
      setusernameEmail('');
      setpasswordfield('');
      setLicense('');
      setStatus('');
      setOrganization('');
      setrolefield('');
    } else {
      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: `${response.data.error}` }
      });
    }
  };

  useEffect(() => {
    if (currentUser && currentUser['custom:role'] !== 'Admin') {
      history.push('/');
    }
    dispatch({ type: GlobalActions.SET_LOADING, value: false });
  }, [currentUser]);

  return (
    <Paper className={classes.root}>
      <Grid container spacing={0} justify='center' direction='row'>
        <Grid item>
          <Grid
            container
            direction='column'
            justify='center'
            spacing={2}
            className={classes.loginForm}>
            <Paper variant='elevation' className={classes.loginBackground}>
              <Grid container className={classes.logoBox} alignItems='center' justify='center'>
                {state.loading ? (
                  <Skeleton variant='rect' width={150} height={150} />
                ) : (
                  <Avatar alt='Clignosis Logo' src={logo} className={classes.logo} />
                )}
              </Grid>

              <Grid item className={classes.titleBox}>
                <Typography className={classes.title} component='h1' variant='h1' align='center'>
                  VivPro
                </Typography>
              </Grid>
              {
                <Grid>
                  <Grid item>
                    <form>
                      <Grid container direction='column' spacing={2}>
                        <Grid item style={{ display: 'flex' }}>
                          <Grid item xs={3}>
                            <div className={classes.fieldName}> User Name :</div>
                          </Grid>

                          <Grid item xs={9} style={{ display: 'flex' }} spacing={2}>
                            {state.loading ? (
                              <Skeleton variant='rect' width={500} height={50} />
                            ) : (
                              <>
                                <TextField
                                  type='text'
                                  placeholder='First Name'
                                  fullWidth
                                  name='username'
                                  id='username'
                                  className={classes.textFieldName}
                                  InputProps={{
                                    style: { fontSize: '1.5vh' },
                                    disableUnderline: true
                                  }}
                                  value={FirstN}
                                  onChange={event => setFirstN(event.target.value)}
                                  required
                                />
                                <TextField
                                  type='text'
                                  placeholder='Last Name'
                                  fullWidth
                                  name='username'
                                  id='username'
                                  InputProps={{
                                    style: { fontSize: '1.5vh' },
                                    disableUnderline: true
                                  }}
                                  className={classes.textFieldName}
                                  value={LastN}
                                  onChange={event => setLastN(event.target.value)}
                                  required
                                />
                              </>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item style={{ display: 'flex' }}>
                          <Grid item xs={3}>
                            <div className={classes.fieldName}>Email :</div>
                          </Grid>

                          <Grid item xs={9}>
                            {state.loading ? (
                              <Skeleton variant='rect' width={500} height={50} />
                            ) : (
                              <TextField
                                type='email'
                                placeholder='Use email as username'
                                fullWidth
                                name='username'
                                id='username'
                                className={classes.textField}
                                InputProps={{
                                  style: { fontSize: '1.5vh' },
                                  disableUnderline: true
                                }}
                                value={usernameEmail}
                                onChange={event => setusernameEmail(event.target.value)}
                                required
                              />
                            )}
                          </Grid>
                        </Grid>
                        <Grid item style={{ display: 'flex' }}>
                          <Grid item xs={3}>
                            <div className={classes.fieldName}>Organization :</div>
                          </Grid>

                          <Grid item xs={9}>
                            {state.loading ? (
                              <Skeleton variant='rect' width={500} height={50} />
                            ) : (
                              <TextField
                                type='text'
                                placeholder='Enter user Organization'
                                fullWidth
                                name='username'
                                id='username'
                                className={classes.textField}
                                InputProps={{
                                  disableUnderline: true,
                                  style: { fontSize: '1.5vh' }
                                }}
                                value={Organization}
                                onChange={event => setOrganization(event.target.value)}
                                required
                              />
                            )}
                          </Grid>
                        </Grid>
                        <Grid item style={{ display: 'flex' }}>
                          <Grid item xs={3}>
                            <div className={classes.fieldName}>Status :</div>
                          </Grid>

                          <Grid item xs={9}>
                            {state.loading ? (
                              <Skeleton variant='rect' width={500} height={50} />
                            ) : (
                              <CustomLoginDropdown
                                id='filterTerm'
                                dropdownValue={status}
                                menuItems={['Active', 'Inactive', '']}
                                title='Select User Status'
                                handleChange={event => setStatus(event.target.value)}
                                className={classes.textField}
                                required
                                style={{ width: '60vh' }}
                              />
                            )}
                          </Grid>
                        </Grid>
                        <Grid item style={{ display: 'flex' }}>
                          <Grid item xs={3}>
                            <div className={classes.fieldName}>License :</div>
                          </Grid>

                          <Grid item xs={9}>
                            {state.loading ? (
                              <Skeleton variant='rect' width={500} height={50} />
                            ) : (
                              <CustomLoginDropdown
                                id='filterTerm'
                                dropdownValue={License}
                                menuItems={[
                                  'Government',
                                  'Educational',
                                  'Enterprise',
                                  'Individual',
                                  'Free',
                                  'Evaluation',
                                  'Business',
                                  ''
                                ]}
                                title='Select User License'
                                handleChange={event => setLicense(event.target.value)}
                                className={classes.textField}
                                required
                              />
                            )}
                          </Grid>
                        </Grid>
                        <Grid item style={{ display: 'flex' }}>
                          <Grid item xs={3}>
                            <div className={classes.fieldName}>Role :</div>
                          </Grid>
                          <Grid item xs={9}>
                            {state.loading ? (
                              <Skeleton variant='rect' width={500} height={50} />
                            ) : (
                              <CustomLoginDropdown
                                className={classes.dropdown}
                                id='filterTerm'
                                dropdownValue={rolefield}
                                menuItems={['Admin', 'Developer', 'User', 'Data Curator', '']}
                                title='Select User Role'
                                handleChange={event => setrolefield(event.target.value)}
                                // className={classes.textField}
                                required
                              />
                            )}
                          </Grid>
                        </Grid>

                        <Grid item style={{ display: 'flex' }}>
                          <Grid item xs={3}>
                            <div className={classes.fieldName}>Password :</div>
                          </Grid>
                          <Grid item xs={9}>
                            {state.loading ? (
                              <Skeleton variant='rect' width={500} height={50} />
                            ) : (
                              <TextField
                                type='password'
                                placeholder='Password'
                                fullWidth
                                name='password'
                                id='password'
                                autoComplete='on'
                                InputProps={{
                                  style: { fontSize: '1.5vh' },
                                  disableUnderline: true
                                }}
                                className={classes.textField}
                                value={passwordfield}
                                onChange={event => setpasswordfield(event.target.value)}
                                required
                              />
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          className={classes.buttonBox}
                          style={{ display: 'flex', margin: '0 auto' }}>
                          {state.loading ? (
                            <Skeleton variant='rect' width={150} height={50} />
                          ) : (
                            <Button
                              variant='contained'
                              color='primary'
                              type='submit'
                              className={classes.button}
                              onClick={event => onSubmit(event)}>
                              Submit
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              }
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Resgistration;
