import React from 'react';
import { createSvgIcon } from '@mui/material';

const AngleDownIcon = createSvgIcon(
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_2875_6268)'>
      <path
        d='M8.00006 11.4468C7.56203 11.4475 7.12814 11.362 6.7232 11.195C6.31825 11.028 5.95019 10.7828 5.64007 10.4734L0.193398 5.02676C0.0678626 4.90123 -0.00266266 4.73096 -0.00266266 4.55343C-0.00266266 4.37589 0.0678626 4.20563 0.193398 4.08009C0.318934 3.95456 0.489197 3.88403 0.666732 3.88403C0.844266 3.88403 1.01453 3.95456 1.14007 4.08009L6.58673 9.52676C6.96173 9.9013 7.47006 10.1117 8.00006 10.1117C8.53007 10.1117 9.0384 9.9013 9.4134 9.52676L14.8601 4.08009C14.9856 3.95456 15.1559 3.88403 15.3334 3.88403C15.5109 3.88403 15.6812 3.95456 15.8067 4.08009C15.9323 4.20563 16.0028 4.37589 16.0028 4.55343C16.0028 4.73096 15.9323 4.90123 15.8067 5.02676L10.3601 10.4734C10.0499 10.7828 9.68188 11.028 9.27694 11.195C8.87199 11.362 8.4381 11.4475 8.00006 11.4468Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_2875_6268'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'AngleDown'
);

export default AngleDownIcon;
