// mui
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

// utils
import { GUIDES } from '../utils/constants';

// components
import GuideStepper from './GuideStepper';

// styles
import styles from '../styles/Content.styles';

const Guides = () => {
  return (
    <Container>
      <Stack spacing={2} mb={5}>
        {GUIDES?.map(guide => (
          <Accordion
            key={guide.id}
            disableGutters={false}
            elavation={0}
            square
            sx={styles.accordian}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon fontSize='medium' sx={styles.expandIcon} />}
              aria-controls='panel1a-content'
              id='panel1a-header'>
              <Typography sx={styles.title}>{guide.summary}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <GuideStepper guide={guide} />
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </Container>
  );
};

export default Guides;
