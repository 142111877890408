import makeRequest from '../client';

/**
 * Function to get Latest News for the Landing page.
 * @param {string} db
 */
export const getLatestNews = db => {
  if (db === 'us') {
    return makeRequest(`/clignosis/news-feed`, 'GET');
  }
  if (db === 'us-devices') {
    return makeRequest(`/clignosis/devices-news-feed`, 'GET');
  }
  if (db === 'ca') {
    return makeRequest(`/clignosis/canada-news-feed`, 'GET');
  }
  if (db === 'hpra') {
    return makeRequest(`/clignosis/hpra-news-feed`, 'GET');
  }
  if (db === 'hma') {
    return makeRequest(`/clignosis/hma-news-feed`, 'GET');
  }
  if (db === 'jp') {
    return makeRequest(`/clignosis/pmda-news-feed`, 'GET');
  }
  return makeRequest(`/clignosis/ema-news-feed`, 'GET');
};

/**
 * Function to get details to display Graphs on the Landing page.
 * @param {string} query
 */
export const getLandingGraphs = query => makeRequest(`/clignosis/fetch-stats/${query}`, 'GET');

/**
 * Function to get details to display Graphs on the Landing page.
 * @param {string} query
 */
export const getCorePageGraphs = query => makeRequest(`/clignosis/fetch-graph/${query}`, 'GET');
/**
 * Function to get details to display Graphs on the Landing page.
 * @param {string} query
 */
export const getDownloadData = query => makeRequest(`/clignosis/fetch-data/${query}`, 'GET');

/**
 * Function to submit searches
 * @param {object} payload
 */
export const submitSearches = payload => makeRequest(`/profile/searches`, 'POST', payload);

export const getAppVersion = () => makeRequest('/release/version', 'GET');

export const getDocumentAndPageCount = () => makeRequest('/data-source-stats/count', 'GET');

export const getUserSurvey = payload => makeRequest('/user-profile/survey', 'POST', payload);

export const submitUserSurvey = payload =>
  makeRequest('/user-profile/survey/submit', 'POST', payload);
