import { createSvgIcon } from '@mui/material';

const SignOutIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='15' viewBox='0 0 14 15' fill='none'>
    <path d='M1.16667 12.75V2.25C1.16667 2.09529 1.22812 1.94692 1.33752 1.83752C1.44692 1.72812 1.59529 1.66667 1.75 1.66667H4.66667V0.5H1.75C1.28587 0.5 0.840752 0.684374 0.512563 1.01256C0.184374 1.34075 0 1.78587 0 2.25L0 12.75C0 13.2141 0.184374 13.6592 0.512563 13.9874C0.840752 14.3156 1.28587 14.5 1.75 14.5H4.66667V13.3333H1.75C1.59529 13.3333 1.44692 13.2719 1.33752 13.1625C1.22812 13.0531 1.16667 12.9047 1.16667 12.75V12.75Z' />
    <path d='M13.4887 6.26239L10.8136 3.58722L9.98874 4.41205L12.4761 6.89939L2.91699 6.9163V8.08297L12.5087 8.06605L9.98758 10.5872L10.8124 11.4121L13.4876 8.73689C13.8158 8.40887 14.0003 7.96392 14.0005 7.49988C14.0008 7.03584 13.8167 6.59071 13.4887 6.26239Z' />
  </svg>,
  'SignOut'
);

export default SignOutIcon;
