import { memo, useContext, useState } from 'react';
import { Badge, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { isEmpty } from 'lodash';
import ManageHiddenCards from '../components/ManageHiddenCards/ManageHiddenCardsDialog';
import ResultActions from '../../../store/SearchResults/actions';
import ResultsStore from '../../../store/SearchResults';
import { UnhideItemsIcon } from '../../../assets/svgs/Icons';
import reviewMenuStyles from '../styles/ReviewMenu.styles';

const HideUnHideApplications = ({ handleParentMenuClose }: { handleParentMenuClose: Function }) => {
  const { resultsState, resultsDispatch } = useContext(ResultsStore);

  const [manageCardOpen, setManageCardOpen] = useState<boolean>(false);
  const selectHiddenApplication = ({
    source,
    identifier,
    checked
  }: {
    source: string;
    identifier: string;
    checked: boolean;
  }): void => {
    if (!resultsState?.hiddenApplications || !source) return;
    const applicationToHideIndex = resultsState?.hiddenApplications.findIndex(
      x => x.source === source && x.identifier === identifier
    );
    if (applicationToHideIndex === -1) return;
    const newHiddenApplications = [...(resultsState?.hiddenApplications ?? [])];
    newHiddenApplications[applicationToHideIndex].isChecked = checked;
    resultsDispatch({
      type: ResultActions.SET_HIDDEN_APPLICATIONS,
      value: newHiddenApplications
    });
  };
  const restoreApplications = (): void => {
    resultsDispatch({
      type: ResultActions.TRIGGER_RESTORE_APPLICATIONS,
      value: true
    });
    setTimeout(() => {
      resultsDispatch({
        type: ResultActions.TRIGGER_RESTORE_APPLICATIONS,
        value: false
      });
    }, 100);
  };
  if (isEmpty(resultsState?.hiddenApplications)) return null;
  return (
    <>
      <MenuItem
        onClick={() => {
          setManageCardOpen(true);
        }}
        sx={{
          // backgroundColor: isNestedMenuOpen ? 'gray.100' : null,
          padding: '8px 24px !important'
        }}>
        <ListItemIcon
          // @ts-ignore
          sx={{
            minWidth: '16px !important'
          }}>
          <Badge
            badgeContent={resultsState?.hiddenApplications.length}
            color='primary'
            sx={reviewMenuStyles.badgeCount}
            invisible={resultsState?.hiddenApplications.length === 0}>
            <UnhideItemsIcon sx={{ height: '16px', width: '16px', color: 'gray.700' }} />
          </Badge>
        </ListItemIcon>
        <ListItemText
          sx={{
            fontFamily: 'Mulish',
            fontSize: '12px',
            fontWeight: '400',
            ml: '8px',
            color: 'gray.800'
          }}>
          Unhide items
        </ListItemText>
      </MenuItem>
      <ManageHiddenCards
        open={manageCardOpen}
        setOpen={(open: any) => {
          setManageCardOpen(open);
        }}
        isGroupByFilterApplied={resultsState?.isGroupByFilterApplied}
        hiddenApplications={resultsState?.hiddenApplications}
        hiddenApplicationsCount={resultsState?.hiddenApplications.length}
        selectHiddenApplication={selectHiddenApplication}
        restoreApplications={restoreApplications}
        keyMapper={resultsState?.selectedGroupOption?.keyMapper}
        selectedGroupOption={resultsState?.selectedGroupOption}
        selectedSortOption={resultsState?.selectedSortOption}
        handleParentMenuClose={handleParentMenuClose}
      />
    </>
  );
};
export default memo(HideUnHideApplications);
