import React, { useCallback, useContext } from 'react';

// mui v5
import uniqueId from 'lodash/uniqueId';
import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';

// components
import CustomBarGraph from '../../../components/CustomComponents/Graphs/CustomBarGraph';
import CustomPieChart from '../../../components/CustomComponents/Graphs/CustomPieChart';
import { InsightsLoader } from './Loaders';

// utils
import { statsId } from '../utils/constants';

// styles
import styles from '../styles/HomePage.styles';

// store
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';
import {
  BAR_CHART_COLOR,
  PIECHARTCOLOR
} from '../../../components/CustomComponents/Graphs/constant';
import VISUALIZE_CHART_MAPPING, {
  VisualizeChartMappingType
} from '../../../components/Insights/const';
import StatsCountTile from '../../../helpers/baseComponents/StatsCountTile';
// import ReportButton from '../../../components/Report/ReportButton';

const HomePage = ({
  stats,
  loading,
  appliedFilters
}: {
  stats: any;
  loading: boolean;
  appliedFilters: { [key: string]: any };
}) => {
  /**
   * function to render stats tile with count data
   * @param title
   * @param value
   * @returns
   */
  const { state, dispatch } = useContext(GlobalStore) as any;

  // eslint-disable-next-line no-unused-vars
  const addToReport = useCallback(
    async (data: { id: string; layerType: keyof VisualizeChartMappingType['chmp'] }) => {
      const { id, layerType } = data;
      const chmpList = VISUALIZE_CHART_MAPPING?.chmp[layerType];
      const addedReport = chmpList?.find((report: any) => report.id === id);
      const random = Math.floor(Math.random() * 1000);
      dispatch({
        type: GlobalActions.ADD_TEMPLATE_TO_REPORT,
        value: {
          templateType: 'custom',
          chartType: addedReport?.graphType,
          sectionType: 'CHART',
          id: uniqueId(`chmp_${random}_`),
          style: {
            placement: {
              h: 40,
              w: 12
            },
            graphStyle: addedReport?.graphStyle,
            title: addedReport?.title
          },
          data: {
            source: 'chmp',
            search_type: 'chmp',
            disableSelectionSource: true,
            filters: appliedFilters,
            query: addedReport?.query
          }
        }
      });
      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: 'Successfully added to Report.', color: 'success' }
      });
    },
    [state.cartItems, dispatch, appliedFilters]
  );

  if (loading) {
    return <InsightsLoader />;
  }
  return (
    <Grid height='100%' m={0} container spacing={2}>
      {/* grid for stats counts */}
      <Grid item xs={2} p='0px !important'>
        <Stack spacing={2.5} direction='column'>
          {Object.keys(stats)?.length > 0 &&
            Array.from({ length: 3 }, (_, index) => (
              <StatsCountTile
                key={statsId[index].title}
                text={statsId[index].title}
                count={stats?.[statsId[index].value]}
                toolTipValue={stats?.[statsId[index].value]}
              />
            ))}
        </Stack>
      </Grid>
      <Grid gap={2} item xs={10} pt='0px !important'>
        <Stack spacing={2} direction='row'>
          {/* basic info */}
          <Stack sx={styles.basicInfoContainer}>
            <Typography variant='subtitle1' align='left' fontWeight='bold'>
              Committee for Medicinal Products for Human Use
            </Typography>
            <Stack sx={{ ...styles.scrollContainer, pr: 1.5 }} spacing={2}>
              <Typography variant='subtitle1' color='gray.darkText' fontWeight='bold'>
                Assessment:
              </Typography>
              <Typography variant='subtitle2' color='gray.darkText'>
                {stats?.basic_info?.assessment}
              </Typography>

              <Typography variant='subtitle1' color='gray.darkText' fontWeight='bold'>
                Role:
              </Typography>
              <Typography variant='subtitle2' color='gray.darkText'>
                {stats?.basic_info?.role}
              </Typography>
            </Stack>
          </Stack>

          {/* pie chart */}
          <Stack spacing={1.5} sx={styles.pieCharContainer}>
            <Box display='flex' justifyContent='space-between'>
              <Typography sx={styles.graphHeading}>Meeting Insights</Typography>
              {/* Disable for now */}
              {/* <ReportButton
                data={{
                  id: 'meeting_insights',
                  layerType: 'layer1'
                }}
                addToReport={addToReport}
              /> */}
            </Box>
            {stats?.meeting_insights &&
            stats?.meeting_insights?.some((item: any) => item.value !== 0) ? (
              <CustomPieChart
                data={stats?.meeting_insights?.filter((obj: any) => parseInt(obj.value, 10) !== 0)}
                colors={PIECHARTCOLOR}
                innerRadius={0.4}
                margin={{ top: 20, right: -50, bottom: 50, left: 0 }}
                fillText='#707070'
                radialLabelsTextColor='#7b808c'
                sliceLabelsTextColor='#7b808c'
                startAngle={0}
              />
            ) : (
              <Stack
                sx={{ width: '100%', height: '100%' }}
                alignItems='center'
                justifyContent='center'>
                <Typography variant='subtitle2' color='gray.darkText'>
                  No Data Available
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>

        {/* bar graph */}
        <Stack spacing={2} mt={2} direction='row'>
          <Stack sx={styles.pieCharContainer}>
            <Box display='flex' justifyContent='space-between'>
              <Typography sx={styles.graphHeading}>
                Total Number of Unique Products by Years
              </Typography>
              {/* Disable for now */}
              {/* <ReportButton
                data={{
                  id: 'meetings_by_year',
                  layerType: 'layer2'
                }}
                addToReport={addToReport}
              /> */}
            </Box>
            {stats?.unique_products_by_year &&
            stats?.unique_products_by_year?.some((item: any) => item.year !== 0) ? (
              <CustomBarGraph
                keys={['year']}
                index='key'
                colors={BAR_CHART_COLOR}
                data={stats?.unique_products_by_year}
                paddings={0.8}
                tickRotationValue={90}
                axisText='#9E9E9E'
                labelTextColor='#ffffff'
                margins={{ top: 30, right: 0, bottom: 70, left: 30 }}
              />
            ) : (
              <Stack
                sx={{ width: '100%', height: '100%' }}
                alignItems='center'
                justifyContent='center'>
                <Typography variant='subtitle2' color='gray.darkText'>
                  No Data Available
                </Typography>
              </Stack>
            )}
          </Stack>

          <Stack
            justifyContent='flex-start'
            alignItems='flex-start'
            spacing={2}
            sx={styles.basicInfoContainer}>
            <Typography variant='subtitle1' align='left' fontWeight='bold'>
              Upcoming Meetings
            </Typography>
            {Object.keys(stats?.upcoming_events).length !== 0 ? (
              <Stack gap={1} width='100%' sx={{ ...styles.scrollContainer, pr: 1 }}>
                {Object.entries(stats?.upcoming_events).map(([key, value]: any) => (
                  <Box key={key} sx={styles.upcommingMeetingContainer}>
                    <Box display='flex' justifyContent='space-between' flexDirection='column'>
                      <Tooltip title={value?.chmp_meeting_title}>
                        <Typography noWrap sx={styles.dialogText}>
                          {value?.chmp_meeting_title}
                        </Typography>
                      </Tooltip>
                      <Box>
                        <Typography style={{ fontSize: 14 }}>
                          Location: {value?.chmp_meeting_location}
                        </Typography>
                        <Typography style={{ fontSize: 14 }}>
                          Start: {value?.chmp_meeting_start_date}
                        </Typography>
                        <Typography style={{ fontSize: 14 }}>
                          End: {value?.chmp_meeting_end_date}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Stack>
            ) : (
              <Box>
                <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
                  No Recently Completed meetings found
                </Typography>
              </Box>
            )}
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default React.memo(HomePage);
