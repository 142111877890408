import { useEffect } from 'react';
import { decodeBase64ToObject } from '../../utils/encodeDecodeObject';

const useAriaShareHandler = (isLoading: boolean, ariaModalOpen: boolean, setAriaModalOpen: any) => {
  useEffect(() => {
    if (isLoading) {
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const encodedPayload = urlParams.get('ariaShare');

    const searchPayload: any = decodeBase64ToObject(encodedPayload ?? '');

    const isItAriaShare =
      searchPayload?.isAriaOpen && searchPayload?.ariaSearchTerm && searchPayload?.ariaSearchId;

    if (isItAriaShare && !isLoading && !ariaModalOpen) {
      setAriaModalOpen(true);
    }
  }, [isLoading, ariaModalOpen, setAriaModalOpen]);
};

export default useAriaShareHandler;
