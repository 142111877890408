const styles = {
  container: {
    display: 'flex',
    margin: '0.25rem 0 -4px 0',
    backgroundColor: 'inherit',
    fontFamily: 'Mulish'
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    textTransform: 'capitalize'
  },
  subTitle: {
    fontFamily: 'Mulish',
    fontSize: '11px',
    fontWeight: 500
  },
  titleContainer: { margin: '12px 0 0 16px' },
  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px'
  },
  actionsContainer: {
    display: 'flex',
    height: '38px'
  },
  reportIcon: { marginRight: '8px' },
  visualizeButton: {
    margin: 'auto'
  },
  visualizeIcon: { fontSize: '1rem !important' },
  subHeaderPipeGreyIcon: { color: '#919191', height: '42px', width: 'auto' }
};

export default styles;
