import { useContext, useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Element, scroller } from 'react-scroll';

// material ui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

// utils
import { DOCUMENT_STATE } from '../utils/constants';
import getPageRange from '../utils/getPageRange';

// api
import { highlightPDF, submitAriaFeedback } from '../../../api/pages/Aria';

// context
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';
import AriaStore from '../../../store/Aria';
import AriaActions from '../../../store/Aria/actions';

// styles
import styles from '../styles/AriaResults.styles';
import AriaResult from './AriaResult';

const AriaResults = ({ exploreSelectedFilters }) => {
  const { dispatch } = useContext(GlobalStore);
  const { ariaState, ariaDispatch } = useContext(AriaStore);
  const [filteredResults, setFilteredResults] = useState([]); // filtered results

  // get the url parameters
  const { source, module } = useParams();
  // state to store results for which feedback is given
  const [selected, setSelected] = useState([]);

  const childRefs = Array(100)
    .fill(null)
    .map(() => useRef(null)); // used to create ref for each aria result item

  // Function to handle scrolling to aria result card when selected or when verify my answer is triggered from generative ai answer.
  const scrollToChild = index => {
    // Scroll to the element with a specified name (you can use any unique name)
    scroller.scrollTo(`child-${index}`, {
      duration: 800, // Duration of the scroll animation (in milliseconds)
      smooth: 'easeInOutQuart', // Easing function for the scroll animation,
      containerId: 'scroll-container'
    });
  };

  useEffect(() => {
    const index = filteredResults.findIndex(
      element => element.result_id === ariaState.selectedDocument.id
    );

    setTimeout(() => {
      scrollToChild(index);
    }, 0);
  }, [ariaState.selectedDocument]);

  // show the document pdf when an answer is clicked
  const openDocument = (documenrUrl, originalLangDocumentUrl = '') => {
    ariaDispatch({ type: AriaActions.SET_DOCUMENT_LINK, value: documenrUrl });
    ariaDispatch({
      type: AriaActions.SET_ORIGINAL_LANG_DOCUMENT_LINK,
      value: originalLangDocumentUrl
    });
    ariaDispatch({ type: AriaActions.SET_DOCUMENT_OPEN, value: true });
    ariaDispatch({ type: AriaActions.SET_DOCUMENT_STATE, value: DOCUMENT_STATE.open });
  };

  /**
   * function to filters the results based on the selected explore page filters
   */
  const filterResultsBasedOnExplore = (results, selectedFiltersInfo) => {
    let newResults = [...results];
    selectedFiltersInfo?.selectedFiltersOrder.forEach(category => {
      newResults = newResults.filter(result =>
        selectedFiltersInfo?.selectedFilters[category]?.some(option =>
          Array.isArray(result[category])
            ? result[category].includes(option)
            : result[category] === option
        )
      );
    });
    return newResults;
  };

  // memoized filtered results
  const filteredMemoResults = () => {
    let results = isEmpty(exploreSelectedFilters)
      ? ariaState.ariaResults
      : filterResultsBasedOnExplore(ariaState.ariaResults, exploreSelectedFilters);

    results = results.filter(result => !ariaState?.uniqueResults || !result?.is_duplicate);

    ariaDispatch({ type: AriaActions.SET_EXPLORE_PAGE_RESULTS_COUNT, value: results.length });
    setFilteredResults(results);
  };

  useEffect(() => {
    let len = 0;
    ariaState.ariaResults.forEach(res => {
      if (!res?.is_duplicate) {
        len += 1;
      }
    });
    ariaDispatch({
      type: AriaActions.SET_UNIQUE_RESULTS_COUNT,
      value: len
    });

    filteredMemoResults();
  }, [
    ariaState.ariaResults,
    exploreSelectedFilters,
    ariaState.uniqueResults,
    ariaState.selectedSort
  ]);

  /**
   * returns and shows the highlighted PDF
   * @param {object} item contains the items of the results
   */
  const ariaClickAnswerEvent = async item => {
    if (item.document_url.includes('.txt')) {
      openDocument(item.document_url, item.document_url_original_language);
      ariaDispatch({
        type: AriaActions.SET_SELECTED_DOCUMENT,
        value: {
          id: item.result_id,
          // eslint-disable-next-line no-underscore-dangle
          title: item.category || item._category,
          url: item.document_url,
          item
        }
      });
      return;
    }

    if (ariaState.documentLink.length) {
      try {
        // releases the existing object URL which was previously created by calling URL.createObjectURL()
        URL.revokeObjectURL(ariaState.documentLink.split('#page=')[0]);
        ariaDispatch({ type: AriaActions.SET_DOCUMENT_LINK, value: '' });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
    if (ariaState.originalLangDocumentLink.length) {
      try {
        // releases the existing object URL which was previously created by calling URL.createObjectURL()
        URL.revokeObjectURL(ariaState.originalLangDocumentLink.split('#page=')[0]);
        ariaDispatch({ type: AriaActions.SET_ORIGINAL_LANG_DOCUMENT_LINK, value: '' });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
    // selected aria results
    ariaDispatch({
      type: AriaActions.SET_SELECTED_DOCUMENT,
      value: {
        id: item.result_id,
        // eslint-disable-next-line no-underscore-dangle
        title: item.category || item._category,
        url: item.document_url,
        item
      }
    });
    ariaDispatch({ type: AriaActions.SET_DOCUMENT_STATE, value: DOCUMENT_STATE.loading });
    const textToHighlight = item.text.replaceAll('<b>', '').replaceAll('</b>', '');
    const listOfTextToHighlight = item.highlighted_words;
    const payload = {
      s3_link: item.document_url,
      text_to_highlight: listOfTextToHighlight,
      phrase_to_highlight: textToHighlight
    };
    const pageNumber = item.document_url.split('#page=').pop();
    const res = await highlightPDF(payload);

    if (res?.status === 200) {
      const file = new Blob([res.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      openDocument(`${fileURL}#page=${pageNumber}`, item.document_url_original_language);
    } else {
      openDocument(item.document_url, item.document_url_original_language);
    }
  };

  /**
   * submit feedback to aws kendra
   * @param {object} e event handler
   * @param {object} result contains the query id and result id for request payload
   * @param {string} type user reaction 'RELEVANT' or 'NOT_RELEVANT'
   */
  const ariaResultFeedback = async (e, result, type) => {
    // check if feedback is alreaady given for that result
    if (selected.some(feedback => feedback.resultId === result.result_id)) {
      dispatch({
        type: GlobalActions.SET_ALERT,
        value: { message: 'Feedback can be submitted only once', status: true }
      });
    } else {
      setSelected([...selected, { type, resultId: result.result_id }]);
      const payload = {
        query_id: result.query_id,
        result_id: result.result_id,
        user_reaction: type
      };
      const res = await submitAriaFeedback(source, payload);
      if (res && res.data.status === 200) {
        await dispatch({
          type: GlobalActions.SET_ALERT,
          value: { message: 'Thank you for the feedback!', status: true, color: 'success' }
        });
      } else {
        setSelected(
          selected.filter(currentSelection => currentSelection.resultId !== result.result_id)
        );
        dispatch({
          type: GlobalActions.SET_ALERT,
          value: { message: 'Failed to submit your feedback', status: true }
        });
      }
    }
  };

  return (
    <Box sx={styles.root}>
      <Stack mt={2} spacing={1.5} sx={styles.container} id='scroll-container'>
        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
          <Box>
            <Typography sx={styles.label}>
              Showing{' '}
              {filteredResults.length !== ariaState.ariaResults.length &&
                `${filteredResults.length} Results from `}
              {getPageRange(ariaState.page, ariaState.ariaResults.length)} of{' '}
              {ariaState.totalResults} Results
              {ariaState.uniqueResults ? ` (${ariaState.uniqueResultsCount} unique results)` : ''}
            </Typography>
          </Box>
        </Box>
        {filteredResults?.map((item, index) => (
          <>
            <Element name={`child-${index}`} />
            <AriaResult
              ref={childRefs[index]}
              key={item?.result_id}
              item={item}
              selected={selected}
              ariaResultFeedback={ariaResultFeedback}
              ariaClickAnswerEvent={ariaClickAnswerEvent}
              source={source}
              module={module}
              selectedDocumentId={ariaState?.selectedDocument?.id ?? ''}
              searchId={ariaState?.searchId}
              showFloatingButtons
              suggestedAnswer={item?.type === 'answer'}
            />
          </>
        ))}
      </Stack>
    </Box>
  );
};

AriaResults.propTypes = {
  exploreSelectedFilters: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
    ])
  ).isRequired
};

export default AriaResults;
