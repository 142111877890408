import React, { useEffect, useMemo, useState, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getGridStringOperators, useGridApiRef } from '@mui/x-data-grid-pro';
import { GridFilterInputValueProps } from '@mui/x-data-grid';
import { debounce } from 'lodash';

import { Stack, InputLabel, Select, MenuItem, TextField, Box, FormControl } from '@mui/material';
import VivproDatagrid from '../../../components/Datagrid';
import ResultsStore from '../../../store/SearchResults';
import ResultActions from '../../../store/SearchResults/actions';

import { DATAGRID_COLUMN_MAPPING } from '../../ResultsPage/utils/constants';
import { decodeBase64ToObject } from '../../../utils/encodeDecodeObject';
import { CT_ROW_ID_MAPPING } from './constants';
import styles from '../styles/ClinicalTrialsTable.styles';

const FilterInputComponent = (props: GridFilterInputValueProps) => {
  const { resultsState } = useContext(ResultsStore);
  const { item, applyValue, focusElementRef = null } = props;

  const [filterValueState, setFilterValueState] = useState<any>(
    item.value ?? { query: '', operator: 'and' }
  );

  const isLastFilter = useMemo(() => {
    const filters = resultsState?.ctDataGridFilters?.items ?? [];
    return filters.length < 2 || filters[filters.length - 1]?.id === item?.id;
  }, [resultsState?.ctDataGridFilters?.items, item?.id]);

  const showLogicalOperator = !isLastFilter;

  useEffect(() => {
    const itemValue = item.value ?? { query: '', operator: 'and' };
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (query: string, operator: string) => {
    setFilterValueState({ query, operator });

    applyValue({ ...item, value: { query, operator } });
  };

  const handleTextChange = (event: any) => {
    const query = event.target.value;
    updateFilterValue(query, filterValueState.operator);
  };
  const handleSelectChange = (event: any) => {
    const operator = event.target.value;
    updateFilterValue(filterValueState.query, operator);
  };

  return (
    <Box sx={styles.operatorFieldWrapper}>
      <TextField
        name='filter-value-input'
        placeholder='Filter Value'
        label='Value'
        variant='standard'
        value={filterValueState?.query ?? ''}
        onChange={handleTextChange}
        type='text'
        inputRef={focusElementRef}
        sx={{ mr: 2 }}
      />
      {showLogicalOperator && (
        <FormControl variant='standard' sx={{ width: '10rem' }}>
          <InputLabel>Boolean Operator</InputLabel>
          <Select
            value={filterValueState?.operator ?? 'and'}
            onChange={handleSelectChange}
            label='Boolean Operator'>
            <MenuItem value='and'>And</MenuItem>
            <MenuItem value='or'>Or</MenuItem>
          </Select>
        </FormControl>
      )}
    </Box>
  );
};

const ClinicalTrialsTable = ({ applications, source }: any) => {
  const { resultsState, resultsDispatch } = useContext(ResultsStore);

  const { payload }: any = useParams();
  const [sort, setSort] = useState<any>([]);
  const [pagination, setPagination] = useState({
    pageSize: 20,
    page: 0
  });

  const { search_term: searchTerm }: any = useMemo(() => decodeBase64ToObject(payload), [payload]);

  const apiRef = useGridApiRef();

  useEffect(() => {
    resultsDispatch({ type: ResultActions.SET_DATA_GRID_META_DATA, value: {} });
  }, [applications, source]);

  const columnDefs = useMemo(() => {
    const ctColumns = DATAGRID_COLUMN_MAPPING[source];

    return ctColumns.map((column: any) => ({
      ...column,
      filterOperators: getGridStringOperators()
        .filter(
          operator =>
            operator.value === 'contains' ||
            operator.value === 'equals' ||
            operator.value === 'startsWith'
        )
        .map((operator: any) => ({
          ...operator,
          InputComponent: FilterInputComponent
        }))
    }));
  }, [applications]);

  const handleSortModelChange = useCallback(
    (newSortingModel: any) => {
      const [sortBy] = newSortingModel;

      setSort(newSortingModel);

      resultsDispatch({
        type: ResultActions.SET_CT_SORT_BY,
        value: { [sortBy?.field]: sortBy?.sort }
      });
    },
    [sort]
  );

  const handlePageChange = useCallback((pageModel: any) => {
    setPagination(pageModel);

    resultsDispatch({
      type: ResultActions.SET_CT_PAGINATION,
      value: pageModel
    });
  }, []);

  const handleFilterChange = useCallback(
    debounce(newFilterModel => {
      resultsDispatch({
        type: ResultActions.SET_CT_DATA_GRID_FILTERS,
        value: newFilterModel?.items?.length > 0 ? newFilterModel : {}
      });
    }, 300),
    []
  );

  useEffect(() => {
    return () => {
      handleFilterChange.cancel();
    };
  }, [handleFilterChange]);

  const debouncedDispatch = debounce((type, value) => {
    resultsDispatch({
      type,
      value
    });
  }, 300);

  useEffect(() => {
    const handleColumnVisibilityModelChange = () => {
      if (!apiRef.current) {
        return;
      }

      const visibleColumns = apiRef.current.getVisibleColumns() ?? [];

      debouncedDispatch(ResultActions.SET_DATA_GRID_META_DATA, {
        ...resultsState.dataGridMetaData,
        visibleColumns
      });
    };

    // Subscribe to the events
    const unsubscribeVisibility = apiRef.current.subscribeEvent(
      'columnVisibilityModelChange',
      handleColumnVisibilityModelChange
    );

    // Cleanup the subscription
    return () => {
      unsubscribeVisibility();
      debouncedDispatch.cancel();
    };
  }, [apiRef, resultsState?.dataGridMetaData]);

  const csvFileName = useMemo(() => `ria_search_ct_${searchTerm}`, [searchTerm]);
  return (
    <Stack id='ListData' sx={{ height: 'calc(100vh - 290px)', width: '100%' }}>
      {columnDefs && (
        <VivproDatagrid
          rows={applications ?? []}
          columnsMapping={columnDefs}
          rowId={CT_ROW_ID_MAPPING[source]}
          csvFileName={csvFileName}
          isPagination
          rowCount={resultsState?.ctStats?.totalApplications ?? 0}
          setPagination={handlePageChange}
          pagination={pagination}
          loading={resultsState?.ctIsLoading}
          isFilterModeServer
          handleFilterModelChange={handleFilterChange}
          allowFilterPanelWithLogicOperators
          sortingModel={sort}
          handleSortModelChange={handleSortModelChange}
          showExportOption={false}
          apiRef={apiRef}
        />
      )}
    </Stack>
  );
};

export default React.memo(ClinicalTrialsTable);
