import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import Typography from '@mui/material/Typography';

import Card from './Card';
import themev5 from '../../../themev5';
import { CARD_COUNT_VIEWPORT_MAPPING } from '../constants';
import findBreakPointKey from '../utils/findBreakpoints';
import { ExpandableApplicationGroupProps } from '../types';

const ExpandableApplicationGroup = ({
  group,
  keyMapper,
  applications,
  hideApplication,
  triggerChatRIA,
  setFavoriteInHook,
  setInProjectInHook,
  setSubscriptionInHook,
  handleCompareView,
  showCompare,
  comparisonApplications,
  labelComparisonLimit,
  selectedLabelSelection,
  handleLabelSection,
  hideCardOptions
}: ExpandableApplicationGroupProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [cardsPerRow, setCardsPerRow] = useState<number>(0);

  useEffect(() => {
    function updateSize() {
      const currentBreakPoint = findBreakPointKey(window.innerWidth, themev5.breakpoints.values);
      const crdsPerRow = CARD_COUNT_VIEWPORT_MAPPING[currentBreakPoint] || 4;
      setCardsPerRow(crdsPerRow);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <Grid
      container
      spacing={2.5}
      key={group}
      sx={{
        bgcolor: 'gray.100',
        borderRadius: '8px',
        transition: 'height .2s ease',
        width: '100% !important'
      }}
      p={2}
      pr={4.5}
      m='auto'
      mt={1.5}
      maxWidth='lg'>
      <Grid
        item
        xs={12}
        p={1}
        pt='0 !important'
        borderBottom='1px solid rgba(0,0,0,0.05)'
        borderColor='gray.400'>
        <Box display='flex' width='100%'>
          <Typography fontWeight='700' fontSize={14} id={`group-title-${group}`}>{`${
            keyMapper?.(group) || group
          }(${applications.length})`}</Typography>
          {applications.length > cardsPerRow && (
            <Typography
              color='primary.600'
              fontSize={14}
              fontWeight='700'
              ml='auto'
              id={`showall-${group}-button`}
              onClick={() => setExpanded(prev => !prev)}
              sx={{
                ':hover': {
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }
              }}>
              {!expanded ? 'Show all' : 'Hide'}
            </Typography>
          )}
        </Box>
      </Grid>
      {!!cardsPerRow &&
        applications
          ?.slice(0, expanded ? applications.length : cardsPerRow)
          ?.map((data: any, index: number) => (
            <Grow in key={data.identifier}>
              <Grid item xs={12} md={6} lg={3} xl={3} id={`${group}-card-container`}>
                <Card
                  data={data}
                  showDottedTitle={
                    data.groupTitle?.toLowerCase() ===
                    applications[index - 1]?.groupTitle?.toLowerCase()
                  }
                  hideApplication={hideApplication}
                  triggerChatRIA={triggerChatRIA}
                  setFavoriteInHook={setFavoriteInHook}
                  setInProjectInHook={setInProjectInHook}
                  setSubscriptionInHook={setSubscriptionInHook}
                  handleCompareView={handleCompareView}
                  showCompare={showCompare}
                  inCompare={
                    comparisonApplications?.some(d => d.identifier === data.identifier) ?? false
                  }
                  disableCompare={comparisonApplications?.length >= labelComparisonLimit}
                  handleLabelSection={handleLabelSection}
                  inLabelSection={
                    selectedLabelSelection?.some(d => d.identifier === data.identifier) ?? false
                  }
                  hideCardOptions={hideCardOptions}
                />
              </Grid>
            </Grow>
          ))}
    </Grid>
  );
};

export default React.memo(ExpandableApplicationGroup);
