import React, { useContext, useMemo, useCallback, useEffect } from 'react';

// muiv5
import Stack from '@mui/material/Stack';

// Custom Components
import { debounce } from 'lodash';
import VivproDatagrid from '../../../components/Datagrid';
import LabelCompare from './labelCompareButton';
import getTableColumnWithCellRenderers from '../../Guidance/utils/getTableColumnWithCellRenderers';
import styles from '../../../components/PMRSurveillance/styles/Details.styles';

// store
import PediatricsStore from '../../../store/Pediatrics';
import { getFileName } from '../../../helpers/getFileName';

// helper
import { COLUMN_MAPPING } from '../utils/columnMapping';
import actions from '../../../store/Pediatrics/actions';
import getDatagridFilterOption from '../../../utils/getDatagridFilterOption';
import { DATAGRID_OPTION_LIST } from '../../../components/Datagrid/constants';

const PediatricsDetails = ({
  pagination,
  setPagination,
  sort,
  setSort,
  sortFields,
  getPediatricsFDAData,
  isFilterUpdated,
  setIsFilterUpdated,
  getDatawithDataGridFilter,
  dataGridFilter
}: any) => {
  const { pediatricsFDAState, pediatricsFDADispatch } = useContext(PediatricsStore) as any;

  const day = new Date();
  const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
  const filename = {
    prefix: 'ria',
    module: 'pediatricsFDA',
    postfix: date,
    additional: '',
    extension: 'csv'
  };

  useEffect(() => {
    if (isFilterUpdated) {
      setIsFilterUpdated(false);
    }
  }, [isFilterUpdated]);

  const labelComparision = useCallback(({ rowData }: any) => <LabelCompare value={rowData} />, []);

  const tableColumns = useMemo(
    () =>
      getTableColumnWithCellRenderers(COLUMN_MAPPING, [
        { field: 's3_links', cellRenderer: labelComparision }
      ]),
    [labelComparision]
  );

  const fetchData = useCallback(
    (pageModel: any, sortModel: any, gridFilters: any) => {
      getPediatricsFDAData(
        pediatricsFDAState.availableFilters,
        pageModel.pageSize,
        pageModel.page * pageModel.pageSize,
        sortModel,
        false,
        gridFilters
      );
    },
    [pediatricsFDAState.availableFilters]
  );

  const handlePageChange = useCallback(
    (pageModel: any) => {
      if (!isFilterUpdated) {
        fetchData(pageModel, sortFields, dataGridFilter);
        setPagination(pageModel);
      }
    },
    [sortFields, pediatricsFDAState.availableFilters, isFilterUpdated, dataGridFilter]
  );

  const handleSortModelChange = useCallback(
    (newSortingModel: any) => {
      const modifiedList: any = [];

      newSortingModel.forEach((item: any) => {
        modifiedList.push(item);
      });

      if (JSON.stringify(modifiedList) !== JSON.stringify(pediatricsFDAState.sortFields)) {
        fetchData(pagination, modifiedList, dataGridFilter);
      }
      pediatricsFDADispatch({
        type: actions.SET_SORT,
        value: modifiedList
      });

      setSort(modifiedList);
    },
    [
      pagination,
      sortFields,
      pediatricsFDAState.filters,
      pediatricsFDAState,
      actions,
      dataGridFilter
    ]
  );

  const renderableTableColumns = useMemo(() => {
    const renderableColumns = [...tableColumns];

    return getDatagridFilterOption(renderableColumns, DATAGRID_OPTION_LIST, pediatricsFDAState);
  }, [pediatricsFDAState]);

  const handleFilterChange = useCallback(
    debounce(newFilterModel => {
      if (newFilterModel?.items?.length > 0) {
        getDatawithDataGridFilter(
          pediatricsFDAState.availableFilters,
          newFilterModel,
          sortFields,
          pagination.pageSize,
          pagination.page * pagination.pageSize
        );
      } else {
        fetchData(pagination, sortFields, {});
      }
    }, 300),
    [pediatricsFDAState.availableFilters, sortFields]
  );

  useEffect(() => {
    return () => {
      handleFilterChange.cancel();
    };
  }, [handleFilterChange]);

  return (
    <Stack sx={styles.root}>
      <VivproDatagrid
        rows={pediatricsFDAState.tableData}
        columnsMapping={renderableTableColumns}
        rowId='application_number'
        csvFileName={useMemo(() => getFileName(filename), [filename])}
        loading={pediatricsFDAState.loading}
        rowCount={pediatricsFDAState.totalRecord}
        isPagination
        pagination={pagination}
        setPagination={handlePageChange}
        sortingModel={sort}
        handleSortModelChange={handleSortModelChange}
        isServerSideExport
        isFilterModeServer
        allowFilterPanelWithLogicOperators
        handleFilterModelChange={handleFilterChange}
      />
    </Stack>
  );
};

export default React.memo(PediatricsDetails);
