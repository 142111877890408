/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';

import Typography from '@mui/material/Typography';
import VideoCardHorizontal from '../VideoCardHorizontal/VideoCardHorizontal';
import { VIDEO_TUTORIALS } from '../../utils/constants';

import styles from './VideoSuggestions.styles';

const VideoSuggestions = ({ video }) => {
  const [recommendations, setRecommendations] = useState();

  useEffect(() => {
    // eslint-disable-next-line array-callback-return, consistent-return
    let recommendationsList = VIDEO_TUTORIALS.filter(obj => {
      const tags = obj.tags.toString().toLowerCase();
      if (obj.id === video.id) {
        return false;
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const tag of video.tags) {
        if (tags.includes(tag.toLowerCase())) {
          return true;
        }
      }
    });
    if (recommendationsList.length === 0) {
      recommendationsList = VIDEO_TUTORIALS.filter(obj => {
        return obj.id === video.id;
      }).slice(-4);
    }
    setRecommendations(recommendationsList);
  }, [video]);

  return (
    <Stack>
      <Typography variant='subtitle1' sx={styles.heading}>
        Knowledge Center Recommendations
      </Typography>
      {recommendations?.map(vid => (
        <VideoCardHorizontal key={vid.id} video={vid} />
      ))}
    </Stack>
  );
};

export default React.memo(VideoSuggestions);
