import { createSvgIcon } from '@mui/material';

const CaretLeftIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
    <path
      d='M9.52871 12.8619L5.33337 8.66656C5.20839 8.54155 5.13818 8.37201 5.13818 8.19523C5.13818 8.01846 5.20839 7.84892 5.33337 7.7239L9.52871 3.52856V12.8619Z'
      fill='currentColor'
    />
  </svg>,
  'CaretLeftIcon'
);
export default CaretLeftIcon;
