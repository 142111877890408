import { useCallback, useContext, useEffect } from 'react';
import Papa from 'papaparse';
import XLSX from 'xlsx';

// context
import PediatricsEMAStore from '../../../store/PediatricsEMA';
import PediatricsEMAActions from '../../../store/PediatricsEMA/actions';
import globalStore from '../../../store/index';
import globalActions from '../../../store/actions';

import { mergeOldFilterValues } from '../../../utils/filters';

// api
import {
  getPediatricsStats,
  getPediatricsDetails,
  getAllPediatricsDocuments
} from '../../../api/pages/Pediatrics';

import { EMA_PIPS_COLUMN_NAME_MAPPING, SUBMODULE } from '../const';
import getTopValuesForDonutChart from '../utils/getTopValuesForDonutChart';
import getFilterOptions from '../utils/common';
import { EXPORT_TYPE, PAGINATION_DEFAULT_OPTIONS } from '../../PediatricsFDA/const';
import { getFileName } from '../../../helpers/getFileName';
import transformData from '../../../utils/transformData';

import AuthContext from '../../../store/Auth/AuthProvider';

import { getDataGridFilter } from '../../../utils/getDatagridFilterOption';

const usePediatricsEMAData = () => {
  const { pediatricsEMAState, pediatricsEMADispatch } = useContext(PediatricsEMAStore);
  const { state, dispatch }: any = useContext(globalStore);
  const { currentUser } = useContext(AuthContext);

  const getPediatricsTableData = async (payload: any, limit: number, offset: number) => {
    const res = await getPediatricsDetails(SUBMODULE, payload, limit, offset);
    if (res?.data?.status === 200) {
      pediatricsEMADispatch({
        type: PediatricsEMAActions.SET_PIP_DETAILS,
        value: res?.data?.body?.pips_doc ?? []
      });

      pediatricsEMADispatch({
        type: PediatricsEMAActions.SET_TOTAL_COUNT,
        value: res?.data?.body?.count ?? 0
      });
    }
  };

  const getPediatricsInsightData = async (filters: any, appliedFilters: any) => {
    const res = await getPediatricsStats(SUBMODULE, { filters });
    if (res?.data.status === 200) {
      const stats = res?.data?.body?.stats;
      stats.year_graph.sort((a: any, b: any) => {
        return a.id - b.id;
      });
      stats.year_graph = stats.year_graph.filter((item: any) => {
        return item.id !== 'NaN';
      });
      stats.formulations_stats = getTopValuesForDonutChart(stats.formulations_stats, 'id', 4);
      stats.route_stats = getTopValuesForDonutChart(stats.route_stats, 'id', 4);
      pediatricsEMADispatch({
        type: PediatricsEMAActions.SET_STATS,
        value: stats
      });

      let mergedFilters = [];

      const newFilters = getFilterOptions(res?.data?.body?.filters);
      if (appliedFilters.length) {
        mergedFilters = mergeOldFilterValues(newFilters, appliedFilters);
      } else {
        mergedFilters = newFilters;
      }
      pediatricsEMADispatch({
        type: PediatricsEMAActions.SET_FILTERS,
        value: mergedFilters
      });

      pediatricsEMADispatch({
        type: PediatricsEMAActions.SET_ASK_RIA_FILTERS,
        value: res?.data?.body?.filters?.entity_id || []
      });
      pediatricsEMADispatch({
        type: PediatricsEMAActions.SET_AVAILABLE_FILTERS,
        value: appliedFilters
      });
      pediatricsEMADispatch({
        type: PediatricsEMAActions.SET_STATS_LOADING,
        value: false
      });
    }
  };

  // Hook to fetch data based on datagrid filter
  const getDatawithDataGridFilter = async (
    appliedFilters: any,
    datagridFilter: any,
    sortFields: any,
    limit: number = PAGINATION_DEFAULT_OPTIONS.limit,
    offset: number = PAGINATION_DEFAULT_OPTIONS.offset
  ) => {
    pediatricsEMADispatch({
      type: PediatricsEMAActions.SET_LOADING,
      value: true
    });

    const source = {
      eu: ['pips']
    };
    const res: any = await getDataGridFilter(
      appliedFilters,
      datagridFilter,
      sortFields,
      limit,
      offset,
      pediatricsEMAState,
      currentUser,
      source
    );
    if (res?.data?.status === 200) {
      pediatricsEMADispatch({
        type: PediatricsEMAActions.SET_PIP_DETAILS,
        value: res?.data?.body?.result?.pips?.results ?? []
      });

      pediatricsEMADispatch({
        type: PediatricsEMAActions.SET_TOTAL_COUNT,
        value: res?.data?.body?.result?.pips?.total ?? 0
      });
      pediatricsEMADispatch({
        type: PediatricsEMAActions.SET_DATA_GRID_FILTERS,
        value: datagridFilter
      });
    }

    pediatricsEMADispatch({
      type: PediatricsEMAActions.SET_LOADING,
      value: false
    });
  };

  const getPediatricsEMAData = useCallback(
    async (
      appliedFilters: any,
      limit: number = PAGINATION_DEFAULT_OPTIONS.limit,
      offset: number = PAGINATION_DEFAULT_OPTIONS.offset,
      sort: Array<any> = [],
      shouldGetInsight: boolean = true,
      dataGridFilter: any = {}
    ) => {
      pediatricsEMADispatch({
        type: PediatricsEMAActions.SET_LOADING,
        value: true
      });

      pediatricsEMADispatch({
        type: PediatricsEMAActions.SET_IS_FILTERS_APPLIED,
        value: Object.keys(appliedFilters).length !== 0
      });

      const payload: any = {};
      payload.filters = { ...appliedFilters };
      if (sort) {
        payload.sort = sort;
      }
      if (Object.keys(dataGridFilter).length !== 0) {
        await getDatawithDataGridFilter(appliedFilters, dataGridFilter, sort, limit, offset);
      } else {
        try {
          await getPediatricsTableData(payload, limit, offset);
          if (shouldGetInsight) {
            // get visualize and filters data
            await getPediatricsInsightData(payload?.filters, appliedFilters);
          }
          pediatricsEMADispatch({
            type: PediatricsEMAActions.SET_DATA_GRID_FILTERS,
            value: {}
          });
          pediatricsEMADispatch({
            type: PediatricsEMAActions.SET_LOADING,
            value: false
          });
        } catch (err) {
          pediatricsEMADispatch({
            type: PediatricsEMAActions.SET_LOADING,
            value: false
          });
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }
    },
    []
  );

  const handleCSVExport = async () => {
    pediatricsEMADispatch({
      type: PediatricsEMAActions.SET_LOADING,
      value: true
    });
    const res = await getAllPediatricsDocuments(SUBMODULE, {
      filters: pediatricsEMAState.availableFilters
    });
    const selectedColumns = state?.exportType?.isAllColumnsSelected
      ? EMA_PIPS_COLUMN_NAME_MAPPING
      : Object.fromEntries(
          Object.entries(EMA_PIPS_COLUMN_NAME_MAPPING).filter(([key]) =>
            state?.exportType?.selectedColumns?.includes(key)
          )
        );
    const formattedData = transformData(res?.data?.body?.pips_doc, selectedColumns);
    const csv = Papa.unparse(formattedData);
    // Create a Blob from the CSV string
    const blob = new Blob([csv], { type: 'text/csv' });
    // Create a download link and click it
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    const day = new Date();
    const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
    const fileName = getFileName({
      prefix: 'ria',
      module: 'pips',
      postfix: date,
      additional: '',
      extension: 'csv'
    });
    a.download = `${fileName}.csv`;
    a.click();
    dispatch({
      type: globalActions.SET_EXPORT_TYPE,
      value: { format: '', selectedColumns: [], isAllColumnsSelected: false }
    });
    pediatricsEMADispatch({
      type: PediatricsEMAActions.SET_LOADING,
      value: false
    });
  };

  const handleExcelExport = async () => {
    pediatricsEMADispatch({
      type: PediatricsEMAActions.SET_LOADING,
      value: true
    });
    const res = await getAllPediatricsDocuments(SUBMODULE, {
      filters: pediatricsEMAState.availableFilters
    });
    const selectedColumns = state?.exportType?.isAllColumnsSelected
      ? EMA_PIPS_COLUMN_NAME_MAPPING
      : Object.fromEntries(
          Object.entries(EMA_PIPS_COLUMN_NAME_MAPPING).filter(([key]) =>
            state?.exportType?.selectedColumns?.includes(key)
          )
        );
    const formattedData = transformData(res?.data?.body?.pips_doc, selectedColumns);
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const day = new Date();
    const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
    const fileName = getFileName({
      prefix: 'ria',
      module: 'pips',
      postfix: date,
      additional: '',
      extension: 'xlsx'
    });
    XLSX.writeFile(wb, `${fileName}.xlsx`);
    dispatch({
      type: globalActions.SET_EXPORT_TYPE,
      value: { format: '', selectedColumns: [], isAllColumnsSelected: false }
    });
    pediatricsEMADispatch({
      type: PediatricsEMAActions.SET_LOADING,
      value: false
    });
  };

  useEffect(() => {
    if (
      state?.exportType?.format &&
      [EXPORT_TYPE.csv, EXPORT_TYPE.xlsx].includes(state?.exportType?.format)
    ) {
      if (state?.exportType?.format === EXPORT_TYPE.csv) {
        handleCSVExport();
      } else if (state?.exportType?.format === EXPORT_TYPE.xlsx) {
        handleExcelExport();
      }
    }
  }, [state?.exportType]);

  return {
    isLoading: pediatricsEMAState.loading,
    isFiltersApplied: pediatricsEMAState.isFiltersApplied,
    pediatricsEMATableData: pediatricsEMAState.pip_details,
    pediatricsEMAFilters: pediatricsEMAState.filters,
    getPediatricsEMAData,
    sortFields: pediatricsEMAState.sortFields,
    getDatawithDataGridFilter,
    dataGridFilter: pediatricsEMAState.dataGridFilters
  };
};

export default usePediatricsEMAData;
