import React from 'react';
import lightLogin from '../../../Images/Group705.svg';

const styles: any = {
  selectLabel: {
    background: '#33B187',
    color: 'white.main',
    padding: '13px 10px',
    borderRadius: '10px 10px 0px 0px',
    marginBottom: 'auto',
    fontSize: '2.3vh'
  },
  loadingContainer: {
    width: '100vw',
    height: '100vh'
  },
  selectLabelC: {
    background: '#33B187',
    color: 'white.main',
    borderRadius: '10px 10px 0px 0px',
    marginBottom: 'auto',
    fontSize: '2.3vh',
    padding: '13px 10px'
  },
  compareBox: {
    backgroundColor: 'rgb(247, 247, 247)',
    overflowY: 'scroll',
    height: '90%',
    position: 'relative'
  },
  labelContainer: {
    borderBottom: '1px solid #ccc',
    '&:hover': {
      backgroundColor: 'rgb(242, 247, 247)',
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },
  labelContainer__selected: {
    borderBottom: '1px solid #ccc',
    backgroundColor: 'rgb(242, 247, 247)',
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'primary.main'
  },
  compareText: {
    textAlign: 'justify',
    display: 'flex',
    margin: '0px 20px 0px 20px',
    lineHeight: '1.5em',
    height: '3em',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
      content: '"..."',
      position: 'absolute',
      right: '0px',
      bottom: '0px',
      padding: '0 5px',
      backgroundColor: 'rgb(247, 247, 247)'
    }
  },
  compareIcon: {
    width: '33px',
    paddingRight: '10px'
  },
  compareIFrame: {
    height: '100%',
    width: '100%'
  },
  centerBall: {
    width: '28px',
    position: 'absolute',
    marginTop: '-14px',
    right: '50%'
  } as React.CSSProperties,
  checkboxIcon: {
    margin: 'unset',
    width: '100%',
    justifyContent: 'center'
  },
  labelTitle: {
    fontSize: '1.4vh',
    fontWeight: 500,
    paddingLeft: '5px',
    margin: 'auto'
  },
  labelTitle2: {
    fontSize: '13px',
    fontWeight: 500,
    paddingLeft: '5px',
    margin: 'auto'
  },
  labelDate: {
    fontSize: '16px',
    fontWeight: '800'
  },
  labelDateGreen: {
    fontSize: '16px',
    fontWeight: '800',
    color: '#33B187'
  },
  labelSup: {
    fontSize: '16px',
    fontWeight: '570'
  },
  LabelTitle: {
    color: '#1C7D5D',
    fontWeight: '800',
    fontSize: '3.5vh',
    fontFamily: 'Constantia',
    paddingTop: '32px'
  },

  labelSupGreen: {
    fontSize: '16px',
    fontWeight: '570',
    color: '#33B187'
  },
  labelChangeGreen: {
    fontSize: '16px',
    fontWeight: '500',
    padding: '10px 0px',
    color: '#33B187'
  },
  labelChange: {
    fontSize: '16px',
    fontWeight: '500',
    padding: '10px 0px'
  },
  mostRecent: {
    margin: '5px',
    padding: '3px 20px',
    fontSize: '1.25vh',
    fontWeight: 'bold',
    border: '2px solid #e3f1ff',
    boxShadow: '0 0 4px rgb(220, 220, 220,0.5)',
    borderRadius: '20px',
    backgroundColor: '#f7fbff',
    color: 'green.accept',
    cursor: 'pointer'
  },
  resize: {
    fontSize: 1.85,
    marginLeft: 1,
    width: '100%'
  },
  compareL: {
    height: '30px',
    padding: '3px 20px',
    fontSize: '1.7vh',
    fontWeight: 'bold',
    border: '2px solid #e3f1ff',
    boxShadow: '0 0 4px rgb(220, 220, 220,0.5)',
    borderRadius: '20px',
    backgroundColor: 'primary.700',
    color: '#FFFFFF',
    cursor: 'pointer',
    margin: '0 auto',
    display: 'flex',
    width: 'fit-content'
  },
  twoBox: {
    background: 'white.main',
    height: '65vh',
    overflowY: 'auto'
  },
  StyledErrorTypography: {
    fontSize: '2vh',
    padding: '15px',
    color: 'black.main',
    borderRadius: '10px',
    backgroundColor: 'blue.alice',
    fontWeight: '700'
  },
  compareN: {
    height: '30px',
    padding: '3px 20px',
    fontSize: '1.7vh',
    fontWeight: 'bold',
    border: '2px solid #e3f1ff',
    boxShadow: '0 0 4px rgb(220, 220, 220,0.5)',
    borderRadius: '20px',
    backgroundColor: 'primary.700',
    color: '#FFFFFF',
    cursor: 'pointer',
    margin: '10px auto',
    display: 'flex',
    width: 'fit-content'
  },
  productCompareButton: {
    margin: '24px 0px 16px',
    height: '40px',
    borderRadius: 2.5,
    backgroundColor: 'primary.700',
    color: 'white.main',
    fontWeight: '600',
    fontSize: '1.3vh',
    display: 'flex',
    cursor: 'pointer',
    padding: 1,
    '&:hover': {
      backgroundColor: 'primary.700',
      color: 'white.main'
    },
    '&:disabled': {
      backgroundColor: 'gray.light',
      color: 'white.main'
    }
  },
  dialog: {
    height: '100%',
    backgroundImage: `url(${lightLogin})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    backgroundColor: 'rgb(247, 247, 247)'
  },
  compareLine: {
    display: 'inline-flex',
    borderTop: '1px solid #a2a2a2',
    marginBottom: '20px',
    width: '46%'
  },
  dialog2: {
    height: '86%',
    backgroundImage: `url(${lightLogin})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed'
  },
  labelLoadErrorMessage: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '16px',
    fontWeight: 'bold'
  }
};

export default styles;
