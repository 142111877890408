import React from 'react';

const PageCountIcon = () => {
  return (
    <svg width='32' height='37' viewBox='0 0 32 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g mask='url(#mask0_73566_86)'>
        <path
          d='M23.9637 14.371H8.45311C7.59652 14.3699 6.7753 14.0092 6.16959 13.3678C5.56389 12.7265 5.22317 11.857 5.22217 10.95V-9.579C5.22317 -10.486 5.56389 -11.3555 6.16959 -11.9968C6.7753 -12.6382 7.59652 -12.9989 8.45311 -13H23.9637C24.8203 -12.9989 25.6415 -12.6382 26.2472 -11.9968C26.8529 -11.3555 27.1937 -10.486 27.1947 -9.579V10.95C27.1937 11.857 26.8529 12.7265 26.2472 13.3678C25.6415 14.0092 24.8203 14.3699 23.9637 14.371ZM8.45311 -11.629C7.93968 -11.6282 7.44746 -11.412 7.08423 -11.0278C6.721 -10.6436 6.51636 -10.1226 6.51511 -9.579V10.95C6.51636 11.4936 6.721 12.0146 7.08423 12.3988C7.44746 12.783 7.93968 12.9992 8.45311 13H23.9637C24.4778 13.0003 24.971 12.7846 25.335 12.4002C25.6991 12.0159 25.9043 11.4943 25.9055 10.95V-9.579C25.905 -10.1233 25.7006 -10.6452 25.337 -11.0301C24.9735 -11.415 24.4806 -11.6315 23.9666 -11.632L8.45311 -11.629Z'
          fill='#646464'
        />
        <path
          d='M21.6017 4.046H11.261C11.0895 4.046 10.9251 3.97388 10.8038 3.84552C10.6826 3.71715 10.6145 3.54304 10.6145 3.3615C10.6145 3.17996 10.6826 3.00586 10.8038 2.87749C10.9251 2.74912 11.0895 2.677 11.261 2.677H21.6017C21.7732 2.677 21.9376 2.74912 22.0588 2.87749C22.1801 3.00586 22.2482 3.17996 22.2482 3.3615C22.2482 3.54304 22.1801 3.71715 22.0588 3.84552C21.9376 3.97388 21.7732 4.046 21.6017 4.046Z'
          fill='#646464'
        />
        <path
          d='M21.6017 9.266H11.261C11.1761 9.266 11.092 9.2483 11.0136 9.2139C10.9351 9.1795 10.8639 9.12908 10.8038 9.06552C10.7438 9.00196 10.6962 8.9265 10.6637 8.84345C10.6312 8.7604 10.6145 8.67139 10.6145 8.5815C10.6145 8.49161 10.6312 8.4026 10.6637 8.31956C10.6962 8.23651 10.7438 8.16105 10.8038 8.09749C10.8639 8.03393 10.9351 7.98351 11.0136 7.94911C11.092 7.91471 11.1761 7.897 11.261 7.897H21.6017C21.7732 7.897 21.9376 7.96912 22.0588 8.09749C22.1801 8.22586 22.2482 8.39996 22.2482 8.5815C22.2482 8.76304 22.1801 8.93715 22.0588 9.06552C21.9376 9.19389 21.7732 9.266 21.6017 9.266Z'
          fill='#646464'
        />
        <path
          d='M42.8326 19H-7.83256C-8.24852 19 -8.6604 18.9871 -9.04469 18.9619C-9.42898 18.9368 -9.77816 18.9 -10.0723 18.8536C-10.3664 18.8071 -10.5997 18.752 -10.7589 18.6913C-10.9181 18.6307 -11 18.5657 -11 18.5C-11 18.4343 -10.9181 18.3693 -10.7589 18.3087C-10.5997 18.248 -10.3664 18.1929 -10.0723 18.1464C-9.77816 18.1 -9.42898 18.0632 -9.04469 18.0381C-8.6604 18.0129 -8.24852 18 -7.83256 18H42.8326C43.6726 18 44.4783 18.0527 45.0723 18.1464C45.6663 18.2402 46 18.3674 46 18.5C46 18.6326 45.6663 18.7598 45.0723 18.8536C44.4783 18.9473 43.6726 19 42.8326 19Z'
          fill='#646464'
        />
        <path
          d='M23.9637 50.371H8.45311C7.59652 50.3699 6.7753 50.0092 6.16959 49.3678C5.56389 48.7265 5.22317 47.857 5.22217 46.95V26.421C5.22317 25.514 5.56389 24.6445 6.16959 24.0032C6.7753 23.3618 7.59652 23.0011 8.45311 23H23.9637C24.8203 23.0011 25.6415 23.3618 26.2472 24.0032C26.8529 24.6445 27.1937 25.514 27.1947 26.421V46.95C27.1937 47.857 26.8529 48.7265 26.2472 49.3678C25.6415 50.0092 24.8203 50.3699 23.9637 50.371ZM8.45311 24.371C7.93968 24.3718 7.44746 24.588 7.08423 24.9722C6.721 25.3564 6.51636 25.8774 6.51511 26.421V46.95C6.51636 47.4936 6.721 48.0146 7.08423 48.3988C7.44746 48.783 7.93968 48.9992 8.45311 49H23.9637C24.4778 49.0003 24.971 48.7846 25.335 48.4002C25.6991 48.0159 25.9043 47.4943 25.9055 46.95V26.421C25.905 25.8767 25.7006 25.3548 25.337 24.9699C24.9735 24.585 24.4806 24.3685 23.9666 24.368L8.45311 24.371Z'
          fill='#646464'
        />
        <path
          d='M21.6017 34.717H11.261C11.0895 34.717 10.9251 34.6449 10.8038 34.5165C10.6826 34.3882 10.6145 34.2141 10.6145 34.0325C10.6145 33.851 10.6826 33.6769 10.8038 33.5485C10.9251 33.4201 11.0895 33.348 11.261 33.348H21.6017C21.7732 33.348 21.9376 33.4201 22.0588 33.5485C22.1801 33.6769 22.2482 33.851 22.2482 34.0325C22.2482 34.2141 22.1801 34.3882 22.0588 34.5165C21.9376 34.6449 21.7732 34.717 21.6017 34.717Z'
          fill='#646464'
        />
        <path
          d='M21.6017 29.388H11.261C11.0895 29.388 10.9251 29.3159 10.8038 29.1876C10.6826 29.0592 10.6145 28.8851 10.6145 28.7035C10.6145 28.522 10.6826 28.3479 10.8038 28.2195C10.9251 28.0912 11.0895 28.019 11.261 28.019H21.6017C21.7732 28.019 21.9376 28.0912 22.0588 28.2195C22.1801 28.3479 22.2482 28.522 22.2482 28.7035C22.2482 28.8851 22.1801 29.0592 22.0588 29.1876C21.9376 29.3159 21.7732 29.388 21.6017 29.388Z'
          fill='#646464'
        />
      </g>
    </svg>
  );
};

export default PageCountIcon;
