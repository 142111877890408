import { createSvgIcon } from '@mui/material';

const SuggestionButton = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <g clipPath='url(#clip0_9529_128565)'>
      <path
        d='M6.66648 16.7907V19.9999H13.3331V16.929C13.3168 16.2187 13.452 15.513 13.7296 14.8589C14.0072 14.2049 14.4209 13.6174 14.9431 13.1357C16.0831 12.1365 16.8935 10.8154 17.2675 9.34649C17.6416 7.87756 17.5618 6.32975 17.0387 4.90708C16.5155 3.4844 15.5735 2.25364 14.3369 1.37702C13.1003 0.500395 11.6271 0.0190546 10.1114 -0.00355949C8.59576 -0.0261736 7.10883 0.411 5.8466 1.25034C4.58438 2.08968 3.60611 3.29179 3.04073 4.69822C2.47536 6.10465 2.34941 7.64939 2.6795 9.12883C3.00959 10.6083 3.78022 11.953 4.88981 12.9857C5.43332 13.4623 5.87188 14.0466 6.17772 14.7016C6.48355 15.3566 6.64999 16.068 6.66648 16.7907ZM11.6665 18.3332H8.33315V16.7907C8.33315 16.749 8.32731 16.7074 8.32648 16.6665H11.6798C11.6798 16.754 11.6665 16.8407 11.6665 16.929V18.3332ZM4.21648 6.72902C4.34037 5.79417 4.68905 4.90329 5.23266 4.13271C5.77626 3.36214 6.49858 2.73484 7.33773 2.30457C8.17687 1.87431 9.10783 1.6539 10.0508 1.66223C10.9938 1.67057 11.9207 1.90739 12.7521 2.35242C13.5835 2.79745 14.2947 3.43742 14.8246 4.21748C15.3545 4.99755 15.6873 5.89445 15.7947 6.83135C15.902 7.76824 15.7806 8.71719 15.4409 9.59689C15.1011 10.4766 14.5532 11.2608 13.844 11.8824C12.9165 12.7128 12.2522 13.7965 11.9331 14.9999H10.8331V9.01319C11.3189 8.84145 11.7397 8.52383 12.0381 8.10377C12.3364 7.68371 12.4977 7.18174 12.4998 6.66652H10.8331C10.8331 6.88753 10.7454 7.09949 10.5891 7.25577C10.4328 7.41206 10.2208 7.49985 9.99981 7.49985C9.7788 7.49985 9.56684 7.41206 9.41056 7.25577C9.25428 7.09949 9.16648 6.88753 9.16648 6.66652H7.49981C7.50195 7.18174 7.66322 7.68371 7.96155 8.10377C8.25989 8.52383 8.68072 8.84145 9.16648 9.01319V14.9999H8.06482C7.68897 13.7575 6.98408 12.64 6.02481 11.7657C5.3411 11.1331 4.81981 10.3451 4.50504 9.46838C4.19028 8.59168 4.0913 7.65206 4.21648 6.72902Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_9529_128565'>
        <rect width='20' height='20' fill='currentColor' />
      </clipPath>
    </defs>
  </svg>,
  'SuggestionIcon'
);
export default SuggestionButton;
