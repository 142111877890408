/* eslint-disable import/prefer-default-export */
/**
 * To be used for results page which has circular tags for each source
 */
import React from 'react';

import { GridRenderCellParams } from '@mui/x-data-grid-pro';

// other components
import CircularAvatar from '../../../pages/ResultsPage/components/CircularAvatar';

// constants
import { arrayAvatarData } from './utils';

// types

const DatagridArrayAvatars = (params: GridRenderCellParams) => {
  const { row, field, colDef } = params;
  // @ts-ignore
  const [rowData, avatarData] = arrayAvatarData(field, row[field], colDef?.source);

  return <CircularAvatar data={rowData} avatarList={avatarData} tooltipWithoutPrefix />;
};

export default DatagridArrayAvatars;
