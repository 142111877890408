import { createSvgIcon } from '@mui/material';

const DocumentSimpleIcon = createSvgIcon(
  <svg
    width='14'
    height='15'
    viewBox='0 0 14 15'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_661_11321)'>
      <path d='M11.6372 3.72959L9.60491 1.69609C9.22651 1.31584 8.7765 1.01437 8.28087 0.809109C7.78525 0.60385 7.25385 0.498877 6.71741 0.500258H4.08366C3.3104 0.501184 2.56907 0.808772 2.02229 1.35555C1.47551 1.90233 1.16792 2.64366 1.16699 3.41692V11.5836C1.16792 12.3569 1.47551 13.0982 2.02229 13.645C2.56907 14.1917 3.3104 14.4993 4.08366 14.5003H9.91699C10.6903 14.4993 11.4316 14.1917 11.9784 13.645C12.5251 13.0982 12.8327 12.3569 12.8337 11.5836V6.61651C12.8351 6.08009 12.7301 5.54871 12.5247 5.05316C12.3194 4.55761 12.0177 4.10773 11.6372 3.72959ZM10.8124 4.55442C10.9905 4.73768 11.1437 4.94362 11.268 5.16692H8.75033C8.59562 5.16692 8.44724 5.10547 8.33785 4.99607C8.22845 4.88667 8.16699 4.7383 8.16699 4.58359V2.06592C8.39037 2.19013 8.59649 2.3431 8.78008 2.52092L10.8124 4.55442ZM11.667 11.5836C11.667 12.0477 11.4826 12.4928 11.1544 12.821C10.8262 13.1492 10.3811 13.3336 9.91699 13.3336H4.08366C3.61953 13.3336 3.17441 13.1492 2.84622 12.821C2.51803 12.4928 2.33366 12.0477 2.33366 11.5836V3.41692C2.33366 2.9528 2.51803 2.50768 2.84622 2.17949C3.17441 1.8513 3.61953 1.66692 4.08366 1.66692H6.71741C6.81308 1.66692 6.90583 1.68559 7.00033 1.69434V4.58359C7.00033 5.04772 7.1847 5.49284 7.51289 5.82103C7.84108 6.14922 8.2862 6.33359 8.75033 6.33359H11.6396C11.6483 6.42809 11.667 6.52026 11.667 6.61651V11.5836Z' />
    </g>
    <defs>
      <clipPath id='clip0_661_11321'>
        <rect width='14' height='14' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>,
  'DocumentSimple'
);

export default DocumentSimpleIcon;
