import { useCallback, useState } from 'react';

// api
import {
  getMeetingsList,
  getMeetingsListByUnknownOffset,
  getStats
} from '../../../api/pages/Adcomm';
import getFilterOptions from '../utils/common';

// util
import removeZeroValueFromPieChartData from '../../../utils/removeZeroValueFromPieChartData';

const useAdcommData = () => {
  const [isLoading, setLoading]: [boolean, Function] = useState(false);
  const [errorMessage, setErrorMessage]: [string, Function] = useState('');
  const [statsData, setstatsData]: [any, Function] = useState({});
  const [meetingData, setMeetingData]: [Array<{ [key: string]: string | any }>, Function] =
    useState([]);
  const [totalPages, setTotalPages]: [number, Function] = useState(0);
  const [adcommFilters, setAdcommFilters]: [any, Function] = useState([]);
  const [isFilterApplied, setIsFilterApplied]: [boolean, Function] = useState(false);
  const [meetingIds, setMeetingIds] = useState<Array<string>>([]);
  const [page, setPage] = useState<number>(-1);

  const getAdcommData = useCallback(
    async (
      limit: number,
      offset: number,
      tab: string,
      appliedFilters: any,
      memberId?: string,
      member?: boolean,
      meetingId?: string | null
    ) => {
      setLoading(true);

      if (Object.keys(appliedFilters).length === 0) {
        setIsFilterApplied(false);
      } else {
        setIsFilterApplied(true);
      }
      if (member) {
        // eslint-disable-next-line no-param-reassign
        appliedFilters.member_id = memberId;
      }
      try {
        const res = await getStats({ filters: appliedFilters }, tab);
        if (res?.status === 200) {
          const stats = res.data.body.result;
          const data = removeZeroValueFromPieChartData(stats, ['nda_bla', 'post_meeting_approval']);
          setstatsData(data);
        }

        let meetingListRequest;
        if (page < 0 && meetingId) {
          meetingListRequest = getMeetingsListByUnknownOffset(
            { filters: appliedFilters },
            limit,
            meetingId,
            tab
          );
        } else {
          meetingListRequest = getMeetingsList({ filters: appliedFilters }, limit, offset, tab);
        }
        const response = await meetingListRequest;
        if (response?.status === 200) {
          setMeetingData(response.data.body.result.meeting_list);
          setTotalPages(
            Math.ceil(response.data.body.total_rows / limit)
              ? Math.ceil(response.data.body.total_rows / limit)
              : 0
          );
          setPage(response?.data?.body?.page_number || page);
          const newfilters = getFilterOptions(response.data.body.result.filters);

          setMeetingIds(response.data?.body?.result?.filters?.meeting_id || []);
          if (response.data?.body?.result?.meeting_list?.length) {
            setAdcommFilters(newfilters);
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        setErrorMessage('No Data Found');
      } finally {
        setLoading(false);
      }
    },
    [page]
  );

  return {
    isLoading,
    statsData,
    meetingData,
    errorMessage,
    totalPages,
    adcommFilters,
    isFilterApplied,
    meetingIds,
    getAdcommData,
    page,
    setPage
  };
};

export default useAdcommData;
