import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  container: {
    width: '100%',
    height: '65vh',
    backgroundColor: theme.palette.backgrounds.main,
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    paddingBottom: theme.spacing(1),
    overflow: 'auto'
  },
  close: {
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: theme.palette.cards.background
  },
  tree: {
    float: 'left',
    width: '50%'
  },
  treeView: {
    width: '100%'
  },
  synonyms: {
    maxHeight: '19vh',
    overflow: 'auto',
    scrollbarColor: `${theme.palette.common.green} #C2D2E4`,
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '8px'
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: '10px'
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.common.green,
      borderRadius: '10px'
    },

    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.common.green
    }
  },
  node_count: {
    width: 'fit-content',
    paddingTop: '3px',
    paddingLeft: '10px',
    paddingRight: '2px',
    paddingBottom: '3px',
    display: 'inline-flex',
    borderRadius: theme.spacing(1.5),
    margin: theme.spacing(0.5),
    fontSize: '1.5vh',
    fontFamily: theme.typography.fontFamily,
    boxShadow: '0px 5px 10px #00000008',
    background: theme.palette.white,
    border: '1px solid #707070'
  },
  node: {
    width: 'fit-content',
    padding: '3px 10px',
    display: 'inline-flex',
    borderRadius: theme.spacing(1.5),
    margin: theme.spacing(0.5),
    fontSize: '1.5vh',
    fontFamily: theme.typography.fontFamily,
    boxShadow: '0px 5px 10px #00000008',
    background: theme.palette.white,
    border: '1px solid #707070'
  },
  count: {
    width: 'fit-content',
    padding: '5px',
    paddingLeft: '15px',
    paddingRight: '10px',
    display: 'inline-flex',
    borderRadius: theme.spacing(1.5),
    margin: theme.spacing(0.5)
  },
  synonymsContainer: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(1)
  },
  nodata: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  subHeading: {
    fontSize: '14px'
  },
  note: {
    fontSize: '14px'
  }
}));
