const styles = {
  menuItem: {
    padding: '8px 24px !important'
  },
  listItemIcon: {
    minWidth: '16px !important'
  },
  listItemText: {
    fontFamily: 'Mulish',
    fontSize: '12px',
    fontWeight: '400',
    ml: '8px',
    color: 'gray.800'
  },
  MenuExpandIcon: { fontSize: '14px', color: 'gray.700' },
  subMenu: {
    pointerEvents: 'none',
    '& .MuiList-root': {
      pointerEvents: 'auto',
      padding: '8px 0 !important'
    },
    '& .MuiPaper-root': { padding: '10px 0px !important' }
  }
};

export default styles;
