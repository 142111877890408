import { createSvgIcon } from '@mui/material';

const DeleteIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='16' viewBox='0 0 14 16' fill='none'>
    <path
      d='M13.6668 2.66667H10.3335V1.33333C10.3335 0.979711 10.193 0.640573 9.94297 0.390524C9.69292 0.140476 9.35379 0 9.00016 0L5.00016 0C4.64654 0 4.3074 0.140476 4.05735 0.390524C3.80731 0.640573 3.66683 0.979711 3.66683 1.33333V2.66667H0.333496V4H1.66683V14C1.66683 14.5304 1.87754 15.0391 2.25262 15.4142C2.62769 15.7893 3.1364 16 3.66683 16H10.3335C10.8639 16 11.3726 15.7893 11.7477 15.4142C12.1228 15.0391 12.3335 14.5304 12.3335 14V4H13.6668V2.66667ZM5.00016 1.33333H9.00016V2.66667H5.00016V1.33333ZM11.0002 14C11.0002 14.1768 10.9299 14.3464 10.8049 14.4714C10.6799 14.5964 10.5103 14.6667 10.3335 14.6667H3.66683C3.49002 14.6667 3.32045 14.5964 3.19543 14.4714C3.0704 14.3464 3.00016 14.1768 3.00016 14V4H11.0002V14Z'
      fill='currentColor'
    />
    <path d='M6.33333 6.66626H5V11.9996H6.33333V6.66626Z' fill='currentColor' />
    <path d='M9.00033 6.66626H7.66699V11.9996H9.00033V6.66626Z' fill='currentColor' />
  </svg>,
  'DeleteIcon'
);
export default DeleteIcon;
