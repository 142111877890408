import { createSvgIcon } from '@mui/material';

const GridIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
    <g clipPath='url(#clip0_4081_49003)'>
      <path
        d='M15.75 0H2.25C1.65326 0 1.08097 0.237053 0.65901 0.65901C0.237053 1.08097 0 1.65326 0 2.25L0 18H18V2.25C18 1.65326 17.7629 1.08097 17.341 0.65901C16.919 0.237053 16.3467 0 15.75 0V0ZM16.5 2.25V4.5H13.5V1.5H15.75C15.9489 1.5 16.1397 1.57902 16.2803 1.71967C16.421 1.86032 16.5 2.05109 16.5 2.25ZM9.75 4.5V1.5H12V4.5H9.75ZM12 6V8.25H9.75V6H12ZM8.25 4.5H6V1.5H8.25V4.5ZM8.25 6V8.25H6V6H8.25ZM4.5 8.25H1.5V6H4.5V8.25ZM4.5 9.75V12H1.5V9.75H4.5ZM6 9.75H8.25V12H6V9.75ZM8.25 13.5V16.5H6V13.5H8.25ZM9.75 13.5H12V16.5H9.75V13.5ZM9.75 12V9.75H12V12H9.75ZM13.5 9.75H16.5V12H13.5V9.75ZM13.5 8.25V6H16.5V8.25H13.5ZM2.25 1.5H4.5V4.5H1.5V2.25C1.5 2.05109 1.57902 1.86032 1.71967 1.71967C1.86032 1.57902 2.05109 1.5 2.25 1.5ZM1.5 13.5H4.5V16.5H1.5V13.5ZM13.5 16.5V13.5H16.5V16.5H13.5Z'
        fill='#616161'
      />
    </g>
    <defs>
      <clipPath id='clip0_4081_49003'>
        <rect width='18' height='18' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'Grid'
);

export default GridIcon;
