import React, { memo, useContext, useMemo, useRef, useState } from 'react';

import { Badge, Drawer, Tooltip } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { SynonymsIcon } from '../../../assets/svgs/Icons';
import ResultsStore from '../../../store/SearchResults';
import reviewMenuStyles from '../../SearchResults/styles/ReviewMenu.styles';
import SynonymsList from '../../../components/Synonyms';
import { cnfToAdvancedSearch } from '../../../components/Header/utils/advanceSearchHelpers';
import ATCTree from '../../../components/Synonyms/ATCTree';

const Synonyms = () => {
  const { resultsState } = useContext(ResultsStore) as any;
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const drawerContentRef = useRef<{ onClose: () => void }>({
    onClose: () => {}
  });
  const onClick = () => {
    setOpenDrawer(true);
  };
  const handleDrawerClose = () => {
    // This will check the conditions of the component before closing it.
    drawerContentRef.current?.onClose();
  };
  const textToDisplay = useMemo(() => {
    /**
     * Determines the text to be displayed on the button based on the search type and categories present in the CNF query.
     *
     * - If the search type is not 'advanced', the button text defaults to 'Synonyms'.
     * - If the search type is 'advanced', the CNF query is decoded to get the categories.
     *  - If there are both 'atc_classification' and other categories, the button text is set to 'Synonyms & ATC Classification'.
     *  - If there is only 'atc_classification', the button text is set to 'ATC Classification'.
     *
     * @returns {string} The text to be displayed on the button.
     */
    let text = 'Synonyms';
    if (resultsState.decryptedPayload.searchType === 'advanced') {
      // Decode the CNF query from the decrypted payload and convert it to list of json objects which gives the key and its value.
      const decodedCNF = cnfToAdvancedSearch(resultsState.decryptedPayload.query);

      // To decide what should be button text, we have to get all of its categories.
      const categories = decodedCNF.map((item: { category: string }) => item.category);

      // Check if the CNF has the categories other than ATC Classification.
      const otherThanATCs =
        categories.filter((item: string) => item !== 'atc_classification').length > 0;

      // Check if the CNF has 'atc_classification' category
      const atcCategories =
        categories.filter((item: string) => item === 'atc_classification').length > 0;

      // If we have ATC Classification with other categories, button text should be "Synonyms & ATC Classification" as out synonyms popup would show both.
      if (otherThanATCs && atcCategories) {
        text = 'Synonyms & ATC Classification';
      } else if (atcCategories) {
        // If we the category selected in advanced search is only ATC Classification, then show the button text `ATC Classification`
        text = 'ATC Classification';
      }
    } else if (resultsState.entities?.category_name === 'atc_classification') {
      text = 'ATC Classification';
    }
    return text;
  }, [resultsState.entities, resultsState?.decryptedPayload]);
  // do not show the button if the source is 505b2
  if (resultsState?.decryptedPayload?.source?.us?.includes('505b2')) return null;
  return (
    <>
      <Box justifySelf='start' display='flex' alignItems='center' ml={-1.5}>
        <Tooltip title={`Filter the search results through ${textToDisplay}`}>
          <IconButton onClick={onClick}>
            <Badge
              variant='dot'
              invisible={
                !(
                  resultsState?.filters?.mesh_tree_numbers?.length > 0 ||
                  resultsState?.filters?.atc_code?.length > 0
                )
              }
              sx={reviewMenuStyles.badgeDot2}
              color='secondary'>
              <SynonymsIcon sx={reviewMenuStyles.icon} />
            </Badge>
          </IconButton>
        </Tooltip>
      </Box>

      <Drawer
        open={openDrawer}
        anchor='right'
        sx={reviewMenuStyles.drawer}
        onClose={handleDrawerClose}>
        {textToDisplay.toLowerCase().includes('synonyms') ? (
          <SynonymsList
            onClose={() => setOpenDrawer(false)}
            drawerRef={drawerContentRef}
            title={textToDisplay}
          />
        ) : (
          <ATCTree onClose={() => setOpenDrawer(false)} drawerRef={drawerContentRef} />
        )}
      </Drawer>
    </>
  );
};
export default memo(Synonyms);
