import { lazy, Suspense } from 'react';
import SuspenseCircularLoader from '../../../helpers/SuspenseCircularLoader';

const ResponsiveRadialBar = lazy(() =>
  import('@nivo/radial-bar').then(module => ({ default: module.ResponsiveRadialBar }))
);

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const MyResponsiveRadialBar = ({ data, keys, index /* see data tab */ }) => (
  <Suspense fallback={<SuspenseCircularLoader />}>
    <ResponsiveRadialBar
      data={data}
      valueFormat='>-.2f'
      keys={keys}
      indexBy={index}
      endAngle={360}
      maxValue={100}
      radialAxisStart={null}
      enableTracks={true}
      enableRadialGrid={false}
      enableCircularGrid={false}
      padding={0.4}
      colors={['#B1BBE4', '#29ABE2', '#AB54DB', '#6868FA', '#2B43A6']}
      colorBy='index'
      cornerRadius={2}
      motionConfig='molasses'
      margin={{ top: 40, right: 70, bottom: 40, left: 40 }}
      // radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
      circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
    />
  </Suspense>
);

export default MyResponsiveRadialBar;
