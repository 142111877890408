export default {
  SET_STATS: 'SET_STATS',
  SET_SHOW_LABEL_COMPARE: 'SET_SHOWLABEL_COMPARE',
  SET_LABEL_COMPARE_LIST: 'SET_LABEL_COMPARE_LIST',
  SET_FILTERS: 'SET_FILTERS',

  SET_TABLE_DATA: 'SET_TABLE_DATA',
  SET_DETERMINATION_GRAPH: 'SET_DETERMINATION_GRAPH',
  SET_FORMULATIONS_GRAPH: 'SET_FORMULATIONS_GRAPH',
  SET_ROUTES_GRAPH: 'SET_ROUTES_GRAPH',
  SET_ERRORS: 'SET_ERRORS',
  SET_DONUT_CHARTS: 'SET_DONUT_CHARTS',
  RESET_FILTERS: 'RESET_FILTERS',
  SET_LOADING: 'SET_LOADING',
  SET_AVAILABLE_FILTERS: 'SET_AVAILABLE_FILTERS',
  SET_IS_FILTERS_APPLIED: 'SET_IS_FILTERS_APPLIED',
  SET_TOTAL_COUNT: 'SET_TOTAL_COUNT',
  SET_SORT: 'SET_SORT',
  SET_ASK_RIA_FILTERS: 'SET_ASK_RIA_FILTERS',
  SET_DATA_GRID_FILTERS: 'SET_DATA_GRID_FILTERS'
};
