export const ARIA_SOURCE_MAPPING: { [key: string]: string } = {
  us: 'us',
  eu: 'eu',
  'us-devices': 'us-devices',
  ct: 'ct',
  ca: 'ca',
  hpra: 'hpra',
  hma: 'hma',
  jp: 'jp',
};

export const ARIA_MODULE_MAPPING: { [key: string]: string } = {
  biologics: 'bla',
  '505b2': '505b2',
  core: 'core',
  guidance: 'guidance',
  'ema-guidance': 'EMAGuidance',
  'canada-guidance': 'CAGuidance',
  adcomm: 'adcomm'
};

export const STATUS: { [key: string]: Array<string> } = {
  YELLOW: ['(Refused)', '(Tentative Approval)'],
  RED: ['(Discontinued)']
};

export const SOURCE_DOCUMENT_TYPE = {
  us: 'SBA',
  eu: 'EPAR',
  ca: 'CA - HPFBs',
  pips: 'PIPs',
  guidances: 'Guidance',
  'ema-guidance': 'EMA Guidance',
  'canada-guidance': 'CA Guidance',
  hta: 'HTA',
  adcom: 'AdComm',
  clinicaltrials: 'Clinical trials',
  ct: 'Clinical trials',
  'fda-written-request': 'FDA Written Requests',
  'us-devices': 'CDRH Approval Summary',
  chmp: 'CHMP',
  hpra: 'HPRA',
  hma: 'HMA',
  letters: 'FDA Warnings and Untitled Letters',
  jp: 'PMDA'
};

export const CT_OVERALL_STATUS_COLOR_GUIDE: {
  [key: string]: 'red' | 'blue' | 'orange' | 'green' | 'gray' | 'pink';
} = {
  recruiting: 'orange',
  completed: 'green',
  terminated: 'red',
  approved_for_marketing: 'pink',
  other: 'gray'
};

export const US_DEVICES_APPLICATION_TYPE_COLOR: {
  [key: string]: 'red' | 'blue' | 'orange' | 'pink';
} = {
  pma: 'blue',
  pmn: 'red',
  eua: 'orange',
  denovo: 'pink'
};

export default STATUS;
