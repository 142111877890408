import React, { useContext, useEffect } from 'react';

// mui v5
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import themev5 from '../../themev5';

// hoc
import CanadaConsultationTabs from './hoc/Tabs';

// store
import Store from '../../store';
import Actions from '../../store/actions';

// Provider
// import { CanadaGuidanceStateProvider } from '../../store/CanadaGuidance';
import { CanadaConsultationStateProvider } from '../../store/CanadaConsultation';

const CanadaConsultation = () => {
  const { dispatch } = useContext(Store) as any;

  useEffect(() => {
    dispatch({ type: Actions.SET_MODULE, value: 'canada-consultation' });
  }, []);

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <CanadaConsultationStateProvider>
        <CanadaConsultationTabs />
      </CanadaConsultationStateProvider>
    </ThemeProvider>
  );
};

export default React.memo(CanadaConsultation);
