/* eslint-disable react/prop-types */
import React from 'react';
import { useParams } from 'react-router-dom';

// material ui imports
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// styles
import ApplicationContentStyles from '../styles/TimelineTable.styles';
// other components
import CustomTimeline from '../../Timeline/CustomTimeline';

const ApprovalHistoryReport = ({ data }) => {
  const classes = ApplicationContentStyles();
  const { source } = useParams();

  return (
    <Box display='flex' flexDirection='column' className={classes.cardDetails}>
      <Paper elevation={0} className={classes.paper}>
        <Typography variant='h3'>
          Approval History{' '}
          {/*   <span className={classes.highlightText}>
              {data.source}/{data.category}/{data.searchTerm} - {data.tradeName}
  </span> */}
        </Typography>
        <CustomTimeline
          filename='report'
          isLoading={false}
          columns={data?.columns}
          timeline={data.timeline}
          triggeredBy={data?.triggeredBy}
          showOptions={false}
          source={data?.applicationType || source || 'us'}
        />
      </Paper>
    </Box>
  );
};

export default ApprovalHistoryReport;
