import { DRUG_DEVELOPMENT_TOOLS_FILTERS, DATA_GRID_LIST_VIEW_MAPPER } from '../constants';

const getFilters = (apiData: any) => {
  const filters = DRUG_DEVELOPMENT_TOOLS_FILTERS.map((currentFilter: { [key: string]: any }) => ({
    ...currentFilter,
    options: [...(apiData?.[currentFilter?.value] || [])]
  }));

  return filters;
};

export default getFilters;

export const valueMapperList = (obj: any) => {
  const finalArray: any = [];

  if (!obj) {
    return finalArray;
  }

  Object.keys(obj).forEach((key: any) => {
    const value = obj[key];

    const headerText = DATA_GRID_LIST_VIEW_MAPPER[key];
    if (headerText) {
      finalArray.push(`${headerText} : ${value}`);
    }
  });

  return finalArray;
};
