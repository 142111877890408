import React from 'react';
import styles from '../Selects/VivproDropdown.styles';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const VivproSelect = React.memo(
  ({ label, value, options, handleChange, icon, disabled = false, additionalStyles = {} }) => {
    return (
      <FormControl variant='standard' sx={styles.formControl}>
        {label && <InputLabel sx={styles.inputLabel}>{label}</InputLabel>}

        <Select
          sx={{ ...styles.select, ...additionalStyles }}
          disabled={disabled}
          disableUnderline={true}
          label={label}
          value={value}
          onChange={handleChange}
          IconComponent={icon}
          MenuProps={{ disableScrollLock: true }}>
          {options.map(option => {
            return (
              <MenuItem key={String(option.value)} sx={styles.menuText} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }
);

export { VivproSelect };
