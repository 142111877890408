import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grow,
  Tooltip
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Download } from '@mui/icons-material';
import React, { lazy, Suspense, useState } from 'react';
import SuspenseCircularLoader from '../../../helpers/SuspenseCircularLoader';

const RearrangeReports = lazy(() => import('./RearrangeReports'));

const DownloadReport = ({
  showDownloadReport,
  reportCount,
  reportData,
  processingReport,
  downloadReport,
  clearReport,
  fetchReportTitle,
  loadingReportTitle
}: any) => {
  const [openClearReportDialog, setOpenClearReportDialog] = useState<boolean>(false);
  const [openReportView, setOpenReportView] = React.useState<boolean>(false);
  const handleClick = async () => {
    setOpenReportView(true);
    await fetchReportTitle();
  };
  const handleClose = () => {
    if (processingReport) return;
    setOpenReportView(false);
  };
  return (
    <>
      <Suspense fallback={<SuspenseCircularLoader />}>
        <RearrangeReports
          reportData={reportData}
          openReportView={openReportView}
          handleClose={handleClose}
          downloadReport={downloadReport}
          processingReport={processingReport}
          setOpenClearReportDialog={setOpenClearReportDialog}
          loadingReportTitle={loadingReportTitle}
        />
      </Suspense>
      <Grow in={showDownloadReport} unmountOnExit>
        <Box>
          <Tooltip title='Download report'>
            <Badge
              badgeContent={reportCount}
              color='primary'
              sx={{
                '& .MuiBadge-badge': {
                  color: 'white.main'
                }
              }}
              max={30}>
              <LoadingButton
                loading={processingReport || loadingReportTitle}
                variant='contained'
                onClick={handleClick}
                sx={{
                  ml: 1,
                  visibility: showDownloadReport ? 'visible' : 'hidden',
                  backgroundColor: 'white.main',
                  borderRadius: 2,
                  '&:hover': {
                    backgroundColor: 'white.main',
                    color: 'primary.main'
                  },
                  color: 'primary.main',
                  textTransform: 'none',
                  width: 40,
                  minWidth: 40
                }}>
                <Download />
              </LoadingButton>
            </Badge>
          </Tooltip>
        </Box>
      </Grow>

      <Dialog
        open={openClearReportDialog}
        onClose={() => setOpenClearReportDialog(false)}
        maxWidth='xs'
        fullWidth>
        <DialogTitle>Clear report?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to clear the report? <br />
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenClearReportDialog(false)}
            sx={{ textTransform: 'capitalize' }}>
            Cancel
          </Button>
          <Button
            sx={{ color: 'white.main', textTransform: 'capitalize' }}
            variant='contained'
            onClick={async () => {
              await clearReport();
              setOpenClearReportDialog(false);
            }}
            autoFocus>
            Clear
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default React.memo(DownloadReport);
