import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';

// material ui
import { Box, Popover } from '@mui/material';

import PMRSurveillance from '../../../store/PMRSurveillance';

import NewFilters from '../../NewFilters';

// hooks
import usePMRSurveillanceData from '../hook/usePMRSurveillanceData';

// components
import PMRSurveillanceDetails from '../components/Details';
import ReportWidgets from '../../Report/Widgets';
import BottomDrawer from '../../CustomComponents/BottomDrawer';
import SubHeader from '../../SubHeader';

// styles
import { PMR_SURVEILLANCE_FILTERS } from '../const';
import { Stat } from '../../../pages/SearchResults/types';
import { PAGINATION_DEFAULT_OPTIONS } from '../../PMRImpact/const';
import VISUALIZE_CHART_MAPPING from '../../Insights/const';
import Insights from '../../Insights/Insights';

const PMRSurveillanceTabs = () => {
  const { pmrState } = useContext(PMRSurveillance) as any;
  const {
    isLoading,
    pmrSurFilters,
    sortFields,
    getPMRSurData,
    getDatawithDataGridFilter,
    dataGridFilter
  } = usePMRSurveillanceData();
  const [sort, setSort] = useState([...sortFields]);
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);
  const [pmrStats, setPmrStats] = useState<Stat[]>([]);
  const [pagination, setPagination] = useState({
    pageSize: PAGINATION_DEFAULT_OPTIONS.limit,
    page: PAGINATION_DEFAULT_OPTIONS.offset
  });
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);

  const [reportChoicesOpenAnchorElement, setReportChoiceAnchorElement] =
    useState<HTMLElement | null>(null);

  useEffect(() => {
    getPMRSurData([], pagination.pageSize, pagination.page, []);
  }, []);

  useEffect(() => {
    if (pmrState?.pmrStats) {
      const newStats: Stat[] = [];
      newStats.push({ title: 'Total', value: pmrState?.pmrStats?.total_count });
      newStats.push({
        title: 'Issued in Last 12 Months',
        value: pmrState?.pmrStats?.issued_last_twelve_month
      });
      newStats.push({
        title: 'Expected Completion in 12 Months',
        value: pmrState?.pmrStats?.expected_in_twelve_months
      });

      setPmrStats(newStats);
    }
  }, [pmrSurFilters]);

  const handleFilterApply = useCallback((currentfilters: any) => {
    setIsFilterUpdated(true);
    setPagination({ page: PAGINATION_DEFAULT_OPTIONS.offset, pageSize: pagination.pageSize });
    getPMRSurData(
      currentfilters,
      pagination.pageSize,
      PAGINATION_DEFAULT_OPTIONS.offset,
      sortFields
    );
  }, []);

  const getFiltersLabelList = () => {
    return PMR_SURVEILLANCE_FILTERS.reduce((acc: any, elem: any) => {
      acc[elem.value] = elem.label;
      return acc;
    }, {});
  };

  const handleVisualizeOpen = () => {
    setIsVisualizeOpen(true);
  };

  const closeDrawer = () => {
    setIsVisualizeOpen(false);
  };

  const getInsightStatsList = useMemo(() => {
    if (!pmrState?.pmrStats) {
      return [];
    }
    return [
      {
        text: 'Total',
        count: pmrState?.pmrStats?.total_count,
        toolTipValue: ''
      },
      {
        text: 'Issued in Last 12 Months',
        count: pmrState?.pmrStats?.issued_last_twelve_month,
        toolTipValue: ''
      },
      {
        text: 'Expected Completion in 12 Months',
        count: pmrState?.pmrStats?.expected_in_twelve_months,
        toolTipValue: ''
      }
    ];
  }, [pmrState?.pmrStats]);

  const getLayer1Charts = useMemo(() => {
    const pmrGraph = VISUALIZE_CHART_MAPPING['pmr-surveillance']?.layer1;
    if (!pmrGraph || !pmrState.pmrStats?.category_graph) {
      return pmrGraph;
    }
    const updatedGraph = pmrGraph.map((graph: any) => ({
      ...graph,
      data: pmrState.pmrStats?.category_graph
    }));
    return updatedGraph;
  }, [pmrState.pmrStats?.category_graph]);

  const getLayer2Charts = useMemo(() => {
    const pmrGraph = VISUALIZE_CHART_MAPPING['pmr-surveillance']?.layer2;
    if (!pmrGraph) {
      return pmrGraph;
    }
    pmrGraph.forEach(graph => {
      if (graph?.id === 'subpart_desc' && pmrState?.pmrStats?.subpart_desc_graph) {
        // eslint-disable-next-line no-param-reassign
        graph.data = pmrState?.pmrStats?.subpart_desc_graph;
      }
      if (graph?.id === 'status' && pmrState?.pmrStats?.cmt_status_graph) {
        // eslint-disable-next-line no-param-reassign
        graph.data = pmrState?.pmrStats?.cmt_status_graph;
      }
    });
    return pmrGraph;
  }, [pmrState]);

  return (
    <>
      <SubHeader
        title='FDA PMR/C Surveillance'
        subTitle='Surveillance for FDA Postmarketing Requirements and Commitments'
        stats={pmrStats}
        handleVisualizeOpen={handleVisualizeOpen}
      />

      <Box>
        {!isLoading && (
          <Popover
            open={!!reportChoicesOpenAnchorElement}
            anchorEl={reportChoicesOpenAnchorElement}
            onClose={() => setReportChoiceAnchorElement(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            sx={{
              '& .MuiPopover-paper': {
                borderRadius: '16px !important',
                backgroundColor: 'none'
              }
            }}>
            <Box>
              <ReportWidgets
                widgets={['pie-widget', 'bar-widget', 'table-widget']}
                title=''
                dataSource={{
                  source: 'pmr',
                  moduleName: 'surveillance',
                  disableSelectionSource: true,
                  filters: pmrState.availableFilters,
                  filterLabelList: getFiltersLabelList()
                }}
                pathMetaData={{
                  module: 'surveillance',
                  href: window.location.href
                }}
                setReportWidgetClose={setReportChoiceAnchorElement}
              />
            </Box>
          </Popover>
        )}
        <PMRSurveillanceDetails
          pagination={pagination}
          setPagination={setPagination}
          sort={sort}
          setSort={setSort}
          sortFields={sortFields}
          getPMRSurData={getPMRSurData}
          isFilterUpdated={isFilterUpdated}
          setIsFilterUpdated={setIsFilterUpdated}
          getDatawithDataGridFilter={getDatawithDataGridFilter}
          dataGridFilter={dataGridFilter}
        />
      </Box>

      <NewFilters
        data={pmrSurFilters}
        applyFilters={handleFilterApply}
        isLoading={isLoading}
        selectedFilters={pmrState.availableFilters}
      />

      <BottomDrawer
        isDrawerOpen={isVisualizeOpen}
        closeDrawer={closeDrawer}
        handleReportChoiceAnchorElement={setReportChoiceAnchorElement}>
        <Insights
          source='pmr'
          searchType='surveillance'
          appliedFilter={pmrState?.availableFilters}
          statsList={getInsightStatsList}
          layer1Component={getLayer1Charts}
          layer2Component={getLayer2Charts}
          isLoading={isLoading}
        />
      </BottomDrawer>
    </>
  );
};

export default memo(PMRSurveillanceTabs);
