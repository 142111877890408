//store
import React from 'react';

const tickInsideCircle = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='17'
      // viewBox="0 0 17 17"
      viewBox='0 0 17 17'>
      <defs>
        <style>
          {
            '\
      .tick1 {\
        fill: #cdcdcd;\
      }\
      '
          }
        </style>
      </defs>
      <path
        className='tick1'
        d='M5.015,6.885l-1.19,1.19L7.65,11.9l8.5-8.5L14.96,2.21,7.65,9.52ZM15.3,8.5A6.8,6.8,0,1,1,8.5,1.7a6.629,6.629,0,0,1,1.87.255L11.73.6A10.346,10.346,0,0,0,8.5,0,8.5,8.5,0,1,0,17,8.5Z'
      />
    </svg>
  );
};

export default tickInsideCircle;
