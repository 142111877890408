import React, { useCallback, useEffect, useRef, useState } from 'react';
import { groupBy, isEmpty } from 'lodash';
import { Box, Chip, Stack } from '@mui/material';
import { format, isToday, isYesterday, parse } from 'date-fns';
import { NotificationListProps, ParentNotification } from './NotificationInterface';
import { getUserNotifications } from '../../../api/modules/userNotification';
import NotificationsItem from './NotificationsItem';
import NoNotification from './NoNotification';
import NotificationLoading from './NotificationLoading';
import notificationsStyles from './Notifications.styles';

const NotificationsList = ({
  notificationData,
  setNotificationData,
  channelType
}: NotificationListProps) => {
  const [loading, setLoading] = useState(false);
  const parentDivRef = useRef<HTMLDivElement>(null);
  const pageEndRef = useRef<HTMLDivElement>(null);

  const fetchNotifications = useCallback(async () => {
    if (!notificationData.hasMore || loading) return;
    setLoading(true);
    const response = await getUserNotifications(channelType, notificationData.page);
    if (response?.status === 200) {
      const results = response?.data?.body?.results as ParentNotification[];
      const hasMore = response?.data?.body?.next_page as boolean;
      if (results && results.length > 0) {
        setNotificationData(prevState => ({
          ...prevState,
          results: [...prevState.results, ...results],
          page: prevState.page + 1,
          hasMore
        }));
      }
    }
    setLoading(false);
  }, [channelType, notificationData.hasMore, notificationData.page, setNotificationData, loading]);

  // Function to determine the appropriate label for the date
  const getLabelForDate = (dateString: string) => {
    const date = parse(dateString, 'yyyy-MM-dd', new Date());
    if (isToday(date)) {
      return 'Today';
    }
    if (isYesterday(date)) {
      return 'Yesterday';
    }
    return format(date, 'MMMM dd, yyyy'); // or any other desired format
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    };
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        fetchNotifications();
      }
    }, options);

    const container = pageEndRef.current;
    if (!container) {
      return;
    }
    observer.observe(container);

    // eslint-disable-next-line consistent-return
    return () => {
      observer.unobserve(container);
    };
  }, [pageEndRef.current, fetchNotifications]);

  useEffect(() => {
    if (notificationData.results.length === 0) fetchNotifications();
  }, [channelType]);

  if (isEmpty(notificationData.results) && !loading) return <NoNotification />;
  return (
    <Box height='90%' overflow='auto' mb='10vh' ref={parentDivRef}>
      {Object?.entries(groupBy(notificationData?.results, 'date'))?.map(([date, results]) => (
        <Box key={date} mb='12px'>
          <Stack justifyContent='center' width='100%'>
            <Chip label={getLabelForDate(date)} sx={notificationsStyles.dateChip} />
          </Stack>
          {results.map(result => (
            <NotificationsItem
              notification={result}
              key={`${result?.source}-${result?.channel?.name}-${result?.date}-list-item-${result?.id}`}
            />
          ))}
        </Box>
      ))}
      <Box width='100%' height='5vh' mt='-10vh' zIndex={-1} ref={pageEndRef} />
      <Box sx={{ width: '100%', height: '10vh', mt: 2 }}>
        {loading ? (
          <Stack height={500}>
            <NotificationLoading />
            <NotificationLoading />
            <NotificationLoading />
          </Stack>
        ) : null}
      </Box>
    </Box>
  );
};
export default NotificationsList;
