import { Reg360Icon, SearchIcon, SubscribeIconFilled } from '../../../assets/svgs/Icons';
import { CHANNELS } from '../constants';
import NotificationFlag from './NotificationFlag';

const NotificationAvatar = ({
  notificationType,
  source
}: {
  notificationType: (typeof CHANNELS)[number];
  source: string | string[];
}) => {
  switch (notificationType) {
    case 'application':
      return <Reg360Icon id='application' />;
    case 'quick_search':
    case 'advanced_search':
    case 'advanced_search_filters':
      return <SearchIcon />;
    case 'data_source':
      return <NotificationFlag id='flag' source={source} />;
    default:
      return <SubscribeIconFilled />;
  }
};

export default NotificationAvatar;
