import React, { lazy, Suspense, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import SuspenseCircularLoader from '../../../helpers/SuspenseCircularLoader';
import { getFontSize } from './utils';
// external libs
const ResponsiveBar = lazy(() =>
  import('@nivo/bar').then(module => ({ default: module.ResponsiveBar }))
);

export const MeanLabels = ({ bars }) => (
  <g>
    {bars?.map(({ data, x, y, width, height }) =>
      data?.data?.mean ? (
        <text
          key={`${data.value}-${data.index}`}
          x={x + width / 2}
          y={y + height / 2}
          fill='white'
          textAnchor='middle'
          dominantBaseline='central'
          transform={`rotate(270, ${x + width / 2}, ${y + height / 2})`}
          fontSize={10}>
          Mean: {data.data.mean || '0'}
        </text>
      ) : null
    )}
  </g>
);
MeanLabels.propTypes = {
  bars: PropTypes.arrayOf([PropTypes.object])
};
MeanLabels.defaultProps = {
  bars: []
};

const CustomBarGraph = ({
  keys,
  index,
  data,
  margins,
  colors,
  paddings,
  borderRadius,
  labelTextColor,
  axisText,
  tickRotationValue,
  layout,
  groupMode,
  defs,
  fill,
  enableLabel,
  borderWidth,
  axisTextWeight
}) => {
  const [fontSize, setFontSize] = useState(50);
  useEffect(() => {
    try {
      setFontSize(getFontSize(data, index, tickRotationValue));
    } catch (err) {
      setFontSize(50);
    }
  }, [data]);

  const getMargin = useMemo(() => {
    const defaultHorizontalMargin = { top: 30, right: 50, bottom: 30, left: fontSize };
    const defaultVerticalMargin = { top: 30, right: 50, bottom: fontSize, left: 30 };

    // Check if layout is horizontal
    if (layout && layout === 'horizontal') {
      return Object.keys(margins).length > 0
        ? { ...margins, left: fontSize }
        : defaultHorizontalMargin;
    }
    return Object.keys(margins).length > 0
      ? { ...margins, bottom: fontSize }
      : defaultVerticalMargin;
  }, [layout, margins, fontSize]);

  return (
    <Suspense fallback={<SuspenseCircularLoader />}>
      <ResponsiveBar
        data={data && data}
        enableGridY
        gridYValues={[0]} // Enable X-axis grid
        enableGridX
        gridXValues={[0]} // Enable X-axis grid
        keys={keys}
        indexBy={index}
        margin={getMargin}
        padding={paddings || 0.1}
        innerPadding={3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        layout={layout !== null ? layout : 'vertical'}
        colors={colors}
        colorBy='index'
        groupMode={groupMode !== 'stacked' ? groupMode : 'grouped'}
        borderRadius={borderRadius || 2}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        borderWidth={borderWidth || 0}
        theme={{
          axis: {
            fontSize: 14,
            tickColor: '#000',
            ticks: {
              line: {
                stroke: '#555555'
              },
              text: {
                fill: axisText || '#1FBDCA',
                fontWeight: axisTextWeight,
                fontSize: 12,
                textTransform: 'uppercase'
              }
            }
          },
          tooltip: {
            container: {
              fontSize: 12,
              textTransform: 'capitalize'
            }
          },
          grid: {
            line: {
              stroke: '#EEE',
              strokeWidth: 1
            }
          }
        }}
        defs={defs}
        fill={fill}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: tickRotationValue !== null ? tickRotationValue : 90,
          legend: '',
          legendPosition: 'middle',
          legendOffset: 0
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendPosition: 'middle',
          legendOffset: 0
        }}
        enableLabel={enableLabel || false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={labelTextColor || ['#fff']}
        animate={false}
        layers={['grid', 'axes', 'bars', 'markers', 'legends', MeanLabels]}
      />
    </Suspense>
  );
};

CustomBarGraph.propTypes = {
  keys: PropTypes.arrayOf(PropTypes.string),
  index: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ),
  margins: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  colors: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  paddings: PropTypes.number,
  borderRadius: PropTypes.number,
  labelTextColor: PropTypes.string,
  axisText: PropTypes.string,
  tickRotationValue: PropTypes.number,
  layout: PropTypes.string,
  groupMode: PropTypes.string,
  defs: PropTypes.arrayOf(PropTypes.oneOfType[PropTypes.object]),
  fill: PropTypes.arrayOf(PropTypes.oneOfType[PropTypes.object]),
  enableLabel: PropTypes.bool,
  borderWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  axisTextWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

CustomBarGraph.defaultProps = {
  keys: [],
  index: '',
  data: [],
  margins: null,
  colors: ['#0E5A94', '#318AC8', '#008B8B', '#3AB09E', '#48E0A6', '#1C2D59', '#20A1C7'],
  paddings: 0,
  borderRadius: 2,
  labelTextColor: '#fff',
  axisText: '#1FBDCA',
  tickRotationValue: 90,
  layout: 'vertical',
  groupMode: 'stacked',
  defs: [],
  fill: [],
  enableLabel: false,
  borderWidth: 0,
  axisTextWeight: 'bold'
};

export default CustomBarGraph;
