// @ts-nocheck
import React, { useContext, useState } from 'react';

// muiv5
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';

// icons
import MoreVert from '@mui/icons-material/MoreVert';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';

// assets
import SortIcon from '../../../assets/svgs/CardsPage/SortIcon';

import HeaderStyles from '../styles/Headers.styles';

// context
import AriaStore from '../../../store/Aria';
import AriaActions from '../../../store/Aria/actions';
import AuthContext from '../../../store/Auth/AuthProvider';

import CollapsableList from './CollapsableList';

import { RESTRICT_DOWNLOADS_BY_ORGANIZATION } from '../../../const';

const ToolsOptionsMenu = () => {
  const styles = HeaderStyles();
  // aria context store
  const { ariaState, ariaDispatch } = useContext(AriaStore) as any;
  const { currentUser } = useContext(AuthContext);

  // state for menu items
  const [openMoreOptions, setOpenMoreOptions] = useState(false);
  const [openMoreOptionsAnchor, setOpenMoreOptionsAnchor] = useState(null);

  // state for download options
  const [downloadOption, setDownloadOption] = useState<string>();
  const [expandedOptionsOpen, setExpandedOptionsOpen] = useState<boolean>(false);

  const handleOptionsMenu = (e: any) => {
    setOpenMoreOptionsAnchor(e.currentTarget);
    setOpenMoreOptions(true);
  };

  const handleOptionsMenuClose = () => {
    setOpenMoreOptionsAnchor(null);
    setOpenMoreOptions(false);
  };

  /**
   * function to open the menu for selected dropdown
   * @param {*} e event handler
   * @param {string} val menu type
   */
  const handleDropdown = (e: any, val: string) => {
    if (downloadOption === val) {
      setDownloadOption('');
    } else {
      setDownloadOption(val);
    }
    setExpandedOptionsOpen(!expandedOptionsOpen);
  };

  const showUniqueResults = () => {
    ariaDispatch({ type: AriaActions.SET_UNIQUE_RESULTS, value: !ariaState.uniqueResults });
  };

  return (
    <>
      <Tooltip title='Open options'>
        <IconButton size='small' onClick={handleOptionsMenu}>
          <Badge
            variant='dot'
            overlap='rectangular'
            invisible={
              ariaState.selectedSort === 'Relevance: Very High -> Low' &&
              ariaState.uniqueResults === false
            }
            color='primary'>
            <MoreVert fontSize='small' />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        id='long-menu'
        anchorEl={openMoreOptionsAnchor}
        open={openMoreOptions}
        onClose={handleOptionsMenuClose}
        PaperProps={{
          style: {
            borderRadius: '8px'
          }
        }}>
        <List sx={{ width: '100%', minWidth: 240, bgcolor: 'background.paper' }}>
          {/* Export results to files */}
          {!RESTRICT_DOWNLOADS_BY_ORGANIZATION.includes(currentUser['custom:organization']) && (
            <ListItem disablePadding>
              <ListItemButton
                sx={{ display: 'flex', justifyContent: 'space-between' }}
                onClick={e => handleDropdown(e, 'download')}>
                <FileDownloadOutlinedIcon fontSize='small' sx={{ mr: 1 }} />
                <ListItemText
                  primary='Download Results'
                  primaryTypographyProps={styles.listItemText}
                  secondary='CSV, Semicolon separated, Excel'
                />
                {downloadOption === 'download' ? (
                  <ExpandLess fontSize='small' />
                ) : (
                  <ExpandMore fontSize='small' />
                )}
              </ListItemButton>
            </ListItem>
          )}
          {downloadOption === 'download' && (
            <CollapsableList
              downloadOption={downloadOption}
              expandedOptionsOpen={downloadOption === 'download'}
            />
          )}
          {/* Sort the results */}
          <ListItemButton onClick={e => handleDropdown(e, 'sort')}>
            <Box sx={{ mr: 1 }}>
              <Badge
                variant='dot'
                invisible={ariaState.selectedSort === 'Relevance: Very High -> Low'}
                color='primary'>
                <SortIcon />
              </Badge>
            </Box>

            <ListItemText
              primary='Sort by'
              primaryTypographyProps={styles.listItemText}
              secondary='Relevance, Active ingredient, Approval Year'
            />
            {downloadOption === 'sort' ? (
              <ExpandLess fontSize='small' />
            ) : (
              <ExpandMore fontSize='small' />
            )}
          </ListItemButton>
          {downloadOption === 'sort' && (
            <CollapsableList
              downloadOption={downloadOption}
              expandedOptionsOpen={downloadOption === 'sort'}
            />
          )}

          {/* Aria cheatsheet pdf */}
          <ListItemButton
            href='https://vivpro-site.s3.amazonaws.com/Help_Page_FAQ/Searching_with_Booleans_Reference_Sheet.pdf'
            target='_blank'>
            <TipsAndUpdatesOutlinedIcon fontSize='small' sx={{ mr: 1 }} />

            <ListItemText
              primary='Tips'
              primaryTypographyProps={styles.listItemText}
              secondary='Download The Booleans Reference Sheet'
            />
          </ListItemButton>

          {/* Show unique results toggele */}
          <Divider />
          <ListItem>
            <ListItemText
              primary='Show Unique Results'
              primaryTypographyProps={styles.listItemText}
              secondary='Unique results are determined based on the latest submission.'
            />
            <Switch
              checked={ariaState?.uniqueResults}
              onChange={showUniqueResults}
              name='Unique Results'
            />
          </ListItem>
        </List>
      </Menu>
    </>
  );
};

export default ToolsOptionsMenu;
