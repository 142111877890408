const getTopValuesForDonutChart = (values, key, count = 5) => {
  let sortedData = values.sort((a, b) => b.value - a.value);
  const otherValues = sortedData.slice(count);

  const otherValue = otherValues.reduce(
    (prev, current) => ({ ...prev, value: prev.value + current.value }),
    {
      id: 'Other',
      value: 0
    }
  );
  sortedData.length = count;
  sortedData.push(otherValue);

  sortedData = sortedData.map(data => ({
    value: data.value,
    id: data.id
  }));
  return sortedData;
};

export default getTopValuesForDonutChart;
