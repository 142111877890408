import { cloneDeep, sortBy, uniqBy } from 'lodash';
import { getProcessedDate } from '../../../utils/filters';
import { FILTERS } from './constants';

const getFieldValues = (row: any, key: string) => {
  return [row[key]];
};

const getFilters = (apiData: any) => {
  const filters = cloneDeep(
    FILTERS.map(fltr => ({ ...fltr, options: [...(fltr?.options || [])] }))
  );

  apiData?.forEach((row: any) => {
    filters.forEach((filter: { [key: string]: any }) => {
      if (filter.filterType === 'select_options') {
        // eslint-disable-next-line no-param-reassign
        filter.options = sortBy(
          uniqBy(
            [
              ...(filter.options || []),
              ...getFieldValues(row, filter.apiResponseKey).map((val: string | number) => ({
                value: val,
                label: val,
                selected: false
              }))
            ],
            'value'
          ),
          'label'
        ) as Array<any>;
      }
    });
  });

  return filters;
};

export const getApiPayloadFromFilters = (filters: Array<any>) => {
  const filtersObject: any = {};
  filters.forEach((filter: any) => {
    if (filter.filterType === 'selectOptions') {
      if (filter.options) {
        filtersObject[filter.value] = filter.options;
      }
    } else if (filter.filterType === 'radio') {
      // Do not consider default value
      if (filter.value !== 'all') {
        filtersObject[filter.value] = filter.value;
      }
    } else if (filter.filterType === 'dateRange') {
      if (filter.value?.startDate) {
        filtersObject[filter.value] = [
          getProcessedDate(filter.value?.startDate),
          getProcessedDate(filter.value?.endDate)
        ];
      }
    } else {
      filtersObject[filter.value] = filter.value;
    }
  });
  return filtersObject;
};

export default getFilters;
