import { createSvgIcon } from '@mui/material';

const FilterEmptyIcon = createSvgIcon(
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.875 7.19V7.04743L4.78028 6.94086L0.875 2.54743V1.5C0.875 1.20163 0.993526 0.915483 1.2045 0.704505C1.41548 0.493526 1.70163 0.375 2 0.375H10C10.2984 0.375 10.5845 0.493527 10.7955 0.704505C11.0065 0.915483 11.125 1.20163 11.125 1.5V2.54743L7.21972 6.94086L7.125 7.04743V7.19V11.25L4.875 9.5625V7.19ZM5.125 9.25V9.4375L5.275 9.55L6.275 10.3L6.875 10.75V10V6.95257L10.7803 2.55914L10.875 2.45257V2.31V1.5C10.875 1.26794 10.7828 1.04538 10.6187 0.881282C10.4546 0.717187 10.2321 0.625 10 0.625H2C1.76794 0.625 1.54538 0.717187 1.38128 0.881282C1.21719 1.04538 1.125 1.26794 1.125 1.5V2.31V2.45257L1.21972 2.55914L5.125 6.95257V9.25Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0.75'
    />
  </svg>,
  'FilterEmpty'
);

export default FilterEmptyIcon;
