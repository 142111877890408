import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
// icons
import CloseIcon from '@mui/icons-material/Close';
import DocumentIcon from '../../../Images/documentsIcon.svg';

// Styles
import styles from '../styles/CompareChip';

interface ComparisonButtonProps {
  documentsToCompare: any[];
  // eslint-disable-next-line no-unused-vars
  removeFromComparison: (id: string) => void;
  // eslint-disable-next-line no-unused-vars
  openComparisonPopup: (value: boolean) => void;
  clearComparison: () => void;
}

const ComparisonButtons = ({
  documentsToCompare,
  removeFromComparison,
  openComparisonPopup,
  clearComparison
}: ComparisonButtonProps) => {
  return (
    <Stack spacing={1} sx={{ pb: 1 }} alignItems='center' direction='row'>
      {documentsToCompare.map((doc: any) => (
        <Stack
          key={`selected_doc${doc.key}`}
          spacing={1}
          direction='row'
          justifyContent='flex-start'
          sx={{ ...styles.selectedOptionsContainer, borderColor: 'primary.main' }}
        >
          <img src={DocumentIcon} alt='documentLogo' />
          <Tooltip title={doc.doc_title}>
            <Typography noWrap variant='subtitle2' flex={3}>
              {doc.doc_title}
            </Typography>
          </Tooltip>
          <Box ml='auto !important' flex={0.5}>
            <CloseIcon
              cursor='pointer'
              fontSize='small'
              onClick={() => {
                removeFromComparison(doc.key);
              }}
            />
          </Box>
        </Stack>
      ))}
      {Array(2 - documentsToCompare.length)
        .fill(1)
        .map((_, index: number) => (
          <Stack
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            spacing={1}
            direction='row'
            justifyContent='flex-start'
            sx={{ ...styles.selectedOptionsContainer, pt: 1.4, pb: 1.4 }}
          >
            <img src={DocumentIcon} alt='documentLogo' />
            <Tooltip title={`Document ${index + 1 + documentsToCompare.length}`}>
              <Typography noWrap variant='subtitle2' flex={3} fontWeight='bold'>
                {`Document ${index + 1 + documentsToCompare.length}`}
              </Typography>
            </Tooltip>
          </Stack>
        ))}
      <Stack spacing={2} alignItems='center' direction='row'>
        <Button
          variant='contained'
          type='submit'
          sx={{
            color: 'white.main',
            fontWeight: 'bold',
            borderRadius: '30px',
            textTransform: 'capitalize'
          }}
          onClick={() => openComparisonPopup(true)}
          disabled={documentsToCompare.length !== 2}
        >
          Compare
        </Button>
        <Button
          variant='contained'
          type='submit'
          disabled={documentsToCompare.length === 0}
          sx={{
            color: 'white.main',
            fontWeight: 'bold',
            borderRadius: '30px',
            textTransform: 'capitalize'
          }}
          onClick={clearComparison}
        >
          Clear Selection
        </Button>
      </Stack>
    </Stack>
  );
};

export default React.memo(ComparisonButtons);
