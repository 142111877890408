const translateButtonBase = {
  borderWidth: 0,
  borderStyle: 'solid',
  borderRadius: 2,
  '& svg': {
    fontSize: '22px !important'
  }
};

const style = {
  translateButtonWhite: {
    ...translateButtonBase,
    borderColor: 'white.main',
    '&:hover': {
      bgcolor: 'gray.lightVariant3'
    }
  },
  translateIconWhite: {
    color: '#ffffff'
  },
  translateButtonWhiteEnabled: {
    ...translateButtonBase,
    borderColor: 'white.main',
    bgcolor: 'gray.contrastText',
    '&:hover': {
      bgcolor: 'gray.lightVariant3'
    }
  },
  translateIconWhiteEnabled: {
    color: '#ffffff'
  },
  translateButtonGreen: {
    ...translateButtonBase,
    borderColor: 'gray.main'
  },
  translateIconGreen: {
    color: 'gray.lightVariant2'
  },
  translateButtonGreenEnabled: {
    ...translateButtonBase,
    borderColor: 'primary.main',
    color: 'primary.main',
    bgcolor: 'primary.backgroundLight',
    '&:hover': {
      bgcolor: 'primary.backgroundDark'
    }
  },
  translateIconGreenEnabled: {
    color: 'gray.lightVariant2'
  }
};

export default style;
