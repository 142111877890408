import React, { useCallback, useContext, useEffect, useState } from 'react';

// Material UI
import Slide from '@mui/material/Slide';
import { Box, Card } from '@mui/material';

// Images
import rightArrowActive from '../../Images/active-right-arrow.svg';
import leftArrowActive from '../../Images/active-left-arrow.svg';
import rightArrow from '../../Images/right-arrow.svg';
import leftArrow from '../../Images/left-arrow.svg';

// store
import ResultsAction from '../../store/SearchResults/actions';
import ResultsStore from '../../store/SearchResults';

// Style
import makeStyles from './SearchListTreeView.styles';

// Types
import { Node, Option } from '../../types/search-list-treeview';
import CardButtons from './CardButtons';
import { getOptions, transverseParentNode } from './utils';

interface SynonymsViewPropsType {
  showCount: Boolean;
  nodes: Array<Node>;
  onNodeSelect?: (value: Node) => void;
}

// eslint-disable-next-line import/prefer-default-export
export const SearchListTreeView: React.FC<SynonymsViewPropsType> = React.memo(
  // eslint-disable-next-line react/prop-types
  ({ showCount, nodes, onNodeSelect }) => {
    const classes = makeStyles();
    const { resultsState, resultsDispatch } = useContext(ResultsStore);
    const [selectedOptions, setSelectedOptions] = useState<Array<Option | undefined>>([]);
    const [selectedNodes, setSelectedNodes] = useState<Array<Node>>([]);
    const [check, setCheck] = useState<any>([]);

    // Keep track of starting index of the selected node which gets displayed as the card
    const [startOfCardIndex, setStartOfCardIndex] = useState<number>(0);

    useEffect(() => {
      // eslint-disable-next-line react/prop-types
      if (selectedNodes.length === 0 && nodes.length > 0 && (nodes[0].children?.length || 0) > 0) {
        const currentNodes = [...nodes];

        // Select first option as selected.
        if (currentNodes && currentNodes[0] && currentNodes[0].children?.length) {
          const options = getOptions(currentNodes[0]);
          setSelectedOptions([options[0]]);
          currentNodes[1] = currentNodes[0].children[0];
          onNodeSelect?.(currentNodes[1]);
        }
        setSelectedNodes(currentNodes);
      }
    }, [nodes, onNodeSelect, selectedNodes]);

    useEffect(() => {
      setCheck(resultsState.synonymsList);
    }, [resultsState.synonymsList]);

    const handleRightArrowClick = () => {
      if (startOfCardIndex < selectedNodes.length - 3) {
        setStartOfCardIndex(startOfCardIndex + 1);
      }
    };
    const handleLeftArrowClick = () => {
      if (startOfCardIndex > 0) {
        setStartOfCardIndex(startOfCardIndex - 1);
      }
    };

    const handleOptionSelection = async (index: number, selectedOption: Option) => {
      const nextSelectedOptions = [...selectedOptions];
      const nextSelectedNodes = [...selectedNodes];

      // If left most card options are clicked, clear the previous selection
      nextSelectedOptions.length = index + 1;
      nextSelectedNodes.length = index + 1;

      // Next card should have the options based on the current selection.
      const selectedNode: Node | undefined = (nextSelectedNodes[index].children || []).find(
        (node: Node) => node.id === selectedOption.value
      );
      if (selectedNode) {
        onNodeSelect?.(selectedNode);
        nextSelectedNodes[index + 1] = selectedNode;
        setSelectedNodes(nextSelectedNodes);
      }
      if (nextSelectedOptions[index] !== selectedOption) {
        setSelectedNodes(nextSelectedNodes);
      }

      nextSelectedOptions[index] = selectedOption;
      setSelectedOptions(nextSelectedOptions);

      // Move the startIndex to right
      if (index > startOfCardIndex && index === startOfCardIndex + 2) {
        setStartOfCardIndex(startOfCardIndex + 1);
      }
      // Move the startIndex to back
      if (index <= startOfCardIndex && startOfCardIndex >= 1) {
        setStartOfCardIndex(startOfCardIndex - 1);
      }
    };

    useEffect(() => {
      setCheck(resultsState.synonymsList);
    }, [resultsState.synonymsList]);

    const handleCheckBox = useCallback(
      (e: any, option: any) => {
        let updatedList = [...resultsState.synonymsList];
        if (e.target.checked && !resultsState.loading) {
          if (updatedList.includes(option)) {
            updatedList = updatedList.filter((item: any) => item.label !== option.label);
          } else {
            // eslint-disable-next-line no-param-reassign
            option.checked = e.target.checked;
            updatedList.push(option);
          }
        } else {
          updatedList = updatedList.filter((item: any) => item.label !== option.label);
        }

        setCheck(updatedList);
        resultsDispatch({
          type: ResultsAction.SET_SYNONYMS,
          value: updatedList
        });
      },
      [resultsState.synonymsList, resultsDispatch, resultsState.loading]
    );

    const array = Array(Math.max(3, selectedNodes.length));
    return (
      <Box>
        <Box
          padding={1}
          width='100%'
          height='100%'
          display='flex'
          flexDirection='row'
          justifyContent='center'
          sx={{ marginTop: 2 }}>
          <Box display='flex' alignItems='center' padding={1} onClick={handleLeftArrowClick}>
            <img width='34px' src={startOfCardIndex > 0 ? leftArrowActive : leftArrow} alt='' />
          </Box>
          <Box
            display='flex'
            flexWrap='nowrap'
            flexShrink={0}
            overflow='hidden'
            width='87%'
            justifyContent='center'>
            {array.fill(1).map((a, index) => (
              <Slide
                key={a.id}
                in={index >= startOfCardIndex && index <= startOfCardIndex + 2}
                direction={index > startOfCardIndex ? 'left' : 'right'}
                unmountOnExit
                mountOnEnter>
                <Card className={classes.card} key={a.id}>
                  <CardButtons
                    showCount={showCount}
                    options={transverseParentNode(selectedNodes[index], check)}
                    selectedOption={selectedOptions[index]}
                    onOptionSelect={(option: Option) => handleOptionSelection(index, option)}
                    onCheckedBox={(e: any, option: Option) => handleCheckBox(e, option)}
                  />
                  <Box />
                </Card>
              </Slide>
            ))}
          </Box>
          <Box display='flex' alignItems='center' padding={1} onClick={handleRightArrowClick}>
            <img
              width='34px'
              src={startOfCardIndex < selectedNodes.length - 3 ? rightArrowActive : rightArrow}
              alt=''
            />
          </Box>
        </Box>
      </Box>
    );
  }
);
