import React, { useState, useContext, useCallback } from 'react';

// mui
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

// Custom components and api's
import PDFPreview from '../../../components/PdfPreview/PDFPreview';
import { FolderIcon } from '../../../assets/svgs/Icons';

// style
import styles from '../styles/PDFCell.styles';

// store
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';
import { getDocumentSourceName } from '../../SearchResults/utils/documentResultsUtils';
import { getPediatricsDocumentsSignedURL } from '../../../api/pages/Pediatrics';

const GridCellDouments = ({ row }: any) => {
  const [PDFData, setPDFData] = useState<string>('');
  const [title, setTitle] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | any>(null);
  const [linkData, setLinkData] = useState<Array<any>>([]);
  const { dispatch } = useContext(GlobalStore) as any;

  const handleSignedURL = useCallback(async (pdfLink: string) => {
    const res: any = await getPediatricsDocumentsSignedURL({
      source: 'written_requests',
      s3_path: pdfLink
    });
    return res?.data?.body?.s3_url ?? '';
  }, []);

  const handleOpenPopup = (e: React.SyntheticEvent) => {
    setAnchorEl(e.currentTarget);
    const data: Array<any> = [];
    const paths = row.file_names;
    const links = row.s3_paths;
    paths.forEach((ele: string, ind: number) => {
      const name = ele.split('_');
      data.push({ docName: name[name.length - 1], docLink: links[ind] });
    });
    setLinkData(data);
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
    setLinkData([]);
  };

  const handlePDFOpen = async (e: React.SyntheticEvent, linkText: string, name: string) => {
    setTitle(name);
    setPDFData(await handleSignedURL(linkText));
  };

  const handleChatRia = ({ pdfUrl = '' }) => {
    const mappedSourceName = getDocumentSourceName('FDA Written Requests');
    dispatch({
      type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM,
      value: 'document'
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: mappedSourceName
    });

    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: {
        blob: pdfUrl,
        url: pdfUrl,
        item: {
          determination_date: row?.determination_date ?? '',
          drug_name: row?.drug_name ?? '',
          wr_sponsor: row?.wr_sponsor ?? '',
          // eslint-disable-next-line no-underscore-dangle
          _category: row?._category ?? '',
          title: `${row?.drug_name ?? ''} - ${row?.determination_date ?? ''} - ${
            // eslint-disable-next-line no-underscore-dangle
            row?._category ?? ''
          }`
        },
        source: mappedSourceName,
        triggerReopenChatRia: false
      }
    });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
    setPDFData('');
    handleClosePopup();
  };

  return (
    <Box>
      <Stack
        direction='row'
        spacing={0.5}
        alignItems='center'
        sx={styles.linkItem}
        onClick={(e: React.SyntheticEvent) => handleOpenPopup(e)}>
        <FolderIcon sx={{ fontSize: 12 }} />
        <Typography variant='subtitle2' sx={styles.pdfCellText}>
          {row.s3_paths.length}
        </Typography>
      </Stack>
      {linkData.length > 0 && (
        <Popover
          id='simple-popover'
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClosePopup}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          elevation={1}>
          <Grid container sx={styles.popoverDiv}>
            <Grid container>
              <Grid item xs={6}>
                <Grid>
                  <Typography sx={styles.linkHeading}>Documents</Typography>
                  {linkData.map(item => {
                    return (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <Link
                        key={item.docName}
                        sx={styles.link}
                        onClick={event => handlePDFOpen(event, item.docLink, item.docName)}>
                        {item.docName}
                      </Link>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Popover>
      )}

      <Box>
        <PDFPreview
          open={PDFData?.length !== 0}
          pdfUrl={PDFData}
          onClose={() => {
            setPDFData('');
          }}
          handleChatRia={handleChatRia}
          title={title}
        />
      </Box>
    </Box>
  );
};

const DatagridPDFCell = (params: any) => {
  const { row } = params;
  return <GridCellDouments row={row} />;
};

export default DatagridPDFCell;
