const plusStyle: any = {
  position: 'relative',
  height: '4rem',
  width: '9rem',
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    background: '#0000',
    height: '4rem',
    width: '9rem'
  },
  '&::before': {
    left: '50%',
    top: '0%',
    bottom: '4px',
    width: '1px',
    transform: 'translateX(-50%)',
    border: '1px dashed #adadad80'
  },
  '&::after': {
    left: '0%',
    top: '50%',
    right: '4px',
    height: '1px',
    transform: 'translateY(-50%)',
    border: '1px dashed #adadad80'
  }
};

export default plusStyle;
