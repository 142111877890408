const valueFormatting = (num: number, fromHoverValueFormatting: boolean = false) => {
  const strNum = num.toString().split('');
  if (strNum.length > 6 && !fromHoverValueFormatting) {
    return `${strNum.slice(0, strNum.length - 6).join('')}.${strNum[strNum.length - 6]}MM`;
  }
  const formattedNum: string[] = [];
  strNum
    .reverse()
    .forEach((ele, index) => formattedNum.push(index % 3 == 0 && index !== 0 ? `${ele},` : ele));
  return formattedNum.reverse().join('');
};

export default valueFormatting;
