import React from 'react';
import { createSvgIcon } from '@mui/material';

const RightArrowIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='6' height='10' viewBox='0 0 6 10' fill='none'>
    <path
      d='M1.20727 9.47148L0.266602 8.52881L3.7926 5.00014L0.266602 1.47147L1.20993 0.528809L4.73326 4.05748C4.98322 4.30751 5.12364 4.64659 5.12364 5.00014C5.12364 5.35369 4.98322 5.69277 4.73326 5.94281L1.20727 9.47148Z'
      fill='#616161'
    />
  </svg>,
  'RightArrow'
);

export default RightArrowIcon;
