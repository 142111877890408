import { createSvgIcon } from '@mui/material';

const EyeDisabledIcon = createSvgIcon(
  <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_4468_10857)'>
      <path
        d='M13.8955 7.02215C13.3319 5.7999 12.5065 4.71654 11.4776 3.84882L13.2463 2.08015L12.4208 1.25415L10.4999 3.17332C9.43672 2.55832 8.22814 2.23946 6.99993 2.2499C2.62493 2.2499 0.616508 5.90215 0.104342 7.02215C0.0355889 7.17224 0 7.33539 0 7.50048C0 7.66557 0.0355889 7.82872 0.104342 7.97882C0.667921 9.20107 1.49339 10.2844 2.52226 11.1522L0.754175 12.9208L1.57901 13.7457L3.49993 11.8265C4.56313 12.4415 5.77171 12.7603 6.99993 12.7499C11.3749 12.7499 13.3833 9.09765 13.8955 7.97765C13.9641 7.82773 13.9996 7.66478 13.9996 7.4999C13.9996 7.33502 13.9641 7.17207 13.8955 7.02215V7.02215ZM1.16659 7.50632C1.60409 6.54673 3.32084 3.41657 6.99993 3.41657C7.91552 3.41122 8.81913 3.62482 9.63543 4.03957L8.60584 5.06915C8.0458 4.69733 7.37437 4.53073 6.70548 4.59761C6.03659 4.66449 5.41143 4.96073 4.93609 5.43607C4.46076 5.91141 4.16451 6.53656 4.09764 7.20545C4.03076 7.87435 4.19736 8.54578 4.56918 9.10582L3.35643 10.3186C2.42331 9.55871 1.6746 8.5972 1.16659 7.50632V7.50632ZM8.74993 7.4999C8.74993 7.96403 8.56555 8.40915 8.23736 8.73734C7.90917 9.06553 7.46406 9.2499 6.99993 9.2499C6.74006 9.24889 6.48379 9.18907 6.25034 9.0749L8.57493 6.75032C8.68909 6.98377 8.74892 7.24003 8.74993 7.4999V7.4999ZM5.24993 7.4999C5.24993 7.03577 5.4343 6.59065 5.76249 6.26246C6.09068 5.93427 6.5358 5.7499 6.99993 5.7499C7.25979 5.75091 7.51606 5.81074 7.74951 5.9249L5.42493 8.24948C5.31076 8.01604 5.25093 7.75977 5.24993 7.4999ZM6.99993 11.5832C6.08433 11.5886 5.18072 11.375 4.36443 10.9602L5.39401 9.93065C5.95405 10.3025 6.62548 10.4691 7.29437 10.4022C7.96327 10.3353 8.58842 10.0391 9.06376 9.56373C9.5391 9.0884 9.83534 8.46324 9.90222 7.79435C9.96909 7.12546 9.80249 6.45402 9.43068 5.89398L10.6428 4.68182C11.5778 5.44238 12.3269 6.40618 12.8333 7.4999C12.3876 8.46648 10.6703 11.5832 6.99993 11.5832Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_4468_10857'>
        <rect width='14' height='14' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>,
  'EyeDisabled'
);

export default EyeDisabledIcon;
