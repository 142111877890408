const getUserDetails = (currentUser: any) => {
  return {
    role: currentUser['custom:role'],
    userid: currentUser.sub,
    email: '',
    organization: currentUser['custom:organization'],
    tier: currentUser['custom:license']
  };
};

export default getUserDetails;
