const styles: { [key: string]: any } = {
  main: {
    padding: '24px'
  },
  tagText: {
    fontWeight: 'bold',
    color: 'gray.main'
  },
  content: {
    padding: '16px 0px',
    bgcolor: 'black.lightVariant',
    color: 'white.main',
    width: '100%',
    borderRadius: '10px 10px 0px 0px',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 900
  },
  background: {
    overflowY: 'auto',
    paddingBottom: '20px',
    bgcolor: 'white.main',
    borderRadius: '0px 0px 10px 10px',
    overflowX: 'hidden',
    width: '100%'
  },
  ListItem: {
    borderLeft: '1px solid gray.light',
    marginLeft: '16px',
    paddingLeft: 'unset',
    cursor: 'pointer'
  },
  borderClass: {
    borderTop: '1px solid gray.light',
    paddingLeft: '8px'
  },
  ListItemText: {
    fontSize: '16px',
    fontWeight: 600,
    color: 'gray.main',
    textTransform: 'capitalize'
  },
  indications_container: {
    maxHeight: '53vh',
    overflow: 'auto',
    paddingLeft: '30px',
    paddingRight: '30px'
  },
  indications_field_container: {
    paddingTop: '30px',
    textAlign: 'justify',
    fontSize: 'larger'
  },
  activeTab: {
    background: 'linear-gradient(102deg, #29ABE2 0%, #06C66C 100%)',
    border: `0.5px solid gray.lightBorder`,
    borderColor: 'gray.lightBorder',
    borderRadius: '10px',
    marginTop: '20px',
    '&.Mui-selected': {
      color: 'white.main',
      border: '0.5px solid',
      fontSize: 'large'
    },
    textTransform: 'capitalize'
  },
  inactiveTab: {
    background: 'primary.background',
    marginTop: '20px',
    textTransform: 'capitalize',
    border: `0.5px solid`,
    borderColor: 'gray.lightBorder',
    borderRadius: '10px'
  },
  tabContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    height: '53vh',
    paddingTop: '5px',
    margin: '5px 5px 5px 15px'
  },
  tabs: {
    paddingRight: '10px',
    width: '200px',
    '& div.MuiTabs-scroller': {
      '& .MuiTabs-flexContainer': {
        padding: '5px'
      }
    }
  },
  indicationText: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '35px',
    textAlign: 'justify',
    fontSize: 'larger',
    padding: '15px 20px 10px 30px'
  },
  chatRiaButton: {
    display: 'flex',
    ml: '10px',
    width: 'fit-content',
    borderRadius: '16px',
    height: '32px',
    padding: '6px 16px',
    border: '1px solid',
    borderColor: 'blue.backgroundLight',
    backgroundColor: 'white.main',
    textTransform: 'capitalize',
    color: 'blue.backgroundLight',
    fontSize: '12px',
    fontWeigth: '400'
  },
  sendIcon: { width: '16px', height: '16px', p: '2.667px 2.666px 2.667px 2.667px' }
};

export default styles;
