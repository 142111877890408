const styles = {
  root: {
    padding: '4px 16px',
    backgroundColor: 'grey.100'
  },
  projectNameText: {
    color: 'grey.900',
    fontSize: '14px',
    fontWeight: 700
  },
  projectDescriptionText: {
    color: 'grey.600',
    fontSize: '14px',
    fontWeight: 400
  },
  divider: {
    width: '1px',
    height: '20px'
  },
  icon: {
    fontSize: '16px'
  }
};

export default styles;
