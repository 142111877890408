import { GUIDANCE_PIE_CHART } from '../const';
import getTopValuesForPieChart from './getTopValuesForPieChart';

const constructDonutChartStructure = (data: any) => {
  const formattedData = [];
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in data) {
    formattedData.push({ id: key, label: key, value: data[key] });
  }
  return formattedData;
};

export const constructPieChartData = (guidanceData: any) => {
  const graphs: any = [];
  GUIDANCE_PIE_CHART.forEach((item: any) => {
    graphs.push({
      label: item.label,
      children: getTopValuesForPieChart(
        constructDonutChartStructure(guidanceData[item.graphItem]),
        item.apiKey,
        item.count
      ),
      apikey: item.apiKey
    });
  });
  return graphs;
};

export default constructDonutChartStructure;
