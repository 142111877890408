import React, { useRef, useEffect } from 'react';

// mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

// styles
import styles from '../styles/MeetingPage.styles';
import CustomChip from '../CustomChip';

const MeetingInfoChip = ({ data, selectedMeeting, handleMeetingChange, boxRef }: any) => {
  const chipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (boxRef.current && chipRef.current) {
      chipRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
        // @ts-ignore
        container: boxRef.current
      });
    }
  }, [boxRef]);
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      sx={
        selectedMeeting?.meeting_id === data.meeting_id
          ? styles.MeetingBox
          : styles.MeetingBoxInactive
      }
      ref={selectedMeeting?.meeting_id === data.meeting_id ? chipRef : null}
      onClick={() => handleMeetingChange(data)}>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box sx={styles.meetingId}>
          {data.month_year !== '' ? (
            <Typography
              sx={styles.date}>{`${data.month_year} ${data.applicant_initials}`}</Typography>
          ) : null}
        </Box>
        {data?.center && <CustomChip label={data.center} />}
      </Box>
      <Box>
        <Tooltip title={data.name}>
          <Typography sx={styles.heading}>{data.name}</Typography>
        </Tooltip>
        <Typography sx={styles.subHeading}>{data.details}</Typography>
      </Box>
    </Box>
  );
};

export default React.memo(MeetingInfoChip);
