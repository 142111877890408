import { createSvgIcon } from '@mui/material';

const CheckboxUncheckedIcon = createSvgIcon(
  <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_3915_12548)'>
      <path
        d='M11.0833 0.5H2.91667C2.1434 0.500926 1.40208 0.808515 0.855295 1.3553C0.308515 1.90208 0.00092625 2.6434 0 3.41667L0 11.5833C0.00092625 12.3566 0.308515 13.0979 0.855295 13.6447C1.40208 14.1915 2.1434 14.4991 2.91667 14.5H11.0833C11.8566 14.4991 12.5979 14.1915 13.1447 13.6447C13.6915 13.0979 13.9991 12.3566 14 11.5833V3.41667C13.9991 2.6434 13.6915 1.90208 13.1447 1.3553C12.5979 0.808515 11.8566 0.500926 11.0833 0.5V0.5ZM12.8333 11.5833C12.8333 12.0475 12.649 12.4926 12.3208 12.8208C11.9926 13.149 11.5475 13.3333 11.0833 13.3333H2.91667C2.45254 13.3333 2.00742 13.149 1.67923 12.8208C1.35104 12.4926 1.16667 12.0475 1.16667 11.5833V3.41667C1.16667 2.95254 1.35104 2.50742 1.67923 2.17923C2.00742 1.85104 2.45254 1.66667 2.91667 1.66667H11.0833C11.5475 1.66667 11.9926 1.85104 12.3208 2.17923C12.649 2.50742 12.8333 2.95254 12.8333 3.41667V11.5833Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_3915_12548'>
        <rect width='14' height='14' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>,
  'CheckboxUnchecked'
);

export default CheckboxUncheckedIcon;
