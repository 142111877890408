/* eslint-disable no-nested-ternary */
import Checkbox from '@mui/material/Checkbox/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';

import Stack from '@mui/material/Stack';
import classes from '../styles/Card.style';

import {
  APPROVAL_STATUS_TAGS,
  CARD_METADATA,
  COLOR_SCHEME_NUMBER,
  COLOR_SCHEME_TEXT
} from '../../ResultsPage/utils/constants';

import CircularAvatar from '../../ResultsPage/components/CircularAvatar';

export interface CardProps {
  source: string;
  index: number;
  selected: boolean;
  showId: boolean;
  // eslint-disable-next-line no-unused-vars
  [key: string | number]: any;
  // eslint-disable-next-line no-unused-vars
  onSelect: (e: any) => void;
}

const Card: React.FC<any> = ({ source, index, selected, showId, onSelect, ...props }) => {
  const {
    title,
    number,
    colorScheme,
    subtitle,
    metadata1,
    metadata2,
    metadata3,
    metadata4,
    dateValue
  }: any = CARD_METADATA[source?.toLowerCase() as string];

  const cardBorder =
    props.marketing_status?.toLowerCase() === 'yellow'
      ? {
          border: 1,
          borderType: 'solid',
          borderColor: 'gray.contrastText'
        }
      : { border: 'none' };
  // used for strikethrough text on trade name
  const textStrikeThrough =
    props.marketing_status?.toLowerCase() === 'red'
      ? {
          textDecoration: 'line-through'
        }
      : { textDecoration: 'none' };

  const metadataCasingCheck = {
    textTransform:
      source?.toLowerCase() === 'hpra'
        ? 'capitalize'
        : props[metadata4]?.toLowerCase() === 'missing at source'
        ? 'capitalize'
        : 'lowercase'
  };
  return (
    <Stack key={index} spacing={2}>
      {/* Card title */}
      <Stack direction='row' justifyContent='space-between'>
        <Tooltip
          title={
            Array.isArray(props[title])
              ? props[title].join(' ').toUpperCase()
              : props[title].toUpperCase()
          }>
          <Typography noWrap variant='activeIngredient' fontWeight='bold' sx={{ width: '80%' }}>
            {showId
              ? Array.isArray(props[title])
                ? props[title].join(' ').toLowerCase()
                : props[title].toLowerCase()
              : '...'}
          </Typography>
        </Tooltip>
        {props?.center === 'CBER' && (
          <Typography variant='subtitle1' fontWeight='bold' color='primary'>
            (CBER)
          </Typography>
        )}
      </Stack>

      {/* selection for cards */}

      <FormControlLabel
        control={
          <Checkbox
            id='checkbox_selection'
            value={props[number]}
            name='checkedH'
            checked={selected}
            onChange={onSelect}
            // value={index}
          />
        }
        label=''
      />

      {/* Card content */}
      <Stack spacing={1} sx={{ ...cardBorder, ...classes.cardContainer }}>
        {/* <Stack
          mt={-1}
          direction='row'
          justifyContent='flex-end'
          onClick={e => hideApplications(e, resultsState.results[index])}>
          <RemoveIcon />
        </Stack> */}
        <Stack spacing={1}>
          <Stack direction='row' justifyContent='space-between'>
            <Typography
              align='center'
              variant='subtitle2'
              fontWeight='bold'
              sx={classes[COLOR_SCHEME_NUMBER[props[colorScheme]?.toLowerCase() || 'ema']]}>
              {props[number]}
            </Typography>
            <CircularAvatar
              data={props}
              avatarList={APPROVAL_STATUS_TAGS[source?.toLowerCase() as string]}
              tooltipWithoutPrefix={false}
            />
          </Stack>
          {/* trade name */}
          <Stack direction='row' spacing={2} justifyContent='space-between' alignItems='center'>
            <Tooltip title={props[subtitle]}>
              <Typography
                variant='tradeName'
                noWrap
                sx={{
                  ...textStrikeThrough,
                  ...classes[COLOR_SCHEME_TEXT[props[colorScheme]?.toLowerCase() || 'ema']]
                }}>
                {Array.isArray(props[subtitle])
                  ? props[subtitle].join(' ').toLowerCase()
                  : props[subtitle]?.toLowerCase()}
              </Typography>
            </Tooltip>
            <Typography variant='subtitle2' fontWeight='bold'>
              {' '}
              {props[dateValue]}
            </Typography>
          </Stack>
          {/* sponsor name */}
          <Tooltip title={props[metadata1]}>
            <Typography
              noWrap
              variant='subtitle2'
              sx={{ ...{ textTransform: 'uppercase' }, ...classes.metadata }}>
              {props[metadata1]?.toLowerCase() || '---'}
            </Typography>
          </Tooltip>
          {/* formulation name */}
          <Tooltip title={props[metadata2]}>
            <Typography
              noWrap
              variant='subtitle2'
              sx={{ ...{ textTransform: 'capitalize' }, ...classes.metadata }}>
              {Array.isArray(props[metadata2])
                ? props[metadata2].join(' ')?.toLowerCase() || '---'
                : props[metadata2]?.toLowerCase() || '---'}
            </Typography>
          </Tooltip>
          {/* route name */}
          <Tooltip title={props[metadata3]}>
            <Typography
              noWrap
              variant='subtitle2'
              sx={{ ...{ textTransform: 'capitalize' }, ...classes.metadata }}>
              {Array.isArray(props[metadata3])
                ? props[metadata3].join(' ')?.toLowerCase() || '---'
                : props[metadata3]?.toLowerCase() || '---'}
            </Typography>
          </Tooltip>
          {/* approval_pathway name */}
          <Stack direction='row' justifyContent='space-between'>
            <Tooltip title={props[metadata4]}>
              <Typography variant='subtitle2' sx={{ ...metadataCasingCheck, ...classes.metadata }}>
                {props[metadata4]?.toLowerCase() || '---'}
              </Typography>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default React.memo(Card);
