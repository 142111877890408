import { Typography, Box, IconButton, Badge, Tooltip } from '@mui/material';
import plusStyle from '../styles/twoDimensionalTimeLineStyle';
import { designationTypesColor, designationName } from './SpecialityTimeLine';
import { badgeStyle } from '../styles/horizontalDesignationCardStyle';

const designationTypes: string[] = ['aa', 'bt', 'ft', 'or'];

const TwoDimensionalBadge = ({
  application,
  year,
  activeBadge,
  isExpandedCardOpen,
  setIsExpandedCardOpen,
  setActiveBadge,
  setExpandedPayload
}: {
  application: any;
  year: string;
  activeBadge: string;
  isExpandedCardOpen: boolean;
  setIsExpandedCardOpen: any;
  setActiveBadge: any;
  setExpandedPayload: any;
}) => {
  return (
    <Box className='plus' sx={plusStyle}>
      <Box
        sx={{
          zIndex: 1,
          position: 'relative',
          top: '28%',
          textAlign: 'center'
        }}>
        {designationTypes.map(designationType => {
          const applicationByYear: any = application;
          const applicationSpecialtyDesignation = applicationByYear?.specialityDesignation;
          const isTypePresent = applicationSpecialtyDesignation.some(
            (e: any) => e.type === designationType
          );
          const count = applicationSpecialtyDesignation?.filter(
            (e: any) => e.type === designationType
          )?.length;
          const badgeKey = `${applicationByYear?.number}-${year}-${designationType}`;
          const isSelected = activeBadge === badgeKey && isExpandedCardOpen;

          return isTypePresent ? (
            <Badge badgeContent={count > 1 ? count : 0} sx={badgeStyle} key={badgeKey}>
              <Tooltip key={applicationByYear?.number} title={designationName[designationType]}>
                <IconButton
                  className='demo'
                  sx={{
                    width: 30,
                    height: 30,
                    backgroundColor: designationTypesColor[designationType].background,
                    mx: '1px',
                    boxShadow: isSelected ? 3 : 0,
                    transform: isSelected ? 'scale(1.3)' : 'none',
                    '&:hover': {
                      backgroundColor: designationTypesColor[designationType].background,
                      cursor: 'pointer',
                      boxShadow: 1,
                      transform: 'scale(1.1)'
                    }
                  }}
                  onClick={() => {
                    setTimeout(() => {
                      setIsExpandedCardOpen(true);
                    }, 200);
                    setActiveBadge(badgeKey);
                    setExpandedPayload({
                      number: applicationByYear?.number,
                      tradeName: applicationByYear?.tradeName,
                      activeIngredients: applicationByYear?.activeIngredients,
                      sponsorName: applicationByYear?.sponsorName,
                      indications: applicationByYear?.indications,
                      route: applicationByYear?.route,
                      formulation: applicationByYear?.formulation,
                      specialityDesignation: applicationSpecialtyDesignation,
                      selectedDesignationType: designationType,
                      variant:
                        applicationByYear?.applicationType?.toLowerCase() === 'nda'
                          ? 'red'
                          : 'blue',
                      strikeText:
                        applicationByYear?.marketingStatus?.toLowerCase().trim() !== 'green'
                    });
                  }}>
                  <Typography
                    variant='body2'
                    sx={{
                      color: 'white.main',
                      fontWeight: 'bold'
                    }}>
                    {designationType.toUpperCase()}
                  </Typography>
                </IconButton>
              </Tooltip>
            </Badge>
          ) : null;
        })}
      </Box>
    </Box>
  );
};

export default TwoDimensionalBadge;
