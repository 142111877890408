import makeRequest from '../client';

/**
 *
 * Function gets all data for guidance module
 */
export const postLetters = (filters: any, limit: number, offset: number) =>
  makeRequest(`/fda-letters/letters?limit=${limit}&offset=${offset}`, 'POST', filters);

export const getLetters = (id: any) => makeRequest(`/fda-letters/letters/${id}`, 'GET');

export const getInsights = (filters: any) => makeRequest('/fda-letters/insights', 'POST', filters);

export const getDocumentsSignedURL = (payload: any) =>
  makeRequest('/fda-letters/resource', 'POST', payload);

export const getAllDocuments = (filters: any) =>
  makeRequest('/fda-letters/letters', 'POST', filters);
