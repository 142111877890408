const styles = {
  dialog: {
    '& .MuiDialog-paper': {
      height: '100%'
    }
  },
  title: {
    paddingTop: '5px',
    textDecoration: 'underline',
    fontSize: 14,
    fontFamily: 'Mulish',
    color: 'primary.dark',
    textTransform: 'capitalize',
    fontWeight: '600',
    cursor: 'pointer'
  },
  dialogContent: {
    height: '79vh',
    boxShadow: '0px 0px 10px blue.alice',
    border: 'unset',
    borderRadius: '10px'
  },
  summaryTitle: {
    fontSize: 18,
    color: 'primary.main',
    fontWeight: 600,
    fontFamily: 'Mulish'
  },
  subHeading: {
    padding: 0.5,
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600,
    fontFamily: 'Mulish'
  },
  heading: {
    color: 'primary.dark',
    padding: '13px 10px',
    borderRadius: '10px 10px 0px 0px',
    marginBottom: 'auto',
    fontSize: 18,
    fontFamily: 'Mulish',
    fontWeight: '600'
  },
  summary: {
    height: 'flex',
    borderRadius: '20px',
    backgroundColor: 'primary.backgroundDark',
    padding: '30px',
    marginLeft: '5px'
  },
  button: {
    fontSize: 14,
    fontFamily: 'Mulish',
    padding: '7px 18px',
    width: 'fit-content',
    border: 1,
    borderColor: 'primary.main',
    borderRadius: 2,
    borderStyle: 'solid',
    textAlign: 'center',
    backgroundColor: 'white.main',
    textTransform: 'capitalize',
    color: 'black.darkVariant',
    fontWeight: '400',
    cursor: 'pointer',
    '&:hover': {
      borderColor: 'gray.lightVariant3',
      boxShadow: 'none',
      bgcolor: 'primary.main',
      color: 'white.main'
    }
  }
};

export default styles;
