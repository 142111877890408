const styles = {
  container: {
    width: '100%',
    borderRadius: 5,
    height: '90vh',
    maxHeight: '90vh',
    mb: 10,
    background: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': { p: 2 }
  },
  snackbar: {
    background: 'rgba(61,61,61,0.65) !important',
    boxShadow: 'none',
    top: ` 0 !important`,
    pt: 5,
    width: '100%'
  },
  closeButton: {
    background: 'rgb(255,255,255)',
    position: 'absolute',
    top: -30,
    right: -30,
    '&:hover': {
      background: 'rgba(255,255,255,0.8)'
    }
  }
};

export default styles;
