/* eslint-disable import/prefer-default-export */
import uniqueId from 'lodash/uniqueId';

// muiv5
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

// stylesheet
import styles from '../styles/CardsGrid.styles';

export const CardGridLoader = () => {
  const numberOfLoadingCards = 12;
  return (
    <Grid container>
      {[...Array(numberOfLoadingCards)].map(() => {
        return (
          <Grid key={uniqueId()} item xs={12} md={6} lg={3} xl={3} sx={styles.cardGrid}>
            <Stack spacing={2}>
              <Skeleton variant='text' width='50%' />
              <Skeleton
                variant='rectangular'
                sx={{
                  marginTop: 1,
                  width: '100%',
                  height: {
                    sm: 200,
                    md: 200,
                    lg: 250,
                    xl: 300
                  },
                  transition: 'transform .2s',
                  borderRadius: 10,
                  '&:hover': {
                    transform: 'scale(1.1)'
                  }
                }}
              />
            </Stack>
          </Grid>
        );
      })}
    </Grid>
  );
};

export const AnalyticViewLoader = () => {
  const numberOfLoadingCards = 12;
  return (
    <Grid container>
      {[...Array(numberOfLoadingCards)].map(() => {
        return (
          <Grid key={uniqueId()} item xs={12} sx={styles.cardGrid}>
            <Stack spacing={1}>
              <Skeleton sx={{ height: 50 }} variant='text' width='100%' />
              <Skeleton
                variant='rectangular'
                sx={{
                  marginTop: 1,
                  width: '100%',
                  height: {
                    sm: 200,
                    md: 200,
                    lg: 250,
                    xl: 300
                  },
                  transition: 'transform .2s',
                  borderRadius: 4,
                  '&:hover': {
                    transform: 'scale(1.1)'
                  }
                }}
              />
            </Stack>
          </Grid>
        );
      })}
    </Grid>
  );
};

export const StatsBarLoader = () => {
  return (
    <Grid container>
      <Grid item xs={12} sx={styles.cardGrid}>
        <Stack spacing={1}>
          <Skeleton
            variant='rectangular'
            sx={{
              width: '40%',
              height: 50,
              transition: 'transform .2s',
              borderRadius: 4
            }}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};
