const styles = {
  wrapper: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray.lightVariant3',
      borderRadius: '4px',
      opacity: '0.8'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    }
  },
  contentWrapper: {
    padding: '0px !important'
  }
};

export default styles;
