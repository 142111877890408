import { memo, useContext, useEffect } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import themev5 from '../../themev5';

// context provider
import { PediatricsEMAStateProvider } from '../../store/PediatricsEMA';
import Store from '../../store';
import Actions from '../../store/actions';

// hoc
import Tabs from './hoc/Tabs';

const PediatricsEMA = () => {
  const { dispatch } = useContext(Store) as any;

  useEffect(() => {
    dispatch({ type: Actions.SET_MODULE, value: 'ema-pips' });
  }, []);

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <PediatricsEMAStateProvider>
        <Tabs />
      </PediatricsEMAStateProvider>
    </ThemeProvider>
  );
};

export default memo(PediatricsEMA);
