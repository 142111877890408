import { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

// mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

// icons
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

// components
import AboutContent from './AboutContent';
import FaqContent from './FaqContent';
import Guides from './Guides';
import WhatsNew from './WhatsNew';
import VideoList from './VideoList/VideoList';

// stylesheet
import styles from '../styles/Banner.styles';
import Header from '../../../components/Header/Header';

// context store
import Store from '../../../store';
import Actions from '../../../store/actions';

// custom hooks
import useQuery from '../../../helpers/customHooks/queryParameter';

const Banner = () => {
  const { dispatch } = useContext(Store);
  const query = useQuery();
  const history = useHistory();

  const selectedView = query.get('content');

  useEffect(() => {
    dispatch({ type: Actions.SET_MODULE, value: 'core' });
    if (!selectedView) {
      history.push('/help?content=about');
    }
  }, []);

  const cards = [
    {
      key: 'about',
      title: 'About',
      description: 'Lorem ipsum dolor sit amet, consectetur sit amet, consectetur',
      icon: <InfoOutlinedIcon sx={styles.icon} />,
      link: `/help?content=about`
    },
    {
      key: 'faq',
      title: 'FAQs',
      description: 'Lorem ipsum dolor sit amet, consectetur sit amet, consectetur',
      icon: <QuizOutlinedIcon sx={styles.icon} />,
      link: `/help?content=faq`
    },
    {
      key: 'guides',
      title: 'Case Studies',
      description: 'Lorem ipsum dolor sit amet, consectetur sit amet, consectetur',
      icon: <LocalLibraryOutlinedIcon sx={styles.icon} />,
      link: `/help?content=guides`
    },
    {
      key: 'whatsnew',
      title: `What's New`,
      description: 'Lorem ipsum dolor sit amet, consectetur sit amet, consectetur',
      icon: <AddRoadIcon sx={styles.icon} />,
      link: `/help?content=whatsnew`
    },
    {
      key: 'videos',
      title: `Video Tutorials`,
      description: 'Lorem ipsum dolor sit amet, consectetur sit amet, consectetur',
      icon: <OndemandVideoIcon sx={styles.icon} />,
      link: `/help?content=videos`
    }
  ];

  return (
    <Stack display='flex' justifyContent='flex-start' sx={styles.root}>
      <Header />
      <Stack sx={{ mt: 6 }}>
        <Typography align='center' variant='h1' paddingBottom={5} sx={styles.heading}>
          Help Center
        </Typography>
        <Box display='flex' flexDirection='row' justifyContent='center' gap={2.5}>
          {cards?.map(each => (
            <Button
              sx={{
                ...styles.headerTab,
                border: each.key === selectedView ? 0 : '1px solid'
              }}
              style={{
                backgroundColor: each.key === selectedView ? '#0D917DFF' : '#FFFFFF',
                borderColor: '#BDBDBD'
              }}
              onClick={() => history.push(each.link)}
              key={each?.title}>
              <Typography
                fontSize={16}
                fontWeight={each.key === selectedView ? 700 : 500}
                color={each.key === selectedView ? 'white.main' : 'gray.800'}>
                {each?.title}
              </Typography>
            </Button>
          ))}
        </Box>
      </Stack>
      <Divider sx={{ my: 4, mx: 20 }} />
      <Stack mx={17}>
        {selectedView === 'about' && <AboutContent />}
        {selectedView === 'faq' && <FaqContent />}
        {selectedView === 'guides' && <Guides />}
        {selectedView === 'whatsnew' && <WhatsNew />}
        {selectedView === 'videos' && <VideoList />}
      </Stack>
    </Stack>
  );
};

export default Banner;
