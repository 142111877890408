// eslint-disable-next-line no-unused-vars
import { useCallback, useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import {
  CATEGORY_SOURCE_MAP,
  MODULE_AND_CATEGORIES,
  MODULE_AND_SOURCES,
  SEARCH_TYPE_TEXT
} from '../const';

// Imports of store.
import Store from '../../../store';
import Actions from '../../../store/actions';

import getSearchPathParams from '../utils/getSearchPathParams';

const useSourceCategory = () => {
  const history = useHistory();
  const [sourceList, setSourceList] = useState(MODULE_AND_SOURCES.core);
  const [categoryList, setCategoryList] = useState(MODULE_AND_SOURCES.core[0].categories);
  const [clearSearch, setClearSearch] = useState(false);

  const [selectedSource, setSelectedSource] = useState(MODULE_AND_SOURCES.core[0]);
  const [selectedCategory, setSelectedCategory] = useState(
    MODULE_AND_SOURCES.core[0].categories[0]
  );
  const [selectedCategoryType, setSelectedCategoryType] = useState(SEARCH_TYPE_TEXT);

  const { state, dispatch } = useContext(Store);

  useEffect(() => {
    let defaultModuleSources = MODULE_AND_SOURCES[state.module];

    if (!defaultModuleSources) {
      // some modules can have only categories
      const moduleCategories = MODULE_AND_CATEGORIES[state.module];
      if (!moduleCategories) {
        return; // which does not have searching
      }
      setSourceList([]);
      setSelectedSource(null);
      setCategoryList(moduleCategories);
      setSelectedCategory(moduleCategories[0]);
      return;
    }

    // Set default values
    let defaultSource = defaultModuleSources[0];
    let defaultCategories = defaultSource.categories;
    let defaultCategory = defaultCategories[0];

    // Since header is outside the Route, need to match the location.pathname with result page path name
    const params = getSearchPathParams(history.location.pathname);
    const searchParams = new URLSearchParams(history.location.search);
    // Set the default values from the URL.
    if (params) {
      const { source, module } = params;
      let { category } = params;
      if (!category && searchParams.has('category')) {
        category = searchParams.get('category');
      }
      defaultModuleSources = MODULE_AND_SOURCES[module] || MODULE_AND_SOURCES[state.module];
      if (defaultModuleSources) {
        defaultSource =
          defaultModuleSources.find(
            src => src.urlRouteParam?.toLowerCase() === source?.toLowerCase()
          ) || defaultModuleSources[0];

        defaultCategories = defaultSource.categories;
        defaultCategory =
          defaultCategories.find(cat => cat.urlRouteParam === category) || defaultCategories[0];
      } else if (MODULE_AND_CATEGORIES[module]) {
        setCategoryList(MODULE_AND_CATEGORIES[module]);
      }
      if (searchParams.has('category')) {
        searchParams.delete('category');
      }
      if (searchParams.has('source')) {
        searchParams.delete('source');
      }
      // eslint-disable-next-line no-restricted-globals
      window.history.replaceState(null, '', `${location.pathname}?${searchParams.toString()}`);
    } else {
      const source = searchParams.get('source');
      const category = searchParams.get('category');
      defaultSource =
        defaultModuleSources.find(
          item => item.urlRouteParam?.toLowerCase() === source?.toLowerCase()
        ) || defaultModuleSources[0];
      defaultCategories = defaultSource.categories;
      defaultCategory =
        defaultCategories.find(cat => cat.urlRouteParam === category) || defaultCategories[0];
    }
    setSourceList(defaultModuleSources);
    setSelectedSource(defaultSource);

    setCategoryList(defaultCategories);
    setSelectedCategory(defaultCategory);

    dispatch({
      type: Actions.SET_DB,
      value: defaultSource.db
    });
  }, [state.module, dispatch]); // ignore history.location.pathname in the dependency list, we need it do only one time
  useEffect(() => {
    if (
      (selectedCategory && selectedSource) ||
      (selectedCategory && state.module === 'comparison')
    ) {
      // eslint-disable-next-line no-restricted-globals
      if (!location.pathname.startsWith('/search')) {
        // eslint-disable-next-line no-restricted-globals
        const updatedSearchParams = new URLSearchParams(location.search);
        if (state.module !== 'comparison') {
          updatedSearchParams.set('source', selectedSource?.urlRouteParam);
          updatedSearchParams.set('category', selectedCategory?.urlRouteParam);
        }
        window.history.replaceState(
          null,
          '',
          // eslint-disable-next-line no-restricted-globals
          `${location.pathname}?${updatedSearchParams?.toString()}`
        );
      }
      setSelectedCategoryType(selectedCategory?.searchType);
      if (document?.getElementById('quick-search-input')) {
        document?.getElementById('quick-search-input')?.focus();
      }
    }
    // eslint-disable-next-line no-restricted-globals
  }, [selectedCategory, selectedSource, location.pathname, location.search]);
  useEffect(() => {
    if (clearSearch) {
      setClearSearch(false);
    }
  }, [clearSearch]);
  const handleSourceChange = useCallback(
    event => {
      // Get the current module sources.
      const moduleSource = MODULE_AND_SOURCES[state.module];

      // Get user selected source from the module sources
      const selectedSourceMenuItem = moduleSource.find(item => item.value === event.target.value);

      // Set the selected source
      setSelectedSource(selectedSourceMenuItem);

      // Update the category list based on the user selection
      const { categories } = selectedSourceMenuItem;
      setCategoryList(categories);

      // When we change the source, previously selected category may not be present in the newly selected source
      // Set the first item as default value
      setSelectedCategory(prevState => {
        if (prevState.urlRouteParam in CATEGORY_SOURCE_MAP) {
          const categorySourceMap = CATEGORY_SOURCE_MAP[prevState.urlRouteParam];
          if (selectedSourceMenuItem.urlRouteParam in categorySourceMap) {
            const selectedValue = categories.find(
              cat => cat.urlRouteParam === categorySourceMap[selectedSourceMenuItem.urlRouteParam]
            );
            if (selectedValue) {
              return selectedValue;
            }
          }
        }
        setClearSearch(true);
        return categories[0];
      });
      // eslint-disable-next-line no-restricted-globals
      if (location.pathname.startsWith('/search/advance')) setClearSearch(true);
      else setClearSearch(false);
      // Update the DB; other sections of the page depends on this.
      const { db } = selectedSourceMenuItem;
      dispatch({
        type: Actions.SET_DB,
        value: db
      });
    },
    // eslint-disable-next-line no-restricted-globals
    [dispatch, state.module, history, selectedCategory.value, location.pathname, location.search]
  );

  const handleCategoryChange = useCallback(
    event => {
      // Update selected category
      const selectedCategoryItem = categoryList.find(cat => cat.value === event.target.value);
      setSelectedCategory(prevState => {
        if ('clearSearchTerm' in prevState && prevState?.clearSearchTerm === true) {
          setClearSearch(true);
        }
        return selectedCategoryItem;
      });
      if (selectedCategoryItem?.clearSearchTerm && selectedCategoryItem?.clearSearchTerm === true) {
        setClearSearch(true);
      }
      dispatch({
        type: Actions.SET_CATEGORY,
        value: selectedCategoryItem.urlRouteParam
      });
    },
    // eslint-disable-next-line no-restricted-globals
    [categoryList, dispatch, location.pathname, location.search, setClearSearch]
  );

  return {
    sourceList,
    categoryList,
    selectedSource,
    selectedCategory,
    selectedCategoryType,
    handleSourceChange,
    handleCategoryChange,
    clearSearchForSourceChange: clearSearch
  };
};

export default useSourceCategory;
