/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { capitalize, isEmpty, uniqueId } from 'lodash';

// types
// muiv5
import { Box, Button, Divider, Paper, Popper, Stack, Tooltip, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

// icons
import CloseIcon from '@mui/icons-material/Close';
import { UrlParameters } from '../../../types/reactRouterDom';

// styles
import styles from '../styles/SelectedFiltersBar';

// constants
import { DOCUMENT_STATE, filterOptions } from '../utils/constants';

// context
import AriaStore from '../../../store/Aria';

// custom hooks
import useQuery from '../../../helpers/customHooks/queryParameter';

import useAriaFilters from '../../../hooks/useAriaFilters';

const SelectedHoverChip = ({ item, removeFromFilters }: { item: any; removeFromFilters: any }) => {
  const { label, filterType, kendraType } = item;
  const [showPopper, setShowPopper] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const chipRef = React.useRef(null);

  // aria context store
  // @ts-ignore
  const { ariaState } = useContext(AriaStore);

  const handleClick = () => {
    setAnchorEl(chipRef.current);
    setShowPopper(true);
  };

  const handleClose = () => {
    setShowPopper(false);
  };

  return (
    <Box onMouseEnter={handleClick} onMouseLeave={handleClose}>
      <Button
        variant='contained'
        sx={styles.filterButton}
        endIcon={showPopper ? <KeyboardArrowUp /> : <KeyboardArrowDown />}>
        <Typography variant='subtitle1' fontWeight='medium'>
          {label}
        </Typography>
      </Button>
      <Popper open={showPopper && anchorEl !== null} anchorEl={anchorEl} placement='bottom-start'>
        <Paper elevation={1}>
          <Stack p={2} spacing={1.5} direction='column' alignItems='flex-start'>
            {filterType === 'range' ? (
              <Button
                key={uniqueId()}
                variant='contained'
                sx={styles.closeButton}
                startIcon={<CloseIcon sx={styles.closeIcon} />}
                onClick={() =>
                  removeFromFilters(
                    kendraType,
                    ariaState.filters[kendraType][0],
                    ariaState.filters[kendraType][1]
                  )
                }>
                <Typography noWrap variant='subtitle2'>
                  From {ariaState.filters[kendraType][0]} to {ariaState.filters[kendraType][1]}
                </Typography>
              </Button>
            ) : (
              ariaState.filters[kendraType]?.map((val: any) => {
                return (
                  <Button
                    key={uniqueId()}
                    variant='contained'
                    sx={styles.closeButton}
                    startIcon={<CloseIcon sx={styles.closeIcon} />}
                    onClick={() => removeFromFilters(kendraType, val)}>
                    {val.length > 15 ? (
                      <Tooltip title={val}>
                        <Typography noWrap variant='subtitle2'>
                          {label === 'Monoclonal Antibody'
                            ? capitalize(val)
                            : val.length > 15
                            ? `${val.substring(0, 15)}...`
                            : val}
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography noWrap variant='subtitle2'>
                        {label === 'Monoclonal Antibody'
                          ? capitalize(val)
                          : val.length > 15
                          ? `${val.substring(0, 15)}...`
                          : val}
                      </Typography>
                    )}
                  </Button>
                );
              })
            )}
          </Stack>
        </Paper>
      </Popper>
      <Box ref={chipRef} />
    </Box>
  );
};

const SelectedFilterBar = () => {
  const query = useQuery();
  const { removeFromFilters, applyFilter, clearFilters } = useAriaFilters();
  const [clear, setClear] = useState<boolean>(false);

  const { module, source, searchId } = useParams<UrlParameters>();
  const { pathname } = useLocation();
  // aria context store
  // @ts-ignore
  const { ariaState } = useContext(AriaStore);

  /**
   * Wrapper for removeFromFilters to call applyFilter
   * @param {string} type filter category type
   * @param {string} value option value that needs to be removed
   * @param {string} filterType filter type
   */
  const removeFromFiltersWrapper = (type: string, value: string, filterType: string) => {
    removeFromFilters(type, value, filterType);
    applyFilter(
      query,
      module as string,
      source as string,
      searchId as string,
      pathname,
      undefined,
      setClear
    );
  };

  useEffect(() => {
    if (clear) {
      applyFilter(
        query,
        module as string,
        source as string,
        searchId as string,
        pathname,
        undefined,
        setClear
      );
    }
  }, [clear]);

  return !ariaState.pendingFilters && !isEmpty(ariaState.filters) ? (
    <Box
      sx={
        ariaState.documentOpen || ariaState.documentState !== DOCUMENT_STATE.close
          ? styles.rootAlternate
          : styles.root
      }>
      {ariaState.documentOpen || ariaState.documentState !== DOCUMENT_STATE.close ? (
        <Box>
          <Typography
            variant='subtitle2'
            onClick={() => clearFilters(setClear)}
            sx={styles.clearText}>
            CLEAR FILTERS
          </Typography>
          <Stack direction='row' spacing={1} sx={{ mt: 1, overflowX: 'auto' }}>
            {filterOptions[source as keyof typeof filterOptions]?.map((item: any) => {
              return item.kendraType in ariaState.filters ? (
                <SelectedHoverChip
                  key={uniqueId()}
                  item={item}
                  removeFromFilters={removeFromFiltersWrapper}
                />
              ) : null;
            })}
            {ariaState.filters.search_terms && ariaState.filters.search_terms.length !== 0 && (
              <SelectedHoverChip
                item={{
                  label: 'Search Keywords',
                  filterType: 'checkbox',
                  kendraType: 'search_terms'
                }}
                removeFromFilters={removeFromFiltersWrapper}
              />
            )}
          </Stack>
        </Box>
      ) : (
        <Stack direction='column' sx={{ overflowY: 'auto' }}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='subtitle1' fontWeight='bold'>
              FILTERS
            </Typography>
            <Typography
              variant='subtitle2'
              onClick={() => clearFilters(setClear)}
              sx={styles.clearText}>
              CLEAR FILTERS
            </Typography>
          </Stack>

          {filterOptions[source as keyof typeof filterOptions]?.map((item: any) => {
            return item.kendraType in ariaState.filters ? (
              <Box key={item.id} sx={{ mb: 1 }}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  {item.label}
                </Typography>
                <Box sx={styles.filterChips}>
                  {/* for range filters */}
                  {item.filterType === 'range' ? (
                    <Button
                      key={uniqueId()}
                      variant='contained'
                      sx={styles.closeButtonAlternate}
                      startIcon={<CloseIcon sx={styles.closeIcon} />}
                      onClick={() =>
                        removeFromFiltersWrapper(
                          item.kendraType,
                          ariaState.filters[item.kendraType][0],
                          item.filterType
                        )
                      }>
                      <Typography noWrap variant='subtitle2'>
                        From {ariaState.filters[item.kendraType][0]} to{' '}
                        {ariaState.filters[item.kendraType][1]}
                      </Typography>
                    </Button>
                  ) : (
                    ariaState.filters[item.kendraType]?.map((val: any) => {
                      return (
                        <Button
                          key={uniqueId()}
                          variant='contained'
                          sx={styles.closeButtonAlternate}
                          startIcon={<CloseIcon sx={styles.closeIcon} />}
                          onClick={() =>
                            removeFromFiltersWrapper(item.kendraType, val, item.filterType)
                          }>
                          {val.length > 15 ? (
                            <Tooltip title={val}>
                              <Typography noWrap variant='subtitle2'>
                                {item.label === 'Monoclonal Antibody'
                                  ? capitalize(val)
                                  : val.length > 15
                                  ? `${val.substring(0, 15)}...`
                                  : val}
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography noWrap variant='subtitle2'>
                              {item.label === 'Monoclonal Antibody'
                                ? capitalize(val)
                                : val.length > 15
                                ? `${val.substring(0, 15)}...`
                                : val}
                            </Typography>
                          )}
                        </Button>
                      );
                    })
                  )}
                </Box>
              </Box>
            ) : null;
          })}
          {ariaState.filters.search_terms && ariaState.filters.search_terms.length !== 0 && (
            <Box>
              <Typography variant='subtitle1' fontWeight='bold'>
                Search Keyword
              </Typography>
              {ariaState.filters.search_terms?.map((val: string) => (
                <Box key={val} sx={styles.filterChips}>
                  <Button
                    key={val}
                    variant='contained'
                    sx={styles.closeButtonAlternate}
                    startIcon={<CloseIcon sx={styles.closeIcon} />}
                    onClick={() => removeFromFiltersWrapper('search_terms', val, 'keywords')}>
                    <Typography noWrap variant='subtitle2'>
                      {val.length > 15 ? `${val.substring(0, 15)}...` : val}
                    </Typography>
                  </Button>
                </Box>
              ))}
            </Box>
          )}
          <Divider />
        </Stack>
      )}
    </Box>
  ) : null;
};
export default SelectedFilterBar;
