import { createSvgIcon } from '@mui/material';

const ReportIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
    <path d='M12.4918 2.91667H9.91699V0.341833L12.4918 2.91667ZM12.8337 4.08333V14H1.16699V1.75C1.16699 1.28587 1.35137 0.840752 1.67956 0.512563C2.00774 0.184374 2.45286 0 2.91699 0L8.75033 0V4.08333H12.8337ZM4.08366 9.33333H8.16699V8.16667H4.08366V9.33333ZM9.91699 10.5H4.08366V11.6667H9.91699V10.5ZM9.91699 5.83333H4.08366V7H9.91699V5.83333Z' />
  </svg>,
  'Report'
);

export default ReportIcon;
