import React, { useContext } from 'react';
import { Button, Collapse, Typography } from '@mui/material';
import { ArrowDownwardOutlined, ArrowUpwardOutlined } from '@mui/icons-material';
import DesignationStore from '../../../store/Designation';
import VerticalDesignationCard from './VerticalDesignationCard';
import { showMoreButtonStyle } from '../styles/designationCardBoxStyle';
import {
  DesignationCardBoxProps,
  VerticalDesignationCardProps
} from '../interface/payloadInterface';

const DesignationCardBox = ({
  designations,
  type,
  viewMode = false
}: {
  designations: DesignationCardBoxProps;
  type: string;
  // eslint-disable-next-line react/require-default-props
  viewMode?: boolean;
}) => {
  const { designationState } = useContext(DesignationStore);
  const [showMore, setShowMore] = React.useState(viewMode);
  return (
    <>
      {designations.slice(0, 3).map(obj => {
        const props: VerticalDesignationCardProps = {
          ...obj,
          key: `${obj.number}-${obj.activeIngredients}`,
          type,
          variant: obj.applicationType?.toLowerCase() === 'nda' ? 'red' : 'blue'
        };
        return <VerticalDesignationCard {...props} compactMode={viewMode} />;
      })}

      {designations.length > 3 && (
        <>
          <Collapse in={showMore || designationState.openAllSeeMore} unmountOnExit>
            {
              designations.slice(3, designations.length).map(obj => {
                const props: VerticalDesignationCardProps = {
                  ...obj,
                  key: `${obj.number}-${obj.activeIngredients}`,
                  type,
                  variant: obj.applicationType?.toLowerCase() === 'nda' ? 'red' : 'blue'
                };
                return <VerticalDesignationCard {...props} compactMode={viewMode} />;
              }) as any
            }
          </Collapse>
          {!viewMode && (
            <Button
              sx={showMoreButtonStyle}
              variant='outlined'
              endIcon={
                showMore || designationState.openAllSeeMore ? (
                  <ArrowUpwardOutlined />
                ) : (
                  <ArrowDownwardOutlined />
                )
              }
              onClick={() => setShowMore(!showMore)}>
              <Typography variant={'caption'}>
                Show {showMore || designationState.openAllSeeMore ? 'Less' : 'More'}
              </Typography>
            </Button>
          )}
        </>
      )}
    </>
  );
};
export default React.memo(DesignationCardBox);
