import getFormatDataForCSV from '../../ResultsPage/utils/getFormatDataForCSV';

const getProjectDetails = (rowData: any) => {
  if (Object.keys(rowData.amqp_details).length !== 0) {
    return getFormatDataForCSV([rowData.amqp_details]);
  }
  if (Object.keys(rowData.bmq_detail).length !== 0) {
    return getFormatDataForCSV([rowData.bmq_detail]);
  }
  if (Object.keys(rowData.coaqp_detail).length !== 0) {
    return getFormatDataForCSV([rowData.coaqp_detail]);
  }
  if (Object.keys(rowData.istand_detail).length !== 0) {
    return getFormatDataForCSV([rowData.istand_detail]);
  }
  return '';
};

export const getProjectDetailsForCSV = (appData: any[]) => {
  const results: Array<any> = [];
  if (appData) {
    appData.forEach((rowData: any) => {
      let projectDetails = {};
      if (Object.keys(rowData.amqp_details).length !== 0) {
        projectDetails = rowData.amqp_details;
      } else if (Object.keys(rowData.bmq_detail).length !== 0) {
        projectDetails = rowData.bmq_detail;
      } else if (Object.keys(rowData.coaqp_detail).length !== 0) {
        projectDetails = rowData.coaqp_detail;
      } else if (Object.keys(rowData.istand_detail).length !== 0) {
        projectDetails = rowData.istand_detail;
      }
      // eslint-disable-next-line no-param-reassign
      rowData.project_details = projectDetails;
      results.push(rowData);
    });
  }
  return results;
};

export const getSortList = (sort: any[]) => {
  const sortList: any = [];
  sort.forEach(item => {
    const newitem = { ...item };
    if (item.field === 'requestor') {
      newitem.field = 'requestor.first_name';
    }
    if (item.field === 'project_details') {
      newitem.field = 'program_type_desc';
    }
    sortList.push(newitem);
  });

  return sortList;
};

export default getProjectDetails;
