const styles = {
  guideButtonWrapper: {
    position: 'absolute',
    top: 'calc(100vh - 238px)',
    transform: 'rotateZ(270deg)',
    cursor: 'pointer',
    right: '-22px',
    borderRadius: '8px 8px 0px 0px',
    background: (theme: any) =>
      `linear-gradient(97deg, ${theme.palette.blue.background} 20.44%, ${theme.palette.secondary[450]} 93.48%)`,
    boxShadow: (theme: any) => `-1px 2px 6px 0px ${theme.palette.gray.lightVariant3}`,
    height: '24px',
    width: '68px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  guideText: {
    color: 'white.main',
    fontSize: '14px',
    fontWeight: 500
  },
  icon: {
    height: '12px',
    width: '12px',
    color: 'white.main',
    transform: 'rotateZ(90deg)'
  }
};

export default styles;
