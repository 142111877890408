import { createSvgIcon } from '@mui/material';

const UpArrowIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='13' height='12' viewBox='0 0 13 12' fill='none'>
    <path
      d='M8.26923 0C7.85438 0 7.51923 0.335156 7.51923 0.75C7.51923 1.16484 7.85438 1.5 8.26923 1.5H10.2075L5.48954 6.22031C5.19657 6.51328 5.19657 6.98906 5.48954 7.28203C5.78251 7.575 6.25829 7.575 6.55126 7.28203L11.2692 2.56172V4.5C11.2692 4.91484 11.6044 5.25 12.0192 5.25C12.4341 5.25 12.7692 4.91484 12.7692 4.5V0.75C12.7692 0.335156 12.4341 0 12.0192 0H8.26923ZM2.64423 0.75C1.60829 0.75 0.769226 1.58906 0.769226 2.625V10.125C0.769226 11.1609 1.60829 12 2.64423 12H10.1442C11.1802 12 12.0192 11.1609 12.0192 10.125V7.5C12.0192 7.08516 11.6841 6.75 11.2692 6.75C10.8544 6.75 10.5192 7.08516 10.5192 7.5V10.125C10.5192 10.3313 10.3505 10.5 10.1442 10.5H2.64423C2.43798 10.5 2.26923 10.3313 2.26923 10.125V2.625C2.26923 2.41875 2.43798 2.25 2.64423 2.25H5.26923C5.68407 2.25 6.01923 1.91484 6.01923 1.5C6.01923 1.08516 5.68407 0.75 5.26923 0.75H2.64423Z'
      fill='#707070'
    />
  </svg>,
  'UpArrowIcon'
);

export default UpArrowIcon;
