import React, { useMemo } from 'react';
// mui
import { Grid, Box, Typography, Tooltip } from '@mui/material';
// icons
import RightArrowIcon from '../../../assets/svgs/PDCO/RightArrow';
import LeftArrowIcon from '../../../assets/svgs/PDCO/LeftArrow';
// utils
import trimText from '../../../utils/trimText';
// styles
import styles from '../styles/SectionFlyout.styles';
// constants
import { SectionKeys, SECTIONS } from '../constants';

const SectionFlyout = ({
  activeSection,
  showSection,
  setShowSection,
  handleSectionClick,
  selectedMeetingSections
}: any) => {
  const sectionsKeyCount = useMemo(() => {
    const countMap: { [key: string]: number } = {};
    if (selectedMeetingSections) {
      Object.keys(selectedMeetingSections).forEach(key => {
        const count = selectedMeetingSections[key]?.length || 0;
        countMap[key] = count;
      });
    }
    return countMap;
  }, [selectedMeetingSections]);

  const handleView = () => {
    setShowSection(!showSection);
  };

  const getTextColor = (subsectionKeyValue: string) => {
    if (activeSection === subsectionKeyValue) {
      return 'primary.600';
    }
    if (sectionsKeyCount[subsectionKeyValue]) {
      return 'gray.800';
    }
    return 'gray.500';
  };

  return (
    <Grid
      item
      sm={showSection ? 2.2 : 1}
      md={showSection ? 3 : 1}
      lg={showSection ? 2.6 : 1}
      id='sectionFlyout'
      sx={styles.sectionFlyoutWrapper}>
      <Box onClick={handleView} sx={styles.hideView}>
        {showSection ? (
          <RightArrowIcon sx={{ fontSize: 10 }} />
        ) : (
          <LeftArrowIcon sx={{ fontSize: 10 }} />
        )}
        <Typography sx={styles.hideViewText}>{showSection ? 'Hide' : 'View'}</Typography>
      </Box>
      {/* Opinions & Discussion */}
      {(Object.keys(SECTIONS) as SectionKeys[]).map(sectionKey => {
        return (
          <Box key={sectionKey} sx={styles.sectionWrapper}>
            <Box>
              <Typography
                sx={{
                  ...styles.sectionKeyText,
                  color: activeSection[0] === sectionKey[0] ? 'primary.600' : 'gray.800',
                  fontWeight: activeSection[0] === sectionKey[0] ? '700' : '400'
                }}>
                {sectionKey}
              </Typography>
            </Box>
            <Box sx={styles.subSectionWrapper}>
              {SECTIONS[sectionKey].map((subsectionKey: any) => {
                return (
                  <Tooltip key={subsectionKey.value} title={subsectionKey?.label || ''}>
                    <Typography
                      onClick={() => {
                        if (subsectionKey?.value in sectionsKeyCount) {
                          handleSectionClick(subsectionKey?.value);
                        }
                      }}
                      sx={{
                        ...styles.subSectionText,
                        fontWeight: activeSection === subsectionKey?.value ? '700' : '400',
                        color: getTextColor(subsectionKey?.value),
                        '&:hover': {
                          cursor: subsectionKey?.value in sectionsKeyCount ? 'pointer' : null
                        }
                      }}>
                      {`${trimText(subsectionKey?.label, 22)} (${
                        sectionsKeyCount[subsectionKey?.value] || 0
                      })`}
                    </Typography>
                  </Tooltip>
                );
              })}
            </Box>
          </Box>
        );
      })}
    </Grid>
  );
};

export default React.memo(SectionFlyout);
