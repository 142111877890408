import React, { useContext } from 'react';
import PropTypes from 'prop-types';
// material ui imports
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import CircularProgress from '@material-ui/core/CircularProgress';
// other components
import PieChartStyles from '../styles/PieCharts.styles';
import CustomPieChart from '../../CustomComponents/Graphs/CustomPieChart';
// store
import Store from '../../../store';
import Actions from '../../../store/actions';

const PieCharts = ({ data }) => {
  const classes = PieChartStyles();
  const { state, dispatch } = useContext(Store);

  const addPieCharts = async () => {
    await dispatch({ type: Actions.SET_CART_ITEMS, value: state.cartItems + 1 });
    await dispatch({
      type: Actions.SET_REPORT,
      value: { id: 'Immunogenicity Pie Charts', data: data && data }
    });
    await dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message: 'Successfully added to Report.', color: 'success' }
    });
  };

  return (
    <>
      <Box
        mr={0}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        className={classes.root}>
        {data.length ? (
          <Box display='flex' flexDirection='column' alignItems='flex-start'>
            <>
              <Typography className={classes.headings}>
                Reporting status of immunogenicity data components{' '}
              </Typography>
              <Typography className={classes.headings}>
                Reported (R); No Report (NR); No Label (NL); No Assessment (NA); No Effect (NE);
                Effect (E); To be decided (TBD)
              </Typography>
            </>
          </Box>
        ) : (
          <Box display='flex' flexDirection='column' className={classes.skeleton}>
            <Skeleton
              variant='rect'
              width='40%'
              height={15}
              mt={2}
              className={classes.textSkeleton}
            />
          </Box>
        )}
      </Box>
      <Grid container spacing={0}>
        {data.length ? (
          data.map(item => (
            <Grid item lg={3}>
              <Box className={classes.container}>
                <Typography className={classes.text}>{item[0].label}</Typography>
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent=''
                  className={classes.graphContainer}>
                  {item?.length ? (
                    <CustomPieChart
                      data={item?.filter(obj => parseInt(obj.value) !== 0)}
                      innerRadius={0.7}
                      colors={[
                        '#2B43A6',
                        '#6868FA',
                        '#48E0A6',
                        '#C9D2F6',
                        '#B1BBE4',
                        '#EA5252',
                        '#29ABE2'
                      ]}
                    />
                  ) : (
                    <Box display='flex' justifyContent='center'>
                      <Typography> No Data </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          ))
        ) : (
          <>
            <Grid item lg={3}>
              <Box
                display='flex'
                justifyContent='center'
                flexDirection='row'
                className={classes.skeleton}>
                <CircularProgress
                  value={90}
                  thickness={5}
                  size={'10rem'}
                  variant='indeterminate'
                  className={classes.circleLoading}
                />
              </Box>
            </Grid>
            <Grid item lg={3}>
              <Box
                display='flex'
                justifyContent='center'
                flexDirection='row'
                className={classes.skeleton}>
                <CircularProgress
                  value={90}
                  thickness={5}
                  size={'10rem'}
                  variant='indeterminate'
                  className={classes.circleLoading}
                />
              </Box>
            </Grid>
            <Grid item lg={3}>
              <Box
                display='flex'
                justifyContent='center'
                flexDirection='row'
                className={classes.skeleton}>
                <CircularProgress
                  value={90}
                  thickness={5}
                  size={'10rem'}
                  variant='indeterminate'
                  className={classes.circleLoading}
                />
              </Box>
            </Grid>
            <Grid item lg={3}>
              <Box
                display='flex'
                justifyContent='center'
                flexDirection='row'
                className={classes.skeleton}>
                <CircularProgress
                  value={90}
                  thickness={5}
                  size={'10rem'}
                  variant='indeterminate'
                  className={classes.circleLoading}
                />
              </Box>
            </Grid>
          </>
        )}
        {/* </Box> */}
      </Grid>
    </>
  );
};

PieCharts.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};
export default PieCharts;
