/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useMemo } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import CircularProgress from '@mui/material/CircularProgress';

import { Box, IconButton, Tooltip } from '@mui/material';

import themev5 from '../../../themev5';

import styles from './styles/ShareLinks.styles';

// context
import Store from '../../../store';
import Actions from '../../../store/actions';

// hooks
import useShareLinks from '../../../hooks/useShareLinks';

// components
import BinderList from './BinderList';

// constants
import { linksEntity } from '../../../hooks/constants';
import CreateNewGroup from './CreateNewGroup';

import TabButton from '../../ui/TabButton';
import {
  AngleLeftIcon,
  CircleCheckedIcon,
  CloseCrossIcon,
  LinkIcon,
  PlusIcon
} from '../../../assets/svgs/Icons';
import { MAX_GROUP_NAME_LENGTH, MAX_MESSAGE_LENGTH } from './constants';

export const ShareLinks = () => {
  const { state, dispatch } = useContext(Store) as any;
  const [createNewGroup, setCreateNewGroup] = React.useState(false);
  const {
    binders,
    selectedBinders,
    handleSelectBinder,
    handleCopy,
    sendMessageToGroup,
    sendLoader,
    handleAdditionalText,
    additionalMessage,
    setBinderType,
    binderType,
    serviceRequestBinders,
    searchText,
    isLinkCopied,
    handleSearchText,
    clearSearchText,
    getBinders,
    loadingBinders,
    clearBinders
  } = useShareLinks();

  const handleClose = () => {
    dispatch({
      type: Actions.SET_SHARE_LINK,
      value: { link: '', open: false }
    });
  };
  const handleCloseCreateGroup = async () => {
    setCreateNewGroup(false);
    await getBinders();
  };

  const bindersToDisplay = useMemo(() => {
    return binders
      ?.filter(
        (binder: any) =>
          binder?.users?.some((userDetails: any) =>
            userDetails?.user.name.toLowerCase().trim().includes(searchText.toLowerCase().trim())
          ) || binder?.name.toLowerCase().trim().includes(searchText.toLowerCase().trim())
      )
      ?.sort((a: any, b: any) => b.updatedAt - a.updatedAt);
  }, [binders, searchText]);

  const title = useMemo(() => {
    if (createNewGroup) {
      return 'Create New Group';
    }
    return state.shareLink.entityType === 'file' ? 'Share file' : 'Share link';
  }, [createNewGroup, state.shareLink.entityType]);

  const noGroupsAvailable = binderType === 'chats' && binders?.length === 0;
  const noServiceRequestAvailable =
    binderType === 'serviceRequests' && serviceRequestBinders?.length === 0;

  return (
    <ThemeProvider theme={themev5}>
      <Dialog
        onClose={handleClose}
        sx={styles.dialog}
        aria-labelledby='customized-dialog-title'
        open={state.shareLink?.open}>
        <DialogTitle sx={{ p: 0 }}>
          {/* title name */}
          <Box sx={{ position: 'relative' }}>
            <Typography fontWeight={700} sx={styles.title}>
              {title}
            </Typography>
            {createNewGroup && (
              <IconButton sx={styles.backIcon} onClick={() => setCreateNewGroup(false)}>
                <AngleLeftIcon sx={{ fontSize: 18, color: 'gray.600' }} />
              </IconButton>
            )}

            <IconButton sx={styles.closeIcon} onClick={handleClose}>
              <CloseCrossIcon sx={{ fontSize: 20, color: 'gray.600' }} />
            </IconButton>
            <Divider sx={styles.titleDivider} />
          </Box>
          {/* Display the tabs if it is not create new group */}
          {!createNewGroup && (
            <>
              <Stack direction='row' py={3} px={4} spacing={1.5} alignItems='center' width='100%'>
                <TabButton
                  title='Collaborate'
                  tooltipText='Share link through Collaborate'
                  onClick={() => {
                    clearSearchText();
                    setBinderType('chats');
                    clearBinders();
                  }}
                  size='small'
                  selected={binderType === 'chats'}
                />
                <TabButton
                  title='Service Requests'
                  tooltipText='Share link through Service Requests'
                  onClick={() => {
                    clearSearchText();
                    setBinderType('serviceRequests');
                    clearBinders();
                  }}
                  size='small'
                  selected={binderType === 'serviceRequests'}
                />
              </Stack>
              <Divider sx={{ borderColor: 'gray.300' }} />
            </>
          )}

          {/* search organization */}
          {!createNewGroup && !noServiceRequestAvailable && (
            <Stack direction='row' py={3} px={4} pb={1.5} alignItems='center'>
              <Typography sx={styles.searchInputLabel}>To</Typography>
              <TextField
                id='search-group'
                placeholder='Search groups'
                variant='outlined'
                fullWidth
                disabled={
                  (binderType === 'chats' && binders?.length <= 0) ||
                  (binderType === 'serviceRequests' && serviceRequestBinders?.length <= 0)
                }
                value={searchText}
                onChange={handleSearchText}
                sx={styles.searchInput}
                InputProps={{
                  sx: styles.searchInputElementProp
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{ maxLength: MAX_GROUP_NAME_LENGTH }}
              />
              <Tooltip title='Create a new group'>
                <Button
                  variant='text'
                  onClick={() => setCreateNewGroup(!createNewGroup)}
                  startIcon={<PlusIcon sx={{ fontSize: '15px !important' }} />}
                  sx={styles.newButton}>
                  New
                </Button>
              </Tooltip>
            </Stack>
          )}
        </DialogTitle>
        {createNewGroup ? (
          <CreateNewGroup closeCreateGroupDialog={handleCloseCreateGroup} />
        ) : (
          <>
            <DialogContent sx={styles.paper}>
              <Stack sx={{ height: '75%' }} data-dd-privacy='mask'>
                {/* binder list */}
                {binderType === 'chats' && (
                  <List sx={{ width: '100%' }} disablePadding>
                    {bindersToDisplay?.map((binder: any) => (
                      <BinderList
                        key={binder.id}
                        binder={binder}
                        handleSelectBinder={handleSelectBinder}
                        selectedBinders={selectedBinders}
                      />
                    ))}
                  </List>
                )}
                {binderType === 'serviceRequests' && (
                  <List sx={{ width: '100%' }} disablePadding>
                    {serviceRequestBinders
                      ?.filter(
                        (binder: any) =>
                          binder?.users?.some((userDetails: any) =>
                            userDetails?.user.name
                              .toLowerCase()
                              .trim()
                              .includes(searchText.toLowerCase().trim())
                          ) ||
                          binder?.name
                            .toLowerCase()
                            .trim()
                            .includes(searchText.toLowerCase().trim())
                      )
                      ?.map((binder: any) => (
                        <BinderList
                          key={binder.id}
                          binder={binder}
                          handleSelectBinder={handleSelectBinder}
                          selectedBinders={selectedBinders}
                        />
                      ))}
                  </List>
                )}
                {noGroupsAvailable && !loadingBinders && (
                  <Typography variant='subtitle2' sx={styles.noBinder} mt={0}>
                    No grouped conversations available
                  </Typography>
                )}
                {noServiceRequestAvailable && !loadingBinders && (
                  <Typography variant='subtitle2' sx={styles.noBinder}>
                    No service requests available
                  </Typography>
                )}
                {loadingBinders && (
                  <Box sx={styles.loaderPosition}>
                    <CircularProgress sx={{ color: 'gray.400' }} />
                  </Box>
                )}
              </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 0, pb: 4 }}>
              {/* selected group chips and send button */}
              <Stack
                spacing={3}
                sx={{
                  width: '100%'
                }}>
                <Divider sx={{ borderColor: 'gray.300' }} />
                <Box px={4}>
                  <TextField
                    id='outlined-basic'
                    fullWidth
                    placeholder='Add a message'
                    variant='standard'
                    size='small'
                    disabled={selectedBinders?.length <= 0}
                    value={additionalMessage}
                    onChange={handleAdditionalText}
                    sx={styles.messageInput}
                    InputProps={{
                      disableUnderline: true,
                      sx: styles.messageInputProp
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{
                      maxLength: MAX_MESSAGE_LENGTH
                    }}
                    error={additionalMessage?.length >= MAX_MESSAGE_LENGTH}
                    helperText={
                      additionalMessage?.length >= MAX_MESSAGE_LENGTH &&
                      `Maximum of ${MAX_MESSAGE_LENGTH} characters allowed.`
                    }
                  />
                </Box>
                <Stack direction='row' alignItems='center' px={4}>
                  {linksEntity.includes(state.shareLink?.entityType) && !createNewGroup && (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                      {!isLinkCopied ? (
                        <Box
                          onClick={() => handleCopy(state.shareLink.entityDetails.link)}
                          sx={styles.copyLinkContainer}>
                          <LinkIcon sx={{ fontSize: 15 }} />
                          <Typography component='span' sx={styles.copyLink}>
                            Copy Link
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          onClick={() => handleCopy(state.shareLink.entityDetails.link)}
                          sx={styles.copyLinkContainer}>
                          <CircleCheckedIcon sx={{ fontSize: 15 }} />
                          <Typography component='span' sx={styles.copyLink}>
                            Link Copied!
                          </Typography>
                        </Box>
                      )}
                    </>
                  )}
                  <LoadingButton
                    sx={styles.sendButton}
                    onClick={sendMessageToGroup}
                    loading={sendLoader}
                    loadingPosition='end'
                    disabled={selectedBinders?.length <= 0}
                    variant='contained'
                    size='small'
                    color='primary'>
                    Send
                  </LoadingButton>
                </Stack>
              </Stack>
            </DialogActions>
          </>
        )}
      </Dialog>
    </ThemeProvider>
  );
};

export default React.memo(ShareLinks);
