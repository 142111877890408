import { Button, Stack, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { InfoOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useRef, useState } from 'react';
import styles from '../styles/SearchBarAutoComplete.styles';

const RelevantTerms = ({
  relevantTerms,
  relevantTermsRef,
  setSearchHighlighted,
  handleSearchRelevantTerms,
  relevantTermUsed
}: any) => {
  const [visibleButtons, setVisibleButtons] = useState<any[]>(relevantTerms);
  const [overflowCount, setOverflowCount] = useState<number>(0);
  const buttonWidths = useRef<number[]>([]); // Store button widths
  const buttonRefs = useRef<any[]>([]); // References to the buttons
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openVisibleButton = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setSearchHighlighted(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Calculate and handle overflow
  const checkOverflow = () => {
    const container = relevantTermsRef.current;
    if (!container || buttonWidths.current.length === 0) return;

    let containerWidth = container.offsetWidth - 85;
    if (containerWidth > 550) containerWidth = 430;

    let totalWidth = 0;
    let visibleCount = 0;
    // Use pre-calculated button widths
    buttonWidths.current.forEach(width => {
      totalWidth += width;
      if (totalWidth <= containerWidth) {
        // eslint-disable-next-line no-plusplus
        visibleCount++;
      }
    });
    if (visibleCount === 0) {
      visibleCount = 1;
    }
    setVisibleButtons(relevantTerms.slice(0, visibleCount));
    setOverflowCount(relevantTerms.length - visibleCount);
  };

  useEffect(() => {
    const buttonWidthsTemp: number[] = [];

    buttonRefs.current.forEach((button: HTMLElement, index: number) => {
      if (button) {
        let offsetWidth = 0;
        if (index === 0) {
          offsetWidth = 130;
        } else if (index === buttonRefs.current.length - 1) {
          offsetWidth = 40;
        }
        const buttonWidth = button.offsetWidth + offsetWidth;
        const marginRight = parseFloat(window.getComputedStyle(button).marginRight);
        buttonWidthsTemp.push(buttonWidth + marginRight);
      }
    });

    // Store button widths only once
    buttonWidths.current = buttonWidthsTemp;

    checkOverflow(); // Calculate initially based on the container size
    const timeoutId = setTimeout(() => {
      checkOverflow(); // Calculate again after the button widths are set
    }, 100);
    const timeoutId1 = setTimeout(() => {
      checkOverflow(); // Calculate again after the button widths are set
    }, 200);
    return () => {
      clearTimeout(timeoutId);
      clearTimeout(timeoutId1);
    };
  }, [relevantTerms]);

  useEffect(() => {
    // Create a ResizeObserver to monitor container size changes
    const resizeObserver = new ResizeObserver(() => {
      checkOverflow();
    });

    if (relevantTermsRef.current) {
      resizeObserver.observe(relevantTermsRef.current);
    }

    // Clean up observer
    return () => {
      resizeObserver.disconnect();
    };
  }, [relevantTermsRef]);
  return (
    <Stack
      onMouseOver={() => setSearchHighlighted?.(false)}
      direction='row'
      ref={relevantTermsRef}
      alignItems='center'
      sx={styles.relevantTermsContainer}>
      <Typography sx={styles.relevantTermsContainerText}>Broader Categories</Typography>
      <Tooltip
        title={`Explore broader MeSH categories related to your search: ${relevantTermUsed} to expand your search scope and include more relevant applications.`}>
        <InfoOutlined fontSize='small' sx={styles.relevantTermsIcon} />
      </Tooltip>
      :
      {visibleButtons.map(({ term, description }: any, index: number) => (
        <>
          <Tooltip title={description} key={term}>
            <Button
              type='button'
              variant='text'
              onClick={() => {
                handleSearchRelevantTerms?.(term);
              }}
              // eslint-disable-next-line no-return-assign
              ref={el => (buttonRefs.current[index] = el)}
              sx={styles.relevantTermsButton}>
              <Typography noWrap overflow='ellipsis'>
                {term}
              </Typography>
            </Button>
          </Tooltip>
          {index < visibleButtons.length - 1 && <Box sx={styles.relevantTermsDivider}>|</Box>}
        </>
      ))}
      {overflowCount > 0 && (
        <>
          <Box sx={styles.relevantTermsDivider}>|</Box>
          <Button
            type='button'
            variant='text'
            aria-controls={openVisibleButton ? 'visible-button-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={openVisibleButton ? 'true' : undefined}
            onClick={handleClick}
            sx={{ ...styles.relevantTermsButton, textDecoration: 'underline' }}>
            +{overflowCount} more
          </Button>
          <Menu
            id='visible-button-menu'
            anchorEl={anchorEl}
            open={openVisibleButton}
            onClose={handleClose}
            MenuListProps={{ 'aria-labelledby': 'visible-button-menu' }}>
            {relevantTerms.slice(visibleButtons.length).map(({ term, description }: any) => (
              <Tooltip title={description} key={term} placement='right'>
                <MenuItem
                  onClick={() => {
                    handleSearchRelevantTerms?.(term);
                  }}
                  sx={{ ...styles.relevantTermsButton, width: '100%' }}>
                  <Typography noWrap overflow='ellipsis'>
                    {term}
                  </Typography>
                </MenuItem>
              </Tooltip>
            ))}
          </Menu>
        </>
      )}
    </Stack>
  );
};
export default RelevantTerms;
