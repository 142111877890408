/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
import * as React from 'react';
import { useEffect } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Typography, Box, Paper, Popper } from '@mui/material';

interface GridCellExpandProps {
  value: any;
  width: number;
}

const isOverflown = (element: Element): boolean => {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
};
export const valueMapperList = (value: any) => {
  const finalArray: any = [];
  if (value) {
    value.forEach((item: any) => {
      if (item) {
        const localArray: string[] = [];
        if (item.measure === 'NA' && item.description === '') {
          return;
        }
        // eslint-disable-next-line no-param-reassign
        if (item.description == null) item.description = 'Not Available';
        // eslint-disable-next-line no-param-reassign
        if (!item.time_frame) item.time_frame = 'Not Available';
        localArray.push(`${item.measure} : ${item.description} : ${item.time_frame}`);
        if (localArray.length > 0) {
          finalArray.push(localArray);
        }
      }
    });
  }
  return finalArray;
};

const GridCellExpand = React.memo((props: GridCellExpandProps) => {
  const { width, value } = props;
  const wrapper = React.useRef<HTMLDivElement | null>(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);
  const [description, setDescription] = React.useState([]);
  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current!);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    const handleKeyDown = (nativeEvent: KeyboardEvent) => {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  useEffect(() => {
    if (value) {
      const finalArray = valueMapperList(value);
      if (finalArray.length > 0) {
        setDescription(finalArray);
      }
    }
  }, [value]);

  if (description.length > 0) {
    return (
      <Box
        ref={wrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          alignItems: 'center',
          lineHeight: '24px',
          width: 1,
          height: 1,
          position: 'relative',
          display: 'flex'
        }}>
        <Box
          ref={cellDiv}
          sx={{
            height: 1,
            width,
            display: 'block',
            position: 'absolute',
            top: 0
          }}
        />
        <Box
          ref={cellValue}
          sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          className='cellValue'>
          {description?.map(item => {
            // @ts-ignore
            return item?.join(', ');
          })}
        </Box>
        {showPopper && (
          <Popper
            open={showFullCell && anchorEl !== null}
            anchorEl={anchorEl}
            placement='bottom'
            style={{ width }}>
            <Paper
              elevation={1}
              sx={{
                maxHeight: 300,
                overflowY: 'auto',
                minHeight: wrapper.current!.offsetHeight - 3,
                borderRadius: '8px',
                p: 1,
                width: 400,
                ml: -10
              }}>
              {description?.map((item, index) => (
                <Box
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  mt={index % 2 === 0 ? '1em' : 0}>
                  {
                    // @ts-ignore
                    item?.map((measure: string) => (
                      <Box
                        key={measure}
                        sx={{
                          width: '100%',
                          textAlign: 'left',
                          backgroundColor: 'primary.backgroundDark',
                          marginBottom: '10px',
                          borderRadius: '16px',
                          padding: 2,
                          textTransform: 'capitalize'
                        }}>
                        <Typography style={{ fontSize: '14px' }}>
                          {measure.includes(' : ') ? (
                            <>
                              <b>{measure.split(' : ')[0]}</b>
                              <br />
                            </>
                          ) : (
                            measure
                          )}
                          {measure.includes(' : ') ? (
                            <>
                              <br />
                              <b>Description:</b> {measure.split(' : ')[1]}
                            </>
                          ) : (
                            ''
                          )}
                          {measure.includes(' : ') && measure.split(':').length > 2 ? (
                            <>
                              <br />
                              <b>Time Frame:</b> {measure.split(' : ')[2]}
                            </>
                          ) : (
                            ''
                          )}
                        </Typography>
                      </Box>
                    ))
                  }
                </Box>
              ))}
            </Paper>
          </Popper>
        )}
      </Box>
    );
  }
  return <Typography>---</Typography>;
});

const DatagridChipsPopup = (params: GridRenderCellParams) => {
  return <GridCellExpand value={params.value || []} width={params.colDef.computedWidth} />;
};

export default DatagridChipsPopup;
