import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import { useHistory, useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { Logout } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import themev5 from '../../themev5';
import AnimatedLogo from '../../assets/svgs/AnimatedLogo';
import capitalize from 'lodash/capitalize';

const SessionLimitError = () => {
  const [error, setError] = useState<boolean>(false);
  const [organization, setOrganization] = useState<string>('');
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/collaborate') {
      // allow user to access chat
      setError(false);
    }
    // eslint-disable-next-line no-unused-vars
    const handleApiError = (errorMessage: Error) => {
      if (location.pathname === '/collaborate') {
        setError(false);
      } else {
        setError(true);
      }
    };
    // Listen for API errors globally
    // @ts-ignore
    window.addEventListener('apiError', handleApiError);

    return () => {
      // Clean up event listener
      // @ts-ignore
      window.removeEventListener('apiError', handleApiError);
    };
  }, [location]);
  useEffect(() => {
    setOrganization(localStorage.getItem('organization') ?? '');
  }, []);

  // Handle the error here. You can display an error message or take other actions.
  return (
    <ThemeProvider theme={themev5}>
      <Dialog
        open={error}
        PaperProps={{ style: { padding: 2, minWidth: '20vw', borderRadius: '40px' } }}>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Tooltip title='Logout'>
              <IconButton
                onClick={() => {
                  setError(false);
                  history.push('/logout');
                }}>
                <Logout />
              </IconButton>
            </Tooltip>
          </Box>

          <Box textAlign='center' sx={{ '& svg': { filter: 'grayscale(1)' }, mt: -2 }}>
            <AnimatedLogo width='256' height='256' />
          </Box>
          <Box sx={{ mt: -5 }}>
            <DialogContentText variant='h6' align='center' sx={{ mt: 1, mb: 1 }}>
              Maximum Concurrent User Capacity Reached!
            </DialogContentText>
            <DialogContentText
              variant='subtitle1'
              align='center'
              sx={{
                lineHeight: '1.25'
              }}>
              RIA has reached{' '}
              {organization && (
                <Button variant='text' sx={{ textTransform: 'none', p: 0 }}>
                  {capitalize(organization)}'s
                </Button>
              )}{' '}
              concurrent user limit. Due to active application use, this is temporary. <br /> Try
              again later or use{' '}
              <Button
                variant='text'
                sx={{ textTransform: 'none', p: 0 }}
                onClick={() => {
                  setError(false);
                  history.push('/collaborate');
                }}>
                Collaborate
              </Button>{' '}
              to request your team members to logout so you can use RIA.
            </DialogContentText>
          </Box>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default SessionLimitError;
