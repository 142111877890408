const styles: { [key: string]: any } = {
  textAlignment: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  },
  dialog: {
    '& .MuiDialog-paper': {
      width: '80%',
      maxWidth: '100%',
      minHeight: '50%',
      maxHeight: '75%'
    }
  },
  heading: {
    color: 'primary.dark',
    padding: '13px 10px',
    borderRadius: '10px 10px 0px 0px',
    marginBottom: 'auto',
    fontSize: 18,
    fontWeight: '600'
  },
  tabs: {
    width: '100%',
    maxHeight: '100%',
    height: '100%',
    padding: '20px'
  },
  title: {
    paddingTop: '5px',
    textDecoration: 'underline',
    fontSize: 16,
    fontFamily: 'Nunito Sans',
    color: 'primary.dark',
    fontWeight: '600',
    cursor: 'pointer'
  },
  titleWithoutLink: {
    paddingTop: '5px',
    fontSize: 16,
    fontFamily: 'Nunito Sans',
    color: 'gray.750',
    fontWeight: '600'
  },
  chips: {
    fontSize: 12,
    fontFamily: 'Nunito Sans',
    padding: '7px 18px',
    width: 'fit-content',
    fontWeight: 'bold',
    marginRight: '10px',
    color: 'gray.main',
    border: '1px solid #C9D2F6',
    borderRadius: 5,
    textAlign: 'center',
    backgroundColor: 'white.main'
  },
  button: {
    fontSize: '14px',
    fontFamily: 'Nunito Sans',
    padding: '7px 18px',
    width: 'fit-content',
    border: 1,
    borderColor: 'primary.main',
    borderRadius: 2,
    borderStyle: 'solid',
    textAlign: 'center',
    backgroundColor: 'white.main',
    textTransform: 'capitalize',
    color: 'black.darkVariant',
    fontWeight: '400',
    cursor: 'pointer',
    '&:hover': {
      borderColor: 'gray.lightVariant3',
      boxShadow: 'none',
      bgcolor: 'primary.main',
      color: 'white.main'
    }
  },
  close: {
    width: '100%',
    paddingTop: 2,
    paddingRight: 2,
    background: 'primary.background',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  pdfCellText: {
    fontSize: 14,
    color: 'primary.600',
    fontWeight: '700'
  },
  pdfIconWrapper: {
    padding: '0.2rem',
    width: '40px',
    height: '40px'
  }
};

export default styles;
