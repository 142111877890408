import actions from './actions';

const reducers = (state, action) => {
  switch (action.type) {
    case actions.SET_LOADING: {
      return {
        ...state,
        loading: action.value
      };
    }
    case actions.SET_ORIGINAL_RESULTS: {
      return {
        ...state,
        originalResults: action.value
      };
    }
    case actions.SET_ARIA_RESULTS: {
      return {
        ...state,
        ariaResults: action.value
      };
    }
    case actions.SET_SUGGESTED_ANS: {
      return {
        ...state,
        suggestedAns: action.value
      };
    }
    case actions.SET_DOCUMENT_OPEN: {
      return {
        ...state,
        documentOpen: action.value
      };
    }
    case actions.SET_DOCUMENT_LINK: {
      return {
        ...state,
        documentLink: action.value
      };
    }
    case actions.SET_ORIGINAL_LANG_DOCUMENT_LINK: {
      return {
        ...state,
        originalLangDocumentLink: action.value
      };
    }
    case actions.SET_DOCUMENT_STATE: {
      return {
        ...state,
        documentState: action.value
      };
    }
    case actions.SET_GLOBAL_FILTERS: {
      return {
        ...state,
        globalFilters: action.value
      };
    }
    case actions.SET_FILTERS: {
      return {
        ...state,
        filters: action.value
      };
    }
    case actions.SET_FILTERS_DRAWER: {
      return {
        ...state,
        showFiltersDrawer: action.value
      };
    }
    case actions.SET_TOP_ANSWER: {
      return {
        ...state,
        topAnswer: action.value
      };
    }
    case actions.SET_ORIGNAL_TOP_ANSWER: {
      return {
        ...state,
        originalTopAnswer: action.value
      };
    }
    case actions.SET_ERROR: {
      return {
        ...state,
        error: action.value
      };
    }
    case actions.SET_PAGE: {
      return {
        ...state,
        page: action.value
      };
    }
    case actions.SET_MAX_PAGE: {
      return {
        ...state,
        maxPage: action.value
      };
    }
    case actions.SET_ORIGINAL_TOTAL_RESULTS: {
      return {
        ...state,
        originalTotalResults: action.value
      };
    }
    case actions.SET_TOTAL_RESULTS: {
      return {
        ...state,
        totalResults: action.value
      };
    }
    case actions.SET_EXPLORE_PAGE_RESULTS_COUNT: {
      return {
        ...state,
        explorePageResultsCount: action.value
      };
    }
    case actions.SET_SELECTED_SORT: {
      return {
        ...state,
        selectedSort: action.value
      };
    }
    case actions.SET_SELECTED_DOCUMENT: {
      return {
        ...state,
        selectedDocument: action.value
      };
    }
    case actions.SET_DID_YOU_MEAN_TEXT: {
      return {
        ...state,
        didYouMeanText: action.value
      };
    }
    case actions.SCROLL_TO_TOP: {
      return {
        ...state,
        scrollToTop: action.value
      };
    }
    case actions.SET_SEARCH_ID: {
      return {
        ...state,
        searchId: action.value
      };
    }
    case actions.SET_IS_FAVORITE: {
      return {
        ...state,
        isFavorite: action.value
      };
    }
    case actions.SET_IN_PROJECT: {
      return {
        ...state,
        inProjects: action.value
      };
    }
    case actions.SET_UNIQUE_RESULTS: {
      return {
        ...state,
        uniqueResults: action.value
      };
    }
    case actions.SET_COLLAPSED_SUGGESTION: {
      return {
        ...state,
        collapsedSuggestions: action.value
      };
    }
    case actions.SET_UNIQUE_RESULTS_COUNT: {
      return {
        ...state,
        uniqueResultsCount: action.value
      };
    }
    case actions.SET_PENDING_FILTERS: {
      return {
        ...state,
        pendingFilters: action.value
      };
    }
    case actions.SET_FAVORITE_ARIA_RESULT: {
      return {
        ...state,
        favoriteAriaResult: action.value
      };
    }
    case actions.SET_ORIGINAL_TEXT_ENABLED: {
      return {
        ...state,
        originalTextEnabled: action.value
      };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
