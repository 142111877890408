import makeRequest from '../client';

/**
 * Function to get PMR Split data.
 * @param {string} page
 */
export const getPMRSplitData = page => makeRequest(`/clignosis/pmr/${page}`, 'GET');

/**
 *
 * Function gets all data for PMR Surveillance module
 */
export const getPMRSurveillanceData = (filters, limit, pageNo) =>
  makeRequest(`/clignosis/pmr/surveillance?limit=${limit}&offset=${pageNo}`, 'POST', filters);
/**
 *
 * Function gets all data for pmr impact  module
 */
export const getPMRImpactData = (payload, limit, offset) =>
  makeRequest(`/clignosis/pmr/impact?limit=${limit}&offset=${offset}`, 'POST', payload);

export const getPMRImpactInsights = payload =>
  makeRequest(`/clignosis/pmr/impact/insights`, 'POST', payload);

export const getPMRImpactSignedURL = payload =>
  makeRequest('/clignosis/pmr/resource', 'POST', payload);

export const getAllPMRImpactDocuments = payload =>
  makeRequest('/clignosis/pmr/impact', 'POST', payload);

export const getPMRSurveillanceInsights = payload =>
  makeRequest(`/clignosis/pmr/surveillance/insights`, 'POST', payload);

export const getAllPMRSurveillanceDocuments = payload =>
  makeRequest('/clignosis/pmr/surveillance', 'POST', payload);
