import { createSvgIcon } from '@mui/material';

const DiagonalArrowIcon = createSvgIcon(
  <svg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_502_4477)'>
      <path
        d='M1.227 0.25H5.5V1.75H2.5605L12 11.1895L10.9395 12.25L1.5 2.8105V5.75H0V1.477C0.000396729 1.1517 0.129797 0.83984 0.359818 0.609818C0.58984 0.379797 0.901701 0.250397 1.227 0.25V0.25Z'
        fill='#92929D'
      />
    </g>
    <defs>
      <clipPath id='clip0_502_4477'>
        <rect width='12' height='12' fill='white' transform='translate(0 0.25)' />
      </clipPath>
    </defs>
  </svg>,
  'DiagonalArrow'
);

export default DiagonalArrowIcon;
