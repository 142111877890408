import TimelineColumnInterface from '../../../interfaces/TimelineColumnInterface';
import TimelineDate from '../../../components/Timeline/TimelineDate/TimelineDate';
import TimelineDocuments from '../../../components/Timeline/TimelineDocuments/TimelineDocuments';
import TimelineAvatars from '../../../components/Timeline/TimelineAvatars/TimelineAvatars';

export const COLUMNS_US: TimelineColumnInterface[] = [
  {
    width: 1.7,
    header: 'Version Date',
    data: 'version_date',
    name: 'version_date',
    rendercomponent: (
      <TimelineDate
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 1,
    header: 'Resources',
    data: 'file_links',
    name: 'file_links',
    rendercomponent: (
      <TimelineDocuments
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 3,
    header: 'Version Flags',
    data: 'rems',
    name: 'rems',
    rendercomponent: (
      <TimelineAvatars
        column={undefined}
        dataItem={undefined}
        triggeredBy='rems'
        source='placeholder'
      />
    )
  }
];

export const TIMELINE_COLUMNS: { [key: string]: TimelineColumnInterface[] } = {
  us: COLUMNS_US
};

export default COLUMNS_US;
