/* eslint-disable camelcase */
/**
 * function uses local storage to persist cached data for aria
 * @param {array} numberList contains list of applications required for aria search
 * @param {string} navigation contains the pathname for back button
 * @param {string} source data source of results page
 * @param {object} filterOptions contains the filters required for specific documents inside kendra index
 */
export const saveAriaCache = (numberList, navigation, source = 'us', filterOptions = {}) => {
  const aria_cache = {
    ariaFilterList: numberList,
    filterOptions,
    navigation,
    source
  };
  sessionStorage.setItem('aria_cache', JSON.stringify(aria_cache));
};

/**
 * function to store the url which will allow users to redirect to the same page where ARIA was opened
 * @param {string} pathname full path of current url
 * @param {object} history used for routing
 * @param {string} redirectUrl sends user to the aria page
 */
export const savePathnameForAriaBackBtn = (pathname, history, redirectUrl) => {
  const ariaCache = JSON.parse(sessionStorage.getItem('aria_cache')) || {};
  ariaCache.navigation = pathname;

  if (!redirectUrl.includes(ariaCache.source)) {
    if (ariaCache.source !== 'fda-letters') sessionStorage.removeItem('aria_cache');
  } else {
    sessionStorage.setItem('aria_cache', JSON.stringify(ariaCache));
  }
};
