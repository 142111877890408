import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  headings: {
    color: theme.palette.biologics.textPrimary,
    fontWeight: 'bold',
    fontSize: theme.spacing(2)
  },
  graph: {
    // display: 'grid',
    height: theme.spacing(40),
    width: theme.spacing(40),
    backgroundColor: 'white',
    marginBottom: theme.spacing(1),
    display: 'flex'
  },
  paper: {
    width: '100%'
  },
  circle: {
    gridRow: 1,
    gridColumn: 1,
    display: 'grid',
    alignItems: 'center',
    margin: 'auto'
  },
  bar: {
    gridRow: 1,
    gridColumn: 1,
    margin: '0 auto',
    zIndex: 1
  },
  track: {
    gridRow: 1,
    gridColumn: 1,
    margin: '0 auto',
    color: theme.palette.action.hover
  },
  text: {
    color: theme.palette.biologics.radialGraphText,
    fontSize: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  note: {
    color: theme.palette.biologics.textSecondary,
    fontWeight: 'medium',
    fontSize: theme.spacing(2)
  },
  add: {
    fontSize: theme.spacing(1.87),
    color: theme.palette.navbar.button,
    cursor: 'pointer'
  }
}));
