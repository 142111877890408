import React, { useCallback } from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
// icons
import CloseIcon from '@mui/icons-material/Close';
import DocumentIcon from '../../../Images/documentsIcon.svg';

// Styles
import styles from '../styles/CompareChip';

const CompareChip: React.FC<any> = ({ title, value, status, issueDate, onClose, style = {} }) => {
  const handleClose = useCallback(() => onClose(value), []);
  return (
    <Stack
      spacing={1}
      direction='row'
      justifyContent='flex-start'
      sx={{ ...styles.selectedOptionsContainer, ...style, borderColor: 'primary.main' }}>
      <img src={DocumentIcon} alt='documentLogo' />
      <Tooltip title={title}>
        <Typography noWrap variant='subtitle2' flex={3}>
          {title}
        </Typography>
      </Tooltip>
      <Tooltip title={`Status: ${status?.toUpperCase()}`}>
        <Typography noWrap variant='subtitle2' flex={1}>
          {status?.toUpperCase()}
        </Typography>
      </Tooltip>
      {issueDate && (
        <Tooltip title={`Issue Date: ${issueDate}`}>
          <Typography noWrap variant='subtitle2' flex={2}>
            {issueDate}
          </Typography>
        </Tooltip>
      )}
      <Box ml='auto !important' flex={0.5}>
        <CloseIcon cursor='pointer' fontSize='small' onClick={handleClose} />
      </Box>
    </Stack>
  );
};

export const CompareChipPlaceholder: React.FC<any> = ({ title }) => {
  return (
    <Stack
      spacing={1}
      direction='row'
      justifyContent='flex-start'
      sx={{ ...styles.selectedOptionsContainer, pt: 1.4, pb: 1.4 }}
    >
      <img src={DocumentIcon} alt='documentLogo' />
      <Tooltip title={title}>
        <Typography noWrap variant='subtitle2' flex={3} fontWeight='bold'>
          {title}
        </Typography>
      </Tooltip>
    </Stack>
  );
};

export default React.memo(CompareChip);
