import { createSvgIcon } from '@mui/material';

const HomeIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
    <path d='M8.00033 1.39941L0.666992 7.99967H2.66699V13.9997H6.66699V9.33301H9.33366V13.9997H13.3337V7.99967H15.3337L8.00033 1.39941Z' />
  </svg>,
  'Home'
);

export default HomeIcon;
