import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Avatar, Badge, Stack, Tab, Tabs, Tooltip } from '@mui/material';

import styles from '../styles/RightPart.styles';
import UserInfoPopover from './UserInfoPopover';
import { userInfoOnClick, userInfoOnClose } from '../utils/rightPartUtils';
import AuthContext from '../../../store/Auth/AuthProvider';
import Store from '../../../store';
import Actions from '../../../store/actions';
import topNavigationStyle from '../styles/TopNavigation.styles';
import tabMapping from './constants';

import HomeIcon from '../../../assets/svgs/Navbar/HomeIcon';
import CollaborateIcon from '../../../assets/svgs/Navbar/CollaborateIcon';
import ReportIcon from '../../../assets/svgs/Navbar/ReportIcon';
import { SubscribeIconFilled } from '../../../assets/svgs/Icons';
import { getUserNotificationsCount } from '../../../api/modules/userNotification';
import { stringAvatar } from '../../../helpers/utils';

const TopNavigation = () => {
  const { state, dispatch } = useContext<any>(Store);

  const location = useLocation();
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);
  const [value, setValue] = useState(0);

  const [isUserInfoOpen, setIsUserInfoOpen] = useState(false);
  const [userInfoAnchorEl, setUserInfoAnchorEl] = useState(null);

  const handleMoreHorizontalButtonClick = useCallback(
    (event: any) => userInfoOnClick(event, setIsUserInfoOpen, setUserInfoAnchorEl),
    []
  );
  const handleUserInfoPopupClose = useCallback(
    () => userInfoOnClose(setIsUserInfoOpen, setUserInfoAnchorEl),
    []
  );

  const fetchUserNotificationsCount = async () => {
    const currentTime = Date.now();
    const fiveMinutes = 5 * 60 * 1000;
    const lastFetchTime = localStorage.getItem('lastFetchTime');
    const lastFetchTimeInt = lastFetchTime ? parseInt(lastFetchTime, 10) : 0;
    // Skip fetching if last fetch time is less than 5 minutes
    if (currentTime - lastFetchTimeInt < fiveMinutes) return;
    try {
      const response = await getUserNotificationsCount();
      if (
        response?.status !== 200 ||
        response?.data?.error ||
        response?.data?.body === null ||
        response?.data?.body === undefined
      )
        throw new Error(response?.data?.message);
      // Update last fetch time in localStorage
      localStorage.setItem('lastFetchTime', currentTime.toString());
      if (response?.data?.body !== state.notificationsCount) {
        dispatch({
          type: Actions.SET_NOTIFICATION_COUNT,
          value: response?.data?.body ?? 0
        });
      }
    } catch (e) {
      dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: `Failed to fetch notifications count ${(e as Error)?.message}`,
          color: 'warning',
          duration: 5000
        }
      });
    }
  };

  const userInitials = useMemo(() => stringAvatar(currentUser?.['custom:user']), [currentUser]);

  useEffect(() => {
    const finalTabMapping: any = tabMapping;
    if (location.pathname.startsWith('/account')) {
      setValue(finalTabMapping.profile);
    } else if (location.pathname.startsWith('/notifications')) {
      setValue(finalTabMapping?.notifications);
    } else if (location.pathname.startsWith('/reports')) {
      setValue(finalTabMapping.reports);
    } else if (location.pathname.startsWith('/collaborate')) {
      setValue(finalTabMapping.collaborate);
    } else if (location.pathname.startsWith('/core') || location.pathname === '/') {
      setValue(finalTabMapping.home);
    } else {
      setValue(-1);
    }
    fetchUserNotificationsCount();
  }, [location.pathname]);

  return (
    <>
      <Tabs value={value} aria-label='right-nav-tabs' sx={topNavigationStyle.tabsStyles}>
        <Tab
          icon={<HomeIcon />}
          iconPosition='start'
          label='Home'
          onClick={() => history.push('/')}
        />
        <Tab
          icon={
            <Badge
              badgeContent={state.notificationsCount}
              max={50}
              color='error'
              sx={topNavigationStyle.menuDotBadge}>
              <SubscribeIconFilled sx={topNavigationStyle.subscribeMenuIcon} />
            </Badge>
          }
          iconPosition='start'
          label='Notifications'
          onClick={() => {
            dispatch({ type: Actions.SET_NOTIFICATION_COUNT, value: 0 });
            history.push('/notifications/notification');
          }}
        />
        <Tab
          icon={
            <Badge variant='dot' color='error' invisible={state.unreadMessages === 0}>
              <CollaborateIcon />
            </Badge>
          }
          iconPosition='start'
          label='Collaborate'
          onClick={() => {
            history.push('/collaborate');
          }}
        />
        <Tab
          icon={
            <Badge
              badgeContent={state.reportLayout.sections.length}
              color='error'
              sx={topNavigationStyle.menuDotBadge}>
              <ReportIcon />
            </Badge>
          }
          iconPosition='start'
          label='Reports'
          onClick={() => window.open('/reports', '_blank')}
        />
        <Tab
          id='profileIcon'
          onClick={handleMoreHorizontalButtonClick}
          label={
            <Tooltip title={currentUser?.['custom:user']}>
              <Stack flexDirection='row' justifyContent='space-between'>
                <Avatar variant='circular' sx={{ ...styles.avatarHeader }}>
                  {userInitials}
                </Avatar>
              </Stack>
            </Tooltip>
          }
        />
      </Tabs>

      <UserInfoPopover
        menuOpen={isUserInfoOpen}
        menuAnchorEl={userInfoAnchorEl}
        menuOnClose={handleUserInfoPopupClose}
      />
    </>
  );
};
export default TopNavigation;
