import { useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { OptionsProps } from './types';
import DateYearRange from './DateYearRange';
import { styles } from './styles';
import OptionFooter from './OptionFooter';

const DateYearRangeOptions = ({
  selectedCategory,
  selectedCategoryOptions,
  setSelectedCategoryOptions,
  handleClose,
  applyFilters,
  isLoading,
  setShowFilterSidebar,
  handleApplyClose,
  previousSelectedCategoryOptions,
  setPreviousSelectedCategoryOptions
}: OptionsProps) => {
  const [shouldRefreshDate, setShouldRefreshDate] = useState(false);
  return (
    <Box width={300} height={176}>
      <Box height={115}>
        <Box sx={styles.header} onClick={handleClose}>
          <Button sx={styles.closeIconButton}>
            <CloseIcon sx={{ ...styles.icon, fontSize: 20 }} />
          </Button>
        </Box>
        <Box mx={3}>
          <Typography fontSize={14} color='primary.700' sx={{ mb: 1.7 }}>
            {selectedCategory.label}
          </Typography>
          <DateYearRange
            rangeType={selectedCategory.filterType}
            selectedCategory={selectedCategory}
            selectedCategoryOptions={selectedCategoryOptions}
            setSelectedCategoryOptions={setSelectedCategoryOptions}
            previousSelectedCategoryOptions={previousSelectedCategoryOptions}
            setPreviousSelectedCategoryOptions={setPreviousSelectedCategoryOptions}
            shouldRefreshDate={shouldRefreshDate}
            setShouldRefreshDate={setShouldRefreshDate}
          />
        </Box>
      </Box>
      <Divider />
      <Box mx={3} height={60} sx={styles.footer}>
        <OptionFooter
          selectedCategory={selectedCategory}
          selectedCategoryOptions={selectedCategoryOptions}
          setSelectedCategoryOptions={setSelectedCategoryOptions}
          applyFilters={applyFilters}
          isLoading={isLoading}
          handleApplyClose={handleApplyClose}
          setShowFilterSidebar={setShowFilterSidebar}
          setShouldRefreshDate={setShouldRefreshDate}
        />
      </Box>
    </Box>
  );
};

export default DateYearRangeOptions;
