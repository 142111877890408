// Functional HOC using the useState hook
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// api
import regulatoryInformationAPI from '../../../api/pages/Regulatory360';

// context
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import RegulatoryActions from '../../../store/RegulatoryInformation/actions';
import useQuery from '../../../helpers/customHooks/queryParameter';
import getSubmissionName from '../../../components/Timeline/TimelineDocuments/getSubmissionName';
import getDocuments from '../../../components/Timeline/TimelineDocuments/getDocuments';
import getDateString from '../../../utils/getDateString';

interface ParameterProps {
  source: string;
  number: string;
}

const withFetchingResourcesDetails = (WrappedComponent: React.FunctionComponent) => {
  const getSubmissions = (documents: any[], src: string) => {
    return documents?.map((history: any) => ({
      id: getSubmissionName(history, src),
      name: getSubmissionName(history, src),
      getDocuments: () => getDocuments(history, src),
      metadata: [history.type, getDateString(history.date)]
    }));
  };
  return () => {
    // context store for aria state management
    const { regulatoryDispatch } = useContext(RegulatoryInformationStore) as any;

    // get the url parameters
    const { source, number } = useParams<ParameterProps>();
    const query: any = useQuery();
    useEffect(() => {
      const getData = async () => {
        try {
          const res = await regulatoryInformationAPI(source, number, query.get('tabname'));
          regulatoryDispatch({
            type: RegulatoryActions.SET_RESOURCES_DETAILS,
            value: res.data.body
          });
          regulatoryDispatch({
            type: RegulatoryActions.SET_RESOURCES_DETAILS_DOCUMENTS,
            value: getSubmissions(res.data.body, `${source}other_documents`)
          });
          regulatoryDispatch({
            type: RegulatoryActions.SET_RESOURCES_DETAILS_LOADING,
            value: false
          });
        } catch (e) {
          regulatoryDispatch({
            type: RegulatoryActions.SET_RESOURCES_DETAILS_LOADING,
            value: false
          });
          // eslint-disable-next-line no-console
          console.error(e);
        }
      };
      regulatoryDispatch({
        type: RegulatoryActions.SET_RESOURCES_DETAILS_LOADING,
        value: true
      });
      regulatoryDispatch({
        type: RegulatoryActions.SET_RESOURCES_DETAILS,
        value: []
      });
      regulatoryDispatch({
        type: RegulatoryActions.SET_RESOURCES_DETAILS_DOCUMENTS,
        value: []
      });
      getData();
    }, []);

    return <WrappedComponent />;
  };
};

export default withFetchingResourcesDetails;
