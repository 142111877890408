import { PIECHARTCOLOR } from '../../components/CustomComponents/Graphs/constant';
import { Filter } from '../../components/Filters';
import { FILTER_TYPE } from '../../components/NewFilters/constants';

export const CENTER = 'ALL';
export const LIMIT = 10;

export const MEETING_TABS = [
  {
    id: 'all',
    value: 'all',
    label: 'All Meetings'
  },
  {
    id: 'completed',
    value: 'completed',
    label: 'Completed'
  },
  {
    id: 'upcoming',
    value: 'upcoming',
    label: 'Upcoming'
  }
];

export const TABS = [
  {
    id: 'summary',
    value: 'summary',
    label: 'Summary',
    selectedBackgroundColor: '#33B187'
  },
  {
    id: 'roster',
    value: 'roster',
    label: 'Roster',
    selectedBackgroundColor: '#33B187'
  }
];

export interface EMAGuidancesFilters extends Filter {
  apiRequestKey: string;
  apiResponseKey?: string;
}

export const ADCOMM_FILTERS = [
  {
    id: 1,
    label: 'Center',
    value: 'center',
    elasticSearchType: 'center',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Committee',
    value: 'committee_title',
    elasticSearchType: 'committee_title',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Application Type',
    value: 'appl_type',
    elasticSearchType: 'appl_type',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Search Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Submission Type',
    value: 'submission_type',
    elasticSearchType: 'submission_type',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Active Ingredient',
    value: 'active_ingredient',
    elasticSearchType: 'active_ingredient',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 7,
    label: 'Trade Name',
    value: 'trade_name',
    elasticSearchType: 'trade_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 8,
    label: 'Device Name',
    value: 'device_name',
    elasticSearchType: 'device_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 9,
    label: 'Approval Pathway',
    value: 'approval_pathway',
    elasticSearchType: 'approval_pathway',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 10,
    label: 'Meeting Date',
    value: 'start_date',
    elasticSearchType: 'start_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  }
];

export const ADCOMM_COMMITTEE_MAPPING: { [key: string]: string | any } = {
  'All Committees': 'All',
  'Nonprescription Drug Advisory Committee': 'NDAC',
  'Oncologic Drugs Advisory Committee': 'ODAC',
  'Anesthetic and Analgesic Drug Products Advisory Committee': 'AADAC',
  'Antimicrobial Drugs Advisory Committee': 'AMDAC',
  'Antiviral Drugs Advisory Committee': 'AVDAC',
  'Arthritis Advisory Committee': 'ARDAC',
  'Bone, Reproductive and Urologic Drugs Advisory Committee': 'BRUDAC',
  'Cardiovascular and Renal Drugs Advisory Committee': 'CRDAC',
  'Dermatologic and Ophthalmic Drugs Advisory Committee': 'DODAC',
  'Drug Safety and Risk Management Advisory Committee': 'DSRMDAC',
  'Endocrinologic and Metabolic Drugs Advisory Committee': 'EMDAC',
  'Gastrointestinal Drugs Advisory Committee': 'GIDAC',
  'Medical Imaging Drugs Advisory Committee': 'MIDAC',
  'Peripheral and Central Nervous System Drugs Advisory Committee': 'PCNSDAC',
  'Pharmaceutical Science and Clinical Pharmacology Advisory Committee': 'PSCPDAC',
  'Pharmacy Compounding Advisory Committee': 'PCAC',
  'Psychopharmacologic Drugs Advisory Committee': 'PPDAC',
  'Pulmonary-Allergy Drugs Advisory Committee': 'PADAC',
  'Anti-Infective Drugs Advisory Committee': 'AIDAC',
  'Reproductive Health Drugs Advisory Committee': 'RHDAC',
  'Anesthetic & Life Support Drugs Advisory Committee': 'ALSDAC'
};

export const barChartStyles = {
  colors: ['#B1BBE4'],
  padding: 0.8,
  axisBottom: {
    tickRotation: 25
  }
};

export const pieChartStyles = {
  colors: PIECHARTCOLOR,
  margin: { top: 20, right: 20, bottom: 20, left: 20 },
  pieLabel: true,
  pieLegend: false,
  innerRadius: 0.7,
  enableLabels: 'both'
};

export const ADCOMM_REPORT_QUERIES = [
  {
    id: 'meeting_by_year',
    title: {
      title: 'Total Number of Meeting by Year',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['start_date'], agg_function: 'count', sort_on_axis: 'x' },
    graphStyle: {
      ...barChartStyles,
      axisLeft: {
        tickRotation: 0
      }
    }
  },
  {
    id: 'appl_type_pie_chart',
    title: {
      title: 'NDAs vs BLAs vs Other',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['appl_type'],
      agg_function: 'count',
      limit: 2
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'appl_type_post_acm',
    title: {
      title: 'Post Meeting Approvals',
      show: true,
      limit: 3
    },
    graph: 'PIE',
    query: {
      'x-axis': ['approval_decision'],
      agg_function: 'count'
    },
    graphStyle: pieChartStyles
  }
];

export const CENTER_OPTIONS: any = {
  CDER: { label: 'CDER', colorCode: 'blue.main' },
  CBER: { label: 'CBER', colorCode: 'black.main' },
  CDRH: { label: 'CDRH', colorCode: 'red.main' }
};

export const CENTER_MAPPING: any = {
  CDER: 'adcomm',
  CBER: 'adcomm-cber',
  CDRH: 'adcomm-cdrh'
};
