import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  main: {
    height: '100%',
    width: '100%'
  },
  GridTitle: {
    color: theme.palette.black,
    textAlign: 'left',
    // fontSize: '1rem',
    // fontWeight: '400',
    // margin: '10px 10px 0 10px',
    fontSize: theme.spacing(2),
    fontWeight: 'bold'
    // fontFamily: 'Lato',
    // color: theme.palette.biologics.textPrimary,
    // fontWeight: 'bold',
    // fontSize: theme.spacing(2.5),
    // fontFamily: 'Roboto-Medium',
  },

  GridContentBox: {
    height: '90%',
    width: '100%'
    // minWidth: '300px',
    // border:'red solid 2px'
  },

  GraphImage: {
    width: '300px',
    height: 'auto'
  },
  graphInnerGrid: {
    height: '100%',
    width: '100%',
    // backgroundColor : theme.palette.white,
    boxShadow: 'none',
    borderRadius: 0
  },
  graphInnerTitleGrid: {
    height: '5%',
    width: '100%',
    // backgroundColor : theme.palette.white,
    boxShadow: 'none',
    borderRadius: 0
  },
  graphInnergChartGrid: {
    height: '90%',
    width: '100%',
    // backgroundColor : theme.palette.white,
    boxShadow: 'none',
    borderRadius: 0
  },
  graphInnerMessageGrid: {
    height: '5%',
    width: '100%',
    // backgroundColor : theme.palette.white,
    boxShadow: 'none',
    borderRadius: 0
  },
  graphInnerMessage: {
    fontSize: '1.5vh',
    fontWeight: '500',
    color: theme.palette.black,
    margin: '5px'
  },
  graphInnerTitle: {
    fontSize: '1.8vh',
    fontWeight: '700',
    color: theme.palette.primary.main,
    padding: '5px'
    // marginTop: '-2vh',
  },
  chart: {
    height: '96%',
    width: '100%',
    // backgroundColor : theme.palette.white,
    boxShadow: 'none',
    borderRadius: 0
  },
  paper: {
    // boxShadow: 'none',
    // borderRadius: theme.spacing(2),
    height: '100%',
    // border:'red solid 2px',
    width: '100%'
    // overflowY: 'auto',
    // margin : '20px',
    // padding: '20px',
  },

  Dropdown: {
    width: '100%'
  },
  DropdownButton: {
    paddingLeft: '15px',
    width: '100%',
    marginTop: '1rem',
    marginBottom: '1rem',
    borderRadius: '15px',
    fontWeight: '400',
    textAlign: 'left',
    textTransform: 'none',
    backgroundColor: theme.palette.primary.lightBorder,
    color: theme.palette.black
  },
  DropdownButtonClosed: {
    paddingLeft: '15px',
    marginTop: '1rem',
    width: '100%',
    borderRadius: '15px',
    fontWeight: '400',
    textAlign: 'left',
    textTransform: 'none',
    backgroundColor: '#0E83DC',
    color: theme.palette.white
  },
  expand: {
    color: theme.palette.white,
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandClose: {
    color: theme.palette.black,
    transform: 'rotate(180deg)'
  },
  graphContainer: {
    // marginTop: theme.spacing(5),
    width: '100%',
    height: '40vh',
    color: '#1FBDCA'
    // border: 'red 2px solid'
  },
  graphBox: {
    width: '100%'
    // border: 'red 2px solid'
  },
  apptype: {
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '5vh',
    height: '8vh',
    display: 'flex',
    margin: '2.5vh 0'
  },
  percentage: {
    color: '#182B7F',
    fontWeight: 'bold',
    margin: '0 8px'
  },
  type: {
    color: '#0E83DC',
    fontWeight: '800'
  },
  details: {
    display: 'flex',
    width: '100%',
    margin: '13px 5px 0 5px'
  },
  paperContainer: {
    width: '100%',
    padding: '10px',
    // margin: '0px 10px',
    paddingTop: 'unset'
  },
  graphTitle: {
    marginLeft: theme.spacing(2),
    // padding: '0px 5px 15px',
    color: '#6D7D8A',
    fontWeight: '400',
    fontSize: '2vh'
  },
  graphTitlePie: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    // padding: '0px 5px 15px',
    color: '#6D7D8A',
    fontWeight: '400',
    fontSize: '2vh'
  },
  cards: {
    width: '100%',
    // border:'2px solid red'
    // paddingLeft: 'unset',
    padding: '15px 35px',
    height: '100%'
  },
  cardContent: {
    height: '100%'
  },
  paperCards: {
    // height: theme.spacing(20),
    // width: theme.spacing(20),
    height: '15vh',
    width: 'inherit',
    // width:'100%',
    // marginTop: theme.spacing(2),
    borderRadius: theme.spacing(1.5)
    // height: '20vh',
  },
  number: {
    fontSize: theme.spacing(3.5),
    color: theme.palette.biologics.textPrimary,
    fontWeight: 'bold'
  },
  cardText: {
    fontSize: '10px',
    // opacity:1,
    // fontWeight:'lighter',
    color: '#6D7D8A'
  }
}));
