import React, { Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Drawer from '@mui/material/Drawer';
import Close from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import SourceDropDown from '../../pages/Home/components/SourceDropDown';
import SearchBarAutoComplete from '../../pages/Home/components/SearchBarAutoComplete';

import { SourceDropdown } from '../../pages/Home/types';
import SearchIcon from '../../assets/svgs/Icons/SearchIcon';

import styles from '../../pages/Home/styles/SearchBar.styles';
import useSearchSuggestions from '../../pages/Home/hooks/useSearchSuggestions';
import { getGenericSearch } from '../../api/pages/ResultsPage';
import Cards from '../CustomCards/Cards';
import { CardData } from '../../pages/SearchResults/types';
import { apiDataToCardProps } from '../../pages/SearchResults/constants';
import { createSourceModulePayloadMapping } from '../../pages/Home/utils';
import RESULT_VIEW_TYPES from '../../pages/SearchResults/components/constants';
import EntitySelectionWindow from '../../pages/SearchResults/components/EntitySelectionWindow';
import EntityChips from './EntityChips';
import getUserDetails from '../../pages/SearchResults/utils/getUserDetails';
import AuthContext from '../../store/Auth/AuthProvider';
import entityHandler from '../../pages/SearchResults/utils/entityHandler';

const PopupSearchBarWithGenericResults = ({
  title,
  modalOpen,
  setModalOpen,
  sources,
  selectedSources,
  setSelectedSources,
  handleCardClick,
  type = 'reg360'
}: {
  title: string;
  modalOpen: string;
  setModalOpen: Dispatch<SetStateAction<string>>;
  sources: SourceDropdown[];
  selectedSources: SourceDropdown[];
  setSelectedSources: Dispatch<SetStateAction<SourceDropdown[]>>;
  // eslint-disable-next-line no-unused-vars
  handleCardClick: (data: CardData) => void;
  type: 'reg360' | 'cdp';
}) => {
  const { currentUser } = useContext(AuthContext);
  const [sourceDropDownAnchorEl, setSourceDropDownAnchorEl] = useState<null | HTMLElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEmptyMsg, setIsEmptyMsg] = useState<boolean>(false);
  const [entityDetected, setEntityDetected] = useState<boolean>(false);
  const [openEntityPopup, setOpenEntityPopup] = useState(false);
  const [entityList, setEntityList] = useState<any>([]);
  const [entityOptions, setEntityOptions] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [applicationData, setApplicationData] = useState<any>([]);
  const {
    searchSuggestions,
    isSuggestionsLoading,
    setIsSearchOpen,
    handleKeyPress,
    clearSearchSuggestions,
    isSearchOpen
  } = useSearchSuggestions(selectedSources);

  const sourceDropDownOpen = Boolean(sourceDropDownAnchorEl);

  const handleAutoCompleteOpen = useCallback(() => setIsSearchOpen(true), [setIsSearchOpen]);

  const handleAutoCompleteClose = () => {
    setIsSearchOpen(false);
    setEntityDetected(false);
    setEntityList([]);
    setEntityOptions([]);
  };

  const handleSourceChange = useCallback(
    (values: SourceDropdown[]) => {
      setSelectedSources(values);
      setApplicationData([]);
    },
    [setSelectedSources]
  );

  const handleSearch = async (value = searchText, isEntityText = false) => {
    setIsEmptyMsg(false);
    setIsSearchOpen(false);
    setIsLoading(true);
    const convertedSelectedSources = createSourceModulePayloadMapping(selectedSources);
    const userDetails = getUserDetails(currentUser);
    const payload: any = {
      query: value,
      source: convertedSelectedSources,
      view_type: RESULT_VIEW_TYPES.APPLICATION,
      userDetails
    };
    if (isEntityText) {
      payload.query = searchText;
      payload.search_term = value;
    }
    try {
      const res = await getGenericSearch(payload);
      if (res?.status === 200) {
        // check for entity detection
        if (res?.data?.body?.view_type === 'entity_popup') {
          const entitiesVal = res?.data?.body?.entities;
          const currentEntityList = entityHandler(entitiesVal);
          setEntityList(currentEntityList);
          setEntityDetected(true);
          setOpenEntityPopup(true);
        }
        const results = res?.data?.body?.result;
        let applList: any = [];
        Object.keys(results).forEach(source => {
          if (apiDataToCardProps[source]) {
            const sourceApps = results[source].results.map((appData: any) =>
              apiDataToCardProps[source]?.(appData)
            );
            applList = [...applList, ...sourceApps];
          }
        });
        applList = applList.filter(
          (appl: any) =>
            !(appl.source === 'us' && type === 'cdp' && appl.application_type === 'EUA')
        );
        setApplicationData(applList);
        if (applList && applList.length !== 0) {
          setIsEmptyMsg(false);
        } else {
          setIsEmptyMsg(true);
        }
      }
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const entityClick = async (entityText: string) => {
    setOpenEntityPopup(false);
    setEntityOptions(
      entityList.map((entity: any) => {
        return {
          key: entity,
          selected: entity === entityText
        };
      })
    );
    await handleSearch(entityText, true);
  };

  return (
    <Drawer
      sx={{
        '& >.MuiDrawer-paper': {
          height: '60vh',
          borderRadius: '16px',
          paddingX: '24px',
          paddingY: '32px',
          position: 'absolute'
        }
      }}
      anchor='bottom'
      onClose={() => setModalOpen('')}
      open={!!modalOpen}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'stretch'
          }}>
          <Typography
            sx={{
              flexGrow: 1,
              textAlign: 'center',
              alignSelf: 'center',
              fontWeight: '700',
              fontSize: '20px',
              color: 'gray.800',
              textTransform: 'capitalize'
            }}>
            {title}
          </Typography>
          <Tooltip title='Close'>
            <IconButton
              onClick={() => setModalOpen('')}
              sx={{ position: 'absolute', right: '24px', top: '26px' }}>
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='center'>
          <Divider
            sx={{
              borderWidth: '2px',
              borderStyle: 'solid',
              borderColor: 'gray.400',
              borderRadius: '2px',
              mt: 1,
              width: '64px'
            }}
          />
        </Box>
        <Box
          sx={{
            paddingTop: '64px',
            alignSelf: 'center',
            width: { xs: '762px', md: '762px', lg: '794px' },
            minWidth: '762px',
            maxWidth: '794px'
          }}>
          <Stack spacing={1}>
            <Box
              sx={{
                ...styles.searchBar,
                borderBottomLeftRadius: sourceDropDownOpen ? 0 : 15,
                backgroundColor: 'gray.0'
              }}>
              <form
                onSubmit={(event: any) => {
                  event.preventDefault();
                  handleSearch();
                }}>
                <Stack direction='row' alignItems='center'>
                  <SourceDropDown
                    selectedSources={selectedSources}
                    handleChange={handleSourceChange}
                    options={sources}
                    anchorEl={sourceDropDownAnchorEl}
                    setAnchorEl={setSourceDropDownAnchorEl}
                  />
                  <Divider
                    orientation='vertical'
                    flexItem
                    sx={{ backgroundColor: 'gray.200', height: '32px', alignSelf: 'center' }}
                  />
                  <Box flex={1}>
                    <SearchBarAutoComplete
                      id='popup-search-input'
                      value={searchText}
                      label={`Search application for direct access to ${
                        type === 'reg360' ? 'Reg 360' : 'CDP'
                      }`}
                      disableSearch={selectedSources.length === 0}
                      options={searchSuggestions}
                      loading={isSuggestionsLoading}
                      onOpen={handleAutoCompleteOpen}
                      open={isSearchOpen}
                      onClose={handleAutoCompleteClose}
                      filterOptions={undefined}
                      onInputChange={handleKeyPress}
                      handleSearch={handleSearch}
                      setSearch={value => setSearchText(value)}
                      clearSearchSuggestions={() => {
                        setApplicationData([]);
                        clearSearchSuggestions();
                      }}
                    />
                  </Box>
                  <Button
                    type='submit'
                    size='small'
                    disabled={selectedSources.length === 0}
                    startIcon={<SearchIcon style={{ fontSize: 12, color: 'gray.50' }} />}
                    sx={styles.searchButton}>
                    Search
                  </Button>
                </Stack>
              </form>
              {isLoading && (
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='center'
                  alignItems='center'
                  sx={{ minHeight: '30vh' }}>
                  {' '}
                  <CircularProgress color='success' />
                </Box>
              )}
              {entityDetected && (
                <Box
                  sx={{
                    maxHeight: '30vh',
                    overflowY: 'scroll',
                    marginX: '16px',
                    marginBottom: '16px'
                  }}>
                  <EntitySelectionWindow
                    openEntityPopup={openEntityPopup}
                    setOpenEntityPopup={setOpenEntityPopup}
                    entityList={entityList}
                    handleEntityClick={entityClick}
                    displayText={searchText}
                    triggeredFromPopup
                  />
                </Box>
              )}
              {!isLoading && applicationData && applicationData?.length !== 0 && (
                <Divider sx={{ backgroundColor: 'gray.50', marginX: '16px', marginY: '16px' }} />
              )}
              {!isLoading && applicationData && applicationData?.length !== 0 ? (
                <Box
                  sx={{
                    maxHeight: '30vh',
                    overflowY: 'scroll',
                    marginX: '16px',
                    marginBottom: '16px'
                  }}>
                  {entityDetected && (
                    <EntityChips entities={entityOptions} handleEntityChange={entityClick} />
                  )}
                  <Grid container spacing={0.5} maxWidth='lg-xl'>
                    {Array.isArray(applicationData) &&
                      applicationData?.map((data: CardData) => (
                        <Cards data={data} handleClick={handleCardClick} key={data.identifier} />
                      ))}
                  </Grid>
                </Box>
              ) : null}
              {isEmptyMsg && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  alignItems='center'
                  height='30vh'>
                  <Box
                    sx={{
                      width: 128,
                      height: 128,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      bgcolor: 'gray.background',
                      borderRadius: '50%',
                      mb: 3
                    }}>
                    <SearchIcon sx={{ fontSize: 45, color: 'black.darkVariant1' }} />
                  </Box>
                  <Typography fontWeight={700} fontSize={16}>
                    No results were found.
                  </Typography>
                  <Typography fontSize={16}>
                    Check spelling or try executing a new search please.
                  </Typography>
                </Box>
              )}
            </Box>
          </Stack>
        </Box>
      </Box>
    </Drawer>
  );
};

export default React.memo(PopupSearchBarWithGenericResults);
