const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center'
  },
  dialogPaper: {
    height: '98vh',
    width: '100vw',
    backgroundColor: '#fff',
    maxWidth: 'none',
    maxHeight: 'none',
    borderRadius: '16px 16px 0px 0px',
    transition: 'all 1s ease-in-out'
  },
  dialogHeading: {
    color: 'primary.dark',
    padding: '13px 10px',
    borderRadius: '10px 10px 0px 0px',
    fontSize: 18,
    fontWeight: '600'
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    overflowY: 'hidden',
    gap: '16px'
  },
  pdfContainer: {
    overflowY: 'hidden',
    boxSizing: 'border-box',
    minWidth: '492px',
    overflowX: 'hidden',
    padding: '0px 0.5px',
    flexGrow: 1,
    maxWidth: '50%'
  },
  missingSectionText: {
    color: 'red.900',
    padding: '2px 10px',
    marginBottom: 'auto'
  },
  selectLabel: {
    background: '#33B187',
    color: 'white.main',
    borderRadius: '10px 10px 0px 0px',
    marginBottom: 'auto',
    fontSize: '1.5vh',
    margin: '0 5vw',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    border: '1.5px transparent solid',
    borderBottom: 'none'
  },
  selectLabelMissing: {
    background: 'rgba(255,0,0,0.3)',
    color: 'white.main',
    borderRadius: '10px 10px 0px 0px',
    border: '1.5px red solid',
    borderBottom: 'none',
    marginBottom: 'auto',
    fontSize: '1.5vh',
    margin: '0 5vw',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  rearrangeIcon: {
    color: '#0D917D',
    padding: 0
  },
  rearrangeMenuIcon: {
    color: '#0D917D'
  },
  loaderWrapper: {
    mt: 2,
    flexDirection: 'column',
    spacing: 1,
    alignItems: 'center'
  },
  loaderCloseIcon: {
    position: 'absolute',
    right: 8,
    top: 8
  },
  loaderButton: {
    textTransform: 'capitalize',
    backgroundColor: 'primary.main',
    color: 'white.main',
    mt: 2,
    fontSize: '14px',
    fontWeight: 700,
    width: '40%',
    border: '2px solid',
    borderColor: 'primary.dark',
    padding: 1,
    '&:hover': {
      opacity: 0.8,
      color: 'black.main',
      backgroundColor: 'white.main',
      borderColor: 'gray.main'
    }
  },
  circularProgress: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  loaderPercentWrapper: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  percentTypography: {
    fontSize: '32px',
    color: 'black',
    fontWeight: 'bold'
  },
  percentText: { fontSize: '20px', paddingTop: '6px' },
  loaderStatusText: {
    fontSize: '25px',
    color: 'black',
    fontWeight: 'bold'
  },
  dialogContentWrapper: {
    mb: 2,
    pl: '5%',
    pr: '5%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  },
  headerWrapper: { backgroundColor: 'white.main', display: 'flex', flexDirection: 'column' },
  titleWrapper: {
    backgroundColor: 'white.main',
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    margin: '24px 40px 10px 40px'
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    alignSelf: 'center',
    fontWeight: '700',
    fontSize: '20px',
    color: 'primary.darkVariant1'
  },
  actionsWrapper: {
    position: 'absolute',
    display: 'flex',
    left: '96%'
  },
  dividerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  divider: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'gray.400',
    borderRadius: '2px',
    mb: '16px',
    width: '64px'
  },
  resetJumpToSectionButton: {
    color: 'primary.darkVariant1',
    fontFamily: 'Mulish',
    fontSize: '12px',
    fontWeight: 400,
    cursor: 'pointer'
  },
  sliderText: {
    color: 'black.main',
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontWeight: 600
  },
  angleIcon: { width: '16px', height: '16px', color: 'primary.darkVariant1' },
  pipeIcon1: { height: '16px', color: 'grey.400', width: '1px', mx: '8px !important' },
  pipeIcon2: { height: '16px', color: 'grey.400', width: '1px', ml: '16px !important' },
  compileSectionText: {
    color: 'primary.700',
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontWeight: 600,
    cursor: 'pointer'
  },
  cancelCompileSectionWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    borderRadius: '15px',
    backgroundColor: 'primary.500',
    padding: '0 4px',
    height: '24px',
    cursor: 'pointer'
  },
  cancelIcon: {
    height: '17px',
    width: '17px',
    color: 'white.main'
  },
  totalSectionsCompliedText: {
    color: 'white.main',
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontWeight: 600
  },
  arrowDownIcon: {
    height: '16px',
    width: '16px',
    color: 'white.main'
  },
  roundedMenu: {
    '& .MuiPaper-root': { padding: '8px 0px !important' },
    '& .MuiList-root': { padding: '0 !important' }
  },
  pdfWrapper: {
    width: '100%',
    height: 'calc(100vh - 170px)',
    background: '#f5f5f5',
    borderRadius: '18px',
    padding: '16px 14px',
    '& iframe': {
      height: 'calc(100vh - 240px)'
    }
  },
  pdfContainerHeader: {
    marginBottom: '14px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  applicationName: {
    fontSize: 15,
    color: 'primary.darkVariant1',
    fontFamily: 'Mulish',
    fontWeight: 700,
    textTransform: 'capitalize',
    maxWidth: '330px',
    textAlign: 'center'
  },
  flagIcon: { width: '18px', height: '12px' },
  callToActionsWrapper: {
    display: 'flex',
    gap: '12px',
    height: '24px'
  },
  headerContainer: {
    bgcolor: 'primary.600',
    alignSelf: 'stretch',
    p: '12px 24px',
    mb: '16px',
    alignItems: 'center'
  },
  changeProductsContainer: {
    cursor: 'pointer',
    position: 'absolute',
    display: 'flex',
    left: '2%'
  },
  leftArrowIcon: { height: '14px', width: '14px', color: 'white.main' },
  changeProductsText: {
    fontFamily: 'Mulish',
    fontSize: '14px',
    color: 'white.main',
    fontWeight: 600
  },
  viewLatestLabelsContainer: { flexGrow: 1, textAlign: 'center', alignSelf: 'center' },
  iconButton: { position: 'absolute', display: 'flex', left: '97%', p: 0 },
  highlightOffOutlinedIcon: { height: '24px', width: '24px', color: 'white.main' },
  sliderWrapper: { display: 'flex', gap: '11px', alignItems: 'center' },
  applicationChip: {
    fontSize: 10,
    height: 13,
    borderRadius: 1,
    fontWeight: 800,
    color: 'white.main',
    '& span': {
      px: 0.5
    },
    cursor: 'default'
  }
};

export default styles;
