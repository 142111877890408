import { useContext, useEffect, useState, memo } from 'react';
import { Divider, IconButton, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import UserProfileStore from '../../../../store/UserProfile';
import trimText from '../../../../utils/trimText';
import EditIcon from '../../../../assets/svgs/UserProfile/EditIcon';
import DeleteIcon from '../../../../assets/svgs/UserProfile/DeleteIcon';
import TitleDescriptionDialog from '../TitleDescriptionDialog';
import useProjectData from '../../hooks/useProjectData';
import styles from '../../styles/ProjectTitle.styles';
import { PROJECT_DESCRIPTION_MAX_LENGTH, PROJECT_TITLE_MAX_LENGTH } from '../../constants';

const ProjectTitle = () => {
  const { isLoading, updateProjectDetail, handleProjectDelete } = useProjectData();
  const { profileState } = useContext(UserProfileStore) as any;
  const [projectData, setProjectData] = useState<any>({});
  const [action, setAction] = useState<string>('');
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (profileState.projectList && location.pathname && profileState.projectList.length !== 0) {
      const projectId = location.pathname.split('/')[3];
      if (projectId) {
        profileState.projectList.forEach((project: any) => {
          if (project?.project_id.toString() === projectId.toString()) {
            setProjectData(project);
          }
        });
      }
    }
  }, [profileState.projectList, location]);

  const handleClose = () => {
    setAction('');
  };

  const handleDelete = async () => {
    const { success, updatedProjectList } = await handleProjectDelete(projectData?.project_id);
    if (success) {
      if (updatedProjectList.length !== 0) {
        history.push(`/account/project/${updatedProjectList[0]?.project_id}?tab=searches`);
      } else {
        history.push(`/account/project`);
      }
    }
    handleClose();
  };

  const handleSubmit = async (title: string, description: string) => {
    if (title) {
      await updateProjectDetail(projectData?.project_id, title, description);
      handleClose();
    }
  };

  if (Object.keys(projectData).length === 0) return null;

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' sx={styles.root}>
      <Stack direction='row' spacing='12px'>
        {isLoading ? (
          <Skeleton variant='rectangular' height='20px' width='140px' />
        ) : (
          <Tooltip title={projectData?.name?.length > 30 ? projectData?.name : ''} placement='top'>
            <Typography sx={styles.projectNameText}>{trimText(projectData?.name, 30)}</Typography>
          </Tooltip>
        )}

        <Divider orientation='vertical' sx={styles.divider} />
        {isLoading ? (
          <Skeleton variant='rectangular' height='20px' width='200px' />
        ) : (
          <Tooltip
            title={projectData?.description?.length > 80 ? projectData?.description : ''}
            placement='top'>
            <Typography sx={styles.projectDescriptionText}>
              {trimText(projectData?.description, 80)}
            </Typography>
          </Tooltip>
        )}
      </Stack>
      <Stack direction='row' spacing='2px' alignItems='center'>
        <Divider orientation='vertical' sx={styles.divider} />
        <IconButton
          onClick={() => {
            setAction('edit');
          }}>
          <EditIcon sx={styles.icon} />
        </IconButton>
        <IconButton
          onClick={() => {
            setAction('delete');
          }}>
          <DeleteIcon sx={styles.icon} />
        </IconButton>
      </Stack>

      {!!action && (
        <TitleDescriptionDialog
          dialogType=''
          heading={action === 'edit' ? 'Edit Project Details' : 'Confirm Delete'}
          title={projectData?.name}
          description={projectData?.description}
          handleSubmit={action === 'edit' ? handleSubmit : handleDelete}
          handleClose={handleClose}
          action={action}
          titleMaxlength={PROJECT_TITLE_MAX_LENGTH}
          descriptionMaxLength={PROJECT_DESCRIPTION_MAX_LENGTH}
        />
      )}
    </Stack>
  );
};

export default memo(ProjectTitle);
