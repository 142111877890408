import { Grid } from '@mui/material';
import { NotificationsStateProvider } from '../../../../store/Notifications';
import NotificationSettings from '../../../Notifications/NotificationSettings';

const Subscriptions = () => {
  return (
    <NotificationsStateProvider>
      <Grid container columnSpacing='16px'>
        <NotificationSettings />
      </Grid>
    </NotificationsStateProvider>
  );
};
export default Subscriptions;
