import React from 'react';

import { Stack, Chip, ListItem } from '@mui/material';

const EntityChips = ({ entities,handleEntityChange }: any) => {
  return (
    <Stack direction='row'>
      {entities.map((entity: any) => {
        return (
          <ListItem
            key={entity.key}
            sx={{
              pl: 0,
              pr: '8px',
              pb: '12px',
              width:'auto'
            }}>
            <Chip
              onClick={() => handleEntityChange(entity.key)}
              sx={{
                fontSize: 13,
                '&:hover': {
                  backgroundColor: (theme: any) =>
                    `${theme.palette.primary.backgroundLight} !important`,
                  borderColor: (theme: any) => `${theme.palette.primary.main} !important`
                },
                '&.MuiChip-filled': {
                  backgroundColor: (theme: any) =>
                    `${theme.palette.primary.lightVariant1} !important`,
                  color: (theme: any) => `${theme.palette.primary.darkVariant2} !important`,
                  fontWeight: 'bold'
                },
                '& > .MuiChip-label': {
                  padding: '8px 16px'
                },
                color: (theme: any) => theme.palette.primary.darkVariant1,
                borderColor: (theme: any) => theme.palette.gray.lightVariant8
              }}
              variant={entity?.selected ? 'filled' : 'outlined'}
              label={entity?.key}
            />
          </ListItem>
        );
      })}
    </Stack>
  );
};

export default EntityChips;
