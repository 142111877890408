import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

// muiv5
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import VivproDatagrid from '../../../components/Datagrid';
import EmptyPage from '../../../components/EmptyPage/EmptyPage';

// context
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';

// hoc
import withFetchingResourcesDetails from './withFetchingResourcesDetails';

import { SIDE_EFFECTS_COLUMN_DEFINITION, SIDE_EFFECTS_ROW_ID_MAP } from './constants';

interface ParameterProps {
  source: string;
}

const SideEffects = () => {
  const { regulatoryState } = useContext(RegulatoryInformationStore);
  // get the url parameters
  const { source } = useParams<ParameterProps>();

  return (
    <Box display='flex' justifyContent='center' sx={{ width: '100%', height: '100%' }}>
      {!regulatoryState.loadingResourcesDetails && !regulatoryState.resourcesDetails?.length ? (
        <EmptyPage message='Details are not available' />
      ) : (
        <Stack sx={{ m: 2, height: '60vh', width: '100%' }}>
          <VivproDatagrid
            rows={regulatoryState?.resourcesDetails}
            columnsMapping={SIDE_EFFECTS_COLUMN_DEFINITION[source?.toLowerCase() as string]}
            rowId={
              source?.toLowerCase() in SIDE_EFFECTS_ROW_ID_MAP
                ? SIDE_EFFECTS_ROW_ID_MAP[source?.toLowerCase() as string]
                : SIDE_EFFECTS_ROW_ID_MAP.default
            }
            loading={regulatoryState?.loadingResourcesDetails}
          />
        </Stack>
      )}
    </Box>
  );
};

export default withFetchingResourcesDetails(SideEffects);
