/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { uniqueId } from 'lodash';
import ResultsStore from '../../../store/SearchResults';
import ResultsActions from '../../../store/SearchResults/actions';

// custom hooks
import useDocumentsViewFunctions from './useDocumentsViewFunctions';

import { METADATA_KEYS } from '../constants';

// apis
import { getReportTitle, sendConversation } from '../../../api/modules/ChatRIA';

import { prepareDocumentCard } from '../utils/documentResultsUtils';
import { sourceMap } from '../../ResultsPage/components/SearchSuggestions';
import GlobalActions from '../../../store/actions';
import GlobalStore from '../../../store';

const getTitle = async (content: string, id: any, question: string): Promise<string> => {
  const payload = {
    [id]: {
      answer: content,
      question
    }
  };
  const response = await getReportTitle(payload);
  if (response?.status === 200) {
    const { data } = response;
    if (data?.body) {
      return data?.body[0].title;
    }
  }
  return '';
};

const useChatRiaAnswer = () => {
  const { state, dispatch } = useContext(GlobalStore) as any;
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const { handleResultClick } = useDocumentsViewFunctions();
  const [addedToReportList, setAddedToReportList] = useState<string[]>([]);

  const { source }: { source: string } = useParams();

  const ariaClickAnswerEvent = async () => {
    const item =
      Object.keys(resultsState.selectedAriaResponse).length === 0
        ? resultsState.documentResults?.results[0]
        : resultsState.selectedAriaResponse.item;
    const formattedData = prepareDocumentCard(item);
    await handleResultClick({
      resultId: formattedData.resultId,
      resultDetails: formattedData,
      pdfUrl: formattedData.pdfUrl,
      pdfUrlOriginal: formattedData.pdfUrlOriginal
    });
  };

  const initializeIntelligentAnswer = async (originalQuestion = '') => {
    try {
      let s3Url = '';
      let pageNumber: number = 0;
      let payloadResultId = '';
      let resultData: any = {};

      // set loader
      resultsDispatch({
        type: ResultsActions.SET_ARIA_RESPONSE_LOADING,
        value: true
      });
      resultsDispatch({ type: ResultsActions.SET_EXPLORE_RESULTS_OPEN, value: false });
      if (resultsState.selectedAriaResponse?.resultId in resultsState.cachedAriaResponse) {
        resultsDispatch({
          type: ResultsActions.SET_ARIA_RESPONSE_ANSWER_TEXT,
          value: resultsState.cachedAriaResponse[resultsState.selectedAriaResponse?.resultId]
        });
        resultsDispatch({
          type: ResultsActions.SET_ARIA_RESPONSE_LOADING,
          value: false
        });
        return;
      }
      const ariaResponseResult = resultsState.selectedAriaResponse;
      if (ariaResponseResult && Object.keys(ariaResponseResult).length !== 0) {
        s3Url = ariaResponseResult?.pdfUrl;
        pageNumber = parseInt(ariaResponseResult?.pdfUrl?.split('#page=')[1], 10);
        payloadResultId = ariaResponseResult?.resultId;
        resultData = ariaResponseResult?.item;
      } else if (resultsState.documentResults?.results?.length > 0) {
        s3Url = resultsState.documentResults?.results[0]?.document_url;
        pageNumber = parseInt(
          resultsState.documentResults?.results[0].document_url?.split('#page=')[1],
          10
        );
        payloadResultId = resultsState.documentResults?.results[0]?.result_id;
        resultData = resultsState.documentResults?.results[0];
      }

      if (s3Url && pageNumber) {
        // Ensure metadataMapping is typed or at least assumed to have a consistent structure.
        const metadataMapping = METADATA_KEYS[resultData.data_source || resultData['data-source']];

        if (!metadataMapping) return; // Early exit if metadataMapping is undefined.

        // Explicitly assert the type of metadataKeys as an array of strings.
        const metadataKeys = metadataMapping?.map((item: any) => item.id);
        const resultDataObj: ResultData = metadataKeys.reduce(
          (acc: any, key: any) => {
            acc[key] = resultData[key];
            return acc;
          },
          {
            resultId: payloadResultId,
            s3_document_url: s3Url,
            page: pageNumber,
            metadataMapping
          }
        );
        //   get conversation answer
        const conversationPayload = {
          help_question: false,
          riaMode: true,
          question: resultsState?.decryptedPayload?.query || originalQuestion,
          source,
          question_source: 'summary',
          results: [resultDataObj]
        };
        const conversationResponse = await sendConversation(conversationPayload);

        if (conversationResponse?.status === 200) {
          if (conversationResponse.data.body?.length > 0) {
            const { content: currentAnswerText, result_id: summaryResultId } =
              conversationResponse.data.body[0];

            const currentCachedResult = resultsState.cachedAriaResponse;
            currentCachedResult[summaryResultId] = currentAnswerText;
            resultsDispatch({
              type: ResultsActions.SET_CACHED_ARIA_RESPONSE,
              value: currentCachedResult
            });
            resultsDispatch({
              type: ResultsActions.SET_ARIA_RESPONSE_ANSWER_TEXT,
              value: currentAnswerText
            });
          }
        }

        resultsDispatch({
          type: ResultsActions.SET_ARIA_RESPONSE_LOADING,
          value: false
        });
      }
    } catch (error) {
      console.error(error);

      resultsDispatch({
        type: ResultsActions.SET_ARIA_RESPONSE_LOADING,
        value: false
      });
    }
  };

  const addToReport = async (id: any = null, content: any = null) => {
    let resultId = id;
    let resultContent = content;
    if (!resultContent || !resultId) {
      const item =
        Object.keys(resultsState.selectedAriaResponse).length === 0
          ? resultsState.documentResults?.results[0]
          : resultsState.selectedAriaResponse.item;
      const formattedData = prepareDocumentCard(item);
      resultId = formattedData.resultId;
      resultContent = resultsState.ariaResponseAnswerText;
    }
    let selectedDocument = resultsState.documentResults.results.filter(
      (d: any) => d.result_id === resultId
    );
    if (selectedDocument.length > 0) {
      selectedDocument = selectedDocument[0];
      let applicationNumber =
        selectedDocument.application_number?.replace('EMEA-H-C-', '')?.replace('EMEA-H-W-', '') ||
        selectedDocument.product_number ||
        selectedDocument['product-number'] ||
        selectedDocument.nct_id ||
        selectedDocument.euct_id ||
        selectedDocument.yj_code ||
        selectedDocument['vin-number'] ||
        selectedDocument.field_docket_number ||
        selectedDocument['orphan-designation-number'] ||
        selectedDocument.project_number;
      selectedDocument = prepareDocumentCard(selectedDocument);
      const reportTitle: string = await getTitle(resultContent, resultId, 'Summary');
      const generatedForSource = selectedDocument?.sourceName;
      if (generatedForSource?.toLowerCase() === 'eu') {
        applicationNumber =
          selectedDocument.dataSource === 'ema-who'
            ? `EMEA-H-W-${applicationNumber}`
            : `EMEA-H-C-${applicationNumber}`;
      }
      const random = Math.floor(Math.random() * 1000);
      const prettySource = sourceMap[generatedForSource];
      const ariaPath = new URL(selectedDocument.s3Link || '');
      const bucket = ariaPath.hostname.split('.')[0];
      const page = selectedDocument.s3Link?.split('page=')[1];
      const subPath = `aria?bucket=${bucket}&path=${encodeURIComponent(
        ariaPath.pathname.replace(/^\//, '')
      )}&page=${page}`;
      const originalUrl = selectedDocument['original-file-url'];
      const generatedForBrandName = selectedDocument.documentTitle;
      dispatch({
        type: GlobalActions.ADD_TEMPLATE_TO_REPORT,
        value: {
          dataSource: 'custom',
          sectionType: 'TEXT',
          textType: 'HTML_TEXT',
          id: uniqueId(`chatria__${random}_`),
          _chatId: resultId,
          style: {
            placement: {
              h: 10,
              w: 12
            },
            references: {
              show: true,
              href: `${window.location.origin}/chatRIA/reports/reference/${subPath}`,
              text: `${prettySource} / ${applicationNumber} /  ${generatedForBrandName}`,
              originalUrl
            }
          },
          content: `<span><h3>${reportTitle}</h3>\n\n${resultContent}</span>`
        }
      });
      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: 'Successfully added to Report.', color: 'success' }
      });
    }
  };

  interface ResultItem {
    document_url: string;
    result_id: string;
    data_source: string;

    [key: string]: any;
  }

  interface ResultData {
    resultId: string;
    s3_document_url: string;
    page: number;
    metadataMapping: any[];

    [key: string]: any;
  }

  interface ConversationResponse {
    status: number;
    data: {
      body: any;
    };
  }
  // Function to check if a resultId is in the summaryResponseList
  const isResultIdInSummary = (resultId: string, summaryList: any) => {
    return summaryList.some((summary: any) => summary.result_id.includes(resultId));
  };

  const generateTop10Summary = async (): Promise<void> => {
    resultsDispatch({ type: ResultsActions.SET_TOP_10_SUMMARY_LOADING, value: true });
    resultsDispatch({
      type: ResultsActions.SET_ARIA_RESPONSE_LOADING,
      value: true
    });
    if (resultsState.top10Summary?.length > 0) {
      resultsDispatch({ type: ResultsActions.SET_RIA_RESPONSE_TITLE, value: 'Key Insights' });
      resultsDispatch({ type: ResultsActions.SET_SHOW_TOP_10_SUMMARY, value: true });
      resultsDispatch({ type: ResultsActions.SET_TOP_10_SUMMARY_LOADING, value: false });
      resultsDispatch({
        type: ResultsActions.SET_ARIA_RESPONSE_LOADING,
        value: false
      });
      return;
    }
    const allResults = resultsState.documentResults.results?.slice(0, 10) || [];
    const top10ResultIds = allResults.map((res: any) => res.result_id);
    const currentCachedResult = resultsState.cachedAriaResponse;

    const payloadResults: ResultData[] = [];

    allResults.forEach((resultItem: ResultItem) => {
      let content = '';
      // Check if the result_id is a key in the currentCachedResult
      const isResultIdPresent = resultItem.result_id in currentCachedResult;
      if (isResultIdPresent) {
        // If the result_id is present, set the content from cached object
        // to avoid regenerating gpt response in backend
        content = currentCachedResult[resultItem.result_id];
      }

      const pageNumber = parseInt(resultItem.document_url?.split('#page=')[1], 10) || 1;

      // Ensure metadataMapping is typed or at least assumed to have a consistent structure.
      const metadataMapping = METADATA_KEYS[resultItem.data_source || resultItem['data-source']];
      if (!metadataMapping) return; // Early exit if metadataMapping is undefined.

      // Explicitly assert the type of metadataKeys as an array of strings.
      const metadataKeys: string[] = metadataMapping.map((item: any) => item.id);

      const resultData: ResultData = metadataKeys.reduce(
        (acc: any, key: any) => {
          acc[key] = resultItem[key];
          return acc;
        },
        {
          resultId: resultItem.result_id,
          s3_document_url: resultItem.document_url,
          page: pageNumber,
          metadataMapping,
          summary: content
        }
      );

      payloadResults.push(resultData);
    });

    try {
      const conversationResponse = (await sendConversation({
        question: resultsState?.decryptedPayload?.query,
        question_source: 'summary',
        ria_mode: true,
        help_question: false,
        results: payloadResults
      })) as ConversationResponse;

      if (conversationResponse.status === 200) {
        const summaryResponseList = conversationResponse.data.body;
        summaryResponseList?.forEach((summaryResponse: any) => {
          const { content: currentAnswerText, result_id: summaryResultId } = summaryResponse;
          summaryResultId.forEach((id: string) => {
            currentCachedResult[id] = currentAnswerText;
          });
        });
        resultsDispatch({
          type: ResultsActions.SET_CACHED_ARIA_RESPONSE,
          value: currentCachedResult
        });

        // Create a list to store missing results
        const missingResults: any = [];

        // Check each top10ResultId
        await top10ResultIds.forEach((resultId: string) => {
          if (!isResultIdInSummary(resultId, summaryResponseList)) {
            // If resultId is not found, add it to missingResults
            missingResults.push({ content: currentCachedResult[resultId], result_id: [resultId] });
          }
        });

        // Add missingResults to the top of summaryResponseList
        const updatedSummaryResponseList = [...missingResults, ...summaryResponseList];

        resultsDispatch({
          type: ResultsActions.SET_TOP_10_SUMMARY,
          value: updatedSummaryResponseList
        });
        resultsDispatch({ type: ResultsActions.SET_RIA_RESPONSE_TITLE, value: 'Key Insights' });
        resultsDispatch({ type: ResultsActions.SET_SHOW_TOP_10_SUMMARY, value: true });
      }
    } catch (error) {
      console.error('Failed to generate top 10 summary:', error);
    } finally {
      resultsDispatch({ type: ResultsActions.SET_TOP_10_SUMMARY_LOADING, value: false });
      resultsDispatch({
        type: ResultsActions.SET_ARIA_RESPONSE_LOADING,
        value: false
      });
    }
  };

  useEffect(() => {
    // Extract chatIds from reportLayout.sections
    // eslint-disable-next-line no-underscore-dangle
    const chatIds = state.reportLayout.sections.map((section: any) => section._chatId);

    // Function to update the list with new chatIds
    const updateReportList = () => {
      const newChatIds = chatIds.filter((chatId: any) => !addedToReportList.includes(chatId));
      if (newChatIds.length > 0) {
        setAddedToReportList(currentList => [...currentList, ...newChatIds]);
      }
    };

    updateReportList();
  }, [state.reportLayout]);

  return {
    ariaClickAnswerEvent,
    initializeIntelligentAnswer,
    generateTop10Summary,
    addToReport,
    addedToReportList
  };
};

export default useChatRiaAnswer;
