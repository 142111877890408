import { createContext, useReducer, Dispatch, ReactNode } from 'react';
import reducers, { initialState } from './reducers';

type Props = {
  children: ReactNode;
};

export interface ContextInterface {
  stats: any;
  projects: any[];
  errors: any[];
  filters: any[];
  availableFilters: any;
  isLoading: boolean;
  isFiltersApplied: boolean;
  programTypeChart: any;
  submissionStatusChart: any;
  barGraph: any;
  charts: any;
}

const OrphanDesignationsStore = createContext<{
  OrphanDesignationsState: ContextInterface;
  OrphanDesignationsDispatch: Dispatch<any>;
}>({ OrphanDesignationsState: initialState, OrphanDesignationsDispatch: () => null });
const { Provider } = OrphanDesignationsStore;

export const OrphanDesignationsStateProvider = ({ children }: Props) => {
  const [OrphanDesignationsState, OrphanDesignationsDispatch] = useReducer(reducers, initialState);
  return (
    <Provider value={{ OrphanDesignationsState, OrphanDesignationsDispatch }}>{children}</Provider>
  );
};

export default OrphanDesignationsStore;
