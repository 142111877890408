import { useEffect, useState, useContext, memo, useCallback, useMemo } from 'react';

// material ui
import { Box, Popover } from '@mui/material';

// store
import OrphanDesignationsStore from '../../store/OrphanDesignations';
import GlobalStore from '../../store';

import useOrphanDesignations from './hooks/useOrphanDesignations';
import SubHeader from '../../components/SubHeader';
import NewFilters from '../../components/NewFilters';
import AriaDrawerCore from '../SearchResults/components/AriaDrawerCore';
import AskRiaButton from '../../components/Buttons/AskRiaButton';
import AskRiaButtonStyles from '../../components/Buttons/styles/AskRiaButton.styles';
import ReportWidgets from '../../components/Report/Widgets';

import { Stat } from '../SearchResults/types';
import { ORPHAN_DESIGNATIONS_FILTERS } from './constants';
import Details from './components/Details';
import useAriaShareHandler from '../../components/AriaShare/useAriaShareHandler';
import BottomDrawer from '../../components/CustomComponents/BottomDrawer';
import truncateCountAboveMillions from '../../utils/truncateCountAboveMillions';
import formatCount from '../../utils/formatCount';
import VISUALIZE_CHART_MAPPING from '../../components/Insights/const';
import Insights from '../../components/Insights/Insights';

const OrphanDesignationsPage = () => {
  const { dispatch } = useContext(GlobalStore) as any;
  const { OrphanDesignationsState } = useContext(OrphanDesignationsStore);
  const { getOrphanDesignations, filters, isLoading } = useOrphanDesignations();

  const [ariaModalOpen, setAriaModalOpen] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);
  const [stats, setStats] = useState<Stat[]>([]);
  const [reportChoiceAnchorElement, setReportChoiceAnchorElement] = useState<HTMLElement | null>(
    null
  );

  const handleVisualizeOpen = () => {
    setIsVisualizeOpen(true);
  };

  useEffect(() => {
    if (Object.keys(filters).length === 0) {
      getOrphanDesignations([]);
    }

    const newStats: Stat[] = [];

    newStats.push({
      title: 'Total',
      value: OrphanDesignationsState?.stats?.total ?? 0
    });
    newStats.push({
      title: 'Total Unique Active Substances',
      value: OrphanDesignationsState?.stats?.totalUniqueActiveSubstances ?? 0
    });
    newStats.push({
      title: 'Total Documents | Pages',
      value: OrphanDesignationsState?.stats?.totalDocuments ?? 0,
      value2: OrphanDesignationsState?.stats?.totalPages ?? 0
    });

    setStats([...newStats]);
  }, [filters]);

  useAriaShareHandler(isLoading, ariaModalOpen, setAriaModalOpen);

  const getAriaDocuments = () => {
    const ariaDocuments = OrphanDesignationsState?.projects?.map(project => ({
      identifier: project.designation_number
    }));
    return ariaDocuments || [];
  };

  const handleFilterApply = useCallback((currentFilters: any) => {
    getOrphanDesignations(currentFilters);
  }, []);

  const getFiltersLabelList = () => {
    return ORPHAN_DESIGNATIONS_FILTERS.reduce((acc: any, element: any) => {
      acc[element.value] = element.label;
      return acc;
    }, {});
  };

  const closeDrawer = () => {
    setIsVisualizeOpen(false);
  };

  const getInsightStatsList = useMemo(() => {
    if (!OrphanDesignationsState?.stats) {
      return [];
    }
    return [
      {
        text: 'Orphan Designations',
        count: OrphanDesignationsState?.stats?.total,
        toolTipValue: ''
      },
      {
        text: 'Active Substance',
        count: OrphanDesignationsState?.stats?.totalUniqueActiveSubstances,
        toolTipValue: ''
      },
      {
        text: 'Total Documents | Pages',
        count: `${truncateCountAboveMillions(
          OrphanDesignationsState?.stats?.totalDocuments ?? 0
        )} | ${truncateCountAboveMillions(OrphanDesignationsState?.stats?.totalPages ?? 0)}`,
        toolTipValue: `${formatCount(
          OrphanDesignationsState?.stats?.totalDocuments ?? 0
        )} | ${formatCount(OrphanDesignationsState?.stats?.totalPages ?? 0)}`
      }
    ];
  }, [OrphanDesignationsState?.stats]);

  const getLayer1Charts = useMemo(() => {
    const orphanGraph = VISUALIZE_CHART_MAPPING?.ema_orphan?.layer1;
    if (!orphanGraph || !OrphanDesignationsState?.barGraph) {
      return orphanGraph;
    }
    const updatedGraph = orphanGraph.map((graph: any) => ({
      ...graph,
      data: OrphanDesignationsState?.barGraph
    }));
    return updatedGraph;
  }, [OrphanDesignationsState?.barGraph]);

  const getLayer2Charts = useMemo(() => {
    const orphanGraph = VISUALIZE_CHART_MAPPING?.ema_orphan.layer2;

    if (!orphanGraph || !OrphanDesignationsState?.charts) {
      return orphanGraph;
    }
    orphanGraph.forEach(graph => {
      const donutItem = OrphanDesignationsState?.charts.find(
        (donut: { apiKey: string }) => donut?.apiKey === graph?.id
      );
      if (donutItem) {
        // eslint-disable-next-line no-param-reassign
        graph.data = donutItem.children;
      }
    });
    return orphanGraph;
  }, [OrphanDesignationsState?.charts]);

  return (
    <>
      <SubHeader
        title='Opinions on Orphan Medicinal Product Designation'
        subTitle="Showing list of orphan medicine designations and amendments for which opinions were adopted by EMA's Committee for Orphan Medicinal Products (COMP)"
        stats={stats}
        handleVisualizeOpen={handleVisualizeOpen}
      />

      <Box>
        {!isLoading && (
          <Popover
            open={!!reportChoiceAnchorElement}
            anchorEl={reportChoiceAnchorElement}
            onClose={() => setReportChoiceAnchorElement(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            sx={{
              '& .MuiPopover-paper': {
                borderRadius: '16px !important',
                backgroundColor: 'none'
              }
            }}>
            <Box>
              <ReportWidgets
                widgets={['pie-widget', 'bar-widget', 'table-widget']}
                title=''
                dataSource={{
                  source: 'ema_orphan',
                  moduleName: 'ema_orphan',
                  disableSelectionSource: true,
                  filters: OrphanDesignationsState?.availableFilters,
                  filterLabelList: getFiltersLabelList()
                }}
                pathMetaData={{
                  module: 'ema_orphan',
                  href: window.location.href
                }}
                setReportWidgetClose={setReportChoiceAnchorElement}
              />
            </Box>
          </Popover>
        )}
        <Details />
      </Box>

      <BottomDrawer
        isDrawerOpen={isVisualizeOpen}
        closeDrawer={closeDrawer}
        handleReportChoiceAnchorElement={setReportChoiceAnchorElement}>
        <Insights
          source='ema_orphan'
          searchType='ema_orphan'
          appliedFilter={OrphanDesignationsState?.availableFilters}
          statsList={getInsightStatsList}
          layer1Component={getLayer1Charts}
          layer2Component={getLayer2Charts}
          isLoading={isLoading}
        />
      </BottomDrawer>

      {ariaModalOpen && (
        <AriaDrawerCore
          applications={OrphanDesignationsState?.isFiltersApplied ? getAriaDocuments() : []}
          sources={{ eu: ['ema_orphan'] }}
          groupId='entity_id'
          docCount={OrphanDesignationsState?.stats?.totalDocuments ?? 0}
          docPageCount={OrphanDesignationsState?.stats?.totalPages ?? 0}
          modalOpen={ariaModalOpen}
          setModalOpen={setAriaModalOpen}
          dispatch={dispatch}
        />
      )}

      <NewFilters data={filters} applyFilters={handleFilterApply} isLoading={isLoading} />

      <Box sx={AskRiaButtonStyles.bottomFilterPosition}>
        <AskRiaButton
          iconId='aria'
          setOpenAriaSearch={setAriaModalOpen}
          text='Ask RIA'
          disabled={isLoading}
        />
      </Box>
    </>
  );
};

export default memo(OrphanDesignationsPage);
