import capitalize from 'lodash/capitalize';
import uppperFirst from 'lodash/upperFirst';

import { APPROVAL_STATUS_TAGS } from '../../ResultsPage/utils/constants';

const getOrphanMedicineBackgroundColor = (flag: any) => {
  if (flag === 'yellow') {
    return '#FFD69A';
  }

  if (flag === 'red') {
    return '#FFC4CA';
  }

  if (flag === 'green') {
    return 'primary.100';
  }

  return 'gray.200';
};

const getAvatarBackgroundColor = (apiData: any, avatarConfig: any) => {
  if (avatarConfig?.fieldName === 'orphan_medicine' && apiData?.[avatarConfig?.fieldName]) {
    const orphanMedicineFlag = apiData?.orphan_medicine_flag?.toLowerCase();

    return getOrphanMedicineBackgroundColor(orphanMedicineFlag);
  }

  return apiData[avatarConfig.fieldName] &&
    !['no', 'not applicable', 'unknown', 'inactive'].includes(
      String(apiData[avatarConfig.fieldName])?.toLowerCase()
    )
    ? 'primary.100'
    : 'gray.200';
};

const getTextColor = (apiData: any, avatarConfig: any, defaultColor: string) => {
  let avatarColor;
  if (
    avatarConfig.initial === 'RM' &&
    ['inactive'].includes(String(apiData[avatarConfig.fieldName])?.toLowerCase())
  ) {
    avatarColor = 'red';
  } else if (
    avatarConfig.initial === 'MS' &&
    apiData[avatarConfig.fieldName]?.toLowerCase() === 'not marketed'
  ) {
    avatarColor = 'red';
  } else if (avatarConfig.initial === 'AA' && apiData[avatarConfig.AA_status] !== 'Converted') {
    avatarColor = defaultColor;
  }

  return avatarColor;
};

const getApprovalTracks = (apiData: any, source: string) => {
  const avatarConfigs = APPROVAL_STATUS_TAGS[source];
  const prefixText = 'indicates status at the time of original approval';
  if (!avatarConfigs || !avatarConfigs.length) {
    return [];
  }
  let AAcolor = '';
  if (apiData[avatarConfigs[0].AA_status] === 'Not Yet Converted') {
    AAcolor = 'orange';
  } else if (
    apiData[avatarConfigs[0].AA_status] === 'Not Converted-Indication Withdrawn' ||
    apiData[avatarConfigs[0].AA_status] === 'Not Converted-Application Withdrawn'
  ) {
    AAcolor = 'red';
  }

  return avatarConfigs.map((avatarConfig: any) => {
    const title =
      avatarConfig?.label === 'REMS' || avatarConfig?.label === 'Orphan Medicine'
        ? `${avatarConfig.label}: ${uppperFirst(apiData[avatarConfig.hoverText]) || 'No'}`
        : `${avatarConfig.label}: ${capitalize(prefixText)} - ${
            uppperFirst(apiData[avatarConfig.hoverText]) || 'No'
          }`;

    const bgColor = getAvatarBackgroundColor(apiData, avatarConfig);

    let textColor = getTextColor(apiData, avatarConfig, AAcolor);
    if (bgColor === 'gray.200' && !textColor) {
      textColor = '#616161'; // hex for gray.lightVartiant4
    }
    return {
      title,
      shortForm: avatarConfig.initial,
      textColor,
      bgColor,
      showOnCard: avatarConfig?.showOnCard ?? true,
      showOnlyTrue: avatarConfig?.showOnlyTrue ?? false
    };
  });
};

export default getApprovalTracks;
