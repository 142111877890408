const styles = {
  navigationText: {
    color: 'black.light'
  },
  addToReport: {
    color: 'primary.main',
    fontWeight: 'bold',
    fontSize: 16,
    textTransform: 'capitalize',
    '&:hover': {
      background: 'none',
      cursor: 'pointer'
    }
  },
  closeBtn: {
    cursor: 'pointer',
    position: 'absolute',
    right: 20,
    top: 20,
    color: 'gray.650'
  },
  label: {
    fontSize: 14,
    color: 'gray.700',
    fontWeight: 400,
    fontFamily: 'Mulish'
  },
  labelSelected: {
    fontSize: 14,
    color: 'primary.600',
    fontWeight: 400,
    fontFamily: 'Mulish'
  },
  trialsSelectedFilterChipStyle: {
    border: '1px solid #27ae60'
  },
  fdaSelectedFilterChipStyle: {
    border: '1px solid #27ae60'
  },
  trialsRadioButtonStyle: {
    '&.Mui-checked': {
      color: '#27ae60'
    }
  },
  fdaRadioButtonStyle: {
    '&.Mui-checked': {
      color: '#27ae60'
    }
  },
  trialsFilterApplyStyle: {
    border: '2px solid #27ae60'
  },
  fdaFilterApplyStyle: {
    border: '2px solid #27ae60'
  },
  trialsSwitch: {
    '.Mui-checked': { color: (theme: any) => `${theme.palette.primary[650]} !important` },
    transform: 'scale(0.75)'
  },
  trialsMessage1: {
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontWeight: '700',
    color: 'grey.900'
  },
  trialsMessage2: {
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontWeight: '500',
    color: 'grey.900'
  },
  trialsMessage3: {
    fontFamily: 'Mulish',
    fontStyle: 'italic',
    fontSize: '14px',
    fontWeight: '400',
    color: 'grey.700'
  },
  trialBoxStyle: {
    mt: '30px',
    padding: '4px 12px 4px 12px',
    borderRadius: '8px',
    backgroundColor: 'grey.100',
    width: '500px',
    height: '30px'
  },
  infoWindow: {
    position: 'absolute',
    width: 380,
    minHeight: 72,
    top: 32,
    left: 32,
    borderRadius: 3,
    border: 2,
    borderColor: 'primary.400',
    boxShadow: '2px 4px 8px 0px rgba(0, 0, 0, 0.25)',
    zIndex: '100',
    p: 2.5,
    backgroundColor: 'gray.0',
    overflow: 'hidden'
  },
  viewDocumentButton: {
    p: 0,
    lineHeight: '100%',
    fontSize: 14,
    fontWeight: 600,
    color: 'primary.700',
    textTransform: 'none',
    width: '100%',
    justifyContent: 'space-between'
  },
  trialDetailsButton: {
    color: 'gray.800',
    border: '1px solid',
    borderColor: 'gray.400',
    borderRadius: 6.5,
    px: 1.5,
    height: 32,
    letterSpacing: 0.4,
    cursor: 'pointer',
    fontSize: 14
  },
  fdaApprovalsButton: {
    color: 'gray.800',
    border: '1px solid',
    borderColor: 'gray.400',
    borderRadius: 6.5,
    px: 1.5,
    height: 32,
    letterSpacing: 0.4,
    cursor: 'pointer',
    ml: 1,
    fontSize: 14,
    mr: 1
  },
  filterActiveButton: { color: 'white.main', fontSize: 14, mr: 1 },
  filterInactiveButton: { color: 'primary.700', fontSize: 14, mr: 1 },
  filterButtonContainer: {
    border: '1px solid',
    borderColor: 'primary.700',
    borderRadius: 6,
    fontWeight: 600,
    px: 3,
    height: 32,
    letterSpacing: 0.4,
    cursor: 'pointer',
    fontSize: 14,
    ml: 1
  },
  documentDrawer: {
    '& >.MuiDrawer-paper': {
      width: '50%',
      height: '100%',
      maxHeight: '100%',
      overflow: 'hidden',
      borderRadius: 5
    }
  },
  meetingDetails: {
    position: 'absolute',
    width: 380,
    minHeight: 72,
    maxHeight: '-webkit-fill-available',
    top: 32,
    left: 32,
    borderRadius: 3,
    border: 2,
    borderColor: 'primary.400',
    boxShadow: '2px 4px 8px 0px rgba(0, 0, 0, 0.25)',
    zIndex: '100',
    p: 2.5,
    backgroundColor: 'gray.100',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      borderRadius: '12px',
      width: 0.5
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray.400',
      borderRadius: 3,
      opacity: '0.8',
      marginTop: 0.25,
      marginBottom: 0.25
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
      marginTop: 0.25,
      marginBottom: 0.25
    }
  },
  infoWindowText: {
    fontSize: 14,
    textTransform: 'capitalize',
    color: 'gray.800'
  },
  meetingListContainer: {
    maxHeight: 320, // Set the maximum height for the Menu
    overflow: 'auto', // Enable scrolling when content exceeds the height
    '&::-webkit-scrollbar': {
      width: '7px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#E0E0E0FF',
      borderRadius: '12px'
    }
  }
};

export default styles;
