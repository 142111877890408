import { createSvgIcon } from '@mui/material';

const PlusIconReport = createSvgIcon(
  <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect
      x='0.444444'
      y='0.611131'
      width='15.1111'
      height='15.1111'
      rx='7.55556'
      stroke='#109E8A'
      strokeWidth='0.888889'
    />
    <path
      d='M8.51848 7.64817V5.05557H7.48145V7.64817H4.88885V8.6852H7.48145V11.2778H8.51848V8.6852H11.1111V7.64817H8.51848Z'
      fill='#109E8A'
    />
  </svg>,
  'PlusIconReport'
);

export default PlusIconReport;
