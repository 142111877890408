import { Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import showReg360 from '../../../pages/SearchResults/utils/showReg360';

interface GridRenderCellParams {
  // eslint-disable-next-line react/require-default-props
  value?: string;
  // eslint-disable-next-line react/require-default-props
  row?: any;
}
const DatagridReg360 = (props: GridRenderCellParams = { value: '', row: {} }) => {
  const { value, row } = props;

  const source = 'us';

  const shouldShowReg360 = showReg360(row, source) && row.appl_type !== 'ANDA';

  const handleReg360 = () => {
    const module = 'core';
    if (shouldShowReg360) {
      window.open(
        `/regulatory360/${module}/${value}/${source?.toLowerCase()}?type=${
          row.appl_type
        }&tabname=approvalHistory`,
        '_blank'
      );
    }
  };

  return (
    <Tooltip title={shouldShowReg360 ? 'Open Reg 360' : ''}>
      <Typography
        color='primary.700'
        fontWeight={700}
        variant='subtitle2'
        noWrap
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          '&:hover': {
            textDecoration: shouldShowReg360 ? 'underline' : 'none',
            cursor: shouldShowReg360 ? 'pointer' : 'initial'
          }
        }}
        onClick={handleReg360}>
        {value}
      </Typography>
    </Tooltip>
  );
};

export default DatagridReg360;
