/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import capitalize from 'lodash/capitalize';

// material ui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import LoadingButton from '@mui/lab/LoadingButton';

// icons
import CloseIcon from '@mui/icons-material/Close';

// components
import StartEndFilter from './StartEndFilter';
import FilterSearchField from './FilterSearchField';
import AutoSearchOptions from '../../../components/Index/AutoSearch/AutoSearch';

// context
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';
import AriaStore from '../../../store/Aria';
import AriaActions from '../../../store/Aria/actions';

// custom hooks
import useQuery from '../../../helpers/customHooks/queryParameter';
import useAriaFilters from '../../../hooks/useAriaFilters';

// styles
import styles from '../styles/Filters.styles';

// constants
import { filterOptions } from '../utils/constants';

const Filters = () => {
  const query = useQuery();
  const { dispatch } = useContext(GlobalStore);
  const { removeFromFilters, applyFilter, clearFilters } = useAriaFilters();
  const { module, source, searchId } = useParams();
  const { pathname } = useLocation();
  const { ariaState, ariaDispatch } = useContext(AriaStore);
  // states for showing filter options
  const [anchor, setAnchor] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [clear, setclear] = useState(false);
  const [options, setOptions] = useState();
  const [textInSearch, setTextInSearch] = useState('');
  const [showTextInSearchError, setShowTextInSearchError] = useState(false);

  /**
   * function to generate the list of options for selected filter type
   * @param {object} e event handler
   * @param {object} filterType filter category for the options list
   */
  const openFilterOptions = (e, filterType) => {
    setAnchor(e.currentTarget);
    setOpenPopup(true);

    const filters = ariaState?.globalFilters;

    if (Object.keys(filters).length > 0) {
      const optionsList = filters[filterType.kendraType]?.map(option => ({
        value: option.label,
        id: filterType.kendraType,
        label: filterType.label,
        count: option.count
      }));
      // sorting the object by value
      optionsList.sort((a, b) => (b.count > a.count ? 1 : -1));

      // set state for options list
      setOptions(optionsList);
    }
  };

  /**
   * function to add the selected filters from each category to context state
   * @param {object} e event handler
   * @param {object} option contains id and value for the filter category
   */
  const handleChange = (e, option) => {
    const currentFilters = ariaState.filters;

    if (option.id in currentFilters) {
      const temp = currentFilters[option.id];
      if (!temp.includes(option.value)) {
        temp.push(option.value);
        currentFilters[option.id] = temp;
      } else {
        currentFilters[option.id] = temp.filter(item => item !== option.value);
      }
    } else {
      currentFilters[option.id] = [option.value];
    }
    if (Array.isArray(currentFilters[option.id]) && !currentFilters[option.id]?.length) {
      delete currentFilters[option.id];
    }

    ariaDispatch({ type: AriaActions.SET_PENDING_FILTERS, value: true });
    ariaDispatch({ type: AriaActions.SET_FILTERS, value: currentFilters });
  };

  useEffect(() => {
    if (clear) {
      applyFilter(query, module, source, searchId, pathname, undefined, setclear);
    }
  }, [clear]);

  return (
    <Stack spacing={2} sx={styles.root}>
      {/* title and action button */}
      <Stack direction='row' display='flex' justifyContent='space-between'>
        <Typography color='primary' sx={styles.title} variant='subtitle1'>
          Filters
        </Typography>
        <Typography
          align='right'
          variant='subtitle1'
          onClick={() => {
            if (!ariaState.loading) clearFilters(setclear);
          }}
          sx={styles.clearText}>
          Clear Filters
        </Typography>
      </Stack>

      {/* filter categories */}
      <Stack mt={2} spacing={2} sx={{ height: '80vh', overflowY: 'auto' }}>
        {filterOptions[source]?.map(item => {
          if (item.filterType === 'range')
            return (
              <StartEndFilter
                key={item.id}
                title={item.label}
                filterType={item.kendraType}
                disabled={ariaState.loading}
                keyValue={item.value}
              />
            );

          return (
            <Stack key={item.id}>
              <Typography variant='subtitle1'>{item.label}</Typography>
              {/* show selected options */}
              {item.kendraType in ariaState.filters
                ? ariaState.filters[item.kendraType]?.map(val => {
                    return (
                      <Stack key={val} direction='row' sx={styles.selectedOptionsContainer}>
                        <CloseIcon
                          fontSize='small'
                          onClick={() => {
                            if (!ariaState.loading)
                              removeFromFilters(item.kendraType, val, item.filterType);
                          }}
                        />
                        <Typography variant='subtitle2'>
                          {item.label === 'Monoclonal Antibody'
                            ? capitalize(val)
                            : val.length > 15
                            ? `${val.substring(0, 15)}...`
                            : val}
                        </Typography>
                      </Stack>
                    );
                  })
                : null}
              <Button
                id='popup'
                disabled={ariaState.loading}
                onClick={e => openFilterOptions(e, item)}
                sx={styles.btn}>
                +Add
              </Button>
            </Stack>
          );
        })}

        {/* search term */}
        <FilterSearchField
          setTextInSearch={setTextInSearch}
          showTextInSearchError={showTextInSearchError}
          setShowTextInSearchError={setShowTextInSearchError}
          textInSearch={textInSearch}
          disabled={ariaState.loading}
        />
      </Stack>

      <Stack>
        <LoadingButton
          variant='contained'
          onClick={() => {
            if (textInSearch) {
              setShowTextInSearchError(true);
              dispatch({
                type: GlobalActions.SET_ALERT,
                value: {
                  message: 'Press Enter in the search bar to add the keyword.',
                  status: true,
                  duration: 4000
                }
              });
              return;
            }
            applyFilter(query, module, source, searchId, pathname, undefined, setclear);
          }}
          loading={ariaState.loading}
          loadingPosition='end'
          sx={{
            mt: 2,
            p: 2,
            width: '80%',
            textTransform: 'capitalize',
            fontSize: 16,
            borderRadius: 10,
            bgcolor: 'primary.main',
            color: 'white.main',
            cursor: 'pointer'
          }}>
          {ariaState.loading ? 'Applying' : 'Apply'}
        </LoadingButton>
      </Stack>

      {/* options popup */}
      <Popover
        id='popup'
        sx={{ width: 500, borderRadius: 2, padding: 16 }}
        open={openPopup}
        anchorEl={anchor}
        onClose={() => setOpenPopup(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <Box style={styles.popoverBox}>
          <AutoSearchOptions
            searchOptions={options}
            filterState={ariaState.filters}
            handleCheckboxChange={handleChange}
            closePopup={setOpenPopup}
          />
        </Box>
      </Popover>
    </Stack>
  );
};

export default Filters;
