import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import styles from './styles/GuideButton.styles';
import TutorialDrawer from './TutorialDrawer';
import { AngleLeftIcon } from '../../assets/svgs/Icons';

const GuideButton = () => {
  const [isTutorialDrawerOpen, setIsTutorialDrawerOpen] = useState(false);

  const openTutorialDrawer = () => {
    setIsTutorialDrawerOpen(true);
  };

  const closeTutorialDrawer = () => {
    setIsTutorialDrawerOpen(false);
  };

  return (
    <>
      <Box sx={styles.guideButtonWrapper} onClick={openTutorialDrawer}>
        <AngleLeftIcon sx={styles.icon} />
        <Typography sx={styles.guideText}>Guide</Typography>
      </Box>
      <TutorialDrawer isDrawerOpen={isTutorialDrawerOpen} closeDrawer={closeTutorialDrawer} />
    </>
  );
};

export default GuideButton;
