import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
// material ui
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { Auth, Hub } from 'aws-amplify';
import { datadogLogs } from '@datadog/browser-logs';
import { version } from '../../package.json';
import theme from '../theme';

// context
import { StateProvider } from '../store';
import { AuthProvider } from '../store/Auth/AuthProvider';

// routes
import PrivateRoute from '../PrivateRoutes';
import { PRIVATE_ROUTES, WITHOUT_NAV_ROUTES } from '../routes';

// error
import ErrorBoundary from './Error/ErrorBoundary';

// components
import AlertMessage from './CustomComponents/Alerts';
import NoInternetDialog from './CustomComponents/NoInternetDialog';
import AppLayout from './Layouts/AppLayout';

import { useBreadcrumbs } from '../hooks/useBreadcrumbs';
import useDetectNonChromeBrowser from '../hooks/useDetectNonChromeBrowser';
import { BREADCRUMB_ROUTES } from '../const';
import Loader from '../helpers/Loader';
import SessionLimitError from './Error/SessionLimitError';
import { ResultsStateProvider } from '../store/SearchResults';
import SourceMaintenance from './CustomComponents/Alerts/SourceMaintenance';

const Login = lazy(() => import('../pages/Login/Login'));
const Logout = lazy(() => import('../pages/Login/components/Logout'));
const ServiceRestriction = lazy(() => import('./Dialogs/ServiceRestriction'));

const RouteSwitches = () => {
  useBreadcrumbs(BREADCRUMB_ROUTES);
  const { detectBrowserAndDisplayDisclaimer } = useDetectNonChromeBrowser();

  useEffect(() => {
    detectBrowserAndDisplayDisclaimer();
  }, []);

  return (
    <Switch>
      <Route key='loginPage' exact path='/' component={Login} />
      <Route key='resetPassword' exact path='/reset' component={Login} />
      <Route key='sso' path='/login/:providerId' component={Login} />
      <Route key='logout' path='/logout' exact component={Logout} />

      {/* Routes without Navbar and Header */}
      {WITHOUT_NAV_ROUTES.map(item => (
        <PrivateRoute
          key={item.id}
          exact={item.exact}
          path={item.path}
          component={item.component}
        />
      ))}

      {/* User routes will be with Navbar and Header */}
      <ResultsStateProvider>
        <AppLayout>
          {PRIVATE_ROUTES.map(item => (
            <PrivateRoute
              key={item.id}
              exact={item.exact}
              path={item.path}
              component={item.component}
            />
          ))}
        </AppLayout>
      </ResultsStateProvider>
    </Switch>
  );
};
const App = () => {
  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'oAuthSignIn':
        case 'signIn':
          // signin actions
          Auth.currentSession()
            .then(() => {
              window.postMessage({
                payload: 'SIGN_IN'
              });
            })
            // eslint-disable-next-line no-console
            .catch(err => console.error(err));
          break;
        case 'oAuthSignOut':
        case 'signOut':
          // signout actions
          window.postMessage({
            payload: 'SIGN_OUT'
          });
          datadogRum.clearUser();
          datadogRum.removeGlobalContextProperty('organization_name');
          window.location.replace('/');
          break;
        default:
          break;
      }
    });

    window.onmessage = ({ data }) => {
      if (data?.payload === 'SIGN_OUT') {
        window.location.replace('/');
      }
      if (data?.payload === 'SIGN_IN') {
        window.location.reload();
      }
    };
    if (process.env.REACT_APP_ENV !== 'dev') {
      datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'ria-dev',
        env: process.env.REACT_APP_ENV, // Specify a version number to identify the deployed version of your application in Datadog
        version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        trackFrustrations: true,
        sampleRate: 100,
        silentMultipleInit: true,
        enableExperimentalFeatures: ['feature_flags']
      });
      datadogLogs.init({
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        service: 'ria-dev',
        sessionSampleRate: 100,
        env: process.env.REACT_APP_ENV,
        forwardConsoleLogs: 'all',
        forwardReports: 'all'
      });
    }
    if (localStorage.getItem('organization')) {
      datadogRum.setGlobalContextProperty(
        'organization_name',
        localStorage.getItem('organization')
      );
    }
    // datadogRum.startSessionReplayRecording();

    return unsubscribe;
  }, []);

  return (
    <div className='App'>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            <AuthProvider>
              <StateProvider>
                <AlertMessage />
                <ServiceRestriction />
                <NoInternetDialog />
                <SourceMaintenance />
                <Router>
                  <SessionLimitError />
                  <RouteSwitches />
                </Router>
              </StateProvider>
            </AuthProvider>
          </Suspense>
        </ErrorBoundary>
      </ThemeProvider>
    </div>
  );
};

export default App;
