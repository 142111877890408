const truncateCountAboveMillions = (num: number = 0) => {
  const strNum = num?.toString().split('') ?? '';
  if (strNum.length > 6) {
    return `${strNum.slice(0, strNum.length - 6).join('')}.${strNum[strNum.length - 6]}MM`;
  }
  if (strNum.length > 4) {
    if ((strNum[strNum.length - 3] as unknown as number) > 5) {
      const numThousand = Number(`${strNum.slice(0, strNum.length - 3).join('')}`) + 1;
      return `${numThousand}K`;
    }
    return `${strNum.slice(0, strNum.length - 3).join('')}K`;
  }
  return num;
};

export default truncateCountAboveMillions;
