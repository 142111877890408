const getDisplayableValue = (values: Array<any>, synonymsList: any, type: string) => {
  if (type !== 'mesh_tree_numbers') {
    return values;
  }

  const visitedValues: any = {};
  const finalValue: any = [];
  values?.forEach((val: string) => {
    const valueSynonym = synonymsList.find((syn: any) => syn.mesh_tree_numbers?.includes(val));
    if (valueSynonym?.label) {
      if (!visitedValues[valueSynonym?.label]) {
        visitedValues[valueSynonym?.label] = true;
        finalValue.push(val);
      }
    }
  });

  return finalValue;
};

export default getDisplayableValue;
