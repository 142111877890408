/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState, useContext, useCallback } from 'react';

// mui
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import { FolderIcon } from '../../../../assets/svgs/Icons';

// style
import styles from '../../styles/PDFCell.styles';
import { getDocumentsSignedURL, getLetters } from '../../../../api/pages/Letters';

import GlobalStore from '../../../../store';
import GlobalActions from '../../../../store/actions';
import PDFPreview from '../../../../components/PdfPreview/PDFPreview';

const GridCellDouments = ({ row }: any) => {
  const [PDFData, setPDFData] = useState<string>('');
  const [title, setTitle] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | any>(null);
  const [linkData, setLinkData] = useState<Array<any>>([]);
  const { dispatch } = useContext(GlobalStore) as any;

  const getData = async () => {
    const res = await getLetters(row.fda_letter_id);
    setLinkData(res?.data?.body[0]?.documents ?? []);
  };

  const handleOpenPopup = (e: React.SyntheticEvent) => {
    setAnchorEl(e.currentTarget);
    getData();
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
    setLinkData([]);
  };

  const handleSignedURL = useCallback(async (pdfLink: string) => {
    const res: any = await getDocumentsSignedURL({
      source: 'fda-letters',
      s3_path: pdfLink
    });
    return res?.data?.body?.s3_url ?? '';
  }, []);

  const handlePDFOpen = async (e: React.SyntheticEvent, linkText: string, name: string) => {
    setTitle(name);
    setPDFData(await handleSignedURL(linkText));
  };

  const handleChatRia = ({ pdfUrl = '' }) => {
    const mappedSourceName = 'fda-letters';
    dispatch({
      type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM,
      value: 'document'
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: mappedSourceName
    });

    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: {
        blob: pdfUrl,
        url: pdfUrl,
        item: {
          issue_date: row?.issue_date ?? '',
          company_name: row?.company_name ?? '',
          _category: 'FDA Warnings and Untitled Letters',
          title: row?.subject ?? ''
        },
        source: mappedSourceName,
        triggerReopenChatRia: false
      }
    });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
    setPDFData('');
    handleClosePopup();
  };

  return (
    <Box>
      <Stack
        direction='row'
        spacing={0.5}
        sx={styles.linkItem}
        alignItems='center'
        onClick={e => handleOpenPopup(e)}>
        <FolderIcon sx={{ fontSize: 12 }} />
        <Typography variant='subtitle2' sx={styles.pdfCellText}>
          {row.document_count}
        </Typography>
      </Stack>
      {linkData.length > 0 && (
        <Popover
          id='simple-popover'
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClosePopup}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          elevation={1}>
          <Grid container sx={styles.popoverDiv}>
            <Grid container>
              <Grid item xs={6}>
                <Grid>
                  <Typography sx={styles.linkHeading}>Documents</Typography>
                  {linkData.map(item => {
                    return (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <Link
                        key={item.doc_title}
                        sx={styles.link}
                        onClick={event => handlePDFOpen(event, item.s3_path, item.doc_title)}>
                        {item.doc_title}
                      </Link>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Popover>
      )}
      <Dialog
        fullWidth
        maxWidth='lg'
        onClose={() => {
          setPDFData('');
        }}
        aria-labelledby='customized-dialog-title'
        open={PDFData?.length !== 0}>
        <DialogTitle>
          <Grid container>
            <Grid container item xs={11} alignItems='center' />

            <Grid container item xs={1} alignItems='center'>
              <IconButton
                aria-label='delete'
                onClick={() => {
                  setPDFData('');
                }}>
                <CancelIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid xs={12} container alignItems='flex-start' item>
            <Grid xs={12} container alignItems='flex-start' item>
              {PDFData.length !== 0 ? (
                <div
                  style={{
                    height: '85vh',
                    width: '100vw',
                    boxShadow: '0px 0px 10px #e3f1ff',
                    border: 'unset',
                    borderRadius: '10px'
                  }}>
                  <PDFPreview
                    open={Boolean(PDFData)}
                    pdfUrl={PDFData}
                    onClose={() => {
                      setPDFData('');
                    }}
                    handleChatRia={handleChatRia}
                    title={title}
                  />
                </div>
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

const DatagridPDFCell = (params: any) => {
  const { row } = params;
  return <GridCellDouments row={row} />;
};

export default DatagridPDFCell;
