import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import styles from './styles';
import CustomPieChart from '../CustomComponents/Graphs/CustomPieChart';

const PieChart = ({ graphData, loading }: any) => {
  return (
    <Box display='flex' justifyContent='center' alignItems='center' sx={styles.graphContainer}>
      {loading ? (
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          sx={styles.graphContainer}>
          <CircularProgress
            value={90}
            thickness={5}
            size='12rem'
            variant='indeterminate'
            sx={{
              color: '#adadac',
              opacity: 0.5
            }}
          />
        </Box>
      ) : (
        <Box height='100%' width='100%'>
          {graphData?.data && graphData?.data?.length > 0 ? (
            <CustomPieChart
              data={graphData?.data}
              innerRadius={graphData?.graphStyle?.innerRadius}
              colors={graphData?.graphStyle?.colors}
              fillText={graphData?.graphStyle?.fillText}
              radialLabelsTextColor={graphData?.graphStyle?.radialLabelsTextColor}
              sliceLabelsTextColor={{ from: 'color' }}
              margin={graphData?.graphStyle?.margin}
              labelCharacterLimit={graphData?.graphStyle?.labelCharacterLimit}
              startAngle={graphData?.graphStyle?.startAngle}
            />
          ) : (
            <Typography variant='subtitle2' sx={{ fontSize: 20, fontWeight: 'bold' }}>
              No data
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default React.memo(PieChart);
