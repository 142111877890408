import { CARD_METADATA } from './constants';

export const processResultsForARIA = (source = 'us', results: any = []) => {
  if (Array.isArray(results)) {
    const processedResults = results?.map((a: any) => {
      if (source?.toLowerCase() === 'eu') {
        const center = a?.center;
        if (center?.toLowerCase() === 'ema-who')
          return `EMEA-H-W-${a[CARD_METADATA[source?.toLowerCase() as string].number]}`;
        return `EMEA-H-C-${a[CARD_METADATA[source?.toLowerCase() as string].number]}`;
      }
      return a[CARD_METADATA[source?.toLowerCase() as string].number];
    });
    return processedResults;
  }
  return [];
};
