import { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import styles from './VideoTutorialCard.styles';
import { PlayButtonIcon } from '../../../../assets/svgs/Icons';

const VideoTutorialCard = ({ video }: any) => {
  const history = useHistory();

  return (
    <Box sx={styles.container} onClick={() => history.push(`/help/videos/${video.id}`)}>
      <Box sx={styles.card}>
        <Typography sx={styles.howToTitle}>{video.howToTitle}</Typography>
        <Typography sx={styles.pageTitle}>{video.pageTitle}</Typography>
        <img
          src={video.thumbnail}
          alt={video.pageTitle}
          style={{ marginTop: '19px', width: '184px' }}
        />
      </Box>
      <Box className='playButtonContainer'>
        <PlayButtonIcon className='playButton' />
      </Box>
    </Box>
  );
};

export default memo(VideoTutorialCard);
