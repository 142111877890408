import { createSvgIcon } from '@mui/material';

const CircleCheckedIcon = createSvgIcon(
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_2527_18303)'>
      <path
        d='M12.6667 0H3.33334C1.49331 0.00221875 0.00221875 1.49331 0 3.33334V12.6667C0.00221875 14.5067 1.49331 15.9978 3.33334 16H12.6667C14.5067 15.9978 15.9978 14.5067 16 12.6667V3.33334C15.9978 1.49331 14.5067 0.00221875 12.6667 0ZM13.3333 5.386L7.164 11.5553C6.64341 12.0762 5.79919 12.0763 5.27838 11.5557L2.66666 8.94469C2.40578 8.68381 2.40578 8.26088 2.66666 8.00003C2.92753 7.73919 3.35047 7.73916 3.61131 8.00003L6.22197 10.6107L12.392 4.44134C12.6528 4.18141 13.075 4.18216 13.335 4.443C13.5949 4.70388 13.5942 5.12606 13.3333 5.386Z'
        fill='#109E8A'
      />
    </g>
    <defs>
      <clipPath id='clip0_2527_18303'>
        <rect width='16' height='16' rx='8' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'CircleCheckedIcon'
);

export default CircleCheckedIcon;
