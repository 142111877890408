import React from 'react';

// material ui v5
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import themev5 from '../../themev5';

import SearchResultsWrapper from './components/SearchResultsWrapper';

const SearchResults = () => {
  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <SearchResultsWrapper />
    </ThemeProvider>
  );
};

export default SearchResults;
