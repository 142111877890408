import valueFormatting from './valueFormatting';

const hoverValueFormatting = (num: number) => {
  const strNum = num.toString().split('').reverse();
  if (strNum.length > 6) {
    return valueFormatting(num, true);
  }
  return '';
};

export default hoverValueFormatting;
