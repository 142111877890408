import React from 'react';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

import Typography from '@mui/material/Typography';
import styles from './TimelineCircularAvatar.styles';

const TimelineCircularAvatar = ({ data, avatarList }: { data: any[]; avatarList: any[] }) => {
  let RM_color = '';
  const rems_color: any = 'rem_color';
  if (data[rems_color] !== '') {
    RM_color = data[rems_color];
  }
  const REMS_color: any = RM_color;

  let AA_color = '';
  let accelerated_approval_status: any = 'accelerated_approval_status';
  if (data[accelerated_approval_status] === 'Not Yet Converted') {
    AA_color = 'orange';
  } else if (
    data[accelerated_approval_status] === 'Not Converted-Indication Withdrawn' ||
    data[accelerated_approval_status] === 'Not Converted-Application Withdrawn'
  ) {
    AA_color = 'red';
  }
  const accelerated_approval_color = AA_color;
  return (
    <Box sx={styles.container}>
      {avatarList.map(avatar => {
        if (avatar?.showOnlyTrue && !data[avatar.fieldName]) return null;
        return (
          <Tooltip key={avatar.label} title={`${avatar.label}: ${data[avatar?.hoverText]}`}>
            <Avatar sx={data[avatar.fieldName] === false ? styles.avatarLight : styles.avatar}>
              {avatar.initial === 'AA' && data[accelerated_approval_status] != 'Converted' ? (
                <Typography variant='body1' style={{ color: accelerated_approval_color }}>
                  {avatar.initial}
                </Typography>
              ) : (
                avatar.initial != 'RM' && <Typography variant='body1'>{avatar.initial}</Typography>
              )}
              {/* REMS has customised initial(RM) color */}
              {avatar.initial === 'RM' && REMS_color && data[REMS_color] != '' ? (
                <Typography variant='body1' style={{ color: REMS_color }}>
                  {avatar.initial}
                </Typography>
              ) : (
                avatar.initial === 'RM' && <Typography variant='body1'>{avatar.initial}</Typography>
              )}
            </Avatar>
          </Tooltip>
        );
      })}
    </Box>
  );
};

export default React.memo(TimelineCircularAvatar);
