import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

// muiv5
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { UrlParameters } from '../../../types/reactRouterDom';

import VivproDatagrid from '../../../components/Datagrid';
import EmptyPage from '../../../components/EmptyPage/EmptyPage';

// context
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';

// hoc
import withFetchingPatents from './withFetchingPatents';

import { PATENTS } from './constants';

const PatentDetails = () => {
  const { regulatoryState } = useContext(RegulatoryInformationStore);
  const { source } = useParams<UrlParameters>();

  return (
    <Box display='flex' justifyContent='center' sx={{ width: '100%', height: '100%' }}>
      {!regulatoryState.loadingPatents && regulatoryState.patents?.length <= 0 ? (
        <EmptyPage message='Patents are not available' />
      ) : (
        <Stack sx={{ height: '60vh', width: 'calc(100% - 24px)' }}>
          <VivproDatagrid
            rows={regulatoryState?.patents}
            columnsMapping={PATENTS[source?.toLowerCase() as string]}
            rowId='patent_num'
            loading={regulatoryState?.loadingPatents}
          />
        </Stack>
      )}
    </Box>
  );
};

export default withFetchingPatents(PatentDetails);
