const stylesReport: any = {
    multipleText: {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
        borderRight: '2px solid #ADADAD',
        borderRadius: '0px'
      },
      '& .MuiOutlinedInput-input': {
        color: '#333333',
        fontSize: '14px',
        padding: '7px 20px 7px 20px',
        lineHeight: '20px'
      }
    },
    singleText: {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      },
      '& .MuiOutlinedInput-input': {
        color: '#333333',
        fontSize: '14px',
        padding: '7px 20px 7px 20px',
        lineHeight: '20px'
      }
    },
    selectLabelReport: {
      '& .MuiSelect-select': {
        lineHeight: '20px',
        color: '#333333',
        fontSize: '14px'
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      }
    }
  };

export {stylesReport}