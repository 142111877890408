/**
 *
 * @param {string} strdate date to generate in current timezone.
 * @description Given the string date it will generate in GMT format. In order to get the date string correct we will add the offset
 * again this will make the date to show correct date.
 * @returns date in current timezone rather than GMT
 * Sample return date format for EDT => Tue May 10 2022 00:00:00 GMT-0400 (Eastern Daylight Time)
 */
export const UTCtoLocalTime = strdate => {
  const date = new Date(strdate);
  const offset = new Date().getTimezoneOffset() / 60;
  date.setHours(date.getHours() + offset + 1);
  return date;
};

export default UTCtoLocalTime;
