// material ui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';

// material icons
import { IconButton, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import CloseIcon from '../../../assets/svgs/CardsPage/CloseIcon';

// styles
import Styles from '../../CardsPage/styles/ExtendedCard.styles';
import PrescribingInfo from '../../RegulatoryInformation/PrescribingInfo/PrescribingInfo';

const ViewLatestLabel = ({ open, setOpen }: any) => {
  const classes: any = Styles();
  return (
    <Dialog
      onClose={() => setOpen(false)}
      onClick={e => e.stopPropagation()}
      aria-labelledby='customized-dialog-title'
      open={open}
      fullWidth
      maxWidth='lg'
      disableScrollLock
      disableRestoreFocus>
      <Box
        display='flex'
        sx={{
          padding: '1.5rem'
        }}
        justifyContent='space-between'>
        <Typography
          sx={{
            flex: '1',
            fontWeight: 'bold',
            fontSize: '1.25rem'
          }}
          textAlign='center'>
          View Latest Label
        </Typography>
        <IconButton
          id='latest-label-view-close-button'
          onClick={e => {
            e.stopPropagation();
            setOpen(false);
          }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <DialogContent className={classes.root}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12}>
            <Box className={classes.extendedCard}>
              <PrescribingInfo />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ViewLatestLabel;
