import { createSvgIcon } from '@mui/material';

const RenameIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
    <path
      d='M13.3817 0.617638C12.9851 0.221669 12.4476 -0.000732422 11.8872 -0.000732422C11.3268 -0.000732422 10.7892 0.221669 10.3927 0.617638L0 11.0103V13.9993H2.989L13.3817 3.60664C13.7775 3.20999 13.9998 2.67251 13.9998 2.11214C13.9998 1.55177 13.7775 1.01429 13.3817 0.617638ZM2.50833 12.8326H1.16667V11.491L8.93083 3.73264L10.2725 5.0743L2.50833 12.8326ZM12.5568 2.7818L11.0944 4.24422L9.75567 2.90255L11.2175 1.44247C11.3954 1.26456 11.6367 1.1646 11.8883 1.1646C12.1399 1.1646 12.3813 1.26456 12.5592 1.44247C12.7371 1.62039 12.837 1.86169 12.837 2.1133C12.837 2.36492 12.7371 2.60622 12.5592 2.78414L12.5568 2.7818Z'
      fill='currentColor'
    />
  </svg>,
  'RenameIcon'
);

export default RenameIcon;
