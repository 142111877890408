/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';

import { VirtuosoGrid } from 'react-virtuoso';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/lab/Skeleton';

import { Box } from '@mui/material';
import { CARD_METADATA } from '../../ResultsPage/utils/constants';
import { ListContainer } from '../../ResultsPage/styledComponents/VirtualListContainers';
import Card from './Card';

import classes from '../styles/Index.style';

const CardContainer = React.memo(({ children }: { children: React.ReactNode }) => {
  return (
    <Grid item xs={12} md={6} sx={classes.cardGrid}>
      {children}
    </Grid>
  );
});

const SkeletonCard = React.memo(() => {
  return (
    <Grid xs={12} md={6} sx={classes.cardGrid}>
      <Stack spacing={2}>
        <Skeleton variant='text' width='50%' />
        <Skeleton
          variant='rectangular'
          sx={{
            marginTop: 1,
            width: '100%',
            height: 250,
            borderRadius: 10,
            transition: 'transform .2s',
            '&:hover': {
              transform: 'scale(1.1)'
            }
          }}
        />
      </Stack>
    </Grid>
  );
});

const SkeletonRenderer = () => <SkeletonCard />;

const CardList: React.FC<any> = ({
  errorMessage,
  cards,
  selectedCards,
  onCardSelection,
  source
}: any) => {
  const { number } = CARD_METADATA[source?.toLowerCase() as string];

  const checkShowId = (index: number) => {
    if (index >= 1) {
      const activeIngredient = Array.isArray(cards[index].active_ingredients)
        ? cards[index].active_ingredients.join(' ')
        : cards[index].active_ingredients;
      const activeIngredientPrevIndex = Array.isArray(cards[index - 1].active_ingredients)
        ? cards[index - 1].active_ingredients.join(' ')
        : cards[index - 1].active_ingredients;
      return activeIngredient !== activeIngredientPrevIndex;
    }
    return true;
  };

  const contentRenderer = useCallback(
    (index: number) => {
      const card = cards[index];
      return (
        <Card
          key={card[number]}
          source={source}
          index={index}
          selected={selectedCards.findIndex((crd: any) => crd[number] === card[number]) > -1}
          showId={checkShowId(index)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...card}
          onSelect={() => onCardSelection(card, source)}
        />
      );
    },
    [cards, source, selectedCards, onCardSelection, errorMessage]
  );
  return (
    <Box overflow='auto' height='100%' display='flex' flexWrap='wrap' justifyContent='center'>
      {errorMessage ? (
        <Box alignSelf='center' fontWeight='bold'>
          {errorMessage}
        </Box>
      ) : (
        <Grid container spacing={2}>
          <VirtuosoGrid
            style={{
              height: '96%',
              width: '100%',
              overflowY: 'auto',
              border: '0px solid green'
            }}
            totalCount={cards.length}
            overscan={1000}
            components={{
              Item: CardContainer as any,
              List: ListContainer as any,
              ScrollSeekPlaceholder: SkeletonRenderer
            }}
            itemContent={contentRenderer}
            scrollSeekConfiguration={{
              enter: velocity => Math.abs(velocity) > 200,
              exit: velocity => Math.abs(velocity) < 30
            }}
          />
        </Grid>
      )}
    </Box>
  );
};

export default React.memo(CardList);
