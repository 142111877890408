import { IconButton, Tooltip } from '@mui/material';
import React, { memo, useContext } from 'react';
import ShareLinks from '../../../components/Dialogs/ShareLink';
import GlobalActions from '../../../store/actions';
import GlobalStore from '../../../store';
import { ShareIcon } from '../../../assets/svgs/Icons';
import buttonStyles from '../styles/Buttons.styles';

interface ShareButtonProps {
  searchType: string;
  search: string;
  // eslint-disable-next-line react/require-default-props
  handleAriaShare?: any;
}

const ShareButton = ({ searchType, search, handleAriaShare = null }: ShareButtonProps) => {
  const { state, dispatch } = useContext(GlobalStore) as any;

  const handleShareClick = async () => {
    let textPrefix = '';
    if (searchType === 'advanced') {
      textPrefix = `Advanced search`;
    } else if (searchType === 'aria') {
      textPrefix = `ARIA Search: ${search}`;
      handleAriaShare?.();
    } else {
      textPrefix = `Search: ${search}`;
    }
    await dispatch({
      type: GlobalActions.SET_SHARE_LINK,
      value: {
        entityType: 'searchLink',
        entityDetails: { link: window.location.href },
        open: true,
        textPrefix
      }
    });
  };
  return (
    <>
      <Tooltip title='Share search through Collaborate'>
        <IconButton onClick={handleShareClick}>
          <ShareIcon sx={buttonStyles.buttonIcon} />
        </IconButton>
      </Tooltip>
      {state.shareLink?.open && <ShareLinks />}
    </>
  );
};
export default memo(ShareButton);
