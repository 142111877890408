const styles = {
  statsTile: {
    height: 68,
    borderRadius: 2,
    p: 2,
    bgcolor: 'gray.0'
  },
  countText: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 700,
    color: 'gray.800'
  },
  statsText: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '150%',
    color: 'gray.700',
    mt: -0.25
  }
};

export default styles;
