// material ui
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const LoadingAriaResults = () => {
  return (
    <Stack ml={2} mt={2} spacing={2}>
      <Skeleton variant='rectangle' width={200} />
      <Skeleton variant='rectangle' sx={{ width: '100%' }} />
      <Skeleton variant='rectangle' sx={{ width: '60%' }} />
      <Skeleton variant='rectangle' sx={{ width: '20%' }} />
      <Stack direction='row' spacing={2}>
        <Skeleton variant='rectangle' sx={{ width: '10%' }} />
        <Skeleton variant='rectangle' sx={{ width: '10%' }} />
        <Skeleton variant='rectangle' sx={{ width: '10%' }} />
      </Stack>
      <Skeleton variant='rectangle' sx={{ width: '100%' }} />
      <Skeleton variant='rectangle' sx={{ width: '60%' }} />
      <Skeleton variant='rectangle' sx={{ width: '20%' }} />
      <Stack direction='row' spacing={2}>
        <Skeleton variant='rectangle' sx={{ width: '10%' }} />
        <Skeleton variant='rectangle' sx={{ width: '10%' }} />
        <Skeleton variant='rectangle' sx={{ width: '10%' }} />
      </Stack>
      <Skeleton variant='rectangle' sx={{ width: '100%' }} />
      <Skeleton variant='rectangle' sx={{ width: '60%' }} />
      <Skeleton variant='rectangle' sx={{ width: '20%' }} />
      <Stack direction='row' spacing={2}>
        <Skeleton variant='rectangle' sx={{ width: '10%' }} />
        <Skeleton variant='rectangle' sx={{ width: '10%' }} />
        <Skeleton variant='rectangle' sx={{ width: '10%' }} />
      </Stack>
      <Skeleton variant='rectangle' sx={{ width: '100%' }} />
      <Skeleton variant='rectangle' sx={{ width: '60%' }} />
      <Skeleton variant='rectangle' sx={{ width: '20%' }} />
      <Stack direction='row' spacing={2}>
        <Skeleton variant='rectangle' sx={{ width: '10%' }} />
        <Skeleton variant='rectangle' sx={{ width: '10%' }} />
        <Skeleton variant='rectangle' sx={{ width: '10%' }} />
      </Stack>
      <Skeleton variant='rectangle' sx={{ width: '100%' }} />
      <Skeleton variant='rectangle' sx={{ width: '60%' }} />
      <Skeleton variant='rectangle' sx={{ width: '20%' }} />
      <Stack direction='row' spacing={2}>
        <Skeleton variant='rectangle' sx={{ width: '10%' }} />
        <Skeleton variant='rectangle' sx={{ width: '10%' }} />
        <Skeleton variant='rectangle' sx={{ width: '10%' }} />
      </Stack>
    </Stack>
  );
};

export default LoadingAriaResults;
