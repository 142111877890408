const styles = {
  pdfViewerContainer: {
    ml: 3,
    p: 2,
    mt: -2,
    height: '80vh',
    bgcolor: 'gray.background',
    borderRadius: '16px'
  },
  pdfViewerContainerUserProfile: {
    ml: 2,
    p: 2,
    bgcolor: 'gray.background',
    height: '100%',
    maxHeight: 'calc(100vh - 76px)',
    borderRadius: '16px'
  },
  pdfViewer: {
    maxHeight: '86%',
    width: '100%'
  },
  icon: {
    cursor: 'pointer',
    fontSize: 12,
    color: 'primary.main',
    fontWeight: 700
  },
  title: {
    color: 'gray.900',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '18px',
    textTransform: 'capitalize'
  },
  chatRiaBtn: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '19.2px',
    textTransform: 'capitalize',
    color: 'blue.backgroundLight'
  },
  tagText: {
    color: 'gray.main',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '21.98px'
  },
  feedbackContainer: {
    width: '100%',
    pl: 1,
    pr: 1,
    pb: 0.5,
    pt: 0.5,
    lineHeight: '17px',
    borderRadius: '8px',
    bgcolor: 'white.main',
    backgroundSize: 'contain  '
  },
  feedbackText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'primary.main',
    fontWeight: 700,
    lineHeight: '17px'
  },
  labelChip: {
    fontSize: 10,
    height: '100%',
    borderRadius: '8px',
    color: 'white.main'
  },
  divider: {
    bgcolor: 'primary.main',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '24px',
    textTransform: 'capitalize'
  },
  reg360Text: {
    color: 'primary.textVariant',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    cursor: 'pointer',
    textTransform: 'capitalize'
  }
};

export default styles;
