import { Button, Typography } from '@mui/material';
import React from 'react';
import styles from './styles/expandButton.styles';
import ExpandIcon from '../../assets/svgs/Report/ExpandIcon';

const ExpandButton = ({ handleClick }: any) => {
  return (
    <Button onClick={handleClick} variant='text' fullWidth={false} sx={styles.expandButton}>
      <Typography align='right' sx={styles.text}>
        <ExpandIcon sx={styles.expandIcon} /> Expand
      </Typography>
    </Button>
  );
};

export default React.memo(ExpandButton);
