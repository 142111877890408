import React from 'react';
import { FormControl, MenuItem, Stack, Typography } from '@mui/material';
import Select from '@mui/material/Select';

const VivproReportSelect = React.memo(
  ({ label, value, options, handleChange, disabled = false }: any) => {
    return (
      <Stack key={label} display='flex' flexDirection='column'>
        <Typography sx={{ fontSize: 14, color: '#A8A8A8', fontWeight: 400 }}>{label}</Typography>
        <FormControl key={label} sx={{ mr: 1, mb: 1, width: '95%' }}>
          <Select
            id={label}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            size='small'
            sx={{
              '& .MuiOutlinedInput-input': {
                fontSize: '14px'
              }
            }}>
            {options.map((opt: any) => (
              <MenuItem
                sx={{ color: 'red', fontSize: '14px' }}
                key={String(opt.value)}
                value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    );
  }
);

export default React.memo(VivproReportSelect);
