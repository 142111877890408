import React, { useState } from 'react';

import {
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Drawer,
  Container,
  Divider,
  Tabs,
  Tab
} from '@mui/material';

import { CrossIcon } from '../../../assets/svgs/Icons';

import AdministrativeSummary from './AdministrativeSummary';
import SubmissionsSummary from './SubmissionSummary';
import ClinicalSummary from './ClinicalSummary';

import styles from './styles';

interface SnapshotLayoutProps {
  dataItem: any;
  onclose: () => void;
  modalOpen: boolean;
}

const SnapshotLayout: React.FC<SnapshotLayoutProps> = ({ dataItem, onclose, modalOpen }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleModalClose = () => {
    setSelectedTab(0);
    onclose();
  };
  return (
    <Drawer
      sx={styles.drawerContainer}
      anchor='bottom'
      onClose={handleModalClose}
      open={!!modalOpen}>
      <Stack sx={{ bgcolor: 'white.main' }}>
        <Stack
          direction='row'
          spacing={2}
          p={3}
          justifyContent='space-between'
          useFlexGap
          sx={{ position: 'sticky' }}>
          <div />
          <Stack spacing={1} alignItems='center'>
            <Typography sx={styles.snapshotTitle}>Snapshot</Typography>
            <Divider sx={styles.divider} />
          </Stack>
          <Stack>
            <Tooltip title='Close'>
              <IconButton onClick={handleModalClose}>
                <CrossIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        {dataItem?.is_snapshot && Object.keys(dataItem?.snapshot)?.length > 1 && (
          <Tabs
            value={selectedTab}
            sx={styles.tabContainer}
            onChange={(ev, newValue) => setSelectedTab(newValue)}>
            {Object.keys(dataItem?.snapshot)?.map((each, index) => (
              <Tab
                sx={{
                  ...styles.productTab,
                  '&.Mui-selected': {
                    color: 'primary.700'
                  },
                  color: 'gray.800',
                  bgcolor: index === selectedTab ? 'primary.50' : 'none',
                  fontWeight: index === selectedTab ? 700 : 400,
                  border: index !== selectedTab ? '1px solid' : 'none',
                  borderColor: index !== selectedTab ? 'gray.300' : 'none'
                }}
                key={each}
                label={each}
              />
            ))}
          </Tabs>
        )}
        <Divider />
        {dataItem.is_snapshot && (
          <Box sx={{ bgcolor: 'gray.100' }}>
            <Container maxWidth='lg-xl'>
              <Grid
                container
                sx={{
                  ...styles.gridContainer,
                  maxHeight: Object.keys(dataItem?.snapshot || {})?.length > 1 ? '85vh' : '90vh'
                }}>
                <Grid item xs={5}>
                  <Stack spacing={2} p={1} mb={4}>
                    {/* administrative summary */}
                    <AdministrativeSummary dataItem={dataItem} selectedTabIndex={selectedTab} />
                    {/* submission summary */}
                    <SubmissionsSummary dataItem={dataItem} selectedTabIndex={selectedTab} />
                  </Stack>
                </Grid>
                <Grid item xs={7}>
                  <Stack spacing={2} p={1} mb={4}>
                    <ClinicalSummary dataItem={dataItem} selectedTabIndex={selectedTab} />
                  </Stack>
                </Grid>
              </Grid>
            </Container>
          </Box>
        )}
      </Stack>
    </Drawer>
  );
};

export default SnapshotLayout;
