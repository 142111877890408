export default {
  SET_LOADING: 'SET_LOADING',
  SET_PROFILE_TIMESTAMP: 'SET_PROFILE_TIMESTAMP',
  SET_PAGINATION_ON_SCROLL: 'SET_PAGINATION_ON_SCROLL',
  SET_ACTIVITY_DATA: 'SET_ACTIVITY_DATA',
  SET_SAVED_DATA: 'SET_SAVED_DATA',
  SET_REPORT_DATA: 'SET_REPORT_DATA',
  SET_PROJECT_LIST: 'SET_PROJECT_LIST',
  SET_CURRENT_PROJECT_ID: 'SET_CURRENT_PROJECT_ID'
};
