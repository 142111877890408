import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toNumber } from 'lodash';
import { getReferencePdf } from '../../../api/modules/ChatRIA';
import useQuery from '../../../helpers/customHooks/queryParameter';
import { PopUpWindow } from '../../Resources';

const ChatRIAReportReference = () => {
  const [open, setOpen] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = React.useState({ url: '', pageNumber: 0 });
  const { type }: any = useParams();
  const query = useQuery();
  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
    history.push('/');
  };
  const fetchData = useCallback(async () => {
    setLoading(true);
    const payload: any = {};
    if (type === 'aria') {
      payload.s3_path = decodeURIComponent(query.get('path') || '');
      payload.s3_bucket = query.get('bucket');
      payload.page_number = query.get('page');
    } else {
      payload.source = query.get('source')?.toLowerCase();
      if (payload.source?.toLowerCase() === 'eu') {
        payload.application_number = query
          .get('application')
          ?.replace('EMEA-H-C-', '')
          ?.replace('EMEA-H-W-', '');
      } else {
        payload.application_number = query.get('application');
      }
      payload.section = query.get('section');
    }
    const response = await getReferencePdf({
      type,
      ...payload
    });
    if (response?.data?.body) {
      const { s3_url: s3URL, page_number: pageNum } = response.data.body;
      const pageNumber = toNumber(pageNum) - 1 || 0;
      setPage({ url: `${s3URL}#page=${pageNumber}`, pageNumber });
    }
    setLoading(false);
  }, [query, type]);
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <PopUpWindow
      documentLink={page?.url}
      initialPage={page.pageNumber}
      handleClose={handleClose}
      loading={loading}
      open={open}
    />
  );
};
export default React.memo(ChatRIAReportReference);
