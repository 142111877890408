const styles = {
  drawer: {
    '& >.MuiDrawer-paper': {
      height: '60vh',
      borderRadius: '16px',
      paddingX: '24px',
      paddingY: '32px',
      position: 'absolute'
    }
  },
  drawerTitleText: {
    flexGrow: 1,
    textAlign: 'center',
    alignSelf: 'center',
    fontWeight: '700',
    fontSize: '20px',
    color: 'gray.800',
    textTransform: 'capitalize'
  },
  divider: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'gray.400',
    borderRadius: '2px',
    mt: 1,
    width: '64px'
  },
  headerWrapper: { display: 'flex', flexDirection: 'row', alignSelf: 'stretch' },
  closeIconButton: { position: 'absolute', right: '24px', top: '26px' },
  separator: { mb: '12px', mt: '12px', backgroundColor: 'gray.200' }
};

export default styles;
