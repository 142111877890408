import palette from '../../../themev5/palette';
import typography from '../../../themev5/typography';

const styles = {
  formControl: {
    width: 'inherit',
    boxShadow: 0,
    backgroundColor: palette.white.main,
    // marginLeft: '1vw',
    padding: '3px 10px',
    borderRadius: '10px',
    opacity: 1,
    '& .MuiInputLabel-shrink': {
      paddingTop: '0.75rem'
    }
  },
  inputLabel: {
    paddingLeft: '1.5rem',
    color: palette.black.light,
    fontFamily: 'Mulish',
    fontWeight: '400',
    fontSize: typography.subtitle2.fontSize
  },
  select: {
    paddingLeft: '0.5rem',
    color: palette.black.light,
    fontFamily: 'Mulish',
    fontWeight: '400',
    fontSize: typography.subtitle1.fontSize
  },
  menuText: {
    color: palette.black.light,
    fontFamily: 'Mulish',
    fontWeight: '400',
    fontSize: typography.subtitle1.fontSize
  }
};

export default styles;
