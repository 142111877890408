import { createSvgIcon } from '@mui/material';

const VerifyChatRiaIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
    <g clipPath='url(#clip0_12551_25560)'>
      <path
        d='M6.41699 10.2083C6.41773 9.46542 6.63669 8.73909 7.04668 8.11955C7.45668 7.50002 8.03964 7.01457 8.72317 6.72352C9.40669 6.43246 10.1607 6.34861 10.8914 6.48239C11.6222 6.61617 12.2976 6.96167 12.8337 7.476V0H2.91699C2.45286 0 2.00774 0.184374 1.67956 0.512563C1.35137 0.840752 1.16699 1.28587 1.16699 1.75V12.25C1.16699 12.7141 1.35137 13.1592 1.67956 13.4874C2.00774 13.8156 2.45286 14 2.91699 14H10.2087C9.20305 14 8.23862 13.6005 7.52755 12.8894C6.81647 12.1784 6.41699 11.2139 6.41699 10.2083ZM4.08366 2.91667H9.91699V4.08333H4.08366V2.91667ZM13.1504 13.9749L11.6005 12.425C11.1847 12.6904 10.7019 12.832 10.2087 12.8333C9.68948 12.8333 9.18197 12.6794 8.75029 12.3909C8.31861 12.1025 7.98216 11.6925 7.78348 11.2129C7.5848 10.7332 7.53281 10.2054 7.6341 9.69622C7.73538 9.18702 7.98539 8.71929 8.3525 8.35218C8.71962 7.98507 9.18735 7.73506 9.69655 7.63377C10.2057 7.53249 10.7335 7.58447 11.2132 7.78315C11.6929 7.98183 12.1028 8.31828 12.3913 8.74996C12.6797 9.18164 12.8337 9.68916 12.8337 10.2083C12.8323 10.7016 12.6907 11.1844 12.4253 11.6002L13.9752 13.1501L13.1504 13.9749Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_12551_25560'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'VerifyIcon'
);

export default VerifyChatRiaIcon;
