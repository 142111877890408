import { createSvgIcon } from '@mui/material';

const UnhideItemsIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='11' viewBox='0 0 14 11' fill='none'>
    <path
      d='M13.8955 5.02225C13.3833 3.90225 11.3749 0.25 6.99993 0.25C2.62493 0.25 0.616508 3.90225 0.104342 5.02225C0.0355889 5.17234 0 5.33549 0 5.50058C0 5.66567 0.0355889 5.82882 0.104342 5.97892C0.616508 7.09775 2.62493 10.75 6.99993 10.75C11.3749 10.75 13.3833 7.09775 13.8955 5.97775C13.9641 5.82783 13.9996 5.66488 13.9996 5.5C13.9996 5.33512 13.9641 5.17217 13.8955 5.02225V5.02225ZM6.99993 9.58333C3.32084 9.58333 1.60409 6.45317 1.16659 5.50642C1.60409 4.54683 3.32084 1.41667 6.99993 1.41667C10.6703 1.41667 12.3876 4.53342 12.8333 5.5C12.3876 6.46658 10.6703 9.58333 6.99993 9.58333Z'
      fill='currentColor'
    />
    <path
      d='M6.99968 2.5835C6.42281 2.5835 5.85891 2.75456 5.37926 3.07504C4.89962 3.39553 4.52578 3.85105 4.30503 4.384C4.08427 4.91695 4.02651 5.5034 4.13905 6.06918C4.25159 6.63495 4.52938 7.15465 4.93728 7.56256C5.34518 7.97046 5.86488 8.24825 6.43066 8.36079C6.99644 8.47333 7.58288 8.41557 8.11584 8.19481C8.64879 7.97406 9.10431 7.60022 9.4248 7.12058C9.74528 6.64093 9.91634 6.07703 9.91634 5.50016C9.91542 4.7269 9.60783 3.98557 9.06105 3.43879C8.51427 2.89201 7.77294 2.58442 6.99968 2.5835V2.5835ZM6.99968 7.25016C6.65356 7.25016 6.31521 7.14753 6.02743 6.95523C5.73964 6.76294 5.51534 6.48963 5.38289 6.16986C5.25043 5.85009 5.21578 5.49822 5.2833 5.15875C5.35083 4.81929 5.5175 4.50747 5.76224 4.26273C6.00698 4.01798 6.3188 3.85131 6.65827 3.78379C6.99773 3.71626 7.3496 3.75092 7.66937 3.88337C7.98914 4.01583 8.26246 4.24013 8.45475 4.52791C8.64704 4.8157 8.74968 5.15405 8.74968 5.50016C8.74968 5.96429 8.5653 6.40941 8.23711 6.7376C7.90892 7.06579 7.4638 7.25016 6.99968 7.25016Z'
      fill='currentColor'
    />
  </svg>,
  'UnhideItems'
);

export default UnhideItemsIcon;
