import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';

import styles from '../styles/EntitrySelectonWindow.styles';
import { CrossIcon } from '../../../assets/svgs/Icons';

import ResultsStore from '../../../store/SearchResults';

const EntitySelectionWindow = ({
  openEntityPopup,
  setOpenEntityPopup,
  entityList,
  handleEntityClick,
  displayText = '',
  triggeredFromPopup = false
}: {
  openEntityPopup: boolean;
  setOpenEntityPopup: any;
  entityList: any[];
  handleEntityClick: any;
  // eslint-disable-next-line react/require-default-props
  displayText?: string;
  // eslint-disable-next-line react/require-default-props
  triggeredFromPopup?: boolean;
}) => {
  const history = useHistory();
  const { resultsState } = useContext(ResultsStore);
  const handleClose = (event: any, reason: any) => {
    if (reason && reason === 'backdropClick') return;
    setOpenEntityPopup(false);
  };
  const suggestions = entityList?.filter(entry => !entry.isOriginalSearch);
  let searchTerm = !triggeredFromPopup ? resultsState?.decryptedPayload?.query || '' : displayText;
  const originalSearchTerm = entityList?.filter(entry => entry.isOriginalSearch);
  if (originalSearchTerm?.length) {
    searchTerm = originalSearchTerm[0]?.text;
  }
  return (
    <Stack>
      <Dialog
        hideBackdrop
        disableEscapeKeyDown
        open={openEntityPopup}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        PaperProps={{
          elevation: 2,
          sx: styles.dialogPaper
        }}
        sx={styles.dialogWrapper}>
        <DialogContent>
          <Tooltip title='Close'>
            <IconButton
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                !triggeredFromPopup ? history.go(-2) : setOpenEntityPopup(false);
              }}
              sx={styles.closeButton}>
              <CrossIcon />
            </IconButton>
          </Tooltip>
          <Box sx={styles.contentWrapper}>
            <Typography sx={styles.keywordsFoundText}>
              {suggestions?.length} keyword{suggestions?.length > 1 && '(s)'} found in the search:
            </Typography>
            <Typography sx={styles.keywordsQueryText}>
              {searchTerm?.split(' ')?.map((term: any) => {
                if (suggestions?.some(entity => entity.text === term)) return <span>{term}</span>;
                return `${term} `;
              })}
            </Typography>
            <Typography sx={styles.selectTermText}>
              Select a keyword for which you want to see result:
            </Typography>
            <Stack sx={styles.buttonsWrapper}>
              {suggestions?.map((entity: any) => (
                <Button
                  variant='outlined'
                  key={entity.text}
                  onClick={() => handleEntityClick(entity.text)}
                  size='small'
                  sx={styles.button}>
                  {entity.text}
                </Button>
              ))}
            </Stack>
          </Box>
          <Divider variant='middle' sx={styles.divider} />
          <Stack justifyContent='center' alignItems='center'>
            <Typography sx={{ ...styles.selectTermText, mb: 0, mt: 0 }}>
              or continue searching for:
            </Typography>
            <Button
              variant='text'
              sx={styles.searchTerm}
              onClick={() => handleEntityClick(searchTerm)}>
              {searchTerm}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default EntitySelectionWindow;
