export const GRAPH_LABEL_LENGTH_LIMIT = 32;

// Function to get the string size in pixels.
export const getWidthOfText = (txt: string, fontSize: number) => {
  let element;
  if (!element) {
    element = document.createElement('span');
    document.body.appendChild(element);
  }
  element.style.fontSize = `${fontSize}px`;
  element.innerText = txt;
  element.style.position = 'absolute';
  element.style.textTransform = 'uppercase';
  element.style.whiteSpace = 'nowrap';
  element.style.top = '-1000px';
  element.innerText = txt;
  return element.offsetWidth;
};

export const calculateSineInDegrees = (degrees: number) => {
  const radians = degrees * (Math.PI / 180);
  return Math.sin(radians);
};

export const getFontSize = (data: Array<any>, index: string, tickRotationValue: number) => {
  let maxLengthString = data.reduce(
    (max, obj) => (obj[index].length > max.length ? obj[index] : max),
    data[0][index]
  );
  if (maxLengthString.length > GRAPH_LABEL_LENGTH_LIMIT) {
    const maxLengthStringArray = maxLengthString.split('');
    maxLengthStringArray.length = GRAPH_LABEL_LENGTH_LIMIT;
    maxLengthString = maxLengthStringArray.join('');
  }
  let calculatedSize = Math.ceil(
    getWidthOfText(maxLengthString, 14) * calculateSineInDegrees(tickRotationValue ?? 90) + 1
  );
  if (calculatedSize === 0) {
    calculatedSize = 140;
  }
  calculatedSize = Math.max(40, calculatedSize);
  return calculatedSize;
};

// Function to split the label into multiple lines based on the character limit
export const getformatLabel = (label: string, limit: number) => {
  const words = label?.split(' ');
  const lines = [];
  let currentLine = '';
  if (words && words.length === 0) {
    return [label];
  }
  words?.forEach(word => {
    if (currentLine.length + word.length + 1 <= limit) {
      currentLine = currentLine ? `${currentLine} ${word}` : word;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  });
  lines.push(currentLine);
  return lines;
};
