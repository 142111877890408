import { useCallback, useContext, useEffect } from 'react';
import Papa from 'papaparse';
import XLSX from 'xlsx';

// api
import {
  postHTAGuidanceDocs,
  postHTAInsights,
  getAllHTADocuments
} from '../../../api/pages/HTADocuments';
import HTAStore from '../../../store/HTADocuments';
import globalActions from '../../../store/actions';
import globalStore from '../../../store/index';

// store
import actions from '../../../store/HTADocuments/actions';
import { constructPieChartData } from '../utils/getDonutChartStructure';
import getFilterOptions from '../utils/common';
import {
  EXPORT_TYPE,
  HTA_GUIDANCE_COLUMN_NAME_MAPPING,
  PAGINATION_DEFAULT_OPTIONS
} from '../const';
import transformData from '../../../utils/transformData';
import { getFileName } from '../../../helpers/getFileName';

import AuthContext from '../../../store/Auth/AuthProvider';

import { getDataGridFilter } from '../../../utils/getDatagridFilterOption';

const useHTAData = () => {
  const { HTAState, HTADispatch } = useContext(HTAStore);
  const { state, dispatch }: any = useContext(globalStore);
  const { currentUser } = useContext(AuthContext);

  const getHTAGuidanceTableData = async (payload: any, limit: number, offset: number) => {
    const res = await postHTAGuidanceDocs(payload, limit, offset);
    if (res?.data?.status === 200) {
      HTADispatch({
        type: actions.SET_HTA_TABLE,
        value: res?.data?.body?.nice_guidances
      });

      HTADispatch({
        type: actions.SET_TOTAL_COUNT,
        value: res?.data?.body?.nice_guidances_count ?? 0
      });
    }
  };

  const getHTAInsightData = async (filters: any, appliedFilters: any) => {
    const res = await postHTAInsights({ filters });
    if (res.data.status === 200) {
      const graphs = constructPieChartData(res?.data?.body?.nice_insights?.donut_graph_data);

      HTADispatch({
        type: actions.SET_TREEMAP_CHARTS,
        value: graphs
      });

      HTADispatch({
        type: actions.SET_DOC_TYPE_TREEMAP,
        value: res?.data?.body?.nice_insights?.donut_graph_data?.nice_doc_type
      });

      HTADispatch({
        type: actions.SET_STATUS_TREEMAP,
        value: res?.data?.body?.nice_insights?.donut_graph_data.std_status
      });

      HTADispatch({
        type: actions.SET_ADVICE_TYPE_TREEMAP,
        value: res?.data.body?.nice_insights?.donut_graph_data?.nice_advice_type
      });

      HTADispatch({
        type: actions.SET_ISSUE_DATE,
        value: res?.data?.body?.nice_insights?.bar_graph_data.filter((item: any) => item.count > 0)
      });

      const stats = {
        totalLastThirtyDays: res?.data?.body?.nice_insights?.last_30_days_count,
        totalGuidancesCount: res?.data.body?.nice_insights?.total_guidances,
        totalNiceRefCount: res?.data?.body?.nice_insights?.total_nice_ref,
        pdfCount: res?.data?.body?.nice_insights?.pdf_stats?.total_pdf_count,
        pageCount: res?.data?.body?.nice_insights?.pdf_stats?.total_page_count
      };

      HTADispatch({
        type: actions.SET_HTA_STATS,
        value: stats
      });
      const newfilters = getFilterOptions(res?.data?.body?.filters);

      HTADispatch({
        type: actions.SET_ASK_RIA_FILTERS,
        value: res?.data?.body?.filters?.guidance_ref ?? []
      });

      HTADispatch({
        type: actions.SET_FILTERS,
        value: newfilters
      });

      HTADispatch({
        type: actions.SET_AVAILABLE_FILTERS,
        value: appliedFilters
      });
    }
  };

  // Hook to fetch data based on datagrid filter
  const getDatawithDataGridFilter = async (
    appliedFilters: any,
    datagridFilter: any,
    sortFields: any,
    limit: number = PAGINATION_DEFAULT_OPTIONS.limit,
    offset: number = PAGINATION_DEFAULT_OPTIONS.offset
  ) => {
    HTADispatch({
      type: actions.SET_LOADING,
      value: true
    });
    const source = {
      uk: ['hta']
    };
    const res: any = await getDataGridFilter(
      appliedFilters,
      datagridFilter,
      sortFields,
      limit,
      offset,
      HTAState,
      currentUser,
      source
    );
    if (res?.data?.status === 200) {
      HTADispatch({
        type: actions.SET_HTA_TABLE,
        value: res?.data?.body?.result?.hta?.results ?? []
      });

      HTADispatch({
        type: actions.SET_TOTAL_COUNT,
        value: res?.data?.body?.result?.hta?.total ?? 0
      });
      HTADispatch({
        type: actions.SET_DATA_GRID_FILTERS,
        value: datagridFilter
      });
    }
    HTADispatch({
      type: actions.SET_LOADING,
      value: false
    });
  };

  const getHTAData = useCallback(
    async (
      appliedFilters: any,
      limit: number = PAGINATION_DEFAULT_OPTIONS.limit,
      offset: number = PAGINATION_DEFAULT_OPTIONS.offset,
      sort: Array<any> = [],
      shouldGetInsight: boolean = true,
      dataGridFilter: any = {}
    ) => {
      HTADispatch({
        type: actions.SET_LOADING,
        value: true
      });

      HTADispatch({
        type: actions.SET_IS_FILTERS_APPLIED,
        value: Object.keys(appliedFilters).length !== 0
      });

      const payload: any = {};
      payload.filters = { ...appliedFilters };
      if (sort) {
        payload.sort = sort;
      }

      if (Object.keys(dataGridFilter).length !== 0) {
        await getDatawithDataGridFilter(appliedFilters, dataGridFilter, sort, limit, offset);
      } else {
        try {
          await getHTAGuidanceTableData(payload, limit, offset);
          if (shouldGetInsight) {
            await getHTAInsightData(payload?.filters, appliedFilters);
          }
          HTADispatch({
            type: actions.SET_DATA_GRID_FILTERS,
            value: {}
          });
          HTADispatch({
            type: actions.SET_LOADING,
            value: false
          });
        } catch (err) {
          HTADispatch({
            type: actions.SET_LOADING,
            value: false
          });
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }
    },
    []
  );

  const handleCSVExport = async () => {
    HTADispatch({
      type: actions.SET_LOADING,
      value: true
    });
    const res = await getAllHTADocuments({ filters: HTAState.availableFilters });
    const selectedColumns = state?.exportType?.isAllColumnsSelected
      ? HTA_GUIDANCE_COLUMN_NAME_MAPPING
      : Object.fromEntries(
          Object.entries(HTA_GUIDANCE_COLUMN_NAME_MAPPING).filter(([key]) =>
            state?.exportType?.selectedColumns?.includes(key)
          )
        );
    const formattedData = transformData(res?.data?.body?.nice_guidances, selectedColumns);
    const csv = Papa.unparse(formattedData);
    // Create a Blob from the CSV string
    const blob = new Blob([csv], { type: 'text/csv' });
    // Create a download link and click it
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    const day = new Date();
    const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
    const fileName = getFileName({
      prefix: 'ria',
      module: 'hta',
      postfix: date,
      additional: '',
      extension: 'csv'
    });
    a.download = `${fileName}.csv`;
    a.click();
    dispatch({
      type: globalActions.SET_EXPORT_TYPE,
      value: { format: '', selectedColumns: [], isAllColumnsSelected: false }
    });
    HTADispatch({
      type: actions.SET_LOADING,
      value: false
    });
  };

  const handleExcelExport = async () => {
    HTADispatch({
      type: actions.SET_LOADING,
      value: true
    });
    const res = await getAllHTADocuments({ filters: HTAState.availableFilters });
    const selectedColumns = state?.exportType?.isAllColumnsSelected
      ? HTA_GUIDANCE_COLUMN_NAME_MAPPING
      : Object.fromEntries(
          Object.entries(HTA_GUIDANCE_COLUMN_NAME_MAPPING).filter(([key]) =>
            state?.exportType?.selectedColumns?.includes(key)
          )
        );
    const formattedData = transformData(res?.data?.body?.nice_guidances, selectedColumns);
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const day = new Date();
    const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
    const fileName = getFileName({
      prefix: 'ria',
      module: 'hta',
      postfix: date,
      additional: '',
      extension: 'xlsx'
    });
    XLSX.writeFile(wb, `${fileName}.xlsx`);
    dispatch({
      type: globalActions.SET_EXPORT_TYPE,
      value: { format: '', selectedColumns: [], isAllColumnsSelected: false }
    });
    HTADispatch({
      type: actions.SET_LOADING,
      value: false
    });
  };

  useEffect(() => {
    if (
      state?.exportType?.format &&
      [EXPORT_TYPE.csv, EXPORT_TYPE.xlsx].includes(state?.exportType?.format)
    ) {
      if (state?.exportType?.format === EXPORT_TYPE.csv) {
        handleCSVExport();
      } else if (state?.exportType?.format === EXPORT_TYPE.xlsx) {
        handleExcelExport();
      }
    }
  }, [state?.exportType]);

  return {
    isLoading: HTAState.loading,
    htafilters: HTAState.filters,
    htaTableData: HTAState.tableData,
    isFilterApplied: HTAState.isFiltersApplied,
    getHTAData,
    sortFields: HTAState.sortFields,
    getDatawithDataGridFilter,
    dataGridFilter: HTAState.dataGridFilters
  };
};

export default useHTAData;
