import { Chip, ListItem, Stack } from '@mui/material';
import React from 'react';
import styles from './BottomDrawer.styles';

const HeaderTabs = ({
  tabList,
  selectedTab,
  handleChange
}: {
  tabList: any[];
  selectedTab: string;
  handleChange: any;
}) => {
  return (
    <Stack direction='row' justifyContent='space-between' sx={styles.headerContainer}>
      <Stack direction='row'>
        {tabList.map((type: any) => {
          return (
            <ListItem
              key={type.id}
              sx={{
                pl: 0,
                pr: '8px',
                py: 0
              }}>
              <Chip
                onClick={() => handleChange(type.id)}
                sx={styles.chip}
                variant={selectedTab === type.id ? 'filled' : 'outlined'}
                label={type.label}
              />
            </ListItem>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default React.memo(HeaderTabs);
