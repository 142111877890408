import React from 'react';

// material ui v5
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import themev5 from '../../themev5';

// components
import Banner from './components/Banner';
import useUpdateModule from '../../hooks/useUpdateModule';

const Aria = () => {
  useUpdateModule('helpCenter');

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Banner />
    </ThemeProvider>
  );
};

export default Aria;
