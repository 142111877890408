import Box from '@mui/material/Box';
import React, { useEffect, useRef } from 'react';
import Typography from '@mui/material/Typography';
import styles from './TableView.styles';

const TableView = ({
  data,
  columnNameMapping,
  onUpdatePlacement,
  order = [],
  referenceText = {},
  title = {},
  fromWidget = false
}: any) => {
  const viewRef = useRef<any>();
  const titleRef = useRef<any>();
  const referenceRef = useRef<any>();
  const columnDef = order
    .filter((key: string) => !!columnNameMapping[key])
    .map((key: string) => ({
      field: key,
      headerName: columnNameMapping[key]
    }));

  useEffect(() => {
    const clientHeight = viewRef?.current?.clientHeight || 0;
    const titleHeight = titleRef?.current?.clientHeight || 0;
    // eslint-disable-next-line no-unsafe-optional-chaining
    let referencHeight = referenceRef?.current?.clientHeight || 8;
    referencHeight = referencHeight === 8 ? 8 : referencHeight + 20;
    const totalHeight = clientHeight + titleHeight + referencHeight + 40;
    onUpdatePlacement?.(Math.ceil(totalHeight / 10) + 1);
  }, [data]);

  return (
    <>
      {title.show && (
        <Box marginBottom='10px' textAlign='center' ref={titleRef}>
          <Typography fontSize={`${title.fontSize}px`} textAlign='center'>
            {title.title}
          </Typography>
        </Box>
      )}
      <Box
        id='table-container'
        sx={{
          ...styles.main,
          overflowY: fromWidget ? 'auto' : null,
          height: fromWidget ? '85%' : null,
          fontSize: '12px'
        }}>
        <table ref={viewRef}>
          <thead
            style={{
              position: 'sticky',
              top: '0',
              display: 'contents'
            }}>
            <tr>
              {columnDef.map((column: any) => (
                <th key={column.field}>{column.headerName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row: any) => (
              <tr key={row.id}>
                {columnDef.map((column: any) => (
                  <td key={column.field}>{String(row[column.field])}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {referenceText.show && !fromWidget && (
          <Box
            ref={referenceRef}
            position='relative'
            textAlign='center'
            m={1}
            sx={{ display: 'flex', justifyContent: 'center' }}>
            {referenceText.text &&
              referenceText?.text?.split('/').map((item: string) => {
                return (
                  <Box
                    key={item}
                    sx={{
                      fontFamily: 'Arial, sans-serif',
                      fontStyle: 'italic',
                      mt: '1rem',
                      ml: '1rem',
                      mb: '10px',
                      display: 'flex',
                      fontSize: '12px',
                      border: '1px solid #ddd',
                      padding: '0 0.5rem',
                      alignItems: 'center'
                    }}>
                    {item}
                  </Box>
                );
              })}
            <Box
              sx={{
                fontFamily: 'Arial, sans-serif',
                fontStyle: 'italic',
                mt: '1rem',
                ml: '1rem',
                mb: '10px',
                display: 'flex',
                fontSize: '12px',
                border: '1px solid #ddd',
                padding: '0 0.5rem',
                alignItems: 'center'
              }}>
              Source:{' '}
              <a
                href={referenceText.href}
                target='_blank'
                style={{ fontSize: '12px', textAlign: 'center' }}
                rel='noreferrer'>
                RIA
              </a>
            </Box>
          </Box>
        )}
      </Box>
      {referenceText.show && fromWidget && (
        <Box
          ref={referenceRef}
          position='relative'
          bottom='0px'
          textAlign='center'
          m={1}
          sx={{ display: 'flex', justifyContent: 'center' }}>
          {referenceText.text &&
            referenceText?.text?.split('/').map((item: string) => {
              return (
                <Box
                  key={item}
                  sx={{
                    fontFamily: 'Arial, sans-serif',
                    fontStyle: 'italic',
                    mt: '1rem',
                    ml: '1rem',
                    mb: '10px',
                    display: 'flex',
                    fontSize: '12px',
                    border: '1px solid #ddd',
                    padding: '0 0.5rem',
                    alignItems: 'center'
                  }}>
                  {item}
                </Box>
              );
            })}
          <Box
            sx={{
              fontFamily: 'Arial, sans-serif',
              fontStyle: 'italic',
              mt: '1rem',
              ml: '1rem',
              mb: '10px',
              display: 'flex',
              fontSize: '12px',
              border: '1px solid #ddd',
              padding: '0 0.5rem',
              alignItems: 'center'
            }}>
            Source:{' '}
            <a
              href={referenceText.href}
              target='_blank'
              style={{ fontSize: '12px', textAlign: 'center' }}
              rel='noreferrer'>
              RIA
            </a>
          </Box>
        </Box>
      )}
    </>
  );
};

export default React.memo(TableView);
