/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
// mui v5
import Skeleton from '@mui/lab/Skeleton';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import Tooltip from '@mui/material/Tooltip';
import CardList from './CardList';
import classes from '../styles/Index.style';
import { menuList } from '../const';
import useLabelSearchData from '../hooks/useLabelSearch';
import styles from '../styles/SourceWiseResult.style';
import CustomPopover from './CustomPopover';

interface SourceData {
  sourceName: string;
  sourceDescription: string;
  selectedCards: unknown[];
  selectedSourceList: unknown[];
  handleCardSelection: (card: any, source: string) => void;
  searchTerm: string;
  handleSourceChange: (event: any, currentSource: any) => void;
}

const SourceWiseResult = (source: SourceData) => {
  const {
    sourceName,
    sourceDescription,
    selectedCards,
    selectedSourceList,
    handleCardSelection,
    searchTerm,
    handleSourceChange
  } = source;
  const {
    cards: cards_,
    isLoading: isLoading_,
    errorMessage: errorMessage_,
    getCards: getCards_
  } = useLabelSearchData();
  const [isResultsLoading, setIsResultsLoading] = useState(true);
  const [isSourceChanged, setIsSourceChanged] = useState(false);

  useEffect(() => {
    if (!searchTerm) {
      return;
    }
    setIsResultsLoading(true);
    getCards_(sourceName);
    setIsResultsLoading(false);
    // eslint-disable-next-line no-restricted-globals
  }, [searchTerm, sourceName, location.search]);
  // we have to call getCards_ when the search terms chnages.
  // but for approval_date we have to check for location.search as searchTerm remains the same just the parametres changes

  useEffect(() => {
    if (!isLoading_) {
      setIsSourceChanged(false);
    }
  }, [isLoading_]);

  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => {
    setIsOpen(prev => !prev);
  };
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setIsOpen(false);
  };

  const handleMenuItemClick = (key: string) => {
    setIsSourceChanged(true);
    setIsOpen(false);
    handleSourceChange(key, sourceName);
    handleMenuClose();
  };

  // Filter the 'menuList' to get items not present in 'selected'
  const isNotSelected = (item: any) => {
    return !selectedSourceList.some((selectedItem: any) => selectedItem.sourceName === item.value);
  };
  const menuItems = menuList.filter(isNotSelected);
  return (
    <>
      <Box textAlign='center' paddingX={1} paddingBottom={0} sx={classes.shadowborder}>
        <Box sx={{ position: 'relative' }}>
          <Box sx={styles.sourceSelectTop}>
            <Typography fontSize={18} color='white.main' display='inline' marginRight={1}>
              {sourceDescription}
              <b>{searchTerm !== 'date' ? `: ${searchTerm}` : null}</b>
              {!isSourceChanged && cards_.length > 0 ? `: ${cards_.length}` : ''}
            </Typography>
            <Tooltip title='Click to switch the Source' arrow placement='right'>
              <IconButton
                aria-label='menu'
                aria-controls='menu-list'
                aria-haspopup='true'
                ref={anchorRef} // Add the ref to IconButton
                onClick={handleToggle}
                style={{ color: 'white' }}>
                <SwapHorizontalCircleIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <CustomPopover
            isOpen={isOpen}
            anchorRef={anchorRef}
            handleMenuClose={handleMenuClose}
            handleMenuItemClick={handleMenuItemClick}
            menuItems={menuItems}
          />
        </Box>
      </Box>
      {isSourceChanged || isLoading_ ? (
        <Box overflow='auto' height='100%' display='flex' flexWrap='wrap' justifyContent='center'>
          {Array(6)
            .fill(1)
            .map((_, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i}>
                <div>
                  <Skeleton variant='text' width='50%' />
                  <Skeleton variant='rectangular' sx={classes.skeletonCard} />
                </div>
              </div>
            ))}
        </Box>
      ) : (
        <CardList
          cards={cards_}
          errorMessage={errorMessage_}
          selectedCards={selectedCards}
          onCardSelection={handleCardSelection}
          source={sourceName.toUpperCase()}
        />
      )}
    </>
  );
};
export default SourceWiseResult;
