import React, { createContext, useReducer } from 'react';
import reducers from './reducers';

type Props = {
  children: React.ReactNode;
};

export interface pediatricsFDAContextInterface {
  stats: any;
  tableData: any[];
  showLabelCompare: boolean;
  labelCompareList: any;
  formulationGraph: any[];
  routesGraph: any[];
  determinationGraph: any[];
  errors: any[];
  filters: any[];
  availableFilters: any;
  isFiltersApplied: boolean;
  loading: boolean;
  totalRecord: number;
  sortFields: any[];
  askRiaFilters: any[];
  dataGridFilters: any;
}

const initialState = {
  stats: {},
  tableData: [],
  showLabelCompare: false,
  labelCompareList: {},
  formulationGraph: [],
  routesGraph: [],
  determinationGraph: [],
  errors: [],
  filters: [],
  availableFilters: {},
  isFiltersApplied: false,
  loading: true,
  totalRecord: 0,
  sortFields: [],
  askRiaFilters: [],
  dataGridFilters: {}
};

const PediatricsStore = createContext<{
  pediatricsFDAState: pediatricsFDAContextInterface;
  pediatricsFDADispatch: React.Dispatch<any>;
}>({ pediatricsFDAState: initialState, pediatricsFDADispatch: () => null });
const { Provider } = PediatricsStore;

export const PediatricsStateProvider = ({ children }: Props) => {
  const [pediatricsFDAState, pediatricsFDADispatch] = useReducer(reducers, initialState);
  return <Provider value={{ pediatricsFDAState, pediatricsFDADispatch }}>{children}</Provider>;
};

export default PediatricsStore;
