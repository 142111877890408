import makeRequest from '../client';

export const getTrialsData = ({ source, data }) =>
  makeRequest(`/cdp/get-trials-data/${source}`, 'POST', data);

export const getOverlays = ({ source, applicationNumber, data }) =>
  makeRequest(`/cdp/get-overlay-data/${applicationNumber}/${source}`, 'POST', data);

export const getClinicalTrialsDocuments = (id, source) =>
  makeRequest(`/cdp/docs/${source}`, 'POST', { id });

export const getDocumentsApi = (applicationNumber, submission, source) =>
  makeRequest(`/cdp/docs/${source}`, 'POST', {
    applno: applicationNumber,
    submission_id: submission
  });

export const getRegInfoSignedURL = (s3Path) =>
  makeRequest(`/cdp/regInfo?s3Path=${s3Path}`, 'GET');

export const sendFeedback = ({ applicationNumber, tradeName, message, type, email }) =>
  makeRequest(`/cdp/feedback`, 'POST', {
    applno: applicationNumber,
    tradeName,
    message,
    type,
    email
  });
