import { createSvgIcon } from '@mui/material';

const ArrowDownVariantIcon = createSvgIcon(
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.99994 9.50425C6.84675 9.50452 6.69502 9.47451 6.55347 9.41594C6.41192 9.35738 6.28333 9.27142 6.17511 9.163L3.08752 6.07483L3.91236 5.25L6.99994 8.33758L10.0875 5.25L10.9124 6.07483L7.82477 9.16242C7.7166 9.27094 7.58804 9.35701 7.44648 9.41567C7.30493 9.47434 7.15317 9.50444 6.99994 9.50425Z'
      fill='currentColor'
    />
  </svg>,
  'ArrowDownVariantIcon'
);

export default ArrowDownVariantIcon;
