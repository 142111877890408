import { createSvgIcon } from '@mui/material';

const LowScoreConfidence = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
    <g clipPath='url(#clip0_830_2142)'>
      <path
        d='M7.58333 3.5H6.41667C6.10725 3.5 5.8105 3.62292 5.59171 3.84171C5.37292 4.0605 5.25 4.35725 5.25 4.66667V14H8.75V4.66667C8.75 4.35725 8.62708 4.0605 8.40829 3.84171C8.1895 3.62292 7.89275 3.5 7.58333 3.5ZM7.58333 12.8333H6.41667V4.66667H7.58333V12.8333Z'
        fill='#EF9A91'
      />
      <path
        d='M12.8333 0H11.6667C11.3572 0 11.0605 0.122916 10.8417 0.341709C10.6229 0.560501 10.5 0.857247 10.5 1.16667V14H14V1.16667C14 0.857247 13.8771 0.560501 13.6583 0.341709C13.4395 0.122916 13.1428 0 12.8333 0V0ZM12.8333 12.8333H11.6667V1.16667H12.8333V12.8333Z'
        fill='#EF9A91'
      />
      <path
        d='M2.33333 7H1.16667C0.857247 7 0.560501 7.12292 0.341709 7.34171C0.122916 7.5605 0 7.85725 0 8.16667L0 14H3.5V8.16667C3.5 7.85725 3.37708 7.5605 3.15829 7.34171C2.9395 7.12292 2.64275 7 2.33333 7Z'
        fill='#EF9A91'
      />
    </g>
    <defs>
      <clipPath id='clip0_830_2142'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'LowScoreConfidence'
);

export default LowScoreConfidence;
