export default function isDateInRange(date: string, startDate: string, endDate: string) {
  // Convert dates to timestamps
  const dateTimestamp = new Date(date).getTime();
  let startTimestamp = new Date(startDate).getTime();
  let endTimestamp = new Date(endDate).getTime();

  // Avoid start date and end date swapping
  const timestamps: any = [startTimestamp, endTimestamp];
  startTimestamp = Math.min(...timestamps) - 1;
  endTimestamp = Math.max(...timestamps) + 1;

  // Check if the date is in the range
  return dateTimestamp <= endTimestamp && dateTimestamp >= startTimestamp;
}
