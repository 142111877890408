const downloadLabelFile = (blob: Blob, fileType: string, fileNameWithExtension: string) => {
  try {
    let type;
    switch (fileType) {
      case 'html':
        type = 'text/html';
        break;
      case 'docx':
        type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        break;
      case 'pdf':
        type = 'application/pdf';
        break;
      default:
        type = 'text/html';
        break;
    }
    const url = window.URL.createObjectURL(
      new Blob([blob], {
        type
      })
    );

    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = fileNameWithExtension;

    // Append the download link to the DOM
    document.body.appendChild(downloadLink);

    // Optionally, simulate a click on the link to trigger the download
    downloadLink.click();

    setTimeout(() => {
      document.body.removeChild(downloadLink);
    }, 1000);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export default downloadLabelFile;
