/* eslint-disable no-underscore-dangle */
const getAriaFilters_ = (resultsState: any, sortFilterCategoriesOptions: any) => {
  let formattedAllowedFilters: any = [];
  if (
    resultsState.documentResults.global_filters &&
    resultsState.documentResults.global_filters.length > 0
  ) {
    formattedAllowedFilters = resultsState.documentResults.global_filters.filter(
      (each: any) => each.show
    );

    formattedAllowedFilters.forEach((each: any, index: any) => {
      // calculated the filter category option from applied filters when there is no data available
      if (
        resultsState?.documentResults?.results?.length === 0 &&
        each?.options?.length === 0 &&
        each?.filter_type === 'selectOptions' &&
        resultsState?.filters[each?.value]
      ) {
        const filterValues = resultsState?.filters[each?.value];
        formattedAllowedFilters[index].options = filterValues;
      }

      formattedAllowedFilters[index].options = formattedAllowedFilters[index]?.options.sort(
        (a: any, b: any) => sortFilterCategoriesOptions(a.label, b.label)
      );
      formattedAllowedFilters[index].showInBottomFilter = true;
      if (!each.filterType)
        delete Object.assign(each, { filterType: each.filter_type }).filter_type;
    });
  }
  return formattedAllowedFilters;
};

export default getAriaFilters_;
