import titleCase from '../../../utils/titleCase';
import { CardData } from '../types';
import getApprovalTracks from './getApprovalTracks';

interface HPRAAPIApps {
  generic_name: Array<string>;
  advertising_status: string;
  approval_year: string;
  atc_classification: Array<any>;
  atc_code: Array<string>;
  document_count: number;
  document_total_page_count: number;
  formulation: string;
  inProjects: Array<any>;
  indications_and_usage: string;
  is_favorite: boolean;
  is_subscribed: boolean;
  legal_status: string;
  licence_status: string;
  marketing_flag: string;
  marketing_text: string;
  marketing_status: string;
  matched_synonyms: Array<string>;
  product_number: string;
  products: Array<any>;
  route: Array<string>;
  s3_bucket: string;
  s3_label_start_page: number;
  s3_label_url: string;
  source_index: string;
  sponsor_name: string;
  supply_status: string;
  product_name: string;
}

export const HPRA_IDENTIFIER_KEY = 'product_number';

const mapHPRAApps = (apiData: HPRAAPIApps) => {
  const approvalTracks = getApprovalTracks(apiData, 'hpra');
  const cardProps: CardData = {
    source: 'hpra',
    title: titleCase(
      Array.isArray(apiData.product_name) ? apiData.product_name.join(' ') : apiData.product_name
    ),
    groupTitle: titleCase(
      Array.isArray(apiData.generic_name) ? apiData.generic_name.join(' ') : apiData.generic_name
    ),
    // using application_number as fallback for product_number (used for reg360 header popup card)
    identifier: apiData.product_number ?? (apiData as any)?.application_number,
    showDiscontinued: apiData.marketing_status?.toLowerCase() === 'red',
    isFavorite: apiData.is_favorite,
    isSubscribed: apiData.is_subscribed,
    metadata: [
      {
        key: 'Sponsor Name',
        value: titleCase(
          (Array.isArray(apiData.sponsor_name)
            ? apiData.sponsor_name.join(';')
            : apiData.sponsor_name) || '---'
        )
      },
      {
        key: 'Formulation',
        value: titleCase(
          (Array.isArray(apiData.formulation)
            ? apiData.formulation.join(';')
            : apiData.formulation) || '---'
        )
      },
      {
        key: 'Route',
        value: titleCase(
          (Array.isArray(apiData.route) ? apiData.route.join(' ') : apiData.route) || '---'
        )
      },
      {
        key: 'Licence Status',
        value: titleCase(apiData.licence_status || '---')
      },
      { key: 'First Approval Year', value: apiData.approval_year }
    ],
    labels: [(apiData.source_index === 'ema' ? 'ema' : 'hpra').toUpperCase()],
    colorScheme: apiData?.source_index,
    approvalTracks,
    ...apiData
  };

  return cardProps;
};

export default mapHPRAApps;
