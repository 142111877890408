import React from 'react';

// material ui
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

// styles
import styles from '../styles/Insights.styles';

const GraphSkeleton = () => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-end'
      alignItems='space-between'
      sx={styles.loaderBox}>
      <Box
        display='flex'
        justifyContent='space-between'
        flexWrap='wrap-reverse'
        sx={styles.barGraphRect}>
        <Skeleton variant='rectangular' sx={{ width: '1px', height: '100%' }} />
        <Skeleton variant='rectangular' sx={{ width: '15px', height: '48%' }} />
        <Skeleton variant='rectangular' sx={{ width: '15px', height: '40%' }} />
        <Skeleton variant='rectangular' sx={{ width: '15px', height: '80%' }} />
        <Skeleton variant='rectangular' sx={{ width: '15px', height: '20%' }} />
        <Skeleton variant='rectangular' sx={{ width: '15px', height: '40%' }} />
        <Skeleton variant='rectangular' sx={{ width: '15px', height: '60%' }} />
        <Skeleton variant='rectangular' sx={{ width: '15px', height: '40%' }} />
        <Skeleton variant='rectangular' sx={{ width: '15px', height: '90%' }} />
        <Skeleton variant='rectangular' sx={{ width: '15px', height: '50%' }} />
        <Skeleton variant='rectangular' sx={{ width: '15px', height: '40%' }} />
        <Skeleton variant='rectangular' sx={{ width: '15px', height: '30%' }} />
        <Skeleton variant='rectangular' sx={{ width: '15px', height: '80%' }} />
        <Skeleton variant='rectangular' sx={{ width: '15px', height: '40%' }} />
      </Box>
      <Skeleton variant='rectangular' sx={{ width: '100%', height: '1px' }} />
    </Box>
  );
};

export default GraphSkeleton;
