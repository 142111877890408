/* eslint-disable import/prefer-default-export */
import makeRequest from '../client';

/**
 * function to fetch landing page stats for chmp
 */
export const fetchChmpStats = () => makeRequest(`/chmp/stats`, 'GET');

/**
 * function to fetch meetings list
 */
export const fetchChmpMeetings = () => makeRequest(`/chmp/meetings`, 'GET');

/**
 * function to fetch meetings list with filters applied
 */
export const fetchChmpMeetingsWithFilters = (filters: any) =>
  makeRequest(`/chmp/meetings`, 'POST', { filters });
