import { createSvgIcon } from '@mui/material';

const KeyDownVariantIcon = createSvgIcon(
  <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.0854 5.79126L7.33929 10.5374C7.24795 10.625 7.12624 10.674 6.99963 10.674C6.87302 10.674 6.75132 10.625 6.65997 10.5374L1.91675 5.79366L1.23743 6.47298L5.98065 11.2167C6.25539 11.4785 6.62035 11.6246 6.99987 11.6246C7.3794 11.6246 7.74436 11.4785 8.01909 11.2167L12.7647 6.47058L12.0854 5.79126Z'
      fill='currentColor'
    />
  </svg>,
  'KeyDownVariant'
);

export default KeyDownVariantIcon;
