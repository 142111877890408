import themev5 from '../../../themev5';

const styles = {
  table: {
    overflowY: 'auto',
    maxHeight: '40vh'
  },
  name: {
    color: 'primary.main',
    cursor: 'pointer',
    fontSize: '14px',
    fontFamily: 'Mulish',
    wordBreak: 'break-word'
  },
  role: {
    textTransform: 'capitalize',
    fontSize: '14px',
    fontFamily: 'Mulish',
    textAlign: 'center'
  },
  heading: {
    padding: '0px 12px',
    textAlign: 'left'
  },
  headingText: {
    fontWeight: 'bold',
    fontSize: '14px',
    fontFamily: 'Mulish',
    textAlign: 'center'
  },
  tablebody: {
    cursor: 'pointer',
    padding: '4px 12px',
    textAlign: 'left'
  },
  selectedTableBody: {
    padding: '4px 12px',
    textAlign: 'left',
    border: `1px solid #33B187`,
    cursor: 'pointer'
  },
  text: {
    fontSize: '14px',
    textAlign: 'center'
  },
  tbody: {
    overflowY: 'auto',
    padding: '10px 0px'
  },
  icon: {
    top: '81px',
    left: '1304px',
    width: '90%',
    background: "transparent url('img/Oval.png') 0% 0 % no - repeat padding- box",
    opacity: '1',
    [themev5.breakpoints.down('lg')]: {
      height: 15,
      width: 15
    }
  },
  memberText: {
    fontSize: '14px',
    textAlign: 'left'
  },
  rosterHeading: {
    fontSize: '14px',
    fontFamily: 'Mulish',
    textAlign: 'left',
    fontWeight: 'bold'
  },
  button: {
    height: '35px',
    cursor: 'pointer',
    background: 'none',
    boxShadow: 'none',
    border: 1,
    borderColor: 'primary.main',
    borderStyle: 'solid',
    marginLeft: '20px',
    marginTop: '20px',
    '&:hover': {
      background: 'none',
      border: 1,
      borderColor: 'primary.main',
      borderStyle: 'solid',
      backgroundColor: 'primary.main',
      color: 'white.main',
      fontWeight: 'bold',
      textTransform: 'capitalize'
    }
  },
  buttonText: {
    color: 'primary',
    fontWeight: 'bold',
    textTransform: 'capitalize'
  },
  graphHeading: {
    fontSize: '14px',
    color: 'gray.lightVariant',
    fontWeight: 'bold',
    marginLeft: 4,
    padding: 1
  },
  subHeading: {
    fontSize: '14px',
    color: 'gray.lightVariant',
    fontWeight: 'bold',
    padding: 1
  }
};

export default styles;
