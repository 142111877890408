import { SOURCE_DOCUMENT_TYPE } from '../constants';
import formatCount from '../../../../utils/formatCount';

const getPlaceholderText = (
  document_count: number = 0,
  document_total_page_count: number = 0,
  source: string = 'us'
) => {
  const documentType = (SOURCE_DOCUMENT_TYPE as any)[source];
  if (document_count > 0 && document_total_page_count > 0) {
    return `Search here from ${formatCount(
      document_count
    )} ${documentType} documents and ${formatCount(document_total_page_count)} pages`;
  }
  if (source?.toLowerCase() === 'hpra' && document_count === 0 && document_total_page_count === 0) {
    return 'No Documents Found in HPRA Products, but can search in EMA Educational Documents';
  }
  return '';
};

export default getPlaceholderText;
