import React, { useCallback, useState, useEffect, useContext } from 'react';

// mui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';

// component
import PDFPreview from '../../../../components/PdfPreview/PDFPreview';

// store
import GlobalStore from '../../../../store';
import GlobalActions from '../../../../store/actions';

// styles
import styles from '../../styles/Resources.styles';
import { CENTER_MAPPING } from '../../constant';

const Resources: React.FC<any> = ({
  resourceData,
  openDialog,
  handleClosePopup,
  selectedMeeting,
  drugsOrDevices
}) => {
  const [PDFData, setPDFData] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [openPdf, setOpenPdf] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<any>({});
  const [documentCategory, setDocumentCategory] = useState<string>('');
  const [documentTitle, setDocumentTitle] = useState<string>('');
  const { dispatch } = useContext(GlobalStore) as any;

  const handlePDFOpenClick = useCallback((pdfLink: string) => {
    setOpenPdf(true);
    setPDFData(pdfLink);
  }, []);

  const handleClosePDF = useCallback(() => {
    setOpenPdf(false);
    setPDFData('');
  }, []);

  const handleShowMore = useCallback(
    (e: React.SyntheticEvent, key: string, count: number) => {
      const keyList = { ...showAll };
      if (keyList[key] === count) {
        keyList[key] = 3;
      } else {
        keyList[key] = count;
      }
      setShowAll(keyList);
    },
    [showAll]
  );

  useEffect(() => {
    if (resourceData?.resources) {
      const fileCategory: any = {};
      Object.keys(resourceData?.resources).forEach((row: any) => {
        fileCategory[row] = 3;
      });
      setShowAll(fileCategory);
    }
  }, [resourceData?.resources]);

  // Sorting data such that Other appear at last
  const sortResources = (resources: { [key: string]: string | any }) => {
    const data = Object.entries(resources);
    data.sort((a: any, b: any) => {
      if (a[0] === 'Other') return 1;
      if (b[0] === 'Other') return -1;
      return a[0].localeCompare(b[0]);
    });
    return Object.fromEntries(data);
  };

  const handleChatRia = ({ pdfUrl = '' }: { pdfUrl?: string }) => {
    const mappedSourceName = CENTER_MAPPING?.[selectedMeeting?.center.trim()] ?? 'adcomm';
    const content: any = {
      adcom_meeting_start_date: selectedMeeting?.month_year ?? '',
      meeting_id: selectedMeeting?.meeting_id ?? '',
      category: documentCategory ?? ''
    };
    if (selectedMeeting?.center === 'CDER') {
      content.active_ingredient =
        drugsOrDevices && drugsOrDevices.length !== 0 ? drugsOrDevices[0] : 'UNKNOWN';
    }
    if (selectedMeeting?.center === 'CDRH') {
      content.device_name =
        drugsOrDevices && drugsOrDevices.length !== 0 ? drugsOrDevices[0] : 'UNKNOWN';
    }
    if (selectedMeeting?.center.trim() === 'CBER') {
      content['document-title'] = documentTitle;
    }
    dispatch({
      type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM,
      value: 'document'
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: mappedSourceName
    });
    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: {
        blob: pdfUrl,
        url: pdfUrl,
        item: content,
        source: mappedSourceName,
        triggerReopenChatRia: false
      }
    });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
    setPDFData('');
    setOpenPdf(false);
    handleClosePopup();
  };

  return (
    <Box>
      <Dialog
        fullWidth
        maxWidth='lg'
        aria-labelledby='customized-dialog-title'
        open={openDialog}
        sx={styles.dialog}
        onClose={handleClosePopup}>
        <DialogTitle>
          <Grid display='flex' justifyContent='space-between' alignItems='flex-start'>
            <Grid sx={styles.heading} display='flex' justifyContent='center'>
              Resources
            </Grid>
            <Box>
              <IconButton aria-label='delete' onClick={handleClosePopup} sx={{ fontSize: '30px' }}>
                <CancelIcon fontSize='inherit' />
              </IconButton>
            </Box>
          </Grid>
          <Divider />
        </DialogTitle>
        <DialogContent sx={{ height: '94%' }}>
          <Stack p={2} spacing={2} display='flex' flexDirection='row' sx={{ height: '94%' }}>
            <Box
              sx={{
                borderRadius: '20px',
                overflow: 'auto',
                width: '70%'
              }}>
              {resourceData?.resources &&
                Object.entries(sortResources(resourceData?.resources)).map(([key, value]: any) => {
                  return (
                    <>
                      <Stack spacing={1} display='flex' flexDirection='row' sx={{ width: '100%' }}>
                        <Box sx={{ width: '15%' }}>
                          <Box
                            sx={{
                              padding: '13px 10px',
                              marginBottom: 'auto'
                            }}>
                            <Typography
                              sx={{
                                fontSize: '16px',
                                color: 'black.darkVariant',
                                fontWeight: '600'
                              }}>
                              {`${key}`}
                            </Typography>
                          </Box>
                        </Box>
                        <Stack
                          display='flex'
                          spacing={1}
                          p={1}
                          flexDirection='column'
                          sx={{ width: '100%' }}>
                          {
                            // eslint-disable-next-line array-callback-return, consistent-return
                            value.map((data: any, index: number) => {
                              if (index < showAll[key]) {
                                return (
                                  <Stack spacing={1} key={data.key}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      sx={{
                                        marginTop: '5px',
                                        padding: '12px 10px',
                                        borderRadius: 8,
                                        backgroundColor: 'primary.background'
                                      }}>
                                      <Box>
                                        <Box
                                          sx={{
                                            padding: '0.2rem',
                                            width: '40px',
                                            height: '40px'
                                          }}>
                                          <img
                                            src='https://vivpro-site.s3.amazonaws.com/Help_Page_FAQ/pdf_icon.png'
                                            alt='PDF download'
                                            height='100%'
                                            width='100%'
                                          />
                                        </Box>
                                      </Box>

                                      <Stack
                                        direction='row'
                                        alignItems='center'
                                        onClick={() => {
                                          setDocumentTitle(data.name);
                                          setDocumentCategory(key);
                                          handlePDFOpenClick(data.link);
                                          setTitle(data.name);
                                        }}>
                                        <Tooltip title={data?.name ? data.name : ''}>
                                          <Typography
                                            sx={styles.title}>{`${data.name}`}</Typography>
                                        </Tooltip>
                                      </Stack>
                                    </Box>
                                  </Stack>
                                );
                              }
                            })
                          }
                          {value.length > 3 && (
                            <Stack direction='row' spacing={2} justifyContent='center'>
                              <Button
                                variant='outlined'
                                sx={styles.button}
                                onClick={(e: React.SyntheticEvent) =>
                                  handleShowMore(e, key, value.length)
                                }
                                endIcon={
                                  showAll[key] === 3 ? (
                                    <ArrowDownwardOutlinedIcon />
                                  ) : (
                                    <ArrowUpwardOutlinedIcon />
                                  )
                                }>
                                {showAll[key] === 3 ? 'Show More' : 'Show Less'}
                              </Button>
                            </Stack>
                          )}
                        </Stack>
                      </Stack>
                      <Divider />
                    </>
                  );
                })}
            </Box>
            <Box
              sx={{
                width: '30%',
                position: 'sticky'
              }}>
              <Paper sx={styles.summary}>
                <Typography sx={styles.summaryTitle}>Summary</Typography>
                {resourceData?.resources &&
                  Object.entries(sortResources(resourceData?.resources)).map(
                    ([key, value]: any) => {
                      return (
                        <Grid container key={key}>
                          <Grid xs={8}>
                            <Typography sx={styles.subHeading}>{key}</Typography>
                          </Grid>

                          <Grid xs={4}>
                            <Typography sx={styles.subHeading}>{value.length}</Typography>
                          </Grid>
                        </Grid>
                      );
                    }
                  )}
              </Paper>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth='lg'
        onClose={() => {
          handleClosePDF();
        }}
        aria-labelledby='customized-dialog-title'
        open={openPdf}>
        <DialogTitle>
          <Grid container>
            <Grid item xs={11} sx={{ justify: 'center', alignItems: 'center' }} />

            <Grid item xs={1} sx={{ justify: 'flex-end', alignItems: 'center' }}>
              <IconButton
                aria-label='delete'
                onClick={() => {
                  handleClosePDF();
                }}>
                <CancelIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {PDFData ? (
            <Box sx={styles.dialogContent}>
              <PDFPreview
                open={Boolean(PDFData)}
                pdfUrl={PDFData}
                onClose={() => handleClosePDF()}
                handleChatRia={handleChatRia}
                title={title}
              />
            </Box>
          ) : null}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default React.memo(Resources);
