import { createSvgIcon } from '@mui/material';

const ReportIcon = createSvgIcon(
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_7263_112473)'>
      <path
        d='M2 14.6507C1.82319 14.6507 1.65362 14.5804 1.5286 14.4554C1.40357 14.3304 1.33333 14.1608 1.33333 13.984V0H0V13.984C0 14.5144 0.210714 15.0231 0.585786 15.3982C0.960859 15.7733 1.46957 15.984 2 15.984H16V14.6507H2Z'
        fill='currentColor'
      />
      <path d='M4.66634 8H3.33301V12.6667H4.66634V8Z' fill='currentColor' />
      <path d='M7.99936 6.66656H6.66602V12.6666H7.99936V6.66656Z' fill='currentColor' />
      <path d='M11.3333 8.66656H10V12.6666H11.3333V8.66656Z' fill='currentColor' />
      <path d='M14.6673 6H13.334V12.6667H14.6673V6Z' fill='currentColor' />
      <mask id='path-6-inside-1_7263_112473' fill='white'>
        <path d='M7.33396 2.94277L10.6673 6.2761L15.8053 1.1381L14.8626 0.195435L10.6673 4.39077L7.33396 1.05743L3.5293 4.8621L4.47196 5.80477L7.33396 2.94277Z' />
      </mask>
      <path
        d='M7.33396 2.94277L10.6673 6.2761L15.8053 1.1381L14.8626 0.195435L10.6673 4.39077L7.33396 1.05743L3.5293 4.8621L4.47196 5.80477L7.33396 2.94277Z'
        fill='currentColor'
      />
      <path
        d='M7.33396 2.94277L18.6477 -8.37094L7.33396 -19.6846L-3.97975 -8.37094L7.33396 2.94277ZM10.6673 6.2761L-0.646412 17.5898L10.6673 28.9035L21.981 17.5898L10.6673 6.2761ZM15.8053 1.1381L27.119 12.4518L38.4327 1.1381L27.119 -10.1756L15.8053 1.1381ZM14.8626 0.195435L26.1763 -11.1183L14.8626 -22.432L3.54892 -11.1183L14.8626 0.195435ZM10.6673 4.39077L-0.646412 15.7045L10.6673 27.0182L21.981 15.7045L10.6673 4.39077ZM7.33396 1.05743L18.6477 -10.2563L7.33396 -21.57L-3.97975 -10.2563L7.33396 1.05743ZM3.5293 4.8621L-7.78441 -6.45161L-19.0981 4.8621L-7.78441 16.1758L3.5293 4.8621ZM4.47196 5.80477L-6.84175 17.1185L4.47196 28.4322L15.7857 17.1185L4.47196 5.80477ZM-3.97975 14.2565L-0.646412 17.5898L21.981 -5.03761L18.6477 -8.37094L-3.97975 14.2565ZM21.981 17.5898L27.119 12.4518L4.49159 -10.1756L-0.646411 -5.03761L21.981 17.5898ZM27.119 -10.1756L26.1763 -11.1183L3.54893 11.5091L4.49159 12.4518L27.119 -10.1756ZM3.54892 -11.1183L-0.646412 -6.92294L21.981 15.7045L26.1763 11.5091L3.54892 -11.1183ZM21.981 -6.92294L18.6477 -10.2563L-3.97975 12.3711L-0.646412 15.7045L21.981 -6.92294ZM-3.97975 -10.2563L-7.78441 -6.45161L14.843 16.1758L18.6477 12.3711L-3.97975 -10.2563ZM-7.78441 16.1758L-6.84175 17.1185L15.7857 -5.50894L14.843 -6.45161L-7.78441 16.1758ZM15.7857 17.1185L18.6477 14.2565L-3.97975 -8.37094L-6.84175 -5.50894L15.7857 17.1185Z'
        fill='currentColor'
        mask='url(#path-6-inside-1_7263_112473)'
      />
    </g>
    <defs>
      <clipPath id='clip0_7263_112473'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'Report'
);

export default ReportIcon;
