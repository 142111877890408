import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  paper: {
    borderRadius: theme.spacing(3),
    padding: theme.spacing(3)
  },
  Heading: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2)
  },
  adminsum: {
    height: '100%',
    background: theme.palette.white,
    borderRadius: theme.spacing(1),
    padding: '20px 40px'
  },
  submission: {
    background: theme.palette.white,
    borderRadius: theme.spacing(1),
    height: '100%'
  },
  clinical: {
    height: '100%',
    background: theme.palette.white,
    borderRadius: theme.spacing(1)
  },
  subheading: {
    background: '#f7f7f7',
    margin: '4px 8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  graph: {
    background: 'white'
  },
  icons: {
    display: 'flex'
  },
  applicationRoot: {
    marginBottom: theme.spacing(4)
  },
  subdata: {
    color: theme.palette.regulatory.blue,
    fontWeight: 700,
    fontSize: '1.7vh'
  },
  adminDate: {
    color: theme.palette.regulatory.lightGreen,
    background: theme.palette.regulatory.lighterGreen,
    borderRadius: theme.spacing(0.5),
    margin: '0px 20px'
  },
  capture: {
    minHeight: '130px',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
    margin: '13px 0px'
  }
}));
