import { Box, Dialog, DialogContent, IconButton, Typography, Divider } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { useHistory } from 'react-router';

import { CrossIcon, WhatsNewHomeTabIcon } from '../../assets/svgs/Icons';
import whatsNewStyles from './WhatsNew.styles';

interface WhatsNewStatsModalProps {
  showStatsModal: boolean;
  handleCloseStatsModal: () => void;
  latestWhatsNewStats: any;
}

const WhatsNewStatsModal = ({
  showStatsModal,
  handleCloseStatsModal,
  latestWhatsNewStats
}: WhatsNewStatsModalProps) => {
  const history = useHistory();

  return (
    <Dialog
      open={showStatsModal}
      sx={whatsNewStyles.modalContainer}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <DialogContent>
        <Box>
          <Box sx={whatsNewStyles.statsContainer}>
            {latestWhatsNewStats?.majorUpdates > 0 && (
              <Box width='200px'>
                <Typography sx={whatsNewStyles.statsNumber}>
                  {latestWhatsNewStats?.majorUpdates}
                </Typography>
                <Typography sx={whatsNewStyles.statsText}>
                  New Major
                  <br />
                  Update(s)
                </Typography>
              </Box>
            )}
            {latestWhatsNewStats?.majorUpdates > 0 &&
              latestWhatsNewStats?.enhancementUpdates > 0 && (
                <Divider orientation='vertical' flexItem sx={{ borderColor: 'black !important' }} />
              )}
            {latestWhatsNewStats?.enhancementUpdates > 0 && (
              <Box width='228px'>
                <Typography sx={whatsNewStyles.statsNumber}>
                  {latestWhatsNewStats?.enhancementUpdates}
                </Typography>
                <Typography sx={whatsNewStyles.statsText}>New Enhancement Update(s)</Typography>
              </Box>
            )}
          </Box>

          <IconButton onClick={handleCloseStatsModal} sx={whatsNewStyles.secondaryCrossIconButton}>
            <CrossIcon />
          </IconButton>
        </Box>

        <Box sx={whatsNewStyles.slideImg1}>
          <WhatsNewHomeTabIcon />
        </Box>
        <Box sx={whatsNewStyles.statsCtaWrapper}>
          <Typography sx={whatsNewStyles.statsCtaText}>Check it out!</Typography>
          <IconButton
            sx={whatsNewStyles.navButton3}
            onClick={() => {
              handleCloseStatsModal();
              history.push('/core#whats-new');
            }}>
            <ArrowForward />
          </IconButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default WhatsNewStatsModal;
