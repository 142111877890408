import { useCallback, useContext } from 'react';
import { getTrialsData } from '../../../../api/pages/cdp';
import { prepareGraphData } from '../../../../pages/CDP/utils';
import { getAnnotations, getShapes } from '../utils';
import ResultsStore from '../../../../store/SearchResults';
import ResultsAction from '../../../../store/SearchResults/actions';

const useTimelineData = () => {
  const { resultsDispatch } = useContext(ResultsStore) as any;

  const getTimelineData = useCallback(
    async (
      cnf: string,
      applicationNumber: string,
      dateRange?: any,
      defaultFilters?: boolean,
      useSynonyms?: boolean,
      source: string = 'ct'
    ) => {
      resultsDispatch({ type: ResultsAction.SET_TRIALS_LOADING, value: true });
      try {
        // TODO: Payload changes needed for EU
        // If the trials data not present throw the error
        const trialsDataRequest = getTrialsData({
          source,
          data: {
            query: cnf,
            date_filters: dateRange || {},
            appl_num: applicationNumber,
            default_filters: !!defaultFilters,
            trials_source: source,
            use_synonyms: !!useSynonyms
          }
        });

        let data: any = {};
        try {
          const trialsData = await trialsDataRequest;
          if (trialsData?.data?.Success) {
            // Get renderable graph data
            const graphData = prepareGraphData(trialsData?.data?.Success, data?.nctIdsInReview);

            const annotations = getAnnotations();
            const shapes = getShapes();
            data = {
              ...data,
              ...graphData,
              annotations,
              shapes,
              trials_data: trialsData.data.Success.trials_data,
              trialsFilters: {},
              barGraphData: [...graphData.barGraphData, ...(data.barGraphData || [])],
              nctIds: [...(data.nctIds || []), ...graphData.ctIds]
            };
            resultsDispatch({ type: ResultsAction.SET_TRIALS_DATA, value: data });
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
          resultsDispatch({
            type: ResultsAction.SET_TRIALS_ERROR,
            value: 'Could not get the graph data'
          });
        } finally {
          resultsDispatch({ type: ResultsAction.SET_TRIALS_LOADING, value: false });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        resultsDispatch({
          type: ResultsAction.SET_TRIALS_ERROR,
          value: 'Could not get the graph data'
        });
        resultsDispatch({ type: ResultsAction.SET_TRIALS_LOADING, value: false });
      }
    },
    []
  );

  return {
    getTimelineData
  };
};

export default useTimelineData;
