import { memo, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';

import searchBarStyles from '../../Home/styles/SearchBar.styles';
import styles from '../styles/DesignationSearchBox.styles';
import { filterOptions } from '../../../components/Header/utils/searchUtils';
import useSearchSuggestions from '../hooks/useSearchSuggestions';
import SearchBarAutoComplete from '../../Home/components/SearchBarAutoComplete';

import { KeyDownIcon, SearchIcon } from '../../../assets/svgs/Icons';

const DesignationSearchBox = ({ modalOpen, setModalOpen }: any) => {
  const history = useHistory();

  // Search Autocomplete.
  const {
    searchSuggestions,
    isSuggestionsLoading,
    setIsSearchOpen,
    handleKeyPress,
    clearSearchSuggestions,
    isSearchOpen
  } = useSearchSuggestions();

  // Search Input
  const [searchText, setSearchText] = useState('');

  const handleSearchSubmit = (value: string = searchText) => {
    history.push(`/designation/${value}`);
    window.location.reload();
  };

  const handleAutoCompleteOpen = useCallback(() => setIsSearchOpen(true), [setIsSearchOpen]);

  const handleAutoCompleteClose = useCallback(() => setIsSearchOpen(false), [setIsSearchOpen]);

  const isSearchButtonDisabled = searchText.trim().length <= 1;

  return (
    <Drawer
      sx={styles.drawerContainer}
      anchor='bottom'
      onClose={() => setModalOpen('')}
      open={!!modalOpen}>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Box sx={styles.drawerHeader}>
          <Typography sx={styles.drawerTitle}>Expedited Pathway</Typography>
          <Tooltip title='Close'>
            <IconButton
              onClick={() => setModalOpen('')}
              sx={{ position: 'absolute', right: '24px', top: '26px' }}>
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='center'>
          <Divider sx={styles.drawerDivider} />
        </Box>
        <Box display='flex' flexDirection='column' alignItems='start' marginTop='64px'>
          <Box sx={styles.drawerCloseIcon}>
            <Box
              sx={{
                ...searchBarStyles.searchBar,
                ...styles.searchBar
              }}>
              <Stack direction='row' alignItems='center'>
                <Box>
                  <Select
                    sx={styles.select}
                    IconComponent={KeyDownIcon}
                    value='US FDA'
                    id='category-select'
                    placeholder='Select category'
                    disabled>
                    <MenuItem key='US FDA' value='US FDA'>
                      US FDA
                    </MenuItem>
                  </Select>
                </Box>
                <Divider orientation='vertical' flexItem sx={styles.divider} />
                <Box>
                  <Select
                    sx={styles.select}
                    IconComponent={KeyDownIcon}
                    value='Indications'
                    id='category-select'
                    placeholder='Select category'
                    disabled>
                    <MenuItem key='Indications' value='Indications'>
                      Indications
                    </MenuItem>
                  </Select>
                </Box>
                <Divider orientation='vertical' flexItem sx={styles.divider} />
                <Box flex={1}>
                  <SearchBarAutoComplete
                    id='designation-search-input'
                    value={searchText}
                    label='Search indications'
                    options={filterOptions(searchSuggestions)}
                    loading={isSuggestionsLoading}
                    onOpen={handleAutoCompleteOpen}
                    open={isSearchOpen}
                    onClose={handleAutoCompleteClose}
                    filterOptions={filterOptions}
                    onInputChange={handleKeyPress}
                    setSearch={value => setSearchText(value)}
                    clearSearchSuggestions={clearSearchSuggestions}
                    handleSearch={handleSearchSubmit}
                  />
                </Box>
                <Button
                  size='small'
                  startIcon={<SearchIcon style={{ fontSize: 12 }} />}
                  sx={searchBarStyles.searchButton}
                  disabled={isSearchButtonDisabled}
                  onClick={() => handleSearchSubmit()}>
                  Search
                </Button>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default memo(DesignationSearchBox);
