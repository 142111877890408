import { createSvgIcon } from '@mui/material';

const InfoIconSummary = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
    <path
      d='M7 14C5.61553 14 4.26216 13.5895 3.11101 12.8203C1.95987 12.0511 1.06266 10.9579 0.532846 9.67879C0.003033 8.3997 -0.13559 6.99224 0.134506 5.63437C0.404603 4.2765 1.07129 3.02922 2.05026 2.05026C3.02922 1.07129 4.2765 0.404603 5.63437 0.134506C6.99224 -0.13559 8.3997 0.003033 9.67879 0.532846C10.9579 1.06266 12.0511 1.95987 12.8203 3.11101C13.5895 4.26216 14 5.61553 14 7C13.998 8.8559 13.2599 10.6352 11.9475 11.9475C10.6352 13.2599 8.8559 13.998 7 14ZM7 1.16667C5.84628 1.16667 4.71846 1.50879 3.75918 2.14976C2.79989 2.79074 2.05222 3.70178 1.61071 4.76768C1.16919 5.83359 1.05368 7.00647 1.27876 8.13803C1.50384 9.26959 2.05941 10.309 2.87521 11.1248C3.69102 11.9406 4.73042 12.4962 5.86198 12.7213C6.99353 12.9463 8.16642 12.8308 9.23232 12.3893C10.2982 11.9478 11.2093 11.2001 11.8502 10.2408C12.4912 9.28154 12.8333 8.15373 12.8333 7C12.8316 5.45343 12.2165 3.97068 11.1229 2.87709C10.0293 1.7835 8.54658 1.16837 7 1.16667Z'
      fill='currentColor'
    />
    <path
      d='M8.16658 11.0835H6.99992V7.00018H5.83325V5.83351H6.99992C7.30934 5.83351 7.60608 5.95643 7.82487 6.17522C8.04366 6.39401 8.16658 6.69076 8.16658 7.00018V11.0835Z'
      fill='currentColor'
    />
    <path
      d='M7 4.66649C7.48325 4.66649 7.875 4.27474 7.875 3.79149C7.875 3.30824 7.48325 2.91649 7 2.91649C6.51675 2.91649 6.125 3.30824 6.125 3.79149C6.125 4.27474 6.51675 4.66649 7 4.66649Z'
      fill='currentColor'
    />
  </svg>,
  'InfoIconSummary'
);

export default InfoIconSummary;
