import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  graphs: {
    marginTop: theme.spacing(40)
    // paddingTop:theme.spacing(),
    // height:'60vh',
  },
  paper: {
    borderRadius: theme.spacing(3),
    // paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    height: '60vh',
    width: '100%'
  },
  bar: {
    width: '100%'
  },
  highlightText: {
    // color:theme.palette.common.primaryBlack,
    opacity: 0.6,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  }
}));
