import * as React from 'react';

import { Typography, Tooltip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

// types
import styles from './DatagridColorNumber.styles';

interface GridCellExpandProps {
  value: string;
}

const GridCellExpand = React.memo((props: GridCellExpandProps) => {
  const { value } = props;

  return (
    <Tooltip title={value} placement='top' arrow>
      <Typography align='center' variant='body1' noWrap sx={styles.purpleNumber}>
        {value}
      </Typography>
    </Tooltip>
  );
});

const DatagridColorNumber = (params: GridRenderCellParams) => {
  const { value } = params;
  return <GridCellExpand value={value || ''} />;
};

export default DatagridColorNumber;
