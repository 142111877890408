import React, { createContext, useReducer } from 'react';
import reducers, { EUCTRPediatricsContextInterface } from './reducers';

type Props = {
  children: React.ReactNode;
};

const initialState = {
  euctrPediatricsStats: {},
  tableData: [],
  errors: [],
  filters: [],
  availableFilters: {},
  isFiltersApplied: false,
  loading: true,
  totalRecord: 0,
  sortFields: [],
  pagination: {
    pageSize: 20,
    page: 0
  }
};

const EUCTRPediatricsStore = createContext<{
  euctrPediatricsState: EUCTRPediatricsContextInterface;
  euctrPediatricsDispatch: React.Dispatch<any>;
}>({ euctrPediatricsState: initialState, euctrPediatricsDispatch: () => null });
const { Provider } = EUCTRPediatricsStore;

export const EUCTRPediatricStatStateProvider = ({ children }: Props) => {
  const [euctrPediatricsState, euctrPediatricsDispatch] = useReducer(reducers, initialState);
  return <Provider value={{ euctrPediatricsState, euctrPediatricsDispatch }}>{children}</Provider>;
};

export default EUCTRPediatricsStore;
