const styles = {
  meetingListContainer: {
    mt: 2,
    height: 'calc(100vh - 290px)',
    overflowY: 'auto',
    p: 2,
    bgcolor: 'white.main',
    borderRadius: 3,
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px'
  },
  summaryText: {
    maxHeight: 'calc(100vh - 460px)',
    overflowY: 'auto',
    color: 'gray.main',
    // display: '-webkit-box',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
    overflowX: 'hidden'
  },
  listContainer: {
    p: 2,
    border: '1px solid #dfe4ea',
    borderRadius: 2
  },
  productNumberText: {
    width: 'fit-content',
    maxWidth: '100%',
    paddingTop: 0.5,
    paddingBottom: 0.5,
    paddingLeft: 2,
    paddingRight: 2,
    borderRadius: 2,
    bgcolor: 'blue.main',
    color: 'white.main'
  },
  brandName: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'blue.main'
  },
  indicationsText: {
    width: '100%',
    color: 'black.main',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflowX: 'hidden'
  },
  sponsorText: {
    ml: 2,
    color: 'black.main',
    textTransform: 'uppercase'
  }
};

export default styles;
