import { useCallback, useContext } from 'react';
// api
import getCanadaConsultationDocs from '../../../api/pages/CanadaConsultation';

// store
import CanadaConsultationStore from '../../../store/CanadaConsultation';
import actions from '../../../store/CanadaConsultation/actions';

// utils
import { constructPieChartData } from '../utils/getDonutChartStructure';
import { mergeOldFilterValues } from '../../../utils/filters';
import getFilterOptions from '../utils/common';

const useCanadaConsultationData = () => {
  const { CanadaConsultationState, CanadaConsultationDispatch } =
    useContext(CanadaConsultationStore);

  const getCanadaConsultationData = useCallback(async (appliedFilters: any) => {
    CanadaConsultationDispatch({
      type: actions.SET_LOADING,
      value: true
    });

    CanadaConsultationDispatch({
      type: actions.SET_IS_FILTERS_APPLIED,
      value: Object.keys(appliedFilters).length !== 0
    });

    try {
      const res = await getCanadaConsultationDocs({ filters: appliedFilters });
      const errors = [];
      if (res.data.status === 200) {
        const graphs = constructPieChartData(res.data.body.insights.donut_graph_data);
        CanadaConsultationDispatch({
          type: actions.SET_DONUT_CHARTS,
          value: graphs
        });
        CanadaConsultationDispatch({
          type: actions.SET_ISSUE_DATE,
          value: res.data.body.insights.bar_graph_data
        });
        const stats = {
          totalLastThirtyDays: res.data.body.insights.last_30_days_count,
          totalConsultation: res.data.body.insights.total_consultations,
          openConsultation: res.data.body.insights.total_open_consultations,
          pdfCount: res.data.body.insights.pdf_stats.total_pdf_count,
          pageCount: res.data.body.insights.pdf_stats.total_page_count
        };
        CanadaConsultationDispatch({
          type: actions.SET_CANADA_CONSULTATION_STATS,
          value: stats
        });
        CanadaConsultationDispatch({
          type: actions.SET_CANADA_CONSULTATION_TABLE,
          value: res.data.body.data
        });
        let mergedFilters = [];
        const newFilters = getFilterOptions(res.data.body.filters);
        if (appliedFilters.length) {
          mergedFilters = mergeOldFilterValues(newFilters, appliedFilters);
        } else {
          mergedFilters = newFilters;
        }

        if (res.data.body.data?.length) {
          CanadaConsultationDispatch({
            type: actions.SET_FILTERS,
            value: mergedFilters
          });
        }
        CanadaConsultationDispatch({
          type: actions.SET_AVAILABLE_FILTERS,
          value: appliedFilters
        });
      } else {
        errors.push('No data was found');

        CanadaConsultationDispatch({
          type: actions.SET_CANADA_CONSULTATION_ERRORS,
          action: errors
        });
      }
      CanadaConsultationDispatch({
        type: actions.SET_LOADING,
        value: false
      });
    } catch (err) {
      CanadaConsultationDispatch({
        type: actions.SET_LOADING,
        value: false
      });
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, []);

  const openConsultationComparison = useCallback((value: boolean) => {
    CanadaConsultationDispatch({
      type: actions.SET_COMPARE_ENABLED,
      value
    });
  }, []);
  const removeFromComparison = useCallback((docDetails: any) => {
    CanadaConsultationDispatch({
      type: actions.REMOVE_DOCUMENT_TO_COMPARE,
      // eslint-disable-next-line no-underscore-dangle
      value: docDetails._id
    });
  }, []);

  const openComparisonPopup = useCallback((value: boolean) => {
    CanadaConsultationDispatch({
      type: actions.SET_COMPARE_POPUP_OPEN,
      value
    });
  }, []);

  const clearComparison = useCallback(() => {
    CanadaConsultationDispatch({
      type: actions.CLEAR_DOCUMENT_TO_COMPARE
    });
    openComparisonPopup(false);
  }, []);

  const clearSearch = useCallback(() => {
    CanadaConsultationDispatch({
      type: actions.SET_SEARCH,
      value: {
        term: ''
      }
    });
  }, []);

  return {
    canadaConsultationfilters: CanadaConsultationState.filters,
    isFilterApplied: CanadaConsultationState.isFiltersApplied,
    canadaConsultationTableData: CanadaConsultationState.tableData,
    isLoading: CanadaConsultationState.loading,
    compareEnabled: CanadaConsultationState.compareEnabled,
    documentsToCompare: CanadaConsultationState.documentsToCompare,
    comparePopupOpen: CanadaConsultationState.comparePopupOpen,
    getCanadaConsultationData,
    openComparisonPopup,
    removeFromComparison,
    openConsultationComparison,
    clearComparison,
    clearSearch
  };
};

export default useCanadaConsultationData;
