const styles = {
  parentBox: {
    padding: '16px',
    borderRadius: '12px',
    borderColor: 'gray.300',
    borderWidth: '1px',
    borderStyle: 'solid',
    width: 306
  },
  title: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: 'gray.800'
  },
  updateChip: {
    backgroundColor: 'secondary.650',
    color: 'white.main',
    borderRadius: '8px',
    padding: '2px 6px',
    m: '0 !important',
    ml: '8px !important',
    width: '47px',
    height: '18px'
  },
  updateDot: { '&&': { color: '#ffffff', fontSize: 6 } },
  updateText: {
    fontWeight: '500',
    textAlign: 'center'
  },
  secondaryText: {
    color: 'gray.600',
    fontSize: 10,
    fontWeight: 500
  },
  seeAllDetailsText: {
    color: 'primary.700',
    fontSize: 10,
    fontWeight: 500
  },
  documentIcon: {
    fontSize: 48
  }
};

export default styles;
