const basePersonalizeButton = {
  textTransform: 'none',
  fontSize: 14,
  borderRadius: 5,
  paddingX: 3,
  minWidth: 210
};

const styles = {
  title: {
    color: 'gray.800',
    fontSize: 16,
    fontWeight: 700,
    textAlign: 'center',
    marginTop: '-14px',
    mx: 2
  },
  titleExpanded: {
    color: 'gray.darkBluish',
    textAlign: 'center',
    fontWeight: 600,
    flexGrow: 1
  },
  personalizeButton: {
    ...basePersonalizeButton,
    color: 'black.lightVariant4',
    borderColor: 'black.lightVariant4',
    '&:hover': {
      borderColor: 'black.lightVariant'
    }
  },
  personalizeDoneButton: {
    ...basePersonalizeButton,
    color: 'white.main',
    backgroundColor: 'primary.700',
    '&:hover': {
      backgroundColor: 'primary.main'
    }
  }
};

export default styles;
