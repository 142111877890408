import { createSvgIcon } from '@mui/material';

const TrashIcon = createSvgIcon(
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_13883_1431)'>
      <path
        d='M13.8334 3.33333H10.9167V2.16667C10.9167 1.85725 10.7938 1.5605 10.575 1.34171C10.3562 1.12292 10.0595 1 9.75008 1H6.25008C5.94066 1 5.64392 1.12292 5.42512 1.34171C5.20633 1.5605 5.08342 1.85725 5.08342 2.16667V3.33333H2.16675V4.5H3.33341V13.25C3.33341 13.7141 3.51779 14.1592 3.84598 14.4874C4.17417 14.8156 4.61929 15 5.08342 15H10.9167C11.3809 15 11.826 14.8156 12.1542 14.4874C12.4824 14.1592 12.6667 13.7141 12.6667 13.25V4.5H13.8334V3.33333ZM6.25008 2.16667H9.75008V3.33333H6.25008V2.16667ZM11.5001 13.25C11.5001 13.4047 11.4386 13.5531 11.3292 13.6625C11.2198 13.7719 11.0715 13.8333 10.9167 13.8333H5.08342C4.92871 13.8333 4.78033 13.7719 4.67094 13.6625C4.56154 13.5531 4.50008 13.4047 4.50008 13.25V4.5H11.5001V13.25Z'
        fill='currentColor'
      />
      <path d='M7.41666 6.83301H6.25V11.4997H7.41666V6.83301Z' fill='currentColor' />
      <path d='M9.75017 6.83301H8.5835V11.4997H9.75017V6.83301Z' fill='currentColor' />
    </g>
    <defs>
      <clipPath id='clip0_13883_1431'>
        <rect width='14' height='14' fill='white' transform='translate(1 1)' />
      </clipPath>
    </defs>
  </svg>,
  'Trash'
);

export default TrashIcon;
