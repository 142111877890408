import { useCallback, useState } from 'react';
import groupBy from 'lodash/groupBy';
import { getClinicalTrialsDocuments } from '../../../api/pages/cdp';

const getFileNameFromURL = (url: string) => {
  // eslint-disable-next-line no-underscore-dangle
  const _url = new URL(url);
  const filePath = _url.pathname;
  const fileName = filePath.substring(filePath.lastIndexOf('/') + 1);
  return fileName;
};
const useCTPDocuments = (id: string, source: string) => {
  const [isLoading, setLoading]: [boolean, Function] = useState(false);
  const [errorMessage, setErrorMessage]: [string, Function] = useState('');
  const [ctDocuments, setCTDocuments]: [any, Function] = useState([]);

  const getCTDocuments = useCallback(async () => {
    setLoading(true);
    setErrorMessage('');
    setCTDocuments([]);
    try {
      const response: any = await getClinicalTrialsDocuments(id, source);
      if (response?.data?.Success) {
        const docs = response?.data?.Success;
        let sortedDocs = docs.sort((a: any, b: any) => a.doc_type.localeCompare(b.doc_type));

        if (source === 'euctr') {
          // For EU CTR, documents could be multiple for each category because each member state will have its own document. We group them by the document category.
          sortedDocs = sortedDocs.map((doc: any) => ({
            ...doc,
            _category: getFileNameFromURL(doc.s3_path)
          }));
          const groupedDocs = groupBy(sortedDocs, 'doc_type');

          const sorted: any = {};

          const sortedKeys = Object.keys(groupedDocs).sort((a: string, b: string) =>
            a.localeCompare(b)
          );
          sortedKeys.forEach((key: string) => {
            sorted[key] = groupedDocs[key];
          });
          sortedDocs = sorted;
        }
        setCTDocuments(sortedDocs);
      } else {
        setErrorMessage('Could not get the Clinical Trials Documents');
      }
      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setErrorMessage('Could not get the Clinical Trials Documents');
    }
  }, [id]);

  return {
    isLoading,
    errorMessage,
    ctDocuments,
    getCTDocuments
  };
};

export default useCTPDocuments;
