import { Button, Typography } from '@mui/material';
import React from 'react';
import styles from './styles/reportButton.styles';
import PlusIcon from '../../assets/svgs/Report/PlusIcon';

const ReportButton = ({ data, addToReport }: any) => {
  return (
    <Button
      onClick={() => addToReport(data)}
      variant='text'
      fullWidth={false}
      sx={styles.addToReport}>
      <Typography align='right' variant='subtitle2' sx={styles.reportText}>
        <PlusIcon sx={styles.plusIcon} /> Report
      </Typography>
    </Button>
  );
};

export default React.memo(ReportButton);
