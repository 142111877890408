import React, { useEffect, useRef, useState } from 'react';
// third party
import isEmpty from 'lodash/isEmpty';
// mui
import { Stack, Box, Typography } from '@mui/material';
// component
import YearRange from './YearRange';
// styles
import styles from '../styles/Meeting.styles';

const MeetingsYearList = ({ handleSelectMeeting, allMeetingsByYear, selectedMeetingId }: any) => {
  const sectionRefs = useRef<any>({});
  const [selectedYear, setSelectedYear] = useState('');

  const scrollToSection = (year: any) => {
    const ref = sectionRefs.current[year];
    if (ref) {
      ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  useEffect(() => {
    scrollToSection(parseInt(selectedMeetingId?.split(' ')[1], 10));
  }, []);

  return (
    <Stack sx={styles.listScrollContainer}>
      {!isEmpty(allMeetingsByYear) &&
        Object.keys(allMeetingsByYear) // get all meetings by year Eg: {2021: [{}, {}], 2020: [{}, {}]
          .sort((a: any, b: any) => b - a) // sort by year
          .map((year: string) => {
            const meetings = allMeetingsByYear[year];
            return (
              <Stack
                key={year}
                ref={el => {
                  sectionRefs.current[year] = el;
                }}
                spacing={2}>
                <Box sx={styles.listAccordion}>
                  <Box sx={styles.listAccordionSummary}>
                    <YearRange
                      year={year}
                      handleDateSelection={scrollToSection}
                      minDate={Object.keys(allMeetingsByYear)[0]}
                      maxDate={
                        Object.keys(allMeetingsByYear)[Object.keys(allMeetingsByYear).length - 1]
                      }
                      selectedYear={selectedYear}
                      setSelectedYear={setSelectedYear}
                    />
                  </Box>
                  <Box sx={styles.listAccordionDetails}>
                    <Stack spacing={1.5}>
                      {meetings?.map((meeting: any) => {
                        return (
                          <Stack
                            key={meeting.meeting_id}
                            spacing={1}
                            sx={{
                              ...styles.listMeetingBox,
                              borderColor:
                                selectedMeetingId === meeting.meeting_id
                                  ? 'primary.400'
                                  : 'gray.300'
                            }}
                            onClick={() => {
                              handleSelectMeeting(meeting.meeting_id);
                            }}>
                            <Typography
                              sx={{
                                ...styles.listMeetingDate,
                                color:
                                  selectedMeetingId === meeting.meeting_id
                                    ? 'primary.700'
                                    : 'gray.900'
                              }}>
                              {`${meeting?.date?.split(' ')[1]} ${meeting?.date?.split(' ')[0]}`}
                            </Typography>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </Box>
                </Box>
              </Stack>
            );
          })}
    </Stack>
  );
};

export default React.memo(MeetingsYearList);
