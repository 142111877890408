const styles = {
  wrapper: {
    padding: '12px 20px',
    borderBottom: '1px solid',
    borderBottomColor: 'gray.background',
    backgroundColor: 'gray.50'
  },
  sectionWrapper: {
    padding: '16px 40px 12px 40px',
    margin: '0px !important'
  },
  variant1: { fontSize: '1rem' }
};

export default styles;
