export const styles = {
  meetingHeader: {
    fontSize: 14,
    fontWeight: 700,
    paddingBottom: '12px',
    color: 'primary.900'
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 20,
    top: 16,
    color: 'gray.650'
  },
  accordion: {
    border: '1px solid',
    borderColor: 'gray.300',
    borderRadius: 2,
    boxShadow: 'none',
    marginBottom: 1,
    '&.MuiAccordion-root:before': {
      backgroundColor: 'white',
      height: '0px'
    },
    '&.MuiAccordion-root.Mui-expanded':{
      border:1,
      borderColor:'primary.400',
      borderRadius:2
    },
    padding: '8px 16px'
  },
  summary: {
    padding: 0
  },
  typography: {
    fontSize: 14,
    fontWeight: 700,
    color: 'gray.800'
  },
  typographyValue: {
    fontWeight: 400,
    fontSize: 14,
    color: 'gray.800'
  },
  descriptionHeader: {
    fontSize: 14,
    fontWeight: 700
  },
  divider: {
    mt: 2,
    mb: 2,
    color: 'gray.300'
  },
  backButton: {
    padding: 0,
    textTransform: 'none'
  },
  arrowIcon: {
    color: 'gray.700',
    fontSize: 16
  },
  submissionText: {
    fontWeight: 600,
    fontSize: 14,
    color: 'primary.700'
  }
};
