import { createSvgIcon } from '@mui/material';

const GuidanceIcon = createSvgIcon(
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.084 9.804L6 6V30L14.084 33.804V9.804ZM16.084 33.776L24.042 30.19V6.19L16.084 9.776V33.776ZM26.042 30.232L34 34V10L26.042 6.232V30.232Z'
      fill='currentColor'
    />
  </svg>,
  'Guidance'
);

export default GuidanceIcon;
