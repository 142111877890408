import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Popover,
  TextField
} from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useCallback, useMemo, useState } from 'react';
import { FormGroup } from '@material-ui/core';
import { groupBy, uniq } from 'lodash';
import KeyDownIcon from '../../../assets/svgs/Icons/KeyDownIcon';
import SearchIcon from '../../../assets/svgs/Icons/SearchIcon';

import styles from '../styles/OptionDropDownStyles';

const SubmissionOptions = ({
  anchorEl,
  onClose,
  options,
  onOptionSelect,
  onGroupSelect,
  onClear
}: any) => {
  const [searchText, setSearchText] = useState<string>('');

  const [expandedGroups, setExpendedGroups] = useState<Array<string>>([]);

  const groupedOptions = useMemo(() => {
    const newOpts =
      options?.filter((opt: any) => opt.name.toLowerCase().includes(searchText.toLowerCase())) ||
      [];
    return groupBy(newOpts, (opt: any) => opt.group);
  }, [options, searchText]);

  const handleSearchTextChange = useCallback(
    (event: any) => {
      setSearchText(event.target.value);
      const newOpts = options.filter((opt: any) =>
        opt.name.toLowerCase().includes(event.target.value?.toLowerCase())
      );
      const newGroups: Array<string> = uniq(newOpts.map((opt: any) => opt.group));
      setExpendedGroups(newGroups);
    },
    [options]
  );

  const handleAccordionClick = useCallback(
    (key: string) => {
      let newExpandedGroup = [...expandedGroups];
      if (newExpandedGroup.includes(key)) {
        newExpandedGroup = newExpandedGroup.filter((gp: string) => gp !== key);
      } else {
        newExpandedGroup.push(key);
      }
      setExpendedGroups(newExpandedGroup);
    },
    [expandedGroups]
  );
  const clearSelectionEnabled = useMemo(() => {
    let isAnySelected = false;
    options?.forEach((opt: any) => {
      if (opt.selected) {
        isAnySelected = true;
      }
    });
    return isAnySelected;
  }, [options]);
  return (
    <Popover
      id='popover'
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}>
      <Box sx={styles.submissionOptionsContainer}>
        {clearSelectionEnabled && (
          <Box pt={1} pl={1}>
            <Typography sx={styles.clearSelection} onClick={onClear}>
              Clear Selection
            </Typography>
          </Box>
        )}

        <Box pb={1} pt={1}>
          <TextField
            size='small'
            value={searchText}
            onChange={handleSearchTextChange}
            placeholder='Search here'
            sx={{ width: '100%' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon sx={{ fontSize: 16, color: 'gray.500' }} />
                </InputAdornment>
              ),
              sx: styles.searchBox
            }}
          />
        </Box>

        {Object.keys(groupedOptions).map((key: string, index: number) => (
          <Accordion
            key={key}
            onChange={() => handleAccordionClick(key)}
            expanded={expandedGroups.includes(key)}
            sx={{
              ...styles.accordion,
              '&::before': {
                backgroundColor: index === 0 ? 'unset !important' : 'gray.200'
              }
            }}>
            <AccordionSummary
              expandIcon={<KeyDownIcon sx={{ fontSize: 12, color: 'gray.700', marginX: 1 }} />}
              id={key}
              sx={{ p: 0 }}>
              <Box display='flex' alignItems='center'>
                <Checkbox
                  sx={styles.checkBox}
                  checked={
                    groupedOptions[key].filter((opt: any) => opt.selected).length ===
                    groupedOptions[key].length
                  }
                  onClick={(e: any) => e.stopPropagation()} // To avoid accordion close.
                  onChange={() => {
                    onGroupSelect(key);
                  }}
                />
                <Typography fontSize={14} component='span' fontWeight={600} color='gray.800'>
                  {key}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <FormGroup>
                {groupedOptions[key].map((submission: any) => (
                  <FormControlLabel
                    sx={{ pl: 4, paddingY: 1.5 }}
                    key={submission.submission}
                    control={
                      <Checkbox
                        sx={styles.checkBox}
                        checked={submission.selected}
                        onChange={() => onOptionSelect(submission.submission)}
                        onClick={(e: any) => e.stopPropagation()}
                      />
                    }
                    label={
                      <Typography fontSize={14} component='span' lineHeight={1} color='gray.800'>
                        {submission.name}
                      </Typography>
                    }
                  />
                ))}
              </FormGroup>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Popover>
  );
};

export default React.memo(SubmissionOptions);
