import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Box, Button, Fab, Badge, Tooltip } from '@mui/material';
import axios, { CancelTokenSource } from 'axios';

import { Auth } from 'aws-amplify';
import { v4 } from 'uuid';
import styles from '../styles/LabelSectionSelection.styles';

import ResultsActions from '../../../store/SearchResults/actions';
import ResultsStore from '../../../store/SearchResults';
import GlobalStore from '../../../store';
import Actions from '../../../store/actions';
import { downloadCollatedSummary } from '../data/collatedSummary';
import CloseIcon from '../../../assets/svgs/Icons/CloseIcon';
import { DownloadIcon, LeftArrowIcon } from '../../../assets/svgs/Icons';
import downloadLabelFile from '../utils/downloadLabelFile';
import { Progress } from '../../../components/Comparison/interface';
import DownloadLoadingModal from '../../../components/Comparison/DownloadModal';
import { simulateProgress } from '../../../components/Comparison/const';

const DownloadLabelSection = () => {
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const { dispatch } = useContext<any>(GlobalStore);
  const [cancel, setCancel] = React.useState<CancelTokenSource | null>(null);
  const [downloadProgress, setDownloadProgress] = useState<Progress>({
    message: 'Starting Compilation',
    progress: 0
  });
  const [cancelProgress, setCancelProgress] = useState<any>(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadModalOpen, setDownloadModalOpen] = useState<boolean>(false);
  const [downloadError, setDownloadError] = useState<boolean>(false);

  const exitLabelSection = () => {
    resultsDispatch({ type: ResultsActions.SET_COLLATED_SUMMARY_APPLICATIONS, value: [] });
    resultsDispatch({ type: ResultsActions.SET_COLLATED_SUMMARY_SELECTED_APPLICATIONS, value: [] });
    resultsDispatch({ type: ResultsActions.SET_COLLATED_SUMMARY_SELECTION, value: false });
    resultsDispatch({
      type: ResultsActions.SET_COLLATED_SUMMARY_SELECTED_CATEGORIES,
      value: [
        {
          id: v4(),
          source: '',
          sections: ''
        }
      ]
    });
  };

  const handleCancelCall = useCallback(async () => {
    if (cancel) {
      cancel.cancel('Operation canceled by the user.');
      setCancel(null);
    }
    if (cancelProgress) {
      clearInterval(cancelProgress);
      setCancelProgress(null);
    }
    setIsDownloading(false);
  }, [cancel]);

  const handleDownloadCollatedSummary = async () => {
    const user = await Auth.currentUserInfo();
    const username = user.attributes['custom:user'] ?? '';
    const cancelToken = axios.CancelToken.source();
    const downloadType = resultsState?.collatedSummaryDownloadType ?? 'html';
    setCancel(cancelToken);

    try {
      if (resultsState?.collatedSummarySelectedApplications?.length < 1) {
        dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Please select at least 1 label', duration: 3000 }
        });
        return;
      }

      setDownloadError(false);
      setDownloadModalOpen(true);
      setDownloadProgress({
        message: 'Starting Compilation',
        progress: 0
      });

      let collatedApplication: any[] = [];

      const applicationNumbers = resultsState?.collatedSummarySelectedApplications?.map(
        selectedApp => selectedApp.labelSelectionIdentifier
      );

      collatedApplication = resultsState?.collatedSummaryApplications?.filter((appl: any) => {
        return applicationNumbers.includes(`${appl.source}-${appl.number}-${appl.section}`);
      });
      const jobID = v4();

      const payload = {
        username,
        download_type: downloadType,
        applications: collatedApplication,
        job_id: jobID
      };
      setIsDownloading(true);
      const res = await downloadCollatedSummary(payload, cancelToken.token);
      if (res?.status === 200) {
        setIsDownloading(false);
        if (cancelProgress) {
          clearInterval(cancelProgress);
          setCancelProgress(null);
        }
        setDownloadProgress({
          message: 'Conversion Completed',
          progress: 100
        });
        downloadLabelFile(res.data, downloadType, `Latest-Label-Comparison.${downloadType}`);
      } else {
        setDownloadError(true);
        setIsDownloading(false);
        if (cancelProgress) {
          clearInterval(cancelProgress);
          setCancelProgress(null);
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setDownloadError(true);
      if (!cancel) {
        setDownloadModalOpen(false);
      }
      setDownloadProgress({
        message: 'Download Failed',
        progress: 0
      });
    } finally {
      setCancel(null);
      setIsDownloading(false);
      if (cancelProgress) {
        clearInterval(cancelProgress);
        setCancelProgress(null);
      }
    }
  };

  const handleSectionSelection = () => {
    resultsDispatch({ type: ResultsActions.SET_COLLATED_SUMMARY_SELECTION, value: true });
  };

  const closeDownloadModal = useCallback(() => {
    setDownloadModalOpen(false);
    setDownloadError(false);
    if (cancelProgress) {
      clearInterval(cancelProgress);
      setCancelProgress(null);
    }
    setDownloadProgress({
      message: 'Starting Compilation',
      progress: 0
    });
    setIsDownloading(false);
  }, []);

  useEffect(() => {
    const applicationNumbers = resultsState?.collatedSummarySelectedApplications?.map(
      selectedApp => selectedApp.labelSelectionIdentifier
    );
    const downloadType = resultsState?.collatedSummaryDownloadType ?? 'html';

    if (!isDownloading || applicationNumbers.length === 0) {
      return () => {};
    }
    let step = 0; // Manage the step as a regular variable

    const intervalId = setInterval(() => {
      // Simulate progress based on the current step
      const { message, progress, stepScale } = simulateProgress({
        currentStep: step,
        fileType: downloadType,
        fileCount: applicationNumbers.length
      });
      // Stop interval when reaching 100 steps
      if (step >= 95 || !isDownloading) {
        clearInterval(intervalId);
      }
      if (isDownloading) {
        // Update the progress message and percentage
        setDownloadProgress(prevProgress => {
          if (prevProgress.progress <= progress) {
            return {
              message,
              progress
            };
          }
          return prevProgress;
        });
      }
      // Increment step based on stepScale
      step += stepScale;
    }, 100); // Progress updates every 100ms
    setCancelProgress(intervalId);
    return () => clearInterval(intervalId);
  }, [isDownloading]);

  return (
    <Box sx={styles.labelSectionDownloadContainer}>
      <Button type='button' sx={styles.sectionSelectionButton} onClick={handleSectionSelection}>
        <LeftArrowIcon sx={styles.leftArrowIcon} />
        Section Selection
      </Button>

      <Fab
        sx={styles.downloadButton}
        aria-label='download-label'
        onClick={handleDownloadCollatedSummary}
        variant='extended'>
        <Badge
          badgeContent={resultsState?.collatedSummarySelectedApplications?.length}
          color='error'
          showZero
          sx={styles.badge}>
          <DownloadIcon sx={styles.downloadIcon} />
        </Badge>
        <Box sx={styles.buttonText}>Download</Box>
      </Fab>

      <Button variant='outlined' type='submit' sx={styles.exitButton} onClick={exitLabelSection}>
        <Tooltip title='Exit mode'>
          <CloseIcon sx={styles.closeIcon} />
        </Tooltip>
      </Button>
      {downloadModalOpen && (
        <DownloadLoadingModal
          modalOpen={downloadModalOpen}
          closeModal={closeDownloadModal}
          progressData={downloadProgress}
          downloadError={downloadError}
          setDownloadError={setDownloadError}
          handleDownloadComparison={handleDownloadCollatedSummary}
          handleCancelCall={handleCancelCall}
        />
      )}
    </Box>
  );
};

export default DownloadLabelSection;
