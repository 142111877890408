import React, { lazy, Suspense, useEffect, useState } from 'react';
import PMRStyles from './Graphs.styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

//context store

import AnimatedNumber from 'animated-number-react';
import SummaryIcon from '../../../Images/pmrSum.svg';
import SuspenseCircularLoader from '../../../helpers/SuspenseCircularLoader';

const ResponsiveBar = lazy(() =>
  import('@nivo/bar').then(module => ({ default: module.ResponsiveBar }))
);
const ResponsivePie = lazy(() =>
  import('@nivo/pie').then(module => ({ default: module.ResponsivePie }))
);

export default function Graphs(props) {
  const classes = PMRStyles();
  const [status, setStatus] = useState();
  let formatValue = value => `${Number(value).toFixed(0)}`;
  const [issueByYear, setissueByYear] = useState();
  const [fulfillByYear, setfulfillByYear] = useState();
  const [issueToFulfill, setissueToFulfill] = useState();
  const [path, setPath] = useState();
  const [totalCount, settotalCount] = useState();
  const [subpart, setSubpart] = useState();
  const [categories, setCategories] = useState();
  const [issueCount, setissueCount] = useState();
  const [completionCount, setcompletionCount] = useState();
  const margin = { top: 30, right: 200, bottom: 30, left: 30 };

  const styles = {
    root: {
      fontFamily: 'consolas, sans-serif',
      textAlign: 'center',
      position: 'relative',
      width: 600,
      height: 600
    },
    overlay: {
      position: 'absolute',
      top: 0,
      right: margin.right,
      bottom: 0,
      left: margin.left,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 96,
      color: '#FFFFFF',
      // background: "#FFFFFF33",
      textAlign: 'center',
      // This is important to preserve the chart interactivity
      pointerEvents: 'none'
    },
    totalLabel: {
      fontSize: 24
    }
  };

  useEffect(() => {
    async function getData() {
      let path = props.path;
      setPath(props && props.path);
      if (path === 'impact') {
        setissueByYear(props && props.graphData && props.graphData.issueGraph);
        setfulfillByYear(props && props.graphData && props.graphData.fulfillGraph);
        setissueToFulfill(props && props.graphData && props.graphData.issueToFulfill);
      } else if (path === 'surveillance') {
        settotalCount(props && props.graphData && props.graphData.total);
        setissueCount(props && props.graphData && props.graphData.issue);
        setcompletionCount(props && props.graphData && props.graphData.completion);

        let result = [];
        result = Object.keys(props.graphData.status).map(key => {
          let obj = {
            id: key,
            label: key,
            value: props.graphData.status[key]
          };
          return obj;
        });
        setStatus(result);

        result = [];
        result = Object.keys(props.graphData.subpart).map(key => {
          let obj = {
            id: key,
            label: key,
            value: props.graphData.subpart[key]
          };
          return obj;
        });
        setSubpart(result);

        setCategories(props.graphData.categories);
      }
    }
    getData();
  }, [props]);

  return (
    <Grid container style={{ padding: '0px 0px' }}>
      {path && path === 'impact' ? (
        <Grid item xs={12} container>
          <Grid item xs={12} container style={{ paddingLeft: '20px' }}>
            <Grid item xs={12} container>
              <Typography className={classes.graphTitle}>
                Number of PMR/PMC and Number of days from issue to fulfillment or release
              </Typography>
            </Grid>
            <Grid item xs={12} container style={{ height: '40vh' }}>
              {issueToFulfill?.length > 0 ? (
                <Suspense fallback={<SuspenseCircularLoader />}>
                  <ResponsiveBar
                    data={issueToFulfill}
                    keys={['days', 'number']}
                    indexBy={'x'}
                    margin={{
                      top: 0,
                      right: 10,
                      bottom: 50,
                      left: 40
                    }}
                    // layout='horizontal'
                    groupMode='grouped'
                    borderRadius={2}
                    padding={0.2}
                    valueScale={{ type: 'linear' }}
                    // type = "symlog"
                    innerPadding={0.1}
                    gridXValues={5}
                    // colors={['#3AB09E', '#D4D4D4']}
                    // defs={[
                    //   {
                    //     id: 'gradientD',
                    //     type: 'linearGradient',
                    //     colors: [
                    //       { offset: 40, color: '#48E0A6' },
                    //       { offset: 100, color: '#3AB09E' },
                    //     ],
                    //   },
                    // ]}
                    // fill={[{ match: '*', id: 'gradientD' }]}
                    defs={[
                      {
                        id: 'dots',
                        // type: 'patternDots',
                        // background: 'inherit',
                        type: 'linearGradient',
                        colors: [
                          { offset: 40, color: '#ab54db' },
                          { offset: 100, color: '#6868fa' }
                        ]
                        // size: 4,
                        // padding: 1,
                        // stagger: true,
                      },
                      {
                        id: 'lines',
                        // type: 'plane',
                        // background: 'inherit',
                        type: 'linearGradient',
                        colors: [
                          { offset: 40, color: '#2b43a6' },
                          { offset: 100, color: '#29abe2' }
                        ],

                        // rotation: -45,
                        // lineWidth: 6,
                        spacing: 10
                      }
                    ]}
                    fill={[
                      {
                        match: {
                          id: 'days'
                        },
                        id: 'dots'
                      },
                      {
                        match: {
                          id: 'number'
                        },
                        id: 'lines'
                      }
                    ]}
                    colorBy='index'
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      orient: 'bottom',
                      tickSize: 1,
                      tickPadding: 5,
                      tickRotation: 0
                    }}
                    theme={{
                      axis: {
                        ticks: {
                          // text: {
                          //   fontSize: '10px',
                          //   fill: '#ffffff',
                          // },
                          text: {
                            fill: '#6D7D8A',
                            fontWeight: 'bold',
                            fontSize: '11px'
                          }
                        }
                      },
                      legends: {
                        text: {
                          fontSize: '16px'
                        }
                      }
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={'#ffffff'}
                    enableGridY={false}
                  />
                </Suspense>
              ) : (
                <Box display='flex' alignItems='center' ml='50%'>
                  <Typography> No Data </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} container style={{ paddingLeft: '20px' }}>
            <Grid item xs={6} container>
              <Grid item xs={6} container>
                <Typography className={classes.graphTitle}>
                  Number of PMR/PMC issued by year (and fulfilled or released)
                </Typography>
              </Grid>
              <Grid item xs={12} container style={{ height: '40vh' }}>
                {issueByYear?.length > 0 ? (
                  <Suspense fallback={<SuspenseCircularLoader />}>
                    <ResponsiveBar
                      data={issueByYear}
                      keys={['y']}
                      indexBy={'x'}
                      margin={{
                        top: 5,
                        right: 10,
                        bottom: 50,
                        left: 40
                      }}
                      // layout="horizontal"
                      borderRadius={2}
                      padding={0.4}
                      // colors={`linear-gradient(to bottom, #318AC8, #54B9FF)`}
                      defs={[
                        {
                          id: 'gradientC',
                          type: 'linearGradient',
                          colors: [
                            // { offset: 0, color: '#29ABE2' },
                            // { offset: 40, color: '#0E83DC' },
                            { offset: 40, color: '#29abe2' },
                            { offset: 100, color: '#b1bbe4' }
                          ]
                        }
                      ]}
                      fill={[{ match: '*', id: 'gradientC' }]}
                      colorBy='index'
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        orient: 'bottom',
                        tickSize: 1,
                        tickPadding: 5,
                        tickRotation: 0
                      }}
                      theme={{
                        axis: {
                          ticks: {
                            // text: {
                            //   fontSize: '10px',
                            //   fill: '#ffffff',
                            // },
                            text: {
                              fill: '#6D7D8A',
                              fontWeight: 'bold',
                              fontSize: '11px'
                            }
                          }
                        },
                        legends: {
                          text: {
                            fontSize: '16px'
                          }
                        }
                      }}
                      labelSkipWidth={12}
                      labelSkipHeight={12}
                      labelTextColor={'#ffffff'}
                      enableGridY={false}
                    />
                  </Suspense>
                ) : (
                  <Box display='flex' alignItems='center' ml='50%'>
                    <Typography>No Data</Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
            <Grid item xs={6} container>
              <Grid item xs={12} container>
                <Typography className={classes.graphTitle}>
                  Number of PMR/PMC fulfilled or released by year
                </Typography>
              </Grid>
              <Grid item xs={12} container style={{ height: '40vh' }}>
                {fulfillByYear?.length > 0 ? (
                  <Suspense fallback={<SuspenseCircularLoader />}>
                    <ResponsiveBar
                      data={fulfillByYear}
                      keys={['y']}
                      indexBy={'x'}
                      margin={{
                        top: 5,
                        right: 10,
                        bottom: 50,
                        left: 40
                      }}
                      // layout="horizontal"
                      borderRadius={2}
                      padding={0.4}
                      // colors={`linear-gradient(to bottom, #318AC8, #54B9FF)`}
                      defs={[
                        {
                          id: 'gradientD',
                          type: 'linearGradient',
                          colors: [
                            { offset: 40, color: '#6868fa' },
                            { offset: 100, color: '#ab54db' }
                          ]
                        }
                      ]}
                      fill={[{ match: '*', id: 'gradientD' }]}
                      colorBy='index'
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        orient: 'bottom',
                        tickSize: 1,
                        tickPadding: 5,
                        tickRotation: 0
                      }}
                      theme={{
                        axis: {
                          ticks: {
                            // text: {
                            //   fontSize: '10px',
                            //   fill: '#ffffff',
                            // },
                            text: {
                              fill: '#6D7D8A',
                              fontWeight: 'bold',
                              fontSize: '11px'
                            }
                          }
                        },
                        legends: {
                          text: {
                            fontSize: '16px'
                          }
                        }
                      }}
                      labelSkipWidth={12}
                      labelSkipHeight={12}
                      labelTextColor={'#ffffff'}
                      enableGridY={false}
                    />
                  </Suspense>
                ) : (
                  <Box display='flex' alignItems='center' ml='50%'>
                    <Typography>No Data</Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={1} container></Grid> */}
        </Grid>
      ) : (
        <Grid item xs={12} container>
          <Grid item xs={2} container>
            <Grid item xs={12} container>
              <Typography className={classes.graphTitle}> Overview </Typography>
            </Grid>
            <Grid item xs={12} container>
              <Box
                padding={2}
                display='flex'
                flexDirection='column'
                alignItems='center'
                className={classes.cards}
              >
                <Paper elevation={3} className={classes.paperCards}>
                  <Box
                    padding={1}
                    display='flex'
                    flexDirection='column'
                    justifyContent='space-evenly'
                    alignItems='center'
                    className={classes.cardContent}
                  >
                    <img style={{ width: '30px' }} src={SummaryIcon} alt='summary-icon'></img>

                    <Typography className={classes.number}>
                      <AnimatedNumber
                        className='animatedNumber'
                        value={String(totalCount)}
                        formatValue={formatValue}
                        duration='4000'
                      />
                    </Typography>
                    <Typography className={classes.cardText}>Total</Typography>
                  </Box>
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={12} container>
              <Box
                padding={2}
                display='flex'
                flexDirection='column'
                alignItems='center'
                className={classes.cards}
              >
                <Paper elevation={3} className={classes.paperCards}>
                  <Box
                    padding={1}
                    display='flex'
                    flexDirection='column'
                    justifyContent='space-evenly'
                    alignItems='center'
                    className={classes.cardContent}
                  >
                    <img style={{ width: '30px' }} src={SummaryIcon} alt='summary-icon'></img>
                    <Typography className={classes.number}>
                      {/*  <AnimatedNumber
                        className="animatedNumber"
                        value={String(issueCount && issueCount)}
                        formatValue={formatValue}
                        duration="4000"
      /> */}

                      {issueCount && issueCount}
                    </Typography>
                    <Typography className={classes.cardText}>issued in last 12 month</Typography>
                  </Box>
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={12} container>
              <Box
                padding={2}
                display='flex'
                flexDirection='column'
                alignItems='center'
                className={classes.cards}
              >
                <Paper elevation={3} className={classes.paperCards}>
                  <Box
                    padding={1}
                    display='flex'
                    flexDirection='column'
                    justifyContent='space-evenly'
                    alignItems='center'
                    className={classes.cardContent}
                  >
                    <img style={{ width: '30px' }} src={SummaryIcon} alt='summary-icon'></img>
                    <Typography className={classes.number}>
                      <AnimatedNumber
                        className='animatedNumber'
                        value={String(completionCount)}
                        formatValue={formatValue}
                        duration='4000'
                      />
                    </Typography>
                    <Typography className={classes.cardText}>
                      Expected Completion in 12 months
                    </Typography>
                  </Box>
                </Paper>
              </Box>
            </Grid>
          </Grid>
          {/* bar graph and pie charts */}
          <Grid item xs={8}>
            <Grid item xs={12}>
              <Typography className={classes.graphTitle}>
                Number of PMRs/PMCs by categories
              </Typography>
              <Grid item xs={12} container style={{ height: '30vh', width: '100%' }}>
                {categories?.length > 0 ? (
                  <Suspense fallback={<SuspenseCircularLoader />}>
                    <ResponsiveBar
                      data={categories?.filter(item => item.number !== 0)}
                      keys={['number']}
                      enableLabel={false}
                      indexBy={'x'}
                      margin={{
                        top: 0,
                        right: 10,
                        bottom: 50,
                        left: 120
                      }}
                      // layout="horizontal"
                      groupMode='grouped'
                      borderRadius={2}
                      padding={0.7}
                      tickRotationValue={90}
                      colorBy='index'
                      colors={['#B1BBE4']}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        orient: 'bottom',
                        tickSize: 1,
                        tickPadding: 5,
                        tickRotation: 90
                      }}
                      labelSkipWidth={12}
                      labelSkipHeight={12}
                      labelTextColor={'#ffffff'}
                      enableGridY={false}
                    />
                  </Suspense>
                ) : (
                  <Box ml='50%' alignItems='center' display='flex'>
                    <Typography>No Data</Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                <Typography className={classes.graphTitlePie}> PMR / PMC Status</Typography>
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  style={{ width: '100%' }}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='center'
                    className={classes.graphContainer}
                  >
                    <Box display='flex' flexDirection='column' className={classes.graphBox}>
                      {status?.length > 0 ? (
                        <Suspense fallback={<SuspenseCircularLoader />}>
                          <ResponsivePie
                            className={classes.graph}
                            data={status}
                            margin={{ top: 40, right: 10, bottom: 40, left: 40 }}
                            padAngle={0.7}
                            innerRadius={0.7}
                            cornerRadius={3}
                            startAngle={-50}
                            borderWidth={0}
                            radialLabelsLinkStrokeWidth={3}
                            arcLabelsTextColor={['#fff']}
                            // colors={['#40C4FF', '#0E83DC']}
                            colors={[
                              '#12815E',
                              '#33B187',
                              '#48E0A6',
                              '#C9D2F6',
                              '#B1BBE4',
                              '#EA5252',
                              '#29ABE2'
                            ]}
                            sliceLabel={function (e) {
                              return e.value;
                            }}
                            borderColor={{
                              from: 'color',
                              modifiers: [['darker', 0.2]]
                            }}
                            radialLabelsLinkOffset={-10}
                            radialLabelsLinkDiagonalLength={20}
                            radialLabelsLinkHorizontalLength={10}
                            radialLabelsSkipAngle={10}
                            radialLabelsTextColor='#1FBDCA'
                            radialLabelsLinkColor={{ from: 'color' }}
                            sliceLabelsSkipAngle={10}
                            sliceLabelsTextColor='#fff'
                            theme={{
                              axis: {
                                fontSize: '14px',
                                tickColor: '#000',
                                ticks: {
                                  line: {
                                    stroke: '#555555'
                                  },
                                  text: {
                                    fill: '#1FBDCA',
                                    fontWeight: 'bold'
                                  }
                                }
                              }
                            }}
                          />
                        </Suspense>
                      ) : (
                        <Box display='flex' alignItems='center' ml='50%'>
                          <Typography>No Data</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.graphTitlePie}> Subpart</Typography>

                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  style={{ width: '100%' }}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='center'
                    className={classes.graphContainer}
                  >
                    <Box display='flex' flexDirection='column' className={classes.graphBox}>
                      {subpart?.length > 0 ? (
                        <Suspense fallback={<SuspenseCircularLoader />}>
                          <ResponsivePie
                            className={classes.graph}
                            data={subpart}
                            margin={{ top: 40, right: 10, bottom: 40, left: 40 }}
                            padAngle={0.7}
                            cornerRadius={3}
                            startAngle={-50}
                            borderWidth={0}
                            innerRadius={0.7}
                            arcLabelsTextColor={['#fff']}
                            radialLabelsLinkStrokeWidth={3}
                            // colors={['#40C4FF', '#0E83DC']}
                            colors={[
                              '#2B43A6',
                              '#6868FA',
                              '#29ABE2',
                              '#C9D2F6',
                              '#0E83DC',
                              '#48E0A6',
                              '#EA5252'
                            ]}
                            sliceLabel={function (e) {
                              return e.value;
                            }}
                            // borderWidth={1}
                            borderColor={{
                              from: 'color',
                              modifiers: [['darker', 0.2]]
                            }}
                            radialLabelsLinkOffset={-10}
                            radialLabelsLinkDiagonalLength={20}
                            radialLabelsLinkHorizontalLength={10}
                            radialLabelsSkipAngle={10}
                            radialLabelsTextColor='#1FBDCA'
                            radialLabelsLinkColor={{ from: 'color' }}
                            sliceLabelsSkipAngle={10}
                            sliceLabelsTextColor='#fff'
                            theme={{
                              axis: {
                                fontSize: '14px',
                                tickColor: '#000',
                                ticks: {
                                  line: {
                                    stroke: '#555555'
                                  },
                                  text: {
                                    fill: '#6D7D8A',
                                    fontWeight: 'bold'
                                  }
                                }
                              }
                            }}
                          />
                        </Suspense>
                      ) : (
                        <Box display='flex' alignItems='center' ml='50%'>
                          <Typography>No Data</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
