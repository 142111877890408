/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useMemo, useState, useContext } from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import Radio from '@mui/material/Radio';
import CancelIcon from '@mui/icons-material/Cancel';
import DocumentCountIcon from '../../../assets/svgs/CardsPage/DocumentCountIcon';
import PageCountIcon from '../../../assets/svgs/CardsPage/PageCountIcon';

// component
import ComparisonButtons from './ComparisonButtons';
import CompareDialog from './CompareDialog';

// utils
import mapEntityData from '../utils/mapEntityData';
import trimText from '../../../utils/trimText';
import formatCount from '../../../utils/formatCount';
import truncateCountAboveMillions from '../../../utils/truncateCountAboveMillions';

// styles
import styles from '../../EMAGuidance/styles/DocumentHistory.styles';

// Icons
import PDFPreview from '../../../components/PdfPreview/PDFPreview';
import { FolderIcon } from '../../../assets/svgs/Icons';

import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';
import { getDocumentSourceName } from '../../SearchResults/utils/documentResultsUtils';
import { getGuidanceDocumentsSignedURL } from '../../../api/pages/GuidanceDocuments';

const GridCellDouments: React.FC<any> = ({ data }) => {
  const [title, setTitle] = useState<string>('');
  const [pdfUrl, setPdfUrl] = useState('');
  const [documentsToCompare, setDocumentsToCompare] = useState<Array<any>>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [compareEnabled, setCompareEnabled] = useState<boolean>(false);
  const [openComparisonPopup, setOpenComparisonPopup] = useState<boolean>(false);
  const { dispatch } = useContext(GlobalStore) as any;

  const handleOpenPopup = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClosePopup = () => {
    setOpen(false);
    setDocumentsToCompare([]);
    setCompareEnabled(false);
  };

  const handlePDFOpenClick = useCallback(async (pdfLink: any) => {
    const res: any = await getGuidanceDocumentsSignedURL({ source: data.source, s3_path: pdfLink });
    setPdfUrl(res?.data?.body?.s3_url ?? '');
  }, []);

  //   const handleCompareClick = useCallback(() => {
  //     if (compareEnabled) {
  //       setCompareEnabled(false);
  //       setDocumentsToCompare([]);
  //     } else {
  //       setCompareEnabled(true);
  //       GuidanceDispatch({
  //         type: actions.CLEAR_DOCUMENT_TO_COMPARE
  //       });
  //       GuidanceDispatch({
  //         type: actions.SET_COMPARE_ENABLED,
  //         value: false
  //       });
  //       GuidanceDispatch({
  //         type: actions.SET_SEARCH,
  //         value: {
  //           term: ''
  //         }
  //       });
  //     }
  //   }, [compareEnabled]);

  const handleComparisonCheckbox = useCallback(
    (id: string) => {
      if (documentsToCompare.find((doc: any) => doc.doc_key === id)) {
        const removeDocument = documentsToCompare.filter((doc: any) => doc.doc_key !== id);
        setDocumentsToCompare(removeDocument);
      } else if (
        documentsToCompare?.length === 2 &&
        !documentsToCompare.find((doc: any) => doc.doc_key === id)
      ) {
        documentsToCompare.pop();
        // Check whether doc is current version
        if (data.doc_key === id) {
          const addDocument = [...documentsToCompare, data];
          setDocumentsToCompare(addDocument);
        } else {
          // Check whether doc is present in array of document_history
          const addDocument = [
            ...documentsToCompare,
            data?.document_history.find((doc: any) => doc.doc_key === id)
          ];
          setDocumentsToCompare(addDocument);
        }
      } else {
        // Check whether doc is current version
        // eslint-disable-next-line no-lonely-if
        if (data.doc_key === id) {
          const addDocument = [...documentsToCompare, data];
          setDocumentsToCompare(addDocument);
        } else {
          // Check whether doc is present in array of document_history
          const addDocument = [
            ...documentsToCompare,
            data?.document_history.find((doc: any) => doc.doc_key === id)
          ];
          setDocumentsToCompare(addDocument);
        }
      }
    },
    [documentsToCompare, data]
  );

  const clearComparison = useCallback(() => {
    setDocumentsToCompare([]);
    setOpenComparisonPopup(false);
  }, []);

  const handleComparisonPopup = useCallback((value: boolean) => {
    setOpenComparisonPopup(value);
  }, []);

  const compareItems: Array<any> = useMemo(
    () =>
      (documentsToCompare ?? []).map(doc => ({
        title: trimText(`${doc.doc_title.replace(/ \([\s\S]*?\)/g, '')}`, 35),
        pdfLink: doc.s3_path
      })),
    [documentsToCompare]
  );

  const handleChatRia = ({
    // eslint-disable-next-line no-shadow
    pdfUrl = ''
  }: {
    resultDetails?: any;
    pdfUrl?: string;
  }) => {
    const mappedSourceName = getDocumentSourceName('Guidances');
    dispatch({
      type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM,
      value: 'document'
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: mappedSourceName
    });
    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: {
        blob: pdfUrl,
        url: pdfUrl,
        item: {
          field_regulated_product_field: data?.regulated_product_field ?? '',
          field_docket_number: data?.docket_number ?? '',
          center: data?.center ?? '',
          field_issue_datetime: data?.issue_datetime ?? '',
          title: data?.title ?? ''
        },
        source: mappedSourceName,
        triggerReopenChatRia: false
      }
    });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
    setPdfUrl('');
  };

  return (
    <Box sx={styles.textAlignment}>
      <Box alignItems='center' sx={{ cursor: 'pointer' }}>
        {data && (
          <Stack
            direction='row'
            spacing={0.5}
            alignItems='center'
            sx={styles.linkItem}
            onClick={e => handleOpenPopup(e)}>
            <FolderIcon sx={{ fontSize: 12 }} />
            <Typography variant='subtitle2' sx={styles.pdfCellText}>
              {data?.documents?.length}
            </Typography>
          </Stack>
        )}
      </Box>
      <Dialog
        fullWidth
        maxWidth='lg'
        aria-labelledby='customized-dialog-title'
        open={open}
        sx={styles.dialog}
        onClose={handleClosePopup}>
        <DialogTitle>
          <Grid display='flex' justifyContent='space-between' alignItems='flex-start'>
            <Grid sx={styles.heading} display='flex' justifyContent='center'>
              Documents
            </Grid>
            <Stack
              display='flex'
              flexDirection='row'
              sx={{
                height: '40px',
                padding: '1px 28px',
                borderRadius: '10px',
                bgcolor: 'white.main'
              }}>
              <Stack direction='row' spacing={2}>
                <DocumentCountIcon />
                <Stack>
                  <Typography variant='subtitle2'>Documents</Typography>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Tooltip title={formatCount(data?.documents?.length || 0)}>
                      <Typography variant='subtitle1' fontWeight='bold'>
                        {truncateCountAboveMillions(data?.documents?.length || 0)}
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Stack>
                <PageCountIcon />
                <Stack>
                  <Typography variant='subtitle2'>Pages</Typography>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Tooltip
                      title={formatCount(
                        data?.documents?.reduce((sum: any, item: any) => sum + item.page_count, 0)
                      )}>
                      <Typography variant='subtitle1' fontWeight='bold'>
                        {truncateCountAboveMillions(
                          data?.documents?.reduce((sum: any, item: any) => sum + item.page_count, 0)
                        )}
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            {/* un-comment below code to enable comparison mode */}
            {/* <Box display='flex' flexDirection='row'>
              {data?.document_history?.length !== 0 && (
                <Stack
                  spacing={1}
                  alignItems='center'
                  direction='row'
                  sx={{ cursor: 'pointer', paddingRight: '50px' }}
                  onClick={handleCompareClick}>
                  <Switch checked={compareEnabled} />
                  <Typography
                    id='filters'
                    sx={{ justifySelf: 'flex-end', color: 'gray.lightVariant' }}
                    variant='subtitle1'>
                    Comparison Mode
                  </Typography>
                </Stack>
              )}
            </Box> */}
            <IconButton aria-label='delete' onClick={handleClosePopup} sx={{ fontSize: '30px' }}>
              <CancelIcon fontSize='inherit' />
            </IconButton>
          </Grid>
          <Divider />
        </DialogTitle>
        <DialogContent sx={{ height: '94%' }}>
          <Stack spacing={1}>
            {openComparisonPopup && (
              <CompareDialog
                open={openComparisonPopup}
                onClose={() => handleComparisonPopup(false)}
                compareItems={compareItems}
                onNewCompare={clearComparison}
              />
            )}
            {compareEnabled && (
              <ComparisonButtons
                documentsToCompare={documentsToCompare}
                removeFromComparison={handleComparisonCheckbox}
                openComparisonPopup={handleComparisonPopup}
                clearComparison={clearComparison}
                // fromDocumentHistory
              />
            )}
            <Stack spacing={1}>
              {data && data.documents && (
                <Stack spacing={1} display='flex' flexDirection='row' sx={{ width: '100%' }}>
                  <Box sx={{ width: '15%' }}>
                    <Box
                      sx={{
                        color: 'primary.dark',
                        padding: '13px 10px',
                        marginBottom: 'auto'
                      }}>
                      <Typography
                        sx={{ fontSize: '16px', color: 'black.darkVariant', fontWeight: '600' }}>
                        Current Version
                      </Typography>
                    </Box>
                  </Box>
                  <Stack spacing={1} sx={{ width: '100%' }}>
                    {data &&
                      data?.documents?.map((each: any) => (
                        <Stack
                          key={each.key}
                          display='flex'
                          flexDirection='column'
                          sx={{
                            padding: '12px 10px',
                            borderRadius: 8,
                            backgroundColor: 'primary.background'
                          }}>
                          <Stack direction='row' spacing={1}>
                            {compareEnabled && (
                              <Tooltip
                                title={
                                  documentsToCompare?.length === 2 &&
                                  !documentsToCompare.find((doc: any) => doc.key === data.key)
                                    ? 'Selection limit reached'
                                    : ''
                                }>
                                <Box>
                                  <Radio
                                    disabled={
                                      documentsToCompare?.length === 2 &&
                                      !documentsToCompare.find((doc: any) => doc.key === data.key)
                                    }
                                    checked={
                                      !!documentsToCompare.find(
                                        (doc: any) => doc.doc_key === data.doc_key
                                      )
                                    }
                                    onClick={() => handleComparisonCheckbox(data.doc_key)}
                                  />
                                </Box>
                              </Tooltip>
                            )}
                            <Box>
                              <Box sx={{ padding: '0.2rem', width: '40px', height: '40px' }}>
                                <img
                                  src='https://vivpro-site.s3.amazonaws.com/Help_Page_FAQ/pdf_icon.png'
                                  alt='PDF download'
                                  height='100%'
                                  width='100%'
                                />
                              </Box>
                            </Box>
                            <Stack>
                              <Stack
                                direction='row'
                                alignItems='center'
                                onClick={() => {
                                  setTitle(each?.title?.replace(/ \([\s\S]*?\)/g, ''));
                                  handlePDFOpenClick(each.pdf_s3_url);
                                }}>
                                <Typography sx={styles.title}>{`${each?.title?.replace(
                                  / \([\s\S]*?\)/g,
                                  ''
                                )}`}</Typography>
                              </Stack>
                              {/* <Stack display='flex' flexDirection='row' sx={{ paddingTop: '10px' }}>
                                {Object.entries(mapEntityData(each)).map(([key, value]: any) => {
                                  if (value) {
                                    return (
                                      <Tooltip key={key} title={`${key}: ${value}`}>
                                        <Box display='flex' sx={styles.chips}>
                                          <Typography sx={{ fontSize: '12px' }}>
                                            {value?.length > 70
                                              ? `${value.slice(0, 70)}...`
                                              : value}
                                          </Typography>
                                        </Box>
                                      </Tooltip>
                                    );
                                  }
                                })}
                              </Stack> */}
                            </Stack>
                          </Stack>
                        </Stack>
                      ))}

                    <Box display='flex' flexDirection='column' sx={{ marginTop: '5px' }} />
                  </Stack>
                </Stack>
              )}
            </Stack>
            <Divider />
            <Stack spacing={1}>
              {data?.documents?.length > 0 &&
                data?.documents?.some((each: any) => each.is_archived) && (
                  <Stack spacing={1} display='flex' flexDirection='row' sx={{ width: '100%' }}>
                    <Box sx={{ width: '15%' }}>
                      <Box
                        sx={{
                          color: 'primary.dark',
                          padding: '13px 10px',
                          marginBottom: 'auto'
                        }}>
                        <Typography
                          sx={{ fontSize: '16px', color: 'black.darkVariant', fontWeight: '600' }}>
                          Document History
                        </Typography>
                      </Box>
                    </Box>
                    <Stack sx={{ width: '100%' }}>
                      {data?.documents.map((row: any) => {
                        return (
                          <Stack key={row.key} spacing={1}>
                            <Box
                              display='flex'
                              flexDirection='column'
                              sx={{
                                padding: '12px 10px',
                                borderRadius: 8,
                                backgroundColor: 'primary.background'
                              }}>
                              <Stack direction='row' spacing={1}>
                                {compareEnabled && (
                                  <Tooltip
                                    title={
                                      documentsToCompare?.length === 2 &&
                                      !documentsToCompare.find(
                                        (doc: any) => doc.doc_key === row.doc_key
                                      )
                                        ? 'Selection limit reached'
                                        : ''
                                    }>
                                    <Box>
                                      <Radio
                                        disabled={
                                          documentsToCompare?.length === 2 &&
                                          !documentsToCompare.find(
                                            (doc: any) => doc.key === row.key
                                          )
                                        }
                                        checked={
                                          !!documentsToCompare.find(
                                            (doc: any) => doc.doc_key === row.doc_key
                                          )
                                        }
                                        onClick={() => handleComparisonCheckbox(row.doc_key)}
                                      />
                                    </Box>
                                  </Tooltip>
                                )}
                                <Box>
                                  <Box sx={{ padding: '0.2rem', width: '40px', height: '40px' }}>
                                    <img
                                      src='https://vivpro-site.s3.amazonaws.com/Help_Page_FAQ/pdf_icon.png'
                                      alt='PDF download'
                                      height='100%'
                                      width='100%'
                                    />
                                  </Box>
                                </Box>
                                <Stack>
                                  <Stack
                                    direction='row'
                                    alignItems='center'
                                    onClick={() => {
                                      setTitle(row?.title?.replace(/ \([\s\S]*?\)/g, ''));
                                      handlePDFOpenClick(row.pdf_s3_url);
                                    }}>
                                    <Typography sx={styles.title}>{`${row.title.replace(
                                      / \([\s\S]*?\)/g,
                                      ''
                                    )}`}</Typography>
                                  </Stack>
                                  <Stack
                                    display='flex'
                                    flexDirection='row'
                                    sx={{ paddingTop: '10px' }}>
                                    {Object.entries(mapEntityData(row)).map(([key, value]) => {
                                      if (value) {
                                        return (
                                          <Tooltip key={key} title={`${key}: ${value}`}>
                                            <Box display='flex' sx={styles.chips}>
                                              <Typography sx={{ fontSize: '12px' }}>
                                                {value}
                                              </Typography>
                                            </Box>
                                          </Tooltip>
                                        );
                                      }
                                    })}
                                  </Stack>
                                </Stack>
                              </Stack>
                            </Box>
                            <Box display='flex' flexDirection='column' sx={{ marginTop: '5px' }} />
                          </Stack>
                        );
                      })}
                    </Stack>
                  </Stack>
                )}
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      {pdfUrl && (
        <Box>
          <PDFPreview
            open={Boolean(pdfUrl)}
            pdfUrl={pdfUrl}
            onClose={() => setPdfUrl('')}
            handleChatRia={handleChatRia}
            title={title}
          />
        </Box>
      )}
    </Box>
  );
};

const DocumentHistoryPDF = (params: any) => {
  return <GridCellDouments data={params.row} />;
};

export default DocumentHistoryPDF;
