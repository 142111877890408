import React, { useContext, useEffect } from 'react';

// mui v5
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import themev5 from '../../themev5';

// hoc
import LettersTabs from './hoc/Tabs';

// store
import Store from '../../store';
import Actions from '../../store/actions';

// Provider
import { LettersStateProvider } from '../../store/Letters';

const Letters = () => {
  const { dispatch } = useContext(Store);

  useEffect(() => {
    dispatch({ type: Actions.SET_MODULE, value: 'fda-letters' });
  }, []);

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <LettersStateProvider>
        <LettersTabs />
      </LettersStateProvider>
    </ThemeProvider>
  );
};

export default Letters;
