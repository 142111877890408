import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Drawer from '@mui/material/Drawer';
import Close from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MenuItem, TextField } from '@mui/material';
import { VivproAutocomplete } from '../CustomComponents/Autocomplete';

import Actions from '../../store/actions';
import GlobalStore from '../../store';
import headerStyles from './styles/Header.styles';
import searchBarStyles from '../../pages/Home/styles/SearchBar.styles';

import {
  decryptQuery,
  getApprovalPayload,
  handle505b2AdvancedSearch,
  urlDateToLocalDate
} from '../Header/utils/advanceSearchHelpers';
import SearchIcon from '../../assets/svgs/Icons/SearchIcon';
import categories from './constant';
import { CalendarIcon, KeyDownIcon } from '../../assets/svgs/Icons';
import CustomDatePicker from '../CustomComponents/DatePicker';

const SearchBox5052B2 = ({ modalOpen, setModalOpen }: any) => {
  const history = useHistory();
  const location = useLocation();
  const { dispatch }: any = useContext<any>(GlobalStore);
  const [datePickerOpen, setDatePickerOpen] = useState({
    startDate: false,
    endDate: false
  });
  const [dateRange, setDateRange] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: null,
    endDate: null
  });
  const [selectedCategory, setSelectedCategory] = useState<{
    label: string;
    value: string;
  }>(categories[0]);
  const [searchText, setSearchText] = useState<{
    from: string;
    to: string;
  }>({
    from: '',
    to: ''
  });

  const handleSearch = async () => {
    const url = handle505b2AdvancedSearch(searchText, selectedCategory.value, dateRange, null);
    if (!url) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: 'Please enter value in From and To fields'
        }
      });
      return;
    }
    history.push(url);
    window.location.reload();
  };

  const handleCategoryChange = useCallback(
    (e: any) => {
      const newSelectedCategory = categories.find(cat => cat.value === e.target.value);
      if (newSelectedCategory) {
        setSelectedCategory(newSelectedCategory);
      }
    },
    [categories]
  );

  const handleFromTextChange = (e: any) => {
    setSearchText(prevState => ({
      ...prevState,
      from: e.target.value
    }));
  };
  const handleToTextChange = (e: any) => {
    setSearchText(prevState => ({
      ...prevState,
      to: e.target.value
    }));
  };
  const disableSubmit = isEmpty(searchText.from) || isEmpty(searchText.to);

  useEffect(() => {
    if (location.search) {
      const urlParams = new URLSearchParams(location.search);
      if (urlParams.has('cnfQuery')) {
        if (urlParams.has('startDate')) {
          const startDate: string = urlParams.get('startDate') as string;
          setDateRange(prevState => ({
            ...prevState,
            startDate: urlDateToLocalDate(startDate)
          }));
        }
        if (urlParams.has('endDate')) {
          const endDate: string = urlParams.get('endDate') as string;

          setDateRange(prevState => ({
            ...prevState,
            endDate: urlDateToLocalDate(endDate)
          }));
        }
        const query = decryptQuery(urlParams.get('cnfQuery') || '');
        const { route, formulations: formulation } = getApprovalPayload(query);
        if (route && route.from && route.to) {
          setSearchText({
            from: route.from,
            to: route.to
          });
        }
        if (formulation && formulation.from && formulation.to) {
          setSearchText({
            from: formulation.from,
            to: formulation.to
          });
        }
      }
    }
    // eslint-disable-next-line no-restricted-globals
  }, [location.search]);

  return (
    <Drawer
      sx={{
        '& >.MuiDrawer-paper': {
          height: '60vh',
          borderRadius: '16px',
          paddingX: '24px',
          paddingY: '32px',
          position: 'absolute'
        }
      }}
      anchor='bottom'
      onClose={() => setModalOpen('')}
      open={!!modalOpen}>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'stretch'
          }}>
          <Typography
            sx={{
              flexGrow: 1,
              textAlign: 'center',
              alignSelf: 'center',
              fontWeight: '700',
              fontSize: '20px',
              color: 'gray.800',
              textTransform: 'capitalize'
            }}>
            505b2
          </Typography>
          <Tooltip title='Close'>
            <IconButton
              onClick={() => setModalOpen('')}
              sx={{ position: 'absolute', right: '24px', top: '26px' }}>
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='center'>
          <Divider
            sx={{
              borderWidth: '2px',
              borderStyle: 'solid',
              borderColor: 'gray.400',
              borderRadius: '2px',
              mt: 1,
              width: '64px'
            }}
          />
        </Box>
        <Box display='flex' flexDirection='column' alignItems='start'>
          <form onSubmit={handleSearch}>
            <Box sx={headerStyles.searchField}>
              <Stack direction='row' alignItems='center'>
                <Box>
                  <Select
                    sx={headerStyles.SearchSelect}
                    IconComponent={KeyDownIcon}
                    value={selectedCategory.value}
                    onChange={handleCategoryChange}
                    defaultValue={selectedCategory.value ?? ''}
                    id='category-select'
                    placeholder='Select category'>
                    {categories.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Divider orientation='vertical' flexItem sx={headerStyles.divider} />
                <Box flexGrow={1} id='autoCompleteRow'>
                  <VivproAutocomplete
                    // @ts-ignore
                    label='From'
                    loading={false}
                    noIcon
                    value={searchText.from}
                    onInputChange={handleFromTextChange}
                  />
                </Box>
                <Divider orientation='vertical' flexItem sx={headerStyles.divider} />
                <Box flexGrow={1} id='autoCompleteRow'>
                  <VivproAutocomplete
                    // @ts-ignore
                    label='To'
                    noIcon
                    loading={false}
                    value={searchText.to}
                    onInputChange={handleToTextChange}
                  />
                </Box>
                <Button
                  type='submit'
                  size='small'
                  disabled={disableSubmit}
                  startIcon={<SearchIcon style={{ fontSize: 12 }} />}
                  sx={searchBarStyles.searchButton}>
                  Search
                </Button>
              </Stack>
            </Box>
          </form>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 500,
              color: 'gray.500',
              marginTop: '30px',
              marginBottom: '5px'
            }}>
            Select Approval Dates:
          </Typography>
          <Box sx={headerStyles.searchDateBox}>
            <CalendarIcon sx={headerStyles.advanceSearchDateIcon} />
            <Box
              sx={{
                display: 'flex',
                alignSelf: 'stretch',
                marginY: '-8px'
              }}>
              <Divider orientation='vertical' />
            </Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Tooltip title='Start date'>
                <CustomDatePicker
                  maxDate={dateRange.endDate ? new Date(dateRange.endDate) : new Date()}
                  disableFuture
                  views={['year', 'month', 'day']}
                  value={dateRange.startDate}
                  inputFormat='MMM dd, yyyy'
                  onChange={newVal => {
                    setDateRange({ ...dateRange, startDate: newVal });
                  }}
                  open={datePickerOpen.startDate}
                  closeOnSelect={false}
                  onClose={() => {
                    setDatePickerOpen({ ...datePickerOpen, startDate: false });
                  }}
                  InputProps={{
                    onMouseDown: () => {
                      setDatePickerOpen({ ...datePickerOpen, endDate: false });
                      setDatePickerOpen({ ...datePickerOpen, startDate: true });
                    },
                    onFocus: () => {
                      setDatePickerOpen({ ...datePickerOpen, endDate: false });
                      setDatePickerOpen({ ...datePickerOpen, startDate: true });
                    },
                    onBlur: () => {
                      setDatePickerOpen({ ...datePickerOpen, startDate: false });
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'select date'
                      }}
                      id='start_date'
                      helperText={null}
                      sx={headerStyles.advanceSearchDateInput}
                    />
                  )}
                  components={{
                    OpenPickerIcon: ArrowDropDownIcon
                  }}
                />
              </Tooltip>
              <Divider
                orientation='vertical'
                flexItem
                sx={{ height: '1.125rem', alignSelf: 'center' }}
              />
              <Tooltip title='End date'>
                <CustomDatePicker
                  views={['year', 'month', 'day']}
                  minDate={dateRange.startDate ? new Date(dateRange.startDate) : new Date()}
                  disableFuture
                  inputFormat='MMM dd, yyyy'
                  value={dateRange.endDate}
                  closeOnSelect={false}
                  onChange={newVal => {
                    setDateRange({ ...dateRange, endDate: newVal });
                  }}
                  open={datePickerOpen.endDate}
                  onClose={() => {
                    setDatePickerOpen({ ...datePickerOpen, endDate: false });
                  }}
                  InputProps={{
                    onMouseDown: () => {
                      setDatePickerOpen({ ...datePickerOpen, startDate: false });
                      setDatePickerOpen({ ...datePickerOpen, endDate: true });
                    },
                    onFocus: () => {
                      setDatePickerOpen({ ...datePickerOpen, startDate: false });
                      setDatePickerOpen({ ...datePickerOpen, endDate: true });
                    },
                    onBlur: () => {
                      setDatePickerOpen({ ...datePickerOpen, endDate: false });
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'select date'
                      }}
                      id='end_date'
                      helperText={null}
                      sx={headerStyles.advanceSearchDateInput}
                    />
                  )}
                />
              </Tooltip>
            </LocalizationProvider>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default React.memo(SearchBox5052B2);
