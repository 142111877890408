const iconBase = { width: '16px', height: '16px' };
const iconSmall = { width: '14px', height: '14px' };
const styles = {
  iconGray: {
    ...iconBase,
    color: 'gray.700'
  },
  iconPrimary: {
    ...iconBase,
    color: 'primary.600'
  },
  menuIconGray: {
    ...iconSmall,
    color: 'gray.700'
  },
  menuIconPrimary: {
    ...iconSmall,
    color: 'primary.600'
  },
  menuItemText: { color: 'gray.800', fontWeight: 400 },
  listItemTitle: { py: '0px', my: '0px' },
  menuItemTitle: { color: 'gray.800', fontWeight: '600', marginLeft: '10px' },
  menuItemCheckbox: { padding: 0, marginLeft: '10px' },
  loadingIcon: {
    ml: '10px'
  },
  createProjecIconBG: {
    display: 'flex',
    borderRadius: '50%',
    backgroundColor: 'gray.200',
    width: '18px',
    height: '18px',
    ml: '10px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  menuItemAddIcon: { width: '14px', height: '14px', color: 'grey.700' },
  roundedeMenu: {
    '& .MuiPaper-root': { padding: '10px 0px !important' },
    '& .MuiList-root': { padding: '8px 0 !important' }
  },
  divider: {
    backgroundColor: 'gray.200',
    opacity: 'unset !important',
    borderColor: '#0000001f !important'
  },
  projectMenuList: {
    maxHeight: '200px',
    overflowY: 'auto'
  }
};

export default styles;
