const formatData: any = (str: any) => {
  const result = [];
  let startIndex = 0;

  while (startIndex < str.length) {
    // Find the next colon in the string starting from startIndex
    const colonIndex = str.indexOf(':', startIndex);

    if (colonIndex === -1) {
      // If no more colons are found, push the rest of the string as a segment
      result.push(str.substring(startIndex).trim());
      break;
    }

    // Find the comma before the colon (if it exists)
    let nextCommaBeforeColon = str.lastIndexOf(',', colonIndex);
    if (nextCommaBeforeColon < startIndex) {
      nextCommaBeforeColon = startIndex - 1;
    }

    // Find the next comma after the colon (if it exists)
    const nextCommaAfterColon = str.indexOf(',', colonIndex);
    if (nextCommaAfterColon === -1) {
      // If no more commas are found after the colon, push the rest of the string
      result.push(str.substring(startIndex).trim());
      break;
    }

    // Take the segment from startIndex to nextCommaAfterColon
    result.push(str.substring(startIndex, nextCommaAfterColon).trim());

    // Update startIndex to the character after the next comma
    startIndex = nextCommaAfterColon + 1;
  }

  return result;
};

export default formatData;
