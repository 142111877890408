import isEmpty from 'lodash/isEmpty';

// api
import { generateAccessToken, getMoxoUserDetails, createClientUser } from '../api/services/Moxo';

// access token used for generating embedded component from moxo js sdk
const moxoAccessToken: { accessToken?: string; expiresIn?: number } = {};

const useMoxoToken = () => {
  /**
   * function to refresh the access token
   * @param userEmail
   */
  const refreshToken = async (userEmail: string) => {
    const tokenRes = await generateAccessToken(userEmail);
    if (tokenRes?.status === 200) {
      moxoAccessToken.accessToken = tokenRes.data.body.access_token;
      // set an expiry time for the token
      const tokenExpiryDuration = tokenRes.data.body.expires_in;
      const now = new Date();
      now.setSeconds(now.getSeconds() + tokenExpiryDuration - 10);
      moxoAccessToken.expiresIn = now.getTime();
    }
  };

  /**
   * function to refresh moxo access token for current user
   * @param email
   * @param name
   * @returns
   */
  const createAccessToken = async (userEmail: string) => {
    // check if user exists
    const userRes = await getMoxoUserDetails(userEmail);

    try {
      if (userRes?.status === 404) {
        // user does not exist
        // create a new client user
        const newUserRes = await createClientUser();

        if (newUserRes?.status !== 200) throw new Error('Client user not created successfully');
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    await refreshToken(userEmail);
  };

  /**
   * function to return access token
   * @returns moxo access token
   */
  const accessToken = (userEmail = '') => {
    const currentTimestamp = new Date().getTime();
    if (!isEmpty(moxoAccessToken) && currentTimestamp < (moxoAccessToken?.expiresIn as number)) {
      return moxoAccessToken.accessToken;
    }
    refreshToken(userEmail);
    return moxoAccessToken.accessToken;
  };

  return { createAccessToken, accessToken, refreshToken };
};

export default useMoxoToken;
