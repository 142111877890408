const styles = {
  rootBox: {
    pt: '8px'
  },
  rootList: {
    borderRadius: '16px',
    marginTop: '24px',
    color: 'primary.800',
    py: 0
  },
  navigationMenuItem: {
    color: 'grey.800',
    paddingX: '12px',
    paddingY: '6px',
    borderRadius: '8px',
    minWidth: '175px',
    '& span': {
      color: 'grey.700',
      fontSize: '14px',
      fontWeight: 600
    },
    '& svg': {
      margin: '2px',
      fontSize: '16px'
    },
    '&.Mui-selected': {
      backgroundColor: 'primary.50',
      '& span': {
        color: 'primary.800'
      },
      '& svg': {
        color: 'primary.800'
      }
    },

    '& > .MuiListItemIcon-root': {
      minWidth: 0,
      marginRight: '8px'
    }
  },
  listItemText: { m: '0px' },
  serviceRequestIcon: {
    width: '16px',
    height: '16px'
  },
  divider: {
    width: '175px',
    height: '1px',
    backgound: 'grey.800',
    mt: '24px',
    mb: '24px'
  }
};

export default styles;
