/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
// MATERIAL UI IMPORTS
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

// other components
import BiologicsStyles from '../styles/Biologics.styles';
import Cards from './Cards';
import LineGraph from './LineGraph';
import Percentages from './Percentages';

// store
import { getBLAstats, getBlaStatsSubset } from '../../../api/pages/Biologics';

const BusinessIntelligence = ({ payload = [] }) => {
  const classes = BiologicsStyles();
  const [percentages, setPercentages] = useState();
  const [linegraph, setLineGraph] = useState();
  const [cards, setCards] = useState();

  useEffect(() => {
    async function getData() {
      try {
        let response = {};
        // check if bla stats needs to be generated for specific list of BLAs or the whole biologics
        if (payload?.length) {
          response = await getBlaStatsSubset(payload);
        } else {
          response = await getBLAstats();
        }

        const percentagesData = {
          acceleratedApproval: response.data.Success.business_intelligence.Accelerated_Approval,
          breakthroughDesignation:
            response.data.Success.business_intelligence.Breakthrough_designation,
          fasttrack: response.data.Success.business_intelligence.Fast_track,
          orphanStatus: response.data.Success.business_intelligence.Orphan_status
        };
        setPercentages(percentagesData);
        let approvaltimeGraphData = [];
        response.data.Success.business_intelligence.median_approval_time.forEach(
          ({ year, count }) => {
            const mean = response.data.Success.business_intelligence.approvaltimeGraph.find(
              elem => elem.year === year
            )?.count;
            approvaltimeGraphData.push({
              year,
              count: parseInt(count),
              mean: mean ? parseInt(mean) : 0
            });
          }
        );
        approvaltimeGraphData.sort((a, b) => parseInt(a.year) - parseInt(b.year));
        let approvalyearGraphData = [];
        for (let elem of response.data.Success.business_intelligence.approvalyearGraph) {
          let year = elem.year;
          let count = parseInt(elem.count);
          approvalyearGraphData.push({ count, year });
        }
        approvalyearGraphData.sort((a, b) => parseInt(a.year) - parseInt(b.year));

        const lineGraphData = {
          approvalTimeGraph: approvaltimeGraphData,
          approvalYearGraph: approvalyearGraphData
        };
        setLineGraph(lineGraphData);
        setCards([
          {
            id: 'Applications',
            count: response.data.Success.business_intelligence.bla_count
          },
          {
            id: 'Active Ingredients',
            count: response.data.Success.business_intelligence.activeIngredients
          },
          {
            id: 'Formulations',
            count: response.data.Success.business_intelligence.formulations
          }
        ]);
      } catch (e) {
        console.error(e);
      }
    }

    getData();
  }, []);

  return (
    <Grid container>
      <Grid item lg={2}>
        <Cards data={cards} />
      </Grid>
      <Grid item lg={10}>
        <Grid item lg={12}>
          <LineGraph data={linegraph} />
        </Grid>
        <Grid item lg={12}>
          <Box className={classes.percentageGraphsContainer}>
            <Percentages data={percentages} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BusinessIntelligence;
