// create a list of random colors for the highlighted words
export const HIGHLIGHT_COLOR_LIST = [
  '#FFFF00',
  '#FFD700',
  '#BADEFC',
  '#B4E2DB',
  '#B2EBF2',
  '#FFB6C1',
  '#E6E6FA',
  '#D3D3D3'
];

export default HIGHLIGHT_COLOR_LIST;
