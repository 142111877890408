import { dateFields } from '../constants';

export const getSortOnAxis = (xProperty: string[] | undefined, chartType = 'pie'): string => {
  if (chartType === 'bar') {
    if (xProperty === undefined) {
      return 'y';
    }
    return dateFields.includes(xProperty[0]) ? 'x' : 'y';
  } else {
    return 'value';
  }
};
