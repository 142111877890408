const styles = {
  root: {
    width: '100%',
    height: '55vh',
    display: 'inline-flex',
    flexDirection: 'column'
  },
  upperText: {
    fontFamily: 'Nunito Sans',
    fontWeight: '500',
    fontSize: '12px',
    color: '#30B5A8'
  },
  lowerText: {
    textAlign: 'center',
    fontFamily: 'Nunito Sans',
    fontWeight: '700',
    fontSize: '16px',
    color: '#0B54A1'
  },
  graphContainer: {
    height: '85%',
    width: '100%',
    fontWeight: 'bold',
    fontSize: '12px'
  }
};

export default styles;
