import React from 'react';
import { Box } from '@mui/material';
import CircularAvatar from '../../../pages/SearchResults/components/CircularAvatar';

interface GridRenderCellParams {
  // eslint-disable-next-line react/require-default-props
  value?: any;
}
const DatagridApprovalTracks = (props: GridRenderCellParams = { value: [] }) => {
  const { value } = props;
  return (
    <Box display='flex'>
      <CircularAvatar avatarList={value} onCard={false} />
    </Box>
  );
};

export default DatagridApprovalTracks;
