/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
// muiv5
import Tooltip from '@mui/material/Tooltip';

import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import Typography from '@mui/material/Typography';

import { CT_COLUMN_STYLES, HTA_COLUMN_STYLES, PMR_SURVEILLANCE_STYLES } from './constants';

import styles from '../Datagrid.styles';

interface GridCellExpandProps {
  value: string;

  field: string;
}

const fieldType: { [key: string]: string } = {
  active_ingredients: 'capitalize',
  sponsor_name: 'titlecase',
  trade_name: 'titlecase',
  formulation: 'titlecase',
  route: 'titlecase',
  generic_name: 'titlecase',
  ...HTA_COLUMN_STYLES,
  ...CT_COLUMN_STYLES,
  ...PMR_SURVEILLANCE_STYLES
};

interface CustomGridCellExpandProps extends GridCellExpandProps {
  toolTipText?: string;
}
export const GridCellExpand = React.memo((props: CustomGridCellExpandProps) => {
  const { field } = props;
  let { value } = props;
  const { toolTipText } = props;

  if (fieldType[field] === 'capitalize' || value?.toLowerCase() === 'missing at source') {
    value = capitalize(value);
  } else if (fieldType[field] === 'uppercase') {
    value = value.toUpperCase();
  } else if (fieldType[field] === 'titlecase') {
    value = startCase(toLower(value));
  }

  return (
    <Tooltip title={toolTipText === 'No tool tip text' ? '' : toolTipText || value}>
      <Typography align='center' variant='subtitle2' sx={styles.datagridCellText} noWrap>
        {value || '---'}
      </Typography>
    </Tooltip>
  );
});

const DatagridTooltip = (params: GridRenderCellParams) => {
  let { value } = params;
  const { field } = params;
  const toolTipText = (params.colDef as any).toolTipValueGetter?.(params);
  if (Array.isArray(value)) {
    value = value.join('; ');
  }
  if ((params.colDef as any).formattedValueGetter) {
    value = (params.colDef as any).formattedValueGetter(params);
  }
  return (
    <GridCellExpand
      value={value || ''}
      field={field}
      toolTipText={
        toolTipText === '' && (params.colDef as any).toolTipValueGetter
          ? 'No tool tip text'
          : toolTipText
      }
    />
  );
};

export default DatagridTooltip;
