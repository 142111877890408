import React from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '../Chip';

import styles from './styles';

export interface Option {
  label: string;
  value: string;
  selected?: boolean;
  selectedChipStyle?: any;
}

const FilterSelect: React.FC<any> = ({
  id,
  label,
  options,
  onAddClick,
  onRemoveSelected,
  selectedChipStyle = {},
  disabled = false
}: any) => {
  return (
    <Stack pr={2}>
      <Typography variant='subtitle1'>{label}</Typography>
      {/* show selected options */}
      {options
        ?.filter((opt: Option) => opt.selected)
        ?.map((opt: Option) => {
          return (
            <Chip
              key={opt.value}
              title={opt.value}
              disabled={disabled}
              style={selectedChipStyle}
              onClose={() => onRemoveSelected(opt)}
            />
          );
        })}
      <Button id='popup' disabled={disabled} onClick={e => onAddClick(e, id)} sx={styles.btn}>
        +Add
      </Button>
    </Stack>
  );
};

export default React.memo(FilterSelect);
