const styles = {
  sourceSelectTop: {
    background: '#33B187',
    color: 'white.main',
    borderRadius: '10px 10px 0px 0px',
    marginBottom: 'auto',
    fontSize: '1.5vh',
    margin: '0 5vw',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    border: '1.5px transparent solid',
    borderBottom: 'none'
  }
};

export default styles;
