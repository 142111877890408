import makeRequest from '../client';

/**
 *
 * Function gets all data for guidance module
 */
export const getCanadaGuidanceDocs = (filters: any, limit: number, offset: number) =>
  makeRequest(`/canada-guidance?limit=${limit}&offset=${offset}`, 'POST', filters);

export const getCanadaGuidanceInsights = (filters: any) =>
  makeRequest('/canada-guidance/insights', 'POST', filters);

export const getCanadaGuidanceDocumentsSignedURL = (payload: any) =>
  makeRequest('/canada-guidance/resource', 'POST', payload);

export const getAllCanadaGuidanceDocuments = (filters: any) =>
  makeRequest('/canada-guidance', 'POST', filters);
