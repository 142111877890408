const styles = {
  paperProps: {
    '& .MuiPickersDay-root': {
      '&.Mui-selected': {
        backgroundColor: 'primary.700',
        color: 'gray.0'
      }
    },
    '& .MuiMonthPicker-root': {
      '& .Mui-selected': {
        backgroundColor: 'primary.700',
        color: 'gray.0'
      }
    },
    '& .MuiYearPicker-root': {
      '& .Mui-selected': {
        backgroundColor: 'primary.700',
        color: 'gray.0'
      }
    }
  },
  dialogProps: {
    color: '#ff0000',
    '& .MuiDialogActions-root': {
      color: '#ff0000'
    }
  }
};

export default styles;
