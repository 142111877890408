import { createSvgIcon } from '@mui/material';

const SendIcon = createSvgIcon(
  <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='8.55078' cy='8.9248' r='8' fill='#0076D6' />
    <g clipPath='url(#clip0_333_1505)'>
      <path
        d='M12.2888 9.74076C12.8004 9.50096 12.8004 8.77331 12.2888 8.5335L6.03889 5.60385C5.52592 5.36339 4.96579 5.83187 5.11179 6.37926L5.72871 8.69273L8.34532 8.69273C8.59077 8.69274 8.78976 8.89173 8.78976 9.13717C8.78973 9.38265 8.59077 9.58161 8.34532 9.58161L5.72867 9.58163L5.11177 11.895C4.96579 12.4424 5.52595 12.9109 6.0389 12.6704L12.2888 9.74076Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_333_1505'>
        <rect width='10.6667' height='10.6667' fill='white' transform='translate(3.2168 3.5918)' />
      </clipPath>
    </defs>
  </svg>,
  'SendIcon'
);

export default SendIcon;
