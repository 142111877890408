import capitalize from 'lodash/capitalize';

const getAdvanceSearchName = term => {
  const dict = term.split(':');
  let result = `${capitalize(
    dict[0].replaceAll('~', '')?.replaceAll('_', ' ') ?? 'NA'
  )}: ${capitalize(dict[1])}`;
  if (term.includes('~')) {
    return 'NOT '.concat(result);
  }
  return result;
};

export default getAdvanceSearchName;
