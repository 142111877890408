import React, { memo, useContext, useState } from 'react';
import { isEmpty } from 'lodash';
import { Button } from '@mui/material';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import ReportWidgets from '../../../components/Report/Widgets';
import GlobalStore from '../../../store';
import ResultsStore from '../../../store/SearchResults';
import RESULT_VIEW_TYPES from '../components/constants';
import AddToReportIcon from '../../../assets/svgs/Report/AddToReportIcon';
import styles from '../styles/Buttons.styles';

interface AddToReportButtonProps {
  source: any;
  module: string;
  search: string;
  searchType: string;
  sourceType: string;
  disableSelectionSource: boolean;
}

const AddToReportButton = ({
  source,
  module,
  search,
  searchType,
  sourceType,
  disableSelectionSource
}: AddToReportButtonProps) => {
  const { state } = useContext(GlobalStore) as any;
  const { resultsState } = useContext(ResultsStore);

  const [reportChoicesOpenAnchorElement, setReportChoiceAnchorElement] = useState<
    HTMLElement | any
  >(null);

  const searchMetaData = {
    ...state.searchDetails,
    searchType,
    source: source[0],
    search,
    module,
    href: window.location.href
  };

  const getSearchId = () => {
    let searchId = '';
    if (resultsState.viewType === RESULT_VIEW_TYPES.APPLICATION) {
      searchId = resultsState.searchIds.applicationSearchId;
    }
    if (resultsState.viewType === RESULT_VIEW_TYPES.CT) {
      searchId = resultsState.searchIds.ctSearchId;
    }
    if (resultsState.viewType === RESULT_VIEW_TYPES.DOCUMENT) {
      searchId = resultsState.searchIds.documentSearchId;
    }

    return searchId;
  };
  if (
    (isEmpty(resultsState?.applicationResults) && isEmpty(resultsState?.ctResults)) ||
    isEmpty(source)
  ) {
    return null;
  }
  return (
    <>
      <Button
        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
          setReportChoiceAnchorElement(event.currentTarget)
        }
        startIcon={<AddToReportIcon sx={styles.primaryButtonIcon} />}
        sx={styles.primaryButton}>
        Create Chart
      </Button>

      <Popover
        open={!!reportChoicesOpenAnchorElement}
        anchorEl={reportChoicesOpenAnchorElement}
        onClose={() => setReportChoiceAnchorElement(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{
          '& .MuiPopover-paper': {
            borderRadius: '16px !important',
            backgroundColor: 'none'
          }
        }}>
        <Box>
          <ReportWidgets
            widgets={['pie-widget', 'bar-widget', 'table-widget']}
            title=''
            dataSource={{
              source: source[0],
              search,
              module,
              searchType,
              searchId: getSearchId(),
              sourceType,
              disableSelectionSource,
              sourceList: source
            }}
            pathMetaData={searchMetaData}
            setReportWidgetClose={setReportChoiceAnchorElement}
          />
        </Box>
      </Popover>
    </>
  );
};

export default memo(AddToReportButton);
