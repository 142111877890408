export const barChartStyles = {
  padding: 0.4,
  labelTextColor: '#ffffff',
  axisText: '#A3A3A3',
  axisBottom: {
    tickRotation: 90
  },
  theme: {
    axis: {
      fontSize: '14px',
      tickColor: '#000',
      ticks: {
        line: {
          stroke: '#555555'
        },
        text: {
          fill: '#1FBDCA',
          fontWeight: 'bold',
          fontSize: '12px',
          textTransform: 'uppercase'
        }
      }
    }
  }
};

export const legend = [
  {
    anchor: 'bottom',
    direction: 'column',
    justify: false,
    translateX: 200,
    translateY: 20,
    itemsSpacing: 0,
    itemWidth: 42,
    itemHeight: 18,
    itemTextColor: '#999',
    itemDirection: 'left-to-right',
    itemOpacity: 1,
    symbolSize: 9,
    symbolShape: 'circle',
    effects: [
      {
        on: 'hover',
        style: {
          itemTextColor: '#000'
        }
      }
    ]
  }
];

export const pieChartStyles = {
  pieLabel: true,
  pieLegend: true,
  innerRadius: 0.1,
  enableLabels: 'inchart',
  margin: { top: 20, right: 20, bottom: 20, left: 20 },
  legends: [
    {
      anchor: 'bottom',
      direction: 'row',
      justify: false,
      translateX: 0,
      translateY: 20,
      itemsSpacing: 0,
      itemWidth: 42,
      itemHeight: 18,
      itemTextColor: '#999',
      itemDirection: 'left-to-right',
      itemOpacity: 1,
      symbolSize: 9,
      symbolShape: 'circle',
      effects: [
        {
          on: 'hover',
          style: {
            itemTextColor: '#000'
          }
        }
      ]
    }
  ]
};

export const REPORT_QUERIES = [
  {
    id: 'us_result_approval_by_year',
    title: {
      title: 'Approval(s) by Year',
      show: true
    },
    graph: 'BAR',
    query: {
      'x-axis': ['first_approval_year'],
      agg_function: 'count',
      sort_on_axis: 'x'
    },
    graphStyle: {
      colors: ['#12815E'],
      axisLeft: {
        tickRotation: 0
      }
    }
  },
  {
    id: 'us_result_approval_time_by_year',
    title: {
      title: 'Median Approval Time, Days from Filing',
      show: true
    },
    graph: 'BAR',
    query: {
      'x-axis': ['first_approval_year'],
      'y-axis': ['approval_time'],
      agg_function: 'median',
      sort_on_axis: 'x'
    },
    graphStyle: {
      colors: ['#6868FA'],
      axisLeft: {
        tickRotation: 0
      },
      enableLabel: false
    }
  },
  {
    id: 'accelerated_approval_percentage',
    title: {
      title: 'Accelerated Approvals',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['appl_num'],
      agg_function: 'percentage'
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'breakthrough_designation_percentage',
    title: {
      title: 'Breakthrough Designation',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['sponsor_name'],
      agg_function: 'percentage'
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'fast_track_percentage',
    title: {
      title: 'Fast Track',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['approval_pathway'],
      agg_function: 'percentage'
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'orphan_designation_percentage',
    title: {
      title: 'Orphan Designation',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['appl_type'],
      agg_function: 'percentage'
    },
    graphStyle: pieChartStyles
  }
];

export const REPORT_QUERIES_US_RESULTS = [
  {
    id: 'us_result_approval_by_year',
    title: {
      title: 'Approval(s) by Year',
      show: true
    },
    graph: 'BAR',
    query: {
      'x-axis': ['approval_year'],
      agg_function: 'count',
      sort_on_axis: 'x'
    },
    graphStyle: {
      colors: ['#12815E'],
      axisLeft: {
        tickRotation: 0
      },
      axisBottom: {
        tickRotation: 80
      }
    }
  },
  {
    id: 'us_result_approval_time_by_year',
    title: {
      title: 'Median Approval Time, Days from Filing',
      show: true
    },
    graph: 'BAR',
    query: {
      'x-axis': ['approval_year'],
      'y-axis': ['approval_time'],
      agg_function: 'median',
      sort_on_axis: 'x'
    },
    graphStyle: {
      colors: ['#6868FA'],
      axisLeft: {
        tickRotation: 0
      },
      axisBottom: {
        tickRotation: 80
      },
      enableLabel: false
    }
  },
  {
    id: 'accelerated_approval_percentage',
    title: {
      title: 'Accelerated Approvals',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['accelerated_approval'],
      agg_function: 'percentage'
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'breakthrough_designation_percentage',
    title: {
      title: 'Breakthrough Designation',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['breakthrough_status'],
      agg_function: 'percentage'
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'fast_track_percentage',
    title: {
      title: 'Fast Track',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['fast_track'],
      agg_function: 'percentage'
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'orphan_designation_percentage',
    title: {
      title: 'Orphan Designation',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['orphan_status'],
      agg_function: 'percentage'
    },
    graphStyle: pieChartStyles
  }
];
