import React, { createContext, useReducer } from 'react';
import reducers from './reducers';

type Props = {
  children: React.ReactNode;
};

export interface AdcommContextInterface {
  meeting_id: any;
  selected_meeting_doc_count: string | number;
  selected_meeting_page_count: string | number;
}

const initialState = {
  meeting_id: '',
  selected_meeting_doc_count: 0,
  selected_meeting_page_count: 0
};

const AdcommStore = createContext<{
  AdcommState: AdcommContextInterface;
  AdcommDispatch: React.Dispatch<any>;
}>({ AdcommState: initialState, AdcommDispatch: () => null });
const { Provider } = AdcommStore;

export const AdcommStateProvider = ({ children }: Props) => {
  const [AdcommState, AdcommDispatch] = useReducer(reducers, initialState);
  return <Provider value={{ AdcommState, AdcommDispatch }}>{children}</Provider>;
};

export default AdcommStore;
