// mui v5
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import themev5 from '../../themev5';

import Page from './Page';
import useUpdateModule from '../../hooks/useUpdateModule';

import { OrphanDesignationsStateProvider } from '../../store/OrphanDesignations';

const OrphanDesignations = () => {
  useUpdateModule('orphanDesignations');

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <OrphanDesignationsStateProvider>
        <Page />
      </OrphanDesignationsStateProvider>
    </ThemeProvider>
  );
};

export default OrphanDesignations;
