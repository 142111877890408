/* eslint-disable no-console */
import { useEffect, useContext, useCallback, useState } from 'react';

import GlobalStore from '../store';
import GlobalActions from '../store/actions';
import AuthContext from '../store/Auth/AuthProvider';

import { getNotificationPreference, modifyNotificationPreference } from '../api/services/Moxo';

import useMoxoToken from './useMoxoToken';

const useMoxoSdkServices = () => {
  const { mepsdk } = window as any;
  const { dispatch } = useContext(GlobalStore) as any;
  const { currentUser } = useContext(AuthContext) as any;
  const { accessToken, createAccessToken } = useMoxoToken();
  const [error, setError] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [notificationPreference, setNotificationPreference] = useState('');

  const setupSdk = async () => {
    try {
      setError(false);
      let currentAccessToken = accessToken(currentUser?.sub || null);
      if (!currentAccessToken) {
        await createAccessToken(currentUser?.sub);
        currentAccessToken = accessToken(currentUser?.sub || null);
      }

      if (!currentAccessToken) {
        setError(true);
        throw new Error('Access token is not valid');
      }
      // setup config for using moxo embedded components
      await mepsdk.setup({
        baseDomain: process.env.REACT_APP_MOXO_API_URL,
        deployDirectory: 'web',
        accessToken: currentAccessToken
      });
      await mepsdk.subscribeUnreadCount(async (count: any) => {
        if (!Number.isNaN(count)) {
          await dispatch({ type: GlobalActions.SET_UNREAD_MESSAGES, value: count });
        }
        if (count > 0) {
          document.title = `(${count}) New message`;
        } else {
          document.title = 'Viv-RIA';
        }
      });
      mepsdk
        .linkUserWithAccessToken(currentAccessToken)
        .then()
        .catch((err: any) => {
          if (err === 'Not logged in') {
            // eslint-disable-next-line no-console
            console.error(`${err} - Access token needs to be refreshed`);
            accessToken(currentUser?.sub);
            window.location.reload();
          }
        });
      setInitialized(true);
    } catch (err) {
      setError(true);
      setInitialized(false);
      // eslint-disable-next-line no-console
      console.error('Error setting up mepsdk', err);
    }
  };

  useEffect(() => {
    setupSdk();
  }, []);

  /**
   * function to enable the chat window component from moxo to a given DOM id
   */
  const initializeMoxoChatWindow = useCallback(async (domElementId: string) => {
    try {
      if (!initialized) {
        await setupSdk();
      }
      mepsdk.setConfig({
        disableWhiteboard: false,
        disableDownload: false,
        disableChangeChatName: false,
        hideInactiveRelationChat: false,
        disableEmoji: false,
        hideChatNotificationsSetting: false,
        disableUserProfile: true,
        fontFileLink: 'https://vivpro-site.s3.amazonaws.com/moxo-utils/moxo.css',
        contentEditInterval: -1
      });

      mepsdk.showMEPClientDashboard(domElementId);
    } catch (err) {
      setError(true);
      // eslint-disable-next-line no-console
      console.error('Error initializing collaborate', err);
    }
  }, []);

  /**
   * function to enable the service requests component from moxo to a given DOM id
   */
  const initializeServiceRequests = useCallback(async (domElementId: string) => {
    try {
      if (!initialized) {
        await setupSdk();
      }
      mepsdk.showRequests(domElementId);
    } catch (err) {
      setError(true);
      // eslint-disable-next-line no-console
      console.error('Error initializing service requests', err);
    }
  }, []);

  /**
   * Modifies the notification preferences for a user.
   *
   * This function sends a request to update the user's default notification settings
   * for collaborate based on the provided value. If the update is successful, it updates the
   * local state to reflect the new preference.
   *
   * @param value The new notification setting value ('all', 'mentions', or 'nothing').
   */
  const modifyNotification = async (value: string) => {
    try {
      const response = await modifyNotificationPreference({ default_notification: value });
      if (response.status !== 200) {
        // Throw an error if the response status is not 200
        throw new Error(`Failed to modify notification preference`);
      }
      setNotificationPreference(value); // Update local state if successful
    } catch (e: any) {
      console.error(`Failed to modify notification preference`, e);
      dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: e.message }
      });
    }
  };

  /**
   * Fetches the current notification preferences for a user in collaborate.
   *
   * This function requests the current user's notification settings and updates
   * the local state with these settings if the fetch is successful.
   */
  const fetchNotificationPreference = async () => {
    try {
      const response = await getNotificationPreference();
      if (response.status !== 200) {
        // Throw an error if the response status is not 200
        throw new Error(`Failed to fetch notification preference`);
      }
      setNotificationPreference(response.data.body.default_notification); // Update local state with fetched value
    } catch (e: any) {
      console.error('Error fetching notification preference:', error);
      dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: e.message }
      });
    }
  };

  return {
    initializeMoxoChatWindow,
    initializeServiceRequests,
    error,
    setupSdk,
    initialized,
    modifyNotification,
    fetchNotificationPreference,
    notificationPreference
  };
};

export default useMoxoSdkServices;
