import React from 'react';

const Logo = () => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xlink='http://www.w3.org/1999/xlink'
      width='90'
      height='90'
      // width="37.612" height="60"
      x='0px'
      y='0px'
      viewBox='0 0 1000 1000'
      style={{ enableBackground: 'new 0 0 1000 1000;' }}
      space='preserve'>
      <style type='text/css'>
        {
          '\
        .st0{\
          fill:url(#SVGID_1_);\
        }\
        .st1{\
          fill:#FFFFFF;\
        }\
      '
        }
      </style>
      <g>
        <g>
          <linearGradient
            id='SVGID_1_'
            gradientUnits='userSpaceOnUse'
            x1='280.5868'
            y1='501.2192'
            x2='738.0239'
            y2='501.2192'>
            <stop offset='1.060230e-06' style={{ stopColor: '#29ABE2' }} />
            <stop offset='1' style={{ stopColor: '#39B54A' }} />
          </linearGradient>
          <path
            class='st0'
            d='M640.8,710.9c-4.5,0-8.3-3.3-11.1-6.2c-16.9-16-34-31.8-51-47.7c-3.5-3.3-7.7-5.7-12.4-7
			c-33.3-9.9-69.7-22-91.9-50.3c-7.8-11-6.8-17.8,2.8-27.4c21.7-21.7,43.5-43.5,65.2-65.2c5.8-5.8,9.4-12.4,10.2-20.9
			c1.1-12.7,15.3-25.2,27.7-25.1c13.1,0.1,27.7,13.3,29.1,26.5c1.1,10.1-5.1,27.2-21.4,30.3c-11.9,2.2-21.3,7.9-29.4,16.5
			c-13.5,14.4-27.5,28.4-40.9,42.9c-8,8.6-8.2,10.5,1.9,16.2c38,22.9,89,44.7,128.3,12.4c13.6-9.7,36.5-27.4,50.1-56.7
			c22.3-56.4-5.7-125.2-74.1-121.6c-13.4,1.2-21.3-20-10-26.2c10.7-5,6.4-12.1,3.5-17.9c-8.5-17.2-19.4-33-36.3-43
			c-37.7-22.5-90.7-18-120.6,15c-16.7,16.7-32.7,45.6-17.9,68.4c9.8,16.1,0.1,38.7-18.3,42.1c-23,3.8-42.6-20.7-32.4-39.3
			c6-12.7,9.6-8.2,14.4-36.1c1.1-6.4-2.3-10.8-8.4-10c-93,10.9-117.7,138.4-32.4,179.5c7.3,3,12.7,0.2,17.1-4.2
			c47.2-46.7,94.3-93.4,140.1-141.4c4.3-4.3,5.1-9.8,6-14.9c3.2-17.9,14.9-24.1,27.5-25.6c11.7-1.3,27.6,13.3,29.6,25.8
			c2.4,14.4-10.7,30-28,32.5c-6.1,0.9-10.9,3.3-15.2,7.4c-49.9,47-97.7,96.2-145.4,145.5c-16.8,17.3-18.2,16.4-39.6,6.9
			c-42.3-18.9-67.5-52.2-75.3-98.2c-13.8-74.5,51.8-147.8,126.8-144.9c10.4,0,17.2-2.5,24-9.9c17.6-19.2,39.8-33.6,64.8-40.8
			c18.7-6.2,35-9.1,55-4.4c49.5,7.6,86.8,45.9,100.6,92.7c2.1,6.9,5.1,10.7,12,13.1c47.9,14.4,74.5,62.1,72.1,109.8
			c4.2,64.8-40.7,112.3-91.3,135.7c-7.6,3-12.7,5.7-8.6,10.6c9.4,11,19.2,21.6,29.2,32l8.1,8.3c5.4,5.5,1.5,14.8-6.3,14.8
			L640.8,710.9z'
          />
          <path
            class='st1'
            d='M557.8,413.3c-6.7-0.2-10.8-4.6-10.6-11.3c0.3-6.7,4.9-11,11.5-10.4c6.6,0.6,10.1,4.6,10.4,11
			C569.3,408.9,564.3,413.4,557.8,413.3z'
          />
          <path
            class='st1'
            d='M419.1,427.9c6,0.7,9,5.1,9.6,11.1c0.6,6.4-4.5,10.7-11.3,10.5c-7-0.2-9.9-4.4-10.2-10.8
			C407,432.3,411.9,427.8,419.1,427.9z'
          />
          <path
            class='st1'
            d='M582,478.8c6.1-0.2,11.2,4.7,11,10.9c-0.3,6.4-3.6,10.4-10.3,10.5c-6.6,0.1-10.4-3.2-10.7-9.9
			C571.5,483.4,575.4,479,582,478.8z'
          />
        </g>
      </g>
    </svg>
  );
};

export default Logo;
