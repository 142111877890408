import { createSvgIcon } from '@mui/material';

const MagicWand = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17' fill='none'>
    <g clipPath='url(#clip0_10168_15137)'>
      <path
        d='M9.88742 4.24069L1.10009 13.0294C0.739033 13.4165 0.542382 13.9287 0.551578 14.458C0.560775 14.9873 0.775101 15.4924 1.14939 15.8667C1.52367 16.2411 2.02868 16.4556 2.55798 16.4649C3.08728 16.4742 3.59952 16.2777 3.98675 15.9167L12.7761 7.12936L9.88742 4.24069ZM10.8908 7.12936L9.16675 8.85336L8.16675 7.85003L9.89075 6.12603L10.8908 7.12936ZM3.04409 14.9754C2.90932 15.1038 2.73027 15.1755 2.54409 15.1755C2.3579 15.1755 2.17886 15.1038 2.04409 14.9754C1.9117 14.8426 1.83735 14.6628 1.83735 14.4754C1.83735 14.2879 1.9117 14.1081 2.04409 13.9754L7.22342 8.79536L8.22675 9.79869L3.04409 14.9754ZM14.7221 10.2687L16.5001 11.158L14.7221 12.05L13.8334 13.8247L12.9448 12.05L11.1668 11.158L12.9448 10.2687L13.8334 8.49136L14.7221 10.2687ZM4.94475 4.07203L3.16675 3.18336L4.94475 2.29469L5.83342 0.516693L6.72209 2.29469L8.50009 3.18336L6.72209 4.07203L5.83342 5.85003L4.94475 4.07203ZM13.3888 3.62803L11.8334 2.85003L13.3888 2.07203L14.1668 0.516693L14.9448 2.07203L16.5001 2.85003L14.9448 3.62803L14.1668 5.18336L13.3888 3.62803Z'
        fill='#0D917D'
      />
    </g>
    <defs>
      <clipPath id='clip0_10168_15137'>
        <rect width='16' height='16' fill='white' transform='translate(0.5 0.516693)' />
      </clipPath>
    </defs>
  </svg>,
  'MagicWand'
);

export default MagicWand;
