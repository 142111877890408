import actions from './actions';
import { CanadaConsultationContextInterface } from '.';

interface ActionProps {
  type: string;
  value: any;
}

const reducers = (state: CanadaConsultationContextInterface, action: ActionProps) => {
  switch (action.type) {
    case actions.SET_CANADA_CONSULTATION_TABLE: {
      return {
        ...state,
        tableData: action.value
      };
    }
    case actions.SET_CANADA_CONSULTATION_STATS: {
      return {
        ...state,
        canadaConsultationStats: action.value
      };
    }
    case actions.SET_CANADA_CONSULTATION_ERRORS: {
      return {
        ...state,
        canadaConsultationErrors: action.value
      };
    }
    case actions.SET_DONUT_CHARTS: {
      return {
        ...state,
        donutCharts: action.value
      };
    }
    case actions.SET_ISSUE_DATE: {
      return {
        ...state,
        issueDate: action.value
      };
    }
    case actions.SET_FILTERS: {
      return {
        ...state,
        filters: action.value
      };
    }
    case actions.SET_AVAILABLE_FILTERS: {
      return {
        ...state,
        availableFilters: action.value
      };
    }
    case actions.SET_LOADING: {
      return {
        ...state,
        loading: action.value
      };
    }
    case actions.SET_IS_FILTERS_APPLIED: {
      return {
        ...state,
        isFiltersApplied: action.value
      };
    }
    case actions.SET_SEARCH: {
      return {
        ...state,
        search: action.value
      };
    }
    case actions.RESET_SEARCH: {
      return {
        ...state,
        search: action.value
      };
    }
    case actions.SET_DOCUMENT_TO_COMPARE: {
      return {
        ...state,
        documentsToCompare: [...state.documentsToCompare, action.value]
      };
    }
    case actions.REMOVE_DOCUMENT_TO_COMPARE: {
      return {
        ...state,
        // eslint-disable-next-line no-underscore-dangle
        documentsToCompare: [...state.documentsToCompare].filter(doc => doc._id !== action.value)
      };
    }
    case actions.CLEAR_DOCUMENT_TO_COMPARE: {
      return {
        ...state,
        documentsToCompare: []
      };
    }
    case actions.SET_COMPARE_ENABLED: {
      return {
        ...state,
        compareEnabled: action.value
      };
    }
    case actions.UPDATE_S3_URL: {
      // eslint-disable-next-line camelcase
      const { _id, s3_url } = action.value;
      return {
        ...state,
        documentsToCompare: state.documentsToCompare.map(doc =>
          // eslint-disable-next-line camelcase, no-underscore-dangle
          doc._id === _id ? { ...doc, s3_url } : doc
        )
      };
    }
    case actions.SET_COMPARE_POPUP_OPEN: {
      return {
        ...state,
        comparePopupOpen: action.value
      };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
