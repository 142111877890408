import Box from '@mui/material/Box';
import React, { useContext, useMemo, useState } from 'react';

import { Divider, Stack, Typography } from '@mui/material';

import _ from 'lodash';
import DropDownButton from './DropDownButton';
import styles from '../styles/ApplicationCard.styles';
import Stats from './Stats';
import SourceDropDown from '../../Home/components/SourceDropDown';
import { getCTSourceDropdown } from '../../Home/utils';
import { SOURCE_DROPDOWN_TYPES } from '../../Home/const';
import SubscriptionActions from '../../../components/Subscription/SubscriptionActions';
import ApplicationsReviewMenu from './ApplicationsReviewMenu';
import FavoriteAndProjectActions from '../../../components/FavoritesAndProjectsAction/FavoriteAndProjectActions';
import ResultsStore from '../../../store/SearchResults';
import ResultActions from '../../../store/SearchResults/actions';
import ShareButton from '../Buttons/ShareButton';
import AdvancedSearch from '../../Home/components/AdvancedSearch';
import Synonyms from '../../ResultsPage/components/Synonyms';
import ClinicalTrialsDownloadButton from '../Buttons/ClinicalTrialsDownloadButton';

const ClinicalTrialsHeader = ({
  sortOptions,
  onSortOptionChange,
  searchType,
  selectedSources,
  handleSourceChange,
  entityCategory,
  entityCategoryOptions,
  applyCategoryEntity,
  entityValue,
  entityTextOptions,
  applyEntityText,
  makeSearch,
  totalCategory
}: any) => {
  const [sourceDropDownAnchorEl, setSourceDropDownAnchorEl] = useState<null | HTMLElement>(null);
  const { resultsState, resultsDispatch } = useContext(ResultsStore);

  const ctSourceDropdown = useMemo(() => getCTSourceDropdown(), []);

  return (
    <Stack pt={0.75} pb={2.5} mx={2}>
      <Stack direction='row' borderBottom='1px solid rgba(0, 0, 0, 0.05)'>
        {searchType === 'advanced' && (
          <Stack direction='row' justifySelf='start' alignItems='center'>
            <Typography variant='body1' sx={{ fontWeight: 700, fontSize: 14, color: 'gray.800' }}>
              Showing results for
            </Typography>
            <AdvancedSearch selectedSources={selectedSources} type='text' />
          </Stack>
        )}
        {searchType !== 'advanced' && (
          <Box justifySelf='start' display='flex' alignItems='center'>
            <Box fontSize={14} fontWeight={700}>
              Showing results for
            </Box>
            <Stack direction='row' alignItems='center'>
              <SourceDropDown
                selectedSources={selectedSources}
                handleChange={handleSourceChange}
                options={ctSourceDropdown}
                anchorEl={sourceDropDownAnchorEl}
                setAnchorEl={setSourceDropDownAnchorEl}
                type={SOURCE_DROPDOWN_TYPES.CT_VIEW}
                miniminumOneSelected
                showApplyButton
                makeSearch={makeSearch}
              />
            </Stack>

            <Divider orientation='vertical' sx={styles.divider} />
            <DropDownButton
              label={
                entityCategory ||
                `${resultsState?.entities?.category_label} (${totalCategory})` ||
                'Category'
              }
              options={entityCategoryOptions}
              onOptionSelect={applyCategoryEntity}
              tooltip='Switch category'
            />

            <Divider orientation='vertical' sx={styles.divider} />
            <DropDownButton
              label={
                _.startCase(entityValue)?.replace(/_/g, '') ||
                _.startCase(resultsState?.entities?.search_term)?.replace(/_/g, '') ||
                'Entity Text'
              }
              options={entityTextOptions}
              onOptionSelect={applyEntityText}
              tooltip='Switch query'
            />
          </Box>
        )}
        <Synonyms />
        <Box display='flex' ml='auto' alignItems='center'>
          <ApplicationsReviewMenu
            sortOptions={sortOptions}
            onSortOptionChange={onSortOptionChange}
            showGroupBy={false}
          />
          <Divider orientation='vertical' sx={styles.verticalDivider} />
          <SubscriptionActions
            isButton
            isSubscribed={resultsState.isSubscribed}
            invokedBy={searchType === 'advanced' ? 'advanced_search' : 'quick_search'}
            id={resultsState?.searchIds?.ctSearchId}
            setSubscriptionInHook={({ isSubscribed }: { isSubscribed: boolean }) => {
              resultsDispatch({ type: ResultActions.SET_IS_SUBSCRIBED, value: isSubscribed });
            }}
          />
          <FavoriteAndProjectActions
            invokedBy='applicationSearch'
            source=''
            id={resultsState?.searchIds?.ctSearchId}
            isButton
            isFavorite={resultsState?.isFavorite ?? false}
            inProjects={resultsState?.inProjects ?? []}
            setFavoriteInHook={({ isFavorite }: { isFavorite: boolean }) => {
              resultsDispatch({ type: ResultActions.SET_IS_FAVORITE, value: isFavorite });
            }}
            setInProjectInHook={({ project }: { project: any }) => {
              let projects: any = [];
              if (project?.inProject) {
                projects = resultsState?.inProjects.filter(
                  (p: any) => (p?.project_id ?? p?.id) !== (project?.project_id ?? project?.id)
                );
              } else {
                projects = [
                  ...(resultsState?.inProjects ?? []),
                  {
                    id: project?.project_id ?? project?.id,
                    name: project?.name
                  }
                ];
              }
              resultsDispatch({ type: ResultActions.SET_IN_PROJECTS, value: projects });
            }}
          />
          <ClinicalTrialsDownloadButton />
          {/* TODO: Un-comment once Approval History download feature is available */}
          {/* <ApplicationsDownloadButton /> */}
          <ShareButton
            searchType={resultsState.decryptedPayload?.searchType ?? ''}
            search={resultsState.decryptedPayload?.query}
          />
        </Box>
      </Stack>
      <Box display='flex' pt={1}>
        <Stats
          stats={[
            {
              title: 'Total Trials',
              value: resultsState?.ctStats?.totalTrials
            },
            {
              title: 'Unique Interventions',
              value: resultsState?.ctStats?.uniqueInterventions
            },
            {
              title: 'Documents/Pages',
              value: resultsState?.ctStats?.totalDocuments,
              value2: resultsState?.ctStats?.totalPages
            }
          ]}
        />
      </Box>
    </Stack>
  );
};

export default React.memo(ClinicalTrialsHeader);
