/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-const */
/* eslint-disable prefer-destructuring */
import {
  Box,
  Chip,
  Divider,
  IconButton,
  Link,
  Portal,
  Slide,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material';
import React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { Close } from '@mui/icons-material';
import styles from '../../../components/ApplicationCard/styles';
import { variantStyles } from './HorizontalDesignationCard';
import { designationName } from './SpecialityTimeLine';
import {
  chipsContainerStyle,
  indicationSectionStyle,
  snackBarContentStyle,
  snackBarStyle
} from '../styles/expandedCardStyle';

import { Reg360Icon } from '../../../assets/svgs/Icons';

interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  index: number;
  value: number;
}

const Transition = (props: TransitionProps) => {
  // @ts-ignore
  return <Slide {...props} direction='left' />;
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && children}
    </div>
  );
};

const TabView = ({ specialityDesignation, uniqueDate }: any) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        variant='scrollable'
        scrollButtons='auto'
        onChange={handleChange}
        aria-label='multi-designation'>
        {specialityDesignation.map((item: any, index: number) => {
          const date =
            item.designation_date ||
            item.aa_date ||
            item.bt_date ||
            item.ft_date ||
            item.designated_date ||
            item.rescinded_date ||
            item.final_approval_date ||
            '';
          const label = uniqueDate
            ? `${item.submission.toUpperCase()}  `
            : `
            ${date} - ${item.submission.toUpperCase()}  `;
          return <Tab key={label} wrapped label={label} {...a11yProps(index)} />;
        })}
      </Tabs>
      {specialityDesignation.map((item: any, index: number) => {
        const date =
          item.designation_date ||
          item.aa_date ||
          item.bt_date ||
          item.ft_date ||
          item.designated_date ||
          item.rescinded_date ||
          item.final_approval_date ||
          '';
        return (
          <TabPanel
            key={
              uniqueDate
                ? `${item.submission.toUpperCase()}  `
                : `
            ${date} - ${item.submission.toUpperCase()}  `
            }
            value={value}
            index={index}>
            <Box mt='24px'>
              <Typography variant='h6' lineHeight={1}>
                Indications
              </Typography>
            </Box>
            <Box sx={indicationSectionStyle}>
              <Typography variant='caption'>
                {item.bt_indication ||
                  item.orphan_designation ||
                  item.aa_indication ||
                  item.indication ||
                  ''}
              </Typography>
            </Box>
          </TabPanel>
        );
      })}
    </>
  );
};
const ExpandedCard = ({ open, onClose, payload }: any) => {
  let conversionWithdrawalStatus = '';
  let fullApprovalConversionWithdrawalDate = '';
  let rescindedDate = '';
  let finalApprovalDate = '';
  let {
    number = '',
    tradeName = '',
    activeIngredients = '',
    sponsorName = '',
    indications = '',
    route = '',
    formulation = '',
    variant = 'red',
    date = '',
    submission = '',
    specialityDesignation = [],
    selectedDesignationType = '',
    strikeText = false
  } = payload;
  const designations = specialityDesignation?.filter(
    (item: any) => item.type === selectedDesignationType
  );
  if (designations.length === 1) {
    indications =
      designations[0].indication ||
      designations[0].bt_indication ||
      designations[0].orphan_designation ||
      designations[0].aa_indication ||
      '';
    date =
      designations[0].designation_date ||
      designations[0].aa_date ||
      designations[0].bt_date ||
      designations[0].ft_date ||
      designations[0].designated_date ||
      designations[0].rescinded_date ||
      designations[0].final_approval_date ||
      '';

    submission = designations[0].submission || '';
  }
  const conversionWithdrawalStatuss = designations.map(
    (item: any) => item.conversion_withdrawal_status || ''
  );
  const uniqueConversionWithdrawalStatuss = [...(new Set(conversionWithdrawalStatuss) as any)];
  if (uniqueConversionWithdrawalStatuss.length === 1) {
    conversionWithdrawalStatus = uniqueConversionWithdrawalStatuss[0];
  }
  const fullApprovalConversionWithdrawalDates = designations.map(
    (item: any) => item.full_approval_conversion_withdrawal_date || ''
  );
  const uniqueFullApprovalConversionWithdrawalDates = [
    ...(new Set(fullApprovalConversionWithdrawalDates) as any)
  ];
  if (uniqueFullApprovalConversionWithdrawalDates.length === 1) {
    fullApprovalConversionWithdrawalDate = uniqueFullApprovalConversionWithdrawalDates[0];
  }
  const rescindedDates = designations.map((item: any) => item.rescinded_date || '');
  const uniqueRescindedDates = [...(new Set(rescindedDates) as any)];
  if (uniqueRescindedDates.length === 1) {
    rescindedDate = uniqueRescindedDates[0];
  }
  const finalApprovalDates = designations.map((item: any) => item.final_approval_date || '');
  const uniqueFinalApprovalDates = [...(new Set(finalApprovalDates) as any)];
  if (uniqueFinalApprovalDates.length === 1) {
    finalApprovalDate = uniqueFinalApprovalDates[0];
  }
  const dates = designations.map(
    (item: any) =>
      item.designation_date ||
      item.aa_date ||
      item.bt_date ||
      item.ft_date ||
      item.designated_date ||
      item.rescinded_date ||
      item.final_approval_date ||
      ''
  );
  const uniqueDates = [...(new Set(dates) as any)];
  if (uniqueDates.length === 1) {
    date = uniqueDates[0];
  }
  return (
    <Portal>
      <Snackbar
        onClose={onClose}
        open={open}
        TransitionComponent={Transition}
        sx={snackBarStyle}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Stack direction='column' sx={snackBarContentStyle}>
          <Box position='absolute' top={2} right={2}>
            <IconButton size='small' aria-label='close' onClick={onClose}>
              <Close fontSize='small' />
            </IconButton>
          </Box>
          <Box display='flex' flexWrap='wrap' justifyContent='space-between' alignItems='center'>
            <Box display='flex'>
              <Typography
                sx={{
                  // @ts-ignore
                  ...variantStyles[variant].appNum,
                  textDecoration: strikeText ? 'line-through' : 'none'
                }}>
                {number}
              </Typography>
              <Typography
                sx={{
                  // @ts-ignore
                  ...variantStyles[variant].name,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  textDecoration: strikeText ? 'line-through' : 'none'
                }}>
                {tradeName}
              </Typography>
              <Typography
                variant='subtitle2'
                className='substance'
                sx={{
                  ...styles.activeIngredient,
                  textDecoration: strikeText ? 'line-through' : 'none'
                }}>
                {activeIngredients}
              </Typography>
            </Box>
            <Link href={`/regulatory360/core/${encodeURIComponent(number)}/us`} target='_blank'>
              <Tooltip title='Open Reg 360&deg;'>
                <Reg360Icon />
              </Tooltip>
            </Link>
          </Box>
          <Divider sx={{ mt: '4px' }} />
          <Stack direction='row' flexWrap='wrap' sx={chipsContainerStyle}>
            <Chip
              label={
                <Tooltip title={`Sponsor Name: ${sponsorName}`}>
                  <Typography variant='body2'>{sponsorName}</Typography>
                </Tooltip>
              }
            />
            <Chip
              label={
                <Tooltip title={`Route: ${route}`}>
                  <Typography variant='body2'>{route}</Typography>
                </Tooltip>
              }
            />
            <Chip
              label={
                <Tooltip title={`Formulation: ${formulation}`}>
                  <Typography variant='body2'>{formulation}</Typography>
                </Tooltip>
              }
            />
            {date && date !== '1900-01-01' && (
              <Chip
                label={
                  <Tooltip
                    title={`${
                      ['ft', 'bt', 'or'].includes(selectedDesignationType)
                        ? 'Designated date'
                        : 'Date'
                    }: ${date}`}>
                    <Typography variant='body2'>{date}</Typography>
                  </Tooltip>
                }
              />
            )}
            {submission && (
              <Chip
                label={
                  <Tooltip title={`Submission: ${submission}`}>
                    <Typography variant='body2'>{submission}</Typography>
                  </Tooltip>
                }
              />
            )}
            {conversionWithdrawalStatus && (
              <Chip
                label={
                  <Tooltip title={`Conversion/Withdrawal Status: ${conversionWithdrawalStatus}`}>
                    <Typography variant='body2'>{conversionWithdrawalStatus}</Typography>
                  </Tooltip>
                }
              />
            )}
            {fullApprovalConversionWithdrawalDate &&
              fullApprovalConversionWithdrawalDate !== '1900-01-01' && (
                <Chip
                  label={
                    <Tooltip
                      title={`Full Approval Conversion/Withdrawal Date: ${fullApprovalConversionWithdrawalDate}`}>
                      <Typography variant='body2'>
                        {fullApprovalConversionWithdrawalDate}
                      </Typography>
                    </Tooltip>
                  }
                />
              )}
            {rescindedDate && rescindedDate !== '1900-01-01' && (
              <Chip
                label={
                  <Tooltip title={`Rescinded Date: ${rescindedDate}`}>
                    <Typography variant='body2'>{rescindedDate}</Typography>
                  </Tooltip>
                }
              />
            )}
            {finalApprovalDate && finalApprovalDate !== '1900-01-01' && (
              <Chip
                label={
                  <Tooltip title={`Final Approval Date: ${finalApprovalDate}`}>
                    <Typography variant='body2'>{finalApprovalDate}</Typography>
                  </Tooltip>
                }
              />
            )}
            {selectedDesignationType && (
              <Chip
                label={
                  <Tooltip title={`Designation Type: ${designationName[selectedDesignationType]}`}>
                    <Typography variant='body2'>
                      {designationName[selectedDesignationType]}
                    </Typography>
                  </Tooltip>
                }
              />
            )}
          </Stack>
          <Divider sx={{ m: 0 }} />
          {indications && (
            <>
              <Box mt='24px'>
                <Typography variant='h6' lineHeight={1}>
                  Indications
                </Typography>
              </Box>
              <Box sx={indicationSectionStyle}>
                <Typography variant='caption'>{indications}</Typography>
              </Box>
            </>
          )}
          {designations.length > 1 && (
            <TabView specialityDesignation={designations} uniqueDate={date} />
          )}
        </Stack>
      </Snackbar>
    </Portal>
  );
};
export default React.memo(ExpandedCard);
