import { useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { OptionContentProps } from './types';
import { DEFAULT_RADIO_OPTION_LIST } from './constants';
import { radioButtonStyles } from './styles';

const RadioGroups = ({
  selectedCategoryOptions,
  setSelectedCategoryOptions,
  selectedCategory,
  categoryOption = []
}: OptionContentProps) => {
  const [value, setValue] = useState('');

  const selectedCategoryValueKey = selectedCategory?.elasticSearchType || selectedCategory?.value;

  useEffect(() => {
    setValue(selectedCategoryOptions[selectedCategoryValueKey] || 'all');
  }, [selectedCategoryOptions]);

  const handleChange = (ev: any) => {
    setValue(ev.target.value);
    if (ev.target.value === 'all') {
      const { [selectedCategoryValueKey]: _, ...others } = selectedCategoryOptions;
      setSelectedCategoryOptions(others);
    } else {
      setSelectedCategoryOptions({
        ...selectedCategoryOptions,
        [selectedCategoryValueKey]: ev.target.value
      });
    }
  };

  return (
    <RadioGroup defaultValue={value} value={value} onChange={handleChange} row>
      {categoryOption?.length !== 0 ? (
        <>
          {categoryOption.map((radioOptions: any) => (
            <FormControlLabel
              key={radioOptions.value}
              value={radioOptions.value}
              control={<Radio size='small' sx={radioButtonStyles} />}
              label={radioOptions.label}
            />
          ))}
        </>
      ) : (
        <>
          {DEFAULT_RADIO_OPTION_LIST.map((radioOptions: any) => (
            <FormControlLabel
              key={radioOptions.value}
              value={radioOptions.value}
              control={<Radio size='small' sx={radioButtonStyles} />}
              label={radioOptions.label}
            />
          ))}
        </>
      )}
    </RadioGroup>
  );
};

export default RadioGroups;
