import React, { Suspense, useContext, useEffect, useState } from 'react';

import { Box, Skeleton, Typography } from '@mui/material';
import styles from '../styles/newGraph.styles';

// import Grid from '@material-ui/core/Grid';
import Store from '../../../store';
import SuspenseCircularLoader from '../../../helpers/SuspenseCircularLoader';

import RadarGraph from '../../../components/CustomComponents/Graphs/RadarGraph';
import BarChart from '../../../components/CustomComponents/Graphs/BarChart';
import LineChart from '../../../components/CustomComponents/Graphs/LineChart';
import PieChart from '../../../components/CustomComponents/Graphs/PieChart';

const Graph = props => {
  const [dataList, setDataList] = useState();
  const [graphType, setGraphType] = useState();
  const [keyList, setKeyList] = useState();
  const [key, setKey] = useState();
  const [title, setTitle] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(true);
  const [yAxis, setyAxis] = useState();
  const { state } = useContext(Store);

  useEffect(() => {
    async function getData() {
      try {
        let dataObj = props.data;
        setGraphType(dataObj['graphType']);
        setDataList(dataObj['Data']);
        if (dataObj['graphType'] === 'Bar') {
          if (dataObj['Data'][0]['data']) {
            const objList = dataObj['Data'][0]['data'].filter(item => parseInt(item.y) > 0);
            setDataList(objList);
          }
        }
        setKey('Title');
        setTitle(dataObj['key']);
        setMessage(dataObj['message']);
        setKeyList(dataObj['keyList']);
        setyAxis(dataObj['yAxis']);

        setLoading(false);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
    getData();
  }, [props.data, state.module]);

  if (loading === true) {
    return <Skeleton variant='rectangular' width={'54.5vh'} height={'74.5vh'} />;
  } else {
    if (graphType === 'Radar') {
      return (
        <Box sx={{ ...styles.root, height: props.height ? props.height : '55vh' }}>
          <Typography sx={styles.upperText}>{message}</Typography>
          <Box sx={styles.graphContainer}>
            {dataList?.length > 0 ? (
              <Suspense fallback={<SuspenseCircularLoader />}>
                <RadarGraph data={dataList} indexKey={key} keys={keyList} />
              </Suspense>
            ) : (
              'No Data'
            )}
          </Box>
          <Typography sx={styles.lowerText}>{title}</Typography>
        </Box>
      );
    } else if (graphType === 'Line') {
      return (
        <Box sx={{ ...styles.root, height: props.height ? props.height : '55vh' }}>
          <Typography sx={styles.upperText}>{message}</Typography>
          <Box sx={styles.graphContainer}>
            {dataList?.length > 0 ? (
              <Suspense fallback={<SuspenseCircularLoader />}>
                <LineChart data={dataList} yAxis={yAxis} />
              </Suspense>
            ) : (
              'No Data'
            )}
          </Box>
          <Typography sx={styles.lowerText}>{title}</Typography>
        </Box>
      );
    } else if (graphType === 'Bar') {
      return (
        <Box sx={{ ...styles.root, height: props.height ? props.height : '55vh' }}>
          <Typography sx={styles.upperText}>{message}</Typography>
          <Box sx={styles.graphContainer}>
            {dataList?.length > 0 ? (
              <Suspense fallback={<SuspenseCircularLoader />}>
                <BarChart data={dataList} yAxis={yAxis} />
              </Suspense>
            ) : (
              'No Data'
            )}
          </Box>
          <Typography sx={styles.lowerText}>{title}</Typography>
        </Box>
      );
    } else if (graphType === 'Pie') {
      return (
        <Box sx={{ ...styles.root, height: props.height ? props.height : '55vh' }}>
          <Typography sx={styles.upperText}>{message}</Typography>
          <Box sx={styles.graphContainer}>
            {dataList?.length > 0 ? (
              <Suspense fallback={<SuspenseCircularLoader />}>
                <PieChart data={dataList} style={props.style} />
              </Suspense>
            ) : (
              'No Data'
            )}
          </Box>
          <Typography sx={styles.lowerText}>{title}</Typography>
        </Box>
      );
    }
  }
};

export default React.memo(Graph);
