import { useCallback, useState, useContext } from 'react';
import { getSummary, getRosterTable, getResources } from '../../../api/pages/Adcomm';
import AdcommStore from '../../../store/Adcomm';
import actions from '../../../store/Adcomm/actions';

const useTabsData = () => {
  const { AdcommDispatch } = useContext(AdcommStore);
  const [isLoading, setLoading]: [boolean, Function] = useState(false);
  const [errorMessage, setErrorMessage]: [string, Function] = useState('');
  const [responseData, setResponseData]: [{ [key: string]: string | any }, Function] = useState({});

  const getTabsData = useCallback(async (meetingId: string, date: string) => {
    setLoading(true);
    let summary: { [key: string]: string | any } = {};
    let roster: { [key: string]: string | any } = {};
    let resource: { [key: string]: string | any } = {};

    // API call for Summary data
    try {
      const res = await getSummary(meetingId);
      if (res?.status === 200) {
        summary = res.data.body.result;
        if (!meetingId) setErrorMessage('No Meeting data to display');
        else if (new Date() < new Date(date))
          setErrorMessage('The content for this meeting is not yet available');
        else if (res.data.body.result.info.status === 'cancelled')
          setErrorMessage('Data unavailable due to Cancelled Meeting');
        else if (res.data.body.result.info.status === 'postponed')
          setErrorMessage('Data unavailable due to Postponed Meeting');
        else if (res.data.body.result.info.status === 'closed meeting')
          setErrorMessage('Data unavailable due to Closed Meeting');
        else setErrorMessage('Data Capture in Progress');
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      setErrorMessage('Data Capture in Progress');
    }

    // API call for Roster data
    try {
      const res = await getRosterTable(meetingId);
      if (res?.status === 200) {
        roster = res.data.body.result;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }

    // API call for Resource data
    try {
      const res = await getResources(meetingId);
      if (res?.status === 200) {
        resource = res.data.body.result;
        AdcommDispatch({
          type: actions.SET_SELECTED_MEETING_ID,
          value: meetingId
        });
        AdcommDispatch({
          type: actions.SET_SELECTED_MEETING_DOCUMENT_COUNT,
          value: resource.document_count
        });
        AdcommDispatch({
          type: actions.SET_SELECTED_MEETING_DOCUMENT_PAGE_COUNT,
          value: resource.document_total_page_count
        });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }

    setResponseData({
      summary,
      roster,
      resource
    });
    setLoading(false);
  }, []);

  return {
    isLoading,
    responseData,
    errorMessage,
    getTabsData
  };
};

export default useTabsData;
