/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import makeRequest from '../client';

const MOXO_API_URL = process.env.REACT_APP_MOXO_API_URL;

/**
 * function to generate an access token for the current user
 * @param email
 */
export const generateAccessToken = (uniqueId: string) =>
  makeRequest(`/moxo/token?unique_id=${uniqueId}`, 'GET');

/**
 * function to get moxo user details
 * @param email
 */
export const getMoxoUserDetails = (uniqueId: string) =>
  makeRequest(`/moxo/user?unique_id=${uniqueId}`, 'GET');

/**
 * function to create a new client user in moxo
 * @param payload
 */
export const createClientUser = () => makeRequest(`/moxo/client`, 'POST');

export const getMyGroupBinders = async (accessToken: any) => {
  let res = null;
  try {
    res = await axios.get(`${MOXO_API_URL}/v1/me/binders?access_token=${accessToken}`, {
      headers: {
        'Content-type': 'application/json'
      }
    });
  } catch (e: any) {
    res = e.response;
  }
  return res;
};

export const addMessageToBinder = async (payload: any, binderId: string, token: any) => {
  let res = null;
  try {
    res = await axios.post(
      `${MOXO_API_URL}/v1/${binderId}/messages?access_token=${token}`,
      payload,
      {
        headers: {
          'Content-type': 'application/json'
        }
      }
    );
  } catch (e: any) {
    res = e.response;
  }
  return res;
};

export const addCommentToBinder = async (payload: any, binderId: string, token: any) => {
  let res = null;
  try {
    res = await axios.post(
      `${MOXO_API_URL}/v1/${binderId}/comments?access_token=${token}`,
      payload,
      {
        headers: {
          'Content-type': 'application/json'
        }
      }
    );
  } catch (e: any) {
    res = e.response;
  }
  return res;
};

export const shareFileToBinder = async (payload: any, binderId: string, token: any) => {
  let res = null;
  try {
    res = await axios.post(`${MOXO_API_URL}/v1/${binderId}/files?access_token=${token}`, payload);
  } catch (e: any) {
    res = e.response;
  }
  return res;
};

export const getUserListForGroup = async () => makeRequest(`/moxo/user-list`, 'GET');

export const createGroup = async (payload: any) => makeRequest(`/moxo/group`, 'POST', payload);

export const getNotificationPreference = () => makeRequest(`/moxo/notifications`, 'GET');

export const modifyNotificationPreference = (payload: any) =>
  makeRequest(`/moxo/notifications`, 'POST', payload);
