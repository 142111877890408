import { createSvgIcon } from '@mui/material';

const HelpCenterIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='13' viewBox='0 0 14 13' fill='none'>
    <path d='M12.2491 6.74717V5.9165C12.2491 4.52412 11.696 3.18876 10.7114 2.20419C9.72684 1.21963 8.39148 0.666504 6.9991 0.666504C5.60671 0.666504 4.27135 1.21963 3.28679 2.20419C2.30222 3.18876 1.7491 4.52412 1.7491 5.9165V6.74717C1.13727 7.01657 0.636563 7.48798 0.330792 8.08246C0.0250212 8.67694 -0.0672514 9.35842 0.0694216 10.0128C0.206095 10.6672 0.563417 11.2548 1.08157 11.6772C1.59972 12.0996 2.24726 12.3312 2.91576 12.3332H4.08243V6.49984H2.91576V5.9165C2.91576 4.83354 3.34597 3.79492 4.11174 3.02915C4.87752 2.26338 5.91613 1.83317 6.9991 1.83317C8.08206 1.83317 9.12067 2.26338 9.88645 3.02915C10.6522 3.79492 11.0824 4.83354 11.0824 5.9165V6.49984H9.91576V11.1665H7.58243V12.3332H11.0824C11.7509 12.3312 12.3985 12.0996 12.9166 11.6772C13.4348 11.2548 13.7921 10.6672 13.9288 10.0128C14.0654 9.35842 13.9732 8.67694 13.6674 8.08246C13.3616 7.48798 12.8609 7.01657 12.2491 6.74717V6.74717ZM2.91576 11.1665C2.45163 11.1665 2.00651 10.9821 1.67833 10.6539C1.35014 10.3258 1.16576 9.88063 1.16576 9.4165C1.16576 8.95238 1.35014 8.50726 1.67833 8.17907C2.00651 7.85088 2.45163 7.6665 2.91576 7.6665V11.1665ZM11.0824 11.1665V7.6665C11.5466 7.6665 11.9917 7.85088 12.3199 8.17907C12.6481 8.50726 12.8324 8.95238 12.8324 9.4165C12.8324 9.88063 12.6481 10.3258 12.3199 10.6539C11.9917 10.9821 11.5466 11.1665 11.0824 11.1665Z' />
  </svg>,
  'HelpCenter'
);

export default HelpCenterIcon;
