import React, { memo, useEffect, useState } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { Box, Stack, Typography, Button } from '@mui/material';

import { SettingsIcon } from '../../../../assets/svgs/Icons';

import themev5 from '../../../../themev5';

import ErrorText from './ErrorText';
import LoadingIndicator from './LoadingIndicator';
import NotificationMenu from './NotificationMenu';

// hooks
import useMoxoSdkServices from '../../../../hooks/useMoxoSdkServices';
import useUpdateModule from '../../../../hooks/useUpdateModule';

// styles
import styles from '../../styles/Moxo.styles';

const Messages = () => {
  const {
    initializeMoxoChatWindow,
    initialized,
    error,
    fetchNotificationPreference,
    modifyNotification,
    notificationPreference
  } = useMoxoSdkServices();

  useUpdateModule('collaborate');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    fetchNotificationPreference();
    initializeMoxoChatWindow('#messagesPortal');
  }, []);

  if (error) {
    return <ErrorText />;
  }
  return (
    <ThemeProvider theme={themev5}>
      <Stack sx={styles.container}>
        <Stack
          py={1}
          pr={5}
          direction='row'
          spacing={1}
          alignItems='center'
          justifyContent='flex-end'>
          <Button
            onClick={handleClick}
            startIcon={<SettingsIcon sx={open ? styles.openSettingsIcon : styles.settingsIcon} />}>
            <Typography variant='body1' sx={open ? styles.openMenuTitle : styles.menuTitle}>
              Notification preferences
            </Typography>
          </Button>
          <NotificationMenu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            currentPreference={notificationPreference}
            onMenuItemClick={modifyNotification}
          />
        </Stack>
        <Box data-dd-privacy='mask' id='messagesPortal' sx={{ height: '87vh' }} border='none'>
          {!initialized && <LoadingIndicator />}
        </Box>
      </Stack>
    </ThemeProvider>
  );
};
export default memo(Messages);
