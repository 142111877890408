import React, { createContext, useReducer } from 'react';
import reducers from './reducers';
import { ReportData } from '../../pages/UserProfile/interface';

type Props = {
  children: React.ReactNode;
};

export interface UserProfileInterface {
  loading: boolean;
  dateGroupedUserActivity: {};
  timestampUserActivity: string;
  paginateOnScroll: boolean;
  activityData: any[];
  savedData: {
    searches: any[];
    documents: any[];
    applications: any[];
  };
  reportData: ReportData[];
  projectList: any[];
  currentProjectId: number;
}
const initialState = {
  loading: false,
  dateGroupedUserActivity: {},
  timestampUserActivity: new Date().toISOString(),
  paginateOnScroll: true,
  activityData: [],
  savedData: {
    searches: [],
    documents: [],
    applications: []
  },
  reportData: [],
  projectList: [],
  currentProjectId: 0
};

const Store = createContext<{
  profileState: UserProfileInterface;
  profileDispatch: React.Dispatch<any>;
}>({
  profileState: initialState,
  profileDispatch: () => null
});
const { Provider } = Store;

export const ProfileStateProvider = ({ children }: Props) => {
  const [profileState, profileDispatch] = useReducer(reducers, initialState);

  return <Provider value={{ profileState, profileDispatch }}>{children}</Provider>;
};

export default Store;
