/* eslint-disable no-unused-vars */
import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { IconButton, Box } from '@mui/material';
import { MoreVert } from '@material-ui/icons';
import { useLocation, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import ResultsStore from '../../../store/ResultsPage';
import ResultsAction from '../../../store/ResultsPage/actions';
import AriaActions from '../../../store/Aria/actions';
import AriaStore from '../../../store/Aria';
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import RegActions from '../../../store/RegulatoryInformation/actions';
import UserProfileStore from '../../../store/UserProfile';
import UserProfileActions from '../../../store/UserProfile/actions';

const CardActions = ({
  setActionId,
  index,
  isFavorite,
  cardsPage,
  type,
  typeData,
  inProject,
  disableDelete,
  showOnlyDelete,
  listView,
  iconSize,
  ariaResultsLoading,
  setAriaResultsLoading
}) => {
  const [favorite, setFavorite] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { profileState, profileDispatch } = useContext(UserProfileStore);
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const { ariaState, ariaDispatch } = useContext(AriaStore);
  const { regulatoryDispatch } = useContext(RegulatoryInformationStore);

  const { projectId } = useParams();
  const location = useLocation();
  const open = Boolean(anchorEl);

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
    if (setActionId) {
      setActionId(index);
    }
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    if (setActionId) {
      setActionId(-1);
    }
  };
  const handleClose = useCallback(
    val => {
      if (val !== undefined) {
        if (
          location.pathname === '/account' ||
          (location.pathname === '/account/activity' && profileState?.dateGroupedUserActivity)
        ) {
          const { dateGroupedUserActivity } = profileState;
          const newListData = {};
          Object.entries(dateGroupedUserActivity).forEach(([key, value]) => {
            newListData[key] = value.map(item => {
              // eslint-disable-next-line react/prop-types
              if (item.id === typeData.id) {
                return { ...item, isFavorite: val };
              }
              return item;
            });
          });
          profileDispatch({ type: UserProfileActions.APPEND_LIST_DATA, value: newListData });
        }

        if (!val) {
          setFavorite(false);
          if (location.pathname === '/account/favorites' && profileState?.listData) {
            const { listData } = profileState;
            const finalListData = [...listData];

            const indexOfObject = finalListData.findIndex(object => {
              // eslint-disable-next-line react/prop-types
              if (typeData.type === 'applications') {
                if ('nct_id' in object) {
                  // eslint-disable-next-line react/prop-types
                  return object.nct_id === typeData.id;
                }
                if ('vin' in object) {
                  // eslint-disable-next-line react/prop-types
                  return object.vin === typeData.id;
                }
                if ('product_number' in object) {
                  // eslint-disable-next-line react/prop-types
                  return object.product_number === typeData.id;
                }
                // eslint-disable-next-line react/prop-types
                return object.application_number === typeData.id;
              }
              // eslint-disable-next-line react/prop-types
              return object.id === typeData.id;
            });
            if (indexOfObject !== -1) {
              // eslint-disable-next-line react/prop-types
              if (typeData.type === 'aria_result') {
                // eslint-disable-next-line react/prop-types
                finalListData[indexOfObject].aria_results = finalListData[
                  indexOfObject
                ]?.aria_results?.filter(
                  // eslint-disable-next-line react/prop-types
                  item => item?.result_id !== typeData?.ariaResultData?.result_id
                );
                if (
                  finalListData[indexOfObject].aria_results.length === 0 &&
                  !finalListData[indexOfObject].isFavorite
                )
                  finalListData.splice(indexOfObject, 1);
              } else if (
                !finalListData[indexOfObject]?.aria_results ||
                finalListData[indexOfObject]?.aria_results?.length === 0
              ) {
                finalListData.splice(indexOfObject, 1);
              } else {
                finalListData[indexOfObject].isFavorite = false;
              }
            }

            profileDispatch({
              type: UserProfileActions.SET_LIST_DATA,
              value: finalListData
            });
          }
        } else {
          setFavorite(true);
          if (
            location.pathname === '/account/favorites' &&
            profileState?.listData &&
            typeData.type !== 'aria_result'
          ) {
            const { listData } = profileState;
            const finalListData = [...listData];
            const indexOfObject = finalListData.findIndex(object => {
              if (typeData.type === 'applications') {
                if ('nct_id' in object) {
                  // eslint-disable-next-line react/prop-types
                  return object.nct_id === typeData.id;
                }
                if ('vin' in object) {
                  // eslint-disable-next-line react/prop-types
                  return object.vin === typeData.id;
                }
                if ('product_number' in object) {
                  // eslint-disable-next-line react/prop-types
                  return object.product_number === typeData.id;
                }
                // eslint-disable-next-line react/prop-types
                return object.application_number === typeData.id;
              }
              return object.id === typeData.id;
            });
            if (indexOfObject !== -1) {
              finalListData[indexOfObject].isFavorite = true;
              profileDispatch({
                type: UserProfileActions.SET_LIST_DATA,
                value: finalListData
              });
            }
          }
        }
        if (location.pathname.startsWith('/search') && type === 'applications') {
          const cardData = resultsState.results;
          cardData[index].isFavorite = val;
          cardData[index].is_favorite = val;
          resultsDispatch({ type: ResultsAction.SET_RESULTS, value: cardData });
        }
        if (location.pathname.startsWith('/regulatory360')) {
          regulatoryDispatch({ type: AriaActions.SET_IS_FAVORITE, value: val });
        }
        if (location.pathname.startsWith('/account/project')) {
          const newLocalData = [];
          profileState?.listData.forEach(item => {
            if (item.id === typeData.id) {
              // check if aria result
              const ariaResultIndex =
                item?.aria_results.findIndex(
                  result => result.result_id === typeData?.ariaResultData?.result_id
                ) ?? -1;
              // update the isFavorite in Aria result instead of Aria Search
              if (typeData.type === 'aria_result' && ariaResultIndex !== -1) {
                const newItem = { ...item };
                newItem.aria_results[ariaResultIndex].isFavorite = val;
                newLocalData.push({ ...newItem });
              } else newLocalData.push({ ...item, isFavorite: val });
            } else {
              newLocalData.push(item);
            }
          });
          profileDispatch({ type: UserProfileActions.SET_LIST_DATA, value: newLocalData });
        }
        if (location.pathname.startsWith('/aria') && typeData?.type === 'aria_result') {
          const { ariaResults, suggestedAns } = ariaState;
          const ariaResultIndex = ariaResults.findIndex(
            item => item.result_id === typeData.ariaResultData.result_id
          );
          if (ariaResultIndex === -1) {
            const suggesedAnsIndex = suggestedAns.findIndex(
              item => item.result_id === typeData.ariaResultData.result_id
            );
            if (suggesedAnsIndex !== -1) {
              suggestedAns[suggesedAnsIndex].isFavorite = val;
              ariaDispatch({ type: AriaActions.SET_SUGGESTED_ANS, value: suggestedAns });
            }
          } else {
            ariaResults[ariaResultIndex].isFavorite = val;
            ariaDispatch({ type: AriaActions.SET_RESULTS, value: ariaResults });
          }
        }
        handleCloseMenu();
      }
    },
    [
      location,
      profileState,
      profileDispatch,
      resultsState,
      resultsDispatch,
      regulatoryDispatch,
      type,
      typeData,
      index
    ]
  );
  const projectLogic = item => {
    let proj = [];
    const newLocalData = [];
    if (item.inProject) {
      proj = [...item.inProject];
    }
    if (item.inProjects) {
      proj = [...item.inProjects];
    }
    if (item.in_projects) {
      proj = [...item.in_projects];
    }
    if (item.in_project) {
      proj = [...item.in_project];
    }
    if (proj.length > 0) {
      proj.forEach(project => {
        if (project.id === parseInt(projectId, 10)) {
          if ('inProject' in item) {
            if (item.inProject) {
              newLocalData.push(item);
            }
          } else {
            newLocalData.push(item);
          }
        }
      });
    }
    return newLocalData;
  };
  const handleProjectsClose = async val => {
    if (val !== undefined) {
      const page = location.pathname.split('/')[1];

      switch (page) {
        case 'account':
          // eslint-disable-next-line no-case-declarations
          const subPage = location.pathname.split('/')[2];
          // eslint-disable-next-line no-case-declarations
          const { listData, dateGroupedUserActivity } = profileState;
          // eslint-disable-next-line no-case-declarations
          let newLocalData = [];
          switch (subPage) {
            case 'project':
              newLocalData = [];
              listData.forEach(item => {
                if ('aria_results' in item) {
                  const finalItem = { ...item };
                  const removeIndex = [];
                  item.aria_results.forEach((ariaResult, ariaIndex) => {
                    if (ariaResult?.result_id === typeData?.ariaResultData?.result_id) {
                      const projectLogicData = projectLogic(ariaResult);
                      if (projectLogicData.length > 0) {
                        finalItem.aria_results[ariaIndex] = projectLogicData;
                      } else {
                        removeIndex.push(ariaIndex);
                      }
                    }
                  });
                  const insertItem = { ...finalItem, aria_results: [] };
                  finalItem.aria_results.forEach((ariaResult, ariaIndex) => {
                    if (!removeIndex.includes(ariaIndex)) {
                      insertItem.aria_results.push(ariaResult);
                    }
                  });

                  if (!isEmpty(insertItem)) {
                    if (insertItem?.aria_results?.length > 0) {
                      newLocalData.push(insertItem);
                    } else {
                      const projectLogivData = projectLogic(insertItem);
                      if (projectLogivData.length > 0) {
                        newLocalData = [...newLocalData, ...projectLogivData];
                      }
                    }
                  }
                } else {
                  const projectLogicData = projectLogic(item);
                  if (projectLogicData.length > 0) {
                    newLocalData = [...newLocalData, ...projectLogicData];
                  }
                }
              });

              profileDispatch({
                type: UserProfileActions.SET_LIST_DATA,
                value: Array.from(new Set(newLocalData))
              });
              break;
            case 'favorites':
              newLocalData = [];
              // eslint-disable-next-line consistent-return
              listData.forEach(item => {
                let checkData = '';
                // eslint-disable-next-line react/prop-types
                if (item.type === 'applications') {
                  if ('nct_id' in item) {
                    checkData = item.nct_id;
                  } else {
                    checkData = item.application_number;
                  }
                } else if (
                  item.type === 'ariaSearch' &&
                  typeData.type === 'aria_result' &&
                  !isEmpty(item.aria_results)
                ) {
                  const newAriaResults = [...item.aria_results];
                  const ariaResultIndex = newAriaResults.findIndex(
                    ariaResult => ariaResult.result_id === typeData.ariaResultData.result_id
                  );
                  if (ariaResultIndex !== -1) {
                    newAriaResults[ariaResultIndex].inProjects = val;
                    return newLocalData.push({ ...item, aria_results: newAriaResults });
                  }
                  return newLocalData.push({ ...item });
                } else {
                  // eslint-disable-next-line react/prop-types
                  checkData = item.id;
                }
                // eslint-disable-next-line react/prop-types
                if (checkData === typeData.id) {
                  return newLocalData.push({ ...item, inProjects: val });
                }
                newLocalData.push({ ...item });
              });
              profileDispatch({
                type: UserProfileActions.SET_LIST_DATA,
                value: newLocalData
              });
              break;
            default:
              newLocalData = {};
              Object.entries(dateGroupedUserActivity)?.forEach(([key, value]) => {
                newLocalData[key] = value?.map(item => {
                  // eslint-disable-next-line react/prop-types
                  if (item.id === typeData.id) {
                    return { ...item, inProjects: val };
                  }
                  return item;
                });
              });
              profileDispatch({
                type: UserProfileActions.APPEND_LIST_DATA,
                value: newLocalData
              });
              break;
          }
          profileDispatch({
            type: UserProfileActions.TRIGGER_PROJECT_UPDATED,
            value: true
          });
          break;
        case 'search':
          if (type === 'applications') {
            const cardData = resultsState.results;
            cardData[index].inProjects = val;
            resultsDispatch({ type: ResultsAction.SET_RESULTS, value: cardData });
          }
          break;
        case 'regulatory360':
          if (type === 'applications') {
            regulatoryDispatch({ type: RegActions.SET_IN_PROJECTS, value: val });
          }
          break;
        case 'aria':
          if (typeData.type === 'aria_result') {
            const { ariaResults, suggestedAns } = ariaState;
            const ariaResultIndex = ariaResults.findIndex(
              item => item.result_id === typeData.ariaResultData.result_id
            );
            if (ariaResultIndex === -1) {
              const suggesedAnsIndex = suggestedAns.findIndex(
                item => item.result_id === typeData.ariaResultData.result_id
              );
              if (suggesedAnsIndex !== -1) {
                suggestedAns[suggesedAnsIndex].inProjects = val;
                ariaDispatch({ type: AriaActions.SET_SUGGESTED_ANS, value: suggestedAns });
              }
            } else {
              ariaResults[ariaResultIndex].inProjects = val;
              ariaDispatch({ type: AriaActions.SET_RESULTS, value: ariaResults });
            }
          } else await ariaDispatch({ type: AriaActions.SET_IN_PROJECT, value: val });
          break;
        default:
          break;
      }
      handleCloseMenu();
    }
  };
  const handleDeleteClose = async val => {
    if (val !== undefined) {
      const { listData } = profileState;
      const newListData = {};
      Object.entries(listData)?.forEach(([key, value]) => {
        newListData[key] = value?.filter(item => {
          return (
            'activity_id' in item && 'activity_id' in val && item.activity_id !== val.activity_id
          );
        });
      });
      profileDispatch({ type: UserProfileActions.SET_LIST_DATA, value: newListData });
    }
    handleCloseMenu();
  };

  useEffect(() => {
    setFavorite(isFavorite);
  }, [isFavorite]);

  // if (showOnlyDelete) {
  //   return (
  //     // eslint-disable-next-line react/jsx-no-useless-fragment
  //     <>
  //       {(location.pathname === '/account' || location.pathname === '/account/activity') && (
  //         <DeleteSearchAction data={{ ...typeData, type }} onClose={handleDeleteClose} />
  //       )}
  //     </>
  //   );
  // }
  // if (listView) {
  //   return (
  //     <Stack direction='row' justifyContent='center' alignItems='center'>
  //       <UserFavoriteAction
  //         type='iconButton'
  //         isFavorite={favorite}
  //         onClose={handleClose}
  //         data={{ ...typeData, type }}
  //         iconSize={iconSize}
  //         ariaResultsLoading={ariaResultsLoading}
  //         setAriaResultsLoading={setAriaResultsLoading}
  //       />
  //       <UserProjectAction
  //         inProject={inProject}
  //         data={{ ...typeData, type }}
  //         onClose={handleProjectsClose}
  //         type='iconButton'
  //         iconSize={iconSize}
  //       />
  //     </Stack>
  //   );
  // }
  return (
    <>
      {(favorite || location.pathname.startsWith('/aria')) && (
        <Box />
        // <UserFavoriteAction
        //   type='iconButton'
        //   isFavorite={favorite}
        //   onClose={handleClose}
        //   data={{ ...typeData, type }}
        //   iconSize={iconSize}
        //   ariaResultsLoading={ariaResultsLoading}
        //   setAriaResultsLoading={setAriaResultsLoading}
        // />
      )}

      <Tooltip title='Open options'>
        <IconButton
          aria-label='more'
          id='long-button'
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          onClick={handleOpenMenu}
          sx={{ color: 'grey', marginRight: cardsPage ? '-10px' : 'initial' }}>
          <MoreVert />
        </IconButton>
      </Tooltip>
      {/* 
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '200px',
            borderRadius: '16px'
          }
        }}>
        <UserFavoriteAction
          type='menuButton'
          isFavorite={favorite}
          onClose={handleClose}
          data={{ ...typeData, type }}
          iconSize={iconSize}
          ariaResultsLoading={ariaResultsLoading}
          setAriaResultsLoading={setAriaResultsLoading}
        />
        <UserProjectAction
          inProject={inProject}
          data={{ ...typeData, type }}
          onClose={handleProjectsClose}
          iconSize={iconSize}
        />
        {
          // eslint-disable-next-line react/prop-types
          !disableDelete &&
            (location.pathname === '/account' || location.pathname === '/account/activity') && (
              <DeleteSearchAction data={{ ...typeData, type }} onClose={handleDeleteClose} />
            )
        }
      </Menu> */}
    </>
  );
};

CardActions.defaultProps = {
  setActionId: undefined,
  cardsPage: false,
  inProject: [],
  disableDelete: false,
  showOnlyDelete: false,
  listView: false,
  iconSize: 'medium',
  ariaResultsLoading: {
    favorite: false,
    collaborate: false
  },
  setAriaResultsLoading: () => {}
};

CardActions.propTypes = {
  setActionId: PropTypes.func,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isFavorite: PropTypes.bool.isRequired,
  cardsPage: PropTypes.bool,
  type: PropTypes.string.isRequired,
  typeData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
    source: PropTypes.string,
    module: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    ariaResultData: PropTypes.objectOf(PropTypes.any),
    isEMAinHPRAFlag: PropTypes.bool
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  inProject: PropTypes.arrayOf(PropTypes.any),
  disableDelete: PropTypes.bool,
  showOnlyDelete: PropTypes.bool,
  listView: PropTypes.bool,
  iconSize: PropTypes.string,
  ariaResultsLoading: PropTypes.shape({
    favorite: PropTypes.bool,
    collaborate: PropTypes.bool
  }),
  setAriaResultsLoading: PropTypes.func
};

export default memo(CardActions);
