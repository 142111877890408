import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';

// material ui
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// styled components
import { Clear } from '@material-ui/icons';
import { Search } from '@mui/icons-material';

import AriaTextField from '../styledComponents/AriaTextField';

// custom hooks
import useQuery from '../../../helpers/customHooks/queryParameter';

// context
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';
import AriaStore from '../../../store/Aria';
import AriaActions from '../../../store/Aria/actions';

// styles
import stylesheet from '../styles/SearchField.styles';

// api
import { getAriaQuerySuggestions } from '../../../api/pages/Aria';

const SearchField = () => {
  const styles = stylesheet();
  const query = useQuery();

  // global context store
  const { dispatch } = useContext(GlobalStore);
  // context store for aria state management
  const { ariaState, ariaDispatch } = useContext(AriaStore);

  // for redirecting to different page
  const history = useHistory();
  // url parameters
  const { module, source } = useParams();

  // state for search term
  const [search, setSearch] = useState('');
  // state for storing the query suggestions if generated
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setSearch(query.get('term'));
  }, [query]);
  /**
   * function to save search text and generate search suggestions for user
   * @param {object} event event handler
   */
  const changeHandler = useCallback(
    async event => {
      const currentText = event.target.value;
      setSearch(currentText);
      // fetch query suggestions only if there are more than 2 chars entered
      if (currentText.length > 1) {
        const res = await getAriaQuerySuggestions(source, currentText);
        // emptythe list if no results found
        const optionList = res && res.status === 200 ? res.data.body : [];
        setOptions(optionList);
      }
    },
    [source]
  );

  /**
   * function to ensure that there is a delay on every subsequent api call
   * for query suggestions to reduce server load
   */
  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 200), [changeHandler]);

  /**
   * function to redirect user to the results page after form submission
   * @param {object} e event handler
   */
  const handleSubmit = e => {
    e.preventDefault();
    if (search?.length > 1000) {
      dispatch({
        type: GlobalActions.SET_ALERT,
        value: {
          status: true,
          message: 'Search query cannot be more than 1000 characters'
        }
      });
      return;
    }
    let queryTerm = query.get('term');
    queryTerm = queryTerm ? queryTerm.trim() : '';
    if (search && search.trim() !== queryTerm) {
      if (ariaState.documentLink?.length) {
        try {
          // releases the existing object URL which was previously created by calling URL.createObjectURL()
          URL.revokeObjectURL(ariaState.documentLink.split('#page=')[0]);
          ariaDispatch({ type: AriaActions.SET_DOCUMENT_LINK, value: '' });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
      // clear the previous search details when new search is executed
      ariaDispatch({ type: AriaActions.SET_ORIGINAL_RESULTS, value: [] });
      ariaDispatch({ type: AriaActions.SET_ARIA_RESULTS, value: [] });
      ariaDispatch({ type: AriaActions.SET_SUGGESTED_ANS, value: [] });
      ariaDispatch({ type: AriaActions.SET_DOCUMENT_OPEN, value: false });
      ariaDispatch({ type: AriaActions.SET_DOCUMENT_LINK, value: '' });
      ariaDispatch({ type: AriaActions.SET_FILTERS, value: {} });
      ariaDispatch({ type: AriaActions.SET_TOP_ANSWER, value: '' });
      ariaDispatch({ type: AriaActions.SET_DID_YOU_MEAN_TEXT, value: '' });
      ariaDispatch({ type: AriaActions.SET_ERROR, value: '' });
      // From Aria page, if we click back button we are going back to reg360. Not for the banner page.
      // So replace the banner route from search route so that browser back button will take you to reg360 on aria result page
      const term = (search || queryTerm).trim();
      const queryParamsObj = {
        bucket: 'all',
        resultsPage: query.get('resultsPage') ? 'true' : '',
        operationId: query.get('operationId') ? query.get('operationId') : '',
        searchTerm: query.get('searchTerm') ? query.get('searchTerm') : '',
        from: query.get('from') ? query.get('from') : '',
        projectName: query.get('projectName') ? query.get('projectName') : '',
        favorites: query.get('favorites') ? 'true' : '',
        backTo: query.get('backTo') ? query.get('backTo') : ''
      };
      const queryParams = Object.entries(queryParamsObj)
        // eslint-disable-next-line array-callback-return, consistent-return
        .map(([key, value]) => {
          if (value !== '') {
            return `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
          }
        })
        .join('');
      history.replace(
        `/aria/${module}/${source}?term=${term}${queryParams}`,
        history.location.state
      );
    }
  };
  const handleClear = () => {
    setSearch('');
  };
  return (
    <Autocomplete
      align='center'
      sx={styles.searchBox}
      id='aria-banner-textfield'
      freeSolo
      ListboxProps={{
        style: { fontSize: '1rem' }
      }}
      value={search}
      onChange={(e, value) => setSearch(value)}
      options={!search || search.length <= 1 ? [] : options}
      renderInput={params => (
        <form onSubmit={handleSubmit}>
          <AriaTextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            // defaultValue={query.get('term')}
            onChange={debouncedChangeHandler}
            placeholder='Search here'
            required
            variant='standard'
            InputProps={{
              ...params.InputProps,
              style: { fontSize: '1rem' },
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position='end'>
                  {search && (
                    <Tooltip title='Clear search'>
                      <IconButton onClick={handleClear} sx={{ color: 'rgba(177,187,228,0.78)' }}>
                        <Clear fontSize='medium' />
                      </IconButton>
                    </Tooltip>
                  )}
                  <IconButton type='submit' sx={{ color: 'rgba(177,187,228,0.78)' }}>
                    <Search fontSize='medium' />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </form>
      )}
    />
  );
};

export default SearchField;
