import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// mui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import BottomDrawer from '../../../components/CustomComponents/BottomDrawer';

// hooks
import useAdcommData from '../hooks/useAdcommData';
import useQuery from '../../../helpers/customHooks/queryParameter';

// components
import SubHeader from '../../../components/SubHeader';
import AriaDrawerCore from '../../SearchResults/components/AriaDrawerCore';
import CommitteeInfo from './CommitteeInfo';
import MeetingPage from './MeetingPage';
import ReportWidgets from '../../../components/Report/Widgets';
import NewFilters from '../../../components/NewFilters';

// utils
import { ADCOMM_FILTERS, LIMIT, MEETING_TABS } from '../constant';

// Store
import GlobalStore from '../../../store';
import AdcommStore from '../../../store/Adcomm';
import AskRiaButton from '../../../components/Buttons/AskRiaButtonAdcomm';
import { Stat } from '../../SearchResults/types';
import useAriaShareHandler from '../../../components/AriaShare/useAriaShareHandler';
import truncateCountAboveMillions from '../../../utils/truncateCountAboveMillions';
import formatCount from '../../../utils/formatCount';
import VISUALIZE_CHART_MAPPING from '../../../components/Insights/const';
import Insights from '../../../components/Insights/Insights';
import styles from '../styles/homePage.styles';

const HomePage = () => {
  const location = useLocation();
  const query = useQuery();
  const history = useHistory();
  const {
    isLoading,
    statsData,
    meetingData,
    // eslint-disable-next-line no-unused-vars
    errorMessage,
    totalPages,
    adcommFilters,
    isFilterApplied,
    meetingIds,
    page,
    setPage,
    getAdcommData
  } = useAdcommData();
  const [selectedTab, setSelectedTab] = useState<string>(query.get('selectedtab') || 'insights');
  const [meetingStatus, setMeetingStatus] = useState<string>(query.get('meetingStatus') || 'all');
  const updatedSearchParams = React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const [reportChoicesOpenAnchorElement, setReportChoiceAnchorElement] =
    useState<HTMLElement | null>(null);

  const { dispatch } = useContext(GlobalStore) as any;
  const { AdcommState } = useContext(AdcommStore) as any;
  const [ariaOption, setAriaOption] = useState<string>('current');
  const [applyFilter, setApplyFilter] = useState<boolean>(false);
  const [appliedFilter, setAppliedFilter] = useState<any>({});
  const [isMember, setIsMember] = useState<boolean>(false);
  const [ariaModalOpen, setAriaModalOpen] = useState(false);
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);
  const [adcommStats, setAdcommStats] = useState<Stat[]>([]);

  useEffect(() => {
    if (query.get('member') === 'true') {
      setIsMember(true);
    }
    if (!applyFilter) {
      getAdcommData(
        LIMIT,
        Math.max(page - 1, 0) * LIMIT,
        meetingStatus || 'all',
        appliedFilter || {},
        query.get('filteredMemberId') || '',
        Boolean(query.get('member')) || false,
        query.get('meetingId')
      );
    }
  }, [meetingStatus, page, query.get('member')]);

  useAriaShareHandler(isLoading, ariaModalOpen, setAriaModalOpen);

  const handlePageChange = useCallback(
    (newPage: number, isFilter: boolean) => {
      setPage(newPage);
      setApplyFilter(isFilter);
      updatedSearchParams.set('page', newPage.toString());
      updatedSearchParams.delete('meetingId');
      history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
    },
    [updatedSearchParams]
  );

  useEffect(() => {
    const curSelectedTab = query.get('selectedtab') || 'insights';
    if (curSelectedTab !== selectedTab) {
      setSelectedTab(curSelectedTab);
    }
    const curPage = query.get('page');
    const curMeetingId = query.get('meetingId');
    // If there was current page set in the url and meeting id is not mentioned in the url.
    if (!curMeetingId && page.toString() !== curPage) {
      setPage(parseInt(query.get('page') || '1', 10));
    }
    const curMeetingStatus = query.get('meetingStatus') || 'all';
    if (curMeetingStatus !== meetingStatus) {
      setMeetingStatus(curMeetingStatus);
    }
  }, [query]);

  const handleMeetingTabChange = useCallback(
    (id: any) => {
      setMeetingStatus(id);
      handlePageChange(1, false);
      updatedSearchParams.set('meetingStatus', id);
      history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
    },
    [updatedSearchParams]
  );

  const handleFilterApply = useCallback(
    (currentfilters: any) => {
      getAdcommData(
        LIMIT,
        0,
        meetingStatus,
        currentfilters,
        query.get('filteredMemberId') || '',
        Boolean(query.get('member')) || false
      );
      setAppliedFilter(currentfilters);
      handlePageChange(1, true);
    },
    [meetingStatus]
  );

  const handleRemoveMember = useCallback(() => {
    getAdcommData(LIMIT, 0, meetingStatus, {}, query.get('filteredMemberId') || '', false);
    setIsMember(false);
    updatedSearchParams.delete('filteredMemberId');
    updatedSearchParams.delete('memberName');
    window.history.replaceState({}, '', `${location.pathname}?${updatedSearchParams.toString()}`);
    handlePageChange(1, false);
  }, [meetingStatus]);

  const getFiltersLabelList = () => {
    return ADCOMM_FILTERS.reduce((acc: any, elem) => {
      acc[elem.value] = elem.label;
      return acc;
    }, {});
  };
  const getAriaDocuments = () => {
    if (ariaOption === 'current') {
      return [{ identifier: AdcommState.meeting_id }];
    }
    if (!isFilterApplied && meetingStatus === 'all') {
      return [];
    }
    const ariaDocuments = meetingIds?.map(item => ({
      identifier: item
    }));
    return ariaDocuments || [];
  };
  const getDocumentStats = (choice: string): any => {
    if (choice === 'document_count') {
      if (ariaOption === 'current') {
        return AdcommState.selected_meeting_doc_count;
      }
      return statsData.stats?.documents;
    }
    if (choice === 'page_count') {
      if (ariaOption === 'current') {
        return AdcommState.selected_meeting_page_count;
      }
      return statsData.stats?.documents_page_count;
    }
    return '';
  };

  useEffect(() => {
    const newStats: Stat[] = [];

    newStats.push({ title: 'Meetings', value: statsData?.stats?.meetings ?? 0 });
    newStats.push({ title: 'Advisors', value: statsData?.stats?.advisors ?? 0 });

    newStats.push({
      title: 'Documents/Pages',
      value: statsData?.stats?.documents ?? 0,
      value2: statsData?.stats?.documents_page_count ?? 0
    });

    setAdcommStats(newStats);
  }, [statsData]);

  const handleVisualizeOpen = () => {
    setIsVisualizeOpen(true);
  };

  const closeDrawer = () => {
    setIsVisualizeOpen(false);
  };

  const getInsightStatsList = useMemo(() => {
    if (!statsData) {
      return [];
    }
    return [
      {
        text: 'Meetings',
        count: statsData?.stats?.meetings,
        toolTipValue: ''
      },
      {
        text: 'Advisors',
        count: statsData?.stats?.advisors,
        toolTipValue: ''
      },
      {
        text: 'Total Documents',
        count: truncateCountAboveMillions(statsData?.stats?.documents),
        toolTipValue: formatCount(statsData?.stats?.documents)
      },
      {
        text: 'Total Pages',
        count: truncateCountAboveMillions(statsData?.stats?.documents_page_count),
        toolTipValue: formatCount(statsData?.stats?.documents_page_count)
      }
    ];
  }, [statsData]);

  const getLayer1Charts = useMemo(() => {
    const meetingGraph = VISUALIZE_CHART_MAPPING.adcomm?.layer1;

    if (!meetingGraph || !statsData?.year_graph) {
      return meetingGraph;
    }
    const updatedGraph = meetingGraph.map((graph: any) => ({
      ...graph,
      data: statsData?.year_graph
    }));

    return updatedGraph;
  }, [statsData?.year_graph]);

  const getLayer2Charts = useMemo(() => {
    const meetingGraph = VISUALIZE_CHART_MAPPING?.adcomm?.layer2;
    if (!meetingGraph) {
      return meetingGraph;
    }

    meetingGraph.forEach(graph => {
      if (graph?.id === 'appl_type_pie_chart' && statsData?.nda_bla) {
        // eslint-disable-next-line no-param-reassign
        graph.data = statsData?.nda_bla;
      }
      if (graph?.id === 'appl_type_post_acm' && statsData?.post_meeting_approval) {
        // eslint-disable-next-line no-param-reassign
        graph.data = statsData?.post_meeting_approval;
      }
    });

    return meetingGraph;
  }, [statsData]);

  return (
    <>
      <SubHeader
        title='Adcomms'
        subTitle='Showing Advisory Committee Meetings'
        stats={adcommStats}
        handleVisualizeOpen={handleVisualizeOpen}
      />

      <BottomDrawer
        isDrawerOpen={isVisualizeOpen}
        closeDrawer={closeDrawer}
        handleReportChoiceAnchorElement={setReportChoiceAnchorElement}
        showTabs
        tabList={MEETING_TABS}
        selectedTab={meetingStatus}
        handleChange={handleMeetingTabChange}>
        <Grid container flexDirection='row' sx={styles.gridContainer}>
          <Grid item xs={10} sx={styles.gridItems}>
            <Insights
              source='us-adcomm'
              searchType='adcomm'
              appliedFilter={{ ...appliedFilter, meeting_status: meetingStatus }}
              statsList={getInsightStatsList}
              layer1Component={getLayer1Charts}
              layer2Component={getLayer2Charts}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={2} sx={styles.gridItems}>
            {Object.keys(statsData).length > 0 && !isLoading ? (
              <CommitteeInfo data={statsData} />
            ) : (
              <Stack gap={2} ml={2} width='100%' height='100%'>
                <Skeleton variant='rectangular' height='15%' width='100%' />
                <Skeleton variant='rectangular' height='30%' width='100%' />
                <Skeleton variant='rectangular' height='30%' width='100%' />
              </Stack>
            )}
          </Grid>
        </Grid>
      </BottomDrawer>

      {!isLoading && (
        <Popover
          open={!!reportChoicesOpenAnchorElement}
          anchorEl={reportChoicesOpenAnchorElement}
          onClose={() => setReportChoiceAnchorElement(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          sx={{
            '& .MuiPopover-paper': {
              borderRadius: '16px !important',
              backgroundColor: 'none'
            }
          }}>
          <Box>
            <ReportWidgets
              widgets={['pie-widget', 'bar-widget', 'table-widget']}
              title=''
              dataSource={{
                source: 'us-adcomm',
                moduleName: 'adcomm',
                disableSelectionSource: true,
                filters: { ...appliedFilter, meeting_status: meetingStatus },
                filterLabelList: getFiltersLabelList()
              }}
              pathMetaData={{
                module: 'adcomm',
                href: window.location.href
              }}
              setReportWidgetClose={setReportChoiceAnchorElement}
            />
          </Box>
        </Popover>
      )}

      {isMember ? (
        <Stack
          spacing={1}
          direction='row'
          justifyContent='flex-start'
          sx={{
            ml: 2.5,
            mt: 1,
            p: 1,
            height: 38,
            borderRadius: 2,
            alignItems: 'center',
            backgroundColor: 'white.main',
            width: 'fit-content',
            border: '1px solid #33B187',
            cursor: 'pointer'
          }}>
          <Typography sx={{ fontSize: '16px' }}>{`Advisory Committee attended by ${query.get(
            'memberName'
          )}`}</Typography>
          <CloseIcon onClick={() => handleRemoveMember()} cursor='pointer' fontSize='small' />
        </Stack>
      ) : null}

      <NewFilters data={adcommFilters} applyFilters={handleFilterApply} isLoading={isLoading} />

      <Box display='flex' flexDirection='column' width='inherit' pt={1} marginBottom='100px'>
        <MeetingPage
          isLoading={isLoading}
          meetingData={meetingData}
          totalPages={totalPages}
          page={page}
          handlePageChange={handlePageChange}
          updatedSearchParams={updatedSearchParams}
          meetingStatus={meetingStatus}
          handleMeetingTabChange={handleMeetingTabChange}
        />
      </Box>

      {ariaModalOpen && (
        <AriaDrawerCore
          applications={getAriaDocuments()}
          sources={{ us: ['adcomm'] }}
          groupId='entity_id'
          docCount={getDocumentStats('document_count')}
          docPageCount={getDocumentStats('page_count')}
          modalOpen={ariaModalOpen}
          setModalOpen={setAriaModalOpen}
          dispatch={dispatch}
        />
      )}

      <Box sx={{ position: 'fixed', bottom: '20px', right: '0', zIndex: 1 }}>
        <AskRiaButton
          setOpenAriaSearch={setAriaModalOpen}
          ariaOption={ariaOption}
          setAriaOption={setAriaOption}
          meetingStatus={meetingStatus}
          disabled={meetingIds.length === 0}
          currentMeeting={getDocumentStats('document_count')}
        />
      </Box>
    </>
  );
};
export default React.memo(HomePage);
