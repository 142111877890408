import theme from '../../../theme';

const styles = {
  box: {
    borderRadius: '3px',
    width: '69.5vw'
  },
  barGraph: {
    backgroundColor: 'white.main',
    height: '40vh',
    borderRadius: '20px',
    [theme.breakpoints.up('xl')]: {
      height: '35vh'
    }
  },
  cards: {
    height: '80vh'
  },
  paper: {
    margin: 3,
    width: '50%',
    height: '50%',
    padding: 3,
    borderRadius: 3
  },
  graphHeading: {
    fontSize: '20px',
    color: 'gray.lightVariant',
    fontWeight: 'bold'
  },
  reportText: {
    color: 'primary',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    '&:hover': {
      background: 'none'
    }
  },
  pieCharts: {
    height: '30vh',
    [theme.breakpoints.up('xl')]: {
      height: '40vh'
    }
  },
  pieContainers: {
    heigth: '100%',
    marginTop: 2,
    marginLeft: 1,
    padding: 2,
    width: '50%',
    backgroundColor: 'white.main',
    borderRadius: '10px'
  },
  loaderBox: {
    width: '90%',
    height: '90%'
  },
  skeltonCircle: {
    width: '100px',
    height: '100px'
  },
  barGraphRect: {
    width: '100%',
    height: '100%'
  },
  circleLoading: {
    color: '#adadac',
    opacity: 0.5
  },
  addToReport: {
    height: '35px',
    cursor: 'pointer',
    background: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: 'none',
      border: 1,
      borderColor: 'primary.main',
      borderStyle: 'solid'
    }
  },
  pieChartMargin: { top: 20, right: 40, bottom: 50, left: 30 }
};

export default styles;
