import React, { Fragment } from 'react';
//MATERIAL UI IMPORTS
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
//material icons
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

//other components
import MyResponsiveRadialBar from '../../CustomComponents/Graphs/CustomeRadialBar';
import ProgressStyles from '../styles/ProgressGraph.styles';

const ProgressGraph = ({ data }) => {
  const classes = ProgressStyles();
  const sizes = [250, 200, 150, 100, 50];
  const graphData = data
    ?.sort((a, b) => {
      return a.value - b.value;
    })
    .map(item => {
      const temp = [
        {
          x: item.id,
          y: parseInt(item.value)
        }
      ];
      return { id: item.id, data: temp };
    });

  const colorMapping = {
    4: '#2B43A6',
    3: '#6868FA',
    2: '#AB54DB',
    1: '#29ABE2',
    0: '#B1BBE4'
  };

  return (
    <Fragment>
      <Box display='flex' flexDirection='row' justifyContent='space-evenly'>
        <Box className={classes.graph}>
          {!data ? (
            <>
              <Box className={classes.circle}>
                <CircularProgress
                  size={sizes[0]}
                  animation='pulse'
                  value={100}
                  thickness={2}
                  variant='determinate'
                  className={classes.track}
                />
                <CircularProgress
                  size={sizes[1]}
                  value={100}
                  thickness={3}
                  variant='determinate'
                  className={classes.track}
                />
                <CircularProgress
                  size={sizes[2]}
                  value={100}
                  thickness={4}
                  variant='determinate'
                  className={classes.track}
                />
                <CircularProgress
                  size={sizes[3]}
                  value={100}
                  thickness={5}
                  variant='determinate'
                  className={classes.track}
                />
                <CircularProgress
                  size={sizes[4]}
                  value={100}
                  thickness={6}
                  variant='determinate'
                  className={classes.track}
                />
              </Box>
            </>
          ) : (
            <>
              {graphData.length > 0 ? (
                <MyResponsiveRadialBar data={graphData} keys={['value']} index='id' />
              ) : (
                <Box display='flex' alignItems='center' ml='50%'>
                  <Typography>No Data</Typography>
                </Box>
              )}
            </>
          )}
        </Box>
        <Box display='flex' flexDirection='column'>
          {data ? (
            data &&
            graphData.map((item, index) => {
              return (
                <Box mt={1} display='flex' flexDirection='row'>
                  <FiberManualRecordIcon
                    fontSize='small'
                    className={classes.icon}
                    style={{ color: colorMapping[index] }}
                  />
                  <Typography align='left' className={classes.text}>
                    {item.data[0].y}% &ensp; {item.id}
                  </Typography>
                </Box>
              );
            })
          ) : (
            <>
              <Skeleton variant='text' width={200} />
              <Skeleton variant='text' width={200} />
              <Skeleton variant='text' width={200} />
              <Skeleton variant='text' width={200} />
              <Skeleton variant='text' width={200} />
            </>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

export default ProgressGraph;
