import React, { useEffect, useMemo, useState } from 'react';

import {
  Chip,
  Tooltip,
  Divider,
  Radio,
  Stack,
  Button,
  InputAdornment,
  TextField,
  Skeleton,
  Box,
  Typography,
  Badge,
  Popover
} from '@mui/material';

import { flattenDeep, isArray } from 'lodash';

import * as Select from '../CustomComponents/Selects';
import { SECTION_LABELS } from './const';

import comparisonClasses from './styles/LabelSelection.style';

import {
  LABEL_COLOR,
  sourceFlagMapping,
  sourceFlagMappingTooltip,
  LABEL_TOOLTIP_TITLE
} from '../../pages/SearchResults/constants';
import {
  SortIconVariant,
  MagnifyingGlassIcon,
  InfoIconSummary,
  KeyDownIcon
} from '../../assets/svgs/Icons';
import labelListStyles from './styles/LabelList.styles';
import Card from '../../pages/SearchResults/components/Card';
import SwitchWithLoader from '../Switch/SwitchWithLoader';

const { VivproSelect }: any = Select; // Work around for usage error.

interface LabelListProps {
  selectedApplication: any;
  isLoading: boolean;
  labelList: Array<any> | undefined;
  selectedLabel: any;
  errorMessage: string | undefined;
  // eslint-disable-next-line no-unused-vars
  onLabelSelect: (label: any, section: string) => void;
}

const LabelList: React.FC<LabelListProps> = ({
  selectedApplication,
  isLoading,
  labelList,
  selectedLabel,
  errorMessage,
  onLabelSelect
}) => {
  const FlagComponent = sourceFlagMapping[selectedApplication?.source?.toLowerCase()];
  const flagTooltipTitle = sourceFlagMappingTooltip[selectedApplication?.source?.toLowerCase()];
  const [oldestTONewest, setOldestTONewest] = useState(false);
  const [cartonFilter, setCartonFilter] = useState({
    filterApplied: false,
    count: 0
  });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [localSearchQuery, setLocalSearchQuery] = useState('');

  useEffect(() => {
    if (labelList) {
      const sortedLabel = JSON.parse(JSON.stringify(labelList));
      setCartonFilter({
        ...cartonFilter,
        count: sortedLabel.reduce((count: number, obj: any) => {
          return count + (obj.label_type === 'carton' ? 1 : 0);
        }, 0)
      });
    }
  }, [labelList]);

  const handleSortBy = () => {
    setOldestTONewest((prev: boolean) => {
      return !prev;
    });
  };

  const handleCartonFilterBy = () => {
    setCartonFilter({
      ...cartonFilter,
      filterApplied: !cartonFilter.filterApplied
    });
  };

  const labelListToShow = useMemo(() => {
    if (!labelList) return [];

    const query = localSearchQuery?.trim()?.toLowerCase();

    return labelList
      .filter(label => {
        // Apply carton filter
        if (cartonFilter.filterApplied && label.label_type === 'carton') {
          return false;
        }

        // Apply search filter
        if (query) {
          const matchesQuery = [
            'approval_date',
            'description',
            'submission_type',
            'submission_num'
          ].some(field => {
            let fieldValue = label?.[field];

            if (field === 'submission_type' || field === 'submission_num') {
              const submissionType = label?.submission_type ?? '';
              const submissionNum = label?.submission_num ?? '';
              fieldValue = `${submissionType}-${submissionNum}`;
            }
            return fieldValue?.toString()?.toLowerCase()?.includes(query);
          });

          if (matchesQuery) {
            return true;
          }

          const changes = isArray(label?.change)
            ? flattenDeep(label?.change)?.toString()?.toLowerCase()
            : label?.change?.toLowerCase();

          if (changes?.includes(query)) {
            return true;
          }

          return false;
        }

        return true;
      })
      .sort((a: any, b: any) => {
        return oldestTONewest
          ? new Date(a.approval_date).getTime() - new Date(b.approval_date).getTime()
          : new Date(b.approval_date).getTime() - new Date(a.approval_date).getTime();
      });
  }, [labelList, oldestTONewest, cartonFilter, localSearchQuery]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalSearchQuery(e.target.value);
  };

  const getApplicationChipTag = () => {
    return (
      selectedApplication?.labels &&
      selectedApplication?.labels.map((label: string) => {
        const labelKey = label.toLowerCase();
        const labelColor = LABEL_COLOR[labelKey];
        const labelTooltipTitle = LABEL_TOOLTIP_TITLE[labelKey];
        return labelColor ? (
          <Tooltip
            title={labelTooltipTitle}
            disableInteractive
            disableFocusListener
            disableTouchListener>
            <Chip
              label={label}
              key={label}
              sx={{ ...labelListStyles.applicationChip, bgcolor: labelColor }}
            />
          </Tooltip>
        ) : null;
      })
    );
  };

  const isLabelSelected = (label: any) => {
    return (
      selectedLabel?.submissionType === label.submission_type &&
      selectedLabel?.submissionNum === label.submission_num &&
      selectedLabel?.description === label.description &&
      selectedLabel?.approvalDate === label.approval_date
    );
  };

  const keyDownIconComponent = () => {
    return <KeyDownIcon sx={labelListStyles.keyDownIcon} />;
  };

  const getChangeText = (change: any) => {
    if (isArray(change)) {
      return flattenDeep(change)?.toString();
    }

    return change;
  };

  function formatApplicationName(): string {
    if (!selectedApplication) return '';

    const {
      application_name: applicationName,
      application_number: applicationNumber,
      product_number: productNumber
    } = selectedApplication;

    // Extract part of the application name after the '-' if it exists
    const formattedName =
      applicationName && applicationName.indexOf('-') !== -1
        ? applicationName.substring(applicationName.indexOf('-') + 1)
        : applicationName || '';

    // Select either the application number or the product number
    const number = applicationNumber || productNumber || '';

    return `${formattedName} - ${number}`;
  }

  return (
    <>
      <Box sx={labelListStyles.mainHeaderWrapper}>
        <Stack direction='row' alignItems='center' spacing={0.5}>
          <Tooltip title={formatApplicationName()}>
            <Typography sx={comparisonClasses.selectLabel} noWrap>
              {formatApplicationName()}
            </Typography>
          </Tooltip>
          <Tooltip
            title={flagTooltipTitle}
            disableInteractive
            disableFocusListener
            disableTouchListener>
            <FlagComponent sx={labelListStyles.flagIcon} />
          </Tooltip>
          {selectedApplication?.labels && getApplicationChipTag()}
        </Stack>

        {selectedApplication?.groupTitle && (
          <Stack>
            <InfoIconSummary
              onMouseEnter={e => handlePopoverOpen(e)}
              onMouseLeave={handlePopoverClose}
              sx={labelListStyles.infoIcon}
            />
          </Stack>
        )}
      </Box>

      <Popover
        id='mouse-over-popover'
        sx={labelListStyles.popoverContainer}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <Box sx={labelListStyles.cardWrapper}>
          <Card
            data={selectedApplication}
            hideApplication={() => {}}
            triggerChatRIA={() => {}}
            setFavoriteInHook={() => {}}
            setInProjectInHook={() => {}}
            setSubscriptionInHook={() => {}}
            hideCardOptions
          />
        </Box>
      </Popover>

      <Box sx={comparisonClasses.compareBox}>
        {isLoading ? (
          <Box p={1}>
            <Skeleton variant='text' width='30%' />
            <Skeleton variant='rectangular' width='90%' height={150} />
            <Skeleton variant='text' width='30%' />
            <Skeleton variant='rectangular' width='90%' height={150} />
            <Skeleton variant='text' width='30%' />
            <Skeleton variant='rectangular' width='90%' height={150} />
          </Box>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {errorMessage ? (
              <Box sx={comparisonClasses.labelLoadErrorMessage}>{errorMessage}</Box>
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  sx={labelListStyles.searchBarWrapper}>
                  <TextField
                    sx={labelListStyles.searchBarTextField}
                    placeholder='Find label'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <MagnifyingGlassIcon sx={labelListStyles.searchIcon} />
                        </InputAdornment>
                      )
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{
                      maxLength: 50
                    }}
                    onChange={handleSearchChange}
                    value={localSearchQuery}
                  />
                  <Stack
                    direction='row'
                    divider={<Divider orientation='vertical' flexItem />}
                    spacing={0.5}
                    sx={labelListStyles.tempCtaContainer}>
                    {selectedApplication?.source.toLowerCase() === 'us' &&
                      cartonFilter.count !== 0 && (
                        <Tooltip
                          title={
                            cartonFilter.filterApplied
                              ? 'Show all carton labels in the list'
                              : 'Hide all carton labels in the list'
                          }>
                          <>
                            <Typography>Show Cartons</Typography>
                            <SwitchWithLoader
                              value={!cartonFilter.filterApplied}
                              checked={!cartonFilter.filterApplied}
                              loading={false}
                              onChange={handleCartonFilterBy}
                            />
                          </>
                        </Tooltip>
                      )}
                    <Tooltip
                      title={`Sort by ${oldestTONewest ? 'Newest to Oldest' : 'Oldest to Newest'}`}>
                      <Button onClick={handleSortBy} sx={labelListStyles.sortIconButton}>
                        <Badge variant='dot' invisible={!oldestTONewest} color='primary'>
                          <SortIconVariant sx={labelListStyles.sortIcon} />
                        </Badge>
                      </Button>
                    </Tooltip>
                  </Stack>
                </Stack>
                <Box sx={labelListStyles.mainSectionWrapper}>
                  {labelListToShow &&
                    labelListToShow.map((item: any) => {
                      const labelChangeText =
                        // eslint-disable-next-line no-nested-ternary
                        !item.change?.[0] || item.change?.[0] === 'UNKNOWN'
                          ? selectedApplication?.source.toLowerCase() === 'eu'
                            ? `${item.variation_number}, Action details: Unknown`
                            : 'Action details: Unknown'
                          : selectedApplication?.source.toLowerCase() === 'eu'
                          ? `${item.variation_number}, ${getChangeText(item.change)}`
                          : getChangeText(item.change);

                      const submissionText = `${item.submission_type}${
                        item.submission_num ? `-${item.submission_num}` : ''
                      } ${item.description}`;

                      const submissionTooltipLimit = isLabelSelected(item) ? 25 : 50;

                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      return (
                        <React.Fragment
                          key={`${item.submission_type}-${item.approval_date}-${item.submission_num}`}>
                          {item.label.sections && item.label.sections.length > 0 && (
                            <Box
                              sx={
                                isLabelSelected(item)
                                  ? comparisonClasses.labelContainer__selected
                                  : comparisonClasses.labelContainer
                              }>
                              <Box mr='9px'>
                                <Radio
                                  size='small'
                                  checked={isLabelSelected(item)}
                                  sx={labelListStyles.radio}
                                  onClick={() => {
                                    onLabelSelect(item, '');
                                  }}
                                />
                              </Box>
                              <Box
                                flex={1}
                                onClick={() => {
                                  onLabelSelect(item, '');
                                }}>
                                <Box display='flex' alignItems='center' height='18px'>
                                  {selectedApplication?.source.toLowerCase() === 'eu' && (
                                    <Tooltip title={`Source: ${item.source}`}>
                                      <Chip
                                        label={item.source}
                                        sx={{
                                          ...labelListStyles.applicationChip,
                                          bgcolor: LABEL_COLOR.ema,
                                          ml: 0,
                                          mr: 1
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                  <Typography
                                    sx={{
                                      ...comparisonClasses.labelDate,
                                      ...(isLabelSelected(item) && { color: 'primary.700' })
                                    }}>
                                    {item.approval_date}
                                  </Typography>
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    sx={labelListStyles.divider}
                                  />
                                  <Tooltip
                                    title={
                                      submissionText?.length > submissionTooltipLimit
                                        ? submissionText
                                        : ''
                                    }>
                                    <Typography
                                      sx={{
                                        ...comparisonClasses.labelSup,
                                        ...(isLabelSelected(item) && { color: 'primary.700' })
                                      }}>
                                      {`${item.submission_type}${
                                        item.submission_num ? `-${item.submission_num}` : ''
                                      }`}{' '}
                                      &nbsp;
                                      {item.description}
                                    </Typography>
                                  </Tooltip>

                                  {selectedApplication?.source.toLowerCase() === 'us' &&
                                    item?.label_type &&
                                    item?.label_type === 'carton' && (
                                      <Box sx={labelListStyles.cartonContainer}>
                                        <Chip
                                          size='small'
                                          label='CARTON'
                                          sx={labelListStyles.cartonChip}
                                        />
                                      </Box>
                                    )}
                                </Box>
                                <Box display='flex' flexDirection='column'>
                                  <Box
                                    sx={comparisonClasses.compareText}
                                    justifyContent='space-between'
                                    display='flex'>
                                    <Tooltip
                                      title={labelChangeText?.length > 140 ? labelChangeText : ''}>
                                      {!item.change?.[0] || item.change?.[0] === 'UNKNOWN' ? (
                                        <span>
                                          {selectedApplication?.source.toLowerCase() === 'eu' &&
                                            `${item.variation_number}, `}
                                          <b>Action details:</b> Unknown
                                        </span>
                                      ) : (
                                        <Typography fontSize='13px'>
                                          {selectedApplication?.source.toLowerCase() === 'eu'
                                            ? `${item.variation_number}, `
                                            : ''}
                                          {getChangeText(item.change)}
                                        </Typography>
                                      )}
                                    </Tooltip>
                                  </Box>
                                </Box>
                              </Box>
                              <Box display='flex' pl='21px' alignItems='center'>
                                {selectedLabel?.submissionType === item.submission_type &&
                                  selectedLabel?.submissionNum === item.submission_num &&
                                  selectedLabel?.description === item.description &&
                                  selectedLabel?.approvalDate === item.approval_date && (
                                    <Box sx={labelListStyles.selectWrapper}>
                                      <VivproSelect
                                        value={selectedLabel?.section}
                                        options={(item.label.sections || []).map(
                                          (section: any) => ({
                                            value: section.section,
                                            label: SECTION_LABELS[section.section]
                                          })
                                        )}
                                        handleChange={(event: any) => {
                                          onLabelSelect(item, event.target.value);
                                        }}
                                        icon={keyDownIconComponent}
                                        additionalStyles={labelListStyles.select}
                                      />
                                    </Box>
                                  )}
                              </Box>
                            </Box>
                          )}
                          <Divider sx={labelListStyles.bottomDivider} />
                        </React.Fragment>
                      );
                    })}

                  {localSearchQuery.length > 0 && labelListToShow.length === 0 && (
                    <Box sx={labelListStyles.emptyResultContainer}>No labels found.</Box>
                  )}

                  {localSearchQuery === '' && labelListToShow.length === 0 && (
                    <Box sx={labelListStyles.emptyResultContainer}>
                      Select a label from the left
                    </Box>
                  )}
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default React.memo(LabelList);
