import React, { useEffect, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';

// api
import { getCanadaConsultationDocDetails } from '../../../api/pages/CanadaConsultation';
// store
import store from '../../../store/CanadaConsultation';
import actions from '../../../store/CanadaConsultation/actions';

// icons
import DocumentIcon from '../../../Images/documentsIcon.svg';

// Styles
import styles from '../../EMAGuidance/styles/CompareChip';

interface ComparisonButtonProps {
  documentsToCompare: any[];
  // eslint-disable-next-line no-unused-vars
  removeFromComparison: (id: string) => void;
  // eslint-disable-next-line no-unused-vars
  openComparisonPopup: (value: boolean) => void;
  clearComparison: () => void;
  fromDocumentHistory: boolean;
}

const ComparisonButtons = ({
  documentsToCompare,
  removeFromComparison,
  openComparisonPopup,
  clearComparison,
  fromDocumentHistory = false
}: ComparisonButtonProps) => {
  const { CanadaConsultationDispatch } = useContext(store);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getDocumentDetails = async (doc: any) => {
      // eslint-disable-next-line no-underscore-dangle
      const res = await getCanadaConsultationDocDetails(doc._id);
      if (res.data.status === 200) {
        if (res.data.body.Consultation && res.data.body.Consultation.length > 0) {
          const docDetails = res.data.body.Consultation[0];
          CanadaConsultationDispatch({
            type: actions.UPDATE_S3_URL,
            // eslint-disable-next-line no-underscore-dangle
            value: { _id: doc._id, s3_url: docDetails.s3_url }
          });
        }
      }
    };
    const fetchData = async () => {
      if (documentsToCompare.length === 2) {
        setLoading(true);
        await Promise.all(documentsToCompare.map(doc => getDocumentDetails(doc)));
        setLoading(false);
      }
    };
    if (!fromDocumentHistory) {
      fetchData();
    }
  }, [documentsToCompare.length]);

  return (
    <Stack spacing={1} sx={{ pb: 1 }} alignItems='center' direction='row'>
      {documentsToCompare.map((doc: any) => (
        <Stack
          // eslint-disable-next-line no-underscore-dangle
          key={`selected_doc${doc._id}`}
          spacing={1}
          direction='row'
          justifyContent='flex-start'
          sx={{ ...styles.selectedOptionsContainer, borderColor: 'primary.main' }}>
          <img src={DocumentIcon} alt='documentLogo' />
          <Tooltip title={doc.doc_title}>
            <Typography noWrap variant='subtitle2' flex={3}>
              {doc.doc_title}
            </Typography>
          </Tooltip>
          <Box ml='auto !important' flex={0.5}>
            <CloseIcon
              cursor='pointer'
              fontSize='small'
              onClick={() => {
                removeFromComparison(doc);
              }}
            />
          </Box>
        </Stack>
      ))}
      {Array(2 - documentsToCompare.length)
        .fill(1)
        .map((_, index: number) => (
          <Stack
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            spacing={1}
            direction='row'
            justifyContent='flex-start'
            sx={{ ...styles.selectedOptionsContainer, pt: 1.4, pb: 1.4 }}>
            <img src={DocumentIcon} alt='documentLogo' />
            <Tooltip title={`Document ${index + 1 + documentsToCompare.length}`}>
              <Typography noWrap variant='subtitle2' flex={3} fontWeight='bold'>
                {`Document ${index + 1 + documentsToCompare.length}`}
              </Typography>
            </Tooltip>
          </Stack>
        ))}
      <Stack spacing={2} alignItems='center' direction='row'>
        <Button
          variant='contained'
          type='submit'
          sx={{
            color: 'white.main',
            fontWeight: 'bold',
            borderRadius: '30px',
            textTransform: 'capitalize'
          }}
          onClick={() => openComparisonPopup(true)}
          disabled={documentsToCompare.length !== 2 || (!fromDocumentHistory && loading)}>
          Compare
        </Button>
        <Button
          variant='contained'
          type='submit'
          disabled={documentsToCompare.length === 0}
          sx={{
            color: 'white.main',
            fontWeight: 'bold',
            borderRadius: '30px',
            textTransform: 'capitalize'
          }}
          onClick={clearComparison}>
          Clear Selection
        </Button>
      </Stack>
    </Stack>
  );
};

export default React.memo(ComparisonButtons);
