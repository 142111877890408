import { ContextInterface } from '.';
import actions from './actions';

interface ActionProps {
  type: string;
  value: any;
}

export const initialState = {
  stats: {},
  projects: [],
  errors: [],
  filters: [],
  availableFilters: {},
  isLoading: false,
  isFiltersApplied: false,
  programTypeChart: {},
  submissionStatusChart: {},
  barGraph: {},
  charts: []
};

const reducers = (state: ContextInterface, action: ActionProps) => {
  switch (action.type) {
    case actions.SET_PROJECTS: {
      return {
        ...state,
        projects: action.value
      };
    }
    case actions.SET_STATS: {
      return {
        ...state,
        stats: action.value
      };
    }
    case actions.SET_ERRORS: {
      return {
        ...state,
        errors: action.value
      };
    }
    case actions.SET_FILTERS: {
      return {
        ...state,
        filters: action.value
      };
    }
    case actions.SET_AVAILABLE_FILTERS: {
      return {
        ...state,
        availableFilters: action.value
      };
    }
    case actions.RESET_FILTERS: {
      return {
        ...state,
        filters: {}
      };
    }
    case actions.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.value
      };
    }
    case actions.SET_IS_FILTERS_APPLIED: {
      return {
        ...state,
        isFiltersApplied: action.value
      };
    }
    case actions.SET_PROGRAM_TYPE_CHART: {
      return {
        ...state,
        programTypeChart: action.value
      };
    }
    case actions.SET_SUBMISSION_STATUS_CHART: {
      return {
        ...state,
        submissionStatusChart: action.value
      };
    }
    case actions.SET_BAR_GRAPH: {
      return {
        ...state,
        barGraph: action.value
      };
    }
    case actions.SET_CHARTS: {
      return {
        ...state,
        charts: action.value
      };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
