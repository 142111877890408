import React from 'react';

import { Box, Skeleton, Typography } from '@mui/material';
import styles from './styles';
import { SKELETON_HEIGHT } from './const';

const Plot = React.lazy(() => import('react-plotly.js'));
interface PlotGraphProps {
  graphData: any;
  loading: boolean;
}

const PlotChart = ({ graphData, loading }: PlotGraphProps) => {
  return (
    <Box display='flex' justifyContent='center' alignItems='center' sx={styles.barGraph}>
      {loading ? (
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='flex-end'
          alignItems='space-between'
          sx={styles.loaderBox}>
          <Box
            display='flex'
            justifyContent='space-between'
            flexWrap='wrap-reverse'
            sx={styles.barGraphRect}>
            {SKELETON_HEIGHT.map((item: any) => (
              <Skeleton
                key={item?.id}
                variant='rectangular'
                height={item?.height}
                width={item?.width}
              />
            ))}
          </Box>
          <Skeleton variant='rectangular' height='.2%' width='100%' />
        </Box>
      ) : (
        <Box height='100%' width='100%'>
          {graphData?.data && graphData?.data?.length > 0 ? (
            <Plot
              data={graphData?.data?.map((dataset: any) => ({
                ...dataset,
                x: dataset.y,
                y: dataset.x,
                orientation: 'h',
                marker: graphData?.graphStyle?.marker
              }))}
              layout={graphData?.graphStyle?.layout}
              style={{ width: '100%', height: '100%' }}
              useResizeHandler
              config={graphData?.graphStyle?.config}
            />
          ) : (
            <Typography variant='subtitle2' sx={{ fontSize: 20, fontWeight: 'bold' }}>
              No data
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default React.memo(PlotChart);
