import { memo, useContext, useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Grow,
  Stack,
  Tooltip,
  IconButton,
  Box,
  ListItem,
  Select,
  MenuItem,
  Divider,
  SelectChangeEvent
} from '@mui/material';
import { AddCircleOutlined, RemoveCircleOutline } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import { v4 } from 'uuid';
import ResultsStore from '../../../store/SearchResults';
import GlobalStore from '../../../store';
import ResultsActions from '../../../store/SearchResults/actions';
import GlobalActions from '../../../store/actions';
import { getApplicationsByLabelSection } from '../data/collatedSummary';
import { SOURCE_MAPPING, DISABLED_LABEL_SECTION_SOURCES } from '../constants';
import { CrossIcon } from '../../../assets/svgs/Icons';

// styles
import styles from '../styles/LabelSectionSelection.styles';
import DropDown from '../../../assets/svgs/Icons/DropDown';
import { DATAGRID_UNIQUE_FIELD } from '../../ResultsPage/utils/constants';
import getAllSections from '../../../components/Comparison/utils';

const LabelSectionSelectionDialog = () => {
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const { dispatch }: any = useContext<any>(GlobalStore);

  const getSourceWiseApplicationsWithSection = () => {
    const allApplications = resultsState?.applicationResults;

    if (!allApplications) {
      return [];
    }

    const sourceWiseApplications: any = {};

    resultsState?.collatedSummarySelectedCategories?.forEach((label: any) => {
      const sourceApplications = sourceWiseApplications[label.source];

      if (sourceApplications) {
        sourceApplications.sections = [...sourceApplications.sections, label.sections];
      } else {
        sourceWiseApplications[label.source] = {
          sections: [label.sections],
          applications:
            allApplications?.[label.source]?.results?.map(
              (currentApplication: any) => currentApplication[DATAGRID_UNIQUE_FIELD[label.source]]
            ) || []
        };
      }
    });

    return sourceWiseApplications;
  };

  const addNewLabelRow = () => {
    resultsDispatch({
      type: ResultsActions.SET_COLLATED_SUMMARY_SELECTED_CATEGORIES,
      value: [
        ...(resultsState?.collatedSummarySelectedCategories || []),
        {
          id: v4(),
          source: '',
          sections: ''
        }
      ]
    });
  };

  const handleApplicationSelection = async () => {
    resultsDispatch({ type: ResultsActions.SET_IS_CONTENT_LOADING, value: true });
    resultsDispatch({ type: ResultsActions.SET_COLLATED_SUMMARY_APPLICATIONS, value: [] });
    resultsDispatch({
      type: ResultsActions.SET_COLLATED_SUMMARY_SELECTED_APPLICATIONS,
      value: []
    });

    const payload = getSourceWiseApplicationsWithSection();

    const { data } = await getApplicationsByLabelSection(payload);

    resultsDispatch({ type: ResultsActions.SET_COLLATED_SUMMARY_SELECTION, value: false });
    resultsDispatch({ type: ResultsActions.SET_IS_CONTENT_LOADING, value: false });

    if (!data?.Success?.result) {
      dispatch({
        type: GlobalActions.SET_ALERT,
        value: {
          status: true,
          message: data?.message,
          duration: 7000
        }
      });
      return;
    }

    resultsDispatch({
      type: ResultsActions.SET_COLLATED_SUMMARY_APPLICATIONS,
      value: data.Success.result
    });
  };

  const changeSource = (event: SelectChangeEvent, id: any) => {
    const categories = resultsState?.collatedSummarySelectedCategories?.map(currentCategory => {
      return currentCategory.id === id
        ? { ...currentCategory, source: event.target.value, sections: '' }
        : { ...currentCategory };
    });

    resultsDispatch({
      type: ResultsActions.SET_COLLATED_SUMMARY_SELECTED_CATEGORIES,
      value: [...categories]
    });
  };

  const changeSection = (event: SelectChangeEvent, id: any) => {
    const categories = resultsState?.collatedSummarySelectedCategories?.map(currentCategory => {
      return currentCategory.id === id
        ? { ...currentCategory, sections: event.target.value }
        : { ...currentCategory };
    });

    resultsDispatch({
      type: ResultsActions.SET_COLLATED_SUMMARY_SELECTED_CATEGORIES,
      value: [...categories]
    });
  };

  const removeItem = (row: any) => {
    const remainingLabelCategories = resultsState?.collatedSummarySelectedCategories?.filter(
      currentCategory => currentCategory.id !== row.id
    );

    resultsDispatch({
      type: ResultsActions.SET_COLLATED_SUMMARY_SELECTED_CATEGORIES,
      value: [...remainingLabelCategories]
    });
  };

  const disableButton = useMemo(() => {
    return (
      Object.values(resultsState?.collatedSummarySelectedCategories).filter(
        a => a.source === '' || a.sections === ''
      ).length > 0 || resultsState?.collatedSummarySelectedCategories?.length < 1
    );
  }, [resultsState?.collatedSummarySelectedCategories]);

  const isSectionDisabled = (source: string, section: string) => {
    return resultsState?.collatedSummarySelectedCategories
      ?.filter((category: any) => category.source === source)
      ?.some((category: any) => category.sections === section);
  };

  const closeLabelSectionModal = () => {
    resultsDispatch({ type: ResultsActions.SET_COLLATED_SUMMARY_SELECTION, value: false });
  };

  const isSourceSelectionDisabled = (source: string) => {
    return resultsState?.collatedSummarySelectedCategories?.some(
      currentCategory => currentCategory.source === source
    );
  };

  const isAddMoreRowDisabled = useMemo(() => {
    const allSources = Object.keys(resultsState.applicationResults).filter(
      (source: any) => SOURCE_MAPPING[source]
    );

    return resultsState?.collatedSummarySelectedCategories?.length === allSources.length;
  }, [resultsState?.collatedSummarySelectedCategories]);

  return (
    <Dialog
      open={resultsState?.collatedSummarySelection}
      fullWidth
      maxWidth='md'
      sx={styles.dialogRoot}>
      <Box>
        <DialogTitle sx={styles.dialogTitle}>
          Specific Label Section
          <Tooltip title='Close'>
            <IconButton onClick={closeLabelSectionModal} sx={styles.iconButton}>
              <CrossIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <Box sx={styles.dividerWrapper}>
          <Divider sx={styles.divider} />
        </Box>
      </Box>

      <DialogContent sx={styles.dialogContent}>
        <DialogContentText sx={styles.dialogContentText}>
          Select the label section by source for document compilation (latest label)
        </DialogContentText>
        {resultsState?.collatedSummarySelectedCategories?.map((row, index) => (
          <Box key={row.id} sx={styles.allSelectionContainer}>
            <Box sx={styles.listItemWrapper}>
              <ListItem sx={styles.listItem}>
                <Stack direction='row' width='100%'>
                  <Select
                    sx={{
                      ...styles.advanceSearchSelect,
                      ...(isEmpty(row.source)
                        ? { color: 'red.900', '& svg>path': { fill: 'red.900' } }
                        : { color: 'gray.800' }),
                      ...{ width: '106px', fontWeight: 700 }
                    }}
                    value={row.source ?? ''}
                    onChange={(event: SelectChangeEvent) => {
                      changeSource(event, row.id);
                    }}
                    labelId={`${index}-category-select-source`}
                    id={`${index}-category-select`}
                    defaultValue={row.source ?? ''}
                    displayEmpty
                    IconComponent={DropDown}
                    placeholder='Select category'>
                    <MenuItem value='' disabled>
                      Select Source
                    </MenuItem>
                    {Object.keys(resultsState.applicationResults)
                      .filter((source: any) => SOURCE_MAPPING[source])
                      .map((source: any) => (
                        <MenuItem
                          value={source}
                          itemID={source}
                          aria-label={source}
                          key={source}
                          disabled={isSourceSelectionDisabled(source)}>
                          {SOURCE_MAPPING[source]}
                        </MenuItem>
                      ))}
                    {Object.keys(resultsState.applicationResults)
                      .filter((source: any) => DISABLED_LABEL_SECTION_SOURCES[source])
                      .map((source: any) => (
                        <Tooltip title='Coming Soon' placement='left' key={source}>
                          <div>
                            <MenuItem
                              value={source}
                              itemID={source}
                              aria-label={source}
                              key={source}
                              style={styles.disabledMenuItem}>
                              {DISABLED_LABEL_SECTION_SOURCES[source]}
                            </MenuItem>
                          </div>
                        </Tooltip>
                      ))}
                  </Select>
                  <Divider
                    orientation='vertical'
                    flexItem
                    variant='middle'
                    sx={styles.horizontalDivider}
                  />
                  <Select
                    sx={{
                      ...styles.advanceSearchSelect,
                      ...(isEmpty(row.sections)
                        ? { color: 'red.900', '& svg>path': { fill: 'red.900' } }
                        : { color: 'gray.800' }),
                      ...{ width: 'inherit' }
                    }}
                    value={row.sections ?? ''}
                    onChange={(event: SelectChangeEvent) => {
                      changeSection(event, row.id);
                    }}
                    labelId={`${index}-category-select-sections`}
                    id={`${index}-category-sections`}
                    defaultValue={row.sections ?? ''}
                    displayEmpty
                    IconComponent={DropDown}
                    placeholder='Select Section'>
                    <MenuItem value='' disabled>
                      Select Section
                    </MenuItem>
                    {row.source &&
                      getAllSections(row.source)?.map((section: any) => {
                        return (
                          <MenuItem
                            key={section}
                            value={section}
                            itemID={section}
                            aria-label={section}
                            disabled={isSectionDisabled(row.source, section)}>
                            {section}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Stack>
              </ListItem>
            </Box>
            <Tooltip title='Remove row'>
              <IconButton
                sx={styles.advanceSearchRemoveListItem}
                color='error'
                onClick={() => removeItem(row)}>
                <RemoveCircleOutline />
              </IconButton>
            </Tooltip>
          </Box>
        ))}
        <Grow in={resultsState?.collatedSummarySelectedCategories?.length < 5}>
          <Stack direction='row' justifyContent='center' width='100%'>
            <Tooltip title='Add new row'>
              <IconButton
                color='primary'
                sx={styles.addNewRowButton}
                onClick={addNewLabelRow}
                disabled={isAddMoreRowDisabled}>
                <AddCircleOutlined fontSize='large' />
              </IconButton>
            </Tooltip>
          </Stack>
        </Grow>
      </DialogContent>
      <Button
        disabled={disableButton}
        onClick={handleApplicationSelection}
        sx={styles.submitButton}>
        Next: Select Applications
      </Button>
    </Dialog>
  );
};

export default memo(LabelSectionSelectionDialog);
