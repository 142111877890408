import { useEffect, useRef } from 'react';

import { Box, Stack } from '@mui/material';
import Activities from './Activities';
import Journeys from './Journeys';
import useActivityData from '../../hooks/useActivityData';
import NotificationLoading from '../../../Notifications/components/NotificationLoading';
import savedItemStyles from '../../styles/SavedItems.styles';
import appicationTabStyles from '../../styles/ApplicationsTab.styles';

const ActivityPage = ({ selectedTab }: any) => {
  const { fetchPaginatedUserActivity, isLoading, activityData, paginationScroll } =
    useActivityData();

  const parentDivRef = useRef<HTMLDivElement>(null);
  const pageEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchPaginatedUserActivity();
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && paginationScroll) {
        fetchPaginatedUserActivity();
      }
    }, options);

    const container = pageEndRef.current;
    if (!container) {
      return;
    }
    observer.observe(container);

    // eslint-disable-next-line consistent-return
    return () => {
      if (container) {
        observer.unobserve(container);
      }
    };
  }, [paginationScroll, fetchPaginatedUserActivity, pageEndRef.current]);

  return (
    <Box height='100%' overflow='auto' ref={parentDivRef}>
      {selectedTab === 'journey' ? (
        <Journeys activityData={activityData} />
      ) : (
        <Activities activityData={activityData} />
      )}
      <Box sx={appicationTabStyles.pageEndBox} ref={pageEndRef} />
      <Box sx={savedItemStyles.listSkeletonBox}>
        {isLoading ? (
          <Stack height={500}>
            <NotificationLoading />
            <NotificationLoading />
            <NotificationLoading />
          </Stack>
        ) : null}
      </Box>
    </Box>
  );
};

export default ActivityPage;
