import { forwardRef, memo, SyntheticEvent, useContext, useState } from 'react';
import { useHistory } from 'react-router';

import { random } from 'lodash';

// mui
import { Box, IconButton, Menu, MenuItem, styled, Tooltip, Typography } from '@mui/material';

// custom icons
import { AddCircleIcon, RemoveCircleIcon, CaretDownIcon } from '../../../assets/svgs/Icons';

// types
import { ExploreFeatureIconButtonProps } from '../types';

// styles
import palette from '../../../themev5/palette';

// store
import Store from '../../../store';
import Actions from '../../../store/actions';

const StyledIconButton = styled(IconButton)(() => ({
  transition: 'transform 0.15s ease-in-out',
  padding: 0,
  color: palette.black.main,
  backgroundColor: 'transparent',
  '&:hover': { backgroundColor: 'transparent', transform: 'scale(1.12)' },
  '&.Mui-disabled': {
    backgroundColor: '#293357',
    color: '#666979'
  }
}));

const ExploreFeatureIconButton = forwardRef<HTMLElement, ExploreFeatureIconButtonProps>(
  (
    {
      index,
      feature,
      onClickAddRemove,
      isPersonalize,
      isAddPersonaize,
      allowDragging,
      setModalOpen
    },
    ref
  ) => {
    const history = useHistory();
    const { id, title, Icon, clickType, onClick, menuItems } = feature;
    const { dispatch } = useContext(Store) as any;

    const animationDuration = random(0.2, 0.35, true);
    const animationDelay = random(0.05, 0.75, true) * -1;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: SyntheticEvent<HTMLButtonElement>) => {
      if (isPersonalize) return;
      switch (clickType) {
        case 'link':
          onClick?.({ history });
          break;
        case 'menu':
          setAnchorEl(event.currentTarget);
          break;
        case 'modal':
          onClick?.({ setModalOpen });
          break;
        default:
          onClick?.({ history });
      }
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const baseStyle = {
      alignItems: 'start',
      justifyContent: 'center',
      display: 'flex',
      paddingX: '22px'
    };
    const style = {
      '@keyframes keyframes1': {
        '0%': {
          transform: 'rotate(-1.5deg)',
          animationTimingFunction: 'ease-in'
        },

        '50%': {
          transform: 'rotate(2deg)',
          animationTimingFunction: 'ease-out'
        }
      },
      '@keyframes keyframes2': {
        '0%': {
          transform: 'rotate(1.5deg)',
          animationTimingFunction: 'ease-in'
        },

        '50%': {
          transform: 'rotate(-2deg)',
          animationTimingFunction: 'ease-out'
        }
      },
      animationName: index % 2 === 0 ? 'keyframes1' : 'keyframes2',
      animationIterationCount: 'infinite',
      animationDirection: 'alternate',
      animationDelay: `${animationDelay}s`,
      animationDuration: `${animationDuration}s`,
      transformOrigin: '50% 10%',
      cursor: 'grab'
    };

    const onClickAddRemoveWrapper = (e: SyntheticEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (!isPersonalize) return;
      if (!allowDragging) {
        dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Cannot remove more than 3 items', color: 'warning' }
        });
        return;
      }
      onClickAddRemove?.();
    };

    return (
      <>
        <Box
          ref={ref}
          key={id}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          <Tooltip title={isAddPersonaize ? 'Add' : 'Remove'}>
            <IconButton
              size='small'
              onClick={onClickAddRemoveWrapper}
              sx={{
                visibility: isPersonalize ? 'visible' : 'hidden',
                padding: 1
              }}>
              {isAddPersonaize ? (
                <AddCircleIcon sx={{ fontSize: 20 }} />
              ) : (
                <RemoveCircleIcon sx={{ fontSize: 20 }} />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title={isPersonalize ? null : feature.tooltip}>
            <Box
              sx={isPersonalize && allowDragging ? { ...baseStyle, ...style } : baseStyle}
              minWidth={isPersonalize ? 130 : 0}
              minHeight={50}>
              <StyledIconButton
                onClick={handleClick}
                disableRipple
                disableFocusRipple
                sx={{ cursor: isPersonalize ? 'grab' : 'pointer' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'center'
                  }}>
                  <Icon sx={{ fontSize: 35, color: 'primary.600' }} />
                  <Typography
                    variant='body1'
                    textAlign='center'
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                </Box>
                {menuItems && menuItems?.length > 0 && (
                  <CaretDownIcon sx={{ fontSize: 16, color: '#616161' }} />
                )}
              </StyledIconButton>
            </Box>
          </Tooltip>
        </Box>
        {menuItems && menuItems.length > 0 && (
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}>
            {menuItems.map(item => (
              <Tooltip key={item.id} title={item.tooltip || ''} placement='top'>
                <MenuItem onClick={() => item?.onClick?.({ history })}>{item.title}</MenuItem>
              </Tooltip>
            ))}
          </Menu>
        )}
      </>
    );
  }
);

export default memo(ExploreFeatureIconButton);
