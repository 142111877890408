import { createSvgIcon } from '@mui/material/utils';

const SubscribeIcon = createSvgIcon(
  <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0.605487 3.93522L-0.00292969 2.94355L3.92582 0.536133L4.53365 1.5313L0.605487 3.93522ZM12.9937 9.95988L10.5875 13.8583L11.5792 14.4714L13.9854 10.5724L12.9937 9.95988ZM12.2062 9.01663L9.3339 13.6734C9.20299 13.8951 9.02286 14.0838 8.80741 14.2248C8.59197 14.3658 8.34699 14.4553 8.0914 14.4865C8.01785 14.4955 7.94383 14.5 7.86974 14.5C7.6472 14.5001 7.42683 14.4562 7.22138 14.3707C7.01592 14.2852 6.82945 14.1598 6.67274 14.0018L6.11099 13.4401C5.57728 13.8136 4.92902 13.9872 4.28007 13.9303C3.63111 13.8733 3.02296 13.5896 2.56242 13.1288C2.10188 12.6681 1.81841 12.0598 1.76179 11.4108C1.70517 10.7619 1.87903 10.1137 2.25282 9.58013L0.498154 7.82488C0.320158 7.64688 0.183963 7.43154 0.0994263 7.19443C0.0148894 6.95733 -0.0158745 6.70439 0.00936108 6.45393C0.0345967 6.20348 0.1152 5.96176 0.245339 5.74629C0.375477 5.53081 0.551894 5.34696 0.76182 5.20805L5.28965 2.43722C6.14566 1.88857 7.15171 1.62113 8.16716 1.67227C9.1826 1.72342 10.1567 2.09059 10.9532 2.72247L12.0569 1.6188L12.8817 2.44363L11.7734 3.55197C12.3785 4.31765 12.7426 5.24572 12.8197 6.21857C12.8967 7.19142 12.6832 8.16525 12.2062 9.01663ZM5.2564 12.5855L3.10274 10.4301C2.94255 10.7315 2.88389 11.0766 2.93549 11.4141C2.98709 11.7515 3.1462 12.0633 3.38915 12.3031C3.63394 12.5359 3.94434 12.6878 4.27833 12.7383C4.61231 12.7888 4.95375 12.7354 5.2564 12.5855ZM10.5764 3.94163C9.97459 3.33515 9.18295 2.95344 8.33365 2.86023C7.48435 2.76702 6.62877 2.96795 5.90974 3.42947L1.38774 6.19213C1.32587 6.23536 1.27429 6.2917 1.23668 6.35714C1.19908 6.42257 1.17636 6.4955 1.17015 6.57072C1.16173 6.64916 1.1711 6.7285 1.19756 6.80283C1.22402 6.87716 1.26689 6.94457 1.32299 7.00005L7.49815 13.177C7.55482 13.2353 7.62445 13.2794 7.70138 13.3058C7.77831 13.3321 7.86038 13.34 7.9409 13.3286C8.02151 13.3189 8.09875 13.2906 8.16656 13.246C8.23438 13.2013 8.29092 13.1416 8.33174 13.0714L11.2017 8.42047C11.5975 7.70715 11.7506 6.88445 11.6378 6.07652C11.525 5.26858 11.1524 4.51926 10.5764 3.94163Z'
      fill='currentColor'
    />
  </svg>,
  'Subscribe'
);

const SubscribeIconFilled = createSvgIcon(
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.9856 10.073L11.5793 13.972L10.5877 13.3589L12.9939 9.45988L13.9856 10.073ZM4.53559 1.0313L3.92484 0.0361328L-0.00390625 2.44063L0.60451 3.43522L4.53559 1.0313ZM11.7713 3.05313L12.8796 1.9448L12.0559 1.1188L10.9528 2.22188C10.1564 1.59028 9.18247 1.22331 8.16725 1.17227C7.15202 1.12123 6.14622 1.38867 5.29043 1.93722L1.34593 4.12413C1.13601 4.26298 0.959601 4.44678 0.829464 4.6622C0.699327 4.87763 0.618723 5.1193 0.593486 5.36971C0.568249 5.62013 0.599012 5.87302 0.683547 6.11008C0.768081 6.34714 0.904273 6.56244 1.08226 6.74038L7.25626 12.9167C7.41287 13.075 7.59928 13.2007 7.80473 13.2865C8.01019 13.3723 8.23061 13.4165 8.45326 13.4166C8.52638 13.4165 8.59943 13.412 8.67201 13.4032C8.92811 13.372 9.17358 13.2822 9.38936 13.1408C9.60514 12.9993 9.78544 12.8101 9.91626 12.5877L12.2076 8.51255C12.6833 7.66153 12.8958 6.68847 12.8182 5.71661C12.7405 4.74474 12.3761 3.81778 11.7713 3.05313ZM1.74959 10.665C1.74617 11.143 1.86708 11.6136 2.10047 12.0307C2.33386 12.4478 2.67169 12.797 3.08082 13.0441C3.48994 13.2912 3.95628 13.4277 4.43407 13.4401C4.91186 13.4526 5.38467 13.3405 5.80609 13.115L2.07626 9.38172C1.86441 9.77648 1.75227 10.217 1.74959 10.665Z'
      fill='currentColor'
    />
  </svg>,
  'Subscribe'
);

const UnsubscribeIcon = createSvgIcon(
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_5585_15605)'>
      <path
        d='M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845886 10.121 0.00229405 8 0V0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.99261 1.46144 6.6994C1.71867 5.40619 2.35361 4.21831 3.28596 3.28596C4.21831 2.35361 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76752 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z'
        fill='#B00020'
      />
      <path d='M12 7.33325H4V8.66659H12V7.33325Z' fill='#B00020' />
    </g>
    <defs>
      <clipPath id='clip0_5585_15605'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'Unsubscribe'
);
export { SubscribeIcon, SubscribeIconFilled, UnsubscribeIcon };
