import { useCallback, useContext, useEffect, useState } from 'react';

import GlobalStore from '../store';
import GlobalActions from '../store/actions';

// api
import {
  addCommentToBinder,
  addMessageToBinder,
  getMyGroupBinders,
  shareFileToBinder
} from '../api/services/Moxo';

import useMoxoToken from './useMoxoToken';

// constants
import { linksEntity } from './constants';
import { Rule, rules } from '../helpers/bbCodeRule';

const useShareLinks = () => {
  const { state, dispatch } = useContext(GlobalStore) as any;
  const { accessToken } = useMoxoToken();
  const [binders, setBinders] = useState<any>([]);
  const [selectedBinders, setSelectedBinders] = useState<any>([]);
  const [serviceRequestBinders, setServiceRequestBinders] = useState<any>([]);
  const [binderType, setBinderType] = useState<string>('chats');
  const [sendLoader, setSendLoader] = useState(false);
  const [additionalMessage, setAdditionalMessage] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [isLinkCopied, setLinkCopied] = useState<boolean>(false);
  const [loadingBinders, setLoadingBinders] = useState<boolean>(false);

  const categorizeBinders = useCallback((binder: any) => {
    const { name, id, users, updated_time: updatedAt } = binder;
    const binderCategory = binder.description ? 'serviceRequests' : 'chats';
    const totalUsers = users?.length;

    return { name, id, totalUsers, binderCategory, users, updatedAt };
  }, []);
  const getBinders = async () => {
    try {
      setLoadingBinders(true);
      const res = await getMyGroupBinders(accessToken());
      const groupedConversations: any[] = [];
      const serviceRequestsConversations: any[] = [];
      if (res.status === 200) {
        res.data.data.binders.forEach((binderDetails: any) => {
          const conversation = categorizeBinders(binderDetails.binder);
          if (conversation.binderCategory === 'chats') {
            groupedConversations.push(conversation);
          } else if (
            !binderDetails.binder?.tags?.some((tag: any) => tag.name === 'Close_By_Info')
          ) {
            serviceRequestsConversations.push(conversation);
          }
        });
      }
      setBinders(groupedConversations);
      setServiceRequestBinders(serviceRequestsConversations);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setLoadingBinders(false);
    }
  };
  useEffect(() => {
    getBinders().then();
  }, []);

  const handleSelectBinder = (item: any) => {
    const selectedIndex = selectedBinders.findIndex(
      (selectedItem: any) => selectedItem.id === item.id
    );
    if (selectedIndex === -1) {
      setSelectedBinders([...selectedBinders, item]);
    } else {
      const newSelectedIds = [...selectedBinders];
      newSelectedIds.splice(selectedIndex, 1);
      setSelectedBinders(newSelectedIds);
    }
  };

  const removeBinderFromChips = (id: string) => {
    const selectedIndex = selectedBinders.findIndex((selectedItem: any) => selectedItem.id === id);
    if (selectedIndex !== -1) {
      const newSelectedIds = [...selectedBinders];
      newSelectedIds.splice(selectedIndex, 1);
      setSelectedBinders(newSelectedIds);
    }
  };

  const handleCopy = async (text: string) => {
    if (window.isSecureContext && 'clipboard' in navigator) {
      navigator.clipboard.writeText(text).then(() => {
        setLinkCopied(true);
      });
    }
  };

  const findLinkFileExtension = (url: string) => {
    if (url?.startsWith('blob:')) {
      return 'pdf';
    }
    return url?.split('.')?.pop()?.split(/[#?]/)?.[0] || 'pdf';
  };

  const shareFile = async () => {
    const token = accessToken();
    // convert the pdf file from url to blob
    fetch(state.shareLink.entityDetails.link)
      .then(response => response.blob())
      .then(blob => {
        // prepare the form data that can be sent to API payload
        const fileExtension = findLinkFileExtension(state.shareLink.entityDetails.link);
        const fileName = state.shareLink.entityDetails.title || 'File';

        const formData = new FormData();
        formData.append('file', blob, `${fileName}.${fileExtension}`);
        selectedBinders.forEach(async (binder: any) => {
          const res = await shareFileToBinder(formData, binder.id, token);
          if (res?.status === 200 && additionalMessage) {
            const payloadWithPlainText = {
              text: `${additionalMessage}`
            };
            await addCommentToBinder(payloadWithPlainText, binder.id, accessToken());
          }
          if (res?.status !== 200)
            dispatch({
              type: GlobalActions.SET_ALERT,
              value: { status: true, message: `Failed to share file to ${binder.name}` }
            });
        });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('File url is incorrect: ', error);
      });
  };
  const parseBBCode = (htmlString: string, bbRules: Rule[]) => {
    let modifiedHtmlString = htmlString;
    bbRules.forEach(bbRule => {
      const regex = new RegExp(`<${bbRule.htmlTag}>(.*?)<\\/${bbRule.htmlTag}>`, 'gi');
      modifiedHtmlString = modifiedHtmlString.replace(
        regex,
        `[${bbRule.bbcodeTag}]$1[/${bbRule.bbcodeTag}]`
      );
    });
    return modifiedHtmlString;
  };

  const handlePromiseResults = async (promises: Promise<any>[]) => {
    const results = await Promise.all(promises);
    results.forEach((res, index) => {
      const binder = selectedBinders[index];
      if (res?.status !== 200) {
        dispatch({
          type: GlobalActions.SET_ALERT,
          value: { status: true, message: `Failed to share link to ${binder.name}` }
        });
      }
    });
  };
  const s3ResourceLink = (link: string) => {
    const loc = new URL(link);
    if (!loc?.hostname?.includes('s3')) return link;

    // eslint-disable-next-line no-restricted-globals
    const domain = new URL(location?.href)?.origin;
    const bucket = loc?.hostname?.split('.')[0];
    return `${domain}/resource?bucket=${bucket}&filename=${loc?.pathname?.replace(/\//, '')}`;
  };

  const sendMessageToGroup = async (e: any) => {
    e.preventDefault();
    setSendLoader(true);

    if (state.shareLink.entityType === 'file') {
      await shareFile();
    }
    if (state.shareLink.entityType === 'aria_result_link') {
      // Additional Message Section
      const additionalMessageText = additionalMessage ? `${additionalMessage}\n` : '';
      // PDF Link Section
      const finalLink = s3ResourceLink(state.shareLink.entityDetails.s3ResourceLink);
      const pdfLink = `[size=15][url=${finalLink}][b][u]${
        state.shareLink.entityDetails.title || 'File'
      }.pdf[/u][/b][/url][/size]`;
      // Aria Result Text
      let ariaResultText = parseBBCode(state.shareLink.entityDetails.text, rules);
      // Removing \n if any to improve appearance on Chat
      ariaResultText = `${ariaResultText.replace(/\n/g, '')}\n`;
      // Final Richtext Composition
      const payloadWithRichText = {
        message: {
          richtext: `[color=#606266]${ariaResultText}[/color]\n${pdfLink}\n\n[b]${additionalMessageText}[/b]`
        }
      };
      const promises = selectedBinders.map(async (binder: any) => {
        let res = null;
        res = addMessageToBinder(payloadWithRichText, binder.id, accessToken());
        return res;
      });
      await handlePromiseResults(promises);
    }
    if (linksEntity.includes(state.shareLink?.entityType)) {
      const payloadWithRichText = {
        message: {
          richtext: additionalMessage ? `${additionalMessage}\n` : ''
        }
      };
      payloadWithRichText.message.richtext += `[url=${state.shareLink.entityDetails.link}]${
        state.shareLink.textPrefix || 'Search link'
      }[/url]`;
      const payloadWithPlainText = {
        text: `${additionalMessage} : ${state.shareLink.entityDetails.link}`
      };
      const promises = selectedBinders.map(async (binder: any) => {
        let res = null;
        if (binder.binderCategory === 'serviceRequests') {
          res = addCommentToBinder(payloadWithPlainText, binder.id, accessToken());
        } else {
          res = addMessageToBinder(payloadWithRichText, binder.id, accessToken());
        }
        return res;
      });
      await handlePromiseResults(promises);
    }
    setSelectedBinders([]);
    setSendLoader(false);
    setAdditionalMessage('');
    dispatch({
      type: GlobalActions.SET_ALERT,
      value: { status: true, message: `Sent Successfully`, color: 'success' }
    });
    dispatch({
      type: GlobalActions.SET_SHARE_LINK,
      value: { open: false }
    });
  };

  const handleAdditionalText = (e: any) => {
    setAdditionalMessage(e.target.value);
  };

  const handleSearchText = (e: any) => {
    setSearchText(e.target.value);
  };

  const clearSearchText = () => {
    setSearchText('');
  };

  const clearBinders = () => {
    setSelectedBinders([]);
  };

  return {
    binders,
    selectedBinders,
    handleSelectBinder,
    removeBinderFromChips,
    handleCopy,
    sendMessageToGroup,
    sendLoader,
    handleAdditionalText,
    additionalMessage,
    setBinderType,
    binderType,
    serviceRequestBinders,
    searchText,
    isLinkCopied,
    handleSearchText,
    clearSearchText,
    getBinders,
    loadingBinders,
    clearBinders
  };
};

export default useShareLinks;
