import { createContext, useReducer, Dispatch, ReactNode } from 'react';
import reducers, { initialState } from './reducers';

type Props = {
  children: ReactNode;
};

export interface ContextInterface {
  stats: any;
  projects: any[];
  errors: any[];
  filters: any[];
  availableFilters: any;
  isLoading: boolean;
  isFiltersApplied: boolean;
  programTypeChart: any;
  submissionStatusChart: any;
  barGraph: any;
  charts: any;
  totalRecord: number;
  sortFields: any[];
  askRiaFilters: any[];
  dataGridFilters: any;
}

const DrugDevelopmentToolsStore = createContext<{
  DrugDevelopmentToolsState: ContextInterface;
  DrugDevelopmentToolsDispatch: Dispatch<any>;
}>({ DrugDevelopmentToolsState: initialState, DrugDevelopmentToolsDispatch: () => null });
const { Provider } = DrugDevelopmentToolsStore;

export const DrugDevelopmentToolsStateProvider = ({ children }: Props) => {
  const [DrugDevelopmentToolsState, DrugDevelopmentToolsDispatch] = useReducer(
    reducers,
    initialState
  );
  return (
    <Provider value={{ DrugDevelopmentToolsState, DrugDevelopmentToolsDispatch }}>
      {children}
    </Provider>
  );
};

export default DrugDevelopmentToolsStore;
