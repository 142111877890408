import { memo, useEffect, useContext, useState, useCallback, MouseEvent } from 'react';
import { Stack, Typography, Divider, Paper, Popper, Box } from '@mui/material';

import styles from './OrphanDesignations.styles';

// API
import { regulatoryInformationAPI } from '../../../api/pages/Regulatory360';

// Store
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import Actions from '../../../store/RegulatoryInformation/actions';
import EmptyPage from '../../../components/EmptyPage/EmptyPage';
import Loading from '../../SearchResults/components/Loading';
import LABEL_NAMES from './constants';

const OrphanDesignations = () => {
  const { regulatoryState, regulatoryDispatch } = useContext(RegulatoryInformationStore);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [popoverAnchor, setPopoverAnchor] = useState<null | { element: HTMLElement; id: string }>(
    null
  );

  const handlePopoverOpen = (id: string) => (event: MouseEvent<HTMLElement>) => {
    setPopoverAnchor({ element: event.currentTarget, id });
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const data = await regulatoryInformationAPI(
          regulatoryState?.db,
          regulatoryState?.applicationDetails?.application_number,
          'orphan_designation'
        );

        regulatoryDispatch({
          type: Actions.SET_ORPHAN_DESIGNATIONS,
          value: data?.data?.body?.orphan_designation ?? []
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (
      regulatoryState?.orphanDesignations?.length < 1 &&
      regulatoryState?.applicationDetails?.application_number
    ) {
      getData();
    }
  }, [regulatoryState?.db, regulatoryState?.applicationDetails]);

  const notAvailable = () => {
    return <Typography sx={styles.notAvailableText}>N/A</Typography>;
  };

  const getDesignation = useCallback((designation: any) => {
    const sections = [
      {
        label: LABEL_NAMES.designation_number,
        content:
          designation?.designation_number?.length > 0
            ? designation?.designation_number
            : notAvailable()
      },
      {
        label: LABEL_NAMES.generic_name,
        content: designation?.generic_name?.length > 0 ? designation?.generic_name : notAvailable()
      },
      {
        label: LABEL_NAMES.product_name,
        content: designation?.product_name?.length > 0 ? designation?.product_name : notAvailable()
      },
      {
        label: LABEL_NAMES.opinion_date,
        content: designation?.opinion_date?.length > 0 ? designation?.opinion_date : notAvailable()
      },
      {
        label: LABEL_NAMES.decision_date,
        content:
          designation?.decision_date?.length > 0 ? designation?.decision_date : notAvailable()
      },
      {
        label: LABEL_NAMES.decision_outcome,
        content:
          designation?.decision_outcome?.length > 0 ? designation?.decision_outcome : notAvailable()
      },
      {
        label: LABEL_NAMES.designation_status,
        content:
          designation?.designation_status?.length > 0
            ? designation?.designation_status
            : notAvailable()
      },
      {
        label: LABEL_NAMES.sponsor_name,
        content: designation?.sponsor_name?.length > 0 ? designation?.sponsor_name : notAvailable()
      },
      {
        label: LABEL_NAMES.indications_and_usage,
        content:
          designation?.indications_and_usage?.length > 0
            ? designation?.indications_and_usage
            : notAvailable()
      },
      {
        label: LABEL_NAMES.product_class,
        content:
          designation?.product_class?.length > 0 ? designation?.product_class : notAvailable()
      },
      {
        label: LABEL_NAMES.mechanism_of_action,
        content:
          designation?.mechanism_of_action?.length > 0
            ? designation?.mechanism_of_action
            : notAvailable()
      },
      {
        label: LABEL_NAMES.therapeutic_area,
        content:
          designation?.therapeutic_area?.length > 0 ? designation?.therapeutic_area : notAvailable()
      },
      {
        label: LABEL_NAMES.prevalence,
        content: designation?.prevalence?.length > 0 ? designation?.prevalence : notAvailable()
      },
      {
        label: LABEL_NAMES.significant_benefit_clause,
        content:
          designation?.significant_benefit_clause?.length > 0
            ? designation?.significant_benefit_clause
            : notAvailable()
      },
      {
        label: LABEL_NAMES.age_ranges,
        content: designation?.age_ranges?.length > 0 ? designation?.age_ranges : notAvailable()
      },
      {
        label: LABEL_NAMES.product_development_stage_at_application,
        content:
          designation?.product_development_stage_at_application?.length > 0
            ? designation?.product_development_stage_at_application
            : notAvailable()
      },
      {
        label: LABEL_NAMES.condition_severity,
        content:
          designation?.condition_severity?.length > 0
            ? designation?.condition_severity
            : notAvailable()
      },
      {
        label: LABEL_NAMES.criteria_for_medical_plausibility,
        content:
          designation?.criteria_for_medical_plausibility?.length > 0
            ? designation?.criteria_for_medical_plausibility
            : notAvailable()
      }
    ];

    return sections;
  }, []);

  const showPopper = (content: any) => {
    if (!content) {
      return false;
    }

    if (typeof content === 'string') {
      return content.length > 60;
    }

    if (Array.isArray(content)) {
      return true;
    }

    return false;
  };

  if (isLoading) {
    return <Loading showTips={false} />;
  }

  return (
    <Stack sx={styles.container}>
      {regulatoryState?.orphanDesignations?.length > 0 ? (
        <Stack sx={styles.gridContainer}>
          <Stack sx={styles.labelsIndicatorWrapper}>
            {Object.keys(LABEL_NAMES)?.map((label: string, labelIndex) => (
              <Box key={label} sx={styles.section}>
                <Stack sx={styles.section}>
                  <Typography variant='subtitle2' sx={styles.sectionLabel}>
                    {LABEL_NAMES[label]}
                  </Typography>
                </Stack>
                {labelIndex !== Object.keys(LABEL_NAMES).length - 1 && (
                  <Divider sx={styles.sectionDivider} />
                )}
              </Box>
            ))}
          </Stack>
          <Stack sx={styles.orphanDesignationWrapper}>
            {regulatoryState?.orphanDesignations?.map(
              (orphanDesignation: any, orphanDesignationIndex) => {
                const deviceSections = getDesignation(orphanDesignation);
                return (
                  <Stack
                    spacing={2}
                    sx={styles.sectionsWrapper}
                    key={orphanDesignation?.designation_number}>
                    {deviceSections?.map((section, sectionIndex) => (
                      <Stack
                        onMouseEnter={handlePopoverOpen(
                          `od-${orphanDesignationIndex}-${sectionIndex}`
                        )}
                        onMouseLeave={handlePopoverClose}
                        key={section.label}
                        sx={styles.sectionWrapper}>
                        <Stack sx={styles.sectionContentWrapper}>
                          <Box sx={styles.sectionText}>{section.content}</Box>
                        </Stack>

                        <Popper
                          open={
                            popoverAnchor?.id === `od-${orphanDesignationIndex}-${sectionIndex}` &&
                            showPopper(section?.content)
                          }
                          anchorEl={popoverAnchor?.element}
                          placement='bottom-start'>
                          <Paper elevation={1} sx={styles.paper}>
                            <Typography variant='subtitle2' sx={styles.paperText}>
                              {section.content}
                            </Typography>
                          </Paper>
                        </Popper>

                        {sectionIndex !== deviceSections.length - 1 && (
                          <Divider sx={styles.sectionDivider} />
                        )}
                      </Stack>
                    ))}
                  </Stack>
                );
              }
            )}
          </Stack>
        </Stack>
      ) : (
        <Stack height={250}>
          <EmptyPage message='No orphan designation available.' />
        </Stack>
      )}
    </Stack>
  );
};

export default memo(OrphanDesignations);
