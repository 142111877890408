/**
 * Normalize a date string to the format 'MMM D, YYYY' (e.g., Aug 14, 2014).
 * If the date string is invalid, return the current date in the same format.
 * @param dateString - The input date string in various formats.
 * @returns A normalized date string in the format 'MMM D, YYYY'.
 */
export const normalizeDate = (dateString: string): string => {
    // Try to parse the input date string
    let parsedDate = new Date(dateString);
  
    // Check if the parsed date is valid
    if (isNaN(parsedDate.getTime())) {
      // If parsing failed, use the current date as a fallback
      return dateString
    }
  
    // Define month abbreviations
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
    // Extract month, day, and year
    const month = monthNames[parsedDate.getMonth()];
    const day = parsedDate.getDate();
    const year = parsedDate.getFullYear();
  
    // Return the formatted date
    return `${month} ${day}, ${year}`;
}