import React, { useCallback } from 'react';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useHistory } from 'react-router-dom';

interface BreadcrumbsLink {
  name: string;
  link: string;
}

interface BreadcrumbsProps {
  links: Array<BreadcrumbsLink>;
}
const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ links }) => {
  const history = useHistory();
  const handleLinkClick = useCallback(
    (url: string) => {
      history.push(url);
    },
    [history]
  );
  return (
    <MuiBreadcrumbs
      separator={<Divider orientation='vertical' />}
      aria-label='breadcrumb'
      sx={{
        fontSize: 14,
        padding: 1,
        '& hr': { borderColor: 'gray.backgroundLight', height: '18px', borderRightWidth: '2px' }
      }}>
      {links.splice(0, links.length - 1).map((link: BreadcrumbsLink) => {
        return (
          <Link
            underline='hover'
            key={link.link}
            href={link.link}
            onClick={(e: any) => {
              e.preventDefault();
              handleLinkClick(link.link);
            }}
            sx={{ color: 'black.light', fontWeight: '400' }}>
            {link.name}
          </Link>
        );
      })}
      <Typography
        key='3'
        display='inline-block'
        fontSize={14}
        fontWeight='bold'
        color='black.light'>
        {links[links.length - 1]?.name}
      </Typography>
    </MuiBreadcrumbs>
  );
};

export default React.memo(Breadcrumbs);
