import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// material ui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

// transitions
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';

// icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';

// context
import AriaStore from '../../../store/Aria';
import AriaActions from '../../../store/Aria/actions';
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';
// components
import SearchField from './SearchField';

// assets
import BotBannerLogo from '../../../assets/svgs/Bot/AriaBannerIcon';

// utils
import {
  ARIA_SEARCH_SUBTEXT,
  MODULE_NAME_MAPPING_NAVIGATION,
  TIMEOUT_DURATION_ARIA_BANNER
} from '../utils/constants';
import generateDescriptionText from '../utils/generateDescriptionText';
import generateNavigationText from '../utils/generateNavigationText';
// custom hooks
import useQuery from '../../../helpers/customHooks/queryParameter';

// styles
import style from '../styles/Banner.styles';

const Banner = () => {
  const styles = style();
  // get query from url parameters
  const query = useQuery();
  // to navigate using react-router-dom
  const history = useHistory();
  // url parameters used for making api calls
  const { source, module } = useParams();

  // update states in aria context provider
  const { ariaState, ariaDispatch } = useContext(AriaStore);
  const { dispatch } = useContext(GlobalStore);

  useEffect(() => {
    // clear the previous earch details when the user comes to banner page
    ariaDispatch({ type: AriaActions.SET_ARIA_RESULTS, value: [] });
    ariaDispatch({ type: AriaActions.SET_SUGGESTED_ANS, value: [] });
    ariaDispatch({ type: AriaActions.SET_DOCUMENT_OPEN, value: false });
    ariaDispatch({ type: AriaActions.SET_DOCUMENT_LINK, value: '' });
    if (
      ariaState.filters['group-id']?.length > 1000 ||
      JSON.parse(sessionStorage.getItem('aria_cache'))?.ariaFilterList?.length > 1000
    ) {
      let searchResults = module;
      if (module === 'htaDocuments') searchResults = 'HTA Documents';
      else if (module === 'core') {
        searchResults = source === 'ct' ? 'trials' : 'applications';
      }
      // eslint-disable-next-line no-alert
      // eslint-disable-next-line no-nested-ternary
      dispatch({
        type: GlobalActions.SET_ALERT,
        value: {
          status: true,
          message: `Please reduce the number of 
          ${searchResults} to less than 1000 using filters to get more relevant results`,
          color: 'warning',
          duration: 5000
        }
      });
    }
  }, [ariaDispatch]);

  return (
    <Box sx={styles.root}>
      <Stack
        p={1}
        direction='row'
        alignItems='center'
        sx={styles.back}
        onClick={() => {
          if (history.location.state?.from) {
            history.goBack();
            return;
          }
          history.push(
            JSON.parse(sessionStorage.getItem('aria_cache'))?.navigation ||
              `/${MODULE_NAME_MAPPING_NAVIGATION[module]}`
          );
        }}>
        <ArrowBackIcon />
        <Typography variant='h4' sx={styles.navigationText}>
          Back to {generateNavigationText(query, module)}
        </Typography>
      </Stack>

      <Stack spacing={1} sx={styles.container}>
        <Fade in timeout={TIMEOUT_DURATION_ARIA_BANNER}>
          <Box>
            <BotBannerLogo />
          </Box>
        </Fade>
        <Typography align='center' variant='h1' sx={styles.subtext}>
          Hello{' '}
          <Fade in timeout={TIMEOUT_DURATION_ARIA_BANNER}>
            <Typography component='span' sx={styles.highlightText}>
              ARIA{' '}
            </Typography>
          </Fade>
          here!
        </Typography>
        <Stack sx={styles.searchBox}>
          <SearchField />
        </Stack>
        <Stack spacing={1} direction='row' alignItems='center'>
          {/* Show a note when there are more than 1000 applications that are being sent in the filters for aria searches */}
          {ariaState.filters['group-id']?.length > 1000 ||
            (JSON.parse(sessionStorage.getItem('aria_cache'))?.ariaFilterList?.length > 1000 && (
              <Slide direction='up' in timeout={2000} style={{ transitionDelay: '5000ms' }}>
                <Tooltip title='ARIA results derived from top 1000 records of the source dataset.'>
                  <TipsAndUpdatesOutlinedIcon sx={{ fontSize: 16 }} color='warning' />
                </Tooltip>
              </Slide>
            ))}
          <Typography align='center' variant='subtitle1' fontStyle='italic'>
            Searching results {generateDescriptionText(query.get('searchTerm')) && 'for '}
            <Typography component='span' variant='subtitle1' sx={styles.searchText}>
              {query.get('resultsPage') && generateDescriptionText(query.get('searchTerm'))}{' '}
            </Typography>
            from
            <Fade in timeout={TIMEOUT_DURATION_ARIA_BANNER}>
              <Typography component='span' variant='subtitle1' sx={styles.description}>
                {ARIA_SEARCH_SUBTEXT[source]}
              </Typography>
            </Fade>
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Banner;
