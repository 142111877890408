const styles = {
  popoverDiv: {
    padding: '20px 32px',
    maxWidth: '700px'
  },
  linkItem: {
    cursor: 'pointer'
  },
  linkHeading: {
    fontSize: '1.7vh',
    fontWeight: '600',
    cursor: 'pointer',
    display: 'block',
    textTransform: 'capitalize'
  },
  link: {
    fontSize: '1.55vh',
    fontWeight: '600',
    cursor: 'pointer',
    display: 'block',
    textAlign: 'left',
    marginBottom: '5px',
    color: 'blue.main',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    },
    textTransform: 'capitalize'
  },
  pdfCellText: {
    fontSize: 14,
    color: 'primary.600',
    fontWeight: '700'
  }
};

export default styles;
