import React, { useState, useMemo, useCallback } from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import debounce from 'lodash/debounce';

import { searchStyle } from '../styles/Search.style';

import ResetButton from '../../../Images/undo-arrow.svg';

const Search = ({ pagination, sortFields, dataGridFilter, fetchData }: any) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleClearSearch = () => {
    setSearchTerm('');
    fetchData(pagination, sortFields, dataGridFilter, '');
  };

  const updateSearch = useCallback(
    (value: string) => {
      fetchData(pagination, sortFields, dataGridFilter, value);
    },
    [pagination, sortFields, dataGridFilter]
  );

  const debouncedChangeHandler = useMemo(() => debounce(updateSearch, 200), [updateSearch]);

  const handleInputTextChange = useCallback(
    (event: any) => {
      setSearchTerm(event.target.value);
      debouncedChangeHandler(event.target.value);
    },
    [debouncedChangeHandler]
  );

  return (
    <Box display='flex'>
      <TextField
        id='guidance-search'
        onChange={handleInputTextChange}
        value={searchTerm}
        placeholder='Search Keyword'
        sx={searchStyle.searchInput}
      />
      <Button onClick={handleClearSearch} sx={searchStyle.buttonReset}>
        <img src={ResetButton} alt='reset' width={12} style={searchStyle.resetIcon} /> Reset
      </Button>
    </Box>
  );
};

export default React.memo(Search);
