import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import TimelineCircularAvatar from "./TimelineCircularAvatar";
import CustomTimelinePropsInterface from "../../../interfaces/CustomTimelinePropsInterface";

import APPROVAL_STATUS_TAGS, { VERSIONS_TAGS } from "./constants";

// Need to change this completely
const TimelineAvatars: React.FC<CustomTimelinePropsInterface> = ({
  column,
  dataItem,
  triggeredBy
}: CustomTimelinePropsInterface) => {
  const [avatarList, setAvatarList] = useState([]);

  useEffect(() => {
    if (column !== undefined && triggeredBy === 'rems') {
      setAvatarList(VERSIONS_TAGS[column.data]);
    } else if (column !== undefined) {
      setAvatarList(APPROVAL_STATUS_TAGS[column.data]);
    }
  }, [column]);

  return (
    <Box sx={{ height: '100%' }} pl={1} pr={1}>
      <TimelineCircularAvatar data={dataItem} avatarList={avatarList} />
    </Box>
  );
};

export default React.memo(TimelineAvatars);
