/* eslint-disable no-console */
import React, { useEffect, useState, useMemo, useContext, useRef } from 'react';
import { flatMap, intersectionBy, debounce } from 'lodash';
import {
  useGridApiRef,
  gridRowsLookupSelector,
  gridFilteredRowsLookupSelector
} from '@mui/x-data-grid-pro';

import Stack from '@mui/material/Stack';
import VivproDatagrid from '../../../components/Datagrid';

import { DATAGRID_COLUMN_MAPPING } from '../../ResultsPage/utils/constants';
import { JP_ORIGINAL_LANG_COLUMNS } from '../../ResultsPage/utils/columnDefinitions';

import ResultsStore from '../../../store/SearchResults';
import ResultsActions from '../../../store/SearchResults/actions';
import getMatchSubmissions from '../hooks/getMatchSubmissions';
import mergeMatchSubmission, { checkForMatchSubmission } from '../utils/getMatchSubmissionData';

const ApplicationsTableView = ({ applications = [], sources = [], searchTerm = '' }: any) => {
  const [columnDefs, setColumnDefs] = useState<any>([]);
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const apiRef = useGridApiRef();
  const resultsStateRef = useRef(resultsState);

  // Update refs when state changes
  useEffect(() => {
    resultsStateRef.current = resultsState;
  }, [resultsState]);

  useEffect(() => {
    resultsDispatch({ type: ResultsActions.SET_DATA_GRID_META_DATA, value: {} });
  }, [applications, sources]);

  useEffect(() => {
    let mergedColumns: any = [];

    sources.forEach((source: string) => {
      mergedColumns = [...mergedColumns, DATAGRID_COLUMN_MAPPING[source]];
    });
    let selectedColumns: any = [];
    if (mergedColumns.length === 1) {
      // eslint-disable-next-line prefer-destructuring
      selectedColumns = mergedColumns[0];
      if (sources.length === 1 && sources[0] === 'jp' && resultsState.originalTextEnabled) {
        selectedColumns = JP_ORIGINAL_LANG_COLUMNS;
      }
    } else {
      selectedColumns = intersectionBy(...mergedColumns, 'field');
    }
    // remove matched_submissions if data is empty
    const appArray = Array.isArray(applications)
      ? applications
      : flatMap(applications, (value: any) => value);

    const isEmpty = appArray.every(
      item => Array.isArray(item.matched_submissions) && item.matched_submissions.length === 0
    );
    if (isEmpty) {
      const updatedColumnList = selectedColumns.filter(
        (col: { field: string }) =>
          col.field !== 'matched_submissions' && col.field !== 'matched_submissions_csv'
      );
      setColumnDefs(updatedColumnList);
    } else {
      setColumnDefs(selectedColumns);
    }
  }, [sources, resultsState.originalTextEnabled]);

  const getSubmissionData = async () => {
    const searchId = resultsState.searchIds?.applicationSearchId;
    const applicationResults = { ...resultsState.applicationResults };
    if (searchId && !checkForMatchSubmission(applicationResults)) {
      resultsDispatch({ type: ResultsActions.SET_IS_CONTENT_LOADING, value: true });
      const submissionData = await getMatchSubmissions(searchId);

      const newApplData = mergeMatchSubmission(applicationResults, submissionData);
      resultsDispatch({
        type: ResultsActions.SET_APPLICATION_RESULTS,
        value: newApplData
      });

      resultsDispatch({ type: ResultsActions.SET_IS_CONTENT_LOADING, value: false });
    }
  };

  useEffect(() => {
    getSubmissionData();
  }, [sources]);

  const csvFileName = useMemo(
    () =>
      `${
        resultsState?.decryptedPayload?.searchType === 'advanced'
          ? 'ria_advance_search'
          : 'ria_search'
      }_${sources.join('_')}_${searchTerm}`,
    [sources, searchTerm, resultsState?.decryptedPayload?.searchType]
  );

  const applicationList = useMemo(() => {
    if (applications) {
      const appArray = Array.isArray(applications)
        ? applications
        : flatMap(applications, (value: any) => value);
      try {
        // show suggested results at the end if available
        return appArray.slice().sort((a: any, b: any) => {
          if (a.relevance_score === 'suggested' && b.relevance_score !== 'suggested') {
            return 1; // Push "suggested" items to the end
          }
          if (a.relevance_score !== 'suggested' && b.relevance_score === 'suggested') {
            return -1; // Keep non-"suggested" items before "suggested"
          }
          if (a.relevance_score === 'suggested' && b.relevance_score === 'suggested') {
            return 0; // Keep the order of suggested items as is
          }
          return a.relevance_score - b.relevance_score; // Regular numerical sort for non-suggested items
        });
      } catch (sortError) {
        console.error('Sorting error:', sortError);
        return appArray; // Return the default array if sorting fails
      }
    }

    return [];
  }, [applications]);

  const debouncedDispatch = debounce((type, value) => {
    resultsDispatch({
      type,
      value
    });
  }, 300);

  useEffect(() => {
    const handleColumnVisibilityModelChange = () => {
      if (!apiRef.current) {
        return;
      }

      const visibleColumns = apiRef.current.getVisibleColumns() ?? [];

      debouncedDispatch(ResultsActions.SET_DATA_GRID_META_DATA, {
        ...resultsStateRef.current.dataGridMetaData,
        visibleColumns
      });
    };

    const handleFilterModelChange = () => {
      if (!apiRef.current) {
        return;
      }

      // Delay access to state slightly
      setTimeout(() => {
        const currentState = apiRef.current.state;
        const filteredRowIds = gridFilteredRowsLookupSelector(currentState);
        const allRows = gridRowsLookupSelector(currentState);

        const allVisibleRows = Object.keys(filteredRowIds)
          ?.filter(rowId => filteredRowIds[rowId])
          ?.map(rowId => allRows[rowId])
          ?.filter(Boolean);

        debouncedDispatch(ResultsActions.SET_DATA_GRID_META_DATA, {
          ...resultsStateRef.current.dataGridMetaData,
          visibleRows: allVisibleRows
        });
      }, 0);
    };

    // Subscribe to the events
    const unsubscribeVisibility = apiRef.current.subscribeEvent(
      'columnVisibilityModelChange',
      handleColumnVisibilityModelChange
    );

    const unsubscribeFilter = apiRef.current.subscribeEvent(
      'filterModelChange',
      handleFilterModelChange
    );

    // Cleanup the subscription
    return () => {
      unsubscribeVisibility();
      unsubscribeFilter();
      debouncedDispatch.cancel();
    };
  }, [apiRef]);

  return (
    <Stack id='ListData' sx={{ height: 'calc(100vh - 280px)', width: '100%', mb: 12 }}>
      {applications && columnDefs && (
        <VivproDatagrid
          rows={applicationList as any}
          columnsMapping={columnDefs}
          rowId='identifier'
          loading={false}
          csvFileName={csvFileName}
          showExportOption={false}
          apiRef={apiRef}
        />
      )}
    </Stack>
  );
};

export default React.memo(ApplicationsTableView);
