const styles = {
  container: {
    position: 'relative',
    '&:hover > .playButtonContainer': {
      display: 'block',
      cursor: 'pointer'
    },
    '.playButtonContainer': {
      display: 'none',
      position: 'absolute',
      width: 288,
      height: 162,
      top: '0px',
      borderRadius: '12px',
      background: '#00000066'
    },
    '.playButton': {
      display: 'block',
      margin: '40px auto auto auto',
      height: '110px',
      width: '110px'
    }
  },
  card: {
    width: 288,
    height: 162,
    borderRadius: '12px',
    background: 'linear-gradient(269deg, #00B893 -0.83%, #4C8FF2 107.76%)',
    fontFamily: 'Mulish',
    color: 'white.main',
    textAlign: 'center',
    overflow: 'hidden',
    cursor: 'pointer',
    '& img': {
      width: '264px',
      mt: '25px',
      borderRadius: '8px'
    }
  },
  howToTitle: {
    mt: 2.8,
    fontSize: 7.2,
    fontWeight: 400
  },
  pageTitle: { fontSize: 23, fontWeight: 300, lineHeight: 1 }
};

export default styles;
