import theme from '../../../theme';

const styles = {
  box: {
    borderRadius: '3px',
    width: '69.5vw'
  },
  barGraph: {
    backgroundColor: 'white.main',
    height: '40vh',
    borderRadius: '20px',
    [theme.breakpoints.up('xl')]: {
      height: '35vh'
    }
  },
  cards: {
    height: '80vh'
  },
  paper: {
    margin: 3,
    width: '50%',
    height: '50%',
    padding: 3,
    borderRadius: 3
  },
  graphHeading: {
    fontSize: '20px',
    color: 'gray.700',
    fontWeight: 'bold',
    fontFamily: 'Mulish'
  },
  reportText: {
    color: 'primary',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    '&:hover': {
      background: 'none'
    },
    fontFamily: 'Mulish'
  },
  pieCharts: {
    height: '30vh',
    [theme.breakpoints.up('xl')]: {
      height: '40vh'
    }
  },
  pieContainers: {
    heigth: '100%',
    marginTop: 2,
    marginLeft: 1,
    padding: 2,
    width: '50%',
    backgroundColor: 'white.main',
    borderRadius: '10px'
  },
  loaderBox: {
    width: '90%',
    height: '90%'
  },
  skeltonCircle: {
    width: '100px',
    height: '100px'
  },
  barGraphRect: {
    width: '100%',
    height: '100%'
  },
  circleLoading: {
    color: '#adadac',
    opacity: 0.5
  },
  addToReport: {
    display: 'flex-end',
    cursor: 'pointer',
    background: 'none',
    fontFamily: 'Mulish',
    boxShadow: 'none',
    '&:hover': {
      background: 'none',
      border: 1,
      borderColor: 'primary.main',
      borderStyle: 'solid'
    }
  },
  roundedMenu: {
    '& .MuiPaper-root': { padding: '8px 0' },
    '& .MuiList-root': { padding: 0 }
  },
  menuItem: {
    cursor: 'pointer',
    padding: '8px 24px !important'
  },
  listItemIcon: {
    minWidth: '14px !important'
  },
  svgIcon: { height: '24px', width: '24px', color: 'gray.750' },
  muiIcon: { fontSize: '14px' },
  listItemText: {
    fontFamily: 'Mulish',
    fontSize: '12px',
    fontWeight: '400',
    ml: '8px',
    color: 'primary.darkVariant1'
  }
};

export default styles;
