import { Box, Button, Divider, Typography } from '@mui/material';
import { ReactNode } from 'react';

import Stats from '../../SearchResults/components/Stats';
import SubHeaderPipeGreyIcon from '../../../assets/svgs/Icons/SubHeaderPipeGreyIcon';
import { Stat } from '../../SearchResults/types';
import { ReportIcon } from '../../../assets/svgs/Icons';
import ReviewMenu from './ReviewMenu';
import styles from '../styles/SubHeader.styles';
import applicationCardStyles from '../../SearchResults/styles/ApplicationCard.styles';
import buttonStyles from '../../SearchResults/styles/Buttons.styles';

type SubHeaderProps = {
  title: string;
  subTitle: string;
  stats: Stat[];
  handleVisualizeOpen: () => void;
  children: ReactNode;
  setModalOpen: any;
  searchQuery: any;
  isLoading: boolean;
};

const SubHeader = ({
  children,
  title,
  subTitle,
  stats,
  handleVisualizeOpen,
  setModalOpen,
  searchQuery,
  isLoading
}: SubHeaderProps) => {
  return (
    <>
      <Box sx={styles.container}>
        <p>
          <SubHeaderPipeGreyIcon sx={{ color: '#919191', height: '42px' }} />
        </p>
        <Box sx={{ margin: '12px 0 0 16px' }}>
          <Typography sx={styles.title}>{title}</Typography>
          <Typography sx={styles.subTitle}>
            {subTitle}
            <Typography sx={styles.searchQueryText}>{` -> ${searchQuery}`}</Typography>
            <Typography
              onClick={() => {
                setModalOpen('expeditedPathway');
              }}
              sx={styles.anchorLink}>
              New Search
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Stats stats={stats} />
        <Box display='flex' alignItems='center'>
          <Button
            onClick={handleVisualizeOpen}
            disabled={isLoading}
            id='visualize-button'
            startIcon={
              <ReportIcon
                sx={
                  isLoading ? buttonStyles.primaryButtonIconLoading : buttonStyles.primaryButtonIcon
                }
              />
            }
            sx={buttonStyles.primaryButton}>
            Visualize & Report
          </Button>
          <Divider orientation='vertical' sx={applicationCardStyles.verticalDivider} />
          <ReviewMenu />
          <Divider orientation='vertical' sx={applicationCardStyles.verticalDivider} />
          {children}
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default SubHeader;
