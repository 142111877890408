import Dialog from '@material-ui/core/Dialog';
import { Close } from '@material-ui/icons';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { cloneDeep } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ResultsStore from '../../store/SearchResults';

import { getATCTreeAPIData } from '../../api/pages/CardsPage';
import SearchListIcon from '../../assets/svgs/CardsPage/SearchListIcon';
import styles from './popup.styles';

import { SearchListTreeView } from '../SearchListTreeView';
import FilterBySynonyms from '../SearchListTreeView/FilterBySynonyms';
import getATCTreeCount from './utils';
import StyledBadge from '../SearchList/StyledBadge';

const ATCClassificationTreeView: React.FC<any> = ({ tree, showCount }: any) => {
  const [selectedNode, setAtcCode]: any = useState('');
  const onNodeSelection = (node: any) => {
    setAtcCode(node);
  };
  return (
    <Box sx={styles.subHeading}>
      <Box paddingLeft={2}>
        <SearchListTreeView
          nodes={[
            {
              name: 'ATC Tree',
              id: '0',
              children: tree,
              count: 0
            }
          ]}
          showCount={showCount}
          onNodeSelect={onNodeSelection}
        />
      </Box>
      <Box paddingLeft={3}>
        <Typography
          variant='subtitle1'
          sx={{ marginLeft: 1, color: 'gray.darkText', fontSize: '16px' }}>
          ATC Code
        </Typography>
        <Box>
          {selectedNode.count ? (
            <Box sx={styles.node_count} key={selectedNode.id}>
              {selectedNode.id}
              <Box sx={styles.count}>
                <StyledBadge badgeContent={selectedNode.count} max={100000} />
              </Box>
            </Box>
          ) : (
            <Box sx={styles.node}>{selectedNode.id}</Box>
          )}
        </Box>
        <hr />
      </Box>
    </Box>
  );
};

export const ClassViewPopup: React.FC<any> = ({ source, searchTerm, open, setOpen }: any) => {
  const [treeData, setTreeData]: any = useState([]);
  const { resultsState } = useContext(ResultsStore);
  const [isLoading, setLoading] = useState(false);

  const getATCTreeData = useCallback(
    async (src: string, term: string) => {
      setLoading(true);
      try {
        const response = await getATCTreeAPIData(src.toLowerCase(), term);
        if (response.data?.body && response.data?.body) {
          const applications = Object.entries(resultsState.applicationResults || {}).reduce(
            (acc: Array<any>, [_, value]: any) => {
              return [...acc, ...value.results];
            },
            []
          );
          setTreeData(getATCTreeCount(cloneDeep(response.data?.body), applications, source));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [resultsState.applicationResults]
  );

  useEffect(() => {
    getATCTreeData(source, searchTerm);
  }, [searchTerm, resultsState.applicationResults]);

  return (
    <Dialog
      onClose={() => setOpen()}
      aria-labelledby='customized-dialog-title'
      open={open}
      fullWidth
      maxWidth='md'>
      <Box
        p={1}
        sx={styles.close}
        display='flex'
        onClick={() => setOpen()}
        justifyContent='flex-end'>
        <Close />
      </Box>
      <Stack sx={{ overflow: 'hidden' }}>
        <Stack sx={styles.Synonymscontainer}>
          <Stack flexDirection='row'>
            <SearchListIcon />
            <Typography
              variant='subtitle1'
              sx={{ marginLeft: '1vh', fontSize: '18px', color: 'gray.darkText' }}>
              Synonyms
            </Typography>
          </Stack>
          <FilterBySynonyms atcView onClose={() => setOpen()} />
        </Stack>
        <Box sx={styles.container}>
          {!isLoading ? (
            <ATCClassificationTreeView tree={treeData} showCount />
          ) : (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Stack>
    </Dialog>
  );
};

export default React.memo(ATCClassificationTreeView);
