import { differenceInDays } from 'date-fns';

const findBarWidthBasedOnNumberOfBars = numberOfBars => {
  if (numberOfBars === 1) return 0.15 * numberOfBars;
  if (numberOfBars === 2) return 0.25 * numberOfBars;
  if (numberOfBars === 3) return 0.2 * numberOfBars;
  return null;
};

const grayBarColor = '#B2B2B2';
const increasingPrimaryDateBarColor = '#ea5252';
const decreasingPrimaryDateBarColor = '#239652';

const createPrimaryCompletionGraphData = (history, todaysDate) => {
  const dates = history.flatMap(item => [
    new Date(item?.submitted_date || ''),
    new Date(item?.primary_completion_date || ''),
    new Date(item?.study_start_date || '')
  ]);

  const maxDate = new Date(Math.max(...dates, todaysDate));
  const minDate = new Date(Math.min(...dates, todaysDate));
  const xRangeDays = differenceInDays(maxDate, minDate);
  const decreasingPCDLegendTrace = {
    x: [null],
    y: [null],
    mode: 'markers',
    marker: { size: 14, color: decreasingPrimaryDateBarColor, symbol: 'square' },
    name: 'Decreasing Primary Completion Date'
  };

  const increasingPCDLegendTrace = {
    x: [null],
    y: [null],
    mode: 'markers',
    marker: { size: 14, color: increasingPrimaryDateBarColor, symbol: 'square' },
    name: 'Increasing Primary Completion Date'
  };

  const data = history.reduce((accumulator, item, index) => {
    const {
      version = '',
      submitted_date: submittedDate = '',
      primary_completion_date: primaryCompletionDate = '',
      study_start_date: studyStartDate = '',
      extended_pc_color_code: extendedPCColorCode = ''
    } = item;
    const commonTrace = {
      alignmentgroup: 'True',
      orientation: 'h',
      hovermode: 'closest',
      xaxis: 'x',
      yaxis: 'y'
    };
    const diff = Math.abs(
      differenceInDays(new Date(primaryCompletionDate), new Date(studyStartDate))
    );
    const submittedDiff = Math.abs(
      differenceInDays(new Date(submittedDate), new Date(studyStartDate))
    );

    const barColor =
      index === 0
        ? grayBarColor
        : (extendedPCColorCode === 1 && increasingPrimaryDateBarColor) ||
          decreasingPrimaryDateBarColor;
    const barGraphData = {
      ...commonTrace,
      showlegend: false,
      type: 'bar',
      name: version,
      base: [new Date(studyStartDate)],
      hovertemplate:
        `Start: <b>${studyStartDate}</b>` +
        `<br>Primary: <b>${primaryCompletionDate}</b><extra></extra>`,
      x: [new Date(primaryCompletionDate).getTime() - new Date(studyStartDate).getTime()],
      y: [version],
      textposition: 'inside',
      insidetextanchor: 'middle',
      text_auto: '.2s',
      width: findBarWidthBasedOnNumberOfBars(history.length),
      textfont: { color: 'white', size: 14 },
      marker: {
        color: barColor,
        opacity: 0.8
      },
      text:
        diff < xRangeDays * 0.2
          ? '<b style={{font-weight: 900}}> &#9432; </b>'
          : `  Start: <b>${studyStartDate}</b> | Primary: <b>${primaryCompletionDate}</b>  `
    };

    const submittedDateMarkers = {
      ...commonTrace,
      showlegend: index === 0,
      legendgroup: 'group1',
      type: 'scatter',
      mode: 'markers',
      name: 'Submitted Date',
      hovertemplate: `Submitted: <b>${submittedDate}</b><extra></extra>`,
      x: [new Date(submittedDate).getTime()],
      y: [version],
      marker: {
        opacity: 0.4,
        line: {
          width: 1,
          color: 'blue'
        },
        color: 'yellow',
        size: 20
      }
    };

    const primaryCompletionDateMarkers = {
      ...commonTrace,
      showlegend: index === 0,
      type: 'scatter',
      mode: 'markers',
      name: 'Primary Completion Date',
      hovertemplate: `Primary: <b>${primaryCompletionDate}</b><extra></extra>`,
      x: [new Date(primaryCompletionDate).getTime()],
      y: [version],
      marker: {
        size: 20,
        opacity: 0.7,
        line: {
          width: 1,
          color: 'black'
        },
        color: 'rgba(128, 128, 128, 0.8)'
      }
    };

    return [...accumulator, barGraphData, submittedDateMarkers, primaryCompletionDateMarkers];
  }, []);
  return [...data, increasingPCDLegendTrace, decreasingPCDLegendTrace];
};

export default createPrimaryCompletionGraphData;
