const styles = {
  roundedMenu: {
    '& .MuiPaper-root': { padding: '8px 0px !important' }
  },
  listItemText: {
    fontSize: '12px',
    fontWeight: '400',
    ml: '8px',
    color: 'primary.darkVariant1'
  },
  downloadIcon: { height: '16px', width: '16px', color: 'gray.700' },
  listItemIcon: { pl: '8px' },
  menuItem: { p: '8px 24px' }
};

export default styles;
