import React from 'react';
import { createSvgIcon } from '@mui/material';

const LeftLongArrowIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='8' viewBox='0 0 14 8' fill='none'>
    <path
      d='M14.0008 4.58315V3.41649L1.49121 3.43457L4.01238 0.912235L3.18754 0.0874023L0.512376 2.76257C0.184302 3.09074 0 3.53578 0 3.99982C0 4.46386 0.184302 4.9089 0.512376 5.23707L3.18754 7.91223L4.01238 7.0874L1.52621 4.60123L14.0008 4.58315Z'
      fill='#424242'
    />
  </svg>,
  'LeftArrow'
);

export default LeftLongArrowIcon;
