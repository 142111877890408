import { createSvgIcon } from '@mui/material';

const BubbleIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 60 60' fill='none'>
    <rect width='60' height='60' rx='30' fill='#FBFBFB' />
    <path
      d='M39.6154 25.8792L41.6061 21.4997L45.9857 19.5089L41.6061 17.5182L39.6154 13.1387L37.6247 17.5182L33.2452 19.5089L37.6247 21.4997L39.6154 25.8792ZM27.6712 26.6755L23.6898 17.9164L19.7083 26.6755L10.9492 30.6569L19.7083 34.6383L23.6898 43.3975L27.6712 34.6383L36.4303 30.6569L27.6712 26.6755ZM39.6154 35.4346L37.6247 39.8142L33.2452 41.8049L37.6247 43.7956L39.6154 48.1752L41.6061 43.7956L45.9857 41.8049L41.6061 39.8142L39.6154 35.4346Z'
      fill='currentColor'
    />
  </svg>,
  'BubbleIcon'
);
export default BubbleIcon;
