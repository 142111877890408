export const sortFilterCatgories = (a: any, b: any, bottomFilters: string[] = []) => {
  const indexOfA = bottomFilters.findIndex(filter => filter.trim() === a.trim());
  const indexOfB = bottomFilters.findIndex(filter => filter.trim() === b.trim());

  // If both elements are in bottomFilters, compare their indices
  if (indexOfA !== -1 && indexOfB !== -1) {
    return indexOfA - indexOfB;
  }

  // If only one of them is in bottomFilters, prioritize the one that is
  if (indexOfA !== -1) {
    return -1;
  }

  if (indexOfB !== -1) {
    return 1;
  }

  // If none of them is in bottomFilters, or if both are not in bottomFilters, maintain the original order
  return 0;
};

export const isObject = (value: any) => {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
};

export const sortFilterCategoriesOptions = (a: any, b: any) => {
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  }
  return a < b ? -1 : 1;
};

export const formatDate = (date: Date) => {
  return [
    date.getFullYear(),
    String(date.getMonth() + 1).padStart(2, '0'),
    String(date.getDate()).padStart(2, '0')
  ].join('-');
};

export const validateMinMaxValues = (
  values: any,
  minValue: number | undefined,
  maxValue: number | undefined
) => {
  if (minValue !== undefined && (values?.min < minValue || values?.max < minValue)) {
    return `Min and max must be greater than ${minValue}`;
  }
  if (maxValue !== undefined && values?.max > maxValue && values?.min > maxValue) {
    return `Min and max must be less than ${maxValue}`;
  }
  if (maxValue !== undefined && values?.min > maxValue) {
    return `Min must be less than ${maxValue}`;
  }
  if (maxValue !== undefined && values?.max > maxValue) {
    return `Max must be less than ${maxValue}`;
  }
  if (values?.max < values?.min) {
    return 'Min must be less than Max';
  }
  return '';
};
