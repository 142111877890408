import { createSvgIcon } from '@mui/material';

const IrelandFlagIcon = createSvgIcon(
  <svg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5.78571 0H0V12H5.78571V0Z' fill='#159B62' />
    <path d='M17.9996 0H12.2139V12H17.9996V0Z' fill='#FF883E' />
    <path d='M12.2147 0H5.78613V12H12.2147V0Z' fill='white' />
  </svg>,
  'IrelandFlagIcon'
);

export default IrelandFlagIcon;
