import { createSvgIcon } from '@mui/material';

const SubHeaderPipeGreyIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='2' height='42' viewBox='0 0 2 42' fill='none'>
    <path d='M1 1L0.999998 41' stroke='currentColor' strokeLinecap='round' />
  </svg>,
  'SubHeaderPipeGrey'
);

export default SubHeaderPipeGreyIcon;
