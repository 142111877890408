import React from 'react';
import Box from '@mui/material/Box';
import { Tooltip, Typography } from '@mui/material';
import { Stat } from '../types';
import truncateCountAboveMillions from '../../../utils/truncateCountAboveMillions';

const Stats = ({ stats, titleText }: { stats: Array<Stat>; titleText?: string }) => {
  const getTooltip = (stat: Stat) => {
    if (!stat?.value2) return stat.value;
    const textSplit = stat.title.split('/');
    const text1 = textSplit?.[0] ?? '';
    const text2 = textSplit?.[1] ?? '';
    return `${text1}: ${stat?.value} / ${text2}: ${stat?.value2}`;
  };

  const getValue = (stat: Stat) => {
    if (typeof stat.value === 'string') {
      return stat.value;
    }
    if (!stat?.value2) return truncateCountAboveMillions(stat.value);
    return `${truncateCountAboveMillions(stat.value)} / ${truncateCountAboveMillions(stat.value2)}`;
  };
  return (
    <Box
      display='flex'
      alignItems='center'
      sx={{
        backgroundColor: 'gray.background',
        borderRadius: '6px',
        height: '26px',
        padding: '0 8px'
      }}>
      {titleText && (
        <Typography fontWeight={700} fontSize={12} sx={{ color: 'primary.600' }} pr='8px'>
          {titleText + ':'}
        </Typography>
      )}
      {stats.map((stat, index: number) => (
        <Box key={stat.title} display='flex' alignItems='center'>
          <Typography fontSize={12} pr='3px' fontWeight={500}>
            {stat.title} :{' '}
          </Typography>
          <Tooltip title={getTooltip(stat)}>
            <Typography fontWeight={700} fontSize={12}>
              {getValue(stat)}
            </Typography>
          </Tooltip>
          {index < stats.length - 1 && (
            <Box
              sx={{ backgroundColor: 'green.jungle' }}
              margin='0 8px'
              width={4}
              height={4}
              borderRadius='50%'
            />
          )}
        </Box>
      ))}
    </Box>
  );
};

export default React.memo(Stats);
