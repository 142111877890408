const styles = {
  grid: {
    background: 'white'
  },
  resultsContainer: {
    height: '90vh',
    backgroundColor: 'white.main'
  },
  drawerContent: {
    height: '100vh',
    bgcolor: 'white.background',
    width: 310
  },
  header: {
    height: '15%'
  },
  results: {
    // marginTop: 1
  },
  documentContainer: {
    p: 2,

    height: '90vh',

    backgroundColor: 'gray.darkVariant'
  },
  documentContainerUserProfile: {
    overflowY: 'scroll',
  },
  moreBtn: {
    marginTop: 2,
    marginBottom: 2,
    width: 0.5 / 4,
    borderRadius: '30px',
    color: 'white.main',
    backgroundColor: 'primary.main',
    transition: 'transform .2s',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.1)',
      color: 'gray.main',
      background: 'white'
    }
  },
  documentTitle: {
    fontWeight: 'bold',
    color: 'white.main'
  },
  closeBtn: {
    cursor: 'pointer'
  },
  selectedFilterContainer: {
    display: { xs: 'none', md: 'block' }
  },
  btn: {
    position: 'fixed',
    bottom: 30,
    right: 30,
    color: 'gray.darkVariant',
    backgroundColor: 'gray.background',
    borderRadius: 2.5,
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.1)',
      color: 'gray.main',
      background: 'white'
    }
  }
};

export default styles;
