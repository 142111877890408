const styles = {
  reportText: {
    color: 'primary.textVariant',
    fontWeight: '600',
    fontSize: 14,
    alignItems: 'center',
    display: 'inline-flex',
    textTransform: 'capitalize',
    gap: 1,
    '&:hover': {
      background: 'none'
    }
  },
  addToReport: {
    display: 'inline-flex',
    height: 32,
    minWidth: 100,
    py: 1,
    px: 1.5,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    '&:hover': {
      borderRadius: 24,
      background: 'primary.background'
    }
  },
  plusIcon: {
    fontSize: 13
  }
};

export default styles;
