/* eslint-disable react/jsx-props-no-spreading */
import { styled } from '@mui/material';

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: `1px solid #EEEEEE`,
  marginLeft: '16px',
  marginRight: '16px',
  borderRadius: '8px',
  marginBottom: '12px',
  overflow: 'hidden',
  backgroundColor: '#F5F5F5',
  '&::before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(() => ({
  flexDirection: 'row-reverse',
  minHeight: '41px',
  paddingX: '16px 10px',
  '& .MuiAccordionSummary-content': {
    alignItems: 'center'
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  backgroundColor: 'white'
}));

export { Accordion, AccordionSummary, AccordionDetails };
