import React from 'react';

const PDFCellComponent = ({ value, rowData, onPDFButtonClick }) => {
  const handleButtonClick = () => {
    onPDFButtonClick(value, rowData);
  };
  return (
    <>
      {' '}
      {!value ? (
        <>-</>
      ) : (
        <div onClick={handleButtonClick} style={{ color: 'blue', cursor: 'pointer' }}>
          PDF
        </div>
      )}
    </>
  );
};

export default PDFCellComponent;
