import React, { SyntheticEvent } from 'react';

// Material UI
import { Box, List, ListItem, ListItemText } from '@material-ui/core';
import CheckBox from '@mui/material/Checkbox';
import StyledBadge from '../SearchList/StyledBadge';

// Style
import makeStyles from './SearchListTreeView.styles';

// Icon
import RightArrow from '../../Images/svgs/right-arrow';

// Types
import { Option } from '../../types/search-list-treeview';
import customTitleCase from '../../utils/titleCase';

interface CardButtonsPropsType {
  showCount: Boolean;
  selectedOption?: Option;
  options: Array<Option>;
  onOptionSelect?: (value: Option) => void;
  onCheckedBox?: (e: any, value: Option) => void;
}

const CardButtons: React.FC<CardButtonsPropsType> = ({
  showCount,
  options,
  selectedOption,
  onOptionSelect,
  onCheckedBox
}) => {
  const classes = makeStyles();
  return (
    <List>
      {options.map((option: Option) => (
        <ListItem
          key={option.value}
          onClick={(e: SyntheticEvent) => {
            onOptionSelect?.(option);
          }}
          className={
            selectedOption && selectedOption.value === option.value
              ? classes.buttonSelected
              : classes.button
          }
        >
          <Box width='100%' display='flex' alignItems='center' justifyContent='space-between'>
            <CheckBox
              checked={option.checked}
              size='small'
              disabled={option.count === 0}
              onClick={(e: SyntheticEvent) => {
                onCheckedBox?.(e, option);
              }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
            />
            <ListItemText primary={customTitleCase(option.label)} />
            {showCount !== false && (
              <Box className={classes.count}>
                <StyledBadge badgeContent={option.count} max={100000} />
              </Box>
            )}
            <Box display='flex' paddingLeft={1.5}>
              {option.showIcon && (
                <RightArrow active={selectedOption && selectedOption.value === option.value} />
              )}
            </Box>
          </Box>
        </ListItem>
      ))}
    </List>
  );
};

export default React.memo(CardButtons);
