import themev5 from '../../../themev5';

const styles: any = {
  listContainer: {
    width: '40%',
    background: 'white',
    padding: themev5.spacing(1)
  },
  listHeader: {
    width: '40%',
    marginTop: 5,
    borderTopLeftRadius: '30px',
    borderTopRightRadius: '30px',
    padding: 3,
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'white.main',
    background: '#33B187'
  },
  fileName: {
    fontWeight: 500
  },
  btn: {
    marginTop: themev5.spacing(3),
    background: '#33B187',
    borderRadius: themev5.spacing(2.5),
    textTransform: 'capitalize',
    color: 'white.main',
    '&:hover': {
      background: '#33B187'
    }
  }
};

export default styles;
