const compactModeStyle = {
  designationContainer: {
    m: 1,
    p: 1
  },
  timelineConnector: {
    ml: 2.5
  },
  timeLineDot: {
    width: 32,
    height: 32
  }
};

export default compactModeStyle;
