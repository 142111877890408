import { Action } from './types';

export const filterByCategory = (documentGroups: any[], categoryId: string) => {
  if (categoryId === 'all') return documentGroups;
  return documentGroups.filter((doc: any) => doc.id === categoryId);
};

export const filterBySearchText = (documentGroups: any[], text: string) => {
  if (!text) return documentGroups;

  return documentGroups
    .map((docGroup: any) => {
      const filteredDocs = docGroup.documents?.filter(
        (doc: any) =>
          doc.title.toLowerCase().includes(text.toLowerCase()) ||
          doc.actions?.some((action: Action) =>
            action.title.toLowerCase().includes(text.toLowerCase())
          ) ||
          doc.metadata?.some((metadata: string) =>
            metadata.toLowerCase().includes(text.toLowerCase())
          )
      );
      return { ...docGroup, documents: filteredDocs };
    })
    .filter((group: any) => group.documents?.length > 0);
};
