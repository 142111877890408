import { Tooltip, Box, Typography } from '@mui/material';
import React from 'react';
import CustomChip from '../CustomChip';

// styles
import styles from '../styles/homePage.styles';
import { formatMeetingData } from '../utils/common';

interface MeetingCardProps {
  title: string;
  meetingData: any;
}

const MeetingCard = ({ title, meetingData }: MeetingCardProps) => (
  <>
    <Typography sx={styles.graphHeading}>{title}</Typography>
    {meetingData?.meeting_title ? (
      <Box sx={styles.meetingCardContiner}>
        <Tooltip title={meetingData.meeting_title}>
          <Typography noWrap sx={styles.dialogText}>
            {meetingData.meeting_title}
          </Typography>
        </Tooltip>
        <CustomChip label={meetingData.center} />

        <Box width='100%'>
          <Tooltip title={meetingData.title}>
            <Typography noWrap sx={styles.meetingData}>
              {meetingData.title}
            </Typography>
          </Tooltip>
          <Typography sx={styles.meetingData}>
            Start: {formatMeetingData(meetingData?.adcom_meeting_start_date)}
          </Typography>
          <Typography sx={styles.meetingData}>
            End: {formatMeetingData(meetingData?.adcom_meeting_end_date)}
          </Typography>
        </Box>
      </Box>
    ) : (
      <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
        No {title.toLowerCase()} meetings found
      </Typography>
    )}
  </>
);

export default React.memo(MeetingCard);
