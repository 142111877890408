import { createSvgIcon } from '@mui/material/utils';

const ListViewIcon = createSvgIcon(
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_8855_7343)'>
      <path d='M16.0003 2.66687H4.66699V4.0002H16.0003V2.66687Z' fill='currentColor' />
      <path d='M16.0003 7.33315H4.66699V8.66649H16.0003V7.33315Z' fill='currentColor' />
      <path d='M16.0003 12H4.66699V13.3333H16.0003V12Z' fill='currentColor' />
      <path
        d='M1.33333 4.66666C2.06971 4.66666 2.66667 4.06971 2.66667 3.33333C2.66667 2.59695 2.06971 2 1.33333 2C0.596954 2 0 2.59695 0 3.33333C0 4.06971 0.596954 4.66666 1.33333 4.66666Z'
        fill='currentColor'
      />
      <path
        d='M1.33333 9.33355C2.06971 9.33355 2.66667 8.73659 2.66667 8.00022C2.66667 7.26384 2.06971 6.66689 1.33333 6.66689C0.596954 6.66689 0 7.26384 0 8.00022C0 8.73659 0.596954 9.33355 1.33333 9.33355Z'
        fill='currentColor'
      />
      <path
        d='M1.33333 13.9998C2.06971 13.9998 2.66667 13.4028 2.66667 12.6664C2.66667 11.9301 2.06971 11.3331 1.33333 11.3331C0.596954 11.3331 0 11.9301 0 12.6664C0 13.4028 0.596954 13.9998 1.33333 13.9998Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_8855_7343'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'ListViewIcon'
);

const GridViewIcon = createSvgIcon(
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_4074_37817)'>
      <path
        d='M8.25 8.25H0V2.25C0 1.65326 0.237053 1.08097 0.65901 0.65901C1.08097 0.237053 1.65326 0 2.25 0L8.25 0V8.25ZM1.5 6.75H6.75V1.5H2.25C2.05109 1.5 1.86032 1.57902 1.71967 1.71967C1.57902 1.86032 1.5 2.05109 1.5 2.25V6.75Z'
        fill='#757575'
      />
      <path
        d='M18 8.25H9.75V0H15.75C16.3467 0 16.919 0.237053 17.341 0.65901C17.7629 1.08097 18 1.65326 18 2.25V8.25ZM11.25 6.75H16.5V2.25C16.5 2.05109 16.421 1.86032 16.2803 1.71967C16.1397 1.57902 15.9489 1.5 15.75 1.5H11.25V6.75Z'
        fill='#757575'
      />
      <path
        d='M8.25 18H2.25C1.65326 18 1.08097 17.7629 0.65901 17.341C0.237053 16.919 0 16.3467 0 15.75L0 9.75H8.25V18ZM1.5 11.25V15.75C1.5 15.9489 1.57902 16.1397 1.71967 16.2803C1.86032 16.421 2.05109 16.5 2.25 16.5H6.75V11.25H1.5Z'
        fill='#757575'
      />
      <path
        d='M15.75 18H9.75V9.75H18V15.75C18 16.3467 17.7629 16.919 17.341 17.341C16.919 17.7629 16.3467 18 15.75 18ZM11.25 16.5H15.75C15.9489 16.5 16.1397 16.421 16.2803 16.2803C16.421 16.1397 16.5 15.9489 16.5 15.75V11.25H11.25V16.5Z'
        fill='#757575'
      />
    </g>
    <defs>
      <clipPath id='clip0_4074_37817'>
        <rect width='18' height='18' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'GridViewIcon'
);

export { ListViewIcon, GridViewIcon };
