export const blankCardStyle: any = {
  // border: '1px solid #e0e0e0',
  width: '100%',
  height: 88,
  alignItems: 'center',
  justifyContent: 'center',
  mt: 1,
  mb: 1
};

export const cardStyle: any = {
  p: 1.5,
  // width: '100%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  mt: 0.5,
  mb: 0.5,
  border: '1px solid',

  borderRadius: 2,
  width: 190
};
export const badgeStyle: any = {
  '& .MuiBadge-badge': {
    backgroundColor: '#fff',
    color: 'primary.main',
    border: '1px solid',
    borderColor: 'primary.main',
    height: '18px',
    width: '18px',
    fontSize: '10px',
    fontWeight: 700,
    right: '3px'
  }
};
