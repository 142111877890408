// Functional HOC using the useState hook
import React, { useContext, useEffect } from 'react';

import { useLocation, useParams } from 'react-router-dom';
import AuthContext from '../../../store/Auth/AuthProvider';

// custom hooks
import useQuery from '../../../helpers/customHooks/queryParameter';

// constants
import { ERROR_MESSAGES } from '../utils/constants';

// utils
import getMaxPageNumber from '../utils/getMaxPageNumber';

// api
import { getAriaQueryById, getAriaQueryResults } from '../../../api/pages/Aria';

// context
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';
import AriaStore from '../../../store/Aria';
import AriaActions from '../../../store/Aria/actions';
import { saveAriaCache } from '../utils/ariaCache';
import { pathNameManipulation } from '../../ResultsPage/hoc/withFetchingSearchResults';

const withFetchingAriaResults = WrappedComponent => {
  return () => {
    // context store for aria state management
    const { ariaDispatch } = useContext(AriaStore);
    const { dispatch } = useContext(GlobalStore);

    // context store for current user
    const { currentUser } = useContext(AuthContext);
    // get the query arguments from url
    const query = useQuery();
    // get the url parameters
    const { module, source, searchId } = useParams();
    const { pathname } = useLocation();

    const userDetails = {
      role: currentUser['custom:role'],
      userid: currentUser.sub,
      email: '',
      organization: currentUser['custom:organization'],
      tier: currentUser['custom:license']
    };

    useEffect(() => {
      const getData = async () => {
        try {
          ariaDispatch({ type: AriaActions.SET_LOADING, value: true });
          ariaDispatch({ type: AriaActions.SET_EXPLORE_PAGE_RESULTS_COUNT, value: 0 });
          // by default bucket should be all
          const bucket = query.get('bucket') ? query.get('bucket') : 'all';

          let filters = {};
          let searchFromFavouite = false;
          let searchFromProject = '';

          // if this is a results page apply the filter list present in local storage
          if (query.get('resultsPage')) {
            const ariaCache = JSON.parse(sessionStorage.getItem('aria_cache'));
            if (ariaCache) {
              const resultsFilterList = ariaCache?.ariaFilterList;
              if (resultsFilterList?.length) {
                filters['group-id'] = resultsFilterList;
              }
              // required filters when not searching from whole index
              if (Object.keys(ariaCache?.filterOptions)?.length > 0) {
                filters = { ...filters, ...ariaCache?.filterOptions };
              }
            }
            const path = ariaCache?.navigation.split('/');
            if (ariaCache?.navigation.includes('favorites')) {
              searchFromFavouite = true;
            }
            if (path?.includes('project')) {
              // get the projectid from path eg:-"/account/project/219?&tab=applications&selectedSource=us"
              // eslint-disable-next-line prefer-destructuring
              searchFromProject = path.slice(-1).join('').split('?')[0];
            }
          }

          // request body for api call
          const payload = {
            userDetails,
            query: query.get('term'),
            filters,
            filterSource: 'default'
          };
          if (query.get('operationId')) {
            payload.search_operation_id = query.get('operationId');
          } else if (searchFromFavouite) {
            payload.search_from_favorites = searchFromFavouite;
          } else if (searchFromProject) {
            payload.search_project_id = searchFromProject;
          } else if (!searchId) {
            if (query.get('from') && query.get('from') === 'reg360') {
              payload.filterSource = 'default';
            } else {
              payload.filterSource = 'custom';
            }
          }
          // if ()

          // get aria results from rest api
          const res = searchId
            ? await getAriaQueryById(searchId)
            : await getAriaQueryResults(module, bucket, source, payload);

          // checks if response exists else display approriate error message
          if (res && res.data.status === 200) {
            const {
              results,
              suggested_answers: suggestedAnswers,
              top_answer: topAnswer,
              total_results: totalResults,
              did_you_mean: didYouMean,
              search_id: searchID,
              isFavorite,
              inProjects,
              // eslint-disable-next-line no-shadow
              filters,
              global_filters: globalFilters
            } = res.data.body;

            let finalResults = results;
            // eslint-disable-next-line camelcase
            let finalSuggestions = suggestedAnswers;
            if (filters) {
              if (filters.checked_filter) {
                await ariaDispatch({
                  type: AriaActions.SET_FILTERS,
                  value: filters.checked_filter
                });
              }
              if (filters?.checked_filter.search_terms?.length > 0) {
                filters.checked_filter.search_terms.forEach(searchTerm => {
                  finalResults = results.filter(x =>
                    x.text.toLowerCase().includes(searchTerm.toLowerCase())
                  );

                  // eslint-disable-next-line camelcase
                  finalSuggestions = suggestedAnswers.filter(x =>
                    x.text.toLowerCase().includes(searchTerm.toLowerCase())
                  );
                });
              }
              if (filters.unchecked_filters && 'group-id' in filters.unchecked_filters) {
                const prevSession = sessionStorage.getItem('aria_cache');
                if (prevSession) {
                  const prevSessionObj = JSON.parse(prevSession);
                  if (prevSessionObj && prevSessionObj.navigation && prevSessionObj.source) {
                    saveAriaCache(
                      filters.unchecked_filters['group-id'],
                      prevSessionObj.navigation,
                      prevSessionObj.source
                    );
                  }
                }
              }
            }
            if (globalFilters && Object.keys(globalFilters).length > 0) {
              await ariaDispatch({ type: AriaActions.SET_GLOBAL_FILTERS, value: globalFilters });
            }

            if (finalResults.length !== 0) {
              await ariaDispatch({ type: AriaActions.SET_ARIA_RESULTS, value: finalResults });
              await ariaDispatch({ type: AriaActions.SET_ORIGINAL_RESULTS, value: results });
              await ariaDispatch({ type: AriaActions.SET_ORIGNAL_TOP_ANSWER, value: topAnswer });
              await ariaDispatch({ type: AriaActions.SET_TOP_ANSWER, value: topAnswer });
              await ariaDispatch({ type: AriaActions.SET_DID_YOU_MEAN_TEXT, value: didYouMean });
              await ariaDispatch({
                type: AriaActions.SET_SUGGESTED_ANS,
                value: finalSuggestions
              });
              await ariaDispatch({
                type: AriaActions.SET_ORIGINAL_TOTAL_RESULTS,
                value: finalResults.length
              });
              await ariaDispatch({ type: AriaActions.SET_TOTAL_RESULTS, value: totalResults });
              await ariaDispatch({ type: AriaActions.SET_SEARCH_ID, value: searchID });
              await ariaDispatch({ type: AriaActions.SET_IS_FAVORITE, value: isFavorite });
              await ariaDispatch({ type: AriaActions.SET_IN_PROJECT, value: inProjects });
              // get the maximum page number possible based on total results
              const maxPageNum = getMaxPageNumber(totalResults);
              await ariaDispatch({ type: AriaActions.SET_MAX_PAGE, value: maxPageNum });
              // reset current page number to 1 for every new search
              await ariaDispatch({ type: AriaActions.SET_PAGE, value: 1 });
              pathNameManipulation(searchId, searchID, pathname, query);
            } else {
              ariaDispatch({
                type: AriaActions.SET_ERROR,
                value: ERROR_MESSAGES['404']
              });
            }
          } else if (res?.status === 429) {
            if (res.data.body?.includes(':')) {
              dispatch({
                type: GlobalActions.SET_SERVICE_RESTRICTION_DETAILS,
                value: { message: res.data.body?.split(':')[1], status: true, closeBtn: false }
              });
            }
          } else {
            ariaDispatch({
              type: AriaActions.SET_DID_YOU_MEAN_TEXT,
              value: res.data.body.did_you_mean
            });
            // check for 404 or 500 errors from rest api
            ariaDispatch({
              type: AriaActions.SET_ERROR,
              value: ERROR_MESSAGES[`${res?.data.status || 500}`]
            });
          }

          ariaDispatch({ type: AriaActions.SET_LOADING, value: false });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      };
      // clear the previous earch details when the user comes to banner page
      ariaDispatch({ type: AriaActions.SET_ORIGINAL_RESULTS, value: [] });
      ariaDispatch({ type: AriaActions.SET_ARIA_RESULTS, value: [] });
      ariaDispatch({ type: AriaActions.SET_SUGGESTED_ANS, value: [] });
      ariaDispatch({ type: AriaActions.SET_DOCUMENT_OPEN, value: false });
      ariaDispatch({ type: AriaActions.SET_DOCUMENT_LINK, value: '' });
      ariaDispatch({ type: AriaActions.SET_FILTERS, value: {} });
      ariaDispatch({ type: AriaActions.SET_TOP_ANSWER, value: '' });
      ariaDispatch({ type: AriaActions.SET_DID_YOU_MEAN_TEXT, value: '' });
      ariaDispatch({ type: AriaActions.SET_ERROR, value: '' });
      getData();
    }, [ariaDispatch, query, module, source, searchId]);

    return <WrappedComponent />;
  };
};

export default withFetchingAriaResults;
