const styles = {
  navigationText: {
    color: 'black.light'
  },
  back: {
    cursor: 'pointer',
    paddingLeft: '40px',
    paddingTop: '10px'
  },
  title: {
    color: 'black.main',
    fontWeight: 'bold'
  },
  secondaryTitle: {
    color: 'black.main',
    fontWeight: 'bold',
    paddingBottom: '4px'
  },
  date: {
    color: 'gray.main',
    fontWeight: 'bold'
  },
  tags: {
    color: 'blue.light',
    paddingTop: '4px'
  },
  secondary: {
    paddingTop: '10px',
    paddingLeft: '20px'
  },
  videoBlock: {
    border: '50px solid green'
  },
  description: {
    color: 'gray.main'
  }
};

export default styles;
