import titleCase from '../../../utils/titleCase';
import { CardData } from '../types';

import getApprovalTracks from './getApprovalTracks';

interface CTAppAPIData {
  acronym: string;
  adverse_events: Array<any>;
  age_category: Array<string>;
  agency_class: Array<string>;
  allocation: string;
  approval_date: string;
  arms_enrollment: string;
  brief_title: string;
  caregiver_masked: any;
  caregiver_masked_flag: boolean;
  comparator_arm: string;
  conditions: string;
  countries: Array<string>;
  design_groups: string;
  document_count: number;
  document_total_page_count: number;
  enrollment: string;
  enrollment_info: string;
  first_submitted_year: string;
  gender: string;
  has_expanded_access: string;
  has_expanded_access_flag: boolean;
  healthy_volunteers: string;
  healthy_volunteers_flag: boolean;
  inProjects: Array<any>;
  interventions: Array<string>;
  investigator_masked: any;
  investigator_masked_flag: boolean;
  is_favorite: boolean;
  is_subscribed: boolean;
  last_updated_year: string;
  location: Array<Location>;
  masking: string;
  matched_synonyms: Array<string>;
  nct_id: string;
  number_of_arms: string;
  official_title: string;
  outcomes_assessor_masked: any;
  outcomes_assessor_masked_flag: boolean;
  overall_status: string;
  overall_status_formated: string;
  patient_summary: Array<any>;
  pcd_delay: number;
  phase: string;
  primary_completion: string;
  primary_completion_date: string;
  primary_outcome: Array<any>;
  secondary_outcome: Array<any>;
  source_index: string;
  sponsors: Array<string>;
  start_date: string;
  start_year: string;
  study_completion: string;
  study_completion_year: string;
  study_first_submitted_date: string;
  study_type: string;
  subject_masked: any;
  subject_masked_flag: boolean;
  active_ingredients: string;
  application_number: string;
  generic_name: string;
  has_adverse_events: boolean;
  trade_name: string;
  sponsor_name: string;
  product_name: string;
}

export const CT_IDENTIFIER_KEY = 'nct_id';

const mapCTApps = (apiData: CTAppAPIData) => {
  const approvalTracks = getApprovalTracks(apiData, 'ct');
  const cardProps: CardData = {
    source: 'ct',
    title: titleCase(apiData.brief_title ?? apiData.generic_name ?? apiData.active_ingredients),
    groupTitle: Array.isArray(apiData.interventions)
      ? titleCase(apiData.interventions.join(' '))
      : titleCase(apiData.interventions),
    identifier: apiData.nct_id ?? (apiData as any)?.application_number,
    showDiscontinued: false,
    isFavorite: apiData.is_favorite,
    isSubscribed: apiData.is_subscribed,
    metadata: [
      {
        key: 'Sponsor Name',
        value: titleCase(
          (Array.isArray(apiData.sponsors) ? apiData.sponsors.join(';') : apiData.sponsors) || '---'
        )
      },
      {
        key: 'Conditions',
        value: Array.isArray(apiData.conditions)
          ? apiData.conditions.join(';')
          : apiData.conditions || '---'
      },
      {
        key: 'Phase',
        value: titleCase(
          (Array.isArray(apiData.phase) ? apiData.phase.join(' ') : apiData.phase) || '---'
        )
      },
      { key: 'Primary Completion Year', value: apiData.primary_completion }
    ],
    approvalTracks,
    labels: ['CT'],
    colorScheme: apiData?.source_index,
    ...apiData
  };

  return cardProps;
};

export default mapCTApps;
