import { createSvgIcon } from '@mui/material';

const UsaFlagIcon = createSvgIcon(
  <svg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M18.0004 0H0V0.923304H18.0004V0Z' fill='#B22234' />
    <path d='M18.0004 0.923828H0V1.84713H18.0004V0.923828Z' fill='white' />
    <path d='M18.0004 1.84668H0V2.76998H18.0004V1.84668Z' fill='#B22234' />
    <path d='M18.0004 2.76953H0V3.69284H18.0004V2.76953Z' fill='white' />
    <path d='M18.0004 3.69238H0V4.61569H18.0004V3.69238Z' fill='#B22234' />
    <path d='M18.0004 4.61621H0V5.53952H18.0004V4.61621Z' fill='white' />
    <path d='M18.0004 5.54004H0V6.46334H18.0004V5.54004Z' fill='#B22234' />
    <path d='M18.0004 6.46289H0V7.3862H18.0004V6.46289Z' fill='white' />
    <path d='M18.0004 7.38672H0V8.31002H18.0004V7.38672Z' fill='#B22234' />
    <path d='M18.0004 8.30957H0V9.23287H18.0004V8.30957Z' fill='white' />
    <path d='M18.0004 9.2334H0V10.1567H18.0004V9.2334Z' fill='#B22234' />
    <path d='M18.0004 10.1562H0V11.0796H18.0004V10.1562Z' fill='white' />
    <path d='M9.00022 0H0V6.46313H9.00022V0Z' fill='#3C3B6E' />
    <path
      d='M0.964128 0.991429L0.755194 1.14809L0.834267 0.893432L0.625977 0.732104H0.883769L0.964128 0.481445L1.04449 0.732104H1.30228L1.09399 0.893432L1.17306 1.14809L0.964128 0.991429Z'
      fill='white'
    />
    <path
      d='M2.37819 0.991429L2.16926 1.14809L2.24833 0.893432L2.04004 0.732104H2.29783L2.37819 0.481445L2.45855 0.732104H2.71634L2.50805 0.893432L2.58712 1.14809L2.37819 0.991429Z'
      fill='white'
    />
    <path
      d='M3.79323 0.991429L3.5843 1.14809L3.66337 0.893432L3.45508 0.732104H3.71287L3.79323 0.481445L3.87359 0.732104H4.13138L3.92309 0.893432L4.00216 1.14809L3.79323 0.991429Z'
      fill='white'
    />
    <path
      d='M5.20729 0.991429L4.99836 1.14809L5.07743 0.893432L4.86914 0.732104H5.12693L5.20729 0.481445L5.28765 0.732104H5.54544L5.33715 0.893432L5.41623 1.14809L5.20729 0.991429Z'
      fill='white'
    />
    <path
      d='M6.62135 0.991429L6.41242 1.14809L6.49149 0.893432L6.2832 0.732104H6.541L6.62135 0.481445L6.70171 0.732104H6.95951L6.75121 0.893432L6.83029 1.14809L6.62135 0.991429Z'
      fill='white'
    />
    <path
      d='M8.03542 0.991429L7.82648 1.14809L7.90556 0.893432L7.69727 0.732104H7.95506L8.03542 0.481445L8.11578 0.732104H8.37357L8.16528 0.893432L8.24435 1.14809L8.03542 0.991429Z'
      fill='white'
    />
    <path
      d='M1.67116 1.79776L1.46223 1.95376L1.5413 1.69976L1.33301 1.53777H1.5908L1.67116 1.28711L1.75152 1.53777H2.00931L1.80102 1.69976L1.88009 1.95376L1.67116 1.79776Z'
      fill='white'
    />
    <path
      d='M3.0862 1.79776L2.87726 1.95376L2.95634 1.69976L2.74805 1.53777H3.00584L3.0862 1.28711L3.16656 1.53777H3.42435L3.21606 1.69976L3.29513 1.95376L3.0862 1.79776Z'
      fill='white'
    />
    <path
      d='M4.50026 1.79776L4.29133 1.95376L4.3704 1.69976L4.16211 1.53777H4.4199L4.50026 1.28711L4.58062 1.53777H4.83841L4.63012 1.69976L4.70919 1.95376L4.50026 1.79776Z'
      fill='white'
    />
    <path
      d='M5.91432 1.79776L5.70539 1.95376L5.78446 1.69976L5.57617 1.53777H5.83396L5.91432 1.28711L5.99468 1.53777H6.25247L6.04418 1.69976L6.12326 1.95376L5.91432 1.79776Z'
      fill='white'
    />
    <path
      d='M7.32839 1.79776L7.11945 1.95376L7.19853 1.69976L6.99023 1.53777H7.24803L7.32839 1.28711L7.40874 1.53777H7.66654L7.45825 1.69976L7.53732 1.95376L7.32839 1.79776Z'
      fill='white'
    />
    <path
      d='M0.964128 2.60276L0.755194 2.75942L0.834267 2.50476L0.625977 2.34277H0.883769L0.964128 2.09277L1.04449 2.34277H1.30228L1.09399 2.50476L1.17306 2.75942L0.964128 2.60276Z'
      fill='white'
    />
    <path
      d='M2.37819 2.60276L2.16926 2.75942L2.24833 2.50476L2.04004 2.34277H2.29783L2.37819 2.09277L2.45855 2.34277H2.71634L2.50805 2.50476L2.58712 2.75942L2.37819 2.60276Z'
      fill='white'
    />
    <path
      d='M3.79323 2.60276L3.5843 2.75942L3.66337 2.50476L3.45508 2.34277H3.71287L3.79323 2.09277L3.87359 2.34277H4.13138L3.92309 2.50476L4.00216 2.75942L3.79323 2.60276Z'
      fill='white'
    />
    <path
      d='M5.20729 2.60276L4.99836 2.75942L5.07743 2.50476L4.86914 2.34277H5.12693L5.20729 2.09277L5.28765 2.34277H5.54544L5.33715 2.50476L5.41623 2.75942L5.20729 2.60276Z'
      fill='white'
    />
    <path
      d='M6.62135 2.60276L6.41242 2.75942L6.49149 2.50476L6.2832 2.34277H6.541L6.62135 2.09277L6.70171 2.34277H6.95951L6.75121 2.50476L6.83029 2.75942L6.62135 2.60276Z'
      fill='white'
    />
    <path
      d='M8.03542 2.60276L7.82648 2.75942L7.90556 2.50476L7.69727 2.34277H7.95506L8.03542 2.09277L8.11578 2.34277H8.37357L8.16528 2.50476L8.24435 2.75942L8.03542 2.60276Z'
      fill='white'
    />
    <path
      d='M1.67116 3.40842L1.46223 3.56508L1.5413 3.31042L1.33301 3.1491H1.5908L1.67116 2.89844L1.75152 3.1491H2.00931L1.80102 3.31042L1.88009 3.56508L1.67116 3.40842Z'
      fill='white'
    />
    <path
      d='M3.08522 3.40842L2.87629 3.56508L2.95536 3.31042L2.74707 3.1491H3.00486L3.08522 2.89844L3.16558 3.1491H3.42337L3.21508 3.31042L3.29415 3.56508L3.08522 3.40842Z'
      fill='white'
    />
    <path
      d='M4.50026 3.40842L4.29133 3.56508L4.3704 3.31042L4.16211 3.1491H4.4199L4.50026 2.89844L4.58062 3.1491H4.83841L4.63012 3.31042L4.70919 3.56508L4.50026 3.40842Z'
      fill='white'
    />
    <path
      d='M5.91432 3.40842L5.70539 3.56508L5.78446 3.31042L5.57617 3.1491H5.83396L5.91432 2.89844L5.99468 3.1491H6.25247L6.04418 3.31042L6.12326 3.56508L5.91432 3.40842Z'
      fill='white'
    />
    <path
      d='M7.32839 3.40842L7.11945 3.56508L7.19853 3.31042L6.99023 3.1491H7.24803L7.32839 2.89844L7.40874 3.1491H7.66654L7.45825 3.31042L7.53732 3.56508L7.32839 3.40842Z'
      fill='white'
    />
    <path
      d='M0.964128 4.21475L0.755194 4.37075L0.834267 4.11676L0.625977 3.95476H0.883769L0.964128 3.7041L1.04449 3.95476H1.30228L1.09399 4.11676L1.17306 4.37075L0.964128 4.21475Z'
      fill='white'
    />
    <path
      d='M2.37819 4.21475L2.16926 4.37075L2.24833 4.11676L2.04004 3.95476H2.29783L2.37819 3.7041L2.45855 3.95476H2.71634L2.50805 4.11676L2.58712 4.37075L2.37819 4.21475Z'
      fill='white'
    />
    <path
      d='M3.79323 4.21475L3.5843 4.37075L3.66337 4.11676L3.45508 3.95476H3.71287L3.79323 3.7041L3.87359 3.95476H4.13138L3.92309 4.11676L4.00216 4.37075L3.79323 4.21475Z'
      fill='white'
    />
    <path
      d='M5.20729 4.21475L4.99836 4.37075L5.07743 4.11676L4.86914 3.95476H5.12693L5.20729 3.7041L5.28765 3.95476H5.54544L5.33715 4.11676L5.41623 4.37075L5.20729 4.21475Z'
      fill='white'
    />
    <path
      d='M6.62135 4.21475L6.41242 4.37075L6.49149 4.11676L6.2832 3.95476H6.541L6.62135 3.7041L6.70171 3.95476H6.95951L6.75121 4.11676L6.83029 4.37075L6.62135 4.21475Z'
      fill='white'
    />
    <path
      d='M8.03542 4.21475L7.82648 4.37075L7.90556 4.11676L7.69727 3.95476H7.95506L8.03542 3.7041L8.11578 3.95476H8.37357L8.16528 4.11676L8.24435 4.37075L8.03542 4.21475Z'
      fill='white'
    />
    <path
      d='M0.964128 5.82444L0.755194 5.9811L0.834267 5.72644L0.625977 5.56511H0.883769L0.964128 5.31445L1.04449 5.56511H1.30228L1.09399 5.72644L1.17306 5.9811L0.964128 5.82444Z'
      fill='white'
    />
    <path
      d='M2.37819 5.82444L2.16926 5.9811L2.24833 5.72644L2.04004 5.56511H2.29783L2.37819 5.31445L2.45855 5.56511H2.71634L2.50805 5.72644L2.58712 5.9811L2.37819 5.82444Z'
      fill='white'
    />
    <path
      d='M3.79323 5.82444L3.5843 5.9811L3.66337 5.72644L3.45508 5.56511H3.71287L3.79323 5.31445L3.87359 5.56511H4.13138L3.92309 5.72644L4.00216 5.9811L3.79323 5.82444Z'
      fill='white'
    />
    <path
      d='M5.20729 5.82444L4.99836 5.9811L5.07743 5.72644L4.86914 5.56511H5.12693L5.20729 5.31445L5.28765 5.56511H5.54544L5.33715 5.72644L5.41623 5.9811L5.20729 5.82444Z'
      fill='white'
    />
    <path
      d='M6.62135 5.82444L6.41242 5.9811L6.49149 5.72644L6.2832 5.56511H6.541L6.62135 5.31445L6.70171 5.56511H6.95951L6.75121 5.72644L6.83029 5.9811L6.62135 5.82444Z'
      fill='white'
    />
    <path
      d='M8.03542 5.82444L7.82648 5.9811L7.90556 5.72644L7.69727 5.56511H7.95506L8.03542 5.31445L8.11578 5.56511H8.37357L8.16528 5.72644L8.24435 5.9811L8.03542 5.82444Z'
      fill='white'
    />
    <path
      d='M1.67116 5.01975L1.46223 5.17641L1.5413 4.92175L1.33301 4.75976H1.5908L1.67116 4.50977L1.75152 4.75976H2.00931L1.80102 4.92175L1.88009 5.17641L1.67116 5.01975Z'
      fill='white'
    />
    <path
      d='M3.08522 5.01975L2.87629 5.17641L2.95536 4.92175L2.74707 4.75976H3.00486L3.08522 4.50977L3.16558 4.75976H3.42337L3.21508 4.92175L3.29415 5.17641L3.08522 5.01975Z'
      fill='white'
    />
    <path
      d='M4.50026 5.01975L4.29133 5.17641L4.3704 4.92175L4.16211 4.75976H4.4199L4.50026 4.50977L4.58062 4.75976H4.83841L4.63012 4.92175L4.70919 5.17641L4.50026 5.01975Z'
      fill='white'
    />
    <path
      d='M5.91432 5.01975L5.70539 5.17641L5.78446 4.92175L5.57617 4.75976H5.83396L5.91432 4.50977L5.99468 4.75976H6.25247L6.04418 4.92175L6.12326 5.17641L5.91432 5.01975Z'
      fill='white'
    />
    <path
      d='M7.32839 5.01975L7.11945 5.17641L7.19853 4.92175L6.99023 4.75976H7.24803L7.32839 4.50977L7.40874 4.75976H7.66654L7.45825 4.92175L7.53732 5.17641L7.32839 5.01975Z'
      fill='white'
    />
    <path d='M18.0004 11.0801H0V12H18.0004V11.0801Z' fill='#B22234' />
  </svg>,
  'UsaFlagIcon'
);

export default UsaFlagIcon;
