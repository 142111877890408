import { Stack, CircularProgress, Typography } from '@mui/material';

const LoadingIndicator = () => (
  <Stack
    sx={{
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: 'white'
    }}
    alignItems='center'
    justifyContent='center'>
    <CircularProgress
      thickness={8}
      size='small'
      sx={{ width: 25, height: 25, color: 'gray.dark', fontWeight: 'bold' }}
    />
    <Typography>
      <b>Initializing collaborate...</b>
    </Typography>
  </Stack>
);

export default LoadingIndicator;
