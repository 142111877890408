import React, { useState } from 'react';

import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

import CustomTimelinePropsInterface from '../../../interfaces/CustomTimelinePropsInterface';
import styles from './TimelineApprovalTime.styles';

import clock from '../../../Images/clock.svg';

const TimelineApprovalTime: React.FC<CustomTimelinePropsInterface> = ({
  column,
  dataItem
}: CustomTimelinePropsInterface) => {
  const [showPopper, setShowPopper] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [mouseOverContent, setMouseOverContent] = useState(false);
  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    if (column?.showPopover) {
      setAnchorEl(event.currentTarget);
      setShowPopper(true);
    }
  };

  const handleMouseLeave = () => {
    if (!mouseOverContent) {
      setAnchorEl(null);
      setShowPopper(false);
    }
  };

  if (column === undefined) {
    return <Box />;
  }

  return (
    <Tooltip title={'tooltip' in column ? dataItem[column.name] : ''}>
      <Box sx={styles.textAlignment} onMouseOver={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Stack spacing={1} direction='row' pl={4}>
          <img style={{ width: '16px' }} src={clock} alt={dataItem[column.name]} />
          <Typography variant='body1' sx={{ marginLeft: '0px' }}>
            {' '}
            {dataItem[column.name]}{' '}
          </Typography>
        </Stack>
        <Popper
          open={showPopper}
          placement='bottom'
          anchorEl={anchorEl}
          onMouseOver={() => setMouseOverContent(true)}
          onMouseLeave={() => {
            setMouseOverContent(false);
            setAnchorEl(null);
            setShowPopper(false);
          }}>
          <Paper
            elevation={1}
            sx={{
              maxHeight: 300,
              overflowY: 'auto',
              borderRadius: '8px',
              p: 1,
              width: 300,
              ml: -10
            }}>
            <Box
              sx={{
                width: '100%',
                textAlign: 'left',
                backgroundColor: 'primary.backgroundDark',
                marginBottom: '10px',
                borderRadius: '16px',
                padding: 2,
                textTransform: 'capitalize'
              }}>
              {column?.popOverColumn &&
                Object.keys(column?.popOverColumn).map(key => (
                  <Typography style={{ fontSize: '14px' }}>
                    <>
                      <b>{`${column?.popOverColumn[key]}: `} </b>
                      {Array.isArray(dataItem[key]) ? dataItem[key][0] : dataItem[key]}
                      <br />
                      <br />
                    </>
                  </Typography>
                ))}
            </Box>
          </Paper>
        </Popper>
      </Box>
    </Tooltip>
  );
};

export default React.memo(TimelineApprovalTime);
