import { createSvgIcon } from '@mui/material';

const MedicinePillIcon = createSvgIcon(
  <svg width='41' height='40' viewBox='0 0 41 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1965_9392)'>
      <path
        d='M7 18.875V13.25C7 9.5285 10.0285 6.5 13.75 6.5C17.4715 6.5 20.5 9.5285 20.5 13.25V18.875H7ZM20.7903 30.494C19.9049 29.2846 19.375 27.7985 19.375 26.1875C19.375 22.1555 22.6555 18.875 26.6875 18.875C28.2985 18.875 29.7835 19.4049 30.994 20.2903L20.7914 30.4929L20.7903 30.494ZM22.381 32.0848C23.5904 32.9701 25.0765 33.5 26.6875 33.5C30.7195 33.5 34 30.2195 34 26.1875C34 24.5765 33.4701 23.0915 32.5848 21.881L22.3821 32.0836L22.381 32.0848ZM17.125 26.1875C17.125 24.3256 17.665 22.5942 18.5853 21.125H7V26.75C7 30.4715 10.0285 33.5 13.75 33.5C15.6839 33.5 17.4265 32.6776 18.6584 31.3693C17.692 29.8753 17.125 28.0989 17.125 26.1875Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1965_9392'>
        <rect width='27' height='27' fill='none' transform='translate(7 6.5)' />
      </clipPath>
    </defs>
  </svg>,
  'MedicinePill'
);

export default MedicinePillIcon;
