import { GridColDef } from '@mui/x-data-grid-pro';

// other components
import DatagridTooltip from '../../../components/Datagrid/DatagridTooltip';
import DatagridLink from '../../../components/Datagrid/DatagridLink';
import renderCellExpand from '../../../components/Datagrid/DatagridPopover';

export const ADVERSE_EVENTS = 'adverse_events';
const ADVERSE_EVENTS_LINK = 'https://clinicaltrials.gov/ct2/show/results';
export const CT_ADVERSE_EVENTS_COLUMNS: GridColDef[] = [
  {
    field: 'nct_id',
    headerName: 'Link',
    headerClassName: 'table-header',
    width: 50,
    cellClassName: 'table-row',
    renderCell: (params: any) => DatagridLink(params, 'usnlm'),
    valueGetter: ({ row }) => `${ADVERSE_EVENTS_LINK}/${row.nct_id}`,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'result_group_title',
    headerName: 'Result Group Title',
    headerClassName: 'table-header',
    width: 300,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${row.result_group_title}`
  },
  {
    field: 'result_group_description',
    headerName: 'Result Group Description',
    headerClassName: 'table-header',
    width: 400,
    cellClassName: 'table-row',
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.result_group_description}`
  },
  {
    field: 'event_type',
    headerName: 'Event Type',
    headerClassName: 'table-header',
    width: 200,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${row.event_type}`,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'organ_system',
    headerName: 'Organ System',
    headerClassName: 'table-header',
    width: 400,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${row.organ_system}`
  },
  {
    field: 'adverse_event_term',
    headerName: 'Adverse Event Term',
    headerClassName: 'table-header',
    width: 350,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${row.adverse_event_term}`
  },
  {
    field: 'subjects_affected',
    headerName: 'N Affected',
    headerClassName: 'table-header',
    width: 200,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${row.subjects_affected}`,
    align: 'center',
    headerAlign: 'center',
    type: 'number'
  },
  {
    field: 'subjects_at_risk',
    headerName: 'N at Risk',
    headerClassName: 'table-header',
    width: 200,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${row.subjects_at_risk}`,
    align: 'center',
    headerAlign: 'center',
    type: 'number'
  },
  {
    field: 'risk_ratio',
    headerName: 'Incidence (%)',
    headerClassName: 'table-header',
    width: 200,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${row.risk_ratio}`,
    align: 'center',
    headerAlign: 'center',
    type: 'number'
  }
];
