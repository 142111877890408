import React, { useEffect, useContext, useState, useMemo } from 'react';

import Stack from '@mui/material/Stack';
import { isArray, capitalize } from 'lodash';
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import Actions from '../../../store/RegulatoryInformation/actions';

import { ADVERSE_EVENTS, CT_ADVERSE_EVENTS_COLUMNS } from './constants';

import regulatoryInformationAPI from '../../../api/pages/Regulatory360';
import VivproDatagridPro from '../../../components/Datagrid';

const AdverseEvents = () => {
  const { regulatoryState, regulatoryDispatch } = useContext(RegulatoryInformationStore);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (
      regulatoryState?.adverseEvents.length === 0 &&
      regulatoryState?.applicationDetails?.application_number &&
      regulatoryState?.db
    ) {
      const getData = async () => {
        setLoading(true);
        const data: any = await regulatoryInformationAPI(
          regulatoryState.db,
          regulatoryState?.applicationDetails?.application_number,
          ADVERSE_EVENTS
        );
        if (data?.data?.body?.length) {
          regulatoryDispatch({ type: Actions.SET_ADVERSE_EVENTS, value: data.data.body });
        }
        setLoading(false);
      };

      getData();
    }
  }, [
    regulatoryState?.adverseEvents,
    regulatoryState?.applicationDetails?.application_number,
    regulatoryState?.db
  ]);

  const adverseEventsData = useMemo(() => {
    if (isArray(regulatoryState?.adverseEvents)) {
      const data = regulatoryState?.adverseEvents.map((item, index) => {
        const {
          adverse_event_term: adverseEventTerm = '',
          event_type: eventType = '',
          organ_system: organSystem = '',
          risk_ratio: riskRatio = 0,
          subjects_affected: subjectsAffected = 0,
          subjects_at_risk: subjectsAtRisk = 0,
          result_group_code: resultGroupCode = '',
          result_group_title: resultGroupTitle = '',
          result_group_description: resultGroupDescription = ''
        } = item;
        const eventTypeCamelCase = capitalize(eventType) || '';
        return {
          adverse_event_term: adverseEventTerm,
          event_type: eventTypeCamelCase,
          organ_system: organSystem,
          risk_ratio: riskRatio,
          subjects_affected: subjectsAffected,
          subjects_at_risk: subjectsAtRisk,
          result_group_code: resultGroupCode,
          result_group_title: resultGroupTitle,
          result_group_description: resultGroupDescription,
          unique_id: `${index}_${riskRatio}`,
          overall_status_formated: '',
          nct_id: regulatoryState?.applicationDetails?.application_number
        };
      });
      return data;
    }
    return [];
  }, [regulatoryState?.adverseEvents]);

  return (
    <Stack id='ListData' sx={{ height: '65vh', width: '100%' }}>
      <VivproDatagridPro
        rows={adverseEventsData}
        columnsMapping={CT_ADVERSE_EVENTS_COLUMNS}
        rowId='unique_id'
        loading={isLoading}
        useQuickSearch
      />
    </Stack>
  );
};

export default AdverseEvents;
