import { createSvgIcon } from '@mui/material';

const UserIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='12' height='14' viewBox='0 0 12 14' fill='none'>
    <path d='M11.25 14.0004H10.0833V11.0586C10.0829 10.6013 9.90099 10.1628 9.5776 9.83942C9.25422 9.51603 8.81575 9.33415 8.35842 9.33369H3.64158C3.18425 9.33415 2.74578 9.51603 2.42239 9.83942C2.09901 10.1628 1.91713 10.6013 1.91667 11.0586V14.0004H0.75V11.0586C0.750926 10.292 1.05587 9.55705 1.59795 9.01497C2.14002 8.47289 2.87497 8.16795 3.64158 8.16702H8.35842C9.12503 8.16795 9.85998 8.47289 10.4021 9.01497C10.9441 9.55705 11.2491 10.292 11.25 11.0586V14.0004Z' />
    <path d='M6 6.99997C5.30777 6.99997 4.63108 6.7947 4.05551 6.41011C3.47993 6.02553 3.03133 5.4789 2.76642 4.83936C2.50152 4.19982 2.4322 3.49609 2.56725 2.81716C2.7023 2.13822 3.03564 1.51458 3.52513 1.0251C4.01461 0.535614 4.63825 0.202271 5.31719 0.0672226C5.99612 -0.0678257 6.69985 0.00148598 7.33939 0.266393C7.97894 0.531299 8.52556 0.979903 8.91015 1.55548C9.29473 2.13105 9.5 2.80774 9.5 3.49997C9.49908 4.42794 9.13003 5.31765 8.47385 5.97382C7.81768 6.63 6.92798 6.99905 6 6.99997V6.99997ZM6 1.16664C5.53851 1.16664 5.08739 1.30349 4.70367 1.55988C4.31996 1.81627 4.02089 2.18068 3.84428 2.60704C3.66768 3.0334 3.62147 3.50256 3.7115 3.95518C3.80153 4.4078 4.02376 4.82356 4.35009 5.14989C4.67641 5.47621 5.09217 5.69844 5.54479 5.78847C5.99741 5.8785 6.46657 5.8323 6.89293 5.65569C7.31929 5.47909 7.68371 5.18002 7.9401 4.7963C8.19649 4.41259 8.33334 3.96146 8.33334 3.49997C8.33334 2.88113 8.0875 2.28764 7.64992 1.85006C7.21233 1.41247 6.61884 1.16664 6 1.16664Z' />
  </svg>,
  'User'
);

export default UserIcon;
