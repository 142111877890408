const styles = {
  otherUserPopover: {
    maxHeight: 300,
    overflow: 'auto',
    borderRadius: 2,
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      marginY: '5px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,0.26)',
      marginY: '5px'
    }
  },
  otherUserPopoverItem: {
    px: 3,
    pr: 4,
    py: 0.75,
    color: 'gray.800',
    fontWeight: 400
  },
  otherUserPopoverItemText: { m: 0, '& span': { fontSize: 14 } },
  groupListItemAvatar: {
    width: 32,
    height: 32,
    fontSize: 15,
    color: 'white.main',
    bgcolor: 'primary.500',
    fontWeight: 600
  },
  groupName: {
    fontWeight: 700,
    fontSize: 14,
    color: 'gray.900'
  },
  userNames: { fontSize: 12, color: 'gray.700', fontWeight: 500, letterSpacing: '0.2px' },
  otherUserNames: {
    textDecoration: 'underline',
    fontWeight: 700,
    '&:hover': { color: 'secondary.700', letterSpacing: '0.2px' }
  },
  listItemIcon: { minWidth: 0, ml: 'auto', '& span': { p: 0 } },
  groupSelectionCheckBox: { fontSize: 20, p: 0 }
};

export default styles;
