import React from 'react';
import { Box, Button, ButtonGroup, MenuItem, MenuList, Popover } from '@mui/material';
import SendIcon from '../../Images/svgs/sendIcon';
import styles from '../../pages/RegulatoryInformation/ApprovalHistory/styles';
import { KeyDownIcon } from '../../assets/svgs/Icons';

const AskRIAButtonAdcomm = ({
  setOpenAriaSearch,
  ariaOption,
  setAriaOption,
  disabled,
  meetingStatus = 'all',
  showCurrentMeeting = true,
  currentMeeting = true
}: any) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'aria-popover' : undefined;
  const meetingStatusString = meetingStatus.charAt(0).toUpperCase() + meetingStatus.slice(1);

  const ariaOptionsLables = showCurrentMeeting
    ? [
        { label: 'Current Meeting', value: 'current' },
        { label: `${meetingStatusString} Meetings`, value: 'all' }
      ]
    : [{ label: `${meetingStatusString} Meetings`, value: 'all' }];
  const handleButtonClick = () => {
    setOpenAriaSearch(true);
  };

  const handleDropdownClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOptionClick = (value: string) => {
    setAnchorEl(null);
    setAriaOption(value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box id='adcomm-aria-ria-button' sx={styles.buttonGroup}>
      <ButtonGroup aria-label='split button'>
        <Button
          id='dropdown'
          value='dropdown'
          aria-label='dropdown'
          disabled={disabled}
          sx={{ ...styles.dropdownButton }}
          onClick={handleDropdownClick}>
          {ariaOption === 'current' && showCurrentMeeting
            ? 'Current Meeting'
            : `${meetingStatusString} Meetings`}{' '}
          <KeyDownIcon sx={{ fontSize: 10, color: 'gray.0', marginLeft: '6px' }} />
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}>
          <MenuList>
            {ariaOptionsLables.map((item: any) => (
              <MenuItem
                key={item.value}
                onClick={e => {
                  e.stopPropagation();
                  handleOptionClick(item.value);
                }}>
                <b>{item.label}</b>
              </MenuItem>
            ))}
          </MenuList>
        </Popover>

        <Button
          id='submit'
          value='submit'
          aria-label='submit'
          disabled={disabled || !currentMeeting}
          sx={styles.ariaButton}
          endIcon={<SendIcon sx={{ fontSize: 20 }} />}
          onClick={handleButtonClick}>
          Ask RIA
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default AskRIAButtonAdcomm;
