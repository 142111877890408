const topNavigationStyle = {
  tabsStyles: {
    overflow: 'visible',
    '& .MuiTabs-fixed': {
      overflow: 'visible !important'
    },
    '& .MuiTab-root': {
      mr: '24px',
      p: 0,
      color: 'gray.800',
      minWidth: '68px'
    },
    '& .MuiTab-root:last-child': {
      mr: 0,
      ml: '24px',
      minWidth: 'inherit'
    },
    '& .MuiTabs-indicator': {
      bottom: -2,
      height: 4,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      backgroundColor: 'primary.700'
    },
    '& .MuiTab-labelIcon': {
      textTransform: 'none',
      fontSize: 13
    },
    '& .Mui-selected': {
      color: '#0A806D !important',
      '.MuiSvgIcon-root': {
        fill: '#0A806D !important'
      }
    },
    '.MuiTab-root > .MuiSvgIcon-root': {
      mr: '6px'
    },
    '.MuiSvgIcon-root': {
      fill: '#424242 !important',
      fontSize: '16px'
    }
  },
  menu: {
    '&.Mui-selected': {
      backgroundColor: `initial !important`,
      fontSize: `16px !important`,
      color: `#0A806D !important`,
      '& > div ': {
        fontWeight: `700 !important`,

        '& > span': {
          fontWeight: `700 !important`,
          color: `#0A806D !important`
        },

        '& > svg': {
          fill: `#0A806D !important`
        }
      }
    }
  },
  menuList: {
    'aria-labelledby': 'basic-button',
    sx: {
      '& .MuiListItemText-root': {
        ml: 0,
        padding: '6px 0'
      },
      '& .MuiListItemText-primary': {
        fontSize: 13
      },
      '& .MuiListItemText-secondary': {
        fontSize: 11
      },
      '& .MuiMenuItem-root': {
        margin: '4px 0',
        padding: '0 20px'
      },
      '& .MuiSvgIcon-root': {
        height: '18px',
        fill: '#424242'
      },
      padding: '12px 0'
    }
  },
  subscribeMenuIcon: { width: '14px', height: '14px' },
  menuDotBadge: {
    '& .MuiBadge-badge': {
      fontSize: '0.8rem !important',
      height: '16px',
      minWidth: '16px',
      fontWeight: 700,
      pl: '4px',
      pr: '4px'
    }
  }
};

export default topNavigationStyle;
