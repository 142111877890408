import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

// MUI
import { Box } from '@mui/material';
// Styles
import { debounce } from 'lodash';
import styles from '../styles/Details.styles';

// Custom Components
import VivproDatagrid from '../../Datagrid';

// store
import store from '../../../store/PMRSurveillance';
import actions from '../../../store/PMRSurveillance/actions';
import { getFileName } from '../../../helpers/getFileName';

// constants
import { PMRSURCOLUMN } from '../const';
import getDatagridFilterOption from '../../../utils/getDatagridFilterOption';
import { DATAGRID_OPTION_LIST } from '../../Datagrid/constants';

const PMRSurveillanceDetails = ({
  pagination,
  setPagination,
  sort,
  setSort,
  sortFields,
  getPMRSurData,
  isFilterUpdated,
  setIsFilterUpdated,
  getDatawithDataGridFilter,
  dataGridFilter
}: any) => {
  const [datagridMessage, setDataGridMessage] = useState('Loading...') as any;
  const { pmrState, pmrDispatch } = useContext(store);

  const day = new Date();
  const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
  const filename = {
    prefix: 'ria',
    module: 'pmrSurveillance',
    postfix: date,
    additional: '',
    extension: 'csv'
  };

  useEffect(() => {
    if (isFilterUpdated) {
      setIsFilterUpdated(false);
    }
  }, [isFilterUpdated]);

  const fetchData = useCallback(
    (pageModel: any, sortModel: any, gridFilters: any) => {
      getPMRSurData(
        pmrState.availableFilters,
        pageModel.pageSize,
        pageModel.page * pageModel.pageSize,
        sortModel,
        false,
        gridFilters
      );
    },
    [pmrState.availableFilters]
  );

  const handlePageChange = useCallback(
    (pageModel: any) => {
      if (!isFilterUpdated) {
        fetchData(pageModel, sortFields, dataGridFilter);
        setPagination(pageModel);
      }
    },
    [sortFields, pmrState.availableFilters, isFilterUpdated, dataGridFilter]
  );

  const handleSortModelChange = useCallback(
    (newSortingModel: any) => {
      const modifiedList: any = [];

      newSortingModel.forEach((item: any) => {
        modifiedList.push(item);
        if (item.field === 'supplement_type') {
          modifiedList.push({
            field: 'supplement_num',
            sort: item.sort
          });
        }
      });

      if (JSON.stringify(modifiedList) !== JSON.stringify(pmrState.sortFields)) {
        fetchData(pagination, modifiedList, dataGridFilter);
      }
      pmrDispatch({
        type: actions.SET_SORT,
        value: modifiedList
      });

      setSort(modifiedList);
    },
    [pagination, sortFields, pmrState.availableFilters, pmrDispatch, actions, dataGridFilter]
  );

  useEffect(() => {
    // If API has loaded the data but filtered or searched data is not present, show message
    if (pmrState.tableData.length > 0) {
      setDataGridMessage('No results found');
    }
    // if API data is empty
    if (pmrState.tableData.length === 0) {
      setDataGridMessage(`No Rows`);
    }
  }, [pmrState.tableData]);

  const renderableTableColumns = useMemo(() => {
    const renderableColumns = [...PMRSURCOLUMN];

    return getDatagridFilterOption(renderableColumns, DATAGRID_OPTION_LIST, pmrState);
  }, [pmrState]);

  const handleFilterChange = useCallback(
    debounce(newFilterModel => {
      if (newFilterModel?.items?.length > 0) {
        getDatawithDataGridFilter(
          pmrState.availableFilters,
          newFilterModel,
          sortFields,
          pagination.pageSize,
          pagination.page * pagination.pageSize
        );
      } else {
        fetchData(pagination, sortFields, {});
      }
    }, 300),
    [pmrState.availableFilters, sortFields]
  );

  useEffect(() => {
    return () => {
      handleFilterChange.cancel();
    };
  }, [handleFilterChange]);

  return (
    <Box sx={styles.root}>
      <VivproDatagrid
        rows={pmrState.tableData}
        columnsMapping={renderableTableColumns}
        rowId='hash'
        csvFileName={useMemo(() => getFileName(filename), [filename])}
        noRowMessage={datagridMessage}
        loading={pmrState.loading}
        rowCount={pmrState.totalRecord}
        isPagination
        pagination={pagination}
        setPagination={handlePageChange}
        sortingModel={sort}
        handleSortModelChange={handleSortModelChange}
        isServerSideExport
        isFilterModeServer
        allowFilterPanelWithLogicOperators
        handleFilterModelChange={handleFilterChange}
      />
    </Box>
  );
};

export default React.memo(PMRSurveillanceDetails);
