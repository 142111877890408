import { createSvgIcon } from '@mui/material';

const NdaIcon = createSvgIcon(
  <svg width='29' height='14' viewBox='0 0 29 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect y='0.5' width='29' height='13' rx='4' fill='#EA5252' />
    <path
      d='M5.2011 10.5V4.155H5.9211L9.8181 9.267H9.5931V4.155H10.4661V10.5H9.7551L5.8671 5.388H6.0741V10.5H5.2011ZM11.9511 10.5V4.155H14.1471C14.8611 4.155 15.4641 4.278 15.9561 4.524C16.4541 4.77 16.8291 5.13 17.0811 5.604C17.3391 6.072 17.4681 6.645 17.4681 7.323C17.4681 7.995 17.3391 8.568 17.0811 9.042C16.8291 9.516 16.4541 9.879 15.9561 10.131C15.4641 10.377 14.8611 10.5 14.1471 10.5H11.9511ZM12.8871 9.717H14.0931C14.9091 9.717 15.5151 9.519 15.9111 9.123C16.3131 8.727 16.5141 8.127 16.5141 7.323C16.5141 6.519 16.3131 5.922 15.9111 5.532C15.5151 5.136 14.9091 4.938 14.0931 4.938H12.8871V9.717ZM17.9562 10.5L20.7822 4.155H21.5832L24.4542 10.5H23.4912L22.7082 8.7L23.1042 8.952H19.2612L19.6842 8.7L18.9012 10.5H17.9562ZM21.1692 5.181L19.8012 8.412L19.5852 8.205H22.7802L22.6002 8.412L21.2052 5.181H21.1692Z'
      fill='white'
    />
  </svg>,
  'NdaIcon'
);

export default NdaIcon;
