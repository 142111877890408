import { GridColDef } from '@mui/x-data-grid-pro';
import renderCellExpand from '../../../components/Datagrid/DatagridPopover';

import DatagridPDFCell from '../components/DatagridPDFCell';

type CustomGridColDef = GridColDef & {
  capitalCasing?: Boolean;
};
// eslint-disable-next-line import/prefer-default-export
export const PIPS_EMA_COLUMNS: CustomGridColDef[] = [
  {
    field: 's3_paths',
    headerName: 'Documents',
    headerClassName: 'table-header',
    width: 100,
    filterable: false,
    capitalCasing: false,
    renderCell: DatagridPDFCell
  },
  {
    field: 'decision_number',
    headerName: 'Decision Number',
    headerClassName: 'table-header',
    width: 140,
    cellClassName: 'table-row'
  },
  {
    field: 'decision_type',
    headerName: 'Decision Type',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    capitalCasing: false,
    renderCell: renderCellExpand
  },
  {
    field: 'first_published',
    headerClassName: 'table-header',
    headerName: 'First Published Date',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'decision_date',
    headerClassName: 'table-header',
    headerName: 'Decision Date',
    width: 120,
    cellClassName: 'table-row'
  },
  {
    field: 'revision_date',
    headerClassName: 'table-header',
    headerName: 'Revision Date',
    width: 120,
    cellClassName: 'table-row'
  },
  {
    field: 'active_ingredient',
    headerClassName: 'table-header',
    headerName: 'Active Ingredient',
    width: 180,
    cellClassName: 'table-row',
    capitalCasing: false,
    renderCell: renderCellExpand
  },
  {
    field: 'formulations',
    headerClassName: 'table-header',
    headerName: 'Formulation ',
    width: 180,
    cellClassName: 'table-row',
    capitalCasing: false,
    renderCell: renderCellExpand
  },
  {
    field: 'indications_and_usage',
    headerClassName: 'table-header',
    headerName: 'Indications ',
    width: 240,
    cellClassName: 'table-row',
    capitalCasing: false,
    renderCell: renderCellExpand
  },
  {
    field: 'routes',
    headerClassName: 'table-header',
    headerName: 'Routes',
    width: 180,
    cellClassName: 'table-row',
    capitalCasing: false,
    renderCell: renderCellExpand
  },
  {
    field: 'therapeutic_area',
    headerClassName: 'table-header',
    headerName: 'Therapeutic Area',
    width: 180,
    cellClassName: 'table-row',
    capitalCasing: false,
    renderCell: renderCellExpand
  },
  {
    field: 'trade_name',
    headerClassName: 'table-header',
    headerName: 'Trade Name',
    width: 180,
    cellClassName: 'table-row',
    capitalCasing: false,
    renderCell: renderCellExpand
  }
];

export const SEARCH_IGNORE_FIELDS = [
  'hashed_active_ingredient',
  'file_names',
  's3_links',
  's3_paths',
  'url'
];

export const ELASTIC_SEARCH_ATTRIBUTES_LABELS: any = {
  active_ingredient: 'Active Ingredients',
  formulations: 'Formulations',
  routes: 'Routes',
  therapeutic_area: 'Therapeutic Area',
  trade_name: 'Trade Name',
  first_published: 'First Published',
  decision_date: 'Decision Date',
  revision_date: 'Revision Date',
  filter_keywords: 'Keywords'
};

export const DATE_FILTER_CATEGORIES: Array<string> = [
  'first_published',
  'decision_date',
  'revision_date'
];
