import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { capitalize } from 'lodash';

import { any } from 'prop-types';
import { Box, Typography } from '@mui/material';
import StyledBadge from './StyledBadge';
import { SearchListTreeView } from '../SearchListTreeView';
import makeStyles from './SearchList.styles';
import styles from '../SearchListTreeView/SynonymsFilter.styles';

let counter = 0;

const reverseComma = nodeName => {
  const term = nodeName.toLowerCase();
  const termSplitted = term.split(', ');
  if (termSplitted.length === 1) return term;

  termSplitted.reverse();
  return termSplitted.join(' ').trim();
};

const addIdToTree = data => {
  if (data.children && data.children.length > 0) {
    // eslint-disable-next-line
    data.id = counter.toString();
    for (let i = 0; i < data.children.length; i += 1) {
      counter += 1;
      // eslint-disable-next-line
      data.children[i].id = counter.toString();
      addIdToTree(data.children[i]);
    }
  }
  return data;
};

const mergeMeSHAndSuppl = (meshTerms = {}, supplTerms = {}) => {
  if (Array.isArray(meshTerms) && Array.isArray(supplTerms)) {
    return [...new Set([...meshTerms, ...supplTerms])];
  }
  const merged = { ...meshTerms, ...supplTerms };

  return merged;
};

const SynonymItem = ({ treeData, showCount }) => {
  const classes = makeStyles();
  const [synonyms, setSynonymns] = useState([]);

  const onlySearchTermUsedForSearching =
    treeData?.synonyms &&
    Object.keys(treeData?.synonyms || {}).length === 0 &&
    Object.keys(treeData?.suppl_concept_terms || {}).length === 0 &&
    Object.keys(treeData?.vivpro_suppl_terms || {}).length === 0;

  const treeNodes = useMemo(() => {
    let temp = { ...treeData };
    temp.name = 'Mesh Terms';
    temp.level = 0;
    temp.clickedId = '0';
    temp.children = temp.trees;
    temp = addIdToTree(temp);
    return [temp];
  }, [treeData, treeData.trees]);

  const renderVivproSupplTerm = useCallback(() => {
    return Object.keys(treeData.vivpro_suppl_terms || {}).map(key => {
      const mergeTerm = mergeMeSHAndSuppl(
        treeData.vivpro_suppl_terms?.[key].mesh_entry_terms,
        treeData.vivpro_suppl_terms?.[key].suppl_terms
      );
      return (
        <Box key={key}>
          <Typography variant='subtitle1' sx={{ fontSize: '14px', color: 'gray.darkText' }}>
            {capitalize(key)}
          </Typography>
          {!Array.isArray(mergeTerm)
            ? Object.keys(mergeTerm).map(term => {
                if (mergeTerm[term].count !== 0 && showCount !== false) {
                  return (
                    <Box sx={styles.node_count} key={term}>
                      {term}
                      <Box className={classes.count}>
                        <StyledBadge badgeContent={mergeTerm[term].count} max={100000} />
                      </Box>
                    </Box>
                  );
                }
                return (
                  <Box sx={styles.node} key={term}>
                    {term}
                  </Box>
                );
              })
            : mergeTerm.map(term => {
                return (
                  <Box sx={styles.node} key={term}>
                    {term}
                  </Box>
                );
              })}
        </Box>
      );
    });
  }, [showCount, treeData.vivpro_suppl_terms]);

  useEffect(() => {
    async function getData() {
      try {
        if (treeData.trees?.length === 0) {
          const syn = treeData.synonyms || {};
          let supplTerms = [];
          Object.keys(treeData.suppl_concept_terms || {}).forEach(
            // eslint-disable-next-line no-return-assign
            key => (supplTerms = { ...supplTerms, ...treeData.suppl_concept_terms[key] })
          );
          const allTerms = { ...supplTerms, ...syn };
          setSynonymns(allTerms);
        }
      } catch (e) {
        // eslint-disable-next-line
        console.error(e);
      }
    }
    getData();
  }, [treeData.trees, treeData.synonyms]);

  const onNodeSelection = node => {
    const name = node.name?.toLowerCase();
    const key = reverseComma(name);
    const supplTerms = treeData.suppl_concept_terms[key] || [];
    const terms = { ...node.synonyms, ...supplTerms };
    setSynonymns(terms);
  };

  const getSearchText = () => {
    if (Array.isArray(treeData?.searchText)) {
      return treeData?.searchText.map(item => {
        return (
          <Typography
            key={item}
            variant='subtitle1'
            sx={{ color: 'gray.darkText', fontWeight: 'bold' }}>
            {item}
          </Typography>
        );
      });
    }
    return (
      <Typography variant='subtitle1' sx={{ color: 'gray.darkText', fontWeight: 'bold' }}>
        {treeData?.searchText}
      </Typography>
    );
  };
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {onlySearchTermUsedForSearching ? (
        <Box className={classes.treeView}>
          Search Term
          <Box>{getSearchText()}</Box>
        </Box>
      ) : (
        <>
          <Box paddingBottom={1}>
            {
              // If there are no nodes, dont show the treeview
              <Box className={classes.treeView}>
                <Box paddingLeft={3}>
                  {treeNodes[0]?.children?.length === 0 && (
                    <Typography
                      variant='subtitle1'
                      sx={{ fontSize: '16px', color: 'gray.darkText' }}>
                      - Not Applicable
                    </Typography>
                  )}
                </Box>
                {treeNodes[0]?.children?.length > 0 && (
                  <SearchListTreeView
                    showCount={showCount}
                    nodes={treeNodes}
                    onNodeSelect={onNodeSelection}
                  />
                )}
              </Box>
            }
            {
              // If there are no nodes but synonyms present, then show synonyms
              Object.keys(synonyms)?.length > 0 ? (
                <Box paddingLeft={3}>
                  <Typography variant='subtitle1' sx={{ fontSize: '16px', color: 'gray.darkText' }}>
                    MeSH Synonyms
                  </Typography>
                  <Box
                    className={classes.synonyms}
                    style={{ maxHeight: treeNodes[0]?.children?.length === 0 ? '80vh' : '19vh' }}>
                    {Object.keys(synonyms).map(synonym => {
                      if (synonyms[synonym].count !== 0 && showCount !== false) {
                        return (
                          <Box sx={styles.node_count} key={synonym}>
                            {synonym}
                            <Box className={classes.count}>
                              <StyledBadge badgeContent={synonyms[synonym].count} max={100000} />
                            </Box>
                          </Box>
                        );
                      }
                      return (
                        <Box sx={styles.node_count} key={synonym}>
                          {synonym}
                        </Box>
                      );
                    })}
                  </Box>
                  <hr />
                </Box>
              ) : (
                <hr />
              )
            }
          </Box>
          {
            // Displaying Vivpro Supplemental Terms
            Object.keys(treeData.vivpro_suppl_terms || {})?.length > 0 && (
              <Box paddingLeft={3}>
                <Typography variant='subtitle1' sx={{ fontSize: '16px', color: 'gray.darkText' }}>
                  Additional Terms (FDA, EMA)
                </Typography>
                {renderVivproSupplTerm()}
              </Box>
            )
          }
        </>
      )}
    </>
  );
};

SynonymItem.propTypes = {
  // eslint-disable-next-line
  treeData: any,
  // eslint-disable-next-line
  showCount: any
};

export default React.memo(SynonymItem);
