const styles = {
  wrapper: {
    ml: 1,
    mb: 3,
    borderRadius: 1,
    border: '1px solid',
    backgroundColor: 'gray.50',
    p: 1,
    cursor: 'pointer'
  },
  yearText: {
    color: 'gray.950',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: ' 120%',
    whiteSpace: 'nowrap'
  },
  sectionText: {
    marginRight: 0.75,
    overflow: 'hidden',
    color: 'gray.950',
    textOverflow: 'ellipsis',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '120%',
    whiteSpace: 'nowrap',
    maxWidth: 200
  },
  documentIcon: { fontSize: 14, mr: 0.75 },
  pdcoNumberText: {
    overflow: 'hidden',
    color: 'gray.650',
    textOverflow: 'ellipsis',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '120%',
    whiteSpace: 'nowrap'
  },
  accordion: {
    display: 'flex',
    flexDirection: 'column-reverse',
    boxShadow: 'none',
    background: 'none',
    border: 'none',
    '&::before': {
      display: 'none'
    },
    '& .Mui-expanded': {
      marginTop: '0px !important'
    }
  },
  hideIcon: {
    width: '16px',
    height: '12px',
    marginRight: '3px'
  },
  accordionSummary: {
    width: 'fit-content',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'row-reverse',
    marginBottom: 0,
    mx: 'auto',
    pt: 0.5,
    pl: 2,
    minHeight: 'unset',
    '&.Mui-expanded': {
      mx: 'auto',

      minHeight: 'unset'
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      '&.Mui-expanded': {
        margin: 0
      }
    }
  },
  hideText: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '120%'
  },
  accordionDetails: {
    boxShadow: 'none',
    border: 'none',
    padding: 0,
    '& .MuiAccordionDetails-root': {
      padding: 0,
      marginTop: '12px'
    }
  },
  ulStyle: { margin: 0, paddingLeft: 3 },
  liStyle: { marginBottom: '8px' },
  accordionSummaryText: {
    color: 'primary.darkVariant1',
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%'
  },
  daysContainer: {
    borderRadius: 1,
    backgroundColor: 'gray.200',
    px: 0.5,
    height: 19,
    mr: 0.75
  }
};

export default styles;
