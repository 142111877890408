import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import { CardMetadata } from '../../pages/SearchResults/types';
import {
  LABEL_COLOR,
  sourceFlagMapping,
  sourceFlagMappingTooltip
} from '../../pages/SearchResults/constants';
import styles from './cardsStyles';

const Cards = ({ data, handleClick }: any) => {
  const { title, identifier, metadata, source, labels, groupTitle } = data;
  const filteredMetadata = metadata.filter(
    (item: any) => item.key === 'Formulation' || item.key === 'Route'
  );
  const FlagComponent = sourceFlagMapping[source];
  const flagTooltipTitle = sourceFlagMappingTooltip[source];

  return (
    <Box>
      <Grid item xs={12} md={6} lg={3} xl={3} key={data.identifier}>
        <Box sx={styles.root} onClick={() => handleClick(data)}>
          <Box display='flex' flexDirection='column' width='100%' justifyContent='center'>
            <Tooltip title={groupTitle}>
              <Typography color='gray.700' fontWeight={400} fontSize={12} sx={styles.heading}>
                {groupTitle}
              </Typography>
            </Tooltip>
            <Box display='flex' flexDirection='row' width='100%' alignItems='center'>
              <Tooltip title={`${title} - ${identifier}`}>
                <Typography color='gray.800' fontWeight={700} fontSize={16} sx={styles.heading}>
                  {title}
                </Typography>
              </Tooltip>
              <Tooltip title={flagTooltipTitle}>
                <FlagComponent sx={{ ml: 1, width: '18px', height: '13px' }} />
              </Tooltip>
              {labels &&
                labels.map((label: string) => {
                  const labelKey = label.toLowerCase();
                  const labelColor = LABEL_COLOR[labelKey];
                  return labelColor ? (
                    <Chip
                      label={label}
                      key={label}
                      sx={{ ...styles.chip, ...{ bgcolor: labelColor } }}
                    />
                  ) : null;
                })}
            </Box>
            {/* <Box
              display='flex'
              flexDirection='row'
              width='100%'
              alignItems='center'
              sx={{ display: 'none' }}>
              <FlagComponent sx={{ fontSize: 20 }} />
              <Typography pl={1} pr={1} fontWeight={500} sx={styles.heading}>
                {identifier}
              </Typography>
            </Box> */}
            <Box display='flex' flexDirection='row' width='100%' alignItems='center'>
              {filteredMetadata.map((dataVal: CardMetadata, index: number) => (
                <>
                  <Tooltip title={`${dataVal.key}: ${dataVal.value}`}>
                    <Typography
                      maxWidth='50%'
                      maxHeight={20}
                      sx={styles.heading}
                      fontWeight={500}
                      color='gray.700'
                      fontSize={12}>
                      {dataVal.value}
                    </Typography>
                  </Tooltip>
                  {index < filteredMetadata.length - 1 && (
                    <Typography pl={1} pr={1} color='gray.700' fontSize={10}>
                      &#x2022;
                    </Typography>
                  )}
                </>
              ))}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default React.memo(Cards);
