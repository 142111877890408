export const LABEL_NAMES: any = {
  indication: 'Indication',
  biomarker: 'Biomarker',
  biomarkerDetails: 'Biomarker Details',
  sampleType: 'Sample Type',
  inCombinationWithApplications: 'In combination with application(s)',
  inCombinationWithDevices: 'In combination with device(s)',
  groupOncologyProductsIndication: 'Group Oncology Products Indication',
  manufacturer: 'Manufacturer'
};

export const APPLICATIONS_TO_IGNORE = ['K124065', 'H140006', 'H230005', 'H140005', 'BR220737'];
