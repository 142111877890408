import { ELASTIC_SEARCH_ATTRIBUTES_LABELS } from './advanceSearchConstants';
import { CATEGORY_INPUT_FORMATTING } from '../const';

/**
 * function to generate advance search raw query into a conjunctive normal form
 * @param {object} rawQuery

 * @returns {string} result
 */
const getCnfQuery = (rawQuery, source = null) => {
  let result = [];
  let normalizedQuery = [];
  // normalize inputs to form raw query
  const dict = {};
  Object.values(rawQuery).forEach(item => {
    let { searchText = '', category, isNot } = item;
    // check if not operator is selected then add a new key for not operator
    if (!Array.isArray(searchText)) {
      searchText = searchText.replace('AND', 'and');

      searchText = searchText.replace('OR', 'or');

      searchText = searchText.replace('NOT', 'not');
    }

    if (isNot) {
      if (Object.keys(dict).includes('not')) {
        dict.not.push({ category, searchText });
      } else dict.not = [{ category, searchText }];
    } else if (Object.keys(dict).includes(category)) {
      dict[category].push(searchText);
    } else dict[category] = [searchText];
  });
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(dict)) {
    let temp = [];
    let normaliedTemp = [];
    if (key === 'not') {
      value.forEach(term => {
        // If formatting required while converting to CNF Query
        const searchText = CATEGORY_INPUT_FORMATTING[term.category]
          ? CATEGORY_INPUT_FORMATTING[term.category](term.searchText)
          : term.searchText;
        const literal = `~${term.category}:${searchText}`;
        temp.push(literal);

        if (source) {
          const normalizedLiteral = `NOT ${
            ELASTIC_SEARCH_ATTRIBUTES_LABELS[source][term.category]
          }:${term.searchText}`;
          normaliedTemp.push(normalizedLiteral);
        }
      });
    } else {
      value.forEach(term => {
        // If formatting required while converting to CNF Query
        const searchText = CATEGORY_INPUT_FORMATTING[key]
          ? CATEGORY_INPUT_FORMATTING[key](term)
          : term;
        const literal = `${key}:${searchText}`;
        temp.push(literal);
        if (source) {
          const normalizedLiteral = `${ELASTIC_SEARCH_ATTRIBUTES_LABELS[source][key]}: ${term}`;
          normaliedTemp.push(normalizedLiteral);
        }
      });
    }

    temp = `(${temp.join(' OR ')})`;
    result.push(temp);
    if (source !== null) {
      normaliedTemp = `(${normaliedTemp.join(' OR ')})`;
      normalizedQuery.push(normaliedTemp);
    }
  }

  result = result.join(' AND ');
  if (source) {
    normalizedQuery = normalizedQuery.join(' AND ');
  }

  if (source) {
    return { result, normalizedQuery };
  }

  return result;
};

export default getCnfQuery;
