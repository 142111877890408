import {
  ActivityIcon,
  StarOutlineIcon,
  MyReportsIcon,
  SubscribeIcon
} from '../../assets/svgs/Icons';
import ServiceRequestIcon from '../../assets/svgs/UserProfile/ServiceRequestIcon';
import { Header } from './interface';

const HEADERS: Record<string, Header[]> = {
  activity: [
    {
      key: 'searches',
      label: 'Searches'
    },
    {
      key: 'journey',
      label: 'Journeys'
    }
  ],
  saved: [
    {
      key: 'searches',
      label: 'Searches'
    },
    {
      key: 'documents',
      label: 'Documents'
    },
    {
      key: 'applications',
      label: 'Applications'
    }
  ],
  reports: [
    {
      key: 'report',
      label: 'My Reports'
    }
  ],
  project: [
    {
      key: 'searches',
      label: 'Searches'
    },
    {
      key: 'documents',
      label: 'Documents'
    },
    {
      key: 'applications',
      label: 'Applications'
    }
  ]
};

const REPORT_TITLE_MAX_LENGTH = 20;
const REPORT_DESCRIPTION_MAX_LENGTH = 60;
const PROJECT_TITLE_MAX_LENGTH = 100;
const PROJECT_DESCRIPTION_MAX_LENGTH = 500;

const ENBALE_APPLICATION_BASED_ON_SOURCE = ['usnlm', 'euctr'];

const USER_PROFILE_NAV_BAR = [
  { text: 'Activity', icon: ActivityIcon, path: 'activity' },
  { text: 'Saved Items', icon: StarOutlineIcon, path: 'saved' },
  { text: 'My Reports', icon: MyReportsIcon, path: 'reports' },
  { text: 'My Subscriptions', icon: SubscribeIcon, path: 'subscriptions' }
];

const SERVICE_REQUESTS_NAV_BAR = {
  text: 'Service Requests',
  Icon: ServiceRequestIcon,
  path: 'serviceRequests'
};

// eslint-disable-next-line import/prefer-default-export
export {
  HEADERS,
  PROJECT_TITLE_MAX_LENGTH,
  PROJECT_DESCRIPTION_MAX_LENGTH,
  REPORT_TITLE_MAX_LENGTH,
  REPORT_DESCRIPTION_MAX_LENGTH,
  ENBALE_APPLICATION_BASED_ON_SOURCE,
  USER_PROFILE_NAV_BAR,
  SERVICE_REQUESTS_NAV_BAR
};
