import { createSvgIcon } from '@mui/material/utils';

const DropDown = createSvgIcon(
  <svg viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.5861 5.2915L7.84003 10.0376C7.74868 10.1253 7.62698 10.1742 7.50037 10.1742C7.37376 10.1742 7.25205 10.1253 7.16071 10.0376L2.41748 5.29391L1.73816 5.97323L6.48139 10.7169C6.75612 10.9788 7.12108 11.1248 7.50061 11.1248C7.88013 11.1248 8.24509 10.9788 8.51983 10.7169L13.2655 5.97082L12.5861 5.2915Z'
      fill='#424242'
    />
  </svg>,
  'DropDown'
);
export default DropDown;
