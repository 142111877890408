import { GridColDef } from '@mui/x-data-grid-pro';
import renderCellExpand from '../Datagrid/DatagridPopover';
import DatagridTooltip from '../Datagrid/DatagridTooltip';
import DatagridColorNumber from './components/DatagridColorNumber/DatagridColorNumber';
import DatagridChipsPopup from '../Datagrid/DatagridChipsPopup';
import { Filter } from '../Filters';
import { PIECHARTCOLOR } from '../CustomComponents/Graphs/constant';
import { FILTER_TYPE } from '../NewFilters/constants';
import DatagridReg360 from '../PMRSurveillance/components/DatagridReg360';

type CustomGridColDef = GridColDef & {
  capitalCasing?: Boolean;
};

export const PMRIMPACT_COLUMNS: CustomGridColDef[] = [
  {
    field: 'pmr_issued_label',
    headerName: '',
    width: 50,
    headerClassName: 'table-header',
    cellClassName: 'table-row',
    disableExport: true,
    valueGetter: (params: { row: any }) => {
      return params.row;
    }
  },
  {
    field: 'appl_type',
    headerName: 'Type',
    width: 100,
    headerClassName: 'table-header',
    cellClassName: 'table-row',
    renderCell: (params: any) => DatagridColorNumber(params.row.appl_type)
  },
  {
    field: 'appl_num',
    headerName: 'Application Number',
    width: 180,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridReg360
  },
  {
    field: 'supplement_details',
    headerName: 'Supplement Issued',
    width: 180,
    headerClassName: 'table-header',
    cellClassName: 'table-row'
  },
  {
    field: 'supplement_fulfilled_details',
    headerName: 'Supplement FulFilled',
    width: 180,
    headerClassName: 'table-header',
    cellClassName: 'table-row'
  },
  {
    field: 'approval_date',
    headerName: 'Approval Date',
    width: 120,
    headerClassName: 'table-header',
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'active_ingredient',
    headerName: 'Active Ingredients',
    width: 240,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    capitalCasing: true,
    renderCell: DatagridTooltip
  },
  {
    field: 'trade_name',
    headerName: 'Trade Name',
    width: 150,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridTooltip
  },
  {
    field: 'cmt_number',
    headerName: 'PMR/PMC Number',
    width: 180,
    headerClassName: 'table-header',
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'cmt_desc',
    headerName: 'Description',
    width: 250,
    headerClassName: 'table-header',
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'time_to_fulfillment',
    headerName: 'Time(days) to fulfillment',
    width: 240,
    headerClassName: 'table-header',
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'subpart_description',
    headerName: 'Subpart',
    width: 180,
    headerClassName: 'table-header',
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'sponsor',
    headerName: 'Sponsor',
    width: 180,
    headerClassName: 'table-header',
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'category',
    headerName: 'PMR/PMC Category',
    width: 240,
    headerClassName: 'table-header',
    cellClassName: 'table-row',
    renderCell: DatagridChipsPopup,
    valueGetter: ({ row }) => `${row.category.replaceAll(',', '; ')}`
  },
  {
    field: 'pmr_status',
    headerName: 'Status',
    width: 180,
    headerClassName: 'table-header',
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  }
];

export const COLOR_SCHEME_NUMBER: { [key: string]: string } = {
  nda: 'redNumber',
  bla: 'purpleNumber'
};
export interface PMRImpactFilters extends Filter {
  apiRequestKey: string;
  apiResponseKey?: string;
}

export const PMR_IMPACT_FILTERS = [
  {
    id: 1,
    label: 'Application Type',
    value: 'appl_type',
    elasticSearchType: 'appl_type',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Supplement Type',
    value: 'supplement_type',
    elasticSearchType: 'supplement_type',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Active Ingredients',
    value: 'active_ingredient',
    elasticSearchType: 'active_ingredient',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Search Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Trade Name',
    value: 'trade_name',
    elasticSearchType: 'trade_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'PMR/PMC Category',
    value: 'category',
    elasticSearchType: 'category',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 7,
    label: 'Subpart',
    value: 'subpart_description',
    elasticSearchType: 'subpart_description',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 8,
    label: 'Sponsor',
    value: 'sponsor',
    elasticSearchType: 'sponsor',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 9,
    label: 'Approval Date',
    value: 'approval_date',
    elasticSearchType: 'approval_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  }
];

export const barChartStyles = {
  colors: ['#B1BBE4'],
  padding: 0.4,
  gridXValues: 5,
  borderRadius: 2,
  axisBottom: {
    tickRotation: 80
  },
  axisLeft: {
    tickRotation: 0
  }
};

export const pieChartStyles = {
  enableArcLinkLabels: false,
  colors: PIECHARTCOLOR,
  margin: { top: 20, right: 20, bottom: 20, left: 20 },
  pieLabel: true,
  pieLegend: false,
  innerRadius: 0.7,
  enableLabels: 'both'
};

export const PMR_IMPACT_QUERIES = [
  {
    id: 'pmrs_issued_by_year',
    title: {
      title: 'PMRs Issued by Year',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['issued_year'], agg_function: 'count', sort_on_axis: 'x' },
    graphStyle: barChartStyles
  },
  {
    id: 'fulfilled_year',
    title: {
      title: 'PMRs Fulfilled by Year',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['fulfilled_year'], agg_function: 'count', sort_on_axis: 'x' },
    graphStyle: barChartStyles
  }
];

export const PAGINATION_DEFAULT_OPTIONS = {
  limit: 20,
  offset: 0
};

export const EXPORT_TYPE = {
  csv: 'csv',
  xlsx: 'xlsx'
};

export const PMR_IMPACT_COLUMN_NAME_MAPPING = {
  appl_type: 'Application Type',
  appl_num: 'Application Number',
  supplement_type: 'Supplement Issued',
  supplement_type_fulfilled: 'Supplement Fullfilled',
  approval_date: 'Approval Date',
  active_ingredient: 'Active Integredient',
  trade_name: 'Trade Name',
  cmt_number: 'CMT Number',
  cmt_desc: 'Description',
  time_to_fulfillment: 'Time(days) to fulfillment',
  sponsor: 'Sponsor',
  subpart_description: 'Subpart',
  category: 'PMR/PMC Category',
  pmr_status: 'Status'
};
