import { createSvgIcon } from '@mui/material';

const MyReportsIcon = createSvgIcon(
  <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_14267_509)'>
      <path
        d='M12.0001 3.89067L8.60944 0.5H3.33344C2.803 0.5 2.29429 0.710714 1.91922 1.08579C1.54415 1.46086 1.33344 1.96957 1.33344 2.5V13.8333H12.0001V3.89067ZM2.66677 12.5V2.5C2.66677 2.32319 2.73701 2.15362 2.86203 2.0286C2.98705 1.90357 3.15662 1.83333 3.33344 1.83333H8.0001V4.5H10.6668V12.5H2.66677ZM14.6668 6.5V16.5H4.66677V15.1667H13.3334V5.16667L14.6668 6.5Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_14267_509'>
        <rect width='16' height='16' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>,
  'MyReports'
);

export default MyReportsIcon;
