import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import { Menu } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import isEmpty from 'lodash/isEmpty';

import styles from '../styles/CDPMain';
import MeetingList from './Meetings/AssociatedMeetingsMenu';
import MeetingDetails from './Meetings/MeetingDetails';

interface InfoWindowProps {
  handleViewDocsClose: () => void;
  mappedEntityData: { [key: string]: string | number };
  handleViewDocsClick: () => void;
  meetingData: any;
  selectedMeeting: any;
  setSelectedMeeting: (meeting: any) => void;
  setHighlightedMeeting: (meeting: any) => void;
}

const InfoWindow: React.FC<InfoWindowProps> = ({
  handleViewDocsClose,
  mappedEntityData,
  handleViewDocsClick,
  meetingData,
  selectedMeeting,
  setSelectedMeeting,
  setHighlightedMeeting
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const viewType = 'NCT ID' in mappedEntityData ? 'CT' : 'FDA';

  // Handles opening the MeetingList menu
  const handleMeetingListClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  // Closes the MeetingList menu
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setHighlightedMeeting({});
    setSelectedMeeting({});
  };

  // Closes the MeetingDetails component
  const handleViewMeetingDetailsClose = () => {
    setHighlightedMeeting({});
    setSelectedMeeting({});
  };

  // Render Meeting Details component if there is a selected meeting and meeting data is available
  const RenderMeetingDetails = () => {
    if (!selectedMeeting || !meetingData) return null;
    const { month, year, index }: any = selectedMeeting;
    const meetings = meetingData?.[year]?.[month];
    if (!meetings) {
      setSelectedMeeting(null);
      setHighlightedMeeting(null);
      return null;
    }

    return (
      <MeetingDetails
        month={month}
        year={year}
        meetings={meetings}
        selectedIndex={index}
        sumbmission={mappedEntityData['Type']}
        onBack={handleViewMeetingDetailsClose} // Back to MeetingList
        setHighlightedMeeting={setHighlightedMeeting}
      />
    );
  };

  return (
    <>
      {!isEmpty(selectedMeeting) ? (
        <Box sx={styles.meetingDetails}>{<RenderMeetingDetails />}</Box>
      ) : (
        <Box sx={styles.infoWindow}>
          <Typography fontSize={14} color='primary.900' fontWeight={700}>
            {viewType === 'CT' ? 'Clinical Trial' : 'Submission'}
          </Typography>
          <CloseIcon onClick={handleViewDocsClose} sx={styles.closeBtn} />
          <Box height='100%'>
            {/* Display Submission Info */}
            <Box
              minHeight='100px'
              maxHeight={236}
              marginTop={1.5}
              marginBottom={1.5}
              overflow='auto'>
              {Object.entries(mappedEntityData).map(([key, value]) => (
                <Box key={key}>
                  <Typography sx={styles.infoWindowText} fontWeight={700} component='span'>
                    {key}
                  </Typography>
                  :{' '}
                  <Typography sx={styles.infoWindowText} fontWeight={400} component='span'>
                    {value}
                  </Typography>
                </Box>
              ))}
            </Box>

            {/* Associated Meetings Button and Menu */}
            {!isEmpty(meetingData) && viewType !== 'CT' && (
              <Box>
                <Divider sx={{ color: 'gray.300' }} />

                <Box display='flex' justifyContent='left' width='100%' pt={'6px'} pb={'6px'}>
                  <Button
                    id='associated-meeting-button'
                    onClick={handleMeetingListClick}
                    sx={styles.viewDocumentButton}
                    endIcon={<ArrowRightIcon sx={{ color: 'gray.700' }} />}>
                    Associated Meeting(s)
                  </Button>
                </Box>

                <Menu
                  id='associated-meeting-menu'
                  anchorEl={menuAnchorEl}
                  open={isMenuOpen}
                  onClose={handleMenuClose}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  PaperProps={{
                    sx: styles.meetingListContainer
                  }}
                  MenuListProps={{ 'aria-labelledby': 'associated-meeting-button' }}>
                  <MeetingList
                    meetingData={meetingData}
                    setSelectedMeeting={setSelectedMeeting}
                    setMenuAnchorEl={setMenuAnchorEl}
                    setHighlightedMeeting={setHighlightedMeeting}
                  />
                </Menu>
              </Box>
            )}

            <Divider sx={{ color: 'gray.300' }} />
            <Box display='flex' justifyContent='left' width='100%' pt={'6px'} pb={0}>
              <Button
                onClick={handleViewDocsClick}
                sx={styles.viewDocumentButton}
                endIcon={<ArrowRightIcon sx={{ color: 'gray.700' }} />}>
                View Documents
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default React.memo(InfoWindow);
