const Actions = {
  SET_LOADING: 'SET_LOADING',
  SET_PRODUCT_DETAILS: 'SET_PRODUCT_DETAILS',
  SET_STATE_DETAILS: 'SET_STATE_DETAILS',
  SET_PATENTS: 'SET_PATENTS',
  SET_EXCLUSIVITY: 'SET_EXCLUSIVITY',
  SET_PRESCRIBING_INFO: 'SET_PRESCRIBING_INFO',
  SET_SEARCH_DETAILS: 'SET_SEARCH_DETAILS',
  SET_DB: 'SET_DB',
  SET_APPLICATION_DETAILS: 'SET_APPLICATION_DETAILS',
  SET_APPROVAL_HISTORY: 'SET_APPROVAL_HISTORY',
  SET_ADVERSE_EVENTS: 'SET_ADVERSE_EVENTS',
  SET_COMMISSION_PROCEDURES: 'SET_COMMISSION_PROCEDURES',
  SET_APPROVAL_HISTORY_APPLIED_FILTERS: 'SET_APPROVAL_HISTORY_APPLIED_FILTERS',
  SET_APPROVAL_HISTORY_FILTERS: 'SET_APPROVAL_HISTORY_FILTERS',
  SET_APPROVAL_HISTORY_FILTERS_APPLIED_FLAG: 'SET_APPROVAL_HISTORY_FILTERS_APPLIED_FLAG',
  SET_PMRC_FILTERS: 'SET_PMRC_FILTERS',
  SET_PMRC_FILTERS_APPLIED_FLAG: 'SET_PMRC_FILTERS_APPLIED_FLAG',
  SET_PostMarketingData: 'SET_PostMarketingData',
  SET_RLD_INFO: 'SET_RLD_INFO',
  SET_PROCEDURAL_STEPS: 'SET_PROCEDURAL_STEPS',
  SET_GENERICS: 'SET_GENERICS',
  SET_TRIGGER_ADDTOREPORT: 'SET_TRIGGER_ADDTOREPORT',
  SET_TRIGGER_CSVDOWNLOAD: 'SET_TRIGGER_CSVDOWNLOAD',
  SET_TRIGGER_OPENFILTERS: 'SET_TRIGGER_OPENFILTERS',
  SET_LOADING_GENERICS: 'SET_LOADING_GENERICS',
  SET_LOADING_PROCEDURAL_STEPS: 'SET_LOADING_PROCEDURAL_STEPS',
  SET_LOADING_PRODUCT_DETAILS: 'SET_LOADING_PRODUCT_DETAILS',
  SET_LOADING_STATE_DETAILS: 'SET_LOADING_STATE_DETAILS',
  SET_LOADING_PATENTS: 'SET_LOADING_PATENTS',
  SET_LOADING_EXCLUSIVITY: 'SET_LOADING_EXCLUSIVITY',
  SET_IS_FAVORITE: 'SET_IS_FAVORITE',
  SET_IS_SUBSCRIBED: 'SET_IS_SUBSCRIBED',
  SET_IN_PROJECTS: 'SET_IN_PROJECTS',
  SET_REMS: 'SET_REMS',
  SET_SORTING_APPROVAL_HISTORY: 'SET_SORTING_APPROVAL_HISTORY',
  SET_SUMMARY_DETAILS: 'SET_SUMMARY_DETAILS',
  SET_RESOURCES_DETAILS: 'SET_RESOURCES_DETAILS',
  SET_RESOURCES_DETAILS_LOADING: 'SET_RESOURCES_DETAILS_LOADING',
  SET_COMPANION_DIAGNOSTIC_DEVICES: 'SET_COMPANION_DIAGNOSTIC_DEVICES',
  SET_ORPHAN_DESIGNATIONS: 'SET_ORPHAN_DESIGNATIONS',
  SET_SUBMISSION_DOCUMENTS: 'SET_SUBMISSION_DOCUMENTS',
  SET_COMMISSION_PROCEDURE_SUBMISSION_DOCUMENTS: 'SET_COMMISSION_PROCEDURE_SUBMISSION_DOCUMENTS',
  SET_REMS_DOCUMENTS: 'SET_REMS_DOCUMENTS',
  SET_RESOURCES_DETAILS_DOCUMENTS: 'SET_RESOURCES_DETAILS_DOCUMENTS',
  SET_SIDE_EFFECT_DOCUMENTS: 'SET_SIDE_EFFECT_DOCUMENTS'
};

export default Actions;
