import React, { useContext, useState, useMemo, useCallback } from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import debounce from 'lodash/debounce';

import { searchStyle } from '../styles/Search.styles';
// store
import EMAGuidanceStore from '../../../store/EMAGuidance/index';
import actions from '../../../store/GuidanceDocuments/actions';
import ResetButton from '../../../Images/undo-arrow.svg';

const Search = () => {
  const { EMAGuidanceDispatch } = useContext(EMAGuidanceStore);

  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleSearchSubmit = () => {
    EMAGuidanceDispatch({
      type: actions.SET_SEARCH,
      value: {
        term: searchTerm
      }
    });
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    EMAGuidanceDispatch({
      type: actions.SET_SEARCH,
      value: {
        term: ''
      }
    });
  };

  const keyPress = (e: any) => {
    if (e.keyCode === 13) {
      handleSearchSubmit();
    }
  };

  const updateSearch = useCallback((value: string) => {
    EMAGuidanceDispatch({
      type: actions.SET_SEARCH,
      value: {
        term: value
      }
    });
  }, []);

  const debouncedChangeHandler = useMemo(() => debounce(updateSearch, 200), [updateSearch]);

  const handleInputTextChange = useCallback((event: any) => {
    setSearchTerm(event.target.value);
    debouncedChangeHandler(event.target.value);
  }, []);

  return (
    <Box display='flex'>
      <TextField
        id='ema-guidance-search'
        onChange={handleInputTextChange}
        onKeyDown={keyPress}
        value={searchTerm}
        placeholder='Search Keyword'
        sx={searchStyle.searchInput}
      />
      <Button onClick={handleClearSearch} sx={searchStyle.buttonReset}>
        <img src={ResetButton} alt='reset' width={12} style={searchStyle.resetIcon} /> Reset
      </Button>
    </Box>
  );
};

export default React.memo(Search);
