import palette from '../../../themev5/palette';

const styles = {
  dialogRoot: {
    '& .MuiBackdrop-root': {
      backgroundColor: '#38383899'
    },
    '& .MuiPaper-root': {
      elevation: 1,
      borderRadius: 2,
      padding: '24px 24px 48px 24px',
      width: '450px',
      m: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  addNewRowButton: {
    mb: '48px',
    p: 0,
    '& svg': {
      fontSize: '38.41px',
      fill: palette.primary['450']
    },
    '&:disabled': {
      '& svg': {
        fill: palette.gray['550']
      }
    }
  },
  advanceSearchListItem: {
    flex: 1,
    border: '1px solid',
    borderColor: palette.gray['300'],
    '&#highlighted': {
      borderColor: palette.primary['600']
    },
    borderRadius: '8px',
    p: '4px 12px',
    height: '40px',
    width: '575px',
    mr: '8px'
  },
  advanceSearchSelect: {
    fontSize: '13px',
    p: 0,
    border: 'none',
    '& > div': {
      p: '0 18px 0 0 !important',
      zIndex: 2,
      width: '128px'
    },
    '& > svg': {
      p: 0,
      position: 'absolute',
      right: 0,
      fontSize: '14px',
      cursor: 'pointer',
      zIndex: 1
    },
    '& > input': {
      border: 'none',
      p: 0
    },
    fieldset: {
      p: 0,
      border: 'none',
      width: '128px'
    }
  },
  horizontalDivider: {
    height: '32px',
    my: 0,
    mx: '16px',
    color: 'gray.300'
  },
  submitButton: {
    height: '30px',
    padding: '15px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '16px',
    backgroundColor: 'primary.650',
    color: 'white.main',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 700,
    '&:hover': {
      backgroundColor: 'white.main',
      border: 1,
      borderColor: 'primary.main',
      borderStyle: 'solid',
      color: 'primary.600'
    },
    '&:disabled': {
      backgroundColor: 'gray.550',
      color: 'gray.background'
    }
  },
  downloadButton: {
    backgroundColor: 'primary.700',
    '&:hover': {
      backgroundColor: 'primary.main'
    },
    color: 'white.main',
    transition: 'width 3s ease-in',
    textTransform: 'none',
    width: '140px',
    padding: '0 12px',
    height: '30px',
    fontSize: '14px',
    '&:not(:disabled)': {
      animation: 'pulse 1s cubic-bezier(0.4, 0, 0.6, 1) 4',
      '@keyframes pulse': {
        '0%': {
          transform: 'scale(1)',
          boxShadow: '0 0 0 0 rgba(51, 177, 135, 0.6)'
        },
        '70%': {
          transform: 'scale(1.05)',
          boxShadow: '0 0 0 10px rgba(51, 177, 135, 0)'
        },
        '100%': {
          transform: 'scale(1)',
          boxShadow: '0 0 0 0 rgba(51, 177, 135, 0)'
        }
      }
    }
  },
  advanceSearchRemoveListItem: {
    p: 0,
    '& svg': {
      fontSize: '16px !important',
      color: 'red.100'
    },
    '&:disabled': {
      '& svg': {
        color: 'gray !important'
      }
    }
  },
  dividerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  divider: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'gray.400',
    borderRadius: '2px',
    mb: '16px',
    width: '64px'
  },
  badge: {
    '& .MuiBadge-badge': {
      height: '14px',
      width: '14px',
      minWidth: '14px',
      fontSize: '10px',
      mt: '2px'
    }
  },
  buttonText: {
    marginLeft: '10px',
    fontSize: '14px',
    fontWeight: 700
  },
  exitButton: {
    borderRadius: '16px',
    textTransform: 'capitalize',
    ml: 1,
    p: 0,
    height: '28px',
    width: '28px',
    minWidth: 'auto',
    backgroundColor: 'red.main',
    color: 'white.main',
    fontSize: '12px',
    fontWeight: 700,
    border: 'unset',
    '&:hover': {
      backgroundColor: 'red.main',
      color: 'white.main',
      border: '1px solid #B73123'
    }
  },
  dialogTitle: {
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 700,
    fontFamily: 'Mulish',
    color: 'grey.800',
    p: 0
  },
  iconButton: {
    position: 'absolute',
    right: '20px',
    top: '22px',
    '& svg': {
      width: '20px',
      height: '20px'
    }
  },
  dialogContent: {
    p: 0,
    m: 0
  },
  dialogContentText: {
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Mulish',
    color: 'grey.800',
    mb: '32px'
  },
  listItem: {
    display: 'flex',
    p: '8px 12px',
    height: '40px',
    alignItem: 'center'
  },
  listItemWrapper: {
    borderRadius: 2,
    border: 1,
    borderColor: 'grey.300',
    position: 'relative',
    height: '40px'
  },
  allSelectionContainer: { display: 'flex', gap: '12px', mb: '8px', justifyContent: 'center' },
  closeIcon: {
    color: 'white',
    height: '12px',
    width: '12px',
    '& path': {
      fill: 'white'
    }
  },
  sectionSelectionButton: {
    height: '30px',
    borderRadius: '16px',
    border: `1px solid ${palette.gray.lightVariant3}`,
    color: 'primary.darkVariant1',
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    textTransform: 'capitalize',
    marginRight: '8px',
    padding: '0 16px'
  },
  leftArrowIcon: {
    width: '14px',
    marginRight: '8px',
    color: 'gray.750'
  },
  labelSectionDownloadContainer: { position: 'relative', display: 'flex', mr: 3 },
  downloadIcon: { height: '16px', width: '16px' },
  disabledMenuItem: {
    cursor: 'default',
    opacity: 0.38
  },

  percentText: { fontSize: '20px', paddingTop: '6px' }
};

export default styles;
