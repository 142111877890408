import { createSvgIcon } from '@mui/material/utils';

const ExpandIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 13 13' fill='none'>
    <g clipPath='url(#clip0_16084_3573)'>
      <path
        d='M10.292 12.9998H9.20866C9.065 12.9998 8.92722 12.9428 8.82564 12.8412C8.72406 12.7396 8.66699 12.6018 8.66699 12.4582C8.66699 12.3145 8.72406 12.1767 8.82564 12.0751C8.92722 11.9736 9.065 11.9165 9.20866 11.9165H10.292C10.723 11.9165 11.1363 11.7453 11.441 11.4405C11.7458 11.1358 11.917 10.7225 11.917 10.2915V9.20817C11.917 9.06451 11.9741 8.92674 12.0756 8.82515C12.1772 8.72357 12.315 8.6665 12.4587 8.6665C12.6023 8.6665 12.7401 8.72357 12.8417 8.82515C12.9432 8.92674 13.0003 9.06451 13.0003 9.20817V10.2915C12.9995 11.0095 12.7138 11.6979 12.2061 12.2056C11.6984 12.7134 11.01 12.999 10.292 12.9998Z'
        fill='currentColor'
      />
      <path
        d='M0.541667 4.33333C0.398008 4.33333 0.260233 4.27627 0.158651 4.17468C0.0570683 4.0731 0 3.93533 0 3.79167V2.70833C0.00086009 1.9903 0.286478 1.30193 0.794202 0.794202C1.30193 0.286478 1.9903 0.00086009 2.70833 0L3.79167 0C3.93533 0 4.0731 0.0570683 4.17468 0.158651C4.27627 0.260233 4.33333 0.398008 4.33333 0.541667C4.33333 0.685326 4.27627 0.823101 4.17468 0.924683C4.0731 1.02627 3.93533 1.08333 3.79167 1.08333H2.70833C2.27736 1.08333 1.86403 1.25454 1.55928 1.55928C1.25454 1.86403 1.08333 2.27736 1.08333 2.70833V3.79167C1.08333 3.93533 1.02627 4.0731 0.924683 4.17468C0.823101 4.27627 0.685326 4.33333 0.541667 4.33333Z'
        fill='currentColor'
      />
      <path
        d='M3.79167 12.9998H2.70833C1.9903 12.999 1.30193 12.7134 0.794202 12.2056C0.286478 11.6979 0.00086009 11.0095 0 10.2915L0 9.20817C0 9.06451 0.0570683 8.92674 0.158651 8.82515C0.260233 8.72357 0.398008 8.6665 0.541667 8.6665C0.685326 8.6665 0.823101 8.72357 0.924683 8.82515C1.02627 8.92674 1.08333 9.06451 1.08333 9.20817V10.2915C1.08333 10.7225 1.25454 11.1358 1.55928 11.4405C1.86403 11.7453 2.27736 11.9165 2.70833 11.9165H3.79167C3.93533 11.9165 4.0731 11.9736 4.17468 12.0751C4.27627 12.1767 4.33333 12.3145 4.33333 12.4582C4.33333 12.6018 4.27627 12.7396 4.17468 12.8412C4.0731 12.9428 3.93533 12.9998 3.79167 12.9998Z'
        fill='currentColor'
      />
      <path
        d='M12.4587 4.33333C12.315 4.33333 12.1772 4.27627 12.0756 4.17468C11.9741 4.0731 11.917 3.93533 11.917 3.79167V2.70833C11.917 2.27736 11.7458 1.86403 11.441 1.55928C11.1363 1.25454 10.723 1.08333 10.292 1.08333H9.20866C9.065 1.08333 8.92722 1.02627 8.82564 0.924683C8.72406 0.823101 8.66699 0.685326 8.66699 0.541667C8.66699 0.398008 8.72406 0.260233 8.82564 0.158651C8.92722 0.0570683 9.065 0 9.20866 0L10.292 0C11.01 0.00086009 11.6984 0.286478 12.2061 0.794202C12.7138 1.30193 12.9995 1.9903 13.0003 2.70833V3.79167C13.0003 3.93533 12.9432 4.0731 12.8417 4.17468C12.7401 4.27627 12.6023 4.33333 12.4587 4.33333Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_16084_3573'>
        <rect width='13' height='13' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'ExpandIcon'
);
export default ExpandIcon;
