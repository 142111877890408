import { createSvgIcon } from '@mui/material';

const AngleLeftIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'>
    <path
      d='M11.0272 16L4.68921 9.65333C4.25312 9.21519 4.0083 8.62218 4.0083 8.004C4.0083 7.38582 4.25312 6.79281 4.68921 6.35467L11.0345 0L12.4465 1.414L6.10121 7.768C6.03872 7.83051 6.00362 7.91528 6.00362 8.00367C6.00362 8.09206 6.03872 8.17682 6.10121 8.23933L12.4385 14.586L11.0272 16Z'
      fill='currentColor'
    />
  </svg>,
  'AngleLeft'
);

export default AngleLeftIcon;
