import { Box, Skeleton, Stack } from '@mui/material';

const NotificationLoading = () => {
  return (
    <Box width='100%'>
      <Stack direction='row' spacing={2} padding={2} sx={{ mx: '26px', mt: '40px' }}>
        <Skeleton variant='rounded' width={40} height={40} />
        <Stack spacing={1}>
          <Stack>
            <Skeleton variant='text' width={100} height={20} />
            <Skeleton variant='text' width={150} height={20} />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
export default NotificationLoading;
