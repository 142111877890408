import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

// muiv5
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import VivproDatagrid from '../../../components/Datagrid';
import EmptyPage from '../../../components/EmptyPage/EmptyPage';

// context
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';

// hoc
import withFetchingProductDetails from './withFetchingProductsDetails';

import { PRODUCT_DETAILS_COLUMN_DEFINITION, PRODUCT_ROW_ID_MAP } from './constants';

interface ParameterProps {
  source: string;
}

const ProductDetails = () => {
  const { regulatoryState } = useContext(RegulatoryInformationStore);
  // get the url parameters
  const { source } = useParams<ParameterProps>();

  return (
    <Box display='flex' justifyContent='center' sx={{ width: '100%', height: '100%' }}>
      {!regulatoryState.loadingProductDetails && !regulatoryState.productDetails?.length ? (
        <EmptyPage message='Product Details are not available' />
      ) : (
        <Stack flexDirection='row' sx={{ m: 2, height: '60vh', width: '100%' }}>
          <VivproDatagrid
            rows={regulatoryState?.productDetails}
            columnsMapping={PRODUCT_DETAILS_COLUMN_DEFINITION[source?.toLowerCase() as string]}
            rowId={
              source?.toLowerCase() in PRODUCT_ROW_ID_MAP
                ? PRODUCT_ROW_ID_MAP[source?.toLowerCase() as string]
                : PRODUCT_ROW_ID_MAP.default
            }
            loading={regulatoryState?.loadingProductDetails}
          />
        </Stack>
      )}
    </Box>
  );
};

export default withFetchingProductDetails(ProductDetails);
