import { Box, Button, Collapse, Divider, Stack, Typography } from '@mui/material';

import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Refresh, Remove } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { isEmpty } from 'lodash';
import { SOURCE_WITH_REG_360 } from '../constants';
import notificationsDetailStyles from './NotificationsDetail.styles';
import { SubNotification } from './NotificationInterface';
import NotificationDetailsHeader from './NotificationDetailsHeader';

// store
import NotificationsStore from '../../../store/Notifications';

// global store
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';

import PDFPreview from '../../../components/PdfPreview/PDFPreview';
import { getPdfResource } from '../../../api/pages/ResultsPage';
import getDocumentMetadata from '../../../components/Timeline/TimelineDocuments/getDocumentMetadata';

const NotificationDetails = () => {
  const { notificationsState } = useContext(NotificationsStore);
  const { dispatch } = useContext(GlobalStore) as any;
  const { selectedNotification } = notificationsState;

  const [openedDocument, setOpenedDocument] = useState<string>('');
  const [openPdfDialog, setOpenPdfDialog] = useState<boolean>(false);
  const [pdfUrlLoading, setPdfUrlLoading] = useState<boolean>(false);
  const [selectedPdfUrl, setSelectedPdfUrl] = useState<string>('');
  const [pdfTitle, setPdfTitle] = useState<string>('');
  const [selectedSubNotification, setSelectedSubNotification] = useState<SubNotification | null>(
    null
  );
  const handleNotificationsReg360 = (
    applNo: string,
    subSource: string,
    entityModuleChangedType: string
  ) => {
    const num = encodeURIComponent(applNo);
    if (subSource === 'eu' && entityModuleChangedType.toLowerCase() === 'annex')
      window.open(
        `/regulatory360/core/${num}/${subSource?.toLowerCase()}?tabname=commissionProcedures`
      );
    else window.open(`/regulatory360/core/${num}/${subSource?.toLowerCase()}`);
  };

  const handleClosePDF = useCallback(() => {
    setOpenPdfDialog(false);
    setSelectedPdfUrl('');
    setPdfUrlLoading(false);
    setSelectedSubNotification(null);
  }, []);

  const isSubNotificationClickable = (
    entityId: string,
    subSource: string,
    s3Bucket: string,
    s3Path: string
  ) => {
    if (s3Path && s3Bucket) return true;
    return (
      (!isEmpty(entityId) && SOURCE_WITH_REG_360.includes(subSource)) ||
      subSource === 'adcomm' ||
      subSource === 'chmp'
    );
  };

  const fetchS3url = async (bucket: string, path: string) => {
    const payload: any = {};
    payload.s3_path = decodeURIComponent(path);
    payload.s3_bucket = bucket;
    try {
      const response = await getPdfResource({
        ...payload
      });
      if (response?.data?.status === 200) {
        return response?.data?.body.s3_url;
      }
      dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: `PDF Not found` }
      });
      return '';
    } catch (error) {
      dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: `Not a valid PDF${error}` }
      });
      return '';
    }
  };

  const handlePDFOpen = async (s3Bucket: string, s3Path: string) => {
    setOpenPdfDialog(true);
    setPdfUrlLoading(true);
    const pdftitle = s3Path?.split('/')?.pop() ?? '';
    setPdfTitle(pdftitle);
    const fileUrl = await fetchS3url(s3Bucket, s3Path);
    if (isEmpty(fileUrl)) {
      setOpenPdfDialog(false);
    }
    setPdfUrlLoading(false);
    setSelectedPdfUrl(fileUrl);
  };

  const handleRedirectLink = (
    source: string,
    entityId: string,
    entityModuleChangedType: string
  ) => {
    if (SOURCE_WITH_REG_360.includes(source)) {
      handleNotificationsReg360(entityId, source, entityModuleChangedType);
    } else if (source === 'adcomm') {
      window.open(`/adcomm?committee=ALL&selectedtab=meetings&meetingId=${entityId}`);
    } else if (source === 'chmp') {
      window.open(`/chmp/meetings?meetingId=${entityId}`);
    }
  };

  const handleSubNotificationClick = (notification: SubNotification) => {
    if (isEmpty(notification)) return;
    if (notification.source === 'ct' && isEmpty(notification.entity_id)) return;
    const {
      entity_id: entityId,
      source,
      s3_bucket: s3Bucket,
      s3_path: s3Path,
      entity_module_changed_type: entityModuleChangedType
    } = notification;
    setSelectedSubNotification(notification);
    if (s3Bucket && s3Path) handlePDFOpen(s3Bucket, s3Path);
    else handleRedirectLink(source, entityId, entityModuleChangedType);
  };

  // Do not delete. For future use
  const handleChatRia = async ({ pdfUrl = '' }: { pdfUrl?: string }) => {
    if (isEmpty(selectedSubNotification)) return;
    const {
      source,
      entity_module_changed_type: entityModuleChangedType,
      entity_id_name: entityIdName,
      entity_id: entityId
    } = selectedSubNotification;
    let metadata = {};
    if (source === 'adcomm') {
      metadata = {
        adcom_meeting_start_date: '',
        meeting_id: entityId,
        category: entityModuleChangedType
      };
    } else {
      metadata = getDocumentMetadata(
        source,
        { generic_name: entityIdName, application_number: entityId, product_name: '' },
        {},
        entityModuleChangedType
      );
    }
    dispatch({
      type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM,
      value: 'document'
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: source
    });
    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: {
        blob: pdfUrl,
        url: pdfUrl,
        item: metadata,
        selectedSource: source,
        triggerReopenChatRia: false
      }
    });
    if (source !== 'adcomm')
      dispatch({ type: GlobalActions.SET_APPLICATION_NAME, value: entityIdName });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
    handleClosePDF();
  };

  const getChangeTypeIcon = (changeType: string) => {
    switch (changeType) {
      case 'add':
        return <AddIcon />;
      case 'update':
        return <Refresh />;
      case 'delete':
        return <Remove />;
      default:
        return <AddIcon />;
    }
  };

  const reg360Text = useMemo(() => {
    if (isEmpty(selectedSubNotification)) return null;
    const { source } = selectedSubNotification;
    if (SOURCE_WITH_REG_360.includes(source)) return 'Open Reg360';
    if (source === 'adcomm') return 'Open AdComm';
    if (source === 'chmp') return 'Open CHMP';
    return null;
  }, [selectedSubNotification]);

  const SubNotificationUI = useCallback(
    (data: SubNotification) => (
      <Stack
        direction='row'
        key={`${data.id}-${data.source}-${data.entity_id}-${data.change_type}`}
        onClick={() => handleSubNotificationClick(data)}
        sx={
          isSubNotificationClickable(
            data?.entity_id ?? '',
            data?.source ?? '',
            data?.s3_bucket ?? '',
            data?.s3_path ?? ''
          )
            ? notificationsDetailStyles.detailsWithHover
            : notificationsDetailStyles.details
        }>
        <Box sx={notificationsDetailStyles.changeTypeIcon}>
          {getChangeTypeIcon(data?.change_type)}
        </Box>
        <Typography sx={notificationsDetailStyles.message}>{data?.message}</Typography>
      </Stack>
    ),
    []
  );

  const NotificationsDetailsList = useCallback(
    (notificationTitle: string, notifications: SubNotification[], optionalCount?: number) => (
      <Stack
        key={`${notificationTitle}-${notifications.length}`}
        sx={notificationsDetailStyles.detailContainer}>
        <Typography sx={notificationsDetailStyles.detailHeader}>
          {notificationTitle} ({optionalCount ?? notifications.length})
        </Typography>
        <Stack>
          {notifications.slice(0, 2).map(data => SubNotificationUI(data))}
          {notifications.length > 2 && (
            <Collapse in={openedDocument === notificationTitle} timeout='auto' unmountOnExit>
              {notifications.slice(2).map(data => SubNotificationUI(data))}
            </Collapse>
          )}
        </Stack>
        {notifications.length > 2 && (
          <Button
            sx={notificationsDetailStyles.showMoreButton}
            onClick={() =>
              setOpenedDocument(openedDocument === notificationTitle ? '' : notificationTitle)
            }>
            {openedDocument === notificationTitle
              ? 'Show Less'
              : `Show More (${notifications.length - 2})`}
          </Button>
        )}
      </Stack>
    ),
    [openedDocument]
  );

  return (
    <>
      <NotificationDetailsHeader notification={selectedNotification} />
      <Box height='100%' overflow='auto'>
        {Object.entries(selectedNotification?.notifications ?? {})
          .filter(
            ([key, value]) => !key.toLowerCase().startsWith('documents') && Array.isArray(value)
          )
          .map(([key, value]) => {
            let count;
            if (selectedNotification?.source === 'ct' && key === 'Trials')
              count = selectedNotification?.count_info?.[key];
            return NotificationsDetailsList(key, value as SubNotification[], count);
          })}
        {selectedNotification?.notifications?.Documents && (
          <Stack sx={notificationsDetailStyles.detailContainer}>
            <Typography sx={notificationsDetailStyles.detailHeader}>
              Documents ({selectedNotification?.count_info?.Documents})
            </Typography>
            <Divider
              sx={{
                mt: '3px'
              }}
            />
            <Stack>
              {selectedNotification?.notifications?.Documents?.map(documentData => {
                return Object.entries(documentData).map(([key, value]) =>
                  NotificationsDetailsList(key, value)
                );
              })}
            </Stack>
          </Stack>
        )}
      </Box>
      {!isEmpty(selectedSubNotification) && (
        <PDFPreview
          open={openPdfDialog}
          pdfUrl={selectedPdfUrl}
          onClose={handleClosePDF}
          handleChatRia={() => {}}
          title={pdfTitle}
          source={selectedSubNotification.source}
          pdfLoading={pdfUrlLoading}
          hideChatRia
          reg360Text={reg360Text}
          onReg360Click={() =>
            handleRedirectLink(
              selectedSubNotification.source,
              selectedSubNotification.entity_id,
              selectedSubNotification.entity_module_changed_type
            )
          }
        />
      )}
    </>
  );
};
export default NotificationDetails;
