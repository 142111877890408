import { createSvgIcon } from '@mui/material';

const CanadaFlagIcon = createSvgIcon(
  <svg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='18.0004' height='12' fill='white' />
    <path
      d='M0 0H5.99987V12.0001H0V0ZM12.0001 0H18V12.0001H12.0001V0ZM10.125 6.83343L11.25 6.27093L10.6875 5.98971V5.42721L9.5625 5.98971L10.125 4.86471H9.5625L9 4.02092L8.4375 4.86471H7.875L8.4375 5.98971L7.3125 5.42721V5.98971L6.75 6.27093L7.875 6.83343L7.59379 7.39593H8.71879V8.23971H9.28129V7.39593H10.4063L10.125 6.83343Z'
      fill='#D80027'
    />
  </svg>,
  'CanadaFlagIcon'
);

export default CanadaFlagIcon;
