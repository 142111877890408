import React, { memo } from 'react';
import { Box, Button, Divider, Skeleton, Stack, Tooltip } from '@mui/material';
import styles from '../styles/DocumentView.styles';
import { DOCUMENT_VIEW_RESULTS_PER_PAGE } from '../constants';

const Footer = ({
  context: { loadMoreData, paginatedResultsCount, isLoading, fetchedCount, totalCount }
}: any) => {
  if (fetchedCount === 0) {
    return null;
  }
  if (
    fetchedCount >= totalCount ||
    Math.ceil(paginatedResultsCount / DOCUMENT_VIEW_RESULTS_PER_PAGE) ===
      Math.ceil(totalCount / DOCUMENT_VIEW_RESULTS_PER_PAGE)
  ) {
    return (
      <>
        <Divider
          sx={{
            my: 5,
            mx: 10,
            mb: 20
          }}>
          You&apos;ve seen everything!
        </Divider>
        <Box height={20} />
      </>
    );
  }
  if (isLoading) {
    return (
      <Stack p={2} gap={3} mb={20}>
        {Array.from({ length: 5 }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Stack key={`loading-${index}`}>
            <Stack direction='row' justifyContent='space-between' alignContent='center'>
              <Skeleton animation='wave' variant='rectangular' width='85%' height={60} />
              <Skeleton
                variant='circular'
                width={30}
                height={30}
                sx={{
                  m: 2,
                  mr: 1
                }}
              />
            </Stack>
            <Skeleton
              animation='wave'
              variant='rectangular'
              width='15%'
              height={20}
              sx={{
                my: 2
              }}
            />
            <Stack direction='row' justifyContent='space-between'>
              <Skeleton variant='rectangular' width='55%' height={20} animation='wave' />
            </Stack>
          </Stack>
        ))}
      </Stack>
    );
  }
  return (
    <Stack justifyContent='center' direction='row'>
      <Tooltip
        title={fetchedCount >= 1000 && 'Cannot fetch more than 1000 results.'}
        placement='bottom'>
        <span>
          <Button
            disabled={fetchedCount >= 1000}
            onClick={loadMoreData}
            variant='contained'
            sx={styles.loadMoreButton}>
            Load More
          </Button>
        </span>
      </Tooltip>
    </Stack>
  );
};

export default memo(Footer);
