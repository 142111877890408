import typography from '../../../themev5/typography';

const styles = {
  refineSearchChip: {
    m: 0.5,
    borderColor: 'primary.backgroundLight'
  },
  refineSearchChipSelected: {
    m: 0.5,
    bgcolor: 'primary.main',
    color: 'white.main',
    '&:hover': { bgcolor: 'primary.medium' }
  },

  resultCountText: {
    fontSize: 14,
    fontWeight: 400,
    ml: '4px !important'
  },
  filtersBox: {
    display: 'flex',
    flexDirection: 'column',
    height: 400,
    maxHeight: 240,
    overflowY: 'auto'
  },
  optionCountAvatar: {
    width: 22,
    height: 22,
    fontSize: typography.body1.fontSize,
    bgcolor: 'primary.dark',
    color: 'white'
  },
  optionCountLargeAvatar: {
    width: 28,
    height: 25,
    fontSize: typography.body1.fontSize,
    bgcolor: 'primary.dark',
    color: 'white'
  },
  totalFilterCountAvatar: {
    width: 22,
    height: 22,
    fontSize: '1em',
    bgcolor: 'primary.dark'
  },
  clearText: {
    cursor: 'pointer',
    fontWeight: 'bold',
    color: 'primary.main',
    '&:hover': {
      color: 'primary.medium'
    }
  }
};

export default styles;
