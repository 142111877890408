/* eslint-disable camelcase */
import { useContext } from "react";
import { getCTAdverseEventsFromId, getCTBusinessIntelligenceFromId } from "../../../api/pages/UserProfile";
import {
  getCTAdverseEvents,
  getCTAdverseEventsAdvancedSearch,
  getCTBusinessIntelligence,
  getCTBusinessIntelligenceAdvancedSearch
} from "../../../api/pages/ResultsPage";
import ResultsStore from "../../../store/ResultsPage";
import ResultsAction from "../../../store/ResultsPage/actions";

const useBusinessIntelligenceAdverseEventsData = () => {
  const { resultsDispatch } = useContext(ResultsStore) as any;

  const clearBusinessIntelligence = () => {
    resultsDispatch({ type: ResultsAction.SET_BUSSINESS_INTELLIGENCE, value: {} });
  };

  const clearAdverseEvents = () => {
    resultsDispatch({ type: ResultsAction.SET_CT_ADVERSE_EVENTS, value: [] });
  };

  const fetchCTBusinessSearchResultsFromId = async (searchId: any) => {
    resultsDispatch({
      type: ResultsAction.SET_CT_BUSINESS_INTELLIGENCE_LOADING,
      value: true
    });
    const res = await getCTBusinessIntelligenceFromId(searchId);
    if (res?.status === 200) {
      clearBusinessIntelligence();
      const { business_intelligence } = res.data.body;
      resultsDispatch({
        type: ResultsAction.SET_BUSSINESS_INTELLIGENCE,
        value: business_intelligence
      });
    } else if (res?.status === 404 || res?.status === 204) {
      clearBusinessIntelligence();
    }
    resultsDispatch({
      type: ResultsAction.SET_CT_BUSINESS_INTELLIGENCE_LOADING,
      value: false
    });
  };

  const fetchCTAdverseEventsResultsFromId = async (searchId: any) => {
    resultsDispatch({
      type: ResultsAction.SET_CT_ADVERSE_EVENTS_LOADING,
      value: true
    });
    const res = await getCTAdverseEventsFromId(searchId);
    if (res?.status === 200) {
      const { results } = res.data.body;
      resultsDispatch({
        type: ResultsAction.SET_CT_ADVERSE_EVENTS,
        value: results
      });
    } else if (res?.status === 404 || res?.status === 204) {
      clearAdverseEvents();
    }
    resultsDispatch({
      type: ResultsAction.SET_CT_ADVERSE_EVENTS_LOADING,
      value: false
    });
  };

  const fetchCTBusinessSearchResultsWithFilters = async (searchStub: any, payloadStub: any) => {
    resultsDispatch({
      type: ResultsAction.SET_CT_BUSINESS_INTELLIGENCE_LOADING,
      value: true
    });
    const res = await getCTBusinessIntelligence(searchStub, payloadStub);
    if (res?.status === 200) {
      clearBusinessIntelligence();
      // @ts-
      const { business_intelligence } = res.data.body;
      resultsDispatch({
        type: ResultsAction.SET_BUSSINESS_INTELLIGENCE,
        value: business_intelligence
      });
    } else if (res?.status === 404 || res?.status === 204) {
      clearBusinessIntelligence();
    }
    resultsDispatch({
      type: ResultsAction.SET_CT_BUSINESS_INTELLIGENCE_LOADING,
      value: false
    });
  };

  const fetchCTAdverseEventsResultsWithFilters = async (searchStub: any, payloadStub: any) => {
    resultsDispatch({
      type: ResultsAction.SET_CT_ADVERSE_EVENTS_LOADING,
      value: true
    });
    const res = await getCTAdverseEvents(searchStub, payloadStub);
    if (res?.status === 200) {
      const { results } = res.data.body;
      resultsDispatch({
        type: ResultsAction.SET_CT_ADVERSE_EVENTS,
        value: results
      });
    } else if (res?.status === 404 || res?.status === 204) {
      clearAdverseEvents();
    }
    resultsDispatch({
      type: ResultsAction.SET_CT_ADVERSE_EVENTS_LOADING,
      value: false
    });
  };

  const fetchCTBusinessAdvancedSearchResultsWithFilters = async (
    searchStub: any,
    payloadStub: any
  ) => {
    resultsDispatch({
      type: ResultsAction.SET_CT_BUSINESS_INTELLIGENCE_LOADING,
      value: true
    });
    const res = await getCTBusinessIntelligenceAdvancedSearch(searchStub, payloadStub);
    if (res?.status === 200) {
      clearBusinessIntelligence();
      // @ts-
      const { business_intelligence } = res.data.body;
      resultsDispatch({
        type: ResultsAction.SET_BUSSINESS_INTELLIGENCE,
        value: business_intelligence
      });
    } else if (res?.status === 404 || res?.status === 204) {
      clearBusinessIntelligence();
    }
    resultsDispatch({
      type: ResultsAction.SET_CT_BUSINESS_INTELLIGENCE_LOADING,
      value: false
    });
  };

  const fetchCTAdverseEventsAdvancedSearchResultsWithFilters = async (
    searchStub: any,
    payloadStub: any
  ) => {
    resultsDispatch({
      type: ResultsAction.SET_CT_ADVERSE_EVENTS_LOADING,
      value: true
    });
    const res = await getCTAdverseEventsAdvancedSearch(searchStub, payloadStub);
    if (res?.status === 200) {
      const { results } = res.data.body;
      resultsDispatch({
        type: ResultsAction.SET_CT_ADVERSE_EVENTS,
        value: results
      });
    } else if (res?.status === 404 || res?.status === 204) {
      clearAdverseEvents();
    }
    resultsDispatch({
      type: ResultsAction.SET_CT_ADVERSE_EVENTS_LOADING,
      value: false
    });
  };

  return {
    fetchCTBusinessSearchResultsFromId,
    fetchCTAdverseEventsResultsFromId,
    fetchCTAdverseEventsResultsWithFilters,
    fetchCTBusinessSearchResultsWithFilters,
    fetchCTBusinessAdvancedSearchResultsWithFilters,
    fetchCTAdverseEventsAdvancedSearchResultsWithFilters
  };
};

export default useBusinessIntelligenceAdverseEventsData;
