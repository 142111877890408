import React from 'react';
import { Box, Typography } from '@mui/material';
import DocumentIcon from '../../../assets/svgs/PDCO/DocumentIcon';
import styles from '../styles/Meeting.styles';

const EmptyMeetingDetails = ({ resources, handlePDFOpenClick }: any) => {
  const formattedResouces = Object.values(resources).flat();
  const noMeetingHappend =
    Object.keys(resources)?.length === 1 && Object.keys(resources)[0] === 'agenda';
  const isUpcomingMeeting = Object.keys(resources)?.length === 0;

  const getMarginBottom = () => {
    if (noMeetingHappend) {
      return 1.5;
    }
    if (isUpcomingMeeting) {
      return 0;
    }
    return 1;
  };

  return (
    <Box sx={styles.noMeetingDetailsContainer}>
      <Box width={426}>
        <Typography
          sx={{
            ...styles.noMeetingDetailsText,
            fontWeight: 700,
            mb: getMarginBottom()
          }}>
          {noMeetingHappend
            ? 'Details will be provided once the Minutes document is available.'
            : 'There are no meeting details available from the source.'}
        </Typography>
        <Typography
          sx={{
            ...styles.noMeetingDetailsText,
            fontWeight: formattedResouces?.length > 0 ? 500 : 700
          }}>
          {formattedResouces?.length > 0
            ? 'Please access the available document(s)'
            : 'Details will be posted as they become available.'}
        </Typography>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          gap={1.5}
          mt={0.5}>
          {formattedResouces?.length > 0 &&
            formattedResouces?.map((resource: any) => (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                width={370}
                key={resource?.title}>
                <DocumentIcon sx={{ fontSize: 14, mr: 0.5, mb: 0.1 }} />
                <Typography
                  onClick={() =>
                    handlePDFOpenClick(resource?.s3_url, resource?.title, resource?.category_bucket)
                  }
                  sx={styles.noMeetingDetailsLink}>
                  {resource?.title}
                </Typography>
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(EmptyMeetingDetails);
