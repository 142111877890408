const styles = {
  accordian: {
    borderRadius: 2,
    border: '1px solid',
    borderColor: 'gray.300',
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    }
  },
  heading: {
    fontWeight: 'bold',
    color: 'gray.main',
    paddingBottom: '20px'
  },
  slideBackGround: {
    backgroundColor: 'blue.lightVariant'
  },
  title: {
    fontWeight: 700,
    color: 'gray.900',
    fontSize: 14
  },
  titleDate: {
    fontWeight: 'bold',
    color: 'grey.main',
    minWidth: '170px'
  },
  subtitleOne: {
    fontWeight: 'bold',
    color: 'primary.main'
  },
  subtitleTwo: {
    fontWeight: 'bold'
  },
  description: {
    color: 'gray.900',
    fontSize: 14
  },
  downloadText: {
    fontFamily: 'Nunito Sans',
    fontWeight: '700',
    fontSize: '16px',
    color: 'primary.main',
    paddingBottom: '1vh',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },
  expandIcon: {
    color: 'primary.600'
  },
  icon: {
    // color: 'aqua',
    '&.Mui-completed': {
      color: 'red.main'
    },
    '&completed': {
      color: 'primary.main'
    }
  },
  completedIcon: {
    backgroundColor: 'primary.main',
    color: 'red'
  },
  stepText: {
    fontWeight: 'bold'
  },
  actionBtn: {
    color: 'white.main',
    textTransform: 'capitalize',
    marginLeft: '5px',
    marginRight: '5px'
  },
  preload: {
    display: 'none'
  },
  btn: {
    borderRadius: 6,
    px: 2,
    py: 1,
    height: 34,
    textTransform: 'capitalize',
    '&:hover': {
      color: 'primary.main'
    }
  },
  image: {
    width: '80%',
    height: '100%',
    border: '1px solid gray',
    borderRadius: '30px'
  },
  imageFullWidth: {
    width: '80%',
    height: '100%',
    border: '1px solid gray',
    borderRadius: '30px'
  },
  chipsListItem: {
    pt: 0,
    pb: 1.5,
    px: 0,
    width: 'auto'
  },
  chip: {
    fontSize: 13,
    '&:hover': {
      backgroundColor: theme => `${theme.palette.primary.backgroundLight} !important`,
      borderColor: theme => `${theme.palette.primary.main} !important`
    },
    '&.MuiChip-filled': {
      backgroundColor: theme => `${theme.palette.primary.lightVariant1} !important`,
      color: theme => `${theme.palette.primary.darkVariant2} !important`,
      fontWeight: 'bold'
    },
    '& > .MuiChip-label': {
      padding: '8px 16px'
    },
    color: 'primary.darkVariant1',
    borderColor: 'gray.lightVariant8'
  }
};

export default styles;
