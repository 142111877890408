import { useEffect, useState, useContext } from 'react';
import GuidanceStore from '../store/GuidanceDocuments';
import GuidanceActions from '../store/GuidanceDocuments/actions';
import EMAGuidanceActions from '../store/EMAGuidance/actions';
import CanadaGuidanceActions from '../store/CanadaGuidance/actions';
import EMAGuidanceStore from '../store/EMAGuidance';
import CanadaGuidanceStore from '../store/CanadaGuidance';

const useSearchedTableData = ({
  tableData = [],
  search,
  ignoreFields = [],
  limit,
  offset,
  searchUrl = '',
  module = ''
}: any) => {
  const [searchedData, setSearchedData] = useState([]);
  const { guidanceDispatch } = useContext(GuidanceStore);
  const { EMAGuidanceDispatch } = useContext(EMAGuidanceStore);
  const { CanadaGuidanceDispatch } = useContext(CanadaGuidanceStore);

  const getGuidanceSearchedDocument = async () => {
    const res = await searchUrl(
      {
        filters: { filter_keywords: [search?.term ?? ''] }
      },
      limit,
      offset
    );

    if (module === 'ema_guidance_documents') {
      setSearchedData(res?.data?.body[module] ?? []);
      EMAGuidanceDispatch({
        type: EMAGuidanceActions.SET_EMA_GUIDANCE_TABLE,
        value: res?.data?.body?.[module] ?? []
      });
    } else if (module === 'canada_guidance_documents') {
      setSearchedData(res?.data?.Success[module] ?? []);
      CanadaGuidanceDispatch({
        type: CanadaGuidanceActions.SET_CANADA_GUIDANCE_TABLE,
        value: res?.data?.Success[module] ?? []
      });
    } else if (module === 'guidances') {
      setSearchedData(res?.data?.body?.[module] ?? []);
      guidanceDispatch({
        type: GuidanceActions.SET_GUIDANCE_TABLE,
        value: res?.data?.body?.[module] ?? []
      });
    }
  };

  useEffect(() => {
    if (!search?.term) {
      setSearchedData(tableData);
    } else {
      getGuidanceSearchedDocument();
    }
  }, [search, ignoreFields, tableData]);

  return {
    searchedData
  };
};

export default useSearchedTableData;
