/* eslint-disable react/prop-types */
import React, { Fragment, useContext } from 'react';
import uniqueId from 'lodash/uniqueId';

// MATERIAL UI IMPORTS
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
// import Paper from '@material-ui/core/Paper';
// styles
import LineGraphStyles from '../styles/LineGraph.styles';
// other imports
import PlusIcon from '../../../Images/svgs/plus';
import CustomBarGraph from '../../CustomComponents/Graphs/CustomBarGraph';
// store
import Store from '../../../store';
import Actions from '../../../store/actions';

import { REPORT_QUERIES } from './constants';

const LineGraph = ({ data, onAddToReport = null }) => {
  const classes = LineGraphStyles();
  const { dispatch } = useContext(Store);

  const addGraphByYear = async id => {
    if (onAddToReport) {
      onAddToReport('us_result_approval_by_year');
      return;
    }
    const addedReport = REPORT_QUERIES.find(report => report.id === id);
    const random = Math.floor(Math.random() * 1000);
    dispatch({
      type: Actions.ADD_TEMPLATE_TO_REPORT,
      value: {
        templateType: 'custom',
        chartType: addedReport.graph,
        sectionType: 'CHART',
        id: uniqueId(`biologics_${random}_`),
        style: {
          placement: {
            h: 40,
            w: 12
          },
          graphStyle: addedReport.graphStyle,
          title: addedReport.title
        },
        data: {
          source: 'us',
          search_type: 'biologics',
          query: addedReport.query
        }
      }
    });
    await dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message: 'Successfully added to Report.', color: 'success' }
    });
  };

  const addGraphByTime = async id => {
    if (onAddToReport) {
      onAddToReport('us_result_approval_time_by_year');
      return;
    }

    const addedReport = REPORT_QUERIES.find(report => report.id === id);
    const random = Math.floor(Math.random() * 1000);
    dispatch({
      type: Actions.ADD_TEMPLATE_TO_REPORT,
      value: {
        templateType: 'custom',
        chartType: addedReport.graph,
        sectionType: 'CHART',
        id: uniqueId(`bi_line_${random}_`),
        style: {
          placement: {
            h: 40,
            w: 12
          },
          graphStyle: addedReport.graphStyle,
          title: addedReport.title
        },
        data: {
          source: 'us',
          search_type: 'biologics',
          query: addedReport.query
        }
      }
    });
    await dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message: 'Successfully added to Report.', color: 'success' }
    });
  };
  return (
    <Grid container lg={12} spacing={2}>
      <Grid item lg={6}>
        <Box mt={1} display='flex' flexDirection='column' className={classes.graphBox}>
          {/* <Paper elevation={2} className={classes.paper}> */}
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            {data ? (
              <>
                <Typography className={classes.headings}>Approval(s) by Year</Typography>
                <Typography
                  onClick={() => addGraphByYear('us_result_approval_by_year')}
                  className={classes.add}>
                  <PlusIcon /> Add to Report
                </Typography>
              </>
            ) : (
              <>
                <Skeleton height={16} width={100} className={classes.headings} />
                <Skeleton height={16} width={100} className={classes.add} />
              </>
            )}
          </Box>
          {data && data ? (
            <>
              {data.approvalYearGraph?.length > 0 ? (
                <CustomBarGraph
                  keys={['count']}
                  colors={['#12815E']}
                  paddings={0.4}
                  index='year'
                  data={data && data.approvalYearGraph?.filter(item => item.count !== 0)}
                  margins={{ top: 30, right: 5, bottom: 50, left: 30 }}
                />
              ) : (
                <Box display='flex' alignItems='center'>
                  <Typography>No Data</Typography>
                </Box>
              )}
            </>
          ) : (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='flex-end'
              alignItems='space-between'
              className={classes.loaderBox}>
              <Box
                display='flex'
                justifyContent='space-between'
                flexWrap='wrap-reverse'
                className={classes.barGraphRect}>
                <Skeleton variant='rect' height='90%' width='.2%' />
                <Skeleton variant='rect' height='60%' width='3%' />
                <Skeleton variant='rect' height='80%' width='3%' />
                <Skeleton variant='rect' height='30%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='60%' width='3%' />
                <Skeleton variant='rect' height='80%' width='3%' />
                <Skeleton variant='rect' height='30%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
              </Box>
              <Skeleton variant='rect' height='.2%' width='100%' />
            </Box>
          )}
          {/* </Paper> */}
        </Box>
      </Grid>
      <Grid item lg={6}>
        <Box mt={1} display='flex' flexDirection='column' className={classes.graphBox}>
          {/* <Paper elevation={2} className={classes.paper}> */}
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            {data ? (
              <>
                <Typography className={classes.headings}>
                  {data &&
                    data.approvalTimeGraph?.filter(item => 'mean' in item).length > 0 &&
                    'Median '}
                  Approval Time, Days from Filing
                </Typography>
                <Typography
                  onClick={() => addGraphByTime('us_result_approval_time_by_year')}
                  className={classes.add}>
                  <PlusIcon /> Add to Report
                </Typography>
              </>
            ) : (
              <>
                <Skeleton height={16} width={150} className={classes.headings} />
                <Skeleton height={16} width={100} className={classes.add} />
              </>
            )}
          </Box>
          {data && data ? (
            <>
              {data.approvalTimeGraph?.length > 0 ? (
                <CustomBarGraph
                  className={classes.graph}
                  keys={['count']}
                  paddings={0.4}
                  colors={['#6868FA']}
                  index='year'
                  data={data && data.approvalTimeGraph?.filter(item => item.count !== 0)}
                  margins={{ top: 30, right: 5, bottom: 50, left: 30 }}
                />
              ) : (
                <Box display='flex' alignItems='center'>
                  <Typography>No Data</Typography>
                </Box>
              )}
            </>
          ) : (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='flex-end'
              alignItems='space-between'
              className={classes.loaderBox}>
              <Box
                display='flex'
                justifyContent='space-between'
                flexWrap='wrap-reverse'
                className={classes.barGraphRect}>
                <Skeleton variant='rect' height='90%' width='.2%' />
                <Skeleton variant='rect' height='60%' width='3%' />
                <Skeleton variant='rect' height='80%' width='3%' />
                <Skeleton variant='rect' height='30%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='60%' width='3%' />
                <Skeleton variant='rect' height='80%' width='3%' />
                <Skeleton variant='rect' height='30%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
              </Box>
              <Skeleton variant='rect' height='.2%' width='100%' />
            </Box>
          )}
          {/* </Paper> */}
        </Box>
      </Grid>
    </Grid>
  );
};

export default LineGraph;
