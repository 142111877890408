import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    height: '85vh',
    background: '#F7F7F7',
    marginLeft: theme.spacing(13),
    width: `calc(100% - ${theme.spacing(15) + 1}px)`
  },
  skeleton: {
    width: '100%'
  },
  airplaneIcon: {
    height: '52px',
    width: '52px',
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 2,
    bottom: theme.spacing(2),
    left: theme.spacing(2)
  },
  dropdownContainer: {
    width: theme.spacing(30),
    paddingLeft: theme.spacing(1),
    background: theme.palette.white,
    borderRadius: theme.spacing(1),
    border: `1px solid #CDD4F7`,
    '&:hover': {
      // you want this to be the same as the backgroundColor above
      backgroundColor: '#FFF'
    },
    marginLeft: theme.spacing(1)
  },
  dropdownText: {
    color: theme.palette.biologics.textPrimary,
    fontWeight: 'bold'
  },
  headings: {
    color: theme.palette.biologics.textPrimary,
    fontWeight: 'bold',
    fontFamily: 'Mulish',
    fontSize: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  percentages: {
    marginTop: theme.spacing(2)
  },
  add: {
    fontSize: theme.spacing(1.87),
    fontFamily: 'Mulish',
    color: theme.palette.navbar.button,
    cursor: 'pointer',
    paddingTop: '1rem',
    paddingRight: '1rem'
  },
  addPercentage: {
    fontSize: 16,
    color: theme.palette.navbar.button,
    cursor: 'pointer',
    fontFamily: 'Mulish'
  },
  botIconFAB: {
    left: theme.spacing(2),
    bottom: theme.spacing(10),
    zIndex: theme.zIndex.drawer + 2,
    position: 'fixed'
  },
  botIcon: {
    height: '100%',
    width: '100%'
  },

  botIntroPopup: {
    fontWeight: '900',
    fontSize: '2vh',
    padding: '4px',
    borderRadius: '10px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.lightBackground
  },

  botTextPopup: {
    fontSize: '1.5vh',
    padding: '4px',
    color: theme.palette.white,
    opacity: 1
  },

  ModalTitle: {
    padding: 0
  },
  ModalCloseIconButton: {
    padding: 0,
    marginBottom: '-20px',
    marginLeft: '-20px',
    color: theme.palette.navbar.button
  },
  ModalCloseIcon: {
    color: theme.palette.biologics.textPrimary
  },

  Dialog: {
    minHeight: '70vh',
    padding: 0
  },
  ariaBtn: {
    padding: theme.spacing(2),
    width: 200,
    height: 66,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.primary.backgroundPrimary,
    borderRadius: '12px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderWidth: 0,
    textDecoration: 'none',
    cursor: 'pointer'
  },
  ariaText: {
    marginLeft: theme.spacing(2),
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    color: 'white',
    fontSize: '18px'
  },
  percentageGraphsContainer: {
    backgroundColor: 'white',
    borderRadius: '16px',
    paddingLeft: '1rem',
    paddingRight: '0.5rem',
    paddingBottom: '1rem',
    paddingTop: '1rem'
  }
}));
