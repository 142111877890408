/* eslint-disable import/prefer-default-export */
import { GridColDef } from '@mui/x-data-grid-pro';
import React from 'react';
import renderCellExpand from '../../../components/Datagrid/DatagridPopover';
import TimelineDocuments from '../../../components/Timeline/TimelineDocuments/TimelineDocuments';

export const JP_RESOURCE_COLUMN_DEFINITION: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    headerClassName: 'table-header',
    width: 530,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'type',
    headerName: 'Type',
    headerClassName: 'table-header',
    width: 230,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'id',
    headerName: ' ',
    headerClassName: 'table-header',
    width: 230,
    cellClassName: 'table-row',
    renderCell: params => {
      // @ts-ignore
      return <TimelineDocuments column='' dataItem={params?.row} />;
    }
  },
  {
    field: 'date',
    headerName: 'Date',
    headerClassName: 'table-header',
    width: 230,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  }
];

export const RESOURCE_DETAILS_COLUMN_DEFINITION: { [key: string]: any } = {
  jp: JP_RESOURCE_COLUMN_DEFINITION
};

export const RESOURCE_ROW_ID_MAP: { [key: string]: any } = {
  jp: 'id',
  default: 'application_id'
};
