import { createSvgIcon } from '@mui/material';

const SearchIcon = createSvgIcon(
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'>
    <path d='M6.752 10.4986C3.85357 10.4986 1.50391 8.14897 1.50391 5.25051C1.50391 2.35206 3.85355 0.00244141 6.752 0.00244141C9.65046 0.00244141 12.0001 2.35208 12.0001 5.25054C12.0001 8.149 9.65044 10.4986 6.752 10.4986ZM6.752 1.50191C4.68168 1.50191 3.00337 3.18022 3.00337 5.25054C3.00337 7.32086 4.68168 8.99917 6.752 8.99917C8.82233 8.99917 10.5006 7.32086 10.5006 5.25054C10.5006 3.18022 8.8223 1.50191 6.752 1.50191Z' />
    <path d='M0.754076 11.9982C0.554789 11.9994 0.363253 11.9212 0.221773 11.7808C-0.0722232 11.4892 -0.0741613 11.0146 0.217431 10.7206C0.218878 10.7191 0.220326 10.7177 0.221773 10.7162L3.04074 7.89725C3.34507 7.60325 3.83011 7.61165 4.12411 7.91598C4.41811 8.22032 4.40971 8.70536 4.10538 8.99935L1.28638 11.7808C1.1449 11.9212 0.953364 11.9994 0.754076 11.9982Z' />
  </svg>,
  'Search'
);

export default SearchIcon;
