import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';

// material ui
import { Box, Popover } from '@mui/material';

// hooks
import usePediatricsFDAData from '../hooks/usePediatricsFDAData';

import GlobalStore from '../../../store';

// components'
import PediatricsDetails from '../components/Details';
import NewFilters from '../../../components/NewFilters';
import BottomDrawer from '../../../components/CustomComponents/BottomDrawer';
import SubHeader from '../../../components/SubHeader';
import AskRiaButton from '../../../components/Buttons/AskRiaButton';
import AriaDrawerCore from '../../SearchResults/components/AriaDrawerCore';

import PediatricsFDAStore from '../../../store/Pediatrics';

import ReportWidgets from '../../../components/Report/Widgets';
import { PAGINATION_DEFAULT_OPTIONS, PEDIATRICS_FDA_FILTERS } from '../const';
import { Stat } from '../../SearchResults/types';
import AskRiaButtonStyles from '../../../components/Buttons/styles/AskRiaButton.styles';
import useAriaShareHandler from '../../../components/AriaShare/useAriaShareHandler';
import VISUALIZE_CHART_MAPPING from '../../../components/Insights/const';
import truncateCountAboveMillions from '../../../utils/truncateCountAboveMillions';
import formatCount from '../../../utils/formatCount';
import Insights from '../../../components/Insights/Insights';

const PediatricsFDATabs = () => {
  const { dispatch } = useContext(GlobalStore) as any;
  const { pediatricsFDAState } = useContext(PediatricsFDAStore);
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);
  const [ariaModalOpen, setAriaModalOpen] = useState(false);
  const [reportChoicesOpenAnchorElement, setReportChoiceAnchorElement] =
    useState<HTMLElement | null>(null);
  const {
    isLoading,
    pediatricsFDAFilters,
    getPediatricsFDAData,
    sortFields,
    getDatawithDataGridFilter,
    dataGridFilter
  } = usePediatricsFDAData();

  const [pediatricStats, setPediatricStats] = useState<Stat[]>([]);
  const [sort, setSort] = useState([...sortFields]);
  const [pagination, setPagination] = useState({
    pageSize: PAGINATION_DEFAULT_OPTIONS.limit,
    page: PAGINATION_DEFAULT_OPTIONS.offset
  });
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);

  useEffect(() => {
    getPediatricsFDAData(
      [],
      PAGINATION_DEFAULT_OPTIONS.limit,
      PAGINATION_DEFAULT_OPTIONS.offset,
      []
    );
  }, []);

  useEffect(() => {
    if (pediatricsFDAState?.stats) {
      const newStats: Stat[] = [];
      const [activeIngredients, formulations, routes] =
        pediatricsFDAState?.stats?.overall_stats || [];

      newStats.push({ title: 'Active Ingredients', value: activeIngredients?.count || 0 });
      newStats.push({ title: 'Formulations', value: formulations?.count || 0 });
      newStats.push({ title: 'Routes', value: routes?.count || 0 });

      newStats.push({
        title: 'Documents/Pages',
        value: pediatricsFDAState?.stats?.pdf_stats?.total_document_count,
        value2: pediatricsFDAState?.stats?.pdf_stats?.total_page_count
      });

      setPediatricStats(newStats);
    }
  }, [pediatricsFDAFilters]);

  useAriaShareHandler(isLoading, ariaModalOpen, setAriaModalOpen);

  const handleFilterApply = useCallback(
    (currentfilters: any) => {
      setIsFilterUpdated(true);
      setPagination({ page: PAGINATION_DEFAULT_OPTIONS.offset, pageSize: pagination.pageSize });
      getPediatricsFDAData(
        currentfilters,
        pagination.pageSize,
        PAGINATION_DEFAULT_OPTIONS.offset,
        sortFields
      );
    },
    [sortFields, pagination]
  );

  const getFiltersLabelList = () => {
    return PEDIATRICS_FDA_FILTERS.reduce((acc: any, elem) => {
      acc[elem.value] = elem.label;
      return acc;
    }, {});
  };

  const handleVisualizeOpen = () => {
    setIsVisualizeOpen(true);
  };

  const closeDrawer = () => {
    setIsVisualizeOpen(false);
  };

  const getAriaDocuments = () => {
    const ariaDocuments = pediatricsFDAState?.askRiaFilters?.map((item: any) => ({
      identifier: item.replace('.pdf', '').replace(/_/g, '-')
    }));

    return ariaDocuments || [];
  };

  const getInsightStatsList = useMemo(() => {
    const StatList: Array<any> = [];
    if (!pediatricsFDAState?.stats?.overall_stats) {
      return StatList;
    }
    pediatricsFDAState?.stats?.overall_stats.forEach((item: { title: any; count: any }) => {
      StatList.push({
        text: item.title,
        count: item.count,
        toolTipValue: ''
      });
    });
    StatList.push({
      text: 'Total Documents | Pages',
      count: `${truncateCountAboveMillions(
        pediatricsFDAState?.stats?.pdf_stats?.total_document_count
      )} | ${truncateCountAboveMillions(pediatricsFDAState?.stats?.pdf_stats?.total_page_count)}`,
      toolTipValue: `${formatCount(
        pediatricsFDAState?.stats?.pdf_stats?.total_document_count
      )} | ${formatCount(pediatricsFDAState?.stats?.pdf_stats?.total_page_count)}`
    });
    return StatList;
  }, [pediatricsFDAState]);

  const getLayer1Charts = useMemo(() => {
    const writtenRequestsGraph = VISUALIZE_CHART_MAPPING['fda-written-requests']?.layer1;
    if (!writtenRequestsGraph || !pediatricsFDAState?.stats?.year_graph) {
      return writtenRequestsGraph;
    }

    const updatedGraph = writtenRequestsGraph.map((graph: any) => ({
      ...graph,
      data: pediatricsFDAState?.stats?.year_graph
    }));
    return updatedGraph;
  }, [pediatricsFDAState?.stats?.year_graph]);

  const getLayer2Charts = useMemo(() => {
    const writtenRequestsGraph = VISUALIZE_CHART_MAPPING['fda-written-requests']?.layer2;
    if (!writtenRequestsGraph) {
      return writtenRequestsGraph;
    }

    writtenRequestsGraph.forEach(graph => {
      if (graph?.id === 'formulations' && pediatricsFDAState?.stats?.formulations_stats) {
        // eslint-disable-next-line no-param-reassign
        graph.data = pediatricsFDAState?.stats?.formulations_stats;
      }
      if (graph?.id === 'route' && pediatricsFDAState?.stats?.route_stats) {
        // eslint-disable-next-line no-param-reassign
        graph.data = pediatricsFDAState?.stats?.route_stats;
      }
    });
    return writtenRequestsGraph;
  }, [pediatricsFDAState]);

  return (
    <>
      <SubHeader
        title='FDA Written Requests'
        subTitle='Showing list of Written Requests published by FDA'
        stats={pediatricStats}
        handleVisualizeOpen={handleVisualizeOpen}
      />

      <Box>
        {!isLoading && (
          <Popover
            open={!!reportChoicesOpenAnchorElement}
            anchorEl={reportChoicesOpenAnchorElement}
            onClose={() => setReportChoiceAnchorElement(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            sx={{
              '& .MuiPopover-paper': {
                borderRadius: '16px !important',
                backgroundColor: 'none'
              }
            }}>
            <Box>
              <ReportWidgets
                widgets={['pie-widget', 'bar-widget', 'table-widget']}
                title=''
                dataSource={{
                  source: 'fda-written-requests',
                  moduleName: 'written_requests',
                  disableSelectionSource: true,
                  filters: pediatricsFDAState.availableFilters,
                  filterLabelList: getFiltersLabelList()
                }}
                pathMetaData={{
                  module: 'fda_written_requests',
                  href: window.location.href
                }}
                setReportWidgetClose={setReportChoiceAnchorElement}
              />
            </Box>
          </Popover>
        )}
        <PediatricsDetails
          pagination={pagination}
          setPagination={setPagination}
          sort={sort}
          setSort={setSort}
          sortFields={sortFields}
          getPediatricsFDAData={getPediatricsFDAData}
          isFilterUpdated={isFilterUpdated}
          setIsFilterUpdated={setIsFilterUpdated}
          getDatawithDataGridFilter={getDatawithDataGridFilter}
          dataGridFilter={dataGridFilter}
        />
      </Box>

      {ariaModalOpen && (
        <AriaDrawerCore
          applications={pediatricsFDAState.isFiltersApplied ? getAriaDocuments() : []}
          sources={{ us: ['wr'] }}
          groupId='entity_id'
          docCount={pediatricsFDAState?.stats.pdf_stats.total_document_count}
          docPageCount={pediatricsFDAState?.stats.pdf_stats.total_page_count}
          modalOpen={ariaModalOpen}
          setModalOpen={setAriaModalOpen}
          dispatch={dispatch}
        />
      )}

      <NewFilters
        data={pediatricsFDAFilters}
        applyFilters={handleFilterApply}
        isLoading={isLoading}
        selectedFilters={pediatricsFDAState.availableFilters}
      />

      <BottomDrawer
        isDrawerOpen={isVisualizeOpen}
        closeDrawer={closeDrawer}
        handleReportChoiceAnchorElement={setReportChoiceAnchorElement}>
        <Insights
          source='fda-written-requests'
          searchType='written_requests'
          appliedFilter={pediatricsFDAState?.availableFilters}
          statsList={getInsightStatsList}
          layer1Component={getLayer1Charts}
          layer2Component={getLayer2Charts}
          isLoading={isLoading}
        />
      </BottomDrawer>

      <Box sx={AskRiaButtonStyles.bottomFilterPosition}>
        <AskRiaButton
          iconId='aria'
          setOpenAriaSearch={setAriaModalOpen}
          text='Ask RIA'
          disabled={isLoading}
        />
      </Box>
    </>
  );
};

export default memo(PediatricsFDATabs);
