const styles: { [key: string]: any } = {
  textAlignment: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    fontWeight: 900
  }
};

export default styles;
