import React from 'react';

// Imports of MUI.
import { Modal, Paper, Box } from '@mui/material';
import { IconButton } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

// Import of styles.
import LandingStyles from '../../Landing/Landing.styles';

// Imports of components.
import PdfPreview from '../../PdfPreview';

const DownloadModal = props => {
  const classes = LandingStyles();
  return (
    <Modal open={props.open} className={classes.modal} onClose={props.onClose}>
      <Paper elavation={3} className={classes.modalContainer}>
        <Box display='flex' justifyContent='flex-end'>
          <IconButton onClick={props.onClose}>
            <CloseOutlinedIcon color='primary' fontSize='small' />
          </IconButton>
        </Box>
        <PdfPreview />
      </Paper>
    </Modal>
  );
};

export default DownloadModal;
