import React, { useCallback, useState } from 'react';
import { Tooltip, Box, IconButton, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import PDFCompare from './PDFCompare';
import { ThreeDotIcon, ComparisonIcon } from '../../../assets/svgs/Icons';
import { RoundedMenu } from '../../ui/Menu';
import styles from '../styles/Details.styles';

const LabelComparisionComponent: React.FC<any> = ({ value }) => {
  const [openPopup, setOpenPopup] = useState<Boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleButtonClick = useCallback(() => {
    setOpenPopup(true);
  }, [openPopup]);

  const handleLabelSelectionClose = useCallback(() => {
    setOpenPopup(false);
  }, [openPopup]);
  return (
    <>
      <Box>
        <IconButton size='small' onClick={handleClick}>
          <ThreeDotIcon />
        </IconButton>
        <RoundedMenu
          id='demo-customized-menu'
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button'
          }}
          anchorEl={anchorEl}
          open={open}
          sx={styles.roundedMenu}
          onClose={handleClose}>
          <Tooltip title={value.fulfilled_label_link === null ? 'Label is not available' : ''}>
            <MenuItem
              sx={styles.menuItem}
              onClick={handleButtonClick}
              disabled={value.fulfilled_label_link === null}>
              <ListItemIcon sx={styles.listItemIcon}>
                <ComparisonIcon sx={styles.svgIcon} />
              </ListItemIcon>
              <ListItemText sx={styles.listItemText}>Compare</ListItemText>
            </MenuItem>
          </Tooltip>
        </RoundedMenu>
      </Box>
      {openPopup ? (
        <PDFCompare open={openPopup} onClose={handleLabelSelectionClose} rowData={value} />
      ) : null}
    </>
  );
};

export default React.memo(LabelComparisionComponent);
