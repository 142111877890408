const getStyleFromAppliedFilter = (appliedStyle: Array<any>) => {
  const styleObject: any = {};
  const styleState = [...appliedStyle];
  styleState.forEach((item: any) => {
    if (item.type === 'multi-value-dropdown') {
      item.options?.forEach((opt: any) => {
        if (opt.key === item.value) {
          styleObject[item.id] = opt.value;
        }
      });
      // eslint-disable-next-line no-param-reassign
      item.customColor = false;
    } else {
      styleObject[item.id] = item.value;
    }
  });

  styleState.forEach((item: any) => {
    if (item.type === 'checkbox' && !item.value) {
      // Loop for the disabled keys by delete them from styleObject
      item.disabled.forEach((row: string) => {
        delete styleObject[row];
      });
    }
  });

  return styleObject;
};

export default getStyleFromAppliedFilter;
