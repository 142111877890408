import { createSvgIcon } from '@mui/material';

const WhatsNewHomeTabIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='46' height='42' viewBox='0 0 46 42' fill='none'>
    <path
      d='M23.6238 34.5081C23.6238 32.5773 23.6227 30.6465 23.6238 28.7157C23.6238 28.1187 23.7522 27.9749 24.3338 27.9738C27.8367 27.9687 31.3386 27.9738 34.8415 27.9697C35.306 27.9697 35.53 28.1619 35.529 28.6448C35.5228 32.5403 35.5238 36.4359 35.529 40.3314C35.529 40.7917 35.3605 41.0157 34.8631 41.0137C31.3438 41.0024 27.8234 41.0096 24.304 41.0065C23.743 41.0065 23.6248 40.8811 23.6238 40.2995C23.6207 38.3687 23.6227 36.4379 23.6227 34.5071L23.6238 34.5081Z'
      fill='currentColor'
    />
    <path
      d='M22.001 34.5379C22.001 36.4348 22.003 38.3306 22.001 40.2275C22.001 40.9057 21.8992 41.0085 21.2396 41.0095C17.788 41.0105 14.3365 41.0105 10.8859 41.0095C10.1923 41.0095 10.0958 40.9098 10.0947 40.2008C10.0947 36.408 10.0947 32.6143 10.0947 28.8215C10.0947 28.0796 10.2026 27.9727 10.963 27.9727C14.3632 27.9707 17.7633 27.9707 21.1635 27.9727C21.8962 27.9727 22.001 28.0775 22.002 28.7979C22.0041 30.7112 22.002 32.6256 22.002 34.5389L22.001 34.5379Z'
      fill='currentColor'
    />
    <path
      d='M9.04986 15.9782C9.48862 15.9042 9.89759 15.8303 10.3076 15.7665C11.0834 15.6463 11.8561 15.5014 12.636 15.4274C13.0964 15.3843 13.345 15.15 13.529 14.776C13.9883 13.8429 14.4445 12.9089 14.9161 11.982C14.984 11.8495 15.1319 11.757 15.2419 11.646C15.3364 11.759 15.4566 11.8577 15.5203 11.9861C15.9766 12.9007 16.4174 13.8224 16.8706 14.739C17.0596 15.1223 17.3175 15.3761 17.7974 15.4285C18.8137 15.5405 19.8227 15.7182 20.8338 15.8744C20.9962 15.8991 21.1544 15.9525 21.4144 16.0183C21.2305 16.2464 21.1143 16.4242 20.9664 16.5691C20.2738 17.2514 19.5812 17.9337 18.8712 18.5985C18.5239 18.9232 18.415 19.2798 18.5064 19.7463C18.7068 20.7667 18.8763 21.7932 19.0479 22.8187C19.0685 22.9431 19.0233 23.0787 19.0089 23.2092C18.8671 23.1928 18.7058 23.2144 18.5866 23.1537C17.6464 22.6708 16.7092 22.1806 15.7824 21.674C15.3827 21.4562 15.023 21.4685 14.6264 21.6864C13.7314 22.1806 12.8251 22.6533 11.9157 23.1219C11.777 23.1938 11.6002 23.1928 11.442 23.2257C11.4317 23.0643 11.3916 22.8979 11.4173 22.7427C11.5632 21.8508 11.7102 20.9588 11.8859 20.0721C12.0133 19.4267 11.9321 18.913 11.3988 18.4249C10.5984 17.6912 9.88731 16.8599 9.04883 15.9772L9.04986 15.9782Z'
      fill='currentColor'
    />
    <path
      d='M26.8225 12.618C26.3159 12.3601 25.9419 12.172 25.5719 11.9799C24.8763 11.6171 24.1786 11.2575 23.4881 10.8845C23.1397 10.6954 22.8171 10.7067 22.4687 10.8927C21.5357 11.3911 20.5965 11.8771 19.6543 12.358C19.5279 12.4228 19.3738 12.4341 19.233 12.469C19.2145 12.3097 19.16 12.1443 19.1847 11.9912C19.3398 11.014 19.495 10.0357 19.6861 9.06466C19.7889 8.54266 19.6728 8.14499 19.2772 7.77918C18.5373 7.09584 17.8242 6.38271 17.109 5.67369C17.0083 5.57402 16.9549 5.4281 16.8799 5.30274C17.0094 5.24314 17.1337 5.1486 17.2683 5.12805C18.2804 4.97083 19.2936 4.81978 20.3078 4.68311C20.7846 4.61941 21.1165 4.42622 21.3261 3.95765C21.7155 3.08627 22.1605 2.23956 22.5879 1.38565C22.6845 1.19247 22.8047 1.01161 22.9661 0.738281C23.1336 0.989008 23.2702 1.15342 23.3637 1.34044C23.7994 2.20976 24.2454 3.07497 24.642 3.96279C24.8516 4.43239 25.1866 4.62146 25.6644 4.68311C26.6457 4.80951 27.626 4.95131 28.6032 5.10339C28.7707 5.12908 28.9259 5.24108 29.0862 5.31301C28.9968 5.44968 28.929 5.60587 28.8159 5.7189C28.0781 6.45464 27.3362 7.18627 26.5831 7.9066C26.282 8.19431 26.1844 8.50567 26.2635 8.91978C26.4556 9.92474 26.617 10.9369 26.7845 11.947C26.8112 12.1083 26.804 12.2758 26.8225 12.62V12.618Z'
      fill='currentColor'
    />
    <path
      d='M34.6053 23.3872C33.923 23.0389 33.3825 22.7656 32.8451 22.4861C32.3149 22.2107 31.8093 21.8726 31.2534 21.6712C30.9441 21.5592 30.5054 21.5602 30.2094 21.6959C29.2805 22.1203 28.3937 22.6371 27.4854 23.1088C27.3302 23.1889 27.1535 23.2269 26.8545 23.3297C26.9881 22.5272 27.1011 21.8449 27.2151 21.1626C27.3056 20.6241 27.4535 20.0877 27.4659 19.5482C27.472 19.2482 27.3271 18.8742 27.1216 18.6522C26.4599 17.9401 25.7365 17.2845 25.0419 16.6032C24.8888 16.4532 24.7542 16.2837 24.5425 16.0484C24.7983 15.9723 24.9627 15.9035 25.1333 15.8757C26.1455 15.7164 27.1555 15.5397 28.1728 15.4267C28.6537 15.3732 28.9096 15.1163 29.0997 14.7341C29.5384 13.8473 29.9638 12.9543 30.4067 12.0696C30.4858 11.9124 30.6225 11.7839 30.7335 11.6411C30.8496 11.7716 31.0006 11.8836 31.0767 12.0347C31.5339 12.9317 31.9768 13.836 32.4197 14.7392C32.6108 15.1287 32.8615 15.3763 33.3424 15.4287C34.3597 15.5387 35.3698 15.7175 36.3819 15.8737C36.5453 15.8994 36.7036 15.9559 36.9574 16.0227C36.8001 16.2179 36.7015 16.3659 36.5782 16.4882C35.8486 17.2074 35.1191 17.9267 34.3772 18.6327C34.0802 18.9153 33.9497 19.2174 34.0309 19.6366C34.2138 20.5912 34.369 21.552 34.5262 22.5118C34.5642 22.7419 34.5693 22.9772 34.6043 23.3852L34.6053 23.3872Z'
      fill='currentColor'
    />
    <path
      d='M35.8965 25.9486C35.9468 25.85 36.0188 25.5684 36.1945 25.3906C38.5959 22.9666 41.0096 20.5559 43.4254 18.1453C43.8312 17.7394 44.4837 17.7281 44.8526 18.1C45.2451 18.4967 45.2267 19.0732 44.7858 19.515C42.3916 21.9154 39.9933 24.3117 37.5961 26.709C37.2837 27.0214 36.923 27.1683 36.4832 26.9947C36.0876 26.8385 35.9026 26.5405 35.8965 25.9486Z'
      fill='currentColor'
    />
    <path
      d='M10.0886 26.3008C9.81223 26.6153 9.62521 26.9513 9.33853 27.125C8.95217 27.3592 8.54423 27.2144 8.22363 26.8958C7.25671 25.9319 6.29286 24.965 5.32696 23.9991C3.98806 22.6591 2.64608 21.3212 1.30924 19.9792C0.788268 19.4562 0.72867 18.9024 1.13661 18.4944C1.56715 18.0628 2.15799 18.105 2.67074 18.6177C4.9293 20.8732 7.18889 23.1277 9.43614 25.3925C9.66529 25.6237 9.8225 25.9268 10.0866 26.3008H10.0886Z'
      fill='currentColor'
    />
  </svg>,
  'WhatsNewHomeTab'
);

export default WhatsNewHomeTabIcon;
