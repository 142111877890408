const sortProductComparator = (v1: any, v2: any) => {
  let value1 = '';
  let value2 = '';

  if (v1 instanceof Array) {
    value1 = v1[0] || '';
  } else {
    value1 = v1 || '';
  }

  if (v2 instanceof Array) {
    value2 = v2[0] || '';
  } else {
    value2 = v2 || '';
  }

  return value1.localeCompare(value2);
};

export default sortProductComparator;
