const actions = {
  SET_PMR_TABLE: 'SET_PMR_TABLE',
  SET_ISSUE_DATE_GRAPH: 'SET_ISSUE_DATE_GRAPH',
  SET_FULLFILLMENT_GARPH: 'SET_FULLFILLMENT_GARPH',
  SET_CATEGORY_GARPH: 'SET_CATEGORY_GARPH',
  SET_ISSUE_DATE: 'SET_ISSUE_DATE',
  SET_PMR_STATS: 'SET_PMR_STATS',
  SET_PMR_ERRORS: 'SET_PMR_ERRORS',
  SET_GRAPH_CHARTS: 'SET_GRAPH_CHARTS',
  SET_FILTERS: 'SET_FILTERS',
  RESET_FILTERS: 'RESET_FILTERS',
  SET_IS_FILTERS_APPLIED: 'SET_IS_FILTERS_APPLIED',
  SET_SEARCH: 'SET_SEARCH',
  RESET_SEARCH: 'RESET_SEARCH',
  SET_LOADING: 'SET_LOADING',
  SET_AVAILABLE_FILTERS: 'SET_AVAILABLE_FILTERS',
  SET_TOTAL_COUNT: 'SET_TOTAL_COUNT',
  SET_SORT: 'SET_SORT',
  SET_DATA_GRID_FILTERS: 'SET_DATA_GRID_FILTERS'
};

export default actions;
