// lodash
import { Dictionary, isEmpty } from 'lodash';

// muiv5
import {
  Box,
  Button,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  ThemeProvider,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ManageHiddenCardsDialogProps, SelectableCardData } from '../../types';
import SelectableManageCard from './SelectableManageCard';
import ExpandableSelectableApplicationGroup from './ExpandableSelectableApplicationGroup';
import removeQuotes from '../../utils/removeQuotes';
import themev5 from '../../../../themev5';
import { sortAndGroupApplications } from '../../utils/sortAndGroup';

const ManageHiddenCardsDialog = ({
  open,
  setOpen,
  isGroupByFilterApplied,
  hiddenApplications,
  hiddenApplicationsCount,
  selectHiddenApplication,
  restoreApplications,
  keyMapper,
  selectedGroupOption,
  selectedSortOption,
  handleParentMenuClose
}: ManageHiddenCardsDialogProps) => {
  const [applications, setApplications] = useState<Dictionary<any[]> | any[]>([]);

  const anyApplicationSelected = () => {
    return hiddenApplications?.some((app: any) => app.isChecked ?? false);
  };

  const handleRestore = () => {
    restoreApplications();
    setOpen(false);
    handleParentMenuClose?.();
  };

  const handleCheckUncheckAll = (checked: boolean) => {
    if (isGroupByFilterApplied) {
      Object.keys(applications).forEach((group: any) => {
        (applications as Dictionary<any[]>)[group].forEach((app: any) => {
          selectHiddenApplication({
            source: app.source,
            identifier: app.identifier,
            checked
          });
        });
      });
    } else {
      hiddenApplications?.forEach((app: any) => {
        selectHiddenApplication({ source: app.source, identifier: app.identifier, checked });
      });
    }
    handleRestore();
  };

  const handleClose = () => {
    handleCheckUncheckAll(false);
    setOpen(false);
    handleParentMenuClose?.();
  };

  useEffect(() => {
    const apps = sortAndGroupApplications(
      isGroupByFilterApplied,
      selectedGroupOption,
      selectedSortOption,
      hiddenApplications
    );
    setApplications(apps);
  }, [isGroupByFilterApplied, hiddenApplications, selectedGroupOption?.id, selectedSortOption?.id]);
  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Dialog
        maxWidth={false}
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'>
        <Stack
          sx={{
            width: hiddenApplicationsCount === 0 ? 400 : '80vw',
            height: '100%',
            minHeight: '20vh',
            maxHeight: '80vh',
            overflowY: 'auto'
          }}>
          <DialogTitle id='responsive-dialog-title'>Manage Hidden Cards</DialogTitle>
          <DialogContent>
            {isEmpty(applications) && (
              <DialogContentText>
                <Typography variant='subtitle2' mt={3}>
                  No applications were hidden
                </Typography>
              </DialogContentText>
            )}
            {!isGroupByFilterApplied ? (
              <Grid container spacing={2.5} pt={2} maxWidth='lg-xl' m='auto'>
                {Array.isArray(applications) &&
                  applications?.map((data: SelectableCardData, index: number) => (
                    <Grid item xs={12} md={6} lg={3} xl={3} key={data.identifier}>
                      <Box>
                        <SelectableManageCard
                          data={data}
                          showDottedTitle={
                            data.groupTitle?.toLowerCase() ===
                            applications[index - 1]?.groupTitle?.toLowerCase()
                          }
                          onCheck={selectHiddenApplication}
                        />
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            ) : (
              Object.keys(applications).map((group: any) => (
                <ExpandableSelectableApplicationGroup
                  key={group}
                  group={removeQuotes(group)}
                  keyMapper={keyMapper}
                  applications={(applications as Dictionary<any[]>)[group]}
                  handleCheck={selectHiddenApplication}
                />
              ))
            )}
          </DialogContent>
          <DialogActions>
            <Stack direction='row' spacing={3} marginX={3} marginY={1}>
              {!isEmpty(applications) && (
                <Button
                  onClick={() => handleCheckUncheckAll(true)}
                  variant='contained'
                  sx={{ color: '#fff' }}
                  autoFocus>
                  Restore All
                </Button>
              )}
              {anyApplicationSelected() ? (
                <Button
                  onClick={handleRestore}
                  variant='contained'
                  sx={{ color: '#fff' }}
                  autoFocus>
                  Restore
                </Button>
              ) : (
                <Button onClick={handleClose} variant='contained' sx={{ color: '#fff' }}>
                  Close
                </Button>
              )}
            </Stack>
          </DialogActions>
        </Stack>
      </Dialog>
    </ThemeProvider>
  );
};

export default ManageHiddenCardsDialog;
