import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { CardData } from '../SearchResults/types';
import {
  createSourceModulePayloadMapping,
  createSourceModuleDropdownMapping,
  getDefaultReg360SourceDropDown,
  getReg360SourceDropdown,
  extractSourceDropdown
} from '../Home/utils';
import PopupSearchBarWithResults from '../../components/CustomSearchBar/PopupSearchBarWithGenericResults';
import { SourceDropdown } from '../Home/types';
import { decodeBase64ToObject, encodeObjectToBase64 } from '../../utils/encodeDecodeObject';

const Reg360SearchBar = ({ modalOpen, setModalOpen }: any) => {
  const history = useHistory();
  const { search: locationSeach } = useLocation();
  const [selectedSources, setSelectedSources] = useState<SourceDropdown[]>([]);

  const reg360SourceDropDown = useMemo(() => getReg360SourceDropdown(), []);
  const reg360DefaultSourceDropDown = useMemo(() => getDefaultReg360SourceDropDown(), []);

  const handleReg360 = (data: CardData) => {
    const { identifier, source } = data;
    const { application_type: type } = data as any;

    const module = 'core';
    const applicationNumber = encodeURIComponent(identifier);
    try {
      const convertedSelectedSources = createSourceModulePayloadMapping(selectedSources);
      const payloadVal = {
        source: convertedSelectedSources
      };
      const encodedPayload = encodeObjectToBase64(payloadVal) ?? '';
      const navigation = JSON.parse((localStorage.getItem('navigation') as any) ?? '{}');
      if (source?.toLowerCase() === 'us') {
        navigation.regulatory = {
          url: `/regulatory360/${module}/${applicationNumber}/${source}?type=${type}&payload=${encodedPayload}`,
          value: `Regulatory360:${type}${applicationNumber}&payload=${encodedPayload}`
        };
      } else {
        navigation.regulatory = {
          url: `/regulatory360/${module}/${applicationNumber}/${source}&payload=${encodedPayload}`,
          value: `Regulatory360:${applicationNumber}`
        };
      }
      localStorage.setItem('navigation', JSON.stringify(navigation));
      if (source?.toLowerCase() === 'us') {
        history.push(
          `/regulatory360/${module}/${applicationNumber}/${source?.toLowerCase()}?type=${type}&tabname=approvalHistory&payload=${encodedPayload}`
        );
      } else {
        history.push(
          `/regulatory360/${module}/${applicationNumber}/${source}?tabname=approvalHistory&payload=${encodedPayload}`
        );
      }
      window.location.reload();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  useEffect(() => {
    try {
      const urlParams = new URLSearchParams(locationSeach);
      const payload = urlParams.get('payload') ?? null;
      const backToSearch = urlParams.get('backTo') ?? null;
      let selectedSourcesFound = false;
      if (payload) {
        const decoded: any = decodeBase64ToObject(payload);
        if (decoded?.source) {
          const selectedSourcesFromParams = createSourceModuleDropdownMapping(decoded.source);
          const newSelectedSources = extractSourceDropdown(
            reg360SourceDropDown,
            selectedSourcesFromParams
          );
          setSelectedSources(newSelectedSources);
          selectedSourcesFound = true;
        }
      } else if (backToSearch) {
        const decodedBackToSearch: any = decodeBase64ToObject(backToSearch);
        const decodedSplit = decodedBackToSearch?.path.split('/');
        const pathValue = decodedSplit?.[decodedSplit.length - 1];
        const decodedPath: any = decodeBase64ToObject(pathValue);
        if (decodedPath?.source) {
          const selectedSourcesFromParams = createSourceModuleDropdownMapping(decodedPath.source);
          const newSelectedSources = extractSourceDropdown(
            reg360SourceDropDown,
            selectedSourcesFromParams
          );
          setSelectedSources(newSelectedSources);
          selectedSourcesFound = true;
        }
      }
      if (!selectedSourcesFound) {
        setSelectedSources(reg360DefaultSourceDropDown);
      }
    } catch (err) {
      setSelectedSources(reg360DefaultSourceDropDown);
    }
  }, [locationSeach]);

  return (
    <PopupSearchBarWithResults
      title='Regulatory 360'
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      sources={reg360SourceDropDown}
      selectedSources={selectedSources}
      setSelectedSources={setSelectedSources}
      handleCardClick={handleReg360}
      type='reg360'
    />
  );
};

export default React.memo(Reg360SearchBar);
