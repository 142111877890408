import { useContext } from 'react';
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';

const useChatRIAConfig = () => {
  const { dispatch } = useContext(GlobalStore) as any;

  const deleteAllChatRIAConfig = () => {
    dispatch({ type: GlobalActions.SET_APPLICATION_SOURCE, value: '' });
    dispatch({ type: GlobalActions.SET_APPLICATION_LIST, value: [] });
    dispatch({ type: GlobalActions.SET_APPLICATION_NUMBER, value: '' });
    dispatch({ type: GlobalActions.SET_ARIA_DOCUMENT, value: {} });
    dispatch({ type: GlobalActions.SET_APPLICATION_NAME, value: '' });
  };

  return {
    deleteAllChatRIAConfig
  };
};

export default useChatRIAConfig;
