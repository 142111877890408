import { ThemeProvider } from '@mui/material/styles';
import { memo, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import Store from '../../store';
import Actions from '../../store/actions';
import ComparisonDialog, {
  ApplicationProps,
  ModeOfComparisonDialog
} from '../../components/Comparison/ComparisonDialog';
import SourceWiseResult from './components/SourceWiseResult';
import { menuList } from './const';
import ComparisonSearch from './components/ComparisonSearch';

// mui v5
import themev5 from '../../themev5';
import classes from './styles/Index.style';
import StatGraphs from './components/StatsGraph';
import GuideTour from './components/GuideTour';
import SourceSwitch from './components/SourceSwitch';

const ComparisonPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const { dispatch }: any = useContext(Store);
  const { sources, category, term }: { sources: string; term: string; category: string } =
    useParams();
  const [source1, , source2] = sources.split('_');
  const [selectedCards, setSelectedCards]: any = useState([]);
  const sourceDetail1 = menuList.filter(source => source.id === source1.toLowerCase());
  const sourceDetail2 = menuList.filter(source => source.id === source2.toLowerCase());
  const [selectedSourceList, setSelectedSourceList] = useState([
    ...sourceDetail1,
    ...sourceDetail2
  ]);

  const handleSourceChange = (
    newSource: any,
    currentSource: any,
    isSourceWiseResult: boolean = true
  ) => {
    const selectedSource = menuList.find(item => item.sourceName === newSource);
    // Replace currentSource with newSource
    const updatedSources = sources.replace(
      new RegExp(currentSource.toLowerCase(), 'g'),
      newSource.toLowerCase()
    );
    const newUrl = isSourceWiseResult
      ? `/comparison/${updatedSources}/${category}/${term}${search}`
      : `/comparison/${updatedSources}`;
    history.push(newUrl);
    if (!selectedSource) {
      return; // If the selected source is not found in sourceDetailsList, return early.
    }

    setSelectedSourceList(prevSelectedSourceList => {
      const indexToReplace = prevSelectedSourceList.findIndex(
        item => item.sourceName === currentSource
      );
      if (indexToReplace === -1) {
        return prevSelectedSourceList; // If currentSource is not found, return the original list unchanged.
      }

      const newSelectedSourceList = [...prevSelectedSourceList];
      newSelectedSourceList[indexToReplace] = selectedSource;
      return newSelectedSourceList;
    });
  };

  useEffect(() => {
    dispatch({ type: Actions.SET_MODULE, value: 'comparison' });
  }, [source1, source2]);

  const handleCardSelection = (card: any, source: string) => {
    const sourceToUse =
      source?.toLowerCase() === 'hpra' && card.source_index === 'ema' ? 'eu' : source;
    const addedCard = { ...card, source: sourceToUse };
    // if user is selecting the card from the same source, then we should slect the newCard i.e. cureentCard
    const sourceAlreadySelected = selectedCards.findIndex((crd: any) => crd.source === source) >= 0;
    if (sourceAlreadySelected) {
      setSelectedCards([addedCard]);
      return;
    }
    const cardIndex = selectedCards.findIndex(
      (crd: any) =>
        (crd.application_number && crd.application_number === card.application_number) ||
        (crd.product_number && crd.product_number === card.product_number) ||
        (crd.vin && crd.vin === card.vin) ||
        (crd.yj_code && crd.yj_code === card.yj_code)
    );

    if (cardIndex > -1) {
      const cards = [...selectedCards];
      cards.splice(cardIndex, 1);
      setSelectedCards(cards);
    } else {
      const cardsForComparison = [...selectedCards, addedCard];
      setSelectedCards(cardsForComparison);
    }
  };

  const handleLabelSelectionClose = () => {
    setSelectedCards([]);
  };

  const getApplicationNameText = (application: any) => {
    return `${application.application_number || application.product_number || application.vin}-${
      Array.isArray(application?.trade_name)
        ? application.trade_name[0].toUpperCase()
        : application.trade_name.toUpperCase()
    }`;
  };
  const getApplicationProps = () => {
    if (selectedCards.length === 2) {
      // const selectedToCompare = sortUSFirst(selectedCards);
      if (selectedCards[0].source.toLowerCase() !== source1) {
        [selectedCards[0], selectedCards[1]] = [selectedCards[1], selectedCards[0]];
      }
      const firstCard: ApplicationProps = {
        source: selectedCards[0].source.toLowerCase(),
        application_type: selectedCards[0].application_type,
        application_number:
          selectedCards[0].application_number ||
          selectedCards[0].product_number ||
          selectedCards[0].vin ||
          selectedCards[0].yj_code,
        application_name: getApplicationNameText(selectedCards[0])
      };
      const secondCard: ApplicationProps = {
        source: selectedCards[1].source.toLowerCase(),
        application_type: selectedCards[1].application_type,
        application_number:
          selectedCards[1].application_number ||
          selectedCards[1].product_number ||
          selectedCards[1].vin ||
          selectedCards[1].yj_code,
        application_name: getApplicationNameText(selectedCards[1])
      };
      return [firstCard, secondCard];
    }
    return [];
  };

  const selectedApplicationsCard = getApplicationProps();
  const mode: ModeOfComparisonDialog = {
    child_labelList_dependent_on_parent: false,
    section_binding: false,
    allsections: true,
    default_section_all_section: true,
    sortOnDate: false
  };

  return (
    <ThemeProvider theme={themev5}>
      <ComparisonSearch />
      <Box height='88vh'>
        {/* If search term present, then show result content */}
        {term ? (
          <Box id='boxoutline' display='flex' flexDirection='row' height='100%'>
            {/* FDA Results section */}
            <Box flex='1' overflow='hidden' sx={classes.shadowborder} pl={2}>
              <SourceWiseResult
                selectedSourceList={[...selectedSourceList]}
                sourceDescription={selectedSourceList[0]?.sourceDescription}
                sourceName={selectedSourceList[0]?.sourceName}
                selectedCards={selectedCards}
                handleCardSelection={handleCardSelection}
                searchTerm={term}
                handleSourceChange={handleSourceChange}
              />
            </Box>
            {/* EMA Results section */}
            <Box flex='1' overflow='hidden' pl={1}>
              <SourceWiseResult
                selectedSourceList={[...selectedSourceList]}
                sourceDescription={selectedSourceList[1]?.sourceDescription}
                sourceName={selectedSourceList[1]?.sourceName}
                selectedCards={selectedCards}
                handleCardSelection={handleCardSelection}
                searchTerm={term}
                handleSourceChange={handleSourceChange}
              />
            </Box>
          </Box>
        ) : (
          // If it is home page, render the graphs
          <Box
            id='boxoutline'
            width='100%'
            height='100%'
            display='flex'
            justifyContent='center'
            fontSize={22}>
            <Box flex='1' sx={classes.shadowborder} pl={2}>
              <SourceSwitch
                selectedSourceList={[...selectedSourceList]}
                sourceDescription={selectedSourceList[0].sourceDescription}
                sourceName={selectedSourceList[0].sourceName}
                handleSourceChange={handleSourceChange}
              />
              <Box pl='16px' pr='16px'>
                <StatGraphs source={source1} />
              </Box>
            </Box>
            <Box flex='1' sx={classes.shadowborder}>
              <SourceSwitch
                selectedSourceList={[...selectedSourceList]}
                sourceDescription={selectedSourceList[1].sourceDescription}
                sourceName={selectedSourceList[1].sourceName}
                handleSourceChange={handleSourceChange}
              />
              <Box pl='16px' pr='16px'>
                <StatGraphs source={source2} />
              </Box>
            </Box>
          </Box>
        )}

        {selectedCards.length === 2 ? (
          <ComparisonDialog
            open={selectedCards.length === 2}
            onClose={handleLabelSelectionClose}
            applications={selectedApplicationsCard}
            mode={mode}
          />
        ) : null}
      </Box>
      {selectedCards.length !== 2 && <GuideTour />}
    </ThemeProvider>
  );
};

export default memo(ComparisonPage);
