const styles = {
  chatRiaButton: {
    display: 'flex',
    ml: '10px',
    width: 'fit-content',
    borderRadius: '16px',
    height: '32px',
    padding: '6px 16px',
    border: '1px solid',
    borderColor: 'blue.backgroundLight',
    backgroundColor: 'white.main',
    textTransform: 'capitalize',
    color: 'blue.backgroundLight',
    fontSize: '12px',
    fontWeigth: '400'
  },
  pdfContainer: {
    borderRadius: 5,
    mb: 10,
    background: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
    maxHeight: '99vh',
    '& > div#pdfBox': { height: '86%', width: '96%' }
  },
  snackBarContainer: {
    top: `50px !important`,
    left: '0px !important'
  },
  titleText: {
    fontFamily: 'Mulish',
    fontSize: 14,
    fontWeight: 700,
    textOverflow: 'ellipsis'
  },
  chatRiaIcon: {
    width: '16px',
    height: '16px',
    p: '2.667px 2.666px 2.667px 2.667px'
  },
  closeIcon: { width: '18px', height: '18px', cursor: 'pointer', color: 'grey.600' },
  drawer: {
    '& .MuiDrawer-paperAnchorRight': {
      top: 0
    },
    '& >.MuiBackdrop-root': {
      backgroundColor: 'rgba(0,0,0,0.25)'
    }
  },
  stackWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    px: 2,
    py: 1.5,
    backgroundColor: 'primary.600',
    height: '5.5%'
  },
  iconButton: {
    border: '2px solid',
    width: '24px',
    height: '24px',
    color: 'white.main',
    borderColor: 'white.main',
    padding: '3px !important',
    ml: 1,
    '& > svg': {
      fontSize: 20
    },
    '&:hover': {
      color: 'primary.main',
      borderColor: 'primary.main',
      backgroundColor: 'white.main'
    }
  },
  timelineWrapper: {
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
    alignSelf: 'stretch',
    backgroundColor: 'white.smokeDark'
  },
  sponsorHeading: {
    overflow: 'hidden',
    color: 'gray.650',
    textOverflow: 'ellipsis',
    fontFamily: 'Mulish',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '120%',
    textTransform: 'capitalize',
    marginBottom: '4px'
  },
  sponsorHeadingText: {
    overflow: 'hidden',
    color: 'gray.800',
    textOverflow: 'ellipsis',
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '120%',
    textTransform: 'capitalize',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 8, // Limit text to 8 lines
    maxHeight: '9.6em' // Ensures that no more than 8 lines are shown based on line height
  },
  pipNumberText: {
    color: 'gray.800',
    fontSize: '13px',
    fontWeight: '600',
    lineHeight: '120%',
    mr: 1.5
  },
  latestPIPText: {
    color: 'gray.800',
    fontSize: '13px',
    fontWeight: '600',
    lineHeight: '120%',
    marginLeft: '4px'
  },
  latestPIPBox: {
    borderRight: '1px solid',
    borderRightColor: 'gray.300',
    mr: 1.5
  },
  otherPIPText: {
    color: 'primary.700',
    fontSize: 13,
    fontWeight: 600,
    cursor: 'pointer',
    lineHeight: '120%'
  },
  timelineTitle: {
    fontSize: 20,
    fontWeight: 700,
    color: 'gray.0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
};

export default styles;
