import React from 'react';
import { createSvgIcon } from '@mui/material';

const ThinArrowDownIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'>
    <path
      d='M8.50014 10.3332C8.32507 10.3335 8.15166 10.2992 7.98989 10.2323C7.82811 10.1653 7.68116 10.0671 7.55748 9.94319L4.02881 6.41386L4.97148 5.47119L8.50014 8.99986L12.0288 5.47119L12.9715 6.41386L9.44281 9.94252C9.31918 10.0666 9.17225 10.1649 9.01047 10.232C8.8487 10.299 8.67526 10.3334 8.50014 10.3332Z'
      fill='#424242'
    />
  </svg>,
  'UpArrowIcon'
);

export default ThinArrowDownIcon;
