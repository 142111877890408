import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton, Link, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';

import { isEmpty } from 'lodash';
import { getSearchParams } from '../../../hooks/useBreadcrumbs';
import { encodeJSONtoString } from '../../../helpers/backToEncoding';
import { elasticQueryToAdvancedSearch } from '../../Header/utils/advanceSearchHelpers';
import { ThreeDotIcon, Reg360Icon, OpenLinkIcon } from '../../../assets/svgs/Icons';
import { RoundedMenu } from '../../ui/Menu';
import styles from '../Datagrid.styles';
import SubscriptionActions from '../../Subscription/SubscriptionActions';

// store
import ResultsStore from '../../../store/SearchResults';
import ResultsAction from '../../../store/SearchResults/actions';

const DatagridActionsMenu = (props: any) => {
  const history = useHistory();
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const { row, colDef } = props;
  const { nct_id: identifier, application_type: type, euct_id: euIdentifier } = row;

  const {
    location: { pathname: locationPathName, search: locationSearch }
  } = window;
  const urlParams = new URLSearchParams(locationSearch);
  const advSearchData = elasticQueryToAdvancedSearch(urlParams);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReg360 = () => {
    // eslint-disable-next-line react/destructuring-assignment
    const { source } = props.colDef;

    const module = 'core';
    const backToObject = {
      module,
      path: locationPathName,
      search: getSearchParams(locationSearch || ''),
      name: 'Search Results'
    };
    const backToString = encodeJSONtoString(backToObject);
    const applicationNumber = encodeURIComponent(identifier);
    let dateText = '';
    if (advSearchData?.dateRange?.startDate && advSearchData?.dateRange?.endDate) {
      dateText = `&startDate=${advSearchData.dateRange.startDate}&endDate=${advSearchData.dateRange.endDate}`;
    }
    try {
      const navigation = JSON.parse((localStorage.getItem('navigation') as any) ?? '{}');
      if (source?.toLowerCase() === 'us') {
        navigation.regulatory = {
          url: `/regulatory360/${module}/${applicationNumber}/${source}?type=${type}&backTo=${backToString}${dateText}`,
          value: `Regulatory360:${type}${applicationNumber}`
        };
      } else {
        navigation.regulatory = {
          url: `/regulatory360/${module}/${applicationNumber}/${source}?backTo=${backToString}${dateText}`,
          value: `Regulatory360:${applicationNumber}`
        };
      }
      localStorage.setItem('navigation', JSON.stringify(navigation));
      if (source?.toLowerCase() === 'us') {
        history.push(
          `/regulatory360/${module}/${applicationNumber}/${source?.toLowerCase()}?type=${type}&tabname=approvalHistory&backTo=${backToString}${dateText}`
        );
      } else {
        history.push(
          `/regulatory360/${module}/${applicationNumber}/${source}?tabname=approvalHistory&backTo=${backToString}${dateText}`
        );
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const setIsSubscribed = ({ isSubscribed }: { isSubscribed: boolean }) => {
    const source = colDef?.source;
    if (
      !source ||
      isEmpty(resultsState.ctResults?.results) ||
      !Array.isArray(resultsState.ctResults?.results)
    )
      return;

    const appIndex = resultsState.ctResults.results.findIndex(
      (app: any) => app.source_index === source && app?.nct_id === identifier
    );
    if (appIndex === -1) return;
    const updatedAppList = [...resultsState.ctResults.results];
    updatedAppList[appIndex] = { ...updatedAppList[appIndex], is_subscribed: isSubscribed };
    resultsDispatch({
      type: ResultsAction.SET_CT_RESULTS,
      value: { ...resultsState.ctResults, results: updatedAppList }
    });
  };

  return (
    <Box>
      <IconButton size='small' onClick={handleClick}>
        <ThreeDotIcon />
      </IconButton>
      <RoundedMenu
        id='demo-customized-menu'
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        anchorEl={anchorEl}
        open={open}
        sx={styles.roundedMenu}
        onClose={handleClose}>
        {colDef?.source !== 'euctr' && (
          <MenuItem sx={styles.menuItem} onClick={handleReg360}>
            <ListItemIcon sx={styles.listItemIcon}>
              <Reg360Icon sx={styles.svgIcon} />
            </ListItemIcon>
            <ListItemText sx={styles.listItemText}>Open Trial 360&deg;</ListItemText>
          </MenuItem>
        )}
        {colDef?.source === 'ct' && (
          <SubscriptionActions
            invokedBy='application'
            isButton={false}
            isSubscribed={row?.is_subscribed ?? false}
            source={colDef?.source}
            id={identifier}
            setSubscriptionInHook={setIsSubscribed}
            handleMenuClose={handleClose}
          />
        )}
        <Link
          href={
            colDef?.source === 'euctr'
              ? `https://www.clinicaltrialsregister.eu/ctr-search/search?query=${euIdentifier}`
              : `https://clinicaltrials.gov/ct2/show/${identifier}`
          }
          target='_blank'
          onClick={handleClose}
          sx={{ textDecoration: 'none' }}>
          <MenuItem sx={styles.menuItem}>
            <ListItemIcon sx={styles.listItemIcon}>
              <OpenLinkIcon sx={styles.svgIcon} />
            </ListItemIcon>
            <ListItemText sx={styles.listItemText}>
              {colDef?.source === 'euctr'
                ? 'Open EU Clinical Trials Register page'
                : 'Open CT Gov page'}
            </ListItemText>
          </MenuItem>
        </Link>
      </RoundedMenu>
    </Box>
  );
};

export default DatagridActionsMenu;
