import React from 'react';
// MATERIAL UI IMPORTS
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import Tooltip from '@mui/material/Tooltip';
// external libs
// styles
import CardsStyles from '../styles/Cards.styles';

const Cards = ({ data }) => {
  const classes = CardsStyles();
  let formatValue = value => `${Number(value).toFixed(0)}`;
  return (
    <Box display='flex' flexDirection='column' justifyContent=''>
      {data ? (
        <Typography align='left' className={classes.headings}>
          Overview
        </Typography>
      ) : (
        <Skeleton height={16} width={100} className={classes.headings} />
      )}
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-around'
        className={classes.cards}>
        {data ? (
          data &&
          data.map(item => {
            return (
              <Paper elevation={2} className={classes.paper} key={item.id}>
                <Box
                  padding={1}
                  display='flex'
                  flexDirection='column'
                  justifyContent='space-evenly'
                  alignItems='center'
                  className={classes.cardContent}>
                  {/* <IconButton className={classes.paperIcon}>
                      <AddIcon fontSize="small" />
                    </IconButton> */}
                  {/* <SummaryIcon /> */}
                  <Typography className={classes.cardText} gutterBottom>
                    {item.id}
                  </Typography>
                  <Tooltip title={item?.toolTipValue ? item?.toolTipValue : ''}>
                    <Typography className={classes.number}>{item.count}</Typography>
                  </Tooltip>
                </Box>
              </Paper>
            );
          })
        ) : (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='space-around'
            className={classes.cards}>
            <Skeleton className={classes.paper} />
            <Skeleton className={classes.paper} />
            <Skeleton className={classes.paper} />
            <Skeleton className={classes.paper} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Cards;
