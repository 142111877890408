import { memo, useEffect, useState, MouseEvent } from 'react';
import { cloneDeep } from 'lodash';
import { Grow, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';

import { VIEW_AS_OPTIONS } from '../constants';
import styles from '../styles/Buttons.styles';

/* 
This component is used to toggle between grid and list view of the search results.
*/
const ResultViewTypeButton = ({
  viewType,
  handleViewToggle
}: {
  viewType: 'grid' | 'list';
  // eslint-disable-next-line no-shadow, no-unused-vars
  handleViewToggle: (viewType: 'grid' | 'list') => void;
}) => {
  const [viewOptions, setViewOptions] = useState<any>(cloneDeep(VIEW_AS_OPTIONS));

  const handleViewToggleWrapper = (_event: MouseEvent<HTMLElement>, value: string | null) => {
    if (value === null) return;
    const newValue = value === 'grid' ? 'grid' : 'list';
    const viewOpts = viewOptions.map((opt: any) => ({
      ...opt,
      selected: newValue === opt.id
    }));
    setViewOptions(viewOpts);
    handleViewToggle?.(newValue);
  };

  useEffect(() => {
    const viewOpts = viewOptions.map((opt: any) => ({
      ...opt,
      selected: viewType === opt.id
    }));
    setViewOptions(viewOpts);
  }, [viewType]);

  return (
    <ToggleButtonGroup
      size='small'
      value={viewType}
      exclusive
      onChange={handleViewToggleWrapper}
      aria-label='view as'
      sx={styles.viewTypeButton}>
      {viewOptions.map((option: any) => (
        <ToggleButton
          key={option.id}
          value={option.id}
          aria-label={option.label}
          sx={styles.viewTypeButtonToggle}>
          <Tooltip title={option.id !== viewType ? `Switch to ${option.label} view` : ''}>
            <Stack direction='row' spacing='5px' alignItems='center'>
              <option.icon sx={styles.viewTypeIcon} />
              {option.id === viewType && (
                <Grow in={option.id === viewType}>
                  <Typography>{option.label}</Typography>
                </Grow>
              )}
            </Stack>
          </Tooltip>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default memo(ResultViewTypeButton);
