import React, { useEffect, useContext } from 'react';
import { Divider, Stack, Box, Typography, Tooltip, Popover, IconButton, Chip } from '@mui/material';
import comparisonClasses from './styles/LabelSelection.style';
import useDetectNonChromeBrowser from '../../hooks/useDetectNonChromeBrowser';
import { DownloadIcon, InfoIconSummary, SwapIcon } from '../../assets/svgs/Icons';
import {
  LABEL_COLOR,
  sourceFlagMapping,
  sourceFlagMappingTooltip,
  LABEL_TOOLTIP_TITLE
} from '../../pages/SearchResults/constants';
import draftableComparisonViewStyles from './styles/DraftableComparisonView.styles';
import ResultsStore from '../../store/SearchResults';
import Card from '../../pages/SearchResults/components/Card';

interface DraftableComparisonViewPropsType {
  viewerURL: string;
  parentApplicationNumber: string;
  childApplicationNumber: string;
  parentApplicationName?: string;
  childApplicationName?: string;
  downloadFile: () => void;
  showFooter?: boolean;
  parentApplicationSource?: string;
  childApplicationSource?: string;
  handleSwapLabels?: () => void;
  isSwitchOptionAvailable?: boolean;
  parentApplicationLabels?: any[];
  childApplicationLabels?: any[];
  fullWidth?: boolean;
}

const DraftableComparisonView: React.FC<DraftableComparisonViewPropsType> = ({
  viewerURL,
  downloadFile,
  parentApplicationNumber,
  childApplicationNumber,
  parentApplicationName = '',
  childApplicationName = '',
  showFooter = true,
  parentApplicationSource = '',
  childApplicationSource = '',
  handleSwapLabels = undefined,
  isSwitchOptionAvailable = false,
  parentApplicationLabels = [],
  childApplicationLabels = [],
  fullWidth = true
}) => {
  const { detectBrowserAndDisplayDisclaimer } = useDetectNonChromeBrowser();

  useEffect(() => {
    detectBrowserAndDisplayDisclaimer();
  }, []);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [comparisonApplicationsIndex, setComparisonApplicationsIndex] = React.useState<number>(0);

  const open = Boolean(anchorEl);

  const ParentFlagComponent = sourceFlagMapping[parentApplicationSource];
  const parentFlagTooltipTitle = sourceFlagMappingTooltip[parentApplicationSource];
  const ChildFlagComponent = sourceFlagMapping[childApplicationSource];
  const childFlagTooltipTitle = sourceFlagMappingTooltip[childApplicationSource];

  const { resultsState } = useContext(ResultsStore);

  const handlePopoverOpen = (event: any, name: string) => {
    setAnchorEl(event.currentTarget);

    const index = resultsState?.comparisonApplications?.findIndex(
      (item: any) => item?.title === name
    );

    if (index < 0) return;

    setComparisonApplicationsIndex(index);
  };

  const getApplicationChipTag = (labels: any[]) => {
    return (
      labels &&
      labels.map((label: string) => {
        const labelKey = label.toLowerCase();
        const labelColor = LABEL_COLOR[labelKey];
        const labelTooltipTitle = LABEL_TOOLTIP_TITLE[labelKey];
        return labelColor ? (
          <Tooltip
            title={labelTooltipTitle}
            disableInteractive
            disableFocusListener
            disableTouchListener>
            <Chip
              label={label}
              key={label}
              sx={{ ...draftableComparisonViewStyles.applicationChip, bgcolor: labelColor }}
            />
          </Tooltip>
        ) : null;
      })
    );
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack>
      {showFooter && (
        <Stack
          direction='row'
          spacing={2}
          divider={
            <Divider
              orientation='vertical'
              flexItem
              sx={draftableComparisonViewStyles.ctaSeparator}
            />
          }
          sx={draftableComparisonViewStyles.secondaryHeaderWrapper}
          justifyContent='flex-end'>
          <Stack
            direction='row'
            spacing={0.75}
            onClick={downloadFile}
            sx={draftableComparisonViewStyles.ctaWrapper}>
            <DownloadIcon sx={draftableComparisonViewStyles.downloadIcon} />
            <Typography sx={draftableComparisonViewStyles.downloadComparisonText}>
              Download Comparison
            </Typography>
          </Stack>
        </Stack>
      )}
      {resultsState?.comparisonApplications?.length > 0 && (
        <Popover
          id='mouse-over-popover'
          sx={draftableComparisonViewStyles.popoverContainer}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus>
          <Box sx={draftableComparisonViewStyles.cardWrapper}>
            <Card
              data={resultsState?.comparisonApplications?.[comparisonApplicationsIndex]}
              hideApplication={() => {}}
              triggerChatRIA={() => {}}
              setFavoriteInHook={() => {}}
              setInProjectInHook={() => {}}
              setSubscriptionInHook={() => {}}
              hideCardOptions
            />
          </Box>
        </Popover>
      )}
      {/* header titles for iframe pdfs */}
      <Box px='24px'>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={draftableComparisonViewStyles.headerWrapper}>
          <Stack direction='row' width='50%'>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              sx={{ ...draftableComparisonViewStyles.titleHeaderWrapper, mr: '30px' }}>
              <Stack direction='row' alignItems='center' spacing={0.5} height={21} width='inherit'>
                <Tooltip title={parentApplicationNumber ?? ''}>
                  <Typography
                    variant='subtitle2'
                    sx={{
                      ...comparisonClasses.titleTab,
                      ...(fullWidth && { maxWidth: 'inherit' })
                    }}
                    noWrap>
                    {parentApplicationNumber}
                  </Typography>
                </Tooltip>
                {parentApplicationSource && (
                  <Tooltip
                    title={parentFlagTooltipTitle}
                    disableInteractive
                    disableFocusListener
                    disableTouchListener>
                    <ParentFlagComponent sx={draftableComparisonViewStyles.flagIcon} />
                  </Tooltip>
                )}
                {parentApplicationLabels &&
                  parentApplicationLabels.length > 0 &&
                  getApplicationChipTag(parentApplicationLabels)}
              </Stack>
              {resultsState?.comparisonApplications?.length > 0 && (
                <Stack>
                  <InfoIconSummary
                    onMouseEnter={e => handlePopoverOpen(e, parentApplicationName ?? '')}
                    onMouseLeave={handlePopoverClose}
                    sx={draftableComparisonViewStyles.infoIcon}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
          {isSwitchOptionAvailable && handleSwapLabels && (
            <Stack direction='row'>
              <Stack direction='row' spacing={1}>
                <Tooltip title='Click to Switch windows' arrow>
                  <IconButton onClick={handleSwapLabels}>
                    <SwapIcon sx={draftableComparisonViewStyles.swapIcon} />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          )}
          <Stack direction='row' width='50%'>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              sx={{ ...draftableComparisonViewStyles.titleHeaderWrapper, ml: '30px' }}>
              <Stack direction='row' alignItems='center' spacing={0.5} height={21} width='inherit'>
                <Tooltip title={childApplicationNumber ?? ''}>
                  <Typography
                    variant='subtitle2'
                    sx={{
                      ...comparisonClasses.titleTab,
                      ...(fullWidth && { maxWidth: 'inherit' })
                    }}
                    noWrap>
                    {childApplicationNumber}
                  </Typography>
                </Tooltip>
                {childApplicationSource && (
                  <Tooltip
                    title={childFlagTooltipTitle}
                    disableInteractive
                    disableFocusListener
                    disableTouchListener>
                    <ChildFlagComponent sx={draftableComparisonViewStyles.flagIcon} />
                  </Tooltip>
                )}
                {childApplicationLabels &&
                  childApplicationLabels.length > 0 &&
                  getApplicationChipTag(childApplicationLabels)}
              </Stack>

              {resultsState?.comparisonApplications?.length > 0 && (
                <Stack>
                  <InfoIconSummary
                    onMouseEnter={e => handlePopoverOpen(e, childApplicationName ?? '')}
                    onMouseLeave={handlePopoverClose}
                    sx={draftableComparisonViewStyles.infoIcon}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>

        {/* draftable embedded iframe */}
        <Box sx={draftableComparisonViewStyles.iframeWrapper}>
          <iframe
            style={comparisonClasses.compareIFrame}
            title='compareDocuments'
            src={viewerURL}
            allowFullScreen
          />
        </Box>
      </Box>
    </Stack>
  );
};

DraftableComparisonView.defaultProps = {
  handleSwapLabels: undefined,
  isSwitchOptionAvailable: false,
  parentApplicationName: '',
  childApplicationName: '',
  showFooter: true,
  parentApplicationSource: '',
  childApplicationSource: '',
  parentApplicationLabels: [],
  childApplicationLabels: [],
  fullWidth: true
};

export default React.memo(DraftableComparisonView);
