import * as React from 'react';

import Typography from '@mui/material/Typography';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { COLOR_SCHEME_NUMBER } from '../../../../pages/ResultsPage/utils/constants';

// types
import styles from './DatagridColorNumber.styles';

interface GridCellExpandProps {
  value: string;
  type: string;
}

const GridCellExpand: React.FC<GridCellExpandProps> = ({ value, type }) => {
  return (
    <Typography
      align='center'
      variant='subtitle2'
      fontWeight='bold'
      sx={styles[COLOR_SCHEME_NUMBER[type?.toLowerCase() || 'ema']]}
    >
      {value}
    </Typography>
  );
};

const DatagridColorNumber: React.FC<GridRenderCellParams> = ({ value, row }) => {
  return <GridCellExpand value={value || ''} type={row.appl_type} />;
};

export default DatagridColorNumber;
