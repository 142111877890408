import React, { useContext, useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Tree, TreeNode } from 'react-organizational-chart';
import Box from '@mui/material/Box';
import uniqueId from 'lodash/uniqueId';
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import Actions from '../../../store/RegulatoryInformation/actions';
import regulatoryInformationAPI from '../../../api/pages/Regulatory360';
import styles from './Rld.styles';
import LabelComapreIcon from '../../../Images/brightness-and-contrast.svg';
import EmptyPage from '../../../components/EmptyPage/EmptyPage';

// custom components
import ComparisonDialog, {
  ApplicationProps,
  ModeOfComparisonDialog
} from '../../../components/Comparison/ComparisonDialog';

const Rld = () => {
  const { regulatoryState, regulatoryDispatch } = useContext(RegulatoryInformationStore);

  const [netDiagramRes, setnetDiagramRes] = useState<Array<any>>([]);
  const [parentNode, setparentNode] = useState();
  const [childNode, setchildNode] = useState();
  useEffect(() => {
    if (regulatoryState.rld_info.length === 0) {
      regulatoryDispatch({
        type: Actions.SET_LOADING,
        value: true
      });
      const getData = async () => {
        const res = await regulatoryInformationAPI(
          regulatoryState?.db,
          regulatoryState?.applicationDetails?.application_number,
          'rld'
        );
        if (res.status === 200) {
          regulatoryDispatch({ type: Actions.SET_RLD_INFO, value: res.data.body });
        }
        regulatoryDispatch({
          type: Actions.SET_LOADING,
          value: false
        });
      };
      getData();
    }
    const networkDiagram = regulatoryState.rld_info;
    networkDiagram.forEach(function (value) {
      if (value.rld_relation === 'parent') {
        setparentNode(value);
      }
      if (value.rld_relation === 'child') {
        setchildNode(value);
      }
    });
    setnetDiagramRes(networkDiagram);
  }, [regulatoryState.db, regulatoryState.applicationDetails, regulatoryState.rld_info]);

  const [applications, setApplications] = useState<any[]>([]);
  const [openComparisonDialog, setOpenComparisonDialog] = useState(false);

  const mode: ModeOfComparisonDialog = {
    child_labelList_dependent_on_parent: false,
    section_binding: false,
    allsections: true,
    default_section_all_section: true,
    parentRld: true,
    childRld: true
  };
  const handleRldCompare = (child: typeof childNode) => {
    const parnetRld: ApplicationProps = {
      source: 'us',
      // @ts-ignore
      application_type: parentNode.appl_type,
      // @ts-ignore
      application_number: parentNode.appl_num
    };
    const childRld: ApplicationProps = {
      source: 'us',
      // @ts-ignore
      application_type: child.appl_type,
      // @ts-ignore
      application_number: child.appl_num
    };
    setApplications([parnetRld, childRld]);
    setOpenComparisonDialog(true);
  };

  const handelRldCompareClose = () => {
    setOpenComparisonDialog(false);
  };

  if (regulatoryState.loading) {
    return <EmptyPage message='Loading RLD Information' />;
  }

  return (
    <Box>
      {openComparisonDialog && (
        <ComparisonDialog
          open={openComparisonDialog}
          onClose={handelRldCompareClose}
          applications={applications as Array<ApplicationProps>}
          mode={mode}
        />
      )}

      <Box>
        {netDiagramRes && (
          <Box sx={styles.networkDiagram}>
            {netDiagramRes?.map(item => {
              return (
                item.rld_relation === 'parent' && (
                  <Tree
                    key={uniqueId()}
                    lineWidth='2px'
                    lineColor='grey'
                    lineBorderRadius='10px'
                    label={
                      <Box sx={styles.styledDiv}>
                        {item.appl_type.includes('NDA') && (
                          <Box sx={styles.ndaNode}>{item.appl_num}</Box>
                        )}
                        {!item.appl_type.includes('NDA') && (
                          <Box sx={styles.ndaNode2}>{item.appl_num}</Box>
                        )}
                        <Box sx={styles.Node}>
                          {item.manufacturer} | {item.approval_year}
                        </Box>
                        <Box sx={styles.Node}>
                          {item.route} | {item.formulation}
                        </Box>
                        <Box sx={styles.Node}>Marketing Status: {item.marketing_status}</Box>
                      </Box>
                    }>
                    {netDiagramRes.map(item2 => {
                      return (
                        item2.rld_relation === 'child' && (
                          <TreeNode
                            key={uniqueId()}
                            label={
                              <Box sx={styles.styledDiv}>
                                <Box>
                                  {item2.appl_type.includes('NDA') && (
                                    <Box sx={styles.ndaNode}>{item2.appl_num}</Box>
                                  )}
                                  {!item2.appl_type.includes('NDA') && (
                                    <Box sx={styles.ndaNode2}>{item2.appl_num}</Box>
                                  )}
                                </Box>
                                <Box sx={styles.Node}>
                                  {item2.manufacturer} | {item2.approval_year}
                                </Box>
                                <Box sx={styles.Node}>
                                  {item2.route} | {item2.formulation}
                                </Box>
                                <Box sx={styles.Node}>
                                  Marketing Status: {item2.marketing_status}
                                </Box>
                                {item2.has_label && (
                                  <Box sx={styles.compareL} onClick={() => handleRldCompare(item2)}>
                                    <img
                                      style={{ width: '15px' }}
                                      src={LabelComapreIcon}
                                      alt='compareIcon'
                                    />
                                    <Typography style={styles.labelTitle}>
                                      Compare Labels
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            }
                          />
                        )
                      );
                    })}
                  </Tree>
                )
              );
            })}
          </Box>
        )}
        {!netDiagramRes.length && <EmptyPage message=' RLD Information not available' />}
      </Box>
    </Box>
  );
};

export default React.memo(Rld);
