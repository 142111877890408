import { Node, Option } from '../../types/search-list-treeview';

export const getOptions = (node: Node | undefined): Array<Option> => {
  if (!node) {
    return [];
  }
  return (
    node.children?.map(
      (node: Node): Option => ({
        label: node.name,
        value: node.id,
        count: node.count,
        mesh_tree_numbers: node.mesh_tree_numbers || [],
        showIcon: (node.children || []).length !== 0,
        children: node.children,
        checked: false
      })
    ) || []
  );
};

export const transverseChildrenNode = (item: any, element: any, checked: boolean) => {
  item.forEach((ele: any) => {
    if (ele.name === element.label) {
      // eslint-disable-next-line no-param-reassign
      element.checked = true;
    } else if (ele.children) {
      transverseChildrenNode(ele.children, element, checked);
    }
  });
};

export const transverseParentNode = (selectedNode: any, checkItems: any) => {
  const checkList = [...checkItems];
  const optionList: any = getOptions(selectedNode);
  if (checkList.length !== 0) {
    optionList.forEach((element: any) => {
      checkItems.forEach((item: any) => {
        if (element.label === item.label) {
          // eslint-disable-next-line no-param-reassign
          element.checked = item.checked;
        }
        if (item.children) {
          transverseChildrenNode(item.children, element, item.checked);
        }
      });
    });
  }
  optionList.sort((a: any, b: any) => {
    return b.count - a.count;
  });
  return optionList;
};
