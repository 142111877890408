import React, { Fragment, useContext } from 'react';
//MATERIAL UI IMPORTS
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import PlusIcon from '../../../Images/svgs/plus';
import Divider from '@mui/material/Divider';
import uniqueId from 'lodash/uniqueId';
// store
import Store from '../../../store';
import Actions from '../../../store/actions';

//styles
import PieChart from '../../CustomComponents/Graphs/PieChart';
import BiologicsStyles from '../../Biologics/styles/Biologics.styles';
import { pieChartStyles, REPORT_QUERIES } from './constants';

const Percentages = ({ data }) => {
  const classes = BiologicsStyles();
  const { dispatch } = useContext(Store);

  const convertData = graphData => {
    if (graphData == undefined && graphData?.length != 0) {
      return null;
    }
    const output = [];
    Object.keys(graphData).forEach(ele => {
      output.push({
        id: ele,
        value: graphData[ele]
      });
    });
    return output;
  };

  const handleAddToReport = async id => {
    const addedReport = REPORT_QUERIES.find(report => report.id === id);
    const random = Math.floor(Math.random() * 1000);
    dispatch({
      type: Actions.ADD_TEMPLATE_TO_REPORT,
      value: {
        templateType: 'custom',
        chartType: addedReport.graph,
        sectionType: 'CHART',
        id: uniqueId(`505b2_${random}_`),
        style: {
          placement: {
            h: 40,
            w: 12
          },
          graphStyle: addedReport.graphStyle,
          title: addedReport.title
        },
        data: {
          source: 'us',
          search_type: '505b2',
          query: addedReport.query
        }
      }
    });
    await dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message: 'Successfully added to Report.', color: 'success' }
    });
  };

  return (
    <Fragment>
      <Grid item lg={12}>
        <Box className={classes.percentageGraphsContainer}>
          {data ? (
            <Box display='flex'>
              <Box height='200px' width='200px' flex={1} pr={1}>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Typography className={classes.headings} align='left'>
                    Accelerated Approvals
                  </Typography>
                  <Typography
                    onClick={() => handleAddToReport('accelerated_approval_percentage')}
                    className={classes.addPercentage}>
                    <PlusIcon /> Add to Report
                  </Typography>
                </Box>
                <PieChart data={convertData(data?.acceleratedApproval)} style={pieChartStyles} />
              </Box>
              <Divider orientation='vertical' sx={{ height: '230px' }} />
              <Box height='200px' width='200px' flex={1} pr={1}>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Typography className={classes.headings} align='left'>
                    Breakthrough Designation
                  </Typography>
                  <Typography
                    onClick={() => handleAddToReport('breakthrough_designation_percentage')}
                    className={classes.addPercentage}>
                    <PlusIcon /> Add to Report
                  </Typography>
                </Box>
                <PieChart
                  data={convertData(data?.breakthroughDesignation)}
                  style={pieChartStyles}
                />
              </Box>
              <Divider orientation='vertical' sx={{ height: '230px' }} />
              <Box height='200px' width='200px' flex={1} pr={1}>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Typography className={classes.headings} align='left'>
                    Fast Track
                  </Typography>
                  <Typography
                    onClick={() => handleAddToReport('fast_track_percentage')}
                    className={classes.addPercentage}>
                    <PlusIcon /> Add to Report
                  </Typography>
                </Box>
                <PieChart data={convertData(data?.fasttrack)} style={pieChartStyles} />
              </Box>
              <Divider orientation='vertical' sx={{ height: '230px' }} />
              <Box height='200px' width='200px' flex={1} pr={1}>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Typography className={classes.headings} align='left'>
                    Orphan Designation
                  </Typography>
                  <Typography
                    onClick={() => handleAddToReport('orphan_designation_percentage')}
                    className={classes.addPercentage}>
                    <PlusIcon /> Add to Report
                  </Typography>
                </Box>
                <PieChart data={convertData(data?.orphan_status)} style={pieChartStyles} />
              </Box>
            </Box>
          ) : (
            <Box height='100px' display='flex' justifyContent='space-between'>
              <Skeleton variant='rectangular' width='20%' height='100%' style />
              <Skeleton variant='rectangular' width='20%' height='100%' />
              <Skeleton variant='rectangular' width='20%' height='100%' />
              <Skeleton variant='rectangular' width='20%' height='100%' />
            </Box>
          )}
        </Box>
      </Grid>
    </Fragment>
  );
};

export default Percentages;
