import axios from 'axios';

const getJsonBlob = identifier =>
  axios.get(`https://api.draftable.com/v1/comparisons/${identifier}/change-details`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: 'Token ca9099491e9effe301b01cf2051bae74'
    }
  });

export default getJsonBlob;
