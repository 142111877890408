export const stringToColor = (string: string) => {
  if (!string?.trim()) {
    // Fallback to a default color if the string is blank or contains only whitespace
    return 'primary.main'; // Default color (light gray)
  }
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name: string) => {
  let initials = null;
  if (name?.trim()) {
    const splitName = name.trim().split(' ');
    if (splitName?.length > 1) {
      initials = `${splitName[0][0]}${splitName[1][0]}`;
    } else {
      initials = `${splitName[0][0]}`;
    }
  }
  return initials;
};
