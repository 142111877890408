/* eslint-disable react/prop-types */
import { useHistory } from 'react-router-dom';
import React from 'react';

// mui
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

// Styles
import styles from './VideoCardHorizontal.styles';

const VideoCardHorizontal = ({ video }) => {
  const history = useHistory();
  const tagString = `#${video.tags.join(' #')}`;
  return (
    <Card sx={styles.card} onClick={() => history.push(`/help/videos/${video.id}`)}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CardMedia
            component='img'
            sx={{ height: '100%' }}
            image={video.thumbnail}
            alt={video.title}
          />
        </Grid>
        <Grid item xs={6} p={2}>
          <Typography variant='subtitle2' sx={styles.title}>
            {video.title}
          </Typography>
          <Typography variant='body1' sx={styles.shortDescription}>
            {video.shortDescription}
          </Typography>
          <Typography variant='body1' sx={styles.tags}>
            {tagString}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default React.memo(VideoCardHorizontal);
