import { memo, useContext, useState, useCallback, MouseEvent } from 'react';
import { Tooltip, MenuItem, ListItemText, IconButton, Typography } from '@mui/material';

import { RoundedMenu } from '../../../components/ui/Menu';
import { DownloadIcon } from '../../../assets/svgs/Icons';
import ResultsActions from '../../../store/SearchResults/actions';
import ResultsStore from '../../../store/SearchResults';
import ApprovalHistoryDownload from './ApprovalHistoryDownload';
import RESULT_VIEW_TYPES from '../components/constants';
import styles from '../styles/DownloadButton.styles';

const ApplicationsDownloadButton = () => {
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const [isDownloadListOpen, setIsDownloadListOpen] = useState(false);
  const [isSpecificLabelSectionDownloadOptionsOpen, setIsSpecificLabelSectionDownloadOptionsOpen] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [secondaryAnchorEl, setSecondaryAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setSecondaryAnchorEl(null);
    setIsDownloadListOpen(false);
    setIsSpecificLabelSectionDownloadOptionsOpen(false);
  }, []);

  const handleCollatedSummary = (type: string) => {
    resultsDispatch({ type: ResultsActions.SET_COLLATED_SUMMARY_SELECTION, value: true });
    resultsDispatch({ type: ResultsActions.SET_COLLATED_SUMMARY_DOWNLOAD_TYPE, value: type });
    handleClose();
  };

  const handleCollatedSummaryDownloadOptions = (event: MouseEvent<HTMLElement>) => {
    setSecondaryAnchorEl(event.currentTarget);
    setIsSpecificLabelSectionDownloadOptionsOpen(true);
  };

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsDownloadListOpen(true);
  }, []);

  const showDownloadLabelSection = () => {
    const keys = Object.keys(resultsState.applicationResults);
    return !(
      (keys.length === 1 && keys.includes('us-devices')) ||
      (keys.length === 2 && keys.includes('us-devices'))
    );
  };

  return (
    <>
      <Tooltip title='Download'>
        <IconButton onClick={handleClick}>
          <DownloadIcon sx={styles.downloadIcon} />
        </IconButton>
      </Tooltip>
      <RoundedMenu
        open={isDownloadListOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={styles.roundedMenu}>
        <ApprovalHistoryDownload handleClose={handleClose} />
        {resultsState?.viewType === RESULT_VIEW_TYPES.APPLICATION && showDownloadLabelSection() && (
          <>
            <Tooltip
              title='Select specific label section, choose applications and download a compiled document.'
              placement='left'>
              <MenuItem
                onClick={handleCollatedSummaryDownloadOptions}
                disabled={resultsState?.labelComparisonSelection}>
                <ListItemText primary='Specific Label Section' />
              </MenuItem>
            </Tooltip>
            <RoundedMenu
              open={isSpecificLabelSectionDownloadOptionsOpen}
              anchorEl={secondaryAnchorEl}
              onClose={() => {
                setIsSpecificLabelSectionDownloadOptionsOpen(false);
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              sx={{
                '& .MuiPaper-root': { padding: '8px 0px !important' },
                '& .MuiList-root': { padding: '0 !important' }
              }}>
              <MenuItem disabled sx={{ p: '6px 24px' }}>
                <ListItemText>
                  <Typography sx={{ color: 'gray.800', fontSize: 12, fontWeight: 400 }}>
                    Select Download Option:
                  </Typography>
                </ListItemText>
              </MenuItem>
              <MenuItem sx={{ p: '6px 24px' }} onClick={() => handleCollatedSummary('html')}>
                HTML file
              </MenuItem>
              <MenuItem sx={{ p: '6px 24px' }} onClick={() => handleCollatedSummary('pdf')}>
                PDF file
              </MenuItem>
              <MenuItem sx={{ p: '6px 24px' }} onClick={() => handleCollatedSummary('docx')}>
                Docx file (.docx)
              </MenuItem>
            </RoundedMenu>
          </>
        )}
        <Tooltip title='Download all applications as csv (.csv) file' placement='left'>
          <MenuItem
            onClick={() => {
              resultsDispatch({
                type: ResultsActions.SET_APPLICATION_EXPORT,
                value: {
                  type: 'csv',
                  isOn: true
                }
              });

              handleClose();
            }}>
            <ListItemText primary='All Applications (.csv)' />
          </MenuItem>
        </Tooltip>
        <Tooltip title='Download all applications as excel (.xlsx) file' placement='left'>
          <MenuItem
            onClick={() => {
              resultsDispatch({
                type: ResultsActions.SET_APPLICATION_EXPORT,
                value: {
                  type: 'xlsx',
                  isOn: true
                }
              });

              handleClose();
            }}>
            <ListItemText primary='All Applications (.xlsx)' />
          </MenuItem>
        </Tooltip>
      </RoundedMenu>
    </>
  );
};

export default memo(ApplicationsDownloadButton);
