import { createSvgIcon } from '@mui/material';

const BestMatchIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
    <g clipPath='url(#clip0_830_2183)'>
      <path
        d='M1.42448 4.33774L0.383818 5.79083C0.0972961 6.18167 -0.0362155 6.66388 0.00849249 7.14643C0.0532005 7.62897 0.273034 8.07845 0.626485 8.40999L6.99999 14.0001L13.4132 8.37616C13.7569 8.03462 13.9638 7.57902 13.9946 7.09541C14.0255 6.61179 13.8782 6.13361 13.5806 5.75116L12.39 4.32958C12.2055 4.07878 11.9649 3.87469 11.6873 3.73368C11.4097 3.59267 11.103 3.51866 10.7917 3.51758H3.03332C2.71959 3.51837 2.41046 3.59303 2.13095 3.73552C1.85145 3.87801 1.60942 4.08432 1.42448 4.33774ZM1.80132 6.8175L2.84198 5.36674C2.86356 5.33605 2.89221 5.31101 2.92552 5.29375C2.95882 5.27649 2.9958 5.26751 3.03332 5.26758H5.35965L4.66665 7.00008H9.33332L8.64032 5.26758H10.7917C10.8379 5.27077 10.8826 5.28532 10.9219 5.30995C10.9612 5.33457 10.9938 5.36851 11.0168 5.40874L12.2045 6.82508C12.241 6.87565 12.2573 6.9381 12.25 7.00008H9.33332L6.99999 11.6702L4.66665 7.00008H1.76048C1.75432 6.96847 1.75477 6.93594 1.7618 6.90451C1.76883 6.87309 1.78228 6.84346 1.80132 6.8175Z'
        fill='#289D78'
      />
      <path d='M7.875 0H6.125V2.33333H7.875V0Z' fill='#289D78' />
      <path
        d='M10.3454 0.175827L9.17883 2.00928L10.6563 2.94934L11.8229 1.11589L10.3454 0.175827Z'
        fill='#289D78'
      />
      <path
        d='M3.65519 0.179993L2.17847 1.12012L3.34508 2.95261L4.82181 2.01249L3.65519 0.179993Z'
        fill='#289D78'
      />
    </g>
    <defs>
      <clipPath id='clip0_830_2183'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'BestMatchIcon'
);

export default BestMatchIcon;
