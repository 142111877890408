import React from 'react';
import CustomProgressStyles from './CustomProgress.styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

const CustomProgressBar = props => {
  const classes = CustomProgressStyles();

  return (
    <Grid container className={classes.Bar}>
      <Tooltip title={'Yes : ' + props.data.vote_yes} aria-label='Button Link'>
        <Grid
          item
          style={{
            width:
              String(
                (props.data.vote_yes * 100) /
                  (props.data.vote_yes + props.data.vote_no + props.data.vote_abstain)
              ) + '%',
            backgroundColor: '#06C66C',
            borderRadius: '2px'
          }}
          className={classes.vote_yes}></Grid>
      </Tooltip>

      <Tooltip title={'No : ' + props.data.vote_no} aria-label='Button Link'>
        <Grid
          item
          style={{
            width:
              String(
                (props.data.vote_no * 100) /
                  (props.data.vote_yes + props.data.vote_no + props.data.vote_abstain)
              ) + '%'
          }}
          className={classes.no}></Grid>
      </Tooltip>

      <Tooltip title={'Abstain : ' + props.data.vote_abstain} aria-label='Button Link'>
        <Grid
          item
          style={{
            width:
              String(
                (props.data.vote_abstain * 100) /
                  (props.data.vote_yes + props.data.vote_no + props.data.vote_abstain)
              ) + '%'
          }}
          className={classes.abstain}></Grid>
      </Tooltip>
    </Grid>
  );
};

export { CustomProgressBar };
