import { Filter } from '../../components/Filters';
import { FILTER_TYPE } from '../../components/NewFilters/constants';

export interface DesignationFilters extends Filter {
  apiRequestKey: string;
  apiResponseKey?: string;
}

export const DESIGNATION_FILTERS = [
  {
    id: 1,
    label: 'Enter Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Application Number',
    value: 'application_number',
    elasticSearchType: 'appl_num',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Application Type',
    value: 'application_type',
    elasticSearchType: 'appl_type',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Trade Name',
    value: 'product_name',
    elasticSearchType: 'product_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Active Ingredient',
    value: 'generic_name',
    elasticSearchType: 'generic_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Formulation',
    value: 'formulation',
    elasticSearchType: 'formulation',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 7,
    label: 'Route',
    value: 'route',
    elasticSearchType: 'route',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 8,
    label: 'Sponsor',
    value: 'sponsor_name',
    elasticSearchType: 'sponsor_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 9,
    label: 'Marketing Status',
    value: 'marketing_status_description',
    elasticSearchType: 'marketing_status',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  }
];
