import { createSvgIcon } from '@mui/material';

const ThumbsDownIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 14 14' fill='none'>
    <g clipPath='url(#clip0_494_3461)'>
      <path
        d='M13.9714 7.175L13.5602 4.25833C13.4591 3.56413 13.1123 2.92921 12.5829 2.46894C12.0535 2.00866 11.3765 1.75357 10.675 1.75H2.91667C2.1434 1.75093 1.40208 2.05851 0.855295 2.6053C0.308515 3.15208 0.00092625 3.8934 0 4.66667L0 7.58333C0.00092625 8.3566 0.308515 9.09793 0.855295 9.64471C1.40208 10.1915 2.1434 10.4991 2.91667 10.5H4.49867L5.61517 12.7622C5.80286 13.1434 6.12136 13.4445 6.51255 13.6105C6.90375 13.7765 7.34159 13.7964 7.7462 13.6665C8.15081 13.5365 8.49524 13.2655 8.71667 12.9028C8.9381 12.5401 9.02177 12.1098 8.95242 11.6906L8.75642 10.5H11.0833C11.5009 10.5 11.9135 10.4104 12.2935 10.2372C12.6734 10.064 13.0117 9.8112 13.2855 9.49597C13.5593 9.18075 13.7622 8.81041 13.8805 8.40999C13.9989 8.00958 14.0299 7.58842 13.9714 7.175ZM2.91667 2.91667H4.08333V9.33333H2.91667C2.45254 9.33333 2.00742 9.14896 1.67923 8.82077C1.35104 8.49258 1.16667 8.04746 1.16667 7.58333V4.66667C1.16667 4.20254 1.35104 3.75742 1.67923 3.42923C2.00742 3.10104 2.45254 2.91667 2.91667 2.91667ZM12.404 8.73133C12.2397 8.92032 12.0369 9.07185 11.809 9.1757C11.5812 9.27956 11.3337 9.33331 11.0833 9.33333H8.06925C7.98464 9.33332 7.90104 9.3517 7.82425 9.38722C7.74745 9.42274 7.67931 9.47454 7.62453 9.53903C7.56976 9.60351 7.52967 9.67914 7.50704 9.76067C7.48441 9.8422 7.47979 9.92768 7.4935 10.0112L7.8015 11.8778C7.81629 11.9645 7.81178 12.0533 7.78829 12.138C7.76479 12.2227 7.7229 12.3012 7.66558 12.3678C7.59839 12.4403 7.51495 12.4957 7.42213 12.5296C7.32932 12.5635 7.22978 12.5749 7.13171 12.5628C7.03364 12.5508 6.93984 12.5156 6.85802 12.4602C6.7762 12.4048 6.70871 12.3307 6.66108 12.2442L5.38417 9.65883C5.35048 9.59417 5.30498 9.5364 5.25 9.4885V2.91667H10.675C11.0963 2.91821 11.5031 3.07109 11.8211 3.34746C12.1391 3.62382 12.3472 4.00526 12.4075 4.42225L12.8193 7.33892C12.8539 7.58722 12.8347 7.84004 12.763 8.08027C12.6914 8.3205 12.5689 8.54253 12.404 8.73133Z'
        fill='#0A806D'
      />
    </g>
    <defs>
      <clipPath id='clip0_494_3461'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'ThumbsDownIcon'
);

export default ThumbsDownIcon;
