const formatDateInUTC = (date: any) => {
  const options: any = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };
  options.timeZone = 'UTC';
  return date.toLocaleDateString(undefined, options);
};
export default formatDateInUTC;
