import { createSvgIcon } from '@mui/material';

const TimeIcon = createSvgIcon(
  <svg
    width='12'
    height='13'
    viewBox='0 0 12 13'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_502_4383)'>
      <path d='M6 12.25C4.81331 12.25 3.65328 11.8981 2.66658 11.2388C1.67989 10.5795 0.910851 9.64246 0.456726 8.5461C0.00259972 7.44975 -0.11622 6.24335 0.115291 5.07946C0.346802 3.91558 0.918247 2.84648 1.75736 2.00736C2.59648 1.16825 3.66558 0.596802 4.82946 0.365291C5.99335 0.13378 7.19975 0.2526 8.2961 0.706726C9.39246 1.16085 10.3295 1.92989 10.9888 2.91658C11.6481 3.90328 12 5.06331 12 6.25C11.9983 7.84077 11.3656 9.3659 10.2407 10.4907C9.1159 11.6156 7.59077 12.2483 6 12.25ZM6 1.75C5.10999 1.75 4.23996 2.01392 3.49994 2.50839C2.75992 3.00286 2.18314 3.70566 1.84254 4.52793C1.50195 5.35019 1.41284 6.25499 1.58647 7.12791C1.7601 8.00082 2.18869 8.80265 2.81802 9.43198C3.44736 10.0613 4.24918 10.4899 5.1221 10.6635C5.99501 10.8372 6.89981 10.7481 7.72208 10.4075C8.54435 10.0669 9.24715 9.49009 9.74162 8.75007C10.2361 8.01005 10.5 7.14002 10.5 6.25C10.4987 5.05693 10.0241 3.91311 9.18052 3.06948C8.33689 2.22586 7.19307 1.75133 6 1.75Z' />
      <path d='M4.21006 8.19799L3.41406 6.92699L5.24956 5.77749V3.38599H6.74956V6.60749L4.21006 8.19799Z' />
    </g>
    <defs>
      <clipPath id='clip0_502_4383'>
        <rect width='12' height='12' fill='white' transform='translate(0 0.25)' />
      </clipPath>
    </defs>
  </svg>,
  'Location'
);

export default TimeIcon;
