export const applNumberMixin = {
  color: 'white.main',
  borderRadius: '8px',
  padding: '3px 8px',
  fontSize: '12px',
  fontWeight: 400,
  width: 'max-content'
};

const nameMixin = {
  fontWeight: 700,
  fontSize: '14px',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap'
};

const styles = {
  dropDownBox: {
    display: 'flex',
    borderRadius: '8px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  appNumRed: {
    ...applNumberMixin,
    backgroundColor: 'red.light'
  },
  appNumBlue: {
    ...applNumberMixin,
    backgroundColor: 'blue.berry'
  },
  nameRed: {
    ...nameMixin,
    color: 'red.light'
  },
  nameBlue: {
    ...nameMixin,
    color: 'blue.berry'
  },
  chipMetadata: {
    ml: 1,
    fontSize: 10,
    height: 13,
    borderRadius: 1,
    fontWeight: 800,
    color: 'white.main',
    '& span': {
      px: 0.5
    }
  },
  tradeName: {
    fontWeight: 700,
    fontSize: '14px',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  identifier: {
    color: 'white.main',
    borderRadius: '8px',
    padding: '3px 8px',
    fontSize: '12px',
    fontWeight: 400,
    width: 'max-content'
  },
  title: {
    fontWeight: 700,
    fontSize: '16px',
    textTransform: 'uppercase',
    paddingLeft: '10px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  searchTerm: {
    color: 'white.main',
    fontWeight: '700',
    fontSize: '14px'
  },
  timeLineCards: {
    pt: '2.5rem',
    position: 'sticky !important',
    left: 0,
    zIndex: 3,
    backgroundColor: 'white.main',
    display: 'flex',
    height: 'fit-content'
  },
  yearBox: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    backgroundColor: 'white.main'
  },
  headerText: {
    fontWeight: 700,
    textTransform: 'capitalize',
    color: 'primary.600',
    fontSize: '14px'
  },
  reportIcon: {
    height: '16px',
    width: '16px',
    color: 'secondary.500'
  },
  visualizeButton: {
    minWidth: 0,
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: '600',
    color: 'secondary.500'
  },
  dialogBox: {
    '& >.MuiDrawer-paper': {
      height: '98%',
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      position: 'absolute',
      backgroundColor: 'white.smokeDark'
    }
  },
  gridContainer: {
    overflow: 'auto',
    maxHeight: '57vh',
    padding: '24px 24px 0px 24px',
    background: 'white',
    border: '1px solid',
    borderColor: 'grey.300',
    '&::-webkit-scrollbar': {
      width: '7px',
      height: '7px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'grey.300',
      borderRadius: '5px'
    }
  },
  badgeContainer: {
    zIndex: 1,
    position: 'relative',
    top: '28%',
    textAlign: 'center',
    pl: '16px',
    pr: '16px'
  },
  chatRiaButton: {
    display: 'flex',
    ml: '10px',
    width: 'fit-content',
    borderRadius: '16px',
    height: '32px',
    padding: '6px 16px',
    border: '1px solid',
    borderColor: 'blue.backgroundLight',
    backgroundColor: 'white.main',
    textTransform: 'capitalize',
    color: 'blue.backgroundLight',
    fontSize: '12px',
    fontWeigth: '400'
  },
  documentIcon: {
    height: '18px',
    width: '18px',
    color: 'blue.background',
    cursor: 'pointer',
    ml: '5px'
  },
  pdfContainer: {
    width: 700,
    borderRadius: 5,
    mb: 10,
    background: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
    maxHeight: '99vh',
    '& > div#pdfBox': { height: '86%', width: '96%' }
  },
  snackBarContainer: {
    right: `655px !important`,
    top: `50px !important`
  },
  titleText: {
    fontFamily: 'Mulish',
    fontSize: 14,
    fontWeight: 700,
    textOverflow: 'ellipsis'
  },
  chatRiaIcon: {
    width: '16px',
    height: '16px',
    p: '2.667px 2.666px 2.667px 2.667px'
  },
  closeIcon: { width: '18px', height: '18px', cursor: 'pointer', color: 'grey.600' },
  message: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  add: {
    height: 35,
    cursor: 'pointer',
    background: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: 'none',
      border: 1,
      color: 'primary.lightBluish',
      borderStyle: 'solid'
    }
  },
  addText: {
    color: 'primary.lightBluish',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    '&:hover': {
      background: 'none'
    }
  },
  reportGridContainer: {
    display: 'inline-block',
    height: '100%',
    width: '100%',
    'page-break-before': 'avoid',
    'page-break-after': 'avoid'
  },
  plusIcon: { height: 13, width: 13, color: 'primary.700' }
};

export const CardContainer: any = {
  p: 0.5,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  mt: 0,
  border: '1px solid',
  borderColor: 'gray.300',
  borderRadius: '8px',
  position: 'relative',
  display: 'flex',
  width: '160px',
  padding: '8px 12px',
  alignItems: 'stretch',
  gap: '2px',
  backgroundColor: 'white.main',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: 'white.main',
    boxShadow: 0
  },
  height: '36px',
  mb: '40px'
};

export const snackBarStyle: any = {
  top: '0px',
  right: '0 !important',
  '& .MuiDrawer-paperAnchorRight': {
    top: 0
  },
  '& >.MuiBackdrop-root': {
    backgroundColor: 'rgba(0,0,0,0.25)'
  },
  '& > .MuiDrawer-paper': {
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15
  }
};

export const snackBarContentStyle: any = {
  backgroundColor: 'white.main',
  backdropFilter: 'blur(10px)',
  p: 5,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  width: 650,
  height: `calc(100vh - 0px)`,
  maxHeight: `calc(100vh - 0px)`,
  top: '0px',
  right: '0 !important'
};

export const chipsContainerStyle: any = {
  mt: 1,
  mb: 2,
  '& > div': {
    mt: 1,
    mr: 1,
    borderRadius: '4px',
    '& > span > p': {
      fontSize: '12px'
    }
  }
};

export const indicationSectionStyle: any = {
  width: '100%',
  pt: 1,
  maxHeight: '100%',
  overflowY: 'scroll',
  overflowX: 'hidden',
  whiteSpace: 'initial',
  textOverflow: 'break-word',
  overflowWrap: 'break-word',
  '&::-webkit-scrollbar': {
    width: '0.4em'
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,0.26)'
  }
};

export const horizontalTimelineStyle: any = {
  cursor: 'grab',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  backgroundColor: 'white.main'
};
export const plusStyle: any = {
  position: 'relative',
  height: '76px',
  borderColor: 'grey.400',
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    background: '#0000'
  },
  '&::before': {
    left: '50%',
    top: '0%',
    bottom: '4px',
    transform: 'translateX(-50%)',
    border: '1px dashed #adadad80'
  },
  '&::after': {
    left: '0%',
    top: '50%',
    right: '4px',
    height: '1px',
    transform: 'translateY(-50%)',
    border: '1px dashed #adadad80'
  }
};

export default styles;
