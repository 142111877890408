/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useCallback, useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import _ from "lodash";

import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import cloneDeep from "lodash/cloneDeep";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

export interface StyleComponentProps {
  styleList: Array<any>;
  // eslint-disable-next-line no-unused-vars
  handleStyleChange: (styleList: any) => void;
  stylesFilters: any;
}

export const SilderComponent = ({ item, handleSliderChange }: any) => {
  return (
    <Stack key={item.id} display='flex' flexDirection='row' alignItems='center'>
      <Typography variant='subtitle1' width='33%' fontSize='14px'>
        {item.label}:
      </Typography>
      <Slider
        size='small'
        sx={{ width: '75%' }}
        defaultValue={item.value || 0.2}
        onChange={(e: any) => {
          handleSliderChange(item.id, e.target.value);
        }}
        valueLabelDisplay='auto'
        step={item.paramter.step}
        min={item.paramter.min}
        max={item.paramter.max}
      />
    </Stack>
  );
};

export const MultipleDropdownComponent = ({ item, handleChange }: any) => {
  return (
    <FormControl key={item.id} sx={{ flexGrow: 1 }}>
      <Select
        id={item.id}
        value={item.value}
        onChange={(event: SelectChangeEvent<any>) => {
          handleChange(event, item.id);
        }}
        size='small'>
        {item.options.map((opt: any) => (
          <MenuItem key={opt.key} value={opt.key}>
            <Stack
              display='flex'
              flexDirection='row'
              alignItems='center'
              width='100%'
              justifyContent='space-between'>
              <Typography variant='subtitle1' fontSize='14px'>
                {opt.key}
              </Typography>
              <Stack
                display='flex'
                flexDirection='row'
                justifyContent='flex-end'
                width='100%'
                alignItems='center'>
                {opt.value.map((val: string) => {
                  return (
                    <Box
                      key={val}
                      display='block'
                      width='15px'
                      padding='10px'
                      margin='5px'
                      height='15px'
                      sx={{ backgroundColor: `${val}` }}
                    />
                  );
                })}
              </Stack>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const DropdownComponent = ({ item, handleDropDownChange, disabled = false }: any) => {
  return (
    <Stack
      key={item.id}
      display='flex'
      flexDirection='column'
      flexGrow={1}
      sx={{ '&:nth-child(even)': { ml: 1 } }}>
      <Typography sx={{ fontSize: 14, color: '#A8A8A8', fontWeight: 400 }}>{item.label}</Typography>
      <FormControl key={item.id} size='small'>
        <Select
          id={item.id}
          value={item.value}
          onChange={(event: SelectChangeEvent<any>) => {
            handleDropDownChange(event, item.id);
          }}
          disabled={disabled}
          sx={{ fontSize: '14px' }}>
          {item.options.map((opt: any) => (
            <MenuItem key={opt.key} value={opt.value} sx={{ fontSize: '14px' }}>
              {opt.key}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export const CheckBoxComponent = ({ item, handleCheckBox }: any) => {
  return (
    <FormControlLabel
      sx={{
        '& .MuiFormControlLabel-label': {
          fontSize: 14
        }
      }}
      label={item.label}
      control={
        <Checkbox
          checked={item.value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleCheckBox(event, item.id);
          }}
          size='small'
        />
      }
    />
  );
};

export const RadioButtonComponent = ({ item, handleRadioComponent }: any) => {
  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby='demo-row-radio-buttons-group-label'
        name='row-radio-buttons-group'
        value={item.value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleRadioComponent(event, item.id);
        }}>
        {item.options.map((opt: any) => (
          <FormControlLabel
            key={opt.label}
            value={opt.value}
            control={<Radio />}
            label={opt.key}
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: 14
              }
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const StyleComponent = ({ styleList, handleStyleChange, stylesFilters }: StyleComponentProps) => {
  const [styleState, setStyle] = useState<Array<any>>([]);
  const [colorId, setColorId] = useState<string>('');
  const [selectedColor, setSelectedColor] = useState<{ [key: string]: string }>({
    color1: '#B1BBE4',
    color2: '#12815E',
    color3: '#6868FA'
  });
  const [customColor, setCustomColor] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleColorChange = (color: any, styleId: string) => {
    const updateColor = { ...selectedColor };
    updateColor[colorId] = color.hex;
    setSelectedColor(updateColor);
    const newStyleList = [...styleState];
    const updatedStyle = newStyleList.find((item: any) => item.id === styleId);
    if (updatedStyle) {
      updatedStyle.customColor = true;
    }
    setStyle(newStyleList);
  };

  const handleOpenPopover = (event: any, col: string) => {
    setColorId(col);
    setCustomColor(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // Use Effect to retain previous apply styles
  useEffect(() => {
    if (Object.keys(stylesFilters).length !== 0) {
      const updatedStyles = [...styleList];
      updatedStyles.forEach((row: any) => {
        if (row.type === 'multi-value-dropdown') {
          if (row.customColor || customColor) {
            // eslint-disable-next-line no-param-reassign
            row.value = 'Choose Colors';
            row.options?.forEach((opt: any) => {
              if (opt.key === 'Choose Colors') {
                // eslint-disable-next-line no-param-reassign
                opt.value = stylesFilters[row.id].slice(0, 3);
              }
            });
            setCustomColor(true);
          }
          if (row.id in stylesFilters) {
            const value = stylesFilters[row.id];
            row.options?.forEach((opt: any) => {
              if (JSON.stringify(opt.value) === JSON.stringify(value)) {
                // eslint-disable-next-line no-param-reassign
                row.value = opt.key;
              }
            });
          }
          if (row.id in stylesFilters) {
            stylesFilters[row.id].forEach((color: string, index: number) => {
              if (index < 3) {
                setSelectedColor(prevSelectedColor => ({
                  ...prevSelectedColor,
                  [`color${index + 1}`]: color
                }));
              }
            });
          }
        } else if (row.id in stylesFilters) {
          // eslint-disable-next-line no-param-reassign
          row.value = stylesFilters[row.id];
        }
      });
      setStyle(updatedStyles);
    } else {
      setStyle(cloneDeep(styleList));
    }
  }, [styleList, customColor]);

  // Use Effect to create applied styles object
  useEffect(() => {
    const styleObject: any = {};
    styleState.forEach((item: any) => {
      if (item.type === 'multi-value-dropdown') {
        if (customColor) {
          styleObject[item.id] = Object.values(selectedColor);
        } else {
          item.options?.forEach((opt: any) => {
            if (opt.key === item.value) {
              styleObject[item.id] = opt.value;
            }
          });
          // eslint-disable-next-line no-param-reassign
          item.customColor = false;
        }
      } else {
        styleObject[item.id] = item.value;
      }
    });

    styleState.forEach((item: any) => {
      if (item.type === 'checkbox' && !item.value) {
        // Loop for the disabled keys by delete them from styleObject
        item.disabled.forEach((row: string) => {
          delete styleObject[row];
        });
      }
    });
    handleStyleChange(styleObject);
  }, [styleState, selectedColor, customColor]);

  const handleSliderChange = (styleId: string, newValue: number) => {
    const newStyleList = [...styleState];
    const updatedStyle = newStyleList.find((item: any) => item.id === styleId);
    if (updatedStyle) {
      updatedStyle.value = newValue;
    }
    setStyle(newStyleList);
  };

  const handleChange = (event: SelectChangeEvent<any>, styleId: string) => {
    const newStyleList = [...styleState];
    const updatedStyle = newStyleList.find((item: any) => item.id === styleId);
    if (updatedStyle) {
      updatedStyle.options?.forEach((opt: any) => {
        if (opt.key === event.target.value) {
          updatedStyle.value = opt.key;
          updatedStyle.customColor = false;
        }
      });
      setStyle(newStyleList);
    }
    setCustomColor(false);
  };

  const handleDropDownChange = (event: SelectChangeEvent<any>, styleId: string) => {
    const newStyleList = [...styleState];
    const updatedStyle = newStyleList.find((item: any) => item.id === styleId);
    if (updatedStyle) {
      updatedStyle.value = event.target.value;
    }
    setStyle(newStyleList);
  };

  const arrangeStyleList = useCallback((dataList: Array<any>) => {
    const updatedStyles = [...dataList];
    const groupedData = _.groupBy(updatedStyles, 'group');
    return groupedData;
  }, []);

  const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>, styleId: string) => {
    const newStyleList = [...styleState];
    const updatedStyle = newStyleList.find((item: any) => item.id === styleId);
    if (updatedStyle) {
      updatedStyle.value = event.target.checked;
    }
    setStyle(newStyleList);
  };

  const handleRadioComponent = (event: React.ChangeEvent<HTMLInputElement>, styleId: string) => {
    const newStyleList = [...styleState];
    const updatedStyle = newStyleList.find((item: any) => item.id === styleId);
    if (updatedStyle) {
      updatedStyle.value = event.target.value;
    }
    setStyle(newStyleList);
  };

  const handleDisable = (styleId: string) => {
    const newStyleList = [...styleState];
    const updatedStyle = newStyleList.find((item: any) => item.id === styleId);
    if (!updatedStyle.value) {
      return true;
    }
    return false;
  };

  return (
    <Stack spacing={3} direction='column' display='flex' justifyContent='space-between' padding={3}>
      {styleState &&
        Object.entries(arrangeStyleList(styleState)).map(([key, value]: any) => {
          if (key === 'slider') {
            return (
              <Stack spacing={3} key={key} display='flex' flexDirection='column' padding='8px'>
                {value.map((item: any) => {
                  return (
                    <SilderComponent
                      key={item.id}
                      item={item}
                      handleSliderChange={handleSliderChange}
                    />
                  );
                })}
              </Stack>
            );
          }

          if (key === 'legend' || key === 'label') {
            return (
              <Stack key={key} display='flex' flexDirection='row' alignItems='flex-end' padding={1}>
                <Box width='43%'>
                  {value.map((item: any) => {
                    if (item.type === 'checkbox') {
                      return (
                        <CheckBoxComponent
                          key={item.id}
                          item={item}
                          handleCheckBox={handleCheckBox}
                        />
                      );
                    }
                  })}
                </Box>
                <Box display='flex' flexDirection='row' width='100%'>
                  {value.map((item: any) => {
                    if (item.type === 'dropdown') {
                      return (
                        <DropdownComponent
                          key={item.id}
                          item={item}
                          handleDropDownChange={handleDropDownChange}
                          disabled={handleDisable(item.disabled)}
                        />
                      );
                    }
                  })}
                </Box>
              </Stack>
            );
          }
          if (key === 'dropdown') {
            return (
              <Stack spacing={2} key={key} display='flex' flexDirection='row' padding='8px'>
                {value.map((item: any) => {
                  return (
                    <DropdownComponent
                      key={item.id}
                      item={item}
                      handleDropDownChange={handleDropDownChange}
                    />
                  );
                })}
              </Stack>
            );
          }

          if (key === 'colors') {
            return (
              <Stack spacing={2} key={key} display='flex' flexDirection='column' padding='8px'>
                <Box>
                  <Divider
                    variant='middle'
                    sx={{ borderWidth: '1px', borderColor: 'black.main', ml: 0, mr: '0' }}
                    flexItem
                  />
                </Box>
                {value.map((item: any) => {
                  return (
                    <Stack key={key} display='flex' flexDirection='row' alignItems='center'>
                      <Typography variant='subtitle1' width='30%' fontSize='14px'>
                        {item.label}:
                      </Typography>
                      <MultipleDropdownComponent
                        key={item.id}
                        item={item}
                        handleChange={handleChange}
                      />
                    </Stack>
                  );
                })}
                <Stack key={key} display='flex' flexDirection='row' alignItems='center'>
                  <Typography variant='subtitle1' width='30%' fontSize='14px'>
                    Choose Colors:
                  </Typography>
                  {selectedColor &&
                    Object.keys(selectedColor).map((col: string, index: number) => {
                      return (
                        <Stack
                          // eslint-disable-next-line react/no-array-index-key
                          key={index + 1}
                          display='flex'
                          flexDirection='row'
                          alignItems='center'
                          sx={{ '&:nth-child(n+3)': { ml: 1 } }}>
                          <Typography variant='subtitle1' fontSize='14px'>
                            Colors {index + 1}:
                          </Typography>
                          <FormControl sx={{ ml: 1 }}>
                            <Select
                              value={selectedColor[col]}
                              label='Select a color'
                              open={open && col === colorId}
                              onOpen={(e: any) => {
                                handleOpenPopover(e, col);
                              }}
                              size='small'>
                              <MenuItem
                                value={selectedColor[col]}
                                onClick={(e: any) => {
                                  handleOpenPopover(e, col);
                                }}>
                                <Stack
                                  display='flex'
                                  flexDirection='row'
                                  justifyContent='flex-end'
                                  width='100%'
                                  alignItems='center'>
                                  <Box
                                    key={selectedColor[col]}
                                    display='block'
                                    width='10px'
                                    height='15px'
                                    sx={{ backgroundColor: `${selectedColor[col]}` }}
                                  />
                                </Stack>
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <Popover
                            open={open && col === colorId}
                            onClose={handleClosePopover}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right'
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left'
                            }}>
                            <Stack display='flex' justifyContent='flex-end'>
                              <CloseIcon
                                onClick={() => handleClosePopover()}
                                cursor='pointer'
                                fontSize='small'
                              />
                              <SketchPicker
                                color={selectedColor[col]}
                                onChange={color => handleColorChange(color, key)}
                              />
                            </Stack>
                          </Popover>
                        </Stack>
                      );
                    })}
                </Stack>
              </Stack>
            );
          }

          if (key === 'layout') {
            return (
              <Stack key={key} display='flex' flexDirection='row' alignItems='center' padding='8px'>
                <Typography
                  variant='subtitle1'
                  width='30%'
                  sx={{ textTransform: 'capitalize' }}
                  fontSize='14px'>
                  {key}:
                </Typography>
                <Box display='flex' flexDirection='row'>
                  {value.map((item: any) => {
                    return (
                      <RadioButtonComponent
                        key={item.id}
                        item={item}
                        handleRadioComponent={handleRadioComponent}
                      />
                    );
                  })}
                </Box>
              </Stack>
            );
          }
        })}
    </Stack>
  );
};

export default React.memo(StyleComponent);
