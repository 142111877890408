import { useEffect, useState } from 'react';
import { Box, Button, Divider, Popover } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { isEqual } from 'lodash';
import AutoSearchOptions from './AutoSearchOptions';
import FilterSidebar from './FilterSidebar';
import { Category, FilterProps, SelectedCategoryOptions } from './types';
import { FILTER_TYPE } from './constants';
import NumberRangeOptions from './NumberRangeOptions';
import RadioGroupsOptions from './RadioGroupsOptions';
import DateYearRangeOptions from './DateYearRangeOptions';
import { filterStyles, styles } from './styles';
import ApplyFilterAlert from './ApplyFilterAlert';
import palette from '../../themev5/palette';
import SearchKeywordOptions from './SearchKeywordOptions';
import MultiSearchKeywordOptions from './MultiSearchKeywordOptions';
import { FilterFilledIcon } from '../../assets/svgs/Icons';

const NewFilters = ({ data, applyFilters, isLoading, selectedFilters = {} }: FilterProps) => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [showFilterSidebar, setShowFilterSidebar] = useState(false);
  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState<SelectedCategoryOptions>(
    {}
  );
  const [previousSelectedCategoryOptions, setPreviousSelectedCategoryOptions] =
    useState<SelectedCategoryOptions>({});
  const [formattedData, setFormattedData] = useState<Category[]>([]);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const selectedFiltersSize = Object.keys(selectedFilters)?.length;

  useEffect(() => {
    setSelectedCategoryOptions(selectedFilters);
    setPreviousSelectedCategoryOptions(selectedFilters);
  }, [selectedFiltersSize]);

  const isAnyLabelPresent = (labels: string[], filters: any) =>
    labels.some((label: string) =>
      filters.some((filter: any) => filter?.value === label || filter?.elasticSearchType === label)
    );

  useEffect(() => {
    // filter the bottom filters according to selectedCategoryOptions
    if (!anchorEl || !formattedData?.length) {
      const selectedCategoryOptionsKeys = Object.keys(selectedCategoryOptions);
      const filteredData = data?.filter((each: any) => each.showInBottomFilter);
      let temp = filteredData?.slice(0, 3);
      // second condition in if statement is to handle the filters not part of bottom filters i.e. synonyms filter only being applied
      // bottom filters not able to display selected filters i.e. synonyms filter due to this filter is not part of bottom filters
      if (
        selectedCategoryOptionsKeys?.length &&
        isAnyLabelPresent(selectedCategoryOptionsKeys, filteredData)
      ) {
        temp = filteredData?.filter((each: any) => {
          if (each.filterType === FILTER_TYPE.MULTI_SEARCH) {
            return each.options.some((item: any) =>
              selectedCategoryOptionsKeys?.slice(0, 3)?.includes(item?.value)
            );
          }
          return selectedCategoryOptionsKeys
            ?.slice(0, 3)
            ?.includes(each?.elasticSearchType || each?.value);
        });
      }

      setFormattedData(temp);
    }
  }, [data, selectedCategoryOptions, anchorEl]);

  const handleCategoryClick = (
    value: (EventTarget & HTMLButtonElement) | null = null,
    category: Category | null = null
  ) => {
    setAnchorEl(prev => (prev !== value ? value : null));
    setSelectedCategory(category);
  };

  const handleClose = () => {
    if (isEqual(selectedCategoryOptions, previousSelectedCategoryOptions)) {
      handleCategoryClick();
      setShowAlert(false);
    } else {
      setShowAlert(true);
    }
  };

  const handleAlertClose = () => {
    setAnchorEl(null);
    setShowAlert(false);
  };

  const handleApplyClose = () => {
    handleAlertClose();
    setPreviousSelectedCategoryOptions(selectedCategoryOptions);
  };

  const handleReset = async () => {
    setSelectedCategoryOptions({});
    setPreviousSelectedCategoryOptions({});
    setShowFilterSidebar(false);
    await applyFilters({});
  };

  const handleCountClick = () => {
    if (!isLoading) {
      setShowFilterSidebar(!showFilterSidebar);
    }
  };

  return (
    <>
      <Box sx={filterStyles.outerContainer}>
        <Box sx={filterStyles.filterIconBox}>
          <FilterFilledIcon sx={filterStyles.filterIcon} />
        </Box>
        <Box sx={filterStyles.innerContainer}>
          {formattedData?.map((each: any) => {
            const valueKey = each?.elasticSearchType || each?.value;
            let selectedOptionCount = 0;
            if (each.filterType === FILTER_TYPE.MULTI_SEARCH) {
              each?.options?.forEach((item: any) => {
                selectedOptionCount += selectedCategoryOptions[item.value]?.length || 0;
              });
            } else {
              selectedOptionCount = selectedCategoryOptions[valueKey]?.length;
            }
            const isCategoryOptionSelected = selectedOptionCount > 0;
            return (
              <Button
                disabled={isLoading}
                size='small'
                key={each.value}
                sx={{
                  ...filterStyles.categoryButton,
                  color: isCategoryOptionSelected ? 'white.main' : 'gray.900',
                  border:
                    selectedCategory?.value === each.value
                      ? `1px solid ${palette.primary[600]}`
                      : 'none'
                }}
                style={{
                  backgroundColor: isCategoryOptionSelected ? '#0D917D' : '#FFFFFF'
                }}
                endIcon={isCategoryOptionSelected ? null : <AddIcon />}
                onClick={ev => handleCategoryClick(ev.currentTarget, each)}>
                {isCategoryOptionSelected &&
                each.filterType !== FILTER_TYPE.DATE_RANGE &&
                each.filterType !== FILTER_TYPE.YEAR_RANGE &&
                each.filterType !== FILTER_TYPE.NUMBER_RANGE &&
                each.filterType !== FILTER_TYPE.RADIO
                  ? `${each.label} (${selectedOptionCount})`
                  : each.label}
              </Button>
            );
          })}
          {Object.keys(selectedCategoryOptions)?.length > 3 && (
            <Box
              sx={{ ...filterStyles.countCircle, opacity: isLoading ? 0.38 : 1 }}
              onClick={handleCountClick}>
              {`+${Object.keys(selectedCategoryOptions).length - 3}`}
            </Box>
          )}
          {Object.keys(selectedCategoryOptions)?.length > 0 && data?.length > 0 && (
            <Button
              disabled={isLoading}
              size='small'
              sx={{ ...styles.clearAll, color: 'gray.800' }}
              onClick={handleReset}>
              Reset
            </Button>
          )}
          <Divider
            orientation='vertical'
            flexItem
            sx={{
              borderRight: `1px solid ${palette.gray[900]}`,
              height: 24,
              mt: 0.5
            }}
          />
          <Button
            disabled={isLoading || data?.length === 0}
            size='small'
            sx={filterStyles.allFiltersButton}
            endIcon={<MoreVertIcon sx={{ color: 'gray.800' }} />}
            onClick={() => setShowFilterSidebar(!showFilterSidebar)}>
            All Filters
          </Button>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            sx={{ mt: '-14px' }}>
            {selectedCategory?.filterType === FILTER_TYPE.SELECT_OPTIONS && (
              <AutoSearchOptions
                selectedCategory={selectedCategory}
                selectedCategoryOptions={selectedCategoryOptions}
                setSelectedCategoryOptions={setSelectedCategoryOptions}
                handleClose={handleClose}
                handleApplyClose={handleApplyClose}
                applyFilters={applyFilters}
                isLoading={isLoading}
                setShowFilterSidebar={setShowFilterSidebar}
                previousSelectedCategoryOptions={previousSelectedCategoryOptions}
              />
            )}

            {(selectedCategory?.filterType === FILTER_TYPE.DATE_RANGE ||
              selectedCategory?.filterType === FILTER_TYPE.YEAR_RANGE) && (
              <DateYearRangeOptions
                selectedCategory={selectedCategory}
                selectedCategoryOptions={selectedCategoryOptions}
                setSelectedCategoryOptions={setSelectedCategoryOptions}
                handleClose={handleClose}
                handleApplyClose={handleApplyClose}
                applyFilters={applyFilters}
                isLoading={isLoading}
                setShowFilterSidebar={setShowFilterSidebar}
                previousSelectedCategoryOptions={previousSelectedCategoryOptions}
                setPreviousSelectedCategoryOptions={setPreviousSelectedCategoryOptions}
              />
            )}

            {selectedCategory?.filterType === FILTER_TYPE.NUMBER_RANGE && (
              <NumberRangeOptions
                selectedCategory={selectedCategory}
                selectedCategoryOptions={selectedCategoryOptions}
                setSelectedCategoryOptions={setSelectedCategoryOptions}
                isLoading={isLoading}
                applyFilters={applyFilters}
                handleClose={handleClose}
                handleApplyClose={handleApplyClose}
                setShowFilterSidebar={setShowFilterSidebar}
                minValue={selectedCategory.min}
                maxValue={selectedCategory.max}
              />
            )}

            {selectedCategory?.filterType === FILTER_TYPE.RADIO && (
              <RadioGroupsOptions
                selectedCategory={selectedCategory}
                selectedCategoryOptions={selectedCategoryOptions}
                setSelectedCategoryOptions={setSelectedCategoryOptions}
                isLoading={isLoading}
                applyFilters={applyFilters}
                handleClose={handleClose}
                handleApplyClose={handleApplyClose}
                setShowFilterSidebar={setShowFilterSidebar}
                categoryOption={selectedCategory?.optionsList ?? []}
              />
            )}

            {selectedCategory?.filterType === FILTER_TYPE.SEARCH && (
              <SearchKeywordOptions
                selectedCategory={selectedCategory}
                selectedCategoryOptions={selectedCategoryOptions}
                setSelectedCategoryOptions={setSelectedCategoryOptions}
                applyFilters={applyFilters}
                isLoading={isLoading}
                setShowFilterSidebar={setShowFilterSidebar}
                previousSelectedCategoryOptions={previousSelectedCategoryOptions}
                handleApplyClose={handleApplyClose}
                handleClose={handleClose}
                isBottomFilterView
              />
            )}
            {selectedCategory?.filterType === FILTER_TYPE.MULTI_SEARCH && (
              <MultiSearchKeywordOptions
                selectedCategory={selectedCategory}
                selectedCategoryOptions={selectedCategoryOptions}
                setSelectedCategoryOptions={setSelectedCategoryOptions}
                applyFilters={applyFilters}
                isLoading={isLoading}
                setShowFilterSidebar={setShowFilterSidebar}
                previousSelectedCategoryOptions={previousSelectedCategoryOptions}
                handleApplyClose={handleApplyClose}
                handleClose={handleClose}
                isBottomFilterView
              />
            )}
          </Popover>
        </Box>
      </Box>
      <ApplyFilterAlert
        showAlert={showAlert}
        applyFilters={applyFilters}
        selectedCategoryOptions={selectedCategoryOptions}
        previousSelectedCategoryOptions={previousSelectedCategoryOptions}
        setSelectedCategoryOptions={setSelectedCategoryOptions}
        handleClose={handleAlertClose}
      />
      {showFilterSidebar && (
        <FilterSidebar
          showFilterSidebar={showFilterSidebar}
          setShowFilterSidebar={() => setShowFilterSidebar(false)}
          data={data}
          selectedCategoryOptions={selectedCategoryOptions}
          setSelectedCategoryOptions={setSelectedCategoryOptions}
          applyFilters={applyFilters}
          isLoading={isLoading}
          previousSelectedCategoryOptions={previousSelectedCategoryOptions}
          setPreviousSelectedCategoryOptions={setPreviousSelectedCategoryOptions}
        />
      )}
    </>
  );
};

export default NewFilters;
