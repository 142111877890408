import { createSvgIcon } from '@mui/material';

const CloseIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
    <path
      d='M15.6451 0.365596C15.1718 -0.105751 14.4073 -0.105751 13.934 0.365596L8 6.26347L2.06598 0.35351C1.59272 -0.117837 0.828214 -0.117837 0.354949 0.35351C-0.118316 0.824856 -0.118316 1.58626 0.354949 2.05761L6.28897 7.96757L0.354949 13.8775C-0.118316 14.3489 -0.118316 15.1103 0.354949 15.5816C0.828214 16.053 1.59272 16.053 2.06598 15.5816L8 9.67167L13.934 15.5816C14.4073 16.053 15.1718 16.053 15.6451 15.5816C16.1183 15.1103 16.1183 14.3489 15.6451 13.8775L9.71104 7.96757L15.6451 2.05761C16.1062 1.59835 16.1062 0.824857 15.6451 0.365596Z'
      fill='currentColor'
    />
  </svg>,
  'CloseIcon'
);

export default CloseIcon;
