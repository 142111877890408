import React from 'react';
import { createSvgIcon } from '@mui/material';

const DocumentIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
    <path
      d='M4.66683 8H11.3335V9.33333H4.66683V8ZM4.66683 12H9.3335V10.6667H4.66683V12ZM14.6668 5.05733V16H1.3335V2C1.3335 1.46957 1.54421 0.960859 1.91928 0.585786C2.29436 0.210714 2.80306 0 3.3335 0L9.6095 0L14.6668 5.05733ZM10.0002 4.66667H12.3908L10.0002 2.276V4.66667ZM13.3335 14.6667V6H8.66683V1.33333H3.3335C3.15669 1.33333 2.98712 1.40357 2.86209 1.5286C2.73707 1.65362 2.66683 1.82319 2.66683 2V14.6667H13.3335Z'
      fill='#0076D6'
    />
  </svg>,
  'Document'
);

export default DocumentIcon;
