import { useState } from 'react';

// mui
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import Button from '@mui/material/Button';
import StepConnector from '@mui/material/StepConnector';
import QontoStepIcon from '../styledComponents/StepperIcon';

// styles
import styles from '../styles/Content.styles';

const GuideStepper = ({ guide }) => {
  const [activeStep, setActiveStep] = useState(0);
  const handleReset = () => {
    setActiveStep(0);
  };
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const connector = (
    <StepConnector
      classes={{
        active: styles.connectorActive,
        completed: styles.connectorCompleted,
        disabled: styles.connectorDisabled,
        line: styles.connectorLine
      }}
    />
  );

  return (
    <Container>
      {/* stepper headings for mobile view */}
      {activeStep < guide.steps.length && (
        <Stack sx={{ display: { md: 'none', lg: 'none', xl: 'none' } }}>
          {guide.steps[activeStep]?.id}
          <Typography>{guide.steps[activeStep].text}</Typography>
        </Stack>
      )}

      {/* stepper view */}
      <Stepper
        connector={connector}
        sx={{ display: { sm: 'none', xs: 'none', md: 'flex', lg: 'flex', xl: 'flex' } }}
        activeStep={activeStep}
        alternativeLabel>
        {guide.steps?.map(item => {
          return (
            <Step key={item.id}>
              <StepLabel StepIconComponent={QontoStepIcon}>
                {item.id}
                <Typography variant='subtitle2' sx={styles.stepText}>
                  {item.text}
                </Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {/* stepper action buttons */}
      <Stack mt={2} justifyContent='center' spacing={2} direction='row'>
        {activeStep === guide.steps.length && (
          <Button
            onClick={handleReset}
            disabled={activeStep === 0}
            variant='contained'
            sx={styles.actionBtn}>
            Reset
          </Button>
        )}
        {activeStep < guide.steps.length && (
          <>
            <Button
              onClick={handleBack}
              sx={styles.actionBtn}
              disabled={activeStep === 0}
              variant='contained'>
              Back
            </Button>
            <Button onClick={handleNext} sx={styles.actionBtn} variant='contained'>
              {activeStep === guide.steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </>
        )}
      </Stack>
      {/* This is to preload images */}
      <Box sx={styles.preload}>
        {guide.steps?.map(step => (
          // eslint-disable-next-line jsx-a11y/alt-text
          <img src={step.image} />
        ))}
      </Box>
      {/* images */}
      {activeStep < guide.steps.length && (
        <Stack
          mt={2}
          justifyContent='center'
          direction='row'
          sx={{
            width: '100%',
            height: { xl: '100%', lg: '100%', md: '100%', sm: '40%', xs: '40%' }
          }}>
          <img
            src={guide.steps[activeStep]?.image}
            alt='guide'
            style={{ width: '80%', height: '100%', border: '1px solid gray', borderRadius: '30px' }}
          />
        </Stack>
      )}
    </Container>
  );
};

export default GuideStepper;
