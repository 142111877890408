import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  graphContainer: {
    width: '100%',
    // height: '70vh',
    [theme.breakpoints.up('xl')]: {
      // height: '50vh',
    }
  },
  paper: {
    height: '100%',
    width: '90%',
    marginRight: theme.spacing(2),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1.25)
  },
  graphBox: {
    height: '35vh',
    padding: '1rem',
    backgroundColor: theme.palette.white,
    borderRadius: '24px',
    [theme.breakpoints.up('xl')]: {
      height: '40vh'
    },
    marginTop: '5vh',
    marginBottom: '2vh'
  },
  loaderBox: {
    width: '90%',
    height: '90%'
    // border:'1px solid red'
  },
  barGraphRect: {
    width: '100%',
    height: '100%'
  },
  graph: {
    width: '100%'
  },
  headings: {
    color: theme.palette.biologics.textPrimary,
    fontWeight: 'bold',
    fontSize: theme.spacing(2),
    fontFamily: 'Mulish'
  },
  add: {
    fontSize: theme.spacing(1.87),
    color: theme.palette.navbar.button,
    cursor: 'pointer',
    fontFamily: 'Mulish'
  }
}));
