import { ADCOMM_FILTERS } from '../constant';

export const formatMeetingData = (dateStr: string) => {
  if (dateStr) {
    const date = new Date(dateStr);

    // Formatting options
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });

    return formattedDate;
  }
  return dateStr;
};
const getFilters = (apiData: any) => {
  const filters = ADCOMM_FILTERS.map((currentFilter: { [key: string]: any }) => ({
    ...currentFilter,
    options: [...(apiData?.[currentFilter?.value] || [])]
  }));

  return filters;
};

export default getFilters;
