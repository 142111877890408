const styles = {
  root: {
    marginTop: 0
  },
  container: {
    paddingRight: 1,
    height: '75vh',
    backgroundColor: 'white.main',
    overflow: 'scroll'
  },
  label: {
    fontSize: 16,
    color: 'gray.light',
    fontWeight: 'bold'
  }
};

export default styles;
