import React, { useMemo, useRef, useState } from 'react';

import Typography from '@mui/material/Typography';
import { Badge, Box, IconButton, Tooltip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import MenuWithTickOptions from '../../../components/ui/Menu/MenuWithTickOptions';
import styles from '../styles/ApplicationCard.styles';
import { ArrowDownIcon } from '../../../assets/svgs/Icons';
import buttonStyles from '../styles/Buttons.styles';
import MenuItemWithSubMenu from '../../../components/ui/Menu/MenuItemWithSubMenu/MenuItemWithSubMenu';
import reviewMenuStyles from '../styles/ReviewMenu.styles';

const DropDownButton = ({
  label,
  options = [],
  onOptionSelect,
  highlightOnSelect,
  tooltip = '',
  isMenuItem = false,
  MenuIcon = null,
  showBadge = false,
  handleParentMenuClose = null
}: any) => {
  const anchorEle = useRef(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const highlightCancelButton = useMemo(() => {
    return !!options.find((opt: any) => opt.selected) && highlightOnSelect;
  }, [options]);

  const handleClose = () => {
    setMenuOpen(false);
    handleParentMenuClose?.();
  };

  return (
    <>
      {isMenuItem ? (
        <MenuItemWithSubMenu
          MenuItemIcon={
            <Badge
              color='primary'
              variant='dot'
              invisible={!showBadge}
              sx={reviewMenuStyles.badgeDot}>
              <MenuIcon sx={buttonStyles.buttonIcon} />
            </Badge>
          }
          MenuItemText={label}>
          <MenuWithTickOptions
            options={options}
            onOptionSelect={(opt: any) => {
              onOptionSelect?.(opt);
              handleClose();
            }}
            isNestedMenu={isMenuItem}
          />
        </MenuItemWithSubMenu>
      ) : (
        <Tooltip title={tooltip}>
          <Box
            display='flex'
            alignItems='center'
            sx={highlightCancelButton ? styles.activeDropdownContainer : styles.dropdownContainer}
            ref={anchorEle}
            onClick={() => setMenuOpen(true)}>
            {highlightCancelButton && (
              <IconButton
                sx={{ p: 0, mr: '6px' }}
                onClick={e => {
                  e.stopPropagation();
                  onOptionSelect(options.find((opt: any) => opt.selected));
                }}>
                <CancelIcon sx={{ color: 'white.main', fontSize: 16 }} />
              </IconButton>
            )}
            <Typography fontSize={14} fontWeight={700} sx={{ cursor: 'pointer' }}>
              {label}
            </Typography>
            <ArrowDownIcon
              sx={{
                ...buttonStyles.arrowDownIcon,
                color: highlightCancelButton ? '#FFFFFF' : 'primary.500'
              }}
            />
          </Box>
        </Tooltip>
      )}
      {!isMenuItem && (
        <MenuWithTickOptions
          open={menuOpen}
          anchorEl={anchorEle.current}
          onClose={handleClose}
          options={options}
          onOptionSelect={(opt: any) => {
            onOptionSelect?.(opt);
            handleClose();
          }}
          isNestedMenu={isMenuItem}
        />
      )}
    </>
  );
};

export default DropDownButton;
