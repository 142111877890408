const styles = {
  container: {
    alignItems: 'center',
    lineHeight: '24px',
    width: 1,
    height: 1,
    position: 'relative',
    display: 'flex'
  },
  refElement: {
    height: 1,
    display: 'block',
    position: 'absolute',
    top: 0
  },
  cellValue: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
  paper: {
    maxHeight: 300,
    overflowY: 'auto',
    borderRadius: '8px',
    p: 1,
    width: 400,
    ml: -10
  },
  contentWrapper: {
    width: '100%',
    textAlign: 'left',
    backgroundColor: 'primary.backgroundDark',
    marginBottom: '10px',
    borderRadius: '16px',
    padding: 2,
    textTransform: 'capitalize'
  },
  text: { fontSize: '14px' }
};

export default styles;
