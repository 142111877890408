import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grow,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { AddCircleOutlined, Close } from '@mui/icons-material';
import { v4 } from 'uuid';
import { useHistory, useLocation } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import AdvancedSearchItems from './AdvancedSearchItems';
import { getCategories } from '../../../api/modules/advancedSearch';
import AdvancedSearchLoading from './AdvancedSearchLoading';
import GlobalActions from '../../../store/actions';
import {
  cnfToAdvancedSearch,
  generateCNFQuery,
  handleAdvancedSearch
} from '../../../components/Header/utils/advanceSearchHelpers';
import Store from '../../../store';
import SearchIcon from '../../../assets/svgs/Icons/SearchIcon';
import AdvanceSearchIcon from '../../../assets/svgs/Icons/AdvanceSearchIcon';
import styles from '../styles/SearchBar.styles';
import { AdvancedSearchProps, AdvancedSearchRow, AdvancedSearchValue } from '../types';
import { decodeBase64ToObject } from '../../../utils/encodeDecodeObject';
import {
  ADVANCED_SEARCH_REGION_LIST,
  ADVANCED_SEARCH_SCOPE_LIST,
  ENABLE_CLINICAL_TRIALS
} from '../const';
import { findRelevantAdvancedSearchScopeRegion } from '../utils';
import getDate from '../../../utils/getDate';
import AdvanceSearchTitleText from './AdvanceSearchTiteText';
import ResultsStore from '../../../store/SearchResults';
import ResultActions from '../../../store/SearchResults/actions';
import AdvanceSearchAlert from './AdvanceSearchAlert';
import CustomCheckbox from '../../../components/CustomComponents/Checkbox';
import CustomSwitch from './CustomSwitch';
import HIGHLIGHT_COLOR_LIST from '../../../components/PDFViewer/constants';

const AdvancedSearch = ({
  selectedSources,
  type = 'iconButton',
  active = false
}: AdvancedSearchProps) => {
  const { dispatch } = useContext<any>(Store);
  const location = useLocation();
  const history = useHistory();
  const [categoryOptions, setCategoryOptions] = useState<any>([
    {
      id: '',
      label: ''
    }
  ]);
  const [open, setOpen] = useState(false);
  const [scope, setScope] = useState('biologics');
  const [regionList, setRegionList] = useState(['us']);
  const [advancedSearch, setAdvancedSearch] = useState<AdvancedSearchValue>({
    startDate: new Date(2019, 0, 1),
    endDate: new Date(),
    useSynonyms: true
  });
  const [advancedSearchTerm, setAdvancedSearchTerm] = useState<AdvancedSearchRow[]>([
    {
      category: '',
      condition: 'contains',
      subCategory: [],
      searchTerm: '',
      categoryKey: '',
      id: v4(),
      createdOrder: 0,
      exactMatch: false,
      compareCondition: 'AND',
      highlightedColor: HIGHLIGHT_COLOR_LIST[0]
    }
  ]);
  const [disabled, setDisabled] = useState(true);
  const [highlightRowIds, setHighlightRowIds] = useState<number[]>([]);
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const [categoryLoading, setCategoryLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const onClose = (event: any = null, reason: any = '') => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpen(false);
  };

  const handleAlertClose = () => {
    resultsDispatch({ type: ResultActions.SET_ADVANCE_SEARCH_ALERT_MSG, value: false });
  };

  /**
   * Generates a mapping payload for sources based on region and scope.
   * - 'hma' is part of the 'eu' source.
   * - 'usnlm' is part of the 'ct' source when the scope is 'clinicalTrials'.
   *
   * @returns {Object} Object containing region-to-source mappings.
   */
  const getSourceMappingPayload = () => {
    const sourcesObj: any = {};

    // Iterate through each region in regionList
    regionList.forEach((regionValue: string) => {
      // Find data corresponding to the current regionValue
      const data = ADVANCED_SEARCH_REGION_LIST.find(item => item.value === regionValue);

      // Check if data exists and matches the current scope
      if (data?.module?.[scope]) {
        // Handle special case for 'hma' (add it to 'eu')
        if (regionValue === 'hma') {
          sourcesObj.eu = [...(sourcesObj.eu || []), 'hma'];
        }
        // Handle special case for 'us' (add 'usnlm' to 'ct' for 'clinicalTrials' scope)
        else if (regionValue === 'us' && scope === 'clinicalTrials') {
          sourcesObj.ct = ['usnlm'];
        }
        // For all other regionValues, ensure the region is initialized and add module sources
        else {
          // Initialize array for the region if not already present
          sourcesObj[regionValue] = sourcesObj[regionValue] || [];

          // Avoid duplicates by checking if the source already exists
          if (!sourcesObj[regionValue].includes(data.module[scope])) {
            sourcesObj[regionValue].push(data.module[scope]);
          }
        }
      }
    });

    return sourcesObj;
  };
  const addNewRow = () => {
    setAdvancedSearchTerm((prev: any) => {
      prev.push({
        category: '',
        condition: 'contains',
        searchTerm: '',
        subCategory: [],
        categoryKey: '',
        id: v4(),
        createdOrder: prev.length,
        exactMatch: false,
        compareCondition: 'AND',
        highlightedColor: HIGHLIGHT_COLOR_LIST[prev.length % HIGHLIGHT_COLOR_LIST.length]
      });
      return [...prev];
    });
  };

  const hasDuplicates = (advancedSearchTermList: AdvancedSearchRow[]) => {
    return advancedSearchTermList.some((term, index) => {
      const searchTermLower: any = term.searchTerm.trim().toLowerCase();
      const categoryLower: any = term.category.trim().toLowerCase();

      return advancedSearchTermList.some((otherTerm, otherIndex) => {
        if (otherIndex <= index) return false; // Skip already checked terms
        return (
          searchTermLower === otherTerm.searchTerm.trim().toLowerCase() &&
          categoryLower === otherTerm.category.trim().toLowerCase() &&
          term.subCategory.every(subCat => otherTerm.subCategory.includes(subCat))
        );
      });
    });
  };

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      const localAdvancedSearch = { ...advancedSearch };
      const checkIfContainsExist = advancedSearchTerm.some(
        ({ condition }) => condition === 'contains' || condition === 'mayContain'
      );
      if (!checkIfContainsExist) {
        dispatch({
          type: GlobalActions.SET_ALERT,
          value: {
            message: 'At least one row should have "Contains" as the condition',
            status: true
          }
        });
        return;
      }

      const checkIfDuplicateExist = hasDuplicates(advancedSearchTerm);
      if (checkIfDuplicateExist) {
        dispatch({
          type: GlobalActions.SET_ALERT,
          value: {
            message: 'Duplicate rows are not allowed',
            status: true
          }
        });
        return;
      }
      // Check if for any row exact match is false
      const listDataAfterExactMatch = advancedSearchTerm.map(
        ({ exactMatch, searchTerm, ...rest }) => ({
          searchTerm: exactMatch ? `"${searchTerm}"` : searchTerm,
          exactMatch,
          ...rest
        })
      );
      // check if 'approval_date' is selected
      const approvalDate = listDataAfterExactMatch.filter(
        ({ category }) => category === 'approval_date'
      );
      if (
        approvalDate.length <= 0 &&
        localAdvancedSearch?.startDate &&
        localAdvancedSearch?.endDate
      ) {
        // @ts-ignore
        delete localAdvancedSearch.startDate;
        // @ts-ignore
        delete localAdvancedSearch.endDate;
      }
      const url = handleAdvancedSearch(
        listDataAfterExactMatch,
        localAdvancedSearch,
        getSourceMappingPayload(),
        null,
        true
      );

      if (Object.keys(resultsState?.filters)?.length > 0) {
        // filters need to be reset with each new advance search
        // this will reset the applied bottom filters and synonym filters when user is trying to execute new advance search
        resultsDispatch({ type: ResultActions.SET_FILTERS, value: {} });
        // search api was calling twice when executing new advance search from result page
        resultsDispatch({ type: ResultActions.SET_IS_NEW_ADVANCE_SEARCH, value: true });
      }
      history.push(url);
      onClose();
    },
    [history, onClose, advancedSearch, advancedSearchTerm, dispatch]
  );

  const disableAddCheck = useMemo(() => {
    return (
      Object.values(advancedSearchTerm).filter(
        a =>
          a.category === '' ||
          (a.searchTerm === '' && a.category !== 'approval_date') ||
          (a.category === 'approval_date' && (!advancedSearch.startDate || !advancedSearch.endDate))
      ).length > 0
    );
  }, [advancedSearchTerm]);
  const tooltipTitle = useMemo(() => {
    if (type === 'text') return 'Show Advanced Search query';
    return 'Advanced search';
  }, [type]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCategoryOptions([]);
    setRegionList(['us']); // change in source -> make 'us' as default region
    if ((event.target as HTMLInputElement).value === 'clinicalTrials') {
      const selectedRegion = regionList.filter(source => ENABLE_CLINICAL_TRIALS.includes(source));
      if (selectedRegion?.length !== 0) {
        setRegionList(selectedRegion);
      } else {
        setRegionList(['us']);
      }
    }
    setScope((event.target as HTMLInputElement).value);
  };
  const handleRegionChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setCategoryOptions([]);
    if (scope === 'clinicalTrials') {
      setRegionList([value]);
    } else if (regionList.includes(value)) {
      // Remove the region from the list if it already exists
      setRegionList(regionList.filter((regionValue: string) => regionValue !== value));
    } else {
      // Add the region to the list if it doesn't exist
      setRegionList([...regionList, value]);
    }
  };

  const isSubCategoryMatched = (subCatId: any, subcategories: any[]) => {
    // Check if subCatId matches any subcategory or subsection id
    return subcategories.some((subCat: any) => {
      const isInSubCategory = subCat?.id?.trim() === subCatId?.id?.trim();
      const isInSubsection = subCat.subsection?.some(
        (subsection: any) => subsection?.id?.trim() === subCatId?.id?.trim()
      );
      return isInSubCategory || isInSubsection;
    });
  };

  // remove the subcategories from advanceSearch item which are not comman in selected regions
  const filterSubCategories = (categoryList: any[], search: any) => {
    const categoryEntry = categoryList.find(cat => cat.id === search.category);

    if (!categoryEntry?.subcategories || !search?.subCategory?.length) {
      return search;
    }

    const filteredSubCategories = search.subCategory.filter((subCatId: any) =>
      isSubCategoryMatched(subCatId, categoryEntry.subcategories)
    );

    return { ...search, subCategory: filteredSubCategories };
  };

  const getDefaultSearchTerm = () => {
    return [
      {
        category: '',
        condition: 'contains',
        subCategory: [],
        searchTerm: '',
        categoryKey: '',
        id: v4(),
        createdOrder: 0,
        exactMatch: false,
        compareCondition: 'AND',
        highlightedColor: HIGHLIGHT_COLOR_LIST[0]
      }
    ];
  };

  // get categories from catgeory api
  const fetchCategoryList = async (): Promise<any[]> => {
    const res: any = await getCategories({ source: getSourceMappingPayload() });
    return res?.data?.body?.result || [];
  };

  // keep persistance if we change region
  const filterSearch = (prevSearchTerms: any[], categoryList: any[]) => {
    return prevSearchTerms.filter(search => categoryList.some(cat => cat.id === search.category));
  };

  const mapSearchTerms = (filteredSearch: any[], categoryList: any[]) => {
    return filteredSearch.map(search => filterSubCategories(categoryList, search));
  };

  const handleFilterSearchTerms = (categoryList: any[]) => {
    setAdvancedSearchTerm(prevSearchTerms => {
      const filteredSearch = filterSearch(prevSearchTerms, categoryList);
      const mappedSearch = mapSearchTerms(filteredSearch, categoryList);

      return mappedSearch.length !== 0 ? mappedSearch : getDefaultSearchTerm();
    });
  };
  useEffect(() => {
    const fetchCategoriesAndFilterSearch = async () => {
      if (!open) return;

      setCategoryLoading(true);
      try {
        const categoryList = await fetchCategoryList();
        setCategoryOptions(categoryList);
        handleFilterSearchTerms(categoryList);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setCategoryLoading(false);
        setDisabled(false);
      }
    };

    fetchCategoriesAndFilterSearch();
  }, [open, regionList, scope]);

  useEffect(() => {
    if (!selectedSources) return;
    const relevantScopeRegion = findRelevantAdvancedSearchScopeRegion(selectedSources);
    if (relevantScopeRegion && relevantScopeRegion.length > 0) {
      setScope(relevantScopeRegion[0]?.scope?.value ?? '');
      const regionValueList: Array<string> = [];
      relevantScopeRegion.forEach((item: any) => {
        regionValueList.push(item?.region?.value);
      });
      setRegionList(regionValueList);
    }
  }, [selectedSources]);

  const queryText = useMemo(() => {
    const queryTextVal: any = generateCNFQuery(
      advancedSearchTerm,
      categoryOptions,
      advancedSearch,
      true
    );
    const title: Array<string> = [];
    // removing starting and ending '(' and ')' symbol and adding '(' and  ')' to each query term except operators
    if (queryTextVal && queryTextVal.length !== 0) {
      queryTextVal.forEach((item: any) => {
        if (![' ( ', ' ) '].includes(item)) {
          if (item.includes(':')) {
            title.push(`(${item})`);
          } else {
            title.push(item);
          }
        }
      });
    }
    return title;
  }, [advancedSearchTerm, categoryOptions, advancedSearch]);

  useEffect(() => {
    if (location.pathname.startsWith('/search')) {
      const encodedPayload = location.pathname.split('/search/')[1];
      const searchPayload: any = decodeBase64ToObject(encodedPayload);

      const highlightedColor = searchPayload?.highlightedColor;
      if (searchPayload?.search_type === 'advanced') {
        const newStartDate = getDate(
          searchPayload?.approval_date?.start_date &&
            searchPayload?.approval_date?.start_date !== 'Invalid Date'
            ? searchPayload?.approval_date?.start_date
            : new Date(1900, 0, 2)
        );
        const newEndDate = getDate(
          searchPayload?.approval_date?.end_date &&
            searchPayload?.approval_date?.end_date !== 'Invalid Date'
            ? searchPayload?.approval_date?.end_date
            : new Date()
        );
        setAdvancedSearch({
          startDate: newStartDate,
          endDate: newEndDate,
          useSynonyms: searchPayload?.use_synonyms
        });
        const newSearchTerms =
          cnfToAdvancedSearch(searchPayload?.search_term ?? null, highlightedColor) ?? [];
        if (searchPayload?.approval_date?.start_date) {
          newSearchTerms.push({
            category: 'approval_date',
            condition: 'contains',
            searchTerm: '',
            categoryKey: 'approval_date',
            id: v4(),
            createdOrder: newSearchTerms.length,
            exactMatch: false
          });
        }
        if (newSearchTerms.length === 0) {
          newSearchTerms.push({
            category: '',
            condition: 'contains',
            searchTerm: '',
            categoryKey: '',
            id: v4(),
            createdOrder: 0,
            exactMatch: false,
            highlightedColor: HIGHLIGHT_COLOR_LIST[0]
          });
        }
        setAdvancedSearchTerm(newSearchTerms);
      } else {
        setAdvancedSearch({
          startDate: new Date(1900, 0, 1),
          endDate: new Date(),
          useSynonyms: true
        });
        setAdvancedSearchTerm([
          {
            category: '',
            condition: 'contains',
            searchTerm: '',
            subCategory: [],
            categoryKey: '',
            id: v4(),
            createdOrder: 0,
            exactMatch: false,
            compareCondition: 'AND',
            highlightedColor: HIGHLIGHT_COLOR_LIST[0]
          }
        ]);
      }
    }
  }, [location.pathname, open]);

  const checkRegionsInScopeList = (itemValue: any) => {
    return regionList.some(region => itemValue.enabled.includes(region));
  };

  return (
    <>
      {type === 'nav' && (
        <Tooltip title={tooltipTitle}>
          <IconButton
            onClick={() => {
              setOpen(true);
            }}
            sx={{
              ...styles.advanceSearchIcon,
              width: 35,
              height: 35,
              marginLeft: {
                md: '100%',
                lg: '95%'
              }
            }}>
            <Badge
              variant='dot'
              color='primary'
              invisible={!active}
              sx={{ '& .MuiBadge-badge': { backgroundColor: 'primary.600' } }}>
              <AdvanceSearchIcon />
            </Badge>
          </IconButton>
        </Tooltip>
      )}
      {type === 'iconButton' && (
        <Tooltip title={tooltipTitle}>
          <IconButton sx={styles.advanceSearchIcon} onClick={() => setOpen(true)}>
            <AdvanceSearchIcon />
          </IconButton>
        </Tooltip>
      )}
      {type === 'text' && (
        <Tooltip title={tooltipTitle}>
          <Button
            variant='text'
            onClick={() => setOpen(true)}
            sx={{
              paddingLeft: '4px',
              fontSize: 14,
              textTransform: 'capitalize',
              color: 'primary.700',
              fontWeight: 700
            }}>
            Advanced Search
          </Button>
        </Tooltip>
      )}
      <Dialog open={open} onClose={onClose} fullWidth maxWidth='lg-md' sx={styles.dialogBox}>
        <DialogTitle p='0 !important' mb='16px'>
          <Box>
            {resultsState.advanceSearchAlertMsg && (
              <AdvanceSearchAlert handleAlertClose={handleAlertClose} />
            )}
          </Box>
          <Typography
            textAlign='center'
            fontWeight={700}
            fontSize={20}
            sx={{ color: 'gray.800', p: 0 }}>
            Advanced Search
          </Typography>
          <Divider sx={styles.divider} />
          <IconButton title='Close' onClick={onClose} sx={styles.advanceSearchCloseIcon}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          {disabled && <AdvancedSearchLoading />}
          <Box sx={styles.query}>
            {queryText.length !== 0 && !categoryLoading ? (
              <Typography sx={{ ...styles.queryTitle, color: 'grey.800', fontWeight: 700 }}>
                {queryText.map((text: string, index: number) => {
                  if (text.includes('Label Sections:[')) {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <AdvanceSearchTitleText key={`${text}-${index}`} title={text} />
                    );
                  }
                  // eslint-disable-next-line react/no-array-index-key
                  return <span key={index}>&nbsp;{text}&nbsp;</span>;
                })}
              </Typography>
            ) : (
              <Typography sx={{ ...styles.queryTitle, color: 'grey.500', fontWeight: 400 }}>
                {categoryLoading
                  ? 'Loading...'
                  : 'Please make a category selection and enter your search term'}
              </Typography>
            )}
          </Box>
          <Box display='flex' height='400px' alignItems='flex-start' flexDirection='row'>
            <Box display='flex' flexDirection='column' sx={styles.scopeBox}>
              <FormControl>
                <FormLabel
                  id='scope-radio-buttons-group'
                  sx={{
                    ...styles.scopeRadio,
                    color: 'grey.600',
                    '&.Mui-focused': { color: 'grey.600' }
                  }}>
                  Scope
                </FormLabel>
                <RadioGroup value={scope} onChange={handleChange}>
                  {ADVANCED_SEARCH_SCOPE_LIST.map((item: any) => (
                    <FormControlLabel
                      key={item.value}
                      value={item.value}
                      sx={{ ...styles.scopeRadio, color: 'grey.800', ml: -1 }}
                      control={<Radio sx={styles.scopeRadioButton} />}
                      label={item.label}
                      disabled={item.enabled.length !== 0 ? !checkRegionsInScopeList(item) : false}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Divider sx={styles.dividerLine} />
              <FormControl>
                <FormLabel
                  id='source-radio-buttons-group'
                  sx={{
                    ...styles.scopeRadio,
                    mt: 0,
                    color: 'grey.600',
                    '&.Mui-focused': { color: 'grey.600' }
                  }}>
                  Region
                </FormLabel>
                {/* when scope is clinicalTrials convert all checkbox in to radio button */}
                {scope === 'clinicalTrials' ? (
                  <RadioGroup
                    value={
                      regionList && regionList?.length !== 0
                        ? regionList.filter(source => ['us', 'eu'].includes(source))[0]
                        : 'us'
                    }
                    onChange={handleRegionChange}>
                    {ADVANCED_SEARCH_REGION_LIST.map((item: any) => (
                      <FormControlLabel
                        key={item.value}
                        value={item.value}
                        sx={{ ...styles.scopeRadio, color: 'grey.800', ml: -1 }}
                        control={<Radio sx={styles.scopeRadioButton} />}
                        label={item.label}
                        disabled={item.enabled.length !== 0 ? !item.enabled.includes(scope) : false}
                      />
                    ))}
                  </RadioGroup>
                ) : (
                  <>
                    {ADVANCED_SEARCH_REGION_LIST.map((item: any) => (
                      <FormControlLabel
                        key={item.value}
                        sx={{ ...styles.checkBox, color: 'grey.800', ml: -1 }}
                        control={
                          <CustomCheckbox
                            checked={regionList.includes(item.value)}
                            value={item.value}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                              handleRegionChange(event, item.value)
                            }
                            size='small'
                            name={item.label}
                            sx={styles.checkBox}
                            disabled={
                              item.enabled.length !== 0 ? !item.enabled.includes(scope) : false
                            }
                          />
                        }
                        label={item.label}
                      />
                    ))}
                  </>
                )}
              </FormControl>
            </Box>
            <Box display='flex' flexDirection='column'>
              {advancedSearchTerm.map((row, index) => (
                <Box borderRadius={2} pl='16px' position='relative' key={row.id}>
                  <AdvancedSearchItems
                    id={row.id}
                    isHighlighted={highlightRowIds.includes(index)}
                    setHighlightRowIds={setHighlightRowIds}
                    category={row.category}
                    subCategoryList={row.subCategory}
                    searchTerm={row.searchTerm}
                    condition={row.condition}
                    exactMatch={row.exactMatch}
                    categoryOptions={categoryOptions}
                    setItem={setAdvancedSearchTerm}
                    disabled={disabled}
                    isLastItem={index === advancedSearchTerm.length - 1}
                    advancedSearch={advancedSearch}
                    setAdvancedSearch={setAdvancedSearch}
                    compareCondition={row.compareCondition}
                    rowIndex={index}
                    rowsLength={advancedSearchTerm.length}
                    rows={advancedSearchTerm}
                    regionList={regionList}
                    source={getSourceMappingPayload()}
                    categoryLoading={categoryLoading}
                  />
                </Box>
              ))}

              <Grow in={advancedSearchTerm.length < 5}>
                <Stack direction='row' justifyContent='center' width='100%'>
                  <Tooltip title='Add new row'>
                    <IconButton
                      disabled={disabled || disableAddCheck}
                      color='primary'
                      sx={{
                        ...styles.addNewRowButton
                      }}
                      onClick={addNewRow}>
                      <AddCircleOutlined sx={{ fontSize: 32 }} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Grow>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={styles.advanceSearchActions}>
          <FormControlLabel
            disabled={disabled}
            value={advancedSearch.useSynonyms}
            onChange={(e: any) => {
              setAdvancedSearch({ ...advancedSearch, useSynonyms: e.target?.checked });
            }}
            control={
              <CustomSwitch
                defaultChecked={advancedSearch.useSynonyms}
                value={advancedSearch.useSynonyms}
              />
            }
            label='Use Synonyms'
            labelPlacement='start'
            sx={{
              mr: '8px',
              color: 'primary.800'
            }}
          />
          <Button
            size='small'
            disabled={disabled || disableAddCheck || regionList.length === 0}
            startIcon={<SearchIcon style={{ fontSize: 12 }} />}
            sx={styles.searchButton}
            onClick={handleSubmit}>
            Advanced Search
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default memo(AdvancedSearch);
