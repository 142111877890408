import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  filterParent: {
    border: `1px solid ${theme.palette.navbar.borderDark}`,
    borderRadius: theme.spacing(1.25),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: '50px',
    minWidth: '150px',
    marginLeft: theme.spacing(1),
    background: theme.palette.white
  },
  filterParentPMR: {
    borderRadius: theme.spacing(1.25),
    // width : theme.spacing(30),
    // paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: '50px',
    minWidth: '150px',
    // marginLeft: theme.spacing(1),
    justifyContent: 'center'
  },
  filterParentL: {
    borderRadius: theme.spacing(1.25),
    background: theme.palette.navbar.grayBackground
  },
  filterLabel: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    textAlign: 'left',
    width: '100%',
    // color: theme.palette.primary.main,
    color: theme.palette.navbar.border
  },
  filterSelect: {
    paddingLeft: '4px',
    textAlign: 'left',
    fontSize: '2vh',
    width: '100%',
    color: theme.palette.biologics.textPrimary
  },
  filterSelectPMR: {
    // paddingLeft: '4px',
    textAlign: 'left',
    fontSize: '0.8rem',
    width: '100%',
    color: theme.palette.black
  },
  filterSelectL: {
    paddingLeft: '20px',
    textAlign: 'left',
    fontSize: '2vh',
    width: '100%',
    color: theme.palette.black
  },
  loginForm: {
    filterParent: {
      border: `2px solid ${theme.palette.primary.lightBorder}`,
      borderRadius: '15px',
      width: '60vh',
      minHeight: '50px',
      marginLeft: theme.spacing(1),
      backgroundColor: theme.palette.primary.lightBorder
    }
  },
  filterParentCompare: {
    borderRadius: '10px 10px 0px 0px',
    // width : theme.spacing(30),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: '50px',
    minWidth: '150px',
    marginLeft: theme.spacing(1),
    // backgroundColor: theme.palette.primary.lightBorder,
    background: '#33B187',
    color: 'white'
  },
  filterSelectC: {
    paddingLeft: '4px',
    textAlign: 'left',
    fontSize: '2vh',
    width: '100%',
    margin: 'auto',
    color: 'white'
  }
}));
