import { Typography, Box, CircularProgress } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './circularProgressGraph.styles';

interface CustomCircularProgressGraphProps {
  graphData: any;
  loading: boolean;
}

const CustomCircularProgressGraph = ({
  graphData = {},
  loading
}: CustomCircularProgressGraphProps) => {
  const [circleSize, setCircleSize] = useState(0);
  const boxRef = useRef(null) as any; // To reference the Box element

  useEffect(() => {
    if (boxRef?.current?.offsetHeight) {
      const boxHeight = boxRef?.current?.offsetHeight ?? 100;
      setCircleSize(boxHeight * 0.8);
    }
  }, [boxRef?.current?.offsetHeight]);

  const selectedItem = useMemo(() => {
    return Array.isArray(graphData?.data)
      ? graphData?.data.find((item: { id: any }) => item?.id === graphData?.keys) ?? {}
      : {};
  }, [graphData?.data, graphData?.keys]);

  const totalValue = useMemo(() => {
    return Array.isArray(graphData?.data)
      ? graphData?.data.reduce((sum: number, item: { value: number }) => sum + (item.value ?? 0), 0)
      : 0;
  }, [graphData?.data]);

  const percentage = useMemo(() => {
    return selectedItem && totalValue > 0 ? (selectedItem.value / totalValue) * 100 : 0;
  }, [selectedItem, totalValue]);
  return (
    <Box ref={boxRef} sx={styles.box}>
      {loading ? (
        <CircularProgress
          variant='indeterminate'
          value={100}
          size={200}
          thickness={3}
          sx={{ ...styles.circularProgress, color: 'gray.200' }}
        />
      ) : (
        <Box height='100%' width='100%' sx={styles.box}>
          {graphData?.data && graphData?.data?.length > 0 ? (
            <>
              <CircularProgress
                variant='determinate'
                value={100}
                size={circleSize}
                thickness={graphData?.graphStyle?.thickness}
                sx={{ ...styles.circularProgress, color: 'gray.200' }}
              />
              <CircularProgress
                variant='determinate'
                value={percentage}
                size={circleSize}
                thickness={graphData?.graphStyle?.thickness}
                sx={{ ...styles.circularProgress, color: graphData?.graphStyle?.color }}
              />
              <Box sx={styles.textContainer}>
                <Typography sx={styles.percentage}>{`${Math.round(percentage)}%`}</Typography>
                <Typography
                  sx={styles.totalText}>{`${selectedItem?.value} Out of ${totalValue}`}</Typography>
              </Box>
            </>
          ) : (
            <Typography variant='subtitle2' sx={{ fontSize: 20, fontWeight: 'bold' }}>
              No data
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default React.memo(CustomCircularProgressGraph);
