import palette from '../../../themev5/palette';

const ariaButtonBase = {
  borderRadius: '16px',
  textTransform: 'capitalize',
  fontSize: '12px',
  fontFamily: 'Mulish',
  fontStyle: 'normal',
  fontWeight: 400
};

const styles = {
  dropdownButton: {
    minWidth: '100px',
    borderRadius: '16px',
    borderColor: 'secondary.700',
    color: 'gray.0',
    textTransform: 'none',
    backgroundColor: 'secondary.700',
    fontSize: '12px',
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 600,
    '&:hover': { backgroundColor: 'secondary.800', borderColor: 'secondary.800' }
  },
  ariaButton: {
    ...ariaButtonBase,
    borderColor: 'secondary.700',
    backgroundColor: 'gray.0',
    color: 'gray.900',
    '&:hover': { backgroundColor: 'gray.150', borderColor: 'secondary.700' }
  },
  ariaButtonFilled: {
    ...ariaButtonBase,
    borderColor: 'secondary.700',
    backgroundColor: 'secondary.700',
    color: 'gray.0',
    '&:hover': { backgroundColor: 'secondary.800' }
  },
  buttonGroup: {
    right: '3%',
    height: '32px',
    position: 'fixed',
    bottom: 20,
    ml: -8,
    display: 'flex',
    justifyContent: 'flex-end',
    zIndex: 1
  },
  selectedTab: {
    width: '50%',
    borderRadius: 4,
    borderColor: palette.blue.backgroundLight,
    backgroundColor: palette.white.smoke,
    textTransform: 'capitalize',
    color: '#202020',
    fontSize: '0.75rem'
  },
  unselectedTab: {
    width: '50%',
    borderRadius: 4,
    borderColor: palette.blue.backgroundLight,
    color: '#FFFFFF',
    textTransform: 'capitalize',
    backgroundColor: palette.blue.backgroundLight,
    fontSize: '0.75rem'
  },
  chatRiaButton: {
    right: '4.5%',
    position: 'fixed',
    bottom: 30,
    ml: -8,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  snapshotHeading: {
    mb: 1,
    fontWeight: 700,
    lineHeight: '24px'
  },
  snapshotSubHeading: {
    mb: 1,
    fontWeight: 700,
    lineHeight: '24px',
    color: 'gray.800'
  },
  snapshotLabel: {
    minWidth: '30%',
    fontWeight: 400,
    lineHeight: '21px',
    color: 'gray.600'
  },
  snapshotText: {
    fontWeight: 500,
    lineHeight: '21px',
    color: 'gray.900'
  },
  snapshotYesText: {
    fontWeight: 500,
    lineHeight: '21px',
    color: 'primary.600'
  },
  snapshotNoText: {
    fontWeight: 500,
    lineHeight: '21px',
    color: 'red.900'
  },
  indicationText: {
    maxWidth: {
      sm: '200px',
      md: '300px',
      lg: '450px',
      xl: '600px',
      'lg-xl': '600px'
    },
    color: 'black.main',
    fontWeight: 600,
    lineHeight: '21px'
  },
  accordion: {
    mt: '28px !important',
    p: 1,
    width: '100%',
    borderRadius: '12px !important',
    borderWidth: 1,
    borderStyle: 'solid'
  },
  tabHeading: {
    fontWeight: 700,
    lineHeight: '21px',
    color: 'gray.600',
    textTransform: 'capitalize',
    '&.Mui-selected': {
      color: 'gray.900'
    }
  },
  drawerContainer: {
    '& >.MuiDrawer-paper': {
      height: '95vh',
      borderTopLeftRadius: '16px',
      borderTopRightRadius: '16px',
      position: 'absolute',
      backgroundColor: 'gray.100',
      overflow: 'hidden'
    }
  },
  snapshotTitle: {
    flexGrow: 1,
    textAlign: 'center',
    alignSelf: 'center',
    fontWeight: '700',
    fontSize: '20px',
    color: 'primary.darkVariant1'
  },
  actionsWrapper: {
    position: 'absolute',
    display: 'flex',
    left: '92%'
  },
  dividerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  divider: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'gray.400',
    borderRadius: '2px',
    mb: '16px',
    width: '64px'
  },
  gridContainer: {
    bgcolor: 'gray.100',
    py: 4,
    overflowY: 'auto'
  },
  verifyBtn: {
    color: 'primary.700',
    fontWeight: 600
  },
  disabledVerifyBtn: {
    color: 'gray.400',
    fontWeight: 600
  },
  verifyIcon: {
    fontSize: 14,
    color: 'primary.700'
  },
  disabledVerifyIcon: {
    fontSize: 14,
    color: 'gray.400'
  },
  badge: {
    backgroundColor: 'primary.300',
    width: '6px',
    height: '6px',
    mb: '6px',
    borderRadius: '5.538px'
  },
  avatar: {
    backgroundColor: 'primary.500',
    width: '10px',
    height: '10px',
    ml: '4px'
  },
  tabContainer: {
    ml: 4,
    '& .MuiTabs-indicator': {
      display: 'none'
    },
    '& .MuiTab-root': {
      minHeight: 32,
      minWidth: 136
    }
  },
  productTab: {
    minWidth: '0px !important',
    py: 1,
    mr: 1,
    borderRadius: 6,
    fontSize: 13
  },
  customDropdown: {
    main: {
      p: 2,
      borderRadius: 2,
      backgroundColor: 'gray.0',
      minWidth: 151,
      minHeight: 206,
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
    },
    radioCheckboxOption: {
      '&.Mui-checked': {
        color: 'primary.600'
      },
      '& .MuiSvgIcon-root': {
        fontSize: 16
      }
    },
    divider: {
      borderRight: '1px solid',
      borderColor: 'grey.200'
    },
    addButton: {
      marginTop: 2,
      color: 'gray.0',
      fontWeight: 700,
      textTransform: 'none',
      borderRadius: 4,
      height: 23,
      '&:hover': {
        bgcolor: 'primary.700'
      },
      '&:disabled': {
        color: 'gray.200'
      }
    }
  }
};

export default styles;
