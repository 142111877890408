import { getCategoryCountValue } from '../../../api/pages/ResultsPage';

const getCategoryCount = async (source: any, query: any, viewType: string, filters?: any) => {
  const payload = {
    source,
    search_term: query,
    view_type: viewType,
    filters
  };
  try {
    const response = await getCategoryCountValue(payload);
    if (response?.status === 200) {
      const value = response?.data?.body?.count;
      return value;
    }
    return [];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Category count failed:', e);
    return [];
  }
};

export default getCategoryCount;
