/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/no-named-as-default */
import React, { useContext } from 'react';

import { format } from 'date-fns';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import CustomTimelinePropsInterface from '../../../interfaces/CustomTimelinePropsInterface';
import DATE_FORMATS from './constants';
import UTCtoLocalTime from '../../Header/utils/localTimeZoneDate';

import TIMELINE_INFO from '../constants';
import styles from './TimelineDate.styles';
import { getApplicationType } from '../../../pages/RegulatoryInformation/ApprovalHistory/utils';

import RegulatoryInformationStore from '../../../store/RegulatoryInformation';

const formatFields: { [key: string]: string } = {
  ...DATE_FORMATS
};

const TimelineDate: React.FC<CustomTimelinePropsInterface> = ({
  column,
  dataItem,
  triggeredBy,
  source
}: CustomTimelinePropsInterface) => {
  if (column === undefined) {
    return <Box />;
  }

  const { regulatoryState } = useContext(RegulatoryInformationStore);

  const dateVal =
    dataItem[column.name] &&
    format(UTCtoLocalTime(dataItem[column.name]), formatFields[column.name]);
  return (
    <Tooltip title={dateVal}>
      <Box
        sx={
          triggeredBy !== 'rems' &&
          dataItem[
            TIMELINE_INFO[
              getApplicationType(source as string, regulatoryState?.applicationDetails)
            ][triggeredBy].dotField
          ] ===
            TIMELINE_INFO[
              getApplicationType(source as string, regulatoryState?.applicationDetails)
            ][triggeredBy].dotFieldCondition
            ? styles.TimelineDateDarkGreen
            : styles.TimelineDateSuccessGreen
        }>
        <Typography align='center' variant='subtitle1'>
          {source === 'hpra' && dateVal === '1900-01-01' ? 'Unknown' : dateVal}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default React.memo(TimelineDate);
