import lightLogin from '../../../Images/Group705.svg';

const styles: any = {
  cardContainer: {
    p: 2,
    bgcolor: 'white.main',
    borderRadius: 6,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  dialog: {
    height: '100%',
    backgroundImage: `url(${lightLogin})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed'
  },
  dialog2: {
    height: '86%',
    backgroundImage: `url(${lightLogin})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed'
  },
  compareLine: {
    display: 'inline-flex',
    borderTop: '1px solid #a2a2a2',
    marginBottom: '20px',
    width: '46%'
  },
  skeletonCard: {
    marginTop: 1,
    width: '34vh',
    height: '29vh',
    borderRadius: 16,
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  title: {
    width: '12vw',
    fontWeight: '600',
    color: 'gray.darkBluish'
  },
  titleHide: {
    width: '70%',
    fontWeight: '400',
    color: 'primary.background'
  },
  numberNDA: {
    paddingTop: 0.5,
    paddingBottom: 0.5,
    paddingLeft: 1.4,
    paddingRight: 1.4,
    borderRadius: 1.5,
    backgroundColor: 'red.light',
    color: 'white.main'
  },
  numberBLA: {
    paddingTop: 0.5,
    paddingBottom: 0.5,
    paddingLeft: 1.4,
    paddingRight: 1.4,
    borderRadius: 1.5,
    backgroundColor: 'blue.berry',
    color: 'white.main'
  },
  avatar: {
    marginLeft: 1,
    height: '3.2vh',
    width: '3.2vh',
    fontSize: '1.6vh',
    backgroundColor: 'green.jungle'
  },
  avatarLight: {
    marginLeft: 1,
    height: '3.2vh',
    width: '3.2vh',
    fontSize: '1.6vh',
    backgroundColor: 'gray.lightText'
  },
  avatarGrey: {
    marginLeft: 1,
    height: '3.2vh',
    width: '3.2vh',
    fontSize: '1.6vh',
    backgroundColor: 'gray.lightVariant2'
  },
  nameNDA: {
    marginTop: 1,
    color: 'red.light',
    textTransform: 'uppercase'
  },
  nameNDAR: {
    marginTop: 1,
    color: 'red.light',
    textDecoration: 'line-through',
    textTransform: 'uppercase'
  },
  nameBLA: {
    marginTop: 1,
    color: 'blue.berry',
    textTransform: 'uppercase'
  },
  nameBLAR: {
    marginTop: 1,
    color: 'blue.berry',
    textDecoration: 'line-through',
    textTransform: 'uppercase'
  },
  filingYear: {
    marginTop: 1,
    fontWeight: '600',
    color: 'gray.lightVariant2',
    fontSize: '2vh'
  },
  description: {
    marginTop: 0.3,
    fontWeight: '400',
    color: 'gray.lightVariant2',
    fontSize: '2vh'
  },
  sponsor: {
    marginTop: 0.3,
    fontWeight: '400',
    color: 'gray.lightVariant2',
    textTransform: 'uppercase',
    fontSize: '2vh'
  },
  close: {
    width: '100%',
    paddingTop: 2,
    paddingRight: 2,
    backgroundColor: 'primary.background'
  },
  regClick: {
    cursor: 'pointer'
  },
  favoriteButton: {
    backgroundColor: 'white',
    textTransform: 'capitalize'
  },
  favoriteIcon: {
    marginTop: -3,
    marginRight: -2,
    paddingBottom: 1
  },
  redNumber: {
    // width: '40%',
    paddingTop: 0.5,
    paddingBottom: 0.5,
    paddingLeft: 2,
    paddingRight: 2,
    borderRadius: 2,
    bgcolor: 'red.main',
    color: 'white.main'
  },
  purpleNumber: {
    paddingTop: 0.5,
    paddingBottom: 0.5,
    paddingLeft: 2,
    paddingRight: 2,
    borderRadius: 2,
    bgcolor: 'blue.main',
    color: 'white.main'
  },
  yellowNumber: {
    paddingTop: 0.5,
    paddingBottom: 0.5,
    paddingLeft: 2,
    paddingRight: 2,
    borderRadius: 2,
    bgcolor: 'orange.main',
    color: 'white.main'
  },
  redText: {
    color: 'red.main',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  yellowText: {
    color: 'orange.main',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  purpleText: {
    color: 'blue.main',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  metadata: {
    color: 'black.lightVariant'
    // fontWeight: 'bold',
    // opacity: 0.8
  },
  headerText: {
    color: 'gray.darkText'
  },
  actionBtns: {
    textTransform: 'capitalize',
    bgcolor: 'primary.backgroundLight',
    color: 'primary.dark',
    fontWeight: 'bold',
    '&:hover': {
      color: 'white.main'
    }
  }
};

export default styles;
