import makeRequest from '../client';

/**
 * Function to do get statistics for pediatrics submodule insights
 * @param {string} submodule
 */
export const getPediatricsStats = (submodule, payload) =>
  makeRequest(`/pediatrics/insights/${submodule}`, 'POST', payload, {});

/**
 * Function to do get all details for pediatrics submodule
 * @param {string} submodule
 */
export const getPediatricsDetails = (submodule, payload, limit, offset) =>
  makeRequest(`/pediatrics/${submodule}?limit=${limit}&offset=${offset}`, 'POST', payload);

export const getPediatricsDocumentsSignedURL = payload =>
  makeRequest('/pediatrics/resource', 'POST', payload);

export const getAllPediatricsDocuments = (submodule, payload) =>
  makeRequest(`/pediatrics/${submodule}`, 'POST', payload);
