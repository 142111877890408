import React from 'react';

import { Box, Typography } from '@mui/material';
import styles from './Metadata.styles';

const DocumentMetadata = ({ data = [] }: { data: string[] }) => {
  return (
    <Box display='flex' alignItems='center' mb={0.5}>
      {data.map((txt: string, index: number) => (
        <React.Fragment key={txt}>
          <Typography fontSize={13} color='gray.600' fontWeight={600}>
            {txt}
          </Typography>
          {data.length - 1 > index && <Box sx={styles.grayDot} />}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default React.memo(DocumentMetadata);
