import { Menu, MenuProps, styled } from '@mui/material';

const RoundedMenu = styled((props: MenuProps) => (
  <Menu
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    padding: '10px 0px'
  },
  '& .MuiMenuItem-root': {
    paddingRight: '32px'
  },
  '& .MuiListItemIcon-root': {}
}));

export default RoundedMenu;
