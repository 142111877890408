import { createSvgIcon } from '@mui/material';

const ListIcon = createSvgIcon(
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M16 7.33337H0V8.66672H16V7.33337Z' fill='currentColor' />
    <path d='M16 1.33337H0V2.6667H16V1.33337Z' fill='currentColor' />
    <path d='M16 13.3334H0V14.6667H16V13.3334Z' fill='currentColor' />
  </svg>,
  'ListIcon'
);

export default ListIcon;
