export const indicationSectionStyle: any = {
  width: '100%',
  pt: 1,
  maxHeight: '100%',
  overflowY: 'scroll',
  overflowX: 'hidden',
  whiteSpace: 'initial',
  textOverflow: 'break-word',
  overflowWrap: 'break-word',
  '&::-webkit-scrollbar': {
    width: '0.4em'
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,0.26)'
  }
};
export const snackBarStyle: any = {
  top: '66px !important',
  right: '0 !important'
};

export const snackBarContentStyle: any = {
  boxShadow: 2,
  backgroundColor: 'white.main',
  backdropFilter: 'blur(10px)',
  p: 5,
  width: '30vw',
  height: 'calc(100vh - 70px)',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  borderRadius: '30px 0 0 30px'
};

export const chipsContainerStyle: any = {
  mt: 1,
  mb: 2,
  '& > div': {
    mt: 1,
    mr: 1,
    borderRadius: '4px',
    '& > span > p': {
      fontSize: '12px'
    }
  }
};
