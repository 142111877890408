import { createSvgIcon } from '@mui/material';

const DownloadIcon = createSvgIcon(
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_6902_108407)'>
      <path
        d='M8.0213 12.6667C8.28403 12.6671 8.54427 12.6156 8.78704 12.5152C9.02982 12.4147 9.25035 12.2673 9.43596 12.0813L12.0486 9.46666L11.106 8.52666L8.68396 10.9493L8.66663 -7.62939e-06H7.3333L7.35063 10.9387L4.9373 8.52533L3.99463 9.46666L6.6073 12.0793C6.79269 12.2655 7.01304 12.4133 7.2557 12.5141C7.49835 12.6149 7.75854 12.6667 8.0213 12.6667Z'
        fill='currentColor'
      />
      <path
        d='M14.6667 10.6669V14.0002C14.6667 14.177 14.5964 14.3466 14.4714 14.4716C14.3464 14.5966 14.1768 14.6669 14 14.6669H2C1.82319 14.6669 1.65362 14.5966 1.5286 14.4716C1.40357 14.3466 1.33333 14.177 1.33333 14.0002V10.6669H0V14.0002C0 14.5306 0.210714 15.0393 0.585786 15.4144C0.960859 15.7895 1.46957 16.0002 2 16.0002H14C14.5304 16.0002 15.0391 15.7895 15.4142 15.4144C15.7893 15.0393 16 14.5306 16 14.0002V10.6669H14.6667Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_6902_108407'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'Download'
);

export default DownloadIcon;
