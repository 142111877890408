import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  graphs: {
    marginTop: theme.spacing(10),
    paddingTop: theme.spacing(10),
    width: '100%',
    height: '100%'
  },
  insightsTypo: {
    fontWeight: '600 !important',
    fontSize: '3vh !important',
    color: '#464255',
    textAlign: 'start',
    fontFamily: 'Nunito Sans !important'
  },
  graphBox: {
    // width: '90vw',
    display: 'inline-flex',
    flexDirection: 'column'
    // alignItems: 'center',
  },
  paper: {
    borderRadius: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  highlightText: {
    opacity: 0.6,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  }
}));
