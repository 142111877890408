/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';

// muiv5
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// utils
import {
  DATAGRID_COLUMN_MAPPING,
  DATAGRID_UNIQUE_FIELD
} from '../../../pages/ResultsPage/utils/constants';

import VivproDatagrid from '../../Datagrid';

// types
// context
import ResultsStore from '../../../store/SearchResults';

// styles
import styles from './CTListViewStyles';
import { getFileName } from '../../../helpers/getFileName';

const CTAdverseEventsListView = () => {
  const { resultsState } = useContext(ResultsStore);
  const [adverseEventsData, setAdverseEventsData] = useState([]);
  const [disclaimer, setDisclaimer] = useState('');

  const day = new Date();
  const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;

  const CSV_FILENAME = {
    prefix: 'ria',
    module: 'CT Adverse Effect',
    postfix: date,
    additional: '',
    extension: ''
  };

  useEffect(() => {
    if (Array.isArray(resultsState?.ctAdverseEvent)) {
      const totalNCTids = resultsState?.ctAdverseEvent.length;
      let numberOfReportedEvents = 0;
      const newData = resultsState?.ctAdverseEvent.map((result_row, row_index) => {
        const { nct_id, adverse_events = [], overall_status_formated = '' } = result_row;
        if (!adverse_events) return [];
        numberOfReportedEvents += 1;
        const advEventTransformedData = adverse_events.map((adv_event: any, adv_index: Number) => {
          const {
            adverse_event_term = '',
            organ_system = '',
            risk_ratio = 0,
            subjects_affected = 0,
            subjects_at_risk = 0,
            result_group_code = '',
            result_group_title = '',
            result_group_description = ''
          } = adv_event;
          let { event_type = '' } = adv_event;
          event_type = event_type ? event_type.charAt(0).toUpperCase() + event_type.slice(1) : '';
          return {
            nct_id,
            adverse_event_term,
            event_type,
            organ_system,
            risk_ratio,
            subjects_affected,
            subjects_at_risk,
            result_group_code,
            result_group_title,
            result_group_description,
            [DATAGRID_UNIQUE_FIELD.ct_adverse_events]: `${row_index}_${adv_index}_${risk_ratio}`,
            overall_status_formated
          };
        });
        return advEventTransformedData;
      });
      const result = newData.reduce((accumulator, value) => accumulator.concat(value), []);
      setAdverseEventsData(result);
      if (totalNCTids && numberOfReportedEvents < totalNCTids) {
        setDisclaimer(
          `${numberOfReportedEvents} out of ${totalNCTids} Clinical Trial(s) have reported events.`
        );
      }
    }
  }, [resultsState?.ctAdverseEvent]);

  return (
    <Stack id='ListData' sx={{ height: '65vh', width: '100%' }}>
      {disclaimer && (
        <Typography variant='caption' sx={styles.disclaimer}>
          {disclaimer}
        </Typography>
      )}
      <VivproDatagrid
        rows={adverseEventsData}
        columnsMapping={DATAGRID_COLUMN_MAPPING.ct_adverse_events}
        rowId={DATAGRID_UNIQUE_FIELD.ct_adverse_events}
        loading={resultsState?.loading || resultsState?.ctAdverseEventLoading}
        csvFileName={getFileName(CSV_FILENAME)}
        useQuickSearch
      />
    </Stack>
  );
};

export default CTAdverseEventsListView;
