import uniqBy from 'lodash/uniqBy';

const getMappedFilters = (filters: { [key: string]: any }) => {
  const mappedFilters: { [key: string]: any } = {};

  Object.entries(filters).forEach(([filterkey, filtervalue]) => {
    // Check if the filter value is Array of Object which has id
    if (Array.isArray(filtervalue) && filtervalue?.[0]?.id) {
      mappedFilters[filterkey] = uniqBy(filtervalue, 'id').map(
        (val: { [key: string]: any }) => val.id || ''
      );
    } else {
      mappedFilters[filterkey] = filtervalue;
    }
  });

  return mappedFilters;
};

export const getSynonymsFilter = (filters: { [key: string]: any }) => {
  // Method to group mesh treenumber by label.
  const meshTreeNumbers = filters.mesh_tree_numbers || [];
  const atcCodes = filters.atc_code || [];
  const names: any = {};

  meshTreeNumbers.forEach((tree: any) => {
    names[tree.label] = [...(names[tree.label] || []), tree.id];
  });

  const synonymFilters: any = uniqBy(meshTreeNumbers, 'label').map((tree: any) => {
    return {
      checked: true,
      label: tree.label,
      mesh_tree_numbers: names[tree.label]
    };
  });
  atcCodes.forEach((atc: any) => {
    synonymFilters.push({
      label: atc?.toUpperCase() || '',
      value: atc,
      checked: true
    });
  });

  return synonymFilters;
};

export default getMappedFilters;
