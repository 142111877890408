import { createSvgIcon } from '@mui/material';

const ThumbsUpIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
    <g clipPath='url(#clip0_494_3459)'>
      <path
        d='M13.2843 4.5041C13.0107 4.18887 12.6726 3.93609 12.2929 3.76289C11.9132 3.58968 11.5007 3.50009 11.0833 3.50018H8.75642L8.95242 2.3096C9.02177 1.89033 8.9381 1.46011 8.71667 1.09739C8.49524 0.73468 8.15081 0.463645 7.7462 0.333725C7.34158 0.203804 6.90375 0.223655 6.51255 0.389657C6.12136 0.555658 5.80286 0.856749 5.61517 1.23801L4.49867 3.50018H2.91667C2.1434 3.50111 1.40208 3.80869 0.855295 4.35547C0.308514 4.90225 0.00092625 5.64358 0 6.41685L0 9.33351C0.00092625 10.1068 0.308514 10.8481 0.855295 11.3949C1.40208 11.9417 2.1434 12.2493 2.91667 12.2502H10.675C11.377 12.2473 12.0547 11.9925 12.5847 11.5322C13.1147 11.0719 13.4619 10.4365 13.5631 9.74185L13.9743 6.82518C14.0322 6.41144 14.0006 5.99009 13.8815 5.58964C13.7625 5.18919 13.5588 4.81899 13.2843 4.5041ZM1.16667 9.33351V6.41685C1.16667 5.95272 1.35104 5.5076 1.67923 5.17941C2.00742 4.85122 2.45254 4.66685 2.91667 4.66685H4.08333V11.0835H2.91667C2.45254 11.0835 2.00742 10.8991 1.67923 10.5709C1.35104 10.2428 1.16667 9.79764 1.16667 9.33351ZM12.8164 6.66126L12.4046 9.57793C12.3444 9.99442 12.1367 10.3755 11.8193 10.6518C11.5019 10.9281 11.0958 11.0813 10.675 11.0835H5.25V4.51168C5.30497 4.46379 5.35048 4.40601 5.38417 4.34135L6.66108 1.75426C6.70897 1.66789 6.77667 1.59411 6.85862 1.53899C6.94056 1.48387 7.03442 1.44898 7.13248 1.4372C7.23053 1.42541 7.32998 1.43706 7.42266 1.47119C7.51534 1.50531 7.59859 1.56095 7.66558 1.63351C7.72289 1.70016 7.76479 1.77863 7.78829 1.86333C7.81178 1.94803 7.81629 2.03687 7.8015 2.12351L7.4935 3.99018C7.47998 4.07358 7.48474 4.15893 7.50745 4.24032C7.53017 4.3217 7.57029 4.39718 7.62504 4.46153C7.6798 4.52588 7.74788 4.57757 7.82458 4.61302C7.90127 4.64847 7.98476 4.66684 8.06925 4.66685H11.0833C11.3338 4.66681 11.5814 4.72055 11.8093 4.82443C12.0372 4.92831 12.2402 5.07991 12.4045 5.26898C12.5688 5.45806 12.6906 5.6802 12.7617 5.92039C12.8327 6.16058 12.8514 6.41323 12.8164 6.66126Z'
        fill='#0A806D'
      />
    </g>
    <defs>
      <clipPath id='clip0_494_3459'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'ThumbsUpIcon'
);

export default ThumbsUpIcon;
