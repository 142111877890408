import { memo, useCallback, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Event, KeyboardArrowDown, Search } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import SearchBarAutoComplete from '../../Home/components/SearchBarAutoComplete';
import useSearchSuggestions from '../hooks/useSearchSuggestions';
import {
  filterOptions,
  handleDateChangeOnAcceptUtil
} from '../../../components/Header/utils/searchUtils';
import useSourceCategory from '../../../components/Header/hooks/useSourceCategory';
import { SearchIcon } from '../../../assets/svgs/Icons';
import styles from '../../Home/styles/SearchBar.styles';
import { SEARCH_TYPE_DATE, SEARCH_TYPE_TEXT } from '../../Home/const';
import getFormattedDate from '../../../components/Header/utils/getFormattedDate';

const OpenPickerIcon = () => <Event sx={{ fontSize: 18 }} />;

const ComparisonSearch = () => {
  const history = useHistory();
  const location = useLocation();

  const { term }: { term: string } = useParams();
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [searchText, setSearchText] = useState(term || '');

  const { categoryList, selectedCategory, selectedCategoryType, handleCategoryChange } =
    useSourceCategory();

  const {
    searchSuggestions,
    isSuggestionsLoading,
    setIsSearchOpen,
    handleKeyPress,
    clearSearchSuggestions,
    isSearchOpen
  } = useSearchSuggestions(selectedCategory);

  const handleAutoCompleteOpen = useCallback(() => setIsSearchOpen(true), [setIsSearchOpen]);

  const handleAutoCompleteClose = useCallback(() => setIsSearchOpen(false), [setIsSearchOpen]);

  const handleSearchSubmit = useCallback(() => {
    const categoryURLParam = selectedCategory.urlRouteParam;
    const sourcesArray = location.pathname.split('/');
    const source = sourcesArray.length >= 3 ? sourcesArray[2] : null;
    if (selectedCategory.searchType === SEARCH_TYPE_DATE) {
      const formattedSelectedEndDate = getFormattedDate(selectedEndDate);
      const formattedSelectedStartDate = getFormattedDate(selectedStartDate);
      history.push(
        `/comparison/${
          source || 'us_vs_eu'
        }/${categoryURLParam}/date?startDate=${formattedSelectedStartDate}&endDate=${formattedSelectedEndDate}`
      );
    } else {
      history.push(`/comparison/${source || 'us_vs_eu'}/${categoryURLParam}/${searchText}`);
    }
  }, [selectedCategory, searchText, selectedEndDate, selectedStartDate]);

  const handleDateChangeOnAccept = (newValue: any, field: any) => {
    if (field === 'start') {
      setSelectedStartDate(handleDateChangeOnAcceptUtil(newValue, selectedStartDate));
    } else {
      setSelectedEndDate(handleDateChangeOnAcceptUtil(newValue, selectedEndDate));
    }
  };

  const handleDateYearChange = (newValue: any, field: any) => {
    if (field === 'start') {
      setSelectedStartDate(newValue);
    } else {
      setSelectedEndDate(newValue);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        background: '#f7f7f7'
      }}>
      <Box
        sx={{
          display: 'flex',
          margin: '0.25rem 0 0.25rem 2rem',
          backgroundColor: '#f7f7f7'
        }}>
        <p>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='2'
            height='41'
            viewBox='0 0 2 41'
            fill='none'>
            <path d='M1 0.5L0.999998 40.5' stroke='#919191' strokeLinecap='round' />
          </svg>
        </p>
        <Box sx={{ margin: '12px 0 0 16px' }}>
          <Typography
            sx={{
              fontFamily: 'Mulish',
              fontSize: '20px',
              fontWeight: 500,
              textTransform: 'capitalize'
            }}>
            Label Comparison
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Mulish',
              fontSize: '10px',
              fontWeight: 500
            }}>
            Make a Quick Search to start comparing labels across US FDA, EU EMA, Health Canada, JP
            PMDA and HPRA Ireland!
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', height: '49px', marginTop: '13px' }}>
        <Box>
          <Select
            sx={{
              marginRight: '8px',
              color: '#202020',
              fontFamily: 'Mulish',
              fontWeight: '400',
              fontSize: '12px',
              borderRadius: '8px',
              background: '#fff',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
              '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                border: 0
              },
              border: '1px solid #bdbdbd',
              height: '49px'
            }}
            disableUnderline
            onChange={handleCategoryChange}
            value={selectedCategory.value}
            IconComponent={KeyboardArrowDown}
            MenuProps={{ disableScrollLock: true }}>
            {categoryList.map(option => {
              return (
                <MenuItem
                  key={String(option.value)}
                  sx={{
                    color: '#202020',
                    fontFamily: 'Mulish',
                    fontWeight: '400',
                    fontSize: '12px'
                  }}
                  value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        {selectedCategoryType === SEARCH_TYPE_TEXT ? (
          <Box
            sx={{ ...styles.searchBar, borderRadius: '8px', background: '#fff', width: '360px' }}>
            <Stack direction='row' alignItems='center'>
              <Box flex={1}>
                <SearchBarAutoComplete
                  id='comparison-search-input'
                  value={searchText}
                  label='Get started...'
                  options={filterOptions(searchSuggestions)}
                  loading={isSuggestionsLoading}
                  onOpen={handleAutoCompleteOpen}
                  open={isSearchOpen}
                  onClose={handleAutoCompleteClose}
                  filterOptions={filterOptions}
                  onInputChange={handleKeyPress}
                  setSearch={value => setSearchText(value)}
                  clearSearchSuggestions={clearSearchSuggestions}
                  handleSearch={handleSearchSubmit}
                />
              </Box>
              <Button
                size='small'
                startIcon={<SearchIcon style={{ fontSize: 12 }} />}
                sx={styles.searchButton}
                onClick={handleSearchSubmit}>
                Search
              </Button>
            </Stack>
          </Box>
        ) : (
          <Stack
            direction='row'
            alignItems='center'
            sx={{
              '& .MuiFormControl-root': {
                '& .MuiFormLabel-root':
                  !selectedStartDate || !selectedEndDate
                    ? {
                        marginTop: -0.65,
                        overflow: 'none'
                      }
                    : {},
                height: '100%',
                m: 0.25,
                '& .MuiInputBase-root': {
                  height: '100%'
                }
              }
            }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label='From'
                inputFormat='MM/dd/yyyy'
                value={selectedStartDate}
                onYearChange={value => {
                  handleDateYearChange(value, 'start');
                }}
                onAccept={value => {
                  handleDateChangeOnAccept(value, 'start');
                }}
                onChange={value => {
                  handleDateYearChange(value, 'start');
                }}
                components={{
                  OpenPickerIcon
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                renderInput={params => <TextField {...params} />}
              />
              <DatePicker
                label='To'
                inputFormat='MM/dd/yyyy'
                value={selectedEndDate}
                onYearChange={value => {
                  handleDateYearChange(value, 'end');
                }}
                components={{
                  OpenPickerIcon
                }}
                onAccept={value => {
                  handleDateChangeOnAccept(value, 'end');
                }}
                onChange={value => {
                  handleDateYearChange(value, 'end');
                }} // eslint-disable-next-line react/jsx-props-no-spreading
                renderInput={params => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Box>
              <IconButton
                sx={{
                  p: 1,
                  mt: 0.5,
                  '& .MuiSvgIcon-root': {
                    fontSize: 23
                  }
                }}
                type='button'
                onClick={handleSearchSubmit}>
                <Search />
              </IconButton>
            </Box>
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default memo(ComparisonSearch);
