import { memo } from 'react';
import { Stack, Typography, Box, Tooltip } from '@mui/material';
import { verticalDesignationCardContainer, styles } from '../styles/verticalDesignationCard';

const variantStyles = {
  red: {
    appNum: styles.appNumRed,
    name: styles.nameRed
  },
  blue: {
    appNum: styles.appNumBlue,
    name: styles.nameBlue
  }
};

const SlimDesignationCard = ({
  tradeName,
  number,
  variant
}: {
  tradeName: any;
  number: any;
  variant: any;
}) => {
  return (
    <Stack
      direction='column'
      sx={{
        ...verticalDesignationCardContainer,
        height: '2rem',
        mb: '2rem',
        mt: 0,
        '&:hover': {
          cursor: 'default',
          backgroundColor: 'white.main',
          boxShadow: 0
        }
      }}>
      <Box display='flex' justifyContent='flex-start' alignItems='center'>
        <Typography
          sx={{
            // @ts-ignore
            ...variantStyles[variant].appNum
          }}>
          {number}
        </Typography>
        <Tooltip title={tradeName as string}>
          <Typography
            sx={{
              // @ts-ignore
              ...variantStyles[variant].name,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}>
            {tradeName}
          </Typography>
        </Tooltip>
      </Box>
    </Stack>
  );
};

export default memo(SlimDesignationCard);
