/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
// HelperMethods
import uniqueId from 'lodash/uniqueId';

// Mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// Styles
import styles from '../styles/Content.styles';

const DescriptionItem = ({ title, body }) => {
  return (
    <Stack spacing={1}>
      {title && (
        <Typography
          variant='h4'
          sx={styles.subtitleTwo}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      {body.map(para => {
        return (
          <Typography
            key={uniqueId()}
            variant='subtitle1'
            dangerouslySetInnerHTML={{ __html: para }}
          />
        );
      })}
    </Stack>
  );
};

export default DescriptionItem;
