import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

// material ui
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';

import store from '../../../store/HTADocuments';
// components
import HTADetails from '../components/Details';
import SubHeader from '../../../components/SubHeader';
import BottomDrawer from '../../../components/CustomComponents/BottomDrawer';
import NewFilters from '../../../components/NewFilters';
import AriaDrawerCore from '../../SearchResults/components/AriaDrawerCore';

// utils
import useHTAData from '../hooks/useHTAData';
import ReportWidgets from '../../../components/Report/Widgets';
import { HTA_FILTERS, PAGINATION_DEFAULT_OPTIONS } from '../const';
import GlobalStore from '../../../store';
import AskRiaButton from '../../../components/Buttons/AskRiaButton';
import { Stat } from '../../SearchResults/types';
import AskRiaButtonStyles from '../../../components/Buttons/styles/AskRiaButton.styles';
import useAriaShareHandler from '../../../components/AriaShare/useAriaShareHandler';
import truncateCountAboveMillions from '../../../utils/truncateCountAboveMillions';
import formatCount from '../../../utils/formatCount';
import VISUALIZE_CHART_MAPPING from '../../../components/Insights/const';
import Insights from '../../../components/Insights/Insights';

// Not a True HOC.
const HTATabs = () => {
  const { HTAState } = useContext(store);
  const { dispatch } = useContext(GlobalStore) as any;
  const {
    htafilters,
    isLoading,
    getHTAData,
    sortFields,
    getDatawithDataGridFilter,
    dataGridFilter
  } = useHTAData();
  const [ariaModalOpen, setAriaModalOpen] = useState(false);
  const [reportChoicesOpenAnchorElement, setReportChoiceAnchorElement] =
    useState<HTMLElement | null>(null);
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);
  const [htaStats, setHtaStats] = useState<Stat[]>([]);
  const [sort, setSort] = useState([...sortFields]);
  const [pagination, setPagination] = useState({
    pageSize: PAGINATION_DEFAULT_OPTIONS.limit,
    page: PAGINATION_DEFAULT_OPTIONS.offset
  });
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);

  useEffect(() => {
    getHTAData([], PAGINATION_DEFAULT_OPTIONS.limit, PAGINATION_DEFAULT_OPTIONS.offset, []);
  }, []);

  useEffect(() => {
    if (HTAState?.HTAStats) {
      const newStats: Stat[] = [];
      newStats.push({
        title: 'Total',
        value: HTAState?.HTAStats?.totalGuidancesCount || 0
      });
      newStats.push({
        title: 'Reference Count',
        value: HTAState?.HTAStats?.totalNiceRefCount || 0
      });
      newStats.push({
        title: 'Recently Published',
        value: HTAState?.HTAStats?.totalLastThirtyDays || 0
      });
      newStats.push({
        title: 'Docs/Page',
        value: HTAState?.HTAStats?.pdfCount || 0,
        value2: HTAState?.HTAStats?.pageCount || 0
      });

      setHtaStats(newStats);
    }
  }, [htafilters]);

  useAriaShareHandler(isLoading, ariaModalOpen, setAriaModalOpen);

  const handleVisualizeOpen = () => {
    setIsVisualizeOpen(true);
  };

  const closeDrawer = () => {
    setIsVisualizeOpen(false);
  };

  const handleFilterApply = useCallback(
    (currentfilters: any) => {
      setIsFilterUpdated(true);
      setPagination({ page: PAGINATION_DEFAULT_OPTIONS.offset, pageSize: pagination.pageSize });
      getHTAData(
        currentfilters,
        pagination.pageSize,
        PAGINATION_DEFAULT_OPTIONS.offset,
        sortFields
      );
    },
    [sortFields, pagination]
  );

  const getFiltersLabelList = () => {
    return HTA_FILTERS.reduce((acc: any, elem: any) => {
      acc[elem.value] = elem.label;
      return acc;
    }, {});
  };

  const getAriaDocuments = () => {
    const ariaDocuments = HTAState?.askRiaFilters?.map((item: any) => ({
      identifier: item
    }));

    return ariaDocuments || [];
  };

  const getInsightStatsList = useMemo(() => {
    if (HTAState?.HTAStats) {
      return [
        {
          text: 'Total Guidance Documents',
          count: HTAState.HTAStats.totalGuidancesCount,
          toolTipValue: ''
        },
        {
          text: 'NICE Reference Count',
          count: HTAState?.HTAStats?.totalNiceRefCount,
          toolTipValue: ''
        },
        {
          text: 'Approval in Last 30 Days',
          count: HTAState?.HTAStats?.totalLastThirtyDays,
          toolTipValue: ''
        },
        {
          text: 'Total Documents | Pages',
          count: `${truncateCountAboveMillions(
            HTAState?.HTAStats?.pdfCount
          )} | ${truncateCountAboveMillions(HTAState?.HTAStats?.pageCount)}`,
          toolTipValue: `${formatCount(HTAState?.HTAStats?.pdfCount)} | ${formatCount(
            HTAState?.HTAStats?.pageCount
          )}`
        }
      ];
    }
    return [];
  }, [HTAState]);

  const getLayer1Charts = useMemo(() => {
    const guidanceGraph = VISUALIZE_CHART_MAPPING?.hta?.layer1;

    if (guidanceGraph && HTAState.issueDateBar) {
      const updatedGraph = guidanceGraph.map((graph: any) => ({
        ...graph,
        data: HTAState?.issueDateBar
      }));

      return updatedGraph;
    }
    return guidanceGraph;
  }, [HTAState.issueDateBar]);

  const getLayer2Charts = useMemo(() => {
    const guidanceGraph = VISUALIZE_CHART_MAPPING?.hta?.layer2;
    if (guidanceGraph && HTAState?.treemapCharts) {
      guidanceGraph.forEach(graph => {
        const donutItem = HTAState?.treemapCharts.find(donut => donut?.apiKey === graph?.id);
        if (donutItem) {
          // eslint-disable-next-line no-param-reassign
          graph.data = donutItem.children;
        }
      });

      return guidanceGraph;
    }
    return guidanceGraph;
  }, [HTAState.treemapCharts]);

  return (
    <>
      <SubHeader
        title='UK NICE'
        subTitle='Showing list of Guidances published by UK NICE'
        stats={htaStats}
        handleVisualizeOpen={handleVisualizeOpen}
      />
      <Box>
        {!isLoading && (
          <Popover
            open={!!reportChoicesOpenAnchorElement}
            anchorEl={reportChoicesOpenAnchorElement}
            onClose={() => setReportChoiceAnchorElement(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            sx={{
              '& .MuiPopover-paper': {
                borderRadius: '16px !important',
                backgroundColor: 'none'
              }
            }}>
            <Box>
              <ReportWidgets
                widgets={['pie-widget', 'bar-widget', 'table-widget']}
                title=''
                dataSource={{
                  source: 'hta',
                  moduleName: 'guidance',
                  disableSelectionSource: true,
                  filters: HTAState.availableFilters,
                  filterLabelList: getFiltersLabelList()
                }}
                pathMetaData={{
                  module: 'hta',
                  href: window.location.href
                }}
                setReportWidgetClose={setReportChoiceAnchorElement}
              />
            </Box>
          </Popover>
        )}
        <HTADetails
          pagination={pagination}
          setPagination={setPagination}
          sort={sort}
          setSort={setSort}
          sortFields={sortFields}
          getHTAGuidanceData={getHTAData}
          isFilterUpdated={isFilterUpdated}
          setIsFilterUpdated={setIsFilterUpdated}
          getDatawithDataGridFilter={getDatawithDataGridFilter}
          dataGridFilter={dataGridFilter}
        />
      </Box>

      <BottomDrawer
        isDrawerOpen={isVisualizeOpen}
        closeDrawer={closeDrawer}
        handleReportChoiceAnchorElement={setReportChoiceAnchorElement}>
        <Insights
          source='hta'
          searchType='guidance'
          appliedFilter={HTAState?.availableFilters}
          statsList={getInsightStatsList}
          layer1Component={getLayer1Charts}
          layer2Component={getLayer2Charts}
          isLoading={isLoading}
        />
      </BottomDrawer>

      {ariaModalOpen && (
        <AriaDrawerCore
          applications={HTAState.isFiltersApplied ? getAriaDocuments() : []}
          sources={{ uk: ['nice'] }}
          groupId='entity_id'
          docCount={HTAState?.HTAStats?.pdfCount}
          docPageCount={HTAState?.HTAStats?.pageCount}
          modalOpen={ariaModalOpen}
          setModalOpen={setAriaModalOpen}
          dispatch={dispatch}
        />
      )}

      <NewFilters
        data={htafilters}
        applyFilters={handleFilterApply}
        isLoading={isLoading}
        selectedFilters={HTAState.availableFilters}
      />

      <Box sx={AskRiaButtonStyles.bottomFilterPosition}>
        <AskRiaButton
          iconId='aria'
          setOpenAriaSearch={setAriaModalOpen}
          text='Ask RIA'
          disabled={isLoading}
        />
      </Box>
    </>
  );
};

export default React.memo(HTATabs);
