import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const ErrorText = () => {
  return (
    <Box
      sx={{
        width: '100%',
        maxHeight: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
        justifyContent: 'center'
      }}>
      <Typography variant='h3' fontWeight='bold'>
        Service not available
      </Typography>
      <Button onClick={() => window.location.reload()}>
        <Typography
          variant='subtitle1'
          sx={{ ml: 2, textDecoration: 'underline', cursor: 'pointer' }}>
          Try again
        </Typography>
      </Button>
    </Box>
  );
};
export default ErrorText;
