import { Box, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import { useEffect, useMemo, useState } from 'react';

const DatagridFilterInputComponent = (props: GridFilterInputValueProps, state: any) => {
  const { item, applyValue, focusElementRef = null } = props;

  const [filterValueState, setFilterValueState] = useState<any>(
    item.value ?? { query: '', operator: 'and' }
  );
  const isLastFilter = useMemo(() => {
    const filters = state?.dataGridFilters?.items ?? [];
    return filters.length < 2 || filters[filters.length - 1]?.id === item?.id;
  }, [state?.dataGridFilters?.items, item?.id]);

  const showLogicalOperator = !isLastFilter;

  useEffect(() => {
    const itemValue = item.value ?? { query: '', operator: 'and' };
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (query: string, operator: string) => {
    setFilterValueState({ query, operator });

    applyValue({ ...item, value: { query, operator } });
  };

  const handleTextChange = (event: any) => {
    const query = event.target.value;
    updateFilterValue(query, filterValueState.operator);
  };
  const handleSelectChange = (event: any) => {
    const operator = event.target.value;
    updateFilterValue(filterValueState.query, operator);
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'end',
        pl: '20px'
      }}>
      <TextField
        name='filter-value-input'
        placeholder='Filter Value'
        label='Value'
        variant='standard'
        value={filterValueState?.query ?? ''}
        onChange={handleTextChange}
        type='text'
        inputRef={focusElementRef}
        autoFocus
        sx={{ mr: 2 }}
      />
      {showLogicalOperator && (
        <FormControl variant='standard' sx={{ width: '10rem' }}>
          <InputLabel>Boolean Operator</InputLabel>
          <Select
            value={filterValueState?.operator ?? 'and'}
            onChange={handleSelectChange}
            label='Boolean Operator'>
            <MenuItem value='and'>And</MenuItem>
            <MenuItem value='or'>Or</MenuItem>
          </Select>
        </FormControl>
      )}
    </Box>
  );
};

export default DatagridFilterInputComponent;
