import { createSvgIcon } from '@mui/material';

const ExpeditedPathwayIcon = createSvgIcon(
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M18.9889 19.1266L5.89296 10.1294C4.85323 9.43294 4 9.94428 4 11.2657V28.7351C4 30.0532 4.85223 30.5645 5.89316 29.8715L18.9889 20.8698C18.9889 20.8698 19.4956 20.5089 19.4956 20.0005C19.4956 19.4922 18.9889 19.1256 18.9889 19.1256V19.1266ZM35.9994 20.0015C35.9994 20.5082 35.4927 20.8708 35.4927 20.8708L22.3966 29.8727C21.3569 30.5659 20.5034 30.0546 20.5034 28.7363V11.2669C20.5034 9.94548 21.3557 9.43415 22.3966 10.1306L35.4909 19.1278C35.4909 19.1278 36 19.4938 36 20.0027L35.9994 20.0015Z'
      fill='currentColor'
    />
  </svg>,
  'ExpeditedPathway'
);

export default ExpeditedPathwayIcon;
