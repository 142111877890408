import { Dictionary, groupBy as groupArrayBy, orderBy } from 'lodash';

const getSortType = (sortType: string) => (sortType === 'asc' ? undefined : '');

export const sortArray = (option: any, applicationList: any): any[] => {
  const newApplications = orderBy(
    applicationList,
    [
      (item: any) =>
        Array.isArray(item[option.field])
          ? item[option.field].join(' ')?.toLowerCase()
          : item[option.field]?.toLowerCase() || getSortType(option.sortType)
    ],
    [option.sortType]
  );
  return newApplications;
};

export const sortGroupedArray = (
  option: any,
  sortingKey: any,
  applicationList: any
): Dictionary<any[]> => {
  const newApplications: any = {};
  orderBy(Object.keys(applicationList), [], [sortingKey]).forEach((key: string) => {
    newApplications[`'${key}'`] = orderBy(
      applicationList[key],
      [
        (item: any) =>
          Array.isArray(item[option.field])
            ? item[option.field].join(' ')?.toLowerCase()
            : item[option.field]?.toLowerCase() || getSortType(option.sortType)
      ],
      [option.sortType]
    );
  });
  return newApplications;
};

export const sortAndGroupApplications = (
  isGroupByFilterApplied: boolean,
  selectedGroupOption: any,
  selectedSortByOption: any,
  applicationList: any[]
): any[] | Dictionary<any[]> => {
  const applicationListCopy = [...applicationList];
  if (isGroupByFilterApplied && selectedGroupOption) {
    // If grouping is applied, then sort within the group
    const groupedApplication = groupArrayBy(applicationListCopy, selectedGroupOption.field);
    const newApplications = sortGroupedArray(
      selectedSortByOption,
      selectedGroupOption.keySorting,
      groupedApplication
    );
    return newApplications;
  }
  //  If grouping was not applied, then directly sort it
  if (selectedSortByOption) {
    const newApplications = sortArray(selectedSortByOption, applicationListCopy);
    return newApplications;
  }
  return applicationList;
};
