import { GridColDef } from '@mui/x-data-grid-pro';
import renderCellExpand from '../Datagrid/DatagridPopover';
import DatagridColorNumber from './components/DatagridColorNumber/DatagridColorNumber';
import DatagridChipsPopup from '../Datagrid/DatagridChipsPopup';
import DatagridTooltip from '../Datagrid/DatagridTooltip';
import { Filter } from '../Filters';
import { FILTER_TYPE } from '../NewFilters/constants';

import { PIECHARTCOLOR } from '../CustomComponents/Graphs/constant';
import DatagridReg360 from './components/DatagridReg360';

type CustomGridColDef = GridColDef & {
  capitalCasing?: boolean;
};

export interface PMRSurFilters extends Filter {
  apiRequestKey: string;
  apiResponseKey?: string;
}

export const PMRSURCOLUMN: CustomGridColDef[] = [
  {
    field: 'appl_type',
    headerName: 'Type',
    width: 100,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridColorNumber
  },
  {
    field: 'appl_num',
    headerName: 'Number',
    width: 100,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridReg360
  },
  {
    field: 'supplement_details',
    headerName: 'Supplement',
    width: 100,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell'
  },
  {
    field: 'active_ingredient',
    headerName: 'Active Ingredients',
    width: 180,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    capitalCasing: false,
    renderCell: DatagridTooltip
  },
  {
    field: 'brand_name',
    headerName: 'Trade Name',
    width: 150,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridTooltip
  },
  {
    field: 'sponsor',
    headerName: 'Sponsor',
    width: 180,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridTooltip
  },
  {
    field: 'submission_status_date',
    headerName: 'Submission Status date',
    width: 200,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: renderCellExpand
  },
  {
    field: 'date_of_fullfillment',
    headerName: 'Date of Fullfillment',
    width: 180,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: renderCellExpand
  },
  {
    field: 'cmt_number',
    headerName: 'PMR/PMC Number',
    width: 180,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridTooltip
  },
  {
    field: 'cmt_desc',
    headerName: 'PMR/PMC Description',
    width: 240,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: renderCellExpand
  },
  {
    field: 'status',
    headerName: 'PMR/PMC Status',
    width: 180,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridTooltip
  },
  {
    field: 'subpart_desc',
    headerName: 'Subpart',
    width: 240,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridTooltip
  },
  {
    field: 'indication_w_pmr_suppl',
    headerName: 'Indications with PMR/C Supplement',
    width: 300,
    cellClassName: 'table-row',
    headerClassName: 'table-header',
    renderCell: renderCellExpand
  },
  {
    field: 'latest_indication',
    headerName: 'Latest Indication',
    width: 240,
    cellClassName: 'table-row',
    headerClassName: 'table-header',
    renderCell: renderCellExpand
  },
  {
    field: 'category',
    headerName: 'Category',
    width: 400,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridChipsPopup,
    valueGetter: ({ row }) => `${row.category.replaceAll(',', '; ')}`
  }
];

export const PMR_SURVEILLANCE_FILTERS = [
  {
    id: 1,
    label: 'Application Type',
    value: 'appl_type',
    elasticSearchType: 'appl_type',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Supplement Type',
    value: 'supplement_type',
    elasticSearchType: 'supplement_type',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Active Ingredient',
    value: 'active_ingredient',
    elasticSearchType: 'active_ingredient',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Search Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Trade Name',
    value: 'brand_name',
    elasticSearchType: 'brand_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'PMR/PMC Category',
    value: 'category',
    elasticSearchType: 'category',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 7,
    label: 'Subpart',
    value: 'subpart_desc',
    elasticSearchType: 'subpart_desc',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 8,
    label: 'PMR/PMC Status',
    value: 'status',
    elasticSearchType: 'status',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 9,
    label: 'Sponsor',
    value: 'sponsor',
    elasticSearchType: 'sponsor',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 10,
    label: 'Submission Status Date',
    value: 'submission_status_date',
    elasticSearchType: 'submission_status_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    id: 11,
    label: 'Date of Fullfillment',
    value: 'date_of_fullfillment',
    elasticSearchType: 'date_of_fullfillment',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  }
];

export const barChartStyles = {
  colors: ['#B1BBE4'],
  padding: 0.8,
  axisBottom: {
    tickRotation: 25
  },
  axisLeft: {
    tickRotation: 0
  }
};

export const pieChartStyles = {
  colors: PIECHARTCOLOR,
  margin: { top: 20, right: 20, bottom: 20, left: 20 },
  pieLabel: true,
  pieLegend: false,
  innerRadius: 0.6,
  enableLabels: 'both'
};

export const PMR_SURVEILLANCE_QUERIES = [
  {
    id: 'pmrs_by_category',
    title: {
      title: 'PMRs/PMCs by Categories',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['category'], agg_function: 'count' },
    graphStyle: barChartStyles
  },
  {
    id: 'status',
    title: {
      title: 'PMR / PMC Status',
      show: true
    },
    graph: 'PIE',
    query: { 'x-axis': ['status'], agg_function: 'count' },
    graphStyle: pieChartStyles
  },
  {
    id: 'subpart_desc',
    title: {
      title: 'PMRs/PMCs by Subpart Description',
      show: true
    },
    graph: 'PIE',
    query: { 'x-axis': ['subpart_desc'], agg_function: 'count' },
    graphStyle: pieChartStyles
  }
];

export const PMR_SUREVILLANCE_COLUMN_NAME_MAPPING = {
  appl_type: 'Application Type',
  appl_num: 'Application Number',
  supplement_type: 'Supplement',
  active_ingredient: 'Active Integredient',
  brand_name: 'Trade Name',
  sponsor: 'Sponsor',
  submission_status_date: 'Submission Status date',
  date_of_fullfillment: 'Date of fulfillment',
  cmt_number: 'PMR/PMC Number',
  cmt_desc: 'PMR/PMC Description',
  subpart_desc: 'Subpart',
  indication_w_pmr_suppl: 'Indications with PMR/C Supplement',
  latest_indication: 'Latest Indication',
  category: 'PMR/PMC Category',
  status: 'PMR/PMC Status'
};
