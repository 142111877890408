import { createSvgIcon } from '@mui/material/utils';

const RightArrow = createSvgIcon(
  <svg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.418 16.0001L7.756 9.65339C8.19209 9.21525 8.43691 8.62224 8.43691 8.00406C8.43691 7.38588 8.19209 6.79287 7.756 6.35473L1.41067 6.10352e-05L0 1.41406L6.34533 7.76806C6.40782 7.83057 6.44293 7.91534 6.44293 8.00373C6.44293 8.09212 6.40782 8.17689 6.34533 8.23939L0.00666682 14.5861L1.418 16.0001Z'
      fill='#757575'
    />
  </svg>,
  'RightArrow'
);
export default RightArrow;
