import { createSvgIcon } from '@mui/material';

const SortIconVariant = createSvgIcon(
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_8799_7333)'>
      <path
        d='M0 2V7.33333H7.33333V0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2H0ZM6 6H1.33333V2C1.33333 1.82319 1.40357 1.65362 1.5286 1.5286C1.65362 1.40357 1.82319 1.33333 2 1.33333H6V6Z'
        fill='currentColor'
      />
      <path
        d='M0 14.0001C0 14.5305 0.210714 15.0392 0.585786 15.4143C0.960859 15.7893 1.46957 16.0001 2 16.0001H7.33333V8.66673H0V14.0001ZM1.33333 10.0001H6V14.6667H2C1.82319 14.6667 1.65362 14.5965 1.5286 14.4715C1.40357 14.3464 1.33333 14.1769 1.33333 14.0001V10.0001Z'
        fill='currentColor'
      />
      <path
        d='M13.3332 14.278V1.72404L15.0558 3.44404L15.9998 2.50071L14.0805 0.58404C13.7055 0.209709 13.1973 -0.000534058 12.6675 -0.000534058C12.1376 -0.000534058 11.6295 0.209709 11.2545 0.58404L9.33781 2.49737L10.2798 3.44071L11.9998 1.72137V14.2774L10.2765 12.556L9.33447 13.4994L11.2538 15.416C11.6288 15.7904 12.137 16.0006 12.6668 16.0006C13.1967 16.0006 13.7048 15.7904 14.0798 15.416L15.9998 13.5027L15.0578 12.5594L13.3332 14.278Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_8799_7333'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'Sort'
);

export default SortIconVariant;
