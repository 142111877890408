import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// mui
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { SlideProps } from '@mui/material';
import Grow from '@mui/material/Grow';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { Close } from '@mui/icons-material';

// styles
import Container from '@mui/material/Container';
import styles from './styles';

// component
import PDFViewer from '../PDFViewer';

// store
import Actions from '../../store/actions';
import Store from '../../store';

// utils
import useQuery from '../../helpers/customHooks/queryParameter';
import { getPdfResource } from '../../api/pages/ResultsPage';

const SlideTransition = (props: SlideProps) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Grow {...props} />;
};
export const PopUpWindow = ({
  handleClose,
  open,
  loading,
  documentLink,
  initialPage = 0
}: {
  handleClose: () => void;
  open: boolean;
  loading: boolean;
  documentLink: string;
  // eslint-disable-next-line react/require-default-props
  initialPage?: number;
}) => {
  const isPdf = documentLink ? new URL(documentLink).pathname.endsWith('.pdf') : false;

  return (
    <Snackbar
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
      sx={styles.snackbar}
      open={open}>
      <Container maxWidth='lg'>
        <Stack flexDirection='column' sx={styles.container} position='relative'>
          <Tooltip title='Close'>
            <IconButton onClick={handleClose} sx={styles.closeButton}>
              <Close />
            </IconButton>
          </Tooltip>
          {loading && <div>Loading...</div>}
          {documentLink &&
            (isPdf ? (
              <PDFViewer fileUrl={documentLink} initialPage={initialPage} />
            ) : (
              <iframe
                src={documentLink}
                title='Document Viewer'
                style={{ width: '100%', height: '90vh' }}
              />
            ))}
        </Stack>
      </Container>
    </Snackbar>
  );
};
const Resources = () => {
  const [open, setOpen] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const { dispatch } = useContext<any>(Store);
  const [documentLink, setDocumentLink] = useState<string>('');
  const query = useQuery();
  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
    history.push('/');
  };
  const fetchData = useCallback(async () => {
    setLoading(true);
    const payload: any = {};
    payload.s3_path = decodeURIComponent(query.get('filename') || '');
    payload.s3_bucket = query.get('bucket');
    try {
      const response = await getPdfResource({
        ...payload
      });
      if (response.data.status === 200) {
        setDocumentLink(response?.data?.body.s3_url);
      } else {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: `PDF Not found` }
        });
      }
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: `Not a valid PDF${error}` }
      });
    }

    setLoading(false);
  }, [query]);
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PopUpWindow
      handleClose={handleClose}
      open={open}
      loading={loading}
      documentLink={documentLink}
    />
  );
};
export default React.memo(Resources);
