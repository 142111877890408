import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { Chip, Grow } from '@mui/material';

import { isEmpty } from 'lodash';
import styles from './styles';
import cardStyles from '../../pages/SearchResults/styles/ApplicationCard.styles';
import trimText from '../../utils/trimText';
import {
  LABEL_COLOR,
  LABEL_TOOLTIP_TITLE,
  sourceFlagMapping,
  sourceFlagMappingTooltip
} from '../../pages/SearchResults/constants';
import { OpenArrowIcon } from '../../assets/svgs/Icons';
import Card from '../../pages/SearchResults/components/Card';

export interface TagProps {
  value: string;
  id: string;
  category: string;
  showCategory: boolean;
}

const ApplicationCard = ({ data }: { data: any }) => {
  const [expandCard, setExpandCard] = useState(false);
  const truncatedName = trimText(data?.title ?? '', 30);
  const FlagComponent = sourceFlagMapping[data?.source];
  const flagTooltipTitle = sourceFlagMappingTooltip[data?.source];
  return (
    <Box display='flex' sx={{ position: 'relative', marginLeft: '8px' }}>
      <Stack
        direction='row'
        onMouseEnter={() => setExpandCard(true)}
        onMouseLeave={() => setExpandCard(false)}>
        <Box display='flex' alignItems='center' sx={styles.cardBox}>
          <Tooltip title={truncatedName !== data?.title ? data?.title : ''}>
            <Typography id='trade-name' sx={styles.name}>
              {truncatedName}
            </Typography>
          </Tooltip>
          <Tooltip title={flagTooltipTitle}>
            {FlagComponent && <FlagComponent sx={styles.flag} />}
          </Tooltip>
          {data?.labels?.map((label: string) => {
            const labelKey = label.toLowerCase();
            const labelColor = LABEL_COLOR[labelKey];
            const labelTooltipTitle = LABEL_TOOLTIP_TITLE[labelKey];
            return labelColor ? (
              <Tooltip title={labelTooltipTitle}>
                <Chip
                  label={label}
                  key={label}
                  sx={{
                    ...cardStyles.labelChip,
                    bgcolor: labelColor,
                    ml: '4px'
                  }}
                />
              </Tooltip>
            ) : null;
          })}
        </Box>
        <OpenArrowIcon sx={{ width: '12px', height: '12px', margin: '12px' }} />
        {!isEmpty(data) && (
          <Grow in={expandCard} style={{ transformOrigin: '0 0 0' }}>
            <Box sx={styles.container}>
              <Card
                data={data}
                hideApplication={() => {}}
                triggerChatRIA={() => {}}
                setFavoriteInHook={() => {}}
                setInProjectInHook={() => {}}
                setSubscriptionInHook={() => {}}
                hideCardOptions
              />
            </Box>
          </Grow>
        )}
      </Stack>
    </Box>
  );
};

export default React.memo(ApplicationCard);
