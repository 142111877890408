import { createSvgIcon } from '@mui/material';

const AriaResponseFollowUp = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='23' height='24' viewBox='0 0 23 24' fill='none'>
    <path
      d='M14.2805 5.42743L12.938 6.76987L14.2805 8.12182L16.3465 10.1878H7.61589C4.98814 10.1878 2.85547 12.3205 2.85547 14.9483V18.7566H4.75964V14.9483C4.75964 13.3773 6.04495 12.092 7.61589 12.092H16.3465L14.2805 14.158L12.938 15.5005L14.2805 16.8524L19.993 11.1399L14.2805 5.42743Z'
      fill='#06705F'
    />
  </svg>,
  'AriaResponseFollowUp'
);

export default AriaResponseFollowUp;
