import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useParams } from 'react-router-dom';

import { getApprovalDateSearch, getQuickSearchResults } from '../../../api/pages/ResultsPage';
import useQuery from '../../../helpers/customHooks/queryParameter';

const EU_SOURCE_CATEGORY_MAP: { [key: string]: string } = {
  active_ingredients: 'active_ingredient',
  first_approval_date: 'approval_date'
};
const CA_SOURCE_CATEGORY_MAP: { [key: string]: string } = {
  first_approval_date: 'approval_date'
};
const JP_SOURCE_CATEGORY_MAP: { [key: string]: string } = {
  first_approval_date: 'sales_date',
  approval_date: 'sales_date'
};
const getMappedCategory = (source: string, category: string): string => {
  const lowerCaseSource = source.toLowerCase();
  if (lowerCaseSource === 'eu' || lowerCaseSource === 'hpra') {
    return EU_SOURCE_CATEGORY_MAP[category] || category;
  }
  if (lowerCaseSource === 'ca') {
    return CA_SOURCE_CATEGORY_MAP[category] || category;
  }
  if (lowerCaseSource === 'jp') {
    return JP_SOURCE_CATEGORY_MAP[category] || category;
  }
  return category;
};

const useLabelSearchData = () => {
  const { term, category }: any = useParams();
  const query: any = useQuery();

  const [cards, setCards]: any = useState([]);
  const [isLoading, setLoading]: any = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getCards = async (source: string) => {
    const user = await Auth.currentUserInfo();

    const mappedCategory = getMappedCategory(source, category);

    let request;
    if (term === 'date') {
      const startDate = query.get('startDate');
      const endDate = query.get('endDate');
      request = getApprovalDateSearch({
        source: source.toLowerCase(),
        category: mappedCategory,
        dateRange: {
          startDate,
          endDate
        },
        searchText: '',
        role: user.attributes['custom:role']
      });
    } else {
      const payloadStub = {
        category: ['eu', 'hpra'].includes(source.toLowerCase())
          ? EU_SOURCE_CATEGORY_MAP[category] || category
          : category,
        searchText: term,
        role: user.attributes['custom:role'], // For testing; Needs to be removed after a week
        query: '',
        source: source.toLowerCase()
      };
      request = getQuickSearchResults(payloadStub);
    }

    try {
      setCards([]);
      setLoading(true);
      setErrorMessage('');

      const response = await request;
      if (response.data && response.data?.body?.results) {
        let cardResults = response.data.body.results;
        if (source?.toLowerCase() === 'hpra') {
          cardResults = cardResults.filter((elem: any) => elem?.source_index === 'hpra');
        }
        setCards(cardResults);
      } else {
        setErrorMessage('Sorry, we couldn’t find any results. Please refine the search criteria.');
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      setErrorMessage('Sorry, we couldn’t find any results. Please refine the search criteria.');
    } finally {
      setLoading(false);
    }
  };

  return {
    cards,
    isLoading,
    errorMessage,
    getCards
  };
};

export default useLabelSearchData;
