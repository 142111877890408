import Chip from '@mui/material/Chip';
import { CENTER_OPTIONS } from './constant';

const CustomChip = ({ label }: any) => {
  return (
    <Chip
      size='small'
      label={label}
      sx={{
        height: 13,
        fontSize: 10,
        borderRadius: 1,
        fontWeight: 800,
        '& span': {
          px: 0.5
        },
        color: CENTER_OPTIONS[label]?.colorCode
      }}
    />
  );
};

export default CustomChip;
