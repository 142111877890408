import { lettersContextInterface } from '.';
import actions from './actions';

interface ActionProps {
  type: string;
  value: any;
}

export const initialState = {
  lettersStats: {},
  tableData: [],

  adviceTypeTreemap: [],
  statusTreemap: [],
  docTypeTreemap: [],
  issueDateBar: [],
  treemapCharts: [],

  lettersErrors: [],
  filters: [],
  availableFilters: {},
  search: {},
  loading: true,
  isFiltersApplied: false,
  totalRecord: 0,
  sortFields: [],
  askRiaFilters: [],
  dataGridFilters: {}
};

const reducers = (state: lettersContextInterface, action: ActionProps) => {
  switch (action.type) {
    case actions.SET_LETTERS_TABLE: {
      return {
        ...state,
        tableData: action.value
      };
    }
    case actions.SET_TREEMAP_CHARTS: {
      return {
        ...state,
        treemapCharts: action.value
      };
    }
    case actions.SET_DOC_TYPE_TREEMAP: {
      return {
        ...state,
        docTypeTreemap: action.value
      };
    }
    case actions.SET_ADVICE_TYPE_TREEMAP: {
      return {
        ...state,
        adviceTypeTreemap: action.value
      };
    }
    case actions.SET_ISSUE_DATE: {
      return {
        ...state,
        issueDateBar: action.value
      };
    }
    case actions.SET_STATUS_TREEMAP: {
      return {
        ...state,
        statusTreemap: action.value
      };
    }
    case actions.SET_LETTERS_STATS: {
      return {
        ...state,
        lettersStats: action.value
      };
    }
    case actions.SET_LETTERS_ERRORS: {
      return {
        ...state,
        lettersErrors: action.value
      };
    }
    case actions.SET_FILTERS: {
      return {
        ...state,
        filters: action.value
      };
    }
    case actions.SET_AVAILABLE_FILTERS: {
      return {
        ...state,
        availableFilters: action.value
      };
    }
    case actions.RESET_FILTERS: {
      return {
        ...state,
        filters: {}
      };
    }
    case actions.SET_SEARCH: {
      return {
        ...state,
        search: action.value
      };
    }
    case actions.RESET_SEARCH: {
      return {
        ...state,
        search: action.value
      };
    }
    case actions.SET_LOADING: {
      return {
        ...state,
        loading: action.value
      };
    }
    case actions.SET_IS_FILTERS_APPLIED: {
      return {
        ...state,
        isFiltersApplied: action.value
      };
    }
    case actions.SET_TOTAL_COUNT: {
      return {
        ...state,
        totalRecord: action.value
      };
    }
    case actions.SET_SORT: {
      return {
        ...state,
        sortFields: action.value
      };
    }
    case actions.SET_ASK_RIA_FILTERS: {
      return {
        ...state,
        askRiaFilters: action.value
      };
    }
    case actions.SET_DATA_GRID_FILTERS: {
      return {
        ...state,
        dataGridFilters: action.value
      };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
