const styles = {
  floatingContainer: {
    position: 'absolute',
    right: '0px',
    top: '-20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '2em 2em 0em 0em',
    paddingY: 0.5,
    paddingX: 1
  },
  suggestedAnswerFloatingContainer: {
    position: 'absolute',
    right: '0px',
    top: '-20px',
    backgroundColor: 'primary.backgroundLightVariant',
    borderRadius: '2em 2em 0em 0em',
    paddingY: 0.5,
    paddingX: 1
  },
  icon: {
    color: 'gray.contrastText'
  },
  iconSelected: {
    color: 'primary.main'
  }
};

export default styles;
