const avatarMixin = {
  marginRight: '8px',
  marginBottom: '4px',
  marginTop: '4px',
  height: '22px',
  width: '24px',
  fontSize: '10px'
};

const styles: { [key: string]: any } = {
  avatar: {
    ...avatarMixin,
    color: 'gray.900',
    bgcolor: 'primary.100'
  },
  avatarLight: {
    ...avatarMixin,
    color: 'gray.800',
    bgcolor: 'gray.200'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    height: '100%'
  }
};

export default styles;
