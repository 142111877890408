export const recruitmentStatusCardBoxContainer: any = {
  p: 0.5,
  width: '100%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  mt: 0.3,
  border: '1px solid',
  borderColor: 'gray.light',
  borderRadius: 2,
  position: 'relative',
  '&:hover': {
    backgroundColor: 'white.main',
    boxShadow: 1
  }
};

export const recruitmentStatusSubmittedDateStyle = {
  color: 'white.main',
  borderRadius: '8px',
  padding: '3px 8px',
  fontSize: '12px',
  fontWeight: 400,
  backgroundColor: 'red.light'
};

export const recruitmentStatusTextStyle = {
  fontWeight: 700,
  fontSize: '16px',
  textTransform: 'uppercase',
  paddingLeft: '10px',
  whiteSpace: 'nowrap'
};
