/* eslint-disable import/prefer-default-export */
import { CHMP_FILTERS } from './constants';

export const groupResourcesByType = (resources: Array<Record<string, string>>) => {
  const meetingResources = resources.reduce((acc: any, item: any) => {
    const categoryBucket = item.category_bucket;
    if (!acc[categoryBucket]) {
      acc[categoryBucket] = [item];
    } else {
      acc[categoryBucket].push(item);
    }
    return acc;
  }, {});

  return meetingResources;
};

export const getStatistics = (stats: any) => {
  let positiveCount = 0;
  let negativeCount = 0;
  let withdrawalCount = 0;

  // Iterate through the stats array using forEach()
  stats?.forEach(({ key, value }: any) => {
    const lowerKey = key?.toLowerCase();

    if (lowerKey.includes('positive')) {
      positiveCount += value;
    } else if (lowerKey.includes('negative')) {
      negativeCount += value;
    } else if (lowerKey.includes('withdrawal')) {
      withdrawalCount += value;
    }
  });

  return {
    Positive: positiveCount,
    Negative: negativeCount,
    Withdrawal: withdrawalCount
  };
};

export const getFiltersOptions = (apiData: any) => {
  const filters = CHMP_FILTERS.map((currentFilter: { [key: string]: any }) => ({
    ...currentFilter,
    options: [...(apiData?.[currentFilter?.value] || [])]
  }));

  return filters;
};
