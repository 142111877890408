import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  IconButton,
  ListItemText,
  MenuItem,
  Tooltip,
  Dialog,
  DialogTitle,
  Stack,
  Box,
  Typography,
  DialogContent
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CSVLink } from 'react-csv';
import axios, { CancelTokenSource } from 'axios';
import { getFileName } from '../../../helpers/getFileName';
import { TIMELINE_HEADERS } from '../../../components/Timeline/constants';
import { DATAGRID_UNIQUE_FIELD } from '../../ResultsPage/utils/constants';
import { RESTRICT_DOWNLOADS_BY_ORGANIZATION } from '../../../const';
import GlobalActions from '../../../store/actions';
import { getCommissionProcedureList, getStackedList } from '../../../api/pages/CardsPage';
import { APPROVAL_HISTORY } from '../../RegulatoryInformation/ApprovalHistory/constants';
import getCSVData from '../../ResultsPage/utils/getCSVdata';
import getSortCSVData from '../../ResultsPage/utils/getSortedData';
import AuthContext from '../../../store/Auth/AuthProvider';
import ResultsStore from '../../../store/SearchResults';
import GlobalStore from '../../../store';
import { RoundedMenu } from '../../../components/ui/Menu';

export const sourceMapping: any = {
  us: {
    label: 'US FDA - Drugs & Biologics',
    value: 'us'
  },
  ca: {
    label: 'CA HPFB - Drugs & Biologics',
    value: 'ca'
  },
  eu: {
    label: 'EU EMA - Drugs & Biologics',
    value: 'eu'
  },
  ct: {
    label: 'CT GOV - Clinical Trials',
    value: 'ct'
  },
  'us-devices': {
    label: 'US FDA - Devices',
    value: 'us-devices'
  },
  hpra: {
    label: 'IE HPRA - Drugs & Biologics',
    value: 'hpra'
  },
  hma: {
    label: 'EU HMA - Drugs & Biologics',
    value: 'hma'
  },
  jp: {
    label: 'JP PMDA - Drugs & Biologics',
    value: 'jp'
  },
  '505b2': {
    label: '505b2',
    value: 'us'
  },
  eua: {
    label: 'US FDA - EUA',
    value: 'eua'
  }
};

const ApprovalHistoryDownload = ({ handleClose }: any) => {
  const { currentUser } = useContext(AuthContext);
  const [source, setSource] = useState('us');
  const { resultsState } = useContext(ResultsStore);
  const { dispatch } = useContext(GlobalStore) as any;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [cancel, setCancel] = React.useState<CancelTokenSource | null>(null);
  const [count, setCount] = useState<number>(0);
  const [progress, setProgress] = useState(0);
  const [data, setData] = useState<any>([]);
  const [download, setDownload] = useState<boolean>(false);
  const [openSourceList, setOpenSourceList] = useState<boolean>(false);

  const [csvLoader, setCsvLoader] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<boolean>(false);

  const getSearchTerm = useCallback(() => {
    if (Object.keys(resultsState.decryptedPayload)) {
      if ('query' in resultsState.decryptedPayload) {
        return resultsState.decryptedPayload.query;
      }
    }
    return '';
  }, [resultsState.decryptedPayload]);

  const [filename, setFilename] = useState({
    prefix: `${getSearchTerm()}`,
    module: `${source}`,
    postfix: new Date().toISOString().split('T')[0],
    additional: '_approval_history',
    extension: 'csv'
  });

  const getStackedData = useCallback(
    async (sourceValue: string) => {
      const cancelToken = axios.CancelToken.source();
      setCancel(cancelToken);
      if (RESTRICT_DOWNLOADS_BY_ORGANIZATION.includes(currentUser['custom:organization'])) {
        dispatch({
          type: GlobalActions.SET_SERVICE_RESTRICTION_DETAILS,
          value: { message: '', closeBtn: true, status: true }
        });
        return;
      }
      try {
        const payload: any = [];
        const day = new Date();
        const id = DATAGRID_UNIQUE_FIELD[sourceValue?.toLowerCase() || 'us'];
        if (sourceValue?.toLowerCase() === 'ct') {
          resultsState.ctResults.results.forEach((appl: any) => {
            if (payload?.length < 50) {
              payload.push(appl[id]);
            }
          });
        } else if (sourceValue?.toLowerCase() === 'eua') {
          // fetch eua application number to download eua record
          resultsState.applicationResults?.us.results.forEach((appl: any) => {
            if (payload?.length < 50) {
              if (appl?.application_type === 'EUA') {
                payload.push(appl[id]);
              }
            }
          });
        } else if (sourceValue?.toLowerCase() === 'us') {
          resultsState.applicationResults?.us.results.forEach((appl: any) => {
            if (payload?.length < 50) {
              if (appl?.application_type !== 'EUA') {
                payload.push(appl[id]);
              }
            }
          });
        } else {
          resultsState.applicationResults[sourceValue].results.forEach((appl: any) => {
            if (payload?.length < 50) {
              if (!(sourceValue?.toLowerCase() === 'hpra' && appl?.source_index !== 'hpra')) {
                payload.push(appl[id]);
              }
            }
          });
        }
        setCount(payload.length);
        if (
          resultsState.applicationResults[sourceValue]?.results?.length > 50 ||
          resultsState.ctResults?.results?.length > 50
        ) {
          await dispatch({
            type: GlobalActions.SET_ALERT,
            value: {
              status: true,
              message: `Download history is limited to top 50 applications for a better experience.
                      Please filter to less than 50 if interested in specific applications.`,
              color: 'warning',
              duration: 5000
            }
          });
        }
        setCsvLoader(true);
        setProgress(0);
        const response = await getStackedList(
          sourceValue === 'eua' ? 'us' : sourceValue,
          { payload },
          APPROVAL_HISTORY as string,
          cancelToken.token
        );
        let commissionData: Array<any> = [];
        if (sourceValue?.toLowerCase() === 'eu') {
          const res = await getCommissionProcedureList(
            sourceValue as string,
            { payload },
            'commission_procedures',
            cancelToken.token
          );
          commissionData = res.data.body;
        }
        if (response.data.body) {
          const domain = new URL(window.location.href).origin;
          const csvData = getCSVData(
            sourceValue,
            response.data.body.concat(commissionData),
            domain
          );
          setFilename({
            prefix: `${getSearchTerm()}`,
            module: `${sourceValue}`,
            postfix: `${day.getMonth() + 1}-${day.getDate()}-${day.getFullYear()}`,
            additional: '_approval_history',
            extension: 'csv'
          });
          // to cleaned up the data.
          Object.values(csvData).forEach((item: any) => {
            Object.entries(item).forEach(([key, field]) => {
              if (Array.isArray(field)) {
                // eslint-disable-next-line no-param-reassign
                item[key] = field.join(' ').replace(/\n/g, '').replace(/,/g, '');
              }
            });
          });
          setData(getSortCSVData(sourceValue as string, csvData));
          setDownload(true);
        } else {
          setErrorMsg(true);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        setErrorMsg(true);
        if (!cancel) {
          setCsvLoader(false);
        }
      }
      setCancel(null);
      handleClose();
    },
    [resultsState.applicationResults, resultsState.ctResults, cancel]
  );

  const handleCancelCall = useCallback(async () => {
    if (cancel) {
      cancel.cancel('Operation canceled by the user.');
      setCancel(null);
    }
  }, [cancel]);
  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenSourceList(true);
  }, []);

  const handleSourceClick = useCallback(
    (sourceValue: string) => {
      setSource(sourceValue);
      if (
        !csvLoader &&
        (Object.keys(resultsState.applicationResults).length !== 0 ||
          resultsState.ctResults?.results?.length !== 0)
      ) {
        setErrorMsg(false);
        getStackedData(sourceValue);
      }
    },
    [csvLoader, resultsState.applicationResults, resultsState.ctResults]
  );

  const getSourceList = () => {
    const sourceList: any = [];
    const appldata: any = resultsState.applicationResults;
    if (Object.keys(appldata).length !== 0) {
      Object.keys(appldata).forEach((item: string) => {
        if (item in sourceMapping) {
          if (item === 'us') {
            const applResult = resultsState.applicationResults?.us?.results ?? [];
            // check if there are eua record present in results list if yes new category to download only EUA record
            const isEUAExists = applResult.some(
              (obj: { application_type: string }) => obj.application_type === 'EUA'
            );
            if (isEUAExists) {
              sourceList.push({
                label: sourceMapping?.eua.label,
                value: sourceMapping?.eua.value
              });
            }
            const isSbasExists = applResult.some(
              (obj: { application_type: string }) => obj.application_type !== 'EUA'
            );
            if (isSbasExists) {
              sourceList.push({
                label: sourceMapping[item].label,
                value: sourceMapping[item].value
              });
            }
          } else {
            sourceList.push({
              label: sourceMapping[item].label,
              value: sourceMapping[item].value
            });
          }
        }
      });
    }
    return sourceList;
  };

  useEffect(() => {
    if (download) {
      document.getElementById('csvDownloadBtn')?.click();
      setDownload(false);
      setProgress(100);
    }
  }, [download]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (progress < 85) {
        setProgress(progress + 100 / count);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [progress]);

  return (
    <>
      {download && (
        <CSVLink
          id='csvDownloadBtn'
          data={data}
          filename={getFileName(filename)}
          headers={TIMELINE_HEADERS?.[source?.toLowerCase() as string]?.approval_stacked_list || []}
        />
      )}
      <Tooltip title='Download approval history of the applications' placement='left'>
        <MenuItem onClick={handleClick}>
          <ListItemText primary='Approval History' />
        </MenuItem>
      </Tooltip>

      <RoundedMenu
        open={openSourceList}
        anchorEl={anchorEl}
        onClose={() => {
          setOpenSourceList(false);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          '& .MuiPaper-root': { padding: '8px 0px !important' },
          '& .MuiList-root': { padding: '0 !important' }
        }}>
        <MenuItem disabled sx={{ p: '6px 24px' }}>
          <ListItemText>
            <Typography sx={{ color: 'gray.800', fontSize: 12, fontWeight: 400 }}>
              Select Source:
            </Typography>
          </ListItemText>
        </MenuItem>
        {getSourceList().map((item: any) => (
          <MenuItem
            key={item.value}
            onClick={() => {
              handleSourceClick(item.value);
            }}
            sx={{ p: '6px 24px' }}>
            {item.label}
          </MenuItem>
        ))}
      </RoundedMenu>

      <Dialog
        open={csvLoader}
        keepMounted={false}
        maxWidth='xs'
        onClose={() => {
          if (progress === 100) {
            setCsvLoader(false);
          }
        }}>
        <DialogTitle id='alert-dialog-title'>
          <Stack mt={2} flexDirection='column' spacing={1} alignItems='center'>
            <Box>
              {(progress === 100 || errorMsg) && (
                <IconButton
                  aria-label='close'
                  onClick={() => {
                    setCsvLoader(false);
                  }}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8
                  }}>
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
            <Box
              sx={{
                position: 'relative',
                display: 'inline-flex',
                justifyContent: 'center',
                width: 200,
                height: 200
              }}>
              <CircularProgress
                variant='determinate'
                value={100}
                size={200}
                thickness={3}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  color: 'gray.background'
                }}
              />
              {!errorMsg && (
                <CircularProgress
                  variant={progress === 100 ? 'determinate' : 'indeterminate'}
                  value={100}
                  color={!errorMsg ? 'primary' : 'error'}
                  size={200}
                  thickness={3}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0
                  }}
                />
              )}

              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                {errorMsg ? (
                  <ErrorOutlineIcon />
                ) : (
                  <Typography
                    display='flex'
                    variant='caption'
                    component='div'
                    sx={{
                      fontSize: '32px',
                      color: 'black',
                      fontWeight: 'bold'
                    }}>
                    <span>{`${Math.round(progress)}`}</span>
                    <span style={{ fontSize: '20px', paddingTop: '6px' }}>%</span>
                  </Typography>
                )}
              </Box>
            </Box>
            <Typography
              ml={1}
              sx={{
                fontSize: '25px',
                color: 'black',
                fontWeight: 'bold'
              }}>
              {!errorMsg
                ? `${progress !== 100 ? 'Downloading...' : 'Download Completed !'}`
                : `Download Failed`}
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box
            mb={2}
            pl='5%'
            pr='5%'
            display='flex'
            flexDirection='column'
            alignItems='center'
            textAlign='center'>
            <Typography sx={{ fontSize: '16px', color: errorMsg ? 'red' : 'black' }}>
              {!errorMsg
                ? `${
                    progress !== 100
                      ? `Please be patient. It may take a few seconds to compile approval history
                      of ${count} applications.`
                      : 'Approval History file has been downloaded successfully'
                  }`
                : `Apologies! An error has occured in downloading.Please try again`}
            </Typography>

            <Button
              variant='contained'
              color='secondary'
              sx={{
                textTransform: 'capitalize',
                backgroundColor: 'primary.main',
                color: 'white.main',
                mt: 2,
                fontSize: '14px',
                fontWeight: 700,
                width: '40%',
                border: '2px solid',
                borderColor: 'primary.dark',
                padding: 1,
                '&:hover': {
                  opacity: 0.8,
                  color: 'black.main',
                  backgroundColor: 'white.main',
                  borderColor: 'gray.main'
                }
              }}
              onClick={() => {
                if (errorMsg) {
                  setErrorMsg(false);
                  getStackedData(source);
                } else if (progress !== 100) {
                  handleCancelCall();
                } else {
                  setCsvLoader(false);
                }
              }}>
              {
                // eslint-disable-next-line no-nested-ternary
                `${errorMsg ? 'Try Again' : progress !== 100 ? 'Cancel' : 'Done'}`
              }
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(ApprovalHistoryDownload);
