/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from 'react';

// muiv5
import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Divider } from '@mui/material';
import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from '../../../assets/svgs/Icons';

import styles from './styles/BinderList.styles';

const BinderList = ({
  binder,
  handleSelectBinder,
  selectedBinders
}: {
  binder: any;
  handleSelectBinder: any;
  selectedBinders: any;
}) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const leaveTimer = React.useRef<any>();
  const leaveDelay = 100;

  const handleLeave = () => {
    clearTimeout(leaveTimer.current);
    leaveTimer.current = setTimeout(() => {
      setAnchorEl(null);
    }, leaveDelay);
  };

  const sortedUsers = useMemo(
    () =>
      binder.users?.sort((a: any, b: any) => {
        return a.user.name?.localeCompare(b.user.name);
      }),
    [binder?.users]
  );

  return (
    <>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement='bottom-start'
        onMouseEnter={() => {
          clearTimeout(leaveTimer.current);
        }}
        sx={{ zIndex: 9999 }}
        onMouseLeave={handleLeave}>
        <Paper elevation={3} sx={styles.otherUserPopover}>
          <List component='div' disablePadding sx={{ py: 1.5 }}>
            {sortedUsers?.map((userDetails: any) => (
              <ListItem key={userDetails.user.id} sx={styles.otherUserPopoverItem}>
                <ListItemText
                  primary={userDetails.user.name}
                  sx={styles.otherUserPopoverItemText}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Popper>
      <ListItem key={binder.id} disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          role={undefined}
          onClick={() => handleSelectBinder(binder)}
          disableRipple
          disableGutters
          sx={{ py: 1.5, px: 1 }}>
          <Tooltip title={binder.name}>
            <Avatar alt='group' variant='circular' sx={styles.groupListItemAvatar}>
              {binder.name[0].toUpperCase()}
            </Avatar>
          </Tooltip>
          <Box pl={2}>
            <Typography sx={styles.groupName}>{binder.name}</Typography>
            <Typography sx={styles.userNames}>
              {sortedUsers
                ?.slice(0, 2)
                ?.map((userDetails: any) => {
                  return userDetails.user.name;
                })
                .join(', ')}
              {sortedUsers && sortedUsers.length > 2 && ', '}
              {sortedUsers && sortedUsers.length > 2 && (
                <Typography
                  component='span'
                  onMouseEnter={event => {
                    clearTimeout(leaveTimer.current);
                    setAnchorEl(event.target);
                  }}
                  sx={styles.otherUserNames}
                  onMouseLeave={handleLeave}>
                  +{sortedUsers.length - 2} Others
                </Typography>
              )}
            </Typography>
          </Box>
          <ListItemIcon sx={styles.listItemIcon}>
            <Checkbox
              checked={selectedBinders.some((selectedItem: any) => selectedItem.id === binder.id)}
              icon={<CheckboxUncheckedIcon sx={styles.groupSelectionCheckBox} />}
              checkedIcon={
                <CheckboxCheckedIcon
                  sx={{ ...styles.groupSelectionCheckBox, color: 'primary.600' }}
                />
              }
              inputProps={{ 'aria-label': 'select group' }}
            />
          </ListItemIcon>
        </ListItemButton>
        <Divider sx={{ borderBottom: '1px solid gray', borderBottomColor: 'gray.200', mx: 1 }} />
      </ListItem>
    </>
  );
};

export default React.memo(BinderList);
