import { useEffect, useContext } from 'react';
import { matchPath, useLocation } from 'react-router';

// context
import GlobalStore from '../store';

// Actions
import GlobalActions from '../store/actions';
import { decodeStringToJSON } from '../helpers/backToEncoding';

const getPathMatch = (pathname: string, routerPath: string) => {
  return matchPath(pathname, {
    path: routerPath,
    exact: true,
    strict: false
  });
};

export const getSearchParams = (locationSearch: string) => {
  const urlSearchParams = new URLSearchParams(locationSearch);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params;
};

const getSearchString = (searchParams: any) => {
  const searchValues = [];
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in searchParams) {
    searchValues.push(`${key}=${searchParams[key]}`);
  }
  return searchValues.join('&');
};

export const useBreadcrumbs = (routes: Array<any>) => {
  const location = useLocation();
  const { dispatch } = useContext<any>(GlobalStore);

  useEffect(() => {
    const currentPageBreadCrumbs = [];
    let backToURL;
    let backToObject;
    let parentModule;
    let addHome = true;
    const searchParams = getSearchParams(location.search || '');
    backToURL = searchParams.backTo || '';
    while (backToURL) {
      backToObject = decodeStringToJSON(backToURL);
      const { path, name, module, search } = backToObject;
      parentModule = module;
      currentPageBreadCrumbs.push({ name, link: `${path}?${getSearchString(search)}` });
      if (name === 'Home') {
        addHome = false;
      }
      backToURL = search?.backTo || '';
    }
    if (addHome) {
      currentPageBreadCrumbs.push({ name: 'Home', link: `/${parentModule || 'core'}` });
    }
    currentPageBreadCrumbs.reverse();
    Object.keys(routes).forEach((path: any) => {
      if (getPathMatch(location.pathname, path)) {
        currentPageBreadCrumbs.push({ name: routes[path].name });
      }
    });
    dispatch({
      type: GlobalActions.SET_BREADCRUMBS,
      value: currentPageBreadCrumbs
    });
  }, [location]);
};
