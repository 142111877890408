import uniqueId from 'lodash/uniqueId';

// muiv5
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// constants
import {
  CARD_METADATA,
  COLOR_SCHEME_TEXT,
  YEAR_GROUPS
} from '../../../pages/ResultsPage/utils/constants';

// stylesheet
import styles from '../../../pages/ResultsPage/styles/CardsGrid.styles';

const AnalyticViewReport = ({
  source,
  results,
  category,
  search,
  module
}: {
  source: string;
  results: any;
  category: string;
  search: string;
  module: string;
}) => {
  const { title, subtitle, dateValue, colorScheme, metadata1 } =
    CARD_METADATA[source?.toLowerCase()];

  const sourceMapping: { [key: string]: string } = {
    us: 'US FDA - Drugs and Biologics',
    eu: 'EU EMA - Drugs and Biologics',
    'us-devices': 'US FDA - Devices'
  };
  const categoryMapping: { [key: string]: string } = {
    indications_and_usage: 'Indications',
    active_ingredients: 'Active Ingredient',
    trade_name: 'Trade Name',
    formulation: 'Formulation',
    route: 'Route',
    sponsor_name: 'Sponsor Name',
    generic_name: 'Generic Name'
  };

  return (
    <Stack mt={2} spacing={2}>
      <Typography variant='h2' align='center' fontWeight='bold'>
        {sourceMapping[source?.toLowerCase()]} /{' '}
        {source?.toLowerCase() === 'us-devices' && category === 'indications_and_usage'
          ? 'Definition/AOS'
          : categoryMapping[category?.toLowerCase()]}{' '}
        / {module} - {search}
      </Typography>
      {YEAR_GROUPS?.map((item: string) => {
        if (!Object.keys(results).includes(item) || !results[item]?.length) {
          return null;
        }
        return (
          <Grid
            container
            key={uniqueId()}
            spacing={2}
            sx={{ mt: 2, bgcolor: 'white.main', borderRadius: 4 }}>
            {/* year range box */}
            <Grid
              item
              xs={12}
              sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                bgcolor: 'primary.lightBluish'
              }}>
              <Typography color='white.main' fontWeight='bold' variant='h3'>
                {item?.split('-')[0]}
              </Typography>
              <Typography color='white.main' fontWeight='bold' variant='h3'>
                -
              </Typography>
              <Typography color='white.main' fontWeight='bold' variant='h3'>
                {item?.split('-')[1]}
              </Typography>
            </Grid>

            {/* applications within a year range */}
            <Grid p={1} item container spacing={3} xs={12}>
              {results[item]?.map((row: { [key: string]: string }) => {
                return (
                  // individual application details
                  <Grid key={uniqueId()} item xs={6} sm={6} lg={2} xl={2} md={2}>
                    <Stack spacing={1}>
                      <Typography color='primary' fontWeight='bold' variant='subtitle1'>
                        {row[dateValue]}
                      </Typography>
                      <Typography
                        noWrap
                        variant='subtitle1'
                        fontWeight='bold'
                        sx={{ textTransform: 'capitalize' }}>
                        {row[title]}
                      </Typography>
                      <Typography
                        noWrap
                        variant='subtitle1'
                        sx={styles[COLOR_SCHEME_TEXT[row[colorScheme]?.toLowerCase() || 'ema']]}>
                        {row[subtitle]}
                      </Typography>
                      <Typography color='gray.main' noWrap variant='subtitle1'>
                        {row[metadata1]}
                      </Typography>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        );
      })}
    </Stack>
  );
};

export default AnalyticViewReport;
