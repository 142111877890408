import React from 'react';

import { Box, Typography } from '@mui/material';

import styles from './Metadata.styles';

const EntityMetadata = ({ data = [] }: { data: string[] }) => {
  return (
    <Box display='flex' alignItems='center' px={1} py={0.25} bgcolor='gray.200' borderRadius={1}>
      {data.map((txt: string, index: number) => (
        <React.Fragment key={txt}>
          <Typography color='gray.900' fontSize={13} fontWeight={600}>
            {txt}
          </Typography>
          {data.length - 1 > index && <Box sx={styles.greenDot} />}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default React.memo(EntityMetadata);
