import { useCallback, useContext, useEffect } from 'react';
import Papa from 'papaparse';
import XLSX from 'xlsx';

// api
import {
  getAllCanadaGuidanceDocuments,
  getCanadaGuidanceDocs,
  getCanadaGuidanceInsights
} from '../../../api/pages/CanadaGuidance';

// store
import CanadaGuidanceStore from '../../../store/CanadaGuidance';
import actions from '../../../store/CanadaGuidance/actions';
import globalActions from '../../../store/actions';
import globalStore from '../../../store/index';

// utils
import { constructPieChartData } from '../utils/getDonutChartStructure';
import { mergeOldFilterValues } from '../../../utils/filters';
import getFilterOptions from '../utils/common';
import {
  PAGINATION_DEFAULT_OPTIONS,
  EXPORT_TYPE,
  CANADA_GUIDANCE_COLUMN_NAME_MAPPING
} from '../const';
import { getFileName } from '../../../helpers/getFileName';
import transformCanadaGuidanceData from '../utils/transformCanadaGuidanceData';

import AuthContext from '../../../store/Auth/AuthProvider';

import { getDataGridFilter } from '../../../utils/getDatagridFilterOption';

const useCanadaGuidanceData = () => {
  const { CanadaGuidanceState, CanadaGuidanceDispatch } = useContext(CanadaGuidanceStore);
  const { state, dispatch }: any = useContext(globalStore);
  const { currentUser } = useContext(AuthContext);

  const getCanadaGuidanceInsightData = async (filters: any, appliedFilters: any) => {
    const insightResponse = await getCanadaGuidanceInsights({ filters });

    // extract and set visualize data
    if (insightResponse?.data?.status === 200) {
      const graphs = constructPieChartData(insightResponse?.data?.body?.stats?.donut_graph_data);
      CanadaGuidanceDispatch({
        type: actions.SET_DONUT_CHARTS,
        value: graphs
      });
      CanadaGuidanceDispatch({
        type: actions.SET_ISSUE_DATE,
        value: insightResponse?.data?.body?.stats?.graph_by_issue_date
      });
      CanadaGuidanceDispatch({
        type: actions.SET_ADOPTED_DATE,
        value: insightResponse?.data?.body?.stats?.graph_by_adopted_date
      });
      const stats = {
        totalLastThirtyDays: insightResponse?.data?.body?.stats?.last_30_days_updated_count,
        totalGuidance: insightResponse?.data?.body?.stats?.total_guidance_documents,
        pdfCount: insightResponse?.data?.body?.stats?.pdf_stats?.total_pdf_count,
        pageCount: insightResponse?.data?.body?.stats?.pdf_stats?.total_page_count
      };
      CanadaGuidanceDispatch({
        type: actions.SET_CANADA_GUIDANCE_STATS,
        value: stats
      });

      // extract and set filters
      let mergedFilters: any = [];
      const newFilters = getFilterOptions(insightResponse?.data?.body?.filters);
      if (appliedFilters.length) {
        mergedFilters = mergeOldFilterValues(newFilters, appliedFilters);
      } else {
        mergedFilters = newFilters;
      }

      CanadaGuidanceDispatch({
        type: actions.SET_FILTERS,
        value: mergedFilters
      });
      CanadaGuidanceDispatch({
        type: actions.SET_ASK_RIA_FILTERS,
        value: insightResponse?.data?.body?.filters?.entity_id || []
      });
      CanadaGuidanceDispatch({
        type: actions.SET_AVAILABLE_FILTERS,
        value: appliedFilters
      });
    }
  };

  const getCanadaGuidanceTableData = async (payload: any, limit: number, offset: number) => {
    const res = await getCanadaGuidanceDocs(payload, limit, offset);
    if (res?.data?.status === 200) {
      CanadaGuidanceDispatch({
        type: actions.SET_CANADA_GUIDANCE_TABLE,
        value: res?.data?.Success?.canada_guidance_documents ?? []
      });
      CanadaGuidanceDispatch({
        type: actions.SET_TOTAL_COUNT,
        value: res?.data?.Success?.total_count ?? 0
      });
    }
  };

  // Hook to fetch data based on datagrid filter
  const getDatawithDataGridFilter = async (
    appliedFilters: any,
    datagridFilter: any,
    sortFields: any,
    limit: number = PAGINATION_DEFAULT_OPTIONS.limit,
    offset: number = PAGINATION_DEFAULT_OPTIONS.offset
  ) => {
    CanadaGuidanceDispatch({
      type: actions.SET_LOADING,
      value: true
    });

    const source = {
      ca: ['canada_guidance']
    };
    const res: any = await getDataGridFilter(
      appliedFilters,
      datagridFilter,
      sortFields,
      limit,
      offset,
      CanadaGuidanceState,
      currentUser,
      source
    );

    if (res?.data?.status === 200) {
      CanadaGuidanceDispatch({
        type: actions.SET_CANADA_GUIDANCE_TABLE,
        value: res?.data?.body?.result?.canada_guidance?.results ?? []
      });

      CanadaGuidanceDispatch({
        type: actions.SET_TOTAL_COUNT,
        value: res?.data?.body?.result?.canada_guidance?.total ?? 0
      });
      CanadaGuidanceDispatch({
        type: actions.SET_DATA_GRID_FILTERS,
        value: datagridFilter
      });
    }

    CanadaGuidanceDispatch({
      type: actions.SET_LOADING,
      value: false
    });
  };

  const getCanadaGuidanceData = useCallback(
    async (
      appliedFilters: any,
      limit: number = PAGINATION_DEFAULT_OPTIONS.limit,
      offset: number = PAGINATION_DEFAULT_OPTIONS.offset,
      sort: Array<any> = [],
      shouldGetInsight: boolean = true,
      dataGridFilter: any = {}
    ) => {
      CanadaGuidanceDispatch({
        type: actions.SET_LOADING,
        value: true
      });

      CanadaGuidanceDispatch({
        type: actions.SET_IS_FILTERS_APPLIED,
        value: Object.keys(appliedFilters).length !== 0
      });

      const payload: any = {};
      payload.filters = { ...appliedFilters };
      if (sort) {
        payload.sort = sort;
      }
      CanadaGuidanceDispatch({
        type: actions.SET_AVAILABLE_FILTERS,
        value: appliedFilters
      });
      if (Object.keys(dataGridFilter).length !== 0) {
        await getDatawithDataGridFilter(appliedFilters, dataGridFilter, sort, limit, offset);
      } else {
        try {
          // get table data
          await getCanadaGuidanceTableData(payload, limit, offset);

          // get visualize and filters data
          if (shouldGetInsight) {
            await getCanadaGuidanceInsightData(payload?.filters, appliedFilters);
          }

          CanadaGuidanceDispatch({
            type: actions.SET_DATA_GRID_FILTERS,
            value: {}
          });

          CanadaGuidanceDispatch({
            type: actions.SET_LOADING,
            value: false
          });
        } catch (err) {
          const errors: any = [];
          errors.push('No data was found');
          CanadaGuidanceDispatch({
            type: actions.SET_CANADA_GUIDANCE_ERRORS,
            action: errors
          });
          CanadaGuidanceDispatch({
            type: actions.SET_LOADING,
            value: false
          });
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }
    },
    []
  );

  const openGuidanceComparison = useCallback((value: boolean) => {
    CanadaGuidanceDispatch({
      type: actions.SET_COMPARE_ENABLED,
      value
    });
  }, []);
  const removeFromComparison = useCallback((id: string) => {
    CanadaGuidanceDispatch({
      type: actions.REMOVE_DOCUMENT_TO_COMPARE,
      value: id
    });
  }, []);

  const openComparisonPopup = useCallback((value: boolean) => {
    CanadaGuidanceDispatch({
      type: actions.SET_COMPARE_POPUP_OPEN,
      value
    });
  }, []);

  const clearComparison = useCallback(() => {
    CanadaGuidanceDispatch({
      type: actions.CLEAR_DOCUMENT_TO_COMPARE
    });
    openComparisonPopup(false);
  }, []);

  const clearSearch = useCallback(() => {
    CanadaGuidanceDispatch({
      type: actions.SET_SEARCH,
      value: {
        term: ''
      }
    });
  }, []);

  const handleCSVExport = async () => {
    CanadaGuidanceDispatch({
      type: actions.SET_LOADING,
      value: true
    });
    const res = await getAllCanadaGuidanceDocuments({
      filters: CanadaGuidanceState.availableFilters
    });

    const selectedColumns = state?.exportType?.isAllColumnsSelected
      ? CANADA_GUIDANCE_COLUMN_NAME_MAPPING
      : Object.fromEntries(
          Object.entries(CANADA_GUIDANCE_COLUMN_NAME_MAPPING).filter(([key]) =>
            state?.exportType?.selectedColumns?.includes(key)
          )
        );
    const formattedData = transformCanadaGuidanceData(
      res?.data?.Success?.canada_guidance_documents,
      selectedColumns
    );
    const csv = Papa.unparse(formattedData);
    // Create a Blob from the CSV string
    const blob = new Blob([csv], { type: 'text/csv' });
    // Create a download link and click it
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    const day = new Date();
    const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
    const fileName = getFileName({
      prefix: 'ria',
      module: 'canada_guidances',
      postfix: date,
      additional: '',
      extension: 'csv'
    });
    a.download = `${fileName}.csv`;
    a.click();
    dispatch({
      type: globalActions.SET_EXPORT_TYPE,
      value: { format: '', selectedColumns: [], isAllColumnsSelected: false }
    });
    CanadaGuidanceDispatch({
      type: actions.SET_LOADING,
      value: false
    });
  };

  const handleExcelExport = async () => {
    CanadaGuidanceDispatch({
      type: actions.SET_LOADING,
      value: true
    });
    const res = await getAllCanadaGuidanceDocuments({
      filters: CanadaGuidanceState.availableFilters
    });
    const selectedColumns = state?.exportType?.isAllColumnsSelected
      ? CANADA_GUIDANCE_COLUMN_NAME_MAPPING
      : Object.fromEntries(
          Object.entries(CANADA_GUIDANCE_COLUMN_NAME_MAPPING).filter(([key]) =>
            state?.exportType?.selectedColumns?.includes(key)
          )
        );
    const formattedData = transformCanadaGuidanceData(
      res?.data?.Success?.canada_guidance_documents,
      selectedColumns
    );
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const day = new Date();
    const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
    const fileName = getFileName({
      prefix: 'ria',
      module: 'canada_guidances',
      postfix: date,
      additional: '',
      extension: 'xlsx'
    });
    XLSX.writeFile(wb, `${fileName}.xlsx`);
    dispatch({
      type: globalActions.SET_EXPORT_TYPE,
      value: { format: '', selectedColumns: [], isAllColumnsSelected: false }
    });
    CanadaGuidanceDispatch({
      type: actions.SET_LOADING,
      value: false
    });
  };

  useEffect(() => {
    if (
      state?.exportType?.format &&
      [EXPORT_TYPE.csv, EXPORT_TYPE.xlsx].includes(state?.exportType?.format)
    ) {
      if (state?.exportType?.format === EXPORT_TYPE.csv) {
        handleCSVExport();
      } else if (state?.exportType?.format === EXPORT_TYPE.xlsx) {
        handleExcelExport();
      }
    }
  }, [state?.exportType]);

  return {
    canadaGuidancefilters: CanadaGuidanceState.filters,
    isFilterApplied: CanadaGuidanceState.isFiltersApplied,
    canadaGuidanceTableData: CanadaGuidanceState.tableData,
    isLoading: CanadaGuidanceState.loading,
    compareEnabled: CanadaGuidanceState.compareEnabled,
    documentsToCompare: CanadaGuidanceState.documentsToCompare,
    comparePopupOpen: CanadaGuidanceState.comparePopupOpen,
    getCanadaGuidanceData,
    openComparisonPopup,
    removeFromComparison,
    openGuidanceComparison,
    clearComparison,
    clearSearch,
    sortFields: CanadaGuidanceState.sortFields,
    getDatawithDataGridFilter,
    dataGridFilter: CanadaGuidanceState.dataGridFilters
  };
};

export default useCanadaGuidanceData;
