import { createSvgIcon } from '@mui/material';

const CheckboxCheckedIcon = createSvgIcon(
  <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.0833 0.5H2.91668C1.30665 0.501941 0.00194141 1.80665 0 3.41668V11.5834C0.00194141 13.1934 1.30665 14.4981 2.91668 14.5H11.0834C12.6934 14.4981 13.9981 13.1934 14 11.5833V3.41668C13.9981 1.80665 12.6934 0.501941 11.0833 0.5ZM11.6667 5.21275L6.2685 10.6109C5.81298 11.0666 5.07429 11.0668 4.61858 10.6113L2.33332 8.3266C2.10506 8.09834 2.10506 7.72827 2.33332 7.50003C2.56159 7.27179 2.93166 7.27176 3.1599 7.50003L5.44422 9.78435L10.843 4.38618C11.0712 4.15873 11.4407 4.15939 11.6681 4.38763C11.8956 4.61589 11.8949 4.98531 11.6667 5.21275Z'
      fill='currentColor'
    />
  </svg>,
  'CheckboxChecked'
);

export default CheckboxCheckedIcon;
