import React, { useEffect, useContext, useState, useCallback } from 'react';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';

import VivproDatagrid from '../../../components/Datagrid';
import Filters from '../../../components/Filters';

// APIs
import regulatoryInformationAPI, {
  regulatory360ApiWithFilters
} from '../../../api/pages/Regulatory360';

// Store
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import Actions from '../../../store/RegulatoryInformation/actions';
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';

import EmptyPage from '../../../components/EmptyPage/EmptyPage';

// Constants
import { PMR_C, PMRC_DATAGRID_COLUMNS, PMRC_UNIQUE_FIELD, FILTERS } from './constants';

import getFilters, { getApiPayloadFromFilters } from './utils';
import { mergeOldFilterValues } from '../../../utils/filters';
import RESULT_VIEW_TYPES from '../../SearchResults/components/constants';

const PostmarketingData = () => {
  const { regulatoryState, regulatoryDispatch } = useContext(RegulatoryInformationStore);

  const { dispatch } = useContext<any>(GlobalStore);

  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [isFilterInValidState, setFilterValid] = useState<boolean>(true);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [noContent, setNoContent] = useState(false);

  const handleFilterButtonClick = useCallback(() => {
    setFilterOpen(true);
  }, []);

  useEffect(() => {
    if (
      regulatoryState?.postmarketingData.length === 0 &&
      regulatoryState?.applicationDetails?.application_number &&
      !regulatoryState?.isPMRCFilterApplied
    ) {
      const getData = async () => {
        setLoading(true);
        const data = await regulatoryInformationAPI(
          regulatoryState.db,
          regulatoryState.applicationDetails.application_number,
          PMR_C
        );

        if (!data?.data?.body?.length) {
          setNoContent(true);
        } else {
          regulatoryDispatch({ type: Actions.SET_PostMarketingData, value: data?.data?.body });

          const filters = getFilters(data.data.body);
          regulatoryDispatch({ type: Actions.SET_PMRC_FILTERS, value: filters });
        }
        setLoading(false);
      };
      getData();
    } else if (regulatoryState?.postmarketingData.length === 0) {
      setNoContent(true);
    }
  }, [regulatoryState.db, regulatoryState.applicationDetails]);

  useEffect(() => {
    if (regulatoryState.triggerOpenFilters) {
      if (handleFilterButtonClick) {
        handleFilterButtonClick();
      }
      regulatoryDispatch({ type: Actions.SET_TRIGGER_OPENFILTERS, value: false });
    }
  }, [regulatoryState.triggerOpenFilters]);

  const triggerAlert = useCallback(() => {
    dispatch({
      type: GlobalActions.SET_ALERT,
      value: { status: true, message: 'Filters were updated, please press the "Apply" button.' }
    });
  }, [dispatch]);

  const handleFilterClose = useCallback(() => {
    if (!isFilterInValidState) {
      triggerAlert();
    } else {
      setFilterOpen(false);
    }
  }, [isFilterInValidState, triggerAlert]);

  const handleClearFilter = useCallback(async () => {
    regulatoryDispatch({
      type: Actions.SET_PMRC_FILTERS_APPLIED_FLAG,
      value: false
    });
    setFilterOpen(false);
    setLoading(true);
    regulatoryDispatch({ type: Actions.SET_PMRC_FILTERS, value: FILTERS });
    try {
      const data = await regulatoryInformationAPI(
        regulatoryState.db,
        regulatoryState?.applicationDetails?.application_number,
        PMR_C
      );
      regulatoryDispatch({ type: Actions.SET_PostMarketingData, value: data.data.body });
      if (data?.data?.body?.length) {
        setNoContent(false);
      }
      const filters = getFilters(data.data.body);
      regulatoryDispatch({ type: Actions.SET_PMRC_FILTERS, value: filters });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      regulatoryDispatch({ type: Actions.SET_PostMarketingData, value: [] });
    } finally {
      setLoading(false);
    }
  }, []);

  const handleFilterApply = useCallback((filters: any) => {
    regulatoryDispatch({ type: Actions.SET_PMRC_FILTERS, value: filters });
    const getData = async () => {
      try {
        setLoading(true);
        const filtersPayload = getApiPayloadFromFilters(filters);
        regulatoryDispatch({
          type: Actions.SET_PMRC_FILTERS_APPLIED_FLAG,
          value: Object.keys(filtersPayload).length > 0
        });
        const data = await regulatory360ApiWithFilters(
          regulatoryState.db,
          regulatoryState?.applicationDetails?.application_number,
          PMR_C,
          {
            filters: filtersPayload,
            view_type: RESULT_VIEW_TYPES.APPLICATION
          }
        );
        if (data.status === 200) {
          regulatoryDispatch({ type: Actions.SET_PostMarketingData, value: data.data.body });

          const newfilters = getFilters(data.data.body);
          const mergedFilters = mergeOldFilterValues(newfilters, filters);
          regulatoryDispatch({ type: Actions.SET_PMRC_FILTERS, value: mergedFilters });
        } else {
          setNoContent(true);
          regulatoryDispatch({ type: Actions.SET_PostMarketingData, value: [] });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        regulatoryDispatch({ type: Actions.SET_PostMarketingData, value: [] });
      } finally {
        setLoading(false);
        setFilterOpen(false);
      }
    };
    getData();
  }, []);

  return (
    <>
      <Drawer anchor='right' open={filterOpen} onClose={handleFilterClose}>
        <Stack sx={{ width: 310 }}>
          <Filters
            onApply={handleFilterApply}
            filters={regulatoryState.filters.pmrc}
            onClear={handleClearFilter}
            loading={isLoading}
            setFilterValidity={setFilterValid}
          />
        </Stack>
      </Drawer>
      {noContent ? (
        <EmptyPage
          message={
            regulatoryState?.isPMRCFilterApplied
              ? 'No submissions are available based on the applied filters'
              : 'There are no postmarketing commitment or requirements'
          }
        />
      ) : (
        <Stack id='ListData' display='flex' justifyContent='center' sx={{ width: '100%' }}>
          <Stack sx={{ marginLeft: 1, height: 'calc(100vh - 300px)', width: 'calc(100% - 24px)' }}>
            <VivproDatagrid
              rows={regulatoryState?.postmarketingData}
              columnsMapping={PMRC_DATAGRID_COLUMNS}
              rowId={PMRC_UNIQUE_FIELD}
              loading={isLoading}
            />
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default React.memo(PostmarketingData);
