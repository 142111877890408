const styles = {
  box: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },
  circularProgress: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1
  },
  textContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 3
  },
  percentage: {
    color: 'gray.800',
    fontFamily: 'Roboto',
    fontSize: 22,
    fontWeight: 700
  },
  totalText: {
    color: 'gray.600',
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500
  }
};

export default styles;
