import { Box } from '@mui/material';
import { horizontalListSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { DroppableContainerProps } from '../types';
import palette from '../../../themev5/palette';

const Droppable = ({
  children,
  id,
  featureButtonItems,
  isPersonalize
}: DroppableContainerProps) => {
  const { setNodeRef } = useSortable({
    id
  });

  return (
    <SortableContext id={id} items={featureButtonItems} strategy={horizontalListSortingStrategy}>
      <Box
        key={id}
        id={id}
        ref={setNodeRef}
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'safe center',
          alignSelf: 'center',
          alignItems: featureButtonItems.length === 0 ? 'center' : 'start',
          border: isPersonalize ? `2px dashed ${palette.gray.light}` : null,
          maxWidth: '80vw',
          minWidth: '50%',
          minHeight: 80,
          overflowX: 'auto',
          overflowY: 'hidden',
          backgroundColor: isPersonalize ? '#F5F5F5' : 'transparent',
          px: '10px'
        }}>
        {children}
      </Box>
    </SortableContext>
  );
};

Droppable.defaultProps = {
  children: null
};

export default Droppable;
