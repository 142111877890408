import { createSvgIcon } from '@mui/material';

const AddToReportIcon = createSvgIcon(
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_4081_49138)'>
      <path
        d='M2.24322 16.4907C2.04411 16.4907 1.85315 16.4116 1.71236 16.2708C1.57156 16.13 1.49247 15.939 1.49247 15.7399V-0.0078125H-0.0090332V15.7399C-0.0090332 16.3373 0.228257 16.9101 0.650636 17.3325C1.07302 17.7549 1.64588 17.9922 2.24322 17.9922H18.009V16.4907H2.24322Z'
        fill='currentColor'
      />
      <path d='M5.24637 9.00098H3.74487V14.2562H5.24637V9.00098Z' fill='currentColor' />
      <path d='M8.9998 7.49902H7.49829V14.2558H8.9998V7.49902Z' fill='currentColor' />
      <path d='M12.7537 9.75195H11.2522V14.2565H12.7537V9.75195Z' fill='currentColor' />
      <path d='M16.5076 6.74805H15.0061V14.2556H16.5076V6.74805Z' fill='currentColor' />
      <path
        d='M9 3.6083H11.3899V5.99819V6.11081H11.5025H12.5035H12.6161V5.99819V3.6083H15.006H15.1186V3.49569V2.49469V2.38208H15.006H12.6161V-0.0078125V-0.120425H12.5035H11.5025H11.3899V-0.0078125V2.38208H9H8.88739V2.49469V3.49569V3.6083H9Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='0.225225'
      />
    </g>
    <defs>
      <clipPath id='clip0_4081_49138'>
        <rect width='18' height='18' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'AddToReport'
);

export default AddToReportIcon;
