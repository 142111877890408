import { sortFilterCategoriesOptions } from '../../../components/NewFilters/utils';

const addOptions = (filter: any, filtersData: any[], resultsStateFilters: any) => {
  if (filter?.value !== 'filter_label_sections') {
    const tempOptions = new Set();
    // caclulate filter from search response data
    if (filtersData?.length > 0) {
      filtersData.forEach((item: any) => {
        const option = item[filter.value];
        if (option) {
          if (Array.isArray(option)) {
            option.forEach(opt => opt && tempOptions.add(opt));
          } else {
            tempOptions.add(option);
          }
        }
      });
    } else if (
      // calculate filter from applied filters when response is empty
      filter?.filterType === 'selectOptions' &&
      resultsStateFilters[filter?.elasticSearchType]
    ) {
      const filterValues = resultsStateFilters[filter?.elasticSearchType];
      if (Array.isArray(filterValues) && filterValues?.length > 0) {
        filterValues.forEach((value: any) => tempOptions.add(value));
      }
    }

    // eslint-disable-next-line no-param-reassign
    filter.options = Array.from(tempOptions).sort((a: any, b: any) =>
      sortFilterCategoriesOptions(a, b)
    );
  }

  return filter;
};

export default addOptions;
