import palette from '../../../themev5/palette';

const styles = {
  timelineButton: {
    color: 'grey.800',
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal'
  },
  latestPipContainer: {
    display: 'flex',
    padding: '12px 24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '20px',
    flex: '1 0 0',
    alignSelf: 'stretch',
    backgroundColor: 'gray.200'
  },
  closeChat: {
    border: '2px solid',
    width: '24px',
    height: '24px',
    color: 'white.main',
    borderColor: 'white.main',
    padding: '3px !important',
    ml: 1,
    '& > svg': {
      fontSize: 20
    },
    '&:hover': {
      color: 'primary.main',
      borderColor: 'primary.main',
      backgroundColor: 'white.main'
    }
  },
  fileName: {
    overflow: 'hidden',
    color: 'secondary.700',
    textOverflow: 'ellipsis',
    fontSize: 13,
    fontWeight: 600,
    whiteSpace: 'nowrap'
  },
  outerContainer: {
    display: 'flex',
    padding: '12px 24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '20px',
    flex: '1 0 0',
    alignSelf: 'stretch',
    backgroundColor: 'gray.200'
  },
  container: {
    margin: '4px 0px 58px 0px',

    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray.lightVariant3',
      borderRadius: '4px',
      opacity: '0.8'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    }
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '6px'
  },
  labelsIndicatorWrapper: {
    minWidth: 188,
    p: 1.5,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    background: palette.white.main,
    boxShadow: `0 0 4px 0 ${palette.gray.lightVariant8}`
  },
  cardHeader: {
    mb: 1,
    fontWeight: 700,
    lineHeight: '24px'
  },
  companionDiagnosticDevicesWrapper: {
    background: palette.white.main,
    boxShadow: `0 0 4px 0 ${palette.gray.lightVariant8}`,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    p: 1.5,
    flexDirection: 'row',
    overflowY: 'hidden'
  },
  sectionLabel: {
    color: 'gray.700',
    px: 2,
    pt: 1,
    pb: 3,
    fontSize: 13,
    lineHeight: '150%'
  },
  sectionText: {
    fontWeight: 500,
    color: 'gray.900',
    fontSize: 13,
    lineHeight: '150%',
    px: 2,
    py: 1
  },
  sectionDivider: {
    borderColor: 'gray.200',
    margin: '0 !important'
  },
  companionDiagnosticDeviceNumberText: {
    fontWeight: 700,
    color: 'gray.950',
    fontSize: '15px',
    margin: 'auto 0',
    padding: '8px 16px'
  },
  sectionWrapper: {
    height: '56px',
    maxHeight: '56px',
    margin: '0 !important'
  },
  section: {
    height: '56px',
    maxHeight: '56px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    margin: '0 !important'
  },
  sectionContentWrapper: {
    height: '46px',
    maxHeight: '46px',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    marginBottom: '10px'
  },
  notAvailableText: {
    fontSize: '13px',
    fontWeight: 400,
    color: 'gray.400'
  },
  paper: {
    maxHeight: 300,
    maxWidth: 300,
    overflowY: 'auto',
    borderRadius: '8px',
    width: 400,
    backgroundColor: 'gray.50',
    padding: '20px 24px'
  },
  paperText: {
    fontWeight: 500,
    color: 'gray.950',
    fontSize: '13px'
  },
  headerBox: {
    display: 'flex',
    padding: '1px',
    alignItems: 'center',
    gap: '10px',
    cursor: 'pointer'
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 20,
    fontWeight: 700,
    color: 'gray.0',
    ml: 1
  }
};

export default styles;
