import palette from '../../../themev5/palette';

const styles: { [key: string]: { [key: string]: string | number | any } } = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    p: '24px 2px 0px 24px',
    height: '100vh',
    overflowY: 'auto',
    bgcolor: 'white.background'
  },
  clearText: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  closeIcon: {
    cursor: 'pointer'
  },
  title: {
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  btn: {
    marginTop: 1,
    padding: 2,
    width: 10,
    height: 4,
    fontSize: 14,
    fontFamily: 'Nunito Sans',
    textTransform: 'capitalize',
    color: 'gray.darkVariant',
    backgroundColor: 'gray.background',
    borderRadius: 2.5
  },
  selectedOptionsContainer: {
    marginTop: 1,
    p: 1,
    borderRadius: 18,
    alignItems: 'center',
    backgroundColor: 'primary.backgroundDark'
  },
  selectedTextContainer: {
    marginTop: 1,
    p: 1,
    borderRadius: 18,
    alignItems: 'center',
    backgroundColor: 'primary.backgroundDark',
    width: '88%'
  },
  selectedOptionsContainerCards: {
    marginTop: 1,
    p: 1,
    borderRadius: 18,
    alignItems: 'center',
    backgroundColor: 'gray.background'
  },
  selectedFilter: {
    marginTop: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pl: 1,
    pr: 1,
    width: 160,
    height: 35,
    borderRadius: 18,
    fontWeight: '600',
    color: 'gray.darkVariant',
    backgroundColor: 'primary.backgroundDark'
  },
  popoverBox: {
    height: '100%',
    maxHeight: 500,
    overflowY: 'auto'
  },
  filterButton: {
    textTransform: 'none',
    backgroundColor: 'white.main',
    padding: '3px 10px',
    borderRadius: '10px',
    border: `1px solid ${palette.gray.border}`,
    boxShadow: 0,
    m: 0,
    mr: 1,
    paddingLeft: '1.5rem',
    color: 'black.light',
    fontFamily: 'Nunito Sans',
    fontWeight: '400',
    minWidth: 'max-content',
    fontSize: 16,
    '&:hover': {
      backgroundColor: 'gray.background',
      boxShadow: 'none'
    }
  },
  chips: {
    textTransform: 'none',
    backgroundColor: 'white.main',
    border: '1px solid',
    borderColor: 'primary.main',
    justifyContent: 'flex-start',
    px: 1,
    py: 0.5,
    mb: 1,
    borderRadius: 2,
    alignItems: 'center',
    boxShadow: 0,
    color: 'black.light',
    fontFamily: 'Nunito Sans',
    fontWeight: '400',
    minWidth: 'max-content',
    fontSize: 14,
    '&:hover': {
      backgroundColor: 'primary.backgroundDark',
      boxShadow: 'none'
    }
  },
  heading: {
    color: 'primary.main',
    cursor: 'pointer',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    width: 'max-content',
    '&:hover': {
      background: 'none'
    }
  },
  clearButton: {
    height: '25px',
    cursor: 'pointer',
    background: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: 'none',
      border: 1,
      borderColor: 'primary.main',
      borderStyle: 'solid'
    }
  },
  scrollContainer: {
    '&::-webkit-scrollbar': {
      width: '7px'
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#c1c1c1',
      borderRadius: '5px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555'
    }
  }
};

export default styles;
