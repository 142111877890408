import { createSvgIcon } from '@mui/material';

const CDPIcon = createSvgIcon(
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.7273 5.45462H18.5455V34.5454H12.7273V5.45462ZM4 25.8182H9.81818V34.5454H4V25.8182ZM21.4545 20H27.2727V34.5454H21.4545V20ZM30.1818 14.1819H36V34.5454H30.1818V14.1819Z'
      fill='currentColor'
    />
  </svg>,
  'CDP'
);

export default CDPIcon;
