import React from 'react';

const DocumentCountIcon = () => {
  return (
    <svg width='30' height='35' viewBox='0 0 30 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M22.844 31.3711H6.421C5.51402 31.37 4.64449 31.0092 4.00316 30.3679C3.36182 29.7266 3.00106 28.857 3 27.9501V7.42106C3.00106 6.51408 3.36182 5.64455 4.00316 5.00322C4.64449 4.36189 5.51402 4.00112 6.421 4.00006H22.844C23.751 4.00112 24.6205 4.36189 25.2618 5.00322C25.9032 5.64455 26.2639 6.51408 26.265 7.42106V27.9501C26.2639 28.857 25.9032 29.7266 25.2618 30.3679C24.6205 31.0092 23.751 31.37 22.844 31.3711ZM6.421 5.37106C5.87736 5.37185 5.35619 5.58803 4.9716 5.97225C4.587 6.35647 4.37032 6.87743 4.369 7.42106V27.9501C4.37032 28.4937 4.587 29.0147 4.9716 29.3989C5.35619 29.7831 5.87736 29.9993 6.421 30.0001H22.844C23.3883 30.0003 23.9105 29.7846 24.296 29.4003C24.6814 29.016 24.8987 28.4944 24.9 27.9501V7.42106C24.8995 6.87673 24.683 6.35485 24.2981 5.96996C23.9132 5.58506 23.3913 5.36859 22.847 5.36806L6.421 5.37106Z'
        fill='#646464'
      />
      <path
        d='M20.343 21.0461H9.39397C9.21243 21.0461 9.03833 20.9739 8.90996 20.8456C8.78159 20.7172 8.70947 20.5431 8.70947 20.3616C8.70947 20.18 8.78159 20.0059 8.90996 19.8775C9.03833 19.7492 9.21243 19.6771 9.39397 19.6771H20.343C20.5245 19.6771 20.6986 19.7492 20.827 19.8775C20.9554 20.0059 21.0275 20.18 21.0275 20.3616C21.0275 20.5431 20.9554 20.7172 20.827 20.8456C20.6986 20.9739 20.5245 21.0461 20.343 21.0461Z'
        fill='#646464'
      />
      <path
        d='M20.343 26.2661H9.39397C9.30408 26.2661 9.21507 26.2484 9.13203 26.214C9.04898 26.1796 8.97352 26.1292 8.90996 26.0656C8.8464 26.002 8.79598 25.9266 8.76158 25.8435C8.72718 25.7605 8.70947 25.6715 8.70947 25.5816C8.70947 25.4917 8.72718 25.4027 8.76158 25.3196C8.79598 25.2366 8.8464 25.1611 8.90996 25.0976C8.97352 25.034 9.04898 24.9836 9.13203 24.9492C9.21507 24.9148 9.30408 24.8971 9.39397 24.8971H20.343C20.5245 24.8971 20.6986 24.9692 20.827 25.0976C20.9554 25.2259 21.0275 25.4001 21.0275 25.5816C21.0275 25.7631 20.9554 25.9372 20.827 26.0656C20.6986 26.194 20.5245 26.2661 20.343 26.2661Z'
        fill='#646464'
      />
      <path
        d='M20.343 15.7171H9.39397C9.21243 15.7171 9.03833 15.645 8.90996 15.5166C8.78159 15.3882 8.70947 15.2141 8.70947 15.0326C8.70947 14.851 8.78159 14.6769 8.90996 14.5486C9.03833 14.4202 9.21243 14.3481 9.39397 14.3481H20.343C20.5245 14.3481 20.6986 14.4202 20.827 14.5486C20.9554 14.6769 21.0275 14.851 21.0275 15.0326C21.0275 15.2141 20.9554 15.3882 20.827 15.5166C20.6986 15.645 20.5245 15.7171 20.343 15.7171Z'
        fill='#646464'
      />
      <path
        d='M20.343 10.3881H9.39397C9.21243 10.3881 9.03833 10.316 8.90996 10.1876C8.78159 10.0592 8.70947 9.88514 8.70947 9.7036C8.70947 9.52206 8.78159 9.34796 8.90996 9.21959C9.03833 9.09122 9.21243 9.0191 9.39397 9.0191H20.343C20.5245 9.0191 20.6986 9.09122 20.827 9.21959C20.9554 9.34796 21.0275 9.52206 21.0275 9.7036C21.0275 9.88514 20.9554 10.0592 20.827 10.1876C20.6986 10.316 20.5245 10.3881 20.343 10.3881Z'
        fill='#646464'
      />
      <path d='M22.4 0.900024H29.4M28.9 7.40002V0.400024' stroke='#646464' />
      <path d='M7 34.0001H0M0.5 27.5001L0.5 34.5001' stroke='#646464' />
      <path d='M28.9 27.4001V34.4001M22.4 33.9001H29.4' stroke='#646464' />
      <path d='M0.5 7.40002L0.5 0.400024M7 0.900024H0' stroke='#646464' />
    </svg>
  );
};

export default DocumentCountIcon;
