import makeRequest from "../client";

/**
 * Function to get Auto Search Result.
 * @param {string} searchText
 * @param {string} module
 * @param {string} source
 * @param {string} fieldName
 */

export const getAutoComplete = (searchText, module, source, fieldName) =>
  makeRequest(
    `/clignosis/suggestions/${searchText}?module=${module}&source=${source}&category=${fieldName}`,
    'GET'
  );

export const getAutoCompleteNew = (searchText, payload) =>
  makeRequest(`/suggestions?term=${searchText}`, 'POST', payload);

export const getBotAutoComplete = (searchText, module) =>
  makeRequest(`/clignosis/bot/querysuggest/${searchText}?module=${module}`, 'GET');

export const getAutoCompleteTN = searchText =>
  makeRequest(`/clignosis/tradeNameSearch/${searchText}?module=others`, 'GET');

export const getAutoCompleteAI = searchText =>
  makeRequest(`/clignosis/activeIngredientsSearch/${searchText}?module=others`, 'GET');

export const getAutoCompleteFormulation = searchText =>
  makeRequest(`/clignosis/formulationSearch/${searchText}?module=others`, 'GET');

export const getAutoCompleteRoute = searchText =>
  makeRequest(`/clignosis/routeSearch/${searchText}?module=others`, 'GET');

export const getAutoCompleteTA = searchText =>
  makeRequest(`/clignosis/termSearch/${searchText}`, 'GET');

export const getAutoCompleteSponsor = searchText =>
  makeRequest(`/clignosis/sponsorSearch/${searchText}`, 'GET');

export const getBlaAutoCompleteTA = searchText =>
  makeRequest(`/clignosis/termSearch/${searchText}?module=default`, 'GET');

export const getBlaAutoCompleteTN = searchText =>
  makeRequest(`/clignosis/tradeNameSearch/bla/${searchText}`, 'GET');

export const getBlaAutoCompleteAI = searchText =>
  makeRequest(`/clignosis/activeIngredientsSearch/bla/${searchText}`, 'GET');

export const getBlaAutoCompleteFormulation = searchText =>
  makeRequest(`/clignosis/formulationSearch/bla/${searchText}`, 'GET');

export const getBlaAutoCompleteRoute = searchText =>
  makeRequest(`/clignosis/routeSearch/bla/${searchText}`, 'GET');

export const getDesignatedAutoComplete = searchText =>
  makeRequest(
    `/clignosis/suggestions/${searchText}?module=core&source=fda&category=ta_indication`,
    'GET'
  );

export const getComparisonAutoComplete = (searchText, category) =>
  makeRequest(
    `/clignosis/suggestions/${searchText}?module=core&source=fda&category=${category}`,
    'GET'
  );
