const actions = {
  SET_PROJECTS: 'SET_PROJECTS',
  SET_STATS: 'SET_HTA_STATS',
  SET_ERRORS: 'SET_HTA_ERRORS',
  SET_FILTERS: 'SET_FILTERS',
  SET_AVAILABLE_FILTERS: 'SET_AVAILABLE_FILTERS',
  RESET_FILTERS: 'RESET_FILTERS',
  SET_SEARCH: 'SET_SEARCH',
  RESET_SEARCH: 'RESET_SEARCH',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_IS_FILTERS_APPLIED: 'SET_IS_FILTERS_APPLIED',
  SET_PROGRAM_TYPE_CHART: 'SET_PROGRAM_TYPE_CHART',
  SET_SUBMISSION_STATUS_CHART: 'SET_SUBMISSION_STATUS_CHART',
  SET_BAR_GRAPH: 'SET_BAR_GRAPH',
  SET_CHARTS: 'SET_CHARTS'
};

export default actions;
