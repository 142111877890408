import { createSvgIcon } from '@mui/material';

const CalendarIcon = createSvgIcon(
  <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_3115_33100)'>
      <path
        d='M11.0833 1.66667H10.5V1.08333C10.5 0.928624 10.4385 0.780251 10.3291 0.670854C10.2197 0.561458 10.0714 0.5 9.91667 0.5C9.76196 0.5 9.61358 0.561458 9.50419 0.670854C9.39479 0.780251 9.33333 0.928624 9.33333 1.08333V1.66667H4.66667V1.08333C4.66667 0.928624 4.60521 0.780251 4.49581 0.670854C4.38642 0.561458 4.23804 0.5 4.08333 0.5C3.92862 0.5 3.78025 0.561458 3.67085 0.670854C3.56146 0.780251 3.5 0.928624 3.5 1.08333V1.66667H2.91667C2.1434 1.66759 1.40208 1.97518 0.855295 2.52196C0.308514 3.06874 0.00092625 3.81007 0 4.58333L0 11.5833C0.00092625 12.3566 0.308514 13.0979 0.855295 13.6447C1.40208 14.1915 2.1434 14.4991 2.91667 14.5H11.0833C11.8566 14.4991 12.5979 14.1915 13.1447 13.6447C13.6915 13.0979 13.9991 12.3566 14 11.5833V4.58333C13.9991 3.81007 13.6915 3.06874 13.1447 2.52196C12.5979 1.97518 11.8566 1.66759 11.0833 1.66667ZM1.16667 4.58333C1.16667 4.1192 1.35104 3.67409 1.67923 3.3459C2.00742 3.01771 2.45254 2.83333 2.91667 2.83333H11.0833C11.5475 2.83333 11.9926 3.01771 12.3208 3.3459C12.649 3.67409 12.8333 4.1192 12.8333 4.58333V5.16667H1.16667V4.58333ZM11.0833 13.3333H2.91667C2.45254 13.3333 2.00742 13.149 1.67923 12.8208C1.35104 12.4926 1.16667 12.0475 1.16667 11.5833V6.33333H12.8333V11.5833C12.8333 12.0475 12.649 12.4926 12.3208 12.8208C11.9926 13.149 11.5475 13.3333 11.0833 13.3333Z'
        fill='currentColor'
      />
      <path
        d='M7 10.125C7.48325 10.125 7.875 9.73325 7.875 9.25C7.875 8.76675 7.48325 8.375 7 8.375C6.51675 8.375 6.125 8.76675 6.125 9.25C6.125 9.73325 6.51675 10.125 7 10.125Z'
        fill='currentColor'
      />
      <path
        d='M4.08325 10.125C4.5665 10.125 4.95825 9.73325 4.95825 9.25C4.95825 8.76675 4.5665 8.375 4.08325 8.375C3.6 8.375 3.20825 8.76675 3.20825 9.25C3.20825 9.73325 3.6 10.125 4.08325 10.125Z'
        fill='currentColor'
      />
      <path
        d='M9.91675 10.125C10.4 10.125 10.7917 9.73325 10.7917 9.25C10.7917 8.76675 10.4 8.375 9.91675 8.375C9.4335 8.375 9.04175 8.76675 9.04175 9.25C9.04175 9.73325 9.4335 10.125 9.91675 10.125Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_3115_33100'>
        <rect width='14' height='14' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>,
  'Calendar'
);

export default CalendarIcon;
