const Actions = {
  SET_LOADING: 'SET_LOADING',
  SET_RESULTS: 'SET_RESULTS',
  SET_BUSSINESS_INTELLIGENCE: 'SET_BUSSINESS_INTELLIGENCE',
  SET_CT_BUSINESS_INTELLIGENCE_LOADING: 'SET_CT_BUSINESS_INTELLIGENCE_LOADING',
  SET_CT_ADVERSE_EVENTS_LOADING: 'SET_CT_ADVERSE_EVENTS_LOADING',
  SET_CT_ADVERSE_EVENTS: 'SET_CT_ADVERSE_EVENTS',
  SET_TOGGLE_VIEW_TYPE: 'SET_TOGGLE_VIEW_TYPE',
  SET_ANALYTIC_VIEW_RESULTS: 'SET_ANALYTIC_VIEW_RESULTS',
  SET_FILTERS: 'SET_FILTERS',
  SET_HIDE_APPLICATIONS: 'SET_HIDE_APPLICATIONS',
  SET_PENDING_FILTERS: 'SET_PENDING_FILTERS',
  SET_LABEL_COMPARISON_SELECTION: 'SET_LABEL_COMPARISON_SELECTION',
  SET_OPEN_COMPARISON_DIALOG: 'SET_OPEN_COMPARISON_DIALOG',
  SET_COMPARISON_APPLICATIONS: 'SET_COMPARISON_APPLICATIONS',
  SET_PARENT_LABEL_CARD: 'SET_PARENT_LABEL_CARD',
  SET_CHILD_LABEL_CARD: 'SET_CHILD_LABEL_CARD',
  SET_PARENT_CARD: 'SET_PARENT_CARD',
  SET_SELECTED_SORT: 'SET_SELECTED_SORT',
  SET_CT_TIMELINE_SELECTED_SORT: 'SET_CT_TIMELINE_SELECTED_SORT',
  SET_ERROR: 'SET_ERROR',
  SET_CT_COUNT: 'SET_CT_COUNT',
  SET_RESULTS_ID: 'SET_RESULTS_ID',
  SET_IS_FAVORITE: 'SET_IS_FAVORITE',
  SET_IN_PROJECT: 'SET_IN_PROJECT',
  SET_SYNONYMS_OPEN: 'SET_SYNONYMS_OPEN',
  SET_SYNONYMS_FILTER: 'SET_SYNONYMS_FILTER',
  SET_APPLIED_FILTER: 'SET_APPLIED_FILTER',
  SET_TRIALS_DATA: 'SET_TRIALS_DATA',
  SET_TRIALS_LOADING: 'SET_TRIALS_LOADING',
  SET_TRIALS_ERROR: 'SET_TRIALS_ERROR',
  SET_ORIGINAL_TEXT_ENABLED: 'SET_ORIGINAL_TEXT_ENABLED'
};

export default Actions;
