import React from 'react';
import { Avatar, Grid, Typography } from '@mui/material';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator
} from '@mui/lab';
import RecruitmentStatusCardBox from './components/RecruitmentStatusCardBox';
import { CT_RECRUITMENT_STATUS_COLORS } from './constants';

interface VerticalTimelineHistory {
  submittedDate: string;
  currentStatus: string;
  version: string;
  currentStatusColorScheme: string;
}

const VerticalTimeline = ({ history = [] }: { history: VerticalTimelineHistory[] }) => {
  if (!Array.isArray(history)) {
    return null;
  }

  const historyLength = history.length;

  return (
    <React.Fragment>
      {history.map((item: VerticalTimelineHistory, index) => (
        <Grid
          item
          xs={3}
          key={`${item?.submittedDate}_${item?.currentStatus}`}
          sx={{
            textTransform: 'uppercase',
            textAlign: 'center'
          }}
        >
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0
              },
              margin: 0
            }}
          >
            {item?.submittedDate && item?.currentStatus && (
              <>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot
                      sx={{
                        bgcolor: `rgb(46, 204, 113)`,
                        marginTop: 0
                      }}
                    >
                      <Avatar sx={{ width: 40, height: 40, p: 2, bgcolor: 'transparent' }}>
                        <Typography variant={'caption'}>
                          <b>{item?.version}</b>
                        </Typography>
                      </Avatar>
                    </TimelineDot>
                    {index !== historyLength - 1 && (
                      <TimelineConnector sx={{ minHeight: 30, mr: 'auto', ml: 3 }} />
                    )}
                  </TimelineSeparator>
                  <TimelineContent>
                    <RecruitmentStatusCardBox
                      submittedDate={item.submittedDate}
                      status={item.currentStatus}
                      recruitmentStatusColor={
                        CT_RECRUITMENT_STATUS_COLORS?.[item?.currentStatusColorScheme] ||
                        CT_RECRUITMENT_STATUS_COLORS['other']
                      }
                    />
                  </TimelineContent>
                </TimelineItem>
              </>
            )}
          </Timeline>
        </Grid>
      ))}
    </React.Fragment>
  );
};
export default React.memo(VerticalTimeline);
