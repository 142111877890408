import themev5 from '../../../themev5';

const styles = () => ({
  root: {
    height: '92vh',
    background: 'white'
  },
  navigationText: {
    color: 'black.light'
  },
  back: {
    cursor: 'pointer'
  },
  container: {
    height: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  searchBox: {
    marginTop: 1,
    width: '36vw',
    height: '7vh',
    maxWidth: 600,
    maxHeight: 63,
    [themev5.breakpoints.down('md')]: {
      width: '70%'
    },
    [themev5.breakpoints.down('sm')]: {
      width: '90%'
    }
  },
  subtext: {
    m: 2,
    marginRight: 10,
    fontStyle: 'italic',
    fontWeight: '300',
    [themev5.breakpoints.down('sm')]: {
      m: 0,
      marginRight: 0
    }
  },
  highlightText: {
    fontSize: 36,
    color: 'black',
    fontWeight: '800',
    fontStyle: 'normal'
  },
  description: {
    fontWeight: 'bold',
    color: 'primary.main'
  },
  searchText: {
    fontWeight: 'bold',
    color: 'gray.main'
  }
});

export default styles;
