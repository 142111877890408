import React, { createContext, useReducer } from 'react';
import reducers, { initialState } from './reducers';

type Props = {
  children: React.ReactNode;
};

export interface HtaContextInterface {
  HTAStats: any;
  tableData: any[];

  adviceTypeTreemap: any[];
  statusTreemap: any[];
  docTypeTreemap: any[];
  issueDateBar: any[];
  treemapCharts: any[];

  HTAErrors: any[];
  filters: any[];
  availableFilters: any;
  search: any;
  loading: boolean;
  isFiltersApplied: boolean;
  totalRecord: number;
  sortFields: any[];
  askRiaFilters: any[];
  dataGridFilters: any;
}

const HTAStore = createContext<{
  HTAState: HtaContextInterface;
  HTADispatch: React.Dispatch<any>;
}>({ HTAState: initialState, HTADispatch: () => null });
const { Provider } = HTAStore;

export const HTAStateProvider = ({ children }: Props) => {
  const [HTAState, HTADispatch] = useReducer(reducers, initialState);
  return <Provider value={{ HTAState, HTADispatch }}>{children}</Provider>;
};

export default HTAStore;
