import { memo, useState, useEffect, useCallback } from 'react';

// MUI
import { Box, Typography } from '@mui/material';

// Components
import Loading from '../../pages/SearchResults/components/Loading';
import MainContent from './MainContent';
import List from './List';

// API
import { getFilteredWhatsNewUpdates } from '../../api/pages/WhatsNew';

// Styles & Icons
import { MagnifyingGlassIcon } from '../../assets/svgs/Icons';
import whatsNewStyles from './WhatsNew.styles';

// Types
import { Update, WhatsNewEntry, WhatsNewTabProps } from './types';

// Constants
import { API_RESPONSE_CODE } from '../../const';

const WhatsNewTab = ({ updateType, dateFilter }: WhatsNewTabProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [whatsNewUpdates, setWhatsNewUpdates] = useState<WhatsNewEntry[]>([]);
  const [selectedWhatsNew, setSelectedWhatsNew] = useState<Update | null>(null);
  const [selectedContentIndex, setSelectedContentIndex] = useState(0);
  const [expandedAccordions, setExpandedAccordions] = useState<string[]>([]);

  const openAllAccordions = useCallback((allFilteredUpdates: WhatsNewEntry[]) => {
    const accordionIds = allFilteredUpdates.map(update => update.id);
    setExpandedAccordions(accordionIds);
  }, []);

  useEffect(() => {
    const fetchWhatsNew = async () => {
      try {
        setIsLoading(true);

        const payload = {
          typeName: updateType,
          date: typeof dateFilter === 'string' ? dateFilter : dateFilter.toLocaleDateString()
        };

        const result = await getFilteredWhatsNewUpdates(payload);

        if (result?.status !== API_RESPONSE_CODE.SUCCESS && !result?.data?.Success?.result) {
          return;
        }

        const allFilteredUpdates = result.data.Success.result;

        setWhatsNewUpdates(allFilteredUpdates);
        const firstUpdate = allFilteredUpdates?.[0]?.updates;
        setSelectedWhatsNew(firstUpdate?.[0]);
        openAllAccordions(allFilteredUpdates);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchWhatsNew();
  }, [updateType, dateFilter]);

  const handlePrevContent = useCallback(() => {
    setSelectedContentIndex(prev => (selectedWhatsNew && prev > 0 ? prev - 1 : 0));
  }, [selectedWhatsNew]);

  const handleNextContent = useCallback(() => {
    setSelectedContentIndex(prev =>
      selectedWhatsNew ? Math.min(prev + 1, selectedWhatsNew.content.length - 1) : prev
    );
  }, [selectedWhatsNew]);

  const handleSelectUpdate = useCallback((item: Update) => {
    setSelectedWhatsNew(item);
    setSelectedContentIndex(0);
  }, []);

  const handleAccordionChange = useCallback(
    (panel: string) => () => {
      setExpandedAccordions(prevExpandedAccordions =>
        prevExpandedAccordions.includes(panel)
          ? prevExpandedAccordions.filter(id => id !== panel)
          : [...prevExpandedAccordions, panel]
      );
    },
    []
  );

  return (
    <Box sx={whatsNewStyles.tabContainer}>
      {isLoading ? (
        <Box width={828}>
          <Loading showTips={false} />
        </Box>
      ) : (
        <Box sx={whatsNewStyles.tabContent}>
          {whatsNewUpdates && whatsNewUpdates?.length > 0 && (
            <List
              updates={whatsNewUpdates}
              expandedAccordions={expandedAccordions}
              onAccordionChange={handleAccordionChange}
              selectedWhatsNew={selectedWhatsNew}
              onSelectUpdate={handleSelectUpdate}
              seenUpdateIds={[]}
              hideViewAllUpdatesButton
              hideUpdateSeen
            />
          )}

          {selectedWhatsNew && selectedWhatsNew?.content?.length > 0 && (
            <MainContent
              selectedWhatsNew={selectedWhatsNew}
              selectedContentIndex={selectedContentIndex}
              handlePrevContent={handlePrevContent}
              handleNextContent={handleNextContent}
            />
          )}

          {(!whatsNewUpdates || whatsNewUpdates?.length < 1) && (
            <Box sx={whatsNewStyles.tabNoResults.container}>
              <Box sx={whatsNewStyles.tabNoResults.iconWrapper}>
                <MagnifyingGlassIcon sx={whatsNewStyles.tabNoResults.icon} />
              </Box>
              <Typography sx={whatsNewStyles.tabNoResults.title}>
                No updates available for the selected period.
              </Typography>
              <Typography sx={whatsNewStyles.tabNoResults.subTitle}>
                Please choose another option or a different period.
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default memo(WhatsNewTab);
