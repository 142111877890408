import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import { getSearchParams } from '../../../hooks/useBreadcrumbs';
import { encodeJSONtoString } from '../../../helpers/backToEncoding';
import { elasticQueryToAdvancedSearch } from '../../Header/utils/advanceSearchHelpers';

import showReg360 from '../../../pages/SearchResults/utils/showReg360';

interface GridRenderCellParams {
  // eslint-disable-next-line react/require-default-props
  value?: string;
  // eslint-disable-next-line react/require-default-props
  row?: any;
}
const DatagridProductNameReg360 = (props: GridRenderCellParams = { value: '', row: {} }) => {
  const { value, row } = props;
  const { source, identifier, application_type: type } = row;

  const history = useHistory();
  const {
    location: { pathname: locationPathName, search: locationSearch }
  } = window;
  const urlParams = new URLSearchParams(locationSearch);
  const advSearchData = elasticQueryToAdvancedSearch(urlParams);

  const shouldShowReg360 = showReg360(row, source);

  const handleReg360 = () => {
    const module = 'core';
    const backToObject = {
      module,
      path: locationPathName,
      search: getSearchParams(locationSearch || ''),
      name: 'Search Results'
    };
    const backToString = encodeJSONtoString(backToObject);
    const applicationNumber = encodeURIComponent(identifier);
    let dateText = '';
    if (advSearchData?.dateRange?.startDate && advSearchData?.dateRange?.endDate) {
      dateText = `&startDate=${advSearchData.dateRange.startDate}&endDate=${advSearchData.dateRange.endDate}`;
    }
    try {
      const navigation = JSON.parse((localStorage.getItem('navigation') as any) ?? '{}');
      if (source?.toLowerCase() === 'us') {
        navigation.regulatory = {
          url: `/regulatory360/${module}/${applicationNumber}/${source}?type=${type}&backTo=${backToString}${dateText}`,
          value: `Regulatory360:${type}${applicationNumber}`
        };
      } else {
        navigation.regulatory = {
          url: `/regulatory360/${module}/${applicationNumber}/${source}?backTo=${backToString}${dateText}`,
          value: `Regulatory360:${applicationNumber}`
        };
      }
      localStorage.setItem('navigation', JSON.stringify(navigation));
      if (source?.toLowerCase() === 'us') {
        history.push(
          `/regulatory360/${module}/${applicationNumber}/${source?.toLowerCase()}?type=${type}&tabname=approvalHistory&backTo=${backToString}${dateText}`
        );
      } else {
        history.push(
          `/regulatory360/${module}/${applicationNumber}/${source}?tabname=approvalHistory&backTo=${backToString}${dateText}`
        );
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  return (
    <Tooltip title={value}>
      <Typography
        color='primary.700'
        fontWeight={700}
        variant='subtitle2'
        noWrap
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          '&:hover': {
            textDecoration: shouldShowReg360 ? 'underline' : 'none',
            cursor: 'pointer'
          }
        }}
        onClick={handleReg360}>
        {value}
      </Typography>
    </Tooltip>
  );
};

export default DatagridProductNameReg360;
