import React, { useContext, useEffect, useState } from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';

// context
import Store from '../../../store';

// styles
import Styles from '../styles/RLD.styles';
import styled from '@emotion/styled';

import { Tree, TreeNode } from 'react-organizational-chart';
import LabelComapreIcon from '../../../Images/brightness-and-contrast.svg';
import { toast } from 'react-toastify';

// api
import { getLabelComparision } from '../../../api/pages/Reg360';

// images
import CenterBallN from '../../../Images/centerBallNext.svg';

const RldInfo = props => {
  const classes = Styles();

  const { state } = useContext(Store);
  const [netDiagramRes, setnetDiagramRes] = useState('');
  const [PDFData, setPDFData] = useState(false, {});
  const [viewerURL, setviewerURL] = useState();
  const [parentNode, setparentNode] = useState();
  const [childNode, setchildNode] = useState();
  const [ChildNda, setChildNda] = useState();
  const [loading, setLoading] = useState(false);
  const [Errorstate, setError] = useState(false);

  useEffect(() => {
    const netwDiagram = props.data.data.success.rld_information;
    setparentNode('');
    setchildNode('');
    for (let i = 0; i < netwDiagram.length; i += 1) {
      if (netwDiagram[i].rld_relation === 'parent') {
        setparentNode(netwDiagram[i]);
      }
      if (netwDiagram[i].rld_relation === 'child') {
        setchildNode(netwDiagram[i]);
      }
    }
    setnetDiagramRes(netwDiagram);
  }, [props]);

  const StyledNode = styled.div`
    padding: 3vh;
    border-radius: 8px;
    display: inline-block;
    cursor: pointer;
    cursor: pointer;
    color: #0352cc;
    margin: 10px;
    background-color: #ffffff;
    border-radius: 20px;
    border: 1px solid #c9d2f6;
  `;

  const handleComparision = async childNda => {
    setLoading(true);
    setChildNda(childNda);
    const pdfData = {
      parent_object: parentNode.appl_type + parentNode.appl_num,
      child_object: childNda
    };

    let res;
    await getLabelComparision(pdfData, state.db)
      .then(response => {
        res = response;
      })
      .catch(error => {
        console.error(error);
      });
    if (res.data.success !== null) {
      setPDFData([true, {}]);
      setviewerURL(res.data.success);

      setError(false);
    } else {
      setPDFData([false, {}]);
      toast.error(res.data.error);
      setError(true);
    }

    setLoading(false);
  };

  return (
    <div>
      {childNode === '' || parentNode === '' ? (
        <Typography className={classes.StyledErrorTypography}>
          No RLD Information data available
        </Typography>
      ) : (
        <div>
          {netDiagramRes ? (
            <div className={classes.networkDiagram}>
              {netDiagramRes.map(item => {
                return item.rld_relation === 'parent' ? (
                  <Tree
                    className={classes.ParentNode}
                    lineWidth={'2px'}
                    lineColor={'grey'}
                    lineBorderRadius={'10px'}
                    label={
                      <StyledNode>
                        {item.appl_type.includes('nda') ? (
                          <div className={classes.ndaNode}>{item.appl_num}</div>
                        ) : (
                          <div className={classes.ndaNode2}>{item.appl_num}</div>
                        )}

                        <div className={classes.Node}>
                          {item.manufacturer} | {item.approval_year}
                        </div>
                        <div className={classes.Node}>
                          {item.route} | {item.formulation}
                        </div>
                        <div className={classes.Node}>
                          Marketing Status: {item.marketing_status}
                        </div>
                      </StyledNode>
                    }>
                    {netDiagramRes.map(item2 => {
                      return item2.rld_relation === 'child' ? (
                        <TreeNode
                          label={
                            <StyledNode value={item2.nda}>
                              <div
                                onClick={() =>
                                  props.handleNetworkChild(item2.appl_type + item2.appl_num)
                                }>
                                {item2.appl_type.includes('nda') ? (
                                  <div className={classes.ndaNode}>{item2.appl_num}</div>
                                ) : (
                                  <div className={classes.ndaNode2}>{item2.appl_num}</div>
                                )}
                              </div>
                              <div className={classes.Node}>
                                {item2.manufacturer} | {item2.approval_year}
                              </div>
                              <div className={classes.Node}>
                                {item2.route} | {item2.formulation}
                              </div>
                              <div className={classes.Node}>
                                Marketing Status: {item2.marketing_status}
                              </div>
                              <div
                                onClick={() => handleComparision(item2.appl_type + item2.appl_num)}
                                className={classes.compareL}>
                                <img
                                  style={{ width: '15px' }}
                                  src={LabelComapreIcon}
                                  alt='compareIcon'></img>
                                <Typography className={classes.labelTitle}>
                                  Compare Labels
                                </Typography>
                              </div>
                            </StyledNode>
                          }></TreeNode>
                      ) : (
                        <div></div>
                      );
                    })}
                  </Tree>
                ) : (
                  <div></div>
                );
              })}
            </div>
          ) : (
            <div className={classes.networkDiagram}>No data to display</div>
          )}
        </div>
      )}

      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        onClose={() => {
          setPDFData([false, {}]);
        }}
        aria-labelledby='customized-dialog-title'
        open={PDFData[0] !== undefined ? PDFData[0] : false}
        classes={{ paper: classes.dialog }}>
        <DialogTitle style={{ padding: '0 24px' }}>
          <Grid container>
            <Grid
              container
              item
              xs={11}
              justify='center'
              alignItems='center'
              className={classes.LabelTitle}>
              <img
                style={{ width: '33px', paddingRight: '10px' }}
                src={LabelComapreIcon}
                alt='compareIcon'></img>
              LABEL COMPARISON
            </Grid>
            <Grid container item xs={1} justify='flex-end' alignItems='center'>
              <IconButton
                aria-label='delete'
                className={classes.ModalCloseIconButton}
                onClick={() => {
                  setPDFData([false, {}]);
                }}>
                <CancelIcon className={classes.ModalCloseIcon} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container item xs={12} justify='center' alignItems='center'></Grid>
        </DialogTitle>
        <DialogContent className={classes.Dialog}>
          <Grid xs={12} container justify='center' alignItems='center' item>
            <Grid container item xs={12} justify='center' alignItems='center'>
              <Grid
                container
                item
                xs={5}
                justify='center'
                alignItems='center'
                className={classes.Label}>
                <Grid
                  xs={12}
                  container
                  justify='center'
                  alignItems='center'
                  item
                  className={classes.selectLabelC}>
                  {parentNode && parentNode.appl_type + parentNode.appl_num}
                </Grid>
              </Grid>
              <Grid container item xs={1} justify='center' alignItems='center'>
                <span
                  style={{
                    borderTop: '1px solid #a2a2a2',
                    width: '100%',
                    marginBottom: '-30%'
                  }}></span>
                <img alt='compareBall' style={{ width: '28px' }} src={CenterBallN}></img>
              </Grid>
              <Grid
                container
                item
                xs={5}
                justify='center'
                alignItems='center'
                className={classes.Label}>
                <Grid
                  xs={12}
                  container
                  justify='center'
                  alignItems='center'
                  item
                  className={classes.selectLabel}>
                  {' '}
                  {ChildNda && ChildNda}
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} container justify='center' alignItems='flex-start' item>
              <div style={{ height: '70vh', width: '100vw' }}>
                {loading ? (
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    className={classes.loadingContainer}>
                    <Typography>Loading...</Typography>
                  </Box>
                ) : !Errorstate && viewerURL ? (
                  <iframe
                    style={{ height: '100%', width: '100%' }}
                    src={viewerURL}
                    allowFullScreen
                    title='pdfCompare'></iframe>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%'
                    }}>
                    <Typography className={classes.StyledErrorTypography}>
                      Sorry , we couldn't find any comparision to {parentNode && parentNode.nda} and{' '}
                      {ChildNda} label. Please try other document.
                    </Typography>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RldInfo;
