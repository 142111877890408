import { memo, useEffect, useMemo, useState } from 'react';

import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Accordion, AccordionDetails, AccordionSummary } from '../Accordian';
import CaretDownIcon from '../../../../assets/svgs/Icons/CaretDown';
import landingPageStyles from '../../styles/LandingPage.styles';
import DocumentResultCard from './DocumentResultCard';
import NoResultsView from '../NoResultsView';
import styles from '../../styles/DocumentsTab.styles';
import { SearchesProps } from '../../interface';
import { encodeObjectToBase64 } from '../../../../utils/encodeDecodeObject';

const Documents = ({ searches, handleFavoriteHook, handleProjectHook }: SearchesProps) => {
  // Initialize the expanded state with all accordions set to true
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>(() =>
    searches.reduce((acc, search) => ({ ...acc, [search.id]: true }), {})
  );

  const finalDocuments = useMemo(() => {
    return searches.filter(search => search.aria_results.length > 0);
  }, [searches]);

  const handleClick = (e: any, data: any) => {
    e.stopPropagation();
    let payload: any = {};

    payload = {
      search_term: data?.term,
      source: data?.source,
      filterSource: 'default',
      filters: data?.filters,
      view_type: 'document',
      document_search_id: data?.id
    };

    const encodedPayload = encodeObjectToBase64(payload) ?? '';
    window.open(`/search/${encodedPayload}`, '_blank');
  };

  useEffect(() => {
    setExpanded(prevExpanded => {
      const newExpanded = { ...prevExpanded };
      finalDocuments.forEach(search => {
        if (!newExpanded[search.id]) {
          newExpanded[search.id] = true;
        }
      });
      return newExpanded;
    });
  }, [finalDocuments]);

  if (finalDocuments.length === 0) return <NoResultsView text='No Documents are saved' />;

  return (
    <Box sx={styles.root}>
      {finalDocuments.map((search: any) => (
        <Accordion key={search.id} expanded={expanded[search.id]}>
          <AccordionSummary
            aria-controls='panel1d-content'
            id='panel1d-header'
            onClick={(e: any) => {
              e.stopPropagation();
              setExpanded(prevExpanded => ({
                ...prevExpanded,
                [search.id]: !prevExpanded[search.id]
              }));
            }}>
            <IconButton sx={styles.accordionIconButton}>
              <CaretDownIcon
                sx={{
                  ...styles.accordionIcon,
                  rotate: expanded[search.id] ? '180deg' : '0deg'
                }}
              />
            </IconButton>
            <Typography
              sx={landingPageStyles.accordionTitleWithUnderline}
              onClick={(e: any) => {
                handleClick(e, search);
              }}>
              {search?.term}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {search.aria_results?.map((result: any, index: number) => (
              <Stack key={search?.id ?? ''} sx={{ mt: index === 0 ? 0 : '16px' }}>
                <DocumentResultCard
                  data={result}
                  key={search?.id ?? ''}
                  documentSearchId={search?.id ?? ''}
                  searchTerm={search?.term}
                  handleFavoriteHook={handleFavoriteHook}
                  handleProjectHook={handleProjectHook}
                />
              </Stack>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default memo(Documents);
