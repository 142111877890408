import { createSvgIcon } from '@mui/material';

const DocumentPdfIcon = createSvgIcon(
  <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.8122 1.08325H2.18717C1.30122 1.08325 0.583008 1.80147 0.583008 2.68742V13.9166H10.3952C11.5748 12.7364 12.2362 12.075 13.4163 10.8954V2.68742C13.4163 1.80147 12.6981 1.08325 11.8122 1.08325ZM1.65246 2.68742C1.65246 2.3921 1.89186 2.1527 2.18717 2.1527H11.8122C12.1075 2.1527 12.3469 2.3921 12.3469 2.68742V9.6388H9.13856V12.8471H1.65246V2.68742ZM10.208 12.591V10.7083H12.0908L10.208 12.591Z'
      fill='currentColor'
    />
    <path
      d='M8.60352 6.97854L9.27192 6.97854V6.31014L9.27192 5.70216H10.2686V5.03375H9.27192V4.42524H10.3483V3.75684H8.60352V6.97854Z'
      fill='currentColor'
    />
    <path
      d='M6.43874 3.77124H5.39551V3.77553V4.22096L5.4014 6.9411H5.73559C5.73559 6.9411 6.26176 6.9411 6.46441 6.93576C7.25336 6.87671 7.78196 6.25023 7.78196 5.35566C7.78196 4.40809 7.24243 3.77124 6.43874 3.77124ZM6.45263 6.26625C6.36493 6.26625 6.21629 6.26893 6.07833 6.26998L6.06818 4.43963H6.44248C7.06918 4.43963 7.11731 5.14065 7.11731 5.35561C7.11355 5.80317 6.90982 6.25823 6.45263 6.26625Z'
      fill='currentColor'
    />
    <path
      d='M3.76272 3.75714H2.71094V7.00237H3.37935V5.87946H3.76274C4.34894 5.87946 4.82418 5.40425 4.82418 4.81802C4.82418 4.2318 4.34897 3.75659 3.76274 3.75659V3.75714H3.76272ZM3.76272 5.21157H3.38306V4.42393H3.76538C3.98244 4.4238 4.1585 4.59966 4.15862 4.81672C4.15875 5.03378 3.98289 5.20984 3.76583 5.20997C3.76568 5.20997 3.76553 5.20997 3.76538 5.20997L3.76272 5.21157Z'
      fill='currentColor'
    />
  </svg>,
  'DocumentPdf'
);

export default DocumentPdfIcon;
