const categories = [
  {
    label: 'Formulation',
    value: 'formulation',
    autoCompleteAPIParam: 'formulation',
    urlRouteParam: 'formulation',
    isAdvancedSearchOption: true
  },
  {
    label: 'Route',
    value: 'route',
    autoCompleteAPIParam: 'route',
    urlRouteParam: 'route',
    isAdvancedSearchOption: true
  }
];

export default categories;
