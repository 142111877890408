import React, { Fragment, useContext, useEffect, useState } from 'react';
//MATERIAL UI IMPORTS
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
//other components
import ImmunogenicityStyles from '../styles/Biologics.styles';
import ProgressGraph from './ProgressGraph';
import BarGraph from './BarGraph';
import PieCharts from './PieCharts';
//store
import Store from '../../../store';
import Actions from '../../../store/actions';
import { getBLAstats, getBlaStatsSubset } from '../../../api/pages/Biologics';

const Immunogenicity = ({ payload = [] }) => {
  const classes = ImmunogenicityStyles();
  const { state, dispatch } = useContext(Store);
  const [progressGraph, setProgressGraph] = useState();
  const [barGraph, setBarGraph] = useState();
  const [pieCharts, setPieCharts] = useState([]);

  useEffect(() => {
    async function getData() {
      try {
        let response;

        let overviewGraph, reportingGraph;

        if (payload?.length) {
          response = await getBlaStatsSubset(payload);
          const { overview_graph, reporting_status_graph } = response.data.Success.immunogenicity;
          overviewGraph = overview_graph;
          reportingGraph = reporting_status_graph;
        } else {
          response = await getBLAstats();
          overviewGraph = response.data.Success.Immunogenicity.overviewGraph;
          reportingGraph = response.data.Success.Immunogenicity.reportingGraph;
        }

        const progressData = overviewGraph;

        const colors = [
          '#0E5A94',
          '#318AC8',
          '#008B8B',
          '#3AB09E',
          '#48E0A6',
          '#1C2D59',
          '#20A1C7'
        ];
        for (let [index, elem] of progressData.entries()) {
          elem.color = colors[index];
        }

        setProgressGraph(progressData);

        const barData = response.data.Success.business_intelligence.approvalyearGraph; // same graph is used in business intelligence view
        barData.sort((a, b) => parseInt(a.year) - parseInt(b.year));
        setBarGraph(barData);

        const pieData = reportingGraph;
        setPieCharts(pieData);
      } catch (e) {
        console.error(e);
      }
    }
    getData();
  }, []);

  const addProgressGraph = async () => {
    await dispatch({
      type: Actions.SET_CART_ITEMS,
      value: state.cartItems + 1
    });
    await dispatch({
      type: Actions.SET_REPORT,
      value: { id: 'Progress Graph', data: progressGraph }
    });
    await dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message: 'Successfully added to Report.', color: 'success' }
    });
  };

  return (
    <Fragment>
      <Grid container style={{ marginTop: '10px' }}>
        <Grid item lg={6}>
          <Box mr={2} display='flex' alignItems='center' justifyContent='space-between'>
            {progressGraph ? (
              <>
                <Typography align='left' className={classes.headings}>
                  Overview
                </Typography>
              </>
            ) : (
              <>
                <Skeleton height={16} width={100} className={classes.headings} />
                <Skeleton height={16} width={100} className={classes.add} />
              </>
            )}
          </Box>
          <ProgressGraph data={progressGraph} />
        </Grid>
        <Grid item lg={6}>
          <BarGraph data={barGraph} />
        </Grid>
        <Grid item lg={12}>
          <PieCharts data={pieCharts} />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Immunogenicity;
