import { trim, uniq } from 'lodash';
import map from 'lodash/map';
import toLower from 'lodash/toLower';
import isEmpty from 'lodash/isEmpty';
import { HIGHLIGHT_COLOR_LIST } from './constants';

interface HighlightPayloadReturn {
  s3_link: string;
  page_number: number | null;
  words_to_highlight: { word: string; color: string }[];
  phrase_to_highlight: string;
}
export const generateHighlightPayload = (
  s3SignedLink = '',
  keywordsToHighlight = [],
  phraseToHighlight = '',
  colorMappedKeywordsToHighlight = []
): HighlightPayloadReturn => {
  let pageNumber = null;
  if (phraseToHighlight) {
    const pageStr = s3SignedLink?.split('#page=')?.pop();
    pageNumber = pageStr ? parseInt(pageStr, 10) : 1;
    pageNumber -= 1;
  }
  let listOfTextToHighlight = [];
  const processedSet = uniq(map(keywordsToHighlight, item => trim(toLower(item))));
  listOfTextToHighlight = processedSet.map((word: string, index) => ({
    word,
    color: HIGHLIGHT_COLOR_LIST[index % HIGHLIGHT_COLOR_LIST.length]
  }));
  if (!isEmpty(colorMappedKeywordsToHighlight) && isEmpty(listOfTextToHighlight)) {
    listOfTextToHighlight = [...listOfTextToHighlight, ...colorMappedKeywordsToHighlight];
  }

  return {
    s3_link: s3SignedLink,
    page_number: pageNumber,
    words_to_highlight: listOfTextToHighlight,
    phrase_to_highlight: phraseToHighlight
  };
};

export default generateHighlightPayload;
