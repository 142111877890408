const styles = {
  dialog: {
    '& .MuiDialog-paper': {
      maxWidth: '580px',
      width: '580px',
      borderRadius: '8px'
    }
  },
  formHeading: {
    color: 'black.main',
    fontSize: '20px',
    fontWeight: 700,
    flexGrow: 1,
    textAlign: 'center',
    alignSelf: 'center'
  },
  divider: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'gray.400',
    borderRadius: '2px',
    mt: '10px',
    mx: 'auto',
    mb: 0,
    width: '64px'
  },
  closeIcon: {
    position: 'absolute',
    right: '24px',
    top: '20px',
    color: 'gray.600'
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      fontSize: '16px',
      fontWeight: 400,
      color: 'black.main'
    }
  },
  submitButton: {
    width: '148px',
    height: '36px',
    padding: '8px 48px',
    borderRadius: '32px',
    backgroundColor: 'primary.600',
    color: 'white.main',
    textTransform: 'capitalize'
  },
  cancelButton: {
    width: '148px',
    height: '36px',
    padding: '8px 48px',
    borderRadius: '32px',
    border: '1px solid',
    borderColor: 'grey.400',
    color: 'black.main',
    textTransform: 'capitalize',
    mr: '24px'
  },
  fieldTitle: {
    color: 'grey.700',
    fontSize: '16px',
    fontWeight: 400
  }
};

export default styles;
