const styles = {
  container: {
    display: 'flex',
    height: 'inherit'
  },
  expandedContainer: {
    width: '632px',
    margin: '28px 4px 28px 24px',
    '@media (min-width: 1024px) and (max-width: 1280px)': {
      width: '500px',
      '& video': {
        height: '290px !important'
      }
    }
  },
  loadingExpandedContainer: {
    m: 'auto',
    height: '100%'
  },
  video: {
    borderRadius: '2px',
    width: 'inherit',
    height: '355px',
    marginTop: '56px'
  },
  expandedVideoTitle: {
    color: 'gray.950',
    fontFamily: 'Mulish',
    fontSize: '20px',
    fontWeight: 700,
    my: '16px',
    lineHeight: 1
  },
  expandedVideoDescContainer: {
    borderRadius: '12px',
    background: 'rgba(0, 0, 0, 0.05)',
    p: '16px'
  },
  expandedVideoDateTagContainer: { mt: '16px', display: 'flex', gap: '12px' },
  expandedVideoDateTagText: {
    color: 'gray.750',
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontWeight: 500
  },
  expandedVideoContent: {
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Mulish',
    color: 'gray.950'
  },
  verticalLine: {
    height: 'calc(100vh - 56px)',
    m: '28px 0 28px 20px'
  },
  guidesContainer: { width: '430px', padding: '28px 24px' },
  guideTitle: {
    flexGrow: 1,
    textAlign: 'left',
    alignSelf: 'center',
    fontWeight: '700',
    fontSize: '20px',
    color: 'gray.800',
    textTransform: 'capitalize',
    lineHeight: 1
  },
  crossIcon: { position: 'absolute', top: '20px', right: '24px' },
  listContainer: {
    m: '32px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    overflowY: 'auto',
    height: '80vh',
    width: '390px'
  },
  listItemContainer: { display: 'flex', gap: '8px' },
  allTutorialButton: {
    padding: '7px 20px',
    borderRadius: '20px',
    border: '1px solid #BDBDBD',
    color: 'gray.950',
    backgroundColor: 'white',
    fontSize: '12px',
    fontWeight: 600,
    fontFamily: 'Mulish',
    cursor: 'pointer'
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center'
  },
  expandDescCtaText: {
    color: (theme: any) => `${theme.palette.primary.darkVariant2} !important`,
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: '14px',
    fontWeight: 600
  }
};

export default styles;
