import React, { useCallback, useContext, useEffect, useMemo } from 'react';

// MUI
import Stack from '@mui/material/Stack';

// Custom Components
import { debounce } from 'lodash';
import VivproDatagrid from '../../../components/Datagrid';

// store
import store from '../../../store/Letters';

// constants
import { CSV_FILENAME, LETTERS_COLUMNS } from '../const';
import { getFileName } from '../../../helpers/getFileName';

import styles from '../../Guidance/styles/Details.styles';
import actions from '../../../store/Letters/actions';
import getDatagridFilterOption from '../../../utils/getDatagridFilterOption';
import { DATAGRID_OPTION_LIST } from '../../../components/Datagrid/constants';

const LettersDetails = ({
  pagination,
  setPagination,
  sort,
  setSort,
  sortFields,
  getLettersData,
  isFilterUpdated,
  setIsFilterUpdated,
  getDatawithDataGridFilter,
  dataGridFilter
}: any) => {
  const { lettersState, lettersDispatch } = useContext(store) as any;

  useEffect(() => {
    if (isFilterUpdated) {
      setIsFilterUpdated(false);
    }
  }, [isFilterUpdated]);

  const fetchData = useCallback(
    (pageModel: any, sortModel: any, gridFilters: any) => {
      getLettersData(
        lettersState.availableFilters,
        pageModel.pageSize,
        pageModel.page * pageModel.pageSize,
        sortModel,
        false,
        gridFilters
      );
    },
    [lettersState.availableFilters, lettersState]
  );

  const handlePageChange = useCallback(
    (pageModel: any) => {
      if (!isFilterUpdated) {
        fetchData(pageModel, sortFields, dataGridFilter);
        setPagination(pageModel);
      }
    },
    [sortFields, lettersState.availableFilters, isFilterUpdated, lettersState, dataGridFilter]
  );

  const handleSortModelChange = useCallback(
    (newSortingModel: any) => {
      const modifiedList: any = [];

      newSortingModel.forEach((item: any) => {
        modifiedList.push(item);
      });

      if (JSON.stringify(modifiedList) !== JSON.stringify(lettersState.sortFields)) {
        fetchData(pagination, modifiedList, dataGridFilter);
      }
      lettersDispatch({
        type: actions.SET_SORT,
        value: modifiedList
      });

      setSort(modifiedList);
    },
    [pagination, sortFields, lettersState.filters, lettersState, actions, dataGridFilter]
  );

  const renderableTableColumns = useMemo(() => {
    const renderableColumns = [...LETTERS_COLUMNS];

    return getDatagridFilterOption(renderableColumns, DATAGRID_OPTION_LIST, lettersState);
  }, [lettersState]);

  const handleFilterChange = useCallback(
    debounce(newFilterModel => {
      if (newFilterModel?.items?.length > 0) {
        getDatawithDataGridFilter(
          lettersState.availableFilters,
          newFilterModel,
          sortFields,
          pagination.pageSize,
          pagination.page * pagination.pageSize
        );
      } else {
        fetchData(pagination, sortFields, {});
      }
    }, 300),
    [lettersState.availableFilters, sortFields]
  );

  useEffect(() => {
    return () => {
      handleFilterChange.cancel();
    };
  }, [handleFilterChange]);

  return (
    <Stack sx={styles.root}>
      <VivproDatagrid
        rows={lettersState.tableData}
        columnsMapping={renderableTableColumns}
        csvFileName={getFileName(CSV_FILENAME)}
        rowId='fda_letter_id'
        loading={lettersState.loading}
        rowCount={lettersState.totalRecord}
        isPagination
        pagination={pagination}
        setPagination={handlePageChange}
        sortingModel={sort}
        handleSortModelChange={handleSortModelChange}
        isServerSideExport
        isFilterModeServer
        allowFilterPanelWithLogicOperators
        handleFilterModelChange={handleFilterChange}
      />
    </Stack>
  );
};

export default React.memo(LettersDetails);
