/* eslint-disable no-underscore-dangle */
import React, { useCallback, useContext, useMemo, useEffect, useState } from 'react';

// MUI
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';

// Custom Components
import { debounce } from 'lodash';
import VivproDatagrid from '../../../components/Datagrid';

// store
import store from '../../../store/CanadaGuidance';
import actions from '../../../store/CanadaGuidance/actions';
import Search from './Search';

import { getFileName } from '../../../helpers/getFileName';

import PDFCellComponent from '../../Guidance/components/PDFCellRenderer';

// constants
import { CANADA_GUIDANCE_COLUMN, CSV_FILENAME } from '../const';
import getTableColumnWithCellRenderers from '../../../utils/getTableColumnWithCellRenders';
import styles from '../../Guidance/styles/Details.styles';

import getDatagridFilterOption from '../../../utils/getDatagridFilterOption';
import { DATAGRID_OPTION_LIST } from '../../../components/Datagrid/constants';

const CanadaGuidanceDetails = ({
  pagination,
  setPagination,
  sort,
  setSort,
  sortFields,
  getCanadaGuidanceData,
  isFilterUpdated,
  setIsFilterUpdated,
  getDatawithDataGridFilter,
  dataGridFilter
}: any) => {
  const datagridMessage = 'Loading...';
  // eslint-disable-next-line no-unused-vars
  const [pdfUrl, setPdfUrl] = useState('');
  const { CanadaGuidanceState, CanadaGuidanceDispatch } = useContext(store);

  useEffect(() => {
    if (isFilterUpdated) {
      setIsFilterUpdated(false);
    }
  }, [isFilterUpdated]);

  const fetchData = useCallback(
    (pageModel: any, sortModel: any, gridFilters: any, searchTerm: string = '') => {
      getCanadaGuidanceData(
        {
          ...CanadaGuidanceState.availableFilters,
          ...(searchTerm ? { filter_keywords: [searchTerm] } : {})
        },
        pageModel.pageSize,
        pageModel.page * pageModel.pageSize,
        sortModel,
        false,
        gridFilters
      );
    },
    [CanadaGuidanceState?.availableFilters]
  );

  const handlePageChange = useCallback(
    (pageModel: any) => {
      if (!isFilterUpdated) {
        fetchData(pageModel, sortFields, dataGridFilter);
        setPagination(pageModel);
      }
    },
    [sortFields, CanadaGuidanceState?.availableFilters, isFilterUpdated, dataGridFilter]
  );

  const handleSortModelChange = useCallback(
    (newSortingModel: any) => {
      const modifiedList: any = [];

      newSortingModel.forEach((item: any) => {
        modifiedList.push(item);
      });

      if (JSON.stringify(modifiedList) !== JSON.stringify(CanadaGuidanceState?.sortFields)) {
        fetchData(pagination, modifiedList, dataGridFilter);
      }
      CanadaGuidanceDispatch({
        type: actions.SET_SORT,
        value: modifiedList
      });

      setSort(modifiedList);
    },
    [
      pagination,
      sortFields,
      CanadaGuidanceState?.filters,
      CanadaGuidanceState,
      actions,
      dataGridFilter
    ]
  );

  const handlePDFOpenClick = useCallback((pdfLink: any) => {
    setPdfUrl(pdfLink);
  }, []);

  const pdfCellRenderer = useCallback(
    ({ value, rowData }: any) => (
      <PDFCellComponent value={value} rowData={rowData} onPDFButtonClick={handlePDFOpenClick} />
    ),
    [handlePDFOpenClick]
  );

  const tableColumns = useMemo(
    () =>
      getTableColumnWithCellRenderers(CANADA_GUIDANCE_COLUMN, [
        { field: 's3_path', cellRenderer: pdfCellRenderer }
      ]),
    [pdfCellRenderer]
  );

  const handleComparisonCheckbox = useCallback(
    (id: string) => {
      if (CanadaGuidanceState?.documentsToCompare?.find((doc: any) => doc?._id === id)) {
        CanadaGuidanceDispatch({
          type: actions.REMOVE_DOCUMENT_TO_COMPARE,
          value: id
        });
      } else {
        CanadaGuidanceDispatch({
          type: actions.SET_DOCUMENT_TO_COMPARE,
          value: CanadaGuidanceState?.tableData?.find((doc: any) => doc?._id === id)
        });
      }
    },
    [CanadaGuidanceState?.tableData, CanadaGuidanceState?.documentsToCompare]
  );

  const renderableTableColumns = useMemo(() => {
    const renderableColumns = [...tableColumns];
    if (CanadaGuidanceState.compareEnabled) {
      renderableColumns.unshift({
        field: '_id',
        headerName: 'Select',
        width: 100,
        headerClassName: 'table-header',
        cellClassName: 'ria-MuiDataGrid-cell',
        disableExport: true,
        renderCell: (params: any) => {
          return (
            <Tooltip
              title={
                CanadaGuidanceState.documentsToCompare.length === 2 &&
                !CanadaGuidanceState.documentsToCompare.find((doc: any) => doc._id === params.value)
                  ? 'Selection limit reached'
                  : ''
              }>
              <Box key={params.value}>
                <Radio
                  disabled={
                    CanadaGuidanceState.documentsToCompare.length === 2 &&
                    !CanadaGuidanceState.documentsToCompare.find(
                      (doc: any) => doc._id === params.value
                    )
                  }
                  checked={
                    !!CanadaGuidanceState.documentsToCompare.find(
                      (doc: any) => doc?._id === params.value
                    )
                  }
                  onClick={() => handleComparisonCheckbox(params.value)}
                />
              </Box>
            </Tooltip>
          );
        }
      });
    }

    return getDatagridFilterOption(renderableColumns, DATAGRID_OPTION_LIST, CanadaGuidanceState);
  }, [
    CanadaGuidanceState.documentsToCompare,
    tableColumns,
    CanadaGuidanceState.compareEnabled,
    CanadaGuidanceState.tableData
  ]);

  const handleFilterChange = useCallback(
    debounce(newFilterModel => {
      if (newFilterModel?.items?.length > 0) {
        getDatawithDataGridFilter(
          CanadaGuidanceState.availableFilters,
          newFilterModel,
          sortFields,
          pagination.pageSize,
          pagination.page * pagination.pageSize
        );
      } else {
        fetchData(pagination, sortFields, {});
      }
    }, 300),
    [CanadaGuidanceState.availableFilters, sortFields]
  );

  useEffect(() => {
    return () => {
      handleFilterChange.cancel();
    };
  }, [handleFilterChange]);

  return (
    <Stack sx={CanadaGuidanceState.compareEnabled ? styles.rootWithComparisonEnabled : styles.root}>
      {CanadaGuidanceState.compareEnabled && (
        <Box paddingTop={1} paddingBottom={1} display='flex' alignItems='center'>
          <Search
            pagination={pagination}
            sortFields={sortFields}
            dataGridFilter={dataGridFilter}
            fetchData={fetchData}
          />
        </Box>
      )}
      <VivproDatagrid
        rows={CanadaGuidanceState?.tableData ?? []}
        columnsMapping={renderableTableColumns}
        rowId='_id'
        csvFileName={getFileName(CSV_FILENAME)}
        noRowMessage={datagridMessage}
        loading={CanadaGuidanceState.loading}
        rowCount={CanadaGuidanceState?.totalRecord ?? 0}
        isPagination
        pagination={pagination}
        setPagination={handlePageChange}
        sortingModel={sort}
        handleSortModelChange={handleSortModelChange}
        isServerSideExport
        isFilterModeServer
        allowFilterPanelWithLogicOperators
        handleFilterModelChange={handleFilterChange}
      />
    </Stack>
  );
};

export default React.memo(CanadaGuidanceDetails);
