import React, {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

// mui5
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button } from '@mui/material';

// CDP Reused Components
import styles from '../../../pages/CDP/styles/CDPMain';
import { GRAPH_ENTITIES } from '../../../pages/CDP/const';
import {
  getSelectedBarMarker,
  getTrialsGraphData,
  mapGraphEntityData
} from '../../../pages/CDP/utils';
import Loading from '../../../pages/CDP/components/Loading';
import ResultsStore from '../../../store/SearchResults';

// Timeline Components
import TimeSeriesGraphSkeleton from '../../CustomComponents/Graphs/CustomTimeSeriesSkeleton';

// Lazy import TimeSeries
const TimeSeriesGraph = lazy(() => import('../../CustomComponents/Graphs/CustomTimeSeries'));

const TimelineCT: React.FC<any> = ({
  ctId,
  setCtId,
  showDocs,
  setShowDocs,
  setTrialsCount,
  setWarningMessage,
  onEntitySelectionUpdate = null
}) => {
  const { resultsState } = useContext(ResultsStore);
  const [donePageDataLoadingAnimation, setDonePageDataLoadingAnimation] = useState(false);
  const [selectedGraphEntityData, setGraphEntityData] = useState<{
    [key: string]: string | number;
  } | null>(null);

  const graphRef = useRef();

  const handleHorizontalBarClick = useCallback(
    (data: any) => {
      if (data.points && data.points[0]) {
        // Clear previous Clinical Trials bar selections
        const prevSelectedCTBar = resultsState.trialsData.barGraphData.find(
          ({ name }: { name: string }) => name === selectedGraphEntityData?.ctId
        );

        if (prevSelectedCTBar) {
          delete prevSelectedCTBar.marker.line;
        }

        // If selected bar is clinical trials bar
        if (data.points[0].customdata[0] === GRAPH_ENTITIES.CLINICAL_TRIAL) {
          const selectedBar = resultsState.trialsData.barGraphData.find(
            ({ name }: { name: string }) => name === data.points[0].label
          );
          if (selectedBar) {
            selectedBar.marker = { ...(selectedBar.marker || {}), ...getSelectedBarMarker() };
          }
          const ctData = {
            ctId: data.points[0].label,
            startDate: data.points[0].customdata[4],
            endDate: data.points[0].customdata[5],
            sampleSize: data.points[0].customdata[1],
            phase: data.points[0].customdata[2],
            studyTitle: data.points[0].customdata[3],
            nctIdInReview: data.points[0].customdata[6],
            primaryCompletionDate: data.points[0].customdata[7]
          };
          setGraphEntityData(ctData);
          if (ctId) {
            setCtId(ctData.ctId);
          } else {
            setShowDocs(false);
          }
        }
      }
    },
    [
      ctId,
      resultsState.trialsData.barGraphData,
      selectedGraphEntityData?.ctId,
      selectedGraphEntityData?.submission
    ]
  );

  const handleViewDocsClose = useCallback(() => {
    const prevSelectedBar = resultsState.trialsData.barGraphData.find(
      ({ name }: { name: string }) => name === selectedGraphEntityData?.ctId
    );
    if (prevSelectedBar) {
      delete prevSelectedBar.marker.line;
    }

    setGraphEntityData(null);
    setCtId('');
    setShowDocs(false);
  }, [
    resultsState.trialsData.barGraphData,
    selectedGraphEntityData?.ctId,
    selectedGraphEntityData?.submission
  ]);

  useEffect(() => {
    if (selectedGraphEntityData && resultsState.trialsData) {
      handleViewDocsClose();
    }
  }, [resultsState.trialsData]);

  const handleViewDocsClick = useCallback(() => {
    if (selectedGraphEntityData?.ctId) {
      setCtId(selectedGraphEntityData?.ctId as string);
    }

    setShowDocs(true);
  }, [selectedGraphEntityData]);

  const mappedEntityData = useMemo(
    () => mapGraphEntityData(selectedGraphEntityData),
    [selectedGraphEntityData]
  );

  const addRef = useCallback(
    (ref: any) => {
      graphRef.current = ref;
    },
    [showDocs]
  );

  // Update Bargraph data when user switches the showTrials.
  const { barGraphData, nctIds } = useMemo(() => {
    const { barGraphData: bData, nctIds: ids } = getTrialsGraphData(resultsState.trialsData, false);
    if (ids.length > 50) {
      setWarningMessage(
        'For optimal chart experience, please narrow results to less than 30 trials'
      );
    }
    setTrialsCount(ids.length);
    bData
      .filter((bar: any) => bar.customType === 'fake_label')
      .forEach((bar: any) => {
        // eslint-disable-next-line no-shadow
        const trial = resultsState.trialsData.trials_data.find(
          (tr: any) => tr.euct_id === bar.name
        );
        if (trial && trial.trial_information) {
          // eslint-disable-next-line no-param-reassign
          bar.text = trial.trial_information;
        }
      });
    return { barGraphData: bData, nctIds: ids };
  }, [resultsState.trialsData.nctIds, selectedGraphEntityData]);

  const handleDoneAnimation = useCallback(() => {
    setDonePageDataLoadingAnimation(true);
  }, []);

  useEffect(() => {
    onEntitySelectionUpdate?.(selectedGraphEntityData);
  }, [selectedGraphEntityData]);

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      height='100%'
      overflow='hidden'
      position='relative'>
      {
        // eslint-disable-next-line no-nested-ternary
        resultsState.trialLoading || resultsState.loading ? (
          <Box justifySelf='center' alignSelf='center' height='600px'>
            {/* Loading skeleton */}
            <Loading
              loading={resultsState.trialLoading || resultsState.loading}
              onDoneAnimation={handleDoneAnimation}
              fda={false}
            />
          </Box>
        ) : donePageDataLoadingAnimation &&
          !resultsState.trialsData.barGraphData &&
          resultsState.trialError ? (
          <Box justifySelf='center' alignSelf='center' margin='auto'>
            <Typography variant='h3'>{resultsState.trialError}</Typography>
          </Box>
        ) : (
          <Box height='100%' position='relative'>
            <Suspense fallback={<TimeSeriesGraphSkeleton />}>
              <TimeSeriesGraph
                ref={addRef}
                barGraphData={barGraphData}
                shapes={resultsState.trialsData.shapes}
                annotations={resultsState.trialsData.annotations}
                yAxisIds={nctIds}
                onHorizontalBarClick={handleHorizontalBarClick}
                plotConfig={{
                  toImageButtonOptions: {
                    filename: `clinical_trials_timeline_plot`
                  }
                }}
              />
            </Suspense>
            {selectedGraphEntityData && (
              <Box
                position='absolute'
                width='520px'
                minHeight='72px'
                top='0px'
                left='50px'
                borderRadius='10px'
                sx={{ backgroundColor: 'primary.backgroundDark' }}
                zIndex='100'>
                <CancelIcon onClick={handleViewDocsClose} sx={styles.closeBtn} />
                <Box p={1} height='100%'>
                  <Box minHeight='100px'>
                    {Object.keys(mappedEntityData).map((key: string) => (
                      <Box key={key}>
                        {key}: {mappedEntityData[key]}
                      </Box>
                    ))}
                  </Box>
                  <Box display='flex' justifyContent='center' width='100%' my={1}>
                    <Button
                      variant='contained'
                      onClick={handleViewDocsClick}
                      sx={{ color: 'white !important', textTransform: 'none' }}>
                      View Documents
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
            {donePageDataLoadingAnimation &&
              resultsState.trialsData.nctIds &&
              resultsState.trialsData.nctIds.length === 0 && (
                <Box
                  position='absolute'
                  top='42.5%'
                  left='50%'
                  sx={{ transform: 'translate(-50%, -50%)' }}>
                  <Typography variant='h3'>Could not find trials.</Typography>
                </Box>
              )}
          </Box>
        )
      }
    </Box>
  );
};

export default React.memo(TimelineCT);
