import { memo, useContext, useState, useCallback, MouseEvent } from 'react';
import { useParams } from 'react-router-dom';

import { Tooltip, MenuItem, ListItemText, IconButton } from '@mui/material';

import { UrlParameters } from '../../../types/reactRouterDom';
import { RoundedMenu } from '../../../components/ui/Menu';
import { DownloadIcon } from '../../../assets/svgs/Icons';
import ResultsActions from '../../../store/SearchResults/actions';
import ResultsStore from '../../../store/SearchResults';
import styles from '../styles/DownloadButton.styles';
import getCtSource from '../utils/getCtSource';
import { decodeBase64ToObject } from '../../../utils/encodeDecodeObject';

const ClinicalTrialsDownloadButton = () => {
  const { resultsDispatch } = useContext(ResultsStore);
  const { payload } = useParams<UrlParameters>();
  const payloadObj: any = decodeBase64ToObject(payload || '');
  const ctSource = getCtSource(payloadObj?.source);
  const [isDownloadListOpen, setIsDownloadListOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setIsDownloadListOpen(false);
  }, []);

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsDownloadListOpen(true);
  }, []);

  return (
    <>
      <Tooltip title='Download'>
        <IconButton onClick={handleClick}>
          <DownloadIcon sx={styles.downloadIcon} />
        </IconButton>
      </Tooltip>
      <RoundedMenu
        open={isDownloadListOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={styles.roundedMenu}>
        <Tooltip title='Download all clinical trials as csv (.csv) file' placement='left'>
          <MenuItem
            onClick={() => {
              resultsDispatch({
                type: ResultsActions.SET_CT_EXPORT,
                value: {
                  type: 'csv',
                  isOn: true,
                  source: ctSource,
                  columns: []
                }
              });

              handleClose();
            }}>
            <ListItemText primary='All Clinical Trials (.csv)' />
          </MenuItem>
        </Tooltip>
        <Tooltip title='Download all clinical trials as excel (.xlsx) file' placement='left'>
          <MenuItem
            onClick={() => {
              resultsDispatch({
                type: ResultsActions.SET_CT_EXPORT,
                value: {
                  type: 'xlsx',
                  isOn: true,
                  source: ctSource,
                  columns: []
                }
              });

              handleClose();
            }}>
            <ListItemText primary='All Clinical Trials (.xlsx)' />
          </MenuItem>
        </Tooltip>
      </RoundedMenu>
    </>
  );
};

export default memo(ClinicalTrialsDownloadButton);
