import { createSvgIcon } from '@mui/material';

const BlaIcon = createSvgIcon(
  <svg width='26' height='14' viewBox='0 0 26 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect y='0.5' width='26' height='13' rx='4' fill='#6868FA' />
    <path
      d='M4.92718 10.5V4.155H7.57318C8.25718 4.155 8.78218 4.299 9.14818 4.587C9.52018 4.875 9.70618 5.28 9.70618 5.802C9.70618 6.192 9.58918 6.519 9.35518 6.783C9.12118 7.047 8.80018 7.224 8.39218 7.314V7.179C8.86018 7.239 9.22318 7.404 9.48118 7.674C9.73918 7.944 9.86818 8.301 9.86818 8.745C9.86818 9.309 9.67618 9.744 9.29218 10.05C8.91418 10.35 8.38018 10.5 7.69018 10.5H4.92718ZM5.83618 9.771H7.59118C8.01718 9.771 8.35018 9.69 8.59018 9.528C8.83618 9.366 8.95918 9.093 8.95918 8.709C8.95918 8.325 8.83618 8.052 8.59018 7.89C8.35018 7.728 8.01718 7.647 7.59118 7.647H5.83618V9.771ZM5.83618 6.927H7.42918C7.88518 6.927 8.22718 6.84 8.45518 6.666C8.68318 6.492 8.79718 6.237 8.79718 5.901C8.79718 5.565 8.68318 5.31 8.45518 5.136C8.22718 4.962 7.88518 4.875 7.42918 4.875H5.83618V6.927ZM11.0883 10.5V4.155H12.0243V9.699H15.1383V10.5H11.0883ZM15.2301 10.5L18.0561 4.155H18.8571L21.7281 10.5H20.7651L19.9821 8.7L20.3781 8.952H16.5351L16.9581 8.7L16.1751 10.5H15.2301ZM18.4431 5.181L17.0751 8.412L16.8591 8.205H20.0541L19.8741 8.412L18.4791 5.181H18.4431Z'
      fill='white'
    />
  </svg>,
  'BlaIcon'
);

export default BlaIcon;
