const whatsNewStyles = {
  drawer: {
    '& > .MuiDrawer-paper': {
      borderRadius: '16px 16px 0px 0px',
      width: '1008px',
      height: '98vh',
      padding: '20px 32px',
      backgroundColor: 'gray.lightVariant8',
      margin: 'auto'
    }
  },
  drawerContainer: { height: 'calc(98vh - 110px)' },
  tabContainer: {
    height: '632px',
    display: 'flex',
    justifyContent: 'center'
  },
  header: {
    container: { display: 'flex', flexDirection: 'column', mb: '24px' },
    bar: {
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'stretch'
    },
    titleWrapper: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'center',
      gap: '8px',
      alignItems: 'center'
    },
    title: {
      textAlign: 'center',
      color: 'primary.darkVariant1',
      fontSize: '20px',
      fontWeight: 700,
      fontFamily: 'Mulish'
    },
    dividerWrapper: { display: 'flex', flexDirection: 'row', justifyContent: 'center', mr: '34px' },
    divider: {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'primary.300',
      borderRadius: '2px',
      width: '64px'
    },
    countChip: {
      backgroundColor: 'secondary.650',
      color: 'white.main',
      borderRadius: '8px',
      padding: '2px 6px',
      m: '0 !important',
      width: '47px',
      height: '18px'
    },
    countChipText: {
      fontWeight: '500',
      textAlign: 'center',
      fontSize: '10px',
      color: 'white',
      fontFamily: 'Mulish'
    }
  },
  content: { display: 'flex', gap: '12px', height: 'inherit' },
  tabContent: {
    display: 'flex',
    gap: '12px',
    height: 'inherit',
    p: '20px',
    backgroundColor: 'gray.lightVariant8',
    borderRadius: '16px',
    mt: '14px'
  },
  leftContainer: {
    width: '264px',
    minWidth: '264px',
    padding: '20px 12px 16px 12px',
    backgroundColor: 'white !important',
    borderRadius: '8px',
    overflowY: 'auto'
  },
  viewAllUpdatesButtonWrapper: {
    textAlign: 'center',
    mt: '24px'
  },
  viewAllUpdatesButton: {
    padding: '7px 20px',
    borderRadius: '20px',
    border: '1px solid #BDBDBD',
    color: 'gray.950',
    backgroundColor: 'white',
    fontSize: '12px',
    fontWeight: 600,
    fontFamily: 'Mulish',
    cursor: 'pointer'
  },
  rightContainer: {
    width: '672px',
    backgroundColor: 'white !important',
    borderRadius: '8px',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  mainContent: {
    descriptionWrapper: {
      borderBottom: '1px solid ',
      borderColor: 'gray.650',
      bgcolor: 'white.smokeDark',
      borderRadius: '8px 8px 0 0',
      padding: '16px'
    },
    descriptionAboutText: {
      fontSize: '14px',
      fontWeight: 700,
      color: 'gray.950',
      fontFamily: 'Mulish'
    },
    mainDescription: {
      fontSize: '14px',
      fontWeight: 500,
      color: 'gray.950',
      fontFamily: 'Mulish',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical'
    },
    image: { width: '640px', height: '360px', borderRadius: '8px', border: '1px solid lightgray' },
    title: {
      color: 'black',
      fontFamily: 'Mulish',
      fontSize: '16px',
      fontWeight: 700,
      mt: '6px'
    },
    description: {
      color: 'black',
      fontFamily: 'Mulish',
      fontSize: '14px',
      fontWeight: 400
    },
    iconButton: {
      height: '30px',
      width: '30px',
      backgroundColor: 'primary.600',
      '& svg': {
        color: 'white.main'
      },
      '&:disabled': {
        backgroundColor: 'gray.200',
        '& svg': {
          color: 'gray.400'
        }
      },
      '&:hover': {
        backgroundColor: 'primary.700',
        '& svg': {
          color: 'white !important'
        }
      },
      '&:active': {
        backgroundColor: 'primary.900',
        '& svg': {
          color: 'white !important'
        }
      }
    },
    icon: { height: '14px', width: '14px' },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      gap: '12px',
      marginTop: '8px'
    },
    link: {
      fontFamily: 'Mulish',
      fontWeight: 400,
      color: 'primary.550',
      fontSize: '14px'
    }
  },
  accordionSummary: {
    icon: { height: '16px', width: '16px', ml: '4px', mr: '2px' },
    main: {
      '&:before': {
        display: 'none'
      }
    },
    container: {
      flexDirection: 'row-reverse',
      padding: '0px',
      maxHeight: '16px !important',
      minHeight: '16px !important',
      mb: '12px'
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between'
    },
    title: {
      fontFamily: 'Mulish',
      fontSize: '12px',
      fontWeight: 600,
      width: '166px',
      color: 'gray.650'
    },
    infoIcon: {
      height: '10px',
      width: '10px',
      color: 'secondary.350'
    },
    date: {
      fontFamily: 'Mulish',
      fontSize: '11px',
      fontWeight: 500,
      width: '46px',
      color: 'gray.550'
    },
    divider: {
      mt: '12px',
      mb: '24px',
      borderColor: 'gray.background'
    }
  },
  accordionDetails: {
    container: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: '8px'
    },
    iconsWrapper: {
      display: 'flex',
      gap: '4px',
      alignItems: 'center'
    },
    unseenDot: {
      bgcolor: 'primary.darkVariant2',
      width: '5px',
      height: '5px',
      borderRadius: '8px'
    },
    itemWrapper: {
      display: 'flex',
      gap: '12px',
      alignItems: 'center',
      padding: '8px 24px 8px 2px',
      cursor: 'pointer'
    },
    iconWrapper: {
      height: '32px',
      width: '32px',
      backgroundColor: 'white.smokeDark',
      borderRadius: '4px'
    },
    icon: {
      height: '12px',
      width: '12px',
      margin: '10px'
    },
    title: {
      color: '#0D141C',
      fontFamily: 'Mulish',
      fontSize: '13px',
      fontWeight: 500,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical'
    },
    selectedItemWrapper: {
      display: 'flex',
      gap: '12px',
      alignItems: 'center',
      padding: '8px 24px 8px 2px',
      cursor: 'pointer',
      borderRadius: '8px',
      backgroundColor: 'primary.lightVariant1'
    },
    selectedIconWrapper: {
      height: '32px',
      width: '32px',
      backgroundColor: 'primary.150',
      borderRadius: '4px'
    },
    selectedIcon: {
      height: '12px',
      width: '12px',
      margin: '10px',
      color: 'primary.textVariant'
    }
  },
  headingContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  mainHeading: {
    color: 'gray.950',
    fontFamily: 'Mulish',
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '120%',
    letterSpacing: '-0.96px'
  },
  mainHeading1: {
    color: 'gray.950',
    fontFamily: 'Mulish',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '120%',
    letterSpacing: '-0.56px'
  },
  mainHeading2: {
    color: 'gray.950',
    fontFamily: 'Mulish',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '120%'
  },
  subHeading: {
    color: 'gray.950',
    fontFamily: 'Mulish',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '120%'
  },
  subHeading1: {
    color: 'gray.950',
    fontFamily: 'Mulish',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '130%'
  },
  slide1Img: {
    position: 'absolute',
    bottom: '24px',
    right: '-10px',
    height: '120px',
    '& img': {
      opacity: '0.8'
    }
  },
  slideImg: {
    position: 'absolute',
    bottom: '-10px',
    right: '-20px',
    height: '180px',
    opacity: 0.5,
    '& svg': {
      color: 'primary.550',
      height: '180px',
      width: '240px'
    }
  },
  slideImg1: {
    position: 'absolute',
    bottom: '-20px',
    left: '-20px',
    height: '180px',
    opacity: 0.5,
    '& svg': {
      color: 'primary.550',
      height: '180px',
      width: '240px'
    }
  },
  navButton1: {
    width: 56,
    height: 56,
    borderRadius: '35px',
    background: 'white',
    border: '1px solid black',
    position: 'absolute',
    bottom: '32px',
    left: '32px'
  },
  navButton2: {
    width: 56,
    height: 56,
    borderRadius: '35px',
    background: 'white',
    border: '1px solid black',
    position: 'absolute',
    bottom: '32px',
    left: '98px'
  },
  navButton3: {
    width: 56,
    height: 56,
    borderRadius: '35px',
    background: 'white',
    border: '1px solid black'
  },
  statsCtaWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    position: 'absolute',
    bottom: '28px',
    right: '20px'
  },
  statsCtaText: {
    color: 'primary.darkVariant2',
    fontFamily: 'Mulish',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '120%'
  },
  secondaryCrossIconButton: {
    position: 'absolute !important',
    right: '12px',
    top: '12px'
  },
  tabNoResults: {
    container: {
      width: '828px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    iconWrapper: {
      width: '64px',
      height: '64px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      bgcolor: 'white !important',
      borderRadius: '50%',
      mb: '24px'
    },
    icon: {
      height: '22px',
      width: '22px',
      color: 'gray.950'
    },
    title: {
      color: 'gray.950',
      fontFamily: 'Mulish',
      fontWeight: 700,
      fontSize: '18px'
    },
    subTitle: {
      color: 'gray.950',
      fontFamily: 'Mulish',
      fontWeight: 400,
      fontSize: '14px',
      textAlign: 'center'
    }
  },
  statsNumber: {
    color: 'black',
    fontFamily: 'Mulish',
    fontSize: '72px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '100%'
  },
  statsText: {
    color: 'gray.950',
    fontFamily: 'Mulish',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '120%',
    mt: '4px'
  },
  statsContainer: {
    mt: '82px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    gap: '24px'
  },
  modalContainer: {
    '& .MuiDialog-paper': {
      width: '500px',
      height: '400px',
      borderRadius: '12px',
      backgroundColor: 'primary.100',
      overflow: 'hidden',
      p: '20px 20px 24px 32px',
      '& .MuiDialogContent-root': {
        p: 0
      }
    }
  },
  modalLoadingContainer: {
    height: '100%',
    backgroundColor: 'white !important',
    borderRadius: '8px',
    '& .MuiLinearProgress-bar': {
      backgroundColor: 'primary.avatar'
    }
  }
};

export default whatsNewStyles;
