import Button from '@mui/material/Button';

// icons
import SendIcon from '../../Images/svgs/sendIcon';
import AskRiaButtonStyles from './styles/AskRiaButton.styles';

const AskRiaButton = ({
  setOpenAriaSearch,
  iconId,
  text,
  disabled = false
}: {
  setOpenAriaSearch: any;
  iconId: string;
  text: string;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
}) => {
  return (
    <Button
      id={iconId}
      onClick={() => {
        setOpenAriaSearch(true);
      }}
      value='submit'
      aria-label='submit'
      sx={AskRiaButtonStyles.button}
      disabled={disabled}>
      {text}
      <SendIcon sx={AskRiaButtonStyles.sendIcon} />
    </Button>
  );
};

export default AskRiaButton;
