import React from 'react';

const css = `
.close_icon{cursor:pointer}.close_a{fill:#ea5252;}
`;

const CloseIcon = () => {
  return (
    <svg
      className='close_icon'
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <defs>
        <style>{css}</style>
      </defs>
      <g transform='translate(-2.084 -2.083)'>
        <path
          className='close_a'
          d='M14.084,26.083A12.014,12.014,0,1,0,5.6,22.565,12,12,0,0,0,14.084,26.083Zm0-21.818A9.829,9.829,0,1,1,7.145,7.144,9.818,9.818,0,0,1,14.084,4.265Z'
        />
        <path
          className='close_a'
          d='M17.054,26.835a1.318,1.318,0,0,0,1.864,0l3.023-3.023,3.023,3.023a1.318,1.318,0,0,0,1.864-1.864L23.8,21.949l3.023-3.023a1.318,1.318,0,0,0-1.864-1.864L21.94,20.085l-3.023-3.023a1.318,1.318,0,0,0-1.864,1.864l3.023,3.023-3.023,3.023a1.318,1.318,0,0,0,0,1.864Z'
          transform='translate(-7.854 -7.868)'
        />
      </g>
    </svg>
  );
};

export default CloseIcon;
