import React, { createContext, useReducer } from 'react';
import reducers from './reducers';

type Props = {
  children: React.ReactNode;
};

export interface CanadaConsultationContextInterface {
  canadaConsultationStats: any;
  tableData: any[];
  donutCharts: any[];
  issueDate: any[];
  // adopted_date: any[];
  canadaConsultationErrors: any[];
  filters: any[];
  availableFilters: any;
  loading: boolean;
  isFiltersApplied: boolean;
  search: any;
  documentsToCompare: any[];
  compareEnabled: boolean;
  comparePopupOpen: boolean;
}

const initialState = {
  canadaConsultationStats: {},
  tableData: [],
  donutCharts: [],
  issueDate: [],
  // adopted_date: [],
  canadaConsultationErrors: [],
  filters: [],
  availableFilters: {},
  loading: true,
  isFiltersApplied: false,
  search: {},
  documentsToCompare: [],
  compareEnabled: false,
  comparePopupOpen: false
};

const CanadaConsultationStore = createContext<{
  CanadaConsultationState: CanadaConsultationContextInterface;
  CanadaConsultationDispatch: React.Dispatch<any>;
}>({ CanadaConsultationState: initialState, CanadaConsultationDispatch: () => null });
const { Provider } = CanadaConsultationStore;

export const CanadaConsultationStateProvider = ({ children }: Props) => {
  const [CanadaConsultationState, CanadaConsultationDispatch] = useReducer(reducers, initialState);
  return (
    <Provider value={{ CanadaConsultationState, CanadaConsultationDispatch }}>{children}</Provider>
  );
};

export default CanadaConsultationStore;
