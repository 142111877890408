import makeRequest from '../client';

/**
 * Function to get BLA statistics
 */
export const getBLAstats = () => makeRequest(`/clignosis/bla_stats`, 'GET');

/**
 * Function to get BLA statistics for a list of BLAs
 */
export const getBlaStatsSubset = payload => makeRequest(`/clignosis/bla/stats`, 'POST', payload);
