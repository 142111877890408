import palette from '../../../themev5/palette';

const headerStyles = {
  search: {
    backgroundColor: palette.white.main,
    width: 'inherit',
    borderRadius: 4,
    border: 'none',
    outline: 'none',
    display: 'flex',
    fieldset: {
      display: 'none'
    },
    '& .MuiTextField-root': {
      justifyContent: 'center'
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingTop: '0 ',
      paddingBottom: '0 ',
      fontSize: '0.875rem'
    }
  },
  submitBtn: {
    color: 'white.main',
    fontWeight: 'bold',
    borderRadius: '30px',
    textTransform: 'capitalize',
    height: 40
  },
  searchBox: {
    minWidth: { sm: 150, lg: 300 },
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 0,
    outline: 'none',
    opacity: 1,
    height: 38,
    '& > div': {
      height: '100%',
      width: '100%',
      '& input': {
        // height: '80%',
      }
    }
  },
  AdvancedRowWrapper: {
    width: '70%',
    '& div#autoCompleteRow': {
      minHeight: '100%',
      '& .MuiAutocomplete-root': {
        height: '100%',
        '& .MuiFormControl-root': {
          height: '100%',
          '& .MuiInputBase-root': {
            height: '100%'
          }
        }
      }
    },
    '& div#row': {
      animation: 'slideInFromLeft 0.3s ease-in'
    },
    '@keyframes slideInFromLeft': {
      from: {
        transform: 'scale(0.5, 0.5)',
        opacity: 0
      },
      to: {
        transform: 'scale(1,1)',
        opacity: 1
      }
    }
  },
  advanceSearchDateInput: {
    width: 90,
    fontSize: 12,
    color: 'gray.800',
    p: 0,
    '& .MuiInputBase-root': {
      border: 'none',
      p: 0,
      fieldset: {
        border: 'none'
      },
      '& .MuiInputBase-input': {
        padding: 0,
        textAlign: 'center',
        fontWeight: 500
      }
    },
    '& .MuiInputAdornment-root': {
      display: 'none'
    }
  },
  advanceSearchDateBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    backgroundColor: 'white.main'
  },
  searchDateBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    backgroundColor: 'white.main',
    padding: 1,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'gray.400',
    borderRadius: '4px'
  },
  advanceSearchDateIcon: {
    color: 'gray.800',
    fontSize: 14,
    mr: '8px'
  },
  close: {
    background: 'rgb(255,255,255)',
    position: 'relative',
    '&:hover': {
      background: 'rgba(255,255,255,0.8)'
    }
  },
  searchField: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'gray.400',
    borderRadius: '8px',
    backgroundColor: 'gray.0',
    marginTop: '64px',
    minWidth: '762px',
    maxWidth: '794px'
  },
  divider: {
    mb: '12px',
    mt: '12px',
    backgroundColor: 'gray.200'
  },
  heading: {
    fontWeight: '800',
    fontSize: '20px',
    textTransform: 'capitalize'
  },
  SearchSelect: {
    maxWidth: 400,
    fontSize: '14px',
    fontWeight: 700,
    p: 0,
    border: 'none',
    '& .MuiSelect-icon': { fontSize: 16, color: 'gray.700' },
    fieldset: {
      p: 0,
      border: 'none'
    }
  }
};
export default headerStyles;
