/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import LabelCompareIcon from '../../../Images/brightness-and-contrast.svg';
import CenterBallN from '../../../Images/centerBallNext.svg';
import next from '../../../Images/next.svg';
import plus from '../../../Images/plusWhite.svg';

import styles from '../styles/Details.styles';
import comparisonClasses from '../../../pages/Comparison/styles/LabelSelection.style';

import getComparisonFile from '../../../helpers/draftableUtils';

import { getLabelComparision } from '../../../api/pages/Reg360';
import { getPMRImpactSignedURL } from '../../../api/pages/PmrPage';

const PDFCompare: React.FC<any> = ({ open, onClose, rowData }) => {
  const [viewerURL, setViewerURL] = useState() as any;
  const [pdfComparision, setpdfComparision] = useState<Boolean>(false);

  const downloadFile = useCallback(
    (viewerURL: any) => {
      try {
        const identifierInURL = viewerURL.split('/')[7];
        const identifier = identifierInURL.split('?')[0];
        getComparisonFile(identifier);
      } catch (e) {
        console.error(e);
      }
    },
    [getComparisonFile]
  );

  const handleSignedURL = useCallback(async (pdfLink: any) => {
    const res: any = await getPMRImpactSignedURL({ source: 'pmr_impact', s3_path: pdfLink });
    return res?.data?.body?.s3_url ?? '';
  }, []);

  const getComparisions = useCallback(
    async (linkL: object, linkR: object) => {
      const pdfData = {
        parent_object: await handleSignedURL(linkL),
        child_object: await handleSignedURL(linkR)
      };
      const res = await getLabelComparision(pdfData /* , state.db */);

      if (res.data.success !== null) {
        setViewerURL(res.data.success);
      } else if (
        res.data.success === null ||
        res.data.success === '' ||
        res.data.error.length > 0
      ) {
        toast.error(res.data.error);
      }
    },
    [getLabelComparision]
  );

  const compareFiles: any = useMemo(() => {
    if (rowData) {
      return {
        issuedLabel: rowData.pmr_issued_label,
        proximalLabel: rowData.proximal_label_link,
        fulfilledLabel: rowData.fulfilled_label_link
      };
    }
    return {
      issuedLabel: null,
      proximalLabel: null,
      fulfilledLabel: null
    };
  }, [rowData]);

  return (
    <Dialog
      fullWidth
      maxWidth='xl'
      aria-labelledby='customized-dialog-title'
      open={open}
      onClose={onClose}>
      <Grid
        sx={comparisonClasses.dialog}
        display='flex'
        justifyContent='space-between'
        alignItems='flex-start'>
        <Box />
        <Grid sx={comparisonClasses.LabelTitle} display='flex' justifyContent='center'>
          <img style={comparisonClasses.compareIcon} alt='compareLogo' src={LabelCompareIcon} />
          LABEL COMPARISON
        </Grid>
        <Box>
          <IconButton aria-label='delete' onClick={onClose} sx={{ fontSize: '40px' }} size='large'>
            <CancelIcon fontSize='inherit' />
          </IconButton>
        </Box>
      </Grid>
      <DialogContent sx={comparisonClasses.dialog}>
        {pdfComparision === false && compareFiles && compareFiles.fulfilledLabel !== null ? (
          <Grid>
            <Grid
              xs={12}
              container
              display='flex'
              justifyContent='center'
              style={{ display: 'inline-flex', padding: '30px' }}>
              <Grid xs={3} container display='flex' justifyContent='center' item>
                <Grid
                  xs={12}
                  container
                  display='flex'
                  justifyContent='center'
                  item
                  sx={comparisonClasses.selectLabel}>
                  {' '}
                  Most Proximal Label{' ('}
                  {rowData.supplement_type_proximal}-{rowData.supplement_num_proximal})
                </Grid>
                <Grid xs={12} container display='flex' justifyContent='center' item>
                  <Typography sx={comparisonClasses.labelDate}>
                    {rowData.approval_date_proximal}
                  </Typography>
                </Grid>
              </Grid>
              <Grid xs={1} container display='flex' justifyContent='center' item>
                <span
                  style={{
                    display: 'inline-flex',
                    borderTop: '1px solid #a2a2a2',
                    marginTop: '25%',
                    width: '100%'
                  }}
                />
                <img style={{ width: '28px', marginTop: '-52%' }} src={CenterBallN} alt='' />
              </Grid>

              <Grid xs={3} container display='flex' justifyContent='center' item>
                <Grid
                  xs={12}
                  container
                  display='flex'
                  justifyContent='center'
                  item
                  sx={comparisonClasses.selectLabel}>
                  {' '}
                  PMR/PMC Fulfilled Label{' ('}
                  {rowData.supplement_type_fulfilled}-{rowData.supplement_num_fulfilled})
                </Grid>
                <Typography sx={comparisonClasses.labelDate}>
                  {rowData.supplement_date_fulfilled}
                </Typography>
              </Grid>

              <Grid
                xs={1}
                container
                display='flex'
                justifyContent='center'
                item
                style={{ cursor: 'pointer', paddingLeft: '20px' }}>
                <img
                  onClick={() => {
                    getComparisions(compareFiles.proximalLabel, compareFiles.fulfilledLabel);
                    setpdfComparision(true);
                  }}
                  style={{ width: '20px' }}
                  src={next}
                  alt='Next'
                />
              </Grid>
            </Grid>
            <Grid
              xs={12}
              style={{ display: 'inline-flex', padding: '30px' }}
              container
              display='flex'
              justifyContent='center'>
              <Grid xs={3} container display='flex' justifyContent='center' item>
                <Grid
                  xs={12}
                  container
                  display='flex'
                  justifyContent='center'
                  item
                  sx={comparisonClasses.selectLabel}>
                  {' '}
                  PMR/PMC Issued Label{' ('}
                  {rowData.supplement_type}-{rowData.supplement_num})
                </Grid>
                <Typography sx={comparisonClasses.labelDate}>{rowData.approval_date}</Typography>
              </Grid>
              <Grid xs={1} container display='flex' justifyContent='center' item>
                <span
                  style={{
                    display: 'inline-flex',
                    borderTop: '1px solid #a2a2a2',
                    marginTop: '25%',
                    width: '100%'
                  }}
                />
                <img style={{ width: '28px', marginTop: '-52%' }} src={CenterBallN} alt='' />
              </Grid>

              <Grid xs={3} container display='flex' justifyContent='center' item>
                <Grid
                  xs={12}
                  container
                  display='flex'
                  justifyContent='center'
                  item
                  sx={comparisonClasses.selectLabel}>
                  {' '}
                  PMR/PMC Fulfilled Label{' ('}
                  {rowData.supplement_type_fulfilled}-{rowData.supplement_num_fulfilled})
                </Grid>
                <Typography sx={comparisonClasses.labelDate}>
                  {rowData.supplement_date_fulfilled}
                </Typography>
              </Grid>

              <Grid
                xs={1}
                container
                item
                style={{ paddingLeft: '20px' }}
                display='flex'
                justifyContent='center'>
                <img
                  onClick={() => {
                    getComparisions(compareFiles.issuedLabel, compareFiles.fulfilledLabel);
                    // handleClick()
                    setpdfComparision(true);
                  }}
                  style={{ cursor: 'pointer', width: '20px' }}
                  src={next}
                  alt='Next'
                />
              </Grid>
            </Grid>
          </Grid>
        ) : viewerURL ? (
          <Grid>
            <Grid container justifyContent='center' alignItems='flex-end'>
              <Grid xs={3}>
                <Typography sx={comparisonClasses.selectLabel} align='center'>
                  Proximal Label
                </Typography>
              </Grid>
              <Grid xs={3} sx={comparisonClasses.compareLine}>
                <img style={comparisonClasses.centerBall} alt='compareBall' src={CenterBallN} />
              </Grid>
              <Grid xs={3}>
                <Typography sx={comparisonClasses.selectLabel} align='center'>
                  Fulfilled Label
                </Typography>
              </Grid>
            </Grid>
            <Box sx={comparisonClasses.dialog}>
              <div style={{ height: '56vh', width: '92vw' }}>
                <iframe
                  style={comparisonClasses.compareIFrame}
                  title='compareDocuments'
                  src={viewerURL}
                  allowFullScreen
                />
              </div>
            </Box>
            <Box display='flex' justifyContent='space-evenly' bottom={0}>
              <Box sx={comparisonClasses.compareN} onClick={() => setpdfComparision(false)}>
                <img style={{ width: '15px' }} alt='compareLogo' src={plus} />
                <Typography sx={comparisonClasses.labelTitle}>New Compare</Typography>
              </Box>
              <Box sx={comparisonClasses.compareN} onClick={() => downloadFile(viewerURL)}>
                <Typography sx={comparisonClasses.labelTitle}>Download Comparison</Typography>
              </Box>
            </Box>
          </Grid>
        ) : (
          <Grid xs={12} container justifyContent='center' item>
            <Box display='flex' justifyContent='center' sx={styles.loadingContainer}>
              <Typography sx={styles.loadingTitle}>Loading...</Typography>
            </Box>
          </Grid>
        )}
        ;
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(PDFCompare);
