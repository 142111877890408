import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import themev5 from '../../../../themev5';
import { CARD_COUNT_VIEWPORT_MAPPING } from '../../constants';
import findBreakPointKey from '../../utils/findBreakpoints';
import SelectableManageCard from './SelectableManageCard';
import { ExpandableSelectableApplicationGroupProps } from '../../types';

const ExpandableSelectableApplicationGroup = ({
  group,
  keyMapper,
  applications,
  handleCheck
}: ExpandableSelectableApplicationGroupProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [cardsPerRow, setCardsPerRow] = useState<number>(0);

  useEffect(() => {
    function updateSize() {
      const currentBreakPoint = findBreakPointKey(window.innerWidth, themev5.breakpoints.values);
      const crdsPerRow = CARD_COUNT_VIEWPORT_MAPPING[currentBreakPoint] || 4;
      setCardsPerRow(crdsPerRow);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const anyApplicationSelected = () => {
    return applications?.some((app: any) => app.isChecked ?? false);
  };

  const onGroupCheck = (checked: boolean) => {
    applications?.forEach((app: any) => {
      handleCheck({ source: app.source, identifier: app.identifier, checked });
    });
  };

  return (
    <Grid
      container
      spacing={2.5}
      key={group}
      sx={{ bgcolor: 'white.smokeDark', borderRadius: '8px', transition: 'height .2s ease' }}
      p={2}
      m='auto'
      mt={1.5}
      maxWidth='lg-xl'>
      <Grid item xs={12} p={1} borderBottom='1px solid rgba(0,0,0,0.05)'>
        <Box display='flex' width='100%'>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Typography fontWeight='700' fontSize={14}>{`${keyMapper?.(group) || group} (${
              applications.length
            })`}</Typography>
            <Checkbox
              value={group}
              checked={anyApplicationSelected()}
              onChange={(_e, checked) => onGroupCheck(checked)}
            />
          </Stack>
          {applications.length > cardsPerRow && (
            <Typography
              color='primary.main'
              fontSize={14}
              fontWeight='700'
              ml='auto'
              onClick={() => setExpanded(prev => !prev)}
              sx={{
                ':hover': {
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }
              }}>
              {!expanded ? 'Show all' : 'Hide'}
            </Typography>
          )}
        </Box>
      </Grid>
      {!!cardsPerRow &&
        applications
          ?.slice(0, expanded ? applications.length : cardsPerRow)
          ?.map((data: any, index: number) => (
            <Grow in key={data.identifier}>
              <Grid item xs={12} md={6} lg={3} xl={3}>
                <SelectableManageCard
                  data={data}
                  showDottedTitle={
                    data.groupTitle?.toLowerCase() ===
                    applications[index - 1]?.groupTitle?.toLowerCase()
                  }
                  onCheck={handleCheck}
                />
              </Grid>
            </Grow>
          ))}
    </Grid>
  );
};

export default React.memo(ExpandableSelectableApplicationGroup);
