import React, { useCallback } from 'react';

import { isEmpty } from 'lodash';
import DesignationStore from '../../../store/Designation';
import { getSpecialtyDesignations } from '../../../api/pages/Designation';
import DesignationActions from '../../../store/Designation/actions';
import { mergeOldFilterValues } from '../../../utils/filters';
import { DESIGNATION_FILTERS } from '../const';
import getFilterOptions from '../utils/common';

const useSpecialityData = () => {
  const [insights, setInsights] = React.useState<any>([]);
  const [designation, setDesignation] = React.useState<any>([]);
  const { designationState, designationDispatch } = React.useContext(DesignationStore);

  const getDesignationData = useCallback(
    async (searchTerm: string, filters: { [key: string]: any }) => {
      designationDispatch({ type: DesignationActions.SET_LOADING, value: true });
      // Removing empty key form filter payload
      Object.keys(filters).forEach(key => {
        if (filters[key]?.length === 0) {
          // eslint-disable-next-line no-param-reassign
          delete filters[key];
        }
      });

      try {
        // get data for graphs shown on insights tab
        let res: any = {};
        designationDispatch({
          type: DesignationActions.SET_IS_FILTERS_APPLIED,
          value: isEmpty(filters)
        });

        const response = await getSpecialtyDesignations(
          searchTerm || 'default',
          Object.keys(filters).length === 0 ? {} : { filters }
        );
        if (response?.data.status === 200) {
          res = response.data.body;
          let mergedFilters = [];
          const newFilters = getFilterOptions(res.timeline, DESIGNATION_FILTERS);
          if (designationState.filters?.length) {
            mergedFilters = mergeOldFilterValues(newFilters, designationState.filters);
          } else {
            mergedFilters = newFilters;
          }
          designationDispatch({
            type: DesignationActions.SET_FILTERS,
            value: mergedFilters
          });

          setTimeout(() => {
            designationDispatch({ type: DesignationActions.SET_LOADING, value: false });
            setInsights(res?.stats);
            setDesignation(res?.timeline);
          }, 1000);
        }
      } catch (err) {
        designationDispatch({ type: DesignationActions.SET_LOADING, value: true });
        // eslint-disable-next-line no-console
        console.error(err);
      }
    },
    [designationDispatch, designationState.filters]
  );

  return {
    getDesignationData,
    insights,
    designation,
    isLoading: designationState.loading,
    designationFilters: designationState.filters,
    isFiltersApplied: designationState.isFiltersApplied
  };
};

export default useSpecialityData;
