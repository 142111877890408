const CrossIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 19' fill='none'>
      <path
        d='M10.5907 9.5001L17.6704 2.42115C18.1098 1.98168 18.1098 1.26916 17.6704 0.829725C17.2309 0.390255 16.5184 0.390255 16.079 0.829725L8.99997 7.90941L1.92103 0.829725C1.48156 0.390255 0.769038 0.390255 0.329603 0.829725C-0.109832 1.26919 -0.109868 1.98171 0.329603 2.42115L7.40929 9.5001L0.329603 16.5791C-0.109868 17.0185 -0.109868 17.7311 0.329603 18.1705C0.769073 18.6099 1.48159 18.61 1.92103 18.1705L8.99997 11.0908L16.0789 18.1705C16.5184 18.61 17.2309 18.61 17.6703 18.1705C18.1098 17.731 18.1098 17.0185 17.6703 16.5791L10.5907 9.5001Z'
        fill='#757575'
      />
    </svg>
  );
};

export default CrossIcon;
