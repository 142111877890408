import React from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import styles from './EmptyPage.styles';

const EmptyPage = ({ message }: { message: string }) => {
  return (
    <Box sx={styles.textAlignment}>
      <Typography variant='h2'>{message}</Typography>
    </Box>
  );
};

export default React.memo(EmptyPage);
