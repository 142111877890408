import React from 'react';
import { createSvgIcon } from '@mui/material';

const TimelineIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
    <g clipPath='url(#clip0_762_1357)'>
      <path
        d='M10.1037 2.36887e-06H3.89705C3.48504 -0.00067591 3.08606 0.144325 2.77063 0.409375C2.45519 0.674425 2.24362 1.04246 2.1733 1.44842L-0.0229492 14H14.0354L11.8275 1.44667C11.7561 1.04133 11.5442 0.674106 11.229 0.409528C10.9137 0.144949 10.5153 -5.3926e-05 10.1037 2.36887e-06ZM1.36538 12.8333L3.3248 1.64967C3.34835 1.51481 3.41858 1.39252 3.52319 1.30422C3.62781 1.21592 3.76015 1.16723 3.89705 1.16667H10.1037C10.2409 1.16666 10.3738 1.21502 10.4789 1.30325C10.5839 1.39148 10.6546 1.51394 10.6783 1.64909L12.6459 12.8333H1.36538ZM6.41647 2.33334H7.58314V4.66667H6.41647V2.33334ZM6.41647 5.83334H7.58314V8.16667H6.41647V5.83334ZM6.41647 9.33334H7.58314V11.6667H6.41647V9.33334Z'
        fill='#0076D6'
      />
    </g>
    <defs>
      <clipPath id='clip0_762_1357'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'Timeline'
);

export default TimelineIcon;
