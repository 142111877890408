import { Box, Button, Divider, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OptionFooter from './OptionFooter';
import { styles } from './styles';
import MultiSearchKeyword from './MultiSearchKeyword';

const MultiSearchKeywordOptions = ({
  selectedCategory,
  applyFilters,
  isLoading,
  handleClose,
  setShowFilterSidebar,
  selectedCategoryOptions,
  setSelectedCategoryOptions,
  handleApplyClose,
  isBottomFilterView
}: any) => {
  return (
    <Box width={295} maxHeight={500}>
      <Box maxHeight={430}>
        <Box sx={styles.header} onClick={handleClose}>
          <Button sx={styles.closeIconButton}>
            <CloseIcon sx={{ ...styles.icon, fontSize: 20 }} />
          </Button>
        </Box>
        <Typography fontSize={14} fontWeight={700} color='primary.700' sx={{ ml: 3, mb: 2 }}>
          {selectedCategory.label}
        </Typography>
        <Box mx={3}>
          <MultiSearchKeyword
            selectedCategory={selectedCategory}
            selectedCategoryOptions={selectedCategoryOptions}
            setSelectedCategoryOptions={setSelectedCategoryOptions}
            isBottomFilterView={isBottomFilterView}
          />
        </Box>
      </Box>
      <Divider sx={{ mt: 1 }} />
      <Box mx={3} height={68} sx={styles.footer}>
        <OptionFooter
          selectedCategory={selectedCategory}
          selectedCategoryOptions={selectedCategoryOptions}
          setSelectedCategoryOptions={setSelectedCategoryOptions}
          applyFilters={applyFilters}
          isLoading={isLoading}
          handleApplyClose={handleApplyClose}
          setShowFilterSidebar={setShowFilterSidebar}
        />
      </Box>
    </Box>
  );
};

export default MultiSearchKeywordOptions;
