import React from 'react';

const css = `
.botlogo{
  padding:0;
  margin:0;
}
@media only screen and (max-width: 600px) {
  .botlogo {
    margin-left:50px;
    height:150px;
    width:150px
  }
}
@media only screen and (min-width: 768px) {
  .botlogo {
    margin-left:100px;
    height:276px;
    width:393px
  }
}
`;

const AriaBannerIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xlink='http://www.w3.org/1999/xlink'
      height='100%'
      width='100%'
      viewBox='0 0 393.155 276.834'
    >
      <style>{css}</style>
      <defs>
        <linearGradient
          id='linear-gradient'
          x1='0.251'
          y1='0.995'
          x2='0.749'
          y2='0.071'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stopColor='#3c999a' />
          <stop offset='1' stopColor='#47a836' />
        </linearGradient>
        <linearGradient
          id='linear-gradient-2'
          x1='0.286'
          y1='0.941'
          x2='0.675'
          y2='-0.166'
          href='#linear-gradient'
        />
      </defs>
      <g id='Group_458' data-name='Group 458' transform='translate(-848.563 -247)'>
        <g id='Group_362' data-name='Group 362' transform='translate(-1277.111 -661.867)'>
          <path
            id='Path_254'
            data-name='Path 254'
            d='M2498.3,908.867H2364.5a19.449,19.449,0,0,0-19.392,19.392v58.7a17.864,17.864,0,0,0,17.812,17.811h3.437L2359.914,1032l40.47-27.227h99.07a19.432,19.432,0,0,0,19.375-19.375v-56A20.591,20.591,0,0,0,2498.3,908.867Z'
            fill='#f0f2fd'
          />
          <g id='Group_361' data-name='Group 361' transform='translate(2358.927 919.229)'>
            <path
              id='Path_255'
              data-name='Path 255'
              d='M2513.566,981.647H2374.059a3.465,3.465,0,0,1-3.455-3.455h0a3.465,3.465,0,0,1,3.455-3.455h139.508a3.465,3.465,0,0,1,3.455,3.455h0A3.465,3.465,0,0,1,2513.566,981.647Z'
              transform='translate(-2370.604 -949.402)'
              fill='#bcc4e8'
            />
            <path
              id='Path_256'
              data-name='Path 256'
              d='M2513.566,1010.182H2374.059a3.465,3.465,0,0,1-3.455-3.455h0a3.465,3.465,0,0,1,3.455-3.455h139.508a3.465,3.465,0,0,1,3.455,3.455h0A3.465,3.465,0,0,1,2513.566,1010.182Z'
              transform='translate(-2370.604 -962.473)'
              fill='#bcc4e8'
            />
            <path
              id='Path_257'
              data-name='Path 257'
              d='M2513.566,1038.718H2374.059a3.465,3.465,0,0,1-3.455-3.455h0a3.465,3.465,0,0,1,3.455-3.455h139.508a3.465,3.465,0,0,1,3.455,3.455h0A3.465,3.465,0,0,1,2513.566,1038.718Z'
              transform='translate(-2370.604 -975.544)'
              fill='#bcc4e8'
            />
            <path
              id='Path_258'
              data-name='Path 258'
              d='M2440.619,936.214h-59.225a4.125,4.125,0,0,1-4.113-4.113h0a4.125,4.125,0,0,1,4.113-4.113h59.225a4.125,4.125,0,0,1,4.113,4.113h0A4.125,4.125,0,0,1,2440.619,936.214Z'
              transform='translate(-2373.663 -927.988)'
              fill='#bcc4e8'
            />
            <rect
              id='Rectangle_60'
              data-name='Rectangle 60'
              width='42.138'
              height='8.226'
              rx='4.113'
              transform='translate(78.967 0)'
              fill='#bcc4e8'
            />
          </g>
        </g>
        <g id='Group_444' data-name='Group 444' transform='translate(580.953 31.823)'>
          <path
            id='Path_268'
            data-name='Path 268'
            d='M364.629,441.261c.961,1.336,2.471,1.4,3.865,1.485a2.441,2.441,0,0,1,2.593,2.7c.067,1.668-.472,2.692-2.374,2.769-3.478.14-5.774,2-7.039,5.2-.79,2-2.525,1.9-4.211,1.909-3.35.008-6.7-.04-10.051.019-12.313.212-20.5,8.373-20.79,20.818-.122,5.188-.02,10.38-.02,15.847C293.444,477.158,259.4,430.8,269.38,375.055a111.928,111.928,0,0,1,220.456.8c9.384,54.848-23.9,101.23-57.336,116.127,0-5.206.077-10.175-.015-15.142-.25-13.439-8.4-22.756-21.5-21.036-9.648,1.267-14.689-5.313-21.816-8.1-2.9-1.132-1.587-4.448,1.893-5.3,1.27.234,2.473.265,3.235-1.056l.035.033c3.345-6.617,9.423-5.6,15.263-5.633,13.6-.079,21.5-5.888,24.426-18.454.8-3.443,2.237-4.392,5.279-4.079,7.259.747,10.407-3.673,11.945-9.768,1.231-4.881,1.9-9.829.619-14.894-1.458-5.773-1.928-12.086-9.924-12.878-2.87-.284-1.924-2.854-2-4.586-.355-7.71-4.164-13.3-9.845-18.317-3.2-2.828-10.047-.917-10.082-6.426-.027-4.255,2.745-8.529,4.27-12.795.563-1.574,1.032-3.188,1.692-4.72,1.348-3.129.835-5.84-2.347-7.169-3.569-1.49-5.912.306-7.161,3.783-2.261,6.292-4.7,12.523-6.789,18.871-1.13,3.44-2.943,4.837-6.691,4.726-8.843-.261-17.7-.043-26.552-.1-6.928-.041-15.293,2.162-20.393-.944-5.493-3.346-5.5-12.678-8.269-19.251-.279-.66-.592-1.311-.807-1.992-1.188-3.754-3.014-7.007-7.615-5.073-4.166,1.751-3.149,5.206-1.9,8.56,2.223,5.96,4.3,11.974,6.479,18.08-14.31,2.128-22.143,7.949-24.152,18.505-.919,4.83-.174,9.668-7.29,10.424-2.472.263-3.562,4.269-4.391,7.019a33.7,33.7,0,0,0-.137,19.784c1.733,5.774,4.73,9.907,11.688,9.162,2.346-.251,4.349-.256,5.313,3.136,4.183,14.721,10.54,19.477,25.725,19.341C356.268,435.642,361.55,435.442,364.629,441.261Z'
            transform='translate(0 0)'
            fill='url(#linear-gradient)'
          />
          <path
            id='Path_269'
            data-name='Path 269'
            d='M527.185,611.653c-.762,1.321-1.965,1.29-3.235,1.056C524.719,611.411,525.877,611.3,527.185,611.653Z'
            transform='translate(-132.888 -170.308)'
            fill='#f5f8fa'
          />
          <path
            id='Path_270'
            data-name='Path 270'
            d='M450.677,502.084c-8.84,0-17.681.1-26.519-.02-11.958-.169-19.953-7.864-19.832-18.875.118-10.625,8.126-18.2,19.661-18.271q26.159-.159,52.321.012c12.148.082,20.189,7.551,20.221,18.446.032,10.954-8.015,18.479-20.052,18.686-4.3.074-8.6.017-12.9.018S454.978,502.08,450.677,502.084Zm37-18.585a10.034,10.034,0,0,0-9.512-9.673,9.609,9.609,0,0,0-.084,19.213A9.828,9.828,0,0,0,487.677,483.5Zm-55.584-.166a9.621,9.621,0,0,0-9.519-9.516,10.087,10.087,0,0,0-9.415,9.792c.1,5.1,4.722,9.631,9.679,9.5A9.493,9.493,0,0,0,432.092,483.333Zm1.932,34.063a3.3,3.3,0,0,0,3.76,3.43,3.463,3.463,0,0,0,3.517-3.747c-.049-2.146-1.507-3.414-3.817-3.362A3.228,3.228,0,0,0,434.025,517.4Zm20-.14c.072-2.116-1.289-3.535-3.617-3.55a3.281,3.281,0,0,0-3.648,3.513,3.481,3.481,0,0,0,3.625,3.644A3.426,3.426,0,0,0,454.022,517.257Zm9.418-3.562c-2.312-.1-3.766,1.13-3.927,3.276a3.463,3.463,0,0,0,3.413,3.842c2.349.256,3.481-1.152,3.885-3.348C466.642,515.383,465.884,513.8,463.44,513.695Z'
            transform='translate(-70.874 -94.289)'
            fill='url(#linear-gradient-2)'
          />
        </g>
      </g>
    </svg>
  );
};

export default AriaBannerIcon;
