import { createSvgIcon } from '@mui/material/utils';

const ViewLabelsIcon = createSvgIcon(
  <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M18.1667 4.23077V15.7692C18.1667 16.3813 17.9258 16.9682 17.4972 17.401C17.0685 17.8338 16.4872 18.0769 15.8809 18.0769H13.2143C13.0122 18.0769 12.8184 17.9959 12.6755 17.8516C12.5326 17.7074 12.4524 17.5117 12.4524 17.3077C12.4524 17.1037 12.5326 16.908 12.6755 16.7638C12.8184 16.6195 13.0122 16.5385 13.2143 16.5385H15.8809C16.083 16.5385 16.2768 16.4574 16.4197 16.3132C16.5626 16.1689 16.6428 15.9732 16.6428 15.7692V4.23077C16.6428 4.02676 16.5626 3.8311 16.4197 3.68684C16.2768 3.54258 16.083 3.46154 15.8809 3.46154H13.2143C13.0122 3.46154 12.8184 3.38049 12.6755 3.23624C12.5326 3.09198 12.4524 2.89632 12.4524 2.69231C12.4524 2.4883 12.5326 2.29264 12.6755 2.14838C12.8184 2.00412 13.0122 1.92308 13.2143 1.92308H15.8809C16.4872 1.92308 17.0685 2.16621 17.4972 2.59898C17.9258 3.03176 18.1667 3.61873 18.1667 4.23077ZM10.9286 0.769231V19.2308C10.9286 19.4348 10.8483 19.6304 10.7054 19.7747C10.5625 19.919 10.3687 20 10.1667 20C9.96459 20 9.77079 19.919 9.62791 19.7747C9.48502 19.6304 9.40475 19.4348 9.40475 19.2308V18.0769H4.45237C3.84616 18.0769 3.26478 17.8338 2.83613 17.401C2.40747 16.9682 2.16666 16.3813 2.16666 15.7692V4.23077C2.16666 3.61873 2.40747 3.03176 2.83613 2.59898C3.26478 2.16621 3.84616 1.92308 4.45237 1.92308H9.40475V0.769231C9.40475 0.565218 9.48502 0.369561 9.62791 0.225302C9.77079 0.0810437 9.96459 0 10.1667 0C10.3687 0 10.5625 0.0810437 10.7054 0.225302C10.8483 0.369561 10.9286 0.565218 10.9286 0.769231ZM9.40475 16.5385V3.46154H4.45237C4.2503 3.46154 4.05651 3.54258 3.91362 3.68684C3.77074 3.8311 3.69047 4.02676 3.69047 4.23077V15.7692C3.69047 15.9732 3.77074 16.1689 3.91362 16.3132C4.05651 16.4574 4.2503 16.5385 4.45237 16.5385H9.40475Z'
      fill='currentColor'
    />
  </svg>,
  'ViewLabels'
);
export default ViewLabelsIcon;
