import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.grey[700],
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#33B187'
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#33B187',
    zIndex: 1,
    fontSize: 24
  },
  '& .QontoStepIcon-circle': {
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  }
}));

const QontoStepIcon = ({ active, completed, className }) => {
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className='QontoStepIcon-completedIcon' />
      ) : (
        <div className='QontoStepIcon-circle' />
      )}
    </QontoStepIconRoot>
  );
};

export default QontoStepIcon;
