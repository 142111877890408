import { createSvgIcon } from '@mui/material';

const GradientArrowDownIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
    <g clipPath='url(#clip0_4722_65025)'>
      <path
        d='M16.3056 17.1655L13.5256 19.9455L13.5006 0.230469H10.5006L10.5256 19.9305L7.76163 17.1655L5.64062 19.2865L9.55862 23.2055C10.215 23.8616 11.1051 24.2302 12.0331 24.2302C12.9612 24.2302 13.8513 23.8616 14.5076 23.2055L18.4256 19.2865L16.3056 17.1655Z'
        fill='url(#paint0_linear_4722_65025)'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_4722_65025'
        x1='18.6379'
        y1='11.9235'
        x2='4.52335'
        y2='12.0493'
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#00B893' />
        <stop offset='1' stopColor='#4C8FF2' />
      </linearGradient>
      <clipPath id='clip0_4722_65025'>
        <rect width='24' height='24' fill='white' transform='translate(0 0.230469)' />
      </clipPath>
    </defs>
  </svg>,
  'GradientArrowDown'
);

export default GradientArrowDownIcon;
