import React, { useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Button,
  Box,
  FormGroup,
  Typography,
  Divider
} from '@mui/material';
import styles from './styles';

const CustomDropdown = ({ data, handleAddToReport }: any) => {
  const [selectedOption, setSelectedOption] = useState<'all' | 'selected' | ''>('');
  const [selectedStudies, setSelectedStudies] = useState<number[]>([]);

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
    if (event.target.value === 'all') {
      setSelectedStudies([]);
    } else if (event.target.value === 'selected') {
      setSelectedStudies(data?.studies?.map((_: any, index: number) => index));
    }
  };

  const handleCheckboxChange = (studyIndex: any) => {
    if (selectedOption !== 'selected') {
      setSelectedOption('selected');
    }
    if (selectedStudies?.includes(studyIndex)) {
      setSelectedStudies(selectedStudies.filter((each: number) => each !== studyIndex));
    } else {
      setSelectedStudies([...selectedStudies, studyIndex]);
    }
  };

  const handleAdd = () => {
    handleAddToReport(selectedOption, selectedStudies);
  };

  const enableAdd =
    (selectedOption === 'selected' && selectedStudies.length) || selectedOption === 'all';

  return (
    <Box display='flex' flexDirection='column' sx={styles.customDropdown.main}>
      <FormControl component='fieldset'>
        <RadioGroup
          aria-label='study-option'
          name='study-option'
          value={selectedOption}
          onChange={handleOptionChange}>
          <FormControlLabel
            value='all'
            control={<Radio size='small' sx={styles.customDropdown.radioCheckboxOption} />}
            label={<Typography fontSize={12}>All Studies</Typography>}
          />
          <FormControlLabel
            value='selected'
            control={<Radio size='small' sx={styles.customDropdown.radioCheckboxOption} />}
            label={<Typography fontSize={12}>Selected Study(s)</Typography>}
          />
        </RadioGroup>
        <Box display='flex' ml={0.5}>
          <Divider orientation='vertical' flexItem sx={styles.customDropdown.divider} />
          <FormGroup sx={{ paddingLeft: 2 }}>
            {data?.studies?.map((study: any, index: number) => (
              <FormControlLabel
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                control={
                  <Checkbox
                    size='small'
                    checked={selectedStudies.includes(index)}
                    onChange={() => handleCheckboxChange(index)}
                    sx={styles.customDropdown.radioCheckboxOption}
                  />
                }
                label={<Typography fontSize={12}>Study {index + 1}</Typography>}
              />
            ))}
          </FormGroup>
        </Box>
      </FormControl>
      <Button
        onClick={handleAdd}
        size='small'
        disabled={!enableAdd}
        sx={{
          ...styles.customDropdown.addButton,
          bgcolor: enableAdd ? 'primary.600' : 'gray.500'
        }}>
        Add
      </Button>
    </Box>
  );
};

export default CustomDropdown;
