import React, { useCallback, useContext, useEffect, useState } from 'react';
import uniqueId from 'lodash/uniqueId';
import capitalize from 'lodash/capitalize';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';

import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DocumentIcon from '../../../assets/svgs/CHMP/DocumentIcon';
import Reg360Icon from '../../../assets/svgs/CardsPage/Reg360Icon';
import PostiveIcon from '../../../assets/svgs/CHMP/tickcircle.svg';
import WithdrawalIcon from '../../../assets/svgs/CHMP/minuscirlce.svg';
import NegativeIcon from '../../../assets/svgs/CHMP/closecircle.svg';
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';

// component
import PDFPreview from '../../../components/PdfPreview/PDFPreview';
import { MeetingsLoader } from './Loaders';

// styles
import styles from '../styles/Meeting.styles';

import { resourceKeyMapping } from '../utils/constants';
import { getDocumentSourceName } from '../../SearchResults/utils/documentResultsUtils';

const iconMapping: any = {
  positive: PostiveIcon,
  negative: NegativeIcon,
  withdrawals: WithdrawalIcon
};

const Meetings = ({
  allMeetingsByYear,
  selectedMeeting,
  handleSelectMeeting,
  resources,
  meetingStats,
  loading
}: {
  allMeetingsByYear: any;
  selectedMeeting: any;
  handleSelectMeeting: any;
  resources: any;
  meetingStats: any;
  loading: boolean;
}) => {
  const [PDFData, setPDFData] = useState<string>('');
  const [openPdf, setOpenPdf] = useState<boolean>(false);
  const [resourceDialog, setResourceDialog] = useState<boolean>(false);
  const [summaryTypeFilter, setSummaryTypeFilter] = useState<string>('all');
  const { dispatch } = useContext(GlobalStore) as any;
  const [title, setTitle] = useState<string>('');

  useEffect(() => {
    setSummaryTypeFilter('all');
  }, [selectedMeeting]);

  const handlePDFOpenClick = useCallback((pdfLink: string, name: string) => {
    setTitle(name);
    setOpenPdf(true);
    setPDFData(pdfLink);
  }, []);

  const handleClosePDF = useCallback(() => {
    setOpenPdf(false);
    setPDFData('');
  }, []);

  const handleMeetingSummaryFilterClick = (filterType: string) => {
    if (filterType === summaryTypeFilter) {
      setSummaryTypeFilter('all');
    } else {
      setSummaryTypeFilter(filterType);
    }
  };

  const openPDFInNewTab = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, link: string) => {
    const loc = new URL(link);
    if (!loc?.hostname?.includes('s3')) window.open(link, '_blank');
    else {
      const domain = new URL(window.location?.href)?.origin;
      const bucket = loc?.hostname?.split('.')[0];
      window.open(
        `${domain}/resource?bucket=${bucket}&filename=${loc?.pathname?.replace(/\//, '')}`,
        '_blank'
      );
    }
    e.stopPropagation();
  };

  const handleChatRia = ({ pdfUrl = '' }: { pdfUrl?: string }) => {
    const mappedSourceName = getDocumentSourceName('CHMP');
    dispatch({
      type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM,
      value: 'document'
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: mappedSourceName
    });
    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: {
        blob: pdfUrl,
        url: pdfUrl,
        item: {
          'first-published': selectedMeeting?.first_published_date ?? '',
          'chmp-meeting-id': selectedMeeting?.meeting_id ?? ''
        },
        source: mappedSourceName,
        triggerReopenChatRia: false
      }
    });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
    setPDFData('');
    setOpenPdf(false);
    setResourceDialog(false);
  };

  if (loading) {
    return <MeetingsLoader />;
  }
  return (
    <Stack direction='column'>
      <Grid container spacing={2} paddingBottom='56px'>
        {/* meeting navigation list */}
        <Grid item xs={2} sx={{ bgcolor: 'none' }}>
          {/* meeting list */}
          <Stack spacing={2} sx={styles.meetingListContainer}>
            {allMeetingsByYear &&
              !isEmpty(allMeetingsByYear) &&
              Object.keys(allMeetingsByYear) // get all meetings by year Eg: {2021: [{}, {}], 2020: [{}, {}]
                .sort((a: any, b: any) => b - a) // sort by year
                .map((year: string) => {
                  const meetings = allMeetingsByYear[year];
                  return (
                    <Stack key={uniqueId()}>
                      <Typography variant='subtitle1' fontWeight='bold'>
                        {year}
                      </Typography>
                      <Stack spacing={2}>
                        {meetings?.map((meeting: any) => {
                          return (
                            <Stack
                              key={meetings.id}
                              spacing={1}
                              sx={{
                                p: 1,
                                transition: 'transform 0.3s',
                                cursor: 'pointer',
                                borderRadius: 1,
                                border:
                                  Object.keys(selectedMeeting)?.length > 0 &&
                                  selectedMeeting?.meeting_id === meeting.meeting_id
                                    ? '1px solid rgb(51, 177, 135)'
                                    : '1px solid #b2bec3',
                                bgcolor:
                                  Object.keys(selectedMeeting).length > 0 &&
                                  selectedMeeting?.meeting_id === meeting.meeting_id
                                    ? 'rgb(220, 237, 231)'
                                    : 'none',
                                '&:hover': {
                                  transform: 'scale(1)',
                                  zIndex: 2
                                }
                              }}
                              onClick={() => {
                                handleSelectMeeting(meeting.meeting_id);
                              }}>
                              <Typography variant='subtitle1'>{meeting.date}</Typography>
                              <Stack
                                direction='row'
                                spacing={1}
                                alignItems='center'
                                justifyContent='flex-start'
                                flexWrap='wrap'>
                                <Tooltip title='Year to Date'>
                                  <Typography variant='body1'>YTD</Typography>
                                </Tooltip>
                                {Object.keys(meeting.year_to_date).map((key: string) => {
                                  if (meeting.year_to_date[key] === 0) {
                                    return null;
                                  }
                                  return (
                                    <Tooltip
                                      title={`${
                                        key[key.length - 1] === 's'
                                          ? capitalize(key)
                                          : `${capitalize(key)}s`
                                      }`}
                                      key={uniqueId()}>
                                      <Stack spacing={1} direction='row' alignItems='center'>
                                        <img
                                          src={iconMapping[key] as any}
                                          alt='icon'
                                          style={{ height: '12px', width: '12px' }}
                                        />
                                        <Typography
                                          variant='body1'
                                          fontWeight='bold'
                                          sx={{ color: 'gray.dark' }}>
                                          {meeting.year_to_date[key]}
                                        </Typography>
                                      </Stack>
                                    </Tooltip>
                                  );
                                })}
                              </Stack>
                            </Stack>
                          );
                        })}
                      </Stack>
                    </Stack>
                  );
                })}

            {isEmpty(allMeetingsByYear) && (
              <Stack
                sx={{ height: 'calc(100vh - 290px)' }}
                alignItems='center'
                justifyContent='center'>
                <Typography variant='subtitle1' fontWeight='bold'>
                  No Meetings available
                </Typography>
              </Stack>
            )}
          </Stack>
        </Grid>
        <Grid item xs={10}>
          {/* Meeting details */}
          <Stack
            spacing={2}
            sx={{
              p: 2,
              mt: 2,
              height: 'calc(100vh - 290px)',
              width: '100%',
              overflowY: 'auto',
              bgcolor: 'white.main',
              borderRadius: 3,
              boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px'
            }}>
            <Stack spacing={4} p={2}>
              {/* meeting stats */}
              {/* Stats bar and aria btn */}
              <Stack
                direction='row'
                spacing={1}
                justifyContent='space-between'
                alignItems='flex-start'
                flexWrap='wrap'>
                {/* total number of applications count */}
                <Stack direction='row' spacing={2}>
                  {!isEmpty(allMeetingsByYear) && (
                    <Chip
                      label='All'
                      sx={{
                        color: summaryTypeFilter === 'all' ? 'primary.main' : 'gray.dark',
                        fontWeight: 'bold',
                        bgcolor: summaryTypeFilter === 'all' ? 'primary.backgroundLight' : 'none'
                      }}
                      onClick={() => handleMeetingSummaryFilterClick('all')}
                    />
                  )}
                  {Object.entries(meetingStats).map(([key, value]: any) => {
                    return (
                      <Chip
                        key={uniqueId()}
                        disabled={value === 0}
                        label={`${key}s(${value})`}
                        sx={{
                          color: summaryTypeFilter === key ? 'primary.main' : 'gray.dark',
                          fontWeight: 'bold',
                          bgcolor: summaryTypeFilter === key ? 'primary.backgroundLight' : 'none'
                        }}
                        onClick={() => handleMeetingSummaryFilterClick(key)}
                      />
                    );
                  })}
                  {!isEmpty(allMeetingsByYear) && (
                    <Chip
                      key={uniqueId()}
                      disabled={selectedMeeting?.resources?.length === 0}
                      label={`Documents(${selectedMeeting?.resources?.length || 0})`}
                      sx={{ p: 1, fontWeight: 'bold', color: 'gray.dark' }}
                      icon={<DocumentIcon />}
                      variant='outlined'
                      onClick={() => setResourceDialog(true)}
                    />
                  )}
                </Stack>
              </Stack>
              {isEmpty(allMeetingsByYear) && (
                <Stack
                  sx={{ width: '100%', height: 'calc(100vh - 290px)' }}
                  alignItems='center'
                  justifyContent='center'>
                  <Typography variant='subtitle1' fontWeight='bold'>
                    {' '}
                    No Data available
                  </Typography>
                </Stack>
              )}
              {/* resources */}
              <Dialog
                fullWidth
                maxWidth='lg'
                onClose={() => {
                  setResourceDialog(false);
                }}
                aria-labelledby='customized-dialog-title'
                open={resourceDialog}>
                <DialogTitle>
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h4' fontWeight='bold' color='primary'>
                      {capitalize(selectedMeeting?.meeting_id?.replace('-', ' '))} Documents
                    </Typography>
                    <IconButton
                      aria-label='delete'
                      onClick={() => {
                        setResourceDialog(false);
                      }}>
                      <CancelIcon />
                    </IconButton>
                  </Stack>
                </DialogTitle>
                <DialogContent>
                  <Stack sx={{ height: 'calc(100vh - 290px)', overflowY: 'auto' }}>
                    {Object.keys(resources).length > 0 &&
                      Object.keys(resources).map((key: string) => {
                        return (
                          <Stack spacing={2} direction='column' key={uniqueId()}>
                            <Typography variant='subtitle1' fontWeight='bold'>
                              {resourceKeyMapping[key] || key}
                            </Typography>
                            {resources[key]?.map((item: any) => {
                              return (
                                <Stack
                                  key={uniqueId()}
                                  direction='row'
                                  spacing={2}
                                  justifyContent='space-between'
                                  alignItems='center'
                                  onClick={() =>
                                    handlePDFOpenClick(item.s3_url, item.original_file_name)
                                  }
                                  sx={{
                                    height: 'flex',
                                    borderRadius: 3,
                                    backgroundColor: '#F2F7F7',
                                    padding: 2,
                                    cursor: 'pointer'
                                  }}>
                                  <Stack direction='row' alignItems='center' spacing={1}>
                                    <Box sx={{ padding: '0.2rem', width: '40px', height: '40px' }}>
                                      <img
                                        src='https://vivpro-site.s3.amazonaws.com/Help_Page_FAQ/pdf_icon.png'
                                        alt='PDF download'
                                        height='100%'
                                        width='100%'
                                      />
                                    </Box>
                                    <Typography
                                      variant='subtitle1'
                                      fontWeight='bold'
                                      sx={{ textDecoration: 'underline', color: 'primary.dark' }}>
                                      {item.original_file_name}
                                    </Typography>
                                  </Stack>
                                  <Tooltip title='Open PDF in new tab'>
                                    <IconButton
                                      aria-label='Open PDF in new tab'
                                      onClick={e => openPDFInNewTab(e, item.s3_url)}>
                                      <OpenInNewIcon fontSize='small' />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              );
                            })}
                          </Stack>
                        );
                      })}
                  </Stack>
                </DialogContent>
              </Dialog>

              <Dialog
                fullWidth
                maxWidth='lg'
                onClose={() => {
                  handleClosePDF();
                }}
                aria-labelledby='customized-dialog-title'
                open={openPdf}>
                <DialogTitle>
                  <Stack direction='row' justifyContent='flex-end'>
                    {/* <Typography variant='subtitle1' fontWeight='bold'>
                      {PDFData} Documents
                    </Typography> */}
                    <Stack>
                      <IconButton
                        aria-label='delete'
                        onClick={() => {
                          handleClosePDF();
                        }}>
                        <CancelIcon />
                      </IconButton>
                    </Stack>
                  </Stack>
                </DialogTitle>
                <DialogContent>
                  {PDFData ? (
                    <Box
                      sx={{
                        height: '79vh',
                        boxShadow: '0px 0px 10px blue.alice',
                        border: 'unset',
                        borderRadius: '10px'
                      }}>
                      <PDFPreview
                        open={Boolean(PDFData)}
                        pdfUrl={PDFData}
                        onClose={() => handleClosePDF()}
                        handleChatRia={handleChatRia}
                        title={title}
                      />
                    </Box>
                  ) : null}
                </DialogContent>
              </Dialog>

              {/* summary headings and cards */}
              {Object.keys(selectedMeeting).length > 0 &&
                Object.keys(selectedMeeting?.summary_keys).map(key => {
                  if (!selectedMeeting?.summary[key]?.length) {
                    return null;
                  }
                  if (
                    // (summaryTypeFilter && summaryTypeFilter === 'all') ||
                    summaryTypeFilter &&
                    summaryTypeFilter !== 'all' &&
                    !key.includes(summaryTypeFilter?.toLowerCase())
                  ) {
                    return null;
                  }
                  return (
                    <Stack spacing={1} key={uniqueId()}>
                      <Typography
                        variant='subtitle1'
                        fontWeight='bold'
                        sx={{ border: '0px solid gray' }}>
                        {selectedMeeting?.summary[key]?.length > 0 &&
                          `${selectedMeeting?.summary_keys[key]} (${selectedMeeting?.summary[key]?.length})`}
                      </Typography>
                      <Typography variant='body1' fontWeight='bold' sx={styles.summaryText}>
                        {selectedMeeting?.summary_text?.[key]}
                      </Typography>
                      {/* application content row */}
                      {selectedMeeting?.summary[key]?.map((item: any) => {
                        return (
                          <Stack
                            key={uniqueId()}
                            direction='row'
                            spacing={1}
                            alignItems='center'
                            sx={styles.listContainer}
                            justifyContent='space-between'>
                            <Grid container spacing={1} alignItems='center'>
                              <Grid item xs={4}>
                                <Stack spacing={2} direction='row' alignItems='center'>
                                  <Tooltip
                                    title={`Application Number: ${
                                      item?.formatted_product_number || 'Not yet approved by EMA'
                                    }`}>
                                    <Typography
                                      variant='subtitle2'
                                      noWrap
                                      sx={styles.productNumberText}>
                                      {item?.formatted_product_number &&
                                      item?.formatted_product_number !== 'NA' ? (
                                        <span>{item.formatted_product_number}</span>
                                      ) : (
                                        <span style={{ margin: '0 1.1em' }}>NA</span>
                                      )}
                                    </Typography>
                                  </Tooltip>

                                  <Tooltip
                                    title={
                                      !has(item, 'formatted_product_number') ||
                                      item?.formatted_product_number === 'NA'
                                        ? 'Not yet approved by EMA'
                                        : 'Open Regulatory 360 view'
                                    }>
                                    <Stack>
                                      <IconButton
                                        disabled={
                                          !has(item, 'formatted_product_number') ||
                                          item?.formatted_product_number === 'NA'
                                        }
                                        size='small'
                                        href={`/regulatory360/chmp/${item.formatted_product_number}/eu`}
                                        target='_blank'>
                                        <Reg360Icon
                                          disabled={
                                            !has(item, 'formatted_product_number') ||
                                            item?.formatted_product_number === 'NA'
                                          }
                                        />
                                      </IconButton>
                                    </Stack>
                                  </Tooltip>

                                  <Tooltip
                                    title={`Brand Name: ${
                                      item.brand_name?.toUpperCase() || '---'
                                    }`}>
                                    <Typography
                                      align='left'
                                      variant='subtitle2'
                                      fontWeight='bold'
                                      noWrap
                                      sx={styles.brandName}>
                                      {item.brand_name || '---'}
                                    </Typography>
                                  </Tooltip>
                                </Stack>
                              </Grid>

                              {/* <Grid item xs={2}></Grid> */}
                              <Grid item xs={2}>
                                <Tooltip
                                  title={`Active Ingredient: ${
                                    capitalize(item.active_ingredient) || '---'
                                  }`}>
                                  <Typography
                                    variant='subtitle2'
                                    textTransform='capitalize'
                                    noWrap
                                    sx={{ color: 'black.main' }}>
                                    {item.active_ingredient || '---'}
                                  </Typography>
                                </Tooltip>
                              </Grid>
                              <Grid item xs={4}>
                                <Tooltip
                                  title={`Indications: ${item.indications_and_usage || 'NA'}`}>
                                  <Typography
                                    align={item.indications_and_usage ? 'left' : 'center'}
                                    variant='subtitle2'
                                    sx={styles.indicationsText}>
                                    {item.indications_and_usage || '---'}
                                  </Typography>
                                </Tooltip>
                              </Grid>
                              <Grid item xs={2}>
                                <Tooltip
                                  title={`Sponsor Name: ${
                                    item?.sponsor_name?.toUpperCase() || 'NA'
                                  }`}>
                                  <Typography
                                    variant='subtitle2'
                                    noWrap
                                    // align='center'
                                    sx={styles.sponsorText}>
                                    {item.sponsor_name || '---'}
                                  </Typography>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Stack>
                        );
                      })}
                    </Stack>
                  );
                })}

              {selectedMeeting?.summary_text?.others && (
                <Stack spacing={1}>
                  <Typography
                    variant='subtitle1'
                    fontWeight='bold'
                    sx={{ border: '0px solid gray' }}>
                    Others
                  </Typography>
                  <Typography variant='body1' fontWeight='bold' sx={styles.summaryText}>
                    {selectedMeeting?.summary_text?.others}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Meetings;
