import { apiDataToCardProps } from '../constants';

const transformData = (applicationResults: any) => {
  const sourceWiseApps = applicationResults;
  let apps: any = [];
  const resultSources: any = [];
  Object.keys(sourceWiseApps).forEach(source => {
    resultSources.push(source);
    if (apiDataToCardProps[source]) {
      const sourceApps = sourceWiseApps[source].results.map((appData: any) =>
        apiDataToCardProps[source]?.(appData)
      );
      apps = [...apps, ...sourceApps];
    }
  });
  return apps;
};

export default transformData;
