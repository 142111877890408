import { isArray } from 'lodash';
import { sourceFlagMapping } from '../../SearchResults/constants';

const NotificationFlag = ({ source, id = 'icon' }: any) => {
  if (isArray(source)) {
    return (
      <>
        {source.map((name: string) => {
          const IndividualFlag = sourceFlagMapping[name];
          if (!IndividualFlag) return null;
          return <IndividualFlag key={name} />;
        })}
      </>
    );
  }
  const IndividualFlag = sourceFlagMapping[source];
  return <IndividualFlag id={id} />;
};

export default NotificationFlag;
