import { createSvgIcon } from '@mui/material';

const ArrowRightIcon = createSvgIcon(
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.81003 12.7521L4.1333 12.0754L8.86894 7.33971C8.95879 7.24984 9.00926 7.12796 9.00926 7.00087C9.00926 6.87379 8.95879 6.75191 8.86894 6.66203L4.14145 1.9355L4.81913 1.25781L9.54566 5.98435C9.81521 6.25397 9.96663 6.61962 9.96663 7.00087C9.96663 7.38213 9.81521 7.74777 9.54566 8.0174L4.81003 12.7521Z'
      fill='currentColor'
    />
  </svg>,
  'ArrowRight'
);

export default ArrowRightIcon;
