import getFormatDataForCSV from '../pages/ResultsPage/utils/getFormatDataForCSV';

// Function to transform and rename the keys using COLUMN_NAME_MAPPING
const transformData = (data: any, columnMapping: any) => {
  const allowedColumns = Object.keys(columnMapping);
  return data?.map((each: any) =>
    allowedColumns.reduce((acc: any, key: any) => {
      if (each[key] !== undefined) {
        if (Array.isArray(each[key])) {
          if (typeof each[key][0] === 'object' && !Array.isArray(each[key][0])) {
            acc[columnMapping[key]] = getFormatDataForCSV(each[key]);
          } else {
            acc[columnMapping[key]] = each[key].join(', ');
          }
        } else if (typeof each[key] === 'object' && !Array.isArray(each[key])) {
          acc[columnMapping[key]] = getFormatDataForCSV([each[key]]);
        } else {
          acc[columnMapping[key]] = each[key];
        }
      }
      return acc;
    }, {})
  );
};

export default transformData;
