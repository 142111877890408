import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const CheckBox = ({ item, filterState, onChangeFn }: any) => {
  return (
    <FormControlLabel
      key={item.value}
      sx={{ fontWeight: '600', fontSize: 16, color: '#000000' }}
      control={
        <Checkbox
          value={item.value}
          checked={
            !!item.selected ||
            (item.id in filterState &&
              filterState[item.id].some(
                (x: string) => x.toLowerCase() === item.value.toLowerCase()
              ))
          }
          onChange={e => onChangeFn(e, item)}
          name={`${item.value} ${item?.count ? `(${item.count})` : ''}`}
        />
      }
      label={`${item.value} ${item?.count ? `(${item.count})` : ''}`}
    />
  );
};

export default React.memo(CheckBox);
