const actions = {
  SET_CANADA_CONSULTATION_TABLE: 'SET_CANADA_CONSULTATION_TABLE',
  SET_CANADA_CONSULTATION_STATS: 'SET_CANADA_CONSULTATION_STATS',
  SET_CANADA_CONSULTATION_ERRORS: 'SET_CANADA_CONSULTATION_ERRORS',
  SET_DONUT_CHARTS: 'SET_DONUT_CHARTS',
  SET_ISSUE_DATE: 'SET_ISSUE_DATE',
  SET_FILTERS: 'SET_FILTERS',
  SET_LOADING: 'SET_LOADING',
  SET_IS_FILTERS_APPLIED: 'SET_IS_FILTERS_APPLIED',
  SET_AVAILABLE_FILTERS: 'SET_AVAILABLE_FILTERS',
  SET_SEARCH: 'SET_SEARCH',
  RESET_SEARCH: 'RESET_SEARCH',
  SET_DOCUMENT_TO_COMPARE: 'SET_DOCUMENT_TO_COMPARE',
  CLEAR_DOCUMENT_TO_COMPARE: 'CLEAR_DOCUMENT_TO_COMPARE',
  REMOVE_DOCUMENT_TO_COMPARE: 'REMOVE_DOCUMENT_TO_COMPARE',
  SET_COMPARE_ENABLED: 'SET_COMPARE_ENABLED',
  SET_COMPARE_POPUP_OPEN: 'SET_COMPARE_POPUP_OPEN',
  UPDATE_S3_URL: 'UPDATE_S3_URL'
};

export default actions;
