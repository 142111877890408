import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// mui
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { ThemeProvider } from '@mui/material/styles';
import themev5 from '../../../themev5';

// hooks
import useQuery from '../../../helpers/customHooks/queryParameter';

// component
import MeetingInfoChip from './MeetingInfoChip';
import Tabs from './Tabs';
import { VivproSelect } from '../../../components/CustomComponents/Selects';

// utils
import { MEETING_TABS } from '../constant';

// styles
import styles from '../styles/MeetingPage.styles';
import MeetingListSkeleton from './Skeleton/MeetingListSkeleton';

const MeetingPage = ({
  isLoading,
  meetingData,
  totalPages,
  page,
  handlePageChange,
  updatedSearchParams,
  meetingStatus,
  handleMeetingTabChange
}: any) => {
  const location = useLocation();
  const boxRef = useRef<HTMLElement>(null);
  const query = useQuery();
  const [selectedMeeting, setSelectedMeeting] = useState<{
    [key: string]: string | number;
  }>({});

  const handleMeetingChange = useCallback(
    (data: any) => {
      if (data && 'meeting_id' in data) {
        updatedSearchParams.set('meetingId', data.meeting_id);
        setSelectedMeeting(data);
      } else {
        setSelectedMeeting({});
        updatedSearchParams.delete('meetingId');
      }
      window.history.pushState({}, '', `${location.pathname}?${updatedSearchParams.toString()}`);
    },
    [selectedMeeting]
  );

  useEffect(() => {
    // IF no meetingId is found. Selected first meeting as default
    if (!query.get('meetingId')) {
      handleMeetingChange(meetingData[0]);
    } else if (meetingData && meetingData.length !== 0) {
      // IF meetingId is found. Selected the meeting associated with that id.
      let found: boolean = false;
      meetingData.forEach((ele: any) => {
        if (ele.meeting_id === query.get('meetingId')) {
          handleMeetingChange(ele);
          found = true;
        }
        if (!found) {
          if (meetingData.length) {
            handleMeetingChange(meetingData[0]);
          }
        }
      });
    } else {
      handleMeetingChange({});
    }
  }, [meetingData, query]);

  if (!isLoading) {
    return (
      <ThemeProvider theme={themev5}>
        <Box height='100%'>
          <Stack display='flex' flexDirection='row'>
            <Paper
              sx={{
                backgroundColor: 'white.main',
                height: 'calc(100vh - 340px)',
                borderRadius: '20px',
                maxWidth: '25%',
                margin: 2
              }}>
              <Box
                paddingBottom={1}
                display='flex'
                justifyContent='space-between'
                flexDirection='column'
                sx={{ height: 'calc(100vh - 340px)' }}
                overflow='auto'>
                <Box id='adcomm-meeting-status' mt={2} ml={2} sx={styles.dropDownBox}>
                  <VivproSelect
                    // @ts-ignore
                    value={meetingStatus}
                    handleChange={handleMeetingTabChange}
                    options={MEETING_TABS}
                    icon={KeyboardArrowDown} // Should change to DownArrow.
                  />
                </Box>
                <Box ref={boxRef} display='flex' flexDirection='column' sx={styles.meetingList}>
                  {meetingData ? (
                    <Box>
                      {meetingData.length !== 0 ? (
                        <>
                          {meetingData.map((ele: any) => {
                            return (
                              <MeetingInfoChip
                                key={ele.meeting_id}
                                data={ele}
                                selectedMeeting={selectedMeeting}
                                handleMeetingChange={handleMeetingChange}
                                boxRef={boxRef}
                              />
                            );
                          })}
                        </>
                      ) : (
                        <Box
                          display='flex'
                          alignItems='center'
                          justifyContent='center'
                          sx={{ height: '6vh' }}>
                          <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
                            No Meetings data available
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      sx={{ height: '' }}>
                      <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
                        No Meetings data available
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box display='flex' justifyContent='center' alignContent='center' pt={2}>
                  {totalPages > 1 ? (
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={(e, newPage) => {
                        handlePageChange(newPage, false);
                      }}
                      variant='text'
                      sx={{
                        color: 'gray.lightVariant',
                        '& .MuiPaginationItem-root': { fontSize: 14, minWidth: '25px' }
                      }}
                      siblingCount={1}
                      boundaryCount={1}
                    />
                  ) : null}
                </Box>
              </Box>
            </Paper>
            <Paper sx={styles.meetingDetailsWrapper}>
              {Object.keys(selectedMeeting).length !== 0 ? (
                <Tabs selectedMeeting={selectedMeeting} updatedSearchParams={updatedSearchParams} />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: 'calc(100vh - 340px)',
                    alignItems: 'center'
                  }}>
                  <Typography variant='h6'>No Meetings data available</Typography>
                </Box>
              )}
            </Paper>
          </Stack>
        </Box>
      </ThemeProvider>
    );
  }
  return <MeetingListSkeleton />;
};

export default React.memo(MeetingPage);
