import { useCallback, useContext, useEffect } from 'react';
import Papa from 'papaparse';
import XLSX from 'xlsx';

// api
import {
  postGuidanceDocs,
  guidanceInsights,
  getAllGuidanceDocuments
} from '../../../api/pages/GuidanceDocuments';

// store
import actions from '../../../store/GuidanceDocuments/actions';
import globalActions from '../../../store/actions';
import GuidanceStore from '../../../store/GuidanceDocuments';
import globalStore from '../../../store/index';

import { mergeOldFilterValues } from '../../../utils/filters';
import { constructPieChartData } from '../utils/getDonutChartStructure';
import getFilterOptions from '../utils/common';
import {
  PAGINATION_DEFAULT_OPTIONS,
  EXPORT_TYPE,
  FDA_GUIDANCE_COLUMN_NAME_MAPPING
} from '../const';
import { getFileName } from '../../../helpers/getFileName';
import transformFDAGuidanceData from '../utils/transformFDAGuidanceData';

import AuthContext from '../../../store/Auth/AuthProvider';
import { getDataGridFilter } from '../../../utils/getDatagridFilterOption';

const useGuidanceData = () => {
  const { guidanceState, guidanceDispatch } = useContext(GuidanceStore);
  const { state, dispatch }: any = useContext(globalStore);
  const { currentUser } = useContext(AuthContext);

  const getGuidanceInsightData = async (filters: any, appliedFilters: any) => {
    const insightResponse = await guidanceInsights({ filters });
    if (insightResponse?.data?.status === 200) {
      const graphs = constructPieChartData(
        insightResponse?.data?.body?.guidances_insights?.donut_graph_data
      );
      guidanceDispatch({
        type: actions.SET_DONUT_CHARTS,
        value: graphs
      });

      guidanceDispatch({
        type: actions.SET_DOC_TYPE_DONUT,
        value: insightResponse?.data?.body?.guidances_insights?.donut_graph_data?.document_donut
      });

      guidanceDispatch({
        type: actions.SET_FDA_ORG_DONUT,
        value: insightResponse?.data?.body?.guidances_insights?.donut_graph_data?.organization_donut
      });

      guidanceDispatch({
        type: actions.SET_PRODUCT_DONUT,
        value: insightResponse?.data?.body?.guidances_insights?.donut_graph_data?.product_donut
      });

      guidanceDispatch({
        type: actions.SET_ISSUE_DATE,
        value: insightResponse?.data?.body?.guidances_insights?.bar_graph_data?.filter(
          (item: any) => item.count > 0
        )
      });
      const stats = {
        totalCountStatusFinal: insightResponse?.data?.body?.guidances_insights?.final_guidances,
        totalGuidanceCount:
          insightResponse?.data?.body?.guidances_insights?.total_guidance_documents,
        totalLastThirtyDays: insightResponse?.data?.body?.guidances_insights?.last_30_days_count,
        pdfCount: insightResponse?.data?.body?.guidances_insights?.pdf_stats?.total_pdf_count,
        pageCount: insightResponse?.data?.body?.guidances_insights?.pdf_stats?.total_page_count
      };

      guidanceDispatch({
        type: actions.SET_GUIDANCE_STATS,
        value: stats
      });

      let mergedFilters: any = [];
      const newFilters = getFilterOptions(insightResponse?.data?.body?.filters);
      if (appliedFilters.length) {
        mergedFilters = mergeOldFilterValues(newFilters, appliedFilters);
      } else {
        mergedFilters = newFilters;
      }

      guidanceDispatch({
        type: actions.SET_FILTERS,
        value: mergedFilters
      });
      guidanceDispatch({
        type: actions.SET_ASK_RIA_FILTERS,
        value: insightResponse?.data?.body?.filters?.entity_id
      });
    }
  };

  const getGuidanceTableData = async (payload: any, limit: number, offset: number) => {
    const res = await postGuidanceDocs(payload, limit, offset);
    if (res?.data?.status === 200) {
      guidanceDispatch({
        type: actions.SET_GUIDANCE_TABLE,
        value: res?.data?.body?.guidances ?? []
      });

      guidanceDispatch({
        type: actions.SET_TOTAL_COUNT,
        value: res?.data?.body?.guidances_count ?? 0
      });
    }
  };

  // Hook to fetch data based on datagrid filter
  const getDatawithDataGridFilter = async (
    appliedFilters: any,
    datagridFilter: any,
    sortFields: any,
    limit: number = PAGINATION_DEFAULT_OPTIONS.limit,
    offset: number = PAGINATION_DEFAULT_OPTIONS.offset
  ) => {
    guidanceDispatch({
      type: actions.SET_LOADING,
      value: true
    });
    const source = {
      us: ['guidance']
    };
    const res: any = await getDataGridFilter(
      appliedFilters,
      datagridFilter,
      sortFields,
      limit,
      offset,
      guidanceState,
      currentUser,
      source
    );

    if (res?.data?.status === 200) {
      guidanceDispatch({
        type: actions.SET_GUIDANCE_TABLE,
        value: res?.data?.body?.result?.guidance?.results ?? []
      });

      guidanceDispatch({
        type: actions.SET_TOTAL_COUNT,
        value: res?.data?.body?.result?.guidance?.total ?? 0
      });
      guidanceDispatch({
        type: actions.SET_DATA_GRID_FILTERS,
        value: datagridFilter
      });
    }

    guidanceDispatch({
      type: actions.SET_LOADING,
      value: false
    });
  };

  const getGuidanceData = useCallback(
    async (
      appliedFilters: any,
      limit: number = PAGINATION_DEFAULT_OPTIONS.limit,
      offset: number = PAGINATION_DEFAULT_OPTIONS.offset,
      sort: Array<any> = [],
      shouldGetInsight: boolean = true,
      dataGridFilter: any = {}
    ) => {
      guidanceDispatch({
        type: actions.SET_LOADING,
        value: true
      });

      guidanceDispatch({
        type: actions.SET_IS_FILTERS_APPLIED,
        value: Object.keys(appliedFilters).length !== 0
      });

      const payload: any = {};
      payload.filters = appliedFilters;
      if (sort) {
        payload.sort = sort;
      }

      guidanceDispatch({
        type: actions.SET_AVAILABLE_FILTERS,
        value: appliedFilters
      });
      if (Object.keys(dataGridFilter).length !== 0) {
        await getDatawithDataGridFilter(appliedFilters, dataGridFilter, sort, limit, offset);
      } else {
        try {
          await getGuidanceTableData(payload, limit, offset);

          if (shouldGetInsight) {
            await getGuidanceInsightData(payload?.filters, appliedFilters);
          }

          guidanceDispatch({
            type: actions.SET_DATA_GRID_FILTERS,
            value: {}
          });

          guidanceDispatch({
            type: actions.SET_LOADING,
            value: false
          });
        } catch (err) {
          const errors: any = [];
          errors.push('No data was found');
          guidanceDispatch({
            type: actions.SET_GUIDANCE_ERRORS,
            action: errors
          });

          guidanceDispatch({
            type: actions.SET_LOADING,
            value: false
          });
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }
    },
    []
  );

  const openGuidanceComparison = useCallback((value: boolean) => {
    guidanceDispatch({
      type: actions.SET_COMPARE_ENABLED,
      value
    });
  }, []);
  const removeFromComparison = useCallback((id: string) => {
    guidanceDispatch({
      type: actions.REMOVE_DOCUMENT_TO_COMPARE,
      value: id
    });
  }, []);

  const openComparisonPopup = useCallback((value: boolean) => {
    guidanceDispatch({
      type: actions.SET_COMPARE_POPUP_OPEN,
      value
    });
  }, []);

  const clearComparison = useCallback(() => {
    guidanceDispatch({
      type: actions.CLEAR_DOCUMENT_TO_COMPARE
    });
    openComparisonPopup(false);
  }, []);

  const clearSearch = useCallback(() => {
    guidanceDispatch({
      type: actions.SET_SEARCH,
      value: {
        term: ''
      }
    });
  }, []);

  const handleCSVExport = async () => {
    guidanceDispatch({
      type: actions.SET_LOADING,
      value: true
    });
    const res = await getAllGuidanceDocuments({ filters: guidanceState.availableFilters });
    const selectedColumns = state?.exportType?.isAllColumnsSelected
      ? FDA_GUIDANCE_COLUMN_NAME_MAPPING
      : Object.fromEntries(
          Object.entries(FDA_GUIDANCE_COLUMN_NAME_MAPPING).filter(([key]) =>
            state?.exportType?.selectedColumns?.includes(key)
          )
        );
    const formattedData = transformFDAGuidanceData(res?.data?.body?.guidances, selectedColumns);
    const csv = Papa.unparse(formattedData);
    // Create a Blob from the CSV string
    const blob = new Blob([csv], { type: 'text/csv' });
    // Create a download link and click it
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    const day = new Date();
    const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
    const fileName = getFileName({
      prefix: 'ria',
      module: 'guidances',
      postfix: date,
      additional: '',
      extension: 'csv'
    });
    a.download = `${fileName}.csv`;
    a.click();
    dispatch({
      type: globalActions.SET_EXPORT_TYPE,
      value: { format: '', selectedColumns: [], isAllColumnsSelected: false }
    });
    guidanceDispatch({
      type: actions.SET_LOADING,
      value: false
    });
  };

  const handleExcelExport = async () => {
    guidanceDispatch({
      type: actions.SET_LOADING,
      value: true
    });
    const res = await getAllGuidanceDocuments({ filters: guidanceState.availableFilters });
    const selectedColumns = state?.exportType?.isAllColumnsSelected
      ? FDA_GUIDANCE_COLUMN_NAME_MAPPING
      : Object.fromEntries(
          Object.entries(FDA_GUIDANCE_COLUMN_NAME_MAPPING).filter(([key]) =>
            state?.exportType?.selectedColumns?.includes(key)
          )
        );
    const formattedData = transformFDAGuidanceData(res?.data?.body?.guidances, selectedColumns);
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const day = new Date();
    const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
    const fileName = getFileName({
      prefix: 'ria',
      module: 'guidances',
      postfix: date,
      additional: '',
      extension: 'xlsx'
    });
    XLSX.writeFile(wb, `${fileName}.xlsx`);
    dispatch({
      type: globalActions.SET_EXPORT_TYPE,
      value: { format: '', selectedColumns: [], isAllColumnsSelected: false }
    });
    guidanceDispatch({
      type: actions.SET_LOADING,
      value: false
    });
  };

  useEffect(() => {
    if (
      state?.exportType?.format &&
      [EXPORT_TYPE.csv, EXPORT_TYPE.xlsx].includes(state?.exportType?.format)
    ) {
      if (state?.exportType?.format === EXPORT_TYPE.csv) {
        handleCSVExport();
      } else if (state?.exportType?.format === EXPORT_TYPE.xlsx) {
        handleExcelExport();
      }
    }
  }, [state?.exportType]);

  return {
    guidancefilters: guidanceState.filters,
    guidanceTableData: guidanceState.tableData,
    isFiltersApplied: guidanceState.isFiltersApplied,
    isLoading: guidanceState.loading,
    compareEnabled: guidanceState.compareEnabled,
    documentsToCompare: guidanceState.documentsToCompare,
    comparePopupOpen: guidanceState.comparePopupOpen,
    openComparisonPopup,
    removeFromComparison,
    getGuidanceData,
    openGuidanceComparison,
    clearComparison,
    clearSearch,
    getDatawithDataGridFilter,
    sortFields: guidanceState.sortFields,
    dataGridFilter: guidanceState.dataGridFilters
  };
};

export default useGuidanceData;
