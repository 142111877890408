const styles = {
  meetingSummaryContainer: {
    mt: 2,
    height: 'calc(100vh - 290px)',
    overflowY: 'auto',
    p: 2,
    bgcolor: 'white.main',
    borderRadius: 3,
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px'
  },
  meetingListContainer: {
    mt: 2,
    height: 'calc(100vh - 290px)',
    overflowY: 'auto',
    p: 2,
    bgcolor: 'white.main',
    borderRadius: 3,
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px'
  },
  barGraphContainer: {
    width: '100%',
    height: 300,
    p: 2,
    cursor: 'pointer',
    bgcolor: 'white.main',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    borderRadius: '10px'
  },
  pieCharContainer: {
    width: '50%',
    height: '30vh',
    p: 2,
    bgcolor: 'white.main',
    cursor: 'pointer',
    borderRadius: '10px'
  },
  basicInfoContainer: {
    p: 2,
    width: '50%',
    fontWeight: 500,
    bgcolor: 'white.main',
    maxHeight: '30vh',
    overflowY: 'auto',
    borderRadius: '10px'
  },
  statsCountContainer: {
    width: '100%',
    height: 150,
    borderRadius: '10px',
    p: 2,
    bgcolor: 'white.main'
  }
};

export default styles;
