const styles = {
  submissionOptionsContainer: {
    p: 3,
    pt: 2,
    pr: 3,
    pb: 0.75,
    mr:1,
    '& .MuiAccordion-root:nth-of-type(1)::before': {
      backgroundColor: '#0f0 !important'
    },
    '& .MuiAccordion-root': {
      boxShadow: 'unset'
    },
    '& .MuiAccordionSummary-content': {
      margin: '10px 0 !important'
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 'unset'
    },
    maxHeight: 500
  },
  clearSelection: {
    cursor: 'pointer',
    color: 'primary.main',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  searchBox: {
    borderRadius: 2,
    mb: 1.5,
    width: '100%',
    height: 32
  },
  accordion: {
    marginY: '0 !important',
    '&::before': {
      position: 'absolute',
      left: 0,
      top: '-1px',
      right: 0,
      height: '1px',
      content: '""',
      opacity: '1 !important',
      backgroundColor: 'gray.200'
    }
  },
  checkBox: {
    p: 0,
    pr: 1.5,
    '& .MuiSvgIcon-root': {
      fontSize: 16
    }
  },
  menuItem: { pr: 3, pl: 2.75, fontSize: 14, paddingY: 1.25 },
  menu: {
    '& .MuiPaper-root': {
      p: 0,
      paddingY: 0.5,
      borderRadius: 2
    }
  }
};

export default styles;
