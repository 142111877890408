import { createSvgIcon } from '@mui/material';

const GridIcon = createSvgIcon(
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_8799_7323)'>
      <path
        d='M15.9629 5.10778L8.00026 0.333115L0.0375977 5.10778L8.00026 9.88511L15.9629 5.10778ZM8.00026 1.88511L13.3709 5.10778L8.00026 8.33311L2.6296 5.10778L8.00026 1.88511Z'
        fill='currentColor'
      />
      <path
        d='M16 9.31006L8 14.1101L0 9.31006V10.8654L8 15.6647L16 10.8654V9.31006Z'
        fill='currentColor'
      />
      <path
        d='M16 6.42262L14.3233 7.42862L8 11.2226L1.67667 7.42862L0 6.42262V7.97729L8 12.7773L16 7.97729V6.42262Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_8799_7323'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'Grid'
);

export default GridIcon;
