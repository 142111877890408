import { memo, useMemo } from 'react';
import { groupBy, maxBy } from 'lodash';

import { Box, Stack, Typography } from '@mui/material';
import { getLabelForDate } from '../../utils';
import ActivityListItem from './ActivityListItem';
import landingPageStyles from '../../styles/LandingPage.styles';

const Activities = ({ activityData }: any) => {
  const processGroupedByDate = (dateWithoutTime: string, dateResults: any[]) => {
    const resultWithLatestDate = maxBy(dateResults, 'date') ?? dateResults[0];

    return {
      ...resultWithLatestDate,
      date: new Date(resultWithLatestDate.date),
      dateWithoutTime,
      quantity: dateResults.length,
      activityIds: dateResults.map((result: any) => result.activity_id),
      activityTime: dateResults
        .map((result: any) => new Date(result.date))
        .sort((a: any, b: any) => b - a)
    };
  };
  const groupResultsByDate = (results: any[]) => {
    const groupedByDate = groupBy(results, (result: any) => {
      const dateInUserTimezone = new Date(result.date);
      return dateInUserTimezone.toLocaleDateString();
    });

    return Object.entries(groupedByDate).map(([dateWithoutTime, dateResults]) =>
      processGroupedByDate(dateWithoutTime, dateResults)
    );
  };

  const finalData = useMemo(() => {
    const dataGroupedById = groupBy(activityData, 'id');
    const dataGroupedByIdAndDate = Object.entries(dataGroupedById).map(([, results]) =>
      groupResultsByDate(results)
    );
    return Object.entries(groupBy(dataGroupedByIdAndDate.flat(), 'dateWithoutTime'));
  }, [activityData]);

  return (
    <Box>
      {finalData?.map(([date, results]) => (
        <Box key={date} mb='12px'>
          <Stack justifyContent='center' width='100%'>
            <Box sx={landingPageStyles.dateChip}>
              <Typography>{getLabelForDate(date)}</Typography>
            </Box>
          </Stack>
          {results.map(result => (
            <ActivityListItem
              key={result.activity_id}
              data={result}
              date={result.date}
              quantity={result.quantity ?? 0}
              title={result.term}
              searchType={result.type}
              source={result.source}
              showDivider
            />
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default memo(Activities);
