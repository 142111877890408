import { useContext } from 'react';
import Store from '../store';
import Actions from '../store/actions';

const useDetectNonChromeBrowser = () => {
  const { dispatch } = useContext(Store) as any;
  const detectBrowserAndDisplayDisclaimer = () => {
    const { userAgent } = navigator;
    const isChrome = userAgent.includes('Chrome') || userAgent.includes('Chromium');
    if (!isChrome)
      dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: 'We advise you to use Google Chrome browser for the best experience.',
          color: 'warning',
          duration: 8000
        }
      });
  };
  return {
    detectBrowserAndDisplayDisclaimer
  };
};

export default useDetectNonChromeBrowser;
