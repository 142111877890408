import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  paper: {
    borderRadius: theme.spacing(3),
    padding: theme.spacing(3)
  },
  cardDetails: {
    marginTop: theme.spacing(15),
    paddingTop: theme.spacing(10),
    height: '100%',
    width: '100%'
  },
  highlightText: {
    // color:theme.palette.common.primaryBlack,
    opacity: 0.6,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  title: {
    fontWeight: '400',
    color: theme.palette.cards.grayDark
  },
  reportText: {
    fontSize: theme.spacing(2),
    color: theme.palette.cards.blue
  },
  nameNDA: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.cards.red
  },
  nameBLA: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.cards.blue
  },
  tags: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    background: theme.palette.cards.grayAlternate
  },
  tagText: {
    marginRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1.2),
    paddingRight: theme.spacing(1.2),
    borderRadius: theme.spacing(1),
    background: theme.palette.cards.grayAlternate,
    color: theme.palette.white
  },
  description: {
    height: theme.spacing(15),
    fontWeight: '400',
    overflowY: 'scroll',
    marginTop: theme.spacing(2),
    // fontSize: theme.spacing(2),
    color: theme.palette.cards.grayDescription
  },
  meter: {
    marginTop: theme.spacing(5),
    height: '100%'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    fontSize: theme.spacing(1.5)
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.tabs.grayLight}`
  },
  activeTab: {
    border: `1px solid ${theme.palette.tabs.grayLight}`,
    borderRadius: '10px 10px 0px 0px',
    // boxShadow: `10px 0px 10px ${theme.palette.tabs.shadow}`,
    fontSize: theme.spacing(2),
    color: theme.palette.tabs.grayText,
    textTransform: 'capitalize',
    background: theme.palette.white
  },
  inactiveTab: {
    border: `1px solid ${theme.palette.tabs.grayLight}`,
    borderRadius: '10px 10px 0px 0px',
    textTransform: 'capitalize',
    color: theme.palette.white,
    background: theme.palette.gradient.main
  },
  indicator: {
    background: theme.palette.white
  },
  heading: {
    color: theme.palette.cards.green,
    fontSize: theme.spacing(2),
    fontWeight: '600'
  },
  headers: {
    padding: theme.spacing(2)
  },
  box: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    background: theme.palette.white,
    boxShadow: '0px 0px 20px #0000000D',
    borderRadius: theme.spacing(1.25)
  },
  avatar: {
    marginLeft: theme.spacing(1),
    height: '3.4vh',
    width: '3.4vh',
    fontSize: '1.5vh',
    background: theme.palette.cards.avatar
  },
  avatarLight: {
    marginLeft: theme.spacing(1),
    height: '3.4vh',
    width: '3.4vh',
    fontSize: '1.5vh',
    background: theme.palette.biologics.textSecondary
  },
  adminDate: {
    color: theme.palette.regulatory.lightGreen,
    background: theme.palette.white,
    borderRadius: theme.spacing(0.8),
    margin: '0px 70px'
  }
}));
