import themev5 from '../../../themev5';

const styles = () => ({
  root: {
    marginTop: 1
  },
  back: {
    cursor: 'pointer'
  },
  navigationText: {
    color: `black !important`,
    textTransform: 'initial !important',
    display: 'flex',
    width: '100%'
  },
  navigationButtonText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  searchBoxContainer: {
    marginTop: 1,
    display: 'flex',
    alignItems: 'center'
  },
  helperTextContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [themev5.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  searchBox: {
    width: '100%',
    height: '100%'
  },
  searchBar: {
    marginTop: 1
  },
  subtext: {
    color: 'gray.dark'
  },
  lowIcon: {
    fontSize: '16px',
    color: 'red.main'
  },
  mediumIcon: {
    fontSize: '16px',
    color: 'orange.main'
  },
  highIcon: {
    fontSize: '16px',
    color: 'primary.light'
  },
  veryHighIcon: {
    fontSize: '16px',
    color: 'primary.darkVariant'
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [themev5.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start'
    }
  },
  buttonGroup: {
    [themev5.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  searchText: {
    fontWeight: 'bold',
    color: 'gray.contrastText'
  },
  didYouMeanText: {
    color: 'black.dark',
    fontSize: '16px',
    fontWeight: 'bold',
    '& span': {
      color: 'primary.darkVariant',
      cursor: 'pointer',
      fontSize: '16px'
    }
  },
  listItemText: {
    fontSize: 14,
    fontWeight: 600,
    color: 'gray.dark'
  }
});

export default styles;
