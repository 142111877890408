import React from 'react';
import { Box, IconButton, Link, Typography } from '@mui/material';
import { LeftArrowIcon, RightArrowIcon } from '../../assets/svgs/Icons';
import whatsNewStyles from './WhatsNew.styles';
import { Update } from './types';

interface MainContentProps {
  selectedWhatsNew: Update;
  selectedContentIndex: number;
  handlePrevContent: () => void;
  handleNextContent: () => void;
}

const MainContent: React.FC<MainContentProps> = ({
  selectedWhatsNew,
  selectedContentIndex,
  handlePrevContent,
  handleNextContent
}) => {
  return (
    <Box sx={whatsNewStyles.rightContainer}>
      {selectedWhatsNew?.description && (
        <Box sx={whatsNewStyles.mainContent.descriptionWrapper}>
          <Typography sx={whatsNewStyles.mainContent.descriptionAboutText}>
            About this Feature:
          </Typography>
          <Typography sx={whatsNewStyles.mainContent.mainDescription}>
            {selectedWhatsNew.description}
          </Typography>
        </Box>
      )}
      <Box padding='16px'>
        {selectedWhatsNew?.content?.[selectedContentIndex]?.visual_url?.length > 0 && (
          <img
            src={selectedWhatsNew?.content?.[selectedContentIndex]?.visual_url}
            alt={selectedWhatsNew?.content?.[selectedContentIndex]?.title}
            style={whatsNewStyles.mainContent.image}
          />
        )}
        {selectedWhatsNew?.content?.length > 1 && (
          <Box sx={whatsNewStyles.mainContent.buttonWrapper}>
            <IconButton
              onClick={handlePrevContent}
              disabled={selectedContentIndex === 0}
              sx={whatsNewStyles.mainContent.iconButton}>
              <LeftArrowIcon sx={whatsNewStyles.mainContent.icon} />
            </IconButton>
            <IconButton
              onClick={handleNextContent}
              disabled={selectedContentIndex === selectedWhatsNew.content.length - 1}
              sx={whatsNewStyles.mainContent.iconButton}>
              <RightArrowIcon sx={whatsNewStyles.mainContent.icon} />
            </IconButton>
          </Box>
        )}
        <Box>
          {selectedWhatsNew?.content?.[selectedContentIndex]?.title?.length > 0 && (
            <Typography sx={whatsNewStyles.mainContent.title}>
              {selectedWhatsNew?.content?.[selectedContentIndex]?.title}
            </Typography>
          )}
          <Typography
            sx={whatsNewStyles.mainContent.description}
            dangerouslySetInnerHTML={{
              __html: selectedWhatsNew?.content?.[selectedContentIndex]?.content
            }}
          />
          {selectedWhatsNew?.content?.[selectedContentIndex]?.link_url &&
            selectedWhatsNew?.content?.[selectedContentIndex]?.link_text && (
              <Link
                sx={whatsNewStyles.mainContent.link}
                variant='body1'
                href={selectedWhatsNew?.content?.[selectedContentIndex]?.link_url}
                target='_blank'
                rel='noreferrer'>
                {selectedWhatsNew?.content?.[selectedContentIndex]?.link_text}
              </Link>
            )}
        </Box>
      </Box>
    </Box>
  );
};

export default MainContent;
