import palette from '../../../themev5/palette';

const headerStyles: any = {
  search: {
    backgroundColor: palette.white.main,
    width: 'inherit',
    borderRadius: 4,
    border: 'none',
    outline: 'none',
    display: 'flex',
    fieldset: {
      display: 'none'
    },
    '& .MuiTextField-root': {
      justifyContent: 'center'
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingTop: '0 ',
      paddingBottom: '0 ',
      fontSize: '0.875rem'
    }
  },
  searchBox: {
    minWidth: { sm: 150, lg: 300 },
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 0,
    outline: 'none',
    opacity: 1,
    height: 38,
    '& > div': {
      height: '100%',
      width: '100%',
      '& input': {
        // height: '80%',
      }
    }
  },
  AdvancedRowWrapper: {
    '& div#autoCompleteRow': {
      minHeight: '100%',
      '& .MuiAutocomplete-root': {
        height: '100%',
        '& .MuiFormControl-root': {
          height: '100%',
          '& .MuiInputBase-root': {
            height: '100%'
          }
        }
      }
    },
    '& div#row': {
      animation: 'slideInFromLeft 0.3s ease-in'
    },
    '@keyframes slideInFromLeft': {
      from: {
        transform: 'scale(0.5, 0.5)',
        opacity: 0
      },
      to: {
        transform: 'scale(1,1)',
        opacity: 1
      }
    }
  }
};
export default headerStyles;
