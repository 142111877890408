import React, { useContext } from 'react';
// material ui
import { Box, Snackbar } from '@mui/material';
import Alert from '@mui/lab/Alert';
import { ToastContainer } from 'react-toastify';
import Store from '../../../store';
import Actions from '../../../store/actions';

const AlertMessage = () => {
  const { state, dispatch } = useContext(Store);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({
      type: Actions.SET_ALERT,
      value: { message: null, status: false }
    });
  };

  return (
    <Box>
      {state.alert && state.alert.status && (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={state.alert && state.alert.status}
          autoHideDuration={state.alert.duration || 2000}
          onClose={handleClose}>
          <Alert onClose={handleClose} severity={state.alert.color || 'error'}>
            {state.alert && state.alert.message}
          </Alert>
        </Snackbar>
      )}
      <ToastContainer pauseOnHover />
    </Box>
  );
};

export default AlertMessage;
