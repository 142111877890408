import React, { createContext, useReducer } from 'react';
import reducers from './reducers';

type Props = {
  children: React.ReactNode;
};

export interface CanadaGuidanceContextInterface {
  canadaGuidanceStats: any;
  tableData: any[];
  donutCharts: any[];
  issueDate: any[];
  adopted_date: any[];
  canadaGuidanceErrors: any[];
  filters: any[];
  availableFilters: any;
  loading: boolean;
  isFiltersApplied: boolean;
  search: any;
  documentsToCompare: any[];
  compareEnabled: boolean;
  comparePopupOpen: boolean;
  totalRecord: number;
  sortFields: any[];
  askRiaFilters: any[];
  dataGridFilters: any;
}

const initialState = {
  canadaGuidanceStats: {},
  tableData: [],
  donutCharts: [],
  issueDate: [],
  adopted_date: [],
  canadaGuidanceErrors: [],
  filters: [],
  availableFilters: {},
  loading: true,
  isFiltersApplied: false,
  search: {},
  documentsToCompare: [],
  compareEnabled: false,
  comparePopupOpen: false,
  totalRecord: 0,
  sortFields: [],
  askRiaFilters: [],
  dataGridFilters: {}
};

const CanadaGuidanceStore = createContext<{
  CanadaGuidanceState: CanadaGuidanceContextInterface;
  CanadaGuidanceDispatch: React.Dispatch<any>;
}>({ CanadaGuidanceState: initialState, CanadaGuidanceDispatch: () => null });
const { Provider } = CanadaGuidanceStore;

export const CanadaGuidanceStateProvider = ({ children }: Props) => {
  const [CanadaGuidanceState, CanadaGuidanceDispatch] = useReducer(reducers, initialState);
  return <Provider value={{ CanadaGuidanceState, CanadaGuidanceDispatch }}>{children}</Provider>;
};

export default CanadaGuidanceStore;
