import { createSvgIcon } from '@mui/material';

const CustomCheckIcon = createSvgIcon(
  <svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_734_5532)'>
      <path
        d='M4.39997 10.7649C4.20303 10.7653 4.00796 10.7267 3.82597 10.6515C3.64397 10.5762 3.47864 10.4658 3.33947 10.3264L0.542969 7.53043L1.24997 6.82293L4.04647 9.61943C4.14023 9.71316 4.26739 9.76582 4.39997 9.76582C4.53255 9.76582 4.6597 9.71316 4.75347 9.61943L11.75 2.62292L12.457 3.32992L5.46047 10.3264C5.32129 10.4658 5.15596 10.5762 4.97397 10.6515C4.79197 10.7267 4.59691 10.7653 4.39997 10.7649Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_734_5532'>
        <rect width='12' height='12' fill='white' transform='translate(0.5 0.5)' />
      </clipPath>
    </defs>
  </svg>,
  'CustomCheckIcon'
);

export default CustomCheckIcon;
