import React from 'react';
import { useHistory } from 'react-router';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Tooltip,
  Typography
} from '@mui/material';
import { CaretDownIcon, InfoIconSummary, ArrowUpIcon, PlusIcon } from '../../assets/svgs/Icons';
import {
  WHATS_NEW_TOOLTIPS,
  WHATS_NEW_TABS as WHATS_NEW_TYPES,
  WHATS_NEW_LIMITS
} from '../../pages/Help/utils/constants';
import whatsNewStyles from './WhatsNew.styles';
import { Update, WhatsNewEntry } from './types';

interface ListProps {
  updates: WhatsNewEntry[];
  expandedAccordions: string[];
  onAccordionChange: any;
  selectedWhatsNew: Update | null;
  onSelectUpdate: any;
  seenUpdateIds: number[];
  hideUpdateSeen?: boolean;
  hideViewAllUpdatesButton?: boolean;
}

const List: React.FC<ListProps> = ({
  updates,
  expandedAccordions,
  onAccordionChange,
  selectedWhatsNew,
  onSelectUpdate,
  seenUpdateIds,
  hideUpdateSeen = false,
  hideViewAllUpdatesButton = false
}) => {
  const history = useHistory();

  return (
    <Box sx={whatsNewStyles.leftContainer}>
      {updates.map((update, index) => (
        <React.Fragment key={update.id}>
          <Accordion
            elevation={0}
            expanded={expandedAccordions.includes(update.id)}
            onChange={onAccordionChange(update.id)}
            sx={whatsNewStyles.accordionSummary.main}>
            <AccordionSummary
              expandIcon={<CaretDownIcon sx={whatsNewStyles.accordionSummary.icon} />}
              aria-controls={update.id}
              id={update.id}
              sx={whatsNewStyles.accordionSummary.container}>
              <Box sx={whatsNewStyles.accordionSummary.titleWrapper}>
                <Typography sx={whatsNewStyles.accordionSummary.title}>
                  {update.type} ({update.updates.length}){' '}
                  <Tooltip title={WHATS_NEW_TOOLTIPS[update.type]} placement='top'>
                    <InfoIconSummary sx={whatsNewStyles.accordionSummary.infoIcon} />
                  </Tooltip>
                </Typography>
                <Typography sx={whatsNewStyles.accordionSummary.date}>
                  {update.published_on}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={whatsNewStyles.accordionDetails.container}>
              {update.updates.map(item => {
                const isUpdateSeen = hideUpdateSeen || seenUpdateIds.includes(item.id);
                const Icon =
                  item.type === WHATS_NEW_TYPES.enhancementUpdates ? ArrowUpIcon : PlusIcon;
                const isUpdateSelected = item.id === selectedWhatsNew?.id;
                return (
                  <Box
                    sx={
                      isUpdateSelected
                        ? whatsNewStyles.accordionDetails.selectedItemWrapper
                        : whatsNewStyles.accordionDetails.itemWrapper
                    }
                    key={item.id}
                    onClick={() => onSelectUpdate(item)}>
                    <Box sx={whatsNewStyles.accordionDetails.iconsWrapper}>
                      <Box
                        sx={whatsNewStyles.accordionDetails.unseenDot}
                        visibility={isUpdateSeen ? 'hidden' : 'visible'}
                      />
                      <Box
                        sx={
                          isUpdateSelected
                            ? whatsNewStyles.accordionDetails.selectedIconWrapper
                            : whatsNewStyles.accordionDetails.iconWrapper
                        }>
                        <Icon
                          sx={
                            isUpdateSelected
                              ? whatsNewStyles.accordionDetails.selectedIcon
                              : {
                                  ...whatsNewStyles.accordionDetails.icon,
                                  ...(!isUpdateSeen && { color: 'primary.650' })
                                }
                          }
                        />
                      </Box>
                    </Box>
                    <Tooltip
                      title={item.title.length > WHATS_NEW_LIMITS.listTitle ? item.title : ''}
                      placement='top'>
                      <Typography
                        sx={{
                          ...whatsNewStyles.accordionDetails.title,
                          ...(!isUpdateSeen && { color: 'primary.darkVariant2' })
                        }}>
                        {item.title}
                      </Typography>
                    </Tooltip>
                  </Box>
                );
              })}
            </AccordionDetails>
          </Accordion>
          {updates.length - 1 !== index && <Divider sx={whatsNewStyles.accordionSummary.divider} />}
        </React.Fragment>
      ))}
      {!hideViewAllUpdatesButton && (
        <Box sx={whatsNewStyles.viewAllUpdatesButtonWrapper}>
          <button
            type='button'
            style={whatsNewStyles.viewAllUpdatesButton}
            onClick={() => history.push('/help?content=whatsnew')}>
            View all updates
          </button>
        </Box>
      )}
    </Box>
  );
};

List.defaultProps = {
  hideUpdateSeen: false,
  hideViewAllUpdatesButton: false
};

export default List;
