/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
// HelperMethods
import uniqueId from "lodash/uniqueId";
import { useState } from "react";

// mui
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

// Components
import DescriptionItem from "./DescriptionItem";

// Styles
import styles from "../styles/Content.styles";

const WhatsNewItem = ({ feature }) => {
  const [curSlide, setCurslide] = useState(0);

  const nextSlide = () => {
    if (curSlide < feature.slides.length - 1) {
      setCurslide(curSlide + 1);
    }
  };

  const prevSlide = () => {
    if (curSlide > 0) {
      setCurslide(curSlide - 1);
    }
  };

  return (
    <>
      {feature.subHeader && (
        <Typography variant='h3' sx={styles.subtitleOne}>
          {feature.subHeader}
        </Typography>
      )}
      <Stack padding='20px' spacing={2}>
        {feature.description?.map(description => {
          return (
            <DescriptionItem key={uniqueId()} title={description.title} body={description.body} />
          );
        })}
      </Stack>

      <Paper elevation={3} sx={styles.slideBackGround}>
        <Stack padding='20px' spacing={2}>
          {feature.slides[curSlide].description?.map(description => {
            return (
              <DescriptionItem key={uniqueId()} title={description.title} body={description.body} />
            );
          })}
        </Stack>

        <Box display='flex' justifyContent='center' sx={{ marginBottom: '20px' }}>
          <Button
            onClick={prevSlide}
            sx={styles.actionBtn}
            variant='contained'
            disabled={!(curSlide > 0)}
          >
            Prev
          </Button>
          <Button
            onClick={nextSlide}
            sx={styles.actionBtn}
            variant='contained'
            disabled={!(curSlide < feature.slides.length - 1)}
          >
            Next
          </Button>
        </Box>
        {/* This is to preload images */}
        <Box sx={styles.preload}>
          {feature.slides?.map(slide => (
            // eslint-disable-next-line jsx-a11y/alt-text
            <img src={slide.image} />
          ))}
        </Box>
        <Box display='flex' justifyContent='center' paddingBottom='20px'>
          {feature.slides[curSlide].image && (
            <img
              src={feature.slides[curSlide].image}
              alt='whatsnew'
              style={styles.imageFullWidth}
            />
          )}
        </Box>
        {feature.slides[curSlide].action && (
          <Box display='flex' alignSelf='center' padding='20px' justifyContent='center' gap='10px'>
            <Button
              target='.blank'
              href={feature.slides[curSlide].action.url}
              sx={styles.actionBtn}
              variant='contained'
            >
              {feature.slides[curSlide].action.text}
            </Button>
          </Box>
        )}

        {feature.slides[curSlide].footer && (
          <Box display='flex' alignSelf='center' padding='20px' justifyContent='center' gap='10px'>
            <TipsAndUpdatesIcon sx={{ color: 'gold.main' }} />
            <Typography
              variant='subtitle1'
              sx={styles.subtitleOne}
              dangerouslySetInnerHTML={{ __html: feature.slides[curSlide].footer }}
            />
          </Box>
        )}
      </Paper>
    </>
  );
};

export default WhatsNewItem;
