import React from 'react';

import { Box } from '@material-ui/core';
import { IconButton, Typography, Checkbox, Tooltip } from '@mui/material';
import { CaretDownIcon } from '../../assets/svgs/Icons';
import customTitleCase from '../../utils/titleCase';

import styles from './Dropdown.style';

const Dropdown = ({
  onSelect,
  onClick,
  isClicked,
  disabled,
  isSelected,
  title,
  count,
  onExpand,
  isExpanded = false,
  hideCheckbox = false,
  expandDisabled,
  hoverEffect = true
}: any) => {
  return (
    <Box display='flex' alignItems='center' p={0.5}>
      <IconButton aria-label='expand' sx={{ p: 0.5 }} onClick={onExpand} disabled={expandDisabled}>
        <CaretDownIcon
          sx={{
            fontSize: 16,
            rotate: isExpanded ? '180deg' : '0deg',
            color: expandDisabled ? 'gray.300' : 'gray.800'
          }}
        />
      </IconButton>
      {!hideCheckbox && (
        <Checkbox
          disabled={disabled}
          checked={isSelected}
          onChange={onSelect}
          sx={styles.cardCheckbox}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      )}
      <Tooltip title={title}>
        <Typography
          onClick={onClick}
          sx={{
            ...styles.label,
            color: isClicked ? 'primary.700' : 'gray.900',
            textDecoration: isClicked ? 'underline' : 'none',
            ...(hoverEffect
              ? {
                  '&:hover': {
                    color: 'primary.700',
                    textDecoration: 'underline'
                  }
                }
              : {})
          }}>
          {customTitleCase(title)}
        </Typography>
      </Tooltip>
      {count !== undefined && (
        <Typography fontSize={14} color='primary.500' p={0.5}>
          ({count})
        </Typography>
      )}
    </Box>
  );
};

export default React.memo(Dropdown);
