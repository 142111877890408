import { memo } from 'react';

// mui v5
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import themev5 from '../../themev5';

// hoc
import HomePage from './component/HomePage';

// store
import { AdcommStateProvider } from '../../store/Adcomm';
import useUpdateModule from '../../hooks/useUpdateModule';

const AdcommModule = () => {
  useUpdateModule('adcomm');

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <AdcommStateProvider>
        <HomePage />
      </AdcommStateProvider>
    </ThemeProvider>
  );
};

export default memo(AdcommModule);
