/* eslint-disable import/prefer-default-export */
import { GridColDef } from '@mui/x-data-grid-pro';
import renderCellExpand from '../../../components/Datagrid/DatagridPopover';
import sortDateComparator from '../../../utils/sortDateComparator';

export const ProceduralSteps: GridColDef[] = [
  {
    field: 'appln_no',
    headerName: 'Application Number',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'scope',
    headerName: 'Scope',
    headerClassName: 'table-header',
    width: 350,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'bookmark_0',
    headerName: 'Opinion/Notification Issued On',
    headerClassName: 'table-header',
    width: 200,
    cellClassName: 'table-row',
    sortComparator: sortDateComparator
  },
  {
    field: 'commission_decision',
    headerName: 'Commission Decision Issued/ amended on',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    sortComparator: sortDateComparator
  },
  {
    field: 'bookmark_2',
    headerName: 'Product Information affecteed',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'summary',
    headerName: 'Summary',
    headerClassName: 'table-header',
    width: 300,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  }
];
