import { createSvgIcon } from '@mui/material';

const ArrowDownIcon = createSvgIcon(
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.0003 10.6667C7.55834 10.6669 7.13437 10.4916 6.82164 10.1793L3.29297 6.65L4.70764 5.236L8.0003 8.52867L11.293 5.236L12.7076 6.65L9.17897 10.1787C9.02417 10.3334 8.84041 10.4562 8.63817 10.5399C8.43594 10.6236 8.21919 10.6667 8.0003 10.6667Z'
      fill='currentColor'
    />
  </svg>,
  'ArrowDownIcon'
);

export default ArrowDownIcon;
