import { memo, useState } from 'react';

import { IconButton, Typography } from '@mui/material';
import { Accordion, AccordionDetails, AccordionSummary } from '../Accordian';
import { CaretDownIcon } from '../../../../assets/svgs/Icons';
import landingPageStyles from '../../styles/LandingPage.styles';
import ActivityListItem from './ActivityListItem';
import journeyGroupStyles from '../../styles/JourneyGroup.styles';

const JourneyGroup = ({ term, data }: { term: string; data: any }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  return (
    <Accordion expanded={isExpanded} onChange={() => setIsExpanded(prev => !prev)}>
      <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
        <IconButton sx={journeyGroupStyles.iconButton}>
          <CaretDownIcon
            sx={{
              ...journeyGroupStyles.caretDownIcon,
              rotate: isExpanded ? '180deg' : '0deg'
            }}
          />
        </IconButton>
        <Typography sx={landingPageStyles.accordionTitle}>{term}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={journeyGroupStyles.accordionDetails}>
        {data?.map((result: any, index: number) => (
          <ActivityListItem
            key={result.activity_id}
            date={result.date}
            quantity={0}
            title={result.term}
            searchType={result.type}
            source={result.source}
            data={result}
            showDivider={index !== data.length - 1}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(JourneyGroup);
