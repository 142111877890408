import React, { Fragment, useContext } from 'react';
//material ui imports
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
//other components
import BarGraphStyles from '../styles/BarGraph.styles';
import CustomBarGraph from '../../CustomComponents/Graphs/CustomBarGraph';
//store
import Store from '../../../store';
import Actions from '../../../store/actions';

const BarGraph = ({ data }) => {
  const classes = BarGraphStyles();
  const { state, dispatch } = useContext(Store);

  const addGraphByYear = async () => {
    await dispatch({ type: Actions.SET_CART_ITEMS, value: state.cartItems + 1 });
    await dispatch({
      type: Actions.SET_REPORT,
      value: {
        id: 'Bar Graph',
        data: {
          title: 'Biologics',
          subtitle: 'Immunogenicity - Approval by Year',
          searchTerm: state.searchTerm ? state.searchTerm.searchTerm : '',
          color: '#6868FA',
          graph: data && data,
          keys: ['count'],
          index: 'year'
        }
      }
    });
    await dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message: 'Successfully added to Report.', color: 'success' }
    });
  };
  return (
    <Fragment>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        className={classes.root}>
        {data ? (
          <>
            <Typography className={classes.headings}>Approval by Year</Typography>
          </>
        ) : (
          <>
            <Skeleton height={16} width={100} className={classes.headings} />
            <Skeleton height={16} width={100} className={classes.add} />
          </>
        )}
      </Box>
      <Box display='flex' className={classes.container}>
        {data && data ? (
          <>
            {data?.length ? (
              <CustomBarGraph
                keys={['count']}
                colors={['#2B43A6']}
                index='year'
                paddings={0.4}
                margin={{ top: 50, right: 0, bottom: 50, left: 60 }}
                data={data && data}
              />
            ) : (
              <Box display='flex' alignItems='center' ml='50%'>
                <Typography> No Data </Typography>
              </Box>
            )}
          </>
        ) : (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='flex-end'
            alignItems='space-between'
            className={classes.loaderBox}>
            <Box
              display='flex'
              justifyContent='space-between'
              flexWrap='wrap-reverse'
              className={classes.barGraphRect}>
              <Skeleton variant='rect' height='90%' width='.2%' />
              <Skeleton variant='rect' height='60%' width='3%' />
              <Skeleton variant='rect' height='80%' width='3%' />
              <Skeleton variant='rect' height='30%' width='3%' />
              <Skeleton variant='rect' height='50%' width='3%' />
              <Skeleton variant='rect' height='50%' width='3%' />
              <Skeleton variant='rect' height='60%' width='3%' />
              <Skeleton variant='rect' height='80%' width='3%' />
              <Skeleton variant='rect' height='30%' width='3%' />
              <Skeleton variant='rect' height='50%' width='3%' />
              <Skeleton variant='rect' height='50%' width='3%' />
            </Box>
            <Skeleton variant='rect' height='.2%' width='100%' />
          </Box>
        )}
      </Box>
    </Fragment>
  );
};

export default BarGraph;
