// eslint-disable-next-line import/prefer-default-export
export const SECTION_LABELS: { [key: string]: string } = {
  adverse_reactions: 'Adverse Reactions',
  clinical_pharmacology: 'Clinical Pharmacology',
  clinical_studies: 'Clinical Studies',
  contraindications: 'Contraindications',
  description: 'Description',
  dosage_and_administration: 'Dosage and Administration',
  dosage_forms_and_strengths: 'Dosage forms and Strengths',
  drug_abuse_and_dependence: 'Drug abuse and Dependence',
  drug_interactions: 'Drug Interactions',
  how_supplied_storage_and_handling: 'How Supplied Storage and Handling',
  indications_and_usage: 'Indications and Usage',
  non_clinical_toxicology: 'Non-clinical Toxicology',
  overdosage: 'Overdose',
  patient_counseling_information: 'Patient Counseling Information',
  references: 'References',
  use_in_specific_populations: 'Use in Specific Populations',
  warnings_and_precautions: 'Warnings and Precautions',
  how_supplied: 'How Supplied',
  precautions: 'Precautions',
  warnings: 'Warnings',
  annex_ii: 'Annex II',
  annex_iii: 'Annex III',
  date_of_first_authorisation_renewal_of_the_authorisation:
    'Date of First Authorisation/Renewal of the Authorisation',
  date_of_revision_of_the_text: 'Date of Revision of the Text',
  effects_on_ability_to_drive_and_use_machines: 'Effects on ability to drive and use machines',
  fertility_pregnancy_and_lactation: 'Fertility, pregnancy and lactation',
  interaction_with_other_medicinal_products_and_other_forms_of_in:
    'Interaction with other medicinal products and other forms of interaction',
  marketing_authorisation_holder: 'Marketing Authorisation Holder',
  marketing_authorisation_number: 'Marketing Authorisation Number(s)',
  name_of_the_medicinal_product: 'Name of the Medicinal Product',
  overdose: 'Overdose',
  pharmaceutical_form: 'Pharmaceutical Form',
  pharmaceutical_particulars: 'Pharmaceutical Particulars',
  pharmacodynamic_properties: 'Pharmacological Properties',
  pharmacokinetic_properties: 'Pharmacokinetic properties',
  posology_and_method_of_administration: 'Posology and method of administration',
  preclinical_safety_data: 'Preclinicalsafety data',
  qualitative_and_quantitative_composition: 'Qualitative and Quantitative Composition',
  special_warnings_and_precautions_for_use: 'Special warnings and precautions for use',
  therapeutic_indications: 'Therapeutic Indications',
  undesirable_effects: 'Undesirable effects',
  indications: 'Indications',
  serious_warnings_and_precautions_box: 'Serious Warnings and Precautions Box',
  dosage_forms_strengths_composition_and_packaging:
    'Dosage Forms Strengths Composition and Packaging',
  storage_stability_and_disposal: 'Storage Stability and Disposal',
  special_handling_instructions: 'Special Handling Instructions',
  pharmaceutical_information: 'Pharmaceutical Information',
  clinical_trials: 'Clinical Trials',
  microbiology: 'Microbiology',
  nonclinical_toxicology: 'Nonclinical Toxicology',
  supporting_product_monographs: 'Supporting Product Monographs',
  detailed_pharmacology: 'Detailed Pharmacology',
  patient_medication_information: 'Patient Medication Information',
  all_sections: 'All Labels Section',
  composition_and_product_description: 'Composition and Product Description',
  precautions_concerning_indications: 'Precautions Concerning Indications',
  precautions_concerning_dosage_and_administration:
    'Precautions Concerning Dosage and Administration',
  important_precautions: 'Important Precautions',
  precautions_concerning_patients_with_specific_backgrounds:
    'Precautions Concerning Patients With Specific Backgrounds',
  interactions: 'Interactions',
  influence_on_laboratory_tests: 'Influence on Laboratory Tests',
  precautions_concerning_use: 'Precautions Concerning Use',
  other_precautions: 'Other Precautions',
  pharmacokinetics: 'Pharmacokinetics',
  pharmacology: 'Pharmacology',
  physicomechanical_properties: 'Physicomechanical Properties',
  precautions_for_handling: 'Precautions for Handling',
  approval_conditions: 'Approval Conditions',
  packaging: 'Packaging',
  reference_request_and_contact_information: 'Reference Request and Contact Information',
  precaution_concerning_health_insurance_benefits:
    'Precaution Concerning Health Insurance Benefits',
  marketing_authorization_holder_etc: 'Marketing Authorization Holder',
  dosimetry: 'Dosimetry',
  instructions_for_preparation_of_radiopharmaceuticals:
    'Instructions for Preparation of Radiopharmaceuticals'
};

export const IGNORE_SECTION_BY_SOURCE: { [key: string]: string[] } = {
  us: ['Limitations of Use']
};

export const SECTION_RENAMING_BY_SOURCE: { [key: string]: { [key: string]: string } } = {
  us: {
    'Indications and Usage (Primary)': 'Indications and Usage'
  }
};

export const getTotalPageCountFromIFrame = (iframeId: string) => {
  const iframe = document.getElementById(iframeId) as HTMLIFrameElement;
  const doc = iframe?.contentDocument;
  return doc?.querySelectorAll('.page').length;
};

// This is a pure JavaScript function that simulates progress and calls the callback with updated progress.
export const simulateProgress = ({
  currentStep,
  fileType,
  fileCount,
  filePageCountList
}: {
  currentStep: number;
  fileType: string;
  fileCount: number;
  filePageCountList?: number[];
}): { message: string; progress: number; stepScale: number } => {
  const totalSteps = 95; // Overall progress goes from 0 to 100
  const totalPageCount = filePageCountList
    ? filePageCountList.reduce((acc, pageCount) => acc + pageCount, 0)
    : fileCount; // Total number of pages across all files

  let downloadPhaseLimit = 0;
  let templatePhaseLimit = 0;
  let mergePhaseLimit = 0;
  let convertPhaseLimit = 0;

  let downloadWeight = 0;
  let templateWeight = 0;
  let mergeWeight = 0;
  let convertWeight = 0;
  let stepScale = 1; // Default step scaling factor

  // Adjust limits and weights based on file type
  if (fileType === 'docx') {
    downloadPhaseLimit = 30; // 30% for downloading
    downloadWeight = 30;
    templatePhaseLimit = 35; // 5% for generating template
    templateWeight = 5;
    mergePhaseLimit = 40; // 5% for merging
    mergeWeight = 5;
    convertPhaseLimit = 100; // 60% for converting to DOCX
    convertWeight = 60;
    stepScale = 0.5; // DOCX takes the most time (slowest)
  } else if (fileType === 'pdf') {
    downloadPhaseLimit = 70; // 70% for downloading
    downloadWeight = 70;
    templatePhaseLimit = 90; // 20% for generating template
    templateWeight = 10;
    mergePhaseLimit = 100; // 10% for merging
    mergeWeight = 10;
    stepScale = 1.5; // PDF takes moderate time
  } else if (fileType === 'html') {
    downloadPhaseLimit = 70; // 70% for downloading
    downloadWeight = 70;
    templatePhaseLimit = 80; // 10% for generating template
    templateWeight = 10;
    mergePhaseLimit = 100; // 20% for processing
    mergeWeight = 20;
    stepScale = 3; // HTML is fastest
  }

  const stepsPerFileDownload = downloadPhaseLimit / fileCount;
  const stepsPerFileTemplate = (templatePhaseLimit - downloadPhaseLimit) / fileCount;
  const stepsPerFileMerge = (mergePhaseLimit - templatePhaseLimit) / fileCount;
  const stepsPerFileConvert = (convertPhaseLimit - mergePhaseLimit) / fileCount;

  let message = '';
  let cumulativeProgress = 0;

  // Calculate page weight for the current file
  let currentFileIndex = Math.min(Math.floor(currentStep / stepsPerFileDownload) + 1, fileCount);
  const filePageCount = filePageCountList ? filePageCountList[currentFileIndex - 1] : 1;
  const pageWeight = filePageCount / totalPageCount || 1; // Avoid division by zero

  // Handle Download phase for all file types
  if (currentStep <= downloadPhaseLimit) {
    currentFileIndex = Math.min(
      Math.floor((currentStep - 1) / stepsPerFileDownload) + 1,
      fileCount
    );
    const downloadProgress = (currentStep % stepsPerFileDownload) / stepsPerFileDownload;
    message = `Downloading ${currentFileIndex}/${fileCount}`;
    cumulativeProgress =
      (currentFileIndex - 1) * (downloadWeight / fileCount) +
      downloadProgress * (downloadWeight / fileCount);
  }
  // Handle Template Generation phase, weighted by pages
  else if (currentStep > downloadPhaseLimit && currentStep <= templatePhaseLimit) {
    currentFileIndex = Math.min(
      Math.floor((currentStep - downloadPhaseLimit - 1) / stepsPerFileTemplate) + 1,
      fileCount
    );
    const templateProgress =
      ((currentStep - downloadPhaseLimit) % stepsPerFileTemplate) / stepsPerFileTemplate;
    message = `Generating template ${currentFileIndex}/${fileCount}`;
    cumulativeProgress =
      downloadWeight +
      (currentFileIndex - 1) * (templateWeight / fileCount) +
      templateProgress * (templateWeight / fileCount) * pageWeight;
  }
  // Handle Merging phase, weighted by pages
  else if (currentStep > templatePhaseLimit && currentStep <= mergePhaseLimit) {
    currentFileIndex = Math.min(
      Math.floor((currentStep - templatePhaseLimit - 1) / stepsPerFileMerge) + 1,
      fileCount
    );
    const mergeProgress =
      ((currentStep - templatePhaseLimit) % stepsPerFileMerge) / stepsPerFileMerge;
    message = `Merging ${currentFileIndex}/${fileCount}`;
    cumulativeProgress =
      downloadWeight +
      templateWeight +
      (currentFileIndex - 1) * (mergeWeight / fileCount) +
      mergeProgress * (mergeWeight / fileCount) * pageWeight;
  }
  // Handle DOCX conversion phase
  else if (
    fileType === 'docx' &&
    currentStep > mergePhaseLimit &&
    currentStep <= convertPhaseLimit
  ) {
    currentFileIndex = Math.min(
      Math.floor((currentStep - mergePhaseLimit - 1) / stepsPerFileConvert) + 1,
      fileCount
    );
    const convertProgress =
      ((currentStep - mergePhaseLimit) % stepsPerFileConvert) / stepsPerFileConvert;
    message = `Converting to docx ${currentFileIndex}/${fileCount}`;
    cumulativeProgress =
      downloadWeight +
      templateWeight +
      mergeWeight +
      (currentFileIndex - 1) * (convertWeight / fileCount) +
      convertProgress * (convertWeight / fileCount) * pageWeight;
  }

  // Ensure progress doesn't exceed 95% and avoid NaN issues
  if (Number.isNaN(cumulativeProgress)) {
    cumulativeProgress = 1; // Default progress if something went wrong
  }

  cumulativeProgress = Math.min(cumulativeProgress, totalSteps);

  return { message, progress: cumulativeProgress, stepScale };
};

export const sourceMapper: { [key: string]: string } = {
  us: 'us',
  eu: 'eu',
  hpra: 'ie',
  jp: 'jp',
  ca: 'ca',
  hma: 'hma'
};
