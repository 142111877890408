import { createSvgIcon } from '@mui/material';

const CheckboxIndeterminateIcon = createSvgIcon(
  <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_3915_12527)'>
      <path
        d='M11.0833 0.5H2.91667C2.1434 0.500926 1.40208 0.808515 0.855295 1.3553C0.308515 1.90208 0.00092625 2.6434 0 3.41667L0 11.5833C0.00092625 12.3566 0.308515 13.0979 0.855295 13.6447C1.40208 14.1915 2.1434 14.4991 2.91667 14.5H11.0833C11.8566 14.4991 12.5979 14.1915 13.1447 13.6447C13.6915 13.0979 13.9991 12.3566 14 11.5833V3.41667C13.9991 2.6434 13.6915 1.90208 13.1447 1.3553C12.5979 0.808515 11.8566 0.500926 11.0833 0.5V0.5ZM12.8333 11.5833C12.8333 12.0475 12.649 12.4926 12.3208 12.8208C11.9926 13.149 11.5475 13.3333 11.0833 13.3333H2.91667C2.45254 13.3333 2.00742 13.149 1.67923 12.8208C1.35104 12.4926 1.16667 12.0475 1.16667 11.5833V3.41667C1.16667 2.95254 1.35104 2.50742 1.67923 2.17923C2.00742 1.85104 2.45254 1.66667 2.91667 1.66667H11.0833C11.5475 1.66667 11.9926 1.85104 12.3208 2.17923C12.649 2.50742 12.8333 2.95254 12.8333 3.41667V11.5833Z'
        fill='currentColor'
      />
      <path
        d='M5.44431 9.78605L3.15822 7.49997C3.04883 7.39061 2.90049 7.32917 2.74581 7.32917C2.59113 7.32917 2.44278 7.39061 2.33339 7.49997C2.22403 7.60936 2.1626 7.7577 2.1626 7.91238C2.1626 8.06706 2.22403 8.21541 2.33339 8.3248L4.61947 10.6109C4.72781 10.7193 4.85645 10.8052 4.99803 10.8639C5.1396 10.9226 5.29135 10.9528 5.4446 10.9528C5.59785 10.9528 5.74959 10.9226 5.89117 10.8639C6.03275 10.8052 6.16138 10.7193 6.26972 10.6109L11.6667 5.21389C11.7761 5.1045 11.8375 4.95615 11.8375 4.80147C11.8375 4.64679 11.7761 4.49844 11.6667 4.38905C11.5573 4.2797 11.409 4.21826 11.2543 4.21826C11.0996 4.21826 10.9513 4.2797 10.8419 4.38905L5.44431 9.78605Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_3915_12527'>
        <rect width='14' height='14' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>,
  'CheckboxIndeterminate'
);

export default CheckboxIndeterminateIcon;
