import { EMA_GUIDANCE_PIE_CHART } from '../const';
import getTopValuesForPieChart from '../../../utils/getTopValuesForDonutChart';

export const constructPieChartData = (guidanceData: any) => {
  const graphs: any = [];
  EMA_GUIDANCE_PIE_CHART.forEach((item: any) => {
    graphs.push({
      label: item.label,
      children: getTopValuesForPieChart(guidanceData[item.graphItem], item.apiKey, item.count),
      graphItem: item.graphItem
    });
  });
  return graphs;
};

export default constructPieChartData;
