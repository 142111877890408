import { Box, Button, Divider, Typography } from '@mui/material';
import { ReactNode } from 'react';

import Stats from '../../pages/SearchResults/components/Stats';
import styles from './styles';
import { Stat } from '../../pages/SearchResults/types';
import buttonStyles from '../../pages/SearchResults/styles/Buttons.styles';
import { ReportIcon, SubHeaderPipeGreyIcon } from '../../assets/svgs/Icons';

type SubHeaderProps = {
  title: string;
  subTitle: string;
  stats: Stat[];
  handleVisualizeOpen: () => void;
  // eslint-disable-next-line react/require-default-props
  children?: ReactNode;
};

const SubHeader = ({ children, title, subTitle, stats, handleVisualizeOpen }: SubHeaderProps) => {
  return (
    <>
      <Box sx={styles.container}>
        <p>
          <SubHeaderPipeGreyIcon sx={styles.subHeaderPipeGreyIcon} />
        </p>
        <Box sx={styles.titleContainer}>
          <Typography sx={styles.title}>{title}</Typography>
          <Typography sx={styles.subTitle}>{subTitle}</Typography>
        </Box>
      </Box>
      <Box sx={styles.contentContainer}>
        <Stats stats={stats} />
        <Box sx={styles.actionsContainer}>
          {children}
          <Button
            onClick={handleVisualizeOpen}
            id='visualize-button'
            startIcon={<ReportIcon sx={buttonStyles.primaryButtonIcon} />}
            sx={buttonStyles.primaryButton}>
            Visualize & Report
          </Button>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default SubHeader;
