import { createSvgIcon } from '@mui/material';

const VerifyIcon = createSvgIcon(
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_9667_759)'>
      <path
        d='M4.66699 4.66667V3.5H9.33366V4.66667H4.66699ZM2.91699 12.8333C2.76228 12.8333 2.61391 12.7719 2.50451 12.6625C2.39512 12.5531 2.33366 12.4047 2.33366 12.25V1.75C2.33366 1.59529 2.39512 1.44692 2.50451 1.33752C2.61391 1.22812 2.76228 1.16667 2.91699 1.16667H11.667V6.70833C12.1002 6.89063 12.4957 7.15172 12.8337 7.47833V0H2.91699C2.45286 0 2.00774 0.184374 1.67956 0.512563C1.35137 0.840752 1.16699 1.28587 1.16699 1.75V12.25C1.16699 12.7141 1.35137 13.1592 1.67956 13.4874C2.00774 13.8156 2.45286 14 2.91699 14H10.2087C9.6985 13.9999 9.19364 13.8965 8.72454 13.696C8.25544 13.4955 7.83182 13.202 7.47924 12.8333H2.91699ZM13.1504 13.9749L11.6005 12.425C11.1847 12.6904 10.7019 12.832 10.2087 12.8333C9.68948 12.8333 9.18197 12.6794 8.75029 12.3909C8.31861 12.1025 7.98216 11.6925 7.78348 11.2129C7.5848 10.7332 7.53281 10.2054 7.6341 9.69622C7.73538 9.18702 7.98539 8.71929 8.3525 8.35218C8.71962 7.98507 9.18735 7.73506 9.69655 7.63377C10.2057 7.53249 10.7335 7.58447 11.2132 7.78315C11.6929 7.98183 12.1028 8.31828 12.3913 8.74996C12.6797 9.18164 12.8337 9.68916 12.8337 10.2083C12.8323 10.7016 12.6907 11.1844 12.4253 11.6002L13.9752 13.1501L13.1504 13.9749ZM10.2087 11.6667C10.4971 11.6667 10.779 11.5811 11.0189 11.4209C11.2587 11.2607 11.4456 11.0329 11.556 10.7664C11.6664 10.4999 11.6952 10.2067 11.639 9.92383C11.5827 9.64094 11.4438 9.38109 11.2399 9.17714C11.0359 8.97318 10.7761 8.83429 10.4932 8.77802C10.2103 8.72175 9.91706 8.75063 9.65058 8.86101C9.3841 8.97139 9.15634 9.15831 8.9961 9.39813C8.83586 9.63795 8.75033 9.9199 8.75033 10.2083C8.75033 10.5951 8.90397 10.966 9.17746 11.2395C9.45095 11.513 9.82189 11.6667 10.2087 11.6667Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_9667_759'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'VerifyIcon'
);

export default VerifyIcon;
