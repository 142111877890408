const styles = {
  leftArrowIcon: { height: '14px', width: '14px', color: 'white.main' },
  changeLabelsText: {
    fontFamily: 'Mulish',
    fontSize: '14px',
    color: 'white.main',
    fontWeight: 600
  },
  secondaryHeaderWrapper: {
    padding: '8px 24px',
    backgroundColor: 'primary.50'
  },
  downloadIcon: {
    height: '16px',
    width: '16px',
    color: 'primary.700'
  },
  downloadComparisonText: {
    fontFamily: 'Mulish',
    fontSize: '13px',
    color: 'primary.700',
    fontWeight: 600
  },
  reportIcon: {
    height: '16px',
    width: '16px',
    color: 'primary.700'
  },
  generateAiSummaryText: {
    fontFamily: 'Mulish',
    fontSize: '13px',
    color: 'primary.700',
    fontWeight: 600
  },
  swapIcon: {
    height: '24px',
    width: '24px',
    color: 'primary.600'
  },
  compareButton: {
    height: '38px',
    padding: '10px 60px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '32px',
    backgroundColor: 'primary.650',
    color: 'white.main',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 700,
    '&:hover': {
      backgroundColor: 'white.main',
      border: 1,
      borderColor: 'primary.main',
      borderStyle: 'solid',
      color: 'primary.600'
    },
    '&:disabled': {
      backgroundColor: 'gray.550',
      color: 'gray.background'
    }
  },
  markdownWrapper: {
    width: '100%',
    maxHeight: '65vh',
    overflowY: 'auto'
  },
  dialog: {
    height: '98vh',
    width: '100vw',
    mt: '2vh',
    ' & .MuiPaper-root': { borderRadius: '8px 8px 0px 0px' }
  },
  headerWrapper: {
    bgcolor: 'primary.600',
    alignSelf: 'stretch',
    p: '12px 24px',
    alignItems: 'center'
  },
  changeLabelsWrapper: {
    cursor: 'pointer',
    position: 'absolute',
    display: 'flex',
    left: '2%'
  },
  changeProductsWrapper: {
    cursor: 'pointer',
    position: 'absolute',
    display: 'flex',
    left: '2%'
  },
  enhancedComparisonWrapper: { flexGrow: 1, textAlign: 'center', alignSelf: 'center' },
  closeIconButton: { position: 'absolute', display: 'flex', left: '97%', p: 0 },
  closeIcon: { height: '24px', width: '24px', color: 'white.main' },
  ctaSeparator: { borderColor: 'primary.200' },
  ctaWrapper: { cursor: 'pointer' },
  labelListGridWrapper: {
    height: '83vh',
    display: 'flex',
    maxHeight: '83vh',
    p: '20px 24px 0 24px'
  },
  loadingSkeleton: { bgcolor: 'primary.backgroundLight', height: 30 },
  dialogActionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    bgcolor: 'gray.200',
    py: '12px'
  },
  closeOutlinedIcon: { fontSize: 24 },
  autoAwesomeIcon: { color: 'primary.avatar' }
};

export default styles;
