import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import MAPPINGKEY, { IGNORE_FIELDS } from './constant';

interface GridCellExpandProps {
  value: any;
  width: number;
  dataItem: string;
  addNote: boolean;
}

const GridCellExpand = React.memo((props: GridCellExpandProps) => {
  const { width, value, dataItem, addNote } = props;
  const wrapper = React.useRef<HTMLDivElement | null>(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);
  const handleMouseEnter = () => {
    setShowPopper(true);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    const handleKeyDown = (nativeEvent: KeyboardEvent) => {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  const getConcatValue = () => {
    if (value.length !== 0) {
      const concatValue: any = [];
      value.forEach((item: any) => {
        if (dataItem === 'matched_submissions') {
          concatValue.push(`${item?.term?.toUpperCase().substring(0, 3)}-${item?.approval_year}`);
        } else {
          concatValue.push(item[dataItem]);
        }
      });
      return concatValue.join(', ');
    }
    return '-----';
  };

  if (value.length > 0) {
    return (
      <Box
        ref={wrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          alignItems: 'center',
          lineHeight: '24px',
          width: 1,
          height: 1,
          position: 'relative',
          display: 'flex'
        }}>
        <Box
          ref={cellDiv}
          sx={{
            height: 1,
            width,
            display: 'block',
            position: 'absolute',
            top: 0
          }}
        />
        <Box
          ref={cellValue}
          sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          className='cellValue'>
          {getConcatValue()}
        </Box>
        {showPopper && (
          <Popper
            open={showFullCell && anchorEl !== null}
            anchorEl={anchorEl}
            placement='bottom'
            style={{ width }}>
            <Paper
              elevation={1}
              sx={{
                maxHeight: 300,
                overflowY: 'auto',
                minHeight: wrapper.current!.offsetHeight - 3,
                borderRadius: '8px',
                p: 1,
                width: 400,
                ml: -10
              }}>
              {value?.map((item: any, index: number) => (
                <Box
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  sx={{
                    width: '100%',
                    textAlign: 'left',
                    backgroundColor: 'primary.backgroundDark',
                    borderRadius: '8px',
                    p: 1,
                    textTransform: 'capitalize',
                    mb: index === value.length - 1 ? 0 : '8px'
                  }}>
                  {Object.keys(item).length !== 0 &&
                    Object.keys(item)
                      ?.filter((itemValue: any) => !IGNORE_FIELDS.includes(itemValue))
                      .map((key: string) => (
                        <Typography key={key} style={{ fontSize: '14px' }}>
                          <b>{key in MAPPINGKEY ? MAPPINGKEY[key] : key}: </b>
                          {item[key] ? item[key] : 'NA'}
                        </Typography>
                      ))}
                  {addNote && (
                    <Typography style={{ fontSize: '12px', color: 'grey.600' }}>
                      *Disclaimer : Based on publicly available label history
                    </Typography>
                  )}
                </Box>
              ))}
            </Paper>
          </Popper>
        )}
      </Box>
    );
  }
  return <Box />;
});
const DatagridArrayListView = (params: any, dataItem: string, addNote: boolean = false) => {
  return (
    <GridCellExpand
      value={params?.value || []}
      width={params?.colDef.computedWidth}
      dataItem={dataItem}
      addNote={addNote}
    />
  );
};

export default DatagridArrayListView;
