/* eslint-disable global-require */
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Close, Error, Remove, Warning } from '@mui/icons-material';
import React from 'react';
import { closeButton, errorMessageDiv } from '../styles/styles';
import SectionSelector from './SectionSelector';

import { CHAT_RIA_MODES } from '../const';

const StatusBox = ({ chatRiaStatus, lastStatusCheckTime }: any) => {
  switch (chatRiaStatus) {
    case 'High':
      return (
        <>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open
            sx={errorMessageDiv}
            message={
              <Box
                sx={{
                  fontSize: 12
                }}>
                ChatRIA is experiencing extremely high usage. <br />
                Responses to your questions may not be answered. <br />
                Please try again later. <br /> <br />
                Status updated at - {lastStatusCheckTime}
              </Box>
            }
          />

          <Error sx={{ color: 'white.main' }} />
        </>
      );
    case 'Low/Medium':
      return (
        <>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open
            sx={errorMessageDiv}
            message={
              <Box
                sx={{
                  fontSize: 12
                }}>
                ChatRIA is experiencing higher-than-normal usage. <br />
                Responses to your questions may be delayed. <br />
                <br />
                Status updated at - {lastStatusCheckTime}
              </Box>
            }
          />

          <Warning sx={{ color: 'white.main' }} />
        </>
      );

    default:
      return null;
  }
};
const ChatHeader = ({
  reportCount,
  handleMinimizeChat,
  closeSession,
  riaMode,
  handleChangeMode,
  chatRiaStatus,
  lastStatusCheckTime,
  handleReportClick,
  currentSection,
  sections,
  brandName,
  handleApplicationChange,
  selectedApplication,
  applicationNameList,
  handleVerifyAction,
  setOpenApplicationOption,
  openApplicationOption
}: any) => {
  const [openModeSelection, setOpenModeSelection] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleModeChange = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpenModeSelection(true);
  };

  const handleNewMode = (modeValue: string) => {
    if (modeValue === 'chatriamode') {
      handleChangeMode(false);
    } else {
      handleChangeMode(true);
    }
  };

  return (
    <>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{
          p: 2,
          backgroundColor:
            // eslint-disable-next-line no-nested-ternary
            chatRiaStatus === 'OK'
              ? 'primary.600'
              : chatRiaStatus === 'High'
              ? 'error.main'
              : 'orange.main',
          height: '6%'
        }}>
        <Stack direction='row'>
          <StatusBox chatRiaStatus={chatRiaStatus} lastStatusCheckTime={lastStatusCheckTime} />
          <Typography
            display='flex'
            flexDirection='row'
            alignItems='center'
            fontSize={19}
            fontWeight='bold'
            sx={{ color: 'white.main', ml: 1 }}>
            {riaMode ? 'Chat RIA' : 'Chat RIA + mode'}
            <Tooltip title='Click here to change chat RIA mode'>
              <IconButton
                sx={{ ml: '4px', color: 'white.main', width: '16px', height: '16px' }}
                onClick={event => {
                  handleModeChange(event);
                }}>
                <KeyboardArrowDownIcon />
              </IconButton>
            </Tooltip>
          </Typography>
        </Stack>
        <Menu
          anchorEl={anchorEl}
          open={openModeSelection}
          onClose={() => {
            setOpenModeSelection(false);
          }}>
          <MenuItem disabled>
            <Typography sx={{ fontSize: '13px' }}>
              Switch mode to expand the scope of RIA
            </Typography>
          </MenuItem>
          {CHAT_RIA_MODES?.map((mode: any) => (
            <MenuItem
              key={mode.value}
              onClick={() => {
                handleNewMode(mode.value);
                setOpenModeSelection(false);
              }}>
              <Typography sx={{ fontSize: '13px' }}>{mode.label}</Typography>
            </MenuItem>
          ))}
        </Menu>
        <Box>
          {window.location.pathname !== '/pdco' && (
            <Tooltip title='Minimize Chat'>
              <IconButton size='small' sx={{ ...closeButton }} onClick={handleMinimizeChat}>
                <Remove fontSize='small' />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title='Close Chat'>
            <IconButton size='small' sx={{ ...closeButton }} onClick={closeSession}>
              <Close fontSize='small' />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
      <Stack
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        sx={{
          backgroundColor: riaMode ? 'rgba(51,177,135,0.1)' : '#eae6e6',
          height: '4%',
          minHeight: '40px'
        }}>
        <SectionSelector
          currentSection={currentSection}
          sections={sections}
          brandName={brandName}
          applicationNameList={applicationNameList}
          selectedApplication={selectedApplication}
          handleApplicationChange={handleApplicationChange}
          reportCount={reportCount}
          handleReportClick={handleReportClick}
          handleVerifyAction={handleVerifyAction}
          setOpenApplicationOption={setOpenApplicationOption}
          openApplicationOption={openApplicationOption}
        />
      </Stack>
    </>
  );
};

export default React.memo(ChatHeader);
