import { matchPath } from 'react-router';
import {
  ROUTE_ADVANCE_SEARCH,
  ROUTE_CLINICAL_TRIAL,
  ROUTE_CLINICAL_TRIAL_ADVANCED_SEARCH,
  ROUTE_QUICK_SEARCH,
  ROUTE_REGULATORY_360,
  ROUTE_SPECIALITY_DESIGNATION_SEARCH,
  ROUTE_XCOMPARE_LANDING_SEARCH,
  ROUTE_XCOMPARE_SEARCH,
  ROUTE_XCOMPARE_SEARCH_HEADER
} from '../../../const';

const getSearchPathParams = locationPathName => {
  // Try to match the possible urls to get the header input values
  const routePathsToCheck = [
    ROUTE_QUICK_SEARCH,
    ROUTE_CLINICAL_TRIAL,
    ROUTE_ADVANCE_SEARCH,
    ROUTE_REGULATORY_360,
    ROUTE_CLINICAL_TRIAL_ADVANCED_SEARCH,
    ROUTE_SPECIALITY_DESIGNATION_SEARCH,
    ROUTE_XCOMPARE_SEARCH,
    ROUTE_XCOMPARE_LANDING_SEARCH,
    ROUTE_XCOMPARE_SEARCH_HEADER
  ];

  for (let i = 0; i < routePathsToCheck.length; i += 1) {
    const path = routePathsToCheck[i];
    const match = matchPath(locationPathName, {
      path,
      exact: false,
      strict: true
    });
    if (match) {
      return {
        ...match.params,
        ...(path === ROUTE_CLINICAL_TRIAL || path === ROUTE_CLINICAL_TRIAL_ADVANCED_SEARCH
          ? { source: 'clinicalTrials' }
          : {})
      };
    }
  }

  return null;
};

export default getSearchPathParams;
