/* eslint-disable import/prefer-default-export */
import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

import customTitleCase from '../../utils/titleCase';

const fieldType = {
  active_ingredient: 'titlecase',
  formulations: 'customtitlecase',
  indications_and_usage: 'customtitlecase',
  routes: 'customtitlecase',
  therapeutic_area: 'titlecase',
  trade_name: 'uppercase'
};

export const customTransform = (value, field) => {
  // check if value is string before making string operations
  // sometime value can be a react element
  if (typeof value !== 'string') {
    return value;
  }
  if (fieldType[field] === 'capitalize' || value?.toLowerCase() === 'missing at source') {
    return capitalize(value);
  }
  if (fieldType[field] === 'uppercase') {
    return value.toUpperCase();
  }
  if (fieldType[field] === 'titlecase') {
    return startCase(toLower(value));
  }
  if (fieldType[field] === 'customtitlecase') {
    return customTitleCase(toLower(value));
  }
  return value;
};
