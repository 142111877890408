const styles = {
  operatorFieldWrapper: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'end',
    pl: '20px'
  }
};

export default styles;
