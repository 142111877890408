import React, { useCallback, useContext, useEffect, useMemo } from 'react';

// MUI
import Stack from '@mui/material/Stack';

// Custom Components
import { debounce } from 'lodash';
import VivproDatagrid from '../../../components/Datagrid';

// store
import store from '../../../store/HTADocuments';

// constants
import { HTA_COLUMNS, CSV_FILENAME } from '../const';
import { getFileName } from '../../../helpers/getFileName';

// styles
import styles from '../../Guidance/styles/Details.styles';
import actions from '../../../store/HTADocuments/actions';
import getDatagridFilterOption from '../../../utils/getDatagridFilterOption';
import { DATAGRID_OPTION_LIST } from '../../../components/Datagrid/constants';

const HTADetails = ({
  pagination,
  setPagination,
  sort,
  setSort,
  sortFields,
  getHTAGuidanceData,
  isFilterUpdated,
  setIsFilterUpdated,
  getDatawithDataGridFilter,
  dataGridFilter
}: any) => {
  const { HTAState, HTADispatch } = useContext(store);
  const datagridMessage = 'Loading...';

  useEffect(() => {
    if (isFilterUpdated) {
      setIsFilterUpdated(false);
    }
  }, [isFilterUpdated]);

  const fetchData = useCallback(
    (pageModel: any, sortModel: any, gridFilters: any) => {
      getHTAGuidanceData(
        HTAState.availableFilters,
        pageModel.pageSize,
        pageModel.page * pageModel.pageSize,
        sortModel,
        false,
        gridFilters
      );
    },
    [HTAState.availableFilters]
  );

  const renderableTableColumns = useMemo(() => {
    const renderableColumns = [...HTA_COLUMNS];

    return getDatagridFilterOption(renderableColumns, DATAGRID_OPTION_LIST, HTAState);
  }, [HTAState]);

  const handlePageChange = useCallback(
    (pageModel: any) => {
      if (!isFilterUpdated) {
        fetchData(pageModel, sortFields, dataGridFilter);
        setPagination(pageModel);
      }
    },
    [sortFields, HTAState.availableFilters, isFilterUpdated, dataGridFilter]
  );

  const handleSortModelChange = useCallback(
    (newSortingModel: any) => {
      const modifiedList: any = [];

      newSortingModel.forEach((item: any) => {
        modifiedList.push(item);
      });

      if (JSON.stringify(modifiedList) !== JSON.stringify(HTAState.sortFields)) {
        fetchData(pagination, modifiedList, dataGridFilter);
      }
      HTADispatch({
        type: actions.SET_SORT,
        value: modifiedList
      });

      setSort(modifiedList);
    },
    [pagination, sortFields, HTAState.filters, HTAState, actions, dataGridFilter]
  );

  const handleFilterChange = useCallback(
    debounce(newFilterModel => {
      if (newFilterModel?.items?.length > 0) {
        getDatawithDataGridFilter(
          HTAState.availableFilters,
          newFilterModel,
          sortFields,
          pagination.pageSize,
          pagination.page * pagination.pageSize
        );
      } else {
        fetchData(pagination, sortFields, {});
      }
    }, 300),
    [HTAState.availableFilters, sortFields]
  );

  useEffect(() => {
    return () => {
      handleFilterChange.cancel();
    };
  }, [handleFilterChange]);

  return (
    <Stack sx={styles.root}>
      <VivproDatagrid
        rows={HTAState.tableData}
        columnsMapping={renderableTableColumns}
        csvFileName={getFileName(CSV_FILENAME)}
        rowId='guidance_ref'
        loading={HTAState.loading}
        noRowMessage={datagridMessage}
        rowCount={HTAState.totalRecord}
        isPagination
        pagination={pagination}
        setPagination={handlePageChange}
        sortingModel={sort}
        handleSortModelChange={handleSortModelChange}
        isServerSideExport
        isFilterModeServer
        allowFilterPanelWithLogicOperators
        handleFilterModelChange={handleFilterChange}
      />
    </Stack>
  );
};

export default React.memo(HTADetails);
