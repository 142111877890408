import { createSvgIcon } from '@mui/material';

const JapaneseToEnglishIcon = createSvgIcon(
  <svg width='51' height='21' viewBox='0 0 51 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_280_10)'>
      <path
        d='M35.6002 20.6998H31.7002L38.9002 0.299805H43.5002L50.7002 20.6998H46.8002L41.4002 4.4998H41.2002L35.6002 20.6998ZM34.5002 12.9998H45.2002V15.9998H34.5002V12.9998Z'
        fill='currentColor'
      />
      <path
        d='M0.3 20.3998C3.6 20.3998 6.4 18.8998 8.9 16.8998C11.4 18.9998 14.2 20.3998 17.6 20.3998V17.2998C17.6 16.7998 17.5 16.7998 17 16.6998C14.8 16.5998 13 15.4998 11.3 14.1998V14.0998C11.4 13.9998 11.5 13.8998 11.5 13.7998C13.1 11.5998 14.4 9.1998 14.8 6.3998C14.9 5.7998 15.1 5.6998 15.6 5.6998H17.7V2.0998H10.6V-0.200195H7V2.0998H0V5.7998H11.3C10.9 7.9998 10.1 9.9998 8.7 11.7998C7.9 10.3998 7.2 9.0998 6.5 7.8998C5.5 8.3998 4.4 8.8998 3.3 9.3998C4.3 11.0998 5.3 12.6998 6.3 14.2998C4.7 15.5998 2.9 16.5998 0.7 16.6998C0.5 16.6998 0.2 16.8998 0.2 16.9998C0.3 18.1998 0.3 19.2998 0.3 20.3998Z'
        fill='currentColor'
      />
      <path
        d='M31.9778 13.8651C31.9778 14.2415 31.6558 14.5506 31.2638 14.5506H22.4858L23.2698 15.3032C23.5078 15.5317 23.5638 15.8946 23.3818 16.1768C23.1298 16.5531 22.5978 16.58 22.2898 16.2843L20.1898 14.2683C19.9518 14.0399 19.9518 13.6904 20.1898 13.4619L22.2758 11.4594C22.5838 11.1637 23.1158 11.204 23.3678 11.5669C23.5498 11.8357 23.4938 12.212 23.2558 12.4405L22.4718 13.1931H31.2638C31.6698 13.1797 31.9778 13.4888 31.9778 13.8651ZM31.8098 7.17201L29.7238 5.16945C29.4158 4.87377 28.8838 4.91409 28.6318 5.27697C28.4498 5.54577 28.5058 5.92209 28.7438 6.15057L29.5278 6.90321H20.7218C20.3298 6.90321 20.0078 7.21233 20.0078 7.58865C20.0078 7.96497 20.3298 8.27409 20.7218 8.27409H29.5138L28.7298 9.02673C28.4918 9.25521 28.4358 9.61809 28.6178 9.90033C28.8698 10.2767 29.4018 10.3035 29.7098 10.0079L31.7958 8.00529C32.0478 7.76337 32.0478 7.40049 31.8098 7.17201Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_280_10'>
        <rect width='51' height='21' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'JapaneseToEnglish'
);

export default JapaneseToEnglishIcon;
