import { createSvgIcon } from '@mui/material';

const Tentative = createSvgIcon(
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_847_2742)'>
      <path
        d='M13.2987 16.0001H2.70138C2.24068 16.0028 1.78704 15.887 1.38402 15.6637C0.98101 15.4405 0.642168 15.1174 0.400048 14.7255C0.160922 14.3429 0.0241683 13.9053 0.00292048 13.4546C-0.0183273 13.004 0.0766442 12.5555 0.278715 12.1521L5.58672 2.80812C5.81513 2.36263 6.1624 1.98907 6.59006 1.7288C7.01772 1.46853 7.50907 1.33172 8.0097 1.33351C8.51033 1.33531 9.00069 1.47564 9.42647 1.73897C9.85225 2.0023 10.1968 2.37834 10.422 2.82545L15.7034 12.1195C15.9127 12.5258 16.0132 12.9794 15.995 13.4362C15.9767 13.8929 15.8404 14.3371 15.5994 14.7255C15.3573 15.1173 15.0186 15.4404 14.6157 15.6636C14.2128 15.8868 13.7593 16.0027 13.2987 16.0001ZM8.00005 2.66678C7.74499 2.66256 7.49401 2.73111 7.27652 2.86442C7.05903 2.99772 6.88404 3.19025 6.77205 3.41945L6.75471 3.45212L1.45605 12.7781C1.36168 12.9749 1.31925 13.1926 1.33279 13.4105C1.34632 13.6284 1.41538 13.8391 1.53338 14.0228C1.65656 14.2212 1.82867 14.3847 2.0332 14.4975C2.23774 14.6102 2.46782 14.6685 2.70138 14.6668H13.3014C13.5349 14.6685 13.765 14.6102 13.9696 14.4975C14.1741 14.3847 14.3462 14.2212 14.4694 14.0228C14.5894 13.8334 14.6581 13.616 14.6686 13.3921C14.6791 13.1681 14.6311 12.9452 14.5294 12.7455L9.24538 3.45212C9.13515 3.21544 8.95908 3.01552 8.73824 2.87625C8.5174 2.73699 8.26112 2.66427 8.00005 2.66678Z'
        fill='#EA8D4A'
      />
    </g>
    <path
      d='M8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667Z'
      fill='#EA8D4A'
    />
    <path d='M8 7V11' stroke='#EA8D4A' strokeLinecap='round' />
    <defs>
      <clipPath id='clip0_847_2742'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'Tentative'
);

export default Tentative;
