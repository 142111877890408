import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// MATERIAL UI IMPORTS
import { ThemeProvider } from '@mui/material/styles';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import { Steps } from 'intro.js-react';
import themev5 from '../../themev5';

// styles
import MainStyles from './styles/505b2.styles';
// other components
import BusinessIntelligence from './BusinessIntelligence';
import FeatureButton from '../Buttons/FeatureButton';
import SearchBox505b2 from './5052bSearchBox';

// store
import Store from '../../store';
import Actions from '../../store/actions';
// guide tour
import 'intro.js/introjs.css';
import Airplane from '../../Images/svgs/airplane';
// assets
import AriaLogoSmall from '../../assets/svgs/Core/AriaLogoSmall';
// utils
import { savePathnameForAriaBackBtn } from '../../pages/Aria/utils/ariaCache';

const ApprovalPathway = () => {
  const classes = MainStyles();
  // get current url path for aria back btn
  const { pathname } = useLocation();
  const history = useHistory();
  const { dispatch } = useContext(Store);

  // guide tour state
  const [steps] = useState([
    {
      element: '#approvalPathwayStats',
      intro:
        'Provides summary by number of approvals by year, approval time for original application as well as proportion approved by accelerated approval, breakthrough designation, Fast Track and Orphan designation',
      position: 'left',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass'
    }
  ]);
  const [stepsEnabled, setStepsEnabled] = useState(false);
  const [placeholderText, setPlaceholderText] = useState('');

  const onExit = () => {
    setStepsEnabled(false);
  };

  useEffect(() => {
    async function getData() {
      try {
        await dispatch({ type: Actions.SET_MODULE, value: '505b2' });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
    getData();
  }, [dispatch]);

  const updatePlaceholderText = placeholderTex => {
    setPlaceholderText(placeholderTex);
  };

  return (
    <Box className={classes.root}>
      <Steps
        enabled={stepsEnabled}
        steps={steps}
        showProgress
        scrollToElement
        initialStep={0}
        onExit={onExit}
      />
      <Box display='flex' justifyContent='space-between'>
        <Typography className={classes.title}>Business Intelligence</Typography>
        <SearchBox505b2 />
      </Box>
      <Box display='flex' justifyContent='flex-end'>
        {/* ARIA BTN */}
        <ThemeProvider theme={themev5}>
          <FeatureButton
            iconId='bot'
            text='Ask RIA'
            ariaAttributes={{
              source: 'us',
              module: '505b2',
              queryParam: '',
              placeholderText
            }}
            handleClick={() => savePathnameForAriaBackBtn(pathname, history, '/aria/505b2/us')}
            startIcon={<AriaLogoSmall />}
          />
        </ThemeProvider>
      </Box>
      <Box mt={2}>
        <BusinessIntelligence onUpdatePlaceholderText={updatePlaceholderText} />
      </Box>
      <Fab
        aria-label='add'
        sx={{
          position: 'fixed',
          left: 10,
          bottom: 10
        }}
        onClick={() => {
          window.scroll(0, 0);
          setStepsEnabled(true);
        }}>
        <Airplane />
      </Fab>
    </Box>
  );
};

export default ApprovalPathway;
