const textWithBackgroundBase = {
  borderRadius: '4px',
  padding: '1px 4px',
  fontSize: '11px',
  fontWeight: 600,
  textTransform: 'uppercase',
  textAlign: 'left',
  display: 'inline-block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  verticalAlign: 'middle'
};
const styles = {
  listItem: {
    py: '0px'
  },
  listItemFlexBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    py: '8px'
    // pt: '8px'
  },
  indexBox: {
    width: '28px',
    mr: '12px'
  },
  indexText: {
    fontSize: '12px',
    fontWeight: 400,
    color: 'gray.700'
  },
  timeText: {
    fontSize: '12px',
    fontWeight: 400,
    color: 'gray.700',
    mr: '4px',
    width: '54px'
  },
  quantityText: { fontSize: '11px', fontWeight: 600, color: 'gray.500', mr: '12px', width: '20px' },
  titleText: {
    display: 'flex',
    fontSize: '14px',
    fontWeight: 600,
    color: 'primary.800',
    flex: 1,
    flexGrow: 1,
    mr: '72px',
    '&:hover': {
      textDecorationLine: 'underline'
    }
  },
  sourceBackgroundText: {
    width: '60px',
    mr: '8px'
  },
  backgroundText: {
    width: '100px',
    mr: '24px'
  },
  sourceTextWithBackground: {
    ...textWithBackgroundBase,
    maxWidth: '60px',
    backgroundColor: 'primary.50',
    color: 'primary.900'
  },
  moduleTextWithBackground: {
    ...textWithBackgroundBase,
    maxWidth: '100px',
    backgroundColor: 'primary.50',
    color: 'primary.900'
  },
  textWithBackground: {
    ...textWithBackgroundBase,
    maxWidth: '100px',
    backgroundColor: 'secondary.75',
    color: 'secondary.900'
  },
  iconBox: {
    height: '32px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    mr: '12px'
  },
  favoriteIconBox: {
    mr: '12px',
    p: '0px',
    '& svg': {
      width: '14px !important',
      height: '14px !important'
    }
  },
  filterIcon: {
    height: '12px',
    color: 'gray.650'
  },
  threeDotIcon: { color: 'gray.700' },
  divider: { backgroundColor: 'gray.100', opacity: 0.3 },
  timePopover: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 16px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '10px',
    borderRadius: '4px',
    backgroundColor: 'gray.750',
    color: 'white.main'
  },
  timeContent: {
    maxHeight: '400px',
    height: 'fit-content',
    overflow: 'auto',
    width: '100%'
  }
};

export default styles;
