import { MouseEvent, useContext, useMemo, useState } from 'react';
import { Badge, Button } from '@mui/material';
import { ArrowDownIcon, GroupIcon, SortIconVariant } from '../../../assets/svgs/Icons';
import buttonStyles from '../styles/Buttons.styles';
import { RoundedMenu } from '../../../components/ui/Menu';
import ResultsStore from '../../../store/SearchResults';
import RESULT_VIEW_TYPES from './constants';
import HideUnHideApplications from '../Buttons/HideUnHideApplications';
import DropDownButton from './DropDownButton';
import reviewMenuStyles from '../styles/ReviewMenu.styles';

const ApplicationsReviewMenu = ({
  sortOptions,
  onSortOptionChange,
  showGroupBy,
  groupByOptions,
  onGroupByOptionChange
}: {
  sortOptions: any;
  onSortOptionChange: Function;
  showGroupBy: boolean;
  groupByOptions?: any;
  onGroupByOptionChange?: Function;
}) => {
  const { resultsState } = useContext(ResultsStore);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event?.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const selectedSort = useMemo(() => sortOptions.find((opt: any) => opt.selected), [sortOptions]);

  const isAnyOptionSelected = useMemo(() => {
    return (
      (showGroupBy && resultsState.isGroupByFilterApplied) ||
      resultsState.hiddenApplications.length > 0 ||
      (selectedSort?.selected && selectedSort?.id !== 'default')
    );
  }, [
    showGroupBy,
    resultsState.isGroupByFilterApplied,
    resultsState.hiddenApplications,
    selectedSort,
    resultsState?.filters?.mesh_tree_numbers,
    resultsState?.filters?.atc_code?.length
  ]);

  return (
    <>
      <Button
        endIcon={<ArrowDownIcon sx={buttonStyles.buttonIcon} />}
        sx={buttonStyles.button}
        onClick={handleOpenMenu}>
        <Badge
          color='primary'
          variant='dot'
          invisible={!isAnyOptionSelected}
          sx={reviewMenuStyles.reviewButtonBadgeDot}>
          Sort & Group
        </Badge>
      </Button>
      <RoundedMenu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        sx={reviewMenuStyles.roundedMenu}>
        <DropDownButton
          label='Sort by'
          type='action'
          options={sortOptions}
          onOptionSelect={onSortOptionChange}
          tooltip='Select to sort items'
          isMenuItem
          MenuIcon={SortIconVariant}
          showBadge={selectedSort?.selected && selectedSort?.id !== 'default'}
          handleParentMenuClose={handleCloseMenu}
        />
        {showGroupBy && (
          <DropDownButton
            label='Group by'
            type='action'
            options={groupByOptions}
            onOptionSelect={onGroupByOptionChange}
            highlightOnSelect
            tooltip='Select to group items'
            isMenuItem
            MenuIcon={GroupIcon}
            showBadge={resultsState.isGroupByFilterApplied}
            handleParentMenuClose={handleCloseMenu}
          />
        )}

        {resultsState.viewType === RESULT_VIEW_TYPES.APPLICATION && (
          <HideUnHideApplications handleParentMenuClose={handleCloseMenu} />
        )}
      </RoundedMenu>
    </>
  );
};

ApplicationsReviewMenu.defaultProps = {
  groupByOptions: [],
  onGroupByOptionChange: () => {}
};

export default ApplicationsReviewMenu;
