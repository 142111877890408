import React, { useEffect, useState, useMemo } from 'react';
import { Collapse, Divider, List, ListItemButton, ListItemText, Box } from '@mui/material';
import { styles } from '../../styles/MeetingList';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
interface Meeting {
  type: string;
  category: string;
}

interface MeetingData {
  [year: string]: {
    [month: string]: Meeting[];
  };
}

interface MeetingListProps {
  meetingData: MeetingData;
  setSelectedMeeting: (meeting: { month: string; year: string; index: number }) => void;
  setMenuAnchorEl: (el: null) => void;
  setHighlightedMeeting: (meeting: { month: string; year: string; idx: number }) => void;
}

interface MonthItemProps {
  month: string;
  year: string;
  open: boolean;
  styles: any;
  handleClick: () => void;
  meetings: Meeting[];
  handleMeetingClick: (month: string, year: string, idx: number) => void;
}

interface MeetingItemProps {
  meeting: Meeting;
  styles: any;
  onClick: () => void;
}

const MeetingItem: React.FC<MeetingItemProps> = ({ meeting, styles, onClick }) => (
  <Box sx={styles.meetingBoxItem}>
    <ListItemButton sx={styles.meetingListItemButton} onClick={onClick} disableGutters>
      <ListItemText
        disableTypography
        primary={`${meeting.type} : ${meeting.category}`}
        sx={styles.meetingText}
      />
    </ListItemButton>
  </Box>
);

const MonthItem: React.FC<MonthItemProps> = ({
  month,
  year,
  open,
  styles,
  handleClick,
  meetings,
  handleMeetingClick
}) => (
  <>
    <ListItemButton onClick={handleClick} sx={styles.listItemButton}>
      <ListItemText disableTypography primary={`${month} ${year}`} sx={styles.listItemText} />
      {open ? (
        <ArrowDropDownIcon sx={styles.dropDownIcon} />
      ) : (
        <ArrowDropUpIcon sx={styles.dropDownIcon} />
      )}
    </ListItemButton>
    <Collapse in={open} timeout='auto' unmountOnExit>
      <List component='div' disablePadding>
        <Box sx={styles.collapseBox}>
          <Divider orientation='vertical' flexItem sx={styles.verticalDivider} />
          <Box sx={styles.meetingBox}>
            {meetings.map((meeting, idx) => (
              <MeetingItem
                key={idx}
                meeting={meeting}
                styles={styles}
                onClick={() => handleMeetingClick(month, year, idx)}
              />
            ))}
          </Box>
        </Box>
      </List>
    </Collapse>
  </>
);

const MeetingList: React.FC<MeetingListProps> = ({
  meetingData,
  setSelectedMeeting,
  setMenuAnchorEl,
  setHighlightedMeeting
}) => {
  const [openItems, setOpenItems] = useState<{ [key: string]: boolean }>({});

  const initialOpenItems = useMemo(() => {
    const computedItems: { [key: string]: boolean } = {};
    Object.entries(meetingData).forEach(([year, months]) => {
      Object.entries(months).forEach(([month]) => {
        computedItems[`${month}-${year}`] = true;
      });
    });
    return computedItems;
  }, [meetingData]);

  useEffect(() => {
    setOpenItems(initialOpenItems);
  }, [initialOpenItems]);

  const handleClick = (key: string) => {
    setOpenItems(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  const handleMeetingClick = (month: string, year: string, index: number) => {
    setSelectedMeeting({ month, year, index });
    setMenuAnchorEl(null);
    setHighlightedMeeting({ month, year, idx: index });
  };

  return (
    <List sx={styles.list}>
      {Object.entries(meetingData).map(([year, months]) =>
        Object.entries(months).map(([month, meetings]) => {
          const key = `${month}-${year}`;
          return (
            <div key={key}>
              <MonthItem
                month={month}
                year={year}
                key={key}
                open={openItems[key]}
                styles={styles}
                handleClick={() => handleClick(key)}
                meetings={meetings}
                handleMeetingClick={handleMeetingClick}
              />
            </div>
          );
        })
      )}
    </List>
  );
};

export default React.memo(MeetingList);
