import React from 'react';

const PlusIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='10.792'
      height='10.792'
      viewBox='0 0 14.792 14.792'>
      <g id='plus' opacity='0.5'>
        <g id='Group_254' data-name='Group 254'>
          <path
            id='Path_147'
            fill='#3AB09E'
            data-name='Path 147'
            d='M14.214,6.818H7.974V.578a.578.578,0,1,0-1.156,0v6.24H.578a.578.578,0,1,0,0,1.156h6.24v6.24a.578.578,0,0,0,1.156,0V7.974h6.24a.578.578,0,1,0,0-1.156Z'
          />
        </g>
      </g>
    </svg>
  );
};

export default PlusIcon;
