import makeRequest from '../client';

/**
 *
 * Function gets all data for guidance module
 */
export const postHTAInsights = filters => makeRequest('/hta/insights', 'post', filters);
export const postHTAGuidanceDocs = (payload, limit, offset) =>
  makeRequest(`/hta/nice_documents?limit=${limit}&offset=${offset}`, 'post', payload);
export const getHTADocs = id => makeRequest(`/hta/nice_category_docs/${id}`, 'GET');
export const getAllHTADocuments = payload => makeRequest('/hta/nice_documents', 'POST', payload);
