import React, { useState, useRef } from 'react';
import { Box, Chip } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ThinArrowDownIcon } from '../../../assets/svgs/Icons';
import styles from '../styles/YearRange.styles';

type YearRangeProps = {
  year: string;
  handleDateSelection: any;
  minDate: string;
  maxDate: string;
  selectedYear: string;
  setSelectedYear: any;
};

const YearRange = ({
  year,
  handleDateSelection,
  minDate,
  maxDate,
  selectedYear,
  setSelectedYear
}: YearRangeProps) => {
  const [date, setDate] = useState<Date>(year ? new Date(parseInt(year, 10), 0, 1) : new Date());
  const customRef = useRef(null);

  const onChangeHandler = (selectedDate: any) => {
    if (selectedDate) {
      setDate(selectedDate);
    }
    handleDateSelection(new Date(selectedDate).getFullYear());
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        // this need to be done in order to avoid displaying one year less
        minDate={new Date(parseInt(minDate, 10), 0, 1)}
        maxDate={new Date(parseInt(maxDate, 10), 0, 1)}
        onChange={value => onChangeHandler(value)}
        onClose={() => {
          setSelectedYear('');
        }}
        views={['year']}
        open={selectedYear === year}
        value={date}
        PopperProps={{ anchorEl: customRef.current, placement: 'bottom-start' }}
        renderInput={({ ref, inputProps, disabled, onChange }) => (
          <Box ref={ref}>
            <input
              style={{ display: 'none' }}
              value={date.toISOString()}
              onChange={onChange}
              disabled={disabled}
              ref={customRef}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...inputProps}
            />
            <Chip
              ref={customRef}
              label={year}
              size='small'
              deleteIcon={<ThinArrowDownIcon />}
              onClick={() => {
                setSelectedYear(year);
              }}
              onDelete={() => {
                setSelectedYear(year);
              }}
              sx={styles.yearLabel}
            />
          </Box>
        )}
      />
    </LocalizationProvider>
  );
};

export default React.memo(YearRange);
