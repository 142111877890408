import React, { useMemo, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import GroupIcon from '@mui/icons-material/Group';
import CancelIcon from '@mui/icons-material/Cancel';

import { isArray } from 'lodash';
import VerticalTimeline from './VerticalTimeline';

import styles from './styles';
import CustomTimelinePropsInterface from '../../../interfaces/CustomTimelinePropsInterface';

interface RecruitmentStatusHistory {
  from: string;
  to: string;
  submitted_date: string;
  version: string;
  to_formatted: string;
}

const RecruitmentStatusComparison: React.FC<CustomTimelinePropsInterface> = ({
  dataItem,
  column
}: any) => {
  const [openModal, setOpenModal] = useState(false);
  const recruitmentStatusData = dataItem[column?.name] || {};
  const { history = [], last_version_changed: lastVersionChanged = '' } = recruitmentStatusData;

  const historyMapping = useMemo(() => {
    if (isArray(history)) {
      const historyLength = history.length;
      const data = history.map((item: RecruitmentStatusHistory, index) => {
        const {
          from: oldStatus = '',
          submitted_date: submittedDate = '',
          version = '',
          to_formatted: currentStatusColorScheme = ''
        } = item;
        let { to: currentStatus = '' } = item;

        if (currentStatus === 'None' && index + 1 < historyLength) {
          const { from = '' } = history[index + 1];
          currentStatus = from;
        }
        return { oldStatus, currentStatus, submittedDate, version, currentStatusColorScheme };
      });
      return data;
    }
    return [];
  }, [history]);

  return (
    <Box sx={styles.iconContainers}>
      <Tooltip
        title={
          history.length
            ? 'View recruitment Status changes'
            : (lastVersionChanged &&
                `Recruitment status has not changed since version ${lastVersionChanged}.`) ||
              `Recruitment status is not added.`
        }
      >
        {/* 
// @ts-ignore */}
        <center>
          <IconButton
            onClick={() => setOpenModal(true)}
            disabled={!history.length}
            sx={{ color: 'green.accept' }}
          >
            <GroupIcon sx={{ fontSize: '32px' }} />
          </IconButton>
          {/* 
// @ts-ignore */}
        </center>
      </Tooltip>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>
          <Grid display='flex' justifyContent='space-between' alignItems='flex-start'>
            <Grid sx={styles.dialogHeading} display='flex' justifyContent='center'>
              Recruitment Status Timeline
            </Grid>
            <Box>
              <IconButton
                aria-label='delete'
                onClick={() => setOpenModal(false)}
                sx={{ fontSize: '30px' }}
              >
                <CancelIcon fontSize='inherit' />
              </IconButton>
            </Box>
          </Grid>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <Box>
            <VerticalTimeline history={historyMapping} />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default RecruitmentStatusComparison;
