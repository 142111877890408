import React, { useContext } from 'react';
// mui
import { Dialog, DialogTitle, DialogContent, Stack, Box, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
// third party
import capitalize from 'lodash/capitalize';
// store
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';
// component
import PDFPreview from '../../../components/PdfPreview/PDFPreview';
// utils
import { getDocumentSourceName } from '../../SearchResults/utils/documentResultsUtils';
// styles
import styles from '../styles/Meeting.styles';

const PDFViewerModal = ({
  openPdf,
  setOpenPdf,
  setResourceDialog,
  meetingId,
  handleClosePDF,
  title,
  documentCategory,
  PDFData,
  setPDFData
}: any) => {
  const { dispatch } = useContext(GlobalStore) as any;

  const handleChatRia = ({ pdfUrl = '' }: any) => {
    const mappedSourceName = getDocumentSourceName('PDCO');
    dispatch({
      type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM,
      value: 'document'
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: mappedSourceName
    });
    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: {
        blob: pdfUrl,
        url: pdfUrl,
        item: {
          'original-file-name': title,
          'meeting-year': meetingId,
          'category-bucket': capitalize(documentCategory)
        },
        source: mappedSourceName,
        triggerReopenChatRia: false
      }
    });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
    setPDFData('');
    setOpenPdf(false);
    setResourceDialog(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth='lg'
      onClose={() => {
        handleClosePDF();
      }}
      aria-labelledby='customized-dialog-title'
      open={openPdf}>
      <DialogTitle>
        <Stack direction='row' justifyContent='flex-end'>
          <Stack>
            <IconButton
              aria-label='delete'
              onClick={() => {
                handleClosePDF();
              }}>
              <CancelIcon />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {PDFData ? (
          <Box sx={styles.pdfDataBox}>
            <PDFPreview
              open={Boolean(PDFData)}
              pdfUrl={PDFData}
              onClose={() => handleClosePDF()}
              handleChatRia={handleChatRia}
              title={title}
            />
          </Box>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(PDFViewerModal);
