const encodeObjectToBase64 = (objectToEncode: object): string | null => {
  try {
    const jsonString = JSON.stringify(objectToEncode);
    return btoa(encodeURIComponent(jsonString));
  } catch (error) {
    return null;
  }
};

const decodeBase64ToObject = (base64String: string): object | null => {
  try {
    const decodedString = decodeURIComponent(atob(base64String));
    return JSON.parse(decodedString);
  } catch (error) {
    return null;
  }
};

const encodeStringForURL = (stringToEncode: string): string => {
  try {
    return encodeURIComponent(stringToEncode?.replace(/%/g, '%25'));
  } catch (error) {
    return stringToEncode;
  }
};

const decodeStringFromURL = (stringToDecode: string): string => {
  try {
    return decodeURIComponent(stringToDecode).replace(/%25/g, '%');
  } catch (error) {
    return stringToDecode;
  }
};

export { encodeObjectToBase64, decodeBase64ToObject, encodeStringForURL, decodeStringFromURL };
