import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  graphContainer: {
    width: '100%',
    // height: '70vh',
    [theme.breakpoints.up('xl')]: {
      // height: '50vh',
    }
  },
  loaderBox: {
    width: '90%',
    height: '90%'
    // border:'1px solid red'
  },
  barGraphRect: {
    width: '100%',
    height: '100%'
  },
  graphBox: {
    height: '40vh',
    backgroundColor: theme.palette.white,
    padding: '1rem',
    borderRadius: '16px',
    [theme.breakpoints.up('xl')]: {
      height: '50vh'
    },
    [theme.breakpoints.down('md')]: {
      height: '40vh'
    },
    marginTop: '1vh'
  },
  graph: {
    width: '100%'
  },
  headings: {
    color: theme.palette.biologics.textPrimary,
    fontWeight: 'bold',
    fontSize: theme.spacing(2)
  },
  add: {
    fontSize: theme.spacing(1.87),
    color: theme.palette.navbar.button,
    cursor: 'pointer'
  }
}));
