import actions from './actions';

interface ActionProps {
  type: string;
  value: any;
}

export interface EUCTRPediatricsContextInterface {
  pagination: any;
  euctrPediatricsStats: any;
  tableData: any[];
  errors: any[];
  filters: any[];
  availableFilters: any;
  isFiltersApplied: boolean;
  loading: boolean;
  totalRecord: number;
  sortFields: any[];
}

const reducers = (state: EUCTRPediatricsContextInterface, action: ActionProps) => {
  switch (action.type) {
    case actions.SET_EUCTR_PEDIATRICS_TABLE: {
      return {
        ...state,
        tableData: action.value
      };
    }
    case actions.SET_EUCTR_PEDIATRICS_STATS: {
      return {
        ...state,
        euctrPediatricsStats: action.value
      };
    }
    case actions.SET_EUCTR_PEDIATRICS_ERRORS: {
      return {
        ...state,
        errors: action.value
      };
    }
    case actions.SET_FILTERS: {
      return {
        ...state,
        filters: action.value
      };
    }
    case actions.SET_IS_FILTERS_APPLIED: {
      return {
        ...state,
        isFiltersApplied: action.value
      };
    }
    case actions.SET_AVAILABLE_FILTERS: {
      return {
        ...state,
        availableFilters: action.value
      };
    }
    case actions.RESET_FILTERS: {
      return {
        ...state,
        filters: []
      };
    }
    case actions.SET_SEARCH: {
      return {
        ...state,
        search: action.value
      };
    }
    case actions.RESET_SEARCH: {
      return {
        ...state,
        search: action.value
      };
    }
    case actions.SET_LOADING: {
      return {
        ...state,
        loading: action.value
      };
    }
    case actions.SET_TOTAL_COUNT: {
      return {
        ...state,
        totalRecord: action.value
      };
    }
    case actions.SET_SORT: {
      return {
        ...state,
        sortFields: action.value
      };
    }
    case actions.SET_PAGINATION: {
      return {
        ...state,
        pagination: action.value
      };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
