const showLatestLabel = (data: any, source: string) => {
  const excludeSources = ['us-devices', 'ct', 'euctr'];

  if ((source === 'us' && data?.application_type === 'EUA') || excludeSources.includes(source)) {
    return false;
  }
  return true;
};

export default showLatestLabel;
