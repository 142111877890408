import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import Plot from 'react-plotly.js';

// mui5
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Button, Stack } from '@mui/material';

// CDP Reused Components
import styles from '../../../pages/CDP/styles/CDPMain';
import ResultsStore from '../../../store/SearchResults';
import { getSelectedBarMarker } from '../../../pages/CDP/utils';
import { prepareDelayGraphData } from './utils';
import Loading from '../../../pages/CDP/components/Loading';

const DelayTimeline: React.FC<any> = ({
  nctId,
  setNctId,
  showDocs,
  setShowDocs,
  setTrialsCount,
  setWarningMessage,
  onEntitySelectionUpdate = null
}) => {
  const { resultsState } = useContext(ResultsStore);
  const [donePageDataLoadingAnimation, setDonePageDataLoadingAnimation] = useState(false);

  const graphRef = useRef();
  const ctTimelineSelectedSort = 'default';
  const handleDoneAnimation = useCallback(() => {
    setDonePageDataLoadingAnimation(true);
  }, []);
  const [selectedGraphEntityData, setGraphEntityData] = useState<{
    [key: string]: string | number;
  } | null>(null);

  const addRef = useCallback(
    (ref: any) => {
      graphRef.current = ref;
    },
    [showDocs]
  );

  const { barGraphData, nctIds, minDelayDays, maxDelayDays } = useMemo(() => {
    const result = prepareDelayGraphData(
      resultsState?.trialsData?.trials_data ?? [],
      ctTimelineSelectedSort
    );
    if (resultsState?.trialsData?.nctIds?.length > 50) {
      setWarningMessage(
        'For optimal chart experience, please narrow results to less than 30 trials'
      );
    }
    setTrialsCount(resultsState?.trialsData?.nctIds?.length);
    return result;
  }, [resultsState?.trialsData?.trials_data, ctTimelineSelectedSort]);

  const handleHorizontalBarClick = useCallback(
    (data: any) => {
      if (data.points && data.points[0]) {
        const prevSelectedBar = barGraphData.find(
          barData => barData?.nctId === selectedGraphEntityData?.nctId
        );

        if (prevSelectedBar) {
          delete prevSelectedBar.marker.line;
        }

        const selectedBar = barGraphData.find(
          ({ name }: { name: string }) => name === data.points[0].label
        );
        if (selectedBar) {
          selectedBar.marker = { ...(selectedBar.marker || {}), ...getSelectedBarMarker() };
        }
        const ctData = {
          nctId: data.points[0].customdata.nctId,
          startDate: data.points[0].customdata.startDate,
          studyFirstSubmittedDate: data.points[0].customdata.studyFirstSubmittedDate,
          lastUpdatedYear: data.points[0].customdata.lastUpdatedYear,
          studyCompletion: data.points[0].customdata.studyCompletion,
          sampleSize: data.points[0].customdata.sampleSize,
          phase: data.points[0].customdata.phase,
          officialTitle: data.points[0].customdata.officialTitle,
          primaryCompletionDate: data.points[0].customdata.primaryCompletionDate
        };
        setGraphEntityData(ctData);
        if (nctId) {
          setNctId(ctData.nctId);
        } else {
          setShowDocs(false);
        }
      }
    },
    [nctId, barGraphData, selectedGraphEntityData?.nctId, selectedGraphEntityData?.submission]
  );

  const handleViewDocsClick = useCallback(() => {
    if (selectedGraphEntityData?.nctId) {
      setNctId(selectedGraphEntityData?.nctId as string);
    }

    setShowDocs(true);
  }, [selectedGraphEntityData]);

  const handleViewDocsClose = useCallback(() => {
    const prevSelectedBar = barGraphData.find(
      ({ name }: { name: string }) => name === selectedGraphEntityData?.nctId
    );
    if (prevSelectedBar) {
      delete prevSelectedBar.marker.line;
    }
    setGraphEntityData(null);
    setNctId('');
    setShowDocs(false);
  }, [barGraphData, selectedGraphEntityData?.nctId, selectedGraphEntityData?.submission]);

  useEffect(() => {
    onEntitySelectionUpdate?.(selectedGraphEntityData);
  }, [selectedGraphEntityData]);

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      height='100%'
      overflow='hidden'
      position='relative'>
      {resultsState?.trialLoading ?? resultsState.loading ? (
        <Box justifySelf='center' alignSelf='center' height='600px'>
          <Loading
            loading={resultsState?.trialLoading ?? resultsState.loading}
            onDoneAnimation={handleDoneAnimation}
            fda={false}
          />
        </Box>
      ) : (
        <Box height='100%' position='relative'>
          <Plot
            key={`CTTimelineChangeGraphs_${ctTimelineSelectedSort}`}
            style={{ width: '100%', height: '100%' }}
            data={barGraphData}
            ref={addRef}
            config={{
              toImageButtonOptions: {
                filename: `clinical_trials_timeline_plot`
              },
              scrollZoom: true
            }}
            layout={{
              title: { text: '⬅ Accelerated  |  Delayed ⮕    &nbsp;' },
              grid: {
                rows: 1,
                columns: 1,
                roworder: 'top to bottom',
                subplots: ['xy'],
                yaxes: ['y']
              },
              margin: {
                t: 50,
                b: 50
              },
              uirevision: 'true',
              autosize: true,
              showlegend: false,
              template: {
                layout: {
                  xaxis: {
                    ticks: '',
                    title: {
                      standoff: 15
                    },
                    automargin: true,
                    zerolinewidth: 2
                  },
                  yaxis: {
                    ticks: '',
                    title: {
                      standoff: 15
                    },
                    automargin: true,
                    zerolinewidth: 2
                  },
                  hovermode: 'closest',
                  hoverlabel: {
                    align: 'left'
                  }
                }
              },
              annotations: [],
              shapes: [],
              xaxis: {
                anchor: 'y',
                range: [minDelayDays, maxDelayDays],
                domain: [0, 1],
                title: {
                  text: 'Changes to the Primary Completion Date in the most recent version compared to the first version reported on clinicaltrials.gov, days'
                }
              },
              yaxis: {
                anchor: 'x',
                domain: [1, 1],
                showticklabels: false,
                categoryorder: 'array',
                categoryarray: nctIds,
                rangemode: 'normal',
                fixedrange: false
              },
              colorway: ['#bdbdbd', '#777777'],
              barmode: 'overlay'
            }}
            useResizeHandler
            onClick={handleHorizontalBarClick}
          />
          {selectedGraphEntityData && (
            <Box
              position='absolute'
              width='520px'
              minHeight='72px'
              top='0px'
              left='50px'
              borderRadius='10px'
              sx={{ backgroundColor: 'primary.backgroundDark' }}
              zIndex='100'>
              <CancelIcon onClick={handleViewDocsClose} sx={styles.closeBtn} />
              <Box p={1} height='100%'>
                <Box minHeight='100px'>
                  <Stack direction='row' spacing={1}>
                    <Typography fontWeight='bold'>NCT ID:</Typography>
                    <Typography>{selectedGraphEntityData?.nctId}</Typography>
                  </Stack>
                  <Stack direction='row' spacing={1}>
                    <Typography fontWeight='bold'>Start Date:</Typography>
                    <Typography>{selectedGraphEntityData?.startDate}</Typography>
                  </Stack>
                  <Stack direction='row' spacing={1}>
                    <Typography fontWeight='bold'>First Submitted Date:</Typography>
                    <Typography>{selectedGraphEntityData?.studyFirstSubmittedDate}</Typography>
                  </Stack>
                  <Stack direction='row' spacing={1}>
                    <Typography fontWeight='bold'>Last Updated Year:</Typography>
                    <Typography>{selectedGraphEntityData?.lastUpdatedYear}</Typography>
                  </Stack>
                  <Stack direction='row' spacing={1}>
                    <Typography fontWeight='bold'>Study Completion:</Typography>
                    <Typography>{selectedGraphEntityData?.studyCompletion}</Typography>
                  </Stack>
                  <Stack direction='row' spacing={1}>
                    <Typography fontWeight='bold'>Primary Completion Date:</Typography>
                    <Typography>{selectedGraphEntityData?.primaryCompletionDate}</Typography>
                  </Stack>
                  <Stack direction='row' spacing={1}>
                    <Typography fontWeight='bold'>Sample Size:</Typography>
                    <Typography>{selectedGraphEntityData?.sampleSize}</Typography>
                  </Stack>
                  <Stack direction='row' spacing={1}>
                    <Typography fontWeight='bold'>Phase:</Typography>
                    <Typography>{selectedGraphEntityData?.phase}</Typography>
                  </Stack>
                  <Stack direction='row' spacing={1}>
                    <Typography fontWeight='bold'>Official Title:</Typography>
                    <Typography>{selectedGraphEntityData?.officialTitle}</Typography>
                  </Stack>
                </Box>
                <Box display='flex' justifyContent='center' width='100%' my={1}>
                  <Button
                    variant='contained'
                    onClick={handleViewDocsClick}
                    sx={{ color: 'white !important', textTransform: 'none' }}>
                    View Documents
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
          {donePageDataLoadingAnimation && nctIds && nctIds.length === 0 && (
            <Box
              position='absolute'
              top='42.5%'
              left='50%'
              sx={{ transform: 'translate(-50%, -50%)' }}>
              <Typography variant='h3'>Could not find trials.</Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default DelayTimeline;
