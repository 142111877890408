const styles = {
  select: {
    width: 200
  },
  searchInput: {
    width: 300,
    padding: 1
  },
  button: {
    color: 'gray.main',
    fontSize: `body1.fontSize`
  },
  buttonReset: {
    color: 'gray.main',
    marginLeft: 2,
    marginRight: 2,
    fontSize: `body1.fontSize`
  },
  resetIcon: {
    marginRight: '2px',
    marginBottom: '2px'
  },
  tabs: {
    paddingLeft: 1,
    marginBottom: '-1px'
  },
  activeTab: {
    border: '0.5px solid',
    borderColor: 'gray.light',
    borderRadius: '10px 10px 0px 0px',
    fontSize: '16px',
    boxShadow: 'none',
    backgroundColor: 'white',
    textTransform: 'capitalize',
    minWidth: 'inherit'
  },
  inactiveTab: {
    borderRadius: '10px 10px 0px 0px',
    textTransform: 'capitalize',
    color: 'white.main',
    fontFamily: 'Nunito Sans',
    fontWeight: '600',
    fontSize: '16px',
    boxShadow: 'none',
    background: 'gradient.main',
    minWidth: 'inherit'
  },
  date: {
    textAlign: 'left',
    padding: '0px 8px',
    color: 'gray.main',
    fontSize: '16px'
  },
  heading: {
    fontSize: '16px',
    fontWeight: 700,
    fontFamily: 'Mulish',
    padding: '0px 8px',
    textAlign: 'left'
  },
  title: {
    fontSize: '14px',
    fontWeight: 500,
    textAlign: 'left'
  },
  TypographyGreen: {
    fontSize: '14px',
    fontWeight: 500,
    color: 'primary.main'
  },
  meetingId: {
    border: `1px solid #B2B2B2`,
    display: 'flex',
    width: 'fit-content',
    fontFamily: 'Mulish',
    backgroundColor: 'white.main',
    justifyContent: 'center',
    borderRadius: 2
  },
  meetingList: {
    overflowY: 'auto',
    height: 'inherit'
  },
  MeetingBox: {
    padding: 1,
    borderRadius: 2,
    margin: '16px',
    backgroundColor: 'primary.backgroundDark',
    cursor: 'pointer',
    border: `1px solid #33B187`,
    justifyContent: 'space-evenly'
  },
  MeetingBoxInactive: {
    padding: 1,
    borderRadius: 2,
    margin: '16px',
    cursor: 'pointer',
    border: `1px solid #B2B2B2`,
    justifyContent: 'space-evenly'
  },
  dropDownBox: {
    display: 'inline-flex',
    borderRadius: '8px',
    boxShadow: '0px 0px 4px rgba(0,0,0,0.16)',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: 'min-content'
  },
  subHeading: {
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Mulish',
    textAlign: 'left',
    padding: '0px 8px'
  },
  doubleArrowIcon: {
    alignSelf: 'center',
    ml: 0,
    color: 'gray.light'
  },
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    top: 0,
    left: 0,
    transition: 'all 2s ease-in-out'
  },
  meetingDetailsWrapper: {
    backgroundColor: 'white.main',
    height: 'calc(100vh - 340px)',
    borderRadius: '20px',
    width: '75%',
    margin: 2,
    overflow: 'auto'
  }
};

export default styles;
