import { Typography, Box } from '@mui/material';
import SearchKeyword from './SearchKeyword';
import { MultiSearchKeywordProps } from './types';

const MultiSearchKeyword = ({
  selectedCategoryOptions,
  setSelectedCategoryOptions,
  selectedCategory,
  isBottomFilterView = false
}: MultiSearchKeywordProps) => {
  return selectedCategory?.options?.map((each: any, index: number) => (
    <>
      <Box border={1} sx={{ borderColor: 'grey.300', borderRadius: 2, p: 1.5 }}>
        <SearchKeyword
          selectedCategoryOptions={selectedCategoryOptions}
          setSelectedCategoryOptions={setSelectedCategoryOptions}
          searchLabel={each.label}
          searchValue={each.value}
          isBottomFilterView={isBottomFilterView}
          isMultiSearch
        />
      </Box>
      {index < selectedCategory.options.length - 1 && (
        <Box
          width='100%'
          display='flex'
          flexDirection='column'
          justifyContent='flex-start'
          alignItems='center'
          mt={1.2}>
          <Box
            width={8}
            sx={{
              transform: 'rotate(-90deg)',
              transformOrigin: '0 0',
              border: '1.25px #9E9E9E solid'
            }}
          />
          <Typography fontSize={14} color='primary.500' fontWeight={700} sx={{ mb: 1.5 }}>
            AND
          </Typography>
          <Box
            width={8}
            sx={{
              transform: 'rotate(-90deg)',
              transformOrigin: '0 0',
              border: '1.25px solid',
              borderColor: 'gray.500'
            }}
          />
        </Box>
      )}
    </>
  ));
};

export default MultiSearchKeyword;
