import { PediatricsContextInterface } from '.';
import actions from './actions';

interface ActionProps {
  type: string;
  value: any;
}

const reducers = (state: PediatricsContextInterface, action: ActionProps) => {
  switch (action.type) {
    case actions.SET_STATS: {
      return {
        ...state,
        stats: action.value
      };
    }
    case actions.SET_PIP_DETAILS: {
      return {
        ...state,
        pip_details: action.value
      };
    }
    case actions.SET_SHOW_LABEL_COMPARE: {
      return {
        ...state,
        showLabelCompare: action.value
      };
    }
    case actions.SET_LABEL_COMPARE_LIST: {
      return {
        ...state,
        labelCompareList: action.value
      };
    }
    case actions.SET_PIP_DOWNLOAD_DATA: {
      return {
        ...state,
        pipDownloadData: action.value
      };
    }
    case actions.SET_FILTERS: {
      return {
        ...state,
        filters: action.value
      };
    }
    case actions.SET_IS_FILTERS_APPLIED: {
      return {
        ...state,
        isFiltersApplied: action.value
      };
    }
    case actions.SET_LOADING: {
      return {
        ...state,
        loading: action.value
      };
    }
    case actions.SET_STATS_LOADING: {
      return {
        ...state,
        statsLoading: action.value
      };
    }
    case actions.SET_AVAILABLE_FILTERS: {
      return {
        ...state,
        availableFilters: action.value
      };
    }
    case actions.SET_TOTAL_COUNT: {
      return {
        ...state,
        totalRecord: action.value
      };
    }
    case actions.SET_SORT: {
      return {
        ...state,
        sortFields: action.value
      };
    }
    case actions.SET_ASK_RIA_FILTERS: {
      return {
        ...state,
        askRiaFilters: action.value
      };
    }
    case actions.SET_DATA_GRID_FILTERS: {
      return {
        ...state,
        dataGridFilters: action.value
      };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
