import { memo, useRef, useState, useEffect } from 'react';
import { Box, Paper, Popper, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { PROJECT_DETAIL_MAPPER } from '../../constants';
import { valueMapperList } from '../../utils/common';
import styles from './DataGridListView.styles';

interface GridCellExpandProps {
  value: any;
  width: number;
  row: any;
  field: any;
}

const GridCellExpand = memo((props: GridCellExpandProps) => {
  // eslint-disable-next-line no-unused-vars
  const { width, value, row, field } = props;
  const wrapper = useRef<HTMLDivElement | null>(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [cellDisplayText, setCellDisplayText] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);
  const [description, setDescription] = useState([]);
  const handleMouseEnter = () => {
    setShowPopper(true);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    const handleKeyDown = (nativeEvent: KeyboardEvent) => {
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  useEffect(() => {
    const valueToBeMapped =
      field === 'requestor'
        ? value
        : {
            program_type: row.program_type_desc,
            ...row[PROJECT_DETAIL_MAPPER[row.program_type_id]]
          };

    const finalArray = valueMapperList(valueToBeMapped);
    if (finalArray.length > 0) {
      setDescription(finalArray);

      const cellValueText =
        field === 'requestor' ? `${value.first_name} ${value.last_name}...` : finalArray[0];
      setCellDisplayText(cellValueText);
    }
  }, [value]);

  if (description.length < 1) {
    return <Box />;
  }

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={styles.container}>
      <Box ref={cellDiv} sx={{ ...styles.refElement, width }} />
      <Box ref={cellValue} sx={styles.cellValue} className='cellValue'>
        {cellDisplayText}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          placement='bottom'
          style={{ width }}>
          <Paper
            elevation={1}
            sx={{ ...styles.paper, minHeight: wrapper.current!.offsetHeight - 3 }}>
            {description?.map((item: any, index) => (
              <Box key={item} mt={index % 2 === 0 ? '1em' : 0}>
                <Box sx={styles.contentWrapper}>
                  <Typography style={styles.text}>
                    <b>{item.split(' : ')[0]}</b>
                    <br />
                    {item.split(' : ')[1]}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

const DataGridListView = ({ value, colDef, row, field }: GridRenderCellParams) => {
  return (
    <GridCellExpand value={value || []} width={colDef.computedWidth} row={row} field={field} />
  );
};

export default DataGridListView;
