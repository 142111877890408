import { createSvgIcon } from '@mui/material';

const MyReportsIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='15' viewBox='0 0 14 15' fill='none'>
    <g clipPath='url(#clip0_1838_5033)'>
      <path d='M10.5003 3.46683L7.53349 0.5H2.91699C2.45286 0.5 2.00774 0.684374 1.67956 1.01256C1.35137 1.34075 1.16699 1.78587 1.16699 2.25V12.1667H10.5003V3.46683ZM2.33366 11V2.25C2.33366 2.09529 2.39512 1.94692 2.50451 1.83752C2.61391 1.72812 2.76228 1.66667 2.91699 1.66667H7.00033V4H9.33366V11H2.33366ZM12.8337 5.75V14.5H4.08366V13.3333H11.667V4.58333L12.8337 5.75Z' />
    </g>
    <defs>
      <clipPath id='clip0_1838_5033'>
        <rect width='14' height='14' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>,
  'MyReports'
);

export default MyReportsIcon;
