import React, { useEffect, useState } from 'react';

import { Box, Dialog, DialogContent, IconButton, Stack, Typography, Tooltip } from '@mui/material';

import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

import DraftableComparisonView from '../../../components/Comparison/DraftableComparisonView';

import comparisonClasses from '../../../components/Comparison/styles/LabelSelection.style';

import { getLabelComparision } from '../../../api/pages/Reg360';
import getComparisonFile from '../../../helpers/draftableUtils';

const ComparisonDialog: React.FC<any> = ({ open, compareItems, onClose }) => {
  const [viewURL, setViewerURL] = useState('');
  useEffect(() => {
    (async () => {
      if (compareItems.length === 2 && open) {
        const res = await getLabelComparision({
          parent_object: compareItems[0]?.pdfLink,
          child_object: compareItems[1]?.pdfLink
        });
        if (res.data.success !== null) {
          setViewerURL(res.data.success);
        }
      }
    })();
  }, [compareItems, open]);

  const downloadComparison = () => {
    try {
      const identifierInURL = viewURL?.split('/')[7];
      const identifier = identifierInURL?.split('?')[0];
      getComparisonFile(identifier);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return (
    <Dialog
      fullScreen
      fullWidth
      maxWidth='lg'
      aria-labelledby='customized-dialog-title'
      sx={comparisonClasses.dialog}
      open={open}
      onClose={onClose}>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={comparisonClasses.headerWrapper}>
        <Box />
        <Stack direction='row' spacing={1}>
          <Typography variant='h3' fontWeight='bold' color='white.main'>
            Canada Guidance Comparison
          </Typography>
        </Stack>
        <Tooltip title='Close'>
          <IconButton aria-label='delete' onClick={onClose} size='small'>
            <HighlightOffOutlinedIcon
              fontSize='inherit'
              sx={comparisonClasses.highlightOffOutlinedIcon}
            />
          </IconButton>
        </Tooltip>
      </Stack>
      <DialogContent sx={comparisonClasses.dialogContent}>
        <DraftableComparisonView
          viewerURL={viewURL}
          downloadFile={downloadComparison}
          parentApplicationNumber={compareItems[0]?.title}
          childApplicationNumber={compareItems[1]?.title}
          parentApplicationName={compareItems[0]?.title}
          childApplicationName={compareItems[1]?.title}
        />
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(ComparisonDialog);
