const styles = {
  secondaryHeaderWrapper: {
    padding: '8px 24px',
    backgroundColor: 'primary.50'
  },
  downloadIcon: {
    height: '16px',
    width: '16px',
    color: 'primary.700'
  },
  downloadComparisonText: {
    fontFamily: 'Mulish',
    fontSize: '13px',
    color: 'primary.700',
    fontWeight: 600
  },
  titleHeaderWrapper: {
    p: '10px 16px',
    width: '100%',
    backgroundColor: 'gray.200',
    borderRadius: '4px 4px 0px 0px'
  },
  ctaSeparator: { borderColor: 'primary.200' },
  ctaWrapper: { cursor: 'pointer' },
  popoverContainer: { pointerEvents: 'none', '& .MuiPaper-root': { borderRadius: '12px' } },
  cardWrapper: { p: 2, width: 288, maxWidth: 288 },
  headerWrapper: { width: '100%', pr: 6.25, pt: '20px' },
  infoIcon: { height: '16px', width: '16px', cursor: 'pointer' },
  flagIcon: { width: '18px', height: '12px' },
  swapIcon: { height: '24px', width: '24px', color: 'primary.600' },
  iframeWrapper: { height: 'calc(100vh - 180px)', border: '1px solid gray' },
  applicationChip: {
    ml: 1,
    fontSize: 10,
    height: 13,
    borderRadius: 1,
    fontWeight: 800,
    color: 'white.main',
    '& span': {
      px: 0.5
    },
    cursor: 'default'
  }
};

export default styles;
