import groupBy from 'lodash/groupBy';
import { useCallback, useState } from 'react';
import { getDocumentsApi, getRegInfoSignedURL } from '../../../api/pages/cdp';

const useDocuments = (application?: string, submission?: string, meetingS3Path?: string) => {
  const [isLoading, setLoading]: [boolean, Function] = useState(false);
  const [errorMessage, setErrorMessage]: [string, Function] = useState('');
  const [documents, setDocuments]: [any, Function] = useState([]);
  const [regInfoDoc,setRegInfoDoc] : [any,Function] = useState(null)

  const getDocuments = useCallback(async () => {
    setLoading(true);
    setErrorMessage('');
    setDocuments([]);
    if (meetingS3Path) {
      try {
        const response: any = await getRegInfoSignedURL(meetingS3Path);
        if (response?.data?.Success) {
          let doc = response?.data?.Success;


          setRegInfoDoc(doc);
        } else {
          setErrorMessage('Could not get the Reg Info Document');
        }
        setLoading(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        setErrorMessage('Could not get the Reg Info Document');
      }
    } else {
      try {
        const response: any = await getDocumentsApi(application, submission, 'fda');
        if (response?.data?.Success) {
          let docs = response?.data?.Success;
          // eslint-disable-next-line no-underscore-dangle
          docs = docs.sort((a: any, b: any) => a._category.localeCompare(b._category));
          const groupedDocs = groupBy(docs, 'category_bucket');

          const sorted: any = {};

          const sortedKeys = Object.keys(groupedDocs).sort((a: string, b: string) =>
            a.localeCompare(b)
          );
          sortedKeys.forEach((key: string) => {
            sorted[key] = groupedDocs[key];
          });

          setDocuments(sorted);
        } else {
          setErrorMessage('Could not get the Clinical Trials Documents');
        }
        setLoading(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        setErrorMessage('Could not get the Clinical Trials Documents');
      }
    }
  }, [application, submission, meetingS3Path]);

  return {
    isLoading,
    errorMessage,
    documents,
    getDocuments,
    regInfoDoc
  };
};

export default useDocuments;
