import React from 'react';
import { Chip } from '@mui/material';
import Box from '@mui/material/Box';

import { LABEL_COLOR } from '../../../pages/SearchResults/constants';
import styles from '../../../pages/SearchResults/styles/ApplicationCard.styles';

interface GridRenderCellParams {
  value?: Array<string>;
  row?: any;
}
const DatagridApplicationType = (props: GridRenderCellParams = { value: [] }) => {
  const { value, row } = props;
  return (
    <Box display='flex'>
      {value &&
        value.map((label: string) => {
          const labelKey = label.toLowerCase();
          const labelColor = LABEL_COLOR[labelKey];
          return (
            <Chip
              label={label}
              key={label}
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                ...styles.labelChip,
                bgcolor: labelColor
              }}
            />
          );
        })}
      {!value && row.application_type && (
        <Chip
          label={row.application_type}
          key={row.application_type}
          sx={{
            ...styles.labelChip,
            display: 'flex',
            alignItems: 'flex-start',
            bgcolor: LABEL_COLOR[row.application_type?.toLowerCase()]
          }}
        />
      )}
    </Box>
  );
};

export default DatagridApplicationType;
