const styles = {
  close: {
    background: 'rgb(255,255,255)',
    position: 'relative',
    '&:hover': {
      background: 'rgba(255,255,255,0.8)'
    }
  },
  searchBar: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'gray.400',
    borderRadius: '8px',
    backgroundColor: 'gray.0',
    marginTop: '64px',
    minWidth: '762px',
    maxWidth: '794px'
  },
  SearchSelect: {
    maxWidth: 400,
    fontSize: '14px',
    p: 0,
    border: 'none',
    '& .MuiSelect-icon': { fontSize: 16, color: 'gray.700' },
    fieldset: {
      p: 0,
      border: 'none'
    }
  },
  divider: { border: '2px solid #9B9BAB', borderRadius: '2px', mt: 1, width: '50px' },
  heading: {
    fontWeight: '800',
    fontSize: '20px',
    textTransform: 'capitalize'
  }
};

export default styles;
