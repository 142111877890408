import palette from '../../../themev5/palette';
import lightLogin from '../../../Images/Group705.svg';

const styles: any = {
  root: {
    height: 'calc(100vh - 210px)',
    marginTop: '16px',
    paddingBottom: '56px',
    '& .cellValue': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '& .fda_org_cell': {
        height: '26px',
        '& .fda_org_cell__more-text': {
          display: 'inline !important'
        }
      }
    },
    '& .MuiDataGrid-root': {
      width: '100%',
      maxWidth: 'max-content',
      '& .MuiDataGrid-columnHeader': {
        color: palette.primary.main,
        fontWeight: 'bold',
        bgcolor: palette.primary.backgroundDark
      },
      '& .MuiDataGrid-columnsContainer': {
        color: palette.primary.lightBluish,
        background: '#F7F7F7'
      },
      '& .MuiDataGrid-main': {
        background: '#fff'
      }
    }
  },
  nda: {
    fontSize: '1vh',
    height: 'auto',
    width: 'auto',
    float: 'left',
    borderRadius: '5px',
    color: 'white',
    padding: '2px',
    marginTop: '16px',
    marginRight: '45px'
    // marginLeft: '45px',
  },
  loadingContainer: {
    width: '100vw',
    height: '300vh'
  },
  loadingTitle: {
    fontSize: '2vh',
    fontWeight: 'bold'
  },
  dialogWithImage: {
    height: 'inherit',
    backgroundImage: `url(${lightLogin})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed'
  },
  title: {
    padding: '24px 24px'
  }
};

export default styles;
