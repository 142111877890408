import React from 'react';

// muiv5
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';

interface RadioFiltersProps {
  id: string;
  label: string;
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (event: any) => void;
  options: Array<{ value: string; label: string }>;
  // eslint-disable-next-line
  radioButtonStyle?: any;
  // eslint-disable-next-line
  disabled?: boolean;
}

const RadioFilters = ({
  id,
  label,
  onChange,
  options,
  value,
  radioButtonStyle = {},
  disabled = false
}: RadioFiltersProps) => {
  return (
    <FormControl id={id}>
      <FormLabel id={`${id}label`}>
        <Typography variant='subtitle1' sx={{ color: 'black.light' }}>
          {label}
        </Typography>
      </FormLabel>
      <RadioGroup
        onChange={onChange}
        row
        defaultValue='all'
        value={value}
        aria-labelledby={`${id}label`}
        name='row-radio-buttons-group'>
        {options?.map((opt: any) => {
          return (
            <FormControlLabel
              key={opt.value}
              value={opt.value}
              // eslint-disable-next-line react/jsx-props-no-spreading
              control={<Radio sx={radioButtonStyle} />}
              label={opt.label}
              disabled={disabled}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default React.memo(RadioFilters);
