import { ReactElement, cloneElement, memo, useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, CircularProgress } from '@mui/material';
import { WarningIcon } from '../../assets/svgs/Icons';
import styles from './styles/CustomAlertBox.styles';

interface CustomAlertBoxProps {
  showAlert: boolean;
  yesButtonOnClick: Function;
  noButtonOnClick: Function;
  yesButtonTitle?: string;
  noButtonTitle?: string;
  yesLoading?: boolean;
  noLoading?: boolean;
  message: string | ReactElement;
  icon?: any;
}

const CustomAlertBox = ({
  showAlert,
  yesButtonOnClick,
  noButtonOnClick,
  yesButtonTitle = 'Yes',
  noButtonTitle = 'No',
  yesLoading = false,
  noLoading = false,
  message,
  icon: CustomIcon
}: CustomAlertBoxProps) => {
  const [buttonWidth, setButtonWidth] = useState(100);

  useEffect(() => {
    const getEstimatedWidth = (contentLength: number) => {
      // Adjust this factor based on your font and styling
      const charWidthFactor = 8;
      return contentLength * charWidthFactor;
    };

    const estimatedWidth = getEstimatedWidth(Math.max(yesButtonTitle.length, noButtonTitle.length));
    setButtonWidth(estimatedWidth);
  }, [yesButtonTitle, noButtonTitle]);

  return (
    <Modal open={showAlert}>
      <Box sx={styles.container}>
        <Box sx={styles.messageContainer}>
          {CustomIcon ? (
            <CustomIcon sx={styles.warningIcon} />
          ) : (
            <WarningIcon sx={styles.warningIcon} />
          )}
          {typeof message === 'string' ? (
            <Typography sx={styles.message}>{message}</Typography>
          ) : (
            cloneElement(message as ReactElement, { sx: styles.message }) // Apply styles.message to the custom message component
          )}
        </Box>
        <Box sx={styles.confirmButtonGroup}>
          <Button
            size='small'
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              noButtonOnClick();
            }}
            sx={{ ...styles.noButton, minWidth: buttonWidth }}>
            {noLoading ? <CircularProgress size={20} color='inherit' /> : noButtonTitle}
          </Button>
          <Button
            size='small'
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              yesButtonOnClick();
            }}
            sx={{ ...styles.yesButton, minWidth: buttonWidth }}>
            {yesLoading ? <CircularProgress size={20} color='inherit' /> : yesButtonTitle}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

CustomAlertBox.defaultProps = {
  yesButtonTitle: 'Yes',
  noButtonTitle: 'No',
  yesLoading: false,
  noLoading: false,
  icon: null
};

export default memo(CustomAlertBox);
