import React, { createContext, useReducer } from 'react';
import reducers, { PMRSurveillanceContextInterface } from './reducers';

type Props = {
  children: React.ReactNode;
};

const initialState = {
  pmrStats: {},
  tableData: [],
  cmtStatusGraph: [],
  subpartDescGraph: [],
  categoryGraph: [],
  pmrErrors: [],
  filters: [],
  availableFilters: {},
  isFiltersApplied: false,
  search: {},
  loading: true,
  totalRecord: 0,
  sortFields: [],
  dataGridFilters: {}
};

const PMRSurveillanceStore = createContext<{
  pmrState: PMRSurveillanceContextInterface;
  pmrDispatch: React.Dispatch<any>;
}>({ pmrState: initialState, pmrDispatch: () => null });
const { Provider } = PMRSurveillanceStore;

export const PMRStateProvider = ({ children }: Props) => {
  const [pmrState, pmrDispatch] = useReducer(reducers, initialState);
  return <Provider value={{ pmrState, pmrDispatch }}>{children}</Provider>;
};

export default PMRSurveillanceStore;
