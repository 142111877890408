import { useCallback, useContext } from 'react';

// api
import postOrphanDesignations from '../../../api/pages/OrphanDesignations';

// store
import OrphanDesignationsStore from '../../../store/OrphanDesignations';
import actions from '../../../store/OrphanDesignations/actions';
import { constructPieChartData } from '../utils/getChartStructure';

import getFilters from '../utils/common';

const useOrphanDesignations = () => {
  const { OrphanDesignationsState, OrphanDesignationsDispatch } =
    useContext(OrphanDesignationsStore);

  const getOrphanDesignations = useCallback(async (appliedFilters: any) => {
    OrphanDesignationsDispatch({
      type: actions.SET_IS_LOADING,
      value: true
    });

    OrphanDesignationsDispatch({
      type: actions.SET_IS_FILTERS_APPLIED,
      value: Object.keys(appliedFilters).length !== 0
    });

    try {
      const response = await postOrphanDesignations({ filters: appliedFilters });
      const errors = [];

      if (response?.data?.status !== 200 || !response?.data?.body?.data) {
        errors.push('No data was found');

        OrphanDesignationsDispatch({
          type: actions.SET_ERRORS,
          action: errors
        });

        return;
      }

      const insights = response?.data?.body?.insights ?? {};

      const stats = {
        total: insights?.total_orphan_application ?? 0,
        totalDocuments: insights?.pdf_stats?.total_pdf_count ?? 0,
        totalPages: insights?.pdf_stats?.total_page_count ?? 0,
        totalUniqueActiveSubstances: insights?.total_unique_active_substances ?? 0
      };

      const graphs = constructPieChartData(insights?.donut_graph_data);

      OrphanDesignationsDispatch({
        type: actions.SET_CHARTS,
        value: graphs
      });

      OrphanDesignationsDispatch({
        type: actions.SET_PROGRAM_TYPE_CHART,
        value: insights?.donut_graph_data?.decision_outcome ?? {}
      });

      OrphanDesignationsDispatch({
        type: actions.SET_SUBMISSION_STATUS_CHART,
        value: insights?.donut_graph_data?.designation_status ?? {}
      });

      OrphanDesignationsDispatch({
        type: actions.SET_BAR_GRAPH,
        value: insights?.bar_graph_data?.filter((item: any) => item.count > 0) ?? []
      });

      OrphanDesignationsDispatch({
        type: actions.SET_STATS,
        value: stats
      });

      OrphanDesignationsDispatch({
        type: actions.SET_PROJECTS,
        value: response?.data?.body?.data ?? []
      });

      if (response?.data?.body?.data?.length) {
        const newFilters = getFilters(response?.data?.body?.filters ?? {});

        OrphanDesignationsDispatch({
          type: actions.SET_FILTERS,
          value: newFilters
        });
      }

      OrphanDesignationsDispatch({
        type: actions.SET_AVAILABLE_FILTERS,
        value: appliedFilters
      });
    } catch (error) {
      OrphanDesignationsDispatch({
        type: actions.SET_IS_LOADING,
        value: false
      });

      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      OrphanDesignationsDispatch({
        type: actions.SET_IS_LOADING,
        value: false
      });
    }
  }, []);

  return {
    filters: OrphanDesignationsState.filters,
    projects: OrphanDesignationsState.projects,
    isLoading: OrphanDesignationsState.isLoading,
    isFiltersApplied: OrphanDesignationsState.isFiltersApplied,
    getOrphanDesignations
  };
};

export default useOrphanDesignations;
