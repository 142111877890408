import React, { createContext, useReducer } from 'react';
import reducers from './reducers';

type Props = {
  children: React.ReactNode;
};
export interface PMRImpactContextInterface {
  pmrStats: any;
  tableData: any[];
  issueYearGraph: any[];
  fulfillmentGraph: any[];
  categoryGraph: any[];
  pmrErrors: any[];
  filters: any[];
  availableFilters: any;
  isFiltersApplied: boolean;
  loading: boolean;
  totalRecord: number;
  sortFields: any[];
  dataGridFilters: any;
}

const initialState = {
  pmrStats: {},
  tableData: [],
  issueYearGraph: [],
  fulfillmentGraph: [],
  categoryGraph: [],
  pmrErrors: [],
  filters: [],
  availableFilters: {},
  isFiltersApplied: false,
  loading: true,
  totalRecord: 0,
  sortFields: [],
  dataGridFilters: {}
};

const PMRStore = createContext<{
  pmrState: PMRImpactContextInterface;
  pmrDispatch: React.Dispatch<any>;
}>({ pmrState: initialState, pmrDispatch: () => null });
const { Provider } = PMRStore;

export const PMRStateProvider = ({ children }: Props) => {
  const [pmrState, pmrDispatch] = useReducer(reducers, initialState);
  return <Provider value={{ pmrState, pmrDispatch }}>{children}</Provider>;
};

export default PMRStore;
