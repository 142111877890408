export const pieChartStyles = {
  pieLabel: false,
  pieLegend: true,
  enableLabels: 'both'
};

const CORE_PAGE_GRAPHS = [
  // US
  {
    id: 'approval_tracks__radar__submission_type_application_type__us_fda',
    title: {
      title: 'Approval Tracks by Submission Type & Application Type (US FDA)',
      show: true
    },
    graph: 'RADAR',
    query: {},
    graphStyle: {}
  },
  {
    id: 'approval_tracks__line__approval_year__us_fda',
    title: {
      title: 'Approval Tracks by Approval Year (US FDA)',
      show: true
    },
    graph: 'LINE',
    query: {},
    graphStyle: {}
  },
  {
    id: 'patent_expiration__bar__quarter__us-fda--nda',
    title: {
      title: 'Patent Expiration (NDA)',
      show: true
    },
    graph: 'BAR',
    query: {},
    graphStyle: {}
  },
  {
    id: 'exclusivity_expiration__bar__quarter__us_fda--nda',
    title: {
      title: 'Exclusivity Expiration (NDA)',
      show: true
    },
    graph: 'BAR',
    query: {},
    graphStyle: {}
  },

  // CA
  {
    id: 'patent_expiration__bar__quarter__ca',
    title: {
      title: 'Patent Expiry Data',
      show: true
    },
    graph: 'BAR',
    query: {},
    graphStyle: {}
  },

  // HPRA
  {
    id: 'products__pie_chart__hpra',
    graph: 'PIE',
    query: {},
    title: {
      title: 'Products (HPRA)',
      show: true
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'approval_stats__bar_quarter__hpra',
    title: {
      title: 'Approval Year',
      show: true
    },
    graph: 'BAR',
    query: {},
    graphStyle: {}
  },

  // HMA
  {
    id: 'products__bar_quater__hma',
    graph: 'BAR',
    query: {},
    title: {
      title: 'Reference State Products',
      show: true
    },
    graphStyle: {}
  },
  {
    id: 'approval_stats__bar_quater__hma',
    title: {
      title: 'Approval Year',
      show: true
    },
    graph: 'BAR',
    query: {},
    graphStyle: {}
  },

  // EU
  {
    id: 'generics_products__pie_chart__yes_no__eu',
    title: {
      title: 'Generics (EU)',
      show: true
    },
    graph: 'PIE',
    query: {},
    graphStyle: pieChartStyles
  },
  {
    id: 'biosimilars_products__pie_chart__yes_no__eu',
    title: {
      title: 'Biosimilars (EU)',
      show: true
    },
    graph: 'PIE',
    query: {},
    graphStyle: pieChartStyles
  },

  // US-DEVICES
  {
    id: 'approval_stats__bar__us-devices',
    title: {
      title: 'Original PMA Approved (CDRH)',
      show: true
    },
    graph: 'LINE',
    query: {},
    graphStyle: {}
  },
  // JP-PMDA
  {
    id: 'approval_stats__bar__jp',
    title: {
      title: 'Approval Year',
      show: true
    },
    graph: 'BAR',
    query: {},
    graphStyle: {}
  }
];

export default CORE_PAGE_GRAPHS;
