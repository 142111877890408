/* eslint-disable import/prefer-default-export */
import makeRequest from '../client';

export const fetchPDCOMeetings = (filters: any) =>
  makeRequest(`/pdco/meetings`, 'POST', { filters });

export const fetchPDCOMeetingDetails = (meetingId: string, filters: any) =>
  makeRequest(`/pdco/meetings/${meetingId}`, 'POST', { filters });

export const fetchPDCOPIPDetails = (filters: any) =>
  makeRequest(`/pdco/get_data`, 'POST', { filters });
