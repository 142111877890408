import React, { useEffect, useState } from "react";

// guide tour
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";

// material ui
import Fab from "@mui/material/Fab";
import Airplane from "../../../Images/svgs/airplane";

// styles

const GuideTour = () => {
  const [stepsEnabled, setStepsEnabled] = useState(false);

  const onExit = () => {
    setStepsEnabled(false);
  };

  const steps = [
    {
      element: '#quickSearch',
      intro:
        'In the header, you can search for drug applications based on a number of criteria of US FDA & EMA. The search process is as follows:',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass'
    },
    {
      element: '#categoryTerm',
      intro:
        'Select a category: Indications, Active Ingredient, Trade Name, Formulation, Route, Approval Date, as applicable to the source',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass'
    },
    {
      element: '#searchTerm',
      intro: 'Search text: Enter a search string and press enter or hit the search button.',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass'
    },
    {
      element: '#boxoutline',
      intro:
        'Provides unique applications (NDA or BLA in the US or Products in the EU) approved for respective active ingredient along with basic information (Sponsor name, Brand Name, Formulation, Approval Pathway etc.)',
      position: 'left',
      tooltipClass: 'myTooltipClass',
      ighlightClass: 'myHighlightClass'
    },
    {
      element: '#checkbox_selection',
      intro:
        'Select the application of interest to compare product labels (USPI for FDA and SmPC for EMA) by sections.',
      position: 'left',
      tooltipClass: 'myTooltipClass',
      ighlightClass: 'myHighlightClass'
    }
    // Uncomment the below code inform users about the user profile functionality.
    // {
    //   element: '#moreIcon',
    //   intro: 'Access preferences, recent history, favorite applications, favorite queries and projects to assist in workflow',
    //   position: 'left',
    //   tooltipClass: 'myTooltipClass',
    //   highlightClass: 'myHighlightClass'
    // },
  ];

  const checker = [
    {
      id: 'checkbox_selection',
      value: 1,
      element: '#control'
    }
  ];

  const onBeforeChange = nextStepIndex => {
    if (steps[nextStepIndex]) {
      steps[nextStepIndex].onBeforeChange?.();
    }
  };

  useEffect(() => {
    let temp = steps;
    checker.forEach(item => {
      if (!item.value) {
        temp = temp.filter(step => step.element !== item.element);
      }
    });
  }, []);

  return (
    <>
      <Steps
        enabled={stepsEnabled}
        onBeforeChange={onBeforeChange}
        steps={steps}
        initialStep={0}
        onExit={onExit}
      />
      <Fab
        aria-label='add'
        sx={{
          position: 'fixed',
          left: 10,
          bottom: 10
        }}
        onClick={() => setStepsEnabled(true)}
      >
        <Airplane />
      </Fab>
    </>
  );
};

export default GuideTour;
