import React, { useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import Tooltip from '@mui/material/Tooltip';
import { menuList } from '../const';
import CustomPopover from './CustomPopover';
import classes from '../styles/Index.style';

interface SourceData {
  sourceName: string;
  sourceDescription: string;
  selectedSourceList: unknown[];
  // eslint-disable-next-line no-unused-vars
  handleSourceChange: (event: any, currentSource: any, isSourceWiseResult?: any) => void;
}

const SourceSwitch = (source: SourceData) => {
  const { sourceName, sourceDescription, selectedSourceList, handleSourceChange } = source;

  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleMenuClose = () => {
    setIsOpen(false);
  };

  const handleMenuItemClick = (key: string) => {
    handleSourceChange(key, sourceName, false);
    handleMenuClose();
  };

  // Filter the 'menuList' to get items not present in 'selected'
  const isNotSelected = (item: any) => {
    return !selectedSourceList.some((selectedItem: any) => selectedItem.sourceName === item.value);
  };
  const menuItems = menuList.filter(isNotSelected);

  return (
    <>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        padding={1}
        sx={classes.shadowborder}>
        <Typography fontSize={18} color='primary.main' display='inline' marginRight={1}>
          {sourceDescription}
        </Typography>
        <Tooltip title='Click to switch the Source' arrow placement='right'>
          <IconButton
            aria-label='menu'
            aria-controls='menu-list'
            aria-haspopup='true'
            ref={anchorRef} // Add the ref to IconButton
            onClick={() => setIsOpen(prev => !prev)}
            style={{ color: '#90EE90' }}>
            <SwapHorizontalCircleIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <CustomPopover
        isOpen={isOpen}
        anchorRef={anchorRef}
        handleMenuClose={handleMenuClose}
        handleMenuItemClick={handleMenuItemClick}
        menuItems={menuItems}
      />
    </>
  );
};
export default SourceSwitch;
