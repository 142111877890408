import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { LinearProgress } from '@mui/material';
import AnimatedLogo from '../assets/svgs/AnimatedLogo';

const Loader = () => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignContent='center'
      height='100vh'
      width='100vw'>
      <Box
        width='60%'
        margin='auto'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'>
        <Box style={{ width: 300, marginBottom: -40 }}>
          <AnimatedLogo />
        </Box>
        <Box mb={2} width={250}>
          <LinearProgress
            sx={{
              color: '#33B187 !important',
              '& .MuiLinearProgress-barColorPrimary': {
                backgroundColor: '#33B187 !important'
              }
            }}
          />
        </Box>
        <Typography variant='h5' align='center'>
          Unlocking the Future of R&D.
        </Typography>
        <Typography variant='caption' align='center' style={{ marginTop: '10px' }}>
          Please wait as we load the power of biointelligence to revolutionize clinical, regulatory,
          and business decisions at lightning speed.
        </Typography>
      </Box>
    </Box>
  );
};

export default Loader;
