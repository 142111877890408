const styles = {
  divider: { borderColor: 'gray.300', mt: 3 },
  createGroupButton: {
    ml: 'auto',
    borderRadius: 6,
    bgcolor: 'primary.600',
    px: 3,
    py: 0.5,
    fontSize: 14,
    fontWeight: 700,
    textTransform: 'capitalize',
    color: 'white.main',
    '&:disabled': {
      border: 0,
      color: 'gray.green',
      bgcolor: 'primary.50'
    }
  },
  inputLabel: {
    color: 'gray.800',
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '200%'
  },
  groupNameInput: {
    '& .MuiInputBase-input': {
      px: 1.5,
      py: 1.25,
      fontSize: 14,
      borderColor: 'unset'
    },
    '& fieldset': {
      borderColor: 'inherit'
    }
  },
  groupNameInputProp: {
    borderRadius: 1.5,
    p: 0,
    borderColor: 'gray.400'
  },
  selectedChip: {
    '& .MuiChip-avatar': { color: 'white !important' },
    '& .MuiChip-label': { pr: 1 },
    fontSize: 14,
    color: 'gray.800',
    fontWeight: 500,
    py: 1,
    pr: 0,
    pl: 0.25
  },
  checkbox: { fontSize: 15 },
  userName: { fontSize: 14, color: 'gray.800', pl: 1.5 },
  paper: {
    height: 360,
    width: 600,
    borderRadius: 5,
    p: 3,
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray.lightVariant3',
      borderRadius: '4px',
      opacity: '0.8'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    }
  },
  memberSuggestionPaper: {
    borderRadius: 2,
    ul: {
      '&::-webkit-scrollbar': {
        width: '4px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'gray.lightVariant3',
        borderRadius: '4px',
        opacity: '0.8'
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent'
      }
    }
  }
};

export default styles;
