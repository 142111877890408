import { createSvgIcon } from '@mui/material';

const PauseIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='24' viewBox='0 0 18 24' fill='none'>
    <path d='M7 24H0V0H7V24ZM2 22H5V2H2V22Z' fill='#616161' />
    <path d='M18.0001 24H11.0001V0H18.0001V24ZM13.0001 22H16.0001V2H13.0001V22Z' fill='#616161' />
  </svg>,
  'Pause'
);

export default PauseIcon;
