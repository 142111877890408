import React from 'react';

const Airplane = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xlink='http://www.w3.org/1999/xlink'
      width='109'
      height='102'
      viewBox='0 0 68 68'>
      <defs>
        <style>
          {
            '\
          .firstColor{\
            fill:url(#firstColor);\
          }\
          .secondColor{\
            fill:#dbdbdb;\
          }\
          .thirdColor{\
            fill:#fff;\
          }\
          '
          }
        </style>
        <linearGradient
          id='firstColor'
          y1='0.499'
          x2='1'
          y2='0.499'
          gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor='#47a7a9' />
          <stop offset='1' stopColor='#3bb44a' />
        </linearGradient>
      </defs>
      <circle className='firstColor' cx='34' cy='34' r='34' transform='translate(0 0)' />
      <path
        className='secondColor'
        d='M133.839,157.054l-7.31-19.96,5.044-5.044c5.017-5.017,3.507-4.235,4.693-6.339a2.982,2.982,0,0,0,.526-1.065c.324-.8.324-1.295.108-1.511s-.715-.216-1.511.108a2.879,2.879,0,0,0-1.065.526c-2.1,1.187-1.322-.337-6.339,4.693l-5.044,5.044-19.96-7.31a.969.969,0,0,0-1.025.229l-1.484,1.484a.984.984,0,0,0-.283.755.951.951,0,0,0,.283.62l.094.094,15.321,11.612c-4.451,4.9-8.753,9.94-11.1,13.042l-4.478-1.146a.737.737,0,0,0-.715.189l-.391.391a.743.743,0,0,0-.216.593.732.732,0,0,0,.216.459c.027.027.067.054.094.081l3.345,2.347a.418.418,0,0,1,.445.445l2.347,3.345a.587.587,0,0,0,.081.094.733.733,0,0,0,.459.216.76.76,0,0,0,.593-.216l.391-.391a.737.737,0,0,0,.189-.715L106,155.247c3.1-2.347,8.146-6.662,13.042-11.1l11.612,15.321a.586.586,0,0,0,.081.094.951.951,0,0,0,.62.283.984.984,0,0,0,.755-.283l1.484-1.484A.949.949,0,0,0,133.839,157.054Z'
        transform='translate(-85.637 -106.398)'
      />
      <path
        className='thirdColor'
        d='M266.3,157.054l-7.31-19.96,5.044-5.044c5.017-5.017,3.507-4.235,4.693-6.339a2.981,2.981,0,0,0,.526-1.065c.324-.8.324-1.295.108-1.511s-.715-.216-1.51.108a2.879,2.879,0,0,0-1.065.526c-2.1,1.187-1.322-.337-6.339,4.693l-5.044,5.044-3.3-1.214v12.6l11.046,14.566a.587.587,0,0,0,.081.094.951.951,0,0,0,.62.283.984.984,0,0,0,.755-.283l1.484-1.484A.962.962,0,0,0,266.3,157.054Z'
        transform='translate(-218.1 -106.398)'
      />
      <g transform='translate(10.122 28.706)'>
        <path
          className='secondColor'
          d='M83.041,212.951a.358.358,0,0,1,0,.5l-7.391,7.391a.358.358,0,0,1-.5,0h0a.358.358,0,0,1,0-.5l7.391-7.391a.358.358,0,0,1,.5,0Z'
          transform='translate(-75.05 -212.85)'
        />
        <path
          className='secondColor'
          d='M100.041,230.051a.358.358,0,0,1,0,.5l-7.391,7.391a.358.358,0,0,1-.5,0h0a.358.358,0,0,1,0-.5l7.391-7.391a.358.358,0,0,1,.5,0Z'
          transform='translate(-89.757 -227.644)'
        />
        <path
          className='secondColor'
          d='M117.141,247.086a.358.358,0,0,1,0,.5l-7.391,7.391a.358.358,0,0,1-.5,0h0a.358.358,0,0,1,0-.5l7.391-7.391a.335.335,0,0,1,.5,0Z'
          transform='translate(-104.551 -242.373)'
        />
        <path
          className='secondColor'
          d='M205.141,335.051a.358.358,0,0,1,0,.5l-7.391,7.391a.358.358,0,0,1-.5,0h0a.358.358,0,0,1,0-.5l7.391-7.391a.358.358,0,0,1,.5,0Z'
          transform='translate(-180.683 -318.483)'
        />
        <path
          className='secondColor'
          d='M222.141,352.151a.358.358,0,0,1,0,.5l-7.391,7.391a.358.358,0,0,1-.5,0h0a.358.358,0,0,1,0-.5l7.391-7.391a.358.358,0,0,1,.5,0Z'
          transform='translate(-195.39 -333.277)'
        />
        <path
          className='secondColor'
          d='M239.241,369.151a.358.358,0,0,1,0,.5l-7.391,7.391a.358.358,0,0,1-.5,0h0a.358.358,0,0,1,0-.5l7.391-7.391a.358.358,0,0,1,.5,0Z'
          transform='translate(-210.184 -347.984)'
        />
      </g>
    </svg>
  );
};

export default Airplane;
