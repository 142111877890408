import makeRequest from '../client';

/**
 *
 * Function gets all data for EUCTR Pediatrcs Module
 */
const getEUCTRPediatricsData = (filters, limit, pageNo) =>
  makeRequest(`/pediatrics/euctr?limit=${limit}&offset=${pageNo}`, 'POST', filters);

export default getEUCTRPediatricsData;
