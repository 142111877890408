import React, { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Box from '@mui/material/Box';
import CustomTimelinePropsInterface from '../../../interfaces/CustomTimelinePropsInterface';

import styles from './TimelineMore.styles';

const isOverflown = (element: Element): boolean => {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
};

const TimelineMore: React.FC<CustomTimelinePropsInterface> = ({
  column,
  dataItem
}: CustomTimelinePropsInterface) => {
  const [concatValue, setConcatValue] = useState('');
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);
  useEffect(() => {
    if (column !== undefined) {
      if (Array.isArray(dataItem[column.name])) {
        const concatString = dataItem[column.name]?.join(', ') || '.......';
        setConcatValue(concatString);
      } else {
        setConcatValue(dataItem[column.name]);
      }
    }
  }, [dataItem, column]);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current!);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      width='auto'
      sx={styles.container}>
      <Typography
        ref={cellValue}
        maxHeight={80}
        overflow='hidden'
        textOverflow='ellipsis'
        variant='subtitle1'
        sx={{ ...styles.text }}>
        {' '}
        {concatValue}
      </Typography>

      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ marginLeft: -17 }}>
          <Paper
            elevation={1}
            sx={{
              maxHeight: 290,
              overflowY: 'auto',
              border: 12,
              borderColor: 'transparent',
              borderRadius: '8px',
              p: 1,
              width: 400
            }}>
            <Typography variant='body2' style={{ fontSize: '12px' }}>
              {concatValue}
            </Typography>
          </Paper>
        </Popper>
      )}
      <Box ref={cellDiv} />
    </Box>
  );
};

export default React.memo(TimelineMore);
