// Functional HOC using the useState hook
import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

// api
import regulatoryInformationAPI from '../../../api/pages/Regulatory360';

// context
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import RegulatoryActions from '../../../store/RegulatoryInformation/actions';

interface ParameterProps {
  source: string;
  number: string;
}

const withFetchingPatents = (WrappedComponent: React.FunctionComponent) => {
  return () => {
    // context store for aria state management
    const { regulatoryDispatch } = useContext(RegulatoryInformationStore) as any;

    // get the url parameters
    const { source, number } = useParams<ParameterProps>();

    useEffect(() => {
      const getData = async () => {
        try {
          regulatoryDispatch({ type: RegulatoryActions.SET_LOADING_PATENTS, value: true });
          const res = await regulatoryInformationAPI(source, number, 'patents');
          if (res.status === 200) {
            regulatoryDispatch({ type: RegulatoryActions.SET_PATENTS, value: res.data.body });
          }
          regulatoryDispatch({ type: RegulatoryActions.SET_LOADING_PATENTS, value: false });
        } catch (e) {
          // eslint-disable-next-line no-console
          regulatoryDispatch({ type: RegulatoryActions.SET_LOADING_PATENTS, value: false });
          console.error(e);
        }
      };

      getData();
    }, []);

    return <WrappedComponent />;
  };
};

export default withFetchingPatents;
