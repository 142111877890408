import { flatten, intersectionBy, map, uniq } from 'lodash';
import { STATS_BAR } from '../constants';
import { Stat } from '../types';

const getApplicationStats = (applications: any, sources: any) => {
  /* 
    Generates the stats array with { title: string, value: string | number }
    We would show the stats present for a given source using `STATS_BAR` constant.
    If there are multiple sources, we take the intersection of the stats for each sources and calculate the stats.
    Total applications and Documents/Pages are calculated by default for all the sources.
  */
  const stats: Stat[] = [];

  stats.push({
    title: 'Total Applications',
    value: applications.length
  });

  let mergedStats: any = [];
  sources.forEach((source: string) => {
    mergedStats = [...mergedStats, STATS_BAR[source]];
  });
  let selectedStats: any = [];
  if (mergedStats.length === 1) {
    // eslint-disable-next-line prefer-destructuring
    selectedStats = mergedStats[0];
  } else {
    selectedStats = intersectionBy(...mergedStats, 'field');
  }

  selectedStats?.forEach((stat: any) => {
    const elements = map(applications, stat.field);
    const totalCount = uniq(flatten(elements))?.length;
    stats.push({
      title: stat.label,
      value: totalCount
    });
  });

  const documentsCount = applications.reduce((acc: any, item: any) => {
    // eslint-disable-next-line no-param-reassign
    acc += item.document_count;
    return acc;
  }, 0);
  const pagesCount = applications.reduce((acc: any, item: any) => {
    // eslint-disable-next-line no-param-reassign
    acc += item.document_total_page_count;
    return acc;
  }, 0);

  stats.push({
    title: 'Documents/Pages',
    value: documentsCount,
    value2: pagesCount
  });
  return stats;
};

export default getApplicationStats;
