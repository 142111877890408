import { createSvgIcon } from '@mui/material';

const ThreeDotIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'>
    <path
      d='M10.0001 10.9803H10.0084M10.0001 5.98031H10.0084M10.0001 15.9803H10.0084M10.8334 10.9803C10.8334 11.4406 10.4603 11.8136 10.0001 11.8136C9.53983 11.8136 9.16675 11.4406 9.16675 10.9803C9.16675 10.5201 9.53983 10.147 10.0001 10.147C10.4603 10.147 10.8334 10.5201 10.8334 10.9803ZM10.8334 15.9803C10.8334 16.4406 10.4603 16.8136 10.0001 16.8136C9.53983 16.8136 9.16675 16.4406 9.16675 15.9803C9.16675 15.5201 9.53983 15.147 10.0001 15.147C10.4603 15.147 10.8334 15.5201 10.8334 15.9803ZM10.8334 5.98031C10.8334 6.44054 10.4603 6.81364 10.0001 6.81364C9.53983 6.81364 9.16675 6.44054 9.16675 5.98031C9.16675 5.52007 9.53983 5.14697 10.0001 5.14697C10.4603 5.14697 10.8334 5.52007 10.8334 5.98031Z'
      stroke='black'
      strokeWidth='1.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'ThreeDotIcon'
);

const ThreeDotIconVariation = createSvgIcon(
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8 4.00001C9.10457 4.00001 10 3.10458 10 2.00001C10 0.895433 9.10457 0 8 0C6.89543 0 6 0.895433 6 2.00001C6 3.10458 6.89543 4.00001 8 4.00001Z'
      fill='currentColor'
    />
    <path
      d='M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z'
      fill='currentColor'
    />
    <path
      d='M8 16C9.10457 16 10 15.1046 10 14C10 12.8954 9.10457 12 8 12C6.89543 12 6 12.8954 6 14C6 15.1046 6.89543 16 8 16Z'
      fill='currentColor'
    />
  </svg>,
  'ThreeDotIconVariation'
);

export { ThreeDotIcon, ThreeDotIconVariation };
