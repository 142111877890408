import { createSvgIcon } from '@mui/material';

const OpenLinkIcon = createSvgIcon(
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.25 0C10.6277 0 10.125 0.502734 10.125 1.125C10.125 1.74727 10.6277 2.25 11.25 2.25H14.1574L7.08047 9.33047C6.64102 9.76992 6.64102 10.4836 7.08047 10.923C7.51992 11.3625 8.23359 11.3625 8.67305 10.923L15.75 3.84258V6.75C15.75 7.37227 16.2527 7.875 16.875 7.875C17.4973 7.875 18 7.37227 18 6.75V1.125C18 0.502734 17.4973 0 16.875 0H11.25ZM2.8125 1.125C1.25859 1.125 0 2.38359 0 3.9375V15.1875C0 16.7414 1.25859 18 2.8125 18H14.0625C15.6164 18 16.875 16.7414 16.875 15.1875V11.25C16.875 10.6277 16.3723 10.125 15.75 10.125C15.1277 10.125 14.625 10.6277 14.625 11.25V15.1875C14.625 15.4969 14.3719 15.75 14.0625 15.75H2.8125C2.50312 15.75 2.25 15.4969 2.25 15.1875V3.9375C2.25 3.62812 2.50312 3.375 2.8125 3.375H6.75C7.37227 3.375 7.875 2.87227 7.875 2.25C7.875 1.62773 7.37227 1.125 6.75 1.125H2.8125Z'
      fill='#757575'
    />
  </svg>,
  'OpenLinkIcon'
);

export default OpenLinkIcon;
