import React, { useState, useEffect } from 'react';

import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import CheckBox from '../CheckBox/CheckBox';

const AutoSearchOptions = ({
  searchOptions,
  filterState,
  handleCheckboxChange,
  closePopup = null
}: any) => {
  const [filterOptions, setFilterOptions] = useState<Array<any>>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleChange = (options: Array<any>, search: string) => {
    const filteredOptions: React.SetStateAction<any[] | undefined> = [];
    if (search !== '') {
      searchOptions?.forEach((option: any) => {
        if (option.value.toLowerCase().includes(search?.toLowerCase().trim())) {
          filteredOptions.push(option);
        }
      });

      setFilterOptions(filteredOptions);
    } else {
      setFilterOptions(searchOptions);
    }
  };

  useEffect(() => {
    if (searchTerm !== '') {
      handleChange(searchOptions, searchTerm);
    } else {
      setFilterOptions(searchOptions);
    }
  }, [searchOptions]);

  const handleFilterOptionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchKey = event.target.value;
    setSearchTerm(event.target.value);
    handleChange(searchOptions, searchKey);
  };

  const handlePopup = () => {
    if (closePopup !== null) {
      closePopup(false);
    }
  };

  return (
    <FormGroup sx={{ padding: 2, width: 300 }}>
      <Stack direction='row' justifyContent='space-between' sx={{ border: '0px solid red' }}>
        <TextField
          sx={{ width: '80%' }}
          placeholder='Search here'
          variant='standard'
          onChange={handleFilterOptionsChange}
        />
        {closePopup !== null && (
          <IconButton onClick={handlePopup}>
            <CancelOutlinedIcon fontSize='small' />
          </IconButton>
        )}
      </Stack>
      {filterOptions?.length > 0 ? (
        filterOptions?.map(item => {
          return (
            <CheckBox
              key={item.value}
              item={item}
              filterState={filterState}
              onChangeFn={handleCheckboxChange}
            />
          );
        })
      ) : (
        <Typography pl={5} pt={2}>
          No Results for above search
        </Typography>
      )}
    </FormGroup>
  );
};

export default React.memo(AutoSearchOptions);
