const styles = {
  selectedOptionsContainer: {
    width: 370,
    p: 1,
    pl: 2,
    pr: 1,
    borderRadius: 2,
    alignItems: 'center',
    backgroundColor: 'gray.background',
    border: '1px solid black',
    borderColor: 'gray.background'
  }
};

export default styles;
