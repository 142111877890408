import { Box, Typography } from '@mui/material';

const NoResultsView = ({ text }: { text: string }) => {
  return (
    <Box display='flex' alignItems='center' justifyContent='center' sx={{ margin: '20%' }}>
      <Typography variant='h6' textAlign='center'>
        {text ?? 'No items found'}
      </Typography>
    </Box>
  );
};

export default NoResultsView;
