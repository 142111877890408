import { useContext } from 'react';
import UserProfileStore from '../../../store/UserProfile';
import UserProfileActions from '../../../store/UserProfile/actions';
import Store from '../../../store';
import Actions from '../../../store/actions';
import {
  createProject,
  deleteProject,
  getAllProjects,
  listProjectEntity,
  updateProject
} from '../../../api/pages/UserProfile';
import { normalizeFavoritesAndProjectsKey } from '../utils';
import { identifierKey } from '../../SearchResults/constants';

const useProjectData = () => {
  const { profileState, profileDispatch } = useContext(UserProfileStore);
  const { dispatch } = useContext(Store) as any;

  const fetchProjectList = async () => {
    try {
      const res = await getAllProjects();
      if (res && res.status === 200) {
        const { body, status, error } = res.data;
        if (status === 200 && !error && body) {
          profileDispatch({
            type: UserProfileActions.SET_PROJECT_LIST,
            value: body
          });
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('error while fetching project data');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error while fetching project data');
    }
  };

  const fetchProjectData = async (projectId: string) => {
    try {
      profileDispatch({
        type: UserProfileActions.SET_LOADING,
        value: true
      });
      const res = await listProjectEntity(projectId);
      if (!res || res?.status !== 200 || !res?.data) {
        throw new Error('Error in fetching data');
      }
      const { body, status, error, message } = res.data;
      if (status !== 200 || error) {
        throw new Error(message);
      }
      const normalizedProjectData = Object.keys(body).reduce((acc: any, key: string) => {
        acc[key] = normalizeFavoritesAndProjectsKey(body[key]);
        return acc;
      }, {});

      const documents = normalizedProjectData?.aria_searches ?? [];
      const documentsWitProjects = documents.filter((doc: any) =>
        doc.inProjects.some((p: any) => p?.id?.toString() === projectId)
      );
      const quickAdvancedSearches = normalizedProjectData?.quick_advanced_searches ?? [];
      const applications = normalizedProjectData?.applications ?? [];

      // Searches is a combination of documentsWitProjects and quickAdvancedSearches
      const searches = [...documentsWitProjects, ...quickAdvancedSearches].sort(
        (searchA: any, searchB: any) =>
          new Date(searchA.date).getTime() - new Date(searchB.date).getTime()
      );

      profileDispatch({
        type: UserProfileActions.SET_SAVED_DATA,
        value: {
          searches,
          documents,
          applications
        }
      });
      profileDispatch({
        type: UserProfileActions.SET_CURRENT_PROJECT_ID,
        value: parseInt(projectId, 10) ?? 0
      });
    } catch (error) {
      const errorMessage = (error as Error)?.message ?? 'Something went wrong';
      await dispatch({
        type: Actions.SET_ALERT,
        value: { message: errorMessage, status: true }
      });
      profileDispatch({
        type: UserProfileActions.SET_CURRENT_PROJECT_ID,
        value: 0
      });
    } finally {
      profileDispatch({
        type: UserProfileActions.SET_LOADING,
        value: false
      });
    }
  };

  const updateProjectDetail = async (projectId: number, title: string, description: string) => {
    try {
      const res = await updateProject(projectId, { title, description });
      if (!res || res?.status !== 200) throw new Error('Fail to updated the project');

      const updatedProjectList = profileState.projectList.map((project: any) => {
        if (project?.project_id.toString() === projectId.toString()) {
          return {
            ...project,
            name: title,
            description
          };
        }
        return project;
      });
      profileDispatch({
        type: UserProfileActions.SET_PROJECT_LIST,
        value: updatedProjectList
      });
      dispatch({
        type: Actions.SET_ALERT,
        value: {
          message: 'Project updated successfully',
          status: true,
          color: 'success'
        }
      });
      return true;
    } catch (e) {
      dispatch({
        type: Actions.SET_ALERT,
        value: { message: e, status: true }
      });
      return false;
    }
  };

  const handleProjectDelete = async (
    projectId: number
  ): Promise<{ success: boolean; updatedProjectList: any[] }> => {
    try {
      const res = await deleteProject(projectId);
      if (res && res.status === 200) {
        const updatedProjectList = profileState.projectList.filter(
          (project: any) => project?.project_id.toString() !== projectId.toString()
        );
        profileDispatch({
          type: UserProfileActions.SET_PROJECT_LIST,
          value: updatedProjectList
        });
        dispatch({
          type: Actions.SET_ALERT,
          value: {
            message: 'Project deleted successfully',
            status: true,
            color: 'success'
          }
        });
        return { success: true, updatedProjectList };
      }
      dispatch({
        type: Actions.SET_ALERT,
        value: { message: 'Fail to deleted the project', status: true }
      });
      return { success: false, updatedProjectList: [] };
    } catch (e) {
      dispatch({
        type: Actions.SET_ALERT,
        value: { message: e, status: true }
      });
      return { success: false, updatedProjectList: [] };
    }
  };

  const creatNewProject = async (payload: any) => {
    try {
      const res = await createProject(payload);
      const { body, status, error, message } = res.data;
      if (error || status !== 200) {
        throw new Error(message);
      }
      const newProject = {
        id: body.id,
        project_id: body.id,
        name: body.name,
        description: payload.description,
        inProject: false
      };
      profileDispatch({
        type: UserProfileActions.SET_PROJECT_LIST,
        value: [...profileState.projectList, newProject]
      });
      dispatch({
        type: Actions.SET_ALERT,
        value: {
          message: 'Project Created successfully',
          status: true,
          color: 'success'
        }
      });
      return { success: true, project: newProject };
    } catch (e) {
      const message = (e as Error)?.message ?? 'Fail to Create the project';
      dispatch({
        type: Actions.SET_ALERT,
        value: { message, status: true }
      });
      return { success: false, project: null };
    }
  };

  // Helper function to update projects in the saved data
  const updateProjects = (projects: any[], project: any): any[] => {
    return project.inProject
      ? projects.filter(p => (p.project_id ?? p.id) !== (project.project_id ?? project.id))
      : [...projects, { id: project.project_id ?? project.id, name: project.name }];
  };

  const handleProjectsSearchTabFavorite = ({
    identifier,
    isFavorite
  }: {
    identifier: string;
    isFavorite: boolean;
  }) => {
    const { searches } = profileState.savedData;

    const searchIndex = searches.findIndex(search => search.id === identifier);

    if (searchIndex === -1) {
      throw new Error('Search not found');
    }
    try {
      const newSearches = [...searches];
      newSearches[searchIndex] = { ...newSearches[searchIndex], isFavorite };

      const newSavedData = {
        ...profileState.savedData,
        searches: newSearches
      };

      profileDispatch({
        type: UserProfileActions.SET_SAVED_DATA,
        value: newSavedData
      });
    } catch (e) {
      const errorMessage = (e as Error)?.message ?? 'Something went wrong';
      dispatch({
        type: Actions.SET_ALERT,
        value: { message: errorMessage, status: true }
      });
    }
  };

  const handleProjectsSearchTabProject = ({
    identifier,
    project
  }: {
    identifier: string;
    project: any;
  }) => {
    const { searches } = profileState.savedData;
    const { currentProjectId } = profileState;
    if (!currentProjectId) {
      throw new Error('Project not found');
    }
    const searchIndex = searches.findIndex(search => search.id === identifier);

    if (searchIndex === -1) {
      throw new Error('Search not found');
    }

    try {
      const newSearches = [...searches];

      const currentSearch = { ...newSearches[searchIndex] };
      if (project.project_id === currentProjectId) {
        newSearches.splice(searchIndex, 1);
      } else {
        currentSearch.inProjects = updateProjects(currentSearch.inProjects, project);
        newSearches[searchIndex] = currentSearch;
      }
      const newSavedData = {
        ...profileState.savedData,
        searches: newSearches
      };
      profileDispatch({
        type: UserProfileActions.SET_SAVED_DATA,
        value: newSavedData
      });
    } catch (e) {
      const errorMessage = (e as Error)?.message ?? 'Something went wrong';
      dispatch({
        type: Actions.SET_ALERT,
        value: { message: errorMessage, status: true }
      });
    }
  };

  const handleProjectsDocumentTabFavorite = ({
    documentSearchId,
    identifier
  }: {
    documentSearchId: string;
    // result ID of the result
    identifier: string;
  }) => {
    const newDocuments = [...profileState.savedData.documents];
    const listItemIndex = newDocuments.findIndex(
      (item: { id: string }) => item.id === documentSearchId
    );
    if (listItemIndex === -1) return;

    const ariaResultIndex = newDocuments[listItemIndex]?.aria_results?.findIndex(
      (item: { result_id: string }) => item?.result_id === identifier
    );

    if (ariaResultIndex === -1) return;
    const newAriaResults = [...newDocuments[listItemIndex].aria_results];
    newAriaResults[ariaResultIndex] = {
      ...newAriaResults[ariaResultIndex],
      isFavorite: !newAriaResults[ariaResultIndex].isFavorite
    };

    newDocuments[listItemIndex].aria_results = newAriaResults;

    const newSavedData = {
      ...profileState.savedData,
      documents: newDocuments
    };

    profileDispatch({
      type: UserProfileActions.SET_SAVED_DATA,
      value: newSavedData
    });
  };

  const handleProjectsDocumentTabProject = ({
    documentSearchId,
    identifier,
    project
  }: {
    documentSearchId: string;
    // result ID of the result
    identifier: string;
    project: any;
  }) => {
    const newDocuments = [...profileState.savedData.documents];
    const { currentProjectId } = profileState;
    const listItemIndex = newDocuments.findIndex(
      (item: { id: string }) => item.id === documentSearchId
    );
    if (listItemIndex === -1) return;

    const ariaResultIndex = newDocuments[listItemIndex]?.aria_results?.findIndex(
      (item: { result_id: string }) => item?.result_id === identifier
    );
    if (ariaResultIndex === -1) return;

    if (project.project_id === currentProjectId) {
      const newAriaResults = [...newDocuments[listItemIndex].aria_results];
      newAriaResults.splice(ariaResultIndex, 1);
      if (newAriaResults.length === 0) newDocuments.splice(listItemIndex, 1);
      else newDocuments[listItemIndex].aria_results = newAriaResults;
    } else {
      const newAriaResults = [...newDocuments[listItemIndex].aria_results];
      const currentAriaResult = { ...newDocuments[listItemIndex].aria_results[ariaResultIndex] };
      currentAriaResult.inProjects = updateProjects(currentAriaResult.inProjects, project);
      newAriaResults[ariaResultIndex] = currentAriaResult;
      newDocuments[listItemIndex].aria_results = newAriaResults;
    }
    const newSavedData = {
      ...profileState.savedData,
      documents: newDocuments
    };

    profileDispatch({
      type: UserProfileActions.SET_SAVED_DATA,
      value: newSavedData
    });
  };

  const handleProjectsApplicationTabFavorite = ({
    source,
    identifier
  }: {
    source: string;
    identifier: string;
  }) => {
    const newApplications = [...profileState.savedData.applications];
    const selectedApplicationIndex = newApplications?.findIndex((x: any) => {
      const appIdentifierKey = identifierKey[source];
      const appIdentifier = x?.[appIdentifierKey] ?? x?.application_number;
      return x.source === source && appIdentifier === identifier;
    });
    if (selectedApplicationIndex === -1) return;

    newApplications[selectedApplicationIndex].isFavorite =
      !newApplications[selectedApplicationIndex].isFavorite;

    const newSavedData = {
      ...profileState.savedData,
      applications: newApplications
    };
    profileDispatch({
      type: UserProfileActions.SET_SAVED_DATA,
      value: newSavedData
    });
  };

  const handleProjectsApplicationTabProject = ({
    source,
    identifier,
    project
  }: {
    source: string;
    identifier: string;
    project: any;
  }) => {
    const newApplications = [...profileState.savedData.applications];
    const { currentProjectId } = profileState;
    const selectedApplicationIndex = newApplications?.findIndex((x: any) => {
      const appIdentifierKey = identifierKey[source];
      const appIdentifier = x?.[appIdentifierKey] ?? x?.application_number;
      return x.source === source && appIdentifier === identifier;
    });
    if (selectedApplicationIndex === -1) return;
    if (project.project_id === currentProjectId) {
      newApplications.splice(selectedApplicationIndex, 1);
    } else {
      newApplications[selectedApplicationIndex].inProjects = updateProjects(
        newApplications[selectedApplicationIndex].inProjects,
        project
      );
    }
    const newSavedData = {
      ...profileState.savedData,
      applications: newApplications
    };
    profileDispatch({
      type: UserProfileActions.SET_SAVED_DATA,
      value: newSavedData
    });
  };

  const handleProjectsApplicationTabSubscription = ({
    source,
    identifier,
    isSubscribed
  }: {
    source: string;
    identifier: string;
    isSubscribed: boolean;
  }) => {
    const newApplications = [...profileState.savedData.applications];
    const selectedApplicationIndex = newApplications?.findIndex((x: any) => {
      const appIdentifierKey = identifierKey[source];
      const appIdentifier = x?.[appIdentifierKey] ?? x?.application_number;
      return x.source === source && appIdentifier === identifier;
    });
    if (selectedApplicationIndex === -1) return;

    newApplications[selectedApplicationIndex].isSubscribed = isSubscribed;
    const newSavedData = {
      ...profileState.savedData,
      applications: newApplications
    };
    profileDispatch({
      type: UserProfileActions.SET_SAVED_DATA,
      value: newSavedData
    });
  };

  return {
    projectDataList: profileState.projectList,
    isLoading: profileState.loading,
    projectData: profileState.savedData,
    fetchProjectData,
    fetchProjectList,
    updateProjectDetail,
    handleProjectDelete,
    creatNewProject,
    handleProjectsSearchTabFavorite,
    handleProjectsSearchTabProject,
    handleProjectsDocumentTabFavorite,
    handleProjectsDocumentTabProject,
    handleProjectsApplicationTabFavorite,
    handleProjectsApplicationTabProject,
    handleProjectsApplicationTabSubscription
  };
};

export default useProjectData;
