import { orderBy } from 'lodash';
import {
  horizontalBarDefaultConfig,
  todayAnnotationsDefaultConfig,
  todayVerticalLineDefaultConfig
} from '../../../pages/CDP/const';
import {
  COLOR_SCHEME_TIMELINE,
  CTTIMELINE_CHANGES_GRAPH_SORTING_OPTIONS
} from '../../../pages/ResultsPage/utils/constants';
import { categoryMappingValue, dateFields } from './consts';

export const getAnnotations = () => {
  const annotations: Array<any> = [];
  const today = new Date();
  const date = today.toISOString().split('T')[0];
  const annotationText = new Date().toLocaleDateString();
  annotations.push({
    ...todayAnnotationsDefaultConfig,
    x: date,
    text: annotationText
  });
  return annotations;
};

export const getShapes = () => {
  const shapes: Array<any> = [];
  const today = new Date();
  const date = today.toISOString().split('T')[0];
  shapes.push({
    ...todayVerticalLineDefaultConfig,
    line: {
      ...todayVerticalLineDefaultConfig.line,
      color: 'red'
    },
    x0: date,
    x1: date
  });
  return shapes;
};

export const getFilters = (filterData: any[], query: any[]) => {
  const dateRange: any = {};
  if (Object.keys(filterData).length > 0) {
    Object.entries(filterData).forEach(([key, val]) => {
      if (categoryMappingValue[key]) {
        // eslint-disable-next-line no-param-reassign
        key = categoryMappingValue[key];
      }
      if (dateFields[key]) {
        dateRange[key] = {};
        dateRange[key].gte = val[0] || '';
        dateRange[key].lte = val[1] || '';
      } else if (Array.isArray(val)) {
        // eslint-disable-next-line no-param-reassign
        query = [
          ...query,
          ...val.map((value: any) => ({
            category: key,
            categoryKey: key,
            searchTerm: value,
            condition: 'contains'
          }))
        ];
      } else {
        // eslint-disable-next-line no-param-reassign
        query = [
          ...query,
          { category: key, categoryKey: key, searchTerm: val, condition: 'contains' }
        ];
      }
    });
  }
  return { dateRange, query };
};

export const prepareDelayGraphData = (apiData: any, ctTimelineSelectedSort: string) => {
  const nctIds: Array<string> = [];
  const barGraphData: Array<any> = [];
  let minDelayDays = 0;
  let maxDelayDays = 0;

  const currentSortingOption = CTTIMELINE_CHANGES_GRAPH_SORTING_OPTIONS.find(
    (ele: any) => ele.id === ctTimelineSelectedSort
  );

  const newData = orderBy(
    apiData,
    [
      (item: any) =>
        item[currentSortingOption.fieldName] ||
        (currentSortingOption.sortType === 'asc' ? undefined : '')
    ],
    [currentSortingOption.sortType as any]
  );

  newData.forEach(
    ({
      acronym,
      nct_id: nctId,
      pcd_delay: pcdDelay,
      overall_status_formated: overallStatusFormated,
      overall_status: overallStatus,
      start_year: startYear,
      start_date: startDate,
      study_first_submitted_date: studyFirstSubmittedDate,
      last_updated_year: lastUpdatedYear,
      study_completion: studyCompletion,
      brief_title: briefTitle,
      primary_completion_date: primaryCompletionDate,
      enrollment: sampleSize,
      official_title: officialTitle,
      phase
    }: any) => {
      let hovertemplate = '';
      if (pcdDelay < 0) {
        hovertemplate = `Acceleration: ${-pcdDelay} | ${nctId}<br>`;
      } else {
        hovertemplate = `Delay: ${pcdDelay} | ${nctId}<br>`;
      }
      hovertemplate +=
        'N: %{customdata.sampleSize} | %{customdata.phase} | %{customdata.acronym}<br>' +
        '<extra></extra>';
      // NCT IDs to render in the Y - Axis
      nctIds.push(nctId);
      // Horizontal Bar Graph data
      // If the NCT ID Present in Review, it should be highlighted with green
      minDelayDays = Math.min(minDelayDays, pcdDelay);
      maxDelayDays = Math.max(maxDelayDays, pcdDelay);
      const barGraphDataObj = {
        ...horizontalBarDefaultConfig,
        name: nctId,
        offsetgroup: nctId,
        legendgroup: overallStatus,
        hovertemplate,
        base: [0],
        x: [pcdDelay],
        y: [nctId],
        text: `N: ${sampleSize} | ${phase}| ${acronym}`,
        textposition: 'inside',
        insidetextanchor: 'start',
        text_auto: '.2s',
        textfont: { color: 'white', size: 14 },
        customdata: [
          {
            sampleSize,
            phase,
            acronym,
            briefTitle,
            officialTitle,
            startYear,
            startDate,
            studyFirstSubmittedDate,
            lastUpdatedYear,
            studyCompletion,
            primaryCompletionDate,
            nctId
          }
        ],
        marker: {
          color: COLOR_SCHEME_TIMELINE[overallStatusFormated]
        },
        nctId
      };
      barGraphData.push({
        ...barGraphDataObj,
        text: pcdDelay,
        textposition: 'outside',
        insidetextanchor: 'start',
        textfont: { color: 'black', size: 12 }
      });
      if (pcdDelay === 0)
        barGraphData.push({
          ...barGraphDataObj,
          alignmentgroup: 'True',
          orientation: 'h',
          type: 'scatter',
          mode: 'markers',
          marker: {
            color: 'black',
            size: 5
          }
        });

      barGraphData.push(barGraphDataObj);
    }
  );
  const minMaxDelayDays = Math.max(Math.abs(minDelayDays - 100), Math.abs(maxDelayDays + 100));
  return {
    nctIds,
    barGraphData,
    minDelayDays: minMaxDelayDays * -1,
    maxDelayDays: minMaxDelayDays
  };
};

export const getCategoryKey = (category: string) => {
  const key = categoryMappingValue[category];

  if (!key) {
    return category;
  }

  return key;
};

export const getFormatFilterCNFQuery = (queryString: string) => {
  const conditions = queryString.match(/\((?:[^():]*:[^():]*)\)/g);

  // Initialize an object to store conditions by key
  const conditionMap: any = {};
  if (conditions) {
    conditions?.forEach((condition: any) => {
      const [key, value] = condition.slice(1, -1).split(':');
      if (conditionMap[key]) {
        // If key already exists, add value to the existing array
        conditionMap[key].push(value);
      } else {
        // Otherwise, create a new array for the key
        conditionMap[key] = [value];
      }
    });

    // Construct the optimized query string
    const buildOrCondition = (key: string, values: string[]) =>
      `(${values.map(value => `${key}:${value}`).join(' OR ')})`;

    const optimizedQuery = Object.entries(conditionMap)
      .map(([key, values]: any) =>
        values.length > 1 ? buildOrCondition(key, values) : `(${key}:${values[0]})`
      )
      .join(' AND ');

    return optimizedQuery;
  }
  return queryString;
};
