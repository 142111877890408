import { createSvgIcon } from '@mui/material';

const SuggestionIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
    <g clipPath='url(#clip0_12439_49179)'>
      <path
        d='M3.9126 10.3887C3.2095 9.73924 2.67319 8.92995 2.34908 8.02933C2.02497 7.12871 1.92259 6.16327 2.0506 5.2147C2.17718 4.25288 2.53509 3.33609 3.09366 2.54291C3.65223 1.74973 4.3948 1.10383 5.25772 0.660548C6.12064 0.21727 7.07818 -0.0101573 8.04827 -0.00224163C9.01835 0.00567404 9.97206 0.248697 10.8276 0.705997C11.6832 1.1633 12.4151 1.82124 12.9607 2.62342C13.5062 3.42561 13.8491 4.34812 13.96 5.31188C14.0709 6.27564 13.9464 7.25191 13.5972 8.15702C13.2481 9.06213 12.6847 9.86908 11.9553 10.5087C11.3271 11.0755 10.9043 11.8343 10.7526 12.6667H8.66727V7.2107C9.05588 7.07331 9.39254 6.81921 9.63121 6.48317C9.86988 6.14712 9.99889 5.74554 10.0006 5.33337H8.66727C8.66727 5.51018 8.59703 5.67975 8.47201 5.80477C8.34698 5.9298 8.17741 6.00003 8.0006 6.00003C7.82379 6.00003 7.65422 5.9298 7.5292 5.80477C7.40417 5.67975 7.33394 5.51018 7.33394 5.33337H6.0006C6.00231 5.74554 6.13133 6.14712 6.37 6.48317C6.60866 6.81921 6.94533 7.07331 7.33394 7.2107V12.6667H5.2506C5.05101 11.7872 4.58353 10.9913 3.9126 10.3887ZM5.33394 14V16H10.6673V14H5.33394Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_12439_49179'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'SuggestionIcon'
);
export default SuggestionIcon;
