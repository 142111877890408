import { createSvgIcon } from '@mui/material';

const HowToIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='42' height='32' viewBox='0 0 42 32' fill='none'>
    <path
      d='M30.5105 23.3755L37.6146 19.9048C38.3684 19.5118 39.2445 19.4243 40.0612 19.6603C40.8778 19.8964 41.5721 20.4379 42 21.1724L27.5923 30.7614C25.6556 31.6856 23.4729 31.9619 21.367 31.5494L11.3088 28.8676C10.3834 28.6209 9.39839 28.7416 8.55987 29.2044L6.47974 30.3537L6.62636 20.129L6.7096 20.1299C8.16514 19.0672 9.88536 18.426 11.6814 18.2768C13.4775 18.1276 15.2799 18.4762 16.8908 19.2843L17.4943 19.587C18.4386 20.0602 19.4776 20.3141 20.5337 20.3295L26.4269 20.4147C27.3297 20.4274 28.2064 20.7187 28.9374 21.2487C29.6683 21.7786 30.2178 22.5214 30.5105 23.3755Z'
      fill='#F7CAA5'
    />
    <path
      d='M6.47973 30.3538L6.4627 31.505L0 31.4123L0.204324 17.2307L6.66703 17.3244L6.62635 20.1291L6.47973 30.3538Z'
      fill='#616161'
    />
    <path
      d='M21.0691 0.264427C20.4186 0.25505 19.7727 0.374865 19.1688 0.61693C18.5649 0.858995 18.015 1.2185 17.551 1.6746C17.0871 2.13071 16.7183 2.67436 16.4659 3.27404C16.2136 3.87372 16.0828 4.51751 16.0811 5.16812V5.87852H17.5946V5.16812C17.5938 4.542 17.7661 3.92786 18.0924 3.3935C18.4187 2.85914 18.8864 2.42536 19.4437 2.14005C20.001 1.85475 20.6264 1.72903 21.2507 1.77676C21.8749 1.8245 22.4739 2.04384 22.9814 2.41057C23.4888 2.77729 23.8851 3.27713 24.1264 3.85489C24.3676 4.43265 24.4446 5.06585 24.3486 5.68457C24.2526 6.30329 23.9876 6.88345 23.5826 7.36099C23.1777 7.83853 22.6487 8.19488 22.0539 8.39067C21.5242 8.56581 21.0635 8.90437 20.7382 9.3577C20.4128 9.81103 20.2396 10.3558 20.2432 10.9138V13.3515H21.7567V10.9138C21.7538 10.675 21.8266 10.4414 21.9647 10.2466C22.1028 10.0517 22.299 9.90563 22.5253 9.82926C23.6287 9.46823 24.5682 8.7275 25.1767 7.73885C25.7852 6.7502 26.0232 5.57775 25.8485 4.43006C25.6738 3.28237 25.0976 2.23388 24.2224 1.47111C23.3473 0.708344 22.2299 0.280759 21.0691 0.264427Z'
      fill='#616161'
    />
    <path d='M21.7567 14.2974H20.2432V16.5676H21.7567V14.2974Z' fill='#616161' />
  </svg>,
  'HowTo'
);

export default HowToIcon;
