import { createSvgIcon } from '@mui/material';

const PlusIcon = createSvgIcon(
  <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14 7.31144H7.58333V0.894775H6.41667V7.31144H0V8.47811H6.41667V14.8948H7.58333V8.47811H14V7.31144Z'
      fill='currentColor'
    />
  </svg>,
  'Plus'
);

export default PlusIcon;
