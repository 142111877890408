import { createSvgIcon } from '@mui/material';

const ConsultationIcon = createSvgIcon(
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M31.7087 18.5176H26.5197C25.6512 18.5176 24.8432 18.7788 24.167 19.2241C24.4391 19.2847 24.7025 19.3667 24.957 19.467C26.9437 20.2487 28.3556 22.1831 28.3556 24.4452V27.634V28.2309H35.9995V22.808C35.9995 20.4418 34.0749 18.5176 31.7087 18.5176Z'
      fill='#0D917D'
    />
    <path
      d='M15.0436 19.4666C15.2985 19.3663 15.5616 19.2844 15.8332 19.2237C15.157 18.7788 14.3498 18.5176 13.482 18.5176H8.29044C5.92424 18.5176 4 20.4418 4 22.808V28.2305H11.6438V27.6336V24.4449C11.6438 22.1827 13.0565 20.248 15.0436 19.4666Z'
      fill='#0D917D'
    />
    <path
      d='M24.1409 19.9894C23.9032 19.9287 23.6596 19.8846 23.4089 19.8625C23.2764 19.8509 23.1432 19.8423 23.0081 19.8423H16.9925C16.8578 19.8423 16.7253 19.8509 16.5936 19.8625C16.3429 19.8842 16.0992 19.9284 15.8612 19.989C13.8711 20.4957 12.3926 22.2987 12.3926 24.4456V27.6343V28.2312V30.3568H27.608V28.2312V27.6343V24.4456C27.608 22.299 26.1302 20.4964 24.1409 19.9894Z'
      fill='#0D917D'
    />
    <path
      d='M29.9706 17.5843C32.1603 17.5843 33.9412 15.8034 33.9412 13.6137C33.9412 11.424 32.1607 9.64307 29.9706 9.64307C27.781 9.64307 26 11.4244 26 13.6137C26 15.8034 27.7814 17.5847 29.9706 17.5843Z'
      fill='#0D917D'
    />
    <path
      d='M9.97062 17.5843C12.1603 17.5843 13.9412 15.8034 13.9412 13.6137C13.9412 11.424 12.1607 9.64307 9.97062 9.64307C7.78096 9.64307 6 11.4244 6 13.6137C6 15.8034 7.78138 17.5847 9.97062 17.5843Z'
      fill='#0D917D'
    />
    <path
      d='M19.9706 18.5843C22.1603 18.5843 23.9412 16.8034 23.9412 14.6137C23.9412 12.424 22.1607 10.6431 19.9706 10.6431C17.781 10.6431 16 12.4244 16 14.6137C16 16.8034 17.7814 18.5847 19.9706 18.5843Z'
      fill='#0D917D'
    />
  </svg>,
  'Consultation'
);

export default ConsultationIcon;
