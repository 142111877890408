/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext, useCallback } from 'react';

// mui
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { FolderIcon } from '../../../assets/svgs/Icons';

// store
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';

// Custom components and api's
import PDFPreview from '../../../components/PdfPreview/PDFPreview';
import { getDocumentSourceName } from '../../SearchResults/utils/documentResultsUtils';
import { getPediatricsDocumentsSignedURL } from '../../../api/pages/Pediatrics';

// style
import styles from '../styles/DatagridPDFCell.styles';

// eslint-disable-next-line react/prop-types, no-unused-vars
const GridCellDouments = ({ value, type, guidanceRef, row }) => {
  const [PDFData, setPDFData] = useState([false, {}]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [linkData, setLinkData] = useState([]);
  const { dispatch } = useContext(GlobalStore);
  const [title, setTitle] = useState('');

  const handleSignedURL = useCallback(async pdfLink => {
    const res = await getPediatricsDocumentsSignedURL({
      source: 'pips',
      s3_path: pdfLink
    });
    return res?.data?.body?.s3_url ?? '';
  }, []);

  const handleOpenPopup = e => {
    setAnchorEl(e.currentTarget);
    const data = [];
    const paths = row.s3_paths;
    const links = row.s3_paths;
    paths.forEach((ele, ind) => {
      const name = ele.split('/');
      data.push({ docName: name[name.length - 1], docLink: links[ind] });
    });
    setLinkData(data);
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
    setLinkData([]);
  };

  const handlePDFOpen = async (e, linkText, name) => {
    setTitle(name);
    const link = await handleSignedURL(linkText);
    setPDFData([true, link]);
  };

  const handleChatRia = ({ pdfUrl = '' }) => {
    const mappedSourceName = getDocumentSourceName('PIP');
    dispatch({
      type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM,
      value: 'document'
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: mappedSourceName
    });

    let docTitle = '';
    if (row?.active_ingredient) {
      docTitle =
        row.active_ingredient?.charAt(0)?.toUpperCase() +
          row.active_ingredient?.slice(1)?.toLowerCase() || '';
    }
    if (row.trade_name) {
      docTitle += ` (${row.trade_name?.toUpperCase()})`;
    }
    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: {
        blob: pdfUrl,
        url: pdfUrl,
        item: {
          decision_date: row?.decision_date ?? '',
          'decision-number': row?.decision_number ?? '',
          formulation: row?.formulations ?? '',
          route: row?.routes ?? '',
          active_ingredient: docTitle
        },
        source: mappedSourceName,
        triggerReopenChatRia: false
      }
    });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
    setPDFData([false, {}]);
    handleClosePopup();
  };

  return (
    <Box>
      <Stack
        direction='row'
        alignItems='center'
        spacing={0.5}
        sx={styles.linkItem}
        onClick={e => handleOpenPopup(e)}>
        <FolderIcon sx={{ fontSize: 12 }} />
        <Typography variant='subtitle2' sx={styles.pdfCellText}>
          {row.s3_paths?.length}
        </Typography>
      </Stack>
      {linkData?.length > 0 && (
        <Popover
          id='simple-popover'
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClosePopup}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          elevation={1}>
          <Grid container sx={styles.popoverDiv}>
            <Grid container>
              <Grid item xs={6}>
                <Grid>
                  <Typography sx={styles.linkHeading}>Documents</Typography>
                  {linkData.map(item => {
                    return (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <Link
                        key={item.docName}
                        sx={styles.link}
                        onClick={event => handlePDFOpen(event, item.docLink, item.docName)}>
                        {item.docName}
                      </Link>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Popover>
      )}

      <Box>
        <PDFPreview
          open={PDFData[0] !== undefined ? PDFData[0] : false}
          pdfUrl={PDFData[1].toString()}
          onClose={() => {
            setPDFData([false, {}]);
          }}
          handleChatRia={handleChatRia}
          title={title}
        />
      </Box>
    </Box>
  );
};

const DatagridPDFCell = params => {
  const { value, row, field } = params;
  return (
    <GridCellDouments value={value || '0'} type={field} guidanceRef={row.guidance_ref} row={row} />
  );
};

export default DatagridPDFCell;
