import React, { createContext, useReducer } from 'react';
import reducers, { initialState } from './reducers';

type Props = {
  children: React.ReactNode;
};

export interface lettersContextInterface {
  lettersStats: any;
  tableData: any[];

  adviceTypeTreemap: any[];
  statusTreemap: any[];
  docTypeTreemap: any[];
  issueDateBar: any[];
  treemapCharts: any[];

  lettersErrors: any[];
  filters: any[];
  availableFilters: any;
  search: any;
  loading: boolean;
  isFiltersApplied: boolean;
  totalRecord: number;
  sortFields: any[];
  askRiaFilters: any[];
  dataGridFilters: any;
}

const lettersStore = createContext<{
  lettersState: lettersContextInterface;
  lettersDispatch: React.Dispatch<any>;
}>({ lettersState: initialState, lettersDispatch: () => null });
const { Provider } = lettersStore;

export const LettersStateProvider = ({ children }: Props) => {
  const [lettersState, lettersDispatch] = useReducer(reducers, initialState);
  return <Provider value={{ lettersState, lettersDispatch }}>{children}</Provider>;
};

export default lettersStore;
