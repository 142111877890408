import { Box, Typography } from '@mui/material';
import styles from '../../../components/Header/styles/NotificationsPopup.styles';
import React from 'react';

const NoNotification = ({ type }: any) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      flexDirection='column'
      justifyContent='center'
      height={150}>
      <Typography align='center' fontSize='medium' style={styles.noDataText}>
        {type === 'subscription' ? 'Nothing subscribed' : 'No new notifications'}
      </Typography>
      <Typography
        align='center'
        fontSize='small'
        sx={{ pt: 2 }}
        fontStyle='italic'
        style={styles.noDataText}>
        <b>Subscribe to</b> <br /> Applications, Quick Search, Advanced search and more!
      </Typography>
    </Box>
  );
};
export default NoNotification;
