import palette from '../../../themev5/palette';

const rootBase = {
  marginTop: '16px',
  paddingBottom: '56px',
  '& .cellValue': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& .fda_org_cell': {
      height: '26px',
      '& .fda_org_cell__more-text': {
        display: 'inline !important'
      }
    }
  },
  '& .MuiDataGrid-root': {
    width: '100%',
    '& .MuiDataGrid-columnHeader': {
      color: palette.primary.main,
      fontWeight: 'bold',
      bgcolor: palette.primary.backgroundDark
    },
    '& .MuiDataGrid-columnsContainer': {
      color: palette.primary.lightBluish,
      background: '#F7F7F7'
    },
    '& .MuiDataGrid-main': {
      background: '#fff'
    }
  }
};

const styles = {
  root: {
    ...rootBase,
    height: 'calc(100vh - 210px)'
  },
  rootWithComparisonEnabled: {
    ...rootBase,
    height: 'calc(100vh - 275px)'
  }
};

export default styles;
