const styles = {
  scrollContainer: {
    '&::-webkit-scrollbar': {
      width: '7px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#E0E0E0FF',
      borderRadius: '5px'
    }
  },
  clearAll: {
    textTransform: 'capitalize',
    color: 'gray.700',
    fontSize: 14,
    fontWeight: 700,
    '&:hover': {
      background: 'none',
      boxShadow: '0 0 2px #0000001a'
    },
    justifyContent: 'flex-start'
  },
  applyButton: {
    color: 'white.main',
    borderRadius: 20,
    fontSize: 14,
    '&:hover': {
      boxShadow: '0 0 8px #0000004d'
    },
    textTransform: 'capitalize',
    px: 3,
    fontWeight: 700
  },
  icon: {
    color: 'gray.lightVariant4',
    cursor: 'pointer'
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  header: {
    display: 'flex',
    flexDirection: 'row-reverse',
    mt: 1
  },
  sidebarHeaderFooter: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  closeIconButton: { p: 0, minWidth: 0, mr: 0.5 }
};

const autoSearchOptionsStyles = {
  searchInput: {
    borderRadius: 2,
    mb: 1.5,
    width: 247,
    height: 32
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    height: 131
  }
};

const dateYearRangeStyles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    '& > .MuiFormControl-root > .MuiInputBase-root': {
      paddingRight: '10px'
    }
  }
};

const filterSidebarStyles = {
  drawer: {
    '& .MuiDrawer-paper': {
      width: 359
    }
  },
  contentContainer: {
    overflowY: 'scroll',
    display: 'flex',
    gap: 1.5,
    flexDirection: 'column',
    pr: 4,
    flexGrow: 1
  },
  searchContent: {
    borderRadius: 2,
    backgroundColor: 'gray.lightBackground',
    p: 2,
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 0 8px #0000001a'
    }
  },
  search: {
    my: 1,
    backgroundColor: 'white.main',
    borderRadius: 2
  },
  searchedText: {
    backgroundColor: 'primary.50',
    borderRadius: 2,
    px: 1,
    py: 0.5,
    display: 'flex',
    alignItems: 'center',
    gap: 0.5
  },
  hideShowSearchedText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #289D78',
    color: 'primary.avatar',
    fontWeight: 700,
    fontSize: 10,
    cursor: 'pointer'
  },
  radioContent: {
    borderRadius: 2,
    backgroundColor: 'gray.lightBackground',
    p: 2,
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 0 8px #0000001a'
    }
  },
  dateYearRangeContent: {
    borderRadius: 2,
    backgroundColor: 'gray.lightBackground',
    display: 'flex',
    flexDirection: 'column',
    p: 2,
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 0 8px #0000001a'
    }
  },
  numberRangeContent: {
    borderRadius: 2,
    backgroundColor: 'gray.lightBackground',
    display: 'flex',
    flexDirection: 'column',
    p: 2,
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 0 8px #0000001a'
    }
  },
  selectOptionsContent: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 2,
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 0 8px #0000001a'
    },
    width: 281,
    height: 40
  },
  selectOptionsText: {
    textTransform: 'capitalize',
    fontSize: 14,
    ml: 2,
    px: 0
  },
  addIcon: {
    fontSize: 16,
    mr: 2
  },
  tooltip: {
    bgcolor: 'rgba(50.41, 50.41, 50.41, 0.70)',
    fontSize: 10,
    borderRadius: 3,
    width: 201,
    height: 40
  }
};

const filterSidebarOptionsStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    right: 0,
    width: 309,
    height: '100vh',
    marginRight: '359px',
    borderRight: '1px solid #289D78',
    bgcolor: 'white.main'
  },
  search: { ml: 4, mt: 4, mb: 1.5 },
  contentContainer: {
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  contentText: {
    wordBreak: 'break-word',
    pl: 4,
    pr: 2,
    color: 'gray.800'
  }
};

const filterStyles = {
  outerContainer: {
    display: 'flex',
    width: 'calc(100% - 32px)',
    height: '56px',
    position: 'fixed',
    bottom: 8,
    backgroundColor: 'gray.300',
    borderRadius: 3,
    ml: '-7px',
    zIndex: 1,
    overflow: 'hidden'
  },
  innerContainer: { display: 'flex', gap: 2, alignSelf: 'center', px: '16px' },
  filterIconBox: {
    display: 'flex',
    backgroundColor: 'gray.600',
    px: '4px',
    alignItems: 'center'
  },
  filterIcon: {
    width: '16px',
    height: '16px',
    color: 'gray.0'
  },
  filterText: { display: 'flex', flexDirection: 'row', mb: 1 },
  categoryButton: {
    borderRadius: 2,
    textTransform: 'capitalize',
    fontWeight: 600,
    fontSize: '0.75rem',
    px: 1.5,
    '&:hover': {
      boxShadow: '0 0 8px #0000004d'
    }
  },
  allFiltersButton: {
    textTransform: 'capitalize',
    color: 'gray.800',
    fontWeight: 700,
    fontSize: 12,
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 0 10px #0000001a',
      background: 'none'
    }
  },
  countCircle: {
    width: '2.2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: 'primary.600',
    color: 'white.main',
    fontWeight: 700,
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 0 8px #0000004d'
    }
  }
};

const applyFilterAlertStyles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 426,
    height: 188,
    bgcolor: 'orange.light',
    borderRadius: 2,
    boxShadow: '0 0 8px #0000004d'
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    mb: 2,
    mt: 3,
    px: 4
  },
  warningIcon: { alignSelf: 'center', mb: 2, color: 'orange.darkVariant1' },
  message: {
    color: 'orange.darkVariant1',
    fontWeight: 500,
    lineHeight: '18px',
    fontSize: 16
  },
  confirmButtonGroup: {
    display: 'flex',
    justifyContent: 'center',
    gap: 1.5,
    mt: 2,
    mb: 3
  },
  yesButton: {
    width: 100,
    textTransform: 'none',
    color: 'orange.light',
    border: '1px solid #BD4B00',
    borderRadius: 10,
    px: 4,
    fontWeight: 700,
    fontSize: 16,
    bgcolor: 'orange.darkVariant1',
    '&:hover': {
      boxShadow: '0 0 8px #0000004d',
      bgcolor: 'orange.darkVariant1'
    }
  },
  noButton: {
    width: 100,
    textTransform: 'none',
    color: 'orange.darkVariant1',
    border: '1px solid #BD4B00',
    borderRadius: 20,
    px: 2,
    fontWeight: 700,
    fontSize: 16,
    bgcolor: 'white.main',
    '&:hover': {
      boxShadow: '0 0 8px #0000004d',
      bgcolor: 'white.main'
    }
  }
};

const radioButtonStyles = {
  '&.Mui-checked': {
    color: 'primary.600'
  }
};

export {
  styles,
  autoSearchOptionsStyles,
  dateYearRangeStyles,
  filterSidebarStyles,
  filterSidebarOptionsStyles,
  filterStyles,
  applyFilterAlertStyles,
  radioButtonStyles
};
