import React from 'react';

const ChartIcon = () => {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        className='cdpIconColor'
        d='M22.5 22H2.5C2.09 22 1.75 21.66 1.75 21.25C1.75 20.84 2.09 20.5 2.5 20.5H22.5C22.91 20.5 23.25 20.84 23.25 21.25C23.25 21.66 22.91 22 22.5 22Z'
        fill='#27AE60'
      />
      <path
        className='cdpIconColor'
        d='M10.25 4V22H14.75V4C14.75 2.9 14.3 2 12.95 2H12.05C10.7 2 10.25 2.9 10.25 4ZM3.5 10V22H7.5V10C7.5 8.9 7.1 8 5.9 8H5.1C3.9 8 3.5 8.9 3.5 10ZM17.5 15V22H21.5V15C21.5 13.9 21.1 13 19.9 13H19.1C17.9 13 17.5 13.9 17.5 15Z'
        fill='#27AE60'
      />
    </svg>
  );
};

export default ChartIcon;
