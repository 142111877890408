import { useState, useEffect, memo, useRef } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Typography, Box, Paper, Popper } from '@mui/material';
import formatData from './utils';

interface GridCellExpandProps {
  value: any;
  width: number;
}

const GridCellExpand = memo((props: GridCellExpandProps) => {
  const { width, value } = props;
  const wrapper = useRef<HTMLDivElement | null>(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);
  const [description, setDescription] = useState([]);

  const handleMouseEnter = () => {
    if (value) {
      setShowPopper(true);
      setAnchorEl(cellDiv.current);
      setShowFullCell(true);
    }
  };

  const handleMouseLeave = () => {
    if (value) {
      setShowFullCell(false);
    }
  };

  useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    const handleKeyDown = (nativeEvent: KeyboardEvent) => {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  useEffect(() => {
    if (value) {
      const formattedData = formatData(value);
      setDescription(formattedData);
    }
  }, [value]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: 1,
        height: 1,
        position: 'relative',
        display: 'flex'
      }}>
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0
        }}
      />
      <Box
        ref={cellValue}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        className='cellValue'>
        {/* extract descriptions and display as text in grid cell without hover  */}
        {/* if value is not available display hyphen in cell */}
        {value
          ? value
              .split(',')
              .map((each: any) => each.split(':')[0]?.trim())
              .join(', ')
          : '---'}
      </Box>
      {!!value && showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          placement='bottom'
          style={{ width }}>
          <Paper
            elevation={1}
            sx={{
              maxHeight: 300,
              overflowY: 'auto',
              minHeight: wrapper.current!.offsetHeight - 3,
              borderRadius: '8px',
              p: 1,
              width: 400,
              ml: -10
            }}>
            {description?.map((item: any, index: number) => (
              <Box key={item} mt={index % 2 === 0 ? '1em' : 0}>
                <Box
                  sx={{
                    width: '100%',
                    textAlign: 'left',
                    backgroundColor: 'primary.backgroundDark',
                    marginBottom: '10px',
                    borderRadius: '16px',
                    padding: 2,
                    textTransform: 'capitalize'
                  }}>
                  <Typography style={{ fontSize: '14px' }}>
                    {item.includes(':') ? (
                      <>
                        {/* extract header and display into the custom cards when hovers into the cell */}
                        <b>{item.split(':')[0]}</b>
                        <br />
                      </>
                    ) : (
                      item
                    )}
                    {/* extract description and display into the custom cards when hovers into the cell */}
                    {item.includes(':') ? item.split(':')[1] : ''}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

const DatagridNestedListView = (params: GridRenderCellParams) => {
  const { value, colDef } = params;
  return <GridCellExpand value={value} width={colDef.computedWidth} />;
};

export default DatagridNestedListView;
