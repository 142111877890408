import React from 'react';
import { createSvgIcon } from '@mui/material';

const HideIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='15' height='14' viewBox='0 0 15 14' fill='none'>
    <path
      d='M14.3955 6.52239C13.8319 5.30014 13.0065 4.21678 11.9776 3.34906L13.7463 1.58039L12.9208 0.754395L10.9999 2.67356C9.93672 2.05856 8.72814 1.7397 7.49993 1.75014C3.12493 1.75014 1.11651 5.40239 0.604342 6.52239C0.535589 6.67249 0.5 6.83564 0.5 7.00073C0.5 7.16582 0.535589 7.32897 0.604342 7.47906C1.16792 8.70131 1.99339 9.78467 3.02226 10.6524L1.25418 12.4211L2.07901 13.2459L3.99993 11.3267C5.06313 11.9417 6.27171 12.2606 7.49993 12.2501C11.8749 12.2501 13.8833 8.5979 14.3955 7.47789C14.4641 7.32797 14.4996 7.16503 14.4996 7.00014C14.4996 6.83526 14.4641 6.67232 14.3955 6.52239ZM1.66659 7.00656C2.10409 6.04698 3.82084 2.91681 7.49993 2.91681C8.41552 2.91146 9.31913 3.12506 10.1354 3.53981L9.10584 4.56939C8.5458 4.19758 7.87437 4.03098 7.20548 4.09785C6.53658 4.16473 5.91143 4.46098 5.43609 4.93631C4.96076 5.41165 4.66451 6.0368 4.59764 6.7057C4.53076 7.37459 4.69736 8.04602 5.06918 8.60606L3.85643 9.81881C2.92331 9.05895 2.1746 8.09744 1.66659 7.00656ZM9.24993 7.00014C9.24993 7.46427 9.06555 7.90939 8.73736 8.23758C8.40917 8.56577 7.96406 8.75014 7.49993 8.75014C7.24006 8.74914 6.98379 8.68931 6.75034 8.57514L9.07493 6.25056C9.18909 6.48401 9.24892 6.74028 9.24993 7.00014ZM5.74993 7.00014C5.74993 6.53602 5.9343 6.0909 6.26249 5.76271C6.59068 5.43452 7.0358 5.25014 7.49993 5.25014C7.75979 5.25115 8.01606 5.31098 8.24951 5.42514L5.92493 7.74973C5.81076 7.51628 5.75093 7.26001 5.74993 7.00014ZM7.49993 11.0835C6.58433 11.0888 5.68072 10.8752 4.86443 10.4605L5.89401 9.43089C6.45405 9.80271 7.12548 9.96931 7.79437 9.90243C8.46327 9.83556 9.08842 9.53931 9.56376 9.06398C10.0391 8.58864 10.3353 7.96349 10.4022 7.29459C10.4691 6.6257 10.3025 5.95427 9.93068 5.39423L11.1428 4.18206C12.0778 4.94262 12.8269 5.90643 13.3333 7.00014C12.8876 7.96673 11.1703 11.0835 7.49993 11.0835Z'
      fill='currentColor'
    />
  </svg>,
  'Hide'
);

export default HideIcon;
