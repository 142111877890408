import { GridColDef } from '@mui/x-data-grid-pro';
import { FILTER_TYPE } from '../../components/NewFilters/constants';
import { PIECHARTCOLOR } from '../../components/CustomComponents/Graphs/constant';
import DatagridTooltip from '../../components/Datagrid/DatagridTooltip';
import DatagridDate from '../../components/Datagrid/DatagridDate';
import renderCellExpand from '../../components/Datagrid/DatagridPopover';
import DataGridListView from './components/DatagridListView/DataGridListView';
import DocumentView from './components/DocumentView';
import getFormatDataForCSV from '../ResultsPage/utils/getFormatDataForCSV';
import getProjectDetails from './utils/getProjectDetails';

type CustomGridColDef = GridColDef & {
  capitalCasing?: Boolean;
};

export const DRUG_DEVELOPMENT_TOOLS_FILTERS = [
  {
    id: 1,
    label: 'Enter Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Organization Name',
    value: 'organization_name',
    elasticSearchType: 'organization_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Project Type',
    value: 'program_type_desc',
    elasticSearchType: 'program_type_desc',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Project Number',
    value: 'project_number',
    elasticSearchType: 'project_number',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Project Name',
    value: 'project_title',
    elasticSearchType: 'project_title',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Submission Stage',
    value: 'submission_stage',
    elasticSearchType: 'submission_stage',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 7,
    label: 'Submission Status',
    value: 'submission_status',
    elasticSearchType: 'submission_status',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 8,
    label: 'Date',
    value: 'latest_stage_date',
    elasticSearchType: 'latest_stage_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  }
];

export const DATA_GRID_COLUMNS: CustomGridColDef[] = [
  {
    field: 'document_count',
    headerName: 'Documents',
    headerClassName: 'table-header',
    width: 100,
    cellClassName: 'table-row',
    disableExport: true,
    sortable: false,
    renderCell: DocumentView
  },
  {
    field: 'project_number',
    headerName: 'Project Number',
    headerClassName: 'table-header',
    width: 170,
    cellClassName: 'table-row'
  },
  {
    field: 'project_title',
    headerName: 'Project Name',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    capitalCasing: false,
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.project_title}`
  },
  {
    field: 'latest_stage_date',
    headerName: 'Date',
    headerClassName: 'table-header',
    width: 120,
    cellClassName: 'table-row',
    renderCell: DatagridDate
  },
  {
    field: 'project_details',
    headerName: 'Program details',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    disableExport: true,
    renderCell: DataGridListView
  },
  {
    field: 'latest_submission_stage_type',
    headerName: 'Submission Stage',
    headerClassName: 'table-header',
    width: 200,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'latest_submission_status',
    headerName: 'Submission Status',
    headerClassName: 'table-header',
    width: 150,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'cou_statement',
    headerName: 'COU Statement',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.cou_statement}`
  },
  {
    field: 'requestor',
    headerName: 'Requestor',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    disableExport: true,
    renderCell: DataGridListView
  },
  {
    field: 'organization_name',
    headerName: 'Organization Name',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.organization_name}`
  },
  {
    field: 'project_details_csv',
    headerName: 'Project details',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${getProjectDetails(row)}`
  },
  {
    field: 'requestor_csv',
    headerName: 'Requestor',
    headerClassName: 'table-header',
    width: 220,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      `${
        row.requestor && Object.keys(row.requestor).length !== 0
          ? getFormatDataForCSV([row.requestor])
          : ''
      }`
  }
];

export const CHART = [
  {
    label: 'Project Type',
    apiKey: 'program_type_desc',
    graphItem: 'program_type_desc',
    count: 4
  },
  {
    label: 'Submission Status',
    apiKey: 'submission_status',
    graphItem: 'submission_status',
    count: 9
  }
];

export const barChartStyles = {
  colors: ['#B1BBE4'],
  padding: 0.8,
  labelTextColor: '#ffffff',
  axisText: '#A3A3A3'
};

export const pieChartStyles = {
  colors: PIECHARTCOLOR,
  pieLabel: true,
  pieLegend: false,
  innerRadius: 0.7,
  margin: { top: 20, right: 20, bottom: 20, left: 20 },
  enableLabels: 'both'
};

export const REPORT_QUERIES = [
  {
    id: 'ddt_projects_by_year',
    title: {
      title: 'Drug Development Tool Projects by Year',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['latest_stage_date'], agg_function: 'count', sort_on_axis: 'x' },
    graphStyle: {
      ...barChartStyles,
      axisLeft: {
        tickRotation: 0
      }
    }
  },
  {
    id: 'program_type_desc',
    title: {
      title: 'Project Type',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['program_type_desc'],
      agg_function: 'count'
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'submission_status',
    title: {
      title: 'Submission Status',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['submission_status'],
      agg_function: 'count'
    },
    graphStyle: pieChartStyles
  }
];

export const DATA_GRID_LIST_VIEW_MAPPER: any = {
  address: 'Address',
  city: 'City',
  country: 'Country',
  email: 'Email',
  first_name: 'First Name',
  last_name: 'Last Name',
  org_name: 'Organization Name',
  phone_number: 'Phone Number',
  state: 'State',
  animal_specie_desc: 'Animal Species',
  challenge_agent: 'Challenge Agent',
  challenge_agent_desc: 'Challenge Agent Category',
  biomarker_category_desc: 'Biomarker Category',
  biomarker_type_desc: 'Biomarker Type',
  disease_or_condition_desc: 'Disease or Condition',
  measure_of_desc: 'Measure of',
  min_age: 'Minimum Age',
  product_type_desc: 'Product Type',
  therapeutic_area_desc: 'Therapeutic Area',
  outcome_of_interest: 'Outcome of Interest',
  age_group_desc: 'Age Group',
  coa_type_desc: 'COA Type',
  concept_of_interest: 'Concept of Interest',
  istand_type_desc: 'ISTAND Type',
  program_type: 'Program Type'
};

export const PROJECT_DETAIL_MAPPER: any = {
  1: 'amqp_details',
  2: 'bmq_detail',
  3: 'coaqp_detail',
  4: 'istand_detail'
};

export const ENTITY_MAPPING: { [key: string]: any } = {
  stage_iteration_number: { name: 'Iternation Number ' },
  stage_date: { name: 'Date ' },
  submission_stage_type: { name: 'Stage ' },
  submission_milestone: { name: 'Document Type ' },
  submission_status: { name: 'FDA Determination ' }
};

const day = new Date();
const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
export const CSV_FILENAME = {
  prefix: 'ria',
  module: 'dd-tools',
  postfix: date,
  additional: '',
  extension: ''
};

export const PAGINATION_DEFAULT_OPTIONS = {
  limit: 20,
  offset: 0
};

export const EXPORT_TYPE = {
  csv: 'csv',
  xlsx: 'xlsx'
};

export const DD_TOOLS_COLUMN_NAME_MAPPING = {
  project_number: 'Project Number',
  latest_stage_date: 'Date',
  project_details: 'Program details',
  latest_submission_stage_type: 'Submission Stage',
  latest_submission_status: 'Submission Status',
  cou_statement: 'COU Statement',
  requestor: 'Requestor',
  organization_name: 'Organization Name'
};
