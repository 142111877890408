const styles: any = {
  loadingButton: {
    mt: 0.5,
    mb: 0.5,
    p: 0.5,
    height: '40px',
    minWidth: '100px',
    textTransform: 'capitalize',
    fontSize: 12,
    borderRadius: 10,
    bgcolor: 'primary.main',
    color: 'white.main',
    cursor: 'pointer'
  },
  FilterNode: {
    marginLeft: 1,
    width: 'fit-content',
    marginTop: 0.5,
    borderRadius: 10,
    fontSize: 12,
    padding: '3px 10px',
    display: 'inline-flex',
    align: 'center',
    backgroundColor: 'primary.backgroundDark'
  },
  synonyms: {
    maxHeight: '19vh',
    overflow: 'auto',
    width: '100%',
    alignContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  node_count: {
    width: 'fit-content',
    padding: 0.5,
    alignItems: 'center',
    display: 'inline-flex',
    borderRadius: 2.5,
    margin: 0.5,
    fontSize: '1.5vh',
    boxShadow: '0px 5px 10px #00000008',
    bfontFamily: 'Nunito Sans',
    color: 'gray.darkVariant',
    backgroundColor: 'gray.background',
    border: '0.5px solid #707070'
  },
  node: {
    width: 'fit-content',
    padding: 0.5,
    alignItems: 'center',
    display: 'inline-flex',
    borderRadius: 2.5,
    margin: 0.5,
    fontSize: '1.5vh',
    boxShadow: '0px 5px 10px #00000008',
    fontFamily: 'Nunito Sans',
    color: 'gray.darkVariant',
    backgroundColor: 'gray.background',
    border: '0.5px solid #707070'
  }
};

export default styles;
