import { useCallback, useContext, useMemo, useState } from 'react';
import { cloneDeep, isEqual } from 'lodash';
import getATCTreeCount from './utils/getATCTreeCount';

import { getATCTreeAPIData } from '../../api/pages/CardsPage';

import ResultsStore from '../../store/SearchResults';
import ResultsAction from '../../store/SearchResults/actions';

export const setIsSelected = (node: any, selectedATCCodes: any) => {
  // eslint-disable-next-line
  node.isSelected = selectedATCCodes.includes(node.id);
  if (node.children?.length) {
    node.children?.forEach((n: any) => setIsSelected(n, selectedATCCodes));
  }
  return node;
};

const useATCClassification = () => {
  const [treeData, setTreeData]: any = useState([]);
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const [selectedATCCodes, setATCCodes]: any = useState([
    ...(resultsState.filters?.atc_code?.map((code: string) => code.toUpperCase()) || [])
  ]);
  const [isLoading, setLoading] = useState(false);

  const { search_term: searchTerm } = resultsState.entities;

  const getATCTreeData = useCallback(
    async (src: string) => {
      setLoading(true);
      try {
        const response = await getATCTreeAPIData(src.toLowerCase(), searchTerm);
        if (response?.data?.body) {
          const applications = Object.entries(resultsState.applicationResults || {}).reduce(
            (acc: Array<any>, [, value]: any) => {
              return [...acc, ...value.results];
            },
            []
          );
          let countData = getATCTreeCount(cloneDeep(response.data?.body), applications);
          // Setting the default expanded nodes
          if (countData?.length) {
            countData = countData.map((tree: any) => ({
              ...tree,
              isExpanded: tree.children?.length > 0
            }));
          }
          setTreeData(countData);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [resultsState.applicationResults]
  );

  const updateATCFilters = (atcCode: string) => {
    let newATCCodes = [...selectedATCCodes];
    if (selectedATCCodes.includes(atcCode)) {
      newATCCodes = selectedATCCodes.filter((code: string) => code !== atcCode);
    } else {
      newATCCodes = [...newATCCodes, atcCode];
    }
    setATCCodes(newATCCodes);
  };

  const applyFilter = useCallback(() => {
    let payloadStub: { [key: string]: any } = {};

    payloadStub = {
      ...resultsState.filters,
      atc_code: selectedATCCodes
    };

    if (!selectedATCCodes?.length) {
      delete payloadStub.atc_code;
    }

    resultsDispatch({ type: ResultsAction.SET_FILTERS, value: payloadStub });
  }, [selectedATCCodes]);

  const resetATCFilters = () => {
    let payloadStub: { [key: string]: any } = {};

    payloadStub = {
      ...resultsState.filters
    };

    if (payloadStub.atc_code) {
      delete payloadStub.atc_code;
    }
    setATCCodes([]);
    resultsDispatch({ type: ResultsAction.SET_FILTERS, value: payloadStub });
  };

  const isATCFilterModified = useMemo(() => {
    return !isEqual(selectedATCCodes, resultsState.filters.atc_code || []);
  }, [resultsState.filters.atc_code, selectedATCCodes]);

  const atcTrees = useMemo(() => {
    const trees = cloneDeep(treeData);
    trees?.forEach((t: any) => setIsSelected(t, selectedATCCodes));

    return trees;
  }, [selectedATCCodes, treeData]);

  return {
    getATCTreeData,
    treeData: atcTrees,
    isLoading,
    selectedATCCodes,
    updateATCFilters,
    applyFilter,
    isATCFilterModified,
    resetATCFilters
  };
};

export default useATCClassification;
