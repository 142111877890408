import { makeStyles } from '@material-ui/core';

const drawerWidth = 240;
export default makeStyles(
  theme => ({
    root: {
      display: 'flex',
      background: theme.palette.backgrounds.main
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      background: theme.palette.backgrounds.main,
      paddingTop: theme.spacing(1),
      marginLeft: theme.spacing(9) + 1,
      width: `calc(100% - ${theme.spacing(9) + 1}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    navIcon: {
      color: '#ced6e0'
    },
    vivLogo: {
      width: theme.spacing(20),
      height: theme.spacing(9)
    },
    badgeBeta: {
      background: theme.palette.navbar.button,
      color: theme.palette.white
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginRight: 36
    },
    NavTitle: {
      fontSize: '3vh',
      color: theme.palette.primary.main
    },
    logoLink: {
      cursor: 'pointer'
    },
    mainLogo: {
      height: '4vh',
      width: 'auto'
    },
    advParent: {
      background: theme.palette.cards.background
    },
    mainSidebarLogo: {
      '& .MuiAvatar-img': {
        height: '2.6vh',
        width: '3.75vh'
      }
    },
    hide: {
      display: 'none'
    },
    pmrOption: {
      padding: theme.spacing(1)
    },
    pmrStatus: {
      color: theme.palette.successGreen,
      fontSize: theme.spacing(1),
      marginRight: theme.spacing(0.5)
    },
    drawerOption: {
      color: theme.palette.home.border,
      textAlign: 'left',
      cursor: 'pointer',
      textTransform: 'capitalize',
      fontSize: theme.spacing(2)
    },
    drawerOption1: {
      color: theme.palette.home.border,
      textAlign: 'left',
      textTransform: 'capitalize',
      marginLeft: theme.spacing(1.2),
      fontSize: theme.spacing(2)
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    drawerOpen: {
      backgroundColor: theme.palette.white,
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerIcons: {
      color: theme.palette.navbar.border,
      fontWeight: 'bold',
      cursor: 'pointer'
      // fontSize:theme.spacing(3.5),
    },
    toggleDrawerIcon: {
      marginTop: theme.spacing(8)
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1
      }
    },
    toggleDrawer: {
      marginTop: theme.spacing(20)
    },
    toolbar: {
      marginTop: theme.spacing(5)
      // display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'flex-end',
      // padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      // ...theme.mixins.toolbar,
    },
    sectionDesktop: {
      display: 'none',
      marginLeft: 'auto',
      [theme.breakpoints.up('md')]: {
        display: 'flex'
      }
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    Icons: {
      color: '#FFFFFF'
    },
    main: {
      height: '95%',
      width: '100%'
    },
    MainGrid: {
      marginTop: '0.5rem',
      height: '100%'
    },
    GridSearchBox: {
      minHeight: '20%',
      boxShadow: 'none',
      borderRadius: 0
    },
    GridPage: {
      height: '90%',
      boxShadow: 'none',
      borderRadius: 0
    },
    DateTitle: {
      fontSize: '2vh'
    },
    searchSpaceGrid: {
      border: `2px solid ${theme.palette.primary.lightBorder}`,
      borderRadius: '10px'
    },
    searchSpace: {
      paddingLeft: '10px',
      width: '90%',
      height: '5vh',
      fontSize: '2vh',
      '& .MuiInputBase-input': {
        fontSize: '2vh'
      }
    },
    searchFilter: {
      borderRadius: '10px',
      backgroundColor: theme.palette.primary.lightBackground,
      border: `2px solid ${theme.palette.primary.lightBorder}`
    },
    filterTabLeft: {
      width: '100%',
      height: '5vh',
      textAlign: 'left',
      '& .MuiSelect-icon': {
        color: theme.palette.primary.main
      },
      '& .MuiSelect-root': {
        textAlign: 'left',
        fontSize: '2vh',
        color: theme.palette.primary.main,
        backgroundColor: 'transparent'
      },
      '& .MuiFilledInput-input': {
        padding: 8
      },
      '& .MuiInputBase-root': {
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
        fontSize: '2vh'
      }
    },

    searchIconButton: {
      width: '5%',
      height: '5vh',
      marginBottom: '-1vh'
    },
    searchIcon: {
      width: '5%',
      height: '5vh',
      marginBottom: '-1vh'
    },
    searchDialog: {
      padding: theme.spacing(4)
    },
    buttonMain: {
      marginLeft: '10%',
      width: 'auto',
      height: '5.4vh',
      marginBottom: '-2px',
      marginRight: '10px',
      fontSize: '3vh',
      borderRadius: '10px',
      background: `linear-gradient(to bottom, ${theme.palette.gradient.primaryFrom} 10%, ${theme.palette.gradient.primaryTo} 90%)`,
      color: theme.palette.white
    },
    buttonSecondary: {
      width: 'auto',
      borderRadius: '10px',
      height: 'auto',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      background: `linear-gradient(to bottom, ${theme.palette.gradient.primaryFrom} 10%, ${theme.palette.gradient.primaryTo} 90%)`,
      color: theme.palette.white
    },
    advancedText: {
      fontSize: '1.1rem',
      color: theme.palette.white
    },
    AdvancedDialog: {
      minHeight: '30%',
      maxHeight: '80%'
    },
    DialogTitle: {
      background: `linear-gradient(to bottom, ${theme.palette.gradient.primaryFrom} 10%, ${theme.palette.gradient.primaryTo} 90%)`,
      color: theme.palette.white
    },
    chart: {
      height: '100%',
      width: '100%',
      backgroundColor: theme.palette.white,
      boxShadow: 'none',
      borderRadius: 0
    },
    DialogContent: {
      padding: theme.spacing(3),
      minHeight: '50vh'
    },
    AdvancedSearchDate: {
      // padding:theme.spacing(2)
    },
    popoverTitle: {
      marginLeft: theme.spacing(1)
    },
    popoverBox: {
      marginTop: theme.spacing(1)
    },
    AdvancedSearchDatePicker: {
      // height: '100%',
      width: theme.spacing(20),
      marginLeft: theme.spacing(1),
      background: theme.palette.white,
      borderRadius: '10px',
      border: `2px solid ${theme.palette.primary.lightBorder}`,
      '& .MuiInput': {
        border: `2px solid ${theme.palette.primary.lightBorder}`
      },
      '& .MuiFormLabel-root': {
        marginLeft: theme.spacing(1),
        color: theme.palette.primary.main
      },
      '& .MuiInput-underline:before': {
        border: 'none'
      },
      '& .MuiInput-underline:after': {
        border: 'none'
      },
      '& .MuiInput-underline:hover': {
        border: 'none'
      },
      '& .MuiInput-underline:focus': {
        border: 'none'
      }
    },
    resize: {
      fontSize: theme.spacing(1.85),
      marginLeft: theme.spacing(1),
      width: '100%'
      // padding: theme.spacing(1)
    },
    calendarIcon: {
      color: theme.palette.variants.title,
      opacity: 0.7
    },
    box: {
      // marginLeft: theme.spacing(.5),
      // marginRight: theme.spacing(.5),
      height: '100%'
    },

    btn: {
      marginLeft: theme.spacing(1),
      fontSize: theme.spacing(2),
      // height : '100%',
      fontWeight: '500',
      borderRadius: '10px',
      background: theme.palette.navbar.button,
      color: theme.palette.white
    },
    logoutBtn: {
      fontSize: theme.spacing(2),
      width: '100%',
      fontWeight: '500',
      borderRadius: '10px',
      background: theme.palette.navbar.button,
      color: theme.palette.white
    },
    search: {
      float: 'left',
      width: '300px',
      marginLeft: theme.spacing(1)
    },
    searchDialog: {
      padding: theme.spacing(4)
    },
    buttonMain: {
      marginLeft: '10%',
      width: 'auto',
      height: '5.4vh',
      marginBottom: '-2px',
      marginRight: '10px',
      fontSize: '3vh',
      borderRadius: '10px',
      background: `linear-gradient(to bottom, ${theme.palette.gradient.primaryFrom} 10%, ${theme.palette.gradient.primaryTo} 90%)`,
      color: theme.palette.white
    },
    buttonSecondary: {
      width: 'auto',
      borderRadius: '10px',
      height: 'auto',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      background: `linear-gradient(to bottom, ${theme.palette.gradient.primaryFrom} 10%, ${theme.palette.gradient.primaryTo} 90%)`,
      color: theme.palette.white
    },
    advancedText: {
      fontSize: '1.1rem',
      color: theme.palette.white
    },
    AdvancedDialog: {
      minHeight: '30%',
      maxHeight: '80%'
    },
    DialogTitle: {
      background: `linear-gradient(to bottom, ${theme.palette.gradient.primaryFrom} 10%, ${theme.palette.gradient.primaryTo} 90%)`,
      color: theme.palette.white
    },
    chart: {
      height: '100%',
      width: '100%',
      backgroundColor: theme.palette.white,
      boxShadow: 'none',
      borderRadius: 0
    },
    DialogContent: {
      padding: theme.spacing(3),
      minHeight: '50vh'
    },
    AdvancedSearchDate: {
      // padding:theme.spacing(2)
    },
    AdvancedButtonBox: {
      width: '70%',
      borderRadius: '10px',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      })
    },
    expandClose: {
      color: theme.palette.white
    },
    expandOpen: {
      color: theme.palette.white,
      transform: 'rotate(180deg)'
    },
    botIcon: {
      marginLeft: '-1vh',
      height: '4vh',
      width: '4vh'
    },
    searchIconButton: {
      width: 'auto',
      background: `linear-gradient(to bottom, ${theme.palette.gradient.primaryFrom} 10%, ${theme.palette.gradient.primaryTo} 90%)`,
      borderRadius: '10px',
      color: theme.palette.white
    },
    searchIcon: {
      width: 'auto',
      height: '3vh',
      color: theme.palette.white
    },
    searchSpace: {
      border: 'unset',
      outline: 'unset',
      width: '85%',
      height: '100%',
      fontSize: '2vh'
    },
    avatar: {
      background: theme.palette.navbar.avatar,
      fontSize: theme.spacing(2),
      fontWeight: 'bold',
      textTransform: 'uppercase',
      opacity: 0.9
    },
    username: {
      color: theme.palette.common.primaryBlack,
      fontWeight: 'bold',
      // letterSpacing:theme.spacing(.3),
      fontSize: theme.spacing(2.5)
    },
    emailText: {
      color: theme.palette.common.primaryBlack,
      opacity: 0.8,
      fontWeight: 'bold',
      // letterSpacing:theme.spacing(.3),
      fontSize: theme.spacing(2)
    },
    statusIcon: {
      color: theme.palette.successGreen
    },
    statusIcon: {
      color: theme.palette.successGreen
    },
    modal: {
      display: 'flex',
      marginTop: '1vh',
      marginBottom: '1vh',
      marginRight: '1vw',
      marginLeft: '1vw'
    },
    modalContainer: {
      margin: 'auto',
      overflowX: 'scroll',
      overflowY: 'auto',
      // borderRadius: theme.spacing(1),
      // outline: 'none',
      // display: 'flex',
      // flexDirection: 'column',
      // justifyContent: 'space-evenly',
      height: '100%',
      // width: theme.spacing(120),
      width: '100vw'
      // padding: theme.spacing(3),
      // position: 'relative',
    },
    badge: {
      color: theme.palette.common.badge
    },
    dropdownAdv: {
      background: theme.palette.white,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      borderRadius: theme.spacing(1.5)
    },
    dropdownLabel: {
      // textAlign: 'left',
      margin: theme.spacing(1),
      color: theme.palette.navbar.border
    },
    textField: {
      background: theme.palette.navbar.grayBackground,
      borderRadius: theme.spacing(1.5),
      padding: theme.spacing(1.5),
      marginLeft: theme.spacing(1)
      // border:`${theme.palette.navbar.border} 1px solid`,
    },
    filterGreen: {
      filter:
        'invert(52%) sepia(9%) saturate(2494%) hue-rotate(109deg) brightness(109%) contrast(102%)'
    }
  }),
  { index: 1 }
);
