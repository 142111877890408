import { useCallback, useMemo, useState, useContext, memo } from 'react';

import {
  Box,
  Stack,
  Typography,
  IconButton,
  Tooltip,
  Drawer,
  Divider,
  Button
} from '@mui/material';

import Close from '@mui/icons-material/Close';

import SearchIcon from '../../assets/svgs/Icons/SearchIcon';
import SearchBarAutoComplete from '../Home/components/SearchBarAutoComplete';
import SourceDropDown from '../Home/components/SourceDropDown';
import { SourceDropdown } from '../Home/types';
import useSearchSuggestions from '../Home/hooks/useSearchSuggestions';

import styles from './styles/SearchBarStyles';
import searchBarStyles from '../Home/styles/SearchBar.styles';
import comparisonSearchBoxStyles from './styles/ComparisonSearchBox.styles';

import {
  createSourceModulePayloadMapping,
  getCompareSourceDropdown,
  getDefaultCompareSourceDropdown
} from '../Home/utils';
import RESULT_VIEW_TYPES from '../SearchResults/components/constants';
import { COMPARE_LABEL_METHODS } from '../SearchResults/constants';

import { getGenericSearch } from '../../api/pages/ResultsPage';

import ResultsStore from '../../store/SearchResults';
import ResultActions from '../../store/SearchResults/actions';
import SelectionView from '../ResultsPage/components/SelectionView';
import EntitySelectionWindow from '../SearchResults/components/EntitySelectionWindow';
import entityHandler from '../SearchResults/utils/entityHandler';
import Loading from '../SearchResults/components/Loading';

const ComparisonSearchBox = ({ modalOpen, setModalOpen }: any) => {
  const { resultsDispatch, resultsState } = useContext(ResultsStore);
  const [selectedSources, setSelectedSources] = useState<SourceDropdown[]>(() =>
    getDefaultCompareSourceDropdown()
  );
  const [sourceDropDownAnchorEl, setSourceDropDownAnchorEl] = useState<null | HTMLElement>(null);
  const [searchText, setSearchText] = useState<string>('');
  const {
    searchSuggestions,
    isSuggestionsLoading,
    setIsSearchOpen,
    handleKeyPress,
    clearSearchSuggestions,
    isSearchOpen
  } = useSearchSuggestions(selectedSources);
  const [viewType, setViewType] = useState('');
  const [entities, setEntities] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleAutoCompleteOpen = useCallback(() => setIsSearchOpen(true), [setIsSearchOpen]);

  const handleAutoCompleteClose = useCallback(() => setIsSearchOpen(false), [setIsSearchOpen]);

  const handleSourceChange = useCallback(
    (values: SourceDropdown[]) => {
      setSelectedSources(values);
    },
    [setSelectedSources]
  );

  const compareSourceDropDown = useMemo(() => getCompareSourceDropdown(), []);

  const enableEnhancedLabelComparison = () => {
    const selectedComparisonMethod = COMPARE_LABEL_METHODS.enhancedComparison;

    resultsDispatch({
      type: ResultActions.SET_LABEL_COMPARISON_SELECTION_METHOD,
      value: {
        limit: selectedComparisonMethod.limit,
        value: selectedComparisonMethod.value
      }
    });

    resultsDispatch({
      type: ResultActions.SET_LABEL_COMPARISON_SELECTION,
      value: true
    });
  };

  const handleApplicationsSearch = async (payload: any) => {
    try {
      setIsLoading(true);
      const result = await getGenericSearch(payload);

      const resultApplications = result?.data?.body?.result || {};

      const currentViewType = result?.data?.body?.view_type ?? RESULT_VIEW_TYPES.APPLICATION;

      const viewTypeMetadata = result?.data?.body?.view_type_metadata ?? {};

      resultsDispatch({ type: ResultActions.SET_DECRYPTED_PAYLOAD, value: payload });
      resultsDispatch({ type: ResultActions.SET_DECRYPTED_SOURCE, value: payload.source });
      resultsDispatch({ type: ResultActions.SET_VIEW_TYPE, value: currentViewType });

      resultsDispatch({
        type: ResultActions.SET_ENTITY_LIST,
        value: viewTypeMetadata
      });

      if (currentViewType === RESULT_VIEW_TYPES.ENTITY) {
        const resultEntities = result?.data?.body?.entities ?? [];
        setEntities(entityHandler(resultEntities));
      }

      resultsDispatch({
        type: ResultActions.SET_SEARCH_SUGGESTIONS,
        value: result?.data?.body?.alternate_results ?? []
      });

      resultsDispatch({
        type: ResultActions.SET_APPLICATION_RESULTS,
        value: resultApplications
      });

      setViewType(currentViewType);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error while fetching search results', error);
    } finally {
      setIsLoading(false);
    }
  };

  const entityClick = async (entityText: string) => {
    const convertedSelectedSources = createSourceModulePayloadMapping(selectedSources);
    const payload = {
      query: searchText,
      search_term: entityText,
      source: convertedSelectedSources,
      view_type: RESULT_VIEW_TYPES.APPLICATION
    };

    setViewType(RESULT_VIEW_TYPES.APPLICATION);

    enableEnhancedLabelComparison();

    await handleApplicationsSearch(payload);
  };

  const handleSearch = async (value: string = searchText) => {
    const convertedSelectedSources = createSourceModulePayloadMapping(selectedSources);
    const payload = {
      query: value,
      source: convertedSelectedSources,
      view_type: RESULT_VIEW_TYPES.APPLICATION
    };

    await handleApplicationsSearch(payload);

    enableEnhancedLabelComparison();
  };

  return (
    <>
      <Drawer
        sx={comparisonSearchBoxStyles.drawer}
        anchor='bottom'
        onClose={() => setModalOpen('')}
        open={!!modalOpen}>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Box sx={comparisonSearchBoxStyles.headerWrapper}>
            <Typography sx={comparisonSearchBoxStyles.drawerTitleText}>Label Comparison</Typography>
            <Tooltip title='Close'>
              <IconButton
                onClick={() => setModalOpen('')}
                sx={comparisonSearchBoxStyles.closeIconButton}>
                <Close />
              </IconButton>
            </Tooltip>
          </Box>
          <Box display='flex' flexDirection='row' justifyContent='center'>
            <Divider sx={comparisonSearchBoxStyles.divider} />
          </Box>
          <Stack spacing={1}>
            <form
              onSubmit={event => {
                event.preventDefault();
                handleSearch();
              }}>
              <Box sx={styles.searchBar}>
                <Stack direction='row' alignItems='center'>
                  <SourceDropDown
                    selectedSources={selectedSources}
                    handleChange={handleSourceChange}
                    options={compareSourceDropDown}
                    anchorEl={sourceDropDownAnchorEl}
                    setAnchorEl={setSourceDropDownAnchorEl}
                  />
                  <Divider
                    orientation='vertical'
                    flexItem
                    sx={comparisonSearchBoxStyles.separator}
                  />
                  <Box flex={1}>
                    <SearchBarAutoComplete
                      id='comparsion-search-input'
                      value={searchText}
                      label='Search for drug name here!'
                      disableSearch={selectedSources.length === 0 || isLoading}
                      options={searchSuggestions}
                      loading={isSuggestionsLoading}
                      onOpen={handleAutoCompleteOpen}
                      open={isSearchOpen}
                      onClose={handleAutoCompleteClose}
                      filterOptions={undefined}
                      onInputChange={handleKeyPress}
                      handleSearch={handleSearch}
                      setSearch={value => setSearchText(value)}
                      clearSearchSuggestions={() => {
                        clearSearchSuggestions();
                      }}
                    />
                  </Box>
                  <Button
                    type='submit'
                    size='small'
                    disabled={isLoading}
                    startIcon={<SearchIcon style={{ fontSize: 12 }} />}
                    sx={searchBarStyles.searchButton}>
                    Search
                  </Button>
                </Stack>
              </Box>
            </form>
          </Stack>
          {isLoading && (
            <Box mt='-10vh'>
              <Loading showTips={false} inContainer />
            </Box>
          )}
        </Box>
      </Drawer>
      {viewType === RESULT_VIEW_TYPES.APPLICATION && resultsState?.labelComparisonSelection && (
        <SelectionView
          open={resultsState?.labelComparisonSelection}
          onClose={() => {
            setModalOpen('');
            resultsDispatch({
              type: ResultActions.SET_LABEL_COMPARISON_SELECTION,
              value: false
            });
          }}
          sources={selectedSources}
          comparisonApplications={resultsState?.comparisonApplications}
          showSecondaryNavigation
          isExternalLoading={isLoading}
        />
      )}
      {viewType === RESULT_VIEW_TYPES.ENTITY && (
        <EntitySelectionWindow
          openEntityPopup={viewType === RESULT_VIEW_TYPES.ENTITY}
          setOpenEntityPopup={() => setViewType('')}
          entityList={entities}
          handleEntityClick={entityClick}
          triggeredFromPopup
        />
      )}
    </>
  );
};

export default memo(ComparisonSearchBox);
