const styles = {
  closeButtonIcon: { height: '24px', width: '24px', color: 'white.main' },
  searchTextField: {
    '& .MuiInputBase-root': { borderRadius: '32px', height: '30px', width: '300px' }
  },
  sortViewButton: {
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: '600',
    ml: '8px',
    color: 'gray.700'
  },
  badge: {
    '& .MuiBadge-badge': {
      height: '6px',
      minWidth: '6px',
      top: 4
    }
  },

  nextButton: {
    height: '30px',
    padding: '15px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '16px',
    backgroundColor: 'primary.650',
    color: 'white.main',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 700,
    '&:hover': {
      backgroundColor: 'white.main',
      border: 1,
      borderColor: 'primary.main',
      borderStyle: 'solid',
      color: 'primary.600'
    },
    '&:disabled': {
      backgroundColor: 'gray.550',
      color: 'gray.background'
    }
  },
  resultStatsWrapper: {
    borderRadius: '6px',
    p: '2px 8px',
    backgroundColor: 'gray.100',
    height: '21px'
  },
  headerWrapper: {
    bgcolor: 'primary.600',
    alignSelf: 'stretch',
    p: '12px 24px',
    borderRadius: '8px 8px 0px 0px'
  },
  titleWrapper: { flexGrow: 1, textAlign: 'center', alignSelf: 'center' },
  closeIconButton: { position: 'absolute', display: 'flex', left: '97%', p: 0 },
  searchIcon: { height: '14px', width: '14px' },
  arrowDownIcon: { height: '16px', width: '16px', color: 'gray.700' },
  roundedMenu: {
    '& .MuiPaper-root': { padding: '10px 0px !important', minWidth: '160px' },
    '& .MuiList-root': { padding: '8px 0 !important' }
  },
  listViewIcon: { height: '16px', width: '16px', color: 'gray.700' },
  divider: { my: '16px', mx: '28px' },
  statsText: { color: 'gray.700', fontFamily: 'Mulish', fontSize: '12px' },
  expandedGroupWrapper: {
    height: 'calc(100vh - 178px)',
    overflowY: 'auto'
  },
  dataGridWrapper: { height: 'calc(100vh - 218px)', mb: '32px' },
  secondaryNavSeparator: { height: '16px', borderColor: 'gray.300' },
  selectedText: { color: 'gray.500', fontSize: '14px', fontWeight: 600 },
  resetText: { color: 'gray.500', fontSize: '14px', fontWeight: 600, cursor: 'default' },
  selectionStatsDivider: { height: 16 }
};

export default styles;
