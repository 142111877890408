import React, { useCallback, useContext, useState } from 'react';

// material ui
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import CenterBallN from '../../../Images/centerBallNext.svg';
import plus from '../../../Images/plusWhite.svg';

// context
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';

// assets
import TickInsideCircle from '../../../Images/svgs/tickInsideCircle';
import Checkedtick from '../../../Images/svgs/checkedtick';
import LabelCompareIcon from '../../../Images/brightness-and-contrast.svg';

// api
import { getLabelComparision } from '../../../api/pages/Reg360';

// helpers
import getComparisonFile from '../../../helpers/draftableUtils';

// style
import styles from '../styles/PDFCompare.styles';
import comparisonClasses from '../../Comparison/styles/LabelSelection.style';
import { getPediatricsDocumentsSignedURL } from '../../../api/pages/Pediatrics';

const PDFCompare: React.FC<any> = ({ open, onClose, rowData }) => {
  const { dispatch } = useContext<any>(GlobalStore);
  const [selectedDocs, setSelectedDocs] = useState<any>([]);
  const [draftableViewer, setDraftableViewer] = useState<any>();
  const [compareOpen, setCompareOpen] = useState<boolean>(false);
  const [firstLabel, setFirstLabel] = useState<any>();
  const [secondLabel, setSecondLabel] = useState<any>();

  const selectDocs = useCallback(
    (e: any, value: any) => {
      try {
        const currentSelection = selectedDocs;

        // if document is already selected, uncheck and remove it from selectedDocs
        if (currentSelection.includes(value)) {
          const index = currentSelection.indexOf(value);
          currentSelection.splice(index, 1);
          setFirstLabel(currentSelection[0]);
          setSecondLabel(null);
          setSelectedDocs(currentSelection);
          return;
        }

        // check if maximum number of documents are selected
        if (currentSelection.length >= 2) {
          dispatch({
            type: GlobalActions.SET_ALERT,
            value: {
              status: true,
              message: 'Only 2 documents can be selected. Please unselect one of the document'
            }
          });
          return;
        }

        // update the states to show checked tick correctly
        if (currentSelection.length === 1) {
          setSecondLabel(value);
        } else {
          setFirstLabel(value);
        }

        currentSelection.push(value);
        setSelectedDocs(currentSelection);
      } catch (error) {
        dispatch({
          type: GlobalActions.SET_ALERT,
          value: {
            status: true,
            message: 'Error in selecting documents'
          }
        });
      }
    },
    [selectedDocs, firstLabel, secondLabel]
  );

  const handleSignedURL = useCallback(async (pdfLink: string) => {
    const res: any = await getPediatricsDocumentsSignedURL({
      source: 'written_requests',
      s3_path: pdfLink
    });
    return res?.data?.body?.s3_url ?? '';
  }, []);

  // function to perform comparison and get the embedded link for draftable viewer
  const compareLabels = useCallback(async () => {
    if (selectedDocs.length !== 2) {
      dispatch({
        type: GlobalActions.SET_ALERT,
        value: {
          status: true,
          message: 'Please selected at least 2 documents  to compare'
        }
      });
      return;
    }
    const response = await getLabelComparision({
      parent_object: await handleSignedURL(selectedDocs[0]),
      child_object: await handleSignedURL(selectedDocs[1])
    });

    setDraftableViewer(response.data.success);
    setCompareOpen(true);
  }, [selectedDocs, compareOpen, dispatch, draftableViewer]);

  // function to download the comparison data for selected files
  const downloadFile = useCallback(
    // eslint-disable-next-line no-shadow
    (draftableViewer: any) => {
      try {
        const identifierInURL = draftableViewer.split('/')[7];
        const identifier = identifierInURL.split('?')[0];

        getComparisonFile(identifier);
      } catch (e) {
        dispatch({
          type: GlobalActions.SET_ALERT,
          value: {
            status: true,
            message: 'Error in downloading comparison'
          }
        });
      }
    },
    [dispatch]
  );

  return (
    <Dialog
      fullWidth
      maxWidth='xl'
      aria-labelledby='customized-dialog-title'
      open={open}
      onClose={onClose}>
      <Grid
        sx={comparisonClasses.dialog}
        display='flex'
        justifyContent='space-between'
        alignItems='flex-start'>
        <Box />
        <Grid sx={comparisonClasses.LabelTitle} display='flex' justifyContent='center'>
          <img style={comparisonClasses.compareIcon} alt='compareLogo' src={LabelCompareIcon} />
          LABEL COMPARISON
        </Grid>
        <Box>
          <IconButton aria-label='delete' onClick={onClose} sx={{ fontSize: '40px' }}>
            <CancelIcon fontSize='inherit' />
          </IconButton>
        </Box>
      </Grid>
      <DialogContent sx={comparisonClasses.dialog}>
        {!compareOpen ? (
          <Grid
            container
            display='flex'
            justifyContent='center'
            flexDirection='column'
            style={{ display: 'inline-flex', padding: '30px' }}>
            <Grid xs={12} container display='flex' justifyContent='center' item>
              <Typography align='center' sx={styles.listHeader}>
                {rowData?.name.join(' ')}
              </Typography>
            </Grid>
            <Grid container display='flex' justifyContent='center' item>
              <Box display='flex' flexDirection='column' sx={styles.listContainer}>
                {rowData?.documentList?.map((item: any) => (
                  <Box key={item} display='flex' justifyContent='space-between'>
                    <Typography variant='subtitle1' sx={styles.fileName}>
                      {item.split('?')[0].split('_').pop()}
                    </Typography>
                    <Checkbox
                      icon={<TickInsideCircle />}
                      checkedIcon={<Checkedtick />}
                      name='checkedH'
                      onChange={e => selectDocs(e, item)}
                      value={item}
                      checked={item === firstLabel || item === secondLabel}
                    />
                  </Box>
                ))}
              </Box>
              <Grid container display='flex' justifyContent='center' item>
                <Button disabled={selectedDocs.length < 2} sx={styles.btn} onClick={compareLabels}>
                  Compare
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid>
            <Grid container justifyContent='center' alignItems='flex-end'>
              <Grid xs={3}>
                <Typography sx={comparisonClasses.selectLabel} align='center'>
                  {firstLabel.split('?')[0].split('_').pop()}
                </Typography>
              </Grid>
              <Grid xs={3} sx={comparisonClasses.compareLine}>
                <img style={comparisonClasses.centerBall} alt='compareBall' src={CenterBallN} />
              </Grid>
              <Grid xs={3}>
                <Typography sx={comparisonClasses.selectLabel} align='center'>
                  {secondLabel.split('?')[0].split('_').pop()}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={comparisonClasses.dialog}>
              <div style={{ height: '56vh', width: '92vw' }}>
                <iframe
                  style={comparisonClasses.compareIFrame}
                  title='compareDocuments'
                  src={draftableViewer}
                  allowFullScreen
                />
              </div>
            </Box>
            <Box display='flex' justifyContent='space-evenly' bottom={0}>
              <Box sx={comparisonClasses.compareN} onClick={() => setCompareOpen(false)}>
                <img style={{ width: '15px' }} alt='compareLogo' src={plus} />
                <Typography sx={comparisonClasses.labelTitle}>New Compare</Typography>
              </Box>
              <Box sx={comparisonClasses.compareN} onClick={() => downloadFile(draftableViewer)}>
                <Typography sx={comparisonClasses.labelTitle}>Download Comparison</Typography>
              </Box>
            </Box>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(PDFCompare);
