import { createSvgIcon } from '@mui/material';

const DDToolsIcon = createSvgIcon(
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_10705_4832)'>
      <path
        d='M30.3519 21.583C29.4518 21.5833 28.5908 21.9513 27.9685 22.6017L25.4416 21.5277C25.6143 21.011 25.7053 20.4705 25.7112 19.9256C25.7051 16.999 23.3342 14.6281 20.4076 14.622C19.9652 14.6275 19.5252 14.6892 19.0983 14.8055L17.6453 12.2188C18.8755 10.8706 18.7798 8.78025 17.4315 7.55004C16.0832 6.31983 13.9929 6.41556 12.7627 7.76384C11.5325 9.11212 11.6282 11.2024 12.9765 12.4326C13.588 12.9906 14.3867 13.2987 15.2145 13.2961C15.3734 13.2916 15.5319 13.2757 15.6885 13.2486L17.1172 15.7899C14.9714 17.4609 14.462 20.4965 15.9449 22.7763L11.7639 26.8214C10.0531 26.0625 8.05095 26.8341 7.29199 28.5449C6.53303 30.2557 7.30464 32.2578 9.01542 33.0168C10.7262 33.7757 12.7283 33.0041 13.4873 31.2933C13.9094 30.3418 13.8708 29.249 13.3826 28.3297L17.494 24.352C19.6783 25.8054 22.6001 25.4237 24.3378 23.4581L27.0647 24.616C27.057 24.7099 27.0371 24.8006 27.0371 24.8956C27.0371 26.7262 28.5212 28.2103 30.3519 28.2103C32.1826 28.2103 33.6666 26.7262 33.6666 24.8955C33.6666 23.0648 32.1826 21.5808 30.3519 21.5808V21.583Z'
        fill='currentColor'
      />
      <path
        d='M20.3334 12.2223V7.77783H22.1238C22.6491 7.77783 23.0925 7.86819 23.4539 8.04891C23.8154 8.22543 24.0886 8.4797 24.2735 8.81172C24.4626 9.13953 24.5572 9.5346 24.5572 9.9969C24.5572 10.455 24.4626 10.8501 24.2735 11.1821C24.0886 11.5141 23.8154 11.7705 23.4539 11.9512C23.0925 12.1319 22.6491 12.2223 22.1238 12.2223H20.3334ZM21.4996 11.2766H22.0481C22.5062 11.2766 22.8445 11.1716 23.0631 10.9614C23.2858 10.7513 23.3972 10.4298 23.3972 9.9969C23.3972 9.55981 23.2858 9.2383 23.0631 9.03236C22.8445 8.82222 22.5062 8.71715 22.0481 8.71715H21.4996V11.2766Z'
        fill='currentColor'
      />
      <path
        d='M25.1324 12.2223V7.77783H26.9228C27.4482 7.77783 27.8916 7.86819 28.253 8.04891C28.6144 8.22543 28.8876 8.4797 29.0725 8.81172C29.2617 9.13953 29.3562 9.5346 29.3562 9.9969C29.3562 10.455 29.2617 10.8501 29.0725 11.1821C28.8876 11.5141 28.6144 11.7705 28.253 11.9512C27.8916 12.1319 27.4482 12.2223 26.9228 12.2223H25.1324ZM26.2987 11.2766H26.8472C27.3053 11.2766 27.6436 11.1716 27.8621 10.9614C28.0849 10.7513 28.1963 10.4298 28.1963 9.9969C28.1963 9.55981 28.0849 9.2383 27.8621 9.03236C27.6436 8.82222 27.3053 8.71715 26.8472 8.71715H26.2987V11.2766Z'
        fill='currentColor'
      />
      <path
        d='M30.6879 12.2223V8.71715H29.3388V7.77783H33.2033V8.71715H31.8542V12.2223H30.6879Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_10705_4832'>
        <rect width='26.6667' height='26.6667' fill='white' transform='translate(7 6.6665)' />
      </clipPath>
    </defs>
  </svg>,
  'DDToolsIcon'
);

export default DDToolsIcon;
