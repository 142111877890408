import { GridColDef } from '@mui/x-data-grid-pro';
import { capitalize } from 'lodash';
import renderCellExpand from '../../components/Datagrid/DatagridPopover';
import DatagridChipsPopup from '../../components/Datagrid/DatagridChipsPopup';

import { PIECHARTCOLOR } from '../../components/CustomComponents/Graphs/constant';
import { Filter } from '../../components/Filters';
import DocumentHistoryPDF from './components/DocumentHistoryPDF';
import { FILTER_TYPE } from '../../components/NewFilters/constants';

export const SEARCH_IGNORE_FIELDS = ['consultation_id'];

export interface CandadaConsultationFilters extends Filter {
  apiRequestKey: string;
  apiResponseKey?: string;
}

type CustomGridColDef = GridColDef & {
  capitalCasing?: boolean;
};

export const CANADA_CONSULTATION_COLUMN: CustomGridColDef[] = [
  {
    field: 'document_count',
    headerName: 'Documents',
    width: 100,
    filterable: false,
    capitalCasing: false,
    headerClassName: 'table-header',
    disableExport: true,
    sortable: false,
    renderCell: DocumentHistoryPDF
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 350,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    capitalCasing: false,
    renderCell: renderCellExpand,
    valueGetter: (params: any) => params.value.replace(/ \([\s\S]*?\)/g, '')
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 350,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    capitalCasing: false,
    renderCell: renderCellExpand
  },
  {
    field: 'start_date',
    headerName: 'Start Date',
    width: 120,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    valueGetter: (params: any) => params.value || '-'
  },
  {
    field: 'end_date',
    headerName: 'End Date',
    width: 120,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    valueGetter: (params: any) => params.value || '-'
  },

  {
    field: 'status',
    headerName: 'Status',
    width: 180,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: renderCellExpand,
    valueGetter: (params: any) => capitalize(params.value) || '-'
  },
  {
    field: 'subjects',
    headerName: 'Subjects',
    width: 300,
    capitalCasing: false,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridChipsPopup,
    // eslint-disable-next-line consistent-return
    valueGetter: (params: any) => {
      const newValue: Array<string> = [];
      if (params.value) {
        params.value.forEach((value: any) => {
          if (value) {
            newValue.push(capitalize(value.trim()));
          }
        });
        return newValue.join('; ');
      }
      return '-';
    }
  },
  {
    field: 'document_type',
    headerName: 'Document Type',
    width: 160,
    capitalCasing: false,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    // eslint-disable-next-line consistent-return
    valueGetter: (params: any) => capitalize(params.value) || '-'
  },
  {
    field: 'owner_organization',
    headerName: 'Owner Organization',
    width: 140,
    capitalCasing: false,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    // eslint-disable-next-line consistent-return
    valueGetter: (params: any) => capitalize(params.value) || '-'
  },
  {
    field: 'partner_departments',
    headerName: 'Partner Departments',
    width: 160,
    capitalCasing: false,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridChipsPopup,
    // eslint-disable-next-line consistent-return
    valueGetter: (params: any) => {
      const newValue: Array<string> = [];
      if (params.value) {
        params.value.forEach((value: any) => {
          if (value) {
            newValue.push(capitalize(value.trim()));
          }
        });
        return newValue.join('; ');
      }
    }
  }
];

export const CANADA_CONSULTATION_FILTERS = [
  {
    id: 1,
    label: 'Document Type',
    value: 'document_type',
    elasticSearchType: 'document_type',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Owner Organization',
    value: 'owner_organization',
    elasticSearchType: 'owner_organization',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Start Date',
    value: 'start_date',
    elasticSearchType: 'start_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'End Date',
    value: 'end_date',
    elasticSearchType: 'end_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Search Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Status',
    value: 'status',
    elasticSearchType: 'status',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 7,
    label: 'Subjects',
    value: 'subjects',
    elasticSearchType: 'subjects',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 8,
    label: 'Title',
    value: 'title',
    elasticSearchType: 'title',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  }
];

const day = new Date();
const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
export const CSV_FILENAME = {
  prefix: 'ria',
  module: 'canada_consultation',
  postfix: date,
  additional: '',
  extension: ''
};

export const ENTITY_MAPPING: { [key: string]: any } = {
  issued_date: { name: 'Issued Date ' },
  last_modified_date: { name: 'Last Modified Date ' }
};

export const CANADA_CONSULTATION_PIE_CHART = [
  {
    label: 'Status',
    apiKey: 'id',
    graphItem: 'status',
    count: 5
  },
  {
    label: 'Subjects',
    apiKey: 'id',
    graphItem: 'subjects',
    count: 5
  }
];
export const barChartStyles = {
  colors: ['#B1BBE4'],
  padding: 0.8,
  labelTextColor: '#ffffff',
  axisText: '#A3A3A3',
  axisBottom: {
    tickRotation: 25
  }
};

export const pieChartStyles = {
  colors: PIECHARTCOLOR,
  margin: { top: 20, right: 20, bottom: 20, left: 20 },
  pieLabel: true,
  pieLegend: false,
  innerRadius: 0.7,
  enableLabels: 'both'
};

export const CANADA_CONSULTATION_REPORT_QUERIES = [
  {
    id: 'consultation_documents_by_year',
    title: {
      title: 'Consultations by Start Year',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['start_date'], agg_function: 'count', sort_on_axis: 'x' },
    graphStyle: {
      ...barChartStyles,
      axisLeft: {
        tickRotation: 0
      },
      axisBottom: {
        tickRotation: 80
      }
    }
  },
  {
    id: 'organization_pie_chart',
    title: {
      title: 'Organization',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['owner_organization'],
      agg_function: 'count',
      limit: 5
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'status_pie_chart',
    title: {
      title: 'Status',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['status'],
      agg_function: 'count',
      limit: 5
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'subject_pie_chart',
    title: {
      title: 'Subjects',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['subjects'],
      agg_function: 'count',
      limit: 5
    },
    graphStyle: pieChartStyles
  }
];

export default CANADA_CONSULTATION_FILTERS;
