import React from 'react';

import { Box, Dialog } from '@mui/material';
import Close from '../../../assets/svgs/CardsPage/CloseIcon';

import PDFViewer from '../../../components/PDFViewer';

import styles from '../styles/PDFPreview.styles';

const PDFPreview: React.FC<any> = ({ open, onClose, pdfUrl }) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby='customized-dialog-title'
      open={open && pdfUrl}
      fullWidth
      maxWidth='lg'>
      <Box sx={styles.close} display='flex'>
        <Box onClick={onClose}>
          <Close />
        </Box>
      </Box>
      {pdfUrl ? (
        <Box sx={styles.iframeContainer}>
          <PDFViewer fileUrl={pdfUrl} />
        </Box>
      ) : null}
    </Dialog>
  );
};

export default React.memo(PDFPreview);
