const actions = {
  SET_LETTERS_TABLE: 'SET_LETTERS_TABLE',
  SET_STATUS_TREEMAP: 'SET_STATUS_TREEMAP',
  SET_DOC_TYPE_TREEMAP: 'SET_DOC_TYPE_TREEMAP',
  SET_ADVICE_TYPE_TREEMAP: 'SET_FDA_ORG_TREEMAP',
  SET_ISSUE_DATE: 'SET_ISSUE_DATE',
  SET_LETTERS_STATS: 'SET_LETTERS_STATS',
  SET_LETTERS_ERRORS: 'SET_LETTERS_ERRORS',
  SET_TREEMAP_CHARTS: 'SET_TREEMAP_CHARTS',
  SET_FILTERS: 'SET_FILTERS',
  SET_AVAILABLE_FILTERS: 'SET_AVAILABLE_FILTERS',
  RESET_FILTERS: 'RESET_FILTERS',
  SET_SEARCH: 'SET_SEARCH',
  RESET_SEARCH: 'RESET_SEARCH',
  SET_LOADING: 'SET_LOADING',
  SET_IS_FILTERS_APPLIED: 'SET_IS_FILTERS_APPLIED',
  SET_TOTAL_COUNT: 'SET_TOTAL_COUNT',
  SET_SORT: 'SET_SORT',
  SET_ASK_RIA_FILTERS: 'SET_ASK_RIA_FILTERS',
  SET_DATA_GRID_FILTERS: 'SET_DATA_GRID_FILTERS'
};

export default actions;
