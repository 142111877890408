/* eslint-disable no-dupe-keys */
const styles: { [key: string]: any } = {
  iconContainers: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  primaryCompletionDialogPaper: {
    height: '90vh',
    width: '90vw',
    backgroundColor: '#EEEEEE',
    maxWidth: 'none',
    maxHeight: 'none',
    borderRadius: 3,
    transition: 'all 1s ease-in-out'
  },
  dialogHeading: {
    color: 'primary.dark',
    padding: '13px 10px',
    borderRadius: '10px 10px 0px 0px',
    fontSize: 18,
    fontWeight: '600'
  }
};

export default styles;
