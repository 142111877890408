import { EMA_GUIDANCE_FILTERS } from '../const';

const getFilters = (apiData: any) => {
  const filters = EMA_GUIDANCE_FILTERS.map((currentFilter: { [key: string]: any }) => ({
    ...currentFilter,
    options: [...(apiData?.[currentFilter?.value] || [])]
  }));

  return filters;
};

export default getFilters;
