import React from 'react';

const css = `
.reg_a{fill:#0A806DFF;}
`;

const cssDisabled = `
.reg_disabled{fill:#b2bec3;}
`;

// eslint-disable-next-line react/prop-types
const Reg360Icon = ({ disabled = false }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='33.705' height='25' viewBox='0 0 33.705 25'>
      <defs>
        <style>{disabled ? cssDisabled : css}</style>
      </defs>
      <path
        className={disabled ? 'reg_disabled' : 'reg_a'}
        d='M308.287,156.49a.987.987,0,0,1-.124-1.967,21.568,21.568,0,0,0,7.868-2.381c1.786-1.041,2.77-2.264,2.77-3.445,0-1.3-1.151-2.366-2.117-3.031a.987.987,0,1,1,1.119-1.627c1.945,1.338,2.972,2.948,2.972,4.658,0,1.94-1.3,3.722-3.751,5.151a23.212,23.212,0,0,1-8.612,2.634,1.042,1.042,0,0,1-.126.008Zm0,0'
        transform='translate(-287.071 -134.394)'
      />
      <path
        className={disabled ? 'reg_disabled' : 'reg_a'}
        d='M16.612,149.308l-2.633-2.633a.987.987,0,1,0-1.4,1.4l.769.769a23.791,23.791,0,0,1-7.705-1.99c-2.333-1.122-3.671-2.551-3.671-3.922,0-1.162.958-2.37,2.7-3.4a.987.987,0,1,0-1.007-1.7c-3.03,1.8-3.666,3.765-3.666,5.1,0,2.191,1.7,4.216,4.79,5.7a26.278,26.278,0,0,0,8.878,2.224l-1.086,1.086a.988.988,0,0,0,1.4,1.4l2.633-2.633a.988.988,0,0,0,0-1.4Zm0,0'
        transform='translate(0 -128.626)'
      />
      <path
        className={disabled ? 'reg_disabled' : 'reg_a'}
        d='M97.636,96.525v-.238c0-.838-.513-1-1.2-1-.425,0-.563-.375-.563-.75s.138-.751.563-.751c.475,0,.976-.062.976-1.076,0-.725-.413-.9-.926-.9-.613,0-.925.15-.925.638,0,.425-.188.713-.913.713-.9,0-1.013-.188-1.013-.788,0-.975.7-2.239,2.852-2.239,1.589,0,2.789.575,2.789,2.264a2.2,2.2,0,0,1-.963,2.051,1.813,1.813,0,0,1,1.276,1.839v.238c0,2.051-1.413,2.827-3.165,2.827-2.151,0-2.977-1.313-2.977-2.364,0-.563.238-.713.925-.713.8,0,1,.175,1,.651,0,.588.551.725,1.113.725C97.336,97.651,97.636,97.338,97.636,96.525Zm0,0'
        transform='translate(-87.294 -84.2)'
      />
      <path
        className={disabled ? 'reg_disabled' : 'reg_a'}
        d='M214.915,96.286V96.4c0,2.151-1.338,2.952-3.064,2.952s-3.077-.8-3.077-2.952V93.084c0-2.151,1.388-2.952,3.19-2.952,2.114,0,2.952,1.313,2.952,2.351,0,.6-.288.788-.913.788-.538,0-1.013-.138-1.013-.713,0-.475-.5-.725-1.088-.725-.738,0-1.176.388-1.176,1.251V94.21a2,2,0,0,1,1.564-.55,2.345,2.345,0,0,1,2.627,2.627Zm-4.19.25c0,.863.425,1.238,1.126,1.238s1.113-.375,1.113-1.238v-.113c0-.913-.413-1.263-1.126-1.263a1,1,0,0,0-1.113,1.151Zm0,0'
        transform='translate(-195.029 -84.199)'
      />
      <path
        className={disabled ? 'reg_disabled' : 'reg_a'}
        d='M325.055,96.4V93.084c0-2.151,1.338-2.952,3.064-2.952s3.077.8,3.077,2.952V96.4c0,2.151-1.351,2.952-3.077,2.952S325.055,98.55,325.055,96.4Zm4.19-3.315c0-.863-.425-1.251-1.126-1.251s-1.113.388-1.113,1.251V96.4c0,.863.413,1.251,1.113,1.251s1.126-.388,1.126-1.251Zm0,0'
        transform='translate(-303.656 -84.199)'
      />
      <path
        className={disabled ? 'reg_disabled' : 'reg_a'}
        d='M412.314,5.925a2.962,2.962,0,1,1,2.962-2.962A2.966,2.966,0,0,1,412.314,5.925Zm0-3.95a.987.987,0,1,0,.987.987A.989.989,0,0,0,412.314,1.975Zm0,0'
        transform='translate(-382.404)'
      />
    </svg>
  );
};

export default Reg360Icon;
