import { Typography, Box } from '@mui/material';
import { MagnifyingGlassIcon } from '../../../assets/svgs/Icons';

const EmptyResult = ({
  firstMessage = '',
  secondMessage = '',
  actionText = null,
  height = '50vh',
  iconStyling = {
    width: 64,
    height: 64,
    fontSize: 45
  },
  marginTop = 10
}: any) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      height={height}
      marginTop={marginTop}>
      <Box
        sx={{
          width: iconStyling.width,
          height: iconStyling.height,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: 'gray.background',
          borderRadius: '50%',
          mb: 3
        }}>
        <MagnifyingGlassIcon sx={{ height: '22px', width: '22px', color: 'gray.950' }} />
      </Box>
      <Typography fontWeight={700} fontSize={14}>
        {firstMessage}
      </Typography>
      <Typography fontSize={14}>{secondMessage}</Typography>
      {actionText && (
        <Box display='inline' mt={4}>
          {actionText}
        </Box>
      )}
    </Box>
  );
};

export default EmptyResult;
