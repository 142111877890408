import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import useQuery from '../../helpers/customHooks/queryParameter';
import {
  createSourceModuleDropdownMapping,
  createSourceModulePayloadMapping,
  extractSourceDropdown,
  getCDPSourceDropdown,
  getDefaultCDPSourceDropDown
} from '../Home/utils';
import PopupSearchBarWithResults from '../../components/CustomSearchBar/PopupSearchBarWithGenericResults';
import { SourceDropdown } from '../Home/types';
import { decodeBase64ToObject, encodeObjectToBase64 } from '../../utils/encodeDecodeObject';

const CdpSearchBar = ({ modalOpen, setModalOpen }: any) => {
  const history = useHistory();
  const { search: locationSeach } = useLocation();
  const query = useQuery();
  const { pathname } = useLocation();
  const [selectedSources, setSelectedSources] = useState<SourceDropdown[]>([]);

  const cdpSourceDropDown = useMemo(() => getCDPSourceDropdown(), []);
  const cdpDefaultSourceDropDown = useMemo(() => getDefaultCDPSourceDropDown(), []);

  const handleCDPClick = useCallback(
    (data: any) => {
      const { application_type: type } = data as any;
      const convertedSelectedSources = createSourceModulePayloadMapping(selectedSources);
      const payloadVal = {
        source: convertedSelectedSources
      };
      const encodedPayload = encodeObjectToBase64(payloadVal) ?? '';
      const activeIngredients = data?.generic_name[0];
      const tradeName = data?.product_name[0];

      let searchQuery = `&searchTerm=${tradeName}`;
      if (type) {
        searchQuery = `type=${type}&${searchQuery}`;
      }
      history.push(
        `/cdp/core/${data.identifier}/${
          data?.source
        }/${activeIngredients.toLowerCase()}?${searchQuery}&payload=${encodedPayload}`,
        { prevPath: pathname }
      );
    },
    [query]
  );

  useEffect(() => {
    try {
      const urlParams = new URLSearchParams(locationSeach);
      const payload = urlParams.get('payload') ?? null;
      const backToSearch = urlParams.get('backTo') ?? null;
      let selectedSourcesFound = false;
      if (payload) {
        const decoded: any = decodeBase64ToObject(payload);
        if (decoded?.source) {
          const selectedSourcesFromParams = createSourceModuleDropdownMapping(decoded.source);
          const newSelectedSources = extractSourceDropdown(
            cdpDefaultSourceDropDown,
            selectedSourcesFromParams
          );
          setSelectedSources(newSelectedSources);
          selectedSourcesFound = true;
        }
      } else if (backToSearch) {
        const decodedBackToSearch: any = decodeBase64ToObject(backToSearch);
        const decodedSplit = decodedBackToSearch?.path.split('/');
        const pathValue = decodedSplit?.[decodedSplit.length - 1];
        const decodedPath: any = decodeBase64ToObject(pathValue);
        if (decodedPath?.source) {
          const selectedSourcesFromParams = createSourceModuleDropdownMapping(decodedPath.source);
          const newSelectedSources = extractSourceDropdown(
            cdpDefaultSourceDropDown,
            selectedSourcesFromParams
          );
          setSelectedSources(newSelectedSources);
          selectedSourcesFound = true;
        }
      }
      if (!selectedSourcesFound) {
        setSelectedSources(cdpDefaultSourceDropDown);
      }
    } catch (err) {
      setSelectedSources(cdpDefaultSourceDropDown);
    }
  }, [locationSeach]);

  return (
    <PopupSearchBarWithResults
      title='Clinical Development Plan (CDP)'
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      sources={cdpSourceDropDown}
      selectedSources={selectedSources}
      setSelectedSources={setSelectedSources}
      handleCardClick={handleCDPClick}
      type='cdp'
    />
  );
};

export default React.memo(CdpSearchBar);
