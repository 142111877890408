import makeRequest from '../client';

export const postDrugDevelopmentTools = (filters: any, limit: number, offset: number) =>
  makeRequest(`/dd-tools?limit=${limit}&offset=${offset}`, 'POST', filters);
export const getDrugDevelopmentToolsProject = (id: string) => makeRequest(`/dd-tools/${id}`, 'GET');

export const getDocumentsSignedURL = (payload: any) =>
  makeRequest('/dd-tools/resource', 'POST', payload);

export const getAllDocuments = (filters: any) => makeRequest('/dd-tools', 'POST', filters);

export const getInsights = (filters: any) => makeRequest('/dd-tools/insights', 'POST', filters);
