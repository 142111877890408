import React, { useMemo } from 'react';
import {
  ListItemIcon,
  ListItemText,
  PopoverOrigin,
  Typography,
  MenuItem,
  CircularProgress,
  Box
} from '@mui/material';
import { CustomCheckIcon } from '../../../assets/svgs/Icons';
import RoundedMenu from './RoundedMenu';
import reviewMenuStyles from '../../../pages/SearchResults/styles/ReviewMenu.styles';

const MenuItemList = ({ options, onOptionSelect }: any) => {
  return options.map((opt: any) => (
    <MenuItem
      key={opt.label}
      onClick={() => onOptionSelect?.(opt)}
      sx={{
        padding: '6px 24px 6px 16px !important'
      }}>
      <ListItemIcon sx={{ minWidth: '14px !important' }}>
        <CustomCheckIcon
          sx={{
            ...reviewMenuStyles.customCheckIcon,
            opacity: opt.selected ? 1 : 0
          }}
        />
      </ListItemIcon>
      <ListItemText>
        <Typography
          sx={{
            ...reviewMenuStyles.listItemText,
            fontWeight: opt.selected ? 800 : 400
          }}>
          {opt.label}
        </Typography>
      </ListItemText>
    </MenuItem>
  ));
};

const MenuWithTickOption = ({
  open,
  anchorEl,
  onClose,
  options,
  onOptionSelect,
  isNestedMenu = false
}: any) => {
  // Function to decide the position of the nested menu
  const getSubMenuPosition = useMemo(() => {
    if (!anchorEl) return undefined;
    // You can customize this logic based on your layout and styles
    const windowWidth = window.innerWidth;
    const nestedRect = anchorEl?.getBoundingClientRect();
    const spaceRight = windowWidth - (nestedRect?.right ?? 0);
    const nestedWidth = nestedRect?.width ?? 999;

    // Open to the right if there is enough space, otherwise open to the left
    return spaceRight > nestedWidth
      ? {
          anchorOrigin: { vertical: 'top', horizontal: 'right' } as PopoverOrigin,
          transformOrigin: { vertical: 'top', horizontal: 'left' } as PopoverOrigin
        }
      : {
          anchorOrigin: { vertical: 'top', horizontal: 'left' } as PopoverOrigin,
          transformOrigin: { vertical: 'top', horizontal: 'right' } as PopoverOrigin
        };
  }, [anchorEl]);

  return isNestedMenu ? (
    <MenuItemList options={options} onOptionSelect={onOptionSelect} />
  ) : (
    <RoundedMenu
      open={open}
      disablePortal={isNestedMenu}
      anchorEl={anchorEl}
      anchorOrigin={getSubMenuPosition?.anchorOrigin}
      transformOrigin={getSubMenuPosition?.transformOrigin}
      MenuListProps={{ onMouseLeave: () => onClose?.() }}
      onClose={onClose}
      sx={{
        '& .MuiList-root': { padding: '0 !important' }
      }}>
      {options?.length > 0 ? (
        <MenuItemList options={options} onOptionSelect={onOptionSelect} />
      ) : (
        <Box display='flex' width={100} justifyContent='center'>
          <CircularProgress color='success' size={20} />
        </Box>
      )}
    </RoundedMenu>
  );
};

export default React.memo(MenuWithTickOption);
