/* eslint-disable import/prefer-default-export */
import { GridColDef } from '@mui/x-data-grid-pro';

export const PATENTS: { [key: string]: GridColDef[] } = {
  us: [
    {
      field: 'patent_num',
      headerName: 'Patent Number',
      headerClassName: 'table-header',
      width: 250,
      cellClassName: 'table-row'
    },
    {
      field: 'patent_use_code',
      headerName: 'Patent Use Code',
      headerClassName: 'table-header',
      width: 400,
      cellClassName: 'table-row'
    },
    {
      field: 'definition',
      headerName: 'Definition',
      headerClassName: 'table-header',
      width: 450,
      cellClassName: 'table-row'
    },
    {
      field: 'patent_expire_date',
      headerName: 'Patent Expire Date',
      headerClassName: 'table-header',
      width: 250,
      cellClassName: 'table-row'
    }
  ],
  ca: [
    {
      field: 'patent_num',
      headerName: 'Patent Number',
      headerClassName: 'table-header',
      width: 250,
      cellClassName: 'table-row'
    },
    {
      field: 'category',
      headerName: 'Category',
      headerClassName: 'table-header',
      width: 250,
      cellClassName: 'table-row'
    },
    {
      field: 'submission_date',
      headerName: 'Submission Date',
      headerClassName: 'table-header',
      width: 250,
      cellClassName: 'table-row'
    },
    {
      field: 'grant_date',
      headerName: 'Grant Date',
      headerClassName: 'table-header',
      width: 250,
      cellClassName: 'table-row'
    },
    {
      field: 'patent_expire_date',
      headerName: 'Patent Expire Date',
      headerClassName: 'table-header',
      width: 250,
      cellClassName: 'table-row'
    }
  ]
};
