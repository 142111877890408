import React, { useCallback } from 'react';
import {
  Autocomplete,
  CircularProgress,
  Grow,
  IconButton,
  InputAdornment,
  TextField
} from '@mui/material';
import headerStyles from '../../Header/styles/Header';
import { Clear, Search } from '@mui/icons-material';

const VivproAutocomplete = React.memo(
  ({
    id,
    label,
    onInputChange,
    setSearch,
    open,
    onOpen,
    onClose,
    loading,
    options = [],
    value,
    filterOptions,
    onFocus,
    noIcon,
    clearSearchSuggestions,
    disableSearchIcon = false,
    disableSearch = false
  }) => {
    const handleOnChange = useCallback(
      (event, value) => {
        // event could be null when we set a value directly
        if (event) {
          onInputChange?.(event);
        }
        setSearch?.(value);
      },
      [onInputChange, setSearch]
    );

    return (
      <Autocomplete
        id={id}
        freeSolo
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        loading={loading}
        options={options}
        value={value}
        filterOptions={filterOptions}
        onInputChange={handleOnChange}
        onFocus={onFocus}
        sx={headerStyles.search}
        disabled={disableSearch}
        disableClearable
        renderInput={params => {
          return (
            <TextField
              required
              {...params}
              placeholder={label}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position='end' sx={noIcon && { display: 'none' }}>
                    <Grow in={value.length > 0} unmountOnExit>
                      <IconButton
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 25
                          },
                          p: 0,
                          mr: 1
                        }}
                        onClick={() => {
                          handleOnChange(null, '');
                          onClose();
                          clearSearchSuggestions();
                        }}>
                        <Clear />
                      </IconButton>
                    </Grow>

                    {loading ? (
                      // loading is undefined in here for some reason...
                      <CircularProgress color='inherit' size={20} ml={1} />
                    ) : (
                      <>
                        {!disableSearchIcon && (
                          <IconButton
                            type='submit'
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: 25
                              },
                              p: 0
                            }}>
                            <Search />
                          </IconButton>
                        )}
                      </>
                    )}
                  </InputAdornment>
                )
              }}
            />
          );
        }}
      />
    );
  }
);

export { VivproAutocomplete };
