import { makeStyles } from '@material-ui/core';
import lightLogin from '../../Images/lightLoginBackground.jpg';

export default makeStyles(theme => ({
  root: {
    height: '100vh',
    width: 'inherit',
    backgroundImage: `url(${lightLogin})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed'
  },
  loginForm: {
    margin: '1rem',
    justifyContent: 'center',
    minWidth: '45vh',
    minHeight: '90vh'
  },
  button: {
    justifyContent: 'center',
    // width: '60%',
    background: `linear-gradient(180deg, ${theme.palette.gradient.primaryFrom} 10%, ${theme.palette.gradient.primaryTo} 90%)`,
    color: theme.palette.white,
    borderRadius: '1.5rem',
    marginTop: '1rem',
    boxShadow: `2px 3px 5px 2px ${theme.palette.primary.shadow}`
  },
  loginBackground: {
    justifyContent: 'center',
    minHeight: '30vh',
    padding: '2rem',
    borderRadius: '0.6rem',
    width: '80vh'
  },
  titleBox: {
    justifyContent: 'center'
    // padding: '1rem'
  },
  logoBox: {
    justifyContent: 'center'
    // padding: '1rem'
  },
  logo: {
    minHeight: '10vh',
    minWidth: '15vh'
    // marginLeft : '33%',
  },
  forgotPassword: {
    justifyContent: 'center',
    padding: '1rem'
  },
  // textField: {
  //   boxShadow: `0 0 10px 3px ${theme.palette.primary.shadow}`,
  //   border: 'none',
  //   width: '85%',
  //   borderRadius: '1rem',
  //   height: '3rem',
  //   '& .MuiInputBase-root': {
  //     margin: '0.5rem',
  //     fontSize: '1rem',
  //   },
  // },
  textField: {
    // width: '85% !important',
    /* border: none; */
    /* height: 3rem; */
    /* box-shadow: 0 0 10px 3px rgb(220, 220, 220,0.5); */
    /* border-radius: 1rem; */

    border: '2px solid #e3f1ff !important',
    // marginLeft: '4px !important',
    borderRadius: '10px !important',
    backgroundColor: '#f7fbff !important',
    buttonBox: {
      justifyContent: 'center'
    },
    '& .MuiInputBase-root': {
      margin: '0.5rem',
      fontSize: '1rem'
    }
  },
  fieldName: {
    padding: '5px',
    // width: '25%',
    textAlign: 'left',
    color: '#3B3B3B',
    opacity: '0.8',
    fontSize: '16px',
    fontWeight: 'bold'
  },
  textFieldName: {
    // width: '42% !important',
    border: '2px solid #e3f1ff !important',
    // marginLeft: '8px !important',
    borderRadius: '10px !important',
    backgroundColor: '#f7fbff !important',
    buttonBox: {
      justifyContent: 'center !important'
    },
    '& .MuiInputBase-root': {
      margin: '0.5rem',
      fontSize: '1rem'
    }
  },
  filterParent: {
    // border: `2px solid ${theme.palette.primary.lightBorder}`,
    borderRadius: '10px',
    // width : '100%',
    // marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.lightBackground
  },
  filterLabel: {
    paddingLeft: '10px',
    textAlign: 'left',
    width: '100%',
    color: theme.palette.primary.main
  },
  filterSelect: {
    paddingLeft: '4px',
    textAlign: 'left',
    fontSize: '2vh',
    width: '100%',
    color: theme.palette.black
  }
}));
