import React, { useCallback } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

// icons
import CloseIcon from '@mui/icons-material/Close';

// Styles
import styles from './styles';

const Chip: React.FC<any> = ({ title, value, onClose, style = {} }) => {
  const handleClose = useCallback(() => onClose(value), []);
  return (
    <Stack
      spacing={2}
      direction='row'
      justifyContent='flex-start'
      sx={{ ...styles.selectedOptionsContainer, ...style }}>
      <CloseIcon cursor='pointer' fontSize='small' onClick={handleClose} />
      <Tooltip title={title}>
        <Typography noWrap variant='subtitle2'>
          {title.length > 20 ? `${title.substring(0, 20)}...` : title}
        </Typography>
      </Tooltip>
    </Stack>
  );
};

export default React.memo(Chip);
