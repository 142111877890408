import { getGridStringOperators } from '@mui/x-data-grid-pro';
import { isEmpty, isEqual } from 'lodash';
import DatagridFilterInputComponent from './DatagridFilterInputComponent';
import getUserDetails from '../pages/SearchResults/utils/getUserDetails';
import { getAdvancedSearchForDataGrdFilters } from '../api/pages/ResultsPage';

export const PAGINATION_DEFAULT_OPTIONS = {
  limit: 20,
  offset: 0
};

// This function will help to return operation list use in datagrid filter (contain, start with etc)
const getDatagridFilterOption = (columns: any[], operatorValues: string[], state: any) => {
  return columns.map((column: any) => ({
    ...column,
    filterOperators: getGridStringOperators()
      .filter(operator => operatorValues.includes(operator.value))
      .map((operator: any) => ({
        ...operator,
        InputComponent: (props: any) => DatagridFilterInputComponent(props, state)
      }))
  }));
};

export const getOperatorBasedSpecialQuery = (operator: string, query: string) => {
  if (operator === 'contains') {
    // eslint-disable-next-line no-useless-escape
    return `\"${query}\"`;
  }

  if (operator === 'equals') {
    return `\`${query}\``;
  }

  return query;
};

export const getPageLimit = (isPreviousFiltersSame: boolean, limit: number) => {
  return isPreviousFiltersSame
    ? limit ?? PAGINATION_DEFAULT_OPTIONS.limit
    : PAGINATION_DEFAULT_OPTIONS.limit;
};

export const getPageOffset = (isPreviousFiltersSame: boolean, offset: number) => {
  return isPreviousFiltersSame
    ? offset ?? PAGINATION_DEFAULT_OPTIONS.offset
    : PAGINATION_DEFAULT_OPTIONS.offset;
};

export const getDataGridFilter = async (
  appliedFilters: any,
  datagridFilter: any,
  sortFields: any,
  limit: number,
  offset: number,
  state: any,
  currentUser: any,
  source: any
) => {
  try {
    // check if there any change in datagrid payload or not
    const isPreviousDataGridFiltersSame = isEqual(state.dataGridFilters, datagridFilter);

    let dataGridFiltersQuery = '';
    let logicalOperator = 'AND';
    const filterFields = datagridFilter?.items || [];
    filterFields.forEach((filter: any, index: number) => {
      if (filter?.value?.query?.length > 0) {
        if (index >= 1) {
          dataGridFiltersQuery += ` ${logicalOperator} `;
        }
        dataGridFiltersQuery += `(${filter.field}: ${getOperatorBasedSpecialQuery(
          filter.operator,
          filter.value.query
        )})`;

        logicalOperator = filter.value.operator.toUpperCase();
      }
    });
    const userDetails = getUserDetails(currentUser);

    // check if there any change in datagrid payload does contain value parameter
    const appliedDatagidFilter =
      datagridFilter?.items?.length > 0 &&
      datagridFilter?.items.every((item: any) => Object.keys(item).includes('value'));

    if (!isEmpty(dataGridFiltersQuery) && appliedDatagidFilter) {
      const payload: any = {
        source,
        query: dataGridFiltersQuery,
        userDetails,
        category_name: 'indications_and_usage', // default
        filters: appliedFilters,
        view_type: 'application', // default
        approval_date: {
          start_date: '', // default
          end_date: ''
        },
        use_synonyms: false,
        approvalPathway: {},
        searchType: 'advanced',
        sort: {}
      };
      if (sortFields.length !== 0) {
        payload.sort[sortFields[0].field] = sortFields[0].sort;
      }

      // if there is not change in datagrid filter but pagination is updated then updated limit and offset else keep the default limit and offset
      const PAGE_LIMIT = getPageLimit(isPreviousDataGridFiltersSame, limit);
      const PAGE_OFFSET = getPageOffset(isPreviousDataGridFiltersSame, offset);

      const res = await getAdvancedSearchForDataGrdFilters(PAGE_OFFSET, PAGE_LIMIT, payload);
      if (res?.data?.status === 200) {
        return res;
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
  return {};
};

export default getDatagridFilterOption;
