import makeRequest from '../client';

/**
 *
 * Function gets all data for guidance module
 */
export const postGuidanceDocs = (filters, limit, offset) =>
  makeRequest(`/guidance_documents?limit=${limit}&offset=${offset}`, 'POST', filters);

export const guidanceInsights = filters =>
  makeRequest(`/guidance_documents/insights`, 'POST', filters);

export const getGuidanceDocumentsSignedURL = payload =>
  makeRequest('/guidance_documents/resource', 'POST', payload);

export const getAllGuidanceDocuments = filters =>
  makeRequest('/guidance_documents', 'POST', filters);
