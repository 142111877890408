import React, { useCallback, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';

import useFeedback from '../../hooks/useFeedback';

const FEEDBACK_TYPES = [
  { label: 'General feedback', id: 'GENERAL' },
  { label: 'Incorrect Data', id: 'INCORRECT_DATA' },
  { label: 'Missing Data', id: 'MISSING_DATA' },
  { label: 'Other', id: 'OTHER' }
];

const FeebackDialog: React.FC<any> = ({ open, onClose, appNumber, tradeName }) => {
  const messageRef = useRef<any>();
  const [feedbackType, setFeedbackType] = useState<string>(FEEDBACK_TYPES[0].id);
  const { submit, isLoading } = useFeedback();
  const handleMessageSubmit = useCallback(async () => {
    await submit(appNumber, tradeName, feedbackType, messageRef.current?.value);
    onClose();
  }, [feedbackType, appNumber]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ textAlign: 'center' }}>Feedback for CDP</DialogTitle>
      <DialogContent>
        <DialogContentText pl={1}>Thank you for taking time to provide feedback.</DialogContentText>
        <FormControl>
          <Box width='420px' p={1}>
            <Typography>Type</Typography>
            <Typography>Choose the type of feedback you are submitting.</Typography>
            <Autocomplete
              disablePortal
              id='feedback-type-selection'
              options={FEEDBACK_TYPES}
              sx={{ width: '100%' }}
              onChange={(e: any, value: any) => setFeedbackType(value.id)}
              defaultValue={FEEDBACK_TYPES[0]}
              // eslint-disable-next-line react/jsx-props-no-spreading
              renderInput={(params: any) => <TextField {...params} />}
            />
          </Box>
          <Box p={1} pt={0}>
            <Typography>Enter your message below</Typography>
            <TextField
              sx={{ width: '100%' }}
              id='feeback-message'
              multiline
              rows={4}
              defaultValue=''
              inputProps={{ maxLength: 1000, ref: messageRef }}
            />
          </Box>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Box pb={1}>
          <LoadingButton
            variant='contained'
            onClick={onClose}
            loadingPosition='end'
            disabled={isLoading}
            sx={{
              mr: 1,
              p: 1,
              pl: 5,
              pr: 5,
              textTransform: 'capitalize',
              fontSize: 16,
              borderRadius: 10,
              bgcolor: 'primary.main',
              color: 'white.main',
              cursor: 'pointer'
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant='contained'
            onClick={handleMessageSubmit}
            loadingPosition='end'
            loading={isLoading}
            sx={{
              ml: 1,
              p: 1,
              pl: 5,
              pr: 5,
              textTransform: 'capitalize',
              fontSize: 16,
              borderRadius: 10,
              bgcolor: 'primary.main',
              color: 'white.main',
              cursor: 'pointer'
            }}
          >
            Submit
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(FeebackDialog);
