import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// MATERIAL UI IMPORTS
import { ThemeProvider } from '@mui/material/styles';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Fab from '@material-ui/core/Fab';
import { Steps } from 'intro.js-react';

import themev5 from '../../themev5';

// styles
import BiologicsStyles from './styles/Biologics.styles';
// other components
import BusinessIntelligence from './BusinessIntelligence';
import Immunogenicity from './Immunogenicity';
import FeatureButton from '../Buttons/FeatureButton';
// import PlusIcon from '../../Images/svgs/plus';
// assets
import AriaLogoSmall from '../../assets/svgs/Core/AriaLogoSmall';
// store
import Store from '../../store';
import Actions from '../../store/actions';
// guide tour
import 'intro.js/introjs.css';
import Airplane from '../../Images/svgs/airplane';
// utils
import { savePathnameForAriaBackBtn } from '../../pages/Aria/utils/ariaCache';

const Biologics = () => {
  const classes = BiologicsStyles();
  // get current url path for aria back btn
  const location = useLocation();
  const history = useHistory();
  const { dispatch } = useContext(Store);
  const [dashboard, setDashboard] = useState('Business Intelligence');
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    const curTab = new URLSearchParams(location.search).get('tab') || 'Business Intelligence';
    if (curTab !== dashboard) {
      setDashboard(curTab);
    }
  }, [location.search]);

  // guide tour state
  const [steps] = useState([
    {
      element: '#blaStats',
      intro:
        'Provides summary by number of approvals by year, approval time for original application as well as proportion approved by accelerated approval, breakthrough designation, Fast Track and Orphan designation',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass'
    },
    {
      element: '#blaStats',
      intro:
        'Provides summary of biologics type, number of approvals by year and important immunogenicity information included in the label (ADA incidence, Neutralizing Activity, Impact on PK, Efficacy and Safety)',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass'
    }
  ]);
  const [stepsEnabled, setStepsEnabled] = useState(false);

  const onExit = () => {
    setStepsEnabled(false);
  };
  const onBeforeChange = nextStepIndex => {
    if (nextStepIndex === 0) {
      setDashboard('Business Intelligence');
    }
    if (nextStepIndex === 1) {
      setDashboard('Immunogenicity');
    }
  };
  useEffect(() => {
    async function getData() {
      try {
        await dispatch({ type: Actions.SET_MODULE, value: 'biologics' });
      } catch (e) {
        //eslint-disable-next-line no-console
        console.error(e);
      }
    }

    getData();
  }, [dispatch]);

  const handleChange = useCallback(
    event => {
      setDashboard(event.target.value);
      const updatedSearchParams = new URLSearchParams(location.search);
      updatedSearchParams.set('tab', event.target.value);
      history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
    },
    [location.search, location.pathname]
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box className={classes.root}>
      <Steps
        enabled={stepsEnabled}
        steps={steps}
        showProgress
        scrollToElement
        initialStep={0}
        onBeforeChange={onBeforeChange}
        onExit={onExit}
      />
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        className={classes.dropdownBox}>
        <FormControl className={classes.formControl}>
          <Select
            labelId='demo-controlled-open-select-label'
            id='blaStats'
            variant='standard'
            disableUnderline
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={dashboard}
            onChange={handleChange}
            className={classes.dropdownContainer}>
            <MenuItem value='Business Intelligence' className={classes.dropdownText}>
              Business Intelligence
            </MenuItem>
            <MenuItem value='Immunogenicity' className={classes.dropdownText}>
              Immunogenicity
            </MenuItem>
          </Select>
        </FormControl>

        {/* ARIA BTN */}
        <ThemeProvider theme={themev5}>
          <FeatureButton
            iconId='bot'
            text='Ask RIA'
            ariaAttributes={{
              source: 'us',
              module: 'bla',
              queryParam: ''
            }}
            handleClick={() =>
              savePathnameForAriaBackBtn(location.pathname, history, '/aria/bla/us')
            }
            startIcon={<AriaLogoSmall />}
          />
        </ThemeProvider>
      </Box>
      {dashboard === 'Business Intelligence' ? <BusinessIntelligence /> : null}
      {dashboard === 'Immunogenicity' ? <Immunogenicity values={[25, 45, 75, 12, 90]} /> : null}
      <Fab
        aria-label='add'
        sx={{
          position: 'fixed',
          left: 10,
          bottom: 10
        }}
        onClick={() => {
          window.scroll(0, 0);
          setStepsEnabled(true);
        }}>
        <Airplane />
      </Fab>
    </Box>
  );
};

export default Biologics;
