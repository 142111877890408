const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    mx: '16px',
    py: '16px',
    overflow: 'hidden',
    height: '100%'
  },
  topStack: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  applicationsGrid: { pr: '6px', pl: '10px' },
  pageEndBox: { width: '100%', height: '5vh', mt: '-10vh', zIndex: -1 },
  divider: { backgroundColor: 'gray.200', opacity: 0.3, m: '12px 0px 16px 12px' }
};

export default styles;
