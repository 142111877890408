import React, { createContext, useReducer } from 'react';

import reducers, { RegulatoryInformationContextInterface } from './reducers';

type Props = {
  children: React.ReactNode;
};
const initialState = {
  loading: false,
  productDetails: [],
  loadingProductDetails: true,
  stateDetails: [],
  loadingStateDetails: true,
  generics: [],
  loadingGenerics: true,
  patents: [],
  loadingPatents: true,
  exclusivity: [],
  loadingExclusivity: true,
  prescribingInfo: [],
  searchDetails: {},
  proceduralSteps: [],
  loadingproceduralSteps: true,
  // This is here because of the header API
  db: '',
  applicationDetails: {},
  approvalHistory: [],
  adverseEvents: [],
  rems: [],
  commissionProcedures: [],
  filters: {
    approvalHistory: [],
    pmrc: []
  },
  isApprovalHistoryFilterApplied: false,
  isPMRCFilterApplied: false,
  postmarketingData: [],
  rld_info: [],
  triggerAddtoReport: false,
  triggerCsvDownload: false,
  triggerOpenFilters: false,
  inProjects: [],
  is_favorite: false,
  is_subscribed: false,
  sortingApprovalHistory: false,
  summaryDetails: {},
  loadingResourcesDetails: true,
  resourcesDetails: [],
  appliedFilters: {},
  companionDiagnosticDevices: [],
  orphanDesignations: [],
  submissionDocuments: [],
  commissionProceduresSubmissionDocuments: [],
  remsDocuments: [],
  resourceDocuments: [],
  sideEffectDocuments: []
};

const RegulatoryInformationStore = createContext<{
  regulatoryState: RegulatoryInformationContextInterface;
  regulatoryDispatch: React.Dispatch<any>;
}>({ regulatoryState: initialState, regulatoryDispatch: () => null });

const { Provider } = RegulatoryInformationStore;

export const RegulatoryInformationStateProvider = ({ children }: Props) => {
  const [regulatoryState, regulatoryDispatch] = useReducer(reducers, initialState);
  return <Provider value={{ regulatoryState, regulatoryDispatch }}>{children}</Provider>;
};

export default RegulatoryInformationStore;
