import { getMatchSubmissionsData } from '../../../api/pages/ResultsPage';

const getMatchSubmissions = async (searchId: string) => {
  try {
    const response = await getMatchSubmissionsData(searchId);
    if (response?.status === 200) {
      const value = response?.data?.body;
      return value;
    }
    return {};
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('failed get matches synonyms', e);
    return {};
  }
};

export default getMatchSubmissions;
