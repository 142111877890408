import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import styles from '../../styles/Project.styles';
import useProjectData from '../../hooks/useProjectData';
import ProjectIcon from '../../../../assets/svgs/UserProfile/ProjectIcon';

import TitleDescriptionDialog from '../TitleDescriptionDialog';
import { PROJECT_DESCRIPTION_MAX_LENGTH, PROJECT_TITLE_MAX_LENGTH } from '../../constants';

const ProjectList = () => {
  const { projectDataList, fetchProjectList, creatNewProject } = useProjectData();

  const history = useHistory();
  const location = useLocation();
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    fetchProjectList();
  }, []);

  useEffect(() => {
    if (location.pathname === '/account/project' && projectDataList.length !== 0) {
      history.push(`/account/project/${projectDataList[0]?.project_id}?tab=searches`);
    }
  }, [projectDataList]);

  const handleClick = (projectId: number) => {
    if (projectId) {
      history.push(`/account/project/${projectId}?tab=searches`);
    }
  };

  const handleSubmit = async (title: string, description: string) => {
    if (title) {
      const payload: any = {
        name: title.trim(),
        description: description.trim()
      };
      await creatNewProject(payload);
      setOpenDialog(false);
    }
  };

  return (
    <Stack direction='column' sx={styles.projectNavigationRoot}>
      <Typography sx={styles.heading}>MY PROJECTS</Typography>
      <ListItem disablePadding>
        <ListItemButton
          sx={{ ...styles.navigationMenuItem, mb: 0 }}
          onClick={() => {
            setOpenDialog(true);
          }}>
          <ListItemIcon sx={styles.createProjecIconBG}>
            <Add sx={styles.menuItemAddIcon} />
          </ListItemIcon>
          <ListItemText primary='Create Project' sx={styles.navigationListItemText} />
        </ListItemButton>
      </ListItem>
      <List sx={styles.rootList} component='nav' aria-labelledby='nested-list-subheader'>
        {projectDataList.map((project: any) => (
          <ListItem key={project?.project_id} disablePadding>
            <ListItemButton
              sx={{
                ...styles.navigationMenuItem,
                mt: '8px',
                mb: 0
              }}
              selected={Boolean(
                location.pathname.match(project?.project_id) ||
                  location.pathname === project?.project_id
              )}
              onClick={() => handleClick(project?.project_id)}>
              <ListItemIcon>
                <ProjectIcon sx={styles.projectIcon} />
              </ListItemIcon>
              <Tooltip title={project?.name?.length > 15 ? project?.name : ''} placement='right'>
                <ListItemText
                  primary={project?.name}
                  sx={styles.navigationListItemText}
                  primaryTypographyProps={{ noWrap: true }}
                />
              </Tooltip>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {openDialog && (
        <TitleDescriptionDialog
          dialogType='project'
          heading='Add Project Details'
          title=''
          description=''
          handleSubmit={handleSubmit}
          handleClose={() => {
            setOpenDialog(false);
          }}
          action='edit'
          titleMaxlength={PROJECT_TITLE_MAX_LENGTH}
          descriptionMaxLength={PROJECT_DESCRIPTION_MAX_LENGTH}
        />
      )}
    </Stack>
  );
};

export default memo(ProjectList);
