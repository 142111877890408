import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    // height:'70vh',
    // border:'2px solid red',

    marginTop: theme.spacing(30)
    // paddingTop: theme.spacing(10),
  },
  graph: {
    height: '100%'
  },
  container: {
    height: '60vh',
    paddingTop: theme.spacing(3),
    // marginTop
    // border:'2px solid red',
    width: '25%'
  },
  text: {
    marginTop: theme.spacing(5),
    color: theme.palette.biologics.pieText,
    fontSize: theme.spacing(2),
    marginLeft: theme.spacing(2)
  }
}));
