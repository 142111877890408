import React, { useCallback, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

// material ui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';

// icons
import IosShareIcon from '@mui/icons-material/IosShare';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import { InfoOutlined } from '@mui/icons-material';
import FilterIcon from '../../../assets/svgs/CardsPage/FilterIcon';

// constants
import { ARIA_SEARCH_SUBTEXT, DOCUMENT_STATE } from '../utils/constants';

// utils
import generateDescriptionText from '../utils/generateDescriptionText';

// components
import SearchField from './SearchField';
import ToolsOptionsMenu from './ToolsOptionsMenu';
import ShareLinks from '../../../components/Dialogs/ShareLink';

// styles
import stylesheet from '../styles/Headers.styles';

// custom hooks
import useQuery from '../../../helpers/customHooks/queryParameter';

// context
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';
import AriaStore from '../../../store/Aria';
import AriaActions from '../../../store/Aria/actions';
import CardActions from '../../CardsPage/components/CardActions';
import SelectedFiltersBar from './SelectedFiltersBar';
import Breadcrumbs from '../../../components/Breadcrumbs';

// eslint-disable-next-line react/prop-types
const Headers = ({ onChangePage }) => {
  const styles = stylesheet();

  // aria context store
  const { ariaState, ariaDispatch } = useContext(AriaStore);
  const { state, dispatch } = useContext(GlobalStore);

  // get query from url parameters
  const query = useQuery();
  // for redirecting to different page
  const history = useHistory();

  // url parameters
  const { module, source, searchId } = useParams();

  const handleDidyoumeanTextClick = () => {
    const search = ariaState.didYouMeanText?.replace(/<\/?[^>]+(>|$)/g, ''); // Remove html tags
    ariaDispatch({ type: AriaActions.SET_ORIGINAL_RESULTS, value: [] });
    ariaDispatch({ type: AriaActions.SET_ARIA_RESULTS, value: [] });
    ariaDispatch({ type: AriaActions.SET_SUGGESTED_ANS, value: [] });
    ariaDispatch({ type: AriaActions.SET_DOCUMENT_OPEN, value: false });
    ariaDispatch({ type: AriaActions.SET_DOCUMENT_LINK, value: '' });
    ariaDispatch({ type: AriaActions.SET_FILTERS, value: {} });
    ariaDispatch({ type: AriaActions.SET_TOP_ANSWER, value: '' });
    ariaDispatch({ type: AriaActions.SET_ERROR, value: '' });
    ariaDispatch({ type: AriaActions.SET_DID_YOU_MEAN_TEXT, value: '' });
    const term = search || query.get('term');
    history.replace(
      `/aria/${module}/${source}?term=${term.trim()}&bucket=all${
        query.get('resultsPage') ? '&resultsPage=true' : ''
      }${query.get('operationId') ? `&operationId=${query.get('operationId')}` : ''}${
        query.get('searchTerm') ? `&searchTerm=${query.get('searchTerm')}` : ''
      }`,
      history.location.state
    );
  };

  const changePageNumber = (event, page) => {
    onChangePage(page);
  };

  const openFilters = () => {
    ariaDispatch({ type: AriaActions.SET_FILTERS_DRAWER, value: !ariaState.showFiltersDrawer });
  };

  const handleShareClick = async () => {
    await dispatch({
      type: GlobalActions.SET_SHARE_LINK,
      value: {
        entityType: 'searchLink',
        entityDetails: { link: window.location.href },
        open: true,
        textPrefix: `Aria search: ${query.get('term')}`
      }
    });
  };
  const getBreadCrumbs = useCallback(() => {
    return <Breadcrumbs links={state.breadCrumbs} />;
  }, [state.breadCrumbs]);

  return (
    <Box sx={styles.root}>
      {/* navigation button */}
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Box sx={{ overflow: 'hidden' }}>{getBreadCrumbs()}</Box>
        <Stack direction='row' spacing={0} sx={{ alignItems: 'center' }}>
          {!ariaState.loading && (
            <CardActions
              index={ariaState.searchId || searchId}
              isFavorite={ariaState.isFavorite}
              cardsPage
              inProject={ariaState.inProjects}
              typeData={{
                id: ariaState.searchId || searchId,
                type: 'aria_search'
              }}
              listView
              type='ariaSearch'
            />
          )}

          {/* share search link */}
          {!ariaState.loading && (
            <>
              <Tooltip title='Share search through Collaborate'>
                <IconButton onClick={handleShareClick}>
                  <IosShareIcon fontSize='small' />
                </IconButton>
              </Tooltip>
              {state.shareLink?.open && <ShareLinks />}
            </>
          )}
          {/* action buttons for downloading and sorting results  */}
          <Tooltip title='Filters'>
            <IconButton onClick={openFilters}>
              <Badge variant='dot' invisible={isEmpty(ariaState.filters)} color='primary'>
                <FilterIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          <ToolsOptionsMenu />
        </Stack>
      </Stack>

      {/* search field box */}
      <Stack direction='row' spacing={2} sx={styles.searchBoxContainer}>
        <Box sx={styles.searchBox}>
          <SearchField />
        </Box>
      </Stack>

      {/* Did you mean text */}
      {ariaState.didYouMeanText && (
        <Box sx={styles.didYouMeanText} pt={2}>
          Did you mean:{' '}
          <Typography
            variant='subtitle2'
            component='span'
            onClick={handleDidyoumeanTextClick}
            dangerouslySetInnerHTML={{ __html: ariaState.didYouMeanText }}
          />
        </Box>
      )}

      {/* search description */}
      <Stack direction='row' spacing={1} sx={styles.helperTextContainer}>
        <Stack spacing={1} direction='row' alignItems='center'>
          {/* Show a note when there are more than 1000 applications that are being sent in the filters for aria searches */}
          {ariaState.filters['group-id']?.length > 1000 ||
            (JSON.parse(sessionStorage.getItem('aria_cache'))?.ariaFilterList?.length > 1000 && (
              <Zoom in timeout={1500}>
                <Tooltip title='ARIA results derived from top 1000 records of the source dataset.'>
                  <TipsAndUpdatesOutlinedIcon sx={{ fontSize: 16 }} color='warning' />
                </Tooltip>
              </Zoom>
            ))}
          <Typography mt={1} variant='subtitle2' sx={styles.subtext}>
            Showing search results {generateDescriptionText(query.get('searchTerm')) && 'for '}
            <Typography component='span' variant='subtitle1' sx={styles.searchText}>
              {query.get('resultsPage') && generateDescriptionText(query.get('searchTerm'))}
            </Typography>{' '}
            from <b>{ARIA_SEARCH_SUBTEXT[source]}</b>
          </Typography>
          {source === 'hpra' && (
            <Tooltip title='This search is limited to HPRA documents and EMA educational materials only'>
              <InfoOutlined fontSize='small' />
            </Tooltip>
          )}
        </Stack>

        {/* pagination for aria results */}
        <Stack direction='row' alignItems='center'>
          <Pagination
            onChange={changePageNumber}
            count={ariaState.maxPage}
            shape='rounded'
            size='small'
            variant='text'
            sx={{ '& .MuiPaginationItem-root': { fontSize: 14 } }}
            page={ariaState.page}
          />
        </Stack>
      </Stack>
      {ariaState.documentOpen || ariaState.documentState !== DOCUMENT_STATE.close ? (
        <SelectedFiltersBar />
      ) : null}
    </Box>
  );
};

export default Headers;
