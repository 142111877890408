const Styles: any = {
  graphTitle: {
    // padding: '0px 5px 15px',
    fontSize: '2vh',
    color: '#838691',
    fontWeight: 'bold',
    fontFamily: 'fontFamily'
  },
  graphContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '40vh',
    width: '98%',
    backgroundColor: 'white.main',
    borderRadius: '16px',
    padding: '1rem'
  },
  add: {
    fontSize: '1.87vh',
    cursor: 'pointer',
    textTransform: 'capitalize',
    align: 'left'
  },
  reportText: {
    color: 'primary',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    '&:hover': {
      background: 'none'
    }
  },
  addToReport: {
    height: '35px',
    display: 'flex-end',
    cursor: 'pointer',
    background: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: 'none',
      border: 1,
      borderColor: 'primary.main',
      borderStyle: 'solid'
    }
  }
};
export default Styles;
