const styles = {
  root: { height: '100%', overflow: 'auto' },
  loadingBox: { height: '10vh', mx: '16px', mt: '90px' },
  applicationSkeletion: {
    borderRadius: '20px'
  },
  listSkeletonBox: { width: '100%', height: '10vh', mt: 2 }
};

export default styles;
