import { createSvgIcon } from '@mui/material';

const OpenArrowIcon = createSvgIcon(
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_4468_45752)'>
      <path
        d='M3.76787 11.3223L7.02063 8.06949C7.40478 7.68533 7.40479 7.05673 7.02063 6.67258L3.76787 3.41977'
        stroke='#757575'
        strokeWidth='0.74834'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.30496 11.3223L10.5577 8.06949C10.9419 7.68533 10.9419 7.05673 10.5577 6.67258L7.30496 3.41977'
        stroke='#616161'
        strokeWidth='0.74834'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_4468_45752'>
        <rect
          width='13.5182'
          height='12.7218'
          fill='white'
          transform='matrix(-1 0 0 -1 13.7187 13.3574)'
        />
      </clipPath>
    </defs>
  </svg>,
  'OpenArrow'
);

export default OpenArrowIcon;
