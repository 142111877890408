import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { Typography, Divider, Stack, Tooltip, Box, Button } from '@mui/material';

import GlobalActions from '../../../store/actions';
import GlobalStore from '../../../store';
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';

import styles from './styles';
import { PlusIcon } from '../../../assets/svgs/Icons';

interface SafetyDataItem {
  id: string;
  sample_size: string;
  description: string;
  // Add any additional fields present in your safety data items
}

interface SafetyDataMetadata {
  sample_size: string;
  description: string;
  // Define any additional fields present in your metadata
}

interface DataItem {
  snapshot?: any;
  center?: string;
  submission_num?: string;
  submission_type?: string;
  // Include other properties of DataItem as needed
}

interface LabelValueRowProps {
  label: string;
  value: string | number;
}

interface SafetyDataRowProps {
  data: SafetyDataItem & { index: number }; // Combining SafetyDataItem with an index
  metadata: SafetyDataMetadata;
  lastItem: boolean;
}

const LabelValueRow: React.FC<LabelValueRowProps> = ({ label, value }) => (
  <Stack direction='row' spacing={2} alignItems='center'>
    <Typography variant='subtitle2' sx={styles.snapshotLabel}>
      {label}:
    </Typography>
    <Typography variant='subtitle2' sx={styles.snapshotText}>
      {value}
    </Typography>
  </Stack>
);

const SafetyDataRow: React.FC<SafetyDataRowProps> = ({ data, metadata, lastItem }) => (
  <>
    <LabelValueRow label={metadata.sample_size} value={data.sample_size} />
    <LabelValueRow label={metadata.description} value={data.description} />
    {!lastItem && <Divider />}
  </>
);

interface SubmissionSummaryProps {
  dataItem: DataItem;
  selectedTabIndex: number;
}

const SubmissionSummary: React.FC<SubmissionSummaryProps> = ({ dataItem, selectedTabIndex }) => {
  const { source, number } = useParams<any>();
  const { state, dispatch } = useContext<any>(GlobalStore);
  const { regulatoryState } = useContext<any>(RegulatoryInformationStore);
  const { snapshot } = dataItem;
  const selectedTab = Object.keys(snapshot)[selectedTabIndex];

  const addToReport = () => {
    const id = `snapshot_submission_summary_${source}_${number}_${dataItem?.submission_type}_${dataItem?.submission_num}_${snapshot[selectedTab]?.document_id}`;
    const isSameAdministrativeSummaryExist =
      state?.reportLayout?.sections?.length > 0 &&
      state?.reportLayout?.sections?.some((section: any) => section.id === id);
    if (!isSameAdministrativeSummaryExist) {
      dispatch({
        type: GlobalActions.ADD_TEMPLATE_TO_REPORT,
        value: {
          id,
          sectionType: 'SNAPSHOT',
          data: {
            number,
            document_id: snapshot[selectedTab]?.document_id,
            source,
            submission_num: dataItem?.submission_num,
            submission_type: dataItem?.submission_type,
            summaryType: 'SUBMISSION_SUMMARY'
          },
          style: {
            placement: {
              h: 50,
              w: 12
            },
            title: `Submission Summary - ${
              typeof selectedTab === 'string'
                ? selectedTab
                : regulatoryState?.applicationDetails?.product_name
            } - ${dataItem?.submission_type} - ${dataItem?.submission_num}`
          }
        }
      });
    }
    dispatch({
      type: GlobalActions.SET_ALERT,
      value: { status: true, message: 'Successfully added to Report.', color: 'success' }
    });
  };

  return (
    <Stack
      spacing={2}
      sx={{
        bgcolor: 'white.main',
        borderRadius: '12px',
        p: '20px'
      }}>
      <Box display='flex' justifyContent='space-between'>
        <Tooltip title='Provides an overview at the submission level' placement='top-start'>
          <Typography variant='subtitle1' sx={styles.snapshotHeading}>
            Submission Summary
          </Typography>
        </Tooltip>
        {snapshot[selectedTab]?.safety_data?.data &&
          snapshot[selectedTab]?.safety_data?.data?.length > 0 && (
            <Button onClick={addToReport}>
              <Box display='flex' gap={0.5} alignItems='center'>
                <PlusIcon sx={{ fontSize: 14, color: 'primary.70' }} />
                <Typography color='primary.700' fontSize={14} textTransform='none'>
                  Add to report
                </Typography>
              </Box>
            </Button>
          )}
      </Box>

      <LabelValueRow
        label='No. of indications'
        value={snapshot[selectedTab]?.clinical_summary?.length || 0}
      />
      <Divider />

      {(snapshot[selectedTab]?.safety_data?.data?.length ?? 0) === 0 &&
        dataItem.center === 'CBER' && (
          <Typography variant='subtitle2' sx={styles.snapshotText}>
            CBER Extraction is in progress...
          </Typography>
        )}

      {(snapshot[selectedTab]?.safety_data?.data?.length ?? 0) > 0 && (
        <Typography variant='subtitle2' sx={styles.snapshotSubHeading}>
          Clinical trial safety database summary used to support product safety
        </Typography>
      )}

      {snapshot[selectedTab]?.safety_data?.data?.map((item: any, index: any, array: any) => (
        <SafetyDataRow
          key={item.id}
          data={{ ...item, index }}
          metadata={snapshot[selectedTab].safety_data!.metadata} // Asserting metadata is non-null
          lastItem={index === array.length - 1}
        />
      ))}
    </Stack>
  );
};

export default SubmissionSummary;
