import React, { useCallback } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface DateRangeSelectorProps {
  label: string;
  maxDate: Date;
  minDate: Date;
  startValue: Date | null;
  endValue: Date | null;
  // eslint-disable-next-line no-unused-vars
  onStartDateChange: (val: Date) => void;
  // eslint-disable-next-line no-unused-vars
  onEndDateChange: (val: Date) => void;
  // eslint-disable-next-line
  disabled?: boolean;
}

const DateRangeSelector = ({
  label,
  maxDate,
  minDate,
  onStartDateChange,
  onEndDateChange,
  startValue = null,
  endValue = null,
  disabled = false
}: DateRangeSelectorProps) => {
  /**
   * function to update start date category
   * @param {string} newValue is the start value that the user clicks inside the calendar
   */
  const handleStartChange = useCallback(
    (newValue: Date | null) => {
      if (newValue) onStartDateChange(newValue);
      if (!endValue) onEndDateChange(new Date());
    },
    [onStartDateChange]
  );

  /**
   * function to update end date category
   * @param {string} newValue is the end value that the user clicks inside the calendar
   */
  const handleEndChange = useCallback(
    (newValue: Date | null) => {
      if (newValue) onEndDateChange(newValue);
    },
    [onEndDateChange]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Typography variant='subtitle1'>{label}</Typography>
      <Box sx={{ justifyContent: 'space-between' }}>
        <DatePicker
          minDate={minDate}
          maxDate={endValue ? new Date(endValue) : maxDate}
          label='From'
          value={startValue}
          onChange={handleStartChange}
          disabled={disabled}
          renderInput={params => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              id='start_date'
              helperText={null}
              sx={{ width: '140px', marginRight: '10px', marginBottom: '15px' }}
            />
          )}
          components={{
            OpenPickerIcon: ArrowDropDownIcon
          }}
        />
        <DatePicker
          minDate={startValue ? new Date(startValue) : minDate}
          disabled={!startValue || disabled}
          maxDate={maxDate}
          label='To'
          value={endValue}
          onChange={handleEndChange}
          renderInput={params => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              id='end_date'
              helperText={null}
              sx={{ width: '140px' }}
            />
          )}
          components={{
            OpenPickerIcon: ArrowDropDownIcon
          }}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default React.memo(DateRangeSelector);
