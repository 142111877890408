import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
    padding: 0,
    // marginTop
    // border:'2px solid red',
    width: '90%',
    backgroundColor: theme.palette.white,
    borderRadius: '16px'
  },
  graphContainer: {
    height: theme.spacing(35)
  },
  headings: {
    color: theme.palette.biologics.textPrimary,
    fontWeight: 'bold',
    fontSize: theme.spacing(2)
  },
  graph: {
    height: '100%'
    // marginTop:theme.spacing(2),
    // border:'2px solid red'
  },
  text: {
    color: theme.palette.biologics.pieText,
    fontSize: theme.spacing(2),
    marginLeft: theme.spacing(2),
    paddingTop: '1rem'
  },
  skeleton: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  circleLoading: {
    color: '#adadac'
  },
  add: {
    fontSize: theme.spacing(1.87),
    color: theme.palette.navbar.button,
    cursor: 'pointer'
  }
}));
