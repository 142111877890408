const actions = {
  SET_PMR_SUR_TABLE: 'SET_PMR_SUR_TABLE',
  SET_CATEGORY_GRAPH: 'SET_CATEGORY_GRAPH',
  SET_CMT_STATUS_GRAPH: 'SET_CMT_STATUS_GRAPH',
  SET_SUBPART_DESC_GRAPH: 'SET_SUBPART_DESC_GRAPH',
  SET_PMR_SUR_STATS: 'SET_PMR_SUR_STATS',
  SET_PMR_SUR_ERRORS: 'SET_PMR_SUR_ERRORS',
  SET_DONUT_CHARTS: 'SET_DONUT_CHARTS',
  SET_FILTERS: 'SET_FILTERS',
  RESET_FILTERS: 'RESET_FILTERS',
  SET_SEARCH: 'SET_SEARCH',
  RESET_SEARCH: 'RESET_SEARCH',
  SET_LOADING: 'SET_LOADING',
  SET_AVAILABLE_FILTERS: 'SET_AVAILABLE_FILTERS',
  SET_IS_FILTERS_APPLIED: 'SET_IS_FILTERS_APPLIED',
  SET_TOTAL_COUNT: 'SET_TOTAL_COUNT',
  SET_SORT: 'SET_SORT',
  SET_DATA_GRID_FILTERS: 'SET_DATA_GRID_FILTERS'
};

export default actions;
