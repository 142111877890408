import { PDCO_FILTERS } from '../constants';

const getFiltersOptions = (apiData: any) => {
  const filters = PDCO_FILTERS.map((currentFilter: { [key: string]: any }) => ({
    ...currentFilter,
    options: [...(apiData?.[currentFilter?.value] || [])]
  }));

  return filters;
};

export default getFiltersOptions;
