/* eslint-disable react/prop-types */
import { Box, Typography, LinearProgress } from '@mui/material';
import LogoIcon from '../../../assets/svgs/Icons/LogoIcon';
import { TIPS } from '../constants';

const Loading = ({ showTips = true, inContainer = false }) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      height={inContainer ? '45vh' : '50vh'}>
      <LogoIcon sx={{ fontSize: 60 }} />
      <Box width={240} mt={2} mb={3}>
        <LinearProgress
          sx={{
            borderRadius: 4,
            color: 'primary.avatar',
            bgcolor: 'gray.300'
          }}
        />
      </Box>
      {showTips && (
        <>
          <Typography color='gray.900' fontSize={14} fontWeight={500} lineHeight='24px'>
            {TIPS[Math.floor(Math.random() * TIPS.length)]}
          </Typography>
          <Typography color='gray.700' lineHeight='24px'>
            For more tips click on &quot;Tips for better workflow&quot; on Home page
          </Typography>
        </>
      )}
    </Box>
  );
};

export default Loading;
