const styles = {
  mainHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px 8px 0px 0px',
    padding: '10px 16px',
    backgroundColor: 'gray.200',
    height: '48px',
    alignItems: 'center'
  },
  searchBarWrapper: {
    padding: '10px 8px 10px 16px',
    backgroundColor: 'gray.50',
    borderBottom: '1px solid',
    borderColor: 'gray.200'
  },
  searchBarTextField: {
    '& .MuiInputBase-root': {
      borderRadius: '32px',
      height: '28px',
      width: '260px',
      backgroundColor: 'white.main'
    }
  },
  mainSectionWrapper: {
    px: '32px',
    pt: '16px',
    overflowY: 'auto',
    height: '90%',
    position: 'relative',
    minHeight: 'auto'
  },
  radio: {
    padding: 0,
    '&.Mui-checked': {
      color: 'primary.600'
    }
  },
  avatar: {
    width: '14px !important',
    height: '14px !important'
  },
  chip: {
    backgroundColor: 'blue.main',
    color: 'white.main',
    textTransform: 'none !important',
    height: '18px',
    fontSize: '10px',
    width: '60px',
    mr: 1
  },
  cartonChip: {
    ml: 1,
    height: 13,
    fontSize: 10,
    borderRadius: 1,
    fontWeight: 800,
    '& span': {
      px: 0.5
    },
    color: 'red.main'
  },
  applicationChip: {
    ml: 1,
    fontSize: 10,
    height: 13,
    borderRadius: 1,
    fontWeight: 800,
    color: 'white.main',
    '& span': {
      px: 0.5
    },
    cursor: 'default'
  },
  keyDownIcon: {
    height: '14px',
    width: '14px',
    color: 'primary.500',
    position: 'absolute',
    right: '12px',
    pointerEvents: 'none'
  },
  flagIcon: { width: '18px', height: '12px' },
  infoIcon: { height: '16px', width: '16px', cursor: 'pointer' },
  popoverContainer: { pointerEvents: 'none', '& .MuiPaper-root': { borderRadius: '12px' } },
  cardWrapper: { p: 2, width: 288, maxWidth: 288 },
  searchIcon: { height: '14px', width: '14px' },
  tempCtaContainer: { height: '24px', alignItems: 'center' },
  sortIconButton: { minWidth: 'auto' },
  sortIcon: { height: '14px', width: '14px', color: 'gray.600' },
  divider: { mx: '8px', color: 'gray.200' },
  cartonContainer: { textDecoration: 'none' },
  bottomDivider: { mt: '14px', mb: '16px' },
  emptyResultContainer: { textAlign: 'center', mt: '12px' },
  select: {
    fontWeight: '700',
    borderRadius: '4px',
    border: '1px solid',
    borderColor: 'primary.500',
    fontSize: '12px',
    color: 'primary.500',
    width: '160px',
    height: '31px',
    pl: '12px',
    pr: '2px',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'unset'
    }
  },
  selectWrapper: {
    width: 160,
    '& .MuiFormControl-root': {
      pl: 0
    }
  }
};

export default styles;
