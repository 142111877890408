import React, { useContext, useEffect } from 'react';

// mui v5
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import themev5 from '../../themev5';

// hoc
import EMAGuidanceTabs from './hoc/Tabs';

// store
import Store from '../../store';
import Actions from '../../store/actions';

// Provider
import { EMAGuidanceStateProvider } from '../../store/EMAGuidance';

const EMAGuidance = () => {
  const { dispatch } = useContext(Store) as any;

  useEffect(() => {
    dispatch({ type: Actions.SET_MODULE, value: 'ema-guidance' });
  }, []);

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <EMAGuidanceStateProvider>
        <EMAGuidanceTabs />
      </EMAGuidanceStateProvider>
    </ThemeProvider>
  );
};

export default React.memo(EMAGuidance);
