import React from 'react';
//material ui imports
// import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
//styles
import ImmunoPieChartsStyles from '../styles/ImmunoPieCharts.styles';
//other components
import CustomPieChart from '../../CustomComponents/Graphs/CustomPieChart';

const ImmunoPieCharts = ({ data }) => {
  const classes = ImmunoPieChartsStyles();

  return (
    <Box className={classes.root}>
      <Typography variant='h3'>Biologics</Typography>
      <Typography variant='h3'>
        Immunogenicity - Reporting status of immunogenicity data components (reported vs non
        reported)
      </Typography>
      {/* <Box mt={2} display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="flex-start" className={classes.constainer}> */}
      <Grid container spacing={3} lg={12}>
        {data
          ? data &&
            data.map(item => {
              return (
                // <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start" className={classes.container}>
                <Grid item lg={4} className={classes.container}>
                  <Typography className={classes.text}>{item[0].label}</Typography>
                  <CustomPieChart
                    className={classes.graph}
                    data={item}
                    margin={{ top: 20, right: 50, bottom: 0, left: 50 }}
                  />
                </Grid>
                // </Box>
              );
            })
          : null}
      </Grid>
      {/* </Box> */}
    </Box>
  );
};

export default ImmunoPieCharts;
