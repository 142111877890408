import { GridColDef } from '@mui/x-data-grid-pro';
import { capitalize } from 'lodash';
import renderCellExpand from '../../components/Datagrid/DatagridPopover';
import DatagridChipsPopup from '../../components/Datagrid/DatagridChipsPopup';
import DocumentHistoryPDF from './components/DocumentHistoryPDF';
import { Filter } from '../../components/Filters';
import { PIECHARTCOLOR } from '../../components/CustomComponents/Graphs/constant';
import { FILTER_TYPE } from '../../components/NewFilters/constants';

export const SEARCH_IGNORE_FIELDS = ['document_history', 'key', '_id'];

type CustomGridColDef = GridColDef & {
  capitalCasing?: Boolean;
};

export interface EMAGuidancesFilters extends Filter {
  apiRequestKey: string;
  apiResponseKey?: string;
}

const EMAGUIDANCE_COLUMN: CustomGridColDef[] = [
  {
    field: 'document_history',
    headerName: 'Documents',
    width: 100,
    filterable: false,
    capitalCasing: false,
    headerClassName: 'table-header',
    disableExport: true,
    sortable: false,
    renderCell: DocumentHistoryPDF
  },
  {
    field: 'ema_document_no',
    headerName: 'EMA Document No',
    width: 300,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    capitalCasing: false,
    renderCell: renderCellExpand,
    valueGetter: (params: any) => params.value || '-'
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 350,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    capitalCasing: false,
    renderCell: renderCellExpand
  },
  {
    field: 'first_published_date',
    headerName: 'Published Date',
    width: 170,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell'
  },
  {
    field: 'last_updated_date',
    headerName: 'Last Updated Date',
    width: 170,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell'
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 200,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    valueGetter: (params: any) => capitalize(params.value) || '-'
  },
  {
    field: 'keywords',
    headerName: 'Keywords',
    width: 300,
    capitalCasing: false,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridChipsPopup,
    valueGetter: (params: any) => {
      const values = params.value.split(';');
      const newValue: Array<string> = [];
      values.forEach((value: any) => {
        newValue.push(capitalize(value.trim()));
      });
      return newValue.join('; ');
    }
  },
  {
    field: 'legal_effective_date',
    headerName: 'Legal Effective Date',
    width: 150,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    valueGetter: (params: any) => params.value || '-'
  },
  {
    field: 'consultation_date_from',
    headerName: 'Consultation Date From',
    width: 180,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    valueGetter: (params: any) => params.value || '-'
  },
  {
    field: 'consultation_date_to',
    headerName: 'Consultation Date To',
    width: 160,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    valueGetter: (params: any) => params.value || '-'
  }
];

export const EMA_GUIDANCE_FILTERS = [
  {
    id: 1,
    label: 'Status',
    value: 'status',
    elasticSearchType: 'status',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Title',
    value: 'doc_title',
    elasticSearchType: 'title',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Keywords',
    value: 'keywords',
    elasticSearchType: 'keywords',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Enter Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Published Date',
    value: 'first_published_date',
    elasticSearchType: 'first_published_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Last Updated Date',
    value: 'last_updated_date',
    elasticSearchType: 'last_updated_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    id: 7,
    label: 'Legal Effective Date',
    value: 'legal_effective_date',
    elasticSearchType: 'legal_effective_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    id: 8,
    label: 'Consultation Date From',
    value: 'consultation_date_from',
    elasticSearchType: 'consultation_date_from',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    id: 9,
    label: 'Consultation Date To',
    value: 'consultation_date_to',
    elasticSearchType: 'consultation_date_to',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  }
];

export const EMA_GUIDANCE_PIE_CHART = [
  {
    label: 'Keywords',
    apiKey: 'id',
    graphItem: 'keyword_pie_chart',
    count: 3
  },
  {
    label: 'Status',
    apiKey: 'id',
    graphItem: 'status_pie_chart',
    count: 3
  }
];

export const ENTITY_MAPPING: { [key: string]: any } = {
  ema_document_no: { name: 'EMA Document No ' },
  first_published_date: { name: 'First Published Date ' },
  status: { name: 'Status ' },
  legal_effective_date: { name: 'Legal Effective Date ' },
  consultation_date_from: { name: 'Consultation Date From ' },
  consultation_date_to: { name: 'Consultation Date To ' }
};

const day = new Date();
const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
export const CSV_FILENAME = {
  prefix: 'ria',
  module: 'ema_guidances',
  postfix: date,
  additional: '',
  extension: ''
};

export const barChartStyles = {
  colors: ['#B1BBE4'],
  padding: 0.8,
  labelTextColor: '#ffffff',
  axisText: '#A3A3A3',
  axisBottom: {
    tickRotation: 25
  }
};

export const pieChartStyles = {
  colors: PIECHARTCOLOR,
  margin: { top: 20, right: 20, bottom: 20, left: 20 },
  pieLabel: true,
  pieLegend: false,
  innerRadius: 0.7,
  enableLabels: 'both'
};

export const EMA_GUIDANCE_REPORT_QUERIES = [
  {
    id: 'guidance_documents_by_year',
    title: {
      title: 'EMA Guidance Documents by Year',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['first_published_date'], agg_function: 'count', sort_on_axis: 'x' },
    graphStyle: {
      ...barChartStyles,
      axisLeft: {
        tickRotation: 0
      },
      axisBottom: {
        tickRotation: 80
      }
    }
  },
  {
    id: 'status_pie_chart',
    title: {
      title: 'Status',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['status'],
      agg_function: 'count'
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'keyword_pie_chart',
    title: {
      title: 'Keywords',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['keywords'],
      agg_function: 'count',
      limit: 5
    },
    graphStyle: pieChartStyles
  }
];

export const PAGINATION_DEFAULT_OPTIONS = {
  limit: 20,
  offset: 0
};

export const EXPORT_TYPE = {
  csv: 'csv',
  xlsx: 'xlsx'
};

export const EMA_GUIDANCE_COLUMN_NAME_MAPPING = {
  ema_document_no: 'EMA Document No',
  title: 'Title',
  first_published_date: 'Published Date',
  last_updated_date: 'Last Updated Date',
  last_modified_date: 'Last Updated Date',
  status: 'Status',
  keywords: 'Keywords',
  legal_effective_date: 'Legal Effective Date',
  consultation_date_from: 'Consultation Date From',
  consultation_date_to: 'Consultation Date To'
};

export default EMAGUIDANCE_COLUMN;
