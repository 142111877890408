import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

const NotificationHeaderRedirectMenuItem = ({ Icon, handleOnClick, menuItemText }: any) => {
  return (
    <MenuItem onClick={handleOnClick}>
      <ListItemIcon>
        <Icon
          sx={{
            height: '16px',
            width: '16px',
            marginLeft: '10px',
            color: 'gray.700'
          }}
        />
      </ListItemIcon>
      <ListItemText
        sx={{ color: 'gray.700', maxWidth: 100 }}
        primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}>
        {menuItemText}
      </ListItemText>
    </MenuItem>
  );
};
export default NotificationHeaderRedirectMenuItem;
