import React from 'react';

// material ui v5
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import themev5 from '../../themev5';

// context provider
import { AriaStateProvider } from '../../store/Aria';

// custom hooks
import useQuery from '../../helpers/customHooks/queryParameter';

// components
import Banner from './components/Banner';
import AriaLayout from './components/AriaLayout';

const Aria = () => {
  const query = useQuery();

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <AriaStateProvider>
        {/* if query parameter exists then display the results page else initial screen of ARIA */}
        {!query.get('term') ? <Banner /> : <AriaLayout />}
      </AriaStateProvider>
    </ThemeProvider>
  );
};

export default Aria;
