import { createSvgIcon } from '@mui/material';

const ShareIcon = createSvgIcon(
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.6514 14.0539L14.6514 7.57696C14.6472 7.05281 14.4351 6.55175 14.0618 6.18385C13.6884 5.81595 13.1843 5.61129 12.6601 5.61483L10.5372 5.61481L10.5372 6.8807L12.6601 6.88072C12.7535 6.87879 12.8463 6.89527 12.9333 6.92921C13.0202 6.96316 13.0997 7.01391 13.167 7.07857C13.2344 7.14322 13.2884 7.22052 13.3258 7.30603C13.3633 7.39155 13.3836 7.48361 13.3855 7.57696L13.3855 14.0571C13.38 14.2445 13.3007 14.4222 13.1648 14.5514C13.029 14.6806 12.8476 14.7509 12.6601 14.747L3.57478 14.747C3.38679 14.7509 3.20491 14.6802 3.06896 14.5503C2.93301 14.4204 2.85407 14.2419 2.84942 14.0539L2.84942 7.57697C2.85132 7.48362 2.87158 7.39156 2.90906 7.30604C2.94654 7.22053 3.0005 7.14324 3.06786 7.07858C3.13522 7.01392 3.21466 6.96317 3.30164 6.92923C3.38862 6.89528 3.48143 6.8788 3.57478 6.88073L5.47361 6.8807L5.47361 5.61481L3.57478 5.61484C3.05063 5.6113 2.5465 5.81596 2.17313 6.18386C1.79977 6.55176 1.58771 7.05282 1.58353 7.57697L1.58353 14.0571C1.58938 14.5801 1.80217 15.0796 2.17535 15.4461C2.54853 15.8126 3.05171 16.0164 3.57478 16.0129L12.6601 16.0129C13.1837 16.0164 13.6874 15.8122 14.0607 15.445C14.4339 15.0777 14.6464 14.5775 14.6514 14.0539Z'
      fill='currentColor'
    />
    <path
      d='M8.59211 10.1768L8.59211 1.69602L11.181 4.28493L12.0348 3.4336L9.0545 0.453943C8.91473 0.314132 8.74878 0.203227 8.56614 0.127559C8.3835 0.0518919 8.18774 0.0129457 7.99004 0.0129457C7.79234 0.0129457 7.59658 0.0518918 7.41394 0.127559C7.2313 0.203227 7.06535 0.314132 6.92558 0.453943L3.94531 3.4336L4.79905 4.28674L7.38797 1.69783L7.38797 10.1768L8.59211 10.1768Z'
      fill='currentColor'
    />
  </svg>,
  'ShareIcon'
);

export default ShareIcon;
