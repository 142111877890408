import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import SearchList from '../../../components/SearchList/SearchList';

// APIs
import { getGenericSynonyms } from '../../../api/pages/ResultsPage';

// Store
import Store from '../../../store';
import GlobalActions from '../../../store/actions';
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';

const SynonymsReg360 = () => {
  const [searchData, setSearchData] = useState();
  const { dispatch } = useContext(Store) as any;
  const { regulatoryState } = useContext(RegulatoryInformationStore);
  const { source }: any = useParams();

  useEffect(() => {
    const getData = async () => {
      const activeIngredients = regulatoryState?.applicationDetails?.active_ingredients;
      let categoryName = '';
      if (
        source.toLowerCase() === 'eu' ||
        source.toLowerCase() === 'hpra' ||
        source.toLowerCase() === 'hma'
      ) {
        categoryName = 'active_ingredient';
      } else {
        categoryName = 'active_ingredients';
      }
      const response = await getGenericSynonyms(categoryName, activeIngredients);
      await dispatch({
        type: GlobalActions.SET_TREE_DATA,
        value: response.data.body.Success
      });
      setSearchData(response.data.body.Success);
    };
    if (!isEmpty(regulatoryState?.applicationDetails)) {
      getData();
    }
  }, [regulatoryState.db, regulatoryState.applicationDetails]);

  return <Box>{searchData && <SearchList showCount={false} />}</Box>;
};

export default React.memo(SynonymsReg360);
