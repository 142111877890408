import { createSvgIcon } from '@mui/material';

const SwapIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
    <g clipPath='url(#clip0_90_14382)'>
      <path
        d='M24 12.5002C24 5.87624 18.624 0.500244 12 0.500244C5.376 0.500244 0 5.87624 0 12.5002C0 19.1242 5.376 24.5002 12 24.5002C18.624 24.5002 24 19.1242 24 12.5002ZM15.6 5.90024L19.8 10.1002L15.6 14.3002V11.3002H10.8V8.90024H15.6V5.90024ZM8.4 19.1002L4.2 14.9002L8.4 10.7002V13.7002H13.2V16.1002H8.4V19.1002Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_90_14382'>
        <rect width='24' height='24' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>,
  'Swap'
);

export default SwapIcon;
