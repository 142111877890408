import { useCallback, useState } from 'react';
import { getOverlays, getTrialsData } from '../../../api/pages/cdp';
import {
  getFilters,
  getSubmissionAnnotationText,
  getSubmissionFlagOptions,
  prepareGraphData,
  prepareOverlayData
} from '../utils';
import {SUBMISSION_CLASS_COLOR, SUBMISSION_CLASS_COLOR_DARK } from '../const';
import findIndices from '../../../utils/findIndices';
import RESULT_VIEW_TYPES from '../../SearchResults/components/constants';
import {
  extractMeetings,
  getFilteredMeetings,
  getUniqueCategoriesAndTypes,
  handleTrialReviewBarMeetings,
} from '../regInfoUtils';

const useCDPData = () => {
  const [isLoading, setLoading]: [boolean, Function] = useState(false);
  const [isLoadingOverlays, setLoadingOverlays]: [boolean, Function] = useState(false);
  const [errorMessage, setErrorMessage]: [string, Function] = useState('');
  const [responseData, setResponseData]: [any, Function] = useState({});



  const getCDPData = useCallback(
    async (
      cnf: string,
      fdaFilters: any,
      applicationNumber: string,
      dateRange?: any,
      defaultFilters?: boolean,
      source: string = 'ct'
    ) => {
      setResponseData({});
      setLoading(true);
      setLoadingOverlays(true);
      try {
        // Fire both the APIs reuqest together, but wait for overlayDataRequest first and then process the data.
        // If the trials data not present throw the error
        const trialsDataRequest = getTrialsData({
          source,
          data: {
            query: cnf,
            date_filters: dateRange || {},
            appl_num: applicationNumber,
            default_filters: !!defaultFilters,
            trials_source: source,
            view_type: RESULT_VIEW_TYPES.APPLICATION
          }
        });

        const overlayDataRequest = getOverlays({
          source: 'us',
          applicationNumber,
          data: {
            query: cnf,
            trials_source: 'ct',
            date_filters: dateRange,
            use_synonyms: true,
            filters: fdaFilters,
            view_type: RESULT_VIEW_TYPES.APPLICATION
          }
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
          setLoadingOverlays(false);
        });

        let data: any = {};
        try {
          const overlayData = await overlayDataRequest;
          if (overlayData?.data?.Success) {
            // Get renderable Overlays
            const allMeetingData = extractMeetings(overlayData?.data?.Success);

            const overlayGraphData = prepareOverlayData(overlayData?.data?.Success,allMeetingData);
            // append the overlays on the graph
            let filters: any = [];
            const { overlay_data: overlays } = overlayData?.data?.Success || {};
            let submissionFlags: any = [];
            Object.keys(overlays).forEach(src => {
              submissionFlags = overlays[src].submission_flags;
              filters = [...filters, ...getFilters(overlays[src].submission_flags, 'FDA', {})];
            });
            data = {
              ...overlayGraphData,
              submissionFlagOptions: getSubmissionFlagOptions(
                submissionFlags,
                responseData?.submissionFlagOptions || []
              ),
              allMeetingData: allMeetingData,
              filteredMeetingData: allMeetingData,
              meetingOptions: getUniqueCategoriesAndTypes(allMeetingData),
              fdaFilters: filters,
              barGraphData: [...overlayGraphData.trialReviewBars]
            };
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        } finally {
          setLoadingOverlays(false);
        }

        try {
          const trialsData = await trialsDataRequest;
          if (trialsData?.data?.Success) {
            // Get renderable graph data
            const graphData = prepareGraphData(
              trialsData?.data?.Success,
              data?.nctIdsInReview,
              data.trialsReview
            );
            const { trials_data: apidData, default_filters: defaultSelectedFilters } =
              trialsData?.data?.Success || {};
            const filters = getFilters(apidData, 'TRIALS', defaultSelectedFilters);
            // If overlays not present, render the graph with existing bar graph data
            data = {
              ...data,
              ...graphData,
              trialsFilters: filters,
              barGraphData: [...graphData.barGraphData, ...(data.barGraphData || [])],
              nctIds: [...(data.nctIds || []), ...graphData.ctIds]
            };
          } else {
            setErrorMessage('Could not get the graph data');
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
          setErrorMessage('Could not get the graph data');
        } finally {
          setLoading(false);
        }

        setResponseData(data);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        setErrorMessage('Could not get the graph data');
        setLoading(false);
      }
    },
    []
  );

  const selectSubmissionLabelOption = (option: any) => {
    const index = responseData?.submissionFlagOptions?.findIndex(
      (sub: any) => sub.submission === option
    );
    if (index > -1) {
      const newSubmissionOptions = [...responseData.submissionFlagOptions];
      newSubmissionOptions[index].selected = !newSubmissionOptions[index].selected;

      const barIndex = responseData.barGraphData.findIndex((bar: any) => bar.name === option);
      // eslint-disable-next-line no-unused-vars
      const shapesIndices = findIndices(responseData.shapes, option, 'submission');
      let color =
        SUBMISSION_CLASS_COLOR[newSubmissionOptions[index].class] || SUBMISSION_CLASS_COLOR.others;

      // Adding label below the submission using annotation.
      if (newSubmissionOptions[index].selected) {
        color =
          SUBMISSION_CLASS_COLOR_DARK[newSubmissionOptions[index].class] ||
          SUBMISSION_CLASS_COLOR_DARK.others;
        responseData.annotations.push({
          text: getSubmissionAnnotationText(option),
          submission: option,
          x: responseData.barGraphData[barIndex].endDate,
          y: responseData.barGraphData[barIndex].y[0],
          yref: 'y2',
          xref: 'x',
          ay: 15,
          ax: 0,
          arrowhead: 1,
          arrowcolor: 'rgba(255, 255, 255, 0)',
          bgcolor: 'rgba(255, 255, 255, 0.75)',
          font: { color, weight: 'bold' }
        });
      } else {
        // Remove the previous annotations
        responseData.annotations = responseData.annotations.filter(
          (ann: any) => ann.submission !== option
        );
      }

      // Set the color
      responseData.barGraphData[barIndex].marker.color = color;

      // Set the submision line color
      shapesIndices.forEach((shapesIndex: number) => {
        responseData.shapes[shapesIndex].line.dash = 'dot';
        if (newSubmissionOptions[index].selected) {
          responseData.shapes[shapesIndex].line.dash = 'solid';
        }
        responseData.shapes[shapesIndex].line.color = color;
      });

      // Adding circle on the submission line and trials review line intersection
      const blackLineShapes = responseData.shapes.filter((shp: any) =>
        shp?.intersectingSubmissions?.find((sub: any) => sub === option)
      );
      if (newSubmissionOptions[index].selected) {
        blackLineShapes.forEach((blackLine: any) => {
          // eslint-disable-next-line no-param-reassign
          blackLine.line.dash = { ...blackLine.line, dash: 'solid' };
          responseData.barGraphData.push({
            x: [responseData?.submissionFlagOptions[index].endDate],
            y: [blackLine.y0],
            mode: 'markers',
            type: 'scatter',
            marker: {
              color: 'rgb(170, 0, 0)',
              size: 3,
              line: {
                color: 'rgb(0, 0, 0)',
                width: 3
              }
            },
            submission: option,
            name: blackLine.y0,
            showlegend: false
          });
        });
      } else {
        blackLineShapes.forEach((blackLine: any) => {
          // eslint-disable-next-line no-param-reassign
          blackLine.line = { ...blackLine.line, dash: 'dot' };
        });
        responseData.barGraphData = responseData.barGraphData.filter(
          (bar: any) => !(bar.submission === option && bar.type === 'scatter')
        );
      }

      setResponseData({
        ...responseData,
        barGraphData: [...responseData.barGraphData],
        shapes: [...responseData.shapes],
        submissionFlagOptions: newSubmissionOptions
      });
    }
  };

  const selectSubmissionLableGroup = (group: string) => {
    if (responseData.submissionFlagOptions) {
      const newSubmissionOptions = [...responseData.submissionFlagOptions];
      const groupOptions = newSubmissionOptions.filter((sub: any) => sub.group === group);
      const isFullSelected =
        newSubmissionOptions.filter((sub: any) => sub.group === group && sub.selected).length ===
        groupOptions.length;
      // eslint-disable-next-line no-return-assign, no-param-reassign
      groupOptions.forEach((opt: any) => {
        // eslint-disable-next-line no-param-reassign
        opt.selected = !isFullSelected;
        const option = opt.submission;

        const barIndex = responseData.barGraphData.findIndex((bar: any) => bar.name === option);
        // eslint-disable-next-line no-unused-vars
        const shapesIndices = findIndices(responseData.shapes, option, 'submission');
        let color = SUBMISSION_CLASS_COLOR[opt.class] || SUBMISSION_CLASS_COLOR.others;

        // Adding label below the submission using annotation.
        if (opt.selected) {
          color = SUBMISSION_CLASS_COLOR_DARK[opt.class] || SUBMISSION_CLASS_COLOR_DARK.others;
          responseData.annotations.push({
            text: getSubmissionAnnotationText(option),
            submission: option,
            x: responseData.barGraphData[barIndex].endDate,
            y: responseData.barGraphData[barIndex].y[0],
            yref: 'y2',
            xref: 'x',
            ay: 15,
            ax: 0,
            arrowhead: 1,
            arrowcolor: 'rgba(255, 255, 255, 0)',
            bgcolor: 'rgba(255, 255, 255, 0.75)',
            font: { color, weight: 'bold' }
          });
        } else {
          // Remove the previous annotations
          responseData.annotations = responseData.annotations.filter(
            (ann: any) => ann.submission !== option
          );
        }

        // Set the color
        responseData.barGraphData[barIndex].marker.color = color;

        // Set the submision line color
        shapesIndices.forEach((shapesIndex: number) => {
          responseData.shapes[shapesIndex].line.dash = 'dot';
          if (opt.selected) {
            responseData.shapes[shapesIndex].line.dash = 'solid';
          }
          responseData.shapes[shapesIndex].line.color = color;
        });

        // Adding circle on the submission line and trials review line intersection
        const blackLineShapes = responseData.shapes.filter((shp: any) =>
          shp?.intersectingSubmissions?.find((sub: any) => sub === option)
        );
        if (opt.selected) {
          blackLineShapes.forEach((blackLine: any) => {
            // eslint-disable-next-line no-param-reassign
            blackLine.line.dash = { ...blackLine.line, dash: 'solid' };
            responseData.barGraphData.push({
              x: [opt.endDate],
              y: [blackLine.y0],
              mode: 'markers',
              type: 'scatter',
              marker: {
                color: 'rgb(170, 0, 0)',
                size: 3,
                line: {
                  color: 'rgb(0, 0, 0)',
                  width: 3
                }
              },
              submission: option,
              name: blackLine.y0,
              showlegend: false
            });
          });
        } else {
          blackLineShapes.forEach((blackLine: any) => {
            // eslint-disable-next-line no-param-reassign
            blackLine.line = { ...blackLine.line, dash: 'dot' };
          });
          responseData.barGraphData = responseData.barGraphData.filter(
            (bar: any) => !(bar.submission === option && bar.type === 'scatter')
          );
        }
      });

      setResponseData({
        ...responseData,
        barGraphData: [...responseData.barGraphData],
        shapes: [...responseData.shapes],
        submissionFlagOptions: newSubmissionOptions
      });
    }
  };

  const clearAllSubmissionOption = () => {
    const newSubmissionOptions = [...responseData.submissionFlagOptions];
    const selectedOptions = newSubmissionOptions.filter((sub: any) => sub.selected);
    selectedOptions.forEach((opt: any) => {
      // eslint-disable-next-line no-param-reassign
      opt.selected = false;
      const option = opt.submission;

      const barIndex = responseData.barGraphData.findIndex((bar: any) => bar.name === option);
      // eslint-disable-next-line no-unused-vars
      const shapesIndices = findIndices(responseData.shapes, option, 'submission');
      const color = SUBMISSION_CLASS_COLOR[opt.class] || SUBMISSION_CLASS_COLOR.others;

      // Remove the previous annotations
      responseData.annotations = responseData.annotations.filter(
        (ann: any) => ann.submission !== option
      );

      responseData.barGraphData[barIndex].marker.color = color;

      shapesIndices.forEach((shapesIndex: number) => {
        responseData.shapes[shapesIndex].line.dash = 'dot';
        responseData.shapes[shapesIndex].line.color = color;
      });

      const blackLineShapes = responseData.shapes.filter((shp: any) =>
        shp?.intersectingSubmissions?.find((sub: any) => sub === option)
      );

      blackLineShapes.forEach((blackLine: any) => {
        // eslint-disable-next-line no-param-reassign
        blackLine.line = { ...blackLine.line, dash: 'dot' };
      });
      responseData.barGraphData = responseData.barGraphData.filter(
        (bar: any) => !(bar.submission === option && bar.type === 'scatter')
      );
    });

    setResponseData({
      ...responseData,
      barGraphData: [...responseData.barGraphData],
      shapes: [...responseData.shapes],
      submissionFlagOptions: newSubmissionOptions
    });
  };

  const selectSubmissionLabelOptions = (options: any) => {
    options.forEach((option: string) => {
      const index = responseData?.submissionFlagOptions?.findIndex(
        (sub: any) => sub.submission === option
      );
      if (index > -1) {
        const newSubmissionOptions = [...responseData.submissionFlagOptions];
        newSubmissionOptions[index].selected = !newSubmissionOptions[index].selected;

        const barIndex = responseData.barGraphData.findIndex((bar: any) => bar.name === option);
        // eslint-disable-next-line no-unused-vars
        const shapesIndices = findIndices(responseData.shapes, option, 'submission');
        let color =
          SUBMISSION_CLASS_COLOR[newSubmissionOptions[index].class] ||
          SUBMISSION_CLASS_COLOR.others;

        // Adding label below the submission using annotation.
        if (newSubmissionOptions[index].selected) {
          color =
            SUBMISSION_CLASS_COLOR_DARK[newSubmissionOptions[index].class] ||
            SUBMISSION_CLASS_COLOR_DARK.others;
          responseData.annotations.push({
            text: getSubmissionAnnotationText(option),
            submission: option,
            x: responseData.barGraphData[barIndex].endDate,
            y: responseData.barGraphData[barIndex].y[0],
            yref: 'y2',
            xref: 'x',
            ay: 15,
            ax: 0,
            arrowhead: 1,
            arrowcolor: 'rgba(255, 255, 255, 0)',
            bgcolor: 'rgba(255, 255, 255, 0.75)',
            font: { color, weight: 'bold' }
          });
        } else {
          // Remove the previous annotations
          responseData.annotations = responseData.annotations.filter(
            (ann: any) => ann.submission !== option
          );
        }

        // Set the color
        responseData.barGraphData[barIndex].marker.color = color;

        // Set the submision line color
        shapesIndices.forEach((shapesIndex: number) => {
          responseData.shapes[shapesIndex].line.dash = 'dot';
          if (newSubmissionOptions[index].selected) {
            responseData.shapes[shapesIndex].line.dash = 'solid';
          }
          responseData.shapes[shapesIndex].line.color = color;
        });

        // Adding circle on the submission line and trials review line intersection
        const blackLineShapes = responseData.shapes.filter((shp: any) =>
          shp?.intersectingSubmissions?.find((sub: any) => sub === option)
        );
        if (newSubmissionOptions[index].selected) {
          blackLineShapes.forEach((blackLine: any) => {
            // eslint-disable-next-line no-param-reassign
            blackLine.line.dash = { ...blackLine.line, dash: 'solid' };
            responseData.barGraphData.push({
              x: [responseData?.submissionFlagOptions[index].endDate],
              y: [blackLine.y0],
              mode: 'markers',
              type: 'scatter',
              marker: {
                color: 'rgb(170, 0, 0)',
                size: 3,
                line: {
                  color: 'rgb(0, 0, 0)',
                  width: 3
                }
              },
              submission: option,
              name: blackLine.y0,
              showlegend: false
            });
          });
        } else {
          blackLineShapes.forEach((blackLine: any) => {
            // eslint-disable-next-line no-param-reassign
            blackLine.line = { ...blackLine.line, dash: 'dot' };
          });
          responseData.barGraphData = responseData.barGraphData.filter(
            (bar: any) => !(bar.submission === option && bar.type === 'scatter')
          );
        }
        responseData.submissionFlagOptions = newSubmissionOptions;
      }
    });

    setResponseData({
      ...responseData,
      barGraphData: [...responseData.barGraphData],
      shapes: [...responseData.shapes]
    });
  };

  const selectMeetingCategory = (category: any) => {
    const currentMeetingOptions = responseData.meetingOptions;
    const index = currentMeetingOptions.findIndex((meeting: any) => meeting.category === category);
    if (index > -1) {
      const newMeetingOptions = [...currentMeetingOptions];
      newMeetingOptions[index].selected = !newMeetingOptions[index].selected;
      const currentSelectedCategories = newMeetingOptions.filter(c => c.selected);
      const newMeetingData = getFilteredMeetings(
        responseData.allMeetingData,
        currentSelectedCategories
      );

      const newTrialReviewBarsData = handleTrialReviewBarMeetings(
        responseData.barGraphData,
        currentSelectedCategories
      );

      setResponseData((prevState:any) => ({
        ...prevState,
        filteredMeetingData: newMeetingData,
        meetingOptions: newMeetingOptions,
        barGraphData: newTrialReviewBarsData
      }));

    }
  };

  const selectMeetingType = (type: any) => {
    const currentMeetingOptions = responseData.meetingOptions;
    currentMeetingOptions.forEach((meetingOption: any) => {
      meetingOption.selected =
        type == meetingOption.type ? !meetingOption.selected : meetingOption.selected;
    });
    const currentSelectedCategories = currentMeetingOptions.filter((c: any) => c.selected);
    const newMeetingData = getFilteredMeetings(
      responseData.allMeetingData,
      currentSelectedCategories
    );
    const newTrialReviewBarsData = handleTrialReviewBarMeetings(
      responseData.barGraphData,
      currentSelectedCategories
    );

    setResponseData((prevState:any) => ({
      ...prevState,
      filteredMeetingData: newMeetingData,
      meetingOptions: currentMeetingOptions,
      barGraphData: newTrialReviewBarsData
    }));
  };

  const clearAllMeetingOption = () => {
    const currentMeetingOptions = responseData.meetingOptions;
    currentMeetingOptions.forEach((meetingOption: any) => {
      meetingOption.selected = false;
    });
    const currentSelectedCategories:any = []

    const newTrialReviewBarsData = handleTrialReviewBarMeetings(
      responseData.barGraphData,
      currentSelectedCategories
    );


    setResponseData((prevState:any) => ({
      ...prevState,
      filteredMeetingData: [],
      meetingOptions: currentMeetingOptions,
      barGraphData: newTrialReviewBarsData
    }));


  };
  
  

  return {
    isLoading,
    errorMessage,
    responseData,
    isLoadingOverlays,
    getCDPData,
    selectSubmissionLabelOption,
    selectSubmissionLableGroup,
    clearAllSubmissionOption,
    selectSubmissionLabelOptions,
    selectMeetingCategory,
    selectMeetingType,
    clearAllMeetingOption,
  };
};

export default useCDPData;
