import { makeStyles } from '@material-ui/core';
import lightLogin from '../../../Images/Group705.svg';

export default makeStyles(theme => ({
  networkDiagram: {
    margin: '25px 0px',
    padding: '20px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  loadingContainer: {
    width: '100vw',
    height: '100vh'
  },
  ParentNode: {
    fontSize: '2vh'
  },
  ndaNode: {
    fontSize: '1.5vh',
    fontWeight: '900',
    color: 'white',
    background: theme.palette.regulatory.nda,
    borderRadius: '10px',
    width: 'fit-content',
    margin: '0 auto',
    padding: '5px',
    marginBottom: '5px'
  },
  ndaNode2: {
    fontSize: '1.5vh',
    fontWeight: '900',
    color: 'white',
    background: theme.palette.regulatory.nda,
    borderRadius: '10px',
    width: 'fit-content',
    margin: '0 auto',
    padding: '5px',
    marginBottom: '5px'
  },
  Node: {
    fontSize: '1.5vh',
    fontWeight: '600',
    color: '#9A98A4',
    paddingRight: '10px',
    paddingLeft: '10px',
    paddingBottom: '6px'
  },
  NodeCompare: {
    fontSize: '1.5vh',
    fontWeight: '600',

    paddingRight: '10px',
    paddingLeft: '10px',
    paddingBottom: '6px',
    background: theme.palette.biologics.gradient,
    color: 'white',
    borderRadius: '13px',
    paddingTop: '6px',
    boxShadow: '0 0 4px rgb(220, 220, 220, 0.5)'
  },

  NodeValue: {
    color: '#0352cc',
    fontSize: '1.5vh',
    fontWeight: '600',
    padding: '0 5px'
  },
  LabelTitle: {
    color: '#1C7D5D',
    fontWeight: '800',
    padding: '20px 0px 0px',
    fontSize: '3.5vh',
    fontFamily: 'Constantia'
  },
  Label: {
    fontSize: '16px',
    textAlign: 'left',
    fontWeight: '600'
  },
  '.MuiDialogTitle-root': {
    padding: '0 24px'
  },
  node: {
    '&::after': {
      content: '',
      position: 'absolute',
      top: '0px',
      height: `var(--tree-line-height)`,
      boxSizing: 'border-box',
      left: '50%',
      width: '0px'
    }
  },
  StyledErrorTypography: {
    fontSize: '2vh',
    padding: '15px',
    color: theme.palette.black,
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.lightBorder,
    fontWeight: '700'
  },
  dialog: {
    height: 'inherit',
    width: 'inherit',
    backgroundImage: `url(${lightLogin})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed'
  },
  selectLabelC: {
    background: '#33B187',
    color: 'white',
    borderRadius: '10px 10px 0px 0px',
    marginBottom: 'auto',
    fontSize: '2.3vh',
    padding: '13px 10px'
  },
  selectLabel: {
    background: '#33B187',
    color: 'white',
    padding: '13px 10px',
    borderRadius: '10px 10px 0px 0px',
    marginBottom: 'auto',
    fontSize: '2.3vh'
  },
  compareL: {
    height: '30px',
    padding: '3px 20px',
    fontSize: '1.7vh',
    fontWeight: 'bold',
    border: '2px solid #e3f1ff',
    boxShadow: '0 0 4px rgb(220, 220, 220,0.5)',
    borderRadius: '20px',
    backgroundColor: '#119E8A',
    color: '#FFFFFF',
    cursor: 'pointer',
    margin: '0 auto',
    display: 'flex',
    width: 'fit-content'
  },
  labelTitle: {
    fontSize: '1.4vh',
    fontWeight: 500,
    paddingLeft: '5px',
    margin: 'auto'
  },
  Dialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
