const acronymMap: any = {
  document_type: {
    'Guidance Document': 'Guidances',
    'Compliance Policy Guide (CPG)': 'CPG',
    'Small Entity Compliance Guide': 'Comp Guide',
    'Information Sheet': 'Info Sheet',
    Memorandum: 'Memo'
  },
  fda_organization: {
    'Center for Drug Evaluation and Research': 'CDER',
    'Office of Regulatory Affairs': 'ORA',
    'Center for Devices and Radiological Health': 'CDRH',
    'Center for Food Safety and Applied Nutrition': 'CFSAN',
    'Center for Veterinary Medicine': 'CVM'
  },
  products: {
    Drugs: 'Drugs',
    'Medical Devices': 'Devices',
    'Food & Beverages': 'Food',
    Biologics: 'Biologics',
    'Animal & Veterinary': 'Animal'
  }
};

const getTopValuesForPieChart = (values: any, key: any, count = 5) => {
  let sortedData = values.sort((a: any, b: any) => b.value - a.value);
  const otherValues = sortedData.slice(count);
  const otherValue = otherValues.reduce(
    (prev: any, current: any) => ({ ...prev, value: prev.value + current.value }),
    {
      id: 'Other',
      label: 'Other',
      value: 0
    }
  );
  sortedData.length = count;
  sortedData.push(otherValue);

  sortedData = sortedData.map((data: any) => ({
    value: data.value,
    id: acronymMap?.[key]?.[data.id] || data.id
  }));
  return sortedData;
};

export default getTopValuesForPieChart;
