const LABEL_NAMES: any = {
  designation_number: 'Designation Number',
  generic_name: 'Generic Name',
  product_name: 'Product Name',
  opinion_date: 'Opinion Date',
  decision_date: 'Decision Date',
  decision_outcome: 'Decision Outcome',
  designation_status: 'Designation Status',
  sponsor_name: 'Sponsor Name',
  indications_and_usage: 'Indications and Usage',
  product_class: 'Product Class',
  mechanism_of_action: 'Mechanism of Action',
  therapeutic_area: 'Therapeutic Area',
  prevalence: 'Prevalence',
  significant_benefit_clause: 'Significant Benefit Clause',
  age_ranges: 'Age Ranges',
  product_development_stage_at_application: 'Product Development Stage at Application',
  condition_severity: 'Condition Severity',
  criteria_for_medical_plausibility: 'Criteria for Medical Plausibility'
};

export default LABEL_NAMES;
