import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  TimelineDotGreen: {
    backgroundColor: `${theme.palette.regulatory.darkGreen} !important`,
    width: '30px'
  },

  TimelineDotRed: {
    backgroundColor: `${theme.palette.regulatory.nda} !important`,
    width: '30px'
  },
  TimelineGridBox: {
    background: theme.palette.white,
    borderRadius: theme.spacing(0.6),
    height: theme.spacing(6)
  },
  text: {
    fontSize: '0.7rem',
    color: '#6D7D8A',
    fontWeight: '600',
    textAlign: 'left'
  },
  TimelineDateRed: {
    height: theme.spacing(6),
    background: `${theme.palette.successGreen} !important`,
    color: theme.palette.white,
    fontSize: '0.7rem',
    fontWeight: '600',
    padding: `20% 5px`,
    borderRadius: `8px 0px 0px 8px`
  },
  TimelineDateGreen: {
    height: theme.spacing(6),
    background: theme.palette.regulatory.darkGreen,
    color: theme.palette.white,
    fontSize: '0.7rem',
    fontWeight: '600',
    padding: `20% 5px`,
    borderRadius: `8px 0px 0px 8px`
  },
  tabsHeading: {
    padding: '6px 16px'
  },
  heading: {
    fontWeight: '500',
    color: '#3AB09E',
    fontSize: '12px',
    padding: 'unset',
    lineHeight: '1.5'
  },
  Timeline: {
    margin: 'unset'
  },
  document: {
    fontSize: '0.7rem',
    color: theme.palette.regulatory.documentLink,
    fontWeight: '600',
    cursor: 'pointer'
  },
  link: {
    fontSize: '1.6vh',
    fontWeight: '600',
    cursor: 'pointer',
    display: 'block',
    textAlign: 'left'
  },
  linkHeading: {
    fontSize: '1.7vh',
    fontWeight: '600',
    cursor: 'pointer',
    display: 'block',
    paddingBottom: theme.spacing(1)
  },
  linkHeadingLabel: {
    fontSize: '1.7vh',
    fontWeight: '600',
    cursor: 'pointer',
    display: 'block',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(3)
  },
  popoverDiv: {
    padding: theme.spacing(1.2)
  },
  '.MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded': {
    boxShadow: '10px 0px 10px #00000029 !important',
    border: '1px solid #C9D2F6',
    opacity: 1
  },
  topTimelineGriddate: {
    'flex-grow': 0,
    'max-width': '5.333333%',
    'flex-basis': '5.333333%'
  },
  topTimelineGridsubmission: {
    'flex-grow': 0,
    'max-width': '10.666667%',
    'flex-basis': '10.666667%',
    padding: '0px 10px'
  },
  more: {
    color: theme.palette.regulatory.darkGreen,
    fontSize: '0.7rem',
    fontWeight: '600',
    cursor: 'pointer',
    display: 'inline'
  },
  dotlabel: {
    margin: '0 auto'
  },
  documentCount: {
    padding: '0 5px',
    display: 'inline',
    fontSize: '0.7rem',
    fontWeight: 700
  },
  documentIcon: {
    width: '10px'
  },
  snapshots: {
    background: theme.palette.regulatory.TabsSelectColor,
    color: theme.palette.white,
    border: `1px solid ${theme.palette.regulatory.documentLink}`,
    padding: '3px 5px',
    display: 'inline',
    fontSize: '0.7rem',
    fontWeight: 700,
    borderRadius: '10px',
    cursor: 'pointer',
    marginLeft: '10px'
  },
  icons: {
    display: 'contents'
  },
  dialogContent: {
    backgroundColor: '#F2F7F7',
    minHeight: '50vh'
  },
  snapshot: {
    color: theme.palette.successGreen,
    fontSize: '3.7vh',
    fontWeight: '600'
  }
}));
