import React, { useContext, useEffect } from 'react';

// mui v5
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import themev5 from '../../themev5';

// hoc
import GuidanceTabs from './hoc/Tabs';

// store
import Store from '../../store';
import Actions from '../../store/actions';

// Provider
import { GuidanceStateProvider } from '../../store/GuidanceDocuments';

const GuidanceDocuments = () => {
  const { dispatch } = useContext(Store);

  useEffect(() => {
    dispatch({ type: Actions.SET_MODULE, value: 'guidance' });
  }, []);

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <GuidanceStateProvider>
        <GuidanceTabs />
      </GuidanceStateProvider>
    </ThemeProvider>
  );
};

export default GuidanceDocuments;
