import palette from '../../../themev5/palette';

const styles = {
  cardGrid: {
    p: 3,
    pb: 2
    // border:'1px solid red'
  },
  shadowborder: {
    boxShadow: `0px 5px 10px ${palette.black.shadow}`
  },
  skeletonCard: {
    marginTop: 1,
    width: '34vh',
    height: '29vh',
    transition: 'transform .2s',
    borderRadius: 2,
    margin: '0 16px',
    boxShadow: `0px 5px 10px ${palette.black.shadow}`
  },
  tagLineText: {
    fontFamily: 'Jura !important',
    fontSize: '16px',
    weight: 500,
    color: '#000000 !important',
    pl: '5px',
    pb: '10px'
  },
  tagLineSource: {
    fontFamily: 'Jura !important',
    fontSize: '22px',
    weight: 800,
    color: '#000000 !important',
    pl: '5px',
    pb: '10px'
  }
};

export default styles;
