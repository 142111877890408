import { UserProfileInterface } from '.';
import actions from './actions';

const reducers = (state: UserProfileInterface, action: { type: any; value: any }) => {
  switch (action.type) {
    case actions.SET_LOADING: {
      return {
        ...state,
        loading: action.value
      };
    }
    case actions.SET_PROFILE_TIMESTAMP: {
      return {
        ...state,
        timestampUserActivity: action.value
      };
    }
    case actions.SET_PAGINATION_ON_SCROLL: {
      return {
        ...state,
        paginateOnScroll: action.value
      };
    }
    case actions.SET_ACTIVITY_DATA: {
      return {
        ...state,
        activityData: [...action.value]
      };
    }
    case actions.SET_SAVED_DATA: {
      return {
        ...state,
        savedData: structuredClone(action.value)
      };
    }
    case actions.SET_REPORT_DATA: {
      return {
        ...state,
        reportData: action.value
      };
    }
    case actions.SET_PROJECT_LIST: {
      return {
        ...state,
        projectList: action.value
      };
    }
    case actions.SET_CURRENT_PROJECT_ID: {
      return {
        ...state,
        currentProjectId: action.value
      };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
