import React, { createContext, useReducer } from 'react';
import reducers from './reducers';

type Props = {
  children: React.ReactNode;
};

const initialState = {
  stats: {},
  pip_details: [],
  showLabelCompare: false,
  labelCompareList: {},
  pipDownloadData: [],
  filters: [],
  isFiltersApplied: false,
  loading: true,
  statsLoading: true,
  availableFilters: {},
  totalRecord: 0,
  sortFields: [],
  askRiaFilters: [],
  dataGridFilters: {}
};

export interface PediatricsContextInterface {
  stats: any;
  pip_details: any[];
  showLabelCompare: boolean;
  labelCompareList: any;
  pipDownloadData: any[];
  filters: any;
  isFiltersApplied: boolean;
  loading: boolean;
  statsLoading: boolean;
  availableFilters: any;
  totalRecord: number;
  sortFields: any[];
  askRiaFilters: any[];
  dataGridFilters: any;
}

const PediatricsEMAStore = createContext<{
  pediatricsEMAState: PediatricsContextInterface;
  pediatricsEMADispatch: React.Dispatch<any>;
}>({ pediatricsEMAState: initialState, pediatricsEMADispatch: () => null });
const { Provider } = PediatricsEMAStore;

export const PediatricsEMAStateProvider = ({ children }: Props) => {
  const [pediatricsEMAState, pediatricsEMADispatch] = useReducer(reducers, initialState);
  return <Provider value={{ pediatricsEMAState, pediatricsEMADispatch }}>{children}</Provider>;
};

export default PediatricsEMAStore;
