import { useCallback, useContext, useEffect } from 'react';
import Papa from 'papaparse';
import XLSX from 'xlsx';

// api
import {
  getAllPMRSurveillanceDocuments,
  getPMRSurveillanceData,
  getPMRSurveillanceInsights
} from '../../../api/pages/PmrPage';
import PMRSurveillanceStore from '../../../store/PMRSurveillance';

// store
import actions from '../../../store/PMRSurveillance/actions';
import globalActions from '../../../store/actions';
import globalStore from '../../../store/index';

import { mergeOldFilterValues } from '../../../utils/filters';
import getFilterOptions from '../utils/common';
import { EXPORT_TYPE, PAGINATION_DEFAULT_OPTIONS } from '../../PMRImpact/const';
import { getFileName } from '../../../helpers/getFileName';
import { PMR_SUREVILLANCE_COLUMN_NAME_MAPPING } from '../const';
import transformData from '../../../utils/transformData';
import AuthContext from '../../../store/Auth/AuthProvider';

import { getDataGridFilter } from '../../../utils/getDatagridFilterOption';

const usePMRSurveillanceData = () => {
  const { pmrState, pmrDispatch } = useContext(PMRSurveillanceStore);
  const { state, dispatch }: any = useContext(globalStore);
  const { currentUser } = useContext(AuthContext);

  const getPMRSurveillanceTableData = async (payload: any, limit: number, offset: number) => {
    const res = await getPMRSurveillanceData(payload, limit, offset);
    if (res?.data?.Status === 200) {
      pmrDispatch({
        type: actions.SET_PMR_SUR_TABLE,
        value: res.data.Success.pmr_surveillance_table
      });

      pmrDispatch({
        type: actions.SET_TOTAL_COUNT,
        value: res?.data?.Success?.pmr_surveillance_count ?? 0
      });
    }
  };

  const getPMRSurevillanceInsightData = async (filters: any, appliedFilters: any) => {
    const res = await getPMRSurveillanceInsights({ filters });
    if (res?.data?.status === 200) {
      pmrDispatch({
        type: actions.SET_PMR_SUR_STATS,
        value: res?.data?.body?.pmr_surveillance_insights
      });

      let mergedFilters = [];
      const newFilters = getFilterOptions(res?.data?.body?.filters);
      if (appliedFilters.length) {
        mergedFilters = mergeOldFilterValues(newFilters, appliedFilters);
      } else {
        mergedFilters = newFilters;
      }

      pmrDispatch({
        type: actions.SET_FILTERS,
        value: mergedFilters
      });
      pmrDispatch({
        type: actions.SET_AVAILABLE_FILTERS,
        value: appliedFilters
      });
    }
  };

  // Hook to fetch data based on datagrid filter
  const getDatawithDataGridFilter = async (
    appliedFilters: any,
    datagridFilter: any,
    sortFields: any,
    limit: number = PAGINATION_DEFAULT_OPTIONS.limit,
    offset: number = PAGINATION_DEFAULT_OPTIONS.offset
  ) => {
    pmrDispatch({
      type: actions.SET_LOADING,
      value: true
    });

    const source = {
      us: ['pmr_surveillance']
    };
    const res: any = await getDataGridFilter(
      appliedFilters,
      datagridFilter,
      sortFields,
      limit,
      offset,
      pmrState,
      currentUser,
      source
    );
    if (Object.keys(res).length !== 0) {
      pmrDispatch({
        type: actions.SET_PMR_SUR_TABLE,
        value: res?.data?.body?.result?.pmr_surveillance?.results ?? []
      });

      pmrDispatch({
        type: actions.SET_TOTAL_COUNT,
        value: res?.data?.body?.result?.pmr_surveillance?.total ?? 0
      });
      pmrDispatch({
        type: actions.SET_DATA_GRID_FILTERS,
        value: datagridFilter
      });
    }

    pmrDispatch({
      type: actions.SET_LOADING,
      value: false
    });
  };

  const getPMRSurData = useCallback(
    async (
      appliedFilters: any,
      limit: number = PAGINATION_DEFAULT_OPTIONS.limit,
      offset: number = PAGINATION_DEFAULT_OPTIONS.offset,
      sort: Array<any> = [],
      shouldGetInsight: boolean = true,
      dataGridFilter: any = {}
    ) => {
      pmrDispatch({
        type: actions.SET_LOADING,
        value: true
      });
      const payload: any = {};

      pmrDispatch({
        type: actions.SET_IS_FILTERS_APPLIED,
        value: Object.keys(appliedFilters).length !== 0
      });

      payload.filters = appliedFilters;
      if (sort) {
        payload.sort = sort;
      }
      if (Object.keys(dataGridFilter).length !== 0) {
        await getDatawithDataGridFilter(appliedFilters, dataGridFilter, sort, limit, offset);
      } else {
        try {
          await getPMRSurveillanceTableData(payload, limit, offset);

          if (shouldGetInsight) {
            await getPMRSurevillanceInsightData(payload?.filters, appliedFilters);
          }

          pmrDispatch({
            type: actions.SET_DATA_GRID_FILTERS,
            value: {}
          });

          pmrDispatch({
            type: actions.SET_LOADING,
            value: false
          });
        } catch (err) {
          pmrDispatch({
            type: actions.SET_LOADING,
            value: false
          });
        }
      }
    },
    []
  );

  const handleCSVExport = async () => {
    pmrDispatch({
      type: actions.SET_LOADING,
      value: true
    });
    const res = await getAllPMRSurveillanceDocuments({ filters: pmrState.availableFilters });
    const selectedColumns = state?.exportType?.isAllColumnsSelected
      ? PMR_SUREVILLANCE_COLUMN_NAME_MAPPING
      : Object.fromEntries(
          Object.entries(PMR_SUREVILLANCE_COLUMN_NAME_MAPPING).filter(([key]) =>
            state?.exportType?.selectedColumns?.includes(key)
          )
        );
    const formattedData = transformData(
      res?.data?.Success?.pmr_surveillance_table,
      selectedColumns
    );
    const csv = Papa.unparse(formattedData);
    // Create a Blob from the CSV string
    const blob = new Blob([csv], { type: 'text/csv' });
    // Create a download link and click it
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    const day = new Date();
    const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
    const fileName = getFileName({
      prefix: 'ria',
      module: 'pmr_surveillance',
      postfix: date,
      additional: '',
      extension: 'csv'
    });
    a.download = `${fileName}.csv`;
    a.click();
    dispatch({
      type: globalActions.SET_EXPORT_TYPE,
      value: { format: '', selectedColumns: [], isAllColumnsSelected: false }
    });
    pmrDispatch({
      type: actions.SET_LOADING,
      value: false
    });
  };

  const handleExcelExport = async () => {
    pmrDispatch({
      type: actions.SET_LOADING,
      value: true
    });
    const res = await getAllPMRSurveillanceDocuments({ filters: pmrState.availableFilters });
    const selectedColumns = state?.exportType?.isAllColumnsSelected
      ? PMR_SUREVILLANCE_COLUMN_NAME_MAPPING
      : Object.fromEntries(
          Object.entries(PMR_SUREVILLANCE_COLUMN_NAME_MAPPING).filter(([key]) =>
            state?.exportType?.selectedColumns?.includes(key)
          )
        );
    const formattedData = transformData(
      res?.data?.Success?.pmr_surveillance_table,
      selectedColumns
    );
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const day = new Date();
    const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
    const fileName = getFileName({
      prefix: 'ria',
      module: 'pmr_surveillance',
      postfix: date,
      additional: '',
      extension: 'xlsx'
    });
    XLSX.writeFile(wb, `${fileName}.xlsx`);
    dispatch({
      type: globalActions.SET_EXPORT_TYPE,
      value: { format: '', selectedColumns: [], isAllColumnsSelected: false }
    });
    pmrDispatch({
      type: actions.SET_LOADING,
      value: false
    });
  };

  useEffect(() => {
    if (
      state?.exportType?.format &&
      [EXPORT_TYPE.csv, EXPORT_TYPE.xlsx].includes(state?.exportType?.format)
    ) {
      if (state?.exportType?.format === EXPORT_TYPE.csv) {
        handleCSVExport();
      } else if (state?.exportType?.format === EXPORT_TYPE.xlsx) {
        handleExcelExport();
      }
    }
  }, [state?.exportType]);

  return {
    isLoading: pmrState.loading,
    isFiltersApplied: pmrState.isFiltersApplied,
    pmrSurFilters: pmrState.filters,
    sortFields: pmrState.sortFields,
    getPMRSurData,
    getDatawithDataGridFilter,
    dataGridFilter: pmrState.dataGridFilters
  };
};

export default usePMRSurveillanceData;
