import React, { createContext, useReducer } from 'react';

import reducers from './reducers';

type Props = {
  children: React.ReactNode;
};

interface ResultsContextInterface {
  loading: boolean;
  ct_business_intelligence_loading: boolean;
  ct_adverse_events_loading: boolean;
  results: Array<any>;
  bussiness_intelligence: any;
  ct_adverse_events: Array<any>;
  toggleViewType: string;
  analyticViewResults: any;
  filters: any;
  hideApplications: Array<any>;
  pendingFilters: boolean;
  labelComparisonSelection: boolean;
  comparisonApplications: Array<any>;
  openComparisonDialog: boolean;
  parentCard: boolean | string;
  parentLabelCard: any | string;
  childLabelCard: any | string;
  selectedSort: string;
  ctTimelineSelectedSort: string;
  error: boolean | any;
  ctCount: number;
  resultsId: number;
  isFavorite: boolean;
  inProjects: any;
  synonymsOpen: boolean;
  synonymsList: Array<any>;
  appliedfilter: boolean;
  trialLoading: boolean;
  trialsData: any;
  trialError: boolean | any;
  originalTextEnabled: boolean;
}

const initialState = {
  loading: false,
  ct_business_intelligence_loading: false,
  ct_adverse_events_loading: false,
  results: [],
  bussiness_intelligence: {},
  ct_adverse_events: [],
  toggleViewType: 'grid',
  analyticViewResults: {},
  filters: {},
  hideApplications: [],
  pendingFilters: false,
  labelComparisonSelection: false,
  comparisonApplications: [],
  openComparisonDialog: false,
  parentLabelCard: null,
  childLabelCard: null,
  parentCard: false,
  selectedSort: 'default',
  ctTimelineSelectedSort: 'default',
  error: false,
  ctCount: 0,
  resultsId: 0,
  isFavorite: false,
  inProjects: [],
  synonymsOpen: false,
  synonymsList: [],
  appliedfilter: false,
  trialLoading: false,
  trialsData: {},
  trialError: false,
  originalTextEnabled: false
};

const ResultsStore = createContext<{
  resultsState: ResultsContextInterface;
  resultsDispatch: React.Dispatch<any>;
}>({ resultsState: initialState, resultsDispatch: () => null });

const { Provider } = ResultsStore;

export const ResultsStateProvider = ({ children }: Props) => {
  const [resultsState, resultsDispatch] = useReducer(reducers, initialState);
  return <Provider value={{ resultsState, resultsDispatch }}>{children}</Provider>;
};

export default ResultsStore;
