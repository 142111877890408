const styles: { [key: string]: any } = {
  purpleNumber: {
    minWidth: '47px',
    py: 0,
    px: 0.5,
    borderRadius: '4px',
    bgcolor: 'blue.main',
    color: 'white.main',
    fontWeight: 700
  },
  redNumber: {
    minWidth: '47px',
    py: 0,
    px: 0.5,
    borderRadius: '4px',
    bgcolor: 'red.main',
    color: 'white.main',
    fontWeight: 700
  }
};

export default styles;
