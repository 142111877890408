import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  headings: {
    color: theme.palette.biologics.textPrimary,
    fontWeight: 'bold',
    fontSize: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  cards: {
    width: '100%',
    padding: theme.spacing(1),
    height: '70vh'
    // border: '2px solid red'
  },
  cardContent: {
    height: '100%'
  },
  paper: {
    // height: theme.spacing(20),
    // width: theme.spacing(20),
    width: '80%',
    height: '20%',
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(1.5)
    // [theme.breakpoints.up('xl')]: {
    //   height: theme.spacing(25),
    //   width: theme.spacing(25),
    // },
  },
  paperIcon: {
    background: theme.palette.biologics.gradient,
    // borderRadius:'50%',
    // padding:theme.spacing(1),
    color: theme.palette.white
  },
  number: {
    fontSize: '20px',
    color: theme.palette.biologics.textPrimary,
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(2.5)
    }
  },
  cardText: {
    fontSize: '15px',
    alignItems: 'center',
    // opacity:1,
    // fontWeight:'lighter',
    color: theme.palette.biologics.textSecondary
  }
}));
