import { createSvgIcon } from '@mui/material/utils';

const AddToReportIcon = createSvgIcon(
  <svg
    width='18px'
    height='18px'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_4092_20511)'>
      <path
        d='M2.24346 16.4902C2.04435 16.4902 1.8534 16.4111 1.7126 16.2703C1.57181 16.1295 1.49271 15.9386 1.49271 15.7394V-0.00830078H-0.00878906V15.7394C-0.00878906 16.3368 0.228501 16.9097 0.65088 17.332C1.07326 17.7544 1.64613 17.9917 2.24346 17.9917H18.0092V16.4902H2.24346Z'
        fill='currentColor'
      />
      <path d='M5.24662 9.00073H3.74512V14.256H5.24662V9.00073Z' fill='currentColor' />
      <path d='M8.99956 7.49902H7.49805V14.2558H8.99956V7.49902Z' fill='currentColor' />
      <path d='M12.7535 9.75146H11.252V14.256H12.7535V9.75146Z' fill='currentColor' />
      <path d='M16.5074 6.74854H15.0059V14.256H16.5074V6.74854Z' fill='currentColor' />
      <path
        d='M9 3.60782H11.3899V5.99771V6.11032H11.5025H12.5035H12.6161V5.99771V3.60782H15.006H15.1186V3.4952V2.4942V2.38159H15.006H12.6161V-0.00830078V-0.120913H12.5035H11.5025H11.3899V-0.00830078V2.38159H9H8.88739V2.4942V3.4952V3.60782H9Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='0.225225'
      />
    </g>
    <defs>
      <clipPath id='clip0_4092_20511'>
        <rect width='18' height='18' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'AddToReportIcon'
);
export default AddToReportIcon;
