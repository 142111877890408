import React, { useContext, useEffect } from 'react';
import { Drawer } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';
import useChatQuery from './hooks/useChatQuery';
import themev5 from '../../themev5';
import { chatWindow } from './styles/styles';
import VerifyAnswer from './component/VerifyAnswer';
import InitializeChat from './component/InitializeChat';
import ChatHeader from './component/ChatHeader';
import ChatSection from './component/ChatSection';
import GlobalStore from '../../store';
import CloseOptions from './component/CloseOptions';

const ChatRIA = () => {
  const { state } = useContext(GlobalStore) as any;

  const applicationNumber = state.applicationNumber || '';
  const triggeredFrom = state.chatRiaTriggeredFrom || '';
  const chatRIAOpen = state.chatRiaOpen || false;
  const { chatRiaCloseSessionTrigger } = state;
  const source = state.applicationSource || '';
  const ariaDocument = state.ariaDocument || {};
  const applicationList = state.applicationList || [];
  const ARIADocumentLink = (ariaDocument?.blob ?? ariaDocument?.url) || '';
  const ariaBrandName = ariaDocument?.item?.trade_name || '';
  const [closeAction, setConfirmDialog] = React.useState(false);
  const location = useLocation();

  const {
    handleChangeQuery,
    handleSubmitQuery,
    queryText,
    openSession,
    openWidget,
    initialised,
    closeSession,
    chatFlow,
    sections,
    queryLoading,
    currentSection,
    handleAskHelp,
    brandName,
    handleAddToReportAction,
    showDownloadReport,
    downloadReport,
    processingReport,
    seeMore,
    setSeeMore,
    handleVerifyAction,
    verifyAnswer,
    reportCount,
    applicationNameList,
    selectedApplication,
    handleApplicationChange,
    handleChangeMode,
    riaMode,
    clearReport,
    chatRiaStatus,
    lastStatusCheckTime,
    handleSetHelpQuery,
    handleCancelCall,
    currentApplicationNumber,
    reportData,
    isARIAMode,
    handleMinimizeChat,
    fetchReportTitle,
    loadingReportTitle,
    minimizedChat,
    handleReopenSession,
    ariaPageNumber,
    handleNewSection,
    latestChatId,
    setSourceUrl,
    setARIADocPageNumber,
    ariaDocPageNumber,
    applicationMeta,
    documentReferencePages,
    sessionOpen,
    applicationSource,
    setOpenApplicationOption,
    openApplicationOption,
    addedToReportList,
    reportLoading
  } = useChatQuery();

  useEffect(() => {
    if (chatRIAOpen) {
      const isDocumentARIAPage = triggeredFrom === 'document';
      if (isDocumentARIAPage) {
        if (
          ariaDocument &&
          ariaDocument.item &&
          'original-file-url' in ariaDocument.item &&
          ariaDocument.item['original-file-url']
        ) {
          setSourceUrl(ariaDocument.item['original-file-url']);
        }
      }

      if (!isEmpty(applicationList) || !isEmpty(ariaDocument) || applicationNumber) {
        if (state.minimizedChatRia) {
          handleReopenSession(
            applicationNumber,
            source?.toLowerCase(),
            applicationList,
            ariaDocument
          );
        } else {
          openSession(applicationNumber, source?.toLowerCase(), applicationList, ariaDocument);
        }
      }
    }
  }, [applicationList, ariaDocument, applicationNumber, chatRIAOpen, triggeredFrom]);

  useEffect(() => {
    if (sessionOpen || minimizedChat) closeSession();
    return () => {
      closeSession();
    };
  }, [chatRiaCloseSessionTrigger, location.pathname]);

  const handleDownloadClick = () => {
    window.open('/reports', '_blank');
  };

  return (
    <ThemeProvider theme={themev5}>
      <Drawer
        anchor='right'
        open={openWidget}
        transitionDuration={500}
        hideBackdrop={false}
        onClose={async (event, reason) => {
          if (reason !== 'backdropClick') {
            setConfirmDialog(true);
          }
        }}
        sx={{
          ...chatWindow,
          ...(openApplicationOption
            ? {
                '& > .MuiDrawer-paper': {
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0
                }
              }
            : {
                '& > .MuiDrawer-paper': {
                  borderTopLeftRadius: 15,
                  borderBottomLeftRadius: 15
                }
              })
        }}>
        <VerifyAnswer
          verifyAnswer={verifyAnswer}
          isARIAMode={isARIAMode}
          ARIADocumentLink={ARIADocumentLink || ''}
          setARIADocPageNumber={setARIADocPageNumber}
          ariaDocPageNumber={ariaDocPageNumber}
          ariaPageNumber={ariaPageNumber}
          applicationMeta={applicationMeta}
          queryLoading={queryLoading}
          applicationNameList={applicationNameList}
          handleVerifyAction={handleVerifyAction}
          setOpenApplicationOption={setOpenApplicationOption}
        />
        <InitializeChat open={!initialised} />
        <ChatHeader
          selectedApplication={selectedApplication}
          riaMode={riaMode}
          reportCount={state?.reportLayout?.sections?.length}
          handleChangeMode={handleChangeMode}
          chatRiaStatus={chatRiaStatus}
          lastStatusCheckTime={lastStatusCheckTime}
          closeSession={closeSession}
          handleMinimizeChat={handleMinimizeChat}
          handleReportClick={handleDownloadClick}
          queryLoading={queryLoading}
          currentSection={currentSection}
          sections={sections}
          brandName={isARIAMode ? ariaBrandName : brandName}
          applicationNameList={applicationNameList}
          handleApplicationChange={handleApplicationChange}
          handleVerifyAction={handleVerifyAction}
          setOpenApplicationOption={setOpenApplicationOption}
          openApplicationOption={openApplicationOption}
        />
        <CloseOptions
          closeAction={closeAction}
          setConfirmDialog={setConfirmDialog}
          closeSession={closeSession}
          handleMinimizeChat={handleMinimizeChat}
        />
        {/* <ChatUnavailable notAvailable={initialised && queryError} brandName={brandName} /> */}
        {initialised && (
          <ChatSection
            applicationMeta={applicationMeta}
            chatFlow={chatFlow}
            seeMore={seeMore}
            setSeeMore={setSeeMore}
            queryLoading={queryLoading}
            handleSubmitQuery={handleSubmitQuery}
            queryText={queryText}
            handleChangeQuery={handleChangeQuery}
            initialised={initialised}
            handleAskHelp={handleAskHelp}
            currentSection={currentSection}
            sections={sections}
            handleAddToReportAction={handleAddToReportAction}
            handleVerifyAction={handleVerifyAction}
            brandName={isARIAMode ? ariaBrandName : brandName}
            verifyAnswer={verifyAnswer}
            processingReport={processingReport}
            downloadReport={downloadReport}
            showDownloadReport={showDownloadReport}
            reportCount={reportCount}
            applicationNameList={applicationNameList}
            selectedApplication={selectedApplication}
            handleApplicationChange={handleApplicationChange}
            clearReport={clearReport}
            source={source || applicationSource}
            handleSetHelpQuery={handleSetHelpQuery}
            handleCancelCall={handleCancelCall}
            currentApplicationNumber={currentApplicationNumber}
            reportData={reportData}
            isARIAMode={isARIAMode}
            fetchReportTitle={fetchReportTitle}
            loadingReportTitle={loadingReportTitle}
            handleNewSection={handleNewSection}
            latestChatId={latestChatId}
            documentReferencePages={documentReferencePages}
            // @ts-ignore
            ariaPageNumber={
              isARIAMode && ARIADocumentLink ? `${ariaPageNumber?.start}-${ariaPageNumber?.end}` : 0
            }
            setOpenApplicationOption={setOpenApplicationOption}
            addedToReportList={addedToReportList}
            reportLoading={reportLoading}
          />
        )}
      </Drawer>
    </ThemeProvider>
  );
};

export default React.memo(ChatRIA);
