import { makeStyles } from '@material-ui/core';

export default makeStyles((theme: any) => ({
  card: {
    width: theme.spacing(27.5),
    height: theme.spacing(37.5),
    overflowY: 'auto !important' as any,
    boxShadow: '0px 5px 10px #00000008',
    // boxShadow: 'inset 0px -25px 100px 1px #eee;',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    display: 'block',
    flexShrink: 0,
    scrollbarColor: `${theme.palette.common.green} #C2D2E4`,
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '6px'
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: '10px'
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.common.green,
      borderRadius: '10px'
    },

    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.common.green
    }
  },
  cardHidden: {
    width: '0vw',
    minHeight: theme.spacing(30),
    float: 'left'
  },
  count: {
    width: 'fit-content',
    padding: '1.5px',
    paddingLeft: '5px',
    display: 'inline-flex',
    borderRadius: theme.spacing(1.5),
    margin: theme.spacing(0.5)
  },
  button: {
    paddingTop: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    cursor: 'pointer',
    borderRadius: '5px',
    color: theme.palette.grayDark,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    '&:hover': {
      background: '#E8E7E7'
    }
  },
  buttonSelected: {
    padding: 1,
    borderRadius: '5px',
    background: theme.palette.common.green,
    color: theme.palette.primary.contrastText,

    paddingTop: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
    cursor: 'pointer',
    fontFamily: theme.typography.fontFamily
  }
}));
