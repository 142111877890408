const styles = {
  statsTile: {
    height: 68,
    borderRadius: 2,
    px: 2.5,
    py: 1.5,
    bgcolor: 'gray.0'
  },
  iconColor: {
    color: '#6C9BFD'
  },
  scrollContainer: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray.400',
      borderRadius: 1,
      opacity: '0.8'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    }
  },
  basicInfoContainer: {
    p: 2.5,
    width: '50%',
    maxHeight: '40vh',
    fontWeight: 500,
    bgcolor: 'gray.0',
    overflowY: 'hidden',
    borderRadius: 2,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    spacing: 1.5
  },
  dialogText: {
    fontSize: '16px',
    textTransform: 'capitalize',
    color: 'gray.lightVariant',
    fontWeight: 'bold'
  },
  pieCharContainer: {
    width: '50%',
    height: '40vh',
    px: 2,
    py: 1.5,
    mt: 2,
    overflow: 'hidden',
    bgcolor: 'gray.0',
    borderRadius: 2,
    pb: 4
  },
  barGraphContainer: {
    width: '100%',
    height: 300,
    p: 2,
    bgcolor: 'white.main',
    borderRadius: '10px'
  },
  addToReport: {
    height: '35px',
    cursor: 'pointer',
    background: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: 'none',
      border: 1,
      borderColor: 'primary.main',
      borderStyle: 'solid'
    }
  },
  reportText: {
    color: 'primary',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    '&:hover': {
      background: 'none'
    }
  },
  upcommingMeetingContainer: {
    backgroundColor: 'primary.backgroundDark',
    borderRadius: 1,
    p: 1,
    mb: 1,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  graphHeading: {
    color: 'grey.800',
    fontFamily: 'Mulish',
    fontSize: 16,
    fontWeight: 700
  }
};

export default styles;
