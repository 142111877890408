import { createSvgIcon } from '@mui/material';

const MagnifyingGlassIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M23.1574 21.8439L17.3349 16.0214C18.8473 14.1716 19.591 11.8113 19.4119 9.42865C19.2329 7.04598 18.145 4.82326 16.3731 3.22026C14.6012 1.61726 12.281 0.756605 9.89235 0.816328C7.50371 0.876052 5.2294 1.85158 3.53985 3.54113C1.8503 5.23068 0.87477 7.50499 0.815047 9.89363C0.755323 12.2823 1.61597 14.6025 3.21898 16.3744C4.82198 18.1462 7.0447 19.2342 9.42737 19.4132C11.81 19.5922 14.1704 18.8486 16.0201 17.3362L21.8426 23.1586L23.1574 21.8439ZM10.14 17.5798C8.66881 17.5798 7.23066 17.1435 6.0074 16.3262C4.78415 15.5088 3.83074 14.3471 3.26774 12.9879C2.70474 11.6287 2.55743 10.133 2.84445 8.69011C3.13146 7.24719 3.83991 5.92177 4.8802 4.88148C5.92049 3.84119 7.24591 3.13274 8.68883 2.84573C10.1318 2.55871 11.6274 2.70602 12.9866 3.26902C14.3458 3.83202 15.5075 4.78543 16.3249 6.00869C17.1422 7.23194 17.5785 8.6701 17.5785 10.1413C17.5763 12.1134 16.7919 14.0041 15.3974 15.3987C14.0029 16.7932 12.1121 17.5776 10.14 17.5798Z'
      fill='currentColor'
    />
  </svg>,
  'MagnifyingGlass'
);

export default MagnifyingGlassIcon;
