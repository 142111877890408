import { cloneDeep, isArray } from 'lodash';

const getFieldValues = (row: any, key: string) => {
  if (isArray(row[key])) {
    return row[key];
  }
  return [row[key]];
};

const getFilters = (apiData: any, filterMapping: any) => {
  const filters = cloneDeep(
    filterMapping.map((fltr: { [key: string]: any }) => ({
      ...fltr,
      options: [...(fltr?.options || [])]
    }))
  );

  apiData.forEach((row: any) => {
    filters.forEach((filter: { [key: string]: any }) => {
      const uniqueValuesSet = new Set(filter.options || []);
      getFieldValues(row, filter.value).forEach((val: string | number) => {
        uniqueValuesSet.add(val);
      });
      // eslint-disable-next-line no-param-reassign
      filter.options = Array.from(uniqueValuesSet);
    });
  });

  return filters;
};

export default getFilters;
