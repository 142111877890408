/* eslint-disable import/prefer-default-export */
import { GridColDef } from '@mui/x-data-grid-pro';

export const US_GENERICS_COLUMN_DEFINITION: GridColDef[] = [
  {
    field: 'Strength',
    headerName: 'Strength',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'activeIngredients',
    headerName: 'Active Ingredients',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'approvalPathway',
    headerName: 'Approval Pathway',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'anda',
    headerName: 'Anda',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'route',
    headerName: 'Route',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'te_code',
    headerName: 'Therapeutic Equivalence',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'tradeName',
    headerName: 'Trade Name',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'sponsorName',
    headerName: 'Sponsor Name',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'marketingStatus',
    headerName: 'Marketing Status',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row'
  }
];

export const GENERICS_COLUMN_DEFINITION: { [key: string]: any } = {
  us: US_GENERICS_COLUMN_DEFINITION
};
