import React, { MouseEvent, memo } from 'react';
import { Box, Typography, Tooltip, IconButton, Divider, Drawer, Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import { AddToReportIcon } from '../../../assets/svgs/Icons';
import styles from './BottomDrawer.styles';
import HeaderTabs from './HeaderTabs';

const BottomDrawer = ({
  isDrawerOpen,
  closeDrawer,
  children,
  handleReportChoiceAnchorElement,
  handleChange,
  hideReportIcon = false,
  hideDivider = false,
  showTabs = false,
  tabList = [],
  selectedTab = ''
}: any) => {
  return (
    <Drawer sx={styles.drawer} anchor='bottom' open={isDrawerOpen}>
      <Box sx={styles.container}>
        <Box sx={styles.headerWrapper}>
          <Box sx={styles.titleWrapper}>
            <Typography sx={styles.title}>Visualize</Typography>
            <Box sx={styles.actionsWrapper}>
              {hideReportIcon || (
                <>
                  <Button
                    onClick={(event: MouseEvent<HTMLButtonElement>) =>
                      handleReportChoiceAnchorElement(event.currentTarget)
                    }
                    startIcon={<AddToReportIcon sx={styles.primaryButtonIcon} />}
                    sx={styles.primaryButton}>
                    Create Chart
                  </Button>
                  <Box sx={styles.separator} />
                </>
              )}
              <Tooltip title='Close'>
                <IconButton size='small' sx={styles.closeButton} onClick={closeDrawer}>
                  <Close fontSize='small' />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
        {!hideDivider && <Divider />}
        {showTabs && (
          <HeaderTabs tabList={tabList} selectedTab={selectedTab} handleChange={handleChange} />
        )}
        <Box
          sx={{
            ...styles.childrenWrapper,
            height: showTabs ? 'calc(98% - 48px - 48px)' : 'calc(98% - 48px)'
          }}>
          {children}
        </Box>
      </Box>
    </Drawer>
  );
};

export default memo(BottomDrawer);
