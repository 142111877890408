import { createSvgIcon } from '@mui/material';

const WarningIcon = createSvgIcon(
  <svg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.71564 0C10.2546 0 10.7519 0.267857 11.0252 0.707143L19.2239 13.85C19.501 14.2929 19.501 14.8393 19.2315 15.2821C18.962 15.725 18.4572 16 17.9144 16H1.51689C0.974099 16 0.469268 15.725 0.199772 15.2821C-0.069724 14.8393 -0.0659282 14.2893 0.207364 13.85L8.40612 0.707143C8.67941 0.267857 9.17665 0 9.71564 0ZM9.71564 4.57143C9.21081 4.57143 8.80467 4.95357 8.80467 5.42857V9.42857C8.80467 9.90357 9.21081 10.2857 9.71564 10.2857C10.2205 10.2857 10.6266 9.90357 10.6266 9.42857V5.42857C10.6266 4.95357 10.2205 4.57143 9.71564 4.57143ZM10.9303 12.5714C10.9303 12.2683 10.8023 11.9776 10.5745 11.7633C10.3467 11.549 10.0378 11.4286 9.71564 11.4286C9.3935 11.4286 9.08455 11.549 8.85677 11.7633C8.62898 11.9776 8.50101 12.2683 8.50101 12.5714C8.50101 12.8745 8.62898 13.1652 8.85677 13.3796C9.08455 13.5939 9.3935 13.7143 9.71564 13.7143C10.0378 13.7143 10.3467 13.5939 10.5745 13.3796C10.8023 13.1652 10.9303 12.8745 10.9303 12.5714Z'
      fill='currentColor'
    />
  </svg>,
  'WarningIcon'
);

export default WarningIcon;
