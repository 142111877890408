import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  MenuItem,
  MenuList,
  Popover
} from '@mui/material';
import SendIcon from '../../Images/svgs/sendIcon';
import styles from '../../pages/RegulatoryInformation/ApprovalHistory/styles';
import GlobalActions from '../../store/actions';
import { KeyDownIcon } from '../../assets/svgs/Icons';

const AskRIA = ({
  setModalOpen,
  triggerChatRIA,
  globalDispatch,
  state,
  disableChatRia = false,
  documentCount = 0
}: any) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>('aria');
  const open = Boolean(anchorEl);
  const id = open ? 'aria-popover' : undefined;
  const handleButtonClick = () => {
    if (selectedOption === 'aria') {
      setModalOpen(true);
    }
    if (selectedOption === 'continueChatRia') {
      triggerChatRIA();
    }
    if (selectedOption === 'chatRia') {
      globalDispatch({ type: GlobalActions.SET_MINIMIZED_CHAT_RIA, value: false });
      triggerChatRIA();
    }
    globalDispatch({ type: GlobalActions.SET_SHOW_NEW_FILTERS, value: false });
  };

  const handleDropdownClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOptionClick = (value: string) => {
    setAnchorEl(null);
    setSelectedOption(value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const allDocsText = documentCount === 0 ? 'All Docs' : `All ${documentCount} Docs`;

  const getOptionText = () => {
    if (selectedOption === 'aria') {
      return allDocsText;
    }
    if (selectedOption === 'continueChatRia') {
      return 'Continue';
    }
    return 'Labels';
  };

  useEffect(() => {
    if (state.minimizedChatRia) {
      setSelectedOption('continueChatRia');
    }
  }, [state.minimizedChatRia]);

  return (
    <Box id='aria-chat-ria-button' sx={styles.buttonGroup}>
      <ButtonGroup aria-label='split button'>
        {!disableChatRia ? (
          <>
            <Button
              id='dropdown'
              value='dropdown'
              aria-label='dropdown'
              sx={styles.dropdownButton}
              onClick={handleDropdownClick}>
              {getOptionText()}
              {selectedOption === 'continueChatRia' && (
                <Badge
                  variant='dot'
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  sx={styles.badge}
                />
              )}

              <KeyDownIcon sx={{ fontSize: 10, color: 'gray.0', marginLeft: '6px' }} />
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}>
              <MenuList>
                {[
                  { label: allDocsText, value: 'aria' },
                  { label: 'Latest Label', value: 'chatRia' },
                  { label: 'Continue Chat', value: 'continueChatRia' }
                ].map(
                  (item: any) =>
                    // Check if isOpen is false and the item is 'continueChatRia', if so, skip rendering
                    !(item.value === 'continueChatRia' && !state.minimizedChatRia) && (
                      <MenuItem
                        key={item.value}
                        onClick={e => {
                          e.stopPropagation();
                          handleOptionClick(item.value);
                        }}>
                        <b>{item.label}</b>&nbsp;{' '}
                        {item.value === 'aria' ? ' (Ask RIA)' : ' (Chat RIA)'}
                        {item.value === 'continueChatRia' && <Avatar sx={styles.avatar}> </Avatar>}
                      </MenuItem>
                    )
                )}
              </MenuList>
            </Popover>
          </>
        ) : null}
        <Button
          id='submit'
          value='submit'
          aria-label='submit'
          endIcon={<SendIcon sx={{ fontSize: 20 }} />}
          sx={styles.ariaButton}
          onClick={handleButtonClick}>
          {selectedOption === 'aria' ? 'Ask RIA' : 'Chat RIA'}
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default AskRIA;
