import { useContext, useState, MouseEvent, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Badge, Button, ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import { ArrowDownIcon, CustomCheckIcon, SortIconVariant } from '../../../assets/svgs/Icons';
import buttonStyles from '../../SearchResults/styles/Buttons.styles';
import { RoundedMenu } from '../../../components/ui/Menu';
import MenuItemWithSubMenu from '../../../components/ui/Menu/MenuItemWithSubMenu/MenuItemWithSubMenu';
import reviewMenuStyles from '../../SearchResults/styles/ReviewMenu.styles';
import DesignationStore from '../../../store/Designation';
import DesignationActions from '../../../store/Designation/actions';
import { ListViewIcon } from '../../../assets/svgs/Icons/ResultsViewType';

const sortOptions = [
  { value: 'asc', label: 'Submission: New (Default)', id: 'default' },
  { value: 'desc', label: 'Submission: Oldest', id: 'submission_date_oldest' }
];

const viewOptions = [
  { value: 'vertical', label: 'Designation (Default)', id: 'default' },
  { value: 'horizontal', label: 'Application', id: 'application' }
];

const ReviewMenu = () => {
  const { designationState, designationDispatch } = useContext(DesignationStore);
  const history = useHistory();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const selectedSort = useMemo(
    () => sortOptions.find(option => option.value === designationState.sortBy),
    [designationState.sortBy]
  );

  const selectedView = useMemo(
    () => viewOptions.find(option => option.value === designationState.view),
    [designationState.view]
  );

  const isSortingApplied = useMemo(() => {
    return selectedSort?.id !== 'default';
  }, [selectedSort]);

  const isViewApplied = useMemo(() => {
    return selectedView?.id !== 'default';
  }, [selectedView]);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event?.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSort = (value: string) => {
    const newValue = value === 'asc' ? 'asc' : 'desc';
    const updatedSearchParams = new URLSearchParams(location.search);
    updatedSearchParams.set('sort', newValue);
    history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
    designationDispatch({
      type: DesignationActions.SORT_BY,
      value: newValue
    });
    handleCloseMenu();
  };

  const handleSwitchView = (value: string) => {
    const newValue = value === 'vertical' ? 'vertical' : 'horizontal';
    const updatedSearchParams = new URLSearchParams(location.search);
    updatedSearchParams.set('view', newValue);
    history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
    designationDispatch({
      type: DesignationActions.SET_VIEW,
      value: newValue
    });
    handleCloseMenu();
  };

  return (
    <>
      <Button
        endIcon={<ArrowDownIcon sx={buttonStyles.buttonIcon} />}
        sx={buttonStyles.button}
        onClick={handleOpenMenu}>
        <Badge
          color='primary'
          variant='dot'
          invisible={!(isSortingApplied || isViewApplied)}
          sx={reviewMenuStyles.reviewButtonBadgeDot}>
          Sort & View
        </Badge>
      </Button>
      <RoundedMenu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        sx={reviewMenuStyles.roundedMenu}>
        <MenuItemWithSubMenu
          key='sort'
          MenuItemIcon={
            <Badge
              color='primary'
              variant='dot'
              invisible={!isSortingApplied}
              sx={reviewMenuStyles.badgeDot}>
              <SortIconVariant sx={buttonStyles.buttonIcon} />
            </Badge>
          }
          MenuItemText='Sort by'>
          {sortOptions.map(option => (
            <MenuItem
              key={option.label}
              onClick={() => handleSort(option.value)}
              sx={reviewMenuStyles.menuItem}>
              <ListItemIcon sx={reviewMenuStyles.listItemIcon}>
                <CustomCheckIcon
                  sx={{
                    ...reviewMenuStyles.customCheckIcon,
                    opacity: designationState.sortBy === option.value ? 1 : 0
                  }}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={{
                    ...reviewMenuStyles.listItemText,
                    fontWeight: designationState.sortBy === option.value ? 700 : 400
                  }}>
                  {option.label}
                </Typography>
              </ListItemText>
            </MenuItem>
          ))}
        </MenuItemWithSubMenu>
        <MenuItemWithSubMenu
          key='view'
          MenuItemIcon={
            <Badge
              color='primary'
              variant='dot'
              invisible={!isViewApplied}
              sx={reviewMenuStyles.badgeDot}>
              <ListViewIcon sx={buttonStyles.buttonIcon} />
            </Badge>
          }
          MenuItemText='View by'>
          {viewOptions.map(option => (
            <MenuItem
              key={option.label}
              onClick={() => handleSwitchView(option.value)}
              sx={reviewMenuStyles.menuItem}>
              <ListItemIcon sx={reviewMenuStyles.listItemIcon}>
                <CustomCheckIcon
                  sx={{
                    ...reviewMenuStyles.customCheckIcon,
                    opacity: designationState.view === option.value ? 1 : 0
                  }}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={{
                    ...reviewMenuStyles.listItemText,
                    fontWeight: designationState.view === option.value ? 700 : 400
                  }}>
                  {option.label}
                </Typography>
              </ListItemText>
            </MenuItem>
          ))}
        </MenuItemWithSubMenu>
      </RoundedMenu>
    </>
  );
};

export default ReviewMenu;
