import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';

// material ui
import { Box, Popover, Tooltip } from '@mui/material';

// hooks
import useEMAGuidanceData from '../hooks/useEMAGuidanceData';

// Store
import GlobalStore from '../../../store';
import store from '../../../store/EMAGuidance';

// components
import EMAGuidanceDetails from '../components/Details';
import ReportWidgets from '../../../components/Report/Widgets';
import SubHeader from '../../../components/SubHeader';
import BottomDrawer from '../../../components/CustomComponents/BottomDrawer';
import NewFilters from '../../../components/NewFilters';
import AskRiaButton from '../../../components/Buttons/AskRiaButton';
import AriaDrawerCore from '../../SearchResults/components/AriaDrawerCore';

// utils
import ComparisonButtons from '../components/ComparisonButtons';
import CompareDialog from '../components/CompareDialog';
import { getFiltersLabelList } from '../../../utils/filters';
import { Stat } from '../../SearchResults/types';
import AskRiaButtonStyles from '../../../components/Buttons/styles/AskRiaButton.styles';
import useAriaShareHandler from '../../../components/AriaShare/useAriaShareHandler';
import { PAGINATION_DEFAULT_OPTIONS } from '../const';
import { getEMAGuidanceDocumentsSignedURL } from '../../../api/pages/EMAGuidance';
import truncateCountAboveMillions from '../../../utils/truncateCountAboveMillions';
import formatCount from '../../../utils/formatCount';
import VISUALIZE_CHART_MAPPING from '../../../components/Insights/const';
import Insights from '../../../components/Insights/Insights';

const EMAGuidanceTabs = () => {
  const {
    EMAGuidancefilters,
    isLoading,
    compareEnabled,
    documentsToCompare,
    comparePopupOpen,
    getEMAGuidanceData,
    openComparisonPopup,
    removeFromComparison,
    clearComparison,
    clearSearch,
    getDatawithDataGridFilter,
    sortFields,
    dataGridFilter
  } = useEMAGuidanceData();
  const { EMAGuidanceState } = useContext(store);
  const [ariaModalOpen, setAriaModalOpen] = useState(false);
  const { dispatch } = useContext(GlobalStore) as any;
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);
  const [reportChoicesOpenAnchorElement, setReportChoiceAnchorElement] =
    useState<HTMLElement | null>(null);
  const [guidanceStats, setGuidanceStats] = useState<Stat[]>([]);
  const [compareItems, setCompareItems] = useState<Array<any>>([]);
  const [sort, setSort] = useState([...sortFields]);
  const [pagination, setPagination] = useState({
    pageSize: PAGINATION_DEFAULT_OPTIONS.limit,
    page: PAGINATION_DEFAULT_OPTIONS.offset
  });
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);

  useEffect(() => {
    getEMAGuidanceData([], PAGINATION_DEFAULT_OPTIONS.limit, PAGINATION_DEFAULT_OPTIONS.offset, []);
  }, []);

  useEffect(() => {
    if (EMAGuidanceState?.emaGuidanceStats) {
      const newStats: Stat[] = [];
      newStats.push({
        title: 'Total',
        value: EMAGuidanceState?.emaGuidanceStats?.totalGuidance || 0
      });
      newStats.push({
        title: 'Status Adopted',
        value: EMAGuidanceState?.emaGuidanceStats?.totalAdoptedCount || 0
      });
      newStats.push({
        title: 'Recently Published',
        value: EMAGuidanceState?.emaGuidanceStats?.totalLastThirtyDays || 0
      });
      newStats.push({
        title: 'Docs/Page',
        value: EMAGuidanceState?.emaGuidanceStats?.pdfCount,
        value2: EMAGuidanceState?.emaGuidanceStats?.pageCount
      });

      setGuidanceStats(newStats);
    }
  }, [EMAGuidancefilters]);

  useEffect(() => {
    const fetchData = async () => {
      const formattedDocumentsToCompare = await Promise.all(
        documentsToCompare.map(async doc => {
          const pdfSignedUrl = await getEMAGuidanceDocumentsSignedURL({
            source: 'ema_guidance',
            s3_path: doc.s3_path
          });
          return {
            title: `${doc.doc_title.replace(/ \([\s\S]*?\)/g, '')}`,
            pdfLink: pdfSignedUrl.data.body.s3_url
          };
        })
      );
      setCompareItems(formattedDocumentsToCompare);
    };

    // check is added so that when user selects both the pdf only pdf url is fetched
    if (documentsToCompare.length === 2) {
      fetchData();
    }
  }, [documentsToCompare]);

  useAriaShareHandler(isLoading, ariaModalOpen, setAriaModalOpen);

  const handleFilterApply = useCallback(
    (currentfilters: any) => {
      setIsFilterUpdated(true);
      setPagination({ page: PAGINATION_DEFAULT_OPTIONS.offset, pageSize: pagination.pageSize });
      clearComparison();
      clearSearch();
      getEMAGuidanceData(
        currentfilters,
        pagination.pageSize,
        // offset is set to default because whenever filter is applied user should be directed to first page
        PAGINATION_DEFAULT_OPTIONS.offset,
        sortFields
      );
    },
    [sortFields, pagination]
  );

  const getAriaDocuments = () => {
    const ariaDocuments = EMAGuidanceState?.askRiaFilters?.map((item: any) => ({
      identifier: item
    }));

    return ariaDocuments || [];
  };

  const handleVisualizeOpen = () => {
    setIsVisualizeOpen(true);
  };

  const closeDrawer = () => {
    setIsVisualizeOpen(false);
  };

  const getInsightStatsList = useMemo(() => {
    if (EMAGuidanceState?.emaGuidanceStats) {
      return [
        {
          text: 'Total Guidances',
          count: EMAGuidanceState.emaGuidanceStats.totalGuidance,
          toolTipValue: ''
        },
        {
          text: 'Status Adopted',
          count: EMAGuidanceState.emaGuidanceStats.totalAdoptedCount,
          toolTipValue: ''
        },
        {
          text: 'Published in Last 30 Days',
          count: EMAGuidanceState.emaGuidanceStats.totalLastThirtyDays,
          toolTipValue: ''
        },
        {
          text: 'Total Documents | Pages',
          count: `${truncateCountAboveMillions(
            EMAGuidanceState.emaGuidanceStats.pdfCount
          )} | ${truncateCountAboveMillions(EMAGuidanceState.emaGuidanceStats.pageCount)}`,
          toolTipValue: `${formatCount(EMAGuidanceState.emaGuidanceStats.pdfCount)} | ${formatCount(
            EMAGuidanceState.emaGuidanceStats.pageCount
          )}`
        }
      ];
    }
    return [];
  }, [EMAGuidanceState]);

  const getLayer1Charts = useMemo(() => {
    const guidanceGraph = VISUALIZE_CHART_MAPPING['ema-guidance']?.layer1;

    if (guidanceGraph && EMAGuidanceState.publishedDate) {
      const updatedGraph = guidanceGraph.map((graph: any) => ({
        ...graph,
        data: EMAGuidanceState.publishedDate
      }));

      return updatedGraph;
    }
    return guidanceGraph;
  }, [EMAGuidanceState.publishedDate]);

  const getLayer2Charts = useMemo(() => {
    const guidanceGraph = [...VISUALIZE_CHART_MAPPING['ema-guidance'].layer2];
    if (guidanceGraph && EMAGuidanceState.donutCharts) {
      guidanceGraph.forEach(graph => {
        const donutItem = EMAGuidanceState.donutCharts.find(
          donut => donut?.graphItem === graph?.id
        );
        if (donutItem) {
          // eslint-disable-next-line no-param-reassign
          graph.data = donutItem.children;
        }
      });

      return guidanceGraph;
    }
    return guidanceGraph;
  }, [EMAGuidanceState.donutCharts]);

  return (
    <>
      <SubHeader
        title='EU EMA Guidances'
        subTitle='Showing list of Guidances published by EU EMA'
        stats={guidanceStats}
        handleVisualizeOpen={handleVisualizeOpen}
      />

      {!isLoading && (
        <Popover
          open={!!reportChoicesOpenAnchorElement}
          anchorEl={reportChoicesOpenAnchorElement}
          onClose={() => setReportChoiceAnchorElement(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          sx={{
            '& .MuiPopover-paper': {
              borderRadius: '16px !important',
              backgroundColor: 'none'
            }
          }}>
          <Box>
            <ReportWidgets
              widgets={['pie-widget', 'bar-widget', 'table-widget']}
              title=''
              dataSource={{
                source: 'ema-guidance',
                moduleName: 'guidance',
                disableSelectionSource: true,
                filters: EMAGuidanceState.availableFilters,
                filterLabelList: getFiltersLabelList()
              }}
              pathMetaData={{
                module: 'ema_guidance',
                href: window.location.href
              }}
              setReportWidgetClose={setReportChoiceAnchorElement}
            />
          </Box>
        </Popover>
      )}
      {comparePopupOpen && (
        <CompareDialog
          open={comparePopupOpen}
          onClose={() => openComparisonPopup(false)}
          compareItems={compareItems}
          onNewCompare={clearComparison}
        />
      )}
      {compareEnabled && (
        <Box marginTop='10px'>
          <ComparisonButtons
            documentsToCompare={documentsToCompare}
            removeFromComparison={removeFromComparison}
            openComparisonPopup={openComparisonPopup}
            clearComparison={clearComparison}
          />
        </Box>
      )}
      <EMAGuidanceDetails
        pagination={pagination}
        setPagination={setPagination}
        sort={sort}
        setSort={setSort}
        sortFields={sortFields}
        getEMAGuidanceData={getEMAGuidanceData}
        isFilterUpdated={isFilterUpdated}
        setIsFilterUpdated={setIsFilterUpdated}
        getDatawithDataGridFilter={getDatawithDataGridFilter}
        dataGridFilter={dataGridFilter}
      />

      <BottomDrawer
        isDrawerOpen={isVisualizeOpen}
        closeDrawer={closeDrawer}
        handleReportChoiceAnchorElement={setReportChoiceAnchorElement}>
        <Insights
          source='ema-guidance'
          searchType='guidance'
          appliedFilter={EMAGuidanceState?.availableFilters}
          statsList={getInsightStatsList}
          layer1Component={getLayer1Charts}
          layer2Component={getLayer2Charts}
          isLoading={isLoading}
        />
      </BottomDrawer>

      {ariaModalOpen && (
        <AriaDrawerCore
          applications={EMAGuidanceState.isFiltersApplied ? getAriaDocuments() : []}
          sources={{ eu: ['guidance'] }}
          groupId='entity_id'
          docCount={EMAGuidanceState?.emaGuidanceStats?.pdfCount}
          docPageCount={EMAGuidanceState?.emaGuidanceStats?.pageCount}
          modalOpen={ariaModalOpen}
          setModalOpen={setAriaModalOpen}
          dispatch={dispatch}
        />
      )}

      <NewFilters
        data={EMAGuidancefilters}
        applyFilters={handleFilterApply}
        isLoading={isLoading}
        selectedFilters={EMAGuidanceState.availableFilters}
      />

      <Tooltip title={compareEnabled ? 'Exit compare mode to search ARIA' : ''}>
        <Box sx={AskRiaButtonStyles.bottomFilterPosition}>
          <AskRiaButton
            iconId='aria'
            setOpenAriaSearch={setAriaModalOpen}
            text='Ask RIA'
            disabled={compareEnabled || isLoading}
          />
        </Box>
      </Tooltip>
    </>
  );
};

export default memo(EMAGuidanceTabs);
