import React from 'react';

const SortIcon = () => {
  return (
    <svg
      id='Layer_2'
      data-name='Layer 2'
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'>
      <g id='invisible_box' data-name='invisible box'>
        <rect id='Rectangle_478' data-name='Rectangle 478' width='24' height='24' fill='none' />
      </g>
      <g id='alphabetical-order' transform='translate(0.174 3.993)'>
        <g id='Group_468' data-name='Group 468' transform='translate(4.568)'>
          <path
            id='Path_342'
            data-name='Path 342'
            d='M10.823,10.754H9.51V.328A.328.328,0,0,0,9.182,0H6.537a.328.328,0,0,0-.328.328V10.754H4.9a.328.328,0,0,0-.255.533L7.6,15.706a.328.328,0,0,0,.511,0l2.963-4.419a.328.328,0,0,0-.255-.533Z'
            transform='translate(-4.568)'
            fill='#646464'
          />
          <path
            id='Path_343'
            data-name='Path 343'
            d='M60.6,6.618,58.416.426a.328.328,0,0,0-.309-.219h-.633a.324.324,0,0,0-.052,0,.305.305,0,0,0-.052,0h-.616a.328.328,0,0,0-.31.219L54.267,6.619a.328.328,0,0,0,.309.436h.947a.328.328,0,0,0,.314-.232l.416-1.365h2.364l.416,1.365a.328.328,0,0,0,.314.232h.946a.328.328,0,0,0,.31-.437ZM56.645,4.154c.393-1.274.659-2.158.79-2.63l.808,2.63Z'
            transform='translate(-46.104 -0.173)'
            fill='#646464'
          />
          <path
            id='Path_344'
            data-name='Path 344'
            d='M62.9,61.609H59.977l3.1-4.46a.33.33,0,0,0,.059-.187V56.4a.328.328,0,0,0-.328-.328H58.537a.328.328,0,0,0-.328.328v.63a.328.328,0,0,0,.328.328h2.751l-3.107,4.455a.328.328,0,0,0-.059.188v.564a.328.328,0,0,0,.328.328H62.9a.328.328,0,0,0,.328-.328v-.63A.328.328,0,0,0,62.9,61.609Z'
            transform='translate(-49.342 -46.881)'
            fill='#646464'
          />
        </g>
      </g>
    </svg>
  );
};

export default SortIcon;
