import { createSvgIcon } from '@mui/material';

const HTAIcon = createSvgIcon(
  <svg width='41' height='40' viewBox='0 0 41 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1965_9415)'>
      <path
        d='M29.8333 13C28.5447 13 27.5364 11.9273 27.1147 10.7096C26.7713 9.71785 26.2059 8.80645 25.4497 8.05025C24.137 6.7375 22.3565 6 20.5 6C18.6435 6 16.863 6.7375 15.5503 8.05025C14.7941 8.80645 14.2287 9.71785 13.8853 10.7096C13.4636 11.9273 12.4553 13 11.1667 13H8.83333C7.54467 13 6.5 14.0447 6.5 15.3333V30.5C6.5 31.4283 6.86875 32.3185 7.52513 32.9749C8.1815 33.6313 9.07174 34 10 34H31C31.9283 34 32.8185 33.6313 33.4749 32.9749C34.1313 32.3185 34.5 31.4283 34.5 30.5V15.3333C34.5 14.0447 33.4553 13 32.1667 13H29.8333ZM20.5 8.33333C21.7377 8.33333 22.9247 8.825 23.7998 9.70017C24.1171 10.0175 24.384 10.3758 24.5954 10.7626C25.2132 11.8936 24.122 13 22.8333 13H18.1667C16.878 13 15.7868 11.8936 16.4046 10.7626C16.616 10.3758 16.8829 10.0175 17.2002 9.70017C18.0753 8.825 19.2623 8.33333 20.5 8.33333Z'
        fill='currentColor'
      />
      <path
        d='M22.4914 21.7793H16.4819C16.7736 20.9419 17.2736 20.1916 17.9361 19.5996C18.6383 18.972 19.4969 18.5457 20.4212 18.3655C21.3455 18.1854 22.3014 18.2581 23.1879 18.576C24.0743 18.8938 24.8585 19.4451 25.4578 20.1716L25.5321 20.2617L25.6221 20.1873L26.4467 19.506L26.5366 19.4317L26.4624 19.3418C25.6961 18.413 24.6878 17.7142 23.549 17.3228C22.4103 16.9314 21.1854 16.8625 20.01 17.1238C18.8345 17.3852 17.7542 17.9665 16.8886 18.8036C16.0447 19.6196 15.4369 20.6477 15.1283 21.7793H13.9346H13.8179V21.8959V22.9655V23.0822H13.9346H14.9051C14.8949 23.2193 14.8875 23.3584 14.8875 23.5003C14.8875 23.6423 14.8949 23.7814 14.9051 23.9185H13.9346H13.8179V24.0351V25.1047V25.2214H13.9346H15.1283C15.4365 26.3535 16.0443 27.3822 16.8884 28.1988C17.7543 29.0363 18.835 29.618 20.0109 29.8794C21.1869 30.1408 22.4122 30.0717 23.5513 29.6797C24.6905 29.2878 25.6989 28.5884 26.4651 27.6588L26.5392 27.5689L26.4494 27.4947L25.6247 26.8133L25.5346 26.7389L25.4604 26.8291C24.9698 27.4252 24.3532 27.905 23.6548 28.234C22.9565 28.5629 22.1939 28.7329 21.422 28.7317L21.4218 28.8483L21.4219 28.7317C20.309 28.7303 19.2255 28.3747 18.3282 27.7162C17.474 27.0894 16.831 26.2192 16.4821 25.2214H22.4914H22.608V25.1047V24.0351V23.9185H22.4914H16.2075C16.1848 23.6402 16.1848 23.3605 16.2075 23.0822H22.4914H22.608V22.9655V21.8959V21.7793H22.4914Z'
        fill='white'
        stroke='white'
        strokeWidth='0.233333'
      />
    </g>
    <defs>
      <clipPath id='clip0_1965_9415'>
        <rect width='28' height='28' fill='white' transform='translate(6.5 6)' />
      </clipPath>
    </defs>
  </svg>,
  'HTA'
);

export default HTAIcon;
