import { useContext, useEffect, useMemo, useState } from 'react';
import { Dictionary } from 'lodash';
import { useParams } from 'react-router-dom';
import { CT_SORT_OPTIONS } from '../constants';

import ResultsStore from '../../../store/SearchResults';
import ResultActions from '../../../store/SearchResults/actions';
import { decodeBase64ToObject } from '../../../utils/encodeDecodeObject';
import getCtSource from '../utils/getCtSource';

const useClinicalTrials = () => {
  const { resultsState, resultsDispatch } = useContext(ResultsStore);

  const [sortOptions, setSortByOptions] = useState<any>([]);
  const [selectSortByOption, setSelectedSortByOption] = useState<any>({});
  const [applications, setApplications] = useState<Array<any> | Dictionary<any[]>>();

  const { payload }: any = useParams();
  const { source: sources }: any = useMemo(() => decodeBase64ToObject(payload), [payload]);
  const ctSource = getCtSource(sources);

  useEffect(() => {
    setApplications(resultsState?.ctResults?.results);
  }, [resultsState.ctResults]);

  const sortBy = (option: any) => {
    const index = sortOptions.findIndex((opt: any) => opt.id === option.id);
    sortOptions[index].selected = true;
    setSelectedSortByOption(sortOptions[index]);
    setSortByOptions(
      sortOptions.map((opt: any, _index: number) => ({ ...opt, selected: index === _index }))
    );

    const sortByFieldAndDirection =
      option?.id === 'default' ? null : { [option?.field]: option?.sortType };

    resultsDispatch({
      type: ResultActions.SET_CT_SORT_BY,
      value: sortByFieldAndDirection
    });
  };

  useEffect(() => {
    setSortByOptions(CT_SORT_OPTIONS[ctSource]);
    setSelectedSortByOption(CT_SORT_OPTIONS[ctSource]?.find((opt: any) => opt.selected));
  }, []);

  return {
    applications,
    sortOptions,
    selectSortByOption,
    sortBy,
    sources
  };
};

export default useClinicalTrials;
