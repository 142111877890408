const styles = {
  roundedMenu: {
    '& .MuiPaper-root': { padding: '8px 0' },
    '& .MuiList-root': { padding: 0 }
  },
  menuItem: {
    padding: '8px 24px !important'
  },
  listItemIcon: {
    minWidth: '16px !important'
  },
  svgIcon: { height: '16px', width: '16px', color: 'gray.750' },
  listItemText: {
    fontFamily: 'Mulish',
    fontSize: '12px',
    fontWeight: '400',
    ml: '8px',
    color: 'primary.darkVariant1'
  }
};

export default styles;
