import React, { memo } from 'react';

// mui v5
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import themev5 from '../../themev5';

import PDCOTabs from './components/Tabs';

// store
import useUpdateModule from '../../hooks/useUpdateModule';

// Provider
// import { EMAGuidanceStateProvider } from '../../store/EMAGuidance';

const PDCO = () => {
  useUpdateModule('PDCO');

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <PDCOTabs />
    </ThemeProvider>
  );
};

export default memo(PDCO);
