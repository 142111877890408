import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

// material ui
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
// icons
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Fab, Grow, Tooltip } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';

// hoc
import withFetchingAriaResults from '../hoc/withFetchingAriaResults';

// components
import Headers from './Headers';
import AriaResults from './AriaResults';
import LoadingAriaResults from './LoadingAriaResults';
import Filters from './Filters';
import PDFViewer from '../../../components/PDFViewer';

// context
import AriaStore from '../../../store/Aria';
import AriaActions from '../../../store/Aria/actions';
import Store from '../../../store';
import GlobalActions from '../../../store/actions';

// custom hooks
import useQuery from '../../../helpers/customHooks/queryParameter';

// api
import { getAriaQueryResults } from '../../../api/pages/Aria';

// styles
import styles from '../styles/AriaLayout.styles';

// constants
import { DOCUMENT_STATE, ERROR_MESSAGES } from '../utils/constants';
import SelectedFiltersBar from './SelectedFiltersBar';
import ExplorePageFilters from './ExplorePageFilters';
import LanguageToggleButton from '../../../components/LanguageToggleComponents/LanguageToggleButton';

const AriaLayout = () => {
  // context store for aria state management
  const { ariaState, ariaDispatch } = useContext(AriaStore);
  // eslint-disable-next-line no-unused-vars
  const { dispatch } = useContext(Store);

  // get the url parameters
  const { module, source } = useParams();
  // get the query arguments from url
  const query = useQuery();

  const ariaScrollRef = useRef(null);

  // state for show or hide back to top button
  const [showButton, setShowButton] = useState(false);

  // state for explore more selected filters
  const [exploreSelectedFilters, setExploreSelectedFilters] = useState({
    selectedFilters: {},
    selectedFiltersOrder: []
  });

  const toggleTextLanugage = value => {
    ariaDispatch({ type: AriaActions.SET_ORIGINAL_TEXT_ENABLED, value });
  };

  const handleFilterDrawerClose = () => {
    if (ariaState.pendingFilters) {
      dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: 'Filters were updated, please press the "Apply" button.' }
      });
      return;
    }
    ariaDispatch({ type: AriaActions.SET_FILTERS_DRAWER, value: false });
  };

  /**
   * function to get results for the requested page number
   */
  const pageChangeResults = async page => {
    // update the current page number
    ariaDispatch({ type: AriaActions.SET_PAGE, value: page });
    ariaDispatch({ type: AriaActions.SET_DOCUMENT_OPEN, value: false });
    ariaDispatch({ type: AriaActions.SET_LOADING, value: true });
    await ariaDispatch({
      type: AriaActions.SET_ERROR,
      value: ''
    });
    // get a deep copy of the current filters
    const currentFilters = JSON.parse(JSON.stringify(ariaState.filters));

    // if this is a results page then also use the cached filter query
    if (query.get('resultsPage')) {
      const ariaCache = JSON.parse(sessionStorage.getItem('aria_cache'));
      const resultsFilterList = ariaCache?.ariaFilterList;

      currentFilters['group-id'] = ariaState.filters['group-id'] || resultsFilterList;
    }

    const filterObject = Object.fromEntries(
      Object.entries(currentFilters).filter(([, value]) => value.length > 0)
    );

    // request body for api call
    const payload = {
      query: query.get('term'),
      filters: filterObject
    };

    // get aria results from rest api
    const res = await getAriaQueryResults(
      module,
      query.get('bucket'),
      source,
      payload,
      page // increments by 1 since query is for next page number
    );
    if (res && res.data.status === 200) {
      const {
        results,
        suggested_answers: suggestedAnswers,
        global_filters: globalFilters
      } = res.data.body;
      const newResult = [].concat(...ariaState.ariaResults, ...results);
      if (globalFilters && Object.keys(globalFilters).length > 0) {
        await ariaDispatch({
          type: AriaActions.SET_GLOBAL_FILTERS,
          value: globalFilters
        });
      }
      // if no filters were applied then update the original results as well to get more filter options
      // DO NOT update the original results when filters is applied coz that will give varying filter options
      if (currentFilters === {})
        ariaDispatch({ type: AriaActions.SET_ORIGINAL_RESULTS, value: newResult });
      let finalResults = results;
      let finalSuggestions = suggestedAnswers;
      // filter out results based on search by keyword for pagination
      if (currentFilters?.search_terms?.length > 0) {
        ariaState.filters?.search_terms.forEach(searchTerm => {
          finalResults = results.filter(x =>
            x.text.toLowerCase().includes(searchTerm.toLowerCase())
          );

          finalSuggestions = suggestedAnswers.filter(x =>
            x.text.toLowerCase().includes(searchTerm.toLowerCase())
          );
        });
      }
      // update the current aria results on display
      await ariaDispatch({ type: AriaActions.SET_ARIA_RESULTS, value: finalResults });
      await ariaDispatch({
        type: AriaActions.SET_SUGGESTED_ANS,
        value: finalSuggestions
      });
      if (finalResults.length === 0) {
        await ariaDispatch({
          type: AriaActions.SET_ERROR,
          value: ERROR_MESSAGES['404']
        });
      }
    }
    // on page change the sort order should be reset to default
    await ariaDispatch({
      type: AriaActions.SET_SELECTED_SORT,
      value: 'Relevance: Very High -> Low'
    });
    // }
    ariaDispatch({
      type: AriaActions.SET_COLLAPSED_SUGGESTION,
      value: false
    });
    ariaDispatch({ type: AriaActions.SET_LOADING, value: false });
  };

  /**
   * function to set both the SET_DOCUMENT_OPEN to false and
   * the SET_DOCUMENT_STATE to 'CLOSED' when the close button
   * is getting clicked.
   */
  const onCloseDocument = async () => {
    await dispatch({ type: GlobalActions.SET_ARIA_DOCUMENT_LINK, value: '' });
    ariaDispatch({ type: AriaActions.SET_DOCUMENT_OPEN, value: false });
    ariaDispatch({ type: AriaActions.SET_DOCUMENT_STATE, value: DOCUMENT_STATE.close });
    ariaDispatch({ type: AriaActions.SET_SELECTED_DOCUMENT, value: {} });
    // releases the existing object URL which was previously created by calling URL.createObjectURL()
    URL.revokeObjectURL(ariaState.documentLink.split('#page=')[0]);
  };

  const scrollToTop = () => {
    ariaScrollRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const showBackToTopButton = () => {
    if (ariaScrollRef.current) {
      if (ariaScrollRef.current.getBoundingClientRect().top < -100) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }
  };

  // get the document link based on the original text toggle (eg: english/japanese in PMDA)
  const getDocumentLink = ariaState.originalTextEnabled
    ? ariaState.originalLangDocumentLink ?? ariaState.documentLink
    : ariaState.documentLink;

  useEffect(() => {
    // reset the filters when the aria results change
    setExploreSelectedFilters({
      selectedFilters: {},
      selectedFiltersOrder: []
    });
  }, [ariaState.ariaResults]);

  useEffect(() => {
    if (ariaState.loading) {
      setShowButton(false);
    }
  }, [ariaState.loading]);

  useEffect(() => {
    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: ariaState.documentOpen
        ? {
            blob: getDocumentLink,
            ...ariaState.selectedDocument,
            source,
            triggerReopenChatRia: false
          }
        : {}
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: source
    });

    return () => {
      dispatch({
        type: GlobalActions.SET_ARIA_DOCUMENT,
        value: {}
      });
      dispatch({
        type: GlobalActions.SET_APPLICATION_SOURCE,
        value: ''
      });
      dispatch({
        type: GlobalActions.SET_APPLICATION_NUMBER,
        value: ''
      });
    };
  }, [ariaState.selectedDocument, ariaState.documentOpen, ariaState.documentLink, source]);

  return (
    <Grid container sx={styles.grid}>
      {/* filters  */}
      {/* filters opens from drawer when document view is open */}
      <Drawer
        anchor='right'
        sx={styles.drawer}
        open={ariaState.showFiltersDrawer}
        onClose={handleFilterDrawerClose}>
        <Stack sx={styles.drawerContent}>
          <Filters />
        </Stack>
      </Drawer>
      <Grid
        xs={12}
        md={9}
        item
        lg={ariaState.documentOpen || ariaState.documentState === DOCUMENT_STATE.loading ? 6 : 8}>
        <Box
          sx={styles.resultsContainer}
          ml={ariaState.documentOpen || ariaState.documentState === DOCUMENT_STATE.loading ? 0 : 4}>
          <Headers sx={styles.header} onChangePage={pageChangeResults} />
          {ariaState.loading ? (
            <LoadingAriaResults />
          ) : (
            <Stack sx={styles.results} id='aria-stack' onScroll={showBackToTopButton}>
              {ariaState.ariaResults.length > 0 && (
                <AriaResults exploreSelectedFilters={exploreSelectedFilters} />
              )}
            </Stack>
          )}
        </Box>
      </Grid>
      {/* for pdf documents */}
      {
        // eslint-disable-next-line no-nested-ternary
        ariaState.documentOpen && ariaState.documentState === DOCUMENT_STATE.open ? (
          <Grid item xs={12} lg={6} sx={styles.documentContainer}>
            <Stack mb={2} direction='row' justifyContent='space-between'>
              <Stack spacing={2} direction='row' alignItems='center'>
                <Typography variant='subtitle1' sx={styles.documentTitle}>
                  {ariaState.selectedDocument.title}
                </Typography>
                <Tooltip title='Open in new tab'>
                  <IconButton size='small' target='_blank' href={getDocumentLink} mt={2}>
                    <OpenInNew color='white' sx={styles.closeBtn} />
                  </IconButton>
                </Tooltip>
                <LanguageToggleButton
                  source={source}
                  originalTextEnabled={ariaState.originalTextEnabled}
                  toggleTextLanugage={toggleTextLanugage}
                  color='white'
                />
              </Stack>
              <CancelIcon onClick={() => onCloseDocument()} color='white' sx={styles.closeBtn} />
            </Stack>
            <div
              style={{
                height: '90%',
                width: '90%'
              }}>
              {getDocumentLink.includes('.txt') ? (
                <iframe
                  src={getDocumentLink}
                  style={{ backgroundColor: 'white' }}
                  title='txt file'
                  width='100%'
                  height='100%'
                />
              ) : (
                <PDFViewer
                  fileUrl={getDocumentLink}
                  initialPage={getDocumentLink.split('#page=')[1] - 1 || 0}
                  defaultScale={'PageWidth'}
                />
              )}
            </div>
          </Grid>
        ) : ariaState.documentState === DOCUMENT_STATE.loading ? (
          <Grid container xs={12} lg={6} alignItems='center' justifyContent='center'>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid item md={3} lg={4}>
            <Stack p={2} spacing={1}>
              <SelectedFiltersBar />
              <ExplorePageFilters
                selectedFiltersInfo={exploreSelectedFilters}
                setSelectedFiltersInfo={setExploreSelectedFilters}
              />
            </Stack>
          </Grid>
        )
      }
      <Grow in={showButton && !ariaState?.loading && !ariaState?.documentOpen} unmountOnExit>
        <Fab
          variant='extended'
          color='primary'
          aria-label='back-to-top'
          sx={{
            position: 'absolute',
            bottom: 16,
            right: 16,
            color: 'white !important'
          }}
          onClick={scrollToTop}>
          <ExpandLessIcon sx={{ mr: 1 }} />
          <Typography variant='caption'>Back to Top</Typography>
        </Fab>
      </Grow>
    </Grid>
  );
};

export default withFetchingAriaResults(AriaLayout);
