import React, { Fragment, useContext } from 'react';
//MATERIAL UI IMPORTS
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
// import Paper from '@material-ui/core/Paper';
//styles
import LineGraphStyles from '../styles/LineGraph.styles';
//other imports
import PlusIcon from '../../../Images/svgs/plus';
import CustomBarGraph from '../../CustomComponents/Graphs/CustomBarGraph';
//store
import Store from '../../../store';
import Actions from '../../../store/actions';
import uniqueId from 'lodash/uniqueId';
import { REPORT_QUERIES } from './constants';

const LineGraph = ({ data }) => {
  const classes = LineGraphStyles();
  const { state, dispatch } = useContext(Store);

  const handleAddToReport = async id => {
    const addedReport = REPORT_QUERIES.find(report => report.id === id);
    const random = Math.floor(Math.random() * 1000);
    dispatch({
      type: Actions.ADD_TEMPLATE_TO_REPORT,
      value: {
        templateType: 'custom',
        chartType: addedReport.graph,
        sectionType: 'CHART',
        id: uniqueId(`505b2_${random}_`),
        style: {
          placement: {
            h: 40,
            w: 12
          },
          graphStyle: addedReport.graphStyle,
          title: addedReport.title
        },
        data: {
          source: 'us',
          search_type: '505b2',
          query: addedReport.query
        }
      }
    });
    await dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message: 'Successfully added to Report.', color: 'success' }
    });
  };
  return (
    <Fragment>
      <Grid container spacing={2} lg={12}>
        <Grid item xs={12} lg={6}>
          <Box display='flex' flexDirection='column' className={classes.graphBox}>
            {/* <Paper elevation={2} className={classes.paper}> */}
            <Box display='flex' flexDirection='row' justifyContent='space-between'>
              {data ? (
                <>
                  <Typography className={classes.headings}>Approval(s) by Year</Typography>
                  <Typography
                    onClick={() => handleAddToReport('us_result_approval_by_year')}
                    className={classes.add}>
                    <PlusIcon /> Add to Report
                  </Typography>
                </>
              ) : (
                <>
                  <Skeleton height={16} width={100} className={classes.headings} />
                  <Skeleton height={16} width={100} className={classes.add} />
                </>
              )}
            </Box>
            {data && data ? (
              <>
                {data.approvalYearGraph?.length > 0 ? (
                  <CustomBarGraph
                    keys={['count']}
                    paddings={0.4}
                    colors={['#12815E']}
                    index='year'
                    data={data && data.approvalYearGraph}
                  />
                ) : (
                  <Box display='flex' justifyContent='center'>
                    <Typography>No Data</Typography>
                  </Box>
                )}
              </>
            ) : (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-end'
                alignItems='space-between'
                className={classes.loaderBox}>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  flexWrap='wrap-reverse'
                  className={classes.barGraphRect}>
                  <Skeleton variant='rect' height='90%' width='.2%' />
                  <Skeleton variant='rect' height='60%' width='3%' />
                  <Skeleton variant='rect' height='80%' width='3%' />
                  <Skeleton variant='rect' height='30%' width='3%' />
                  <Skeleton variant='rect' height='50%' width='3%' />
                  <Skeleton variant='rect' height='50%' width='3%' />
                  <Skeleton variant='rect' height='60%' width='3%' />
                  <Skeleton variant='rect' height='80%' width='3%' />
                  <Skeleton variant='rect' height='30%' width='3%' />
                  <Skeleton variant='rect' height='50%' width='3%' />
                  <Skeleton variant='rect' height='50%' width='3%' />
                </Box>
                <Skeleton variant='rect' height='.2%' width='100%' />
              </Box>
            )}
            {/* </Paper> */}
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box display='flex' flexDirection='column' className={classes.graphBox}>
            {/* <Paper elevation={2} className={classes.paper}> */}
            <Box display='flex' flexDirection='row' justifyContent='space-between'>
              {data ? (
                <>
                  <Typography className={classes.headings}>
                    Approval Time, Days from Filling
                  </Typography>
                  <Typography
                    onClick={() => handleAddToReport('us_result_approval_time_by_year')}
                    className={classes.add}>
                    <PlusIcon /> Add to Report
                  </Typography>
                </>
              ) : (
                <>
                  <Skeleton height={16} width={100} className={classes.headings} />
                  <Skeleton height={16} width={100} className={classes.add} />
                </>
              )}
            </Box>
            {data && data ? (
              <>
                {data.approvalTimeGraph?.length > 0 ? (
                  <CustomBarGraph
                    className={classes.graph}
                    keys={['count']}
                    paddings={0.4}
                    colors={['#6868FA']}
                    index='year'
                    data={data && data.approvalTimeGraph}
                  />
                ) : (
                  <Box display='flex' justifyContent='center'>
                    <Typography>No Data</Typography>
                  </Box>
                )}
              </>
            ) : (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-end'
                alignItems='space-between'
                className={classes.loaderBox}>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  flexWrap='wrap-reverse'
                  className={classes.barGraphRect}>
                  <Skeleton variant='rect' height='90%' width='.2%' />
                  <Skeleton variant='rect' height='60%' width='3%' />
                  <Skeleton variant='rect' height='80%' width='3%' />
                  <Skeleton variant='rect' height='30%' width='3%' />
                  <Skeleton variant='rect' height='50%' width='3%' />
                  <Skeleton variant='rect' height='50%' width='3%' />
                  <Skeleton variant='rect' height='60%' width='3%' />
                  <Skeleton variant='rect' height='80%' width='3%' />
                  <Skeleton variant='rect' height='30%' width='3%' />
                  <Skeleton variant='rect' height='50%' width='3%' />
                  <Skeleton variant='rect' height='50%' width='3%' />
                </Box>
                <Skeleton variant='rect' height='.2%' width='100%' />
              </Box>
            )}
            {/* </Paper> */}
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default LineGraph;
