import { memo, useContext, useState, useCallback, MouseEvent } from 'react';
import { Tooltip, IconButton } from '@mui/material';

import { RoundedMenu } from '../../../components/ui/Menu';
import { DownloadIcon } from '../../../assets/svgs/Icons';
import ResultsStore from '../../../store/SearchResults';
import styles from '../styles/DownloadButton.styles';
import { RESTRICT_DOWNLOADS_BY_ORGANIZATION } from '../../../const';
import CollapsableList from '../../Aria/components/CollapsableList';
import AuthContext from '../../../store/Auth/AuthProvider';

const DocumentsDownloadButton = () => {
  const { currentUser } = useContext(AuthContext);
  const { resultsState } = useContext(ResultsStore);
  const [isDownloadListOpen, setIsDownloadListOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setIsDownloadListOpen(false);
  }, []);

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsDownloadListOpen(true);
  }, []);

  return (
    <>
      <Tooltip title='Download'>
        <IconButton
          disabled={RESTRICT_DOWNLOADS_BY_ORGANIZATION.includes(currentUser['custom:organization'])}
          aria-label='more'
          id='long-button'
          aria-controls={isDownloadListOpen ? 'long-menu' : undefined}
          aria-expanded={isDownloadListOpen ? 'true' : undefined}
          aria-haspopup='true'
          onClick={handleClick}>
          <DownloadIcon sx={styles.downloadIcon} />
        </IconButton>
      </Tooltip>
      <RoundedMenu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={isDownloadListOpen}
        onClose={handleClose}>
        <CollapsableList
          downloadOption='download'
          resultsState={resultsState}
          expandedOptionsOpen
        />
      </RoundedMenu>
    </>
  );
};

export default memo(DocumentsDownloadButton);
