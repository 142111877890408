import React from 'react';

// material ui v5
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import themev5 from '../../themev5';
import CDPMain from './components/CDPMain';
import useUpdateModule from '../../hooks/useUpdateModule';

// custom hooks
import useQuery from '../../helpers/customHooks/queryParameter';
import { decodeStringFromURL } from '../../utils/encodeDecodeObject';

const CDP: React.FC<any> = () => {
  const query = useQuery();
  const { active_ingredients: activeIngredient, number }: any = useParams();
  useUpdateModule('cdp');

  // For now we have it from Reg360; later modify it using query paramsto support from different sources
  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Box width='100%' height='calc(100vh - 90px)'>
        <CDPMain
          activeIngredient={decodeStringFromURL(activeIngredient)}
          applicationNumber={number}
          tradeName={query.get('searchTerm') || ''}
        />
      </Box>
    </ThemeProvider>
  );
};

export default React.memo(CDP);
