import {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { isEmpty } from 'lodash';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Chip,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { Clear, ExpandMore, Search } from '@mui/icons-material';

import ResultsPage from '../../../store/SearchResults';
import ResultsActions from '../../../store/SearchResults/actions';

// styles
import styles from '../styles/ExplorePageFilters.styles';
import typography from '../../../themev5/typography';

// constants
import { frontEndAriaFilters, frontEndAriaFiltersBySource } from '../utils/constants';

interface FrontEndAriaFiltersType {
  id: string;
  label: string;
}

interface ExtendedFrontEndAriaFiltersType extends FrontEndAriaFiltersType {
  options: { [key: string]: number };
  categoryOptionsVisibleCount: number;
}

interface ExplorePageProps {
  // explorePageMapper: any;
  selectedFiltersInfo: selectedFilterInfoType;
  setSelectedFiltersInfo: Dispatch<SetStateAction<selectedFilterInfoType>>;
  paginatedData: any;
}

interface selectedFilterInfoType {
  selectedFilters: selectedFiltersType;
  selectedFiltersOrder: string[];
}

export interface selectedFiltersType {
  [key: string]: (string | string[])[];
}

const ExplorePageFilters = ({
  selectedFiltersInfo,
  setSelectedFiltersInfo,
  paginatedData
}: ExplorePageProps) => {
  // const { source }: { source: string } = useParams();
  // const source = 'us';

  // @ts-ignore
  // const typedFrontEndAriaFilters: any = explorePageMapper?.commonMapper
  //   ? frontEndAriaFilters
  //   : (frontEndAriaFiltersBySource[
  //       explorePageMapper?.source as keyof typeof frontEndAriaFiltersBySource
  //     ] as any);

  const [searchText, setSearchText] = useState('');
  const { resultsState, resultsDispatch } = useContext(ResultsPage) as any;
  const [filters, setFilters] = useState<ExtendedFrontEndAriaFiltersType[]>([]);
  const [originalFilters, setOriginalFilters] = useState<ExtendedFrontEndAriaFiltersType[]>([]);

  const defaultVisibleOptions = 5;
  const defaultShowMoreOptionsCount = 10;

  /**
   * This function creates the original filters for the first time
   * @param results - the aria results
   * @returns the original filters with the count of each option without any filters selected
   * */
  const createOriginalFilters = (results: any[]) => {
    if (isEmpty(results)) return [];
    let explorePageFilters: any = resultsState?.explorePageMapper?.commonMapper
      ? frontEndAriaFilters
      : (frontEndAriaFiltersBySource[
          resultsState?.explorePageMapper?.source as keyof typeof frontEndAriaFiltersBySource
        ] as any);

    if (!explorePageFilters) {
      explorePageFilters = frontEndAriaFilters;
    }
    // adds options and categoryOptionsVisibleCount field to each filter
    const tempFilters: ExtendedFrontEndAriaFiltersType[] = explorePageFilters?.map(
      (filter: any) => ({
        ...filter,
        options: {},
        categoryOptionsVisibleCount: defaultVisibleOptions
      })
    );

    // get the count of each option for each filter
    results?.forEach((res: any) => {
      tempFilters?.forEach((filter, index) => {
        const attribute = filter.id;
        const option = res[attribute];
        const modifiedOptions = { ...filter.options };
        // check if object attribute has an array of values
        // add the count of each option for each selected filter
        if (Array.isArray(option) && option?.length > 1) {
          option.forEach(opt => {
            modifiedOptions[opt] = (modifiedOptions[opt] || 0) + 1;
          });
        } else modifiedOptions[option] = (modifiedOptions[option] || 0) + 1;
        tempFilters[index] = { ...filter, options: modifiedOptions };
      });
    });
    return tempFilters;
  };
  /**
   * This function creates the filters based on the selected filters and get the count of each option for each filter
   * It iteratively filters the results based on the order of selected category and drills down the results
   * The count of the subsequent filters are dependent on the previous filters (parent child relationship)
   * @param results - the aria results
   * @param newSelectedFiltersInfo - the new selected filters
   * @param resetOriginalFilters - whether to reset the original filters or not
   * */
  const createFilter = (
    results: any[],
    newSelectedFiltersInfo: selectedFilterInfoType,
    resetOriginalFilters: boolean
  ) => {
    if (isEmpty(results) || !results) {
      setFilters([]);
      return;
    }
    // destructure the selected filters
    const { selectedFilters: newSelectedFilters, selectedFiltersOrder: newSelectedFiltersOrder } =
      newSelectedFiltersInfo;

    let tempFilters: ExtendedFrontEndAriaFiltersType[] = [];

    // remove duplicates if unique results is true or if the result is an answer
    let newResults = [...results];
    // if no filters are selected, then create the original filters, else reuse it
    if (isEmpty(newSelectedFiltersOrder)) {
      if (resetOriginalFilters || isEmpty(originalFilters)) {
        const newOriginalFilters = createOriginalFilters(newResults);
        setOriginalFilters(newOriginalFilters);
        tempFilters = newOriginalFilters ? [...newOriginalFilters] : [];
      } else tempFilters = [...originalFilters];
    } else {
      if (resetOriginalFilters || isEmpty(originalFilters)) {
        const newOriginalFilters = createOriginalFilters(newResults);
        setOriginalFilters(newOriginalFilters);
        tempFilters = newOriginalFilters?.map(filter => ({
          ...filter,
          options: Object.fromEntries(Object.entries(filter.options).map(([key]) => [key, 0]))
        }));
      } else {
        tempFilters = originalFilters.map(filter => ({
          ...filter,
          options: Object.fromEntries(Object.entries(filter.options).map(([key]) => [key, 0]))
        }));
      }

      // get the count of each option for each selected filter
      newSelectedFiltersOrder.forEach(category => {
        const tempFilterIndex = tempFilters.findIndex(filter => filter.id === category);
        if (tempFilterIndex !== -1) {
          const attribute = tempFilters[tempFilterIndex].id;
          newResults.forEach((res: any) => {
            const option = res[attribute];
            const modifiedOptions = { ...tempFilters[tempFilterIndex].options };
            // check if object attribute has an array of values
            // add the count of each option for each selected filter
            if (Array.isArray(option) && option?.length > 1) {
              option.forEach(opt => {
                modifiedOptions[opt] = (modifiedOptions[opt] || 0) + 1;
              });
            } else modifiedOptions[option] = (modifiedOptions[option] || 0) + 1;
            tempFilters[tempFilterIndex].options = modifiedOptions;
          });
        }
        // filter the results based on the selected filters and use the filtered results for the next filter
        // this drills down the results based on the selected filters
        newResults = newResults.filter(result =>
          selectedFiltersInfo?.selectedFilters[category]?.some(option =>
            Array.isArray(result[category])
              ? result[category].includes(option)
              : result[category] === option
          )
        );
      });

      // calculate the count of each option for each unselected filter
      tempFilters.forEach((filter, index) => {
        if (newSelectedFiltersOrder.includes(filter?.id)) return;
        const attribute = filter.id;
        newResults.forEach((res: any) => {
          const option = res[attribute];
          const modifiedOptions = { ...filter.options };
          // check if object attribute has an array of values
          // add the count of each option for each selected filter
          if (Array.isArray(option) && option?.length > 1) {
            option.forEach(opt => {
              modifiedOptions[opt] = (modifiedOptions[opt] || 0) + 1;
            });
          } else modifiedOptions[option] = (modifiedOptions[option] || 0) + 1;
          tempFilters[index].options = modifiedOptions;
        });
      });
    }
    // sort the filters
    tempFilters.forEach((filter, index) => {
      const selectedOptions: [string, number][] = [];
      const unselectedOptions: [string, number][] = [];

      // seperate the selected and unselected options for sorting
      // Object.entries(filter.options).forEach(([key, value]) => {
      //   if (Array.isArray(filter.id)) {
      //     filter.id?.forEach(filterId => {
      //       if (newSelectedFilters[filterId]?.includes(key)) selectedOptions.push([key, value]);
      //       else unselectedOptions.push([key, value]);
      //     });
      //   } else if (newSelectedFilters[filter.id]?.includes(key)) selectedOptions.push([key, value]);
      //   else unselectedOptions.push([key, value]);
      // });
      Object.entries(filter.options).forEach(([key, value]) => {
        if (newSelectedFilters[filter.id]?.includes(key)) selectedOptions.push([key, value]);
        else unselectedOptions.push([key, value]);
      });

      // sort the unselected options based on ascending order of the option name and combine the selected and unselected options
      tempFilters[index].options = {
        ...Object.fromEntries(selectedOptions),
        ...Object.fromEntries(
          unselectedOptions.sort(
            ([key1, value1], [key2, value2]) => value2 - value1 || key1.localeCompare(key2)
          )
        )
      };
      const previousCategoryOptionsVisibleCount =
        filters.find(f => f.id === filter.id)?.categoryOptionsVisibleCount ?? defaultVisibleOptions;
      tempFilters[index].categoryOptionsVisibleCount = Math.max(
        previousCategoryOptionsVisibleCount,
        selectedOptions.length
      );
    });
    const sortOrder = [
      ...newSelectedFiltersOrder,
      ...tempFilters
        .map(filter => filter.id)
        .filter(filter => !newSelectedFiltersOrder.includes(filter))
    ];

    tempFilters.sort(
      (filter1, filter2) => sortOrder.indexOf(filter1.id) - sortOrder.indexOf(filter2.id)
    );
    // set the original filters to the temp filters for the first time
    setFilters(tempFilters);
  };

  /**
   * This function is called when a filter is clicked
   * @param category - the category of the filter (e.g. category_bucket)
   * @param option - the option of the filter (e.g. 'Labels')
   * */
  const clickFilter = (category: string, option: string | string[]) => {
    const newSelectedFiltersInfo = { ...selectedFiltersInfo };
    // if the filter is already selected, then remove it, else add it
    // the order of the selected filters is maintained in the selectedFiltersOrder array
    if (category in newSelectedFiltersInfo.selectedFilters) {
      if (newSelectedFiltersInfo.selectedFilters[category].includes(option)) {
        newSelectedFiltersInfo.selectedFilters[category] = newSelectedFiltersInfo.selectedFilters[
          category
        ].filter(x => x !== option);
        if (newSelectedFiltersInfo.selectedFilters[category].length === 0) {
          delete newSelectedFiltersInfo.selectedFilters[category];
          newSelectedFiltersInfo.selectedFiltersOrder =
            newSelectedFiltersInfo.selectedFiltersOrder.filter(x => x !== category);
        }
      } else {
        newSelectedFiltersInfo.selectedFilters[category].push(option);
      }
    } else {
      newSelectedFiltersInfo.selectedFilters[category] = [option];
      newSelectedFiltersInfo.selectedFiltersOrder.push(category);
    }
    setSelectedFiltersInfo(newSelectedFiltersInfo);
  };

  const filterBySearch = ([option]: any) => {
    const textToSearch = searchText.toLowerCase().trim();
    if (textToSearch === '') return true;
    if (typeof option === 'string') return option.toLowerCase().includes(textToSearch);
    if (Array.isArray(option))
      return option.some(x => x.toLowerCase().includes(searchText.toLowerCase()));
    return true;
  };

  // calculate the total number of selected filters
  const totalSelectedFilters: number = useMemo(
    () =>
      Object.values(selectedFiltersInfo?.selectedFilters).reduce(
        (sum, curr) => sum + curr.length,
        0
      ),
    [selectedFiltersInfo]
  );

  const finalFilter = useCallback(
    (filter: ExtendedFrontEndAriaFiltersType) =>
      searchText.trim() === ''
        ? Object.entries(filter?.options)
            .filter(filterBySearch)
            .slice(0, filter?.categoryOptionsVisibleCount)
        : Object.entries(filter?.options).filter(filterBySearch),
    [filters, searchText]
  );

  useEffect(() => {
    setSearchText('');
    createFilter(paginatedData, selectedFiltersInfo, true);
  }, [paginatedData, resultsState.explorePageMapper, selectedFiltersInfo]);

  return (
    <Box sx={{ maxWidth: 389 }}>
      <Accordion
        elevation={0}
        expanded={resultsState.exploreResultsOpen}
        square={false}
        onChange={() =>
          resultsDispatch({
            type: ResultsActions.SET_EXPLORE_RESULTS_OPEN,
            value: !resultsState.exploreResultsOpen
          })
        }
        defaultExpanded={filters.length !== 0}
        sx={{
          border: '1px solid #DBDBDB',
          borderRadius: '8px !important'
        }}>
        <AccordionSummary
          expandIcon={
            <Tooltip title='Refine'>
              <ExpandMore />
            </Tooltip>
          }
          aria-controls='panel1a-content'
          id='panel1a-header'>
          <Tooltip title='Refine'>
            <Stack direction='row' alignItems='center' spacing={1}>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography variant='subtitle2' fontWeight='bold'>
                  Explore
                </Typography>
              </Stack>
              <Typography variant='subtitle2' sx={styles.resultCountText}>
                (Top{' '}
                {`${resultsState?.exploreResultLength} ${
                  paginatedData?.length === 1 ? 'Result' : 'Results'
                }`}
                )
              </Typography>
              {totalSelectedFilters > 0 && (
                <Avatar sx={styles.totalFilterCountAvatar}>{totalSelectedFilters}</Avatar>
              )}
            </Stack>
          </Tooltip>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction='column' spacing={0.5}>
            <TextField
              value={searchText}
              disabled={filters.length === 0}
              onChange={e => setSearchText(e.target.value)}
              fullWidth
              placeholder='Search'
              size='small'
              InputProps={{
                sx: { fontSize: typography.subtitle2.fontSize, borderRadius: '8px' },
                'aria-label': 'Search',
                endAdornment: (
                  <>
                    <InputAdornment position='start'>
                      <Search fontSize='small' sx={{ color: 'gray.light' }} />
                    </InputAdornment>
                    {searchText && (
                      <Tooltip title='Clear search'>
                        <IconButton onClick={() => setSearchText('')} size='small'>
                          <Clear fontSize='small' />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                )
              }}
            />
            {totalSelectedFilters > 0 && (
              <Typography
                variant='body1'
                px={2}
                onClick={() => {
                  setSelectedFiltersInfo({ selectedFilters: {}, selectedFiltersOrder: [] });
                  createFilter(
                    paginatedData,
                    {
                      selectedFilters: {},
                      selectedFiltersOrder: []
                    },
                    false
                  );
                }}
                sx={styles.clearText}>
                CLEAR ALL SELECTIONS
              </Typography>
            )}
            <Box px={2} py={1} sx={styles.filtersBox}>
              <Stack direction='column' spacing={3}>
                {filters.map(
                  filter =>
                    finalFilter(filter).length > 0 && (
                      <Stack key={filter?.id} direction='column' spacing={1}>
                        <Stack direction='row' spacing={1} alignItems='center'>
                          <Typography
                            variant='subtitle2'
                            sx={{ color: 'gray.light', fontWeight: 'bold' }}>
                            {filter?.label}
                          </Typography>
                          <Box
                            sx={{
                              backgroundColor: 'gray.background',
                              px: '5px',
                              borderRadius: 1,
                              textAlign: 'center'
                            }}>
                            <Typography
                              variant='body1'
                              sx={{ color: 'gray.lightVariant2', fontWeight: 'bold' }}>
                              {`${selectedFiltersInfo.selectedFilters[filter?.id]?.length ?? 0}/${
                                Object.keys(filter?.options).length
                              }`}
                            </Typography>
                          </Box>
                        </Stack>
                        <Box sx={{ flexWrap: 'wrap' }}>
                          {finalFilter(filter).map(([option, count]) => (
                            <Chip
                              key={`${filter?.id}-${option}`}
                              title={option.length > 20 ? option : ''}
                              disabled={
                                count === 0 &&
                                !selectedFiltersInfo?.selectedFilters[filter?.id]?.includes(option)
                              }
                              label={
                                <Stack direction='row' alignItems='center' spacing={1}>
                                  <Typography variant='body1'>
                                    {option.length > 20 ? `${option.substring(0, 20)}...` : option}
                                  </Typography>{' '}
                                  &nbsp;({(count as number) > 99 ? '99+' : count})
                                </Stack>
                              }
                              onClick={() => clickFilter(filter?.id, option)}
                              variant={
                                selectedFiltersInfo.selectedFilters[filter?.id]?.includes(option)
                                  ? 'filled'
                                  : 'outlined'
                              }
                              sx={
                                selectedFiltersInfo.selectedFilters[filter?.id]?.includes(option)
                                  ? styles.refineSearchChipSelected
                                  : styles.refineSearchChip
                              }
                            />
                          ))}
                          {searchText.trim() === '' &&
                            filter?.categoryOptionsVisibleCount <
                              Object.keys(filter?.options).length && (
                              <Chip
                                key={`${filter?.id}-more`}
                                label={`+${
                                  Object.keys(filter?.options).length -
                                  (filter?.categoryOptionsVisibleCount ?? 0)
                                }`}
                                onClick={() => {
                                  setFilters(prevFilters => {
                                    const newFilters = [...prevFilters];
                                    const index = newFilters.findIndex(f => f.id === filter?.id);
                                    // increase the optionsVisibleCount by 10 or the remaining options count
                                    newFilters[index].categoryOptionsVisibleCount += Math.min(
                                      defaultShowMoreOptionsCount,
                                      Object.keys(filter?.options).length -
                                        // eslint-disable-next-line no-unsafe-optional-chaining
                                        filter?.categoryOptionsVisibleCount
                                    );
                                    return newFilters;
                                  });
                                }}
                                variant='filled'
                                sx={styles.refineSearchChipSelected}
                              />
                            )}
                          {searchText.trim() === '' &&
                            filter?.categoryOptionsVisibleCount ===
                              Object.keys(filter?.options).length &&
                            filter?.categoryOptionsVisibleCount !== defaultVisibleOptions && (
                              <Chip
                                key={`${filter?.id}-less`}
                                label='Show less'
                                onClick={() => {
                                  setFilters(prevFilters => {
                                    const newFilters = [...prevFilters];
                                    const index = newFilters.findIndex(f => f.id === filter?.id);
                                    newFilters[index].categoryOptionsVisibleCount = Math.max(
                                      defaultVisibleOptions,
                                      selectedFiltersInfo?.selectedFilters[filter?.id]?.length ??
                                        defaultVisibleOptions
                                    );
                                    return newFilters;
                                  });
                                }}
                                variant='filled'
                                sx={styles.refineSearchChipSelected}
                              />
                            )}
                        </Box>
                      </Stack>
                    )
                )}
              </Stack>
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default memo(ExplorePageFilters);
