const styles = {
  mainDrawer: {
    '& .MuiDrawer-paper': {
      right: 0,
      width: 632,
      maxWidth: 632,
      height: '100%',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      overflow: 'hidden'
    }
  },
  drawerTitle: {
    maxWidth: '45%',
    overflow: 'hidden',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    color: 'white.main',
    fontSize: 20,
    fontWeight: 700
  },
  accordion: {
    mb: 2,
    mt: '0 !important',
    boxShadow: 'unset',
    borderRadius: 1,
    border: '1px solid',
    borderColor: 'gray.200',
    '&::before': {
      display: 'none',
      height: 0
    }
  },
  accordionSummary: {
    height: 32,
    px: 3,
    py: 1,
    bgcolor: 'primary.0',
    minHeight: 'unset !important'
  },
  accordionSummaryTitle: {
    color: 'gray.900',
    fontSize: 13,
    fontWeight: 700
  },
  accordionDetails: { p: 0 },
  documentListContainer: {
    p: 0,
    '& .clickableDocumentTitleListItem': {
      cursor: 'pointer'
    }
  },
  documentListItem: {
    pr: 1,
    pl: 3,
    py: 1,
    '&:hover': {
      bgcolor: 'gray.50',
      '& .documentTitle': {
        textDecoration: 'underline',
        cursor: 'pointer'
      }
    }
  },
  documentIcon: { fontSize: 14, color: 'gray.300', mt: 0.25 },
  clickableDocumentTitle: {
    color: 'primary.700',
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 0.4,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  nonClickableDocumentTitle: {
    fontSize: 14,
    fontWeight: 600,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: 'gray.800',
    lineHeight: 1.5,
    letterSpacing: 0.25,
    textWrap: 'nowrap',
    width: 'calc(100% - 92px)'
  },
  verticalDivider: { height: 16, mx: 1, borderColor: 'gray.300' },
  secondaryDrawer: {
    position: 'fixed',
    top: 0,
    transform: 'translate(-100%, 0)',
    height: '100%',
    width: 300,
    bgcolor: 'white.main',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    borderRight: '1px solid gray',
    borderColor: 'primary.100',
    overflow: 'hidden'
  },
  secondaryDrawerListItemText: {
    color: 'gray.800',
    fontSize: 14,
    letterSpacing: 0.2,
    width: 'calc(100% - 28px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap'
  },
  secondaryDrawerListItemSelectedText: {
    color: 'gray.900',
    fontSize: 14,
    letterSpacing: 0.2,
    width: 'calc(100% - 28px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    fontWeight: 700
  },
  secondaryDrawerListItemSelectedCheckbox: { ml: 'auto', fontSize: 16, color: 'gray.800' },
  secondaryDrawerListItem: { px: 3, py: 0.75, cursor: 'pointer' },
  secondaryDrawerListItemSelected: { px: 3, py: 0.75, bgcolor: 'primary.0' },
  searchInputBox: {
    ml: 'auto',
    '.MuiInputBase-root': {
      pr: 1.25
    },
    '& input': {
      fontSize: 13,
      px: 1.25,
      pr: 0,
      py: 1,
      height: 16,
      width: 138,
      '&::placeholder': {
        color: 'gray.500',
        opacity: 1
      }
    },
    '& fieldset': {
      borderColor: 'gray.300'
    },
    '& svg': {
      fontSize: 14,
      color: 'gray.500',
      rotate: '280deg'
    }
  },
  categorySelectionPopover: {
    borderRadius: 2
  },
  categorySelection: {
    width: 182,
    borderRadius: 1.5,
    borderColor: 'gray.300',
    fontSize: 14,
    fontWeight: 500,
    p: 0,
    '& .MuiSelect-select': {
      py: 0.75,
      px: 1.25,
      height: 18,
      '& > .MuiListItemIcon-root': {
        display: 'none'
      },
      '& > .MuiListItemText-root span': {
        fontWeight: 500,
        fontSize: 13
      }
    },
    '& fieldset': {
      borderColor: 'gray.300'
    },
    '& svg': {
      color: 'gray.800',
      fontSize: 14
    }
  },
  secondaryDrawerTitleText: { fontSize: 20, fontWeight: 500, color: 'white.main' },
  secondaryDrawerScrollContainer: {
    height: 'calc(100% - 54px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray.400',
      borderRadius: 1,
      opacity: '0.8'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    }
  },
  drawerHeaderIcon: { fontSize: 16, color: 'white.main' },
  accordionContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100% - 200px)',
    position: 'relative',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray.400',
      borderRadius: 1,
      opacity: '0.8'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    }
  },
  secondaryDrawerCloseIcon: { p: 0.75, m: 0, ml: 'auto', mr: -0.75 }
};

export default styles;
