/* eslint-disable react/destructuring-assignment */
import { Button, Drawer, Tooltip, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import ReportIcon from '../../../../assets/svgs/Icons/ReportIcon';
import Visualize from '../Visualize';
import ResultsStore from '../../../../store/SearchResults';
import transformData from '../../utils/dataTransform';
import styles from './styles';

const VisualizeButton = (params: any) => {
  const [openVisulize, setOpenVisulize] = useState<boolean>(false);
  const { resultsState } = useContext(ResultsStore);
  const handleClose = () => {
    setOpenVisulize(false);
  };

  const getApplicationData = (applData: any) => {
    if (applData && Object.keys(applData).length !== 0) {
      return transformData(applData);
    }
    return [];
  };
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography sx={styles.headerText}>{params.colDef.headerName}</Typography>
      <Tooltip title='Click here to view Visualize'>
        <Button
          onClick={() => {
            setOpenVisulize(true);
          }}
          id='visualize-button'
          startIcon={<ReportIcon sx={styles.reportIcon} />}
          sx={styles.visualizeButton}
        />
      </Tooltip>

      <Drawer anchor='bottom' open={openVisulize} sx={styles.dialogBox}>
        <Visualize
          handleClose={handleClose}
          applications={getApplicationData(resultsState?.applicationResults)}
          tab='matchSubmissions'
        />
      </Drawer>
    </div>
  );
};

export default VisualizeButton;
