import palette from '../../../themev5/palette';

const styles = {
  dialogPaper: {
    width: '100%',
    pt: '16px',
    pb: '40px',
    borderRadius: '8px'
  },
  dialogWrapper: {
    background: 'rgba(56, 56, 56, 0.40)'
  },
  closeButton: {
    p: 0,
    position: 'absolute',
    right: '16px',
    top: '16px',
    width: '18px',
    height: '18px',
    '& svg': {
      width: '18px',
      height: '18px'
    }
  },
  contentWrapper: {
    mt: '17px',
    textAlign: 'center',
    padding: '0 48px'
  },
  keywordsFoundText: {
    color: palette.gray[900],
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '150%'
  },
  keywordsQueryText: {
    color: palette.gray[900],
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
    '& span': {
      textDecorationLine: 'underline',
      mr: 0.5
    }
  },
  selectTermText: {
    mt: '32px',
    mb: '16px',
    color: palette.gray[900],
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '150%' /* 18px */
  },
  buttonsWrapper: {
    flexDirection: 'row',
    mt: '16px !important',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px'
  },
  button: {
    color: palette.primary[600],
    'font-feature-settings': "'clig' off, 'liga' off",
    padding: '10px 20px',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    borderRadius: '8px',
    border: `1px solid ${palette.primary[600]}`,
    textTransform: 'capitalize'
  },
  searchWrapper: {
    display: 'flex',
    direction: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px'
  },
  divider: {
    mx: '40px',
    my: '20px'
  },
  searchTerm: {
    color: palette.primary[600],
    'font-feature-settings': "'clig' off, 'liga' off",
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '150%' /* 18px */,
    textTransform: 'initial',
    py: '2px',
    textDecoration: 'underline'
  }
};

export default styles;
