import React from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from '../styles/OptionDropDownStyles';

const LabelOptions = ({ anchorEl, onClose, options, onMenuItemClick, open }: any) => {
  return (
    <Menu id='lock-menu' anchorEl={anchorEl} open={open} onClose={onClose} sx={styles.menu}>
      {options.map((option: any) => (
        <MenuItem
          key={option.id}
          onClick={() => onMenuItemClick(option)}
          id={option.id}
          sx={styles.menuItem}>
          <Box display='flex' alignItems='center'>
            <Checkbox checked={option.selected} sx={styles.checkBox} />
            <Typography component='span' fontSize={14} lineHeight={1}>
              {option.label}
            </Typography>
          </Box>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default React.memo(LabelOptions);
