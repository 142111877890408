import React, { useCallback, useContext, useEffect, useState } from 'react';
import { v4 } from 'uuid';

// material UI
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';

// types
import CloseIcon from '@mui/icons-material/Close';

// api
import styles from './SynonymsFilter.styles';

// store
import ResultsAction from '../../store/SearchResults/actions';
import ResultsStore from '../../store/SearchResults';

const FilterBySynonyms: React.FC<any> = ({ atcView = false, onClose = () => {} }: any) => {
  const { resultsState, resultsDispatch } = useContext(ResultsStore);

  // eslint-disable-next-line no-unused-vars
  const [isloading, setLoading] = useState<boolean>(false);
  const [synonymsNode, setSynonymsNode] = useState<any>([]);

  useEffect(() => {
    setSynonymsNode(resultsState?.synonymsList);
  }, [resultsState.synonymsList]);

  const applyFilter = useCallback(
    (synonyms: any) => {
      let payloadStub: { [key: string]: any } = {};
      let meshTreeNumberList: any = [];
      if (atcView) {
        synonyms.forEach((element: any) => {
          // eslint-disable-next-line array-callback-return
          meshTreeNumberList.push(element.value);
        });
        payloadStub = {
          ...resultsState.filters,
          atc_code: meshTreeNumberList
        };
      } else {
        synonyms.forEach((element: any) => {
          // eslint-disable-next-line array-callback-return
          meshTreeNumberList = [...meshTreeNumberList, ...element.mesh_tree_numbers];
        });
        payloadStub = {
          ...resultsState.filters,
          mesh_tree_numbers: meshTreeNumberList
        };
      }

      if (!meshTreeNumberList?.length) {
        if (atcView) {
          delete payloadStub.atc_code;
        } else {
          delete payloadStub.mesh_tree_numbers;
        }
      }

      resultsDispatch({ type: ResultsAction.SET_FILTERS, value: payloadStub });
      onClose();
    },
    [synonymsNode]
  );

  const removeFromFilters = useCallback(
    (e: any, element: any) => {
      let currentSynonyms = [...resultsState.synonymsList];
      const currentFilters = { ...resultsState.filters };
      currentSynonyms.forEach((item: any) => {
        if (item === element) {
          currentSynonyms = currentSynonyms.filter(ele => ele !== element);
        }
      });
      if (Array.isArray(currentSynonyms) && currentSynonyms.length === 0) {
        if (atcView) {
          delete currentFilters.atc_code;
        } else {
          delete currentFilters.mesh_tree_numbers;
        }
      }
      resultsDispatch({ type: ResultsAction.SET_FILTERS, value: currentFilters });
      resultsDispatch({ type: ResultsAction.SET_SYNONYMS, value: currentSynonyms });
      setSynonymsNode(currentSynonyms);
    },
    [resultsState.synonymsList, resultsState.filters]
  );

  return (
    <Box
      display='flex'
      flexWrap='nowrap'
      flexShrink={0}
      overflow='auto'
      width='inherit'
      height='30%'
      justifyContent='end'
      sx={{ marginTop: 1, marginLeft: 2 }}>
      <Box sx={styles.synonyms} display='flex'>
        {synonymsNode?.length ? (
          synonymsNode.map((element: any) => {
            return (
              <Box sx={styles.FilterNode} key={v4()}>
                <CloseIcon
                  cursor='pointer'
                  fontSize='small'
                  onClick={(e: any) => {
                    removeFromFilters(e, element);
                  }}
                />
                <Tooltip title={element.label}>
                  <Typography noWrap variant='subtitle2'>
                    {element.label.length > 15
                      ? `${element.label.substring(0, 15)}...`
                      : element.label}
                  </Typography>
                </Tooltip>
              </Box>
            );
          })
        ) : (
          <Typography variant='subtitle1' sx={{ fontSize: '14px', color: 'gray.darkText' }}>
            {atcView
              ? 'Select term from ATC Classification Tree to filter the applications'
              : 'Select term from MeSH (Medical Subject Heading) Tree to filter the applications'}
          </Typography>
        )}
      </Box>
      <Box display='flex' width='15%'>
        <LoadingButton
          variant='contained'
          onClick={() => applyFilter(synonymsNode)}
          loading={isloading}
          loadingPosition='end'
          sx={styles.loadingButton}>
          Apply
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default React.memo(FilterBySynonyms);
