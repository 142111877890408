import { isEmpty, isArray } from 'lodash';

const customTitleCase = (value: any) => {
  // https://en.wikipedia.org/wiki/Title_case
  // https://en.wikipedia.org/wiki/Letter_case#Stylistic_or_specialised_usage
  if (isArray(value)) {
    // eslint-disable-next-line no-param-reassign
    value = value.join(' ');
  }
  if (isEmpty(value)) return '';
  const exceptions = [
    'and',
    'the',
    'a',
    'an',
    'for',
    'to',
    'but',
    'at',
    'by',
    'of',
    'in',
    'with',
    'from'
  ];
  const splitStr = value?.toLowerCase()?.split(/[ -]/);
  for (let i = 0; i < splitStr.length; i += 1) {
    if (i !== 0 && exceptions.includes(splitStr[i])) {
      // eslint-disable-next-line no-continue
      continue;
    }
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  const array = splitStr.join(' ');
  return array;
};

export const customDateTitle = (dateString: string) => {
  const dateParts = dateString.match(/[a-zA-Z]{3} [a-zA-Z]{3} \d{1,2} \d{4}/g);
  if (dateParts) {
    const startDate = new Date(dateParts[0]);
    const endDate = new Date(dateParts[1]);

    const formattedStartDate = startDate.toISOString().split('T')[0];
    const formattedEndDate = endDate.toISOString().split('T')[0];
    const outputString = `from ${formattedStartDate} to ${formattedEndDate}`;
    return outputString;
  }
  return dateString;
};

export default customTitleCase;
