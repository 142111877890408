/* eslint-disable no-console */
/* eslint-disable react/require-default-props */
import React, { memo, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import Box from '@mui/material/Box';
import { MagicWand } from '../../../assets/svgs/Icons';

import ResultsStore from '../../../store/SearchResults';

import useChatRiaAnswer from '../hooks/useChatRiaAnswer';

import AriaResponse from './AriaResponse';
import ExplorePageFilters from '../../Aria/components/ExplorePageFilters';
import PdfDetailsView from './PdfDetailsView';
import Loading from './Loading';
import styles from '../styles/DocumentView.styles';

const useWindowSize = () => {
  const [size, setSize] = useState<{
    widthOfRightContainer: number;
  } | null>({ widthOfRightContainer: 670 });
  const updateSize = () => {
    const containerDiv = document.getElementById('pdf-viewer-container');
    if (containerDiv) {
      // get  pdf-viewer-div offsetHeight
      // set width of pdf-viewer-div to that of pdf-viewer-container
      setSize({
        widthOfRightContainer: containerDiv.getBoundingClientRect().width
      });
    }
    setSize(null);
  };
  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return { ...size, updateSize };
};
const DocumentRightGrid = ({
  exploreSelectedFilters,
  setExploreSelectedFilters,
  paginatedData
}: any) => {
  const { resultsState } = useContext(ResultsStore);
  const showAriaResponse = !resultsState?.selectedDocument && !resultsState.documentLoader;
  const { widthOfRightContainer, updateSize } = useWindowSize();
  const { generateTop10Summary } = useChatRiaAnswer();
  const rightGridRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (rightGridRef?.current && widthOfRightContainer) {
      rightGridRef.current.style.width = `${widthOfRightContainer}px`;
      rightGridRef.current.style.minWidth = `${widthOfRightContainer}px`;
      rightGridRef.current.style.maxWidth = `${widthOfRightContainer}px`;
    }
  }, [widthOfRightContainer]);
  useEffect(() => {
    updateSize();
  }, [resultsState?.selectedDocument]);
  return (
    <Stack
      spacing={2}
      sx={styles.rightGrid}
      pb={resultsState?.selectedDocument ? 0 : 5}
      height={resultsState?.selectedDocument ? '80vh' : '70vh'}
      ref={rightGridRef}>
      {showAriaResponse && (
        <ExplorePageFilters
          selectedFiltersInfo={exploreSelectedFilters}
          setSelectedFiltersInfo={setExploreSelectedFilters}
          paginatedData={paginatedData}
        />
      )}
      {/* AriaResponse is always mounted, but its visibility is controlled */}
      <Box
        sx={{
          visibility: showAriaResponse ? 'visible' : 'hidden',
          position: showAriaResponse ? 'static' : 'absolute',
          pb: 5
        }}>
        <AriaResponse />

        {/* show this button only when top 10 summary results are not in view */}
        {!resultsState.showTop10Summary && (
          <Stack mt={2} px={2} justifyContent='center' alignItems='center' sx={{ maxWidth: 389 }}>
            <LoadingButton
              loadingIndicator='Generating summary…'
              loading={resultsState.top10SummaryLoading}
              onClick={generateTop10Summary}
              variant='outlined'
              startIcon={
                <MagicWand
                  sx={{ fontSize: 12, opacity: resultsState.top10SummaryLoading ? 0.3 : 1 }}
                />
              }
              sx={styles.keyInsightsButton}>
              Generate key insights from Top Results
            </LoadingButton>
          </Stack>
        )}
      </Box>

      {resultsState.documentLoader ? (
        <Stack justifyContent='center' alignItems='center'>
          <Loading showTips={false} />
        </Stack>
      ) : (
        <Stack>
          <PdfDetailsView />
        </Stack>
      )}
    </Stack>
  );
};

export default memo(DocumentRightGrid);
