import { Box, Skeleton, Typography } from '@mui/material';
import React from 'react';
import styles from './styles';
import CustomBarGraph from '../CustomComponents/Graphs/CustomBarGraph';
import { SKELETON_HEIGHT } from './const';

const BarChart = ({ graphData, loading }: any) => {
  return (
    <Box display='flex' justifyContent='center' alignItems='center' sx={styles.barGraph}>
      {loading ? (
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='flex-end'
          alignItems='space-between'
          sx={styles.loaderBox}>
          <Box
            display='flex'
            justifyContent='space-between'
            flexWrap='wrap-reverse'
            sx={styles.barGraphRect}>
            {SKELETON_HEIGHT.map((item: any) => (
              <Skeleton
                key={item?.id}
                variant='rectangular'
                height={item?.height}
                width={item?.width}
              />
            ))}
          </Box>
          <Skeleton variant='rectangular' height='.2%' width='100%' />
        </Box>
      ) : (
        <Box height='100%' width='100%'>
          {graphData?.data && graphData?.data?.length > 0 ? (
            <CustomBarGraph
              keys={graphData?.keys}
              index={graphData?.index}
              groupMode={graphData?.graphStyle?.groupMode ?? 'stacked'}
              defs={graphData?.graphStyle?.defs}
              fill={graphData?.graphStyle?.fill}
              colors={graphData?.graphStyle?.colors}
              data={graphData?.data}
              paddings={graphData?.graphStyle?.padding}
              labelTextColor={graphData?.graphStyle?.labelTextColor}
              axisText={graphData?.graphStyle?.axisText}
              margins={graphData?.graphStyle?.margin}
              tickRotationValue={graphData?.graphStyle?.tickRotationValue}
              layout={graphData?.graphStyle?.layout}
            />
          ) : (
            <Typography variant='subtitle2' sx={{ fontSize: 20, fontWeight: 'bold' }}>
              No data
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default React.memo(BarChart);
