import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

const DidYouMeanText = ({
  text,
  onClick,
  fontStyle = {}
}: {
  text: string;
  // eslint-disable-next-line no-unused-vars
  onClick: (param: any) => void;
  // eslint-disable-next-line react/require-default-props
  fontStyle?: any;
}) => {
  return (
    <Box
      sx={{
        color: 'gray.900',
        fontSize: '14px',
        fontWeight: '700',
        ...fontStyle,
        '& span': {
          color: 'secondary.600',
          cursor: 'pointer',
          fontSize: '14px',
          fontWeight: '700',
          ...fontStyle,
          '&:hover': {
            textDecoration: 'underline'
          }
        }
      }}
      pt={2}>
      Did you mean:{' '}
      <Typography
        variant='subtitle2'
        component='span'
        onClick={() => {
          const search = text?.replace(/<\/?[^>]+(>|$)/g, ''); // Remove html tags
          onClick(search);
        }}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </Box>
  );
};

export default React.memo(DidYouMeanText);
