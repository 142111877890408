import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Virtuoso } from 'react-virtuoso';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import { filterSidebarOptionsStyles, styles } from './styles';
import { FilterSidebarOptionsProps } from './types';
import { isObject } from './utils';
import MultiSearchKeyword from './MultiSearchKeyword';
import { FILTER_TYPE } from './constants';
import VirtuosoCustomScrollbar from '../VirtuosoCustomScrollbar/VirtuosoCustomScrollbar';

const FilterSidebarOptions = ({
  setShowFilterSidebarOptions,
  selectedCategory,
  setSelectedCategory,
  selectedCategoryOptions,
  setSelectedCategoryOptions
}: FilterSidebarOptionsProps) => {
  const [searchText, setSearchText] = useState<string>('');
  const [options, setOptions] = useState<Array<string>>([]);

  const selectedCategoryValueKey = selectedCategory?.elasticSearchType || selectedCategory?.value;

  useEffect(() => {
    setOptions(selectedCategory.options);
    const container: any = document.getElementById('filter-sidebar-options-content-container');
    if (container) {
      container.scrollTop = 0;
    }
  }, [selectedCategory]);

  const handleChange = (ev: any) => {
    setSearchText(ev.target.value);
    const formattedOptions = selectedCategory.options.filter((option: any) => {
      let selectedOption = option;
      if (isObject(option)) {
        selectedOption = option.label;
      }
      return selectedOption.toLowerCase().includes(ev.target.value.toLowerCase());
    });
    setOptions(formattedOptions);
  };

  const handleCheckbox = (value: any) => {
    if (
      Object.keys(selectedCategoryOptions).length > 0 &&
      (selectedCategoryOptions[selectedCategoryValueKey]?.includes(value) ||
        selectedCategoryOptions[selectedCategoryValueKey]
          ?.map((item: any) => item?.toLowerCase())
          ?.includes(value.toLowerCase()))
    ) {
      const formattedSelectedCategoryOptions = selectedCategoryOptions[
        selectedCategoryValueKey
      ].filter((option: any) => option.toLowerCase() !== value.toLowerCase());
      if (formattedSelectedCategoryOptions?.length) {
        setSelectedCategoryOptions({
          ...selectedCategoryOptions,
          [selectedCategoryValueKey]: formattedSelectedCategoryOptions
        });
      } else {
        const { [selectedCategoryValueKey]: _, ...others } = selectedCategoryOptions;
        setSelectedCategoryOptions(others);
      }
    } else {
      setSelectedCategoryOptions({
        ...selectedCategoryOptions,
        [selectedCategoryValueKey]:
          Object.keys(selectedCategoryOptions).length > 0 &&
          selectedCategoryOptions[selectedCategoryValueKey]
            ? [...selectedCategoryOptions[selectedCategoryValueKey], value]
            : [value]
      });
    }
  };

  const handleSelectAll = () => {
    const formattedOptions = options.map((each: any) => (isObject(each) ? each?.label : each));
    const isAllCategoryOptionsSelected =
      Object.keys(selectedCategoryOptions)?.length > 0 &&
      selectedCategoryOptions[selectedCategoryValueKey]?.length === options?.length;
    if (!isAllCategoryOptionsSelected) {
      setSelectedCategoryOptions({
        ...selectedCategoryOptions,
        [selectedCategoryValueKey]: formattedOptions
      });
    } else {
      const { [selectedCategoryValueKey]: _, ...others } = selectedCategoryOptions;
      setSelectedCategoryOptions(others);
    }
  };

  const handleReset = () => {
    if (selectedCategory.filterType === FILTER_TYPE.MULTI_SEARCH) {
      const valuesToRemove = selectedCategory.options.map((option: any) => option.value);
      // removing keys present in valuesToRemove variable from selectedCategoryOptions
      const { ...result } = selectedCategoryOptions;
      valuesToRemove.forEach((value: any) => {
        delete result[value];
      });
      setSelectedCategoryOptions(result);
    } else {
      const { [selectedCategoryValueKey]: _, ...others } = selectedCategoryOptions;
      setSelectedCategoryOptions(others);
    }
  };

  const isChecked = (each: any) => {
    return (
      Object.keys(selectedCategoryOptions).length > 0 &&
      !!selectedCategoryOptions[selectedCategoryValueKey] &&
      selectedCategoryOptions[selectedCategoryValueKey]
        .map((item: any) => item?.toLowerCase())
        .includes(isObject(each) ? each.label?.toLowerCase() : each?.toLowerCase())
    );
  };

  return (
    <Box sx={filterSidebarOptionsStyles.container}>
      <Box height={60} sx={styles.sidebarHeaderFooter}>
        <Typography fontSize={20} color='gray.800' fontWeight={700} ml={4}>
          {selectedCategory.label}
        </Typography>
        <Button
          onClick={() => {
            setShowFilterSidebarOptions();
            setSelectedCategory();
          }}
          sx={{ ...styles.closeIconButton, mr: 4 }}>
          <CloseIcon sx={styles.icon} />
        </Button>
      </Box>
      <Divider />
      {selectedCategory.filterType === FILTER_TYPE.SELECT_OPTIONS && (
        <>
          <TextField
            placeholder={`Search ${selectedCategory.label}`}
            onChange={handleChange}
            value={searchText}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon sx={styles.icon} />
                </InputAdornment>
              ),
              sx: { borderRadius: 2, fontSize: 14, width: 242, height: 44 }
            }}
            sx={filterSidebarOptionsStyles.search}
          />
          <Box display='flex' justifyContent='flex-end'>
            {options?.length > 0 && (
              <Button
                sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  textTransform: 'none',
                  textDecorationLine: 'underline',
                  color: 'primary.600',
                  mr: 2.5
                }}
                onClick={handleSelectAll}>
                {Object.keys(selectedCategoryOptions)?.length > 0 &&
                selectedCategoryOptions[selectedCategoryValueKey]?.length === options?.length
                  ? 'Deselect all'
                  : 'Select all'}
              </Button>
            )}
          </Box>
        </>
      )}
      <Box
        sx={{
          ...styles.scrollContainer,
          ...filterSidebarOptionsStyles.contentContainer
        }}
        id='filter-sidebar-options-content-container'>
        {selectedCategory.filterType === FILTER_TYPE.SELECT_OPTIONS ? (
          <Virtuoso
            components={{
              Scroller: VirtuosoCustomScrollbar
            }}
            overscan={800}
            totalCount={options?.length ?? 0}
            data={options}
            // eslint-disable-next-line react/no-unstable-nested-components
            itemContent={(_index, each: any) => (
              <FormControlLabel
                key={isObject(each) ? each.label : each}
                control={
                  <Checkbox
                    checked={isChecked(each)}
                    value={isChecked(each)}
                    onChange={() => {
                      handleCheckbox(isObject(each) ? each.label : each);
                    }}
                    size='small'
                    style={{
                      color: isChecked(each) ? '#0D917D' : '#424242'
                    }}
                  />
                }
                label={isObject(each) ? `${each.label} (${each.count})` : each}
                sx={filterSidebarOptionsStyles.contentText}
              />
            )}
          />
        ) : (
          <Box m={2.5} ml={3}>
            <MultiSearchKeyword
              selectedCategoryOptions={selectedCategoryOptions}
              setSelectedCategoryOptions={setSelectedCategoryOptions}
              selectedCategory={selectedCategory}
            />
          </Box>
        )}
      </Box>
      <Divider />
      <Box minHeight={60} sx={styles.sidebarHeaderFooter}>
        <Button
          disabled={Object.keys(selectedCategoryOptions).length === 0}
          size='small'
          sx={{ ...styles.clearAll, ml: 3 }}
          onClick={handleReset}>
          Clear
        </Button>
      </Box>
    </Box>
  );
};

export default React.memo(FilterSidebarOptions);
