import React, { useState } from 'react';
import { Box, Dialog, DialogContent, IconButton, Stack, Typography } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

import { CrossIcon, WhatsNewHomeTabIcon } from '../../assets/svgs/Icons';
import WhatsNewImg from '../../assets/images/whatsNew.png';

import whatsNewStyles from './WhatsNew.styles';

interface SlideProps {
  onNext: () => void;
  onPrev: () => void;
  handleCloseOnboardingExperience: () => void;
}

const Slide1 = ({
  onNext,
  handleCloseOnboardingExperience
}: {
  onNext: () => void;
  handleCloseOnboardingExperience: () => void;
}) => {
  return (
    <>
      <Box>
        <Box width='318px'>
          <Typography sx={whatsNewStyles.mainHeading}>Introducing What&apos;s New!</Typography>
          <Typography sx={whatsNewStyles.subHeading} mt='12px'>
            Staying on top of changes has never been easier!
          </Typography>
        </Box>
        <IconButton
          onClick={handleCloseOnboardingExperience}
          sx={whatsNewStyles.secondaryCrossIconButton}>
          <CrossIcon />
        </IconButton>
      </Box>

      <Box sx={whatsNewStyles.slide1Img}>
        <img src={WhatsNewImg} height='100%' alt='Whats new stats' />
      </Box>
      <IconButton sx={whatsNewStyles.navButton1} onClick={onNext}>
        <ArrowForward />
      </IconButton>
    </>
  );
};

const Slide2 = ({ onNext, onPrev, handleCloseOnboardingExperience }: SlideProps) => {
  return (
    <>
      <Box>
        <Box>
          <Typography sx={whatsNewStyles.mainHeading1}>Weekly Updates</Typography>
          <Typography sx={whatsNewStyles.subHeading} mt='6px'>
            Every week, we&apos;ll highlight updates directly within the RIA platform.
          </Typography>
          <Typography sx={whatsNewStyles.mainHeading1} mt='32px'>
            Interactive Content
          </Typography>
          <Typography sx={whatsNewStyles.subHeading} mt='6px'>
            Access features updates through interactive visuals and concise descriptions.
          </Typography>
        </Box>

        <IconButton
          onClick={handleCloseOnboardingExperience}
          sx={whatsNewStyles.secondaryCrossIconButton}>
          <CrossIcon />
        </IconButton>
      </Box>

      <Box sx={whatsNewStyles.slideImg}>
        <WhatsNewHomeTabIcon />
      </Box>
      <Stack direction='row'>
        <IconButton sx={whatsNewStyles.navButton1} onClick={onPrev}>
          <ArrowBack />
        </IconButton>
        <IconButton sx={whatsNewStyles.navButton2} onClick={onNext}>
          <ArrowForward />
        </IconButton>
      </Stack>
    </>
  );
};

const Slide3 = ({ onNext, onPrev, handleCloseOnboardingExperience }: SlideProps) => {
  return (
    <>
      <Box>
        <Box>
          <Typography sx={whatsNewStyles.mainHeading1}>Stay Informed</Typography>
          <Typography sx={whatsNewStyles.subHeading} mt='6px'>
            Always be the first to know about updates to make the most out of RIA!{' '}
          </Typography>
          <Typography sx={whatsNewStyles.mainHeading1} mt='32px'>
            Save Time{' '}
          </Typography>
          <Typography sx={whatsNewStyles.subHeading} mt='6px'>
            Quick visual guides mean you spend less time learning and more time utilizing new
            features.
          </Typography>
        </Box>

        <IconButton
          onClick={handleCloseOnboardingExperience}
          sx={whatsNewStyles.secondaryCrossIconButton}>
          <CrossIcon />
        </IconButton>
      </Box>
      <Box sx={whatsNewStyles.slideImg}>
        <WhatsNewHomeTabIcon />
      </Box>
      <Stack direction='row'>
        <IconButton sx={whatsNewStyles.navButton1} onClick={onPrev}>
          <ArrowBack />
        </IconButton>
        <IconButton sx={whatsNewStyles.navButton2} onClick={onNext}>
          <ArrowForward />
        </IconButton>
      </Stack>
    </>
  );
};

const Slide4 = ({
  onPrev,
  handleCloseOnboardingExperience
}: {
  onPrev: () => void;
  handleCloseOnboardingExperience: () => void;
}) => {
  return (
    <>
      <Box>
        <Box width='318px'>
          <Typography sx={whatsNewStyles.mainHeading2}>Access from:</Typography>

          <Typography sx={whatsNewStyles.subHeading} mt='12px !important'>
            <Typography sx={whatsNewStyles.subHeading1} display='inline'>
              Home Page
            </Typography>{' '}
            for quick entry and latest updates of past 30 days
          </Typography>

          <Typography sx={whatsNewStyles.subHeading} mt='12px !important'>
            <Typography sx={whatsNewStyles.subHeading1} display='inline'>
              Help Center
            </Typography>{' '}
            to get full archive and filter capabilities for detailed insights
          </Typography>
        </Box>
        <IconButton
          onClick={handleCloseOnboardingExperience}
          sx={whatsNewStyles.secondaryCrossIconButton}>
          <CrossIcon />
        </IconButton>
      </Box>
      <Box sx={whatsNewStyles.slideImg}>
        <WhatsNewHomeTabIcon />
      </Box>
      <Stack direction='row'>
        <IconButton sx={whatsNewStyles.navButton1} onClick={onPrev}>
          <ArrowBack />
        </IconButton>
        <IconButton sx={whatsNewStyles.navButton2} onClick={handleCloseOnboardingExperience}>
          <ArrowForward />
        </IconButton>
      </Stack>
    </>
  );
};

interface WhatsNewOnboardingExperienceProps {
  showOnboardingExperience: boolean;
  handleCloseOnboardingExperience: () => void;
}

const WhatsNewOnboardingExperience: React.FC<WhatsNewOnboardingExperienceProps> = ({
  showOnboardingExperience,
  handleCloseOnboardingExperience
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNextSlide = () => setCurrentSlide(prev => Math.min(prev + 1, 3));
  const handlePrevSlide = () => setCurrentSlide(prev => Math.max(prev - 1, 0));

  const slides = [
    <Slide1
      key={1}
      onNext={handleNextSlide}
      handleCloseOnboardingExperience={handleCloseOnboardingExperience}
    />,
    <Slide2
      key={2}
      onNext={handleNextSlide}
      onPrev={handlePrevSlide}
      handleCloseOnboardingExperience={handleCloseOnboardingExperience}
    />,
    <Slide3
      key={3}
      onNext={handleNextSlide}
      onPrev={handlePrevSlide}
      handleCloseOnboardingExperience={handleCloseOnboardingExperience}
    />,
    <Slide4
      key={4}
      onPrev={handlePrevSlide}
      handleCloseOnboardingExperience={handleCloseOnboardingExperience}
    />
  ];

  return (
    <Dialog
      open={showOnboardingExperience}
      sx={whatsNewStyles.modalContainer}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <DialogContent>{slides[currentSlide]}</DialogContent>
    </Dialog>
  );
};

export default WhatsNewOnboardingExperience;
